import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * 書類並び順(値オブジェクト)
 *
 *
 *
 * updated_at_asc:
 * updated_at_desc:
 * document_control_number_asc:
 * document_control_number_desc:  */
export type DocumentSortCondition = z.infer<
  typeof documentSortConditionMeta.schema
>;
/**
 * 書類並び順(値オブジェクト)のメタデータ。
 *
 *  */
export const documentSortConditionMeta = new ValueObjectMetaEnum<
  [
    "updated_at_asc",
    "updated_at_desc",
    "document_control_number_asc",
    "document_control_number_desc"
  ]
>(
  "DocumentSortCondition",
  "\u66F8\u985E\u4E26\u3073\u9806",
  messageId("lbl.\u66F8\u985E\u4E26\u3073\u9806"),
  [
    "updated_at_asc",
    "updated_at_desc",
    "document_control_number_asc",
    "document_control_number_desc",
  ],
  {
    updated_at_asc:
      "code.\u4E26\u3073\u9806.\u66F4\u65B0\u65E5\u6642\u6607\u9806",
    updated_at_desc:
      "code.\u4E26\u3073\u9806.\u66F4\u65B0\u65E5\u6642\u964D\u9806",
    document_control_number_asc:
      "code.\u4E26\u3073\u9806.\u66F8\u985E\u7BA1\u7406\u756A\u53F7\u6607\u9806",
    document_control_number_desc:
      "code.\u4E26\u3073\u9806.\u66F8\u985E\u7BA1\u7406\u756A\u53F7\u964D\u9806",
  }
);
