import { hasValue, isNullish } from "../../../../lib/util/common-util";
import { useMemo } from "react";
import { ReportOfficeMemberNotesPage } from "../../../model/document/OfficeMemberNotes/ReportOfficeMemberNotesPage";
import { ApplicationOfficeMemberNotesPage } from "../../../model/document/OfficeMemberNotes/ApplicationOfficeMemberNotesPage";
import { useAppParams } from "../../../../hooks/use-app-params";
import { useAppGetProjectQuery } from "../../../../hooks/query/use-app-get-project-query";
import { useSavedDocument } from "../../../../hooks/document/use-saved-document";
import { ApplicationOfficeMemberNotes } from "../../../../lib/object/value/application-office-member-notes";
import { ReportOfficeMemberNotes } from "../../../../lib/object/value/report-office-member-notes";

export const FN40S39OfficeMemberNotes = () => {
  const { documentId } = useAppParams();

  const { projectId, document } = useSavedDocument(documentId);

  // 案件情報を取得。
  const { data: projectData } = useAppGetProjectQuery(
    {
      projectId: projectId ?? -1,
    },
    { skip: isNullish(projectId) },
  );

  // 案件状態
  const projectState = useMemo(() => {
    return projectData?.projectState;
  }, [projectData?.projectState]);

  // 事務局記入内容（申請）の初期値
  const initialApplicationOfficeMemberNotesContent:
    | ApplicationOfficeMemberNotes
    | undefined = useMemo(() => {
    if (isNullish(document) || !document.isApplication) return undefined;
    return (
      document.officeMemberNotes ?? emptyApplicationOfficeMemberNotesContent()
    );
  }, [document]);

  // 事務局記入内容（報告）の初期値
  const initialReportOfficeMemberNotesContent:
    | ReportOfficeMemberNotes
    | undefined = useMemo(() => {
    if (isNullish(document) || document.isApplication) return undefined;
    return document.officeMemberNotes ?? emptyReportOfficeMemberNotesContent();
  }, [document]);

  return (
    <>
      {/*書類タイプが申請系の場合*/}
      {hasValue(document) &&
        document.isApplication &&
        hasValue(initialApplicationOfficeMemberNotesContent) &&
        hasValue(documentId) &&
        hasValue(projectState) && (
          <ApplicationOfficeMemberNotesPage
            initialOfficeMemberNotesContent={
              initialApplicationOfficeMemberNotesContent
            }
            projectState={projectState}
            documentState={document.documentState}
            documentId={documentId}
            sx={{ h: "100%" }}
          />
        )}
      {/*書類タイプが報告系の場合*/}
      {hasValue(document) &&
        !document.isApplication &&
        hasValue(initialReportOfficeMemberNotesContent) &&
        hasValue(projectState) &&
        hasValue(documentId) && (
          <ReportOfficeMemberNotesPage
            initialOfficeMemberNotesContent={
              initialReportOfficeMemberNotesContent
            }
            projectState={projectState}
            documentState={document.documentState}
            documentId={documentId}
          />
        )}
    </>
  );
};

function emptyApplicationOfficeMemberNotesContent(): ApplicationOfficeMemberNotes {
  return {
    contractPerson: "",
    provisionFeeRequired: "required",
    reviewFeeRequired: "required",
    invoiceIssuanceMemoForReviewFee: "",
    explanationOfStockMaintenanceFee: "",
    status: "",
    patentLicenseExplanationStatus: "not_explained",
    securityExportControl: "",
    officeMemberNote: "",
  };
}

function emptyReportOfficeMemberNotesContent(): ReportOfficeMemberNotes {
  return {
    officeMemberNote: "",
  };
}
