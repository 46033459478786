import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * iPS細胞の使用目的詳細区分(値オブジェクト)
 *
 *
 *
 * cellbank:
 * clinical:  */
export type IpsCellStockUsePurposeSubType = z.infer<
  typeof ipsCellStockUsePurposeSubTypeMeta.schema
>;
/**
 * iPS細胞の使用目的詳細区分(値オブジェクト)のメタデータ。
 *
 *  */
export const ipsCellStockUsePurposeSubTypeMeta = new ValueObjectMetaEnum<
  ["cellbank", "clinical"]
>(
  "IpsCellStockUsePurposeSubType",
  "iPS\u7D30\u80DE\u306E\u4F7F\u7528\u76EE\u7684\u8A73\u7D30\u533A\u5206",
  messageId(
    "lbl.iPS\u7D30\u80DE\u306E\u4F7F\u7528\u76EE\u7684\u8A73\u7D30\u533A\u5206"
  ),
  ["cellbank", "clinical"],
  {
    cellbank:
      "code.iPS\u7D30\u80DE\u306E\u4F7F\u7528\u76EE\u7684\u8A73\u7D30\u533A\u5206.\u30BB\u30EB\u30D0\u30F3\u30AF\u306E\u4F5C\u88FD",
    clinical:
      "code.iPS\u7D30\u80DE\u306E\u4F7F\u7528\u76EE\u7684\u8A73\u7D30\u533A\u5206.\u81E8\u5E8A\u7814\u7A76\u30FB\u6CBB\u9A13",
  }
);
