import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";
import { InstitutionSaved } from "../../../../../../lib/object/entity/institution";
import React, { memo, useMemo } from "react";
import { TFunction } from "i18next";
import { SearchResultRowContent } from "@pscsrvlab/psc-react-components/src/components/search/row/types";
import { getMessageFromEnumValue } from "../../../../../../lib/util/common-util";
import { foreignTypeMeta } from "../../../../../../lib/object/value/foreign-type";
import { commercialTypeMeta } from "../../../../../../lib/object/value/commercial-type";
import { SearchResultTableContent } from "@pscsrvlab/psc-react-components/src/components/search/table/types";
import { CMSearchResultTable } from "@pscsrvlab/psc-react-components";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";

export type SearchResultTableInstitutionProps = {
  entities: InstitutionSaved[];
} & ComponentStyleProps;

export const SearchResultTableInstitution = memo(
  function SearchResultTableInstitution({
    entities,

    sx,
    ...rest
  }: SearchResultTableInstitutionProps) {
    const { t } = useAppTranslation();

    const header = useMemo(() => {
      return {
        cells: [
          { value: t("lbl.機関ID"), width: "130px" },
          { value: t("lbl.機関名"), width: "130px" },
          { value: t("lbl.機関名かな"), width: "130px" },
          { value: t("lbl.国内外区分"), width: "100px" },
          { value: t("lbl.営利非営利区分"), width: "100px" },
          { value: t("lbl.更新日時"), width: "140px" },
        ],
      };
    }, [t]);
    const tableContent: SearchResultTableContent = useMemo(() => {
      return {
        header,
        rows: createRows(entities, t),
      };
    }, [entities, header, t]);

    return (
      <CMSearchResultTable
        content={tableContent}
        stickyHeaderTop={"29px"}
        stickyFirstColLeft={"30px"}
        sx={sx}
        {...rest}
      />
    );
  },
);

function createRows(
  entities: InstitutionSaved[],
  t: TFunction<"translation", undefined, "translation">,
): SearchResultRowContent[] {
  return entities.map((entity) => {
    return {
      cells: [
        {
          // 機関ID
          type: "react_router_link",
          value: {
            text: entity.displayInstitutionId,
            to: `/institution/${entity.id}`,
          },
        },
        {
          // 機関名
          type: "text",
          value: entity.name,
        },
        {
          // 機関名かな
          type: "text",
          value: entity.nameKana,
        },
        {
          // 国内外区分
          type: "text",
          value: getMessageFromEnumValue(
            t,
            foreignTypeMeta,
            entity.foreignType,
          ),
        },
        {
          // 営利非営利区分
          type: "text",
          value: getMessageFromEnumValue(
            t,
            commercialTypeMeta,
            entity.commercialType,
          ),
        },
        {
          // 更新日時
          type: "datetime",
          value: entity.updated?.datetime,
        },
      ],
    };
  });
}
