import {
  messageId,
  PropertyMeta,
  EntityMeta,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { institutionIdMeta } from "../value/institution-id";
import { displayInstitutionIdMeta } from "../value/display-institution-id";
import { institutionNameMeta } from "../value/institution-name";
import { institutionNameKanaMeta } from "../value/institution-name-kana";
import { foreignTypeMeta } from "../value/foreign-type";
import { commercialTypeMeta } from "../value/commercial-type";
import { creationMetadataMeta } from "../value/creation-metadata";
import { updateMetadataMeta } from "../value/update-metadata";
/**
 * 機関(エンティティ)
 *
 *  */
export type Institution = z.infer<typeof schema>;
export type InstitutionSaved = z.infer<typeof savedSchema>;
const minimalSchema = z.object({
  id: institutionIdMeta.minimalSchema.optional(),
  displayInstitutionId: displayInstitutionIdMeta.minimalSchema,
  name: institutionNameMeta.minimalSchema,
  nameKana: institutionNameKanaMeta.minimalSchema,
  foreignType: foreignTypeMeta.minimalSchema,
  commercialType: commercialTypeMeta.minimalSchema,
  created: creationMetadataMeta.minimalSchema,
  updated: updateMetadataMeta.minimalSchema,
});
const savedMinimalSchema = z.object({
  id: institutionIdMeta.minimalSchema,
  displayInstitutionId: displayInstitutionIdMeta.savedMinimalSchema,
  name: institutionNameMeta.savedMinimalSchema,
  nameKana: institutionNameKanaMeta.savedMinimalSchema,
  foreignType: foreignTypeMeta.savedMinimalSchema,
  commercialType: commercialTypeMeta.savedMinimalSchema,
  created: creationMetadataMeta.savedMinimalSchema,
  updated: updateMetadataMeta.savedMinimalSchema,
});
const schema = z.object({
  id: institutionIdMeta.minimalSchema.optional(),
  displayInstitutionId: displayInstitutionIdMeta.schema,
  name: institutionNameMeta.schema,
  nameKana: institutionNameKanaMeta.schema,
  foreignType: foreignTypeMeta.schema,
  commercialType: commercialTypeMeta.schema,
  created: creationMetadataMeta.schema,
  updated: updateMetadataMeta.schema,
});
const savedSchema = z.object({
  id: institutionIdMeta.minimalSchema,
  displayInstitutionId: displayInstitutionIdMeta.savedSchema,
  name: institutionNameMeta.savedSchema,
  nameKana: institutionNameKanaMeta.savedSchema,
  foreignType: foreignTypeMeta.savedSchema,
  commercialType: commercialTypeMeta.savedSchema,
  created: creationMetadataMeta.savedSchema,
  updated: updateMetadataMeta.savedSchema,
});
const jsonSchema = z.object({
  id: institutionIdMeta.jsonSchema.optional(),
  displayInstitutionId: displayInstitutionIdMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  name: institutionNameMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  nameKana: institutionNameKanaMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  foreignType: foreignTypeMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  commercialType: commercialTypeMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  created: creationMetadataMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  updated: updateMetadataMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
/**
 * 機関(エンティティ)のメタデータ。
 *
 *  */
export const institutionMeta = new EntityMeta<
  typeof schema,
  typeof savedSchema,
  typeof jsonSchema
>(
  "Institution",
  "\u6A5F\u95A2",
  messageId("lbl.\u6A5F\u95A2"),
  minimalSchema,
  savedMinimalSchema,
  schema,
  savedSchema,
  jsonSchema,
  undefined,
  undefined,
  {
    id: new PropertyMeta(
      "id",
      "ID",
      messageId("lbl.ID"),
      "afterSave",
      false,
      institutionIdMeta
    ),
    displayInstitutionId: new PropertyMeta(
      "displayInstitutionId",
      "\u8868\u793A\u7528\u6A5F\u95A2ID",
      messageId("lbl.\u8868\u793A\u7528\u6A5F\u95A2ID"),
      "always",
      false,
      displayInstitutionIdMeta
    ),
    name: new PropertyMeta(
      "name",
      "\u6A5F\u95A2\u540D",
      messageId("lbl.\u6A5F\u95A2\u540D"),
      "always",
      false,
      institutionNameMeta
    ),
    nameKana: new PropertyMeta(
      "nameKana",
      "\u6A5F\u95A2\u540D\u304B\u306A",
      messageId("lbl.\u6A5F\u95A2\u540D\u304B\u306A"),
      "always",
      false,
      institutionNameKanaMeta
    ),
    foreignType: new PropertyMeta(
      "foreignType",
      "\u56FD\u5185\u5916\u533A\u5206",
      messageId("lbl.\u56FD\u5185\u5916\u533A\u5206"),
      "always",
      false,
      foreignTypeMeta
    ),
    commercialType: new PropertyMeta(
      "commercialType",
      "\u55B6\u5229\u975E\u55B6\u5229\u533A\u5206",
      messageId("lbl.\u55B6\u5229\u975E\u55B6\u5229\u533A\u5206"),
      "always",
      false,
      commercialTypeMeta
    ),
    created: new PropertyMeta(
      "created",
      "\u4F5C\u6210",
      messageId("lbl.\u4F5C\u6210"),
      "always",
      false,
      creationMetadataMeta
    ),
    updated: new PropertyMeta(
      "updated",
      "\u66F4\u65B0",
      messageId("lbl.\u66F4\u65B0"),
      "always",
      false,
      updateMetadataMeta
    ),
  }
);
