import { useGetProjectSnapshotQuery } from "../../store/api/generated/stock-request-api";
import { isNullish } from "../../lib/util/common-util";
import { useMemo } from "react";
import {
  projectSnapshotMeta,
  ProjectSnapshotSaved,
} from "../../lib/object/entity/project-snapshot";

export function useAppGetProjectSnapshotQuery(
  arg: Parameters<typeof useGetProjectSnapshotQuery>[0],
  options?: Parameters<typeof useGetProjectSnapshotQuery>[1],
  skipOptionalValidations = true, // get系は、skipOptionalValidationsのデフォルトはtrueとする。
): { data: ProjectSnapshotSaved | null } {
  const { data: rawData } = useGetProjectSnapshotQuery(arg, options);
  const data = useMemo(() => {
    if (isNullish(rawData)) return null;
    return projectSnapshotMeta.toSavedDomainObjectOrNull(
      rawData,
      skipOptionalValidations,
    );
  }, [rawData, skipOptionalValidations]);
  return { data };
}
