import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";
import { LoginUserInfo } from "../../../../../../store/auth/types";
import { RevisionRequestItemViewModel } from "../../../../../../lib/object/vm/revision-request-view-model";
import {
  ApplicationContentViewModel,
  ApplicationDocument,
} from "../../_components/ApplicationDocument/ApplicationDocument";
import React, { Ref, useCallback } from "react";
import { SignatureValue } from "../../../../../../lib/object/value/signature-value";
import {
  ChangeApplicationViewModel,
  ChangeReasonViewModel,
} from "../../../../../../lib/object/vm/change-application-view-model";
import { PositionProps } from "@chakra-ui/react";
import { Comments, ValidationError } from "@pscsrvlab/psc-react-components";

export type ChangeApplicationDocumentProps = {
  loginUserInfo: LoginUserInfo;

  editMode: "editable" | "readOnly";

  /**
   * 値。
   */
  value: ChangeApplicationViewModel;
  onChange?: (
    change: (before: ChangeApplicationViewModel) => ChangeApplicationViewModel,
  ) => void;

  /**
   * 修正依頼。
   */
  revisionRequestItems?: RevisionRequestItemViewModel[];

  /**
   * 1書類単位の修正依頼のコメント・返信履歴（履歴参照用）
   */
  commentsList?: Comments[];

  /**
   * 修正依頼コメントを選択したときのコールバック。
   * 未選択状態になる場合はnullを送出する。
   */
  onSelectComment?: (path: string | null) => void;
  /**
   * 修正依頼の変更。
   */
  onChangeRevisionRequestItems?: (
    change: (
      before: RevisionRequestItemViewModel[],
    ) => RevisionRequestItemViewModel[],
  ) => void;

  revisionMode?:
  | "none"
  | "readOnly"
  | "office_member_editable"
  | "applicant_editable";

  /**
   * 編集モード時、連絡担当者の最初の一人目を編集不能にしたければtrueを設定する。
   */
  freezeFirstContactPerson?: boolean;

  /**
   * バリデーションエラー。
   * 編集画面でのみ必要。
   */
  validationErrors?: ValidationError[];

  /**
   * stickyなエリアのtopの値。
   */
  stickyAreaTop?: PositionProps["top"];

  scrollableRef: Ref<any>;
  scrollOffset: number;
} & ComponentStyleProps;

export const ChangeApplicationDocument = ({
  loginUserInfo,

  editMode,

  value,
  onChange,

  revisionRequestItems,
  commentsList,
  onSelectComment,
  onChangeRevisionRequestItems,

  revisionMode = "none",

  freezeFirstContactPerson = false,

  validationErrors,

  stickyAreaTop,

  scrollableRef,
  scrollOffset,

  sx,
  ...rest
}: ChangeApplicationDocumentProps) => {
  const handleChangeContent = useCallback(
    (
      change: (
        before: ApplicationContentViewModel,
      ) => ApplicationContentViewModel,
    ) => {
      onChange?.((before) => ({
        ...before,
        contentVM: change(before.contentVM),
      }));
    },
    [onChange],
  );
  const handleChangeSignatureValue = useCallback(
    (change: (before: SignatureValue) => SignatureValue) => {
      onChange?.((before) => ({
        ...before,
        signatureValue: change(before.signatureValue),
      }));
    },
    [onChange],
  );
  const handleChangeReason = useCallback(
    (change: (before: ChangeReasonViewModel) => ChangeReasonViewModel) => {
      onChange?.((before) => ({
        ...before,
        changeReasonVM: change(before.changeReasonVM),
      }));
    },
    [onChange],
  );

  return (
    <ApplicationDocument
      loginUserInfo={loginUserInfo}
      documentType={"change_application"}
      editMode={editMode}
      projectId={value.projectId}
      showControlNumbers={true}
      projectControlNumber={value.projectControlNumber}
      documentControlNumber={value.documentControlNumber}
      showDates={true}
      submissionDate={value.submissionDate}
      receptionDate={value.receptionDate}
      approvalDate={value.approvalDate}
      documentCompletionDate={value.documentCompletionDate}
      value={value.contentVM}
      onChange={handleChangeContent}
      signatureValue={value.signatureValue}
      onChangeSignatureValue={handleChangeSignatureValue}
      beforeValue={value.beforeValue}
      changeReasonVM={value.changeReasonVM}
      onChangeReason={handleChangeReason}
      revisionRequestItems={revisionRequestItems}
      commentsList={commentsList}
      onSelectComment={onSelectComment}
      onChangeRevisionRequestItems={onChangeRevisionRequestItems}
      revisionMode={revisionMode}
      freezeFirstContactPerson={freezeFirstContactPerson}
      validationErrors={validationErrors}
      stickyAreaTop={stickyAreaTop}
      scrollableRef={scrollableRef}
      scrollOffset={scrollOffset}
      sx={sx}
      {...rest}
    />
  );
};
