import {
  Box,
  Button,
  Center,
  Container,
  Input,
  Skeleton,
  Tag,
  Text,
  VStack,
} from "@chakra-ui/react";
import QRCode from "qrcode.react";
import { FormEvent, useEffect, useState } from "react";
import { useAppDispatch } from "../../../../hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { setupTotp, verifyTotp } from "../../../../store/auth/slice";
import { Form, useNavigate } from "react-router-dom";
import useCustomToast from "../../../../hooks/use-custom-toast";
import log from "loglevel";
import { errorNameAndMessageOf } from "../../../../lib/util/error-util";
import { useAppTranslation } from "../../../../hooks/use-app-translation";

/**
 * 二段階認証設定ページ
 */
export const FN10S03MFASettings = () => {
  const dispatch = useAppDispatch();
  const {
    setupTotpState: { isFetching, data },
    enabledMfa,
  } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const { t } = useAppTranslation();
  const { errorToast } = useCustomToast();

  // 認証コード
  const [mfaCode, setMfaCode] = useState("");
  // シークレットキーの表示状態
  const [hideSecret, setHideSecret] = useState(true);
  // ロード状態
  const [isLoading, setIsLoading] = useState(false);

  useEffect(
    () => {
      // コンポーネント初回ロード時にシークレットキーを生成
      dispatch(setupTotp());
    },
    // eslint-disable-next-line
    [],
  );

  useEffect(
    () => {
      // 既に二段階認証が設定済みであればホーム画面へ遷移
      if (enabledMfa) {
        navigate("/");
      }
    },
    // eslint-disable-next-line
    [enabledMfa],
  );

  /**
   * 認証ボタン押下時のイベント
   * @param event イベント情報
   */
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Submit防止
    setIsLoading(true);
    // バリデーションエラーがある場合は中断
    if (!validate()) {
      setIsLoading(false);
      return;
    }
    // 二段階認証設定処理を実行
    await executeSetMFA();
  };

  /**
   * バリデーションチェック
   * @return true: 非エラー / false: エラー
   */
  const validate = () => {
    // 認証コードが未入力の場合
    if (!mfaCode) {
      errorToast(t("mes.必須チェックエラー2", { name: t("lbl.認証コード") }));
      return false;
    }
    return true;
  };

  /**
   * 二段階認証設定処理
   */
  const executeSetMFA = async () => {
    try {
      // 非同期処理を実行
      await dispatch(verifyTotp({ code: mfaCode })).unwrap();
      // ここまで到達すると二段階認証設定成功
      // ページ遷移は useEffect 側で行われる為ここでは実装しない
      //
    } catch (e) {
      log.error("二段階認証設定処理でエラー発生：", e);

      // ロードを中断
      setIsLoading(false);

      const error = errorNameAndMessageOf(e);

      // 認証コードに誤りがある場合
      if (
        error?.name === "InvalidParameterException" ||
        error?.name === "EnableSoftwareTokenMFAException"
      ) {
        errorToast(t("mes.認証コード不一致エラーメッセージ"));
        return;
      }
      // その他のエラーの場合はやり直し
      errorToast(t("mes.汎用エラーメッセージ"));
      setMfaCode("");
      return;
    }
  };

  return (
    <>
      <VStack
        flex={"1 1 auto"}
        minH={0}
        alignSelf={"stretch"}
        overflow={"auto"}
        pb={"40px"}
      >
        <Container pt={"24px"} pb={"24px"} maxW={"800px"}>
          <Form onSubmit={handleSubmit}>
            <VStack align={"center"} spacing={"30px"} mt={"40px"}>
              <Text fontSize={"24px"} fontWeight={"bold"}>
                {t("lbl.二段階認証設定画面タイトル")}
              </Text>
            </VStack>
            <VStack align={"start"} spacing={"30px"} mt={"40px"}>
              <Text fontSize={"14px"}>
                {t("gdc.二段階認証手順1ガイダンス")}
              </Text>
              <Box>
                <Text fontSize={"14px"}>
                  {t("gdc.二段階認証手順2ガイダンス")}
                </Text>
                <Box ml={"50px"} mt={"10px"}>
                  {isFetching ? (
                    <Skeleton w={"150px"} h={"150px"} noOfLines={3} />
                  ) : (
                    <QRCode
                      value={data?.qrCode ?? ""}
                      width={"150px"}
                      height={"150px"}
                    />
                  )}
                </Box>
              </Box>
              <Box>
                <Text fontSize={"14px"} ml={"50px"}>
                  {t("gdc.シークレットキー表示案内")}
                </Text>
                <VStack align={"start"} ml={"50px"} mt={"10px"}>
                  <Button
                    disabled={isFetching}
                    colorScheme={"teal"}
                    onClick={() => setHideSecret(!hideSecret)}
                  >
                    {hideSecret
                      ? t("btn.シークレットキーを表示ボタン")
                      : t("btn.シークレットキーを隠すボタン")}
                  </Button>
                  {!hideSecret && (
                    <Tag colorScheme={"yellow"}>{data?.secret ?? ""}</Tag>
                  )}
                </VStack>
              </Box>
              <Box>
                <Text fontSize={"14px"}>
                  {t("gdc.二段階認証手順3ガイダンス")}
                </Text>
                <Input
                  type={"number"}
                  value={mfaCode}
                  placeholder={t("lbl.認証コード")}
                  onChange={(e) => setMfaCode(e.target.value)}
                  w={"300px"}
                  ml={"50px"}
                  mt={"10px"}
                ></Input>
              </Box>
            </VStack>
            <Center mt={"40px"}>
              <Button
                type={"submit"}
                colorScheme={"teal"}
                isLoading={isLoading}
                disabled={isFetching || isLoading}
                w={"150px"}
              >
                {t("btn.認証ボタン")}
              </Button>
            </Center>
          </Form>
        </Container>
      </VStack>
    </>
  );
};
