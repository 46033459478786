import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";
import { Center, Text, VStack } from "@chakra-ui/react";
import { LatestMailTable } from "./_components/LatestMailTable/LatestMailTable";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";

export type LatestMailSectionProps = ComponentStyleProps;

export const LatestMailSection = ({ sx, ...rest }: LatestMailSectionProps) => {
  const { t } = useAppTranslation();

  return (
    <VStack sx={sx} {...rest} alignItems={"stretch"} spacing={"8px"}>
      <Center>
        <Text
          color={"blue.700"}
          fontSize={"28px"}
          lineHeight={"28px"}
          fontWeight={"bold"}
          whiteSpace={"nowrap"}
        >
          {t("lbl.新着メールタイトル")}
        </Text>
      </Center>
      <LatestMailTable />
    </VStack>
  );
};
