import { LoginUserInfo } from "../../../../../../../store/auth/types";
import { useMemo } from "react";
import { RevisionRequestItemViewModel } from "../../../../../../../lib/object/vm/revision-request-view-model";
import { TerminationReportContentViewModel } from "../../../../../../../lib/object/vm/termination-report-view-model";
import {
  getCommentProps,
  useRevisionRequestComment,
} from "../../../../../../../hooks/revision-request/use-revision-request-comment";

export function useTerminationReportDocumentCommentProps(
  loginUserInfo: LoginUserInfo,
  value: TerminationReportContentViewModel,

  revisionMode:
    | "none"
    | "readOnly"
    | "office_member_editable"
    | "applicant_editable",
  revisionRequestItems?: RevisionRequestItemViewModel[],
  /**
   * 修正依頼コメントを選択したときのコールバック。
   * 未選択状態になる場合はnullを送出する。
   */
  onSelectComment?: (path: string | null) => void,
  /**
   * 修正依頼の変更。
   */
  onChangeRevisionRequestItems?: (
    requestsChange: (
      before: RevisionRequestItemViewModel[],
    ) => RevisionRequestItemViewModel[],
  ) => void,
) {
  const { ctx } = useRevisionRequestComment(
    loginUserInfo,
    revisionMode,
    revisionRequestItems,
    onSelectComment,
    onChangeRevisionRequestItems,
  );

  // [注意] 修正依頼コメントのパスは、データ構造のJSONポインタと1:1対応させることは不可能なので、修正依頼特有のパスとする。
  //   単純に、修正依頼の配置場所の識別子だと考えれば良い。
  const projectName = useMemo(
    () => getCommentProps(ctx, "/00:projectName"),
    [ctx],
  );
  const institution = useMemo(
    () => getCommentProps(ctx, "/01:institution"),
    [ctx],
  );
  const principalInvestigator = useMemo(
    () => getCommentProps(ctx, "/02:principalInvestigator"),
    [ctx],
  );
  const newApplicationApprovalDate = useMemo(
    () => getCommentProps(ctx, "/03:newApplicationApprovalDate"),
    [ctx],
  );
  const usageEndDate = useMemo(
    () => getCommentProps(ctx, "/04:usageEndDate"),
    [ctx],
  );
  const cellProvisionType = useMemo(
    () => getCommentProps(ctx, "/05:cellProvisionType"),
    [ctx],
  );
  const cellProvisions = useMemo(
    () => getCommentProps(ctx, "/06:cellProvisions"),
    [ctx],
  );
  const cellProvisionsN = useMemo(
    () =>
      value.cellProvisions.map((_v, idx) => {
        const paddedIdx = padIndex(idx);
        return getCommentProps(ctx, `/07:cellProvisions/${paddedIdx}`);
      }),
    [ctx, value],
  );
  const partner = useMemo(() => getCommentProps(ctx, "/08:partners"), [ctx]);
  const partnerN = useMemo(
    () =>
      value.partner.map((_v, idx) => {
        const paddedIdx = padIndex(idx);
        return {
          root: getCommentProps(ctx, `/08:partners/${paddedIdx}`),
          cellProvisionsN: (_v.cellProvisions ?? []).map(
            (_cellProvision, cellProvisionIdx) =>
              getCommentProps(
                ctx,
                `/08:partner/${paddedIdx}/cellProvisions/${cellProvisionIdx}`,
              ),
          ),
        };
      }),
    [ctx, value],
  );
  const problemsDuringThePeriod = useMemo(
    () => getCommentProps(ctx, "/09:problemsDuringThePeriod"),
    [ctx],
  );
  const handlingAfterTermination = useMemo(
    () => getCommentProps(ctx, "/10:handlingAfterTermination"),
    [ctx],
  );
  const researchResultOutline = useMemo(
    () => getCommentProps(ctx, "/11:researchResultOutline"),
    [ctx],
  );
  const attachmentFiles = useMemo(
    () => getCommentProps(ctx, "/12:attachmentFiles"),
    [ctx],
  );

  return useMemo(() => {
    return {
      projectName,
      institution,
      principalInvestigator,
      cellProvisionType,
      cellProvisions,
      cellProvisionsN,
      attachmentFiles,
      newApplicationApprovalDate,
      researchResultOutline,
      handlingAfterTermination,
      problemsDuringThePeriod,
      partner,
      partnerN,
      usageEndDate,
    };
  }, [
    projectName,
    institution,
    principalInvestigator,
    cellProvisionType,
    cellProvisions,
    cellProvisionsN,
    attachmentFiles,
    newApplicationApprovalDate,
    researchResultOutline,
    handlingAfterTermination,
    problemsDuringThePeriod,
    partner,
    partnerN,
    usageEndDate,
  ]);
}
function padIndex(idx: number): string {
  return idx.toString(10).padStart(2, "0");
}
