import { Stack, Text } from "@chakra-ui/react";
import { TypographyProps } from "@chakra-ui/styled-system";
import { ComponentStyleProps } from "../../../../lib/styles/props/component-style-props";
import { CMMessageInfo } from "@pscsrvlab/psc-react-components";
import { BsExclamationDiamondFill } from "react-icons/bs";
import { useAppTranslation } from "../../../../hooks/use-app-translation";

export type PasswordPolicyProps = Pick<TypographyProps, "fontSize"> &
  ComponentStyleProps;

export const PasswordPolicy = ({
  fontSize = "inherit",
  ...rest
}: PasswordPolicyProps) => {
  const { t } = useAppTranslation();

  return (
    <>
      <Stack fontSize={fontSize} {...rest}>
        <CMMessageInfo
          label={t("gdc.パスワードポリシー注意")}
          icon={BsExclamationDiamondFill}
          iconColor={"red.500"}
        />
        <Stack pl={"20px"}>
          <Text>{t("gdc.パスワードポリシー1")}</Text>
          <Text>{t("gdc.パスワードポリシー2")}</Text>
          <Text>{t("gdc.パスワードポリシー3")}</Text>
          <Stack pl={"20px"}>
            <Text>{t("gdc.パスワードポリシー4")}</Text>
            <Text>{t("gdc.パスワードポリシー5")}</Text>
            <Text>{t("gdc.パスワードポリシー6")}</Text>
            <Text>{t("gdc.パスワードポリシー7")}</Text>
            <Text>{t("gdc.パスワードポリシー8")}</Text>
            <Text>{t("gdc.パスワードポリシー9")}</Text>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
