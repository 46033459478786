import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";

import { useNavigate } from "react-router-dom";
import useCustomToast from "../../../../../../hooks/use-custom-toast";
import { HStack, useDisclosure, VStack } from "@chakra-ui/react";
import { ValidationError } from "@pscsrvlab/psc-react-components";
import { FrameUpperRightButton } from "../../../../../ui/frame/FrameUpperRightButton/FrameUpperRightButton";
import { EditEndButton } from "../../../../../ui/button/EditEndButton/EditEndButton";
import React, { Ref, useCallback, useState } from "react";
import { useLeaveEditingPrompt } from "../../../../../../hooks/use-leave-editing-prompt";
import { SaveChangesButton } from "../../../../../ui/button/SaveChangesButton/SaveChangesButton";
import { LoginUserInfo } from "../../../../../../store/auth/types";
import { NewApplicationViewModel } from "../../../../../../lib/object/vm/new-application-view-model";
import { NewApplicationDocument } from "../NewApplicationDocument/NewApplicationDocument";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { ConfirmationModal } from "../../../../../ui/modal/ConfirmationModal/ConfirmationModal";
import { sleep } from "../../../../../../lib/util/common-util";

export type DocumentContentNewApplicationEditProps = {
  loginUserInfo: LoginUserInfo;

  /**
   * 画面表示時の書類内容の初期値。
   */
  initialViewModel: NewApplicationViewModel;

  validationErrors: ValidationError[];

  saveDocument: (
    vm: NewApplicationViewModel,
    skipOptionalValidations: boolean,
  ) => Promise<
    | { state: "ok"; documentId: number }
    | { state: "error"; errors: ValidationError[] }
    | { state: "unexpectedError" }
  >;

  /**
   * 戻り先のURLパス。
   */
  backUrlPath: string;

  scrollableRef: Ref<any>;
  scrollOffset: number;
} & ComponentStyleProps;

export const DocumentContentNewApplicationEdit = ({
  loginUserInfo,
  saveDocument,

  initialViewModel,

  validationErrors,

  backUrlPath,

  scrollableRef,
  scrollOffset,

  sx,
  ...rest
}: DocumentContentNewApplicationEditProps) => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const { errorToast } = useCustomToast();

  /**
   * 編集中の書類内容の現在の値。
   */
  const [viewModel, setViewModel] =
    useState<NewApplicationViewModel>(initialViewModel);

  const { navigateWithoutPrompt } = useLeaveEditingPrompt();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleEditEnd = useCallback(() => {
    navigate(backUrlPath);
  }, [navigate, backUrlPath]);
  const handleConfirmSave = useCallback(async () => {
    const result = await saveDocument(viewModel, false);
    switch (result.state) {
      case "ok":
        await sleep(200); // すぐに遷移すると、遷移後に古い値が表示されてしまうことへの対策。
        await navigateWithoutPrompt(backUrlPath);
        break;
      case "error":
        break;
      case "unexpectedError":
        errorToast(t("mes.汎用エラーメッセージ"));
        break;
    }
  }, [
    backUrlPath,
    errorToast,
    navigateWithoutPrompt,
    saveDocument,
    t,
    viewModel,
  ]);

  return (
    <>
      <VStack alignItems={"center"} spacing={0} sx={sx} {...rest}>
        <VStack alignSelf={"stretch"}>
          <FrameUpperRightButton
            ml={"auto"}
            sx={{ position: "sticky", top: 0 }}
          >
            <HStack w={"max-content"}>
              <SaveChangesButton onClick={onOpen} />
              <EditEndButton onClick={handleEditEnd} />
            </HStack>
          </FrameUpperRightButton>
        </VStack>
        <VStack alignSelf={"stretch"} mt={"18px"} pb={"100px"}>
          <NewApplicationDocument
            loginUserInfo={loginUserInfo}
            editMode={"editable"}
            value={viewModel}
            onChange={setViewModel}
            freezeFirstContactPerson={false}
            validationErrors={validationErrors}
            scrollableRef={scrollableRef}
            scrollOffset={scrollOffset}
          />
        </VStack>
      </VStack>
      <ConfirmationModal
        isOpen={isOpen}
        message={t("mes.変更保存確認メッセージ")}
        onSubmit={handleConfirmSave}
        onCancel={onClose}
      />
    </>
  );
};
