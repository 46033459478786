import { Button, Container, Text, VStack } from "@chakra-ui/react";
import { FormEvent, useState } from "react";
import {
  clearAuthState,
  completeNewPassword,
} from "../../../../store/auth/slice";
import { useAppDispatch } from "../../../../hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import log from "loglevel";
import { Form, useNavigate } from "react-router-dom";
import { CMFormInputPassword } from "@pscsrvlab/psc-react-components";
import { PasswordPolicy } from "../../../model/user/PasswordPolicy/PasswordPolicy";
import useCustomToast from "../../../../hooks/use-custom-toast";
import { isValidPassword } from "../../../../lib/util/password-util";
import { useAppTranslation } from "../../../../hooks/use-app-translation";

/**
 * 初回ログイン時パスワード設定ページ
 */
export const FN10S02PasswordSettings = () => {
  const dispatch = useAppDispatch();
  const { t } = useAppTranslation();
  const { errorToast } = useCustomToast();
  const navigate = useNavigate();
  const { signInInfo } = useSelector((state: RootState) => state.auth);

  // 新しいパスワード
  const [newPassword, setNewPassword] = useState("");
  // 新しいパスワード(確認)
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  // ロード状態
  const [isLoading, setIsLoading] = useState(false);

  /**
   * 入力内容変更時のイベント
   * @param _
   * @param _newPassword 新しいパスワード
   * @param _confirmPassword 新しいパスワード(確認)
   */
  const handleChangeValue = (
    _: string, // 使用しない項目
    _newPassword: string,
    _confirmPassword: string,
  ) => {
    setNewPassword(_newPassword);
    setConfirmNewPassword(_confirmPassword);
  };

  /**
   * 新しいパスワードを設定するボタン押下時のイベント
   * @param event イベント情報
   */
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Submit防止
    setIsLoading(true);
    // バリデーションエラーがある場合は中断
    if (!validate()) {
      setIsLoading(false);
      return;
    }
    // パスワード設定処理を実行
    await executeSetPassword();
  };

  /**
   * バリデーションチェック
   * @return true: 非エラー / false: エラー
   */
  const validate = () => {
    // 新しいパスワードが未入力の場合
    if (!newPassword) {
      errorToast(
        t("mes.必須チェックエラー2", { name: t("lbl.新しいパスワード") }),
      );
      return false;
    }
    // 新しいパスワード(確認用)が未入力の場合
    if (!confirmNewPassword) {
      errorToast(
        t("mes.必須チェックエラー2", {
          name: t("lbl.新しいパスワード（確認用）"),
        }),
      );
      return false;
    }
    // パスワードポリシーに沿っていない場合
    if (!isValidPassword(newPassword)) {
      errorToast(t("mes.パスワードポリシーエラーメッセージ"));
      return false;
    }
    // パスワード(確認用)が一致していない場合
    if (newPassword !== confirmNewPassword) {
      errorToast(t("mes.パスワード確認不一致エラー"));
      return false;
    }
    return true;
  };

  /**
   * 初回パスワード設定処理
   */
  const executeSetPassword = async () => {
    try {
      // サインイン情報が存在しない場合(URL直打ちでページ遷移した場合など)はログイン画面へ戻す
      if (!signInInfo?.cognitoUser) {
        log.error("不正なアクセス：ユーザー情報無し");
        errorToast(t("mes.汎用エラーメッセージ"));
        setIsLoading(false);
        navigate("/login");
        return;
      }
      // 非同期処理を実行
      await dispatch(
        completeNewPassword({
          cognitoUser: signInInfo.cognitoUser,
          newPassword: newPassword,
        }),
      ).unwrap();
      // ここまで到達するとログイン成功
      // ページ遷移は GuestRoute 側で行われる為ここでは実装しない
      //
    } catch (e) {
      log.error("パスワード設定処理でエラー発生：", e);

      // ロードを中断
      setIsLoading(false);

      // エラーが発生した場合はログイン画面へ戻す
      errorToast(t("mes.汎用エラーメッセージ"));
      await dispatch(clearAuthState);
      navigate("/login", { replace: true });
      return;
    }
  };

  return (
    <>
      <VStack
        flex={"1 1 auto"}
        minH={0}
        alignSelf={"stretch"}
        overflow={"auto"}
        pb={"40px"}
      >
        <Container pt={"24px"} pb={"24px"} maxW={"600px"}>
          <Form onSubmit={handleSubmit}>
            <VStack align={"center"} spacing={"30px"} mt={"40px"}>
              <Text fontSize={"14px"}>{t("gdc.パスワード初回設定案内")}</Text>
              <CMFormInputPassword
                initialValue={{
                  newPassword: "",
                  verifyPassword: "",
                }}
                labelNewPassword={t("lbl.新しいパスワード")}
                labelVerifyPassword={t("lbl.新しいパスワード（確認用）")}
                labelDisplayChangeButton={{
                  show: t("btn.パスワード表示ボタン"),
                  hide: t("btn.パスワード非表示ボタン"),
                }}
                isDisplayCurrentPassword={false}
                onChange={handleChangeValue}
                sx={{ width: "100%" }}
              />
              <PasswordPolicy fontSize={"12px"} />
              <Button
                isLoading={isLoading}
                disabled={isLoading}
                colorScheme={"teal"}
                type={"submit"}
                pl={"30px"}
                pr={"30px"}
              >
                {t("lbl.新しいパスワードを設定するボタン")}
              </Button>
            </VStack>
          </Form>
        </Container>
      </VStack>
    </>
  );
};
