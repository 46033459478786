import React, { memo } from "react";
import { CMButton } from "@pscsrvlab/psc-react-components";
import { useAppTranslation } from "../../../../hooks/use-app-translation";

export type SaveDraftButtonProps = {
  onClick?: () => void;
};
export const SaveDraftButton = memo(function SaveDraftButton({
  onClick,
}: SaveDraftButtonProps) {
  const { t } = useAppTranslation();
  return (
    <CMButton
      colorScheme={"teal"}
      size={"sm"}
      label={t("btn.下書き保存ボタン")}
      onClick={onClick}
    />
  );
});
