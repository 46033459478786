import {
  messageId,
  PropertyMeta,
  ValueObjectMetaString,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.string().max(10000);
const savedMinimalSchema = z.string().max(10000);
const schema = z.string().min(1).max(10000);
const savedSchema = z.string().min(1).max(10000);
const jsonSchema = z.string();
/**
 * iPS細胞ストック必要理由その他(値オブジェクト)のメタデータ。
 *
 *  */
export const ipsCellStockRequirementReasonOtherMeta = new ValueObjectMetaString(
  "IpsCellStockRequirementReasonOther",
  "iPS\u7D30\u80DE\u30B9\u30C8\u30C3\u30AF\u5FC5\u8981\u7406\u7531\u305D\u306E\u4ED6",
  messageId(
    "lbl.iPS\u7D30\u80DE\u30B9\u30C8\u30C3\u30AF\u5FC5\u8981\u7406\u7531\u305D\u306E\u4ED6"
  ),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
