import {
  messageId,
  PropertyMeta,
  EntityMeta,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { projectIdMeta } from "../value/project-id";
import { projectControlNumberMeta } from "../value/project-control-number";
import { yearMeta } from "../value/year";
import { serialNumberMeta } from "../value/serial-number";
import { projectStateMeta } from "../value/project-state";
import { migrationDataTypeMeta } from "../value/migration-data-type";
import { yearMonthDayMeta } from "../value/year-month-day";
import { terminationReportSubmittedMeta } from "../value/termination-report-submitted";
import { disclosureDocumentsMeta } from "../value/disclosure-documents";
import { applicationContentMeta } from "../value/application-content";
import { creationMetadataMeta } from "../value/creation-metadata";
import { updateMetadataMeta } from "../value/update-metadata";
/**
 * 案件(エンティティ)
 *
 *  */
export type Project = z.infer<typeof schema>;
export type ProjectSaved = z.infer<typeof savedSchema>;
const minimalSchema = z.object({
  id: projectIdMeta.minimalSchema.optional(),
  projectControlNumber: projectControlNumberMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  projectControlNumberYear: yearMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  projectControlNumberSerialNumber: serialNumberMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  projectState: projectStateMeta.minimalSchema,
  migrationDataType: migrationDataTypeMeta.minimalSchema,
  newApplicationSubmissionDate: yearMonthDayMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  newApplicationApprovalDate: yearMonthDayMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  projectCompletionDate: yearMonthDayMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  lastAnnualReportSubmissionDate: yearMonthDayMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  nextAnnualReportDeadline: yearMonthDayMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  terminationReportSubmitted: terminationReportSubmittedMeta.minimalSchema,
  disclosureDocument: disclosureDocumentsMeta.minimalSchema,
  annualReportRemindNotificationDate: yearMonthDayMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  terminationReportRemindNotificationDate: yearMonthDayMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  applicationContent: applicationContentMeta.minimalSchema,
  created: creationMetadataMeta.minimalSchema,
  updated: updateMetadataMeta.minimalSchema,
});
const savedMinimalSchema = z.object({
  id: projectIdMeta.minimalSchema,
  projectControlNumber: projectControlNumberMeta.savedMinimalSchema,
  projectControlNumberYear: yearMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  projectControlNumberSerialNumber: serialNumberMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  projectState: projectStateMeta.savedMinimalSchema,
  migrationDataType: migrationDataTypeMeta.savedMinimalSchema,
  newApplicationSubmissionDate: yearMonthDayMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  newApplicationApprovalDate: yearMonthDayMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  projectCompletionDate: yearMonthDayMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  lastAnnualReportSubmissionDate: yearMonthDayMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  nextAnnualReportDeadline: yearMonthDayMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  terminationReportSubmitted: terminationReportSubmittedMeta.savedMinimalSchema,
  disclosureDocument: disclosureDocumentsMeta.savedMinimalSchema,
  annualReportRemindNotificationDate: yearMonthDayMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  terminationReportRemindNotificationDate: yearMonthDayMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  applicationContent: applicationContentMeta.savedMinimalSchema,
  created: creationMetadataMeta.savedMinimalSchema,
  updated: updateMetadataMeta.savedMinimalSchema,
});
const schema = z.object({
  id: projectIdMeta.minimalSchema.optional(),
  projectControlNumber: projectControlNumberMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
  projectControlNumberYear: yearMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
  projectControlNumberSerialNumber: serialNumberMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
  projectState: projectStateMeta.schema,
  migrationDataType: migrationDataTypeMeta.schema,
  newApplicationSubmissionDate: yearMonthDayMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
  newApplicationApprovalDate: yearMonthDayMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
  projectCompletionDate: yearMonthDayMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
  lastAnnualReportSubmissionDate: yearMonthDayMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
  nextAnnualReportDeadline: yearMonthDayMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
  terminationReportSubmitted: terminationReportSubmittedMeta.schema,
  disclosureDocument: disclosureDocumentsMeta.schema,
  annualReportRemindNotificationDate: yearMonthDayMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
  terminationReportRemindNotificationDate: yearMonthDayMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
  applicationContent: applicationContentMeta.schema,
  created: creationMetadataMeta.schema,
  updated: updateMetadataMeta.schema,
});
const savedSchema = z.object({
  id: projectIdMeta.minimalSchema,
  projectControlNumber: projectControlNumberMeta.savedSchema,
  projectControlNumberYear: yearMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  projectControlNumberSerialNumber: serialNumberMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  projectState: projectStateMeta.savedSchema,
  migrationDataType: migrationDataTypeMeta.savedSchema,
  newApplicationSubmissionDate: yearMonthDayMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  newApplicationApprovalDate: yearMonthDayMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  projectCompletionDate: yearMonthDayMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  lastAnnualReportSubmissionDate: yearMonthDayMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  nextAnnualReportDeadline: yearMonthDayMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  terminationReportSubmitted: terminationReportSubmittedMeta.savedSchema,
  disclosureDocument: disclosureDocumentsMeta.savedSchema,
  annualReportRemindNotificationDate: yearMonthDayMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  terminationReportRemindNotificationDate: yearMonthDayMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  applicationContent: applicationContentMeta.savedSchema,
  created: creationMetadataMeta.savedSchema,
  updated: updateMetadataMeta.savedSchema,
});
const jsonSchema = z.object({
  id: projectIdMeta.jsonSchema.optional(),
  projectControlNumber: projectControlNumberMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  projectControlNumberYear: yearMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  projectControlNumberSerialNumber: serialNumberMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  projectState: projectStateMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  migrationDataType: migrationDataTypeMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  newApplicationSubmissionDate: yearMonthDayMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  newApplicationApprovalDate: yearMonthDayMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  projectCompletionDate: yearMonthDayMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  lastAnnualReportSubmissionDate: yearMonthDayMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  nextAnnualReportDeadline: yearMonthDayMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  terminationReportSubmitted: terminationReportSubmittedMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  disclosureDocument: disclosureDocumentsMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  annualReportRemindNotificationDate: yearMonthDayMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  terminationReportRemindNotificationDate: yearMonthDayMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  applicationContent: applicationContentMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  created: creationMetadataMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  updated: updateMetadataMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
/**
 * 案件(エンティティ)のメタデータ。
 *
 *  */
export const projectMeta = new EntityMeta<
  typeof schema,
  typeof savedSchema,
  typeof jsonSchema
>(
  "Project",
  "\u6848\u4EF6",
  messageId("lbl.\u6848\u4EF6"),
  minimalSchema,
  savedMinimalSchema,
  schema,
  savedSchema,
  jsonSchema,
  undefined,
  undefined,
  {
    id: new PropertyMeta(
      "id",
      "ID",
      messageId("lbl.ID"),
      "afterSave",
      false,
      projectIdMeta
    ),
    projectControlNumber: new PropertyMeta(
      "projectControlNumber",
      "\u6848\u4EF6\u7BA1\u7406\u756A\u53F7",
      messageId("lbl.\u6848\u4EF6\u7BA1\u7406\u756A\u53F7"),
      "afterSave",
      false,
      projectControlNumberMeta
    ),
    projectControlNumberYear: new PropertyMeta(
      "projectControlNumberYear",
      "\u6848\u4EF6\u7BA1\u7406\u756A\u53F7\u5E74",
      messageId("lbl.\u6848\u4EF6\u7BA1\u7406\u756A\u53F7\u5E74"),
      "none",
      false,
      yearMeta
    ),
    projectControlNumberSerialNumber: new PropertyMeta(
      "projectControlNumberSerialNumber",
      "\u6848\u4EF6\u7BA1\u7406\u756A\u53F7\u9023\u756A",
      messageId("lbl.\u6848\u4EF6\u7BA1\u7406\u756A\u53F7\u9023\u756A"),
      "none",
      false,
      serialNumberMeta
    ),
    projectState: new PropertyMeta(
      "projectState",
      "\u6848\u4EF6\u72B6\u614B",
      messageId("lbl.\u6848\u4EF6\u72B6\u614B"),
      "always",
      false,
      projectStateMeta
    ),
    migrationDataType: new PropertyMeta(
      "migrationDataType",
      "\u79FB\u884C\u30C7\u30FC\u30BF\u533A\u5206",
      messageId("lbl.\u79FB\u884C\u30C7\u30FC\u30BF\u533A\u5206"),
      "always",
      false,
      migrationDataTypeMeta
    ),
    newApplicationSubmissionDate: new PropertyMeta(
      "newApplicationSubmissionDate",
      "\u65B0\u898F\u7533\u8ACB\u63D0\u51FA\u65E5",
      messageId("lbl.\u65B0\u898F\u7533\u8ACB\u63D0\u51FA\u65E5"),
      "none",
      false,
      yearMonthDayMeta
    ),
    newApplicationApprovalDate: new PropertyMeta(
      "newApplicationApprovalDate",
      "\u65B0\u898F\u7533\u8ACB\u627F\u8A8D\u65E5",
      messageId("lbl.\u65B0\u898F\u7533\u8ACB\u627F\u8A8D\u65E5"),
      "none",
      false,
      yearMonthDayMeta
    ),
    projectCompletionDate: new PropertyMeta(
      "projectCompletionDate",
      "\u6848\u4EF6\u5B8C\u4E86\u65E5",
      messageId("lbl.\u6848\u4EF6\u5B8C\u4E86\u65E5"),
      "none",
      false,
      yearMonthDayMeta
    ),
    lastAnnualReportSubmissionDate: new PropertyMeta(
      "lastAnnualReportSubmissionDate",
      "\u524D\u56DE\u5E74\u6B21\u5831\u544A\u63D0\u51FA\u65E5",
      messageId("lbl.\u524D\u56DE\u5E74\u6B21\u5831\u544A\u63D0\u51FA\u65E5"),
      "none",
      false,
      yearMonthDayMeta
    ),
    nextAnnualReportDeadline: new PropertyMeta(
      "nextAnnualReportDeadline",
      "\u6B21\u56DE\u5E74\u6B21\u5831\u544A\u671F\u9650\u65E5",
      messageId("lbl.\u6B21\u56DE\u5E74\u6B21\u5831\u544A\u671F\u9650\u65E5"),
      "none",
      false,
      yearMonthDayMeta
    ),
    terminationReportSubmitted: new PropertyMeta(
      "terminationReportSubmitted",
      "\u4F7F\u7528\u7D42\u4E86\u63D0\u51FA\u6E08",
      messageId("lbl.\u4F7F\u7528\u7D42\u4E86\u63D0\u51FA\u6E08"),
      "always",
      false,
      terminationReportSubmittedMeta
    ),
    disclosureDocument: new PropertyMeta(
      "disclosureDocument",
      "\u60C5\u5831\u516C\u958B\u6587\u66F8",
      messageId("lbl.\u60C5\u5831\u516C\u958B\u6587\u66F8"),
      "always",
      false,
      disclosureDocumentsMeta
    ),
    annualReportRemindNotificationDate: new PropertyMeta(
      "annualReportRemindNotificationDate",
      "\u5E74\u6B21\u5831\u544A\u30EA\u30DE\u30A4\u30F3\u30C9\u901A\u77E5\u65E5",
      messageId(
        "lbl.\u5E74\u6B21\u5831\u544A\u30EA\u30DE\u30A4\u30F3\u30C9\u901A\u77E5\u65E5"
      ),
      "none",
      false,
      yearMonthDayMeta
    ),
    terminationReportRemindNotificationDate: new PropertyMeta(
      "terminationReportRemindNotificationDate",
      "\u4F7F\u7528\u7D42\u4E86\u5831\u544A\u30EA\u30DE\u30A4\u30F3\u30C9\u901A\u77E5\u65E5",
      messageId(
        "lbl.\u4F7F\u7528\u7D42\u4E86\u5831\u544A\u30EA\u30DE\u30A4\u30F3\u30C9\u901A\u77E5\u65E5"
      ),
      "none",
      false,
      yearMonthDayMeta
    ),
    applicationContent: new PropertyMeta(
      "applicationContent",
      "\u7533\u8ACB\u5185\u5BB9",
      messageId("lbl.\u7533\u8ACB\u5185\u5BB9"),
      "always",
      false,
      applicationContentMeta
    ),
    created: new PropertyMeta(
      "created",
      "\u4F5C\u6210",
      messageId("lbl.\u4F5C\u6210"),
      "always",
      false,
      creationMetadataMeta
    ),
    updated: new PropertyMeta(
      "updated",
      "\u66F4\u65B0",
      messageId("lbl.\u66F4\u65B0"),
      "always",
      false,
      updateMetadataMeta
    ),
  }
);
