import { ReactNode } from "react";
import { Text, VStack } from "@chakra-ui/react";
import { ComponentStyleProps } from "../../../../lib/styles/props/component-style-props";

export type FrameLeftSideMenuProps = {
  title: string;
  children?: ReactNode;
} & ComponentStyleProps;

/**
 * 修正依頼一覧等を表示する左サイドメニューのレイアウトを統一するためのコンポーネント
 * @param title
 * @param children
 * @param sx
 * @param rest
 * @constructor
 */
export const FrameLeftSideMenu = ({
  title,
  children,
  sx,
  ...rest
}: FrameLeftSideMenuProps) => {
  const capitalizedTitle = title;
  return (
    <VStack
      flex={"0 0 236px"}
      minH={0}
      p={"10px"}
      bgColor={"gray.100"}
      border={"1px"}
      borderColor={"gray.200"}
      overflowY={"auto"}
      alignItems={"stretch"}
      spacing={0}
      sx={sx}
      {...rest}
    >
      <Text>{capitalizedTitle}</Text>
      <VStack alignItems={"stretch"} spacing={0}>
        {children}
      </VStack>
    </VStack>
  );
};
