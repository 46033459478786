import { Ref, useCallback } from "react";
import { hasValue } from "../lib/util/common-util";
import log from "loglevel";

// 参考: https://stackoverflow.com/a/11041376/12579447
export function useScrollToId(
  scrollableRef: Ref<any> | null | undefined,
  offset = 0,
) {
  const scrollToId = useCallback(
    (id: string) => {
      setTimeout(() => {
        const element = document.getElementById(id);
        if (
          hasValue(element) &&
          hasValue(scrollableRef) &&
          "current" in scrollableRef &&
          "scrollTop" in scrollableRef.current &&
          "offsetTop" in scrollableRef.current &&
          typeof scrollableRef.current.offsetTop === "number"
        ) {
          log.debug(`scrollToId: element.offsetTop=${element.offsetTop}`);
          log.debug(
            `scrollToId: scrollableRef.current.offsetTop=${scrollableRef.current.offsetTop}`,
          );
          scrollableRef.current.scrollTop =
            element.offsetTop - scrollableRef.current.offsetTop - offset;
        }
      }, 0);
    },
    [scrollableRef, offset],
  );
  return { scrollToId };
}
