import {
  messageId,
  PropertyMeta,
  ValueObjectMetaComposite,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { appUserIdMeta } from "../value/app-user-id";
import { datetimeMeta } from "../value/datetime";
/**
 * 更新メタデータ(値オブジェクト)
 *
 *  */
export type UpdateMetadata = z.infer<typeof schema>;
export type UpdateMetadataSaved = z.infer<typeof savedSchema>;
const minimalSchema = z.object({
  appUserId: appUserIdMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  datetime: datetimeMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
const savedMinimalSchema = z.object({
  appUserId: appUserIdMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  datetime: datetimeMeta.savedMinimalSchema,
});
const schema = z.object({
  appUserId: appUserIdMeta.schema.nullish().transform((x) => x ?? undefined),
  datetime: datetimeMeta.schema.nullish().transform((x) => x ?? undefined),
});
const savedSchema = z.object({
  appUserId: appUserIdMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  datetime: datetimeMeta.savedSchema,
});
const jsonSchema = z.object({
  appUserId: appUserIdMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  datetime: datetimeMeta.jsonSchema.nullish().transform((x) => x ?? undefined),
});
/**
 * 更新メタデータ(値オブジェクト)のメタデータ。
 *
 *  */
export const updateMetadataMeta = new ValueObjectMetaComposite(
  "UpdateMetadata",
  "\u66F4\u65B0\u30E1\u30BF\u30C7\u30FC\u30BF",
  messageId("lbl.\u66F4\u65B0\u30E1\u30BF\u30C7\u30FC\u30BF"),
  minimalSchema,
  savedMinimalSchema,
  schema,
  savedSchema,
  jsonSchema,
  undefined,
  undefined,
  {
    appUserId: new PropertyMeta(
      "appUserId",
      "\u30E6\u30FC\u30B6\u30FCID",
      messageId("lbl.\u30E6\u30FC\u30B6\u30FCID"),
      "none",
      false,
      appUserIdMeta
    ),
    datetime: new PropertyMeta(
      "datetime",
      "\u65E5\u6642",
      messageId("lbl.\u65E5\u6642"),
      "afterSave",
      false,
      datetimeMeta
    ),
  }
);
