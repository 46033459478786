import {
  messageId,
  PropertyMeta,
  ValueObjectMetaComposite,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { institutionIdMeta } from "../value/institution-id";
import { displayInstitutionIdMeta } from "../value/display-institution-id";
import { institutionNameMeta } from "../value/institution-name";
import { institutionNameKanaMeta } from "../value/institution-name-kana";
import { foreignTypeMeta } from "../value/foreign-type";
import { commercialTypeMeta } from "../value/commercial-type";
/**
 * 機関情報(値オブジェクト)
 *
 *  */
export type InstitutionInformation = z.infer<typeof schema>;
export type InstitutionInformationSaved = z.infer<typeof savedSchema>;
const minimalSchema = z.object({
  institutionId: institutionIdMeta.minimalSchema,
  displayInstitutionId: displayInstitutionIdMeta.minimalSchema,
  institutionName: institutionNameMeta.minimalSchema,
  institutionNameKana: institutionNameKanaMeta.minimalSchema,
  foreignType: foreignTypeMeta.minimalSchema,
  commercialType: commercialTypeMeta.minimalSchema,
});
const savedMinimalSchema = z.object({
  institutionId: institutionIdMeta.savedMinimalSchema,
  displayInstitutionId: displayInstitutionIdMeta.savedMinimalSchema,
  institutionName: institutionNameMeta.savedMinimalSchema,
  institutionNameKana: institutionNameKanaMeta.savedMinimalSchema,
  foreignType: foreignTypeMeta.savedMinimalSchema,
  commercialType: commercialTypeMeta.savedMinimalSchema,
});
const schema = z.object({
  institutionId: institutionIdMeta.schema,
  displayInstitutionId: displayInstitutionIdMeta.schema,
  institutionName: institutionNameMeta.schema,
  institutionNameKana: institutionNameKanaMeta.schema,
  foreignType: foreignTypeMeta.schema,
  commercialType: commercialTypeMeta.schema,
});
const savedSchema = z.object({
  institutionId: institutionIdMeta.savedSchema,
  displayInstitutionId: displayInstitutionIdMeta.savedSchema,
  institutionName: institutionNameMeta.savedSchema,
  institutionNameKana: institutionNameKanaMeta.savedSchema,
  foreignType: foreignTypeMeta.savedSchema,
  commercialType: commercialTypeMeta.savedSchema,
});
const jsonSchema = z.object({
  institutionId: institutionIdMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  displayInstitutionId: displayInstitutionIdMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  institutionName: institutionNameMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  institutionNameKana: institutionNameKanaMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  foreignType: foreignTypeMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  commercialType: commercialTypeMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
/**
 * 機関情報(値オブジェクト)のメタデータ。
 *
 *  */
export const institutionInformationMeta = new ValueObjectMetaComposite(
  "InstitutionInformation",
  "\u6A5F\u95A2\u60C5\u5831",
  messageId("lbl.\u6A5F\u95A2\u60C5\u5831"),
  minimalSchema,
  savedMinimalSchema,
  schema,
  savedSchema,
  jsonSchema,
  undefined,
  undefined,
  {
    institutionId: new PropertyMeta(
      "institutionId",
      "\u6A5F\u95A2ID",
      messageId("lbl.\u6A5F\u95A2ID"),
      "always",
      false,
      institutionIdMeta
    ),
    displayInstitutionId: new PropertyMeta(
      "displayInstitutionId",
      "\u8868\u793A\u7528\u6A5F\u95A2ID",
      messageId("lbl.\u8868\u793A\u7528\u6A5F\u95A2ID"),
      "always",
      false,
      displayInstitutionIdMeta
    ),
    institutionName: new PropertyMeta(
      "institutionName",
      "\u6A5F\u95A2\u540D",
      messageId("lbl.\u6A5F\u95A2\u540D"),
      "always",
      false,
      institutionNameMeta
    ),
    institutionNameKana: new PropertyMeta(
      "institutionNameKana",
      "\u6A5F\u95A2\u540D\u304B\u306A",
      messageId("lbl.\u6A5F\u95A2\u540D\u304B\u306A"),
      "always",
      false,
      institutionNameKanaMeta
    ),
    foreignType: new PropertyMeta(
      "foreignType",
      "\u56FD\u5185\u5916\u533A\u5206",
      messageId("lbl.\u56FD\u5185\u5916\u533A\u5206"),
      "always",
      false,
      foreignTypeMeta
    ),
    commercialType: new PropertyMeta(
      "commercialType",
      "\u55B6\u5229\u975E\u55B6\u5229\u533A\u5206",
      messageId("lbl.\u55B6\u5229\u975E\u55B6\u5229\u533A\u5206"),
      "always",
      false,
      commercialTypeMeta
    ),
  }
);
