import {
  messageId,
  PropertyMeta,
  ValueObjectMetaString,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.string().max(100);
const savedMinimalSchema = z.string().max(100);
const schema = z.string().min(1).max(100);
const savedSchema = z.string().min(1).max(100);
const jsonSchema = z.string();
/**
 * 質疑応答件名(値オブジェクト)のメタデータ。
 *
 *  */
export const inquirySubjectMeta = new ValueObjectMetaString(
  "InquirySubject",
  "\u8CEA\u7591\u5FDC\u7B54\u4EF6\u540D",
  messageId("lbl.\u8CEA\u7591\u5FDC\u7B54\u4EF6\u540D"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
