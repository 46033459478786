import { ComponentStyleProps } from "../../../../lib/styles/props/component-style-props";
import React, { useCallback, useMemo } from "react";
import {
  CMFormInputBaseText,
  CMFormInputSearchAndSelect,
} from "@pscsrvlab/psc-react-components";
import log from "loglevel";
import { hasValue } from "../../../../lib/util/common-util";
import { useAppListInstitutionQuery } from "../../../../hooks/query/use-app-list-institution-query";
import { stockRequestApi } from "../../../../store/api/enhanced-api";
import { errorMessageOf } from "../../../../lib/util/error-util";
import useCustomToast from "../../../../hooks/use-custom-toast";
import { SearchDropdownDisplayValue } from "@pscsrvlab/psc-react-components/src/lib";
import { useAppTranslation } from "../../../../hooks/use-app-translation";

export type InstitutionSearchSelectProps = {
  institutionId?: number;
  onSelect?: (institutionId?: number) => void;
  editMode?: "editable" | "readOnly";
} & ComponentStyleProps;

export const InstitutionSearchSelect = ({
  institutionId,
  onSelect,
  editMode = "editable",
  sx,
  ...rest
}: InstitutionSearchSelectProps) => {
  const { t } = useAppTranslation();
  const { errorToast } = useCustomToast();

  // 機関情報一覧を取得
  const { data: allInstitutions } = useAppListInstitutionQuery({});
  const [triggerListInstitutionQuery] =
    stockRequestApi.useLazyListInstitutionQuery();

  const [triggerGetInstitutionQuery] =
    stockRequestApi.useLazyGetInstitutionQuery();

  const toDisplayValue: (
    value: number,
  ) => Promise<SearchDropdownDisplayValue> = async (value) => {
    try {
      const institution = await triggerGetInstitutionQuery({
        institutionId: value,
      }).unwrap();
      return {
        headingLabel: institution.displayInstitutionId,
        mainLabel: institution.name ?? "",
      };
    } catch (e) {
      log.error(errorMessageOf(e));
      return { headingLabel: undefined, mainLabel: "" };
    }
  };

  const selectedInstitutionName = useMemo(() => {
    return (
      allInstitutions?.find((institution) => institution.id === institutionId)
        ?.name ?? ""
    );
  }, [allInstitutions, institutionId]);

  // 検索時のイベントハンドラ
  const handleSearch: (text: string) => Promise<number[]> = useCallback(
    async (text: string) => {
      try {
        const selectableInstitutions: number[] = (
          await triggerListInstitutionQuery({
            institutionName: text,
          }).unwrap()
        )
          .map((v) => v?.id)
          .filter(hasValue);
        return selectableInstitutions;
      } catch (e) {
        log.error(errorMessageOf(e));
        errorToast(t("mes.汎用エラーメッセージ"));
        return [];
      }
    },
    [errorToast, t, triggerListInstitutionQuery],
  );

  // 確定ボタン押下時のイベントハンドラ
  const handleChange = (value: number | null) => {
    onSelect?.(value ?? undefined);
  };

  return (
    <>
      {editMode === "editable" && (
        <CMFormInputSearchAndSelect<number>
          label={t("mes.機関選択案内メッセージ")}
          popoverTitle={t("mes.機関選択案内メッセージ")}
          searchResultNone={t("mes.該当機関なしメッセージ")}
          labelSubmit={t("btn.確定ボタン")}
          labelClear={t("btn.クリアボタン")}
          toDisplayValue={toDisplayValue}
          menuShowMaxCount={30}
          value={institutionId}
          onSearch={handleSearch}
          onChange={handleChange}
          usePortal={false}
          w={"100%"}
          sx={sx}
          {...rest}
        />
      )}
      {editMode === "readOnly" && (
        <CMFormInputBaseText
          editMode={"readOnly"}
          value={selectedInstitutionName}
          sx={sx}
          {...rest}
        />
      )}
    </>
  );
};
