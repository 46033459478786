import {
  messageId,
  PropertyMeta,
  ValueObjectMetaNumber,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.number().min(0);
const savedMinimalSchema = z.number().min(0);
const schema = z.number().min(0);
const savedSchema = z.number().min(0);
const jsonSchema = z.number();
/**
 * 案件ID(値オブジェクト)のメタデータ。
 *
 * 案件の主キー。
 * システムが自動採番する。 */
export const projectIdMeta = new ValueObjectMetaNumber(
  "ProjectId",
  "\u6848\u4EF6ID",
  messageId("lbl.\u6848\u4EF6ID"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
