import { Result } from "../../lib/object/value/result";
import { useLatestReviewIfHeld } from "./use-latest-review-if-held";
import { useMemo } from "react";
import { isNullish } from "../../lib/util/common-util";
import { useAppGetDocumentQuery } from "../query/use-app-get-document-query";

/**
 * 指定した書類において、現在選択可能な結果の一覧を返却する。
 * ロードできるまでは空配列を返す。
 */
export function useSelectableResults(documentId: number): {
  selectableResults: Result[];
} {
  const { data: document } = useAppGetDocumentQuery({ documentId });
  const { latestReview } = useLatestReviewIfHeld(documentId);

  const selectableResults: Result[] = useMemo(() => {
    if (isNullish(document)) return [];

    const documentType = document.documentType;
    const documentState = document.documentState;

    if (
      documentType === "new_application" ||
      documentType === "change_application"
    ) {
      if (documentState === "awaiting_conclusion_no_review") {
        return ["rejected", "no_need_to_review"];
      }
      if (documentState === "awaiting_conditions_met") {
        return ["approved"];
      }
      if (isNullish(latestReview) || isNullish(latestReview.reviewResult))
        return [];
      return [latestReview.reviewResult];
    }

    if (
      documentType === "annual_report" ||
      documentType === "termination_report"
    ) {
      return ["accepted", "rejected"];
    }

    // unreachable
    return [];
  }, [document, latestReview]);

  return { selectableResults };
}
