import {
  messageId,
  PropertyMeta,
  ValueObjectMetaComposite,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { reviewTypeMeta } from "../value/review-type";
import { clinicalStocksInventoryCheckStateMeta } from "../value/clinical-stocks-inventory-check-state";
import { freeTextMeta } from "../value/free-text";
import { attachmentFileInformationMeta } from "../value/attachment-file-information";
/**
 * 事務局確認内容(値オブジェクト)
 *
 *  */
export type OfficeMemberCheckContent = z.infer<typeof schema>;
export type OfficeMemberCheckContentSaved = z.infer<typeof savedSchema>;
const minimalSchema = z.object({
  reviewType: reviewTypeMeta.minimalSchema,
  clinicalStocksInventoryCheckState:
    clinicalStocksInventoryCheckStateMeta.minimalSchema,
  note: freeTextMeta.minimalSchema,
  officeChecklist: z.array(attachmentFileInformationMeta.minimalSchema),
});
const savedMinimalSchema = z.object({
  reviewType: reviewTypeMeta.savedMinimalSchema,
  clinicalStocksInventoryCheckState:
    clinicalStocksInventoryCheckStateMeta.savedMinimalSchema,
  note: freeTextMeta.savedMinimalSchema,
  officeChecklist: z.array(attachmentFileInformationMeta.savedMinimalSchema),
});
const schema = z.object({
  reviewType: reviewTypeMeta.schema,
  clinicalStocksInventoryCheckState:
    clinicalStocksInventoryCheckStateMeta.schema,
  note: freeTextMeta.schema,
  officeChecklist: z.array(attachmentFileInformationMeta.schema),
});
const savedSchema = z.object({
  reviewType: reviewTypeMeta.savedSchema,
  clinicalStocksInventoryCheckState:
    clinicalStocksInventoryCheckStateMeta.savedSchema,
  note: freeTextMeta.savedSchema,
  officeChecklist: z.array(attachmentFileInformationMeta.savedSchema),
});
const jsonSchema = z.object({
  reviewType: reviewTypeMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  clinicalStocksInventoryCheckState:
    clinicalStocksInventoryCheckStateMeta.jsonSchema
      .nullish()
      .transform((x) => x ?? undefined),
  note: freeTextMeta.jsonSchema.nullish().transform((x) => x ?? undefined),
  officeChecklist: z
    .array(attachmentFileInformationMeta.jsonSchema)
    .nullish()
    .transform((x) => x ?? undefined),
});
/**
 * 事務局確認内容(値オブジェクト)のメタデータ。
 *
 *  */
export const officeMemberCheckContentMeta = new ValueObjectMetaComposite(
  "OfficeMemberCheckContent",
  "\u4E8B\u52D9\u5C40\u78BA\u8A8D\u5185\u5BB9",
  messageId("lbl.\u4E8B\u52D9\u5C40\u78BA\u8A8D\u5185\u5BB9"),
  minimalSchema,
  savedMinimalSchema,
  schema,
  savedSchema,
  jsonSchema,
  undefined,
  undefined,
  {
    reviewType: new PropertyMeta(
      "reviewType",
      "\u5BE9\u67FB\u7A2E\u5225",
      messageId("lbl.\u5BE9\u67FB\u7A2E\u5225"),
      "always",
      false,
      reviewTypeMeta
    ),
    clinicalStocksInventoryCheckState: new PropertyMeta(
      "clinicalStocksInventoryCheckState",
      "\u81E8\u5E8A\u7528\u682A\u306E\u5728\u5EAB\u78BA\u8A8D\u72B6\u614B",
      messageId(
        "lbl.\u81E8\u5E8A\u7528\u682A\u306E\u5728\u5EAB\u78BA\u8A8D\u72B6\u614B"
      ),
      "always",
      false,
      clinicalStocksInventoryCheckStateMeta
    ),
    note: new PropertyMeta(
      "note",
      "\u5099\u8003",
      messageId("lbl.\u5099\u8003"),
      "always",
      false,
      freeTextMeta
    ),
    officeChecklist: new PropertyMeta(
      "officeChecklist",
      "\u7533\u8ACB\u7528\u4E8B\u52D9\u5C40\u30C1\u30A7\u30C3\u30AF\u30EA\u30B9\u30C8",
      messageId(
        "lbl.\u7533\u8ACB\u7528\u4E8B\u52D9\u5C40\u30C1\u30A7\u30C3\u30AF\u30EA\u30B9\u30C8"
      ),
      "always",
      true,
      attachmentFileInformationMeta
    ),
  }
);
