import { SearchInstitutionApiArg } from "../../../../../../store/api/generated/stock-request-api";
import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";
import { SearchConditionSidebar } from "../../../../../ui/search/SearchConditionSidebar/SearchConditionSidebar";
import { SearchConditionSidebarSection } from "../../../../../ui/search/SearchConditionSidebar/_components/SearchConditionSidebarSection/SearchConditionSidebarSection";
import { ButtonSearchConditionRadio } from "../../../../../model/search/button/ButtonSearchConditionRadio/ButtonSearchConditionRadio";
import { ButtonSearchConditionText } from "../../../../../model/search/button/ButtonSearchConditionText/ButtonSearchConditionText";
import { institutionSortConditionMeta } from "../../../../../../lib/object/value/institution-sort-condition";
import {
  convertToNumberFromResultsB,
  convertToResultsBFromNumber,
} from "../../../../../../lib/util/search-util";
import { numberOfSearchResultsBMeta } from "../../../../../../lib/object/value/number-of-search-results-b";
import { ButtonSearchConditionCheckbox } from "../../../../../model/search/button/ButtonSearchConditionCheckbox/ButtonSearchConditionCheckbox";
import { foreignTypeMeta } from "../../../../../../lib/object/value/foreign-type";
import { commercialTypeMeta } from "../../../../../../lib/object/value/commercial-type";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";

export type SearchConditionSidebarInstitutionProps = {
  searchArgs: SearchInstitutionApiArg;

  /**
   * 検索条件が変更されたタイミングで呼び出し元に値を返却する
   */
  onChange: (searchArgs: SearchInstitutionApiArg) => void;
} & ComponentStyleProps;

export const SearchConditionSidebarInstitution = ({
  searchArgs,

  onChange,

  sx,
  ...rest
}: SearchConditionSidebarInstitutionProps) => {
  const { t } = useAppTranslation();

  return (
    <SearchConditionSidebar sx={sx} {...rest}>
      <SearchConditionSidebarSection>
        <ButtonSearchConditionRadio
          value={searchArgs.sortCondition}
          label={t("lbl.並び順")}
          valueObjectMeta={institutionSortConditionMeta}
          onChange={(value) => {
            onChange({
              ...searchArgs,
              sortCondition: value,
            });
          }}
        />
        <ButtonSearchConditionRadio
          label={t("lbl.表示件数")}
          value={convertToResultsBFromNumber(searchArgs.limit)}
          valueObjectMeta={numberOfSearchResultsBMeta}
          onChange={(value: string | undefined) => {
            onChange({
              ...searchArgs,
              limit: convertToNumberFromResultsB(value),
            });
          }}
        />
        <ButtonSearchConditionText
          value={searchArgs.displayInstitutionId}
          label={t("lbl.機関ID")}
          onChange={(value: string | undefined) => {
            onChange({
              ...searchArgs,
              displayInstitutionId: value,
            });
          }}
        />
        <ButtonSearchConditionText
          value={searchArgs.institutionName}
          label={t("lbl.機関名")}
          onChange={(value: string | undefined) => {
            onChange({
              ...searchArgs,
              institutionName: value,
            });
          }}
        />
        <ButtonSearchConditionCheckbox
          values={searchArgs.foreignType}
          label={t("lbl.国内外区分")}
          valueObjectMeta={foreignTypeMeta}
          onChange={(value) => {
            onChange({
              ...searchArgs,
              foreignType: value,
            });
          }}
        />
        <ButtonSearchConditionCheckbox
          values={searchArgs.commercialType}
          label={t("lbl.営利非営利区分")}
          valueObjectMeta={commercialTypeMeta}
          onChange={(value) => {
            onChange({
              ...searchArgs,
              commercialType: value,
            });
          }}
        />
      </SearchConditionSidebarSection>
    </SearchConditionSidebar>
  );
};
