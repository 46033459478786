import { ComponentStyleProps } from "../../../../../../../../../lib/styles/props/component-style-props";
import {
  CMFormFileUpload,
  CMFormInputTextArea,
  Comments,
  DeleteConfirmationModal,
} from "@pscsrvlab/psc-react-components";
import { useDisclosure, VStack } from "@chakra-ui/react";
import { UploadButtonEmpty } from "../../../../../../../../ui/button/UploadButtonEmpty/UploadButtonEmpty";
import { isNullish } from "../../../../../../../../../lib/util/common-util";
import { memo, useCallback, useMemo, useState } from "react";
import useFileUpload from "../../../../../../../../../hooks/use-file-upload";
import { storageFileIdMeta } from "../../../../../../../../../lib/object/value/storage-file-id";
import { useCountPastProjectQuery } from "../../../../../../../../../store/api/generated/stock-request-api";
import { AttachmentFileInformation } from "../../../../../../../../../lib/object/value/attachment-file-information";
import log from "loglevel";
import { CMButtonFormCommentProps } from "@pscsrvlab/psc-react-components/src/components/form/comment";
import { attachmentFileToFileUploadItemWithChangeMode } from "../../../../../../../../../lib/util/app-util";
import { useChangeBadgeProperties } from "../../../../../../../../../hooks/document/change-application/use-change-badge-properties";
import { isEqual } from "lodash";
import { researchPlanProjectNameMeta } from "../../../../../../../../../lib/object/value/research-plan-project-name";
import { useKeyedFileData } from "../../../../../../../../../hooks/use-keyed-file-data";
import { AttachmentFilesSectionViewModel } from "../../../../../../../../../lib/object/vm/attachment-files-section-view-model";
import { useAppTranslation } from "../../../../../../../../../hooks/use-app-translation";
import useLinkCommentPath from "../../../../../../../../../hooks/use-link-comment-path";
import { useFileModalContext } from "../../../../../../../../ui/modal/FileModal/FileModal";

// [注意] propsを追加・変更するときは、memoの第二引数の比較関数が適切に動いているかを確認すること。
export type AttachmentFilesContentProps = {
  sectionType: "attachmentFiles" | "partners";

  documentType:
  | "new_application"
  | "change_application"
  | "project_force_update"
  | "project_force_create"
  | "project_content";
  editMode?: "editable" | "disabled" | "readOnly";

  /**
   * ファイルアップロード時の権限制御情報付与に使用する、機関ID。
   * 機関IDと案件IDの両方が未設定の場合は、案件強制作成でしかあり得ないため、ひとまず財団プライベートに設定する。
   * ※どのように設定した場合も、書類/案件の新規保存時に、これらファイルはすべて申請者パブリックかつ機関IDと案件IDが設定された状態となる。
   */
  institutionId: number | null;
  /**
   * ファイルアップロード時の権限制御情報付与に使用する、案件ID。
   * 機関IDと案件IDの両方が未設定の場合は、案件強制作成でしかあり得ないため、ひとまず財団プライベートに設定する。
   * ※どのように設定した場合も、書類/案件の新規保存時に、これらファイルはすべて申請者パブリックかつ機関IDと案件IDが設定された状態となる。
   */
  projectId: number | null;

  /**
   * 使用実績の有無をチェックし、添付資料の表示・非表示や必須マークを切り替えるための、研究責任者ID。
   * 編集中かつ代表機関の添付資料の場合、現在選択中の研究責任者IDを必ず渡すこと。
   * 分担機関の添付資料においては、無関係であるため、この値の設定は不要。
   * TODO 要テスト: 研究責任者を変更した場合の挙動が正しいか？
   * TODO 読み取り専用時はチェック不要（チェックするようにすると、使用実績が変わったタイミングで書類内容が見た目上変わってしまい得る）。読み取り専用時には一貫性のある表示としたいが、どのような表示とするかは決めること。
   */
  principalInvestigatorAppUserId?: number;
  /**
   * iPS細胞の使用目的
   */
  purposeType?: "clinical" | "research";

  valueResearchPlanProjectName?: string;
  valueAttachmentFiles: AttachmentFileInformation[];
  onChange?: (
    change: (
      before: AttachmentFilesSectionViewModel,
    ) => AttachmentFilesSectionViewModel,
  ) => void;

  changedFromResearchPlanProjectName?: string;
  changedFromAttachmentFiles?: AttachmentFileInformation[];

  /**
   * コメントボタンのprops。
   * これが存在すれば、コメントボタンを表示する。
   */
  commentButtonProps?: {
    research_proposal: CMButtonFormCommentProps | undefined;
    certificate_of_ethics_committee_approval:
    | CMButtonFormCommentProps
    | undefined;
    management_system: CMButtonFormCommentProps | undefined;
    principal_investigator_resume: CMButtonFormCommentProps | undefined;
    organization_overview: CMButtonFormCommentProps | undefined;
    research_achievement: CMButtonFormCommentProps | undefined;
    facility_gmp_compliance: CMButtonFormCommentProps | undefined;
    other: CMButtonFormCommentProps | undefined;
  };

  /**
   * 1セクション単位の修正依頼コメント・返信。履歴参照画面で使用。
   */
  commentsList?: Comments[];

} & ComponentStyleProps;


/**
 * 添付資料(代表機関用)セクション
 */
export const AttachmentFilesContent = memo(
  function AttachmentFilesContent({
    sectionType,

    documentType,
    editMode,

    institutionId,
    projectId,

    principalInvestigatorAppUserId,
    purposeType,

    valueResearchPlanProjectName,
    valueAttachmentFiles,
    onChange,

    changedFromResearchPlanProjectName,
    changedFromAttachmentFiles,

    commentButtonProps,

    commentsList,

    sx,
    ...rest
  }: AttachmentFilesContentProps) {
    const { t } = useAppTranslation();

    const { allValuesIncludingDeleted } = useKeyedFileData(
      valueAttachmentFiles,
      changedFromAttachmentFiles,
    );

    const { openFileModal } = useFileModalContext()
    const { fileUpload } = useFileUpload();

    /**
     * 研究責任者の過去案件数
     */
    const { data: countPastProjectData } = useCountPastProjectQuery(
      {
        appUserId: principalInvestigatorAppUserId ?? -1,
      },
      { skip: isNullish(principalInvestigatorAppUserId) },
    );
    /**
     * 研究責任者の使用実績
     */
    const hasUsageRecord: boolean | null = useMemo(() => {
      if (
        isNullish(countPastProjectData) ||
        isNullish(countPastProjectData.pastProjectCount)
      )
        return null;
      return countPastProjectData.pastProjectCount > 0;
    }, [countPastProjectData]);

    /**
     * ファイルアップロード時に呼ばれる。
     * @param sectionId
     * @param subSectionId
     * @param file
     */
    const handleAddFile = useCallback(
      async (
        sectionId: string,
        subSectionId:
          | "research_proposal"
          | "certificate_of_ethics_committee_approval"
          | "management_system"
          | "principal_investigator_resume"
          | "organization_overview"
          | "research_achievement"
          | "facility_gmp_compliance"
          | "other",
        file: File,
      ) => {
        const filePrivacyType =
          isNullish(institutionId) && isNullish(projectId)
            ? "private"
            : "public";
        const resp = await fileUpload(
          file,
          filePrivacyType,
          institutionId,
          projectId,
        );
        if (isNullish(resp)) {
          log.error("ファイルアップロードに失敗しました。");
          return;
        }
        onChange?.((before) => ({
          ...before,
          attachmentFiles: [
            ...before.attachmentFiles,
            {
              storageFileId: resp.id,
              attachmentFileType: subSectionId,
              attachmentFileName: file.name,
              uploadedAt: resp.created.datetime,
            },
          ],
        }));
      },
      [fileUpload, institutionId, onChange, projectId],
    );

    const handleDownloadFile = useCallback(
      (fileId: string, fileName: string) => {
        void openFileModal(fileId, fileName);
      },
      [openFileModal],
    );

    /**
     * 当該計画書の課題名の変更時に呼ばれる。
     */
    const handleChangeResearchPlanProjectName = useCallback(
      (text: string) => {
        onChange?.((before) => ({
          ...before,
          researchPlanProjectName: text,
        }));
      },
      [onChange],
    );

    /**
     * 添付ファイル削除時に呼ばれる。
     */
    const handleDeleteFile = useCallback(
      (_sectionId: string, _subSectionId: string, fileId: string) => {
        const storageFileId = storageFileIdMeta.toDomainObjectOrNull(
          Number.parseInt(fileId, 10),
        );
        if (isNullish(storageFileId)) return;

        onChange?.((before) => {
          const newAttachmentFiles = before.attachmentFiles.filter(
            (v) => v.storageFileId !== storageFileId,
          );
          return {
            ...before,
            attachmentFiles: newAttachmentFiles,
          };
        });
      },
      [onChange],
    );

    // モーダル制御
    const {
      isOpen: isOpenDeleteModal,
      onOpen: onOpenDeleteModal,
      onClose: onCloseDeleteModal,
    } = useDisclosure();

    /**
     * カード削除対象のカードインデックス
     */
    const [deletingFile, setDeletingFile] = useState<{
      sectionId: string;
      subSectionId: string;
      fileId: string;
    }>({ sectionId: "", subSectionId: "", fileId: "" });

    /**
     * カード削除ボタンが押下されると呼ばれ、モーダルを出現させる。
     */
    const handleDeleteButtonClick = useCallback(
      (sectionId: string, subSectionId: string, fileId: string) => {
        setDeletingFile({
          sectionId: sectionId,
          subSectionId: subSectionId,
          fileId: fileId,
        });
        onOpenDeleteModal();
      },
      [onOpenDeleteModal],
    );

    /**
     * モーダルの削除ボタンを押下すると呼ばれ、該当カードを削除する。
     */
    const handleConfirmDelete = useCallback(() => {
      handleDeleteFile(
        deletingFile.sectionId,
        deletingFile.subSectionId,
        deletingFile.fileId,
      );
      onCloseDeleteModal();
    }, [
      deletingFile.fileId,
      deletingFile.sectionId,
      deletingFile.subSectionId,
      handleDeleteFile,
      onCloseDeleteModal,
    ]);

    const changeBadgePropertiesResearchPlanProjectName =
      useChangeBadgeProperties(
        documentType,
        changedFromResearchPlanProjectName,
      );

    // 初めてiPS細胞ストックを使用する機関が添付可能なファイルが添付されているかどうか
    const isAttachedFirstApplicationFiles = useMemo(() => {
      return allValuesIncludingDeleted.filter((file) =>
        ["principal_investigator_resume", "organization_overview", "research_achievement"].includes(file.value.attachmentFileType)
      ).length > 0
    }, [allValuesIncludingDeleted])

    //commentsListをフィルタリングする関数
    const { filterCommetsListByInclude } = useLinkCommentPath();

    return (
      <>
        <VStack
          spacing={"25px"}
          pr={"10px"}
          alignItems={"stretch"}
          sx={sx}
          {...rest}
        >
          <CMFormFileUpload
            onAddFile={handleAddFile}
            onDeleteFile={handleDeleteButtonClick}
            onDownloadFile={handleDownloadFile}
            sx={{ fontSize: "sm" }}
            value={{
              id: "共通項目",
              title: t("lbl.共通項目"),
              subSectionContents: [
                {
                  editMode: editMode,
                  id: "research_proposal",
                  title: t("lbl.書類添付資料1"),
                  isRequired: true,
                  messageRequired: t("lbl.必須マーク"),
                  childrenButton: <UploadButtonEmpty />,
                  isDescription: true,
                  children: (
                    <CMFormInputTextArea
                      editMode={editMode}
                      noHeader={false}
                      changeBadgeProperties={
                        changeBadgePropertiesResearchPlanProjectName
                      }
                      label={t("lbl.当該計画書の課題名")}
                      minRows={2}
                      valueObjectMeta={researchPlanProjectNameMeta}
                      value={valueResearchPlanProjectName}
                      onChange={handleChangeResearchPlanProjectName}
                      mt={"10px"}
                    />
                  ),
                  commentButtonProps: commentButtonProps?.research_proposal,
                  commentsList: filterCommetsListByInclude(
                    commentsList, "research_proposal"
                  ),
                  files: allValuesIncludingDeleted
                    .filter(
                      (file) =>
                        file.value.attachmentFileType === "research_proposal",
                    )
                    .map((v) =>
                      attachmentFileToFileUploadItemWithChangeMode(
                        t,
                        v.value,
                        v.mode,
                      ),
                    ),
                },
                {
                  editMode: editMode,
                  id: "certificate_of_ethics_committee_approval",
                  title: t("lbl.書類添付資料2"),
                  isRequired: true,
                  messageRequired: t(
                    "lbl.注釈(倫理承認書・機関長許可書またはエグゼンプションレター)",
                  ),
                  childrenButton: <UploadButtonEmpty />,
                  commentButtonProps:
                    commentButtonProps?.certificate_of_ethics_committee_approval,
                  commentsList:
                    filterCommetsListByInclude(
                      commentsList, "certificate_of_ethics_committee_approval"
                    ),
                  files: allValuesIncludingDeleted
                    .filter(
                      (file) =>
                        file.value.attachmentFileType ===
                        "certificate_of_ethics_committee_approval",
                    )
                    .map((v) =>
                      attachmentFileToFileUploadItemWithChangeMode(
                        t,
                        v.value,
                        v.mode,
                      ),
                    ),
                },
                {
                  editMode: editMode,
                  id: "management_system",
                  title: t("lbl.書類添付資料3"),
                  isRequired: true,
                  messageRequired: t("lbl.必須マーク"),
                  childrenButton: <UploadButtonEmpty />,
                  commentButtonProps: commentButtonProps?.management_system,
                  commentsList:
                    filterCommetsListByInclude(
                      commentsList,
                      "management_system"
                    ),
                  files: allValuesIncludingDeleted
                    .filter(
                      (file) =>
                        file.value.attachmentFileType === "management_system",
                    )
                    .map((v) =>
                      attachmentFileToFileUploadItemWithChangeMode(
                        t,
                        v.value,
                        v.mode,
                      ),
                    ),
                },
              ],
            }}
          />

          {(sectionType === "partners" || hasUsageRecord !== true || isAttachedFirstApplicationFiles) && (
            <CMFormFileUpload
              sx={{ fontSize: "sm" }}
              onAddFile={handleAddFile}
              onDeleteFile={(
                sectionId: string,
                subSectionId: string,
                fileId: string,
              ) => handleDeleteButtonClick(sectionId, subSectionId, fileId)}
              onDownloadFile={handleDownloadFile}
              value={{
                id: "初めてiPS細胞ストックを使用される機関が含まれる場合のみ",
                title: t(
                  "lbl.初めてiPS細胞ストックを使用される機関が含まれる場合のみ",
                ),
                subSectionContents: [
                  {
                    editMode: editMode,
                    id: "principal_investigator_resume",
                    title: t("lbl.書類添付資料4"),
                    isRequired:
                      sectionType === "attachmentFiles" &&
                      hasUsageRecord === false,
                    messageRequired: t("lbl.必須マーク"),
                    childrenButton: <UploadButtonEmpty />,
                    commentButtonProps:
                      commentButtonProps?.principal_investigator_resume,
                    commentsList:
                      filterCommetsListByInclude(
                        commentsList, "principal_investigator_resume"
                      ),
                    files: allValuesIncludingDeleted
                      .filter(
                        (file) =>
                          file.value.attachmentFileType ===
                          "principal_investigator_resume",
                      )
                      .map((v) =>
                        attachmentFileToFileUploadItemWithChangeMode(
                          t,
                          v.value,
                          v.mode,
                        ),
                      ),
                  },
                  {
                    editMode: editMode,
                    id: "organization_overview",
                    title: t("lbl.書類添付資料5"),
                    isRequired:
                      sectionType === "attachmentFiles" &&
                      hasUsageRecord === false,
                    messageRequired: t("lbl.必須マーク"),
                    childrenButton: <UploadButtonEmpty />,
                    commentButtonProps:
                      commentButtonProps?.organization_overview,
                    commentsList:
                      filterCommetsListByInclude(
                        commentsList, "organization_overview"
                      ),
                    files: allValuesIncludingDeleted
                      .filter(
                        (file) =>
                          file.value.attachmentFileType ===
                          "organization_overview",
                      )
                      .map((v) =>
                        attachmentFileToFileUploadItemWithChangeMode(
                          t,
                          v.value,
                          v.mode,
                        ),
                      ),
                  },
                  {
                    editMode: editMode,
                    id: "research_achievement",
                    title: t("lbl.書類添付資料6"),
                    isRequired:
                      sectionType === "attachmentFiles" &&
                      hasUsageRecord === false,
                    messageRequired: t("lbl.必須マーク"),
                    childrenButton: <UploadButtonEmpty />,
                    commentButtonProps:
                      commentButtonProps?.research_achievement,
                    commentsList:
                      filterCommetsListByInclude(
                        commentsList,
                        "research_achievement"
                      ),
                    files: allValuesIncludingDeleted
                      .filter(
                        (file) =>
                          file.value.attachmentFileType ===
                          "research_achievement",
                      )
                      .map((v) =>
                        attachmentFileToFileUploadItemWithChangeMode(
                          t,
                          v.value,
                          v.mode,
                        ),
                      ),
                  },
                ],
              }}
            />
          )}

          {purposeType === "clinical" && (
            <CMFormFileUpload
              sx={{ fontSize: "sm" }}
              onAddFile={handleAddFile}
              onDeleteFile={(
                sectionId: string,
                subSectionId: string,
                fileId: string,
              ) => handleDeleteButtonClick(sectionId, subSectionId, fileId)}
              onDownloadFile={handleDownloadFile}
              value={{
                id: "臨床用途の場合",
                title: t("lbl.臨床用途の場合"),
                subSectionContents: [
                  {
                    editMode: editMode,
                    id: "facility_gmp_compliance",
                    title: t("lbl.書類添付資料7"),
                    isRequired: sectionType === "attachmentFiles",
                    messageRequired: t("lbl.必須マーク"),
                    childrenButton: <UploadButtonEmpty />,
                    commentButtonProps:
                      commentButtonProps?.facility_gmp_compliance,
                    commentsList:
                      filterCommetsListByInclude(
                        commentsList, "facility_gmp_compliance"
                      ),
                    files: allValuesIncludingDeleted
                      .filter(
                        (file) =>
                          file.value.attachmentFileType ===
                          "facility_gmp_compliance",
                      )
                      .map((v) =>
                        attachmentFileToFileUploadItemWithChangeMode(
                          t,
                          v.value,
                          v.mode,
                        ),
                      ),
                  },
                ],
              }}
            />
          )}

          <CMFormFileUpload
            sx={{ fontSize: "sm", minH: "70px" }}
            onAddFile={handleAddFile}
            onDeleteFile={(
              sectionId: string,
              subSectionId: string,
              fileId: string,
            ) => handleDeleteButtonClick(sectionId, subSectionId, fileId)}
            onDownloadFile={handleDownloadFile}
            value={{
              id: "その他参考となる資料",
              title: t("lbl.その他参考となる資料"),
              commentButtonProps:
                commentButtonProps?.other,
              commentsList:
                filterCommetsListByInclude(
                  commentsList, "other"
                ),
              subSectionContents: [
                {
                  editMode: editMode,
                  id: "other",
                  isRequired: false,
                  childrenButton: <UploadButtonEmpty />,
                  files: allValuesIncludingDeleted
                    .filter((file) => file.value.attachmentFileType === "other")
                    .map((v) =>
                      attachmentFileToFileUploadItemWithChangeMode(
                        t,
                        v.value,
                        v.mode,
                      ),
                    ),
                },
              ],
            }}
          />
        </VStack>
        {/*ファイル削除時に出現するモーダル*/}
        <DeleteConfirmationModal
          isOpen={isOpenDeleteModal}
          onClose={onCloseDeleteModal}
          title={t("lbl.確認ポップアップタイトル")}
          message={t("mes.添付資料削除確認メッセージ")}
          deleteButtonLabel={t("btn.削除ボタン")}
          cancelButtonLabel={t("btn.キャンセルボタン")}
          onConfirm={handleConfirmDelete}
        />
      </>
    );
  },
  (prevProps, nextProps) => {
    const _isEqual = isEqual(prevProps, nextProps);
    log.debug(`AttachmentFilesContent: _isEqual=${_isEqual}`);
    return _isEqual;
  },
);
