import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";
import React, { memo, useEffect, useMemo, useState } from "react";
import { CMSearchResultTable } from "@pscsrvlab/psc-react-components";
import log from "loglevel";
import {
  getMessageFromEnumValue,
  hasValue,
  isNullish,
} from "../../../../../../lib/util/common-util";
import { CollaborativePartner } from "../../../../../../lib/object/value/collaborative-partner";
import { RequestingCellSaved } from "../../../../../../lib/object/value/requesting-cell";
import { SearchResultRowContent } from "@pscsrvlab/psc-react-components/src/components/search/row/types";
import { foreignTypeMeta } from "../../../../../../lib/object/value/foreign-type";
import { ProjectSaved } from "../../../../../../lib/object/entity/project";
import { TFunction } from "i18next";
import {
  ymdToDateText,
  ymdToDateTextOrHyphen,
} from "../../../../../../lib/util/common-date-util";
import { projectStateMeta } from "../../../../../../lib/object/value/project-state";
import { commercialTypeMeta } from "../../../../../../lib/object/value/commercial-type";
import { ipsCellStockUsePurposeTypeMeta } from "../../../../../../lib/object/value/ips-cell-stock-use-purpose-type";
import { terminationReportSubmittedMeta } from "../../../../../../lib/object/value/termination-report-submitted";
import { stockRequestApi } from "../../../../../../store/api/enhanced-api";
import { QueryDefinition } from "@reduxjs/toolkit/query";
import {
  ListCellStockDeliveryApiArg,
  ListCellStockDeliveryApiResponse,
} from "../../../../../../store/api/generated/stock-request-api";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { LazyQueryTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import {
  cellStockDeliveryMeta,
  CellStockDeliverySaved,
} from "../../../../../../lib/object/entity/cell-stock-delivery";
import { errorMessageOf } from "../../../../../../lib/util/error-util";
import { ShippingCellSaved } from "../../../../../../lib/object/value/shipping-cell";
import { YearMonthDay } from "../../../../../../lib/object/value/year-month-day";
import { SearchResultTableContent } from "@pscsrvlab/psc-react-components/src/components/search/table/types";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";

export type SearchResultTableProjectProps = {
  entities: ProjectSaved[];
} & ComponentStyleProps;

/**
 * 案件検索結果テーブル。
 */
export const SearchResultTableProject = memo(function SearchResultTableProject({
  entities,

  sx,
  ...rest
}: SearchResultTableProjectProps) {
  const { t, i18n } = useAppTranslation();
  const [triggerListCellStockDeliveryQuery] =
    stockRequestApi.useLazyListCellStockDeliveryQuery();

  // 検索結果テーブルのヘッダ情報
  const header = useMemo(() => {
    return {
      cells: [
        { value: t("lbl.案件管理番号"), width: "130px" },
        { value: t("lbl.課題名"), width: "150px" },
        { value: t("lbl.案件状態"), width: "80px" },
        { value: t("lbl.機関名"), width: "120px" },
        { value: t("lbl.国内外区分"), width: undefined },
        { value: t("lbl.営利非営利区分"), width: undefined },
        { value: t("lbl.研究責任者名"), width: "120px" },
        { value: t("lbl.分担機関名"), width: "150px" },
        { value: t("lbl.使用する細胞"), width: "150px" },
        { value: t("lbl.発送する細胞"), width: "150px" },
        { value: t("lbl.細胞発送日"), width: "140px" },
        { value: t("lbl.iPS細胞の使用目的"), width: "120px" },
        { value: t("lbl.新規申請提出日"), width: "100px" },
        { value: t("lbl.新規申請承認日"), width: "100px" },
        { value: t("lbl.次回年次報告期限日"), width: "100px" },
        { value: t("lbl.使用終了日"), width: "100px" },
        { value: t("lbl.使用終了報告提出済"), width: "120px" },
        { value: t("lbl.更新日時"), width: "140px" },
      ],
    };
  }, [t]);

  const [tableContent, setTableContent] = useState<SearchResultTableContent>({
    header: { cells: [] },
    rows: [],
  });
  // テーブル表示用にデータを整形する
  useEffect(() => {
    (async () => {
      try {
        // 各項目ごとにデータを整形
        const rows: SearchResultRowContent[] = await createRows(
          entities,
          t,
          i18n,
          triggerListCellStockDeliveryQuery,
        );

        // log.debug("rows", rows);

        // テーブル表示に必要なデータを設定
        setTableContent({
          header,
          rows,
        });
      } catch (e) {
        log.error(errorMessageOf(e));
      }
    })();
  }, [entities, t, i18n, triggerListCellStockDeliveryQuery, header]);

  return (
    <CMSearchResultTable
      content={tableContent}
      stickyHeaderTop={"29px"}
      stickyFirstColLeft={"30px"}
      stickySecondColLeft={"160px"}
      sx={sx}
      {...rest}
    />
  );
});

/**
 * 分担機関名の名称を改行コードで結合して返却する
 * @param t
 * @param values 分担機関情報
 */
function formatCollaborativePartners(
  t: TFunction<"translation", undefined, "translation">,
  values?: CollaborativePartner[],
): string[] {
  if (isNullish(values)) {
    return [];
  }

  return values.map((v) => {
    const name = v.collaborativePartnerName;
    const foreignType = getMessageFromEnumValue(
      t,
      foreignTypeMeta,
      v.foreignType,
    );
    const commercialType = getMessageFromEnumValue(
      t,
      commercialTypeMeta,
      v.commercialType,
    );

    return `${name}(${foreignType}・${commercialType})`;
  });
}

/**
 * 使用する細胞の名称を改行コードで結合して返却する
 * @param values 細胞情報
 * @param langType 使用する名称の言語設定
 */
function formatRequestingCellsName(
  values?: RequestingCellSaved[],
  langType: "ja" | "en" = "ja",
): string[] {
  if (isNullish(values)) {
    return [];
  }

  return values.map((v) => {
    if (langType === "ja") {
      return v.cellNameJa;
    } else {
      return v.cellNameEn;
    }
  });
}

/**
 * 発送する細胞の名称を改行コードで結合して返却する
 * @param values 発送する細胞
 * @param langType 使用する名称の言語設定
 */
function formatShippingCellsName(
  values?: ShippingCellSaved[],
  langType: "ja" | "en" = "ja",
): string[] {
  if (isNullish(values)) {
    return [];
  }

  return values.map((v) => {
    if (langType === "ja") {
      return v.cellNameJa;
    } else {
      return v.cellNameEn;
    }
  });
}

/**
 * 細胞発送日を改行コードで結合して返却する
 * @param values 細胞発送日
 */
function formatShippingDate(values?: YearMonthDay[]): string[] {
  if (isNullish(values)) {
    return [];
  }

  return values.map((v) => ymdToDateText(v));
}

function createRows(
  entities: ProjectSaved[],
  t: TFunction<"translation", undefined, "translation">,
  i18n: any,
  triggerListCellStockDeliveryQuery: LazyQueryTrigger<
    QueryDefinition<
      ListCellStockDeliveryApiArg,
      BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, {}>,
      never,
      ListCellStockDeliveryApiResponse,
      "api"
    >
  >,
): Promise<SearchResultRowContent[]> {
  // log.debug("ProjectSearchTable", "contents", contents);

  return Promise.all(
    entities.map(async (entity) => {
      const listCellStockDeliveryResp = await triggerListCellStockDeliveryQuery(
        {
          projectId: entity.id,
        },
      ).unwrap();
      const cellStockDeliveries: CellStockDeliverySaved[] =
        listCellStockDeliveryResp
          .map((v) => cellStockDeliveryMeta.toSavedDomainObjectOrNull(v))
          .filter(hasValue);
      return {
        cells: [
          {
            // 案件管理番号
            type: "react_router_link",
            value: {
              text: entity.projectControlNumber ?? "",
              to: `/project/${entity.id}/content/application`,
            },
          },
          {
            // 課題名
            type: "text",
            value: entity.applicationContent?.projectName,
          },
          {
            // 案件状態
            type: "text",
            value: getMessageFromEnumValue(
              t,
              projectStateMeta,
              entity.projectState,
            ),
          },
          {
            // 機関名
            type: "text",
            value: entity.applicationContent?.institution.institutionName,
          },
          {
            // 国内外区分
            type: "text",
            value: getMessageFromEnumValue(
              t,
              foreignTypeMeta,
              entity.applicationContent?.institution.foreignType,
            ),
          },
          {
            // 営利非営利区分
            type: "text",
            value: getMessageFromEnumValue(
              t,
              commercialTypeMeta,
              entity.applicationContent.institution.commercialType,
            ),
          },
          {
            // 研究責任者名
            type: "text",
            value: entity.applicationContent.principalInvestigator?.fullName,
          },
          {
            // 分担機関名
            type: "text_list",
            value: formatCollaborativePartners(
              t,
              entity.applicationContent.partners,
            ),
          },
          {
            // 使用する細胞
            type: "text_list",
            value: formatRequestingCellsName(
              [
                ...entity.applicationContent.requestingResearchCells,
                ...entity.applicationContent.requestingClinicalCells,
              ],
              i18n.language,
            ),
          },
          {
            // 発送する細胞
            type: "text_list",
            value: formatShippingCellsName(
              cellStockDeliveries.flatMap((v) => [
                ...v.shippingResearchCells,
                ...v.shippingClinicalCells,
              ]),
              i18n.language,
            ),
          },
          {
            // 細胞発送日
            type: "text_list",
            value: formatShippingDate(
              cellStockDeliveries
                .map((v) => v.shippingInformation.shippingDate)
                .filter(hasValue),
            ),
          },
          {
            // iPS細胞の使用目的
            type: "text",
            value: getMessageFromEnumValue(
              t,
              ipsCellStockUsePurposeTypeMeta,
              entity?.applicationContent?.ipsCellStockUsePurpose.purposeType,
            ),
          },
          {
            // 新規申請提出日
            type: "text",
            value: ymdToDateTextOrHyphen(entity?.newApplicationSubmissionDate),
          },
          {
            // 新規申請承認日
            type: "text",
            value: ymdToDateTextOrHyphen(entity?.newApplicationApprovalDate),
          },
          {
            // 次回年次報告期限日
            type: "text",
            value: ymdToDateTextOrHyphen(entity?.nextAnnualReportDeadline),
          },
          {
            // 使用終了日
            type: "text",
            value: ymdToDateTextOrHyphen(
              entity?.applicationContent?.usageEndDate,
            ),
          },
          {
            // 使用終了報告提出済
            type: "text",
            value: getMessageFromEnumValue(
              t,
              terminationReportSubmittedMeta,
              entity?.terminationReportSubmitted,
            ),
          },
          {
            // 更新日時
            type: "datetime",
            value: entity?.updated?.datetime,
          },
        ],
      };
    }),
  );
}
