import {
  messageId,
  PropertyMeta,
  EntityMeta,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { resultNotificationIdMeta } from "../value/result-notification-id";
import { documentIdMeta } from "../value/document-id";
import { resultNotificationTypeMeta } from "../value/result-notification-type";
import { resultMeta } from "../value/result";
import { yearMonthDayMeta } from "../value/year-month-day";
import { unapprovalReasonMeta } from "../value/unapproval-reason";
import { freeTextMeta } from "../value/free-text";
import { executiveDirectorApprovalMeta } from "../value/executive-director-approval";
import { creationMetadataMeta } from "../value/creation-metadata";
import { updateMetadataMeta } from "../value/update-metadata";
/**
 * 結果通知(エンティティ)
 *
 *  */
export type ResultNotification = z.infer<typeof schema>;
export type ResultNotificationSaved = z.infer<typeof savedSchema>;
const minimalSchema = z.object({
  id: resultNotificationIdMeta.minimalSchema.optional(),
  documentId: documentIdMeta.minimalSchema,
  resultNotificationType: resultNotificationTypeMeta.minimalSchema,
  result: resultMeta.minimalSchema,
  reviewDate: yearMonthDayMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  approvalConditionConfirmationDate: yearMonthDayMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  unapprovalReason: unapprovalReasonMeta.minimalSchema,
  note: freeTextMeta.minimalSchema,
  executiveDirectorApproval: executiveDirectorApprovalMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  resultNotificationDate: yearMonthDayMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  created: creationMetadataMeta.minimalSchema,
  updated: updateMetadataMeta.minimalSchema,
});
const savedMinimalSchema = z.object({
  id: resultNotificationIdMeta.minimalSchema,
  documentId: documentIdMeta.savedMinimalSchema,
  resultNotificationType: resultNotificationTypeMeta.savedMinimalSchema,
  result: resultMeta.savedMinimalSchema,
  reviewDate: yearMonthDayMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  approvalConditionConfirmationDate: yearMonthDayMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  unapprovalReason: unapprovalReasonMeta.savedMinimalSchema,
  note: freeTextMeta.savedMinimalSchema,
  executiveDirectorApproval: executiveDirectorApprovalMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  resultNotificationDate: yearMonthDayMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  created: creationMetadataMeta.savedMinimalSchema,
  updated: updateMetadataMeta.savedMinimalSchema,
});
const schema = z.object({
  id: resultNotificationIdMeta.minimalSchema.optional(),
  documentId: documentIdMeta.schema,
  resultNotificationType: resultNotificationTypeMeta.schema,
  result: resultMeta.schema,
  reviewDate: yearMonthDayMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
  approvalConditionConfirmationDate: yearMonthDayMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
  unapprovalReason: unapprovalReasonMeta.schema,
  note: freeTextMeta.schema,
  executiveDirectorApproval: executiveDirectorApprovalMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
  resultNotificationDate: yearMonthDayMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
  created: creationMetadataMeta.schema,
  updated: updateMetadataMeta.schema,
});
const savedSchema = z.object({
  id: resultNotificationIdMeta.minimalSchema,
  documentId: documentIdMeta.savedSchema,
  resultNotificationType: resultNotificationTypeMeta.savedSchema,
  result: resultMeta.savedSchema,
  reviewDate: yearMonthDayMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  approvalConditionConfirmationDate: yearMonthDayMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  unapprovalReason: unapprovalReasonMeta.savedSchema,
  note: freeTextMeta.savedSchema,
  executiveDirectorApproval: executiveDirectorApprovalMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  resultNotificationDate: yearMonthDayMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  created: creationMetadataMeta.savedSchema,
  updated: updateMetadataMeta.savedSchema,
});
const jsonSchema = z.object({
  id: resultNotificationIdMeta.jsonSchema.optional(),
  documentId: documentIdMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  resultNotificationType: resultNotificationTypeMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  result: resultMeta.jsonSchema.nullish().transform((x) => x ?? undefined),
  reviewDate: yearMonthDayMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  approvalConditionConfirmationDate: yearMonthDayMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  unapprovalReason: unapprovalReasonMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  note: freeTextMeta.jsonSchema.nullish().transform((x) => x ?? undefined),
  executiveDirectorApproval: executiveDirectorApprovalMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  resultNotificationDate: yearMonthDayMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  created: creationMetadataMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  updated: updateMetadataMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
/**
 * 結果通知(エンティティ)のメタデータ。
 *
 *  */
export const resultNotificationMeta = new EntityMeta<
  typeof schema,
  typeof savedSchema,
  typeof jsonSchema
>(
  "ResultNotification",
  "\u7D50\u679C\u901A\u77E5",
  messageId("lbl.\u7D50\u679C\u901A\u77E5"),
  minimalSchema,
  savedMinimalSchema,
  schema,
  savedSchema,
  jsonSchema,
  undefined,
  undefined,
  {
    id: new PropertyMeta(
      "id",
      "ID",
      messageId("lbl.ID"),
      "afterSave",
      false,
      resultNotificationIdMeta
    ),
    documentId: new PropertyMeta(
      "documentId",
      "\u66F8\u985EID",
      messageId("lbl.\u66F8\u985EID"),
      "always",
      false,
      documentIdMeta
    ),
    resultNotificationType: new PropertyMeta(
      "resultNotificationType",
      "\u7D50\u679C\u901A\u77E5\u7A2E\u5225",
      messageId("lbl.\u7D50\u679C\u901A\u77E5\u7A2E\u5225"),
      "always",
      false,
      resultNotificationTypeMeta
    ),
    result: new PropertyMeta(
      "result",
      "\u7D50\u679C",
      messageId("lbl.\u7D50\u679C"),
      "always",
      false,
      resultMeta
    ),
    reviewDate: new PropertyMeta(
      "reviewDate",
      "\u5BE9\u67FB\u5B9F\u65BD\u65E5",
      messageId("lbl.\u5BE9\u67FB\u5B9F\u65BD\u65E5"),
      "none",
      false,
      yearMonthDayMeta
    ),
    approvalConditionConfirmationDate: new PropertyMeta(
      "approvalConditionConfirmationDate",
      "\u6761\u4EF6\u5BFE\u5FDC\u78BA\u8A8D\u65E5",
      messageId("lbl.\u6761\u4EF6\u5BFE\u5FDC\u78BA\u8A8D\u65E5"),
      "none",
      false,
      yearMonthDayMeta
    ),
    unapprovalReason: new PropertyMeta(
      "unapprovalReason",
      "\u627F\u8A8D\u4EE5\u5916\u306E\u7406\u7531",
      messageId("lbl.\u627F\u8A8D\u4EE5\u5916\u306E\u7406\u7531"),
      "always",
      false,
      unapprovalReasonMeta
    ),
    note: new PropertyMeta(
      "note",
      "\u5099\u8003",
      messageId("lbl.\u5099\u8003"),
      "always",
      false,
      freeTextMeta
    ),
    executiveDirectorApproval: new PropertyMeta(
      "executiveDirectorApproval",
      "\u696D\u52D9\u57F7\u884C\u7406\u4E8B\u627F\u8A8D",
      messageId("lbl.\u696D\u52D9\u57F7\u884C\u7406\u4E8B\u627F\u8A8D"),
      "none",
      false,
      executiveDirectorApprovalMeta
    ),
    resultNotificationDate: new PropertyMeta(
      "resultNotificationDate",
      "\u7D50\u679C\u901A\u77E5\u65E5",
      messageId("lbl.\u7D50\u679C\u901A\u77E5\u65E5"),
      "none",
      false,
      yearMonthDayMeta
    ),
    created: new PropertyMeta(
      "created",
      "\u4F5C\u6210",
      messageId("lbl.\u4F5C\u6210"),
      "always",
      false,
      creationMetadataMeta
    ),
    updated: new PropertyMeta(
      "updated",
      "\u66F4\u65B0",
      messageId("lbl.\u66F4\u65B0"),
      "always",
      false,
      updateMetadataMeta
    ),
  }
);
