import { ComponentStyleProps } from "../../../../../../../../../lib/styles/props/component-style-props";
import {
  CMFormInputRadio,
  CMFormInputYearMonth,
  Comments,
} from "@pscsrvlab/psc-react-components";
import { HStack, Text } from "@chakra-ui/react";
import {
  EthicalApplicationStatusType,
  ethicalApplicationStatusTypeMeta,
} from "../../../../../../../../../lib/object/value/ethical-application-status-type";
import React, { memo, useCallback, useMemo } from "react";
import { EthicalApplicationStatus } from "../../../../../../../../../lib/object/value/ethical-application-status";
import { YearMonth } from "../../../../../../../../../lib/object/value/year-month";
import { CMButtonFormCommentProps } from "@pscsrvlab/psc-react-components/src/components/form/comment";
import { useSectionDiffMode } from "../../../../../../../../../hooks/document/change-application/use-section-diff-mode";
import { useChangeBadgeProperties } from "../../../../../../../../../hooks/document/change-application/use-change-badge-properties";
import { ChangeReasonForm } from "../../form/ChangeReasonForm/ChangeReasonForm";
import { FormSection } from "../../../../../../../../ui/form/FormSection/FormSection";
import { useAppTranslation } from "../../../../../../../../../hooks/use-app-translation";
import {
  hasValue,
  isNullish,
} from "../../../../../../../../../lib/util/common-util";
import { ymToYearMonthText } from "../../../../../../../../../lib/util/common-date-util";
import { ChangeReasonItemViewModel } from "../../../../../../../../../lib/object/vm/change-application-view-model";

export type EthicalApplicationStatusSectionProps = {
  documentType:
  | "new_application"
  | "change_application"
  | "project_force_update"
  | "project_force_create"
  | "project_content";
  editMode: "editable" | "readOnly";

  value: EthicalApplicationStatus;
  onChange?: (
    change: (before: EthicalApplicationStatus) => EthicalApplicationStatus,
  ) => void;

  changedFrom?: EthicalApplicationStatus;
  changeReason?: string;
  onChangeReason?: (
    change: (before: ChangeReasonItemViewModel) => ChangeReasonItemViewModel,
  ) => void;
  displayChangesOnly?: boolean;

  /**
   * コメントボタンのprops。
   * これが存在すれば、コメントボタンを表示する。
   */
  commentButtonProps?: CMButtonFormCommentProps;
  /**
   * 1セクション単位の修正依頼コメント・返信。履歴参照画面で使用。
   */
  commentsList?: Comments[];
} & ComponentStyleProps;

export const EthicalApplicationStatusSection = memo(
  function EthicalApplicationStatusSection({
    documentType,
    editMode,

    value,
    onChange,

    changedFrom,
    changeReason,
    onChangeReason,
    displayChangesOnly,

    commentButtonProps,
    commentsList,

    sx,
    ...rest
  }: EthicalApplicationStatusSectionProps) {
    const { t } = useAppTranslation();

    const handleChangeStatus = useCallback(
      (v: EthicalApplicationStatusType) => {
        onChange?.((before) => ({
          ethicalApplicationStatus: v,
          estimatedApprovalYearMonth:
            v === "requesting" ? before.estimatedApprovalYearMonth : undefined,
        }));
      },
      [onChange],
    );
    const handleChangeYearMonth = useCallback(
      (v: YearMonth | null) => {
        onChange?.((before) => ({
          ...before,
          estimatedApprovalYearMonth: v ?? undefined,
        }));
      },
      [onChange],
    );

    const sectionDiffMode = useSectionDiffMode(
      documentType,
      changedFrom,
      value,
      onChangeReason,
    );

    const changeBadgePropertiesEthicalApplicationStatus =
      useChangeBadgeProperties(
        documentType,
        changedFrom?.ethicalApplicationStatus,
      );
    const changeBadgePropertiesEstimatedApprovalYearMonth =
      useChangeBadgeProperties(
        documentType,
        changedFrom?.estimatedApprovalYearMonth,
      );

    const readOnlyStatus: string = useMemo(() => {
      if (editMode !== "readOnly" || isNullish(value)) return "";
      switch (value.ethicalApplicationStatus) {
        case "approved":
          return t("code.倫理申請状況区分.承認済");
        case "requesting":
          if (
            isNullish(value.estimatedApprovalYearMonth) ||
            isNullish(value.estimatedApprovalYearMonth?.year) ||
            isNullish(value.estimatedApprovalYearMonth?.month)
          )
            return "";
          return (
            t("code.倫理申請状況区分.申請中") +
            " (" +
            ymToYearMonthText(
              value.estimatedApprovalYearMonth.year,
              value.estimatedApprovalYearMonth.month,
            ) +
            t("lbl.承認見込み") +
            ")"
          );
      }
    }, [editMode, value, t]);

    return (
      <FormSection
        title={t("lbl.倫理申請の状況")}
        displayMode={sectionDiffMode}
        displayChangesOnly={displayChangesOnly}
        commentButtonProps={commentButtonProps}
        commentsList={commentsList}
        sx={sx}
        {...rest}
      >
        {editMode === "readOnly" ? (
          <Text>{readOnlyStatus}</Text>
        ) : (
          <CMFormInputRadio
            value={value?.ethicalApplicationStatus}
            valueObjectMeta={ethicalApplicationStatusTypeMeta}
            editMode={editMode}
            noHeader={!hasValue(changedFrom?.ethicalApplicationStatus)}
            onChange={handleChangeStatus}
            changeBadgeProperties={
              changeBadgePropertiesEthicalApplicationStatus
            }
            insertedNodeOnSelected={
              <HStack>
                <CMFormInputYearMonth
                  size={"md"}
                  value={value?.estimatedApprovalYearMonth}
                  noHeader={!hasValue(changedFrom?.estimatedApprovalYearMonth)}
                  editMode={editMode}
                  onChange={handleChangeYearMonth}
                  changeBadgeProperties={
                    changeBadgePropertiesEstimatedApprovalYearMonth
                  }
                  ml={"30px"}
                  w={"220px"}
                />
                <Text whiteSpace={"nowrap"}>{t("lbl.承認見込み")}</Text>
              </HStack>
            }
            insertValue={"requesting"}
          />
        )}

        {sectionDiffMode === "updated" && (
          <ChangeReasonForm
            value={changeReason}
            onChange={onChangeReason}
            editMode={editMode}
          />
        )}
      </FormSection>
    );
  },
);
