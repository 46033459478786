import { emptySplitApi as api } from "../empty-api";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    uploadFile: build.mutation<UploadFileApiResponse, UploadFileApiArg>({
      query: (queryArg) => ({
        url: `/storage-files:upload`,
        method: "POST",
        body: queryArg.body,
        params: {
          filePrivacyType: queryArg.filePrivacyType,
          institutionId: queryArg.institutionId,
          projectId: queryArg.projectId,
        },
      }),
    }),
    listSharedAttachmentFile: build.query<
      ListSharedAttachmentFileApiResponse,
      ListSharedAttachmentFileApiArg
    >({
      query: () => ({ url: `/shared-attachment-files` }),
    }),
    createSharedAttachmentFile: build.mutation<
      CreateSharedAttachmentFileApiResponse,
      CreateSharedAttachmentFileApiArg
    >({
      query: (queryArg) => ({
        url: `/shared-attachment-files`,
        method: "POST",
        body: queryArg.sharedAttachmentFile,
      }),
    }),
    listRevisionRequest: build.query<
      ListRevisionRequestApiResponse,
      ListRevisionRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/revision-requests`,
        params: {
          documentId: queryArg.documentId,
          revisionRequestState: queryArg.revisionRequestState,
        },
      }),
    }),
    createRevisionRequest: build.mutation<
      CreateRevisionRequestApiResponse,
      CreateRevisionRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/revision-requests`,
        method: "POST",
        body: queryArg.revisionRequest,
      }),
    }),
    createReviewConference: build.mutation<
      CreateReviewConferenceApiResponse,
      CreateReviewConferenceApiArg
    >({
      query: (queryArg) => ({
        url: `/review-conferences`,
        method: "POST",
        body: queryArg.reviewConference,
      }),
    }),
    notifyReviewConference: build.mutation<
      NotifyReviewConferenceApiResponse,
      NotifyReviewConferenceApiArg
    >({
      query: (queryArg) => ({
        url: `/review-conferences/${queryArg.reviewConferenceId}:notify`,
        method: "POST",
      }),
    }),
    concludeReviewConference: build.mutation<
      ConcludeReviewConferenceApiResponse,
      ConcludeReviewConferenceApiArg
    >({
      query: (queryArg) => ({
        url: `/review-conferences/${queryArg.reviewConferenceId}:conclude`,
        method: "POST",
      }),
    }),
    listResultNotification: build.query<
      ListResultNotificationApiResponse,
      ListResultNotificationApiArg
    >({
      query: (queryArg) => ({
        url: `/result-notifications`,
        params: { documentId: queryArg.documentId },
      }),
    }),
    createResultNotification: build.mutation<
      CreateResultNotificationApiResponse,
      CreateResultNotificationApiArg
    >({
      query: (queryArg) => ({
        url: `/result-notifications`,
        method: "POST",
        body: queryArg.resultNotification,
      }),
    }),
    forceCreateProject: build.mutation<
      ForceCreateProjectApiResponse,
      ForceCreateProjectApiArg
    >({
      query: (queryArg) => ({
        url: `/projects:force-create`,
        method: "POST",
        body: queryArg.project,
      }),
    }),
    listInstitution: build.query<
      ListInstitutionApiResponse,
      ListInstitutionApiArg
    >({
      query: (queryArg) => ({
        url: `/institutions`,
        params: { institutionName: queryArg.institutionName },
      }),
    }),
    createInstitution: build.mutation<
      CreateInstitutionApiResponse,
      CreateInstitutionApiArg
    >({
      query: (queryArg) => ({
        url: `/institutions`,
        method: "POST",
        body: queryArg.institution,
      }),
    }),
    createInquiry: build.mutation<
      CreateInquiryApiResponse,
      CreateInquiryApiArg
    >({
      query: (queryArg) => ({
        url: `/inquiries`,
        method: "POST",
        body: queryArg.inquiry,
      }),
    }),
    createTerminationReportDraft: build.mutation<
      CreateTerminationReportDraftApiResponse,
      CreateTerminationReportDraftApiArg
    >({
      query: (queryArg) => ({
        url: `/documents.termination-report:create-draft`,
        method: "POST",
        body: queryArg.terminationReportContent,
        params: { projectId: queryArg.projectId },
      }),
    }),
    submitTerminationReportFromDraft: build.mutation<
      SubmitTerminationReportFromDraftApiResponse,
      SubmitTerminationReportFromDraftApiArg
    >({
      query: (queryArg) => ({
        url: `/documents.termination-report/${queryArg.documentId}:submit`,
        method: "POST",
      }),
    }),
    createNewApplicationDraft: build.mutation<
      CreateNewApplicationDraftApiResponse,
      CreateNewApplicationDraftApiArg
    >({
      query: (queryArg) => ({
        url: `/documents.new-application:create-draft`,
        method: "POST",
        body: queryArg.applicationContent,
        params: { correctnessCheck: queryArg.correctnessCheck },
      }),
    }),
    createChangeApplicationDraft: build.mutation<
      CreateChangeApplicationDraftApiResponse,
      CreateChangeApplicationDraftApiArg
    >({
      query: (queryArg) => ({
        url: `/documents.change-application:create-draft`,
        method: "POST",
        body: queryArg.changeApplicationContent,
        params: {
          projectId: queryArg.projectId,
          correctnessCheck: queryArg.correctnessCheck,
        },
      }),
    }),
    submitChangeApplicationFromDraft: build.mutation<
      SubmitChangeApplicationFromDraftApiResponse,
      SubmitChangeApplicationFromDraftApiArg
    >({
      query: (queryArg) => ({
        url: `/documents.change-application/${queryArg.documentId}:submit`,
        method: "POST",
      }),
    }),
    createAnnualReportDraft: build.mutation<
      CreateAnnualReportDraftApiResponse,
      CreateAnnualReportDraftApiArg
    >({
      query: (queryArg) => ({
        url: `/documents.annual-report:create-draft`,
        method: "POST",
        body: queryArg.annualReportContent,
        params: { projectId: queryArg.projectId },
      }),
    }),
    submitAnnualReportFromDraft: build.mutation<
      SubmitAnnualReportFromDraftApiResponse,
      SubmitAnnualReportFromDraftApiArg
    >({
      query: (queryArg) => ({
        url: `/documents.annual-report/${queryArg.documentId}:submit`,
        method: "POST",
      }),
    }),
    listCellStockDelivery: build.query<
      ListCellStockDeliveryApiResponse,
      ListCellStockDeliveryApiArg
    >({
      query: (queryArg) => ({
        url: `/cell-stock-deliveries`,
        params: { projectId: queryArg.projectId },
      }),
    }),
    createCellStockDelivery: build.mutation<
      CreateCellStockDeliveryApiResponse,
      CreateCellStockDeliveryApiArg
    >({
      query: (queryArg) => ({
        url: `/cell-stock-deliveries`,
        method: "POST",
        body: queryArg.cellStockDelivery,
      }),
    }),
    applyConfig: build.mutation<ApplyConfigApiResponse, ApplyConfigApiArg>({
      query: () => ({ url: `/apply-config`, method: "POST" }),
    }),
    listAppUser: build.query<ListAppUserApiResponse, ListAppUserApiArg>({
      query: (queryArg) => ({
        url: `/app-users`,
        params: {
          institutionId: queryArg.institutionId,
          fullName: queryArg.fullName,
        },
      }),
    }),
    createAppUser: build.mutation<
      CreateAppUserApiResponse,
      CreateAppUserApiArg
    >({
      query: (queryArg) => ({
        url: `/app-users`,
        method: "POST",
        body: queryArg.appUser,
      }),
    }),
    resetTemporaryPassword: build.mutation<
      ResetTemporaryPasswordApiResponse,
      ResetTemporaryPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/app-users/${queryArg.appUserId}:reset-temporary-password`,
        method: "POST",
      }),
    }),
    resetMfa: build.mutation<ResetMfaApiResponse, ResetMfaApiArg>({
      query: (queryArg) => ({
        url: `/app-users/${queryArg.appUserId}:reset-mfa`,
        method: "POST",
      }),
    }),
    submitRevisionRequestReply: build.mutation<
      SubmitRevisionRequestReplyApiResponse,
      SubmitRevisionRequestReplyApiArg
    >({
      query: (queryArg) => ({
        url: `/revision-requests/${queryArg.revisionRequestId}:submit-reply`,
        method: "PATCH",
      }),
    }),
    saveRevisionRequestReply: build.mutation<
      SaveRevisionRequestReplyApiResponse,
      SaveRevisionRequestReplyApiArg
    >({
      query: (queryArg) => ({
        url: `/revision-requests/${queryArg.revisionRequestId}:save-reply`,
        method: "PATCH",
        body: queryArg.commonUpdateRequest,
      }),
    }),
    getReview: build.query<GetReviewApiResponse, GetReviewApiArg>({
      query: (queryArg) => ({ url: `/reviews/${queryArg.reviewId}` }),
    }),
    updateReview: build.mutation<UpdateReviewApiResponse, UpdateReviewApiArg>({
      query: (queryArg) => ({
        url: `/reviews/${queryArg.reviewId}`,
        method: "PATCH",
        body: queryArg.commonUpdateRequest,
      }),
    }),
    concludeReview: build.mutation<
      ConcludeReviewApiResponse,
      ConcludeReviewApiArg
    >({
      query: (queryArg) => ({
        url: `/reviews/${queryArg.reviewId}:conclude`,
        method: "PATCH",
      }),
    }),
    approveReview: build.mutation<
      ApproveReviewApiResponse,
      ApproveReviewApiArg
    >({
      query: (queryArg) => ({
        url: `/reviews/${queryArg.reviewId}:approve`,
        method: "PATCH",
      }),
    }),
    getReviewConference: build.query<
      GetReviewConferenceApiResponse,
      GetReviewConferenceApiArg
    >({
      query: (queryArg) => ({
        url: `/review-conferences/${queryArg.reviewConferenceId}`,
      }),
    }),
    deleteReviewConference: build.mutation<
      DeleteReviewConferenceApiResponse,
      DeleteReviewConferenceApiArg
    >({
      query: (queryArg) => ({
        url: `/review-conferences/${queryArg.reviewConferenceId}`,
        method: "DELETE",
      }),
    }),
    updateReviewConference: build.mutation<
      UpdateReviewConferenceApiResponse,
      UpdateReviewConferenceApiArg
    >({
      query: (queryArg) => ({
        url: `/review-conferences/${queryArg.reviewConferenceId}`,
        method: "PATCH",
        body: queryArg.commonUpdateRequest,
      }),
    }),
    updateDisclosureDocument: build.mutation<
      UpdateDisclosureDocumentApiResponse,
      UpdateDisclosureDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectId}:update-disclosure-document`,
        method: "PATCH",
        body: queryArg.commonUpdateRequest,
      }),
    }),
    forceUpdateProject: build.mutation<
      ForceUpdateProjectApiResponse,
      ForceUpdateProjectApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectId}:force-update`,
        method: "PATCH",
        body: queryArg.commonUpdateRequest,
      }),
    }),
    abortProject: build.mutation<AbortProjectApiResponse, AbortProjectApiArg>({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectId}:abort`,
        method: "PATCH",
      }),
    }),
    getInstitution: build.query<
      GetInstitutionApiResponse,
      GetInstitutionApiArg
    >({
      query: (queryArg) => ({ url: `/institutions/${queryArg.institutionId}` }),
    }),
    updateInstitution: build.mutation<
      UpdateInstitutionApiResponse,
      UpdateInstitutionApiArg
    >({
      query: (queryArg) => ({
        url: `/institutions/${queryArg.institutionId}`,
        method: "PATCH",
        body: queryArg.commonUpdateRequest,
      }),
    }),
    respondInquiry: build.mutation<
      RespondInquiryApiResponse,
      RespondInquiryApiArg
    >({
      query: (queryArg) => ({
        url: `/inquiries/${queryArg.inquiryId}:respond`,
        method: "PATCH",
        body: queryArg.inquiryReply,
      }),
    }),
    readInquiry: build.mutation<ReadInquiryApiResponse, ReadInquiryApiArg>({
      query: (queryArg) => ({
        url: `/inquiries/${queryArg.inquiryId}:read`,
        method: "PATCH",
      }),
    }),
    withdrawDocument: build.mutation<
      WithdrawDocumentApiResponse,
      WithdrawDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/documents/${queryArg.documentId}:withdraw`,
        method: "PATCH",
      }),
    }),
    updateReportOfficeMemberNotes: build.mutation<
      UpdateReportOfficeMemberNotesApiResponse,
      UpdateReportOfficeMemberNotesApiArg
    >({
      query: (queryArg) => ({
        url: `/documents/${queryArg.documentId}:update-report-office-member-notes`,
        method: "PATCH",
        body: queryArg.commonUpdateRequest,
      }),
    }),
    updateApplicationOfficeMemberNotes: build.mutation<
      UpdateApplicationOfficeMemberNotesApiResponse,
      UpdateApplicationOfficeMemberNotesApiArg
    >({
      query: (queryArg) => ({
        url: `/documents/${queryArg.documentId}:update-application-office-member-notes`,
        method: "PATCH",
        body: queryArg.commonUpdateRequest,
      }),
    }),
    completeOfficeMemberCheck: build.mutation<
      CompleteOfficeMemberCheckApiResponse,
      CompleteOfficeMemberCheckApiArg
    >({
      query: (queryArg) => ({
        url: `/documents/${queryArg.documentId}:complete-office-member-check`,
        method: "PATCH",
      }),
    }),
    abortDocument: build.mutation<
      AbortDocumentApiResponse,
      AbortDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/documents/${queryArg.documentId}:abort`,
        method: "PATCH",
      }),
    }),
    updateTerminationReportContent: build.mutation<
      UpdateTerminationReportContentApiResponse,
      UpdateTerminationReportContentApiArg
    >({
      query: (queryArg) => ({
        url: `/documents.termination-report/${queryArg.documentId}:update-content`,
        method: "PATCH",
        body: queryArg.commonUpdateRequest,
      }),
    }),
    receiveTerminationReport: build.mutation<
      ReceiveTerminationReportApiResponse,
      ReceiveTerminationReportApiArg
    >({
      query: (queryArg) => ({
        url: `/documents.termination-report/${queryArg.documentId}:receive`,
        method: "PATCH",
      }),
    }),
    updateNewApplicationOfficeMemberCheck: build.mutation<
      UpdateNewApplicationOfficeMemberCheckApiResponse,
      UpdateNewApplicationOfficeMemberCheckApiArg
    >({
      query: (queryArg) => ({
        url: `/documents.new-application/${queryArg.documentId}:update-office-member-check`,
        method: "PATCH",
        body: queryArg.commonUpdateRequest,
      }),
    }),
    updateNewApplicationContent: build.mutation<
      UpdateNewApplicationContentApiResponse,
      UpdateNewApplicationContentApiArg
    >({
      query: (queryArg) => ({
        url: `/documents.new-application/${queryArg.documentId}:update-content`,
        method: "PATCH",
        body: queryArg.commonUpdateRequest,
        params: { correctnessCheck: queryArg.correctnessCheck },
      }),
    }),
    submitNewApplicationFromDraft: build.mutation<
      SubmitNewApplicationFromDraftApiResponse,
      SubmitNewApplicationFromDraftApiArg
    >({
      query: (queryArg) => ({
        url: `/documents.new-application/${queryArg.documentId}:submit`,
        method: "PATCH",
      }),
    }),
    receiveNewApplication: build.mutation<
      ReceiveNewApplicationApiResponse,
      ReceiveNewApplicationApiArg
    >({
      query: (queryArg) => ({
        url: `/documents.new-application/${queryArg.documentId}:receive`,
        method: "PATCH",
      }),
    }),
    updateChangeApplicationOfficeMemberCheck: build.mutation<
      UpdateChangeApplicationOfficeMemberCheckApiResponse,
      UpdateChangeApplicationOfficeMemberCheckApiArg
    >({
      query: (queryArg) => ({
        url: `/documents.change-application/${queryArg.documentId}:update-office-member-check`,
        method: "PATCH",
        body: queryArg.commonUpdateRequest,
      }),
    }),
    updateChangeApplicationContent: build.mutation<
      UpdateChangeApplicationContentApiResponse,
      UpdateChangeApplicationContentApiArg
    >({
      query: (queryArg) => ({
        url: `/documents.change-application/${queryArg.documentId}:update-content`,
        method: "PATCH",
        body: queryArg.commonUpdateRequest,
        params: { correctnessCheck: queryArg.correctnessCheck },
      }),
    }),
    receiveChangeApplication: build.mutation<
      ReceiveChangeApplicationApiResponse,
      ReceiveChangeApplicationApiArg
    >({
      query: (queryArg) => ({
        url: `/documents.change-application/${queryArg.documentId}:receive`,
        method: "PATCH",
      }),
    }),
    updateAnnualReportContent: build.mutation<
      UpdateAnnualReportContentApiResponse,
      UpdateAnnualReportContentApiArg
    >({
      query: (queryArg) => ({
        url: `/documents.annual-report/${queryArg.documentId}:update-content`,
        method: "PATCH",
        body: queryArg.commonUpdateRequest,
      }),
    }),
    receiveAnnualReport: build.mutation<
      ReceiveAnnualReportApiResponse,
      ReceiveAnnualReportApiArg
    >({
      query: (queryArg) => ({
        url: `/documents.annual-report/${queryArg.documentId}:receive`,
        method: "PATCH",
      }),
    }),
    changeLanguage: build.mutation<
      ChangeLanguageApiResponse,
      ChangeLanguageApiArg
    >({
      query: (queryArg) => ({
        url: `/change-language`,
        method: "PATCH",
        body: queryArg.changeLanguageRequest,
      }),
    }),
    getCellStockDelivery: build.query<
      GetCellStockDeliveryApiResponse,
      GetCellStockDeliveryApiArg
    >({
      query: (queryArg) => ({
        url: `/cell-stock-deliveries/${queryArg.cellStockDeliveryId}`,
      }),
    }),
    deleteCellStockDelivery: build.mutation<
      DeleteCellStockDeliveryApiResponse,
      DeleteCellStockDeliveryApiArg
    >({
      query: (queryArg) => ({
        url: `/cell-stock-deliveries/${queryArg.cellStockDeliveryId}`,
        method: "DELETE",
      }),
    }),
    updateCellStockDelivery: build.mutation<
      UpdateCellStockDeliveryApiResponse,
      UpdateCellStockDeliveryApiArg
    >({
      query: (queryArg) => ({
        url: `/cell-stock-deliveries/${queryArg.cellStockDeliveryId}`,
        method: "PATCH",
        body: queryArg.commonUpdateRequest,
      }),
    }),
    getAppUser: build.query<GetAppUserApiResponse, GetAppUserApiArg>({
      query: (queryArg) => ({ url: `/app-users/${queryArg.appUserId}` }),
    }),
    updateAppUser: build.mutation<
      UpdateAppUserApiResponse,
      UpdateAppUserApiArg
    >({
      query: (queryArg) => ({
        url: `/app-users/${queryArg.appUserId}`,
        method: "PATCH",
        body: queryArg.commonUpdateRequest,
      }),
    }),
    enableAppUser: build.mutation<
      EnableAppUserApiResponse,
      EnableAppUserApiArg
    >({
      query: (queryArg) => ({
        url: `/app-users/${queryArg.appUserId}:enable`,
        method: "PATCH",
      }),
    }),
    disableAppUser: build.mutation<
      DisableAppUserApiResponse,
      DisableAppUserApiArg
    >({
      query: (queryArg) => ({
        url: `/app-users/${queryArg.appUserId}:disable`,
        method: "PATCH",
      }),
    }),
    searchWorkLog: build.query<SearchWorkLogApiResponse, SearchWorkLogApiArg>({
      query: (queryArg) => ({
        url: `/work-logs:search`,
        params: {
          sortCondition: queryArg.sortCondition,
          limit: queryArg.limit,
          workDateTimeFrom: queryArg.workDateTimeFrom,
          workDateTimeTo: queryArg.workDateTimeTo,
          workType: queryArg.workType,
          institutionId: queryArg.institutionId,
          appUserId: queryArg.appUserId,
          projectId: queryArg.projectId,
          documentId: queryArg.documentId,
          operationType: queryArg.operationType,
          pageNumber: queryArg.pageNumber,
        },
      }),
    }),
    getSystemEnv: build.query<GetSystemEnvApiResponse, GetSystemEnvApiArg>({
      query: () => ({ url: `/system-env` }),
    }),
    getPreviewLink: build.query<
      GetPreviewLinkApiResponse,
      GetPreviewLinkApiArg
    >({
      query: (queryArg) => ({
        url: `/storage-files/${queryArg.storageFileId}:preview`,
      }),
    }),
    downloadFile: build.query<DownloadFileApiResponse, DownloadFileApiArg>({
      query: (queryArg) => ({
        url: `/storage-files/${queryArg.storageFileId}:download`,
      }),
    }),
    getRevisionRequest: build.query<
      GetRevisionRequestApiResponse,
      GetRevisionRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/revision-requests/${queryArg.revisionRequestId}`,
      }),
    }),
    listReview: build.query<ListReviewApiResponse, ListReviewApiArg>({
      query: (queryArg) => ({
        url: `/reviews`,
        params: {
          documentId: queryArg.documentId,
          reviewType: queryArg.reviewType,
          reviewState: queryArg.reviewState,
          reviewConferenceId: queryArg.reviewConferenceId,
          reviewConferenceIdIsNull: queryArg.reviewConferenceIdIsNull,
          documentControlNumber: queryArg.documentControlNumber,
        },
      }),
    }),
    searchReviewConference: build.query<
      SearchReviewConferenceApiResponse,
      SearchReviewConferenceApiArg
    >({
      query: (queryArg) => ({
        url: `/review-conferences:search`,
        params: { limit: queryArg.limit, pageNumber: queryArg.pageNumber },
      }),
    }),
    getNumberOfReviewConferenceTodos: build.query<
      GetNumberOfReviewConferenceTodosApiResponse,
      GetNumberOfReviewConferenceTodosApiArg
    >({
      query: () => ({ url: `/review-conferences:number-of-todos` }),
    }),
    getResultNotification: build.query<
      GetResultNotificationApiResponse,
      GetResultNotificationApiArg
    >({
      query: (queryArg) => ({
        url: `/result-notifications/${queryArg.resultNotificationId}`,
      }),
    }),
    listProject: build.query<ListProjectApiResponse, ListProjectApiArg>({
      query: (queryArg) => ({
        url: `/projects`,
        params: {
          institutionId: queryArg.institutionId,
          projectControlNumber: queryArg.projectControlNumber,
        },
      }),
    }),
    searchProject: build.query<SearchProjectApiResponse, SearchProjectApiArg>({
      query: (queryArg) => ({
        url: `/projects:search`,
        params: {
          sortCondition: queryArg.sortCondition,
          limit: queryArg.limit,
          projectControlNumber: queryArg.projectControlNumber,
          projectName: queryArg.projectName,
          projectState: queryArg.projectState,
          newApplicationSubmissionDateFrom:
            queryArg.newApplicationSubmissionDateFrom,
          newApplicationSubmissionDateTo:
            queryArg.newApplicationSubmissionDateTo,
          newApplicationApprovalDateFrom:
            queryArg.newApplicationApprovalDateFrom,
          newApplicationApprovalDateTo: queryArg.newApplicationApprovalDateTo,
          usageEndDateFrom: queryArg.usageEndDateFrom,
          usageEndDateTo: queryArg.usageEndDateTo,
          nextAnnualReportDeadlineDateFrom:
            queryArg.nextAnnualReportDeadlineDateFrom,
          nextAnnualReportDeadlineDateTo:
            queryArg.nextAnnualReportDeadlineDateTo,
          terminationReportSubmitted: queryArg.terminationReportSubmitted,
          institutionId: queryArg.institutionId,
          foreignType: queryArg.foreignType,
          commercialType: queryArg.commercialType,
          principalInvestigatorAppUserId:
            queryArg.principalInvestigatorAppUserId,
          collaborativePartnerName: queryArg.collaborativePartnerName,
          collaborativePartnerForeignType:
            queryArg.collaborativePartnerForeignType,
          collaborativePartnerCommercialType:
            queryArg.collaborativePartnerCommercialType,
          requestingCellsCellId: queryArg.requestingCellsCellId,
          shippingCellsCellId: queryArg.shippingCellsCellId,
          shippingCellsDateFrom: queryArg.shippingCellsDateFrom,
          shippingCellsDateTo: queryArg.shippingCellsDateTo,
          pageNumber: queryArg.pageNumber,
        },
      }),
    }),
    createProjectCsv: build.query<
      CreateProjectCsvApiResponse,
      CreateProjectCsvApiArg
    >({
      query: (queryArg) => ({
        url: `/projects:create-csv`,
        params: {
          sortCondition: queryArg.sortCondition,
          projectControlNumber: queryArg.projectControlNumber,
          projectName: queryArg.projectName,
          projectState: queryArg.projectState,
          newApplicationSubmissionDateFrom:
            queryArg.newApplicationSubmissionDateFrom,
          newApplicationSubmissionDateTo:
            queryArg.newApplicationSubmissionDateTo,
          newApplicationApprovalDateFrom:
            queryArg.newApplicationApprovalDateFrom,
          newApplicationApprovalDateTo: queryArg.newApplicationApprovalDateTo,
          usageEndDateFrom: queryArg.usageEndDateFrom,
          usageEndDateTo: queryArg.usageEndDateTo,
          nextAnnualReportDeadlineDateFrom:
            queryArg.nextAnnualReportDeadlineDateFrom,
          nextAnnualReportDeadlineDateTo:
            queryArg.nextAnnualReportDeadlineDateTo,
          terminationReportSubmitted: queryArg.terminationReportSubmitted,
          institutionId: queryArg.institutionId,
          foreignType: queryArg.foreignType,
          commercialType: queryArg.commercialType,
          principalInvestigatorAppUserId:
            queryArg.principalInvestigatorAppUserId,
          collaborativePartnerName: queryArg.collaborativePartnerName,
          collaborativePartnerForeignType:
            queryArg.collaborativePartnerForeignType,
          collaborativePartnerCommercialType:
            queryArg.collaborativePartnerCommercialType,
          requestingCellsCellId: queryArg.requestingCellsCellId,
          shippingCellsCellId: queryArg.shippingCellsCellId,
          shippingCellsDateFrom: queryArg.shippingCellsDateFrom,
          shippingCellsDateTo: queryArg.shippingCellsDateTo,
        },
      }),
    }),
    getProject: build.query<GetProjectApiResponse, GetProjectApiArg>({
      query: (queryArg) => ({ url: `/projects/${queryArg.projectId}` }),
    }),
    getProjectSnapshot: build.query<
      GetProjectSnapshotApiResponse,
      GetProjectSnapshotApiArg
    >({
      query: (queryArg) => ({
        url: `/project-snapshots/${queryArg.projectSnapshotId}`,
      }),
    }),
    getMessages: build.query<GetMessagesApiResponse, GetMessagesApiArg>({
      query: () => ({ url: `/messages` }),
    }),
    searchMailLog: build.query<SearchMailLogApiResponse, SearchMailLogApiArg>({
      query: (queryArg) => ({
        url: `/mail-logs:search`,
        params: {
          sortCondition: queryArg.sortCondition,
          limit: queryArg.limit,
          sendDateTimeFrom: queryArg.sendDateTimeFrom,
          sendDateTimeTo: queryArg.sendDateTimeTo,
          recipientRole: queryArg.recipientRole,
          institutionId: queryArg.institutionId,
          projectId: queryArg.projectId,
          documentId: queryArg.documentId,
          operationType: queryArg.operationType,
          subject: queryArg.subject,
          appUserId: queryArg.appUserId,
          mailAddress: queryArg.mailAddress,
          pageNumber: queryArg.pageNumber,
        },
      }),
    }),
    searchInstitution: build.query<
      SearchInstitutionApiResponse,
      SearchInstitutionApiArg
    >({
      query: (queryArg) => ({
        url: `/institutions:search`,
        params: {
          sortCondition: queryArg.sortCondition,
          limit: queryArg.limit,
          displayInstitutionId: queryArg.displayInstitutionId,
          institutionName: queryArg.institutionName,
          foreignType: queryArg.foreignType,
          commercialType: queryArg.commercialType,
          pageNumber: queryArg.pageNumber,
        },
      }),
    }),
    createInstitutionCsv: build.query<
      CreateInstitutionCsvApiResponse,
      CreateInstitutionCsvApiArg
    >({
      query: (queryArg) => ({
        url: `/institutions:create-csv`,
        params: {
          sortCondition: queryArg.sortCondition,
          displayInstitutionId: queryArg.displayInstitutionId,
          institutionName: queryArg.institutionName,
          foreignType: queryArg.foreignType,
          commercialType: queryArg.commercialType,
        },
      }),
    }),
    searchInquiry: build.query<SearchInquiryApiResponse, SearchInquiryApiArg>({
      query: (queryArg) => ({
        url: `/inquiries:search`,
        params: {
          todosFirst: queryArg.todosFirst,
          sortCondition: queryArg.sortCondition,
          limit: queryArg.limit,
          institutionId: queryArg.institutionId,
          projectId: queryArg.projectId,
          documentId: queryArg.documentId,
          pageNumber: queryArg.pageNumber,
        },
      }),
    }),
    getNumberOfInquiryTodos: build.query<
      GetNumberOfInquiryTodosApiResponse,
      GetNumberOfInquiryTodosApiArg
    >({
      query: () => ({ url: `/inquiries:number-of-todos` }),
    }),
    health: build.query<HealthApiResponse, HealthApiArg>({
      query: () => ({ url: `/health` }),
    }),
    listDocument: build.query<ListDocumentApiResponse, ListDocumentApiArg>({
      query: (queryArg) => ({
        url: `/documents`,
        params: {
          projectId: queryArg.projectId,
          documentType: queryArg.documentType,
          documentControlNumber: queryArg.documentControlNumber,
          documentState: queryArg.documentState,
          documentPosition: queryArg.documentPosition,
        },
      }),
    }),
    searchDocument: build.query<
      SearchDocumentApiResponse,
      SearchDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/documents:search`,
        params: {
          todosFirst: queryArg.todosFirst,
          sortCondition: queryArg.sortCondition,
          limit: queryArg.limit,
          documentControlNumber: queryArg.documentControlNumber,
          documentType: queryArg.documentType,
          documentState: queryArg.documentState,
          documentPosition: queryArg.documentPosition,
          reviewType: queryArg.reviewType,
          result: queryArg.result,
          submissionDateFrom: queryArg.submissionDateFrom,
          submissionDateTo: queryArg.submissionDateTo,
          receptionDateFrom: queryArg.receptionDateFrom,
          receptionDateTo: queryArg.receptionDateTo,
          approvalDateFrom: queryArg.approvalDateFrom,
          approvalDateTo: queryArg.approvalDateTo,
          completionDateFrom: queryArg.completionDateFrom,
          completionDateTo: queryArg.completionDateTo,
          projectId: queryArg.projectId,
          projectName: queryArg.projectName,
          institutionId: queryArg.institutionId,
          principalInvestigatorAppUserId:
            queryArg.principalInvestigatorAppUserId,
          pageNumber: queryArg.pageNumber,
        },
      }),
    }),
    getNumberOfDocumentTodos: build.query<
      GetNumberOfDocumentTodosApiResponse,
      GetNumberOfDocumentTodosApiArg
    >({
      query: () => ({ url: `/documents:number-of-todos` }),
    }),
    getDocument: build.query<GetDocumentApiResponse, GetDocumentApiArg>({
      query: (queryArg) => ({ url: `/documents/${queryArg.documentId}` }),
    }),
    deleteDocumentDraft: build.mutation<
      DeleteDocumentDraftApiResponse,
      DeleteDocumentDraftApiArg
    >({
      query: (queryArg) => ({
        url: `/documents/${queryArg.documentId}`,
        method: "DELETE",
      }),
    }),
    documentHasUnheldReviewConference: build.query<
      DocumentHasUnheldReviewConferenceApiResponse,
      DocumentHasUnheldReviewConferenceApiArg
    >({
      query: (queryArg) => ({
        url: `/documents/${queryArg.documentId}:has-unheld-review-conference`,
      }),
    }),
    getTerminationReport: build.query<
      GetTerminationReportApiResponse,
      GetTerminationReportApiArg
    >({
      query: (queryArg) => ({
        url: `/documents.termination-report/${queryArg.documentId}`,
      }),
    }),
    getNewApplication: build.query<
      GetNewApplicationApiResponse,
      GetNewApplicationApiArg
    >({
      query: (queryArg) => ({
        url: `/documents.new-application/${queryArg.documentId}`,
      }),
    }),
    getChangeApplication: build.query<
      GetChangeApplicationApiResponse,
      GetChangeApplicationApiArg
    >({
      query: (queryArg) => ({
        url: `/documents.change-application/${queryArg.documentId}`,
      }),
    }),
    getAnnualReport: build.query<
      GetAnnualReportApiResponse,
      GetAnnualReportApiArg
    >({
      query: (queryArg) => ({
        url: `/documents.annual-report/${queryArg.documentId}`,
      }),
    }),
    getDocumentSnapshot: build.query<
      GetDocumentSnapshotApiResponse,
      GetDocumentSnapshotApiArg
    >({
      query: (queryArg) => ({
        url: `/document-snapshots/${queryArg.documentSnapshotId}`,
      }),
    }),
    getCurrentDatetime: build.query<
      GetCurrentDatetimeApiResponse,
      GetCurrentDatetimeApiArg
    >({
      query: () => ({ url: `/current-datetime` }),
    }),
    getCurrentAppUser: build.query<
      GetCurrentAppUserApiResponse,
      GetCurrentAppUserApiArg
    >({
      query: () => ({ url: `/current-app-user` }),
    }),
    listCellStock: build.query<ListCellStockApiResponse, ListCellStockApiArg>({
      query: (queryArg) => ({
        url: `/cell-stocks`,
        params: {
          cellType: queryArg.cellType,
          cellStockCategoryId: queryArg.cellStockCategoryId,
        },
      }),
    }),
    listCellStockCategory: build.query<
      ListCellStockCategoryApiResponse,
      ListCellStockCategoryApiArg
    >({
      query: (queryArg) => ({
        url: `/cell-stock-categories`,
        params: { cellType: queryArg.cellType },
      }),
    }),
    searchAppUser: build.query<SearchAppUserApiResponse, SearchAppUserApiArg>({
      query: (queryArg) => ({
        url: `/app-users:search`,
        params: {
          sortCondition: queryArg.sortCondition,
          limit: queryArg.limit,
          role: queryArg.role,
          displayUserId: queryArg.displayUserId,
          fullName: queryArg.fullName,
          institutionId: queryArg.institutionId,
          titleAndPosition: queryArg.titleAndPosition,
          accountState: queryArg.accountState,
          pageNumber: queryArg.pageNumber,
        },
      }),
    }),
    countPastProject: build.query<
      CountPastProjectApiResponse,
      CountPastProjectApiArg
    >({
      query: (queryArg) => ({
        url: `/app-users/${queryArg.appUserId}:count-past-project`,
      }),
    }),
    deleteSharedAttachmentFile: build.mutation<
      DeleteSharedAttachmentFileApiResponse,
      DeleteSharedAttachmentFileApiArg
    >({
      query: (queryArg) => ({
        url: `/shared-attachment-files/${queryArg.sharedAttachmentFileId}`,
        method: "DELETE",
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as injectedRtkApi };
export type UploadFileApiResponse = /** status 200 OK */ StorageFile;
export type UploadFileApiArg = {
  filePrivacyType: "private" | "public";
  institutionId?: number;
  projectId?: number;
  body: {
    file: Blob;
  };
};
export type ListSharedAttachmentFileApiResponse =
  /** status 200 OK */ SharedAttachmentFile[];
export type ListSharedAttachmentFileApiArg = void;
export type CreateSharedAttachmentFileApiResponse =
  /** status 200 OK */ SharedAttachmentFile;
export type CreateSharedAttachmentFileApiArg = {
  sharedAttachmentFile: SharedAttachmentFile;
};
export type ListRevisionRequestApiResponse =
  /** status 200 OK */ RevisionRequest[];
export type ListRevisionRequestApiArg = {
  documentId: number;
  revisionRequestState?: "revising" | "revised";
};
export type CreateRevisionRequestApiResponse =
  /** status 200 OK */ RevisionRequest;
export type CreateRevisionRequestApiArg = {
  revisionRequest: RevisionRequest;
};
export type CreateReviewConferenceApiResponse =
  /** status 200 OK */ ReviewConference;
export type CreateReviewConferenceApiArg = {
  reviewConference: ReviewConference;
};
export type NotifyReviewConferenceApiResponse =
  /** status 200 OK */ ReviewConference;
export type NotifyReviewConferenceApiArg = {
  reviewConferenceId: number;
};
export type ConcludeReviewConferenceApiResponse =
  /** status 200 OK */ ReviewConference;
export type ConcludeReviewConferenceApiArg = {
  reviewConferenceId: number;
};
export type ListResultNotificationApiResponse =
  /** status 200 OK */ ResultNotification[];
export type ListResultNotificationApiArg = {
  documentId: number;
};
export type CreateResultNotificationApiResponse =
  /** status 200 OK */ ResultNotification;
export type CreateResultNotificationApiArg = {
  resultNotification: ResultNotification;
};
export type ForceCreateProjectApiResponse = /** status 200 OK */ Project;
export type ForceCreateProjectApiArg = {
  project: Project;
};
export type ListInstitutionApiResponse = /** status 200 OK */ Institution[];
export type ListInstitutionApiArg = {
  institutionName?: string;
};
export type CreateInstitutionApiResponse = /** status 200 OK */ Institution;
export type CreateInstitutionApiArg = {
  institution: Institution;
};
export type CreateInquiryApiResponse = /** status 200 OK */ Inquiry;
export type CreateInquiryApiArg = {
  inquiry: Inquiry;
};
export type CreateTerminationReportDraftApiResponse =
  /** status 200 OK */ TerminationReport;
export type CreateTerminationReportDraftApiArg = {
  projectId: number;
  terminationReportContent: TerminationReportContent;
};
export type SubmitTerminationReportFromDraftApiResponse =
  /** status 200 OK */ TerminationReport;
export type SubmitTerminationReportFromDraftApiArg = {
  documentId: number;
};
export type CreateNewApplicationDraftApiResponse =
  /** status 200 OK */ NewApplication;
export type CreateNewApplicationDraftApiArg = {
  correctnessCheck: "unchecked" | "checked";
  applicationContent: ApplicationContent;
};
export type CreateChangeApplicationDraftApiResponse =
  /** status 200 OK */ ChangeApplication;
export type CreateChangeApplicationDraftApiArg = {
  projectId: number;
  correctnessCheck: "unchecked" | "checked";
  changeApplicationContent: ChangeApplicationContent;
};
export type SubmitChangeApplicationFromDraftApiResponse =
  /** status 200 OK */ ChangeApplication;
export type SubmitChangeApplicationFromDraftApiArg = {
  documentId: number;
};
export type CreateAnnualReportDraftApiResponse =
  /** status 200 OK */ AnnualReport;
export type CreateAnnualReportDraftApiArg = {
  projectId: number;
  annualReportContent: AnnualReportContent;
};
export type SubmitAnnualReportFromDraftApiResponse =
  /** status 200 OK */ AnnualReport;
export type SubmitAnnualReportFromDraftApiArg = {
  documentId: number;
};
export type ListCellStockDeliveryApiResponse =
  /** status 200 OK */ CellStockDelivery[];
export type ListCellStockDeliveryApiArg = {
  projectId?: number;
};
export type CreateCellStockDeliveryApiResponse =
  /** status 200 OK */ CellStockDelivery;
export type CreateCellStockDeliveryApiArg = {
  cellStockDelivery: CellStockDelivery;
};
export type ApplyConfigApiResponse = /** status 200 OK */ string;
export type ApplyConfigApiArg = void;
export type ListAppUserApiResponse = /** status 200 OK */ AppUser[];
export type ListAppUserApiArg = {
  institutionId?: number;
  fullName?: string;
};
export type CreateAppUserApiResponse = /** status 200 OK */ AppUser;
export type CreateAppUserApiArg = {
  appUser: AppUser;
};
export type ResetTemporaryPasswordApiResponse = /** status 200 OK */ AppUser;
export type ResetTemporaryPasswordApiArg = {
  appUserId: number;
};
export type ResetMfaApiResponse = /** status 200 OK */ AppUser;
export type ResetMfaApiArg = {
  appUserId: number;
};
export type SubmitRevisionRequestReplyApiResponse =
  /** status 200 OK */ RevisionRequest;
export type SubmitRevisionRequestReplyApiArg = {
  revisionRequestId: number;
};
export type SaveRevisionRequestReplyApiResponse =
  /** status 200 OK */ RevisionRequest;
export type SaveRevisionRequestReplyApiArg = {
  revisionRequestId: number;
  commonUpdateRequest: CommonUpdateRequest;
};
export type GetReviewApiResponse = /** status 200 OK */ Review;
export type GetReviewApiArg = {
  reviewId: number;
};
export type UpdateReviewApiResponse = /** status 200 OK */ Review;
export type UpdateReviewApiArg = {
  reviewId: number;
  commonUpdateRequest: CommonUpdateRequest;
};
export type ConcludeReviewApiResponse = /** status 200 OK */ Review;
export type ConcludeReviewApiArg = {
  reviewId: number;
};
export type ApproveReviewApiResponse = /** status 200 OK */ Review;
export type ApproveReviewApiArg = {
  reviewId: number;
};
export type GetReviewConferenceApiResponse =
  /** status 200 OK */ ReviewConference;
export type GetReviewConferenceApiArg = {
  reviewConferenceId: number;
};
export type DeleteReviewConferenceApiResponse = /** status 200 OK */ string;
export type DeleteReviewConferenceApiArg = {
  reviewConferenceId: number;
};
export type UpdateReviewConferenceApiResponse =
  /** status 200 OK */ ReviewConference;
export type UpdateReviewConferenceApiArg = {
  reviewConferenceId: number;
  commonUpdateRequest: CommonUpdateRequest;
};
export type UpdateDisclosureDocumentApiResponse = /** status 200 OK */ Project;
export type UpdateDisclosureDocumentApiArg = {
  projectId: number;
  commonUpdateRequest: CommonUpdateRequest;
};
export type ForceUpdateProjectApiResponse = /** status 200 OK */ Project;
export type ForceUpdateProjectApiArg = {
  projectId: number;
  commonUpdateRequest: CommonUpdateRequest;
};
export type AbortProjectApiResponse = /** status 200 OK */ Project;
export type AbortProjectApiArg = {
  projectId: number;
};
export type GetInstitutionApiResponse = /** status 200 OK */ Institution;
export type GetInstitutionApiArg = {
  institutionId: number;
};
export type UpdateInstitutionApiResponse = /** status 200 OK */ Institution;
export type UpdateInstitutionApiArg = {
  institutionId: number;
  commonUpdateRequest: CommonUpdateRequest;
};
export type RespondInquiryApiResponse = /** status 200 OK */ Inquiry;
export type RespondInquiryApiArg = {
  inquiryId: number;
  inquiryReply: InquiryReply;
};
export type ReadInquiryApiResponse = /** status 200 OK */ Inquiry;
export type ReadInquiryApiArg = {
  inquiryId: number;
};
export type WithdrawDocumentApiResponse = /** status 200 OK */ Document;
export type WithdrawDocumentApiArg = {
  documentId: number;
};
export type UpdateReportOfficeMemberNotesApiResponse =
  /** status 200 OK */ Document;
export type UpdateReportOfficeMemberNotesApiArg = {
  documentId: number;
  commonUpdateRequest: CommonUpdateRequest;
};
export type UpdateApplicationOfficeMemberNotesApiResponse =
  /** status 200 OK */ Document;
export type UpdateApplicationOfficeMemberNotesApiArg = {
  documentId: number;
  commonUpdateRequest: CommonUpdateRequest;
};
export type CompleteOfficeMemberCheckApiResponse =
  /** status 200 OK */ Document;
export type CompleteOfficeMemberCheckApiArg = {
  documentId: number;
};
export type AbortDocumentApiResponse = /** status 200 OK */ Document;
export type AbortDocumentApiArg = {
  documentId: number;
};
export type UpdateTerminationReportContentApiResponse =
  /** status 200 OK */ TerminationReport;
export type UpdateTerminationReportContentApiArg = {
  documentId: number;
  commonUpdateRequest: CommonUpdateRequest;
};
export type ReceiveTerminationReportApiResponse =
  /** status 200 OK */ TerminationReport;
export type ReceiveTerminationReportApiArg = {
  documentId: number;
};
export type UpdateNewApplicationOfficeMemberCheckApiResponse =
  /** status 200 OK */ NewApplication;
export type UpdateNewApplicationOfficeMemberCheckApiArg = {
  documentId: number;
  commonUpdateRequest: CommonUpdateRequest;
};
export type UpdateNewApplicationContentApiResponse =
  /** status 200 OK */ NewApplication;
export type UpdateNewApplicationContentApiArg = {
  documentId: number;
  correctnessCheck: "unchecked" | "checked";
  commonUpdateRequest: CommonUpdateRequest;
};
export type SubmitNewApplicationFromDraftApiResponse =
  /** status 200 OK */ NewApplication;
export type SubmitNewApplicationFromDraftApiArg = {
  documentId: number;
};
export type ReceiveNewApplicationApiResponse =
  /** status 200 OK */ NewApplication;
export type ReceiveNewApplicationApiArg = {
  documentId: number;
};
export type UpdateChangeApplicationOfficeMemberCheckApiResponse =
  /** status 200 OK */ ChangeApplication;
export type UpdateChangeApplicationOfficeMemberCheckApiArg = {
  documentId: number;
  commonUpdateRequest: CommonUpdateRequest;
};
export type UpdateChangeApplicationContentApiResponse =
  /** status 200 OK */ ChangeApplication;
export type UpdateChangeApplicationContentApiArg = {
  documentId: number;
  correctnessCheck: "unchecked" | "checked";
  commonUpdateRequest: CommonUpdateRequest;
};
export type ReceiveChangeApplicationApiResponse =
  /** status 200 OK */ ChangeApplication;
export type ReceiveChangeApplicationApiArg = {
  documentId: number;
};
export type UpdateAnnualReportContentApiResponse =
  /** status 200 OK */ AnnualReport;
export type UpdateAnnualReportContentApiArg = {
  documentId: number;
  commonUpdateRequest: CommonUpdateRequest;
};
export type ReceiveAnnualReportApiResponse = /** status 200 OK */ AnnualReport;
export type ReceiveAnnualReportApiArg = {
  documentId: number;
};
export type ChangeLanguageApiResponse = /** status 200 OK */ string;
export type ChangeLanguageApiArg = {
  changeLanguageRequest: ChangeLanguageRequest;
};
export type GetCellStockDeliveryApiResponse =
  /** status 200 OK */ CellStockDelivery;
export type GetCellStockDeliveryApiArg = {
  cellStockDeliveryId: number;
};
export type DeleteCellStockDeliveryApiResponse = /** status 200 OK */ string;
export type DeleteCellStockDeliveryApiArg = {
  cellStockDeliveryId: number;
};
export type UpdateCellStockDeliveryApiResponse =
  /** status 200 OK */ CellStockDelivery;
export type UpdateCellStockDeliveryApiArg = {
  cellStockDeliveryId: number;
  commonUpdateRequest: CommonUpdateRequest;
};
export type GetAppUserApiResponse = /** status 200 OK */ AppUser;
export type GetAppUserApiArg = {
  appUserId: number;
};
export type UpdateAppUserApiResponse = /** status 200 OK */ AppUser;
export type UpdateAppUserApiArg = {
  appUserId: number;
  commonUpdateRequest: CommonUpdateRequest;
};
export type EnableAppUserApiResponse = /** status 200 OK */ AppUser;
export type EnableAppUserApiArg = {
  appUserId: number;
};
export type DisableAppUserApiResponse = /** status 200 OK */ AppUser;
export type DisableAppUserApiArg = {
  appUserId: number;
};
export type SearchWorkLogApiResponse = /** status 200 OK */ SearchResultWorkLog;
export type SearchWorkLogApiArg = {
  sortCondition?: "datetime_asc" | "datetime_desc";
  limit?: number;
  workDateTimeFrom?: string;
  workDateTimeTo?: string;
  workType?: ("create" | "update" | "delete" | "other")[];
  institutionId?: number;
  appUserId?: number;
  projectId?: number;
  documentId?: number;
  operationType?:
    | "GetCurrentUser"
    | "ChangeLanguage"
    | "ListSharedAttachmentFile"
    | "DownloadFile"
    | "UploadFile"
    | "CreateSharedAttachmentFile"
    | "DeleteSharedAttachmentFile"
    | "ListProject"
    | "GetProject"
    | "UpdateDisclosureDocument"
    | "CreateCellStockDelivery"
    | "ListCellStockDelivery"
    | "GetCellStockDelivery"
    | "UpdateCellStockDelivery"
    | "DeleteCellStockDelivery"
    | "ForceCreateProject"
    | "ForceUpdateProject"
    | "ListDocument"
    | "GetNumberOfDocumentTodos"
    | "GetNewApplication"
    | "GetChangeApplication"
    | "GetAnnualReport"
    | "GetTerminationReport"
    | "ListRevisionRequest"
    | "GetRevisionRequest"
    | "RemindAnnualReport"
    | "RemindTerminationReport"
    | "GetInstitution"
    | "GetUser"
    | "CreateNewApplicationDraft"
    | "CreateChangeApplicationDraft"
    | "CreateAnnualReportDraft"
    | "CreateTerminationReportDraft"
    | "DeleteDocumentDraft"
    | "SubmitNewApplication"
    | "SubmitChangeApplication"
    | "SubmitAnnualReport"
    | "SubmitTerminationReport"
    | "UpdateNewApplicationContent"
    | "UpdateChangeApplicationContent"
    | "UpdateAnnualReportContent"
    | "UpdateTerminationReportContent"
    | "ReceiveNewApplication"
    | "ReceiveChangeApplication"
    | "ReceiveAnnualReport"
    | "ReceiveTerminationReport"
    | "UpdateNewApplicationOfficeMemberCheck"
    | "UpdateChangeApplicationOfficeMemberCheck"
    | "CompleteOfficeMemberCheck"
    | "CreateRevisionRequest"
    | "SaveRevisionRequestReply"
    | "SubmitRevisionRequestReply"
    | "ListReview"
    | "GetReview"
    | "UpdateReview"
    | "ConcludeReview"
    | "ApproveReview"
    | "ListResultNotification"
    | "GetResultNotification"
    | "CreateResultNotification"
    | "UpdateApplicationOfficeMemberNotes"
    | "UpdateReportOfficeMemberNotes"
    | "ListReviewConference"
    | "GetReviewConference"
    | "NotifyReviewConference"
    | "UpdateReviewConference"
    | "CreateReviewConference"
    | "DeleteReviewConference"
    | "ConcludeReviewConference"
    | "ListInquiry"
    | "GetNumberOfInquiryTodos"
    | "RespondInquiry"
    | "ReadInquiry"
    | "CreateInquiry"
    | "ListWorkLog"
    | "ListMailLog"
    | "ListInstitution"
    | "CreateInstitution"
    | "UpdateInstitution"
    | "ListAppUser"
    | "CreateAppUser"
    | "UpdateAppUser"
    | "EnableAppUser"
    | "DisableAppUser"
    | "ResetTemporaryPassword"
    | "ResetMFA"
    | "ListCellStockCategory"
    | "ListCellStock"
    | "AbortProject"
    | "AbortDocument"
    | "WithdrawDocument";
  pageNumber?: number;
};
export type GetSystemEnvApiResponse = /** status 200 OK */ SystemEnvResponse;
export type GetSystemEnvApiArg = void;
export type GetPreviewLinkApiResponse = /** status 200 OK */ string;
export type GetPreviewLinkApiArg = {
  storageFileId: number;
};
export type DownloadFileApiResponse = /** status 200 OK */ string;
export type DownloadFileApiArg = {
  storageFileId: number;
};
export type GetRevisionRequestApiResponse =
  /** status 200 OK */ RevisionRequest;
export type GetRevisionRequestApiArg = {
  revisionRequestId: number;
};
export type ListReviewApiResponse = /** status 200 OK */ Review[];
export type ListReviewApiArg = {
  documentId?: number;
  reviewType?:
    | "unselected"
    | "office_review"
    | "committee_review"
    | "no_need_to_review";
  reviewState?: "unheld" | "held";
  reviewConferenceId?: number;
  reviewConferenceIdIsNull?: boolean;
  documentControlNumber?: string;
};
export type SearchReviewConferenceApiResponse =
  /** status 200 OK */ SearchResultReviewConferenceSearchResultRow;
export type SearchReviewConferenceApiArg = {
  limit?: number;
  pageNumber?: number;
};
export type GetNumberOfReviewConferenceTodosApiResponse =
  /** status 200 OK */ number;
export type GetNumberOfReviewConferenceTodosApiArg = void;
export type GetResultNotificationApiResponse =
  /** status 200 OK */ ResultNotification;
export type GetResultNotificationApiArg = {
  resultNotificationId: number;
};
export type ListProjectApiResponse = /** status 200 OK */ Project[];
export type ListProjectApiArg = {
  institutionId?: number;
  projectControlNumber?: string;
};
export type SearchProjectApiResponse = /** status 200 OK */ SearchResultProject;
export type SearchProjectApiArg = {
  sortCondition?:
    | "updated_at_asc"
    | "updated_at_desc"
    | "project_control_number_asc"
    | "project_control_number_desc";
  limit?: number;
  projectControlNumber?: string;
  projectName?: string;
  projectState?: (
    | "draft"
    | "applied"
    | "ongoing"
    | "terminated"
    | "invalid"
    | "withdrawn"
    | "aborted"
  )[];
  newApplicationSubmissionDateFrom?: string;
  newApplicationSubmissionDateTo?: string;
  newApplicationApprovalDateFrom?: string;
  newApplicationApprovalDateTo?: string;
  usageEndDateFrom?: string;
  usageEndDateTo?: string;
  nextAnnualReportDeadlineDateFrom?: string;
  nextAnnualReportDeadlineDateTo?: string;
  terminationReportSubmitted?: ("not_submitted" | "submitted")[];
  institutionId?: number;
  foreignType?: ("domestic" | "foreign")[];
  commercialType?: ("commercial" | "noncommercial")[];
  principalInvestigatorAppUserId?: number;
  collaborativePartnerName?: string;
  collaborativePartnerForeignType?: ("domestic" | "foreign")[];
  collaborativePartnerCommercialType?: ("commercial" | "noncommercial")[];
  requestingCellsCellId?: number;
  shippingCellsCellId?: number;
  shippingCellsDateFrom?: string;
  shippingCellsDateTo?: string;
  pageNumber?: number;
};
export type CreateProjectCsvApiResponse = /** status 200 OK */ CsvRespBody;
export type CreateProjectCsvApiArg = {
  sortCondition?:
    | "updated_at_asc"
    | "updated_at_desc"
    | "project_control_number_asc"
    | "project_control_number_desc";
  projectControlNumber?: string;
  projectName?: string;
  projectState?: (
    | "draft"
    | "applied"
    | "ongoing"
    | "terminated"
    | "invalid"
    | "withdrawn"
    | "aborted"
  )[];
  newApplicationSubmissionDateFrom?: string;
  newApplicationSubmissionDateTo?: string;
  newApplicationApprovalDateFrom?: string;
  newApplicationApprovalDateTo?: string;
  usageEndDateFrom?: string;
  usageEndDateTo?: string;
  nextAnnualReportDeadlineDateFrom?: string;
  nextAnnualReportDeadlineDateTo?: string;
  terminationReportSubmitted?: ("not_submitted" | "submitted")[];
  institutionId?: number;
  foreignType?: ("domestic" | "foreign")[];
  commercialType?: ("commercial" | "noncommercial")[];
  principalInvestigatorAppUserId?: number;
  collaborativePartnerName?: string;
  collaborativePartnerForeignType?: ("domestic" | "foreign")[];
  collaborativePartnerCommercialType?: ("commercial" | "noncommercial")[];
  requestingCellsCellId?: number;
  shippingCellsCellId?: number;
  shippingCellsDateFrom?: string;
  shippingCellsDateTo?: string;
};
export type GetProjectApiResponse = /** status 200 OK */ Project;
export type GetProjectApiArg = {
  projectId: number;
};
export type GetProjectSnapshotApiResponse =
  /** status 200 OK */ ProjectSnapshot;
export type GetProjectSnapshotApiArg = {
  projectSnapshotId: number;
};
export type GetMessagesApiResponse = /** status 200 OK */ MessageConfigObject;
export type GetMessagesApiArg = void;
export type SearchMailLogApiResponse = /** status 200 OK */ SearchResultMailLog;
export type SearchMailLogApiArg = {
  sortCondition?: "sent_at_asc" | "sent_at_desc";
  limit?: number;
  sendDateTimeFrom?: string;
  sendDateTimeTo?: string;
  recipientRole?: (
    | "applicant"
    | "office_member"
    | "committee_member"
    | "executive_director"
    | "internal"
  )[];
  institutionId?: number;
  projectId?: number;
  documentId?: number;
  operationType?:
    | "GetCurrentUser"
    | "ChangeLanguage"
    | "ListSharedAttachmentFile"
    | "DownloadFile"
    | "UploadFile"
    | "CreateSharedAttachmentFile"
    | "DeleteSharedAttachmentFile"
    | "ListProject"
    | "GetProject"
    | "UpdateDisclosureDocument"
    | "CreateCellStockDelivery"
    | "ListCellStockDelivery"
    | "GetCellStockDelivery"
    | "UpdateCellStockDelivery"
    | "DeleteCellStockDelivery"
    | "ForceCreateProject"
    | "ForceUpdateProject"
    | "ListDocument"
    | "GetNumberOfDocumentTodos"
    | "GetNewApplication"
    | "GetChangeApplication"
    | "GetAnnualReport"
    | "GetTerminationReport"
    | "ListRevisionRequest"
    | "GetRevisionRequest"
    | "RemindAnnualReport"
    | "RemindTerminationReport"
    | "GetInstitution"
    | "GetUser"
    | "CreateNewApplicationDraft"
    | "CreateChangeApplicationDraft"
    | "CreateAnnualReportDraft"
    | "CreateTerminationReportDraft"
    | "DeleteDocumentDraft"
    | "SubmitNewApplication"
    | "SubmitChangeApplication"
    | "SubmitAnnualReport"
    | "SubmitTerminationReport"
    | "UpdateNewApplicationContent"
    | "UpdateChangeApplicationContent"
    | "UpdateAnnualReportContent"
    | "UpdateTerminationReportContent"
    | "ReceiveNewApplication"
    | "ReceiveChangeApplication"
    | "ReceiveAnnualReport"
    | "ReceiveTerminationReport"
    | "UpdateNewApplicationOfficeMemberCheck"
    | "UpdateChangeApplicationOfficeMemberCheck"
    | "CompleteOfficeMemberCheck"
    | "CreateRevisionRequest"
    | "SaveRevisionRequestReply"
    | "SubmitRevisionRequestReply"
    | "ListReview"
    | "GetReview"
    | "UpdateReview"
    | "ConcludeReview"
    | "ApproveReview"
    | "ListResultNotification"
    | "GetResultNotification"
    | "CreateResultNotification"
    | "UpdateApplicationOfficeMemberNotes"
    | "UpdateReportOfficeMemberNotes"
    | "ListReviewConference"
    | "GetReviewConference"
    | "NotifyReviewConference"
    | "UpdateReviewConference"
    | "CreateReviewConference"
    | "DeleteReviewConference"
    | "ConcludeReviewConference"
    | "ListInquiry"
    | "GetNumberOfInquiryTodos"
    | "RespondInquiry"
    | "ReadInquiry"
    | "CreateInquiry"
    | "ListWorkLog"
    | "ListMailLog"
    | "ListInstitution"
    | "CreateInstitution"
    | "UpdateInstitution"
    | "ListAppUser"
    | "CreateAppUser"
    | "UpdateAppUser"
    | "EnableAppUser"
    | "DisableAppUser"
    | "ResetTemporaryPassword"
    | "ResetMFA"
    | "ListCellStockCategory"
    | "ListCellStock"
    | "AbortProject"
    | "AbortDocument"
    | "WithdrawDocument";
  subject?: string;
  appUserId?: number;
  mailAddress?: string;
  pageNumber?: number;
};
export type SearchInstitutionApiResponse =
  /** status 200 OK */ SearchResultInstitution;
export type SearchInstitutionApiArg = {
  sortCondition?:
    | "institution_id_asc"
    | "institution_id_desc"
    | "updated_at_asc"
    | "updated_at_desc";
  limit?: number;
  displayInstitutionId?: string;
  institutionName?: string;
  foreignType?: ("domestic" | "foreign")[];
  commercialType?: ("commercial" | "noncommercial")[];
  pageNumber?: number;
};
export type CreateInstitutionCsvApiResponse = /** status 200 OK */ CsvRespBody;
export type CreateInstitutionCsvApiArg = {
  sortCondition?:
    | "institution_id_asc"
    | "institution_id_desc"
    | "updated_at_asc"
    | "updated_at_desc";
  displayInstitutionId?: string;
  institutionName?: string;
  foreignType?: ("domestic" | "foreign")[];
  commercialType?: ("commercial" | "noncommercial")[];
};
export type SearchInquiryApiResponse = /** status 200 OK */ SearchResultInquiry;
export type SearchInquiryApiArg = {
  todosFirst?: boolean;
  sortCondition?: "updated_at_asc" | "updated_at_desc";
  limit?: number;
  institutionId?: number;
  projectId?: number;
  documentId?: number;
  pageNumber?: number;
};
export type GetNumberOfInquiryTodosApiResponse = /** status 200 OK */ number;
export type GetNumberOfInquiryTodosApiArg = void;
export type HealthApiResponse = /** status 200 OK */ string;
export type HealthApiArg = void;
export type ListDocumentApiResponse = /** status 200 OK */ Document[];
export type ListDocumentApiArg = {
  projectId?: number;
  documentType?: (
    | "new_application"
    | "change_application"
    | "annual_report"
    | "termination_report"
  )[];
  documentControlNumber?: string;
  documentState?: (
    | "draft"
    | "submitted"
    | "office_check"
    | "withdrawn"
    | "reviewing"
    | "awaiting_conclusion_rereview"
    | "executive_director_reviewing"
    | "awaiting_conclusion"
    | "awaiting_conclusion_no_review"
    | "awaiting_conditions_met"
    | "done"
    | "aborted"
  )[];
  documentPosition?: ("applicant" | "office_member" | "executive_director")[];
};
export type SearchDocumentApiResponse =
  /** status 200 OK */ SearchResultDocumentSearchResultRow;
export type SearchDocumentApiArg = {
  todosFirst?: boolean;
  sortCondition?:
    | "updated_at_asc"
    | "updated_at_desc"
    | "document_control_number_asc"
    | "document_control_number_desc";
  limit?: number;
  documentControlNumber?: string;
  documentType?: (
    | "new_application"
    | "change_application"
    | "annual_report"
    | "termination_report"
  )[];
  documentState?: (
    | "draft"
    | "submitted"
    | "office_check"
    | "withdrawn"
    | "reviewing"
    | "awaiting_conclusion_rereview"
    | "executive_director_reviewing"
    | "awaiting_conclusion"
    | "awaiting_conclusion_no_review"
    | "awaiting_conditions_met"
    | "done"
    | "aborted"
  )[];
  documentPosition?: ("applicant" | "office_member" | "executive_director")[];
  reviewType?: (
    | "unselected"
    | "office_review"
    | "committee_review"
    | "no_need_to_review"
  )[];
  result?: (
    | "approved"
    | "conditionally_approved"
    | "reexamination"
    | "rejected"
    | "no_need_to_review"
    | "accepted"
  )[];
  submissionDateFrom?: string;
  submissionDateTo?: string;
  receptionDateFrom?: string;
  receptionDateTo?: string;
  approvalDateFrom?: string;
  approvalDateTo?: string;
  completionDateFrom?: string;
  completionDateTo?: string;
  projectId?: number;
  projectName?: string;
  institutionId?: number;
  principalInvestigatorAppUserId?: number;
  pageNumber?: number;
};
export type GetNumberOfDocumentTodosApiResponse = /** status 200 OK */ number;
export type GetNumberOfDocumentTodosApiArg = void;
export type GetDocumentApiResponse = /** status 200 OK */ Document;
export type GetDocumentApiArg = {
  documentId: number;
};
export type DeleteDocumentDraftApiResponse = /** status 200 OK */ string;
export type DeleteDocumentDraftApiArg = {
  documentId: number;
};
export type DocumentHasUnheldReviewConferenceApiResponse =
  /** status 200 OK */ boolean;
export type DocumentHasUnheldReviewConferenceApiArg = {
  documentId: number;
};
export type GetTerminationReportApiResponse =
  /** status 200 OK */ TerminationReport;
export type GetTerminationReportApiArg = {
  documentId: number;
};
export type GetNewApplicationApiResponse = /** status 200 OK */ NewApplication;
export type GetNewApplicationApiArg = {
  documentId: number;
};
export type GetChangeApplicationApiResponse =
  /** status 200 OK */ ChangeApplication;
export type GetChangeApplicationApiArg = {
  documentId: number;
};
export type GetAnnualReportApiResponse = /** status 200 OK */ AnnualReport;
export type GetAnnualReportApiArg = {
  documentId: number;
};
export type GetDocumentSnapshotApiResponse =
  /** status 200 OK */ DocumentSnapshot;
export type GetDocumentSnapshotApiArg = {
  documentSnapshotId: number;
};
export type GetCurrentDatetimeApiResponse =
  /** status 200 OK */ CurrentDatetimeResponse;
export type GetCurrentDatetimeApiArg = void;
export type GetCurrentAppUserApiResponse = /** status 200 OK */ AppUser;
export type GetCurrentAppUserApiArg = void;
export type ListCellStockApiResponse = /** status 200 OK */ CellStock[];
export type ListCellStockApiArg = {
  cellType?: "research_purpose" | "clinical_purpose";
  cellStockCategoryId?: number;
};
export type ListCellStockCategoryApiResponse =
  /** status 200 OK */ CellStockCategory[];
export type ListCellStockCategoryApiArg = {
  cellType: "research_purpose" | "clinical_purpose";
};
export type SearchAppUserApiResponse = /** status 200 OK */ SearchResultAppUser;
export type SearchAppUserApiArg = {
  sortCondition?:
    | "user_id_asc"
    | "user_id_desc"
    | "updated_at_asc"
    | "updated_at_desc";
  limit?: number;
  role?: (
    | "applicant"
    | "office_member"
    | "committee_member"
    | "executive_director"
    | "internal"
  )[];
  displayUserId?: string;
  fullName?: string;
  institutionId?: number;
  titleAndPosition?: string;
  accountState?: ("enabled" | "disabled")[];
  pageNumber?: number;
};
export type CountPastProjectApiResponse =
  /** status 200 OK */ CountPastProjectResponse;
export type CountPastProjectApiArg = {
  appUserId: number;
};
export type DeleteSharedAttachmentFileApiResponse = /** status 200 OK */ string;
export type DeleteSharedAttachmentFileApiArg = {
  sharedAttachmentFileId: number;
};
export type CreationMetadata = {
  appUserId?: number;
  datetime?: string;
};
export type UpdateMetadata = {
  appUserId?: number;
  datetime?: string;
};
export type StorageFile = {
  id?: number;
  storageSideFolderId?: string;
  storageSideFileId?: string;
  filePrivacyType?: "private" | "public";
  institutionId?: number;
  projectId?: number;
  created?: CreationMetadata;
  updated?: UpdateMetadata;
};
export type ErrorRespBody = {
  message: string;
};
export type AttachmentFileInformation = {
  id?: number;
  storageFileId?: number;
  attachmentFileType?:
    | "research_proposal"
    | "certificate_of_ethics_committee_approval"
    | "management_system"
    | "principal_investigator_resume"
    | "organization_overview"
    | "research_achievement"
    | "facility_gmp_compliance"
    | "other"
    | "office_checklist_for_application"
    | "disclosure_document"
    | "shared"
    | "cell_treatment_warnings"
    | "donor_information"
    | "test_report"
    | "review_conference_attachment"
    | "inquiry_attachment"
    | "cell_delivery_office_member";
  attachmentFileName?: string;
  uploadedAt?: string;
};
export type SharedAttachmentFile = {
  id?: number;
  attachmentFileInformation?: AttachmentFileInformation;
  created?: CreationMetadata;
  updated?: UpdateMetadata;
};
export type UserInformation = {
  appUserId?: number;
  displayUserId?: string;
  role?:
    | "applicant"
    | "office_member"
    | "committee_member"
    | "executive_director"
    | "internal";
  fullName?: string;
  fullNameKana?: string;
  titleAndPosition?: string;
  phoneNumber?: string;
  mailAddress?: string;
};
export type RevisionRequestItemComment = {
  body?: string;
  datetime?: string;
  commenter?: UserInformation;
};
export type RevisionRequestItem = {
  id?: number;
  path?: string;
  officeMemberComment?: RevisionRequestItemComment;
  applicantComment?: RevisionRequestItemComment;
};
export type RevisionRequest = {
  id?: number;
  documentId?: number;
  revisionRequestState?: "revising" | "revised";
  body?: string;
  reply?: string;
  revisionRequester?: UserInformation;
  revisionRequestReplier?: UserInformation;
  revisionRequestDatetime?: string;
  revisionRequestReplyDatetime?: string;
  items?: RevisionRequestItem[];
  created?: CreationMetadata;
  updated?: UpdateMetadata;
};
export type ReviewTarget = {
  id?: number;
  reviewId?: number;
  documentId?: number;
  reviewResult?: "approved" | "conditionally_approved" | "reexamination";
  unapprovalReason?: string;
  officeMemberNote?: string;
};
export type ReviewConference = {
  id?: number;
  reviewConferenceName?: string;
  reviewConferenceDate?: string;
  startDatetime?: string;
  endDatetime?: string;
  venue?: string;
  agenda?: string;
  attachmentFiles?: AttachmentFileInformation[];
  mailPostscript?: string;
  officeMemberMemo?: string;
  reviewTarget?: ReviewTarget[];
  reviewConferenceNotificationDatetime?: string;
  reviewConferenceNotificationState?: "not_notified" | "notified";
  reviewConferenceState?: "unheld" | "held";
  created?: CreationMetadata;
  updated?: UpdateMetadata;
};
export type ExecutiveDirectorApproval = {
  executiveDirectorApprovalState?: "unapproved" | "approved";
  appUserId?: number;
  fullName?: string;
  approvalDate?: string;
};
export type ResultNotification = {
  id?: number;
  documentId?: number;
  resultNotificationType?:
    | "application_no_review"
    | "application_approve_or_conditional"
    | "application_condition_confirmation"
    | "application_rereview"
    | "report_result";
  result?:
    | "approved"
    | "conditionally_approved"
    | "reexamination"
    | "rejected"
    | "no_need_to_review"
    | "accepted";
  reviewDate?: string;
  approvalConditionConfirmationDate?: string;
  unapprovalReason?: string;
  note?: string;
  executiveDirectorApproval?: ExecutiveDirectorApproval;
  resultNotificationDate?: string;
  created?: CreationMetadata;
  updated?: UpdateMetadata;
};
export type DisclosureDocuments = {
  id?: number;
  files?: AttachmentFileInformation[];
};
export type InstitutionInformation = {
  institutionId?: number;
  displayInstitutionId?: string;
  institutionName?: string;
  institutionNameKana?: string;
  foreignType?: "domestic" | "foreign";
  commercialType?: "commercial" | "noncommercial";
};
export type UserInformationKeyed = {
  id?: number;
  key?: number;
  appUserId?: number;
  displayUserId?: string;
  role?:
    | "applicant"
    | "office_member"
    | "committee_member"
    | "executive_director"
    | "internal";
  fullName?: string;
  fullNameKana?: string;
  titleAndPosition?: string;
  phoneNumber?: string;
  mailAddress?: string;
};
export type RequestingCell = {
  id?: number;
  key?: number;
  cellStockId?: number;
  cellType?: "research_purpose" | "clinical_purpose";
  cellStockCategoryId?: number;
  cellCategoryNumber?: number;
  cellCategoryNameJa?: string;
  cellCategoryNameEn?: string;
  cellNumber?: number;
  cellNameJa?: string;
  cellNameEn?: string;
  cellProvisionType?: "from_foundation" | "own" | "from_others";
  stockProviderOrganizationName?: string;
  stockProviderProjectControlNumber?: string;
};
export type IpsCellStockUsePurpose = {
  purposeType?: "research" | "clinical";
  purposeSubType?: "cellbank" | "clinical";
};
export type IpsCellStockRequirementReason = {
  reasonType?: "risk_reduction" | "other";
  other?: string;
};
export type YearMonth = {
  year?: number;
  month?: number;
};
export type EthicalApplicationStatus = {
  ethicalApplicationStatus?: "requesting" | "approved";
  estimatedApprovalYearMonth?: YearMonth;
};
export type HandlingAfterUse = {
  type?: "abandonment" | "return" | "other";
  other?: string;
};
export type CollaborativeResearchContractStateWithFoundation = {
  state?: "not_yet" | "discussing" | "agreed";
  estimatedAgreementYearMonth?: YearMonth;
};
export type CollaborativePartnerUserInformation = {
  fullName?: string;
  titleAndPosition?: string;
  phoneNumber?: string;
  mailAddress?: string;
};
export type CollaborativePartner = {
  id?: number;
  key?: number;
  collaborativePartnerName?: string;
  foreignType?: "domestic" | "foreign";
  commercialType?: "commercial" | "noncommercial";
  collaborativePartnerRole?: string;
  principalInvestigator?: CollaborativePartnerUserInformation;
  contactPerson?: CollaborativePartnerUserInformation;
  researchPlanProjectName?: string;
  attachmentFiles?: AttachmentFileInformation[];
};
export type ApplicationContent = {
  id?: number;
  projectName?: string;
  institution?: InstitutionInformation;
  principalInvestigator?: UserInformation;
  contactPeople?: UserInformationKeyed[];
  requestingResearchCells?: RequestingCell[];
  requestingClinicalCells?: RequestingCell[];
  ipsCellStockUsePurpose?: IpsCellStockUsePurpose;
  ipsCellStockRequirementReason?: IpsCellStockRequirementReason;
  planSummary?: string;
  hasCollaborativePartner?: "has_partner" | "no_partner";
  usageEndDate?: string;
  researchSite?: string;
  ipsCultureExperience?: "has_experience" | "no_experience";
  ethicalApplicationStatus?: EthicalApplicationStatus;
  handlingAfterUse?: HandlingAfterUse;
  foundationContractState?: CollaborativeResearchContractStateWithFoundation;
  researchPlanProjectName?: string;
  attachmentFiles?: AttachmentFileInformation[];
  partners?: CollaborativePartner[];
  checklistItem1?: "unchecked" | "checked";
  checklistItem2?: "unchecked" | "checked";
  checklistItem3?: "unchecked" | "checked";
  checklistItem4?: "unchecked" | "checked";
  checklistItem5?: "unchecked" | "checked";
  checklistItem6?: "unchecked" | "checked";
};
export type Project = {
  id?: number;
  projectControlNumber?: string;
  projectControlNumberYear?: number;
  projectControlNumberSerialNumber?: number;
  projectState?:
    | "draft"
    | "applied"
    | "ongoing"
    | "terminated"
    | "invalid"
    | "withdrawn"
    | "aborted";
  migrationDataType?: "migration_data" | "normal_data";
  newApplicationSubmissionDate?: string;
  newApplicationApprovalDate?: string;
  projectCompletionDate?: string;
  lastAnnualReportSubmissionDate?: string;
  nextAnnualReportDeadline?: string;
  terminationReportSubmitted?: "not_submitted" | "submitted";
  disclosureDocument?: DisclosureDocuments;
  annualReportRemindNotificationDate?: string;
  terminationReportRemindNotificationDate?: string;
  applicationContent?: ApplicationContent;
  created?: CreationMetadata;
  updated?: UpdateMetadata;
};
export type Institution = {
  id?: number;
  displayInstitutionId?: string;
  name?: string;
  nameKana?: string;
  foreignType?: "domestic" | "foreign";
  commercialType?: "commercial" | "noncommercial";
  created?: CreationMetadata;
  updated?: UpdateMetadata;
};
export type InquiryReply = {
  id?: number;
  replierAppUserId?: number;
  replierRole?:
    | "applicant"
    | "office_member"
    | "committee_member"
    | "executive_director"
    | "internal";
  content?: string;
  attachmentFiles?: AttachmentFileInformation[];
  replyDatetime?: string;
};
export type Inquiry = {
  id?: number;
  institutionId?: number;
  projectId?: number;
  documentId?: number;
  issuerRole?:
    | "applicant"
    | "office_member"
    | "committee_member"
    | "executive_director"
    | "internal";
  subject?: string;
  content?: string;
  attachmentFiles?: AttachmentFileInformation[];
  applicantReadState?: "unread" | "read";
  officeMemberReadState?: "unread" | "read";
  replies?: InquiryReply[];
  created?: CreationMetadata;
  updated?: UpdateMetadata;
};
export type DifferentiatedCellProvision = {
  id?: number;
  key?: number;
  recipientName?: string;
  cellInformation?: string;
  provisionReason?: string;
  cellDisposalType?: string;
};
export type CollaborativePartnerOnReport = {
  id?: number;
  key?: number;
  collaborativePartnerName?: string;
  collaborativePartnerRole?: string;
  principalInvestigator?: CollaborativePartnerUserInformation;
  cellProvisionType?: "provided" | "none";
  cellProvisions?: DifferentiatedCellProvision[];
};
export type ProblemsDuringThePeriod = {
  problemPresence?: "has_problem" | "none";
  problemDetails?: string;
  responseToProblem?: string;
};
export type HandlingAfterTermination = {
  planComplianceType?: "yes" | "no";
  reasonsForNotBeingAbleToComply?: string;
};
export type TerminationReportContent = {
  id?: number;
  projectName?: string;
  institution?: InstitutionInformation;
  principalInvestigator?: UserInformation;
  newApplicationApprovalDate?: string;
  usageEndDate?: string;
  attachmentFiles?: AttachmentFileInformation[];
  partner?: CollaborativePartnerOnReport[];
  cellProvisionType?: "provided" | "none";
  cellProvisions?: DifferentiatedCellProvision[];
  problemsDuringThePeriod?: ProblemsDuringThePeriod;
  handlingAfterTermination?: HandlingAfterTermination;
  researchResultOutline?: string;
};
export type ReportOfficeMemberNotes = {
  officeMemberNote?: string;
};
export type TerminationReport = {
  id?: number;
  documentControlNumber?: string;
  projectId?: number;
  documentType?:
    | "new_application"
    | "change_application"
    | "annual_report"
    | "termination_report";
  documentState?:
    | "draft"
    | "submitted"
    | "office_check"
    | "withdrawn"
    | "reviewing"
    | "awaiting_conclusion_rereview"
    | "executive_director_reviewing"
    | "awaiting_conclusion"
    | "awaiting_conclusion_no_review"
    | "awaiting_conditions_met"
    | "done"
    | "aborted";
  documentPosition?: "applicant" | "office_member" | "executive_director";
  result?:
    | "approved"
    | "conditionally_approved"
    | "reexamination"
    | "rejected"
    | "no_need_to_review"
    | "accepted";
  submissionDate?: string;
  receptionDate?: string;
  officeMemberCheckDate?: string;
  approvalDate?: string;
  documentCompletionDate?: string;
  created?: CreationMetadata;
  updated?: UpdateMetadata;
  terminationReportSerialNumber?: number;
  content?: TerminationReportContent;
  officeMemberNotes?: ReportOfficeMemberNotes;
};
export type SignatureValue = {
  correctnessCheck?: "unchecked" | "checked";
  checkDate?: string;
};
export type OfficeMemberCheckContent = {
  id?: number;
  reviewType?:
    | "unselected"
    | "office_review"
    | "committee_review"
    | "no_need_to_review";
  clinicalStocksInventoryCheckState?: "unchecked" | "checked";
  note?: string;
  officeChecklist?: AttachmentFileInformation[];
};
export type ApplicationOfficeMemberNotes = {
  contractPerson?: string;
  provisionFeeRequired?: "required" | "not_required";
  reviewFeeRequired?: "required" | "not_required";
  invoiceIssuanceMemoForReviewFee?: string;
  explanationOfStockMaintenanceFee?: string;
  status?: string;
  patentLicenseExplanationStatus?: "not_explained" | "explained";
  securityExportControl?: string;
  officeMemberNote?: string;
};
export type NewApplication = {
  id?: number;
  documentControlNumber?: string;
  projectId?: number;
  documentType?:
    | "new_application"
    | "change_application"
    | "annual_report"
    | "termination_report";
  documentState?:
    | "draft"
    | "submitted"
    | "office_check"
    | "withdrawn"
    | "reviewing"
    | "awaiting_conclusion_rereview"
    | "executive_director_reviewing"
    | "awaiting_conclusion"
    | "awaiting_conclusion_no_review"
    | "awaiting_conditions_met"
    | "done"
    | "aborted";
  documentPosition?: "applicant" | "office_member" | "executive_director";
  result?:
    | "approved"
    | "conditionally_approved"
    | "reexamination"
    | "rejected"
    | "no_need_to_review"
    | "accepted";
  submissionDate?: string;
  receptionDate?: string;
  officeMemberCheckDate?: string;
  approvalDate?: string;
  documentCompletionDate?: string;
  created?: CreationMetadata;
  updated?: UpdateMetadata;
  content?: ApplicationContent;
  signatureValue?: SignatureValue;
  officeMemberCheckContent?: OfficeMemberCheckContent;
  officeMemberNotes?: ApplicationOfficeMemberNotes;
};
export type ChangeReason = {
  id?: number;
  path?: string;
  content?: string;
};
export type AnyValue = any | null;
export type JsonPatchOperation = {
  id?: number;
  op?: "add" | "remove" | "replace" | "move" | "copy" | "test";
  path?: string;
  value?: AnyValue;
  from?: string;
};
export type ChangeApplicationContent = {
  id?: number;
  changeReasons?: ChangeReason[];
  changeItems?: JsonPatchOperation[];
};
export type ChangeApplication = {
  id?: number;
  documentControlNumber?: string;
  projectId?: number;
  documentType?:
    | "new_application"
    | "change_application"
    | "annual_report"
    | "termination_report";
  documentState?:
    | "draft"
    | "submitted"
    | "office_check"
    | "withdrawn"
    | "reviewing"
    | "awaiting_conclusion_rereview"
    | "executive_director_reviewing"
    | "awaiting_conclusion"
    | "awaiting_conclusion_no_review"
    | "awaiting_conditions_met"
    | "done"
    | "aborted";
  documentPosition?: "applicant" | "office_member" | "executive_director";
  result?:
    | "approved"
    | "conditionally_approved"
    | "reexamination"
    | "rejected"
    | "no_need_to_review"
    | "accepted";
  submissionDate?: string;
  receptionDate?: string;
  officeMemberCheckDate?: string;
  approvalDate?: string;
  documentCompletionDate?: string;
  created?: CreationMetadata;
  updated?: UpdateMetadata;
  changeApplicationSerialNumber?: number;
  content?: ChangeApplicationContent;
  signatureValue?: SignatureValue;
  officeMemberCheckContent?: OfficeMemberCheckContent;
  officeMemberNotes?: ApplicationOfficeMemberNotes;
  projectSnapshotIdBeforeChangeApplication?: number;
};
export type UsedCell = {
  id?: number;
  cellStockId?: number;
  cellType?: "research_purpose" | "clinical_purpose";
  cellStockCategoryId?: number;
  cellCategoryNumber?: number;
  cellCategoryNameJa?: string;
  cellCategoryNameEn?: string;
  cellNumber?: number;
  cellNameJa?: string;
  cellNameEn?: string;
  cellProvisionType?: "from_foundation" | "own" | "from_others";
  opinions?: string;
};
export type AnnualReportContent = {
  id?: number;
  projectName?: string;
  institution?: InstitutionInformation;
  principalInvestigator?: UserInformation;
  usedResearchCells?: UsedCell[];
  usedClinicalCells?: UsedCell[];
  reportPeriodStartDate?: string;
  reportPeriodEndDate?: string;
  attachmentFiles?: AttachmentFileInformation[];
  partners?: CollaborativePartnerOnReport[];
  cellProvisionType?: "provided" | "none";
  cellProvisions?: DifferentiatedCellProvision[];
  progressResultOutline?: string;
};
export type AnnualReport = {
  id?: number;
  documentControlNumber?: string;
  projectId?: number;
  documentType?:
    | "new_application"
    | "change_application"
    | "annual_report"
    | "termination_report";
  documentState?:
    | "draft"
    | "submitted"
    | "office_check"
    | "withdrawn"
    | "reviewing"
    | "awaiting_conclusion_rereview"
    | "executive_director_reviewing"
    | "awaiting_conclusion"
    | "awaiting_conclusion_no_review"
    | "awaiting_conditions_met"
    | "done"
    | "aborted";
  documentPosition?: "applicant" | "office_member" | "executive_director";
  result?:
    | "approved"
    | "conditionally_approved"
    | "reexamination"
    | "rejected"
    | "no_need_to_review"
    | "accepted";
  submissionDate?: string;
  receptionDate?: string;
  officeMemberCheckDate?: string;
  approvalDate?: string;
  documentCompletionDate?: string;
  created?: CreationMetadata;
  updated?: UpdateMetadata;
  annualReportSerialNumber?: number;
  content?: AnnualReportContent;
  officeMemberNotes?: ReportOfficeMemberNotes;
};
export type ShippingInformation = {
  shippingStatus?: "unshipped" | "shipped";
  shippingDate?: string;
};
export type ReceiptInformation = {
  receivedStatus?: "not_received" | "received";
  receiptDate?: string;
};
export type ShippingCell = {
  id?: number;
  cellStockId?: number;
  cellType?: "research_purpose" | "clinical_purpose";
  cellStockCategoryId?: number;
  cellCategoryNumber?: number;
  cellCategoryNameJa?: string;
  cellCategoryNameEn?: string;
  cellNumber?: number;
  cellNameJa?: string;
  cellNameEn?: string;
  quantity?: number;
};
export type ShippingDestination = {
  shippingDestinationName?: string;
  pickupLocation?: string;
  postalCode?: string;
  address?: string;
  titleAndPosition?: string;
  fullName?: string;
  phoneNumber?: string;
  mailAddress?: string;
  other?: string;
};
export type PickupDestination = {
  destinationName?: string;
  pickupLocation?: string;
  postalCode?: string;
  address?: string;
  titleAndPosition?: string;
  fullName?: string;
  phoneNumber?: string;
  mailAddress?: string;
  other?: string;
};
export type CellStockDelivery = {
  id?: number;
  projectId?: number;
  shippingInformation?: ShippingInformation;
  receiptInformation?: ReceiptInformation;
  shippingResearchCells?: ShippingCell[];
  shippingClinicalCells?: ShippingCell[];
  shippingDestination?: ShippingDestination;
  preferredDeliveryDate?: string;
  deliveryArrangementMethodType?:
    | "arranged_by_applicant"
    | "arranged_by_foundation"
    | "decide_after_consultation";
  domesticDelivererType?:
    | "not_selected"
    | "seino_super_express"
    | "seino_transportation"
    | "other";
  domesticDelivererOther?: string;
  domesticDelivererShipperRegistrationType?:
    | "registered"
    | "not_registered"
    | "other";
  foreignDelivererType?:
    | "not_selected"
    | "dhl"
    | "world_courier"
    | "fedex"
    | "marken"
    | "other";
  foreignDelivererOther?: string;
  foreignDelivererAccountInformation?: string;
  shippingContainerArrangementType?:
    | "arranged_by_applicant"
    | "arranged_by_foundation"
    | "decide_after_consultation";
  shippingContainerReturningDate?: string;
  pickupDestination?: PickupDestination;
  provisionFeeMemo?: string;
  officeMemberCommentForApplicants?: string;
  officeMemberCommentInternal?: string;
  attachmentFiles?: AttachmentFileInformation[];
  created?: CreationMetadata;
  updated?: UpdateMetadata;
};
export type AppUser = {
  id?: number;
  displayUserId?: string;
  role?:
    | "applicant"
    | "office_member"
    | "committee_member"
    | "executive_director"
    | "internal";
  fullName?: string;
  fullNameKana?: string;
  cognitoUsername?: string;
  titleAndPosition?: string;
  phoneNumber?: string;
  mailAddress?: string;
  language?: "ja" | "en";
  accountState?: "enabled" | "disabled";
  mailNotification?: "notification" | "stop";
  institutionId?: number;
  created?: CreationMetadata;
  updated?: UpdateMetadata;
};
export type CommonUpdateRequest = {
  patchOperations?: JsonPatchOperation[];
};
export type Review = {
  id?: number;
  documentId?: number;
  reviewType?:
    | "unselected"
    | "office_review"
    | "committee_review"
    | "no_need_to_review";
  reviewDate?: string;
  reviewResult?: "approved" | "conditionally_approved" | "reexamination";
  unapprovalReason?: string;
  note?: string;
  executiveDirectorApproval?: ExecutiveDirectorApproval;
  reviewState?: "unheld" | "held";
  reviewResultConfirmationDate?: string;
  documentSnapshotIdOnConclusion?: number;
  reviewConferenceId?: number;
  officeChecklist?: AttachmentFileInformation[];
  created?: CreationMetadata;
  updated?: UpdateMetadata;
};
export type Document = {
  id?: number;
  documentControlNumber?: string;
  projectId?: number;
  documentType?:
    | "new_application"
    | "change_application"
    | "annual_report"
    | "termination_report";
  documentState?:
    | "draft"
    | "submitted"
    | "office_check"
    | "withdrawn"
    | "reviewing"
    | "awaiting_conclusion_rereview"
    | "executive_director_reviewing"
    | "awaiting_conclusion"
    | "awaiting_conclusion_no_review"
    | "awaiting_conditions_met"
    | "done"
    | "aborted";
  documentPosition?: "applicant" | "office_member" | "executive_director";
  result?:
    | "approved"
    | "conditionally_approved"
    | "reexamination"
    | "rejected"
    | "no_need_to_review"
    | "accepted";
  submissionDate?: string;
  receptionDate?: string;
  officeMemberCheckDate?: string;
  approvalDate?: string;
  documentCompletionDate?: string;
  created?: CreationMetadata;
  updated?: UpdateMetadata;
};
export type ChangeLanguageRequest = {
  language?: "ja" | "en";
};
export type WorkLog = {
  id?: number;
  institutionId?: number;
  projectId?: number;
  documentId?: number;
  workType?: "create" | "update" | "delete" | "other";
  operation?:
    | "GetCurrentUser"
    | "ChangeLanguage"
    | "ApplyConfig"
    | "ListSharedAttachmentFile"
    | "DownloadFile"
    | "UploadFile"
    | "CreateSharedAttachmentFile"
    | "DeleteSharedAttachmentFile"
    | "ListProject"
    | "GetProject"
    | "UpdateDisclosureDocument"
    | "CreateCellStockDelivery"
    | "ListCellStockDelivery"
    | "GetCellStockDelivery"
    | "UpdateCellStockDelivery"
    | "DeleteCellStockDelivery"
    | "ForceCreateProject"
    | "ForceUpdateProject"
    | "ListDocument"
    | "GetNumberOfDocumentTodos"
    | "GetNewApplication"
    | "GetChangeApplication"
    | "GetAnnualReport"
    | "GetTerminationReport"
    | "ListRevisionRequest"
    | "GetRevisionRequest"
    | "RemindAnnualReport"
    | "RemindTerminationReport"
    | "CreateProjectSnapshot"
    | "GetProjectSnapshot"
    | "GetInstitution"
    | "GetUser"
    | "CreateNewApplicationDraft"
    | "CreateChangeApplicationDraft"
    | "CreateAnnualReportDraft"
    | "CreateTerminationReportDraft"
    | "DeleteDocumentDraft"
    | "SubmitNewApplication"
    | "SubmitChangeApplication"
    | "SubmitAnnualReport"
    | "SubmitTerminationReport"
    | "UpdateNewApplicationContent"
    | "UpdateChangeApplicationContent"
    | "UpdateAnnualReportContent"
    | "UpdateTerminationReportContent"
    | "ReceiveNewApplication"
    | "ReceiveChangeApplication"
    | "ReceiveAnnualReport"
    | "ReceiveTerminationReport"
    | "UpdateNewApplicationOfficeMemberCheck"
    | "UpdateChangeApplicationOfficeMemberCheck"
    | "CompleteOfficeMemberCheck"
    | "CreateRevisionRequest"
    | "SaveRevisionRequestReply"
    | "SubmitRevisionRequestReply"
    | "SaveNewApplicationRevision"
    | "SubmitNewApplicationRevision"
    | "SaveChangeApplicationRevision"
    | "SubmitChangeApplicationRevision"
    | "SaveAnnualReportRevision"
    | "SubmitAnnualReportRevision"
    | "SaveTerminationReportRevision"
    | "SubmitTerminationReportRevision"
    | "ListReview"
    | "GetReview"
    | "UpdateReview"
    | "ConcludeReview"
    | "ApproveReview"
    | "ListResultNotification"
    | "GetResultNotification"
    | "CreateResultNotification"
    | "UpdateApplicationOfficeMemberNotes"
    | "UpdateReportOfficeMemberNotes"
    | "ListReviewConference"
    | "GetReviewConference"
    | "NotifyReviewConference"
    | "UpdateReviewConference"
    | "CreateReviewConference"
    | "DeleteReviewConference"
    | "ConcludeReviewConference"
    | "ListInquiry"
    | "GetNumberOfInquiryTodos"
    | "RespondInquiry"
    | "ReadInquiry"
    | "CreateInquiry"
    | "ListWorkLog"
    | "ListMailLog"
    | "ListInstitution"
    | "CreateInstitution"
    | "UpdateInstitution"
    | "ListAppUser"
    | "CreateAppUser"
    | "UpdateAppUser"
    | "EnableAppUser"
    | "DisableAppUser"
    | "ResetTemporaryPassword"
    | "ResetPassword"
    | "ResetMFA"
    | "UpdateAccountState"
    | "ListCellStockCategory"
    | "ListCellStock"
    | "DeleteDocument"
    | "AbortProject"
    | "AbortDocument"
    | "WithdrawDocument";
  beforeJson?: AnyValue;
  afterJson?: AnyValue;
  created?: CreationMetadata;
  updated?: UpdateMetadata;
};
export type SearchResultWorkLog = {
  totalElements: number;
  items: WorkLog[];
};
export type SystemEnvResponse = {
  isMaintenance?: boolean;
  internalMemberInstitutionId?: number;
  maxFileSize?: number;
  uploadableFileExtension?: string[];
  cachePollingInterval?: number;
};
export type ReviewConferenceSearchResultRow = {
  reviewConference: ReviewConference;
  todo: boolean;
};
export type SearchResultReviewConferenceSearchResultRow = {
  totalElements: number;
  items: ReviewConferenceSearchResultRow[];
};
export type SearchResultProject = {
  totalElements: number;
  items: Project[];
};
export type CsvRespBody = {
  body: string;
  now: string;
};
export type ProjectSnapshot = {
  id?: number;
  projectId?: number;
  projectJson?: AnyValue;
  created?: CreationMetadata;
  updated?: UpdateMetadata;
};
export type LanguageMessages = {
  common: {
    content?: {
      [key: string]: AnyValue;
    };
    isEmpty: boolean;
    size: number;
    entries: {
      value?: AnyValue;
      key?: string;
    }[];
    keys: string[];
    values: AnyValue[];
    [key: string]: AnyValue;
  };
  custom: {
    content?: {
      [key: string]: AnyValue;
    };
    isEmpty: boolean;
    size: number;
    entries: {
      value?: AnyValue;
      key?: string;
    }[];
    keys: string[];
    values: AnyValue[];
    [key: string]: AnyValue;
  };
  item: {
    content?: {
      [key: string]: AnyValue;
    };
    isEmpty: boolean;
    size: number;
    entries: {
      value?: AnyValue;
      key?: string;
    }[];
    keys: string[];
    values: AnyValue[];
    [key: string]: AnyValue;
  };
};
export type MessageConfigObject = {
  en: LanguageMessages;
  ja: LanguageMessages;
};
export type MailLog = {
  id?: number;
  institutionId?: number;
  projectId?: number;
  documentId?: number;
  operation?:
    | "GetCurrentUser"
    | "ChangeLanguage"
    | "ApplyConfig"
    | "ListSharedAttachmentFile"
    | "DownloadFile"
    | "UploadFile"
    | "CreateSharedAttachmentFile"
    | "DeleteSharedAttachmentFile"
    | "ListProject"
    | "GetProject"
    | "UpdateDisclosureDocument"
    | "CreateCellStockDelivery"
    | "ListCellStockDelivery"
    | "GetCellStockDelivery"
    | "UpdateCellStockDelivery"
    | "DeleteCellStockDelivery"
    | "ForceCreateProject"
    | "ForceUpdateProject"
    | "ListDocument"
    | "GetNumberOfDocumentTodos"
    | "GetNewApplication"
    | "GetChangeApplication"
    | "GetAnnualReport"
    | "GetTerminationReport"
    | "ListRevisionRequest"
    | "GetRevisionRequest"
    | "RemindAnnualReport"
    | "RemindTerminationReport"
    | "CreateProjectSnapshot"
    | "GetProjectSnapshot"
    | "GetInstitution"
    | "GetUser"
    | "CreateNewApplicationDraft"
    | "CreateChangeApplicationDraft"
    | "CreateAnnualReportDraft"
    | "CreateTerminationReportDraft"
    | "DeleteDocumentDraft"
    | "SubmitNewApplication"
    | "SubmitChangeApplication"
    | "SubmitAnnualReport"
    | "SubmitTerminationReport"
    | "UpdateNewApplicationContent"
    | "UpdateChangeApplicationContent"
    | "UpdateAnnualReportContent"
    | "UpdateTerminationReportContent"
    | "ReceiveNewApplication"
    | "ReceiveChangeApplication"
    | "ReceiveAnnualReport"
    | "ReceiveTerminationReport"
    | "UpdateNewApplicationOfficeMemberCheck"
    | "UpdateChangeApplicationOfficeMemberCheck"
    | "CompleteOfficeMemberCheck"
    | "CreateRevisionRequest"
    | "SaveRevisionRequestReply"
    | "SubmitRevisionRequestReply"
    | "SaveNewApplicationRevision"
    | "SubmitNewApplicationRevision"
    | "SaveChangeApplicationRevision"
    | "SubmitChangeApplicationRevision"
    | "SaveAnnualReportRevision"
    | "SubmitAnnualReportRevision"
    | "SaveTerminationReportRevision"
    | "SubmitTerminationReportRevision"
    | "ListReview"
    | "GetReview"
    | "UpdateReview"
    | "ConcludeReview"
    | "ApproveReview"
    | "ListResultNotification"
    | "GetResultNotification"
    | "CreateResultNotification"
    | "UpdateApplicationOfficeMemberNotes"
    | "UpdateReportOfficeMemberNotes"
    | "ListReviewConference"
    | "GetReviewConference"
    | "NotifyReviewConference"
    | "UpdateReviewConference"
    | "CreateReviewConference"
    | "DeleteReviewConference"
    | "ConcludeReviewConference"
    | "ListInquiry"
    | "GetNumberOfInquiryTodos"
    | "RespondInquiry"
    | "ReadInquiry"
    | "CreateInquiry"
    | "ListWorkLog"
    | "ListMailLog"
    | "ListInstitution"
    | "CreateInstitution"
    | "UpdateInstitution"
    | "ListAppUser"
    | "CreateAppUser"
    | "UpdateAppUser"
    | "EnableAppUser"
    | "DisableAppUser"
    | "ResetTemporaryPassword"
    | "ResetPassword"
    | "ResetMFA"
    | "UpdateAccountState"
    | "ListCellStockCategory"
    | "ListCellStock"
    | "DeleteDocument"
    | "AbortProject"
    | "AbortDocument"
    | "WithdrawDocument";
  appUserId?: number;
  mailAddress?: string;
  recipientRole?:
    | "applicant"
    | "office_member"
    | "committee_member"
    | "executive_director"
    | "internal";
  subject?: string;
  content?: string;
  sentAt?: string;
  createdAt?: string;
  updatedAt?: string;
};
export type SearchResultMailLog = {
  totalElements: number;
  items: MailLog[];
};
export type SearchResultInstitution = {
  totalElements: number;
  items: Institution[];
};
export type SearchResultInquiry = {
  totalElements: number;
  items: Inquiry[];
};
export type DocumentSearchResultRow = {
  document: Document;
  reviewType?:
    | "unselected"
    | "office_review"
    | "committee_review"
    | "no_need_to_review";
  todo: boolean;
};
export type SearchResultDocumentSearchResultRow = {
  totalElements: number;
  items: DocumentSearchResultRow[];
};
export type DocumentSnapshot = {
  id?: number;
  documentId?: number;
  documentType?:
    | "new_application"
    | "change_application"
    | "annual_report"
    | "termination_report";
  documentInformationJson?: AnyValue;
  created?: CreationMetadata;
  updated?: UpdateMetadata;
};
export type CurrentDatetimeResponse = {
  currentDatetime?: string;
};
export type CellStock = {
  cellStockId?: number;
  cellNumber?: number;
  cellNameJa?: string;
  cellNameEn?: string;
  cellType?: "research_purpose" | "clinical_purpose";
  cellStockCategoryId?: number;
};
export type CellStockCategory = {
  cellStockCategoryId?: number;
  cellCategoryNumber?: number;
  cellCategoryNameJa?: string;
  cellCategoryNameEn?: string;
  cellType?: "research_purpose" | "clinical_purpose";
};
export type SearchResultAppUser = {
  totalElements: number;
  items: AppUser[];
};
export type CountPastProjectResponse = {
  pastProjectCount?: number;
};
export const {
  useUploadFileMutation,
  useListSharedAttachmentFileQuery,
  useCreateSharedAttachmentFileMutation,
  useListRevisionRequestQuery,
  useCreateRevisionRequestMutation,
  useCreateReviewConferenceMutation,
  useNotifyReviewConferenceMutation,
  useConcludeReviewConferenceMutation,
  useListResultNotificationQuery,
  useCreateResultNotificationMutation,
  useForceCreateProjectMutation,
  useListInstitutionQuery,
  useCreateInstitutionMutation,
  useCreateInquiryMutation,
  useCreateTerminationReportDraftMutation,
  useSubmitTerminationReportFromDraftMutation,
  useCreateNewApplicationDraftMutation,
  useCreateChangeApplicationDraftMutation,
  useSubmitChangeApplicationFromDraftMutation,
  useCreateAnnualReportDraftMutation,
  useSubmitAnnualReportFromDraftMutation,
  useListCellStockDeliveryQuery,
  useCreateCellStockDeliveryMutation,
  useApplyConfigMutation,
  useListAppUserQuery,
  useCreateAppUserMutation,
  useResetTemporaryPasswordMutation,
  useResetMfaMutation,
  useSubmitRevisionRequestReplyMutation,
  useSaveRevisionRequestReplyMutation,
  useGetReviewQuery,
  useUpdateReviewMutation,
  useConcludeReviewMutation,
  useApproveReviewMutation,
  useGetReviewConferenceQuery,
  useDeleteReviewConferenceMutation,
  useUpdateReviewConferenceMutation,
  useUpdateDisclosureDocumentMutation,
  useForceUpdateProjectMutation,
  useAbortProjectMutation,
  useGetInstitutionQuery,
  useUpdateInstitutionMutation,
  useRespondInquiryMutation,
  useReadInquiryMutation,
  useWithdrawDocumentMutation,
  useUpdateReportOfficeMemberNotesMutation,
  useUpdateApplicationOfficeMemberNotesMutation,
  useCompleteOfficeMemberCheckMutation,
  useAbortDocumentMutation,
  useUpdateTerminationReportContentMutation,
  useReceiveTerminationReportMutation,
  useUpdateNewApplicationOfficeMemberCheckMutation,
  useUpdateNewApplicationContentMutation,
  useSubmitNewApplicationFromDraftMutation,
  useReceiveNewApplicationMutation,
  useUpdateChangeApplicationOfficeMemberCheckMutation,
  useUpdateChangeApplicationContentMutation,
  useReceiveChangeApplicationMutation,
  useUpdateAnnualReportContentMutation,
  useReceiveAnnualReportMutation,
  useChangeLanguageMutation,
  useGetCellStockDeliveryQuery,
  useDeleteCellStockDeliveryMutation,
  useUpdateCellStockDeliveryMutation,
  useGetAppUserQuery,
  useUpdateAppUserMutation,
  useEnableAppUserMutation,
  useDisableAppUserMutation,
  useSearchWorkLogQuery,
  useGetSystemEnvQuery,
  useGetPreviewLinkQuery,
  useDownloadFileQuery,
  useGetRevisionRequestQuery,
  useListReviewQuery,
  useSearchReviewConferenceQuery,
  useGetNumberOfReviewConferenceTodosQuery,
  useGetResultNotificationQuery,
  useListProjectQuery,
  useSearchProjectQuery,
  useCreateProjectCsvQuery,
  useGetProjectQuery,
  useGetProjectSnapshotQuery,
  useGetMessagesQuery,
  useSearchMailLogQuery,
  useSearchInstitutionQuery,
  useCreateInstitutionCsvQuery,
  useSearchInquiryQuery,
  useGetNumberOfInquiryTodosQuery,
  useHealthQuery,
  useListDocumentQuery,
  useSearchDocumentQuery,
  useGetNumberOfDocumentTodosQuery,
  useGetDocumentQuery,
  useDeleteDocumentDraftMutation,
  useDocumentHasUnheldReviewConferenceQuery,
  useGetTerminationReportQuery,
  useGetNewApplicationQuery,
  useGetChangeApplicationQuery,
  useGetAnnualReportQuery,
  useGetDocumentSnapshotQuery,
  useGetCurrentDatetimeQuery,
  useGetCurrentAppUserQuery,
  useListCellStockQuery,
  useListCellStockCategoryQuery,
  useSearchAppUserQuery,
  useCountPastProjectQuery,
  useDeleteSharedAttachmentFileMutation,
} = injectedRtkApi;
