import { UsedCell } from "../../../../../../../../lib/object/value/used-cell";
import { Stack } from "@chakra-ui/react";
import {
  CMFormInputText,
  CMFormInputTextArea,
} from "@pscsrvlab/psc-react-components";
import { freeTextMeta } from "../../../../../../../../lib/object/value/free-text";
import { useAppTranslation } from "../../../../../../../../hooks/use-app-translation";
import { memo, useCallback } from "react";
import { isEqual } from "lodash";
import { CellType } from "../../../../../../../../lib/object/value/cell-type";
import { ComponentStyleProps } from "../../../../../../../../lib/styles/props/component-style-props";

export type UsedCellCardProps = {
  editMode: "editable" | "readOnly";

  cellType: CellType;
  index: number;

  value: UsedCell;
  onChange: (
    cellType: CellType,
    index: number,
    change: (before: UsedCell) => UsedCell,
  ) => void;
} & ComponentStyleProps;
export const UsedCellCard = memo(
  function UsedCellCard({
    editMode,

    cellType,
    index,

    value,
    onChange,

    sx,
    ...rest
  }: UsedCellCardProps) {
    const { t, i18n } = useAppTranslation();

    const handleChange = useCallback(
      (opinions: string) => {
        onChange?.(cellType, index, (before) => ({
          ...before,
          opinions: opinions,
        }));
      },
      [cellType, index, onChange],
    );

    return (
      <Stack direction={"row"} spacing={"20px"} sx={sx} {...rest}>
        <CMFormInputText
          editMode={"readOnly"}
          label={t("lbl.番号・株名")}
          value={i18n.language === "ja" ? value.cellNameJa : value.cellNameEn}
          valueObjectMeta={freeTextMeta}
          minW={0}
        />
        <CMFormInputTextArea
          editMode={editMode}
          label={t("lbl.使用株へのご意見・お気づきの点等(任意)")}
          value={value.opinions}
          valueObjectMeta={freeTextMeta}
          onChange={handleChange}
          minW={0}
        />
      </Stack>
    );
  },
  (prevProps, nextProps) => {
    const _isEqual = isEqual(prevProps, nextProps);
    // log.debug(`UsedCellCard: _isEqual=${_isEqual}`);
    return _isEqual;
  },
);
