import { hasValue, isNullish } from "../../../../lib/util/common-util";
import { ResultNotificationSideMenu } from "./_components/ResultNotificationSideMenu";
import { ResultNotificationPageEmpty } from "./_components/ResultNotificationPageEmpty";
import { HStack } from "@chakra-ui/react";
import { useAppParams } from "../../../../hooks/use-app-params";
import { useResultNotificationCreateInitialValue } from "../../../../hooks/result-notification/use-result-notification-create-initial-value";
import { ResultNotificationPageReadOnly } from "./_components/ResultNotificationPageReadOnly";
import { ResultNotificationPageCreate } from "./_components/ResultNotificationPageCreate";
import { useAppListResultNotificationQuery } from "../../../../hooks/query/use-app-list-result-notification-query";
import { useMemo } from "react";
import { useCanCreateResultNotification } from "../../../../hooks/review/use-can-create-result-notification";

export type ResultNotificationApplicationPageProps = {
  /**
   * 表示モード
   * view: 参照
   * create: 新規作成
   * empty: id未指定
   */
  mode: "view" | "create" | "empty";
  documentId: number;
};

/**
 * FN40S33 結果通知
 * サイドメニューとメインエリアを表示
 */
export const ResultNotificationApplicationPage = ({
  mode,
  documentId,
}: ResultNotificationApplicationPageProps) => {
  const { resultNotificationId } = useAppParams();

  const resultNotificationCreateInitialValue =
    useResultNotificationCreateInitialValue(documentId);

  const { data: resultNotifications } = useAppListResultNotificationQuery({
    documentId,
  });
  const canCreate = useCanCreateResultNotification(documentId);
  const emptyPageMode: "tbd" | "create_one" | "select_one" | undefined =
    useMemo(() => {
      if (isNullish(resultNotifications)) return undefined;
      if (resultNotifications.length > 0) return "select_one";
      return canCreate ? "create_one" : "tbd";
    }, [canCreate, resultNotifications]);

  return (
    <HStack
      flex={1}
      minH={0}
      alignItems={"stretch"}
      spacing={0}
      overflow={"hidden"}
    >
      <ResultNotificationSideMenu
        documentId={documentId}
        selectedResultNotificationId={resultNotificationId}
      />

      {mode === "empty" && hasValue(emptyPageMode) ? (
        <ResultNotificationPageEmpty mode={emptyPageMode} />
      ) : mode === "view" && hasValue(resultNotificationId) ? (
        <ResultNotificationPageReadOnly
          resultNotificationId={resultNotificationId}
        />
      ) : mode === "create" &&
        hasValue(resultNotificationCreateInitialValue) ? (
        <ResultNotificationPageCreate
          initialVM={resultNotificationCreateInitialValue}
        />
      ) : null}
    </HStack>
  );
};
