import { Center, Divider, Heading, Text, VStack } from "@chakra-ui/react";
import { WarningTwoIcon } from "@chakra-ui/icons";

export const InternalServerErrorPage = () => {
  return (
    <Center w={"100vw"} h={"100vh"}>
      <VStack align={"start"}>
        <Heading>
          <WarningTwoIcon color={"red.400"} /> 500 Internal Server Error.
        </Heading>
        <Divider />
        <Text>
          システムエラーが発生しました。システム管理者(事務局)へ問い合わせてください。
        </Text>
        <Text>
          A system error has occurred. Please contact the system administrator.
        </Text>
      </VStack>
    </Center>
  );
};
