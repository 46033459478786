import {
  messageId,
  PropertyMeta,
  ValueObjectMetaNumber,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.number().min(1000).max(9999);
const savedMinimalSchema = z.number().min(1000).max(9999);
const schema = z.number().min(1000).max(9999);
const savedSchema = z.number().min(1000).max(9999);
const jsonSchema = z.number();
/**
 * 年(値オブジェクト)のメタデータ。
 *
 * 年。 */
export const yearMeta = new ValueObjectMetaNumber(
  "Year",
  "\u5E74",
  messageId("lbl.\u5E74"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
