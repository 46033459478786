import { InquiryPage } from "../../../model/inquiry/InquiryPage/InquiryPage";
import React, { useMemo } from "react";
import { useAppParams } from "../../../../hooks/use-app-params";
import { hasValue } from "../../../../lib/util/common-util";

/**
 * FN60-S01質疑応答検索
 * FN30-S17質疑応答検索(案件)
 * FN40-S38質疑応答検索(書類)
 */
export const FN60S01InquirySearch = () => {
  const { projectId, documentId } = useAppParams();

  const type = useMemo(() => {
    if (hasValue(documentId)) return "document";
    if (hasValue(projectId)) return "project";
    return "root";
  }, [projectId, documentId]);

  return <InquiryPage type={type} />;
};
