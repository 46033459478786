import {
  messageId,
  PropertyMeta,
  ValueObjectMetaComposite,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { fullNameMeta } from "../value/full-name";
import { titleAndPositionMeta } from "../value/title-and-position";
import { phoneNumberMeta } from "../value/phone-number";
import { mailAddressMeta } from "../value/mail-address";
/**
 * 分担機関ユーザー情報(値オブジェクト)
 *
 *  */
export type CollaborativePartnerUserInformation = z.infer<typeof schema>;
export type CollaborativePartnerUserInformationSaved = z.infer<
  typeof savedSchema
>;
const minimalSchema = z.object({
  fullName: fullNameMeta.minimalSchema,
  titleAndPosition: titleAndPositionMeta.minimalSchema,
  phoneNumber: phoneNumberMeta.minimalSchema,
  mailAddress: mailAddressMeta.minimalSchema,
});
const savedMinimalSchema = z.object({
  fullName: fullNameMeta.savedMinimalSchema,
  titleAndPosition: titleAndPositionMeta.savedMinimalSchema,
  phoneNumber: phoneNumberMeta.savedMinimalSchema,
  mailAddress: mailAddressMeta.savedMinimalSchema,
});
const schema = z.object({
  fullName: fullNameMeta.schema,
  titleAndPosition: titleAndPositionMeta.schema,
  phoneNumber: phoneNumberMeta.schema,
  mailAddress: mailAddressMeta.schema,
});
const savedSchema = z.object({
  fullName: fullNameMeta.savedSchema,
  titleAndPosition: titleAndPositionMeta.savedSchema,
  phoneNumber: phoneNumberMeta.savedSchema,
  mailAddress: mailAddressMeta.savedSchema,
});
const jsonSchema = z.object({
  fullName: fullNameMeta.jsonSchema.nullish().transform((x) => x ?? undefined),
  titleAndPosition: titleAndPositionMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  phoneNumber: phoneNumberMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  mailAddress: mailAddressMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
/**
 * 分担機関ユーザー情報(値オブジェクト)のメタデータ。
 *
 *  */
export const collaborativePartnerUserInformationMeta =
  new ValueObjectMetaComposite(
    "CollaborativePartnerUserInformation",
    "\u5206\u62C5\u6A5F\u95A2\u30E6\u30FC\u30B6\u30FC\u60C5\u5831",
    messageId(
      "lbl.\u5206\u62C5\u6A5F\u95A2\u30E6\u30FC\u30B6\u30FC\u60C5\u5831"
    ),
    minimalSchema,
    savedMinimalSchema,
    schema,
    savedSchema,
    jsonSchema,
    undefined,
    undefined,
    {
      fullName: new PropertyMeta(
        "fullName",
        "\u6C0F\u540D",
        messageId("lbl.\u6C0F\u540D"),
        "always",
        false,
        fullNameMeta
      ),
      titleAndPosition: new PropertyMeta(
        "titleAndPosition",
        "\u6240\u5C5E\u30FB\u8077\u540D",
        messageId("lbl.\u6240\u5C5E\u30FB\u8077\u540D"),
        "always",
        false,
        titleAndPositionMeta
      ),
      phoneNumber: new PropertyMeta(
        "phoneNumber",
        "\u96FB\u8A71\u756A\u53F7",
        messageId("lbl.\u96FB\u8A71\u756A\u53F7"),
        "always",
        false,
        phoneNumberMeta
      ),
      mailAddress: new PropertyMeta(
        "mailAddress",
        "\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9",
        messageId("lbl.\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9"),
        "always",
        false,
        mailAddressMeta
      ),
    }
  );
