import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * 培養経験区分(値オブジェクト)
 *
 *
 *
 * has_experience:
 * no_experience:  */
export type IpsCultureExperience = z.infer<
  typeof ipsCultureExperienceMeta.schema
>;
/**
 * 培養経験区分(値オブジェクト)のメタデータ。
 *
 *  */
export const ipsCultureExperienceMeta = new ValueObjectMetaEnum<
  ["has_experience", "no_experience"]
>(
  "IpsCultureExperience",
  "\u57F9\u990A\u7D4C\u9A13\u533A\u5206",
  messageId("lbl.\u57F9\u990A\u7D4C\u9A13\u533A\u5206"),
  ["has_experience", "no_experience"],
  {
    has_experience:
      "code.\u57F9\u990A\u7D4C\u9A13\u533A\u5206.\u4EAC\u5927CiRA\u307E\u305F\u306FiPS\u8CA1\u56E3\u304C\u6A39\u7ACB\u3057\u305FiPS\u7D30\u80DE\u30B9\u30C8\u30C3\u30AF\u4EE5\u5916\u306E\u30D2\u30C8iPS\u7D30\u80DE\u3092\u4F7F\u7528\u3057\u305F\u3053\u3068\u304C\u3042\u308B",
    no_experience:
      "code.\u57F9\u990A\u7D4C\u9A13\u533A\u5206.\u4EAC\u5927CiRA\u307E\u305F\u306FiPS\u8CA1\u56E3\u304C\u6A39\u7ACB\u3057\u305FiPS\u7D30\u80DE\u30B9\u30C8\u30C3\u30AF\u4EE5\u5916\u306E\u30D2\u30C8iPS\u7D30\u80DE\u3092\u4F7F\u7528\u3057\u305F\u3053\u3068\u304C\u306A\u3044",
  }
);
