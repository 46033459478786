import { useAppParams } from "./use-app-params";
import { useMemo } from "react";
import { hasValue } from "../lib/util/common-util";

/**
 * URLから案件IDが取れれば `/project/${projectId}`を、
 * 取れなければ空文字列を返す。
 *
 * ※ /project/${projectId} の内側にいるかどうかの判定用ではあるが、
 * 当然パスの他の部分に案件IDが入っていても取得できてしまうので、そのあたりは使う側が考えることとする。
 */
export function useProjectPagePathPrefix() {
  const { projectId } = useAppParams();
  const projectPagePathPrefix: string = useMemo(
    () => (hasValue(projectId) ? `/project/${projectId}` : ""),
    [projectId],
  );
  return { projectPagePathPrefix };
}
