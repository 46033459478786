import { useCallback, useState } from "react";
import { ProjectForceCreatePage } from "../../../model/project/FN30S13ProjectForceCreate/_components/ProjectForceCreatePage/ProjectForceCreatePage";
import { CreateProjectContentValue } from "../../../model/project/FN30S13ProjectForceCreate/_components/ProjectCreateApplicationDocument/ProjectCreateApplicationDocument";
import { ProjectForceCreateCompletePage } from "../../../model/project/FN30S13ProjectForceCreate/_components/ProjectForceCreateCompletePage/ProjectForceCreateCompletePage";
import { useNavigate } from "react-router-dom";
import { useLeaveEditingPrompt } from "../../../../hooks/use-leave-editing-prompt";
import { ApplicationContentViewModel } from "../../../model/document/application/_components/ApplicationDocument/ApplicationDocument";

export type FN30S13ProjectForceCreateProps = {
  mode: "create" | "complete";
};
export const FN30S13ProjectForceCreate = ({
  mode,
}: FN30S13ProjectForceCreateProps) => {
  const navigate = useNavigate();

  const { navigateWithoutPrompt } = useLeaveEditingPrompt({
    skip: mode === "complete",
  });

  const [createdProjectControlNumberList, setCreatedProjectControlNumberList] =
    useState<string[]>([]);

  const [createPageKey, setCreatePageKey] = useState(0);

  const handleContinueCreateProject = useCallback(
    async (projectControlNumber: string, completeOptional?: boolean) => {
      setCreatedProjectControlNumberList([
        ...createdProjectControlNumberList,
        projectControlNumber,
      ]);
      setCreatePageKey((prevState) => prevState + 1);
      if (completeOptional) {
        await navigateWithoutPrompt("/project/force-create/complete");
      } else {
        await navigateWithoutPrompt("/project/force-create/create");
      }
    },
    [createdProjectControlNumberList, navigateWithoutPrompt],
  );

  /**
   * 案件検索に戻るボタン押下時に呼ばれる。
   */
  const handleClickBack = useCallback(() => {
    navigate("/project");
  }, [navigate]);
  return (
    <>
      {mode === "create" ? (
        <ProjectForceCreatePage
          key={createPageKey}
          initialViewModel={emptyViewModel()}
          initialCreateProjectContentViewModel={emptyCreateProjectContentViewModel()}
          h={"100%"}
          w={"100%"}
          handleContinueCreateProject={handleContinueCreateProject}
          handleClickBack={handleClickBack}
        />
      ) : (
        <ProjectForceCreateCompletePage
          projectControlNumbers={createdProjectControlNumberList}
        />
      )}
    </>
  );
};

function emptyCreateProjectContentViewModel(): CreateProjectContentValue {
  return {
    projectControlNumber: "",
    newApplicationApprovalDate: undefined,
    newApplicationSubmissionDate: undefined,
    nextAnnualReportDeadline: undefined,
  };
}

/**
 * 空の申請内容
 */
function emptyViewModel(): ApplicationContentViewModel {
  return {
    projectName: "",
    institution: {},
    principalInvestigator: undefined,
    contactPeople: [],
    requestingResearchCells: [],
    requestingClinicalCells: [],
    ipsCellStockUsePurpose: {
      purposeType: "research",
      purposeSubType: undefined,
    },
    ipsCellStockRequirementReason: {
      reasonType: "risk_reduction",
      other: undefined,
    },
    planSummary: "",
    hasCollaborativePartner: "no_partner",
    usageEndDate: undefined,
    researchSite: "",
    ipsCultureExperience: "no_experience",
    ethicalApplicationStatus: {
      ethicalApplicationStatus: "requesting",
      estimatedApprovalYearMonth: undefined,
    },
    handlingAfterUse: { type: "abandonment", other: undefined },
    foundationContractState: {
      state: "not_yet",
      estimatedAgreementYearMonth: undefined,
    },
    researchPlanProjectName: "",
    attachmentFiles: [],
    partners: [],
    checklistItem1: "checked",
    checklistItem2: "checked",
    checklistItem3: "checked",
    checklistItem4: "checked",
    checklistItem5: "checked",
    checklistItem6: "checked",
  };
}
