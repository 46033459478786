import { CMPageNumberAndSearchResult } from "@pscsrvlab/psc-react-components";
import React from "react";
import { ComponentStyleProps } from "../../../../lib/styles/props/component-style-props";

export type PageNumberAndSearchResultProps = {
  /**
   * ページ番号のラベル
   */
  labelPageNumber?: string;

  /**
   * 現在のページ番号
   */
  currentPageNumber?: number;

  /**
   * 区切り用ラベル
   */
  labelOf?: string;

  /**
   * 総検索結果件数
   */
  totalElements?: number;

  /**
   * 検索結果のラベル
   */
  labelSearchResult?: string;
} & ComponentStyleProps;

export const PageNumberAndSearchResult = ({
  labelPageNumber = "Page",
  labelOf = "of",
  labelSearchResult = "results",

  currentPageNumber = 0,
  totalElements = 0,

  sx,
  ...rest
}: PageNumberAndSearchResultProps) => {
  return (
    <CMPageNumberAndSearchResult
      labelPageNumber={labelPageNumber}
      currentPageNumber={currentPageNumber}
      labelOf={labelOf}
      totalElements={totalElements}
      labelSearchResult={labelSearchResult}
      sx={sx}
      {...rest}
    />
  );
};
