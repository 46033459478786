import { ApplicationContentViewModel } from "../../../../document/application/_components/ApplicationDocument/ApplicationDocument";
import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";

import useCustomToast from "../../../../../../hooks/use-custom-toast";
import { useAppSelector } from "../../../../../../hooks/redux-hooks";
import { selectUserInfo } from "../../../../../../store/auth/slice";
import React, { useCallback, useMemo, useRef, useState } from "react";
import {
  useForceCreateProjectMutation,
  useGetCurrentDatetimeQuery,
} from "../../../../../../store/api/generated/stock-request-api";
import { useApplicationContentValidation } from "../../../../../../hooks/document/application-content/use-application-content-validation";
import { applicationContentMeta } from "../../../../../../lib/object/value/application-content";
import { hasValue, isNullish } from "../../../../../../lib/util/common-util";
import log from "loglevel";
import { errorMessageOf } from "../../../../../../lib/util/error-util";
import {
  Box,
  Button,
  HStack,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { FrameUpperLeftButton } from "../../../../../ui/frame/FrameUpperLeftButton/FrameUpperLeftButton";
import { CMButtonBack, ValidationError } from "@pscsrvlab/psc-react-components";
import { IoIosArrowForward } from "react-icons/io";
import { Icon } from "@chakra-ui/icons";
import {
  CreateProjectContentValue,
  ProjectCreateApplicationDocument,
} from "../ProjectCreateApplicationDocument/ProjectCreateApplicationDocument";
import {
  Project,
  projectMeta,
} from "../../../../../../lib/object/entity/project";
import { datetimeMeta } from "../../../../../../lib/object/value/datetime";
import {
  isDateString,
  ymdToDateText,
} from "../../../../../../lib/util/common-date-util";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { ConfirmationModal } from "../../../../../ui/modal/ConfirmationModal/ConfirmationModal";

/**
 * 案件内容強制変更
 */
export type ProjectForceCreatePageProps = {
  /**
   * 画面表示時の書類内容の初期値。
   */
  initialViewModel: ApplicationContentViewModel;
  /**
   * 画面表示時の署名欄の初期値。
   */
  initialCreateProjectContentViewModel: CreateProjectContentValue;

  handleContinueCreateProject: (
    value: string,
    completeOptional?: boolean,
  ) => void;
  handleClickBack: () => void;
} & ComponentStyleProps;

/**
 * 新規申請作成画面
 */
export const ProjectForceCreatePage = ({
  initialViewModel,
  initialCreateProjectContentViewModel,
  handleContinueCreateProject,
  handleClickBack,

  sx,
  ...rest
}: ProjectForceCreatePageProps) => {
  const { t } = useAppTranslation();
  const { errorToast } = useCustomToast();
  const loginUserInfo = useAppSelector(selectUserInfo);

  const [
    createProjectContentValidationErrors,
    setCreateProjectContentValidationErrors,
  ] = useState<ValidationError[]>([]);
  /**
   * バックエンドから現在日時を取得する。
   * 取得できるまでは、ローカルPC日時で埋めておく。
   *
   * 実際に使用するのは日付部分のみなので、一定時間ごとに取得し直したりはしない。
   */
  const { data: currentDateData } = useGetCurrentDatetimeQuery();
  const currentDate: Date = useMemo(() => {
    if (isNullish(currentDateData)) return new Date();
    return (
      datetimeMeta.toDomainObjectOrNull(currentDateData.currentDatetime) ??
      new Date()
    );
  }, [currentDateData]);

  /**
   * 編集中の書類内容の現在の値。
   */
  const [viewModel, setViewModel] =
    useState<ApplicationContentViewModel>(initialViewModel);

  /**
   * 編集中の案件強制作成内容(案件管理番号等)の現在の値。
   */
  const [createProjectContentViewModel, setCreateProjectContentViewModel] =
    useState<CreateProjectContentValue>(initialCreateProjectContentViewModel);

  const [forceCreateProject] = useForceCreateProjectMutation();

  const { validate, validationErrors: contentValidationErrors } =
    useApplicationContentValidation();

  const validationErrors = useMemo(
    () => [...createProjectContentValidationErrors, ...contentValidationErrors],
    [createProjectContentValidationErrors, contentValidationErrors],
  );

  /**
   * 書類内容が編集されるたびに呼ばれる。
   */
  const handleChange = useCallback(
    (
      change: (
        before: ApplicationContentViewModel,
      ) => ApplicationContentViewModel,
    ) => {
      setViewModel(change);
    },
    [],
  );

  //案件強制作成時に入力を必要とする値（案件管理番号等）が変更された際に呼ばれる処理。
  const handleChangeCreateProjectValue = useCallback(
    (
      change: (before: CreateProjectContentValue) => CreateProjectContentValue,
    ) => {
      log.debug("change");
      setCreateProjectContentViewModel(change);
    },
    [],
  );

  //mutation時に渡す案件内容
  const projectContent: Project | undefined = useMemo(() => {
    if (isNullish(loginUserInfo)) return undefined;
    const applicationContent =
      applicationContentMeta.toDomainObjectOrNull(viewModel);
    if (isNullish(applicationContent)) return undefined;
    if (
      isNullish(createProjectContentViewModel.projectControlNumber) ||
      isNullish(createProjectContentViewModel.newApplicationSubmissionDate) ||
      isNullish(createProjectContentViewModel.newApplicationApprovalDate) ||
      isNullish(createProjectContentViewModel.nextAnnualReportDeadline)
    )
      return undefined;
    return {
      id: undefined,
      projectControlNumber: createProjectContentViewModel.projectControlNumber,
      //自由入力のため、undefinedとしている。
      projectControlNumberYear: undefined,
      projectControlNumberSerialNumber: undefined,
      projectState: "ongoing",
      migrationDataType: "migration_data",
      newApplicationSubmissionDate: {
        year: createProjectContentViewModel.newApplicationSubmissionDate.year,
        month: createProjectContentViewModel.newApplicationSubmissionDate.month,
        day: createProjectContentViewModel.newApplicationSubmissionDate.day,
      },
      newApplicationApprovalDate: {
        year: createProjectContentViewModel.newApplicationApprovalDate.year,
        month: createProjectContentViewModel.newApplicationApprovalDate.month,
        day: createProjectContentViewModel.newApplicationApprovalDate.day,
      },
      projectCompletionDate: undefined,
      lastAnnualReportSubmissionDate: undefined,
      nextAnnualReportDeadline: {
        year: createProjectContentViewModel.nextAnnualReportDeadline.year,
        month: createProjectContentViewModel.nextAnnualReportDeadline.month,
        day: createProjectContentViewModel.nextAnnualReportDeadline.day,
      },
      terminationReportSubmitted: "not_submitted",
      disclosureDocument: { files: [] },
      terminationReportRemindNotificationDate: undefined,
      annualReportRemindNotificationDate: undefined,
      applicationContent: applicationContent,
      created: {
        appUserId: loginUserInfo.id,
        datetime: currentDate,
      },
      updated: {
        appUserId: loginUserInfo.id,
        datetime: currentDate,
      },
    };
  }, [
    createProjectContentViewModel.newApplicationApprovalDate,
    createProjectContentViewModel.newApplicationSubmissionDate,
    createProjectContentViewModel.nextAnnualReportDeadline,
    createProjectContentViewModel.projectControlNumber,
    currentDate,
    loginUserInfo,
    viewModel,
  ]);

  /**
   * 案件強制作成時に入力を必要とする値（案件管理番号等）のバリデーションを行う。
   */
  function createProjectContentValidation() {
    const errorMessages = [];
    if (
      isNullish(createProjectContentViewModel.projectControlNumber) ||
      createProjectContentViewModel.projectControlNumber === ""
    ) {
      errorMessages.push({
        errorMessage: t("mes.必須チェックエラー2", {
          name: t("lbl.案件管理番号"),
        }),
        objectName: t("lbl.案件管理番号"),
        objectLogicalName: "",
        fullPropertyName: null,
        fullPropertyDisplayName: null,
      });
    }
    if (createProjectContentViewModel.projectControlNumber.length > 100) {
      errorMessages.push({
        errorMessage: t("errors.too_big.string.inclusive", {
          maximum: 100,
        }),
        objectName: "",
        objectLogicalName: "",
        fullPropertyName: null,
        fullPropertyDisplayName: t("lbl.案件管理番号"),
      });
    }
    if (isNullish(createProjectContentViewModel.newApplicationSubmissionDate)) {
      errorMessages.push({
        errorMessage: t("mes.必須チェックエラー2", {
          name: t("lbl.新規申請提出日"),
        }),
        objectName: t("lbl.新規申請提出日"),
        objectLogicalName: "",
        fullPropertyName: null,
        fullPropertyDisplayName: null,
      });
    }
    if (
      hasValue(createProjectContentViewModel.newApplicationSubmissionDate) &&
      !isDateString(
        ymdToDateText({
          year: createProjectContentViewModel.newApplicationSubmissionDate.year,
          month:
            createProjectContentViewModel.newApplicationSubmissionDate.month,
          day: createProjectContentViewModel.newApplicationSubmissionDate.day,
        }),
      )
    ) {
      errorMessages.push({
        errorMessage: t("mes.必須チェックエラー2", {
          name: t("lbl.新規申請提出日"),
        }),
        objectName: t("lbl.新規申請提出日"),
        objectLogicalName: "",
        fullPropertyName: null,
        fullPropertyDisplayName: null,
      });
    }
    if (isNullish(createProjectContentViewModel.newApplicationApprovalDate)) {
      errorMessages.push({
        errorMessage: t("mes.必須チェックエラー2", {
          name: t("lbl.新規申請承認日"),
        }),
        objectName: t("lbl.新規申請承認日"),
        objectLogicalName: "",
        fullPropertyName: null,
        fullPropertyDisplayName: null,
      });
    }
    if (
      hasValue(createProjectContentViewModel.newApplicationApprovalDate) &&
      !isDateString(
        ymdToDateText({
          year: createProjectContentViewModel.newApplicationApprovalDate.year,
          month: createProjectContentViewModel.newApplicationApprovalDate.month,
          day: createProjectContentViewModel.newApplicationApprovalDate.day,
        }),
      )
    ) {
      errorMessages.push({
        errorMessage: t("mes.必須チェックエラー2", {
          name: t("lbl.新規申請承認日"),
        }),
        objectName: t("lbl.新規申請承認日"),
        objectLogicalName: "",
        fullPropertyName: null,
        fullPropertyDisplayName: null,
      });
    }
    if (isNullish(createProjectContentViewModel.nextAnnualReportDeadline)) {
      errorMessages.push({
        errorMessage: t("mes.必須チェックエラー2", {
          name: t("lbl.次回年次報告期限日"),
        }),
        objectName: t("lbl.次回年次報告期限日"),
        objectLogicalName: "",
        fullPropertyName: null,
        fullPropertyDisplayName: null,
      });
    }
    if (
      hasValue(createProjectContentViewModel.nextAnnualReportDeadline) &&
      !isDateString(
        ymdToDateText({
          year: createProjectContentViewModel.nextAnnualReportDeadline.year,
          month: createProjectContentViewModel.nextAnnualReportDeadline.month,
          day: createProjectContentViewModel.nextAnnualReportDeadline.day,
        }),
      )
    ) {
      errorMessages.push({
        errorMessage: t("mes.必須チェックエラー2", {
          name: t("lbl.次回年次報告期限日"),
        }),
        objectName: t("lbl.次回年次報告期限日"),
        objectLogicalName: "",
        fullPropertyName: null,
        fullPropertyDisplayName: null,
      });
    }
    setCreateProjectContentValidationErrors(errorMessages);
    return errorMessages.length > 0 ? "error" : "ok";
  }

  /**
   * 続けて案件を作成ボタン押下時、案件強制作成完了ボタン押下時に呼ばれる。
   */
  const handleCreateProject = async (completeOptional: boolean) => {
    try {
      const projectJson = projectMeta.toJsonObjectOrNull(projectContent);
      if (isNullish(projectJson)) return;
      await forceCreateProject({
        project: projectJson,
      }).unwrap();
      handleContinueCreateProject(
        createProjectContentViewModel.projectControlNumber,
        completeOptional,
      );
    } catch (e) {
      errorToast(t("mes.案件強制作成失敗エラー"));
      log.error(errorMessageOf(e));
    }
  };

  const onContinueCreateProject = async () => {
    await handleCreateProject(false);
  };

  const onCompleteCreateProject = async () => {
    // log.debug("complete!");
    await handleCreateProject(true);
  };

  // 案件連続作成確認モーダル制御
  const {
    isOpen: isOpenContinueCreateModal,
    onOpen: onOpenContinueCreateModal,
    onClose: onCloseContinueCreateModal,
  } = useDisclosure();

  /**
   * 続けて案件を作成ボタン押下時に呼ばれる。
   */
  const handleContinueCreate = async () => {
    const createProjectValidationResult = createProjectContentValidation();
    if (createProjectValidationResult !== "ok") return;
    const validationResult = await validate(viewModel, false);
    if (
      validationResult.state !== "ok" ||
      createProjectContentValidation() !== "ok"
    )
      return;
    onOpenContinueCreateModal();
  };

  // 案件作成完了確認モーダル制御
  const {
    isOpen: isOpenCompleteCreateModal,
    onOpen: onOpenCompleteCreateModal,
    onClose: onCloseCompleteCreateModal,
  } = useDisclosure();

  /**
   * 続けて案件を作成ボタン押下時に呼ばれる。
   */
  const handleCompleteCreate = async () => {
    const createProjectValidationResult = createProjectContentValidation();
    if (createProjectValidationResult !== "ok") return;
    const validationResult = await validate(viewModel, false);
    if (
      validationResult.state !== "ok" ||
      createProjectContentValidation() !== "ok"
    )
      return;
    onOpenCompleteCreateModal();
  };

  const scrollableRef = useRef(null);

  return (
    <>
      <HStack
        alignItems={"flex-start"}
        overflow={"auto"}
        spacing={0}
        // scrollPaddingTop={"60px"} // position: stickyのエリア分、スクロール位置をずらすため。
        sx={sx}
        {...rest}
        ref={scrollableRef}
      >
        {/*案件検索に戻るボタン*/}
        <FrameUpperLeftButton sx={{ position: "sticky", top: 0 }}>
          <CMButtonBack
            onClick={handleClickBack}
            labelBack={t("btn.案件検索画面に戻るボタン")}
          />
        </FrameUpperLeftButton>
        <VStack flex={"1 1 auto"} minW={0} alignItems={"stretch"}>
          <VStack>
            <Text mt={"20px"} fontSize={"2xl"} fontWeight={"bold"}>
              {t("lbl.案件強制作成画面タイトル")}
            </Text>
          </VStack>
          {/*メイン領域*/}
          {hasValue(loginUserInfo) && (
            <VStack alignSelf={"stretch"} pb={"100px"}>
              <ProjectCreateApplicationDocument
                value={viewModel}
                createProjectContentValue={createProjectContentViewModel}
                onChange={handleChange}
                onChangeCreateProjectContentValue={
                  handleChangeCreateProjectValue
                }
                validationErrors={validationErrors}
                scrollableRef={scrollableRef}
                scrollOffset={-130}
                minW={"500px"}
              />
              {/*提出ボタン*/}
              <VStack spacing={"30px"} pt={"30px"}>
                <Button
                  minW={"300px"}
                  colorScheme={"teal"}
                  rightIcon={<Icon as={IoIosArrowForward} />}
                  onClick={handleContinueCreate}
                >
                  {t("btn.続けて案件を作成するボタン")}
                </Button>
                <Button
                  minW={"300px"}
                  colorScheme={"teal"}
                  onClick={handleCompleteCreate}
                >
                  {t("btn.案件強制作成を完了するボタン")}
                </Button>
              </VStack>
            </VStack>
          )}
        </VStack>

        <Box sx={{ position: "sticky", top: 0 }}></Box>
      </HStack>
      {/* 続けて案件を作成確認モーダル */}
      <ConfirmationModal
        isOpen={isOpenContinueCreateModal}
        message={t("mes.案件連続作成確認メッセージ")}
        onSubmit={onContinueCreateProject}
        onCancel={onCloseContinueCreateModal}
      />
      {/* 案件強制作成完了モーダル */}
      <ConfirmationModal
        isOpen={isOpenCompleteCreateModal}
        message={t("mes.案件強制作成完了確認メッセージ")}
        onSubmit={onCompleteCreateProject}
        onCancel={onCloseCompleteCreateModal}
      />
    </>
  );
};
