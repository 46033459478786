import {
  messageId,
  PropertyMeta,
  ValueObjectMetaComposite,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { revisionRequestItemCommentBodyMeta } from "../value/revision-request-item-comment-body";
import { datetimeMeta } from "../value/datetime";
import { userInformationMeta } from "../value/user-information";
/**
 * 修正依頼項目コメント(値オブジェクト)
 *
 *  */
export type RevisionRequestItemComment = z.infer<typeof schema>;
export type RevisionRequestItemCommentSaved = z.infer<typeof savedSchema>;
const minimalSchema = z.object({
  body: revisionRequestItemCommentBodyMeta.minimalSchema,
  datetime: datetimeMeta.minimalSchema,
  commenter: userInformationMeta.minimalSchema,
});
const savedMinimalSchema = z.object({
  body: revisionRequestItemCommentBodyMeta.savedMinimalSchema,
  datetime: datetimeMeta.savedMinimalSchema,
  commenter: userInformationMeta.savedMinimalSchema,
});
const schema = z.object({
  body: revisionRequestItemCommentBodyMeta.schema,
  datetime: datetimeMeta.schema,
  commenter: userInformationMeta.schema,
});
const savedSchema = z.object({
  body: revisionRequestItemCommentBodyMeta.savedSchema,
  datetime: datetimeMeta.savedSchema,
  commenter: userInformationMeta.savedSchema,
});
const jsonSchema = z.object({
  body: revisionRequestItemCommentBodyMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  datetime: datetimeMeta.jsonSchema.nullish().transform((x) => x ?? undefined),
  commenter: userInformationMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
/**
 * 修正依頼項目コメント(値オブジェクト)のメタデータ。
 *
 *  */
export const revisionRequestItemCommentMeta = new ValueObjectMetaComposite(
  "RevisionRequestItemComment",
  "\u4FEE\u6B63\u4F9D\u983C\u9805\u76EE\u30B3\u30E1\u30F3\u30C8",
  messageId("lbl.\u4FEE\u6B63\u4F9D\u983C\u9805\u76EE\u30B3\u30E1\u30F3\u30C8"),
  minimalSchema,
  savedMinimalSchema,
  schema,
  savedSchema,
  jsonSchema,
  undefined,
  undefined,
  {
    body: new PropertyMeta(
      "body",
      "\u30B3\u30E1\u30F3\u30C8\u672C\u6587",
      messageId("lbl.\u30B3\u30E1\u30F3\u30C8\u672C\u6587"),
      "always",
      false,
      revisionRequestItemCommentBodyMeta
    ),
    datetime: new PropertyMeta(
      "datetime",
      "\u65E5\u6642",
      messageId("lbl.\u65E5\u6642"),
      "always",
      false,
      datetimeMeta
    ),
    commenter: new PropertyMeta(
      "commenter",
      "\u6295\u7A3F\u8005\u60C5\u5831",
      messageId("lbl.\u6295\u7A3F\u8005\u60C5\u5831"),
      "always",
      false,
      userInformationMeta
    ),
  }
);
