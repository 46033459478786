import {
  messageId,
  PropertyMeta,
  ValueObjectMetaString,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.string().max(10000);
const savedMinimalSchema = z.string().max(10000);
const schema = z.string().min(1).max(10000);
const savedSchema = z.string().min(1).max(10000);
const jsonSchema = z.string();
/**
 * 計画の概要(値オブジェクト)のメタデータ。
 *
 *  */
export const planSummaryMeta = new ValueObjectMetaString(
  "PlanSummary",
  "\u8A08\u753B\u306E\u6982\u8981",
  messageId("lbl.\u8A08\u753B\u306E\u6982\u8981"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
