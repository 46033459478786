import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";
import { Text } from "@chakra-ui/react";
import { useMemo } from "react";
import { SystemStyleObject } from "@chakra-ui/styled-system";
import { AppLink } from "../../../../../ui/link/AppLink/AppLink";
import { useLocation } from "react-router-dom";

export type ProjectContentTabProps = {
  label: string;
  to: string;
} & ComponentStyleProps;

export const ProjectContentTab = ({
  label,
  to,

  sx,
  ...rest
}: ProjectContentTabProps) => {
  const location = useLocation();
  const selected = useMemo(() => {
    return location.pathname.startsWith(to);
  }, [location.pathname, to]);

  const styles: SystemStyleObject = useMemo(() => {
    const _styles = selected
      ? {
          color: "blue.600",
          fontWeight: "bold",
          borderBottomWidth: "1px",
          borderColor: "blue.600",
        }
      : {
          color: "gray.600",
          fontWeight: "normal",
        };
    return {
      ..._styles,
      display: "flex",
      flexFlow: "column nowrap",
      justifyContent: "center",
      alignItems: "center",
      px: "4px",
      pb: "4px",
      fontSize: "sm",
      whiteSpace: "nowrap",
      _hover: {
        textDecoration: "none",
      },
      ...sx,
    };
  }, [selected, sx]);
  return (
    <AppLink to={to} sx={styles} {...rest}>
      <Text>{label}</Text>
    </AppLink>
  );
};
