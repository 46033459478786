import { ComponentStyleProps } from "../../../../lib/styles/props/component-style-props";
import { memo, ReactNode, useMemo } from "react";
import { Center, Flex, VStack } from "@chakra-ui/react";
import { useAppTranslation } from "../../../../hooks/use-app-translation";

export type CalloutBoxProps = {
  label?: string;

  children?: ReactNode;
} & ComponentStyleProps;

export const CalloutBox = memo(function CalloutBox({
  label,

  children,

  sx,
  ...rest
}: CalloutBoxProps) {
  const { t } = useAppTranslation();
  const _label = useMemo(() => label ?? t("lbl.お願い"), [label, t]);

  return (
    <Flex
      direction={"row"}
      minW={"240px"}
      overflow={"hidden"}
      borderRadius={"4px"}
      borderWidth={"1px"}
      borderColor={"gray.400"}
      sx={sx}
      {...rest}
    >
      <Center
        flex={"0 0 120px"}
        bgColor={"gray.400"}
        color={"white"}
        fontWeight={"bold"}
        fontSize={"md"}
      >
        {_label}
      </Center>
      <VStack
        flex={1}
        justifyContent={"center"}
        alignItems={"flex-start"}
        px={"12px"}
        py={"16px"}
      >
        {children}
      </VStack>
    </Flex>
  );
});
