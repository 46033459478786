import {
  messageId,
  PropertyMeta,
  ValueObjectMetaNumber,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.number().min(0);
const savedMinimalSchema = z.number().min(0);
const schema = z.number().min(0);
const savedSchema = z.number().min(0);
const jsonSchema = z.number();
/**
 * 質疑応答ID(値オブジェクト)のメタデータ。
 *
 *  */
export const inquiryIdMeta = new ValueObjectMetaNumber(
  "InquiryId",
  "\u8CEA\u7591\u5FDC\u7B54ID",
  messageId("lbl.\u8CEA\u7591\u5FDC\u7B54ID"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
