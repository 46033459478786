import {
  messageId,
  PropertyMeta,
  EntityMeta,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { appUserIdMeta } from "../value/app-user-id";
import { displayUserIdMeta } from "../value/display-user-id";
import { roleMeta } from "../value/role";
import { fullNameMeta } from "../value/full-name";
import { fullNameKanaMeta } from "../value/full-name-kana";
import { cognitoUsernameMeta } from "../value/cognito-username";
import { titleAndPositionMeta } from "../value/title-and-position";
import { phoneNumberMeta } from "../value/phone-number";
import { mailAddressMeta } from "../value/mail-address";
import { languageMeta } from "../value/language";
import { accountStateMeta } from "../value/account-state";
import { mailNotificationMeta } from "../value/mail-notification";
import { institutionIdMeta } from "../value/institution-id";
import { creationMetadataMeta } from "../value/creation-metadata";
import { updateMetadataMeta } from "../value/update-metadata";
/**
 * ユーザー(エンティティ)
 *
 *  */
export type AppUser = z.infer<typeof schema>;
export type AppUserSaved = z.infer<typeof savedSchema>;
const minimalSchema = z.object({
  id: appUserIdMeta.minimalSchema.optional(),
  displayUserId: displayUserIdMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  role: roleMeta.minimalSchema,
  fullName: fullNameMeta.minimalSchema,
  fullNameKana: fullNameKanaMeta.minimalSchema,
  cognitoUsername: cognitoUsernameMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  titleAndPosition: titleAndPositionMeta.minimalSchema,
  phoneNumber: phoneNumberMeta.minimalSchema,
  mailAddress: mailAddressMeta.minimalSchema,
  language: languageMeta.minimalSchema,
  accountState: accountStateMeta.minimalSchema,
  mailNotification: mailNotificationMeta.minimalSchema,
  institutionId: institutionIdMeta.minimalSchema,
  created: creationMetadataMeta.minimalSchema,
  updated: updateMetadataMeta.minimalSchema,
});
const savedMinimalSchema = z.object({
  id: appUserIdMeta.minimalSchema,
  displayUserId: displayUserIdMeta.savedMinimalSchema,
  role: roleMeta.savedMinimalSchema,
  fullName: fullNameMeta.savedMinimalSchema,
  fullNameKana: fullNameKanaMeta.savedMinimalSchema,
  cognitoUsername: cognitoUsernameMeta.savedMinimalSchema,
  titleAndPosition: titleAndPositionMeta.savedMinimalSchema,
  phoneNumber: phoneNumberMeta.savedMinimalSchema,
  mailAddress: mailAddressMeta.savedMinimalSchema,
  language: languageMeta.savedMinimalSchema,
  accountState: accountStateMeta.savedMinimalSchema,
  mailNotification: mailNotificationMeta.savedMinimalSchema,
  institutionId: institutionIdMeta.savedMinimalSchema,
  created: creationMetadataMeta.savedMinimalSchema,
  updated: updateMetadataMeta.savedMinimalSchema,
});
const schema = z.object({
  id: appUserIdMeta.minimalSchema.optional(),
  displayUserId: displayUserIdMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
  role: roleMeta.schema,
  fullName: fullNameMeta.schema,
  fullNameKana: fullNameKanaMeta.schema,
  cognitoUsername: cognitoUsernameMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
  titleAndPosition: titleAndPositionMeta.schema,
  phoneNumber: phoneNumberMeta.schema,
  mailAddress: mailAddressMeta.schema,
  language: languageMeta.schema,
  accountState: accountStateMeta.schema,
  mailNotification: mailNotificationMeta.schema,
  institutionId: institutionIdMeta.schema,
  created: creationMetadataMeta.schema,
  updated: updateMetadataMeta.schema,
});
const savedSchema = z.object({
  id: appUserIdMeta.minimalSchema,
  displayUserId: displayUserIdMeta.savedSchema,
  role: roleMeta.savedSchema,
  fullName: fullNameMeta.savedSchema,
  fullNameKana: fullNameKanaMeta.savedSchema,
  cognitoUsername: cognitoUsernameMeta.savedSchema,
  titleAndPosition: titleAndPositionMeta.savedSchema,
  phoneNumber: phoneNumberMeta.savedSchema,
  mailAddress: mailAddressMeta.savedSchema,
  language: languageMeta.savedSchema,
  accountState: accountStateMeta.savedSchema,
  mailNotification: mailNotificationMeta.savedSchema,
  institutionId: institutionIdMeta.savedSchema,
  created: creationMetadataMeta.savedSchema,
  updated: updateMetadataMeta.savedSchema,
});
const jsonSchema = z.object({
  id: appUserIdMeta.jsonSchema.optional(),
  displayUserId: displayUserIdMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  role: roleMeta.jsonSchema.nullish().transform((x) => x ?? undefined),
  fullName: fullNameMeta.jsonSchema.nullish().transform((x) => x ?? undefined),
  fullNameKana: fullNameKanaMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  cognitoUsername: cognitoUsernameMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  titleAndPosition: titleAndPositionMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  phoneNumber: phoneNumberMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  mailAddress: mailAddressMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  language: languageMeta.jsonSchema.nullish().transform((x) => x ?? undefined),
  accountState: accountStateMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  mailNotification: mailNotificationMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  institutionId: institutionIdMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  created: creationMetadataMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  updated: updateMetadataMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
/**
 * ユーザー(エンティティ)のメタデータ。
 *
 *  */
export const appUserMeta = new EntityMeta<
  typeof schema,
  typeof savedSchema,
  typeof jsonSchema
>(
  "AppUser",
  "\u30E6\u30FC\u30B6\u30FC",
  messageId("lbl.\u30E6\u30FC\u30B6\u30FC"),
  minimalSchema,
  savedMinimalSchema,
  schema,
  savedSchema,
  jsonSchema,
  undefined,
  undefined,
  {
    id: new PropertyMeta(
      "id",
      "ID",
      messageId("lbl.ID"),
      "afterSave",
      false,
      appUserIdMeta
    ),
    displayUserId: new PropertyMeta(
      "displayUserId",
      "\u8868\u793A\u7528\u30E6\u30FC\u30B6\u30FCID",
      messageId("lbl.\u8868\u793A\u7528\u30E6\u30FC\u30B6\u30FCID"),
      "afterSave",
      false,
      displayUserIdMeta
    ),
    role: new PropertyMeta(
      "role",
      "\u30ED\u30FC\u30EB",
      messageId("lbl.\u30ED\u30FC\u30EB"),
      "always",
      false,
      roleMeta
    ),
    fullName: new PropertyMeta(
      "fullName",
      "\u6C0F\u540D",
      messageId("lbl.\u6C0F\u540D"),
      "always",
      false,
      fullNameMeta
    ),
    fullNameKana: new PropertyMeta(
      "fullNameKana",
      "\u6C0F\u540D\u304B\u306A",
      messageId("lbl.\u6C0F\u540D\u304B\u306A"),
      "always",
      false,
      fullNameKanaMeta
    ),
    cognitoUsername: new PropertyMeta(
      "cognitoUsername",
      "Cognito\u30E6\u30FC\u30B6\u30FC\u540D",
      messageId("lbl.Cognito\u30E6\u30FC\u30B6\u30FC\u540D"),
      "afterSave",
      false,
      cognitoUsernameMeta
    ),
    titleAndPosition: new PropertyMeta(
      "titleAndPosition",
      "\u6240\u5C5E\u30FB\u8077\u540D",
      messageId("lbl.\u6240\u5C5E\u30FB\u8077\u540D"),
      "always",
      false,
      titleAndPositionMeta
    ),
    phoneNumber: new PropertyMeta(
      "phoneNumber",
      "\u96FB\u8A71\u756A\u53F7",
      messageId("lbl.\u96FB\u8A71\u756A\u53F7"),
      "always",
      false,
      phoneNumberMeta
    ),
    mailAddress: new PropertyMeta(
      "mailAddress",
      "\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9",
      messageId("lbl.\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9"),
      "always",
      false,
      mailAddressMeta
    ),
    language: new PropertyMeta(
      "language",
      "\u8A00\u8A9E",
      messageId("lbl.\u8A00\u8A9E"),
      "always",
      false,
      languageMeta
    ),
    accountState: new PropertyMeta(
      "accountState",
      "\u30A2\u30AB\u30A6\u30F3\u30C8\u72B6\u614B",
      messageId("lbl.\u30A2\u30AB\u30A6\u30F3\u30C8\u72B6\u614B"),
      "always",
      false,
      accountStateMeta
    ),
    mailNotification: new PropertyMeta(
      "mailNotification",
      "\u30E1\u30FC\u30EB\u901A\u77E5",
      messageId("lbl.\u30E1\u30FC\u30EB\u901A\u77E5"),
      "always",
      false,
      mailNotificationMeta
    ),
    institutionId: new PropertyMeta(
      "institutionId",
      "\u6A5F\u95A2ID",
      messageId("lbl.\u6A5F\u95A2ID"),
      "always",
      false,
      institutionIdMeta
    ),
    created: new PropertyMeta(
      "created",
      "\u4F5C\u6210",
      messageId("lbl.\u4F5C\u6210"),
      "always",
      false,
      creationMetadataMeta
    ),
    updated: new PropertyMeta(
      "updated",
      "\u66F4\u65B0",
      messageId("lbl.\u66F4\u65B0"),
      "always",
      false,
      updateMetadataMeta
    ),
  }
);
