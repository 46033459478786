import { ComponentStyleProps } from "../../../../../../../../../lib/styles/props/component-style-props";
import {
  CMSectionMultipleCardAddable,
  Comments,
  DeleteConfirmationModal,
} from "@pscsrvlab/psc-react-components";
import { useDisclosure, VStack } from "@chakra-ui/react";
import React, { memo, useCallback, useState } from "react";

import { CMButtonFormCommentProps } from "@pscsrvlab/psc-react-components/src/components/form/comment";
import { useSectionDiffMode } from "../../../../../../../../../hooks/document/change-application/use-section-diff-mode";
import { ChangeReasonForm } from "../../form/ChangeReasonForm/ChangeReasonForm";
import { UserInformationKeyed } from "../../../../../../../../../lib/object/value/user-information-keyed";
import { UserInformationKeyedViewModel } from "../../../ApplicationDocument";
import { nextGroupLocalKey } from "../../../../../../../../../lib/util/app-util";
import { UserInformation } from "../../../../../../../../../lib/object/value/user-information";
import { ContactPersonCard } from "../../card/ContactPersonCard/ContactPersonCard";
import { useKeyedData } from "../../../../../../../../../hooks/use-keyed-data";
import { FormSection } from "../../../../../../../../ui/form/FormSection/FormSection";
import { useAppTranslation } from "../../../../../../../../../hooks/use-app-translation";
import { ChangeReasonItemViewModel } from "../../../../../../../../../lib/object/vm/change-application-view-model";
import useLinkCommentPath from "../../../../../../../../../hooks/use-link-comment-path";

export type ContactPeopleSectionProps = {
  documentType:
  | "new_application"
  | "change_application"
  | "project_force_update"
  | "project_force_create"
  | "project_content";
  editMode: "editable" | "readOnly";

  institutionId?: number;

  /**
   * 値。
   */
  value: UserInformationKeyedViewModel[];
  onChange?: (
    change: (
      before: UserInformationKeyedViewModel[],
    ) => UserInformationKeyedViewModel[],
  ) => void;

  changedFrom?: UserInformationKeyed[];
  changeReason?: string;
  onChangeReason?: (
    change: (before: ChangeReasonItemViewModel) => ChangeReasonItemViewModel,
  ) => void;
  displayChangesOnly?: boolean;

  /**
   * コメントボタンのprops。
   * これが存在すれば、コメントボタンを表示する。
   */
  commentButtonProps?: CMButtonFormCommentProps;
  /**
   * 1セクション単位の修正依頼コメント・返信。履歴参照画面で使用。
   */
  commentsList?: Comments[];

  /**
   * 子要素のコメントボタンのprops。
   * これが存在すれば、コメントボタンを表示する。
   * valueと同じ要素数であること。
   */
  commentButtonPropsChildren?: {
    key: number;
    value?: CMButtonFormCommentProps;
  }[];
  /**
   * 1セクション単位の修正依頼コメント・返信(子要素)。履歴参照画面で使用。
   */
  commentsListChildren?: Comments[];

  /**
   * 編集モード時、最初の一人目を編集不能にしたければtrueを設定する。
   */
  freezeFirst?: boolean;
} & ComponentStyleProps;

/**
 * 連絡担当者セクション
 */
export const ContactPeopleSection = memo(function ContactPeopleSection({
  documentType,
  editMode,
  institutionId,

  value,
  onChange,

  changedFrom,
  changeReason,
  onChangeReason,
  displayChangesOnly,

  commentButtonProps,
  commentsList,
  commentButtonPropsChildren,
  commentsListChildren,

  freezeFirst = false,

  sx,
  ...rest
}: ContactPeopleSectionProps) {
  const { t } = useAppTranslation();

  const { keyedValues: allValuesIncludingDeleted } = useKeyedData(
    value,
    changedFrom,
  );

  /**
   * 追加ボタン押下時に空の連絡担当者を追加する。
   */
  const handleAddContactPeople = useCallback(() => {
    const nextKey = nextGroupLocalKey(
      allValuesIncludingDeleted.map((v) => v.value),
    );
    onChange?.((before) => [...before, { key: nextKey }]);
  }, [allValuesIncludingDeleted, onChange]);

  /**
   * ユーザー選択時の処理。
   */
  const handleSelect = useCallback(
    (key: number, selectedValue: UserInformation | null) => {
      onChange?.((before) => {
        return before.map((v) =>
          v.key === key
            ? {
              key,
              ...selectedValue,
            }
            : v,
        );
      });
    },
    [onChange],
  );

  // モーダル制御
  const {
    isOpen: isOpenDeleteModal,
    onOpen: onOpenDeleteModal,
    onClose: onCloseDeleteModal,
  } = useDisclosure();

  /**
   * カード削除対象のカードインデックス
   */
  const [deletingKey, setDeletingKey] = useState(0);

  /**
   * カード削除ボタンが押下されると呼ばれ、モーダルを出現させる。
   * @param index
   */
  const handleDeleteButtonClick = useCallback(
    (key: number) => {
      setDeletingKey(key);
      onOpenDeleteModal();
    },
    [onOpenDeleteModal],
  );

  /**
   * モーダルの削除ボタンを押下すると呼ばれ、該当カードを削除する。
   */
  const handleConfirmDelete = useCallback(() => {
    onChange?.((before) => {
      return before.filter((v) => v.key !== deletingKey);
    });
    onCloseDeleteModal();
  }, [deletingKey, onChange, onCloseDeleteModal]);

  const sectionDiffMode = useSectionDiffMode(
    documentType,
    changedFrom,
    value,
    onChangeReason,
  );

  //commentsListをフィルタリングする関数
  const { filterCommetsListByEndWith } = useLinkCommentPath();

  return (
    <>
      <FormSection
        title={t("lbl.連絡担当者")}
        description={
          editMode === "editable" ? t("gdc.連絡担当者補足") : undefined
        }
        displayMode={sectionDiffMode}
        displayChangesOnly={displayChangesOnly}
        commentButtonProps={commentButtonProps}
        commentsList={commentsList}
        sx={sx}
        {...rest}
      >
        <CMSectionMultipleCardAddable
          editMode={editMode}
          onAddSection={handleAddContactPeople}
          buttonLabel={t("btn.連絡担当者を追加ボタン")}
        >
          <VStack spacing={"10px"} alignItems={"stretch"}>
            {allValuesIncludingDeleted.map(({ mode, value: v }, index) => (
              <ContactPersonCard
                key={v.key}
                documentType={documentType}
                editMode={editMode}
                mode={mode}
                institutionId={institutionId}
                value={v}
                index={index}
                freeze={freezeFirst && index === 0}
                onSelectUser={handleSelect}
                onDelete={handleDeleteButtonClick}
                changedFrom={changedFrom?.find((cf) => cf.key === v.key)}
                commentButtonProps={
                  commentButtonPropsChildren?.find((p) => p.key === v.key)
                    ?.value
                }
                /**
                 * 修正依頼履歴（子要素）
                 * commentButtonPropsChildrenのidをkeyで検索し
                 * commentsListChildrenのpathと突き合わせて必要な子要素を受け渡す 
                 */
                commentsList={
                  filterCommetsListByEndWith(
                    commentsListChildren,
                    commentButtonPropsChildren?.find((p) =>
                      p.key === v.key)?.value?.id)
                }

              />
            ))}
          </VStack>
        </CMSectionMultipleCardAddable>
        {sectionDiffMode === "updated" && (
          <ChangeReasonForm
            value={changeReason}
            onChange={onChangeReason}
            editMode={editMode}
          />
        )}
      </FormSection>
      {/*カード削除時に出現するモーダル*/}
      <DeleteConfirmationModal
        isOpen={isOpenDeleteModal}
        title={t("lbl.確認ポップアップタイトル")}
        message={t("mes.連絡担当者削除確認メッセージ")}
        deleteButtonLabel={t("btn.削除ボタン")}
        cancelButtonLabel={t("btn.キャンセルボタン")}
        onConfirm={handleConfirmDelete}
        onCancel={onCloseDeleteModal}
        onClose={onCloseDeleteModal}
      />
    </>
  );
});
