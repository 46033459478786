import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";
import React from "react";
import { numberOfSearchResultsBMeta } from "../../../../../../lib/object/value/number-of-search-results-b";
import { SearchDocumentApiArg } from "../../../../../../store/api/generated/stock-request-api";
import { dateTextToYmd } from "../../../../../../lib/util/common-date-util";
import {
  convertToNumberFromResultsB,
  convertToResultsBFromNumber,
} from "../../../../../../lib/util/search-util";
import { SearchConditionSidebar } from "../../../../../ui/search/SearchConditionSidebar/SearchConditionSidebar";
import { SearchConditionSidebarSection } from "../../../../../ui/search/SearchConditionSidebar/_components/SearchConditionSidebarSection/SearchConditionSidebarSection";
import { ButtonSearchConditionRadio } from "../../../../../model/search/button/ButtonSearchConditionRadio/ButtonSearchConditionRadio";
import { documentSortConditionMeta } from "../../../../../../lib/object/value/document-sort-condition";
import { ButtonSearchConditionText } from "../../../../../model/search/button/ButtonSearchConditionText/ButtonSearchConditionText";
import { ButtonSearchConditionCheckbox } from "../../../../../model/search/button/ButtonSearchConditionCheckbox/ButtonSearchConditionCheckbox";
import { ButtonSearchConditionDateRange } from "../../../../../model/search/button/ButtonSearchConditionDateRange/ButtonSearchConditionDateRange";
import { ButtonSearchConditionInstitution } from "../../../../../model/search/button/ButtonSearchConditionInstitution/ButtonSearchConditionInstitution";
import { documentTypeMeta } from "../../../../../../lib/object/value/document-type";
import { documentStateMeta } from "../../../../../../lib/object/value/document-state";
import { documentPositionMeta } from "../../../../../../lib/object/value/document-position";
import { reviewTypeMeta } from "../../../../../../lib/object/value/review-type";
import { resultMeta } from "../../../../../../lib/object/value/result";
import { ButtonSearchConditionProject } from "../../../../../model/search/button/ButtonSearchConditionProject/ButtonSearchConditionProject";
import { ButtonSearchConditionUser } from "../../../../../model/search/button/ButtonSearchConditionUser/ButtonSearchConditionUser";
import { ButtonSearchConditionRadioWithTodo } from "../../../../../model/search/button/ButtonSearchConditionRadioWithTodo/ButtonSearchConditionRadioWithTodo";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";

export type SearchConditionSidebarDocumentProps = {
  searchArgs: SearchDocumentApiArg;

  isFoundationMember: boolean;
  /**
   * 検索条件が変更されたタイミングで呼び出し元に値を返却する
   */
  onChange: (searchArgs: SearchDocumentApiArg) => void;
} & ComponentStyleProps;

export const SearchConditionSidebarDocument = ({
  searchArgs,
  isFoundationMember,

  onChange,

  sx,
  ...rest
}: SearchConditionSidebarDocumentProps) => {
  const { t } = useAppTranslation();

  return (
    <SearchConditionSidebar sx={sx} {...rest}>
      <SearchConditionSidebarSection title={t("lbl.並び順・表示件数")}>
        <ButtonSearchConditionRadioWithTodo
          label={t("lbl.並び順")}
          todosFirst={searchArgs.todosFirst}
          value={searchArgs.sortCondition}
          valueObjectMeta={documentSortConditionMeta}
          onChange={(todosFirst, value) => {
            onChange({
              ...searchArgs,
              todosFirst,
              sortCondition: value,
            });
          }}
        />
        <ButtonSearchConditionRadio
          label={t("lbl.表示件数")}
          value={convertToResultsBFromNumber(searchArgs.limit)}
          valueObjectMeta={numberOfSearchResultsBMeta}
          onChange={(value: string | undefined) => {
            onChange({
              ...searchArgs,
              limit: convertToNumberFromResultsB(value),
            });
          }}
        />
      </SearchConditionSidebarSection>
      <SearchConditionSidebarSection title={t("lbl.書類")}>
        <ButtonSearchConditionText
          value={searchArgs.documentControlNumber}
          label={t("lbl.書類管理番号")}
          placeholder={""}
          onChange={(value: string | undefined) => {
            onChange({
              ...searchArgs,
              documentControlNumber: value,
            });
          }}
        />
        <ButtonSearchConditionCheckbox
          values={searchArgs.documentType}
          label={t("lbl.書類種別")}
          valueObjectMeta={documentTypeMeta}
          onChange={(value) => {
            onChange({
              ...searchArgs,
              documentType: value,
            });
          }}
        />
        <ButtonSearchConditionCheckbox
          values={searchArgs.documentState}
          label={t("lbl.書類状態")}
          valueObjectMeta={documentStateMeta}
          onChange={(value) => {
            onChange({
              ...searchArgs,
              documentState: value,
            });
          }}
        />
        <ButtonSearchConditionCheckbox
          values={searchArgs.documentPosition}
          label={t("lbl.書類位置")}
          valueObjectMeta={documentPositionMeta}
          onChange={(value) => {
            onChange({
              ...searchArgs,
              documentPosition: value,
            });
          }}
        />
        {isFoundationMember && (
          <ButtonSearchConditionCheckbox
            values={searchArgs.reviewType}
            label={t("lbl.審査種別")}
            valueObjectMeta={reviewTypeMeta}
            onChange={(value) => {
              onChange({
                ...searchArgs,
                reviewType: value,
              });
            }}
          />
        )}
        <ButtonSearchConditionCheckbox
          values={searchArgs.result}
          label={t("lbl.結果")}
          valueObjectMeta={resultMeta}
          onChange={(value) => {
            onChange({
              ...searchArgs,
              result: value,
            });
          }}
        />
        <ButtonSearchConditionDateRange
          value={{
            start: dateTextToYmd(searchArgs.submissionDateFrom),
            end: dateTextToYmd(searchArgs.submissionDateTo),
          }}
          label={t("lbl.提出日")}
          onChange={(start: string | undefined, end: string | undefined) => {
            onChange({
              ...searchArgs,
              submissionDateFrom: start,
              submissionDateTo: end,
            });
          }}
        />
        <ButtonSearchConditionDateRange
          value={{
            start: dateTextToYmd(searchArgs.receptionDateFrom),
            end: dateTextToYmd(searchArgs.receptionDateTo),
          }}
          label={t("lbl.受付日")}
          onChange={(start: string | undefined, end: string | undefined) => {
            onChange({
              ...searchArgs,
              receptionDateFrom: start,
              receptionDateTo: end,
            });
          }}
        />
        <ButtonSearchConditionDateRange
          value={{
            start: dateTextToYmd(searchArgs.approvalDateFrom),
            end: dateTextToYmd(searchArgs.approvalDateTo),
          }}
          label={t("lbl.承認日")}
          onChange={(start: string | undefined, end: string | undefined) => {
            onChange({
              ...searchArgs,
              approvalDateFrom: start,
              approvalDateTo: end,
            });
          }}
        />
        <ButtonSearchConditionDateRange
          value={{
            start: dateTextToYmd(searchArgs.completionDateFrom),
            end: dateTextToYmd(searchArgs.completionDateTo),
          }}
          label={t("lbl.完了日")}
          onChange={(start: string | undefined, end: string | undefined) => {
            onChange({
              ...searchArgs,
              completionDateFrom: start,
              completionDateTo: end,
            });
          }}
        />
      </SearchConditionSidebarSection>

      <SearchConditionSidebarSection title={t("lbl.案件")}>
        <ButtonSearchConditionProject
          value={searchArgs.projectId}
          label={t("lbl.案件管理番号")}
          searchResultNone={t("mes.該当案件なしメッセージ")}
          onChange={(value: number | null) => {
            onChange({
              ...searchArgs,
              projectId: value ?? undefined,
            });
          }}
        />
        <ButtonSearchConditionText
          value={searchArgs.projectName}
          label={t("lbl.課題名")}
          onChange={(value: string | undefined) => {
            onChange({
              ...searchArgs,
              projectName: value,
            });
          }}
        />
        {isFoundationMember && (
          <ButtonSearchConditionInstitution
            value={searchArgs.institutionId}
            label={t("lbl.機関名")}
            onChange={(value: number | null) => {
              onChange({
                ...searchArgs,
                institutionId: value ?? undefined,
              });
            }}
          />
        )}
        {isFoundationMember && (
          <ButtonSearchConditionUser
            value={searchArgs.principalInvestigatorAppUserId}
            label={t("lbl.研究責任者名")}
            searchResultNone={t("mes.該当ユーザーなしメッセージ")}
            onChange={(value: number | null) => {
              onChange({
                ...searchArgs,
                principalInvestigatorAppUserId: value ?? undefined,
              });
            }}
          />
        )}
      </SearchConditionSidebarSection>
    </SearchConditionSidebar>
  );
};
