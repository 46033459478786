import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * 質疑応答既読状況(値オブジェクト)
 *
 *
 *
 * unread:
 * read:  */
export type InquiryReadState = z.infer<typeof inquiryReadStateMeta.schema>;
/**
 * 質疑応答既読状況(値オブジェクト)のメタデータ。
 *
 *  */
export const inquiryReadStateMeta = new ValueObjectMetaEnum<["unread", "read"]>(
  "InquiryReadState",
  "\u8CEA\u7591\u5FDC\u7B54\u65E2\u8AAD\u72B6\u6CC1",
  messageId("lbl.\u8CEA\u7591\u5FDC\u7B54\u65E2\u8AAD\u72B6\u6CC1"),
  ["unread", "read"],
  {
    unread:
      "code.\u8CEA\u7591\u5FDC\u7B54\u65E2\u8AAD\u72B6\u6CC1.\u672A\u8AAD",
    read: "code.\u8CEA\u7591\u5FDC\u7B54\u65E2\u8AAD\u72B6\u6CC1.\u65E2\u8AAD",
  }
);
