import { useDisclosure } from "@chakra-ui/react";
import React, { useCallback, useMemo } from "react";
import { CMModalOptionsCancel } from "@pscsrvlab/psc-react-components";
import { useAppTranslation } from "../../../../hooks/use-app-translation";

// モーダルが閉じられた際の操作
export type CloseResult = "OK" | "CANCEL";

export type PromiseModalOptionsCancelProps = {
  // 表示ラベルの設定
  labelHeader?: string;
  labelBody?: string;
  labelSubmitButton?: string;
  labelCancelButton?: string;
  submitButtonColorScheme?: string;
  CancelButtonColorScheme?: string;

  /**
   * モーダル表示設定
   */
  isOpenModal?: boolean;

  /**
   * モーダルを閉じたときのコールバック
   */
  onCloseModal?: (result: CloseResult) => void;
};

/**
 * キャンセル可アラートモーダルを表示するコンポーネント
 * モーダルを閉じた処理を呼び出し元で受け取る際に使用する
 */
export const PromiseModalOptionsCancel = ({
  labelHeader,
  labelBody,
  labelSubmitButton,
  labelCancelButton,
  submitButtonColorScheme = "teal",
  CancelButtonColorScheme = "gray",

  isOpenModal = true,
  onCloseModal,
}: PromiseModalOptionsCancelProps) => {
  const { t } = useAppTranslation();

  // 開閉設定
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: isOpenModal });

  // 表示ラベルの設定
  const header = useMemo(
    () => labelHeader ?? t("lbl.確認ポップアップタイトル"),
    [labelHeader, t],
  );

  const body = useMemo(() => labelBody ?? "", [labelBody]);
  const submitButton = useMemo(
    () => labelSubmitButton ?? t("btn.OKボタン"),
    [labelSubmitButton, t],
  );
  const cancelButton = useMemo(
    () => labelCancelButton ?? t("btn.キャンセルボタン"),
    [labelCancelButton, t],
  );

  const handleCloseModal = useCallback(
    (result: CloseResult) => {
      // モーダルを閉じる
      onClose();

      // 呼び出し元に結果を通知
      onCloseModal?.(result);
    },
    [onClose, onCloseModal],
  );

  return (
    <>
      <CMModalOptionsCancel
        isOpen={isOpen}
        labelHeader={header}
        labelBody={body}
        labelSubmitButton={submitButton}
        submitButtonColorScheme={submitButtonColorScheme}
        labelCancelButton={cancelButton}
        CancelButtonColorScheme={CancelButtonColorScheme}
        onSubmit={() => {
          handleCloseModal("OK");
        }}
        onCancel={() => {
          handleCloseModal("CANCEL");
        }}
      />
    </>
  );
};
