import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * 検索結果件数A(値オブジェクト)
 *
 *
 *
 * n5:
 * n10:
 * n20:
 * n50:  */
export type NumberOfSearchResultsA = z.infer<
  typeof numberOfSearchResultsAMeta.schema
>;
/**
 * 検索結果件数A(値オブジェクト)のメタデータ。
 *
 *  */
export const numberOfSearchResultsAMeta = new ValueObjectMetaEnum<
  ["n5", "n10", "n20", "n50"]
>(
  "NumberOfSearchResultsA",
  "\u691C\u7D22\u7D50\u679C\u4EF6\u6570A",
  messageId("lbl.\u691C\u7D22\u7D50\u679C\u4EF6\u6570A"),
  ["n5", "n10", "n20", "n50"],
  {
    n5: "code.\u691C\u7D22\u7D50\u679C\u4EF6\u6570.5\u4EF6",
    n10: "code.\u691C\u7D22\u7D50\u679C\u4EF6\u6570.10\u4EF6",
    n20: "code.\u691C\u7D22\u7D50\u679C\u4EF6\u6570.20\u4EF6",
    n50: "code.\u691C\u7D22\u7D50\u679C\u4EF6\u6570.50\u4EF6",
  }
);
