import { CMFormFileUpload } from "@pscsrvlab/psc-react-components";
import React, { useCallback, useMemo, useState } from "react";
import {
  CloseResult,
  PromiseModalOptionsCancel,
  PromiseModalOptionsCancelProps,
} from "../../../../../ui/modal/PromiseModalOptionsCancel/PromiseModalOptionsCancel";
import { isNullish } from "../../../../../../lib/util/common-util";
import { UploadButtonEmpty } from "../../../../../ui/button/UploadButtonEmpty/UploadButtonEmpty";
import useFileUpload from "../../../../../../hooks/use-file-upload";
import { AttachmentFileInformation } from "../../../../../../lib/object/value/attachment-file-information";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { attachmentFileToFileUploadItem } from "../../../../../../lib/util/app-util";
import { FormSection } from "../../../../../ui/form/FormSection/FormSection";
import { useFileModalContext } from "../../../../../ui/modal/FileModal/FileModal";

export type SectionOfficeChecklistProps = {
  /**
   * 添付ファイル
   */
  files: AttachmentFileInformation[];

  /**
   * 画面編集モード
   */
  editMode: "editable" | "readOnly";

  onChange: (
    change: (
      before: AttachmentFileInformation[],
    ) => AttachmentFileInformation[],
  ) => void;
};

/**
 * FN40-S30審査
 * 添付資料セクション
 */
export const SectionOfficeChecklist = ({
  editMode = "readOnly",
  files,
  onChange,
}: SectionOfficeChecklistProps) => {
  const { t } = useAppTranslation();

  const { fileUpload } = useFileUpload();
  const { openFileModal } = useFileModalContext()

  const fileItems = useMemo(() => {
    return files.map((v) => attachmentFileToFileUploadItem(t, v));
  }, [t, files]);

  /**
   * ファイルアップロード処理
   */
  const handleAddFile = useCallback(
    async (
      _sectionId: string,
      subSectionId: "office_checklist_for_application",
      file: File,
    ) => {
      const response = await fileUpload(file, "private", null, null);
      if (isNullish(response)) return;

      const newFile: AttachmentFileInformation = {
        storageFileId: response.id,
        attachmentFileType: subSectionId,
        attachmentFileName: file.name,
        uploadedAt: response.created.datetime,
      };

      onChange((before) => [...before, newFile]);
    },
    [fileUpload, onChange],
  );

  /**
   * ファイルダウンロード処理
   */
  const handleDownloadFile = useCallback(
    (fileId: string, fileName: string) => {
      // ダウンロード処理の呼び出し
      void openFileModal(fileId, fileName);
    },
    [openFileModal],
  );

  // 削除確認モーダルの表示制御設定
  const [deleteConfirmModalConfig, setDeleteConfirmModalConfig] =
    useState<PromiseModalOptionsCancelProps>();

  /**
   * 添付ファイル削除時に呼ばれる。
   */
  const handleDeleteFile = useCallback(
    async (_sectionId: string, _subSectionId: string, fileId: string) => {
      const result = await new Promise<CloseResult>((resolve) => {
        setDeleteConfirmModalConfig({
          onCloseModal: resolve,
        });
      });
      setDeleteConfirmModalConfig(undefined);
      if (result !== "OK") return;

      onChange((before) =>
        before.filter((value) => value?.storageFileId?.toString() !== fileId),
      );
    },
    [onChange],
  );

  return (
    <>
      <FormSection title={t("lbl.添付資料")}>
        <CMFormFileUpload
          onAddFile={handleAddFile}
          onDeleteFile={handleDeleteFile}
          onDownloadFile={handleDownloadFile}
          value={{
            id: "cell_delivery",
            title: "",
            subSectionContents: [
              {
                editMode: editMode,
                id: "office_checklist_for_application",
                title: t("lbl.申請用事務局チェックリスト"),
                childrenButton: <UploadButtonEmpty />,
                files: fileItems,
              },
            ],
          }}
        />
      </FormSection>

      {/*添付ファイル削除確認メッセージ*/}
      {deleteConfirmModalConfig && (
        <PromiseModalOptionsCancel
          labelBody={t("mes.添付資料削除確認メッセージ")}
          onCloseModal={deleteConfirmModalConfig.onCloseModal}
        />
      )}
    </>
  );
};
