import {
  messageId,
  PropertyMeta,
  ValueObjectMetaComposite,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { attachmentFileInformationMeta } from "../value/attachment-file-information";
/**
 * 情報公開文書(値オブジェクト)
 *
 *  */
export type DisclosureDocuments = z.infer<typeof schema>;
export type DisclosureDocumentsSaved = z.infer<typeof savedSchema>;
const minimalSchema = z.object({
  files: z.array(attachmentFileInformationMeta.minimalSchema),
});
const savedMinimalSchema = z.object({
  files: z.array(attachmentFileInformationMeta.savedMinimalSchema),
});
const schema = z.object({
  files: z.array(attachmentFileInformationMeta.schema),
});
const savedSchema = z.object({
  files: z.array(attachmentFileInformationMeta.savedSchema),
});
const jsonSchema = z.object({
  files: z
    .array(attachmentFileInformationMeta.jsonSchema)
    .nullish()
    .transform((x) => x ?? undefined),
});
/**
 * 情報公開文書(値オブジェクト)のメタデータ。
 *
 *  */
export const disclosureDocumentsMeta = new ValueObjectMetaComposite(
  "DisclosureDocuments",
  "\u60C5\u5831\u516C\u958B\u6587\u66F8",
  messageId("lbl.\u60C5\u5831\u516C\u958B\u6587\u66F8"),
  minimalSchema,
  savedMinimalSchema,
  schema,
  savedSchema,
  jsonSchema,
  undefined,
  undefined,
  {
    files: new PropertyMeta(
      "files",
      "\u30D5\u30A1\u30A4\u30EB\u60C5\u5831",
      messageId("lbl.\u30D5\u30A1\u30A4\u30EB\u60C5\u5831"),
      "always",
      true,
      attachmentFileInformationMeta
    ),
  }
);
