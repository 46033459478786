import { IpsCellStockUsePurpose } from "../value/ips-cell-stock-use-purpose";
import { ErrorMessageObject, messageId } from "@pscsrvlab/psc-react-components";
import { hasValue } from "../../util/common-util";

export function ipsCellStockUsePurposeCustomValidation(
  value: IpsCellStockUsePurpose,
  skipOptional: boolean,
): ErrorMessageObject[] {
  const mesId = messageId("mes.iPS細胞の使用目的エラー");
  /**
   * ヒトへの投与なしが選択されているにもかかわらず、セルバンクの作製、臨床研究・治験が選択されている場合のエラー。
   */
  if (
    !skipOptional &&
    value.purposeType === "research" &&
    hasValue(value.purposeSubType)
  ) {
    return [{ errorMessageId: mesId }];
  }
  return [];
}
