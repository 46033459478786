import { ComponentStyleProps } from "../../../../../lib/styles/props/component-style-props";
import { Container, Flex, Text, VStack } from "@chakra-ui/react";
import { hasValue, isNullish } from "../../../../../lib/util/common-util";
import { useAppGetResultNotificationQuery } from "../../../../../hooks/query/use-app-get-result-notification-query";
import { ResultNotificationBody } from "./ResultNotificationBody";
import React, { useMemo } from "react";
import { useAppTranslation } from "../../../../../hooks/use-app-translation";
import { useSavedDocument } from "../../../../../hooks/document/use-saved-document";

export type ResultNotificationReadOnlyProps = {
  resultNotificationId: number;
} & ComponentStyleProps;

export const ResultNotificationPageReadOnly = ({
  resultNotificationId,

  sx,
  ...rest
}: ResultNotificationReadOnlyProps) => {
  const { t } = useAppTranslation();

  const { data: resultNotification } = useAppGetResultNotificationQuery({
    resultNotificationId,
  });
  const { document } = useSavedDocument(resultNotification?.documentId);

  const isConfirmationNote = useMemo(() => {
    if (isNullish(resultNotification)) return undefined;
    return (
      resultNotification.resultNotificationType ===
        "application_condition_confirmation" &&
      resultNotification.result === "approved"
    );
  }, [resultNotification]);

  return (
    <>
      {hasValue(resultNotification) && hasValue(isConfirmationNote) && (
        <Flex
          direction={"column"}
          alignItems={"center"}
          flex={"1 1 auto"}
          minW={0}
          overflow={"auto"}
          pt={"20px"}
          pb={"80px"}
          sx={sx}
          {...rest}
        >
          <Container maxW={"720px"} mb={"8px"}>
            <VStack alignItems={"flex-start"} pl={"4px"}>
              {isConfirmationNote && (
                <Text>{t("gdc.結果通知案内(条件対応確認後)")}</Text>
              )}
              {!isConfirmationNote &&
                hasValue(document) &&
                document.isApplication && (
                  <>
                    {(resultNotification.result === "approved" ||
                      resultNotification.result === "no_need_to_review" ||
                      resultNotification.result === "rejected") && (
                      <Text>{t("gdc.結果通知案内(承認、審査不要)")}</Text>
                    )}
                    {(resultNotification.result === "conditionally_approved" ||
                      resultNotification.result === "reexamination") && (
                      <Text>
                        {t("gdc.結果通知案内(条件付承認、保留・再審査)")}
                      </Text>
                    )}
                  </>
                )}
            </VStack>
          </Container>
          <Text fontSize={"2xl"} fontWeight={"bold"} mb={"12px"}>
            {isConfirmationNote
              ? t("lbl.確認書タイトル")
              : t("lbl.結果通知タイトル")}
          </Text>
          <ResultNotificationBody
            editMode={"readOnly"}
            vm={resultNotification}
          />
          {(resultNotification.result === "approved" ||
            resultNotification.result === "conditionally_approved") && (
            <Container maxW={"720px"} mt={"12px"}>
              <VStack alignItems={"flex-start"} pl={"4px"}>
                <Text color={"gray.900"}>{t("gdc.細胞株使用案内1")}</Text>
                <Text color={"red.500"} whiteSpace={"pre-wrap"}>
                  {t("gdc.細胞株使用案内2")}
                </Text>
              </VStack>
            </Container>
          )}
        </Flex>
      )}
    </>
  );
};
