import { ComponentStyleProps } from "../../../../../lib/styles/props/component-style-props";
import { Container, VStack } from "@chakra-ui/react";
import { ResultNotificationSectionResult } from "../section/ResultNotificationSectionResult";
import { hasValue } from "../../../../../lib/util/common-util";
import { ResultNotificationSectionReviewDate } from "../section/ResultNotificationSectionReviewDate";
import { ResultNotificationSectionApprovalConditionConfirmationDate } from "../section/ResultNotificationSectionApprovalConditionConfirmationDate";
import { ResultNotificationSectionUnapprovalReason } from "../section/ResultNotificationSectionUnapprovalReason";
import { ResultNotificationSectionNote } from "../section/ResultNotificationSectionNote";
import { ResultNotificationSectionExecutiveDirectorApproval } from "../section/ResultNotificationSectionExecutiveDirectorApproval";
import { ResultNotificationViewModel } from "../../../../../lib/object/vm/result-notification-view-model";
import { useMemo } from "react";

export type ResultNotificationBodyProps = {
  editMode: "editable" | "readOnly";

  vm: ResultNotificationViewModel["contentVM"];
  onChange?: (
    change: (
      before: ResultNotificationViewModel["contentVM"],
    ) => ResultNotificationViewModel["contentVM"],
  ) => void;
} & ComponentStyleProps;

export const ResultNotificationBody = ({
  editMode,

  vm,
  onChange,

  sx,
  ...rest
}: ResultNotificationBodyProps) => {
  const type = useMemo(
    () => vm.resultNotificationType,
    [vm.resultNotificationType],
  );
  return (
    <Container
      maxW={"720px"}
      className={"ResultNotificationBody"}
      sx={sx}
      {...rest}
    >
      <VStack alignItems={"stretch"}>
        <ResultNotificationSectionResult
          editMode={editMode}
          documentId={vm.documentId}
          value={vm.result}
          onChange={(v) => onChange?.((before) => ({ ...before, result: v }))}
        />
        {type === "application_approve_or_conditional" &&
          hasValue(vm.reviewDate) && (
            <ResultNotificationSectionReviewDate value={vm.reviewDate} />
          )}
        {type === "application_condition_confirmation" && (
          <ResultNotificationSectionApprovalConditionConfirmationDate
            editMode={editMode}
            value={vm.approvalConditionConfirmationDate}
            onChange={(v) =>
              onChange?.((before) => ({
                ...before,
                approvalConditionConfirmationDate: v,
              }))
            }
          />
        )}
        {(type === "application_no_review" ||
          type === "application_approve_or_conditional" ||
          type === "application_condition_confirmation" ||
          type === "application_rereview") && (
          <ResultNotificationSectionUnapprovalReason
            resultNotificationType={type}
            editMode={editMode}
            value={vm.unapprovalReason}
            onChange={(v) =>
              onChange?.((before) => ({ ...before, unapprovalReason: v }))
            }
          />
        )}
        <ResultNotificationSectionNote
          editMode={editMode}
          value={vm.note}
          onChange={(v) => onChange?.((before) => ({ ...before, note: v }))}
        />
        {type === "application_approve_or_conditional" &&
          hasValue(vm.executiveDirectorApproval) && (
            <ResultNotificationSectionExecutiveDirectorApproval
              value={vm.executiveDirectorApproval}
            />
          )}
      </VStack>
    </Container>
  );
};
