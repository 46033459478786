import { ProjectContentDisclosureDocument } from "../../../model/project/FN30S08ProjectContentDisclosureDocument/ProjectContentDisclosureDocument";
import { useAppParams } from "../../../../hooks/use-app-params";
import { hasValue } from "../../../../lib/util/common-util";

/**
 * FN30-S08情報公開文書
 */
export const FN30S08ProjectContentDisclosureDocument = () => {
  const { projectId } = useAppParams();

  return (
    <>
      {hasValue(projectId) && (
        <ProjectContentDisclosureDocument projectId={projectId} />
      )}
    </>
  );
};
