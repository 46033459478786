import { isNullish } from "../../../lib/util/common-util";
import { merge } from "lodash";
import { useMemo } from "react";
import { useAppGetAnnualReportQuery } from "../../query/use-app-get-annual-report-query";
import { useAnnualReportVMOrEmpty } from "./use-annual-report-vm";

/**
 * 年次報告の表示内容を取得する。
 *
 * 書類IDを指定しなかった場合、
 * - その案件から年次報告を新規作成する場合の内容を取得する。
 * - 保存時は、新規作成オペレーションを呼び出す。
 *
 * 書類IDを指定した場合、
 * - その書類IDの年次報告の内容を取得する。
 * - 保存時は、更新オペレーションを呼び出す。
 */
export function useAnnualReport(
  /**
   * 案件ID。
   */
  projectId: number,

  /**
   * 書類ID。
   * この値がない場合、
   * 案件IDから新規の（空の）年次報告ビューモデルを作成する。
   */
  documentId: number | undefined,

  options?: {
    /**
     * skipがtrueの場合、何も行わない。
     */
    skip: boolean;
  },
) {
  const _options = useMemo(() => merge({ skip: false }, options), [options]);

  // 書類IDが指定されている場合、年次報告を取得する。
  const { data: annualReport } = useAppGetAnnualReportQuery(
    { documentId: documentId ?? -1 },
    { skip: _options.skip || isNullish(documentId) },
  );

  return useAnnualReportVMOrEmpty(
    projectId,
    documentId,
    annualReport,
    _options,
  );
}
