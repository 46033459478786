import { ProblemsDuringThePeriod } from "../value/problems-during-the-period";
import { ErrorMessageObject, messageId } from "@pscsrvlab/psc-react-components";
import { isNullish } from "../../util/common-util";

export function problemsDuringThePeriodCustomValidation(
  value: ProblemsDuringThePeriod,
  skipOptional: boolean,
): ErrorMessageObject[] {
  const errorMessageList = [];
  if (
    !skipOptional &&
    value.problemPresence === "has_problem" &&
    isNullish(value.problemDetails)
  ) {
    const mesId = messageId("mes.使用終了問題点有無問題内容未入力エラー");
    errorMessageList.push({ errorMessageId: mesId });
  }
  if (
    !skipOptional &&
    value.problemPresence === "has_problem" &&
    isNullish(value.responseToProblem)
  ) {
    const mesId = messageId("mes.使用終了問題点有無問題対応未入力エラー");
    errorMessageList.push({ errorMessageId: mesId });
  }
  return errorMessageList;
}
