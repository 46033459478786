import {
  messageId,
  PropertyMeta,
  ValueObjectMetaString,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.string().max(10000);
const savedMinimalSchema = z.string().max(10000);
const schema = z.string().min(1).max(10000);
const savedSchema = z.string().min(1).max(10000);
const jsonSchema = z.string();
/**
 * 分担機関研究計画書課題名(値オブジェクト)のメタデータ。
 *
 *  */
export const collaborativePartnerResearchPlanProjectNameMeta =
  new ValueObjectMetaString(
    "CollaborativePartnerResearchPlanProjectName",
    "\u5206\u62C5\u6A5F\u95A2\u7814\u7A76\u8A08\u753B\u66F8\u8AB2\u984C\u540D",
    messageId("lbl.\u7814\u7A76\u8A08\u753B\u66F8\u8AB2\u984C\u540D"),
    minimalSchema,
    schema,
    jsonSchema,
    undefined,
    undefined
  );
