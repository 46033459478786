import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * 審査結果(値オブジェクト)
 *
 *
 *
 * approved:
 * conditionally_approved:
 * reexamination:  */
export type ReviewResult = z.infer<typeof reviewResultMeta.schema>;
/**
 * 審査結果(値オブジェクト)のメタデータ。
 *
 *  */
export const reviewResultMeta = new ValueObjectMetaEnum<
  ["approved", "conditionally_approved", "reexamination"]
>(
  "ReviewResult",
  "\u5BE9\u67FB\u7D50\u679C",
  messageId("lbl.\u5BE9\u67FB\u7D50\u679C"),
  ["approved", "conditionally_approved", "reexamination"],
  {
    approved: "code.\u5BE9\u67FB\u7D50\u679C.\u627F\u8A8D",
    conditionally_approved:
      "code.\u5BE9\u67FB\u7D50\u679C.\u6761\u4EF6\u4ED8\u627F\u8A8D",
    reexamination:
      "code.\u5BE9\u67FB\u7D50\u679C.\u4FDD\u7559\u30FB\u518D\u5BE9\u67FB",
  }
);
