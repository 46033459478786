import {
  messageId,
  PropertyMeta,
  ValueObjectMetaString,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.string().max(10000);
const savedMinimalSchema = z.string().max(10000);
const schema = z.string().min(1).max(10000);
const savedSchema = z.string().min(1).max(10000);
const jsonSchema = z.string();
/**
 * 遵守できなかった理由(値オブジェクト)のメタデータ。
 *
 *  */
export const reasonsForNotBeingAbleToComplyMeta = new ValueObjectMetaString(
  "ReasonsForNotBeingAbleToComply",
  "\u9075\u5B88\u3067\u304D\u306A\u304B\u3063\u305F\u7406\u7531",
  messageId("lbl.\u9075\u5B88\u3067\u304D\u306A\u304B\u3063\u305F\u7406\u7531"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
