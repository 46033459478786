import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import { CMButton } from "@pscsrvlab/psc-react-components";
import { hasValue, isNullish } from "../../../../lib/util/common-util";
import { useNavigate } from "react-router-dom";
import { useMemo } from "react";
import { useAppTranslation } from "../../../../hooks/use-app-translation";
import { useAppParams } from "../../../../hooks/use-app-params";
import { useAppGetDocumentQuery } from "../../../../hooks/query/use-app-get-document-query";
import { LabeledTextVertical } from "../../../ui/text/LabeledTextVertical/LabeledTextVertical";
import { ymdToDateText } from "../../../../lib/util/common-date-util";

export const FN40S18OfficeMemberCheckComplete = () => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();

  const { documentId } = useAppParams();

  const { data: documentData } = useAppGetDocumentQuery(
    {
      documentId: documentId ?? -1,
    },
    { skip: isNullish(documentId) },
  );

  const documentControlNumber = useMemo(() => {
    return documentData?.documentControlNumber;
  }, [documentData]);

  const officeMemberCheckDateText = useMemo(() => {
    if (
      hasValue(documentData) &&
      hasValue(documentData.officeMemberCheckDate)
    ) {
      return ymdToDateText(documentData.officeMemberCheckDate);
    }
    return undefined;
  }, [documentData]);

  const handleClickBack = () => {
    navigate(`/document/${documentId}/office-member-check`);
  };

  return (
    <Box>
      {hasValue(documentControlNumber) && (
        <VStack spacing={"20px"} mt={"50px"}>
          <Box fontSize={"2xl"} fontWeight={"bold"}>
            {t("mes.事務局確認完了メッセージ")}
          </Box>
          <Text whiteSpace={"pre-wrap"}>
            {t("gdc.事務局確認後案内", { 書類管理番号: documentControlNumber })}
          </Text>
          <HStack>
            <LabeledTextVertical
              label={t("lbl.書類管理番号")}
              text={documentControlNumber}
            />
            <LabeledTextVertical
              label={t("lbl.確認日")}
              text={officeMemberCheckDateText}
            />
          </HStack>
          <CMButton
            label={t("btn.事務局確認へ戻るボタン")}
            onClick={handleClickBack}
          />
        </VStack>
      )}
    </Box>
  );
};
