import {
  messageId,
  PropertyMeta,
  ValueObjectMetaString,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.string().max(300);
const savedMinimalSchema = z.string().max(300);
const schema = z.string().min(1).max(300);
const savedSchema = z.string().min(1).max(300);
const jsonSchema = z.string();
/**
 * 所属・職名(値オブジェクト)のメタデータ。
 *
 *  */
export const titleAndPositionMeta = new ValueObjectMetaString(
  "TitleAndPosition",
  "\u6240\u5C5E\u30FB\u8077\u540D",
  messageId("lbl.\u6240\u5C5E\u30FB\u8077\u540D"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
