import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * 書類種別(値オブジェクト)
 *
 *
 *
 * new_application:
 * change_application:
 * annual_report:
 * termination_report:  */
export type DocumentType = z.infer<typeof documentTypeMeta.schema>;
/**
 * 書類種別(値オブジェクト)のメタデータ。
 *
 *  */
export const documentTypeMeta = new ValueObjectMetaEnum<
  [
    "new_application",
    "change_application",
    "annual_report",
    "termination_report"
  ]
>(
  "DocumentType",
  "\u66F8\u985E\u7A2E\u5225",
  messageId("lbl.\u66F8\u985E\u7A2E\u5225"),
  [
    "new_application",
    "change_application",
    "annual_report",
    "termination_report",
  ],
  {
    new_application: "code.\u66F8\u985E\u7A2E\u5225.\u65B0\u898F\u7533\u8ACB",
    change_application:
      "code.\u66F8\u985E\u7A2E\u5225.\u5909\u66F4\u7533\u8ACB",
    annual_report: "code.\u66F8\u985E\u7A2E\u5225.\u5E74\u6B21\u5831\u544A",
    termination_report:
      "code.\u66F8\u985E\u7A2E\u5225.\u4F7F\u7528\u7D42\u4E86\u5831\u544A",
  }
);
