import { ComponentStyleProps } from "../../../../../../../lib/styles/props/component-style-props";
import { chakra, Select } from "@chakra-ui/react";
import React from "react";
import { useAppTranslation } from "../../../../../../../hooks/use-app-translation";

export type SharedAttachmentFilesSectionSortCondition =
  | "uploaded_at_asc"
  | "uploaded_at_desc"
  | "name_asc"
  | "name_desc";

export type SharedAttachmentFilesSectionSortConditionSelectProps = {
  value: SharedAttachmentFilesSectionSortCondition;
  onChange: (value: SharedAttachmentFilesSectionSortCondition) => void;
} & ComponentStyleProps;

export const SharedAttachmentFilesSectionSortConditionSelect = ({
  value,
  onChange,
}: SharedAttachmentFilesSectionSortConditionSelectProps) => {
  const { t } = useAppTranslation();

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    // 未選択の場合はnullとする
    const v = e.target.value === "" ? null : e.target.value;
    if (
      v === "uploaded_at_asc" ||
      v === "uploaded_at_desc" ||
      v === "name_asc" ||
      v === "name_desc"
    )
      onChange?.(v);
    else onChange?.("uploaded_at_desc");
  };

  return (
    <Select maxW={"120px"} size={"xs"} value={value} onChange={handleChange}>
      <chakra.option value="uploaded_at_asc">
        {t("lbl.並び順登録日時昇順")}
      </chakra.option>
      <chakra.option value="uploaded_at_desc">
        {t("lbl.並び順登録日時降順")}
      </chakra.option>
      <chakra.option value="name_asc">{t("lbl.並び順名前昇順")}</chakra.option>
      <chakra.option value="name_desc">{t("lbl.並び順名前降順")}</chakra.option>
    </Select>
  );
};
