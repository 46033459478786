import { Box, Button, Container, Text, VStack } from "@chakra-ui/react";
import { FormEvent, useEffect, useState } from "react";
import { useAppDispatch } from "../../../../hooks/redux-hooks";
import { resetPasswordSubmit } from "../../../../store/auth/slice";
import { Form, useLocation, useNavigate } from "react-router-dom";
import log from "loglevel";
import { hasValue } from "../../../../lib/util/common-util";
import useCustomToast from "../../../../hooks/use-custom-toast";
import {
  CMButtonBack,
  CMFormInputPassword,
} from "@pscsrvlab/psc-react-components";
import { PasswordPolicy } from "../../../model/user/PasswordPolicy/PasswordPolicy";
import { isValidPassword } from "../../../../lib/util/password-util";
import { errorNameAndMessageOf } from "../../../../lib/util/error-util";
import { useAppTranslation } from "../../../../hooks/use-app-translation";
import { FrameUpperLeftButton } from "../../../ui/frame/FrameUpperLeftButton/FrameUpperLeftButton";

/**
 * パスワードリセットページ
 */
export const FN10S06ResetPasswordConfirm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useAppTranslation();
  const { errorToast, successToast } = useCustomToast();

  // 検証コード
  const [code, setCode] = useState("");
  // メールアドレス
  const [mailAddress, setMailAddress] = useState("");
  // 新しいパスワード
  const [newPassword, setNewPassword] = useState("");
  // 新しいパスワード(確認)
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  // ロード状態
  const [isLoading, setIsLoading] = useState(false);

  // 初回ロード時
  useEffect(() => {
    // メールアドレス入力画面からメールアドレスを受け取る
    if (hasValue(location.state?.mailAddress)) {
      setMailAddress(location.state?.mailAddress);
    }
    // メールアドレスが取得できない場合はメールアドレス入力画面へ戻す
    else {
      log.error("不正なアクセス：メールアドレス指定なし");
      errorToast(t("mes.汎用エラーメッセージ"));
      navigate("/reset-password/request", { replace: true });
    }
    // eslint-disable-next-line
  }, []);

  /**
   * 入力内容変更時のイベント
   * @param _code 検証用コード
   * @param _newPassword 新しいパスワード
   * @param _confirmPassword 新しいパスワード(確認)
   */
  const handleChangeValue = (
    _code: string,
    _newPassword: string,
    _confirmPassword: string,
  ) => {
    setCode(_code);
    setNewPassword(_newPassword);
    setConfirmNewPassword(_confirmPassword);
  };

  /**
   * パスワードをリセットするボタン押下時イベント
   * @param event イベント情報
   */
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Submit防止
    setIsLoading(true);
    // バリデーションチェックでエラーがある場合は中断
    if (!validate()) {
      setIsLoading(false);
      return;
    }
    // パスワードリセット処理を実行
    await executeResetPassword();
  };

  /**
   * バリデーションチェック
   * @return true: 非エラー / false: エラー
   */
  const validate = () => {
    // 検証用コードが未入力の場合
    if (!code) {
      errorToast(t("mes.必須チェックエラー2", { name: t("lbl.検証用コード") }));
      return false;
    }
    // 新しいパスワードが未入力の場合
    if (!newPassword) {
      errorToast(
        t("mes.必須チェックエラー2", { name: t("lbl.新しいパスワード") }),
      );
      return false;
    }
    // 新しいパスワード(確認用)が未入力の場合
    if (!confirmNewPassword) {
      errorToast(
        t("mes.必須チェックエラー2", {
          name: t("lbl.新しいパスワード（確認用）"),
        }),
      );
      return false;
    }
    // パスワードポリシーに沿っていない場合
    if (!isValidPassword(newPassword)) {
      errorToast(t("mes.パスワードポリシーエラーメッセージ"));
      return false;
    }
    // パスワード(確認用)が一致していない場合
    if (newPassword !== confirmNewPassword) {
      errorToast(t("mes.パスワード確認不一致エラー"));
      return false;
    }
    return true;
  };

  /**
   * パスワードリセット処理
   */
  const executeResetPassword = async () => {
    try {
      // 非同期処理を実行
      await dispatch(
        resetPasswordSubmit({
          username: mailAddress,
          code: code,
          newPassword: newPassword,
        }),
      ).unwrap();
      successToast(t("mes.パスワード変更完了メッセージ"));
      // ログイン画面へ遷移
      navigate("/login", { replace: true });
    } catch (e) {
      log.error("パスワードリセット処理でエラー発生：", e);

      // ロードを中断
      setIsLoading(false);

      const error = errorNameAndMessageOf(e);

      // 検証コードに誤りがある場合
      if (error?.name === "CodeMismatchException") {
        errorToast(t("mes.確認コード不一致エラーメッセージ"));
        return;
      }
      // 検証コードが期限切れの場合
      if (error?.name === "ExpiredCodeException") {
        errorToast(t("mes.確認コード有効期限切れエラーメッセージ"));
        return;
      }
      // 試行回数の上限を超えた為、一時的にロックアウトされている場合
      if (error?.name === "LimitExceededException") {
        errorToast(t("mes.ロックアウトエラーメッセージ"));
        return;
      }
      // Cognito側のパスワードポリシーに違反している場合
      if (error?.name === "InvalidPasswordException") {
        errorToast(t("mes.パスワードポリシーエラーメッセージ"));
        return;
      }
      // その他のエラーの場合はパスワードリセット依頼画面へ戻す
      errorToast(t("mes.汎用エラーメッセージ"));
      navigate("/reset-password/request", { replace: true });
      return;
    }
  };

  return (
    <VStack alignSelf={"stretch"} pt={"24px"} pb={"24px"} overflow={"auto"}>
      <FrameUpperLeftButton sx={{ alignSelf: "flex-start" }}>
        <CMButtonBack
          labelBack={t("btn.メールアドレス入力に戻るボタン")}
          onClick={() => navigate("/reset-password/request", { replace: true })}
        />
      </FrameUpperLeftButton>
      <Container maxW={"600px"}>
        <Form onSubmit={handleSubmit}>
          <VStack align={"center"} spacing={"30px"} mt={"40px"}>
            <Text fontSize={"24px"} fontWeight={"bold"}>
              {t("lbl.パスワードリセット画面タイトル")}
            </Text>
            <Box>
              <Text fontSize={"14px"}>
                {t("gdc.パスワードリセット案内1", { mailAddress: mailAddress })}
                <br />
                {t("gdc.パスワードリセット案内2")}
                <br />
                <br />
                {t("gdc.パスワードリセット案内3")}
              </Text>
            </Box>
            <CMFormInputPassword
              initialValue={{
                currentPassword: "",
                newPassword: "",
                verifyPassword: "",
              }}
              labelCurrentPassword={t("lbl.検証用コード")}
              labelNewPassword={t("lbl.新しいパスワード")}
              labelVerifyPassword={t("lbl.新しいパスワード（確認用）")}
              labelDisplayChangeButton={{
                show: t("btn.パスワード表示ボタン"),
                hide: t("btn.パスワード非表示ボタン"),
              }}
              onChange={handleChangeValue}
              sx={{ width: "100%" }}
            />
            <PasswordPolicy fontSize={"12px"} />
            <Button
              isLoading={isLoading}
              disabled={isLoading}
              colorScheme={"teal"}
              type={"submit"}
            >
              {t("btn.パスワードリセットボタン")}
            </Button>
          </VStack>
        </Form>
      </Container>
    </VStack>
  );
};
