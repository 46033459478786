import { Button, Container, Input, Text, VStack } from "@chakra-ui/react";
import { useAppDispatch } from "../../../../hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { FormEvent, useState } from "react";
import { clearAuthState, confirmSignIn } from "../../../../store/auth/slice";
import log from "loglevel";
import { Form, useNavigate } from "react-router-dom";
import useCustomToast from "../../../../hooks/use-custom-toast";
import { errorNameAndMessageOf } from "../../../../lib/util/error-util";
import { useAppTranslation } from "../../../../hooks/use-app-translation";

/**
 * 二段階認証ページ
 */
export const FN10S04MFA = () => {
  const dispatch = useAppDispatch();
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const { signInInfo } = useSelector((state: RootState) => state.auth);
  const { errorToast } = useCustomToast();

  // 認証コード
  const [mfaCode, setMfaCode] = useState<string>("");
  // ロード状態
  const [isLoading, setIsLoading] = useState(false);

  /**
   * 認証ボタン押下時のイベント
   * @param event イベント情報
   */
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Submit防止
    setIsLoading(true);
    // バリデーションエラーがある場合は中断
    if (!validate()) {
      setIsLoading(false);
      return;
    }
    // 二段階認証処理を実行
    await executeMFAAuthenticate();
  };

  /**
   * バリデーションチェック
   * @return true: 非エラー / false: エラー
   */
  const validate = () => {
    // 認証コードが未入力の場合
    if (!mfaCode) {
      errorToast(t("mes.必須チェックエラー2", { name: t("lbl.認証コード") }));
      return false;
    }
    return true;
  };

  /**
   * 二段階認証処理
   */
  const executeMFAAuthenticate = async () => {
    try {
      // サインイン情報が存在しない場合(URL直打ちでページ遷移した場合など)はログイン画面へ戻す
      if (!signInInfo?.cognitoUser) {
        log.error("不正なアクセス：ユーザー情報無し");
        errorToast(t("mes.汎用エラーメッセージ"));
        setIsLoading(false);
        navigate("/login");
        return;
      }
      // 非同期処理を実行
      await dispatch(
        confirmSignIn({ cognitoUser: signInInfo.cognitoUser, code: mfaCode }),
      ).unwrap();
      // ここまで到達するとログイン成功
      // ページ遷移は GuestRoute 側で行われる為ここでは実装しない
      //
    } catch (e) {
      log.error("二段階認証処理でエラー発生：", e);

      // ロードを中断
      setIsLoading(false);

      const error = errorNameAndMessageOf(e);

      // 検証コードに誤りがある場合
      if (error?.name === "CodeMismatchException") {
        errorToast(t("mes.認証コード不一致エラーメッセージ"));
        return;
      }
      // 試行回数の上限を超えた為、一時的にロックアウトされている場合
      // ※ NotAuthorizedException には上記以外の場合も含まれている為、メッセージで判断するしかない…
      // メッセージの内容は変更される可能性がある為、外部定義化しておくほうが望ましい可能性があるが、ひとまずべた書きとする。
      if (
        error?.name === "NotAuthorizedException" &&
        error?.message ===
          "Too many invalid credentials attempts. User temporarily locked. Please try again after few seconds."
      ) {
        errorToast(t("mes.ロックアウトエラーメッセージ"));
        return;
      }
      // その他のエラーの場合はログイン画面へ戻す
      errorToast(t("mes.汎用エラーメッセージ"));
      await dispatch(clearAuthState);
      navigate("/login", { replace: true });
      return;
    }
  };

  return (
    <>
      <Container pt={"24px"} pb={"24px"} maxW={"600px"}>
        <Form onSubmit={handleSubmit}>
          <VStack align={"center"} spacing={"30px"}>
            <Text fontSize={"24px"} fontWeight={"bold"}>
              {t("lbl.二段階認証画面タイトル")}
            </Text>
            <Text fontSize={"14px"}>{t("gdc.二段階認証案内")}</Text>
            <Input
              type={"number"}
              value={mfaCode}
              placeholder={t("lbl.認証コード")}
              onChange={(e) => setMfaCode(e.target.value)}
              w={"300px"}
            ></Input>
            <Button
              type={"submit"}
              isLoading={isLoading}
              disabled={isLoading}
              colorScheme={"teal"}
              w={"150px"}
            >
              {t("btn.認証ボタン")}
            </Button>
          </VStack>
        </Form>
      </Container>
    </>
  );
};
