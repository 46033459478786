import { ComponentStyleProps } from "../../../../../../../../../lib/styles/props/component-style-props";
import { VStack } from "@chakra-ui/react";
import {
  CMFormControlHeader,
  CMFormInputSearchAndSelect,
  SearchDropdownDisplayValue,
} from "@pscsrvlab/psc-react-components";
import {
  hasValue,
  isNullish,
} from "../../../../../../../../../lib/util/common-util";
import useCustomToast from "../../../../../../../../../hooks/use-custom-toast";
import { stockRequestApi } from "../../../../../../../../../store/api/enhanced-api";
import log from "loglevel";
import { errorMessageOf } from "../../../../../../../../../lib/util/error-util";
import { useCallback } from "react";
import { UserInformation } from "../../../../../../../../../lib/object/value/user-information";
import { appUserMeta } from "../../../../../../../../../lib/object/entity/app-user";
import { AppLink } from "../../../../../../../../ui/link/AppLink/AppLink";
import { useChangeBadgeProperties } from "../../../../../../../../../hooks/document/change-application/use-change-badge-properties";
import { useAppTranslation } from "../../../../../../../../../hooks/use-app-translation";

export type ApplicationUserSelectProps = {
  documentType:
    | "new_application"
    | "change_application"
    | "project_force_update"
    | "project_force_create"
    | "project_content";
  editMode: "editable" | "readOnly" | "disabled";

  /**
   * この機関IDで絞って、ユーザーを検索する。
   */
  institutionId?: number;

  /**
   * 値。ユーザーID。
   */
  value: number | null;
  /**
   * 氏名の値。
   * readOnly(読み取り専用)モードでのみ、これを表示に用いる。
   * (マスタ検索して最新の値を使ってしまってはいけないので。)
   */
  valueFullName: string | null;

  /**
   * 変更前の値。
   * このコンポーネントでは、ユーザーIDか氏名のどちらかが変わっていれば、変化ありとして扱う。
   */
  changedFrom?: {
    appUserId: number;
    fullName: string;
  };

  onChange?: (value: UserInformation | null) => void;
} & ComponentStyleProps;

export const ApplicationUserSelect = ({
  documentType,
  editMode,
  institutionId,

  value,
  valueFullName,

  onChange,

  changedFrom,

  sx,
  ...rest
}: ApplicationUserSelectProps) => {
  const { t } = useAppTranslation();
  const { errorToast } = useCustomToast();

  // const { data: selectedUser } = useAppGetAppUserQuery(
  //   {
  //     appUserId: value ?? -1,
  //   },
  //   { skip: isNullish(value) },
  // );

  const [triggerListAppUserQuery] = stockRequestApi.useLazyListAppUserQuery();
  const [triggerGetAppUserQuery] = stockRequestApi.useLazyGetAppUserQuery();

  const toDisplayValue: (value: number) => Promise<SearchDropdownDisplayValue> =
    useCallback(
      async (value) => {
        try {
          const appUser = await triggerGetAppUserQuery(
            {
              appUserId: value,
            },
            true,
          ).unwrap();
          return {
            headingLabel: appUser.displayUserId,
            mainLabel: appUser.fullName ?? "",
          };
        } catch (e) {
          log.error(errorMessageOf(e));
          return { headingLabel: undefined, mainLabel: "" };
        }
      },
      [triggerGetAppUserQuery],
    );

  const handleSearch: (text: string) => Promise<number[]> = useCallback(
    async (text: string) => {
      if (isNullish(institutionId)) return [];
      try {
        const selectableAppUsers: number[] = (
          await triggerListAppUserQuery({
            fullName: text,
            institutionId,
          }).unwrap()
        )
          .map((v) => v?.id)
          .filter(hasValue);
        return selectableAppUsers;
      } catch (e) {
        log.error(errorMessageOf(e));
        errorToast(t("mes.汎用エラーメッセージ"));
        return [];
      }
    },
    [errorToast, institutionId, t, triggerListAppUserQuery],
  );

  const handleChange = useCallback(
    async (value: number | null) => {
      if (isNullish(value)) {
        onChange?.(null);
        return;
      }
      try {
        const appUserData = await triggerGetAppUserQuery({
          appUserId: value,
        }).unwrap();
        const appUser = appUserMeta.toSavedDomainObjectOrNull(appUserData);
        if (isNullish(appUser)) {
          onChange?.(null);
          return;
        }
        onChange?.({
          appUserId: appUser.id,
          displayUserId: appUser.displayUserId,
          role: appUser.role,
          fullName: appUser.fullName,
          fullNameKana: appUser.fullNameKana,
          titleAndPosition: appUser.titleAndPosition,
          phoneNumber: appUser.phoneNumber,
          mailAddress: appUser.mailAddress,
        });
      } catch (e) {
        log.error(errorMessageOf(e));
        onChange?.(null);
      }
    },
    [onChange, triggerGetAppUserQuery],
  );

  /**
   * 等価性を判定する。
   * ユーザーIDが同じで、かつ氏名も同じならtrue。
   * ユーザーIDが同じで、かつ氏名の取得に失敗してもtrue。
   * それ以外はfalse。
   */
  const equalityFn = useCallback(
    async (appUserIdBefore: number, appUserIdAfter: number) => {
      if (appUserIdBefore !== appUserIdAfter) return false;
      try {
        const appUserData = await triggerGetAppUserQuery({
          appUserId: appUserIdAfter,
        }).unwrap();
        if (isNullish(appUserData?.fullName)) return true;
        return appUserData.fullName === changedFrom?.fullName;
      } catch (e) {
        return true;
      }
    },
    [changedFrom?.fullName, triggerGetAppUserQuery],
  );
  const changeBadgeProperties = useChangeBadgeProperties(
    documentType,
    changedFrom?.appUserId,
  );

  return (
    <VStack sx={sx} {...rest} alignItems={"flex-start"}>
      {editMode === "readOnly" && hasValue(value) && hasValue(valueFullName) ? (
        <VStack alignItems={"flex-start"}>
          <CMFormControlHeader label={t("lbl.氏名")} />
          <AppLink isExternal to={`/user/${value}`} mt={"10px"}>
            {valueFullName}
          </AppLink>
        </VStack>
      ) : editMode === "editable" || editMode === "disabled" ? (
        <CMFormInputSearchAndSelect<number>
          editMode={editMode}
          label={t("lbl.氏名")}
          value={value}
          labelSubmit={t("btn.確定ボタン")}
          labelClear={t("btn.クリアボタン")}
          placeholder={t("lbl.選択プルダウンプレースホルダー")}
          placeholderPopover={t("lbl.選択プルダウンプレースホルダー")}
          popoverTitle={t("mes.ユーザー選択案内メッセージ")}
          searchResultNone={t("mes.該当ユーザーなしメッセージ")}
          toDisplayValue={toDisplayValue}
          menuShowMaxCount={30}
          changedFromDisplayValue={changedFrom?.fullName}
          checkEqualityFn={equalityFn}
          onSearch={handleSearch}
          onChange={handleChange}
          changeBadgeProperties={changeBadgeProperties}
          usePortal={false}
          minW={"200px"}
          w={"max-content"}
          maxW={"300px"}
        />
      ) : null}
    </VStack>
  );
};
