import { HStack } from "@chakra-ui/react";
import { searchConditionPageNumberMeta } from "../../../../lib/object/value/search-condition-page-number";
import {
  getAllEnumFromSearchParams,
  getEnumFromSearchParams,
  getISO8601StringFromSearchParams,
  getNumberFromSearchParams,
} from "../../../../lib/util/search-params-util";
import { searchConditionLimitMeta } from "../../../../lib/object/value/search-condition-limit";
import { operationTypeMeta } from "../../../../lib/object/value/operation-type";
import { appUserIdMeta } from "../../../../lib/object/value/app-user-id";
import { projectIdMeta } from "../../../../lib/object/value/project-id";
import { useSearchPage } from "../../../../hooks/use-search-page";
import {
  SearchWorkLogApiArg,
  useSearchWorkLogQuery,
} from "../../../../store/api/generated/stock-request-api";
import React, { useCallback, useMemo } from "react";
import { hasValue, isNullish } from "../../../../lib/util/common-util";
import { useSearchResultPaginationNumbers } from "../../../../hooks/use-search-result-pagination-numbers";
import {
  workLogMeta,
  WorkLogSaved,
} from "../../../../lib/object/entity/work-log";
import { SearchResultArea } from "../../../ui/search/SearchResultArea/SearchResultArea";
import { SearchConditionSidebarWorkLog } from "./_components/SearchConditionSidebarWorkLog/SearchConditionSidebarWorkLog";
import { SearchResultTableWorkLog } from "./_components/SearchResultTableWorkLog/SearchResultTableWorkLog";
import { workLogSortConditionMeta } from "../../../../lib/object/value/work-log-sort-condition";
import { useAppParams } from "../../../../hooks/use-app-params";
import { documentIdMeta } from "../../../../lib/object/value/document-id";
import { workTypeMeta } from "../../../../lib/object/value/work-type";
import { institutionIdMeta } from "../../../../lib/object/value/institution-id";
import { getDefaultPeriod } from "../../../../lib/util/search-util";

export type WorkLogPageProps = {
  type: "root" | "project" | "document";
};
export const WorkLogPage = ({ type }: WorkLogPageProps) => {
  const { projectId, documentId } = useAppParams();

  // 案件画面内なら案件ID、書類画面内なら書類IDをデフォルト検索条件とする。
  const urlSearchParamsToSearchArgsWithDefault = useCallback(
    (searchParams: URLSearchParams) => {
      return urlSearchParamsToSearchArgs(
        searchParams,
        type === "project" && hasValue(projectId) ? projectId : null,
        type === "document" && hasValue(documentId) ? documentId : null,
      );
    },
    [type, projectId, documentId],
  );

  const { searchArgs, handleChangeSearchCondition, handleChangePage } =
    useSearchPage(urlSearchParamsToSearchArgsWithDefault);

  /**
   * 検索処理。
   */
  const { data, isLoading } = useSearchWorkLogQuery(searchArgs);
  const entities: WorkLogSaved[] = useMemo(() => {
    if (isNullish(data)) return [];
    return data.items
      .map((v) => {
        return workLogMeta.toSavedDomainObjectOrNull(v, true);
      })
      .filter(hasValue);
  }, [data]);

  const { totalElements, currentPageNumber, totalPageCount } =
    useSearchResultPaginationNumbers(searchArgs, data);

  return (
    <HStack flex={1} spacing={0} overflow={"hidden"} alignItems={"stretch"}>
      <SearchConditionSidebarWorkLog
        type={type}
        searchArgs={searchArgs}
        onChange={handleChangeSearchCondition}
      />
      <SearchResultArea
        isLoading={isLoading}
        currentPageNumber={currentPageNumber}
        totalPageCount={totalPageCount}
        totalElements={totalElements}
        onPageChange={handleChangePage}
      >
        <SearchResultTableWorkLog sx={{ flex: 1 }} entities={entities} />
      </SearchResultArea>
    </HStack>
  );
};

/**
 * クエリパラメータから検索条件を作成する.
 */
function urlSearchParamsToSearchArgs(
  searchParams: URLSearchParams,
  defaultProjectId: number | null,
  defaultDocumentId: number | null,
): SearchWorkLogApiArg {
  const { defaultFromDateText, defaultToDateText } = getDefaultPeriod(30);

  const sortCondition =
    getEnumFromSearchParams(
      searchParams,
      "sortCondition",
      workLogSortConditionMeta,
    ) ?? "datetime_desc";
  const limit =
    getNumberFromSearchParams(
      searchParams,
      "limit",
      searchConditionLimitMeta,
    ) ?? 50;

  const workDateTimeFrom =
    getISO8601StringFromSearchParams(searchParams, "workDateTimeFrom") ??
    defaultFromDateText;
  const workDateTimeTo =
    getISO8601StringFromSearchParams(searchParams, "workDateTimeTo") ??
    defaultToDateText;
  const workType = getAllEnumFromSearchParams(
    searchParams,
    "workType",
    workTypeMeta,
  );
  const institutionId = getNumberFromSearchParams(
    searchParams,
    "institutionId",
    institutionIdMeta,
  );
  const appUserId = getNumberFromSearchParams(
    searchParams,
    "appUserId",
    appUserIdMeta,
  );
  const projectId = hasValue(defaultProjectId)
    ? defaultProjectId
    : getNumberFromSearchParams(searchParams, "projectId", projectIdMeta);
  const documentId = hasValue(defaultDocumentId)
    ? defaultDocumentId
    : getNumberFromSearchParams(searchParams, "documentId", documentIdMeta);
  const operationType = getEnumFromSearchParams(
    searchParams,
    "operationType",
    operationTypeMeta,
  );

  const pageNumber = getNumberFromSearchParams(
    searchParams,
    "pageNumber",
    searchConditionPageNumberMeta,
  );

  return {
    sortCondition,
    limit,

    workDateTimeFrom,
    workDateTimeTo,
    workType,
    institutionId,
    appUserId,
    projectId,
    documentId,
    operationType,

    pageNumber,
  };
}
