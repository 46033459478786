import React from "react";
import { ComponentStyleProps } from "../../../../lib/styles/props/component-style-props";
import { Box } from "@chakra-ui/react";
import { InstitutionDetail } from "../../../model/institionDetail/InstitutionDetail/InstitutionDetail";

export type FN80S02InstitutionDetailsProps = {
  /**
   * 新規登録モード
   */
  createMode?: boolean;
} & ComponentStyleProps;

/**
 * FN80-S02機関詳細
 */
export const FN80S02InstitutionDetails = ({
  createMode = true,
}: FN80S02InstitutionDetailsProps) => {
  return (
    <Box overflowY={"auto"}>
      <InstitutionDetail createMode={createMode} />
    </Box>
  );
};
