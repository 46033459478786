import { Container, Divider, HStack, Text, VStack } from "@chakra-ui/react";
import { CMButton } from "@pscsrvlab/psc-react-components";
import { hasValue, isNullish } from "../../../../lib/util/common-util";
import { useNavigate } from "react-router-dom";
import { useMemo } from "react";
import { DocumentType } from "../../../../lib/object/value/document-type";
import { ProgressStepDocumentCreate } from "../../../ui/progress/ProgressStepDocumentCreate/ProgressStepDocumentCreate";
import { CalloutBox } from "../../../ui/text/CalloutBox/CalloutBox";
import { useAppTranslation } from "../../../../hooks/use-app-translation";
import { useAppParams } from "../../../../hooks/use-app-params";
import { useAppGetDocumentQuery } from "../../../../hooks/query/use-app-get-document-query";
import { LabeledTextVertical } from "../../../ui/text/LabeledTextVertical/LabeledTextVertical";
import { ymdToDateText } from "../../../../lib/util/common-date-util";

export type FN40S10DocumentCreateCompleteProps = {
  documentType: DocumentType;
};
export const FN40S10DocumentCreateComplete = ({
  documentType: _documentType,
}: FN40S10DocumentCreateCompleteProps) => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();

  const { documentId } = useAppParams();

  //書類情報を取得
  const { data: documentData } = useAppGetDocumentQuery(
    {
      documentId: documentId ?? -1,
    },
    { skip: isNullish(documentId) },
  );

  const documentControlNumber = useMemo(() => {
    if (hasValue(documentData?.documentControlNumber)) {
      return documentData?.documentControlNumber;
    }
  }, [documentData]);

  const submissionDateText = useMemo(() => {
    if (hasValue(documentData) && hasValue(documentData.submissionDate)) {
      return ymdToDateText(documentData.submissionDate);
    }
  }, [documentData]);

  const handleClickBack = () => {
    navigate("/document");
  };

  return (
    <VStack overflow={"auto"}>
      <Container minW={"500px"} maxW={"720px"}>
        {hasValue(documentControlNumber) && (
          <VStack spacing={"20px"}>
            <ProgressStepDocumentCreate
              currentStep={2}
              sx={{ alignSelf: "stretch" }}
            />
            <Text fontSize={"2xl"} fontWeight={"bold"}>
              {t("mes.修正提出完了メッセージ")}
            </Text>
            <HStack>
              <LabeledTextVertical
                label={t("lbl.書類管理番号")}
                text={documentControlNumber}
              />
              <LabeledTextVertical
                label={t("lbl.提出日")}
                text={submissionDateText}
              />
            </HStack>
            <Divider />
            <CalloutBox>
              <Text>{t("gdc.提出完了お願い1")}</Text>
              <Text>{t("gdc.提出完了お願い2")}</Text>
            </CalloutBox>

            <CMButton
              label={t("btn.書類検索に戻るボタン")}
              onClick={handleClickBack}
            />
          </VStack>
        )}
      </Container>
    </VStack>
  );
};
