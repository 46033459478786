import { useGetDocumentSnapshotQuery } from "../../store/api/generated/stock-request-api";
import { isNullish } from "../../lib/util/common-util";
import { useMemo } from "react";
import {
  documentSnapshotMeta,
  DocumentSnapshotSaved,
} from "../../lib/object/entity/document-snapshot";

export function useAppGetDocumentSnapshotQuery(
  arg: Parameters<typeof useGetDocumentSnapshotQuery>[0],
  options?: Parameters<typeof useGetDocumentSnapshotQuery>[1],
  skipOptionalValidations = true, // get系は、skipOptionalValidationsのデフォルトはtrueとする。
): { data: DocumentSnapshotSaved | null } {
  const { data: rawData } = useGetDocumentSnapshotQuery(arg, options);
  const data = useMemo(() => {
    if (isNullish(rawData)) return null;
    return documentSnapshotMeta.toSavedDomainObjectOrNull(
      rawData,
      skipOptionalValidations,
    );
  }, [rawData, skipOptionalValidations]);
  return { data };
}
