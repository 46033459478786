import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";
import { Container, HStack, useDisclosure, VStack } from "@chakra-ui/react";

import { useNavigate } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import useCustomToast from "../../../../../../hooks/use-custom-toast";
import { hasValue } from "../../../../../../lib/util/common-util";
import {
  CMButtonBack,
  CMExtendedMenu,
  DeleteConfirmationModal,
  ValidationError,
} from "@pscsrvlab/psc-react-components";
import { FrameUpperLeftButton } from "../../../../../ui/frame/FrameUpperLeftButton/FrameUpperLeftButton";
import { ProgressStepDocumentCreate } from "../../../../../ui/progress/ProgressStepDocumentCreate/ProgressStepDocumentCreate";
import { CommonNextButton } from "../../../../../ui/button/CommonNextButton/CommonNextButton";
import { SaveDraftButton } from "../../../../../ui/button/SaveDraftButton/SaveDraftButton";
import { EditEndButton } from "../../../../../ui/button/EditEndButton/EditEndButton";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { FrameUpperRightButton } from "../../../../../ui/frame/FrameUpperRightButton/FrameUpperRightButton";
import { useAppSelector } from "../../../../../../hooks/redux-hooks";
import { selectUserInfo } from "../../../../../../store/auth/slice";
import { TerminationReportDocument } from "../TerminationReportDocument/TerminationReportDocument";
import { TerminationReportViewModel } from "../../../../../../lib/object/vm/termination-report-view-model";
import { useLeaveEditingPrompt } from "../../../../../../hooks/use-leave-editing-prompt";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { ConfirmationModal } from "../../../../../ui/modal/ConfirmationModal/ConfirmationModal";

export type TerminationReportCreatePageProps = {
  projectId: number;
  /**
   * 下書き保存済の書類が存在する場合、その書類ID。
   * 新規作成時はundefined。
   */
  savedDocumentId?: number;
  /**
   * 画面表示時の書類内容の初期値。
   */
  initialViewModel: TerminationReportViewModel;

  validationErrors: ValidationError[];

  saveTerminationReportContent: (
    vm: TerminationReportViewModel,
    skipOptionalValidations: boolean,
  ) => Promise<
    | { state: "ok"; documentId: number }
    | { state: "error"; errors: ValidationError[] }
    | { state: "unexpectedError" }
  >;
  deleteTerminationReportDraft: () => Promise<
    { state: "ok" } | { state: "unexpectedError" }
  >;
} & ComponentStyleProps;

/**
 * 使用終了報告作成画面
 */
export const TerminationReportCreatePage = ({
  projectId,
  savedDocumentId,

  initialViewModel,

  validationErrors,

  saveTerminationReportContent,
  deleteTerminationReportDraft,

  sx,
  ...rest
}: TerminationReportCreatePageProps) => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const loginUserInfo = useAppSelector(selectUserInfo);

  const [viewModel, setViewModel] =
    useState<TerminationReportViewModel>(initialViewModel);

  const { errorToast } = useCustomToast();
  const { navigateWithoutPrompt } = useLeaveEditingPrompt();

  // 提供先の有無で無が選択されると提供先を空にする。
  useEffect(() => {
    if (
      viewModel.contentVM.cellProvisionType === "none" &&
      viewModel.contentVM.cellProvisions.length !== 0
    ) {
      setViewModel({
        ...viewModel,
        contentVM: { ...viewModel.contentVM, cellProvisions: [] },
      });
    }
  }, [viewModel]);

  /**
   * 戻るボタン押下時に呼ばれる。
   */
  const handleClickBack = useCallback(async () => {
    navigate(`/project/${projectId}`);
  }, [navigate, projectId]);

  // 下書き保存確認モーダル制御
  const {
    isOpen: isOpenSaveDraftModal,
    onOpen: onOpenSaveDraftModal,
    onClose: onCloseSaveDraftModal,
  } = useDisclosure();
  /**
   * 下書き保存ボタン押下時、確認へ進むボタン押下時に呼ばれる。
   */
  const handleSaveDraft = useCallback(
    async (to: "back" | "confirm", skipOptionalValidations: boolean) => {
      // 使用終了報告下書きを新規作成、または使用終了報告内容を更新する。
      const result = await saveTerminationReportContent(
        viewModel,
        skipOptionalValidations,
      );
      if (result.state === "error") {
        return;
      }
      if (result.state === "unexpectedError") {
        errorToast(t("mes.汎用エラーメッセージ"));
        return;
      }
      if (to === "back") {
        await navigateWithoutPrompt(`/project/${projectId}`);
      }
      if (to === "confirm") {
        // await sleep(200); // すぐに遷移すると、遷移後に古い値が表示されてしまうことへの対策。
        await navigateWithoutPrompt(
          `/document/create-termination-report/${projectId}/confirm/${result.documentId}`,
        );
      }
    },
    [
      errorToast,
      navigateWithoutPrompt,
      projectId,
      saveTerminationReportContent,
      t,
      viewModel,
    ],
  );

  /**
   * 編集終了ボタン押下時に呼ばれる。
   */
  const handleClickEditingEnd = useCallback(() => {
    navigate(`/project/${projectId}`);
  }, [navigate, projectId]);

  //下書き削除確認モーダル制御
  const {
    isOpen: isOpenDeleteDraftModal,
    onOpen: onOpenDeleteDraftModal,
    onClose: onCloseDeleteDraftModal,
  } = useDisclosure();

  /**
   * 下書き削除ボタン押下時に呼ばれる。
   */
  const handleDeleteDraftButtonClick = useCallback(() => {
    onOpenDeleteDraftModal();
  }, [onOpenDeleteDraftModal]);

  /**
   * モーダルの削除ボタンを押下すると呼ばれる。
   */
  const handleConfirmDeleteDraft = useCallback(async () => {
    onCloseDeleteDraftModal();

    const result = await deleteTerminationReportDraft();
    if (result.state === "unexpectedError") {
      errorToast(t("mes.下書き削除失敗エラー"));
      return;
    }
    navigateWithoutPrompt(`/project/${projectId}`);
  }, [
    deleteTerminationReportDraft,
    errorToast,
    navigateWithoutPrompt,
    onCloseDeleteDraftModal,
    projectId,
    t,
  ]);

  return (
    <>
      <HStack
        justifyContent={"space-between"}
        alignItems={"flex-start"}
        overflow={"auto"}
        spacing={0}
        sx={sx}
        {...rest}
      >
        <FrameUpperLeftButton sx={{ position: "sticky", top: 0 }}>
          <CMButtonBack
            onClick={handleClickBack}
            labelBack={t("btn.案件画面に戻るボタン")}
          />
        </FrameUpperLeftButton>

        <Container minW={"500px"} maxW={"720px"}>
          <VStack alignItems={"stretch"}>
            <ProgressStepDocumentCreate />
            {hasValue(loginUserInfo) && (
              <VStack alignSelf={"stretch"} mt={"18px"} pb={"100px"}>
                <TerminationReportDocument
                  loginUserInfo={loginUserInfo}
                  editMode={"editable"}
                  value={viewModel}
                  onChange={setViewModel}
                  validationErrors={validationErrors}
                />
                <CommonNextButton
                  label={t("btn.確認へ進むボタン")}
                  onClick={() => handleSaveDraft("confirm", false)}
                />
              </VStack>
            )}
          </VStack>
        </Container>

        <FrameUpperRightButton sx={{ position: "sticky", top: 0 }}>
          <HStack w={"max-content"}>
            <SaveDraftButton onClick={onOpenSaveDraftModal} />
            <EditEndButton onClick={handleClickEditingEnd} />
            {hasValue(savedDocumentId) && (
              <CMExtendedMenu
                size={"sm"}
                menuItems={[
                  {
                    key: 0,
                    icon: RiDeleteBin6Fill,
                    label: t("btn.下書きを削除するボタン"),
                  },
                ]}
                onClick={handleDeleteDraftButtonClick}
              />
            )}
          </HStack>
        </FrameUpperRightButton>
      </HStack>
      <DeleteConfirmationModal
        isOpen={isOpenDeleteDraftModal}
        title={t("lbl.確認ポップアップタイトル")}
        message={t("mes.下書き削除確認メッセージ")}
        deleteButtonLabel={t("btn.削除ボタン")}
        cancelButtonLabel={t("btn.キャンセルボタン")}
        onConfirm={handleConfirmDeleteDraft}
        onCancel={onCloseDeleteDraftModal}
        onClose={onCloseDeleteDraftModal}
      />
      <ConfirmationModal
        isOpen={isOpenSaveDraftModal}
        message={t("mes.変更保存確認メッセージ")}
        onSubmit={() => handleSaveDraft("back", true)}
        onCancel={onCloseSaveDraftModal}
      />
    </>
  );
};
