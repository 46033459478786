import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * 添付資料種別(値オブジェクト)
 *
 *
 *
 * research_proposal:
 * certificate_of_ethics_committee_approval:
 * management_system:
 * principal_investigator_resume:
 * organization_overview:
 * research_achievement:
 * facility_gmp_compliance:
 * other:
 * office_checklist_for_application:
 * disclosure_document:
 * shared:
 * cell_treatment_warnings:
 * donor_information:
 * test_report:
 * review_conference_attachment:
 * inquiry_attachment:
 * cell_delivery_office_member:  */
export type AttachmentFileType = z.infer<typeof attachmentFileTypeMeta.schema>;
/**
 * 添付資料種別(値オブジェクト)のメタデータ。
 *
 *  */
export const attachmentFileTypeMeta = new ValueObjectMetaEnum<
  [
    "research_proposal",
    "certificate_of_ethics_committee_approval",
    "management_system",
    "principal_investigator_resume",
    "organization_overview",
    "research_achievement",
    "facility_gmp_compliance",
    "other",
    "office_checklist_for_application",
    "disclosure_document",
    "shared",
    "cell_treatment_warnings",
    "donor_information",
    "test_report",
    "review_conference_attachment",
    "inquiry_attachment",
    "cell_delivery_office_member"
  ]
>(
  "AttachmentFileType",
  "\u6DFB\u4ED8\u8CC7\u6599\u7A2E\u5225",
  messageId("lbl.\u6DFB\u4ED8\u8CC7\u6599\u7A2E\u5225"),
  [
    "research_proposal",
    "certificate_of_ethics_committee_approval",
    "management_system",
    "principal_investigator_resume",
    "organization_overview",
    "research_achievement",
    "facility_gmp_compliance",
    "other",
    "office_checklist_for_application",
    "disclosure_document",
    "shared",
    "cell_treatment_warnings",
    "donor_information",
    "test_report",
    "review_conference_attachment",
    "inquiry_attachment",
    "cell_delivery_office_member",
  ],
  {
    research_proposal:
      "code.\u6DFB\u4ED8\u8CC7\u6599\u7A2E\u5225.\u7814\u7A76\u8A08\u753B\u66F8",
    certificate_of_ethics_committee_approval:
      "code.\u6DFB\u4ED8\u8CC7\u6599\u7A2E\u5225.\u502B\u7406\u627F\u8A8D\u66F8\u30FB\u6A5F\u95A2\u8A31\u53EF\u66F8\u30FB\u30A8\u30B0\u30BC\u30F3\u30D7\u30B7\u30E7\u30F3\u30EC\u30BF\u30FC",
    management_system:
      "code.\u6DFB\u4ED8\u8CC7\u6599\u7A2E\u5225.\u7BA1\u7406\u4F53\u5236\u306B\u95A2\u3059\u308B\u6587\u66F8",
    principal_investigator_resume:
      "code.\u6DFB\u4ED8\u8CC7\u6599\u7A2E\u5225.\u7814\u7A76\u8CAC\u4EFB\u8005\u306E\u7565\u6B74",
    organization_overview:
      "code.\u6DFB\u4ED8\u8CC7\u6599\u7A2E\u5225.\u6A5F\u95A2\u306E\u6982\u8981",
    research_achievement:
      "code.\u6DFB\u4ED8\u8CC7\u6599\u7A2E\u5225.\u7814\u7A76\u5B9F\u7E3E",
    facility_gmp_compliance:
      "code.\u6DFB\u4ED8\u8CC7\u6599\u7A2E\u5225.\u4F7F\u7528\u65BD\u8A2D\u306E\u9069\u683C\u6027\u3092\u793A\u3059\u6587\u66F8",
    other:
      "code.\u6DFB\u4ED8\u8CC7\u6599\u7A2E\u5225.\u305D\u306E\u4ED6\u6DFB\u4ED8\u8CC7\u6599",
    office_checklist_for_application:
      "code.\u6DFB\u4ED8\u8CC7\u6599\u7A2E\u5225.\u7533\u8ACB\u7528\u4E8B\u52D9\u5C40\u30C1\u30A7\u30C3\u30AF\u30EA\u30B9\u30C8",
    disclosure_document:
      "code.\u6DFB\u4ED8\u8CC7\u6599\u7A2E\u5225.\u60C5\u5831\u516C\u958B\u6587\u66F8",
    shared:
      "code.\u6DFB\u4ED8\u8CC7\u6599\u7A2E\u5225.\u5171\u6709\u6DFB\u4ED8\u8CC7\u6599",
    cell_treatment_warnings:
      "code.\u6DFB\u4ED8\u8CC7\u6599\u7A2E\u5225.\u7D30\u80DE\u53D6\u308A\u6271\u3044\u6CE8\u610F\u4E8B\u9805",
    donor_information:
      "code.\u6DFB\u4ED8\u8CC7\u6599\u7A2E\u5225.\u30C9\u30CA\u30FC\u60C5\u5831",
    test_report:
      "code.\u6DFB\u4ED8\u8CC7\u6599\u7A2E\u5225.\u8A66\u9A13\u6210\u7E3E\u66F8",
    review_conference_attachment:
      "code.\u6DFB\u4ED8\u8CC7\u6599\u7A2E\u5225.\u5BE9\u67FB\u4F1A\u6DFB\u4ED8\u8CC7\u6599",
    inquiry_attachment:
      "code.\u6DFB\u4ED8\u8CC7\u6599\u7A2E\u5225.\u8CEA\u7591\u5FDC\u7B54\u6DFB\u4ED8\u8CC7\u6599",
    cell_delivery_office_member:
      "code.\u6DFB\u4ED8\u8CC7\u6599\u7A2E\u5225.\u7D30\u80DE\u767A\u9001\u4E8B\u52D9\u5C40\u6DFB\u4ED8\u8CC7\u6599",
  }
);
