import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";
import { HStack } from "@chakra-ui/react";
import { DocumentHeaderTab } from "../DocumentHeaderTab/DocumentHeaderTab";
import { useMemo } from "react";
import { hasValue } from "../../../../../../lib/util/common-util";
import { useAppSelector } from "../../../../../../hooks/redux-hooks";
import { selectUserInfo } from "../../../../../../store/auth/slice";
import { DocumentType } from "../../../../../../lib/object/value/document-type";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";

export type DocumentHeaderTabsProps = {
  /**
   * 案件ID。値が存在すれば、案件画面内であると判断する。
   */
  projectId?: number;
  /**
   * 書類ID。
   */
  documentId: number;
  /**
   * 書類種別。
   */
  documentType: DocumentType;
} & ComponentStyleProps;

export const DocumentHeaderTabs = ({
  projectId,
  documentId,
  documentType,

  sx,
  ...rest
}: DocumentHeaderTabsProps) => {
  const { t } = useAppTranslation();
  const currentUserInfo = useAppSelector(selectUserInfo);
  const myRole = useMemo(() => currentUserInfo?.role, [currentUserInfo]);

  /**
   * 書類種別が申請であればtrue。
   */
  const isDocumentTypeApplication = useMemo(() => {
    return (
      documentType === "new_application" ||
      documentType === "change_application"
    );
  }, [documentType]);

  const toPrefix = useMemo(() => {
    if (hasValue(projectId))
      return `/project/${projectId}/document/${documentId}`;
    return `/document/${documentId}`;
  }, [documentId, projectId]);

  return (
    <HStack sx={sx} {...rest} pt={0} pb={0}>
      <DocumentHeaderTab
        label={t("tab.書類内容タブ")}
        to={`${toPrefix}/content`}
      />
      {isDocumentTypeApplication &&
        (myRole === "office_member" || myRole === "executive_director") && (
          <DocumentHeaderTab
            label={t("tab.事務局確認タブ")}
            to={`${toPrefix}/office-member-check`}
          />
        )}
      {(myRole === "applicant" ||
        myRole === "office_member" ||
        myRole === "executive_director") && (
        <DocumentHeaderTab
          label={t("tab.修正依頼タブ")}
          to={`${toPrefix}/revision-request`}
        />
      )}
      {isDocumentTypeApplication &&
        (myRole === "office_member" ||
          myRole === "committee_member" ||
          myRole === "executive_director") && (
          <DocumentHeaderTab
            label={t("tab.審査タブ")}
            to={`${toPrefix}/review`}
          />
        )}
      <DocumentHeaderTab
        label={t("tab.結果通知タブ")}
        to={`${toPrefix}/result-notification`}
      />
      {(myRole === "office_member" || myRole === "executive_director") && (
        <DocumentHeaderTab
          label={t("tab.事務局記入タブ")}
          to={`${toPrefix}/office-member-notes`}
        />
      )}
      {(myRole === "applicant" || myRole === "office_member") && (
        <DocumentHeaderTab
          label={t("tab.質疑応答タブ")}
          to={`${toPrefix}/inquiry`}
        />
      )}
      <DocumentHeaderTab
        label={t("tab.メールログタブ")}
        to={`${toPrefix}/mail-log`}
      />
      {myRole === "office_member" && (
        <DocumentHeaderTab
          label={t("tab.作業ログタブ")}
          to={`${toPrefix}/work-log`}
        />
      )}
    </HStack>
  );
};
