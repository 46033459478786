import React from "react";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import { selectUserInfo } from "../../../../store/auth/slice";
import { useAppParams } from "../../../../hooks/use-app-params";
import { useAppGetRevisionRequestQuery } from "../../../../hooks/query/use-app-get-revision-request-query";
import { hasValue, isNullish } from "../../../../lib/util/common-util";
import { useSavedDocumentVM } from "../../../../hooks/document/use-saved-document-vm";
import { NewApplicationViewModel } from "../../../../lib/object/vm/new-application-view-model";
import { ChangeApplicationViewModel } from "../../../../lib/object/vm/change-application-view-model";
import { AnnualReportViewModel } from "../../../../lib/object/vm/annual-report-view-model";
import { TerminationReportViewModel } from "../../../../lib/object/vm/termination-report-view-model";
import { ReviseConfirmPage } from "./_components/ReviseConfirmPage/ReviseConfirmPage";

export const FN40S28ReviseConfirm = () => {
  const loginUserInfo = useAppSelector(selectUserInfo);

  const {
    projectId: projectIdParam,
    documentId,
    revisionRequestId,
  } = useAppParams();

  const { data: revisionRequest } = useAppGetRevisionRequestQuery(
    {
      revisionRequestId: revisionRequestId ?? -1,
    },
    { skip: isNullish(revisionRequestId) },
  );

  const { projectId, vmAndCallback } = useSavedDocumentVM(documentId);

  return (
    <>
      {hasValue(loginUserInfo) &&
        hasValue(projectId) &&
        hasValue(documentId) &&
        hasValue(vmAndCallback) &&
        hasValue(revisionRequest) && (
          <>
            {vmAndCallback?.type === "new_application" && (
              <ReviseConfirmPage<NewApplicationViewModel>
                loginUserInfo={loginUserInfo}
                projectIdParam={projectIdParam}
                projectId={projectId}
                documentId={documentId}
                vm={vmAndCallback.vm}
                revisionRequest={revisionRequest}
              />
            )}
            {vmAndCallback?.type === "change_application" && (
              <ReviseConfirmPage<ChangeApplicationViewModel>
                loginUserInfo={loginUserInfo}
                projectIdParam={projectIdParam}
                projectId={projectId}
                documentId={documentId}
                vm={vmAndCallback.vm}
                revisionRequest={revisionRequest}
              />
            )}
            {vmAndCallback?.type === "annual_report" && (
              <ReviseConfirmPage<AnnualReportViewModel>
                loginUserInfo={loginUserInfo}
                projectIdParam={projectIdParam}
                projectId={projectId}
                documentId={documentId}
                vm={vmAndCallback.vm}
                revisionRequest={revisionRequest}
              />
            )}
            {vmAndCallback?.type === "termination_report" && (
              <ReviseConfirmPage<TerminationReportViewModel>
                loginUserInfo={loginUserInfo}
                projectIdParam={projectIdParam}
                projectId={projectId}
                documentId={documentId}
                vm={vmAndCallback.vm}
                revisionRequest={revisionRequest}
              />
            )}
          </>
        )}
    </>
  );
};
