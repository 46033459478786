import {
  messageId,
  PropertyMeta,
  ValueObjectMetaComposite,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { receiptStateMeta } from "../value/receipt-state";
import { yearMonthDayMeta } from "../value/year-month-day";
/**
 * 受領情報(値オブジェクト)
 *
 *  */
export type ReceiptInformation = z.infer<typeof schema>;
export type ReceiptInformationSaved = z.infer<typeof savedSchema>;
const minimalSchema = z.object({
  receivedStatus: receiptStateMeta.minimalSchema,
  receiptDate: yearMonthDayMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
const savedMinimalSchema = z.object({
  receivedStatus: receiptStateMeta.savedMinimalSchema,
  receiptDate: yearMonthDayMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
const schema = z.object({
  receivedStatus: receiptStateMeta.schema,
  receiptDate: yearMonthDayMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
});
const savedSchema = z.object({
  receivedStatus: receiptStateMeta.savedSchema,
  receiptDate: yearMonthDayMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
const jsonSchema = z.object({
  receivedStatus: receiptStateMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  receiptDate: yearMonthDayMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
/**
 * 受領情報(値オブジェクト)のメタデータ。
 *
 *  */
export const receiptInformationMeta = new ValueObjectMetaComposite(
  "ReceiptInformation",
  "\u53D7\u9818\u60C5\u5831",
  messageId("lbl.\u53D7\u9818\u60C5\u5831"),
  minimalSchema,
  savedMinimalSchema,
  schema,
  savedSchema,
  jsonSchema,
  undefined,
  undefined,
  {
    receivedStatus: new PropertyMeta(
      "receivedStatus",
      "\u53D7\u9818\u72B6\u6CC1",
      messageId("lbl.\u53D7\u9818\u72B6\u6CC1"),
      "always",
      false,
      receiptStateMeta
    ),
    receiptDate: new PropertyMeta(
      "receiptDate",
      "\u53D7\u9818\u65E5",
      messageId("lbl.\u53D7\u9818\u65E5"),
      "none",
      false,
      yearMonthDayMeta
    ),
  }
);
