import {
  Document as DocumentJson,
  useListDocumentQuery,
} from "../../store/api/generated/stock-request-api";
import { hasValue, isNullish } from "../../lib/util/common-util";
import { useMemo } from "react";
import { documentMeta, DocumentSaved } from "../../lib/object/entity/document";

export function useAppListDocumentQuery(
  arg: Parameters<typeof useListDocumentQuery>[0],
  options?: Parameters<typeof useListDocumentQuery>[1],
  skipOptionalValidations = true, // get系は、skipOptionalValidationsのデフォルトはtrueとする。
): { data: DocumentSaved[] | null } {
  const { data: rawData } = useListDocumentQuery(arg, options);
  const data = useMemo(() => {
    if (isNullish(rawData)) return null;
    return rawData
      .map((d: DocumentJson) =>
        documentMeta.toSavedDomainObjectOrNull(d, skipOptionalValidations),
      )
      .filter(hasValue);
  }, [rawData, skipOptionalValidations]);
  return { data };
}
