import {
  messageId,
  PropertyMeta,
  ValueObjectMetaNumber,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.number().min(1).max(10000);
const savedMinimalSchema = z.number().min(1).max(10000);
const schema = z.number().min(1).max(10000);
const savedSchema = z.number().min(1).max(10000);
const jsonSchema = z.number();
/**
 * 検索条件_ページ番号(値オブジェクト)のメタデータ。
 *
 *  */
export const searchConditionPageNumberMeta = new ValueObjectMetaNumber(
  "SearchConditionPageNumber",
  "\u691C\u7D22\u6761\u4EF6_\u30DA\u30FC\u30B8\u756A\u53F7",
  messageId("lbl.\u30DA\u30FC\u30B8\u756A\u53F7"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
