import React, { useCallback, useEffect } from "react";
import { Box, Stack } from "@chakra-ui/react";
import { CMFormInputDropDown } from "@pscsrvlab/psc-react-components";
import {
  ReviewResult,
  reviewResultMeta,
} from "../../../../../../lib/object/value/review-result";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import log from "loglevel";
import { FormSection } from "../../../../../ui/form/FormSection/FormSection";

export type SectionResultProps = {
  /**
   * 画面編集モード
   */
  editMode?: "editable" | "disabled" | "readOnly";

  value?: string;

  /**
   * 変更イベント
   */
  onChange?: (changedValue: ReviewResult | null) => void;
};

/**
 * FN40-S30審査
 * コメントセクション
 */
export const SectionResult = ({
  editMode = "readOnly",

  value,

  onChange,
}: SectionResultProps) => {
  const { t } = useAppTranslation();

  const handleChange = useCallback(
    (selectedValue: ReviewResult | null) => {
      onChange?.(selectedValue);
    },
    [onChange],
  );

  useEffect(() => {
    log.debug(`SectionResultProps: value=${value}`);
  }, [value]);

  return (
    <FormSection title={t("lbl.審査結果")}>
      <Stack pl={"16px"} pr={"16px"}>
        <Box w={"220px"}>
          <CMFormInputDropDown
            editMode={editMode}
            noHeader={true}
            nullable={true}
            value={value}
            valueObjectMeta={reviewResultMeta}
            onChange={handleChange}
          />
        </Box>
      </Stack>
    </FormSection>
  );
};
