import { EthicalApplicationStatus } from "../value/ethical-application-status";
import { ErrorMessageObject, messageId } from "@pscsrvlab/psc-react-components";
import { hasValue, isNullish } from "../../util/common-util";

export function ethicalApplicationStatusCustomValidation(
  value: EthicalApplicationStatus,
  skipOptional: boolean,
): ErrorMessageObject[] {
  if (skipOptional) return [];

  const errorMessageList: ErrorMessageObject[] = [];

  /**
   * 申請中が選択されているにも関わらず、承認見込み月が未入力の場合のエラー
   */
  if (
    value.ethicalApplicationStatus === "requesting" &&
    isNullish(value.estimatedApprovalYearMonth)
  ) {
    errorMessageList.push({
      errorMessageId: messageId("mes.倫理申請の状況エラー"),
    });
  }

  /**
   * 承認済が選択されているにもかかわらず、承認見込み月が入力されている場合のエラー
   */
  if (
    value.ethicalApplicationStatus === "approved" &&
    hasValue(value.estimatedApprovalYearMonth)
  ) {
    errorMessageList.push({
      errorMessageId: messageId("mes.一般エラーメッセージ"),
    });
  }
  return errorMessageList;
}
