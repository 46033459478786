import { Box, Stack, Text } from "@chakra-ui/react";
import {
  CMFormInputDate,
  CMFormInputRadio,
  CMFormInputText,
} from "@pscsrvlab/psc-react-components";
import React, { memo, useCallback } from "react";
import { shippingDestinationNameMeta } from "../../../../../../lib/object/value/shipping-destination-name";
import { pickupLocationMeta } from "../../../../../../lib/object/value/pickup-location";
import { shippingDestinationOtherMeta } from "../../../../../../lib/object/value/shipping-destination-other";
import {
  ShippingContainersArrangementType,
  shippingContainersArrangementTypeMeta,
} from "../../../../../../lib/object/value/shipping-containers-arrangement-type";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { FormSection } from "../../../../../ui/form/FormSection/FormSection";
import { PickupDestination } from "../../../../../../lib/object/value/pickup-destination";
import { CellStockDeliveryViewModel } from "../../../../../../lib/object/vm/cell-stock-delivery-view-model";
import { YearMonthDay } from "../../../../../../lib/object/value/year-month-day";
import { freeTextMeta } from "../../../../../../lib/object/value/free-text";

export type SectionShippingContainersProps = {
  /**
   * 画面編集モード
   */
  editMode: "editable" | "readOnly";

  /**
   * 輸送容器
   */
  container: CellStockDeliveryViewModel["container"];
  onChangeContainer: (
    change: (
      before: CellStockDeliveryViewModel["container"],
    ) => CellStockDeliveryViewModel["container"],
  ) => void;

  /**
   * 集荷先
   */
  pickup: PickupDestination;
  onChangePickupDestination: (
    change: (before: PickupDestination) => PickupDestination,
  ) => void;
};

/**
 * FN30-S09細胞発送管理
 * 輸送容器セクション
 */
export const SectionShippingContainers = memo(
  function SectionShippingContainers({
    editMode,

    container,
    onChangeContainer,

    pickup,
    onChangePickupDestination,
  }: SectionShippingContainersProps) {
    const { t } = useAppTranslation();

    // 輸送容器関係
    const handleChangeShippingContainerArrangement = useCallback(
      (selectedValue: ShippingContainersArrangementType) => {
        onChangeContainer?.((before) => ({
          ...before,
          shippingContainerArrangementType: selectedValue,
        }));
      },
      [onChangeContainer],
    );
    const handleChangeShippingContainerReturningDate = useCallback(
      (v: YearMonthDay) => {
        onChangeContainer?.((before) => ({
          ...before,
          shippingContainerReturningDate: v,
        }));
      },
      [onChangeContainer],
    );

    // 集荷先関係
    const handleChangeDestinationName = useCallback(
      (v: string) => {
        onChangePickupDestination?.((before) => ({
          ...before,
          destinationName: v,
        }));
      },
      [onChangePickupDestination],
    );
    const handleChangePickupLocation = useCallback(
      (v: string) => {
        onChangePickupDestination?.((before) => ({
          ...before,
          pickupLocation: v,
        }));
      },
      [onChangePickupDestination],
    );
    const handleChangePostalCode = useCallback(
      (v: string) => {
        onChangePickupDestination?.((before) => ({
          ...before,
          postalCode: v,
        }));
      },
      [onChangePickupDestination],
    );
    const handleChangeAddress = useCallback(
      (v: string) => {
        onChangePickupDestination?.((before) => ({
          ...before,
          address: v,
        }));
      },
      [onChangePickupDestination],
    );
    const handleChangeTitleAndPosition = useCallback(
      (v: string) => {
        onChangePickupDestination?.((before) => ({
          ...before,
          titleAndPosition: v,
        }));
      },
      [onChangePickupDestination],
    );
    const handleChangeFullName = useCallback(
      (v: string) => {
        onChangePickupDestination?.((before) => ({
          ...before,
          fullName: v,
        }));
      },
      [onChangePickupDestination],
    );
    const handleChangePhoneNumber = useCallback(
      (v: string) => {
        onChangePickupDestination?.((before) => ({
          ...before,
          phoneNumber: v,
        }));
      },
      [onChangePickupDestination],
    );
    const handleChangeMailAddress = useCallback(
      (v: string) => {
        onChangePickupDestination?.((before) => ({
          ...before,
          mailAddress: v,
        }));
      },
      [onChangePickupDestination],
    );
    const handleChangeOther = useCallback(
      (v: string) => {
        onChangePickupDestination?.((before) => ({
          ...before,
          other: v,
        }));
      },
      [onChangePickupDestination],
    );

    return (
      <FormSection title={t("lbl.輸送容器について")}>
        {editMode === "editable" && (
          <Text fontSize={"12px"} color={"red"}>
            {t("gdc.輸送容器補足")}
          </Text>
        )}

        <Box>
          <Text fontWeight={"bold"} fontSize={"15px"}>
            {t("lbl.輸送容器の手配方法")}
          </Text>
          <CMFormInputRadio
            editMode={editMode}
            noHeader={true}
            value={container.shippingContainerArrangementType}
            valueObjectMeta={shippingContainersArrangementTypeMeta}
            onChange={handleChangeShippingContainerArrangement}
            sx={{
              pl: "16px",
              // 2個目以降のmarginを調整
              ".chakra-stack div:nth-of-type(n+2)": { marginTop: "5px" },
            }}
          />
        </Box>

        <Box>
          <Text fontWeight={"bold"} fontSize={"15px"}>
            {t("lbl.輸送容器返却日")}
          </Text>
          {editMode === "editable" && (
            <Text mt={"8px"} fontSize={"12px"} color={"red"}>
              {t("gdc.容器の返却日時補足")}
            </Text>
          )}
          <CMFormInputDate
            editMode={editMode}
            noHeader={true}
            value={container.shippingContainerReturningDate}
            onChange={handleChangeShippingContainerReturningDate}
            mt={"8px"}
            pl={"16px"}
          />
        </Box>

        <Box>
          <Text fontWeight={"bold"} fontSize={"15px"}>
            {t("lbl.集荷先情報")}
          </Text>
          {editMode === "editable" && (
            <Text mt={"8px"} fontSize={"12px"} color={"red"}>
              {t("gdc.集荷先情報補足")}
            </Text>
          )}
          <Stack mt={"8px"} spacing={"12px"}>
            <CMFormInputText
              editMode={editMode}
              label={t("lbl.名称")}
              value={pickup.destinationName}
              valueObjectMeta={shippingDestinationNameMeta}
              onChange={handleChangeDestinationName}
            />
            <CMFormInputText
              editMode={editMode}
              label={t("lbl.受渡場所")}
              value={pickup.pickupLocation}
              valueObjectMeta={pickupLocationMeta}
              onChange={handleChangePickupLocation}
            />
            <CMFormInputText
              editMode={editMode}
              label={t("lbl.郵便番号")}
              value={pickup.postalCode}
              valueObjectMeta={freeTextMeta}
              onChange={handleChangePostalCode}
            />
            <CMFormInputText
              editMode={editMode}
              label={t("lbl.住所")}
              value={pickup.address}
              valueObjectMeta={freeTextMeta}
              onChange={handleChangeAddress}
            />
            <CMFormInputText
              editMode={editMode}
              label={t("lbl.受渡人所属")}
              value={pickup.titleAndPosition}
              valueObjectMeta={freeTextMeta}
              onChange={handleChangeTitleAndPosition}
            />
            <CMFormInputText
              editMode={editMode}
              label={t("lbl.受渡人氏名")}
              value={pickup.fullName}
              valueObjectMeta={freeTextMeta}
              onChange={handleChangeFullName}
            />
            <CMFormInputText
              editMode={editMode}
              label={t("lbl.電話番号")}
              value={pickup.phoneNumber}
              valueObjectMeta={freeTextMeta}
              onChange={handleChangePhoneNumber}
            />
            <CMFormInputText
              editMode={editMode}
              label={t("lbl.メールアドレス")}
              value={pickup.mailAddress}
              valueObjectMeta={freeTextMeta}
              onChange={handleChangeMailAddress}
            />
            <CMFormInputText
              editMode={editMode}
              label={t("lbl.その他")}
              value={pickup.other}
              valueObjectMeta={shippingDestinationOtherMeta}
              onChange={handleChangeOther}
            />
          </Stack>
        </Box>
      </FormSection>
    );
  },
);
