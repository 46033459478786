import { ComponentStyleProps } from "../../../lib/styles/props/component-style-props";
import {
  ChangeBadgeProperties,
  CMButtonFormCommentProps,
  CMFormInputSearchAndSelect,
  FormRequiredLabelProps,
  SearchDropdownDisplayValue,
} from "@pscsrvlab/psc-react-components";
import { ReactElement, useCallback, useState } from "react";
import { hasValue } from "../../../lib/util/common-util";
import { stockRequestApi as api } from "../../../store/api/enhanced-api";
import log from "loglevel";
import { errorMessageOf } from "../../../lib/util/error-util";
import useCustomToast from "../../../hooks/use-custom-toast";
import { Institution } from "../../../store/api/generated/stock-request-api";
import { useAppTranslation } from "../../../hooks/use-app-translation";

export type FormInputInstitutionSelectProps = {
  editMode?: "editable" | "disabled" | "readOnly";
  size?: "sm" | "md" | "lg";
  noHeader?: boolean;
  label?: string;
  placeholder?: string;
  placeholderPopover?: string;
  institutionId?: number;
  hideClearButton?: boolean;
  labelSubmit?: string;
  labelClear?: string;
  selectButtonRightIcon?: ReactElement;
  popoverTitle?: string;
  searchResultNone?: string;
  changedFromDisplayValue?: string;
  onChange?: (value?: number) => void;
  requiredLabelProps?: FormRequiredLabelProps;
  changeBadgeProperties?: ChangeBadgeProperties<number>;
  checkEqualityFn?: (v0: number, v1: number) => boolean | Promise<boolean>;
  commentButtonProps?: CMButtonFormCommentProps;
  usePortal?: boolean;
} & ComponentStyleProps;

/**
 * 機関セレクトボックス(入力フォーム用)
 * @constructor
 */
export const FormInputInstitutionSelect = ({
  editMode,
  size,
  noHeader,
  label,
  placeholder,
  placeholderPopover,
  institutionId,
  hideClearButton,
  labelSubmit,
  labelClear,
  selectButtonRightIcon,
  popoverTitle,
  searchResultNone,
  changedFromDisplayValue,
  onChange,
  requiredLabelProps,
  changeBadgeProperties,
  checkEqualityFn,
  commentButtonProps,
  usePortal,
  sx,
  ...rest
}: FormInputInstitutionSelectProps) => {
  const { t } = useAppTranslation();
  const { errorToast } = useCustomToast();

  // 機関情報一覧取得用遅延クエリ
  const [triggerListInstitutionQuery] = api.useLazyListInstitutionQuery();
  // 機関情報取得用遅延クエリ
  const [triggerGetInstitutionQuery] = api.useLazyGetInstitutionQuery();

  // 検索結果(退避用)
  const [searchResult, setSearchResult] = useState<Institution[]>([]);

  // 表示用ラベルの取得
  const toDisplayValue = async (institutionId: number) => {
    // 機関情報を取得し機関名を返却
    const institution = await getInstitution(institutionId);
    return {
      mainLabel: institution?.name ?? "",
    } as SearchDropdownDisplayValue;
  };

  // 機関情報の取得
  const getInstitution = useCallback(
    async (institutionId: number): Promise<Institution | undefined> => {
      try {
        // 検索結果の退避が無い場合はAPIから取得
        if (!searchResult.length) {
          return await triggerGetInstitutionQuery({
            institutionId: institutionId,
          }).unwrap();
        }
        // 検索結果の退避が在る場合はそこから取得
        else {
          // 機関IDが一致するデータを検索結果(退避用)から抽出して返却
          return searchResult?.find(
            (institution) => institution.id === institutionId,
          );
        }
      } catch (e) {
        log.error(errorMessageOf(e));
        errorToast(t("mes.汎用エラーメッセージ"));
      }
    },
    [errorToast, searchResult, t, triggerGetInstitutionQuery],
  );

  // 検索時のイベントハンドラ
  const handleSearch = async (text: string) => {
    try {
      // 機関名(入力値)を元に機関を検索
      const data = await triggerListInstitutionQuery({
        institutionName: text,
      }).unwrap();
      // 検索結果を退避
      setSearchResult(data);
      // 機関IDのリストを返却
      return data.map((institution) => institution.id);
    } catch (e) {
      // 機関取得に失敗した場合は空のリストを返却
      log.error(errorMessageOf(e));
      errorToast(t("mes.汎用エラーメッセージ"));
      setSearchResult([]);
      return [];
    }
  };

  // 確定ボタン押下時のイベントハンドラ
  const handleChange = (institutionId: number | null) => {
    // 親コンポーネントから渡されたイベントをトリガー
    onChange?.(hasValue(institutionId) ? institutionId : undefined);
  };

  return (
    <CMFormInputSearchAndSelect
      editMode={editMode}
      size={size}
      noHeader={noHeader}
      label={label ?? t("lbl.機関名")}
      placeholder={placeholder}
      placeholderPopover={placeholderPopover}
      value={institutionId}
      hideClearButton={hideClearButton}
      labelSubmit={labelSubmit ?? t("btn.確定ボタン")}
      labelClear={labelClear ?? t("btn.クリアボタン")}
      selectButtonRightIcon={selectButtonRightIcon}
      popoverTitle={popoverTitle ?? t("mes.機関選択案内メッセージ")}
      searchResultNone={searchResultNone ?? t("mes.該当機関なしメッセージ")}
      toDisplayValue={toDisplayValue}
      menuShowMaxCount={30}
      changedFromDisplayValue={changedFromDisplayValue}
      onSearch={handleSearch}
      onChange={handleChange}
      requiredLabelProps={requiredLabelProps}
      changeBadgeProperties={changeBadgeProperties}
      checkEqualityFn={checkEqualityFn}
      commentButtonProps={commentButtonProps}
      usePortal={usePortal}
      sx={sx}
      {...rest}
    />
  );
};
