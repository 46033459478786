import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * 検索結果件数C(値オブジェクト)
 *
 *
 *
 * n50:
 * n100:
 * n150:
 * n200:  */
export type NumberOfSearchResultsC = z.infer<
  typeof numberOfSearchResultsCMeta.schema
>;
/**
 * 検索結果件数C(値オブジェクト)のメタデータ。
 *
 *  */
export const numberOfSearchResultsCMeta = new ValueObjectMetaEnum<
  ["n50", "n100", "n150", "n200"]
>(
  "NumberOfSearchResultsC",
  "\u691C\u7D22\u7D50\u679C\u4EF6\u6570C",
  messageId("lbl.\u691C\u7D22\u7D50\u679C\u4EF6\u6570C"),
  ["n50", "n100", "n150", "n200"],
  {
    n50: "code.\u691C\u7D22\u7D50\u679C\u4EF6\u6570.50\u4EF6",
    n100: "code.\u691C\u7D22\u7D50\u679C\u4EF6\u6570.100\u4EF6",
    n150: "code.\u691C\u7D22\u7D50\u679C\u4EF6\u6570.150\u4EF6",
    n200: "code.\u691C\u7D22\u7D50\u679C\u4EF6\u6570.200\u4EF6",
  }
);
