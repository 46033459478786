import {
  messageId,
  PropertyMeta,
  EntityMeta,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { cellStockDeliveryIdMeta } from "../value/cell-stock-delivery-id";
import { projectIdMeta } from "../value/project-id";
import { shippingInformationMeta } from "../value/shipping-information";
import { receiptInformationMeta } from "../value/receipt-information";
import { shippingCellMeta } from "../value/shipping-cell";
import { shippingDestinationMeta } from "../value/shipping-destination";
import { yearMonthDayMeta } from "../value/year-month-day";
import { deliveryArrangementMethodTypeMeta } from "../value/delivery-arrangement-method-type";
import { domesticDelivererTypeMeta } from "../value/domestic-deliverer-type";
import { domesticDelivererOtherMeta } from "../value/domestic-deliverer-other";
import { domesticDelivererShipperRegistrationTypeMeta } from "../value/domestic-deliverer-shipper-registration-type";
import { foreignDelivererTypeMeta } from "../value/foreign-deliverer-type";
import { foreignDelivererOtherMeta } from "../value/foreign-deliverer-other";
import { foreignDelivererAccountInformationMeta } from "../value/foreign-deliverer-account-information";
import { shippingContainersArrangementTypeMeta } from "../value/shipping-containers-arrangement-type";
import { pickupDestinationMeta } from "../value/pickup-destination";
import { freeTextMeta } from "../value/free-text";
import { attachmentFileInformationMeta } from "../value/attachment-file-information";
import { creationMetadataMeta } from "../value/creation-metadata";
import { updateMetadataMeta } from "../value/update-metadata";
/**
 * 細胞発送(エンティティ)
 *
 *  */
export type CellStockDelivery = z.infer<typeof schema>;
export type CellStockDeliverySaved = z.infer<typeof savedSchema>;
const minimalSchema = z.object({
  id: cellStockDeliveryIdMeta.minimalSchema.optional(),
  projectId: projectIdMeta.minimalSchema,
  shippingInformation: shippingInformationMeta.minimalSchema,
  receiptInformation: receiptInformationMeta.minimalSchema,
  shippingResearchCells: z.array(shippingCellMeta.minimalSchema),
  shippingClinicalCells: z.array(shippingCellMeta.minimalSchema),
  shippingDestination: shippingDestinationMeta.minimalSchema,
  preferredDeliveryDate: yearMonthDayMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  deliveryArrangementMethodType:
    deliveryArrangementMethodTypeMeta.minimalSchema,
  domesticDelivererType: domesticDelivererTypeMeta.minimalSchema,
  domesticDelivererOther: domesticDelivererOtherMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  domesticDelivererShipperRegistrationType:
    domesticDelivererShipperRegistrationTypeMeta.minimalSchema,
  foreignDelivererType: foreignDelivererTypeMeta.minimalSchema,
  foreignDelivererOther: foreignDelivererOtherMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  foreignDelivererAccountInformation:
    foreignDelivererAccountInformationMeta.minimalSchema,
  shippingContainerArrangementType:
    shippingContainersArrangementTypeMeta.minimalSchema,
  shippingContainerReturningDate: yearMonthDayMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  pickupDestination: pickupDestinationMeta.minimalSchema,
  provisionFeeMemo: freeTextMeta.minimalSchema,
  officeMemberCommentForApplicants: freeTextMeta.minimalSchema,
  officeMemberCommentInternal: freeTextMeta.minimalSchema,
  attachmentFiles: z.array(attachmentFileInformationMeta.minimalSchema),
  created: creationMetadataMeta.minimalSchema,
  updated: updateMetadataMeta.minimalSchema,
});
const savedMinimalSchema = z.object({
  id: cellStockDeliveryIdMeta.minimalSchema,
  projectId: projectIdMeta.savedMinimalSchema,
  shippingInformation: shippingInformationMeta.savedMinimalSchema,
  receiptInformation: receiptInformationMeta.savedMinimalSchema,
  shippingResearchCells: z.array(shippingCellMeta.savedMinimalSchema),
  shippingClinicalCells: z.array(shippingCellMeta.savedMinimalSchema),
  shippingDestination: shippingDestinationMeta.savedMinimalSchema,
  preferredDeliveryDate: yearMonthDayMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  deliveryArrangementMethodType:
    deliveryArrangementMethodTypeMeta.savedMinimalSchema,
  domesticDelivererType: domesticDelivererTypeMeta.savedMinimalSchema,
  domesticDelivererOther: domesticDelivererOtherMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  domesticDelivererShipperRegistrationType:
    domesticDelivererShipperRegistrationTypeMeta.savedMinimalSchema,
  foreignDelivererType: foreignDelivererTypeMeta.savedMinimalSchema,
  foreignDelivererOther: foreignDelivererOtherMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  foreignDelivererAccountInformation:
    foreignDelivererAccountInformationMeta.savedMinimalSchema,
  shippingContainerArrangementType:
    shippingContainersArrangementTypeMeta.savedMinimalSchema,
  shippingContainerReturningDate: yearMonthDayMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  pickupDestination: pickupDestinationMeta.savedMinimalSchema,
  provisionFeeMemo: freeTextMeta.savedMinimalSchema,
  officeMemberCommentForApplicants: freeTextMeta.savedMinimalSchema,
  officeMemberCommentInternal: freeTextMeta.savedMinimalSchema,
  attachmentFiles: z.array(attachmentFileInformationMeta.savedMinimalSchema),
  created: creationMetadataMeta.savedMinimalSchema,
  updated: updateMetadataMeta.savedMinimalSchema,
});
const schema = z.object({
  id: cellStockDeliveryIdMeta.minimalSchema.optional(),
  projectId: projectIdMeta.schema,
  shippingInformation: shippingInformationMeta.schema,
  receiptInformation: receiptInformationMeta.schema,
  shippingResearchCells: z.array(shippingCellMeta.schema),
  shippingClinicalCells: z.array(shippingCellMeta.schema),
  shippingDestination: shippingDestinationMeta.schema,
  preferredDeliveryDate: yearMonthDayMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
  deliveryArrangementMethodType: deliveryArrangementMethodTypeMeta.schema,
  domesticDelivererType: domesticDelivererTypeMeta.schema,
  domesticDelivererOther: domesticDelivererOtherMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
  domesticDelivererShipperRegistrationType:
    domesticDelivererShipperRegistrationTypeMeta.schema,
  foreignDelivererType: foreignDelivererTypeMeta.schema,
  foreignDelivererOther: foreignDelivererOtherMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
  foreignDelivererAccountInformation:
    foreignDelivererAccountInformationMeta.schema,
  shippingContainerArrangementType:
    shippingContainersArrangementTypeMeta.schema,
  shippingContainerReturningDate: yearMonthDayMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
  pickupDestination: pickupDestinationMeta.schema,
  provisionFeeMemo: freeTextMeta.schema,
  officeMemberCommentForApplicants: freeTextMeta.schema,
  officeMemberCommentInternal: freeTextMeta.schema,
  attachmentFiles: z.array(attachmentFileInformationMeta.schema),
  created: creationMetadataMeta.schema,
  updated: updateMetadataMeta.schema,
});
const savedSchema = z.object({
  id: cellStockDeliveryIdMeta.minimalSchema,
  projectId: projectIdMeta.savedSchema,
  shippingInformation: shippingInformationMeta.savedSchema,
  receiptInformation: receiptInformationMeta.savedSchema,
  shippingResearchCells: z.array(shippingCellMeta.savedSchema),
  shippingClinicalCells: z.array(shippingCellMeta.savedSchema),
  shippingDestination: shippingDestinationMeta.savedSchema,
  preferredDeliveryDate: yearMonthDayMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  deliveryArrangementMethodType: deliveryArrangementMethodTypeMeta.savedSchema,
  domesticDelivererType: domesticDelivererTypeMeta.savedSchema,
  domesticDelivererOther: domesticDelivererOtherMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  domesticDelivererShipperRegistrationType:
    domesticDelivererShipperRegistrationTypeMeta.savedSchema,
  foreignDelivererType: foreignDelivererTypeMeta.savedSchema,
  foreignDelivererOther: foreignDelivererOtherMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  foreignDelivererAccountInformation:
    foreignDelivererAccountInformationMeta.savedSchema,
  shippingContainerArrangementType:
    shippingContainersArrangementTypeMeta.savedSchema,
  shippingContainerReturningDate: yearMonthDayMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  pickupDestination: pickupDestinationMeta.savedSchema,
  provisionFeeMemo: freeTextMeta.savedSchema,
  officeMemberCommentForApplicants: freeTextMeta.savedSchema,
  officeMemberCommentInternal: freeTextMeta.savedSchema,
  attachmentFiles: z.array(attachmentFileInformationMeta.savedSchema),
  created: creationMetadataMeta.savedSchema,
  updated: updateMetadataMeta.savedSchema,
});
const jsonSchema = z.object({
  id: cellStockDeliveryIdMeta.jsonSchema.optional(),
  projectId: projectIdMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  shippingInformation: shippingInformationMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  receiptInformation: receiptInformationMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  shippingResearchCells: z
    .array(shippingCellMeta.jsonSchema)
    .nullish()
    .transform((x) => x ?? undefined),
  shippingClinicalCells: z
    .array(shippingCellMeta.jsonSchema)
    .nullish()
    .transform((x) => x ?? undefined),
  shippingDestination: shippingDestinationMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  preferredDeliveryDate: yearMonthDayMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  deliveryArrangementMethodType: deliveryArrangementMethodTypeMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  domesticDelivererType: domesticDelivererTypeMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  domesticDelivererOther: domesticDelivererOtherMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  domesticDelivererShipperRegistrationType:
    domesticDelivererShipperRegistrationTypeMeta.jsonSchema
      .nullish()
      .transform((x) => x ?? undefined),
  foreignDelivererType: foreignDelivererTypeMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  foreignDelivererOther: foreignDelivererOtherMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  foreignDelivererAccountInformation:
    foreignDelivererAccountInformationMeta.jsonSchema
      .nullish()
      .transform((x) => x ?? undefined),
  shippingContainerArrangementType:
    shippingContainersArrangementTypeMeta.jsonSchema
      .nullish()
      .transform((x) => x ?? undefined),
  shippingContainerReturningDate: yearMonthDayMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  pickupDestination: pickupDestinationMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  provisionFeeMemo: freeTextMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  officeMemberCommentForApplicants: freeTextMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  officeMemberCommentInternal: freeTextMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  attachmentFiles: z
    .array(attachmentFileInformationMeta.jsonSchema)
    .nullish()
    .transform((x) => x ?? undefined),
  created: creationMetadataMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  updated: updateMetadataMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
/**
 * 細胞発送(エンティティ)のメタデータ。
 *
 *  */
export const cellStockDeliveryMeta = new EntityMeta<
  typeof schema,
  typeof savedSchema,
  typeof jsonSchema
>(
  "CellStockDelivery",
  "\u7D30\u80DE\u767A\u9001",
  messageId("lbl.\u7D30\u80DE\u767A\u9001"),
  minimalSchema,
  savedMinimalSchema,
  schema,
  savedSchema,
  jsonSchema,
  undefined,
  undefined,
  {
    id: new PropertyMeta(
      "id",
      "ID",
      messageId("lbl.ID"),
      "afterSave",
      false,
      cellStockDeliveryIdMeta
    ),
    projectId: new PropertyMeta(
      "projectId",
      "\u6848\u4EF6ID",
      messageId("lbl.\u6848\u4EF6ID"),
      "always",
      false,
      projectIdMeta
    ),
    shippingInformation: new PropertyMeta(
      "shippingInformation",
      "\u767A\u9001\u60C5\u5831",
      messageId("lbl.\u767A\u9001\u60C5\u5831"),
      "always",
      false,
      shippingInformationMeta
    ),
    receiptInformation: new PropertyMeta(
      "receiptInformation",
      "\u53D7\u9818\u60C5\u5831",
      messageId("lbl.\u53D7\u9818\u60C5\u5831"),
      "always",
      false,
      receiptInformationMeta
    ),
    shippingResearchCells: new PropertyMeta(
      "shippingResearchCells",
      "\u767A\u9001\u3059\u308B\u7D30\u80DE(\u7814\u7A76\u7528)",
      messageId("lbl.\u767A\u9001\u3059\u308B\u7D30\u80DE(\u7814\u7A76\u7528)"),
      "always",
      true,
      shippingCellMeta
    ),
    shippingClinicalCells: new PropertyMeta(
      "shippingClinicalCells",
      "\u767A\u9001\u3059\u308B\u7D30\u80DE(\u81E8\u5E8A\u7528)",
      messageId("lbl.\u767A\u9001\u3059\u308B\u7D30\u80DE(\u81E8\u5E8A\u7528)"),
      "always",
      true,
      shippingCellMeta
    ),
    shippingDestination: new PropertyMeta(
      "shippingDestination",
      "\u51FA\u8377\u5148",
      messageId("lbl.\u51FA\u8377\u5148"),
      "always",
      false,
      shippingDestinationMeta
    ),
    preferredDeliveryDate: new PropertyMeta(
      "preferredDeliveryDate",
      "\u5230\u7740\u5E0C\u671B\u65E5",
      messageId("lbl.\u5230\u7740\u5E0C\u671B\u65E5"),
      "none",
      false,
      yearMonthDayMeta
    ),
    deliveryArrangementMethodType: new PropertyMeta(
      "deliveryArrangementMethodType",
      "\u8F38\u9001\u624B\u914D\u65B9\u6CD5\u533A\u5206",
      messageId("lbl.\u8F38\u9001\u624B\u914D\u65B9\u6CD5\u533A\u5206"),
      "always",
      false,
      deliveryArrangementMethodTypeMeta
    ),
    domesticDelivererType: new PropertyMeta(
      "domesticDelivererType",
      "\u56FD\u5185\u8F38\u9001\u624B\u914D\u696D\u8005\u533A\u5206",
      messageId(
        "lbl.\u56FD\u5185\u8F38\u9001\u624B\u914D\u696D\u8005\u533A\u5206"
      ),
      "always",
      false,
      domesticDelivererTypeMeta
    ),
    domesticDelivererOther: new PropertyMeta(
      "domesticDelivererOther",
      "\u56FD\u5185\u8F38\u9001\u624B\u914D\u696D\u8005\u305D\u306E\u4ED6",
      messageId(
        "lbl.\u56FD\u5185\u8F38\u9001\u624B\u914D\u696D\u8005\u305D\u306E\u4ED6"
      ),
      "none",
      false,
      domesticDelivererOtherMeta
    ),
    domesticDelivererShipperRegistrationType: new PropertyMeta(
      "domesticDelivererShipperRegistrationType",
      "\u56FD\u5185\u8F38\u9001\u624B\u914D\u696D\u8005\u8377\u4E3B\u767B\u9332\u533A\u5206",
      messageId(
        "lbl.\u56FD\u5185\u8F38\u9001\u624B\u914D\u696D\u8005\u8377\u4E3B\u767B\u9332\u533A\u5206"
      ),
      "always",
      false,
      domesticDelivererShipperRegistrationTypeMeta
    ),
    foreignDelivererType: new PropertyMeta(
      "foreignDelivererType",
      "\u56FD\u5916\u8F38\u9001\u624B\u914D\u696D\u8005\u533A\u5206",
      messageId(
        "lbl.\u56FD\u5916\u8F38\u9001\u624B\u914D\u696D\u8005\u533A\u5206"
      ),
      "always",
      false,
      foreignDelivererTypeMeta
    ),
    foreignDelivererOther: new PropertyMeta(
      "foreignDelivererOther",
      "\u56FD\u5916\u8F38\u9001\u624B\u914D\u696D\u8005\u305D\u306E\u4ED6",
      messageId(
        "lbl.\u56FD\u5916\u8F38\u9001\u624B\u914D\u696D\u8005\u305D\u306E\u4ED6"
      ),
      "none",
      false,
      foreignDelivererOtherMeta
    ),
    foreignDelivererAccountInformation: new PropertyMeta(
      "foreignDelivererAccountInformation",
      "\u56FD\u5916\u8F38\u9001\u30A2\u30AB\u30A6\u30F3\u30C8\u60C5\u5831",
      messageId(
        "lbl.\u56FD\u5916\u8F38\u9001\u30A2\u30AB\u30A6\u30F3\u30C8\u60C5\u5831"
      ),
      "always",
      false,
      foreignDelivererAccountInformationMeta
    ),
    shippingContainerArrangementType: new PropertyMeta(
      "shippingContainerArrangementType",
      "\u8F38\u9001\u5BB9\u5668\u624B\u914D\u65B9\u6CD5\u533A\u5206",
      messageId(
        "lbl.\u8F38\u9001\u5BB9\u5668\u624B\u914D\u65B9\u6CD5\u533A\u5206"
      ),
      "always",
      false,
      shippingContainersArrangementTypeMeta
    ),
    shippingContainerReturningDate: new PropertyMeta(
      "shippingContainerReturningDate",
      "\u8F38\u9001\u5BB9\u5668\u8FD4\u5374\u65E5",
      messageId("lbl.\u8F38\u9001\u5BB9\u5668\u8FD4\u5374\u65E5"),
      "none",
      false,
      yearMonthDayMeta
    ),
    pickupDestination: new PropertyMeta(
      "pickupDestination",
      "\u96C6\u8377\u5148",
      messageId("lbl.\u96C6\u8377\u5148"),
      "always",
      false,
      pickupDestinationMeta
    ),
    provisionFeeMemo: new PropertyMeta(
      "provisionFeeMemo",
      "\u63D0\u4F9B\u4EE3\u30E1\u30E2",
      messageId("lbl.\u63D0\u4F9B\u4EE3\u30E1\u30E2"),
      "always",
      false,
      freeTextMeta
    ),
    officeMemberCommentForApplicants: new PropertyMeta(
      "officeMemberCommentForApplicants",
      "\u7533\u8ACB\u8005\u5411\u3051\u4E8B\u52D9\u5C40\u30B3\u30E1\u30F3\u30C8\u6B04",
      messageId(
        "lbl.\u7533\u8ACB\u8005\u5411\u3051\u4E8B\u52D9\u5C40\u30B3\u30E1\u30F3\u30C8\u6B04"
      ),
      "always",
      false,
      freeTextMeta
    ),
    officeMemberCommentInternal: new PropertyMeta(
      "officeMemberCommentInternal",
      "\u4E8B\u52D9\u5C40\u30B3\u30E1\u30F3\u30C8\u6B04",
      messageId("lbl.\u4E8B\u52D9\u5C40\u30B3\u30E1\u30F3\u30C8\u6B04"),
      "always",
      false,
      freeTextMeta
    ),
    attachmentFiles: new PropertyMeta(
      "attachmentFiles",
      "\u6DFB\u4ED8\u8CC7\u6599",
      messageId("lbl.\u6DFB\u4ED8\u8CC7\u6599"),
      "always",
      true,
      attachmentFileInformationMeta
    ),
    created: new PropertyMeta(
      "created",
      "\u4F5C\u6210",
      messageId("lbl.\u4F5C\u6210"),
      "always",
      false,
      creationMetadataMeta
    ),
    updated: new PropertyMeta(
      "updated",
      "\u66F4\u65B0",
      messageId("lbl.\u66F4\u65B0"),
      "always",
      false,
      updateMetadataMeta
    ),
  }
);
