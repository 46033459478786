import { Text } from "@chakra-ui/react";
import React, { memo } from "react";
import { ComponentStyleProps } from "../../../../lib/styles/props/component-style-props";

export type TitleTextProps = {
  title?: string;
} & ComponentStyleProps;

/**
 * 黒太文字のタイトル。
 * capitalizeはしない。
 */
export const TitleText = memo(function TitleText({
  title,

  sx,
  ...rest
}: TitleTextProps) {
  return (
    <Text
      fontWeight={700}
      fontSize={"24px"}
      color={"gray.900"}
      sx={sx}
      {...rest}
    >
      {title}
    </Text>
  );
});
