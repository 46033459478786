import { VStack } from "@chakra-ui/react";
import { CMMessageInfo } from "@pscsrvlab/psc-react-components";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";

export const DocumentContentEmptyPage = () => {
  const { t } = useAppTranslation();
  return (
    <VStack flex={1} alignItems={"flex-start"}>
      <CMMessageInfo label={t("gdc.審査結果未確定時インフォメーション")} />
    </VStack>
  );
};
