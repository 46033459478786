import * as React from "react";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import useConfigFile from "./hooks/use-config-file";
import { ProcessLoader } from "./components/ui/other/ProcessLoader/ProcessLoader";
import { hasValue } from "./lib/util/common-util";
import i18n from "./i18n";
import log from "loglevel";
import { AuthProvider } from "./AuthProvider";
import { AppContextProvider } from "./AppContextProvider";

export const App = () => {
  // 設定ファイルを取得
  const { isLoading, messages } = useConfigFile();
  // メッセージリソースのセットアップ完了状態
  const [setupMessageCompleted, setSetupMessageCompleted] = useState(false);

  // メッセージリソースを読み込み
  useEffect(() => {
    log.debug("setup message resources.");
    if (hasValue(messages)) {
      // 既に存在するデータを削除
      i18n.removeResourceBundle("en", "translation");
      i18n.removeResourceBundle("en", "commonValidation");
      i18n.removeResourceBundle("ja", "translation");
      i18n.removeResourceBundle("ja", "commonValidation");

      // バックエンドから取得したデータを投入
      i18n.addResourceBundle("en", "translation", messages.en.common);
      i18n.addResourceBundle("en", "translation", messages.en.custom);
      i18n.addResourceBundle("en", "translation", messages.en.item);
      i18n.addResourceBundle("en", "commonValidation", messages.en.common);

      i18n.addResourceBundle("ja", "translation", messages.ja.common);
      i18n.addResourceBundle("ja", "translation", messages.ja.custom);
      i18n.addResourceBundle("ja", "translation", messages.ja.item);
      i18n.addResourceBundle("ja", "commonValidation", messages.ja.common);

      setSetupMessageCompleted(true);
    }
  }, [messages]);

  // useHashLinkSim();

  // 設定ファイルの読み込み中はローダーを表示
  if (isLoading || !setupMessageCompleted) {
    return <ProcessLoader />;
  }
  // 読み込みが完了すればコンテンツを表示
  return (
    <AuthProvider>
      <AppContextProvider>
        <Outlet />
      </AppContextProvider>
    </AuthProvider>
  );
};
