import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * 検索結果件数B(値オブジェクト)
 *
 *
 *
 * n10:
 * n20:
 * n50:
 * n100:  */
export type NumberOfSearchResultsB = z.infer<
  typeof numberOfSearchResultsBMeta.schema
>;
/**
 * 検索結果件数B(値オブジェクト)のメタデータ。
 *
 *  */
export const numberOfSearchResultsBMeta = new ValueObjectMetaEnum<
  ["n10", "n20", "n50", "n100"]
>(
  "NumberOfSearchResultsB",
  "\u691C\u7D22\u7D50\u679C\u4EF6\u6570B",
  messageId("lbl.\u691C\u7D22\u7D50\u679C\u4EF6\u6570B"),
  ["n10", "n20", "n50", "n100"],
  {
    n10: "code.\u691C\u7D22\u7D50\u679C\u4EF6\u6570.10\u4EF6",
    n20: "code.\u691C\u7D22\u7D50\u679C\u4EF6\u6570.20\u4EF6",
    n50: "code.\u691C\u7D22\u7D50\u679C\u4EF6\u6570.50\u4EF6",
    n100: "code.\u691C\u7D22\u7D50\u679C\u4EF6\u6570.100\u4EF6",
  }
);
