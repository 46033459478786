import {
  messageId,
  PropertyMeta,
  ValueObjectMetaComposite,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { problemsDuringThePeriodCustomValidation } from "../custom-validation/problems-during-the-period";
import { problemPresenceMeta } from "../value/problem-presence";
import { problemDetailsMeta } from "../value/problem-details";
import { responseToProblemMeta } from "../value/response-to-problem";
/**
 * 期間中の問題点(値オブジェクト)
 *
 *  */
export type ProblemsDuringThePeriod = z.infer<typeof schema>;
export type ProblemsDuringThePeriodSaved = z.infer<typeof savedSchema>;
const minimalSchema = z.object({
  problemPresence: problemPresenceMeta.minimalSchema,
  problemDetails: problemDetailsMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  responseToProblem: responseToProblemMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
const savedMinimalSchema = z.object({
  problemPresence: problemPresenceMeta.savedMinimalSchema,
  problemDetails: problemDetailsMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  responseToProblem: responseToProblemMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
const schema = z.object({
  problemPresence: problemPresenceMeta.schema,
  problemDetails: problemDetailsMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
  responseToProblem: responseToProblemMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
});
const savedSchema = z.object({
  problemPresence: problemPresenceMeta.savedSchema,
  problemDetails: problemDetailsMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  responseToProblem: responseToProblemMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
const jsonSchema = z.object({
  problemPresence: problemPresenceMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  problemDetails: problemDetailsMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  responseToProblem: responseToProblemMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
/**
 * 期間中の問題点(値オブジェクト)のメタデータ。
 *
 *
 *
 * 以下のカスタムバリデーションを、 problemsDuringThePeriodCustomValidation にて行う。
 * - (任意) 問題点有無が有の場合に問題内容を必須にする。 (エラーメッセージID=mes.使用終了問題点有無問題内容未入力エラー)
 * - (任意) 問題点有無が有の場合に問題対応を必須にする。 (エラーメッセージID=mes.使用終了問題点有無問題対応未入力エラー) */
export const problemsDuringThePeriodMeta = new ValueObjectMetaComposite(
  "ProblemsDuringThePeriod",
  "\u671F\u9593\u4E2D\u306E\u554F\u984C\u70B9",
  messageId("lbl.\u671F\u9593\u4E2D\u306E\u554F\u984C\u70B9"),
  minimalSchema,
  savedMinimalSchema,
  schema,
  savedSchema,
  jsonSchema,
  undefined,
  problemsDuringThePeriodCustomValidation,
  {
    problemPresence: new PropertyMeta(
      "problemPresence",
      "\u554F\u984C\u70B9\u6709\u7121",
      messageId("lbl.\u554F\u984C\u70B9\u6709\u7121"),
      "always",
      false,
      problemPresenceMeta
    ),
    problemDetails: new PropertyMeta(
      "problemDetails",
      "\u554F\u984C\u5185\u5BB9",
      messageId("lbl.\u554F\u984C\u5185\u5BB9"),
      "none",
      false,
      problemDetailsMeta
    ),
    responseToProblem: new PropertyMeta(
      "responseToProblem",
      "\u554F\u984C\u5BFE\u5FDC\u5185\u5BB9",
      messageId("lbl.\u554F\u984C\u5BFE\u5FDC\u5185\u5BB9"),
      "none",
      false,
      responseToProblemMeta
    ),
  }
);
