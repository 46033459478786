import {
  Container,
  Flex,
  HStack,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useUpdateApplicationOfficeMemberNotesMutation } from "../../../../store/api/generated/stock-request-api";
import React, { useMemo, useState } from "react";
import useCustomToast from "../../../../hooks/use-custom-toast";
import { ProjectState } from "../../../../lib/object/value/project-state";
import log from "loglevel";
import {
  CMFormInputRadio,
  CMFormInputText,
  CMFormInputTextArea,
} from "@pscsrvlab/psc-react-components";
import { FrameUpperRightButton } from "../../../ui/frame/FrameUpperRightButton/FrameUpperRightButton";
import { contractPersonMeta } from "../../../../lib/object/value/contract-person";
import { provisionFeeRequiredMeta } from "../../../../lib/object/value/provision-fee-required";
import { reviewFeeRequiredMeta } from "../../../../lib/object/value/review-fee-required";
import { freeTextMeta } from "../../../../lib/object/value/free-text";
import { patentLicenseExplanationStateMeta } from "../../../../lib/object/value/patent-license-explanation-state";
import { ComponentStyleProps } from "../../../../lib/styles/props/component-style-props";
import {
  ApplicationOfficeMemberNotes,
  applicationOfficeMemberNotesMeta,
} from "../../../../lib/object/value/application-office-member-notes";
import { useCreateJsonPatchOperations } from "../../../../hooks/use-create-json-patch-operations";
import { isNullish } from "../../../../lib/util/common-util";
import { useAppTranslation } from "../../../../hooks/use-app-translation";
import { ConfirmationModal } from "../../../ui/modal/ConfirmationModal/ConfirmationModal";
import { SaveChangesButton } from "../../../ui/button/SaveChangesButton/SaveChangesButton";
import { EditEndButton } from "../../../ui/button/EditEndButton/EditEndButton";
import { EditButton } from "../../../ui/button/EditButton/EditButton";
import { useLeaveEditingPrompt } from "../../../../hooks/use-leave-editing-prompt";
import { FormSection } from "../../../ui/form/FormSection/FormSection";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import { selectHasRole } from "../../../../store/auth/slice";
import { errorMessageOf } from "../../../../lib/util/error-util";
import { DocumentState } from "../../../../lib/object/value/document-state";

export type ApplicationOfficeMemberNotesPageProps = {
  documentId: number;
  /**
   * バックエンドから取得する最新の事務局確認内容。参照モードではこれを表示する。
   */
  initialOfficeMemberNotesContent: ApplicationOfficeMemberNotes;
  projectState: ProjectState;
  documentState: DocumentState;
} & ComponentStyleProps;

export const ApplicationOfficeMemberNotesPage = ({
  documentId,
  initialOfficeMemberNotesContent,
  projectState,
  documentState,

  sx,
  ...rest
}: ApplicationOfficeMemberNotesPageProps) => {
  const { t } = useAppTranslation();
  const { errorToast, validationErrorToast } = useCustomToast();
  const { isOfficeMember } = useAppSelector(selectHasRole);

  const [updateApplicationOfficeMemberNotes] =
    useUpdateApplicationOfficeMemberNotesMutation();

  const [editMode, setEditMode] = useState<"editable" | "readOnly">("readOnly");

  useLeaveEditingPrompt({
    skip: editMode === "readOnly",
  });

  //事務局記入内容
  const [content, setContent] = useState<ApplicationOfficeMemberNotes>(
    initialOfficeMemberNotesContent,
  );

  const handleChange = (value: ApplicationOfficeMemberNotes) => {
    setContent(value);
  };

  const canEdit = useMemo(() => {
    return (
      isOfficeMember &&
      (projectState === "applied" || projectState === "ongoing") &&
      documentState !== "draft" &&
      documentState !== "submitted"
    );
  }, [documentState, isOfficeMember, projectState]);

  //編集ボタン押下時の処理。
  const handleClickEditButton = () => {
    setEditMode("editable");
  };

  //変更を保存するボタン押下時の処理。

  const { createJsonPatchOperations } = useCreateJsonPatchOperations(
    applicationOfficeMemberNotesMeta,
  );

  const handleSave = async () => {
    const validationResult = applicationOfficeMemberNotesMeta.validate(content);
    if (validationResult.state === "error") {
      validationErrorToast(validationResult.errors);
      return;
    }

    const jsonPatchOperations = createJsonPatchOperations(
      initialOfficeMemberNotesContent,
      content,
    );
    if (isNullish(jsonPatchOperations)) return;

    try {
      await updateApplicationOfficeMemberNotes({
        documentId: documentId,
        commonUpdateRequest: {
          patchOperations: jsonPatchOperations,
        },
      }).unwrap();

      setEditMode("readOnly");
    } catch (e) {
      log.error(errorMessageOf(e));
      errorToast(t("mes.事務局記入内容更新失敗エラー"));
    }
  };

  //編集終了確認モーダルの制御。
  const {
    isOpen: isOpenEditEndModal,
    onOpen: onOpenEditEndModal,
    onClose: onCloseEditEndModal,
  } = useDisclosure();

  //編集終了ボタン押下時の処理。
  const handleClickEndEditButton = () => {
    onOpenEditEndModal();
  };

  //編集終了確認モーダルの確定ボタン押下時の処理。
  const handleSubmitEditEnd = () => {
    setContent(initialOfficeMemberNotesContent);
    setEditMode("readOnly");
    onCloseEditEndModal();
  };

  // 保存確認モーダルの表示制御設定
  const {
    isOpen: isOpenSaveModal,
    onOpen: onOpenSaveModal,
    onClose: onCloseSaveModal,
  } = useDisclosure();

  return (
    <>
      <Flex
        direction={"column"}
        flex={"1 1 auto"}
        alignSelf={"stretch"}
        minH={0}
        overflow={"auto"}
        sx={sx}
        {...rest}
      >
        {canEdit && (
          <FrameUpperRightButton sx={{ alignSelf: "flex-end" }}>
            {editMode === "readOnly" ? (
              <EditButton onClick={handleClickEditButton} />
            ) : (
              <HStack>
                <SaveChangesButton onClick={onOpenSaveModal} />
                <EditEndButton onClick={handleClickEndEditButton} />
              </HStack>
            )}
          </FrameUpperRightButton>
        )}

        <Container minW={"500px"} maxW={"720px"} mt={"20px"} mb={"50px"}>
          <VStack alignItems={"stretch"}>
            <FormSection title={t("lbl.契約担当")}>
              <CMFormInputText
                noHeader={true}
                editMode={editMode}
                valueObjectMeta={contractPersonMeta}
                value={content.contractPerson}
                onChange={(value: string) =>
                  handleChange({ ...content, contractPerson: value })
                }
              />
            </FormSection>
            <FormSection title={t("lbl.提供代")}>
              <CMFormInputRadio
                noHeader={true}
                editMode={editMode}
                valueObjectMeta={provisionFeeRequiredMeta}
                value={content.provisionFeeRequired ?? "required"}
                onChange={(value: "required" | "not_required") =>
                  handleChange({ ...content, provisionFeeRequired: value })
                }
              />
            </FormSection>
            <FormSection title={t("lbl.審査料")}>
              <CMFormInputRadio
                noHeader={true}
                editMode={editMode}
                valueObjectMeta={reviewFeeRequiredMeta}
                value={content.reviewFeeRequired ?? "required"}
                onChange={(value: "required" | "not_required") =>
                  handleChange({ ...content, reviewFeeRequired: value })
                }
              />
            </FormSection>
            <FormSection title={t("lbl.審査料メモ")}>
              <CMFormInputTextArea
                noHeader={true}
                editMode={editMode}
                valueObjectMeta={freeTextMeta}
                value={content.invoiceIssuanceMemoForReviewFee}
                onChange={(value: string) =>
                  handleChange({
                    ...content,
                    invoiceIssuanceMemoForReviewFee: value,
                  })
                }
              />
            </FormSection>
            <FormSection title={t("lbl.ストック等維持管理料の説明")}>
              <CMFormInputTextArea
                noHeader={true}
                editMode={editMode}
                valueObjectMeta={freeTextMeta}
                value={content.explanationOfStockMaintenanceFee}
                onChange={(value: string) =>
                  handleChange({
                    ...content,
                    explanationOfStockMaintenanceFee: value,
                  })
                }
              />
            </FormSection>
            <FormSection title={t("lbl.状況")}>
              <CMFormInputTextArea
                noHeader={true}
                editMode={editMode}
                valueObjectMeta={freeTextMeta}
                value={content.status}
                onChange={(value: string) =>
                  handleChange({ ...content, status: value })
                }
              />
            </FormSection>
            <FormSection title={t("lbl.特許ライセンスに関する説明の実施")}>
              <CMFormInputRadio
                noHeader={true}
                editMode={editMode}
                valueObjectMeta={patentLicenseExplanationStateMeta}
                value={
                  content.patentLicenseExplanationStatus ?? "not_explained"
                }
                onChange={(value: "not_explained" | "explained") =>
                  handleChange({
                    ...content,
                    patentLicenseExplanationStatus: value,
                  })
                }
              />
            </FormSection>
            <FormSection title={t("lbl.安全輸出管理")}>
              <CMFormInputTextArea
                noHeader={true}
                editMode={editMode}
                valueObjectMeta={freeTextMeta}
                value={content.securityExportControl}
                onChange={(value: "required" | "not_required") =>
                  handleChange({ ...content, securityExportControl: value })
                }
              />
            </FormSection>
            <FormSection title={t("lbl.事務局コメント欄")}>
              <CMFormInputTextArea
                noHeader={true}
                editMode={editMode}
                valueObjectMeta={freeTextMeta}
                value={content.officeMemberNote}
                onChange={(value: "required" | "not_required") =>
                  handleChange({ ...content, officeMemberNote: value })
                }
              />
            </FormSection>
          </VStack>
        </Container>
      </Flex>
      <ConfirmationModal
        isOpen={isOpenSaveModal}
        message={t("mes.変更保存確認メッセージ")}
        onSubmit={handleSave}
        onCancel={onCloseSaveModal}
      />
      <ConfirmationModal
        isOpen={isOpenEditEndModal}
        message={t("mes.変更内容破棄メッセージ")}
        onSubmit={handleSubmitEditEnd}
        onCancel={onCloseEditEndModal}
      />
    </>
  );
};
