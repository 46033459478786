import {
  messageId,
  PropertyMeta,
  ValueObjectMetaString,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.string().max(100);
const savedMinimalSchema = z.string().max(100);
const schema = z.string().max(100);
const savedSchema = z.string().max(100);
const jsonSchema = z.string();
/**
 * 受取場所(値オブジェクト)のメタデータ。
 *
 *  */
export const pickupLocationMeta = new ValueObjectMetaString(
  "PickupLocation",
  "\u53D7\u53D6\u5834\u6240",
  messageId("lbl.\u53D7\u53D6\u5834\u6240"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
