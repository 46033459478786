import { ComponentStyleProps } from "../../../../../../../../../lib/styles/props/component-style-props";
import { HStack, VStack } from "@chakra-ui/react";
import { ApplicationUserSelect } from "../../selectButton/ApplicationUserSelect/ApplicationUserSelect";
import { memo, useCallback, useEffect, useMemo } from "react";
import { UserInformation } from "../../../../../../../../../lib/object/value/user-information";
import { useChangeBadgeProperties } from "../../../../../../../../../hooks/document/change-application/use-change-badge-properties";
import { LabelAndStringValue } from "../../../../../../../../ui/form/LabelAndStringValue/LabelAndStringValue";
import { titleAndPositionMeta } from "../../../../../../../../../lib/object/value/title-and-position";
import { phoneNumberMeta } from "../../../../../../../../../lib/object/value/phone-number";
import { mailAddressMeta } from "../../../../../../../../../lib/object/value/mail-address";
import { isNullish } from "../../../../../../../../../lib/util/common-util";
import { useAppGetAppUserQuery } from "../../../../../../../../../hooks/query/use-app-get-app-user-query";
import { useAppTranslation } from "../../../../../../../../../hooks/use-app-translation";

export type UserInfoCardBodyProps = {
  documentType:
    | "new_application"
    | "change_application"
    | "project_force_update"
    | "project_force_create"
    | "project_content";
  editMode: "editable" | "readOnly";
  institutionId?: number;

  value: Partial<UserInformation>;
  onSelect?: (value: UserInformation | null) => void;

  /**
   * 変更前の値。
   * 変更申請では、この値を与えること。
   */
  changedFrom?: UserInformation;
} & ComponentStyleProps;

/**
 * ユーザー情報カード内容。
 */
export const UserInfoCardContent = memo(function UserSelectAndInfo({
  documentType,
  editMode,
  institutionId,

  value,
  onSelect,

  changedFrom,

  sx,
  ...rest
}: UserInfoCardBodyProps) {
  const { t } = useAppTranslation();

  // 編集モードなら常にマスタの最新情報に更新する。
  // (参照モードなら素直に保存済のデータをそのまま表示する。)
  const { data: appUser } = useAppGetAppUserQuery(
    {
      appUserId: value.appUserId ?? -1,
    },
    { skip: editMode !== "editable" || isNullish(value.appUserId) },
  );
  useEffect(() => {
    if (editMode !== "editable" || isNullish(appUser)) return;
    onSelect?.({
      appUserId: appUser.id,
      displayUserId: appUser.displayUserId,
      role: appUser.role,
      fullName: appUser.fullName,
      fullNameKana: appUser.fullNameKana,
      titleAndPosition: appUser.titleAndPosition,
      phoneNumber: appUser.phoneNumber,
      mailAddress: appUser.mailAddress,
    });
  }, [editMode, appUser, onSelect]);

  const handleChange = useCallback(
    (value: UserInformation | null) => {
      if (editMode !== "editable") return;
      onSelect?.(value);
    },
    [editMode, onSelect],
  );

  const changeBadgePropertiesTitleAndPosition = useChangeBadgeProperties(
    documentType,
    changedFrom?.titleAndPosition,
  );
  const changeBadgePropertiesPhoneNumber = useChangeBadgeProperties(
    documentType,
    changedFrom?.phoneNumber,
  );
  const changeBadgePropertiesMailAddress = useChangeBadgeProperties(
    documentType,
    changedFrom?.mailAddress,
  );

  const changedFromIdAndName = useMemo(() => {
    if (isNullish(changedFrom)) return undefined;
    return {
      appUserId: changedFrom.appUserId,
      fullName: changedFrom.fullName,
    };
  }, [changedFrom]);

  return (
    <VStack spacing={"15px"} alignItems={"stretch"} sx={sx} {...rest}>
      <HStack alignItems={"flex-start"} justifyContent={"stretch"} minW={0}>
        <ApplicationUserSelect
          documentType={documentType}
          editMode={isNullish(institutionId) ? "disabled" : editMode}
          institutionId={institutionId}
          value={value?.appUserId ?? null}
          valueFullName={value?.fullName ?? null}
          changedFrom={changedFromIdAndName}
          onChange={handleChange}
          sx={{ flex: "1 1 0" }}
        />
        <LabelAndStringValue
          valueObjectMeta={titleAndPositionMeta}
          label={t("lbl.所属・職名")}
          value={value?.titleAndPosition}
          changeBadgeProperties={changeBadgePropertiesTitleAndPosition}
          sx={{ flex: "1 1 0" }}
        />
      </HStack>
      <HStack alignItems={"flex-start"} justifyContent={"stretch"}>
        <LabelAndStringValue
          valueObjectMeta={phoneNumberMeta}
          label={t("lbl.電話番号")}
          value={value?.phoneNumber}
          changeBadgeProperties={changeBadgePropertiesPhoneNumber}
          sx={{ flex: 1 }}
        />
        <LabelAndStringValue
          valueObjectMeta={mailAddressMeta}
          label={t("lbl.メールアドレス")}
          value={value?.mailAddress}
          changeBadgeProperties={changeBadgePropertiesMailAddress}
          sx={{ flex: 1 }}
        />
      </HStack>
    </VStack>
  );
});
