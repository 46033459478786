import { useSavedCurrentDocument } from "./use-saved-current-document";
import { useNewApplicationReadonlyVm } from "./new-application/use-new-application-readonly-vm";
import { hasValue, isNullish } from "../../lib/util/common-util";
import { useChangeApplicationReadonlyVm } from "./change-application/use-change-application-readonly-vm";
import { useAnnualReportReadonlyVm } from "./annual-report/use-annual-report-readonly-vm";
import { useTerminationReportReadonlyVm } from "./termination-report/use-termination-report-readonly-vm";
import { DocumentViewModel } from "../../lib/object/vm/document-view-model";
import { useMemo } from "react";

export function useSavedDocumentReadonlyVM(
  documentId?: number | null,
  skipOptionalValidations = true,
) {
  const { projectId, document } = useSavedCurrentDocument(
    documentId,
    skipOptionalValidations,
  );

  const { vm: newApplicationVM } = useNewApplicationReadonlyVm(documentId, {
    skip: isNullish(document) || document.documentType !== "new_application",
  });
  const { vm: changeApplicationVM } = useChangeApplicationReadonlyVm(
    documentId,
    {
      skip:
        isNullish(document) || document.documentType !== "change_application",
    },
  );
  const { vm: annualReportVM } = useAnnualReportReadonlyVm(documentId, {
    skip: isNullish(document) || document.documentType !== "annual_report",
  });
  const { vm: terminationReportVM } = useTerminationReportReadonlyVm(
    documentId,
    {
      skip:
        isNullish(document) || document.documentType !== "termination_report",
    },
  );

  const vm: DocumentViewModel | null = useMemo(() => {
    if (hasValue(newApplicationVM)) return newApplicationVM;
    if (hasValue(changeApplicationVM)) return changeApplicationVM;
    if (hasValue(annualReportVM)) return annualReportVM;
    if (hasValue(terminationReportVM)) return terminationReportVM;
    return null;
  }, [
    annualReportVM,
    changeApplicationVM,
    newApplicationVM,
    terminationReportVM,
  ]);

  return { projectId, document, vm };
}
