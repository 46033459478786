import {
  messageId,
  PropertyMeta,
  ValueObjectMetaString,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.string().max(10000);
const savedMinimalSchema = z.string().max(10000);
const schema = z.string().min(1).max(10000);
const savedSchema = z.string().min(1).max(10000);
const jsonSchema = z.string();
/**
 * 修正依頼本文(値オブジェクト)のメタデータ。
 *
 *  */
export const revisionRequestBodyMeta = new ValueObjectMetaString(
  "RevisionRequestBody",
  "\u4FEE\u6B63\u4F9D\u983C\u672C\u6587",
  messageId("lbl.\u4FEE\u6B63\u4F9D\u983C\u672C\u6587"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
