import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";
import { HStack, Text } from "@chakra-ui/react";
import { Icon } from "@chakra-ui/icons";
import { BiChevronRight } from "react-icons/bi";

export type GuidanceMessageAndArrowProps = {
  message: string;
} & ComponentStyleProps;

export const GuidanceMessageAndArrow = ({
  message,

  sx,
  ...rest
}: GuidanceMessageAndArrowProps) => {
  return (
    <HStack sx={sx} {...rest} spacing={"0"}>
      <Text fontSize={"14px"}>{message}</Text>
      <Icon pt={"1px"} boxSize={"22px"} as={BiChevronRight} />
    </HStack>
  );
};
