import {
  messageId,
  PropertyMeta,
  ValueObjectMetaString,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.string().max(100);
const savedMinimalSchema = z.string().max(100);
const schema = z.string().min(1).max(100);
const savedSchema = z.string().min(1).max(100);
const jsonSchema = z.string();
/**
 * メール件名(値オブジェクト)のメタデータ。
 *
 *  */
export const mailSubjectMeta = new ValueObjectMetaString(
  "MailSubject",
  "\u30E1\u30FC\u30EB\u4EF6\u540D",
  messageId("lbl.\u30E1\u30FC\u30EB\u4EF6\u540D"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
