import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * 営利非営利区分(値オブジェクト)
 *
 *
 *
 * commercial:
 * noncommercial:  */
export type CommercialType = z.infer<typeof commercialTypeMeta.schema>;
/**
 * 営利非営利区分(値オブジェクト)のメタデータ。
 *
 *  */
export const commercialTypeMeta = new ValueObjectMetaEnum<
  ["commercial", "noncommercial"]
>(
  "CommercialType",
  "\u55B6\u5229\u975E\u55B6\u5229\u533A\u5206",
  messageId("lbl.\u55B6\u5229\u975E\u55B6\u5229\u533A\u5206"),
  ["commercial", "noncommercial"],
  {
    commercial: "code.\u55B6\u5229\u975E\u55B6\u5229\u533A\u5206.\u55B6\u5229",
    noncommercial:
      "code.\u55B6\u5229\u975E\u55B6\u5229\u533A\u5206.\u975E\u55B6\u5229",
  }
);
