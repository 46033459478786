import {
  ResultNotification as ResultNotificationJson,
  useListResultNotificationQuery,
} from "../../store/api/generated/stock-request-api";
import { hasValue, isNullish } from "../../lib/util/common-util";
import { useMemo } from "react";
import {
  resultNotificationMeta,
  ResultNotificationSaved,
} from "../../lib/object/entity/result-notification";

export function useAppListResultNotificationQuery(
  arg: Parameters<typeof useListResultNotificationQuery>[0],
  options?: Parameters<typeof useListResultNotificationQuery>[1],
  skipOptionalValidations = true, // get系は、skipOptionalValidationsのデフォルトはtrueとする。
): { data: ResultNotificationSaved[] | null } {
  const { data: rawData } = useListResultNotificationQuery(arg, options);
  const data = useMemo(() => {
    if (isNullish(rawData)) return null;
    return rawData
      .map((d: ResultNotificationJson) =>
        resultNotificationMeta.toSavedDomainObjectOrNull(
          d,
          skipOptionalValidations,
        ),
      )
      .filter(hasValue);
  }, [rawData, skipOptionalValidations]);
  return { data };
}
