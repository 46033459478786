import { hasValue } from "./common-util";
import { ValidationError } from "@pscsrvlab/psc-react-components";
import { TFunction } from "i18next";

type ObjectWithMessage = {
  message: string;
};

function isObjectWithMessage(error: unknown): error is ObjectWithMessage {
  return (
    typeof error === "object" &&
    hasValue(error) &&
    "message" in error &&
    typeof error.message === "string"
  );
}

function toErrorWithMessage(maybeError: unknown): ObjectWithMessage {
  if (maybeError instanceof Error || isObjectWithMessage(maybeError))
    return maybeError;

  try {
    return new Error(JSON.stringify(maybeError));
  } catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.
    return new Error(String(maybeError));
  }
}

export function errorMessageOf(error: unknown) {
  return toErrorWithMessage(error).message;
}

export type ObjectWithNameAndMessage = {
  name: string;
  message: string;
};

function isObjectWithNameAndMessage(
  error: unknown,
): error is ObjectWithNameAndMessage {
  return (
    typeof error === "object" &&
    hasValue(error) &&
    "name" in error &&
    "message" in error &&
    typeof error.name === "string" &&
    typeof error.message === "string"
  );
}

export function toErrorWithNameAndMessage(
  maybeError: unknown,
): ObjectWithNameAndMessage {
  if (maybeError instanceof Error || isObjectWithNameAndMessage(maybeError))
    return maybeError;

  try {
    return new Error(JSON.stringify(maybeError));
  } catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.
    return new Error(String(maybeError));
  }
}
export function errorNameAndMessageOf(error: unknown) {
  return toErrorWithNameAndMessage(error);
}

/**
 * バリデーションエラーをトースト用のエラーメッセージに変換します。
 * @param error バリデーションエラー
 */
export function validationErrorToToastMessage(error?: ValidationError) {
  if (!error) {
    return "";
  }
  return `[${error.fullPropertyDisplayName}] ${error.errorMessage}`;
}

/**
 * バックエンドエラーのエラーメッセージ部分を取り出し、stringとして返す。
 * 別のエラーだった場合など、取り出せなかった場合は、汎用エラーメッセージを返す。
 */
export function backendErrorToErrorMessage(
  t: TFunction<"translation", undefined, "translation">,
  error: unknown,
): string {
  if (
    typeof error === "object" &&
    hasValue(error) &&
    "data" in error &&
    typeof error.data === "object" &&
    hasValue(error.data) &&
    "message" in error.data &&
    typeof error.data.message === "string"
  ) {
    return error.data.message;
  }
  return t("mes.汎用エラーメッセージ");
}
