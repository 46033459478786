import {
  messageId,
  PropertyMeta,
  ValueObjectMetaString,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.string().max(100);
const savedMinimalSchema = z.string().max(100);
const schema = z.string().max(100);
const savedSchema = z.string().max(100);
const jsonSchema = z.string();
/**
 * 国外輸送アカウント情報(値オブジェクト)のメタデータ。
 *
 *  */
export const foreignDelivererAccountInformationMeta = new ValueObjectMetaString(
  "ForeignDelivererAccountInformation",
  "\u56FD\u5916\u8F38\u9001\u30A2\u30AB\u30A6\u30F3\u30C8\u60C5\u5831",
  messageId(
    "lbl.\u56FD\u5916\u8F38\u9001\u30A2\u30AB\u30A6\u30F3\u30C8\u60C5\u5831"
  ),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
