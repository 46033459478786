import { SignatureValue } from "../../../../../../lib/object/value/signature-value";
import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";
import React, { memo, Ref, useCallback, useMemo } from "react";
import { Container, PositionProps, VStack } from "@chakra-ui/react";
import { ProjectNameFormSection } from "../../../../document/application/_components/ApplicationDocument/_components/section/ProjectNameFormSection/ProjectNameFormSection";
import { PrincipalInvestigatorSection } from "../../../../document/application/_components/ApplicationDocument/_components/section/PrincipalInvestigatorSection/PrincipalInvestigatorSection";
import { ContactPeopleSection } from "../../../../document/application/_components/ApplicationDocument/_components/section/ContactPeopleSection/ContactPeopleSection";
import { RequestingCellsSection } from "../../../../document/application/_components/ApplicationDocument/_components/section/RequestingCellsSection/RequestingCellsSection";
import { IpsCellStockUsePurposeSection } from "../../../../document/application/_components/ApplicationDocument/_components/section/IpsCellStockUsePurposeSection/IpsCellStockUsePurposeSection";
import { IpsCellStockRequirementReasonSection } from "../../../../document/application/_components/ApplicationDocument/_components/section/IpsCellStockRequirementReasonSection/IpsCellStockRequirementReasonSection";
import { HasCollaborativePartnerSection } from "../../../../document/application/_components/ApplicationDocument/_components/section/HasCollaborativePartnerSection/HasCollaborativePartnerSection";
import { UsageEndDateSection } from "../../../../document/application/_components/ApplicationDocument/_components/section/UsageEndDateSection/UsageEndDateSection";
import { ResearchSiteSection } from "../../../../document/application/_components/ApplicationDocument/_components/section/ResearchSiteSection/ResearchSiteSection";
import { IpsCultureExperienceSection } from "../../../../document/application/_components/ApplicationDocument/_components/section/IpsCultureExperienceSection/IpsCultureExperienceSection";
import { EthicalApplicationStatusSection } from "../../../../document/application/_components/ApplicationDocument/_components/section/EthicalApplicationStatusSection/EthicalApplicationStatusSection";
import { HandlingAfterUseSection } from "../../../../document/application/_components/ApplicationDocument/_components/section/HandlingAfterUseSection/HandlingAfterUseSection";
import { FoundationContractStateSection } from "../../../../document/application/_components/ApplicationDocument/_components/section/FoundationContractStateSection/FoundationContractStateSection";
import { AttachmentFilesSection } from "../../../../document/application/_components/ApplicationDocument/_components/section/AttachmentFilesSection/AttachmentFilesSection";
import { PartnersSection } from "../../../../document/application/_components/ApplicationDocument/_components/section/PartnersSection/PartnersSection";
import { InstitutionInformation } from "../../../../../../lib/object/value/institution-information";
import { UserInformation } from "../../../../../../lib/object/value/user-information";
import { IpsCellStockUsePurpose } from "../../../../../../lib/object/value/ips-cell-stock-use-purpose";
import { HasCollaborativePartner } from "../../../../../../lib/object/value/has-collaborative-partner";
import { YearMonthDay } from "../../../../../../lib/object/value/year-month-day";
import { IpsCultureExperience } from "../../../../../../lib/object/value/ips-culture-experience";
import { EthicalApplicationStatus } from "../../../../../../lib/object/value/ethical-application-status";
import { HandlingAfterUse } from "../../../../../../lib/object/value/handling-after-use";
import { CollaborativeResearchContractStateWithFoundation } from "../../../../../../lib/object/value/collaborative-research-contract-state-with-foundation";
import { CollaborativePartner } from "../../../../../../lib/object/value/collaborative-partner";
import { PlanSummarySection } from "../../../../document/application/_components/ApplicationDocument/_components/section/PlanSumamryFormSection/PlanSummarySection";
import { NewApplicationSubmissionDateCreateProjectSection } from "./_components/section/NewApplicationSubmissionDateCreateProjectSection";
import { ProjectControlNumberCreateProjectSection } from "./_components/section/ProjectControlNumberCreateProjectSection";
import { NewApplicationApprovalDateCreateProjectSection } from "./_components/section/NewApplicationApprovalDateCreateProjectSection";
import { NextAnnualReportDeadlineCreateProjectSection } from "./_components/section/NextAnnualReportDeadlineCreateProjectSection";
import { InstitutionNameCreateProjectSection } from "./_components/section/InstitutionNameCreateProjectSection";
import {
  ApplicationContentViewModel,
  RequestingCellViewModel,
  UserInformationKeyedViewModel,
} from "../../../../document/application/_components/ApplicationDocument/ApplicationDocument";
import { AttachmentFilesSectionViewModel } from "../../../../../../lib/object/vm/attachment-files-section-view-model";
import { hasValue } from "../../../../../../lib/util/common-util";
import { ErrorMessageArea } from "../../../../../ui/form/ErrorMessageArea/ErrorMessageArea";
import { InPageLinkApplicationDocument } from "../../../../document/application/_components/ApplicationDocument/_components/link/InPageLinkApplicationDocument/InPageLinkApplicationDocument";
import { ValidationError } from "@pscsrvlab/psc-react-components";

export type ProjectCreateApplicationDocumentProps = {
  /**
   * 値。申請内容。
   */
  value: ApplicationContentViewModel;
  onChange?: (
    change: (
      before: ApplicationContentViewModel,
    ) => ApplicationContentViewModel,
  ) => void;
  createProjectContentValue: CreateProjectContentValue;
  onChangeCreateProjectContentValue?: (
    change: (before: CreateProjectContentValue) => CreateProjectContentValue,
  ) => void;

  /**
   * バリデーションエラー。
   */
  validationErrors: ValidationError[];

  /**
   * stickyなエリアのtopの値。
   */
  stickyAreaTop?: PositionProps["top"];

  scrollableRef: Ref<any>;
  scrollOffset: number;
} & ComponentStyleProps;

/**
 * 申請書類(ドメインコンポーネント)
 */
export const ProjectCreateApplicationDocument = memo(
  function ProjectCreateApplicationDocument({
    value,
    onChange,

    createProjectContentValue,
    onChangeCreateProjectContentValue,

    validationErrors,

    stickyAreaTop = 0,

    scrollableRef,
    scrollOffset,

    sx,
    ...rest
  }: ProjectCreateApplicationDocumentProps) {
    const documentType = useMemo(() => "project_force_create" as const, []);
    const editMode = useMemo(() => "editable" as const, []);

    const institutionId: number | undefined = useMemo(
      () => value.institution.institutionId,
      [value.institution.institutionId],
    );
    const principalInvestigatorAppUserId: number | undefined = useMemo(
      () => value.principalInvestigator?.appUserId,
      [value.principalInvestigator?.appUserId],
    );

    const {
      handleChangeProjectControlNumberSection,
      handleChangeNewApplicationSubmissionDateSection,
      handleChangeNewApplicationApprovalDateSection,
      handleChangeNextAnnualReportDeadlineSection,
      handleChangeProjectNameFormSection,
      handleChangeInstitutionNameSection,
      handleChangePrincipalInvestigatorSection,
      handleChangeContactPeopleSection,
      handleChangeRequestingCellsSectionResearch,
      handleChangeRequestingCellsSectionClinical,
      handleChangeIpsCellStockUsePurposeSection,
      handleChangeIpsCellStockRequirementReasonSection,
      handleChangePlanSummaryFormSection,
      handleChangeHasCollaborativePartnerSection,
      handleChangeUsageEndDateSection,
      handleChangeResearchSiteSection,
      handleChangeIpsCultureExperienceSection,
      handleChangeEthicalApplicationStatusSection,
      handleChangeHandlingAfterUseSection,
      handleChangeFoundationContractStateSection,
      handleChangeAttachmentFilesSection,
      handleChangePartnersSection,
    } = useChangeHandlers(
      onChange,
      undefined,
      onChangeCreateProjectContentValue,
    );

    return (
      <Container maxW={"720px"} sx={sx} {...rest}>
        <VStack justifyContent={"flex-start"} alignItems={"stretch"}>
          <VStack
            alignItems={"stretch"}
            pt={"10px"}
            position={"sticky"}
            top={stickyAreaTop}
            bgColor={"white"}
            zIndex={20}
          >
            {hasValue(validationErrors) && validationErrors.length > 0 && (
              <ErrorMessageArea validationErrors={validationErrors} />
            )}
            <InPageLinkApplicationDocument
              scrollableRef={scrollableRef}
              scrollOffset={scrollOffset}
            />
          </VStack>
          <VStack
            alignItems={"stretch"}
            position={"relative"}
            overflowX={"hidden"}
            overflowY={"hidden"}
          >
            {/*書類内リンク用のタグ*/}
            <div id={"main"}></div>
            <ProjectControlNumberCreateProjectSection
              editMode={editMode}
              value={createProjectContentValue.projectControlNumber}
              onChange={handleChangeProjectControlNumberSection}
            />

            <NewApplicationSubmissionDateCreateProjectSection
              editMode={editMode}
              value={createProjectContentValue.newApplicationSubmissionDate}
              onChange={handleChangeNewApplicationSubmissionDateSection}
            />

            <NewApplicationApprovalDateCreateProjectSection
              editMode={editMode}
              value={createProjectContentValue.newApplicationApprovalDate}
              onChange={handleChangeNewApplicationApprovalDateSection}
            />

            <NextAnnualReportDeadlineCreateProjectSection
              editMode={editMode}
              value={createProjectContentValue.nextAnnualReportDeadline}
              onChange={handleChangeNextAnnualReportDeadlineSection}
            />

            <ProjectNameFormSection
              documentType={documentType}
              editMode={editMode}
              value={value.projectName}
              onChange={handleChangeProjectNameFormSection}
            />

            <InstitutionNameCreateProjectSection
              editMode={editMode}
              value={value.institution}
              onChange={handleChangeInstitutionNameSection}
            />

            <PrincipalInvestigatorSection
              documentType={documentType}
              editMode={editMode}
              institutionId={institutionId}
              value={value.principalInvestigator ?? null}
              onChange={handleChangePrincipalInvestigatorSection}
            />

            <ContactPeopleSection
              documentType={documentType}
              editMode={editMode}
              institutionId={institutionId}
              value={value.contactPeople}
              onChange={handleChangeContactPeopleSection}
              freezeFirst={false}
            />

            <RequestingCellsSection
              documentType={documentType}
              editMode={editMode}
              valueResearchCells={value.requestingResearchCells}
              valueClinicalCells={value.requestingClinicalCells}
              onChangeResearchCells={handleChangeRequestingCellsSectionResearch}
              onChangeClinicalCells={handleChangeRequestingCellsSectionClinical}
            />

            <IpsCellStockUsePurposeSection
              documentType={documentType}
              editMode={editMode}
              value={value.ipsCellStockUsePurpose}
              onChange={handleChangeIpsCellStockUsePurposeSection}
            />

            <IpsCellStockRequirementReasonSection
              documentType={documentType}
              value={value.ipsCellStockRequirementReason}
              editMode={editMode}
              onChange={handleChangeIpsCellStockRequirementReasonSection}
            />

            <PlanSummarySection
              documentType={documentType}
              editMode={editMode}
              value={value.planSummary}
              onChange={handleChangePlanSummaryFormSection}
            />

            <HasCollaborativePartnerSection
              documentType={documentType}
              editMode={editMode}
              value={value.hasCollaborativePartner}
              onChange={handleChangeHasCollaborativePartnerSection}
            />

            <UsageEndDateSection
              documentType={documentType}
              editMode={editMode}
              value={value.usageEndDate}
              onChange={handleChangeUsageEndDateSection}
            />

            <ResearchSiteSection
              documentType={documentType}
              editMode={editMode}
              value={value.researchSite}
              onChange={handleChangeResearchSiteSection}
            />

            <IpsCultureExperienceSection
              documentType={documentType}
              editMode={editMode}
              value={value.ipsCultureExperience}
              onChange={handleChangeIpsCultureExperienceSection}
            />
            <EthicalApplicationStatusSection
              documentType={documentType}
              editMode={editMode}
              value={value.ethicalApplicationStatus}
              onChange={handleChangeEthicalApplicationStatusSection}
            />

            <HandlingAfterUseSection
              documentType={documentType}
              editMode={editMode}
              value={value.handlingAfterUse}
              onChange={handleChangeHandlingAfterUseSection}
            />
            <FoundationContractStateSection
              documentType={documentType}
              editMode={editMode}
              value={value.foundationContractState}
              onChange={handleChangeFoundationContractStateSection}
            />
            {/*書類内リンク用のタグ*/}
            <div id={"attachmentFiles"}></div>
            <AttachmentFilesSection
              documentType={documentType}
              principalInvestigatorAppUserId={principalInvestigatorAppUserId}
              purposeType={value.ipsCellStockUsePurpose.purposeType}
              editMode={editMode}
              institutionId={null}
              projectId={null}
              valueResearchPlanProjectName={value.researchPlanProjectName}
              valueAttachmentFiles={value.attachmentFiles}
              onChange={handleChangeAttachmentFilesSection}
            />
            {/*書類内リンク用のタグ*/}
            <div id={"partners"}></div>
            <PartnersSection
              documentType={documentType}
              hasCollaborativePartner={
                value.hasCollaborativePartner === "has_partner"
              }
              purposeType={value.ipsCellStockUsePurpose.purposeType}
              editMode={editMode}
              institutionId={null}
              projectId={null}
              value={value.partners}
              onChange={handleChangePartnersSection}
            />
          </VStack>
        </VStack>
      </Container>
    );
  },
);

export type CreateProjectContentValue = {
  projectControlNumber: string;
  newApplicationSubmissionDate?: { year: number; month: number; day: number };
  newApplicationApprovalDate?: { year: number; month: number; day: number };
  nextAnnualReportDeadline?: { year: number; month: number; day: number };
};

function useChangeHandlers(
  onChange?: (
    change: (
      before: ApplicationContentViewModel,
    ) => ApplicationContentViewModel,
  ) => void,
  onChangeSignatureValue?: (
    change: (before: SignatureValue) => SignatureValue,
  ) => void,
  onChangeCreateProjectContentValue?: (
    change: (before: CreateProjectContentValue) => CreateProjectContentValue,
  ) => void,
) {
  const handleChange = useCallback(
    (
      change: (
        before: ApplicationContentViewModel,
      ) => ApplicationContentViewModel,
    ) => {
      onChange?.(change);
    },
    [onChange],
  );

  const handleChangeProjectControlNumberSection = useCallback(
    (v: string) =>
      onChangeCreateProjectContentValue?.((_before) => ({
        ..._before,
        projectControlNumber: v,
      })),
    [onChangeCreateProjectContentValue],
  );

  const handleChangeNewApplicationSubmissionDateSection = useCallback(
    (v: { year: number; month: number; day: number } | undefined) =>
      onChangeCreateProjectContentValue?.((_before) => ({
        ..._before,
        newApplicationSubmissionDate: v,
      })),
    [onChangeCreateProjectContentValue],
  );

  const handleChangeNewApplicationApprovalDateSection = useCallback(
    (v: { year: number; month: number; day: number } | undefined) =>
      onChangeCreateProjectContentValue?.((_before) => ({
        ..._before,
        newApplicationApprovalDate: v,
      })),
    [onChangeCreateProjectContentValue],
  );

  const handleChangeNextAnnualReportDeadlineSection = useCallback(
    (v: { year: number; month: number; day: number } | undefined) =>
      onChangeCreateProjectContentValue?.((_before) => ({
        ..._before,
        nextAnnualReportDeadline: v,
      })),
    [onChangeCreateProjectContentValue],
  );

  const handleChangeProjectNameFormSection = useCallback(
    (v: string) =>
      handleChange((before) => ({
        ...before,
        projectName: v,
      })),
    [handleChange],
  );
  const handleChangeInstitutionNameSection = useCallback(
    (v: Partial<InstitutionInformation>) =>
      handleChange((before) => ({
        ...before,
        institution: v,
      })),
    [handleChange],
  );
  const handleChangePrincipalInvestigatorSection = useCallback(
    (v: UserInformation | null) =>
      handleChange((before) => ({
        ...before,
        principalInvestigator: v ?? undefined,
      })),
    [handleChange],
  );
  const handleChangeContactPeopleSection = useCallback(
    (
      change: (
        before: UserInformationKeyedViewModel[],
      ) => UserInformationKeyedViewModel[],
    ) =>
      handleChange((before) => ({
        ...before,
        contactPeople: change(before.contactPeople),
      })),
    [handleChange],
  );
  const handleChangeRequestingCellsSectionResearch = useCallback(
    (
      change: (before: RequestingCellViewModel[]) => RequestingCellViewModel[],
    ) =>
      handleChange((before) => ({
        ...before,
        requestingResearchCells: change(before.requestingResearchCells),
      })),
    [handleChange],
  );
  const handleChangeRequestingCellsSectionClinical = useCallback(
    (
      change: (before: RequestingCellViewModel[]) => RequestingCellViewModel[],
    ) =>
      handleChange((before) => ({
        ...before,
        requestingClinicalCells: change(before.requestingClinicalCells),
      })),
    [handleChange],
  );
  const handleChangeIpsCellStockUsePurposeSection = useCallback(
    (v: IpsCellStockUsePurpose) =>
      handleChange((before) => ({
        ...before,
        ipsCellStockUsePurpose: v,
      })),
    [handleChange],
  );
  const handleChangeIpsCellStockRequirementReasonSection = useCallback(
    (v: { reasonType: "risk_reduction" | "other"; other?: string }) =>
      handleChange((before) => ({
        ...before,
        ipsCellStockRequirementReason: v,
      })),
    [handleChange],
  );
  const handleChangePlanSummaryFormSection = useCallback(
    (v: string) =>
      handleChange((before) => ({
        ...before,
        planSummary: v,
      })),
    [handleChange],
  );
  const handleChangeHasCollaborativePartnerSection = useCallback(
    (v: HasCollaborativePartner) =>
      handleChange((before) => ({
        ...before,
        hasCollaborativePartner: v,
        partners: v === "no_partner" ? [] : before.partners,
      })),
    [handleChange],
  );
  const handleChangeUsageEndDateSection = useCallback(
    (v: YearMonthDay) =>
      handleChange((before) => ({
        ...before,
        usageEndDate: v,
      })),
    [handleChange],
  );
  const handleChangeResearchSiteSection = useCallback(
    (v: string) =>
      handleChange((before) => ({
        ...before,
        researchSite: v,
      })),
    [handleChange],
  );
  const handleChangeIpsCultureExperienceSection = useCallback(
    (v: IpsCultureExperience) =>
      handleChange((before) => ({
        ...before,
        ipsCultureExperience: v,
      })),
    [handleChange],
  );
  const handleChangeEthicalApplicationStatusSection = useCallback(
    (change: (before: EthicalApplicationStatus) => EthicalApplicationStatus) =>
      handleChange((before) => ({
        ...before,
        ethicalApplicationStatus: change(before.ethicalApplicationStatus),
      })),
    [handleChange],
  );
  const handleChangeHandlingAfterUseSection = useCallback(
    (v: HandlingAfterUse) =>
      handleChange((before) => ({
        ...before,
        handlingAfterUse: v,
      })),
    [handleChange],
  );
  const handleChangeFoundationContractStateSection = useCallback(
    (v: CollaborativeResearchContractStateWithFoundation) =>
      handleChange((before) => ({
        ...before,
        foundationContractState: v,
      })),
    [handleChange],
  );
  const handleChangeAttachmentFilesSection = useCallback(
    (
      change: (
        before: AttachmentFilesSectionViewModel,
      ) => AttachmentFilesSectionViewModel,
    ) =>
      handleChange((before) => {
        const updated = change({
          researchPlanProjectName: before.researchPlanProjectName,
          attachmentFiles: before.attachmentFiles,
        });
        return {
          ...before,
          ...updated,
        };
      }),
    [handleChange],
  );
  const handleChangePartnersSection = useCallback(
    (change: (before: CollaborativePartner[]) => CollaborativePartner[]) =>
      handleChange((before) => ({
        ...before,
        partners: change(before.partners),
      })),
    [handleChange],
  );
  const handleChangeChecklistSection = useCallback(
    (v: {
      checklistItem1: "checked" | "unchecked";
      checklistItem2: "checked" | "unchecked";
      checklistItem3: "checked" | "unchecked";
      checklistItem4: "checked" | "unchecked";
      checklistItem5: "checked" | "unchecked";
      checklistItem6: "checked" | "unchecked";
    }) => {
      handleChange((before) => ({
        ...before,
        ...v,
      }));
    },
    [handleChange],
  );
  const handleChangeSignatureSection = useCallback(
    (v: SignatureValue) =>
      onChangeSignatureValue?.((_before) => ({
        correctnessCheck: v.correctnessCheck,
        checkDate: v.checkDate,
      })),
    [onChangeSignatureValue],
  );

  return {
    handleChangeProjectControlNumberSection,
    handleChangeNewApplicationSubmissionDateSection,
    handleChangeNewApplicationApprovalDateSection,
    handleChangeNextAnnualReportDeadlineSection,
    handleChangeProjectNameFormSection,
    handleChangeInstitutionNameSection,
    handleChangePrincipalInvestigatorSection,
    handleChangeContactPeopleSection,
    handleChangeRequestingCellsSectionResearch,
    handleChangeRequestingCellsSectionClinical,
    handleChangeIpsCellStockUsePurposeSection,
    handleChangeIpsCellStockRequirementReasonSection,
    handleChangePlanSummaryFormSection,
    handleChangeHasCollaborativePartnerSection,
    handleChangeUsageEndDateSection,
    handleChangeResearchSiteSection,
    handleChangeIpsCultureExperienceSection,
    handleChangeEthicalApplicationStatusSection,
    handleChangeHandlingAfterUseSection,
    handleChangeFoundationContractStateSection,
    handleChangeAttachmentFilesSection,
    handleChangePartnersSection,
    handleChangeChecklistSection,
    handleChangeSignatureSection,
  };
}
