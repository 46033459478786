import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";
import { SearchAppUserApiArg } from "../../../../../../store/api/generated/stock-request-api";
import { SearchConditionSidebar } from "../../../../../ui/search/SearchConditionSidebar/SearchConditionSidebar";
import { SearchConditionSidebarSection } from "../../../../../ui/search/SearchConditionSidebar/_components/SearchConditionSidebarSection/SearchConditionSidebarSection";
import { ButtonSearchConditionRadio } from "../../../../../model/search/button/ButtonSearchConditionRadio/ButtonSearchConditionRadio";
import {
  convertToNumberFromResultsB,
  convertToResultsBFromNumber,
} from "../../../../../../lib/util/search-util";
import { numberOfSearchResultsBMeta } from "../../../../../../lib/object/value/number-of-search-results-b";
import { ButtonSearchConditionText } from "../../../../../model/search/button/ButtonSearchConditionText/ButtonSearchConditionText";
import { ButtonSearchConditionCheckbox } from "../../../../../model/search/button/ButtonSearchConditionCheckbox/ButtonSearchConditionCheckbox";
import { userSortConditionMeta } from "../../../../../../lib/object/value/user-sort-condition";
import { roleMeta } from "../../../../../../lib/object/value/role";
import { ButtonSearchConditionInstitution } from "../../../../../model/search/button/ButtonSearchConditionInstitution/ButtonSearchConditionInstitution";
import React from "react";
import { accountStateMeta } from "../../../../../../lib/object/value/account-state";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";

export type SearchConditionSidebarUserProps = {
  searchArgs: SearchAppUserApiArg;
  isFoundationMember: boolean;

  /**
   * 検索条件が変更されたタイミングで呼び出し元に値を返却する
   */
  onChange: (searchArgs: SearchAppUserApiArg) => void;
} & ComponentStyleProps;

export const SearchConditionSidebarUser = ({
  searchArgs,
  isFoundationMember,

  onChange,

  sx,
  ...rest
}: SearchConditionSidebarUserProps) => {
  const { t } = useAppTranslation();

  return (
    <SearchConditionSidebar sx={sx} {...rest}>
      <SearchConditionSidebarSection>
        <ButtonSearchConditionRadio
          value={searchArgs.sortCondition}
          label={t("lbl.並び順")}
          valueObjectMeta={userSortConditionMeta}
          onChange={(value) => {
            onChange({
              ...searchArgs,
              sortCondition: value,
            });
          }}
        />
        <ButtonSearchConditionRadio
          label={t("lbl.表示件数")}
          value={convertToResultsBFromNumber(searchArgs.limit)}
          valueObjectMeta={numberOfSearchResultsBMeta}
          onChange={(value: string | undefined) => {
            onChange({
              ...searchArgs,
              limit: convertToNumberFromResultsB(value),
            });
          }}
        />
        {isFoundationMember && (
          <ButtonSearchConditionCheckbox
            values={searchArgs.role}
            label={t("lbl.ロール")}
            valueObjectMeta={roleMeta}
            onChange={(value) => {
              onChange({
                ...searchArgs,
                role: value,
              });
            }}
          />
        )}
        <ButtonSearchConditionText
          value={searchArgs.displayUserId}
          label={t("lbl.ユーザーID")}
          onChange={(value: string | undefined) => {
            onChange({
              ...searchArgs,
              displayUserId: value,
            });
          }}
        />
        <ButtonSearchConditionText
          value={searchArgs.fullName}
          label={t("lbl.氏名")}
          onChange={(value: string | undefined) => {
            onChange({
              ...searchArgs,
              fullName: value,
            });
          }}
        />
        {isFoundationMember && (
          <ButtonSearchConditionInstitution
            value={searchArgs.institutionId}
            label={t("lbl.機関名")}
            onChange={(value: number | null) => {
              onChange({
                ...searchArgs,
                institutionId: value ?? undefined,
              });
            }}
          />
        )}
        <ButtonSearchConditionText
          value={searchArgs.titleAndPosition}
          label={t("lbl.所属・職名")}
          onChange={(value: string | undefined) => {
            onChange({
              ...searchArgs,
              titleAndPosition: value,
            });
          }}
        />
        <ButtonSearchConditionCheckbox
          values={searchArgs.accountState}
          label={t("lbl.アカウント状態")}
          valueObjectMeta={accountStateMeta}
          onChange={(value) => {
            onChange({
              ...searchArgs,
              accountState: value,
            });
          }}
        />
      </SearchConditionSidebarSection>
    </SearchConditionSidebar>
  );
};
