import {
  messageId,
  PropertyMeta,
  ValueObjectMetaComposite,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { reviewIdMeta } from "../value/review-id";
import { documentIdMeta } from "../value/document-id";
import { reviewResultMeta } from "../value/review-result";
import { unapprovalReasonMeta } from "../value/unapproval-reason";
import { freeTextMeta } from "../value/free-text";
/**
 * 審査対象(値オブジェクト)
 *
 *  */
export type ReviewTarget = z.infer<typeof schema>;
export type ReviewTargetSaved = z.infer<typeof savedSchema>;
const minimalSchema = z.object({
  reviewId: reviewIdMeta.minimalSchema,
  documentId: documentIdMeta.minimalSchema,
  reviewResult: reviewResultMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  unapprovalReason: unapprovalReasonMeta.minimalSchema,
  officeMemberNote: freeTextMeta.minimalSchema,
});
const savedMinimalSchema = z.object({
  reviewId: reviewIdMeta.savedMinimalSchema,
  documentId: documentIdMeta.savedMinimalSchema,
  reviewResult: reviewResultMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  unapprovalReason: unapprovalReasonMeta.savedMinimalSchema,
  officeMemberNote: freeTextMeta.savedMinimalSchema,
});
const schema = z.object({
  reviewId: reviewIdMeta.schema,
  documentId: documentIdMeta.schema,
  reviewResult: reviewResultMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
  unapprovalReason: unapprovalReasonMeta.schema,
  officeMemberNote: freeTextMeta.schema,
});
const savedSchema = z.object({
  reviewId: reviewIdMeta.savedSchema,
  documentId: documentIdMeta.savedSchema,
  reviewResult: reviewResultMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  unapprovalReason: unapprovalReasonMeta.savedSchema,
  officeMemberNote: freeTextMeta.savedSchema,
});
const jsonSchema = z.object({
  reviewId: reviewIdMeta.jsonSchema.nullish().transform((x) => x ?? undefined),
  documentId: documentIdMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  reviewResult: reviewResultMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  unapprovalReason: unapprovalReasonMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  officeMemberNote: freeTextMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
/**
 * 審査対象(値オブジェクト)のメタデータ。
 *
 *  */
export const reviewTargetMeta = new ValueObjectMetaComposite(
  "ReviewTarget",
  "\u5BE9\u67FB\u5BFE\u8C61",
  messageId("lbl.\u5BE9\u67FB\u5BFE\u8C61"),
  minimalSchema,
  savedMinimalSchema,
  schema,
  savedSchema,
  jsonSchema,
  undefined,
  undefined,
  {
    reviewId: new PropertyMeta(
      "reviewId",
      "\u5BE9\u67FBID",
      messageId("lbl.\u5BE9\u67FBID"),
      "always",
      false,
      reviewIdMeta
    ),
    documentId: new PropertyMeta(
      "documentId",
      "\u66F8\u985EID",
      messageId("lbl.\u66F8\u985EID"),
      "always",
      false,
      documentIdMeta
    ),
    reviewResult: new PropertyMeta(
      "reviewResult",
      "\u5BE9\u67FB\u7D50\u679C",
      messageId("lbl.\u5BE9\u67FB\u7D50\u679C"),
      "none",
      false,
      reviewResultMeta
    ),
    unapprovalReason: new PropertyMeta(
      "unapprovalReason",
      "\u627F\u8A8D\u4EE5\u5916\u306E\u7406\u7531",
      messageId("lbl.\u627F\u8A8D\u4EE5\u5916\u306E\u7406\u7531"),
      "always",
      false,
      unapprovalReasonMeta
    ),
    officeMemberNote: new PropertyMeta(
      "officeMemberNote",
      "\u5099\u8003",
      messageId("lbl.\u5099\u8003"),
      "always",
      false,
      freeTextMeta
    ),
  }
);
