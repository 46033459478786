import { isNullish } from "../../../lib/util/common-util";
import { useAppGetChangeApplicationQuery } from "../../query/use-app-get-change-application-query";
import { useChangeApplicationVMOrEmpty } from "./use-change-application-vm";
import { merge } from "lodash";
import { useMemo } from "react";

/**
 * 変更申請の表示内容を取得する。
 *
 * 書類IDを指定しなかった場合、
 * - その案件から変更申請を新規作成する場合の内容を取得する。
 * - 保存時は、新規作成オペレーションを呼び出す。
 *
 * 書類IDを指定した場合、
 * - その書類IDの変更申請の内容を取得する。
 * - 保存時は、更新オペレーションを呼び出す。
 */
export function useChangeApplication(
  /**
   * 案件ID。
   */
  projectId: number,

  /**
   * 書類ID。
   * この値がない場合、
   * 案件IDから新規の（空の）変更申請ビューモデルを作成する。
   */
  documentId: number | undefined,

  options?: {
    /**
     * skipがtrueの場合、何も行わない。
     */
    skip: boolean;
  },
) {
  const _options = useMemo(() => merge({ skip: false }, options), [options]);

  // 書類IDが指定されている場合、変更申請を取得する。
  const { data: changeApplication } = useAppGetChangeApplicationQuery(
    { documentId: documentId ?? -1 },
    { skip: isNullish(documentId) },
  );

  return useChangeApplicationVMOrEmpty(
    projectId,
    documentId,
    changeApplication,
    _options,
  );
}
