import {
  messageId,
  PropertyMeta,
  EntityMeta,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { documentSnapshotIdMeta } from "../value/document-snapshot-id";
import { documentIdMeta } from "../value/document-id";
import { documentTypeMeta } from "../value/document-type";
import { jsonMeta } from "../value/json";
import { creationMetadataMeta } from "../value/creation-metadata";
import { updateMetadataMeta } from "../value/update-metadata";
/**
 * 書類スナップショット(エンティティ)
 *
 *  */
export type DocumentSnapshot = z.infer<typeof schema>;
export type DocumentSnapshotSaved = z.infer<typeof savedSchema>;
const minimalSchema = z.object({
  id: documentSnapshotIdMeta.minimalSchema.optional(),
  documentId: documentIdMeta.minimalSchema,
  documentType: documentTypeMeta.minimalSchema,
  documentInformationJson: jsonMeta.minimalSchema,
  created: creationMetadataMeta.minimalSchema,
  updated: updateMetadataMeta.minimalSchema,
});
const savedMinimalSchema = z.object({
  id: documentSnapshotIdMeta.minimalSchema,
  documentId: documentIdMeta.savedMinimalSchema,
  documentType: documentTypeMeta.savedMinimalSchema,
  documentInformationJson: jsonMeta.savedMinimalSchema,
  created: creationMetadataMeta.savedMinimalSchema,
  updated: updateMetadataMeta.savedMinimalSchema,
});
const schema = z.object({
  id: documentSnapshotIdMeta.minimalSchema.optional(),
  documentId: documentIdMeta.schema,
  documentType: documentTypeMeta.schema,
  documentInformationJson: jsonMeta.schema,
  created: creationMetadataMeta.schema,
  updated: updateMetadataMeta.schema,
});
const savedSchema = z.object({
  id: documentSnapshotIdMeta.minimalSchema,
  documentId: documentIdMeta.savedSchema,
  documentType: documentTypeMeta.savedSchema,
  documentInformationJson: jsonMeta.savedSchema,
  created: creationMetadataMeta.savedSchema,
  updated: updateMetadataMeta.savedSchema,
});
const jsonSchema = z.object({
  id: documentSnapshotIdMeta.jsonSchema.optional(),
  documentId: documentIdMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  documentType: documentTypeMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  documentInformationJson: jsonMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  created: creationMetadataMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  updated: updateMetadataMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
/**
 * 書類スナップショット(エンティティ)のメタデータ。
 *
 *  */
export const documentSnapshotMeta = new EntityMeta<
  typeof schema,
  typeof savedSchema,
  typeof jsonSchema
>(
  "DocumentSnapshot",
  "\u66F8\u985E\u30B9\u30CA\u30C3\u30D7\u30B7\u30E7\u30C3\u30C8",
  messageId("lbl.\u66F8\u985E\u30B9\u30CA\u30C3\u30D7\u30B7\u30E7\u30C3\u30C8"),
  minimalSchema,
  savedMinimalSchema,
  schema,
  savedSchema,
  jsonSchema,
  undefined,
  undefined,
  {
    id: new PropertyMeta(
      "id",
      "ID",
      messageId("lbl.ID"),
      "afterSave",
      false,
      documentSnapshotIdMeta
    ),
    documentId: new PropertyMeta(
      "documentId",
      "\u66F8\u985EID",
      messageId("lbl.\u66F8\u985EID"),
      "always",
      false,
      documentIdMeta
    ),
    documentType: new PropertyMeta(
      "documentType",
      "\u66F8\u985E\u7A2E\u5225",
      messageId("lbl.\u66F8\u985E\u7A2E\u5225"),
      "always",
      false,
      documentTypeMeta
    ),
    documentInformationJson: new PropertyMeta(
      "documentInformationJson",
      "\u66F8\u985EJSON",
      messageId("lbl.\u66F8\u985EJSON"),
      "always",
      false,
      jsonMeta
    ),
    created: new PropertyMeta(
      "created",
      "\u4F5C\u6210",
      messageId("lbl.\u4F5C\u6210"),
      "always",
      false,
      creationMetadataMeta
    ),
    updated: new PropertyMeta(
      "updated",
      "\u66F4\u65B0",
      messageId("lbl.\u66F4\u65B0"),
      "always",
      false,
      updateMetadataMeta
    ),
  }
);
