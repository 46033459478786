import { useGetProjectQuery } from "../../store/api/generated/stock-request-api";
import { isNullish } from "../../lib/util/common-util";
import { useMemo } from "react";
import { projectMeta, ProjectSaved } from "../../lib/object/entity/project";

export function useAppGetProjectQuery(
  arg: Parameters<typeof useGetProjectQuery>[0],
  options?: Parameters<typeof useGetProjectQuery>[1],
  skipOptionalValidations = true, // get系は、skipOptionalValidationsのデフォルトはtrueとする。
): { data: ProjectSaved | null } {
  const { data: rawData } = useGetProjectQuery(arg, options);
  const data = useMemo(() => {
    if (isNullish(rawData)) return null;
    return projectMeta.toSavedDomainObjectOrNull(
      rawData,
      skipOptionalValidations,
    );
  }, [rawData, skipOptionalValidations]);
  return { data };
}
