import React, { memo, useMemo } from "react";
import { HStack, Stack, Text } from "@chakra-ui/react";
import { jsDateToDatetimeText } from "../../../../../../lib/util/common-date-util";
import { isNullish } from "../../../../../../lib/util/common-util";
import { ReviewConferenceViewModel } from "../../ReviewConferenceDetailsPage";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";

export type SectionConferenceTitleProps = {
  /**
   * 審査会情報
   */
  value: ReviewConferenceViewModel;
};

/**
 * FN50S02 審査会詳細
 * タイトルセクション
 */
export const SectionConferenceTitle = memo(function SectionConferenceTitle({
  value,
}: SectionConferenceTitleProps) {
  const { t } = useAppTranslation();

  /**
   * 通知日時
   */
  const notifyTime = useMemo(() => {
    if (isNullish(value?.reviewConferenceNotificationDatetime)) {
      return "";
    }
    const date = jsDateToDatetimeText(
      value?.reviewConferenceNotificationDatetime,
    );
    return `${t("lbl.通知日時")}: ${date}`;
  }, [t, value]);

  /**
   * 作成日時
   */
  const createTime = useMemo(() => {
    if (isNullish(value.created) || isNullish(value.created.datetime)) {
      return "";
    }

    const date = jsDateToDatetimeText(value.created.datetime);
    return `${t("lbl.作成日時")}: ${date}`;
  }, [t, value]);

  /**
   * 更新日時
   */
  const updateTime = useMemo(() => {
    if (isNullish(value.updated) || isNullish(value.updated.datetime)) {
      return "";
    }
    const date = jsDateToDatetimeText(value.updated.datetime);
    return `${t("lbl.更新日時")}: ${date}`;
  }, [t, value]);

  return (
    <HStack alignItems={"flex-end"}>
      <Stack flex={"1 1 auto"} minW={0}>
        {/*審査会名*/}
        <Text fontSize={"24px"} fontWeight={"bold"} whiteSpace={"normal"}>
          {value.reviewConferenceName}
        </Text>
      </Stack>
      <Stack flex={"0 0 auto"} alignItems={"flex-end"}>
        {/*通知日時*/}
        <Text fontSize={"14px"} color={"gray"}>
          {notifyTime}
        </Text>
        {/*作成日時*/}
        <Text fontSize={"14px"} color={"gray"}>
          {createTime}
        </Text>
        {/*更新日時*/}
        <Text fontSize={"14px"} color={"gray"}>
          {updateTime}
        </Text>
      </Stack>
    </HStack>
  );
});
