import { ComponentStyleProps } from "../../../../../../../../lib/styles/props/component-style-props";
import { MailLogSaved } from "../../../../../../../../lib/object/entity/mail-log";
import React, { useMemo } from "react";
import { useAppTranslation } from "../../../../../../../../hooks/use-app-translation";
import { Center, Flex, HStack, Spacer, Text, VStack } from "@chakra-ui/react";
import { CMDatetimeText } from "@pscsrvlab/psc-react-components";
import { AppLink } from "../../../../../../../ui/link/AppLink/AppLink";
import { useAppGetDocumentQuery } from "../../../../../../../../hooks/query/use-app-get-document-query";
import {
  hasValue,
  isNullish,
} from "../../../../../../../../lib/util/common-util";
import { useAppGetProjectQuery } from "../../../../../../../../hooks/query/use-app-get-project-query";

export type LatestMailRowProps = {
  mailLog: MailLogSaved;
  styleType: 0 | 1;
} & ComponentStyleProps;

export const LatestMailRow = ({
  mailLog,
  styleType,

  sx,
  ...rest
}: LatestMailRowProps) => {
  const { t } = useAppTranslation();

  const bgColor = useMemo(
    () => (styleType === 0 ? "white" : "blue.50"),
    [styleType],
  );

  const { data: project } = useAppGetProjectQuery(
    {
      projectId: mailLog.projectId ?? -1,
    },
    { skip: isNullish(mailLog.projectId) },
  );
  const { data: document } = useAppGetDocumentQuery(
    {
      documentId: mailLog.documentId ?? -1,
    },
    { skip: isNullish(mailLog.documentId) },
  );

  return (
    <Flex
      direction={"row"}
      justifyContent={"flex-start"}
      alignItems={"center"}
      h={"54px"}
      bgColor={bgColor}
      sx={sx}
      {...rest}
    >
      <Center flex={"0 0 150px"}>
        <CMDatetimeText
          sx={{
            color: "gray.500",
            fontSize: "sm",
          }}
          value={mailLog.sentAt}
        />
      </Center>
      <VStack flex={"1 1 auto"} minW={0} alignItems={"stretch"} spacing={"2px"}>
        <HStack pr={"12px"} minW={0}>
          <AppLink
            to={"/mail-log"}
            sx={{ color: "gray.900", flex: "1 1 auto" }}
            minW={0}
          >
            <Text
              fontSize={"md"}
              whiteSpace={"nowrap"}
              overflow={"hidden"}
              textOverflow={"ellipsis"}
            >
              {mailLog.subject}
            </Text>
          </AppLink>
          <Spacer />
          <HStack>
            <Text color={"gray.500"} fontSize={"xs"} whiteSpace={"nowrap"}>
              {t("lbl.案件")}:
            </Text>
            {hasValue(project) ? (
              <AppLink to={`/project/${project.id}`}>
                <Text whiteSpace={"nowrap"}>
                  {project.projectControlNumber}
                </Text>
              </AppLink>
            ) : (
              <Text color={"gray.500"} fontSize={"xs"}>
                -
              </Text>
            )}
          </HStack>
          <HStack>
            <Text color={"gray.500"} fontSize={"xs"} whiteSpace={"nowrap"}>
              {t("lbl.書類")}:
            </Text>
            {hasValue(document) ? (
              <AppLink to={`/document/${document.id}`}>
                <Text whiteSpace={"nowrap"}>
                  {document.documentControlNumber}
                </Text>
              </AppLink>
            ) : (
              <Text color={"gray.500"} fontSize={"xs"}>
                -
              </Text>
            )}
          </HStack>
        </HStack>
        <Text
          color={"gray.500"}
          fontSize={"sm"}
          whiteSpace={"nowrap"}
          overflow={"hidden"}
          textOverflow={"ellipsis"}
        >
          {mailLog.content}
        </Text>
      </VStack>
    </Flex>
  );
};
