import { Box, Flex, HStack, Stack, VStack } from "@chakra-ui/react";
import { useAppParams } from "../../../../hooks/use-app-params";
import React, { useCallback, useMemo } from "react";
import { hasValue, isNullish } from "../../../../lib/util/common-util";
import { useSearchPage } from "../../../../hooks/use-search-page";
import {
  SearchMailLogApiArg,
  useSearchMailLogQuery,
} from "../../../../store/api/generated/stock-request-api";
import { useSearchResultPaginationNumbers } from "../../../../hooks/use-search-result-pagination-numbers";
import { PageNumberAndSearchResult } from "../../../ui/search/PageNumberAndSearchResult/PageNumberAndSearchResult";
import { Pagination } from "../../../ui/search/Pagination/Pagination";
import {
  getAllEnumFromSearchParams,
  getEnumFromSearchParams,
  getISO8601StringFromSearchParams,
  getNumberFromSearchParams,
  getStringFromSearchParams,
} from "../../../../lib/util/search-params-util";
import { searchConditionLimitMeta } from "../../../../lib/object/value/search-condition-limit";
import { institutionIdMeta } from "../../../../lib/object/value/institution-id";
import { projectIdMeta } from "../../../../lib/object/value/project-id";
import { documentIdMeta } from "../../../../lib/object/value/document-id";
import { searchConditionPageNumberMeta } from "../../../../lib/object/value/search-condition-page-number";
import {
  mailLogMeta,
  MailLogSaved,
} from "../../../../lib/object/entity/mail-log";
import { mailLogSortConditionMeta } from "../../../../lib/object/value/mail-log-sort-condition";
import { roleMeta } from "../../../../lib/object/value/role";
import { operationTypeMeta } from "../../../../lib/object/value/operation-type";
import { mailSubjectMeta } from "../../../../lib/object/value/mail-subject";
import { mailAddressMeta } from "../../../../lib/object/value/mail-address";
import { appUserIdMeta } from "../../../../lib/object/value/app-user-id";
import { SearchConditionSidebarMailLog } from "./_components/SearchConditionSidebarMailLog/SearchConditionSidebarMailLog";
import { MailLogCard } from "./_components/MailLogCard/MailLogCard";
import { getDefaultPeriod } from "../../../../lib/util/search-util";

export type MailLogPageProps = {
  type: "root" | "project" | "document";
};
export const MailLogPage = ({ type }: MailLogPageProps) => {
  const { projectId, documentId } = useAppParams();

  // 案件画面内なら案件ID、書類画面内なら書類IDをデフォルト検索条件とする。
  const urlSearchParamsToSearchArgsWithDefault = useCallback(
    (searchParams: URLSearchParams) => {
      return urlSearchParamsToSearchArgs(
        searchParams,
        type === "project" && hasValue(projectId) ? projectId : null,
        type === "document" && hasValue(documentId) ? documentId : null,
      );
    },
    [type, projectId, documentId],
  );

  const { searchArgs, handleChangeSearchCondition, handleChangePage } =
    useSearchPage(urlSearchParamsToSearchArgsWithDefault);

  /**
   * メールログ一覧の取得
   */
  const { data, isLoading } = useSearchMailLogQuery(searchArgs);

  // 取得したデータをオブジェクトに変換する
  const mailLogs: MailLogSaved[] = useMemo(() => {
    if (isNullish(data)) return [];
    return data.items
      .map((v) => {
        // 検索時は、原則skipOptionalをtrueとする。
        return mailLogMeta.toSavedDomainObjectOrNull(v, true);
      })
      .filter(hasValue);
  }, [data]);

  const { totalElements, currentPageNumber, totalPageCount } =
    useSearchResultPaginationNumbers(searchArgs, data);

  return (
    <HStack flex={1} spacing={0} overflow={"hidden"} alignItems={"stretch"}>
      <SearchConditionSidebarMailLog
        type={type}
        searchArgs={searchArgs}
        onChange={handleChangeSearchCondition}
      />

      {/* 検索結果一覧エリア */}
      <Flex
        direction={"column"}
        flexGrow={1}
        h={"100%"}
        pb={"50px"}
        overflow={"auto"}
        position={"relative"}
      >
        <VStack alignSelf={"flex-start"}>
          {!isLoading && (
            <PageNumberAndSearchResult
              currentPageNumber={currentPageNumber}
              totalElements={totalElements}
              sx={{ mt: "10px", pl: "50px" }}
            />
          )}
        </VStack>

        {/*質疑応答*/}
        <Box mt={"10px"} paddingX={"50px"}>
          {!isLoading && (
            <Stack spacing="32px">
              {mailLogs.map((mailLog, _index) => (
                <MailLogCard key={mailLog.id} mailLog={mailLog} />
              ))}
            </Stack>
          )}
        </Box>
        {/*フッタ*/}
        <VStack
          alignSelf={"stretch"}
          left={"0px"}
          position={"sticky"}
          pb={"80px"}
        >
          {/*ページネーション*/}
          {hasValue(totalPageCount) && totalPageCount > 0 && (
            <Pagination
              totalPageCount={totalPageCount}
              currentPageNumber={currentPageNumber}
              onPageChange={handleChangePage}
              sx={{
                display: "grid",
                justifyItems: "center",
                mt: "30px",
              }}
            />
          )}
        </VStack>
      </Flex>
    </HStack>
  );
};

/**
 * クエリパラメータから検索条件を作成する.
 */
function urlSearchParamsToSearchArgs(
  searchParams: URLSearchParams,
  defaultProjectId: number | null,
  defaultDocumentId: number | null,
): SearchMailLogApiArg {
  const { defaultFromDateText, defaultToDateText } = getDefaultPeriod(30);

  const sortCondition =
    getEnumFromSearchParams(
      searchParams,
      "sortCondition",
      mailLogSortConditionMeta,
    ) ?? "sent_at_desc";
  const limit =
    getNumberFromSearchParams(
      searchParams,
      "limit",
      searchConditionLimitMeta,
    ) ?? 10;

  const sendDateTimeFrom =
    getISO8601StringFromSearchParams(searchParams, "sendDateTimeFrom") ??
    defaultFromDateText;
  const sendDateTimeTo =
    getISO8601StringFromSearchParams(searchParams, "sendDateTimeTo") ??
    defaultToDateText;
  const recipientRole = getAllEnumFromSearchParams(
    searchParams,
    "recipientRole",
    roleMeta,
  );
  const institutionId = getNumberFromSearchParams(
    searchParams,
    "institutionId",
    institutionIdMeta,
  );
  const projectId = hasValue(defaultProjectId)
    ? defaultProjectId
    : getNumberFromSearchParams(searchParams, "projectId", projectIdMeta);
  const documentId = hasValue(defaultDocumentId)
    ? defaultDocumentId
    : getNumberFromSearchParams(searchParams, "documentId", documentIdMeta);
  const operationType = getEnumFromSearchParams(
    searchParams,
    "operationType",
    operationTypeMeta,
  );
  const subject = getStringFromSearchParams(
    searchParams,
    "subject",
    mailSubjectMeta.partial,
  );
  const appUserId = getNumberFromSearchParams(
    searchParams,
    "appUserId",
    appUserIdMeta,
  );
  const mailAddress = getStringFromSearchParams(
    searchParams,
    "mailAddress",
    mailAddressMeta.partial,
  );

  const pageNumber = getNumberFromSearchParams(
    searchParams,
    "pageNumber",
    searchConditionPageNumberMeta,
  );

  return {
    sortCondition,
    limit,

    sendDateTimeFrom,
    sendDateTimeTo,
    recipientRole,
    institutionId,
    projectId,
    documentId,
    operationType,
    subject,
    appUserId,
    mailAddress,

    pageNumber,
  };
}
