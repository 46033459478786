import { Text } from "@chakra-ui/react";
import { CMFormInputDate } from "@pscsrvlab/psc-react-components";
import React, { memo, useCallback } from "react";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { YearMonthDay } from "../../../../../../lib/object/value/year-month-day";
import { FormSection } from "../../../../../ui/form/FormSection/FormSection";

export type SectionDeliveryDateProps = {
  editMode?: "editable" | "readOnly";

  value?: YearMonthDay;

  onChange?: (changedValue: YearMonthDay) => void;
};
/**
 * FN30-S09細胞発送管理
 * 到着希望日セクション
 */
export const SectionDeliveryDate = memo(function SectionDeliveryDate({
  editMode,
  value,
  onChange,
}: SectionDeliveryDateProps) {
  const { t } = useAppTranslation();

  const handleChange = useCallback(
    (selectedValue: YearMonthDay) => {
      onChange?.(selectedValue);
    },
    [onChange],
  );

  return (
    <FormSection title={t("lbl.到着希望日")}>
      {editMode === "editable" && (
        <Text fontSize={"12px"} color={"red"}>
          {t("gdc.到着希望日補足")}
        </Text>
      )}
      <CMFormInputDate
        editMode={editMode}
        noHeader={true}
        value={value}
        onChange={handleChange}
      />
    </FormSection>
  );
});
