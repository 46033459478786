import { useEffect, useMemo, useState } from "react";
import { isEqualWith } from "lodash";
import { hasValue, isNullish } from "../../../lib/util/common-util";
import { dropAllUndefinedFieldsRecursively } from "../../../lib/util/common-object-util";
import { useDebounce } from "react-use";
import { ChangeReasonItemViewModel } from "../../../lib/object/vm/change-application-view-model";

export function useSectionDiffMode(
  documentType:
    | "new_application"
    | "change_application"
    | "project_force_update"
    | "project_force_create"
    | "project_content",
  changedFrom: any,
  value: any,

  /**
   * これを渡した場合、
   * diffモードが"normal"になったときにchanged=false,
   * diffモードが"normal"以外になったときにchanged=true
   * を送出する。
   */
  onChangeReason:
    | ((
        change: (
          before: ChangeReasonItemViewModel,
        ) => ChangeReasonItemViewModel,
      ) => void)
    | undefined,

  /**
   * overwriteの値がある場合は、計算結果に関わらず常にこの値とする。
   * overwriteの値がない場合は、計算結果により、"none"または"updated"となる。
   */
  overwrite?: "normal" | "added" | "updated" | "deleted",
  debounceMs = 500,
): "normal" | "added" | "updated" | "deleted" {
  const [sectionDiffModeInternal, setSectionDiffModeInternal] = useState<
    "updated" | "normal"
  >("normal");
  useDebounce(
    () => {
      if (
        documentType === "change_application" &&
        !_isEqual(value, changedFrom)
      ) {
        setSectionDiffModeInternal("updated");
      } else {
        setSectionDiffModeInternal("normal");
      }
    },
    debounceMs,
    [documentType, changedFrom, value],
  );
  const sectionDiffMode = useMemo(() => {
    if (hasValue(overwrite)) return overwrite;
    return sectionDiffModeInternal;
  }, [sectionDiffModeInternal, overwrite]);
  useEffect(() => {
    onChangeReason?.((before) => ({
      ...before,
      changed: sectionDiffMode !== "normal",
    }));
  }, [onChangeReason, sectionDiffMode]);

  return sectionDiffMode;
}

function _isEqual(v0: any, v1: any): boolean {
  const _v0 = dropAllUndefinedFieldsRecursively(v0);
  const _v1 = dropAllUndefinedFieldsRecursively(v1);
  return isEqualWith(_v0, _v1, isEqualWithCustomizer);
}

function isEqualWithCustomizer(v0: any, v1: any) {
  if (isNullish(v0) && isNullish(v1)) return true;
  return undefined;
}
