import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";
import React, { memo, useEffect, useMemo, useState } from "react";
import { WorkLogSaved } from "../../../../../../lib/object/entity/work-log";
import { TFunction } from "i18next";
import { SearchResultRowContent } from "@pscsrvlab/psc-react-components/src/components/search/row/types";
import { stockRequestApi } from "../../../../../../store/api/enhanced-api";
import { LazyQueryTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { QueryDefinition } from "@reduxjs/toolkit/query";
import {
  AppUser as AppUserJson,
  Document as DocumentJson,
  GetAppUserApiArg,
  GetDocumentApiArg,
  GetInstitutionApiArg,
  GetProjectApiArg,
  Institution as InstitutionJson,
  Project as ProjectJson,
} from "../../../../../../store/api/generated/stock-request-api";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/dist/query/react";
import {
  institutionMeta,
  InstitutionSaved,
} from "../../../../../../lib/object/entity/institution";
import {
  appUserMeta,
  AppUserSaved,
} from "../../../../../../lib/object/entity/app-user";
import {
  getMessageFromEnumValue,
  isNullish,
} from "../../../../../../lib/util/common-util";
import { workTypeMeta } from "../../../../../../lib/object/value/work-type";
import log from "loglevel";
import { errorMessageOf } from "../../../../../../lib/util/error-util";
import {
  projectMeta,
  ProjectSaved,
} from "../../../../../../lib/object/entity/project";
import {
  documentMeta,
  DocumentSaved,
} from "../../../../../../lib/object/entity/document";
import { SearchResultTableContent } from "@pscsrvlab/psc-react-components/src/components/search/table/types";
import {
  CMSearchResultTable,
  SearchResultTableOptions,
} from "@pscsrvlab/psc-react-components";
import { operationTypeMeta } from "../../../../../../lib/object/value/operation-type";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";

export type SearchResultTableWorkLogProps = {
  entities: WorkLogSaved[];
} & ComponentStyleProps;

export const SearchResultTableWorkLog = memo(function SearchResultTableWorkLog({
  entities,

  sx,
  ...rest
}: SearchResultTableWorkLogProps) {
  const { t } = useAppTranslation();
  const [triggerGetInstitutionQuery] =
    stockRequestApi.useLazyGetInstitutionQuery();
  const [triggerGetAppUserQuery] = stockRequestApi.useLazyGetAppUserQuery();
  const [triggerGetProjectQuery] = stockRequestApi.useLazyGetProjectQuery();
  const [triggerGetDocumentQuery] = stockRequestApi.useLazyGetDocumentQuery();

  const header = useMemo(() => {
    return {
      cells: [
        { value: t("lbl.作業日時"), width: "140px" },
        { value: t("lbl.作業区分"), width: "60px" },
        { value: t("lbl.機関名"), width: "130px" },
        { value: t("lbl.ユーザー名"), width: "130px" },
        { value: t("lbl.案件管理番号"), width: "130px" },
        { value: t("lbl.書類管理番号"), width: "130px" },
        { value: t("lbl.オペレーション名"), width: "130px" },
        { value: t("lbl.変更前後データ"), width: "130px" },
      ],
    };
  }, [t]);

  const [tableContent, setTableContent] = useState<SearchResultTableContent>({
    header: { cells: [] },
    rows: [],
  });
  // テーブル表示用にデータを整形する
  useEffect(() => {
    (async () => {
      try {
        // 各項目ごとにデータを整形
        const rows: SearchResultRowContent[] = await createRows(
          entities,
          t,
          triggerGetInstitutionQuery,
          triggerGetAppUserQuery,
          triggerGetProjectQuery,
          triggerGetDocumentQuery,
        );

        // テーブル表示に必要なデータを設定
        setTableContent({
          header,
          rows,
        });
      } catch (e) {
        log.error(errorMessageOf(e));
      }
    })();
  }, [entities, t, triggerGetInstitutionQuery, header, triggerGetAppUserQuery, triggerGetProjectQuery, triggerGetDocumentQuery]);

  const tableOptions: SearchResultTableOptions = useMemo(() => {
    return {
      jsonDiff: {
        contentText: t("lbl.参照"),
        beforeLabel: t("lbl.変更前"),
        afterLabel: t("lbl.変更後"),
        closeButtonLabel: t("btn.閉じるボタン"),
      },
    };
  }, [t]);

  return (
    <CMSearchResultTable
      content={tableContent}
      options={tableOptions}
      stickyHeaderTop={"29px"}
      stickyFirstColLeft={"30px"}
      sx={sx}
      {...rest}
    />
  );
});

function createRows(
  entities: WorkLogSaved[],
  t: TFunction<"translation", undefined, "translation">,
  triggerGetInstitutionQuery: LazyQueryTrigger<
    QueryDefinition<
      GetInstitutionApiArg,
      BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, {}>,
      string,
      InstitutionJson,
      "api"
    >
  >,
  triggerGetAppUserQuery: LazyQueryTrigger<
    QueryDefinition<
      GetAppUserApiArg,
      BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, {}>,
      string,
      AppUserJson,
      "api"
    >
  >,
  triggerGetProjectQuery: LazyQueryTrigger<
    QueryDefinition<
      GetProjectApiArg,
      BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, {}>,
      string,
      ProjectJson,
      "api"
    >
  >,
  triggerGetDocumentQuery: LazyQueryTrigger<
    QueryDefinition<
      GetDocumentApiArg,
      BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, {}>,
      string,
      DocumentJson,
      "api"
    >
  >,
): Promise<SearchResultRowContent[]> {
  return Promise.all(
    entities.map(async (entity) => {
      const [institution, appUser, project, document] = await Promise.all([
        getInstitutionOrNull(entity.institutionId, triggerGetInstitutionQuery),
        getAppUserOrNull(entity.created?.appUserId, triggerGetAppUserQuery),
        getProjectOrNull(entity.projectId, triggerGetProjectQuery),
        getDocumentOrNull(entity.documentId, triggerGetDocumentQuery),
      ]);

      return {
        cells: [
          {
            // 作業日時
            type: "datetime",
            value: entity.created?.datetime,
          },
          {
            // 作業区分
            type: "text",
            value: getMessageFromEnumValue(t, workTypeMeta, entity.workType),
          },
          {
            // 機関名
            type: "text",
            value: institution?.name,
          },
          {
            // ユーザー名
            type: "text",
            value: appUser?.fullName,
          },
          {
            // 案件管理番号
            type: "text",
            value: project?.projectControlNumber,
          },
          {
            // 書類管理番号
            type: "text",
            value: document?.documentControlNumber,
          },
          {
            // オペレーション名
            type: "text",
            value: getMessageFromEnumValue(
              t,
              operationTypeMeta,
              entity.operation,
            ),
          },
          {
            // 変更前後データ
            type: "json_diff",
            value: {
              before: entity.beforeJson,
              after: entity.afterJson,
            },
          },
        ],
      };
    }),
  );
}

async function getInstitutionOrNull(
  institutionId: number | undefined,
  triggerGetInstitutionQuery: LazyQueryTrigger<
    QueryDefinition<
      GetInstitutionApiArg,
      BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, {}>,
      string,
      InstitutionJson,
      "api"
    >
  >,
): Promise<InstitutionSaved | null> {
  if (isNullish(institutionId)) return null;
  try {
    const institutionJson = await triggerGetInstitutionQuery({
      institutionId,
    }).unwrap();
    return institutionMeta.toSavedDomainObjectOrNull(institutionJson, true);
  } catch (e) {
    log.error(errorMessageOf(e));
    return null;
  }
}

async function getAppUserOrNull(
  appUserId: number | undefined,
  triggerGetAppUserQuery: LazyQueryTrigger<
    QueryDefinition<
      GetAppUserApiArg,
      BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, {}>,
      string,
      AppUserJson,
      "api"
    >
  >,
): Promise<AppUserSaved | null> {
  if (isNullish(appUserId)) return null;
  try {
    const appUserJson = await triggerGetAppUserQuery({
      appUserId,
    }).unwrap();
    return appUserMeta.toSavedDomainObjectOrNull(appUserJson, true);
  } catch (e) {
    log.error(errorMessageOf(e));
    return null;
  }
}

async function getProjectOrNull(
  projectId: number | undefined,
  triggerGetProjectQuery: LazyQueryTrigger<
    QueryDefinition<
      GetProjectApiArg,
      BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, {}>,
      string,
      ProjectJson,
      "api"
    >
  >,
): Promise<ProjectSaved | null> {
  if (isNullish(projectId)) return null;
  try {
    const projectJson = await triggerGetProjectQuery({
      projectId,
    }).unwrap();
    return projectMeta.toSavedDomainObjectOrNull(projectJson, true);
  } catch (e) {
    log.error(errorMessageOf(e));
    return null;
  }
}

async function getDocumentOrNull(
  documentId: number | undefined,
  triggerGetDocumentQuery: LazyQueryTrigger<
    QueryDefinition<
      GetDocumentApiArg,
      BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, {}>,
      string,
      DocumentJson,
      "api"
    >
  >,
): Promise<DocumentSaved | null> {
  if (isNullish(documentId)) return null;
  try {
    const documentJson = await triggerGetDocumentQuery({
      documentId,
    }).unwrap();
    return documentMeta.toSavedDomainObjectOrNull(documentJson, true);
  } catch (e) {
    log.error(errorMessageOf(e));
    return null;
  }
}
