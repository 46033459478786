import { ComponentStyleProps } from "../../../../../../../../lib/styles/props/component-style-props";
import React, { memo, useCallback, useEffect } from "react";

import { isNullish } from "../../../../../../../../lib/util/common-util";
import { HStack, VStack } from "@chakra-ui/react";
import { ApplicationInstitutionSelect } from "../selectButton/ApplicationInstitutionSelect";
import { LabelAndEnumValue } from "../../../../../../../ui/form/LabelAndEnumValue/LabelAndEnumValue";
import { foreignTypeMeta } from "../../../../../../../../lib/object/value/foreign-type";
import { commercialTypeMeta } from "../../../../../../../../lib/object/value/commercial-type";
import { InstitutionInformation } from "../../../../../../../../lib/object/value/institution-information";
import { useAppGetInstitutionQuery } from "../../../../../../../../hooks/query/use-app-get-institution-query";
import { useAppTranslation } from "../../../../../../../../hooks/use-app-translation";

export type InstitutionContentProps = {
  editMode: "editable" | "readOnly";
  value: Partial<InstitutionInformation>;
  onSelect?: (value: InstitutionInformation | null) => void;
} & ComponentStyleProps;

/**
 * 機関情報内容。
 */
export const InstitutionContent = memo(function InstitutionContent({
  editMode,

  value,
  onSelect,

  sx,
  ...rest
}: InstitutionContentProps) {
  const { t } = useAppTranslation();

  const { data: institution } = useAppGetInstitutionQuery(
    {
      institutionId: value.institutionId ?? -1,
    },
    { skip: editMode !== "editable" || isNullish(value.institutionId) },
  );

  useEffect(() => {
    if (editMode !== "editable" || isNullish(institution)) return;
    onSelect?.({
      institutionId: institution.id,
      displayInstitutionId: institution.displayInstitutionId,
      institutionName: institution.name,
      institutionNameKana: institution.nameKana,
      foreignType: institution.foreignType,
      commercialType: institution.commercialType,
    });
  }, [editMode, institution, onSelect]);

  const handleChange = useCallback(
    (value: InstitutionInformation | null) => {
      onSelect?.(value);
    },
    [onSelect],
  );

  return (
    <HStack
      alignItems={"flex-start"}
      justifyContent={"stretch"}
      minW={0}
      spacing={"20px"}
      sx={sx}
      {...rest}
    >
      <ApplicationInstitutionSelect
        editMode={editMode}
        value={value?.institutionId ?? null}
        onChange={handleChange}
        sx={{ flex: "2 1 auto" }}
      />
      <VStack flex={"1 1 auto"} minW={0}>
        <LabelAndEnumValue
          valueObjectMeta={foreignTypeMeta}
          label={t("lbl.国内外区分")}
          value={value?.foreignType}
        />
      </VStack>
      <VStack flex={"1 1 auto"} minW={0}>
        <LabelAndEnumValue
          valueObjectMeta={commercialTypeMeta}
          label={t("lbl.営利非営利区分")}
          value={value?.commercialType}
        />
      </VStack>
    </HStack>
  );
});
