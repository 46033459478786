import { createStandaloneToast, UseToastOptions } from "@chakra-ui/react";
import {
  filterOutErrorsOfPaths,
  renameErrorsOfPaths,
  ValidationError,
} from "@pscsrvlab/psc-react-components";
import { useCallback, useMemo } from "react";
import { hasValue } from "../lib/util/common-util";

const useCustomToast = () => {
  const { toast } = createStandaloneToast();

  const globalOptions = useMemo(
    () => ({
      duration: 5000,
      isClosable: true,
      position: "bottom",
    }),
    [],
  );

  const successToast = useCallback(
    (message: string, options: UseToastOptions = {}) => {
      return toast(
        Object.assign({ title: message }, globalOptions, options, {
          status: "success",
        }),
      );
    },
    [globalOptions, toast],
  );

  const infoToast = useCallback(
    (message: string, options: UseToastOptions = {}) => {
      return toast(
        Object.assign({ title: message }, globalOptions, options, {
          status: "info",
        }),
      );
    },
    [globalOptions, toast],
  );

  const warnToast = useCallback(
    (message: string, options: UseToastOptions = {}) => {
      return toast(
        Object.assign({ title: message }, globalOptions, options, {
          status: "warn",
        }),
      );
    },
    [globalOptions, toast],
  );

  const errorToast = useCallback(
    (message: string, options: UseToastOptions = {}) => {
      return toast(
        Object.assign(
          { title: message },
          globalOptions,
          { duration: 10000 },
          options,
          {
            status: "error",
          },
        ),
      );
    },
    [globalOptions, toast],
  );

  const validationErrorToast = useCallback(
    (
      errors: ValidationError[],
      filterOutPaths?: string[],
      renames?: {
        path: string;
        objectLogicalName?: string;
        fullPropertyDisplayName?: string;
      }[],
    ) => {
      const _errors1 = hasValue(filterOutPaths)
        ? filterOutErrorsOfPaths(errors, filterOutPaths)
        : errors;
      const _errors2 = hasValue(renames)
        ? renameErrorsOfPaths(_errors1, renames)
        : _errors1;
      _errors2.forEach((e) => {
        errorToast(`${e.fullPropertyDisplayName}: ${e.errorMessage}`);
      });
    },
    [errorToast],
  );

  return {
    successToast,
    infoToast,
    warnToast,
    errorToast,
    validationErrorToast,
  };
};
export default useCustomToast;
