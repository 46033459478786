import { ComponentStyleProps } from "../../../../lib/styles/props/component-style-props";
import { CMModalOptionsCancel } from "@pscsrvlab/psc-react-components";
import React from "react";
import { useAppTranslation } from "../../../../hooks/use-app-translation";

export type ConfirmationModalProps = {
  isOpen: boolean;

  message: string;

  onSubmit: () => void;
  onCancel: () => void;
} & ComponentStyleProps;

export const ConfirmationModal = ({
  isOpen,

  message,

  onSubmit,
  onCancel,

  sx,
  ...rest
}: ConfirmationModalProps) => {
  const { t } = useAppTranslation();

  return (
    <CMModalOptionsCancel
      isOpen={isOpen}
      labelHeader={t("lbl.確認ポップアップタイトル")}
      labelBody={message}
      labelSubmitButton={t("btn.OKボタン")}
      labelCancelButton={t("btn.キャンセルボタン")}
      onSubmit={onSubmit}
      onCancel={onCancel}
      sx={sx}
      {...rest}
    />
  );
};
