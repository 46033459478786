import {
  messageId,
  PropertyMeta,
  ValueObjectMetaString,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.string().max(10000);
const savedMinimalSchema = z.string().max(10000);
const schema = z.string().max(10000);
const savedSchema = z.string().max(10000);
const jsonSchema = z.string();
/**
 * 使用株の意見(値オブジェクト)のメタデータ。
 *
 *  */
export const opinionsOfStockUsedMeta = new ValueObjectMetaString(
  "OpinionsOfStockUsed",
  "\u4F7F\u7528\u682A\u306E\u610F\u898B",
  messageId("lbl.\u4F7F\u7528\u682A\u306E\u610F\u898B"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
