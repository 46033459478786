import {
  messageId,
  PropertyMeta,
  ValueObjectMetaString,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.string().max(10000);
const savedMinimalSchema = z.string().max(10000);
const schema = z.string().min(1).max(10000);
const savedSchema = z.string().min(1).max(10000);
const jsonSchema = z.string();
/**
 * 問題内容(値オブジェクト)のメタデータ。
 *
 *  */
export const problemDetailsMeta = new ValueObjectMetaString(
  "ProblemDetails",
  "\u554F\u984C\u5185\u5BB9",
  messageId("lbl.\u554F\u984C\u5185\u5BB9"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
