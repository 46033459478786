import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * 輸送手配方法区分(値オブジェクト)
 *
 *
 *
 * arranged_by_applicant:
 * arranged_by_foundation:
 * decide_after_consultation:  */
export type DeliveryArrangementMethodType = z.infer<
  typeof deliveryArrangementMethodTypeMeta.schema
>;
/**
 * 輸送手配方法区分(値オブジェクト)のメタデータ。
 *
 *  */
export const deliveryArrangementMethodTypeMeta = new ValueObjectMetaEnum<
  [
    "arranged_by_applicant",
    "arranged_by_foundation",
    "decide_after_consultation"
  ]
>(
  "DeliveryArrangementMethodType",
  "\u8F38\u9001\u624B\u914D\u65B9\u6CD5\u533A\u5206",
  messageId("lbl.\u8F38\u9001\u624B\u914D\u65B9\u6CD5\u533A\u5206"),
  [
    "arranged_by_applicant",
    "arranged_by_foundation",
    "decide_after_consultation",
  ],
  {
    arranged_by_applicant:
      "code.\u8F38\u9001\u624B\u914D\u65B9\u6CD5\u533A\u5206.\u7533\u8ACB\u8005\u306B\u3088\u308B\u624B\u914D",
    arranged_by_foundation:
      "code.\u8F38\u9001\u624B\u914D\u65B9\u6CD5\u533A\u5206.CiRA_F\u306B\u3088\u308B\u624B\u914D",
    decide_after_consultation:
      "code.\u8F38\u9001\u624B\u914D\u65B9\u6CD5\u533A\u5206.CiRA_F\u3068\u76F8\u8AC7\u306E\u5F8C\u306B\u6C7A\u5B9A",
  }
);
