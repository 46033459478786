import {
  messageId,
  PropertyMeta,
  ValueObjectMetaComposite,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { pickupDestinationNameMeta } from "../value/pickup-destination-name";
import { pickupLocationMeta } from "../value/pickup-location";
import { freeTextMeta } from "../value/free-text";
import { pickupDestinationOtherMeta } from "../value/pickup-destination-other";
/**
 * 集荷先(値オブジェクト)
 *
 *  */
export type PickupDestination = z.infer<typeof schema>;
export type PickupDestinationSaved = z.infer<typeof savedSchema>;
const minimalSchema = z.object({
  destinationName: pickupDestinationNameMeta.minimalSchema,
  pickupLocation: pickupLocationMeta.minimalSchema,
  postalCode: freeTextMeta.minimalSchema,
  address: freeTextMeta.minimalSchema,
  titleAndPosition: freeTextMeta.minimalSchema,
  fullName: freeTextMeta.minimalSchema,
  phoneNumber: freeTextMeta.minimalSchema,
  mailAddress: freeTextMeta.minimalSchema,
  other: pickupDestinationOtherMeta.minimalSchema,
});
const savedMinimalSchema = z.object({
  destinationName: pickupDestinationNameMeta.savedMinimalSchema,
  pickupLocation: pickupLocationMeta.savedMinimalSchema,
  postalCode: freeTextMeta.savedMinimalSchema,
  address: freeTextMeta.savedMinimalSchema,
  titleAndPosition: freeTextMeta.savedMinimalSchema,
  fullName: freeTextMeta.savedMinimalSchema,
  phoneNumber: freeTextMeta.savedMinimalSchema,
  mailAddress: freeTextMeta.savedMinimalSchema,
  other: pickupDestinationOtherMeta.savedMinimalSchema,
});
const schema = z.object({
  destinationName: pickupDestinationNameMeta.schema,
  pickupLocation: pickupLocationMeta.schema,
  postalCode: freeTextMeta.schema,
  address: freeTextMeta.schema,
  titleAndPosition: freeTextMeta.schema,
  fullName: freeTextMeta.schema,
  phoneNumber: freeTextMeta.schema,
  mailAddress: freeTextMeta.schema,
  other: pickupDestinationOtherMeta.schema,
});
const savedSchema = z.object({
  destinationName: pickupDestinationNameMeta.savedSchema,
  pickupLocation: pickupLocationMeta.savedSchema,
  postalCode: freeTextMeta.savedSchema,
  address: freeTextMeta.savedSchema,
  titleAndPosition: freeTextMeta.savedSchema,
  fullName: freeTextMeta.savedSchema,
  phoneNumber: freeTextMeta.savedSchema,
  mailAddress: freeTextMeta.savedSchema,
  other: pickupDestinationOtherMeta.savedSchema,
});
const jsonSchema = z.object({
  destinationName: pickupDestinationNameMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  pickupLocation: pickupLocationMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  postalCode: freeTextMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  address: freeTextMeta.jsonSchema.nullish().transform((x) => x ?? undefined),
  titleAndPosition: freeTextMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  fullName: freeTextMeta.jsonSchema.nullish().transform((x) => x ?? undefined),
  phoneNumber: freeTextMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  mailAddress: freeTextMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  other: pickupDestinationOtherMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
/**
 * 集荷先(値オブジェクト)のメタデータ。
 *
 *  */
export const pickupDestinationMeta = new ValueObjectMetaComposite(
  "PickupDestination",
  "\u96C6\u8377\u5148",
  messageId("lbl.\u96C6\u8377\u5148"),
  minimalSchema,
  savedMinimalSchema,
  schema,
  savedSchema,
  jsonSchema,
  undefined,
  undefined,
  {
    destinationName: new PropertyMeta(
      "destinationName",
      "\u96C6\u8377\u5148\u540D\u79F0",
      messageId("lbl.\u96C6\u8377\u5148\u540D\u79F0"),
      "always",
      false,
      pickupDestinationNameMeta
    ),
    pickupLocation: new PropertyMeta(
      "pickupLocation",
      "\u53D7\u53D6\u5834\u6240",
      messageId("lbl.\u53D7\u53D6\u5834\u6240"),
      "always",
      false,
      pickupLocationMeta
    ),
    postalCode: new PropertyMeta(
      "postalCode",
      "\u90F5\u4FBF\u756A\u53F7",
      messageId("lbl.\u90F5\u4FBF\u756A\u53F7"),
      "always",
      false,
      freeTextMeta
    ),
    address: new PropertyMeta(
      "address",
      "\u4F4F\u6240",
      messageId("lbl.\u4F4F\u6240"),
      "always",
      false,
      freeTextMeta
    ),
    titleAndPosition: new PropertyMeta(
      "titleAndPosition",
      "\u6240\u5C5E\u30FB\u8077\u540D",
      messageId("lbl.\u6240\u5C5E\u30FB\u8077\u540D"),
      "always",
      false,
      freeTextMeta
    ),
    fullName: new PropertyMeta(
      "fullName",
      "\u6C0F\u540D",
      messageId("lbl.\u6C0F\u540D"),
      "always",
      false,
      freeTextMeta
    ),
    phoneNumber: new PropertyMeta(
      "phoneNumber",
      "\u96FB\u8A71\u756A\u53F7",
      messageId("lbl.\u96FB\u8A71\u756A\u53F7"),
      "always",
      false,
      freeTextMeta
    ),
    mailAddress: new PropertyMeta(
      "mailAddress",
      "\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9",
      messageId("lbl.\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9"),
      "always",
      false,
      freeTextMeta
    ),
    other: new PropertyMeta(
      "other",
      "\u96C6\u8377\u5148\u305D\u306E\u4ED6",
      messageId("lbl.\u96C6\u8377\u5148\u305D\u306E\u4ED6"),
      "always",
      false,
      pickupDestinationOtherMeta
    ),
  }
);
