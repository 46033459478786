import { useMemo } from "react";
import { merge } from "lodash";
import { useAppGetNewApplicationQuery } from "../../query/use-app-get-new-application-query";
import { isNullish } from "../../../lib/util/common-util";
import { useNewApplicationVM } from "./use-new-application-vm";
import { NewApplicationViewModel } from "../../../lib/object/vm/new-application-view-model";
import { NewApplicationSaved } from "../../../lib/object/entity/new-application";

export function useNewApplicationReadonlyVm(
  documentId: number | null | undefined,

  options?: {
    /**
     * skipがtrueの場合、何も行わない。
     */
    skip: boolean;
  },
): {
  currentData: NewApplicationSaved | null;
  vm: NewApplicationViewModel | null;
} {
  const _options = useMemo(() => merge({ skip: false }, options), [options]);

  const { currentData } = useAppGetNewApplicationQuery(
    { documentId: documentId ?? -1 },
    { skip: _options.skip || isNullish(documentId) },
  );

  const vm = useNewApplicationVM(currentData);
  return { currentData, vm };
}
