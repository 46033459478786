import { ComponentStyleProps } from "../../../../../lib/styles/props/component-style-props";
import { CMButtonSearchConditionDatalistFree } from "@pscsrvlab/psc-react-components";
import React, { useCallback, useMemo } from "react";
import { useAppTranslation } from "../../../../../hooks/use-app-translation";
import { useAppListCellStockQuery } from "../../../../../hooks/query/use-app-list-cell-stock-query";
import { isNullish } from "../../../../../lib/util/common-util";

export type ButtonSearchConditionCellProps = {
  label: string;

  /**
   * 値。細胞ID。
   */
  value?: number | null;

  hideClearButton?: boolean;

  onChange?: (value: number | undefined) => void;
} & ComponentStyleProps;

export const ButtonSearchConditionCell = ({
  label,

  value = null,

  hideClearButton = false,

  onChange,

  sx,
  ...rest
}: ButtonSearchConditionCellProps) => {
  const { t, i18n } = useAppTranslation();

  const { data: cells } = useAppListCellStockQuery({});

  const options: { label: string; value: number }[] = useMemo(() => {
    if (isNullish(cells)) return [];
    return cells.map((c) => ({
      label: i18n.language === "ja" ? c.cellNameJa : c.cellNameEn,
      value: c.cellStockId,
    }));
  }, [cells, i18n.language]);

  const handleChange = useCallback(
    (value: number | null) => {
      onChange?.(value ?? undefined);
    },
    [onChange],
  );

  const capitalizedLabel = label;

  return (
    <CMButtonSearchConditionDatalistFree<number>
      label={capitalizedLabel}
      value={value}
      hideClearButton={hideClearButton}
      options={options}
      labelSubmit={t("btn.確定ボタン")}
      labelClear={t("btn.クリアボタン")}
      onChange={handleChange}
      sx={sx}
      {...rest}
    />
  );
};
