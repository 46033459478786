import { LoginUserInfo } from "../../store/auth/types";
import { RevisionRequestItemViewModel } from "../../lib/object/vm/revision-request-view-model";
import { UserInformation } from "../../lib/object/value/user-information";
import { useCallback, useMemo } from "react";
import { hasValue, isNullish } from "../../lib/util/common-util";
import { CMButtonFormCommentProps } from "@pscsrvlab/psc-react-components/src/components/form/comment/button/CMButtonFormComment/CMButtonFormComment";
import { commentButtonIdOf } from "../../lib/util/app-util";
import { useAppTranslation } from "../use-app-translation";
import { orderBy } from "lodash";

export function useRevisionRequestComment(
  loginUserInfo: LoginUserInfo,

  revisionMode:
    | "none"
    | "readOnly"
    | "office_member_editable"
    | "applicant_editable",
  revisionRequestItems?: RevisionRequestItemViewModel[],
  /**
   * 修正依頼コメントを選択したときのコールバック。
   * 未選択状態になる場合はnullを送出する。
   */
  onSelectComment?: (path: string | null) => void,
  /**
   * 修正依頼の変更。
   * 戻り値は、必ずパスの辞書順に並んでいる。
   */
  onChangeRevisionRequestItems?: (
    requestsChange: (
      before: RevisionRequestItemViewModel[],
    ) => RevisionRequestItemViewModel[],
  ) => void,
) {
  const { t } = useAppTranslation();

  const commenter: UserInformation = useMemo(
    () => ({
      appUserId: loginUserInfo.id,
      displayUserId: loginUserInfo.displayUserId,
      role: loginUserInfo.role,
      fullName: loginUserInfo.fullName,
      fullNameKana: loginUserInfo.fullNameKana,
      titleAndPosition: loginUserInfo.titleAndPosition,
      phoneNumber: loginUserInfo.phoneNumber,
      mailAddress: loginUserInfo.mailAddress,
    }),
    [loginUserInfo],
  );

  const commentPropsCommon: {
    editButtonLabel: string;
    maxLength: number;
    commentInput: {
      main: {
        placeholder?: string;
        finishButtonLabel: string;
        cancelButtonLabel: string;
        deleteButtonLabel: string;
      };
      reply: {
        placeholder?: string;
        finishButtonLabel: string;
        cancelButtonLabel: string;
        deleteButtonLabel: string;
      };
    };
    deleteConfirmationModal: {
      title: string;
      message?: string;
      deleteButtonLabel: string;
      cancelButtonLabel: string;
    };
    onClose: () => void;
    usePortal: boolean;
  } = useMemo(() => {
    return {
      editButtonLabel: t("btn.編集ボタン"),
      maxLength: 10000,
      commentInput: {
        main: {
          placeholder: t("lbl.修正依頼コメント"),
          finishButtonLabel: t("btn.保存して閉じるボタン"),
          cancelButtonLabel: t("btn.キャンセルボタン"),
          deleteButtonLabel: t("btn.削除ボタン"),
        },
        reply: {
          placeholder: t("lbl.申請者コメント"),
          finishButtonLabel: t("btn.保存して閉じるボタン"),
          cancelButtonLabel: t("btn.キャンセルボタン"),
          deleteButtonLabel: t("btn.削除ボタン"),
        },
      },
      deleteConfirmationModal: {
        title: t("lbl.確認ポップアップタイトル"),
        message:
          revisionMode === "office_member_editable"
            ? t("mes.修正依頼コメント削除確認メッセージ")
            : revisionMode === "applicant_editable"
            ? t("mes.返信コメント削除確認メッセージ")
            : "",
        deleteButtonLabel: t("btn.削除ボタン"),
        cancelButtonLabel: t("btn.キャンセルボタン"),
      },
      onClose: () => onSelectComment?.(null),
      usePortal: true,
    };
  }, [t, revisionMode, onSelectComment]);

  const onSaveMain = useCallback(
    (path: string, value: string | null) => {
      if (revisionMode !== "office_member_editable") return;

      onChangeRevisionRequestItems?.((before) => {
        if (isNullish(value)) {
          return before.filter((v) => v.path !== path);
        }

        const item: RevisionRequestItemViewModel = {
          path,
          officeMemberComment: {
            body: value,
            datetime: new Date(), // バックエンドには送らない。あくまで暫定。
            commenter,
          },
        };
        return orderBy(
          [...before.filter((v) => v.path !== path), item],
          ["path"],
          ["asc"],
        );
      });
      // onSelectComment?.(path);
    },
    [revisionMode, onChangeRevisionRequestItems, commenter],
  );
  const onSaveReply = useCallback(
    (path: string, value: string | null) => {
      if (revisionMode !== "applicant_editable") return;

      onChangeRevisionRequestItems?.((before) => {
        const beforeItem = before.find((v) => v.path === path);
        if (isNullish(beforeItem)) return before;

        const item: RevisionRequestItemViewModel = {
          ...beforeItem,
          applicantComment: hasValue(value)
            ? {
                body: value,
                datetime: new Date(), // バックエンドには送らない。あくまで暫定。
                commenter,
              }
            : undefined,
        };
        return orderBy(
          [...before.filter((v) => v.path !== path), item],
          ["path"],
          ["asc"],
        );
      });
      // onSelectComment?.(path);
    },
    [revisionMode, onChangeRevisionRequestItems, commenter],
  );

  const officeMemberCommenterName = useMemo(() => t("lbl.事務局"), [t]);

  const ctx = useMemo(
    () => ({
      revisionMode,
      revisionRequestItems,
      commentPropsCommon,
      onSelectComment,
      onSaveMain,
      onSaveReply,
      officeMemberCommenterName,
    }),
    [
      revisionMode,
      revisionRequestItems,
      commentPropsCommon,
      onSelectComment,
      onSaveMain,
      onSaveReply,
      officeMemberCommenterName,
    ],
  );

  return {
    ctx,
  };
}

export function getCommentProps(
  {
    revisionMode,
    revisionRequestItems,
    commentPropsCommon,
    onSelectComment,
    onSaveMain,
    onSaveReply,
    officeMemberCommenterName,
  }: {
    revisionMode:
      | "none"
      | "readOnly"
      | "office_member_editable"
      | "applicant_editable";
    revisionRequestItems: RevisionRequestItemViewModel[] | undefined;
    commentPropsCommon: {
      editButtonLabel: string;
      commentInput: {
        main: {
          placeholder?: string;
          finishButtonLabel: string;
          cancelButtonLabel: string;
          deleteButtonLabel: string;
        };
        reply: {
          placeholder?: string;
          finishButtonLabel: string;
          cancelButtonLabel: string;
          deleteButtonLabel: string;
        };
      };
      deleteConfirmationModal: {
        title: string;
        message?: string;
        deleteButtonLabel: string;
        cancelButtonLabel: string;
      };
      onClose: () => void;
    };
    onSelectComment?: (value: string | null) => void;
    onSaveMain: (path: string, value: string | null) => void;
    onSaveReply: (path: string, value: string | null) => void;

    officeMemberCommenterName: string;
  },

  path: string,
): CMButtonFormCommentProps | undefined {
  if (isNullish(revisionRequestItems) || revisionMode === "none")
    return undefined;
  const item = revisionRequestItems.find((i) => i.path === path);
  if (isNullish(item)) {
    // 既存の修正依頼項目が見つからない場合は、
    // 事務局側の依頼中なら空でコメントボタンを表示し、
    // それ以外ならコメントボタン自体表示しない。
    if (revisionMode === "office_member_editable") {
      return {
        ...commentPropsCommon,
        onSaveMain: (value: string | null) => onSaveMain(path, value),
        onSaveReply: undefined,
      };
    }
    return undefined;
  }

  const mainComment = {
    userName: officeMemberCommenterName,
    date: item.officeMemberComment.datetime,
    comment: item.officeMemberComment.body,
  };
  const replyComment = hasValue(item.applicantComment)
    ? {
        userName: item.applicantComment.commenter.fullName,
        date: item.applicantComment.datetime,
        comment: item.applicantComment.body,
      }
    : undefined;
  return {
    ...commentPropsCommon,
    mainComment,
    replyComment,
    onOpen: () => onSelectComment?.(path),
    onSaveMain:
      revisionMode === "office_member_editable"
        ? (value: string | null) => onSaveMain(path, value)
        : undefined,
    onSaveReply:
      revisionMode === "applicant_editable"
        ? (value: string | null) => onSaveReply(path, value)
        : undefined,
    id: commentButtonIdOf(path),
  };
}
