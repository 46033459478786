import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * 書類位置(値オブジェクト)
 *
 *
 *
 * applicant:
 * office_member:
 * executive_director:  */
export type DocumentPosition = z.infer<typeof documentPositionMeta.schema>;
/**
 * 書類位置(値オブジェクト)のメタデータ。
 *
 *  */
export const documentPositionMeta = new ValueObjectMetaEnum<
  ["applicant", "office_member", "executive_director"]
>(
  "DocumentPosition",
  "\u66F8\u985E\u4F4D\u7F6E",
  messageId("lbl.\u66F8\u985E\u4F4D\u7F6E"),
  ["applicant", "office_member", "executive_director"],
  {
    applicant: "code.\u66F8\u985E\u4F4D\u7F6E.\u7533\u8ACB\u8005",
    office_member: "code.\u66F8\u985E\u4F4D\u7F6E.\u4E8B\u52D9\u5C40",
    executive_director:
      "code.\u66F8\u985E\u4F4D\u7F6E.\u696D\u52D9\u57F7\u884C\u7406\u4E8B",
  }
);
