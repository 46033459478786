import {
  messageId,
  PropertyMeta,
  EntityMeta,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { projectSnapshotIdMeta } from "../value/project-snapshot-id";
import { projectIdMeta } from "../value/project-id";
import { jsonMeta } from "../value/json";
import { creationMetadataMeta } from "../value/creation-metadata";
import { updateMetadataMeta } from "../value/update-metadata";
/**
 * 案件スナップショット(エンティティ)
 *
 *  */
export type ProjectSnapshot = z.infer<typeof schema>;
export type ProjectSnapshotSaved = z.infer<typeof savedSchema>;
const minimalSchema = z.object({
  id: projectSnapshotIdMeta.minimalSchema.optional(),
  projectId: projectIdMeta.minimalSchema,
  projectJson: jsonMeta.minimalSchema,
  created: creationMetadataMeta.minimalSchema,
  updated: updateMetadataMeta.minimalSchema,
});
const savedMinimalSchema = z.object({
  id: projectSnapshotIdMeta.minimalSchema,
  projectId: projectIdMeta.savedMinimalSchema,
  projectJson: jsonMeta.savedMinimalSchema,
  created: creationMetadataMeta.savedMinimalSchema,
  updated: updateMetadataMeta.savedMinimalSchema,
});
const schema = z.object({
  id: projectSnapshotIdMeta.minimalSchema.optional(),
  projectId: projectIdMeta.schema,
  projectJson: jsonMeta.schema,
  created: creationMetadataMeta.schema,
  updated: updateMetadataMeta.schema,
});
const savedSchema = z.object({
  id: projectSnapshotIdMeta.minimalSchema,
  projectId: projectIdMeta.savedSchema,
  projectJson: jsonMeta.savedSchema,
  created: creationMetadataMeta.savedSchema,
  updated: updateMetadataMeta.savedSchema,
});
const jsonSchema = z.object({
  id: projectSnapshotIdMeta.jsonSchema.optional(),
  projectId: projectIdMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  projectJson: jsonMeta.jsonSchema.nullish().transform((x) => x ?? undefined),
  created: creationMetadataMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  updated: updateMetadataMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
/**
 * 案件スナップショット(エンティティ)のメタデータ。
 *
 *  */
export const projectSnapshotMeta = new EntityMeta<
  typeof schema,
  typeof savedSchema,
  typeof jsonSchema
>(
  "ProjectSnapshot",
  "\u6848\u4EF6\u30B9\u30CA\u30C3\u30D7\u30B7\u30E7\u30C3\u30C8",
  messageId("lbl.\u6848\u4EF6\u30B9\u30CA\u30C3\u30D7\u30B7\u30E7\u30C3\u30C8"),
  minimalSchema,
  savedMinimalSchema,
  schema,
  savedSchema,
  jsonSchema,
  undefined,
  undefined,
  {
    id: new PropertyMeta(
      "id",
      "ID",
      messageId("lbl.ID"),
      "afterSave",
      false,
      projectSnapshotIdMeta
    ),
    projectId: new PropertyMeta(
      "projectId",
      "\u6848\u4EF6ID",
      messageId("lbl.\u6848\u4EF6ID"),
      "always",
      false,
      projectIdMeta
    ),
    projectJson: new PropertyMeta(
      "projectJson",
      "\u6848\u4EF6JSON",
      messageId("lbl.\u6848\u4EF6JSON"),
      "always",
      false,
      jsonMeta
    ),
    created: new PropertyMeta(
      "created",
      "\u4F5C\u6210",
      messageId("lbl.\u4F5C\u6210"),
      "always",
      false,
      creationMetadataMeta
    ),
    updated: new PropertyMeta(
      "updated",
      "\u66F4\u65B0",
      messageId("lbl.\u66F4\u65B0"),
      "always",
      false,
      updateMetadataMeta
    ),
  }
);
