import { Text, VStack } from "@chakra-ui/react";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { CMMessageInfo } from "@pscsrvlab/psc-react-components";
import React from "react";

export type ReviewDetailsEmptyPageProps = {
  anyReviewsExists: boolean;
};
export const ReviewDetailsEmptyPage = ({
  anyReviewsExists,
}: ReviewDetailsEmptyPageProps) => {
  const { t } = useAppTranslation();
  return (
    <VStack flex={1} pt={"12px"} pl={"20px"} alignItems={"flex-start"}>
      {anyReviewsExists ? (
        <Text>{t("gdc.審査選択案内")}</Text>
      ) : (
        <CMMessageInfo label={t("gdc.審査未作成時インフォメーション")} />
      )}
    </VStack>
  );
};
