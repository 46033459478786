import { ComponentStyleProps } from "../../../../../../../../../lib/styles/props/component-style-props";
import {
  CMFormControlHeader,
  CMFormInputBaseCheckbox,
} from "@pscsrvlab/psc-react-components";
import { Box, Stack, VStack } from "@chakra-ui/react";
import { memo, useCallback, useMemo } from "react";
import {
  hasValue,
  isNullish,
} from "../../../../../../../../../lib/util/common-util";
import { ymdToDateText } from "../../../../../../../../../lib/util/common-date-util";
import { useGetCurrentDatetimeQuery } from "../../../../../../../../../store/api/generated/stock-request-api";
import { datetimeMeta } from "../../../../../../../../../lib/object/value/datetime";
import { ChecklistItemCheckState } from "../../../../../../../../../lib/object/value/checklist-item-check-state";
import { YearMonthDay } from "../../../../../../../../../lib/object/value/year-month-day";
import { useAppTranslation } from "../../../../../../../../../hooks/use-app-translation";

export type SignatureSectionProps = {
  principalInvestigatorName?: string;

  editMode: "editable" | "disabled" | "readOnly";

  valueCorrectnessCheck?: ChecklistItemCheckState;
  valueCheckDate?: YearMonthDay;

  onCheck?: (selectedValues: {
    correctnessCheck: "checked" | "unchecked";
    checkDate?: { year: number; month: number; day: number };
  }) => void;
} & ComponentStyleProps;

export const SignatureSection = memo(function SignatureSection({
  principalInvestigatorName,

  editMode,

  valueCorrectnessCheck,
  valueCheckDate,

  onCheck,

  sx,
  ...rest
}: SignatureSectionProps) {
  const { t } = useAppTranslation();

  const checkboxValues = useMemo(() => {
    return valueCorrectnessCheck === "checked" ? ["0"] : [];
  }, [valueCorrectnessCheck]);

  /**
   * バックエンドから現在日時を取得する。
   * 取得できるまでは、ローカルPC日時で埋めておく。
   *
   * 実際に使用するのは日付部分のみなので、一定時間ごとに取得し直したりはしない。
   */
  const { data: currentDateData } = useGetCurrentDatetimeQuery();
  const currentDate: Date = useMemo(() => {
    if (isNullish(currentDateData)) return new Date();
    return (
      datetimeMeta.toDomainObjectOrNull(currentDateData.currentDatetime) ??
      new Date()
    );
  }, [currentDateData]);

  // 事実関係確認にチェックが入ったタイミングで確認日に現在日付を設定する。
  const handleChangeSignature = useCallback(
    (values: string[]) => {
      if (values.length > 0 && values[0] === "0") {
        onCheck?.({
          correctnessCheck: "checked",
          checkDate: {
            year: currentDate.getFullYear(),
            month: currentDate.getMonth() + 1,
            day: currentDate.getDate(),
          },
        });
      } else {
        onCheck?.({ correctnessCheck: "unchecked", checkDate: undefined });
      }
    },
    [currentDate, onCheck],
  );

  return (
    <VStack sx={sx} {...rest} pt={"20px"} pb={"20px"}>
      <CMFormInputBaseCheckbox
        editMode={editMode === "editable" ? "editable" : "disabled"}
        content={{
          options: [{ label: t("gdc.事実確認"), value: "0" }],
        }}
        values={checkboxValues}
        onChange={handleChangeSignature}
      />
      <Stack direction={"row"} spacing={"30px"}>
        <Box>
          <CMFormControlHeader value={""} label={t("lbl.確認日")} />
          {valueCorrectnessCheck === "checked" && hasValue(valueCheckDate) && (
            <Box ml={"10px"}>{ymdToDateText(valueCheckDate)}</Box>
          )}
        </Box>
        <Box>
          <CMFormControlHeader value={""} label={t("lbl.研究責任者")} />
          <Box ml={"10px"}>{principalInvestigatorName ?? ""}</Box>
        </Box>
      </Stack>
    </VStack>
  );
});
