import {
  ApplicationContent,
  applicationContentMeta,
} from "../../../lib/object/value/application-content";
import {
  filterOutErrorsOfPaths,
  ValidationError,
} from "@pscsrvlab/psc-react-components";
import { ApplicationContentViewModel } from "../../../components/model/document/application/_components/ApplicationDocument/ApplicationDocument";
import { useCallback, useState } from "react";
import log from "loglevel";
import { isNullish } from "../../../lib/util/common-util";
import { errorMessageOf } from "../../../lib/util/error-util";
import { stockRequestApi } from "../../../store/api/enhanced-api";
import { useCurrentDatetime } from "../../use-current-datetime";
import { TFunction } from "i18next";
import useCustomToast from "../../use-custom-toast";
import { useAppTranslation } from "../../use-app-translation";

/**
 * 申請書類内容のバリデーションを行う関数を返す。
 * metaのvalidateだけでは対応しきれない部分もあるため、フックとして切り出した。
 */
export function useApplicationContentValidation() {
  const { t } = useAppTranslation();
  const { errorToast } = useCustomToast();

  const [validationErrors, setValidationErrors] = useState<ValidationError[]>(
    [],
  );

  // 研究責任者の過去案件数を取得するクエリ。
  const [triggerCountPastProjectQuery] =
    stockRequestApi.useLazyCountPastProjectQuery();
  // 現在日時
  const currentDatetime = useCurrentDatetime();

  /**
   * 申請書類内容のバリデーションを行う。
   * バリデーションエラーがあった場合、それらをvalidationErrorsに設定する。
   * その他検証に失敗した場合などは、エラートーストを表示する。
   * @return エラーなしの場合は"ok"、バリデーションエラーありの場合は"error"、その他検証自体に失敗した場合などは"unexpectedError"を返す。
   */
  const validate = useCallback(
    async (
      viewModel: ApplicationContentViewModel,
      skipOptional: boolean,
    ): Promise<
      | { state: "ok"; value: ApplicationContent }
      | { state: "error"; errors: ValidationError[] }
      | { state: "unexpectedError" }
    > => {
      const regularValidationResult = regularValidations(
        viewModel,
        skipOptional,
      );

      const usageEndDateValidationResult = usageEndDateValidation(
        viewModel,
        currentDatetime,
        t,
        skipOptional,
      );

      const attachmentFilesValidationResult = await attachmentFilesValidation(
        viewModel,
        triggerCountPastProjectQuery,
        t,
        skipOptional,
      );
      if (attachmentFilesValidationResult.state === "unexpectedError") {
        errorToast(t("mes.一般エラーメッセージ"));
        return { state: "unexpectedError" };
      }

      const validationErrors = [
        ...(regularValidationResult.state === "error"
          ? regularValidationResult.errors
          : []),
        ...(usageEndDateValidationResult.state === "error"
          ? usageEndDateValidationResult.errors
          : []),
        ...(attachmentFilesValidationResult.state === "error"
          ? attachmentFilesValidationResult.errors
          : []),
      ];
      setValidationErrors(validationErrors);

      return regularValidationResult.state === "ok" &&
        usageEndDateValidationResult.state === "ok" &&
        attachmentFilesValidationResult.state === "ok"
        ? { state: "ok", value: regularValidationResult.value }
        : { state: "error", errors: validationErrors };
    },
    [currentDatetime, errorToast, t, triggerCountPastProjectQuery],
  );

  return { validate, validationErrors };
}

/**
 * 書類内容に対するバリデーション。
 * バリデーション完了後、エラー一覧をセットするが、その際にユーザーに表示したくないエラーは除外する。
 * ただし、ユーザーに表示したくないエラーであっても、エラーがあれば戻り値は"error"を返す。
 * 注意: よって、ユーザーに表示したくないエラーがあるときは、他の（表示すべき）エラーもある状態とすべき。
 */
function regularValidations(
  viewModel: ApplicationContentViewModel,
  skipOptional: boolean,
):
  | { state: "ok"; value: ApplicationContent }
  | { state: "error"; errors: ValidationError[] } {
  const validationState = applicationContentMeta.validate(
    viewModel,
    skipOptional,
  );
  if (validationState.state === "ok")
    return { state: "ok", value: validationState.value };

  const errors = filterOutErrorsOfPaths(validationState.errors, [
    "institution.institutionId",
    "institution.displayInstitutionId",
    "institution.institutionNameKana",
    "institution.commercialType",
    "institution.foreignType",
    "principalInvestigator.appUserId",
    "principalInvestigator.displayUserId",
    "principalInvestigator.role",
    "principalInvestigator.fullNameKana",
    "principalInvestigator.titleAndPosition",
    "principalInvestigator.phoneNumber",
    "principalInvestigator.mailAddress",
    "contactPeople.#.appUserId",
    "contactPeople.#.displayUserId",
    "contactPeople.#.role",
    "contactPeople.#.fullNameKana",
    "contactPeople.#.titleAndPosition",
    "contactPeople.#.phoneNumber",
    "contactPeople.#.mailAddress",

    "requestingResearchCells.#.key",
    "requestingResearchCells.#.cellStockId",
    "requestingResearchCells.#.cellType",
    "requestingResearchCells.#.cellStockCategoryId",
    "requestingResearchCells.#.cellCategoryNameJa",
    "requestingResearchCells.#.cellCategoryNameEn",
    "requestingResearchCells.#.cellNameJa",
    "requestingResearchCells.#.cellNameEn",

    "requestingClinicalCells.#.key",
    "requestingClinicalCells.#.cellStockId",
    "requestingClinicalCells.#.cellType",
    "requestingClinicalCells.#.cellStockCategoryId",
    "requestingClinicalCells.#.cellCategoryNameJa",
    "requestingClinicalCells.#.cellCategoryNameEn",
    "requestingClinicalCells.#.cellNameJa",
    "requestingClinicalCells.#.cellNameEn",
  ]);
  return { state: "error", errors };
}

/**
 * 使用終了日の妥当性チェックバリデーション。
 * サーバ側のシステム日時（JST）が取れていない間は、ローカルPCの日時にフォールバックする。
 */
function usageEndDateValidation(
  viewModel: ApplicationContentViewModel,
  currentDatetime: Date | null,
  t: TFunction<"translation", undefined, "translation">,
  skipOptional: boolean,
): { state: "ok" } | { state: "error"; errors: ValidationError[] } {
  const usageEndDate = viewModel.usageEndDate;

  if (skipOptional || isNullish(usageEndDate)) return { state: "ok" };

  const _currentDatetime = currentDatetime ?? new Date();

  if (
    _currentDatetime <
    new Date(usageEndDate.year, usageEndDate.month - 1, usageEndDate.day)
  )
    return { state: "ok" };

  const objectName = applicationContentMeta.name;
  const objectLogicalName = applicationContentMeta.logicalName;
  const errors = [
    {
      objectName,
      objectLogicalName,
      fullPropertyName: null,
      fullPropertyDisplayName: null,
      errorMessage: t("mes.使用終了日妥当性チェックエラー"),
    },
  ];
  return { state: "error", errors };
}

/**
 * 添付資料(代表機関用)で、使用実績が無い場合に必須の添付資料がアップロードされていない場合のバリデーション。
 * @return エラーなしの場合は空配列、バリデーションエラーありの場合はその配列、その他検証自体に失敗した場合などは文字列"unexpectedError"を返す。
 */
async function attachmentFilesValidation(
  viewModel: ApplicationContentViewModel,
  triggerCountPastProjectQuery: ReturnType<
    typeof stockRequestApi.useLazyCountPastProjectQuery
  >[0],
  t: TFunction<"translation", undefined, "translation">,
  skipOptional: boolean,
): Promise<
  | { state: "ok" }
  | { state: "error"; errors: ValidationError[] }
  | { state: "unexpectedError" }
> {
  if (skipOptional) return { state: "ok" };

  const principalInvestigatorAppUserId =
    viewModel.principalInvestigator?.appUserId;

  // 研究責任者未指定の場合は、判定のしようがないため、ここではエラーとしない。ただしもちろん、研究責任者未指定により別途エラーが出る。
  if (isNullish(principalInvestigatorAppUserId)) return { state: "ok" };

  let hasUsageRecord: boolean;
  try {
    const countPastProjectData = await triggerCountPastProjectQuery({
      appUserId: principalInvestigatorAppUserId,
    }).unwrap();
    if (isNullish(countPastProjectData.pastProjectCount))
      return { state: "unexpectedError" };
    hasUsageRecord = countPastProjectData.pastProjectCount > 0;
  } catch (e) {
    log.error(errorMessageOf(e));
    return { state: "unexpectedError" };
  }

  // 使用実績があれば、OK。
  if (hasUsageRecord) return { state: "ok" };

  /**
   * 研究責任者の略歴の添付ファイル
   */
  const principalInvestigatorResume = viewModel.attachmentFiles.filter(
    (file) => file.attachmentFileType === "principal_investigator_resume",
  );
  /**
   * 研究責任者が所属する機関の概要の添付ファイル
   */
  const organizationOverview = viewModel.attachmentFiles.filter(
    (file) => file.attachmentFileType === "organization_overview",
  );
  /**
   * ヒトiPS細胞の培養経験かつ目的の細胞への分化がわかる
   * 研究実績を示す資料(論文・研究発表資料等)の添付ファイル
   */
  const researchAchievement = viewModel.attachmentFiles.filter(
    (file) => file.attachmentFileType === "research_achievement",
  );
  /**
   * 添付資料(代表機関用)セクションについて、使用実績がない場合、以下の添付ファイルを必須チェックする。
   * ・研究責任者の略歴
   * ・研究責任者の所属する機関の概要
   * ・ヒトiPS細胞の培養経験かつ目的の細胞への分化がわかる研究実績を示す資料(論文・研究発表資料等)
   * 「UI-FN40_機能仕様(書類・申請)」の「S02相関チェック」シートの「添付資料の必須チェック(共通項目)」参照
   */
  const objectName = applicationContentMeta.name;
  const objectLogicalName = applicationContentMeta.logicalName;

  const errors: ValidationError[] = [];
  if (principalInvestigatorResume.length === 0) {
    errors.push({
      objectName,
      objectLogicalName,
      fullPropertyName: null,
      fullPropertyDisplayName: null,
      errorMessage: t("mes.研究者の略歴必須エラーメッセージ"),
    });
  }
  if (organizationOverview.length === 0) {
    errors.push({
      objectName,
      objectLogicalName,
      fullPropertyName: null,
      fullPropertyDisplayName: null,
      errorMessage: t("mes.研究責任者の所属する機関の概要必須エラーメッセージ"),
    });
  }
  if (researchAchievement.length === 0) {
    errors.push({
      objectName,
      objectLogicalName,
      fullPropertyName: null,
      fullPropertyDisplayName: null,
      errorMessage: t("mes.研究実績を示す資料必須エラーメッセージ"),
    });
  }
  return errors.length <= 0 ? { state: "ok" } : { state: "error", errors };
}
