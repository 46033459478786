import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";
import React from "react";
import { ButtonSearchConditionRadio } from "../../../../../model/search/button/ButtonSearchConditionRadio/ButtonSearchConditionRadio";
import { projectSortConditionMeta } from "../../../../../../lib/object/value/project-sort-condition";
import { numberOfSearchResultsBMeta } from "../../../../../../lib/object/value/number-of-search-results-b";
import { ButtonSearchConditionCheckbox } from "../../../../../model/search/button/ButtonSearchConditionCheckbox/ButtonSearchConditionCheckbox";
import { foreignTypeMeta } from "../../../../../../lib/object/value/foreign-type";
import { commercialTypeMeta } from "../../../../../../lib/object/value/commercial-type";
import { projectStateMeta } from "../../../../../../lib/object/value/project-state";
import { ButtonSearchConditionText } from "../../../../../model/search/button/ButtonSearchConditionText/ButtonSearchConditionText";
import { ButtonSearchConditionDateRange } from "../../../../../model/search/button/ButtonSearchConditionDateRange/ButtonSearchConditionDateRange";
import { SearchProjectApiArg } from "../../../../../../store/api/generated/stock-request-api";
import { dateTextToYmd } from "../../../../../../lib/util/common-date-util";
import { terminationReportSubmittedMeta } from "../../../../../../lib/object/value/termination-report-submitted";
import {
  convertToNumberFromResultsB,
  convertToResultsBFromNumber,
} from "../../../../../../lib/util/search-util";
import { ButtonSearchConditionUser } from "../../../../../model/search/button/ButtonSearchConditionUser/ButtonSearchConditionUser";
import { ButtonSearchConditionInstitution } from "../../../../../model/search/button/ButtonSearchConditionInstitution/ButtonSearchConditionInstitution";
import { ButtonSearchConditionCell } from "../../../../../model/search/button/ButtonSearchConditionCell/ButtonSearchConditionCell";
import { SearchConditionSidebar } from "../../../../../ui/search/SearchConditionSidebar/SearchConditionSidebar";
import { SearchConditionSidebarSection } from "../../../../../ui/search/SearchConditionSidebar/_components/SearchConditionSidebarSection/SearchConditionSidebarSection";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";

export type SearchConditionSidebarProjectProps = {
  searchArgs: SearchProjectApiArg;

  isFoundationMember: boolean;
  /**
   * 検索条件が変更されたタイミングで呼び出し元に値を返却する
   */
  onChange: (searchArgs: SearchProjectApiArg) => void;
} & ComponentStyleProps;

export const SearchConditionSidebarProject = ({
  searchArgs,
  isFoundationMember,

  onChange,

  sx,
  ...rest
}: SearchConditionSidebarProjectProps) => {
  const { t } = useAppTranslation();

  return (
    <SearchConditionSidebar sx={sx} {...rest}>
      <SearchConditionSidebarSection title={t("lbl.並び順・表示件数")}>
        {/*並び順*/}
        <ButtonSearchConditionRadio
          value={searchArgs.sortCondition}
          label={t("lbl.並び順")}
          valueObjectMeta={projectSortConditionMeta}
          onChange={(value) => {
            onChange({
              ...searchArgs,
              sortCondition: value,
            });
          }}
        />
        {/*表示件数*/}
        <ButtonSearchConditionRadio
          label={t("lbl.表示件数")}
          value={convertToResultsBFromNumber(searchArgs.limit)}
          valueObjectMeta={numberOfSearchResultsBMeta}
          onChange={(value: string | undefined) => {
            onChange({
              ...searchArgs,
              limit: convertToNumberFromResultsB(value),
            });
          }}
        />
      </SearchConditionSidebarSection>
      <SearchConditionSidebarSection title={t("lbl.案件")}>
        {/*案件管理番号*/}
        <ButtonSearchConditionText
          value={searchArgs.projectControlNumber}
          label={t("lbl.案件管理番号")}
          placeholder={""}
          onChange={(value: string | undefined) => {
            onChange({
              ...searchArgs,
              projectControlNumber: value,
            });
          }}
        />
        {/*課題名*/}
        <ButtonSearchConditionText
          value={searchArgs.projectName}
          label={t("lbl.課題名")}
          placeholder={""}
          onChange={(value: string | undefined) => {
            onChange({
              ...searchArgs,
              projectName: value,
            });
          }}
        />

        {/*案件状態*/}
        <ButtonSearchConditionCheckbox
          values={searchArgs.projectState}
          label={t("lbl.案件状態")}
          valueObjectMeta={projectStateMeta}
          onChange={(value) => {
            onChange({
              ...searchArgs,
              projectState: value,
            });
          }}
        />

        {/*新規申請提出日*/}
        <ButtonSearchConditionDateRange
          value={{
            start: dateTextToYmd(searchArgs.newApplicationSubmissionDateFrom),
            end: dateTextToYmd(searchArgs.newApplicationSubmissionDateTo),
          }}
          label={t("lbl.新規申請提出日")}
          onChange={(start: string | undefined, end: string | undefined) => {
            onChange({
              ...searchArgs,
              newApplicationSubmissionDateFrom: start,
              newApplicationSubmissionDateTo: end,
            });
          }}
        />

        {/*新規申請承認日*/}
        <ButtonSearchConditionDateRange
          value={{
            start: dateTextToYmd(searchArgs.newApplicationApprovalDateFrom),
            end: dateTextToYmd(searchArgs.newApplicationApprovalDateTo),
          }}
          label={t("lbl.新規申請承認日")}
          onChange={(start: string | undefined, end: string | undefined) => {
            onChange({
              ...searchArgs,
              newApplicationApprovalDateFrom: start,
              newApplicationApprovalDateTo: end,
            });
          }}
        />

        {/*次回年次報告期限日*/}
        <ButtonSearchConditionDateRange
          value={{
            start: dateTextToYmd(searchArgs.nextAnnualReportDeadlineDateFrom),
            end: dateTextToYmd(searchArgs.nextAnnualReportDeadlineDateTo),
          }}
          label={t("lbl.次回年次報告期限日")}
          onChange={(start: string | undefined, end: string | undefined) => {
            onChange({
              ...searchArgs,
              nextAnnualReportDeadlineDateFrom: start,
              nextAnnualReportDeadlineDateTo: end,
            });
          }}
        />

        {/*使用終了日*/}
        <ButtonSearchConditionDateRange
          value={{
            start: dateTextToYmd(searchArgs.usageEndDateFrom),
            end: dateTextToYmd(searchArgs.usageEndDateTo),
          }}
          label={t("lbl.使用終了日")}
          onChange={(start: string | undefined, end: string | undefined) => {
            onChange({
              ...searchArgs,
              usageEndDateFrom: start,
              usageEndDateTo: end,
            });
          }}
        />

        {/*使用終了報告提出済*/}
        <ButtonSearchConditionCheckbox
          values={searchArgs.terminationReportSubmitted}
          label={t("lbl.使用終了報告提出済")}
          valueObjectMeta={terminationReportSubmittedMeta}
          onChange={(value) => {
            onChange({
              ...searchArgs,
              terminationReportSubmitted: value,
            });
          }}
        />
      </SearchConditionSidebarSection>
      {isFoundationMember && (
        <SearchConditionSidebarSection title={t("lbl.代表機関")}>
          {/*機関名*/}
          <ButtonSearchConditionInstitution
            value={searchArgs.institutionId}
            label={t("lbl.機関名")}
            placeholder={""}
            onChange={(value: number | null) => {
              onChange({
                ...searchArgs,
                institutionId: value ?? undefined,
              });
            }}
          />

          {/*国内外区分*/}
          <ButtonSearchConditionCheckbox
            values={searchArgs.foreignType}
            label={t("lbl.国内外区分")}
            valueObjectMeta={foreignTypeMeta}
            onChange={(value) => {
              onChange({
                ...searchArgs,
                foreignType: value,
              });
            }}
          />

          {/*営利非営利区分*/}
          <ButtonSearchConditionCheckbox
            values={searchArgs.commercialType}
            label={t("lbl.営利非営利区分")}
            valueObjectMeta={commercialTypeMeta}
            onChange={(value) => {
              onChange({
                ...searchArgs,
                commercialType: value,
              });
            }}
          />

          {/*研究責任者名*/}
          <ButtonSearchConditionUser
            value={searchArgs.principalInvestigatorAppUserId}
            label={t("lbl.研究責任者名")}
            placeholder={""}
            searchResultNone={t("mes.該当ユーザーなしメッセージ")}
            onChange={(value: number | null) => {
              onChange({
                ...searchArgs,
                principalInvestigatorAppUserId: value ?? undefined,
              });
            }}
          />
        </SearchConditionSidebarSection>
      )}
      <SearchConditionSidebarSection title={t("lbl.分担機関")}>
        {/*分担機関名*/}
        <ButtonSearchConditionText
          value={searchArgs.collaborativePartnerName}
          label={t("lbl.分担機関名")}
          onChange={(value: string | undefined) => {
            onChange({
              ...searchArgs,
              collaborativePartnerName: value,
            });
          }}
        />
        {/*分担機関国内外区分*/}
        <ButtonSearchConditionCheckbox
          values={searchArgs.collaborativePartnerForeignType}
          label={t("lbl.分担機関国内外区分")}
          valueObjectMeta={foreignTypeMeta}
          onChange={(value) => {
            onChange({
              ...searchArgs,
              collaborativePartnerForeignType: value,
            });
          }}
        />
        {/*分担機関営利非営利区分*/}
        <ButtonSearchConditionCheckbox
          values={searchArgs.collaborativePartnerCommercialType}
          label={t("lbl.分担機関営利非営利区分")}
          valueObjectMeta={commercialTypeMeta}
          onChange={(value) => {
            onChange({
              ...searchArgs,
              collaborativePartnerCommercialType: value,
            });
          }}
        />
      </SearchConditionSidebarSection>
      <SearchConditionSidebarSection title={t("lbl.細胞株")}>
        {/*使用する細胞*/}
        <ButtonSearchConditionCell
          value={searchArgs.requestingCellsCellId}
          label={t("lbl.使用する細胞")}
          onChange={(value: number | undefined) => {
            onChange({
              ...searchArgs,
              requestingCellsCellId: value,
            });
          }}
        />

        {/*発送する細胞*/}
        <ButtonSearchConditionCell
          value={searchArgs.shippingCellsCellId}
          label={t("lbl.発送する細胞")}
          onChange={(value: number | undefined) => {
            onChange({
              ...searchArgs,
              shippingCellsCellId: value,
            });
          }}
        />

        {/*細胞発送日*/}
        <ButtonSearchConditionDateRange
          value={{
            start: dateTextToYmd(searchArgs.shippingCellsDateFrom),
            end: dateTextToYmd(searchArgs.shippingCellsDateTo),
          }}
          label={t("lbl.細胞発送日")}
          onChange={(start: string | undefined, end: string | undefined) => {
            onChange({
              ...searchArgs,
              shippingCellsDateFrom: start,
              shippingCellsDateTo: end,
            });
          }}
        />
      </SearchConditionSidebarSection>
    </SearchConditionSidebar>
  );
};
