import { Center, Divider, Heading, Text, VStack } from "@chakra-ui/react";
import { WarningTwoIcon } from "@chakra-ui/icons";

export const ServiceTemporarilyUnavailablePage = () => {
  return (
    <Center w={"100vw"} h={"100vh"}>
      <VStack align={"start"}>
        <Heading>
          <WarningTwoIcon color={"red.400"} /> 503 Service Temporarily
          Unavailable.
        </Heading>
        <Divider />
        <Text>
          システムメンテナンス中または、サーバーにアクセスが集中しているため一時的にサービスが利用できません。
          <br />
          しばらく時間をおいてから再度お試しください。
        </Text>
        <Text>
          The service is temporarily unavailable due to system maintenance or
          server overload.
          <br />
          Please try again later.
        </Text>
      </VStack>
    </Center>
  );
};
