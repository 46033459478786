import { Center, Divider, Heading, Text, VStack } from "@chakra-ui/react";
import { WarningTwoIcon } from "@chakra-ui/icons";

export const NotFoundPage = () => {
  return (
    <Center w={"100vw"} h={"100vh"}>
      <VStack align={"start"}>
        <Heading>
          <WarningTwoIcon color={"red.400"} /> 404 Not Found.
        </Heading>
        <Divider />
        <Text>指定されたページが見つかりません。</Text>
        <Text>The page you are looking for could not be found.</Text>
      </VStack>
    </Center>
  );
};
