import { ComponentStyleProps } from "../../../../../../../lib/styles/props/component-style-props";
import { Center, Flex, Text } from "@chakra-ui/react";
import { useAppListSharedAttachmentFileQuery } from "../../../../../../../hooks/query/use-app-list-shared-attachment-file-query";
import { SharedAttachmentFilesTableRow } from "./SharedAttachmentFilesTableRow";
import { isNullish } from "../../../../../../../lib/util/common-util";
import { SharedAttachmentFilesSectionSortCondition } from "./SharedAttachmentFilesSectionSortConditionSelect";
import { useMemo } from "react";
import { orderBy } from "lodash";
import { SharedAttachmentFileSaved } from "../../../../../../../lib/object/entity/shared-attachment-file";
import { useAppTranslation } from "../../../../../../../hooks/use-app-translation";

export type SharedAttachmentFilesTableProps = {
  sortCondition: SharedAttachmentFilesSectionSortCondition;
} & ComponentStyleProps;

export const SharedAttachmentFilesTable = ({
  sortCondition,

  sx,
  ...rest
}: SharedAttachmentFilesTableProps) => {
  const { t } = useAppTranslation();

  const { data: files } = useAppListSharedAttachmentFileQuery();
  const sortedFiles: SharedAttachmentFileSaved[] = useMemo(() => {
    if (isNullish(files)) return [];
    switch (sortCondition) {
      case "uploaded_at_asc":
        return orderBy(
          files,
          [(v) => v.attachmentFileInformation.uploadedAt, (v) => v.id],
          ["asc", "asc"],
        );
      case "uploaded_at_desc":
        return orderBy(
          files,
          [(v) => v.attachmentFileInformation.uploadedAt, (v) => v.id],
          ["desc", "asc"],
        );
      case "name_asc":
        return orderBy(
          files,
          [(v) => v.attachmentFileInformation.attachmentFileName, (v) => v.id],
          ["asc", "asc"],
        );
      case "name_desc":
        return orderBy(
          files,
          [(v) => v.attachmentFileInformation.attachmentFileName, (v) => v.id],
          ["desc", "asc"],
        );
    }
  }, [files, sortCondition]);

  return (
    <Flex
      direction={"column"}
      minW={"760px"}
      minH={"60px"}
      borderWidth={"1px"}
      borderColor={"gray.400"}
      borderRadius={"4px"}
      alignItems={"stretch"}
      justifyContent={"center"}
      overflow={"hidden"}
      sx={sx}
      {...rest}
    >
      {sortedFiles.length > 0 ? (
        sortedFiles.map((f, idx) => (
          <SharedAttachmentFilesTableRow
            key={f.id}
            file={f}
            styleType={(idx % 2) as 0 | 1}
          />
        ))
      ) : (
        <Center flex={1}>
          <Text color={"gray.700"}>
            {t("mes.共有添付資料ファイルがありません")}
          </Text>
        </Center>
      )}
    </Flex>
  );
};
