import { ComponentStyleProps } from "../../../../../../../../../lib/styles/props/component-style-props";
import { Text, VStack } from "@chakra-ui/react";
import React, { memo, useCallback } from "react";
import { CMFormInputTextArea } from "@pscsrvlab/psc-react-components";
import { useAppTranslation } from "../../../../../../../../../hooks/use-app-translation";
import { ChangeReasonItemViewModel } from "../../../../../../../../../lib/object/vm/change-application-view-model";
import { changeReasonContentMeta } from "../../../../../../../../../lib/object/value/change-reason-content";

export type ChangeReasonFormProps = {
  value?: string;
  onChange?: (
    change: (before: ChangeReasonItemViewModel) => ChangeReasonItemViewModel,
  ) => void;
  editMode: "editable" | "readOnly";
} & ComponentStyleProps;

export const ChangeReasonForm = memo(function ChangeReasonForm({
  value,
  onChange,
  editMode,

  sx,
  ...rest
}: ChangeReasonFormProps) {
  const { t } = useAppTranslation();

  const handleChange = useCallback(
    (v: string) => {
      onChange?.((before) => ({
        ...before,
        reason: v,
      }));
    },
    [onChange],
  );

  return (
    <VStack alignItems={"flex-start"} sx={sx} {...rest}>
      <Text fontSize={"md"} fontWeight={"bold"} color={"gray.900"}>
        {t("lbl.変更理由")}
      </Text>
      <CMFormInputTextArea
        editMode={editMode}
        noHeader={true}
        valueObjectMeta={changeReasonContentMeta}
        value={value}
        onChange={handleChange}
      />
    </VStack>
  );
});
