import React from "react";
import { ComponentStyleProps } from "../../../../lib/styles/props/component-style-props";
import { Box } from "@chakra-ui/react";
import { UserDetail } from "../../../model/userDetail/UserDetail/UserDetail";

export type FN81S02UserDetailsProps = {
  /**
   * 新規登録モード
   */
  createMode?: boolean;
} & ComponentStyleProps;

/**
 * FN81-S02ユーザー詳細
 */
export const FN81S02UserDetails = ({
  createMode = true,
}: FN81S02UserDetailsProps) => {
  return (
    <Box overflowY={"auto"}>
      <UserDetail createMode={createMode} />
    </Box>
  );
};
