import {
  messageId,
  PropertyMeta,
  ValueObjectMetaString,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.string().max(10000);
const savedMinimalSchema = z.string().max(10000);
const schema = z.string().max(10000);
const savedSchema = z.string().max(10000);
const jsonSchema = z.string();
/**
 * 契約担当(値オブジェクト)のメタデータ。
 *
 *  */
export const contractPersonMeta = new ValueObjectMetaString(
  "ContractPerson",
  "\u5951\u7D04\u62C5\u5F53",
  messageId("lbl.\u5951\u7D04\u62C5\u5F53"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
