import { FC, ReactNode, createContext, useCallback, useContext, useState } from "react";
import { useAppTranslation } from "../../../../hooks/use-app-translation";
import { ComponentStyleProps } from "../../../../lib/styles/props/component-style-props";
import { CMModalOptionsTwoAndCancel } from "@pscsrvlab/psc-react-components";
import useFileDownload from "../../../../hooks/use-file-download";
import { useDisclosure } from "@chakra-ui/react";

export type FileModalProps = {
    isOpen: boolean;
    onCloseModal: () => void,
    fileId?: number | string,
    fileName: string,
} & ComponentStyleProps

export const FileModal: FC<FileModalProps> = ({
    isOpen,
    onCloseModal,
    fileId,
    fileName,
    sx,
    ...rest
}: FileModalProps) => {
    const { t } = useAppTranslation();

    const { fileDownload, getPreviewLink } = useFileDownload()

    // ダウンロードボタン押下時のイベント
    const handleDownload = useCallback(async () => {
        if (fileId !== undefined) {
            await fileDownload(fileId, fileName)
        }
    }, [fileId, fileName, fileDownload])

    // プレビューボタン押下時のイベント
    const handlePreview = useCallback(async () => {
        if (fileId !== undefined) {
            await getPreviewLink(fileId, fileName)
        }
    }, [fileId, fileName, getPreviewLink])

    return <CMModalOptionsTwoAndCancel
        isOpen={isOpen}
        labelHeader={t("lbl.ファイルポップアップタイトル")}
        labelBody={fileName}
        labelSubmitButton={t("btn.ダウンロードボタン")}
        onSubmit={handleDownload}
        labelCloseButton={t("btn.プレビューボタン")}
        onClose={handlePreview}
        labelCancelButton={t("btn.キャンセルボタン")}
        onCancel={onCloseModal}
        sx={sx}
        {...rest}
    />
}
export default FileModal

export type FileModalContextType = {
    isOpen: boolean;
    fileId?: number | string,
    fileName: string,
    openFileModal: (fileId: number | string, fileName: string) => void
    closeFileModal: () => void
};

const defaultValue: FileModalContextType = {
    isOpen: false,
    fileId: undefined,
    fileName: "",
    openFileModal: (_fileId: number | string, _fileName: string) => { },
    closeFileModal: () => { }
};

const FileModalContext = createContext<FileModalContextType>(defaultValue);

export const FileModalProvider = (props: { children: ReactNode }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [fileId, setFileId] = useState<number | string>()
    const [fileName, setFileName] = useState("")

    // ファイルモーダルを表示する
    const openFileModal = (fileId: number | string, fileName: string) => {
        setFileId(fileId)
        setFileName(fileName)
        onOpen()
    }

    // ファイルモーダルを閉じる
    const closeFileModal = () => {
        setFileId(undefined)
        setFileName("")
        onClose()
    }

    return (
        <FileModalContext.Provider
            value={{
                isOpen: isOpen,
                fileId: fileId,
                fileName: fileName,
                openFileModal: openFileModal,
                closeFileModal: closeFileModal
            }}
        >
            <FileModal isOpen={isOpen} onCloseModal={closeFileModal} fileId={fileId} fileName={fileName} sx={{ maxW: "500px" }} />
            {props.children}
        </FileModalContext.Provider>
    )
};

export const useFileModalContext = (): FileModalContextType =>
    useContext(FileModalContext);