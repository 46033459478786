import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * 質疑応答並び順(値オブジェクト)
 *
 *
 *
 * updated_at_asc:
 * updated_at_desc:  */
export type InquirySortCondition = z.infer<
  typeof inquirySortConditionMeta.schema
>;
/**
 * 質疑応答並び順(値オブジェクト)のメタデータ。
 *
 *  */
export const inquirySortConditionMeta = new ValueObjectMetaEnum<
  ["updated_at_asc", "updated_at_desc"]
>(
  "InquirySortCondition",
  "\u8CEA\u7591\u5FDC\u7B54\u4E26\u3073\u9806",
  messageId("lbl.\u8CEA\u7591\u5FDC\u7B54\u4E26\u3073\u9806"),
  ["updated_at_asc", "updated_at_desc"],
  {
    updated_at_asc:
      "code.\u4E26\u3073\u9806.\u66F4\u65B0\u65E5\u6642\u6607\u9806",
    updated_at_desc:
      "code.\u4E26\u3073\u9806.\u66F4\u65B0\u65E5\u6642\u964D\u9806",
  }
);
