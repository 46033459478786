import { useCallback } from "react";
import { hasValue} from "../lib/util/common-util";
import { Comments } from "@pscsrvlab/psc-react-components";

/**
 * 履歴参照画面で用いるprops(commentsList)を各セクションごとにフィルタリングする
 * @returns 
 * filterCommetsListByEndWith: commentsListをpathの後方一致でフィルタリングする関数
 * filterCommetsListByStartsWith: commentsListをpathの前方一致でフィルタリングする関数
 * filterCommetsListByInclude: commentsListを引数のpathが含まれるかどうかでフィルタリングする関数
 */
export function useLinkCommentPath() {

  /**
   * @param
   * commentsList: 修正依頼コメント・返信履歴の配列
   * path: 各セクションのパス
   */
  const filterCommetsListByEndWith = useCallback(
    (commentsList?: Comments[], path?: string) => {
      return commentsList?.filter(comments => hasValue(comments.path) ? path?.endsWith(comments.path) : false);
    },
    [],
  );

  /**
   * @param
   * commentsList: 修正依頼コメント・返信履歴の配列
   * path: 各セクションのパス
   */
  const filterCommetsListByStartsWith = useCallback(
    (commentsList?: Comments[], path?: string) => {
      return commentsList?.filter(comments => hasValue(path) ? comments.path?.startsWith(path) : false)
    },
    [],
  )

  /**
   * @param
   * commentsList: 修正依頼コメント・返信履歴の配列
   * path: フィルタリングしたいパス
   */
  const filterCommetsListByInclude = useCallback(
    (commentsList?: Comments[], path?: string) => {
      return commentsList?.filter(comments => hasValue(path) ? comments.path?.includes(path) : false)
    },
    [],
  )

return {filterCommetsListByEndWith,filterCommetsListByStartsWith,filterCommetsListByInclude}
}

export default useLinkCommentPath;
