import {
  messageId,
  PropertyMeta,
  EntityMeta,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { mailLogIdMeta } from "../value/mail-log-id";
import { institutionIdMeta } from "../value/institution-id";
import { projectIdMeta } from "../value/project-id";
import { documentIdMeta } from "../value/document-id";
import { operationTypeMeta } from "../value/operation-type";
import { appUserIdMeta } from "../value/app-user-id";
import { mailAddressMeta } from "../value/mail-address";
import { roleMeta } from "../value/role";
import { mailSubjectMeta } from "../value/mail-subject";
import { mailContentMeta } from "../value/mail-content";
import { datetimeMeta } from "../value/datetime";
/**
 * メールログ(エンティティ)
 *
 *  */
export type MailLog = z.infer<typeof schema>;
export type MailLogSaved = z.infer<typeof savedSchema>;
const minimalSchema = z.object({
  id: mailLogIdMeta.minimalSchema.optional(),
  institutionId: institutionIdMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  projectId: projectIdMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  documentId: documentIdMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  operation: operationTypeMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  appUserId: appUserIdMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  mailAddress: mailAddressMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  recipientRole: roleMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  subject: mailSubjectMeta.minimalSchema,
  content: mailContentMeta.minimalSchema,
  sentAt: datetimeMeta.minimalSchema.nullish().transform((x) => x ?? undefined),
  createdAt: datetimeMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  updatedAt: datetimeMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
const savedMinimalSchema = z.object({
  id: mailLogIdMeta.minimalSchema,
  institutionId: institutionIdMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  projectId: projectIdMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  documentId: documentIdMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  operation: operationTypeMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  appUserId: appUserIdMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  mailAddress: mailAddressMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  recipientRole: roleMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  subject: mailSubjectMeta.savedMinimalSchema,
  content: mailContentMeta.savedMinimalSchema,
  sentAt: datetimeMeta.savedMinimalSchema,
  createdAt: datetimeMeta.savedMinimalSchema,
  updatedAt: datetimeMeta.savedMinimalSchema,
});
const schema = z.object({
  id: mailLogIdMeta.minimalSchema.optional(),
  institutionId: institutionIdMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
  projectId: projectIdMeta.schema.nullish().transform((x) => x ?? undefined),
  documentId: documentIdMeta.schema.nullish().transform((x) => x ?? undefined),
  operation: operationTypeMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
  appUserId: appUserIdMeta.schema.nullish().transform((x) => x ?? undefined),
  mailAddress: mailAddressMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
  recipientRole: roleMeta.schema.nullish().transform((x) => x ?? undefined),
  subject: mailSubjectMeta.schema,
  content: mailContentMeta.schema,
  sentAt: datetimeMeta.schema.nullish().transform((x) => x ?? undefined),
  createdAt: datetimeMeta.schema.nullish().transform((x) => x ?? undefined),
  updatedAt: datetimeMeta.schema.nullish().transform((x) => x ?? undefined),
});
const savedSchema = z.object({
  id: mailLogIdMeta.minimalSchema,
  institutionId: institutionIdMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  projectId: projectIdMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  documentId: documentIdMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  operation: operationTypeMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  appUserId: appUserIdMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  mailAddress: mailAddressMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  recipientRole: roleMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  subject: mailSubjectMeta.savedSchema,
  content: mailContentMeta.savedSchema,
  sentAt: datetimeMeta.savedSchema,
  createdAt: datetimeMeta.savedSchema,
  updatedAt: datetimeMeta.savedSchema,
});
const jsonSchema = z.object({
  id: mailLogIdMeta.jsonSchema.optional(),
  institutionId: institutionIdMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  projectId: projectIdMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  documentId: documentIdMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  operation: operationTypeMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  appUserId: appUserIdMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  mailAddress: mailAddressMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  recipientRole: roleMeta.jsonSchema.nullish().transform((x) => x ?? undefined),
  subject: mailSubjectMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  content: mailContentMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  sentAt: datetimeMeta.jsonSchema.nullish().transform((x) => x ?? undefined),
  createdAt: datetimeMeta.jsonSchema.nullish().transform((x) => x ?? undefined),
  updatedAt: datetimeMeta.jsonSchema.nullish().transform((x) => x ?? undefined),
});
/**
 * メールログ(エンティティ)のメタデータ。
 *
 *  */
export const mailLogMeta = new EntityMeta<
  typeof schema,
  typeof savedSchema,
  typeof jsonSchema
>(
  "MailLog",
  "\u30E1\u30FC\u30EB\u30ED\u30B0",
  messageId("lbl.\u30E1\u30FC\u30EB\u30ED\u30B0"),
  minimalSchema,
  savedMinimalSchema,
  schema,
  savedSchema,
  jsonSchema,
  undefined,
  undefined,
  {
    id: new PropertyMeta(
      "id",
      "ID",
      messageId("lbl.ID"),
      "afterSave",
      false,
      mailLogIdMeta
    ),
    institutionId: new PropertyMeta(
      "institutionId",
      "\u6A5F\u95A2ID",
      messageId("lbl.\u6A5F\u95A2ID"),
      "none",
      false,
      institutionIdMeta
    ),
    projectId: new PropertyMeta(
      "projectId",
      "\u6848\u4EF6ID",
      messageId("lbl.\u6848\u4EF6ID"),
      "none",
      false,
      projectIdMeta
    ),
    documentId: new PropertyMeta(
      "documentId",
      "\u66F8\u985EID",
      messageId("lbl.\u66F8\u985EID"),
      "none",
      false,
      documentIdMeta
    ),
    operation: new PropertyMeta(
      "operation",
      "\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3",
      messageId("lbl.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3"),
      "none",
      false,
      operationTypeMeta
    ),
    appUserId: new PropertyMeta(
      "appUserId",
      "\u30E6\u30FC\u30B6\u30FCID",
      messageId("lbl.\u30E6\u30FC\u30B6\u30FCID"),
      "none",
      false,
      appUserIdMeta
    ),
    mailAddress: new PropertyMeta(
      "mailAddress",
      "\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9",
      messageId("lbl.\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9"),
      "none",
      false,
      mailAddressMeta
    ),
    recipientRole: new PropertyMeta(
      "recipientRole",
      "\u30E1\u30FC\u30EB\u9001\u4FE1\u5148\u30ED\u30FC\u30EB",
      messageId("lbl.\u30E1\u30FC\u30EB\u9001\u4FE1\u5148\u30ED\u30FC\u30EB"),
      "none",
      false,
      roleMeta
    ),
    subject: new PropertyMeta(
      "subject",
      "\u30E1\u30FC\u30EB\u4EF6\u540D",
      messageId("lbl.\u30E1\u30FC\u30EB\u4EF6\u540D"),
      "always",
      false,
      mailSubjectMeta
    ),
    content: new PropertyMeta(
      "content",
      "\u30E1\u30FC\u30EB\u672C\u6587",
      messageId("lbl.\u30E1\u30FC\u30EB\u672C\u6587"),
      "always",
      false,
      mailContentMeta
    ),
    sentAt: new PropertyMeta(
      "sentAt",
      "\u9001\u4FE1\u65E5\u6642",
      messageId("lbl.\u9001\u4FE1\u65E5\u6642"),
      "afterSave",
      false,
      datetimeMeta
    ),
    createdAt: new PropertyMeta(
      "createdAt",
      "\u4F5C\u6210\u65E5\u6642",
      messageId("lbl.\u4F5C\u6210\u65E5\u6642"),
      "afterSave",
      false,
      datetimeMeta
    ),
    updatedAt: new PropertyMeta(
      "updatedAt",
      "\u66F4\u65B0\u65E5\u6642",
      messageId("lbl.\u66F4\u65B0\u65E5\u6642"),
      "afterSave",
      false,
      datetimeMeta
    ),
  }
);
