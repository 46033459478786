import { ComponentStyleProps } from "../../../../lib/styles/props/component-style-props";
import { Link } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import React, { ReactNode } from "react";

export type AppLinkProps = {
  to: string;
  isExternal?: boolean;

  children?: ReactNode;
} & ComponentStyleProps;

export const AppLink = ({
  to,
  isExternal,

  children,

  sx,
  ...rest
}: AppLinkProps) => {
  return (
    <Link
      isExternal={isExternal}
      as={RouterLink}
      to={to}
      color={"blue.600"}
      sx={sx}
      {...rest}
    >
      {children}
    </Link>
  );
};
