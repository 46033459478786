import {
  messageId,
  PropertyMeta,
  ValueObjectMetaComposite,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { handlingAfterTerminationCustomValidation } from "../custom-validation/handling-after-termination";
import { planComplianceTypeMeta } from "../value/plan-compliance-type";
import { reasonsForNotBeingAbleToComplyMeta } from "../value/reasons-for-not-being-able-to-comply";
/**
 * 使用終了後の取り扱い（使用終了報告）(値オブジェクト)
 *
 *  */
export type HandlingAfterTermination = z.infer<typeof schema>;
export type HandlingAfterTerminationSaved = z.infer<typeof savedSchema>;
const minimalSchema = z.object({
  planComplianceType: planComplianceTypeMeta.minimalSchema,
  reasonsForNotBeingAbleToComply:
    reasonsForNotBeingAbleToComplyMeta.minimalSchema
      .nullish()
      .transform((x) => x ?? undefined),
});
const savedMinimalSchema = z.object({
  planComplianceType: planComplianceTypeMeta.savedMinimalSchema,
  reasonsForNotBeingAbleToComply:
    reasonsForNotBeingAbleToComplyMeta.savedMinimalSchema
      .nullish()
      .transform((x) => x ?? undefined),
});
const schema = z.object({
  planComplianceType: planComplianceTypeMeta.schema,
  reasonsForNotBeingAbleToComply: reasonsForNotBeingAbleToComplyMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
});
const savedSchema = z.object({
  planComplianceType: planComplianceTypeMeta.savedSchema,
  reasonsForNotBeingAbleToComply: reasonsForNotBeingAbleToComplyMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
const jsonSchema = z.object({
  planComplianceType: planComplianceTypeMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  reasonsForNotBeingAbleToComply: reasonsForNotBeingAbleToComplyMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
/**
 * 使用終了後の取り扱い（使用終了報告）(値オブジェクト)のメタデータ。
 *
 *
 *
 * 以下のカスタムバリデーションを、 handlingAfterTerminationCustomValidation にて行う。
 * - (任意) 不順守の場合に遵守できなかった理由を必須にする。 (エラーメッセージID=mes.使用終了使用終了後の取り扱い不順守理由未入力エラー) */
export const handlingAfterTerminationMeta = new ValueObjectMetaComposite(
  "HandlingAfterTermination",
  "\u4F7F\u7528\u7D42\u4E86\u5F8C\u306E\u53D6\u308A\u6271\u3044\uFF08\u4F7F\u7528\u7D42\u4E86\u5831\u544A\uFF09",
  messageId(
    "lbl.\u4F7F\u7528\u7D42\u4E86\u5F8C\u306E\u53D6\u308A\u6271\u3044\uFF08\u4F7F\u7528\u7D42\u4E86\u5831\u544A\uFF09"
  ),
  minimalSchema,
  savedMinimalSchema,
  schema,
  savedSchema,
  jsonSchema,
  undefined,
  handlingAfterTerminationCustomValidation,
  {
    planComplianceType: new PropertyMeta(
      "planComplianceType",
      "\u8A08\u753B\u66F8\u9075\u5B88\u533A\u5206",
      messageId("lbl.\u8A08\u753B\u66F8\u9075\u5B88\u533A\u5206"),
      "always",
      false,
      planComplianceTypeMeta
    ),
    reasonsForNotBeingAbleToComply: new PropertyMeta(
      "reasonsForNotBeingAbleToComply",
      "\u9075\u5B88\u3067\u304D\u306A\u304B\u3063\u305F\u7406\u7531",
      messageId(
        "lbl.\u9075\u5B88\u3067\u304D\u306A\u304B\u3063\u305F\u7406\u7531"
      ),
      "none",
      false,
      reasonsForNotBeingAbleToComplyMeta
    ),
  }
);
