import { LoginUserInfo } from "../../../../../../../store/auth/types";
import { useMemo } from "react";
import { RevisionRequestItemViewModel } from "../../../../../../../lib/object/vm/revision-request-view-model";
import { AnnualReportContentViewModel } from "../../../../../../../lib/object/vm/annual-report-view-model";
import {
  getCommentProps,
  useRevisionRequestComment,
} from "../../../../../../../hooks/revision-request/use-revision-request-comment";

export function useAnnualReportDocumentCommentProps(
  loginUserInfo: LoginUserInfo,
  value: AnnualReportContentViewModel,

  revisionMode:
    | "none"
    | "readOnly"
    | "office_member_editable"
    | "applicant_editable",
  revisionRequestItems?: RevisionRequestItemViewModel[],
  /**
   * 修正依頼コメントを選択したときのコールバック。
   * 未選択状態になる場合はnullを送出する。
   */
  onSelectComment?: (path: string | null) => void,
  /**
   * 修正依頼の変更。
   */
  onChangeRevisionRequestItems?: (
    requestsChange: (
      before: RevisionRequestItemViewModel[],
    ) => RevisionRequestItemViewModel[],
  ) => void,
) {
  const { ctx } = useRevisionRequestComment(
    loginUserInfo,
    revisionMode,
    revisionRequestItems,
    onSelectComment,
    onChangeRevisionRequestItems,
  );

  // [注意] 修正依頼コメントのパスは、データ構造のJSONポインタと1:1対応させることは不可能なので、修正依頼特有のパスとする。
  //   単純に、修正依頼の配置場所の識別子だと考えれば良い。
  const projectName = useMemo(
    () => getCommentProps(ctx, "/00:projectName"),
    [ctx],
  );
  const institution = useMemo(
    () => getCommentProps(ctx, "/01:institution"),
    [ctx],
  );
  const principalInvestigator = useMemo(
    () => getCommentProps(ctx, "/02:principalInvestigator"),
    [ctx],
  );
  const reportPeriod = useMemo(
    () => getCommentProps(ctx, "/03:reportPeriod"),
    [ctx],
  );
  // 「使用する細胞」セクション全体のコメントのパスは、 "/04:usedCells" とする。
  const usedCells = useMemo(() => getCommentProps(ctx, "/04:usedCells"), [ctx]);
  const usedResearchCellsN = useMemo(
    () =>
      value.usedResearchCells.map((_v, idx) => {
        const paddedIdx = padIndex(idx);
        return getCommentProps(ctx, `/05:usedResearchCells/${paddedIdx}`);
      }),
    [ctx, value],
  );
  const usedClinicalCellsN = useMemo(
    () =>
      value.usedClinicalCells.map((_v, idx) => {
        const paddedIdx = padIndex(idx);
        return getCommentProps(ctx, `/06:usedClinicalCells/${paddedIdx}`);
      }),
    [ctx, value],
  );
  const cellProvisionType = useMemo(
    () => getCommentProps(ctx, "/07:cellProvisionType"),
    [ctx],
  );
  const cellProvisions = useMemo(
    () => getCommentProps(ctx, "/08:cellProvisions"),
    [ctx],
  );
  const cellProvisionsN = useMemo(
    () =>
      value.cellProvisions.map((_v, idx) => {
        const paddedIdx = padIndex(idx);
        return getCommentProps(ctx, `/09:cellProvisions/${paddedIdx}`);
      }),
    [ctx, value],
  );
  const partners = useMemo(() => getCommentProps(ctx, "/10:partners"), [ctx]);
  const partnersN = useMemo(
    () =>
      value.partners.map((_v, idx) => {
        const paddedIdx = padIndex(idx);
        return {
          root: getCommentProps(ctx, `/10:partners/${paddedIdx}`),
          cellProvisionsN:
            _v.cellProvisions?.map((_cellProvision, cellProvisionIdx) =>
              getCommentProps(
                ctx,
                `/10:partners/${paddedIdx}/cellProvisions/${cellProvisionIdx}`,
              ),
            ) ?? [],
        };
      }),
    [ctx, value],
  );
  const progressResultOutline = useMemo(
    () => getCommentProps(ctx, "/11:progressResultOutline"),
    [ctx],
  );
  const attachmentFiles = useMemo(
    () => getCommentProps(ctx, "/12:attachmentFiles"),
    [ctx],
  );

  return useMemo(() => {
    return {
      projectName,
      institution,
      principalInvestigator,
      reportPeriod,
      usedCells,
      usedResearchCellsN,
      usedClinicalCellsN,
      cellProvisionType,
      cellProvisions,
      cellProvisionsN,
      partners,
      partnersN,
      progressResultOutline,
      attachmentFiles,
    };
  }, [
    projectName,
    institution,
    principalInvestigator,
    reportPeriod,
    usedCells,
    usedResearchCellsN,
    usedClinicalCellsN,
    cellProvisionType,
    cellProvisions,
    cellProvisionsN,
    partners,
    partnersN,
    progressResultOutline,
    attachmentFiles,
  ]);
}

function padIndex(idx: number): string {
  return idx.toString(10).padStart(2, "0");
}
