import { useNavigate } from "react-router-dom";
import { Box, VStack } from "@chakra-ui/react";
import { FrameUpperRightButton } from "../../../ui/frame/FrameUpperRightButton/FrameUpperRightButton";
import { CMButton, CMMessageInfo } from "@pscsrvlab/psc-react-components";
import { TitleText } from "../../../ui/text/TitleText/TitleText";
import { WeakTextItem } from "../../../ui/text/WeakTextItem/WeakTextItem";
import { VerticalTableFrame } from "../../../ui/table/VerticalTableFrame/VerticalTableFrame";
import { VerticalTableRowText } from "../../../ui/table/VerticalTableRowText/VerticalTableRowText";
import React, { useEffect, useMemo } from "react";
import {
  getMessageFromEnumValue,
  hasValue,
} from "../../../../lib/util/common-util";
import { LoginUserInfo } from "../../../../store/auth/types";
import { useAppGetInstitutionQuery } from "../../../../hooks/query/use-app-get-institution-query";
import { useAppTranslation } from "../../../../hooks/use-app-translation";
import { languageMeta } from "../../../../lib/object/value/language";

export type ProfilePageProps = {
  currentUserInfo: LoginUserInfo;
};

/**
 * プロフィールページ
 */
export const ProfilePage = ({ currentUserInfo }: ProfilePageProps) => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();

  const role = useMemo(() => currentUserInfo.role, [currentUserInfo]);

  // 機関情報を取得
  const { data: institutionInfo, refetch: refetchInstitution } =
    useAppGetInstitutionQuery({
      institutionId: currentUserInfo.institutionId,
    });

  // 機関情報を再フェッチ
  useEffect(() => {
    refetchInstitution();
  }, [refetchInstitution]);

  // 言語のメタデータ
  const language = useMemo(() => {
    return getMessageFromEnumValue(t, languageMeta, currentUserInfo.language);
  }, [currentUserInfo.language, t]);

  // メッセージ情報表示の判定用
  const shouldDisplayMessage = useMemo(() => {
    return (
      role === "applicant" ||
      role === "committee_member" ||
      role === "executive_director"
    );
  }, [role]);

  return (
    <Box h={"100%"} overflowY={"auto"}>
      {role === "applicant" && (
        <FrameUpperRightButton>
          <CMButton
            size={"sm"}
            label={t("btn.パスワードを変更するボタン")}
            onClick={() => navigate("/change-password")}
          />
        </FrameUpperRightButton>
      )}

      <VStack>
        <VStack
          spacing={"unset"}
          maxW={"540px"}
          alignItems={"flex-start"}
          mb={"68px"}
        >
          {shouldDisplayMessage && (
            <CMMessageInfo
              label={t("gdc.プロフィール情報編集インフォメーション")}
              sx={{ mt: "36px" }}
            />
          )}

          <Box pt={"20px"} pb={"7px"}>
            <TitleText title={currentUserInfo.fullName} />
            <WeakTextItem
              label={t("lbl.ユーザーID")}
              value={
                hasValue(currentUserInfo.displayUserId)
                  ? currentUserInfo.displayUserId
                  : undefined
              }
            />
          </Box>

          <VerticalTableFrame>
            <VerticalTableRowText
              label={t("lbl.氏名")}
              value={currentUserInfo.fullName}
            />
            <VerticalTableRowText
              label={t("lbl.氏名かな")}
              value={currentUserInfo.fullNameKana}
            />
            <VerticalTableRowText
              label={t("lbl.機関名")}
              value={institutionInfo?.name}
            />
            <VerticalTableRowText
              label={t("lbl.所属・職名")}
              value={currentUserInfo.titleAndPosition}
            />
            <VerticalTableRowText
              label={t("lbl.電話番号")}
              value={currentUserInfo.phoneNumber}
            />
            <VerticalTableRowText
              label={t("lbl.メールアドレス")}
              value={currentUserInfo.mailAddress}
            />
            <VerticalTableRowText
              label={t("lbl.言語")}
              value={language ?? undefined}
            />
          </VerticalTableFrame>
        </VStack>
      </VStack>
    </Box>
  );
};
