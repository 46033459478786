import { ProjectForceUpdatePage } from "../../../model/project/FN30S14ProjectForceUpdate/_components/ProjectForceUpdatePage/ProjectForceUpdatePage";
import { useEffect, useMemo, useState } from "react";
import { hasValue, isNullish } from "../../../../lib/util/common-util";
import { ApplicationContentViewModel } from "../../../model/document/application/_components/ApplicationDocument/ApplicationDocument";
import { applicationContentMeta } from "../../../../lib/object/value/application-content";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import { selectUserInfo } from "../../../../store/auth/slice";
import { useAppGetProjectQuery } from "../../../../hooks/query/use-app-get-project-query";
import { useAppParams } from "../../../../hooks/use-app-params";

export const FN30S14ProjectForceUpdate = () => {
  const [viewModel, setViewModel] =
    useState<ApplicationContentViewModel | null>(null);
  const loginUserInfo = useAppSelector(selectUserInfo);

  /**
   * urlにprojectIdがあれば取得する。
   */
  const { projectId } = useAppParams();

  /**
   * 案件情報取得。
   */
  const { data: project } = useAppGetProjectQuery(
    {
      projectId: projectId ?? -1,
    },
    { skip: isNullish(projectId) },
  );

  const projectApplicationContent = useMemo(() => {
    if (isNullish(project)) {
      return undefined;
    }
    return applicationContentMeta.toDomainObjectOrNull(
      project.applicationContent,
      true,
    );
  }, [project]);

  useEffect(() => {
    if (hasValue(viewModel)) return;
    if (isNullish(projectApplicationContent)) return;

    setViewModel(projectApplicationContent);
  }, [viewModel, projectApplicationContent]);

  return (
    <>
      {hasValue(projectId) &&
        hasValue(viewModel) &&
        loginUserInfo?.role === "office_member" &&
        project?.projectState !== "draft" && (
          <ProjectForceUpdatePage
            projectId={projectId}
            initialViewModel={viewModel}
            h={"100%"}
            w={"100%"}
          />
        )}
    </>
  );
};
