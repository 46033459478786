import {
  MessageConfigObject,
  SystemEnvResponse,
  useGetMessagesQuery,
  useGetSystemEnvQuery,
} from "./store/api/generated/stock-request-api";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { stockRequestApi as api } from "./store/api/enhanced-api";
import log from "loglevel";
import { hasValue } from "./lib/util/common-util";

export type ConfigFileContextType = {
  isLoading: boolean;
  systemEnv?: SystemEnvResponse;
  messages?: MessageConfigObject;
};

const defaultValue: ConfigFileContextType = {
  isLoading: false,
};

const ConfigFileContext = createContext<ConfigFileContextType>(defaultValue);

export const ConfigFileProvider = (props: { children: ReactNode }) => {
  // セットアップ完了状態
  const [setupCompleted, setSetupCompleted] = useState(false);
  // キャッシュ有効期間
  const [expiration, setExpiration] = useState<number>();

  // 初回に実行する為のシステム環境設定取得遅延クエリ
  const [lazyGetSystemEnvQuery] = api.useLazyGetSystemEnvQuery();
  // システム環境設定取得クエリ
  const { data: systemEnvData, isLoading: isLoadingGetSystemEnvQuery } =
    useGetSystemEnvQuery(undefined, {
      // セットアップが完了していない場合はクエリを発行しない
      skip: !setupCompleted,
      // キャッシュの有効期間を適用
      // refetchOnMountOrArgChange は今回使用できそうに無い為、
      // pollingInterval を使用して強制的に refetch する
      pollingInterval: expiration,
    });
  // メッセージ取得
  const { data: messageData, isLoading: isLoadingGetMessagesQuery } =
    useGetMessagesQuery(undefined, {
      // セットアップが完了していない場合はクエリを発行しない
      skip: !setupCompleted,
      // キャッシュの有効期間を適用
      // refetchOnMountOrArgChange は今回使用できそうに無い為、
      // pollingInterval を使用して強制的に refetch する
      pollingInterval: expiration,
    });

  /**
   * セットアップ処理
   */
  const setup = useCallback(async () => {
    log.debug("setup start");

    // キャッシュの有効期間を取得する為にクエリを実行
    const response = await lazyGetSystemEnvQuery().unwrap();
    log.debug("lazyGetSystemEnvQuery: ", response);

    // システム環境設定の取得成功時
    if (hasValue(response)) {
      // 有効期間を算出(分 => ミリ秒へ変換)
      const _expiration = hasValue(response.cachePollingInterval)
        ? response.cachePollingInterval * 60000
        : undefined;
      // キャッシュ有効期間を設定
      setExpiration(_expiration);
      // セットアップ完了
      setSetupCompleted(true);
      log.debug(`setup end: expiration=${_expiration}s`);
    }
  }, [lazyGetSystemEnvQuery]);

  // コンポーネントのロード完了状態
  const isLoading = useMemo(() => {
    // セットアップが完了している かつ 各クエリの初回ロードが完了しているかを返却
    return (
      setupCompleted && !isLoadingGetSystemEnvQuery && isLoadingGetMessagesQuery
    );
  }, [setupCompleted, isLoadingGetSystemEnvQuery, isLoadingGetMessagesQuery]);

  // コンポーネント初回レンダリング時の処理
  useEffect(
    () => {
      // セットアップを実行
      setup().then();
    },
    // eslint-disable-next-line
    [],
  );

  return (
    <ConfigFileContext.Provider
      value={{
        isLoading: isLoading,
        systemEnv: systemEnvData,
        messages: messageData,
      }}
    >
      {props.children}
    </ConfigFileContext.Provider>
  );
};

export const useConfigFileContext = (): ConfigFileContextType =>
  useContext(ConfigFileContext);
