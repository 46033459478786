import { ComponentStyleProps } from "../../../../../../../../../lib/styles/props/component-style-props";
import { memo, ReactNode } from "react";
import { VStack } from "@chakra-ui/react";
import { SystemProps } from "@chakra-ui/system";

export type SectionContentFrameProps = {
  spacing?: SystemProps["margin"];
  children?: ReactNode;
} & ComponentStyleProps;

/**
 * セクション内の余白等を整えるためのフレーム
 */
export const SectionContentFrame = memo(function SectionContentFrame({
  spacing,

  children,

  sx,
  ...rest
}: SectionContentFrameProps) {
  return (
    <VStack
      alignSelf={"stretch"}
      alignItems={"stretch"}
      px={"6px"}
      spacing={spacing}
      sx={sx}
      {...rest}
    >
      {children}
    </VStack>
  );
});
