import {
  messageId,
  PropertyMeta,
  ValueObjectMetaNumber,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.number().min(1).max(10000);
const savedMinimalSchema = z.number().min(1).max(10000);
const schema = z.number().min(1).max(10000);
const savedSchema = z.number().min(1).max(10000);
const jsonSchema = z.number();
/**
 * 検索条件_表示件数(値オブジェクト)のメタデータ。
 *
 *  */
export const searchConditionLimitMeta = new ValueObjectMetaNumber(
  "SearchConditionLimit",
  "\u691C\u7D22\u6761\u4EF6_\u8868\u793A\u4EF6\u6570",
  messageId("lbl.\u8868\u793A\u4EF6\u6570"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
