import {
  messageId,
  PropertyMeta,
  ValueObjectMetaString,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.string().max(100);
const savedMinimalSchema = z.string().max(100);
const schema = z.string().min(1).max(100);
const savedSchema = z.string().min(1).max(100);
const jsonSchema = z.string();
/**
 * 表示用機関ID(値オブジェクト)のメタデータ。
 *
 * 事務局が、機関登録時に手動採番する。一度登録した後に変更することはできない。 */
export const displayInstitutionIdMeta = new ValueObjectMetaString(
  "DisplayInstitutionId",
  "\u8868\u793A\u7528\u6A5F\u95A2ID",
  messageId("lbl.\u8868\u793A\u7528\u6A5F\u95A2ID"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
