import { ComponentStyleProps } from "../../../../../lib/styles/props/component-style-props";
import { FormSection } from "../../../../ui/form/FormSection/FormSection";
import { CMFormInputDate } from "@pscsrvlab/psc-react-components";
import React from "react";
import { useAppTranslation } from "../../../../../hooks/use-app-translation";
import { YearMonthDay } from "../../../../../lib/object/value/year-month-day";

export type ResultNotificationSectionReviewDateProps = {
  value: YearMonthDay;
} & ComponentStyleProps;

export const ResultNotificationSectionReviewDate = ({
  value,

  sx,
  ...rest
}: ResultNotificationSectionReviewDateProps) => {
  const { t } = useAppTranslation();
  return (
    <FormSection title={t("lbl.審査実施日")} sx={sx} {...rest}>
      <CMFormInputDate editMode={"readOnly"} value={value} />
    </FormSection>
  );
};
