import { DocumentState } from "../../../../../lib/object/value/document-state";
import { Role } from "../../../../../lib/object/value/role";
import {
  OfficeMemberCheckContent,
  officeMemberCheckContentMeta,
} from "../../../../../lib/object/value/office-member-check-content";
import { useNavigate } from "react-router-dom";
import React, { useCallback, useMemo, useState } from "react";
import useFileUpload from "../../../../../hooks/use-file-upload";
import { AttachmentFileInformation } from "../../../../../lib/object/value/attachment-file-information";
import log from "loglevel";
import { isNullish } from "../../../../../lib/util/common-util";
import {
  Container,
  Flex,
  HStack,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import {
  CMButton,
  CMFormFileUpload,
  CMFormInputDropDown,
  CMFormInputRadio,
  CMFormInputTextArea,
  CMModalOptionsCancel,
  DeleteConfirmationModal,
} from "@pscsrvlab/psc-react-components";
import { FrameUpperRightButton } from "../../../../ui/frame/FrameUpperRightButton/FrameUpperRightButton";
import {
  ReviewType,
  reviewTypeMeta,
} from "../../../../../lib/object/value/review-type";
import { clinicalStocksInventoryCheckStateMeta } from "../../../../../lib/object/value/clinical-stocks-inventory-check-state";
import { UploadButtonEmpty } from "../../../../ui/button/UploadButtonEmpty/UploadButtonEmpty";
import { DocumentPosition } from "../../../../../lib/object/value/document-position";
import {
  useCompleteOfficeMemberCheckMutation,
  useDocumentHasUnheldReviewConferenceQuery,
  useUpdateChangeApplicationOfficeMemberCheckMutation,
  useUpdateNewApplicationOfficeMemberCheckMutation,
} from "../../../../../store/api/generated/stock-request-api";
import useCustomToast from "../../../../../hooks/use-custom-toast";
import { attachmentFileToFileUploadItem } from "../../../../../lib/util/app-util";
import { freeTextMeta } from "../../../../../lib/object/value/free-text";
import { useCreateJsonPatchOperations } from "../../../../../hooks/use-create-json-patch-operations";
import { useAppTranslation } from "../../../../../hooks/use-app-translation";
import { useLeaveEditingPrompt } from "../../../../../hooks/use-leave-editing-prompt";
import { useProjectPagePathPrefix } from "../../../../../hooks/use-project-page-path-prefix";
import { DocumentType } from "../../../../../lib/object/value/document-type";
import { ConfirmationModal } from "../../../../ui/modal/ConfirmationModal/ConfirmationModal";
import { EditButton } from "../../../../ui/button/EditButton/EditButton";
import { SaveChangesButton } from "../../../../ui/button/SaveChangesButton/SaveChangesButton";
import { EditEndButton } from "../../../../ui/button/EditEndButton/EditEndButton";
import { FormSection } from "../../../../ui/form/FormSection/FormSection";
import { useFileModalContext } from "../../../../ui/modal/FileModal/FileModal";

export type OfficeMemberCheckPageProps = {
  /**
   * バックエンドから取得する最新の事務局確認内容。参照モードではこれを表示する。
   */
  initialOfficeMemberCheckContent: OfficeMemberCheckContent;
  documentId: number;
  documentType: DocumentType;
  documentState: DocumentState;
  documentPosition: DocumentPosition;
  loginUserRole: Role;
};

export const OfficeMemberCheckPage = ({
  initialOfficeMemberCheckContent,
  documentId,
  documentType,
  documentState,
  documentPosition,
  loginUserRole,
}: OfficeMemberCheckPageProps) => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const { projectPagePathPrefix } = useProjectPagePathPrefix();
  const { errorToast, validationErrorToast } = useCustomToast();

  const [updateNewApplicationOfficeMemberCheck] =
    useUpdateNewApplicationOfficeMemberCheckMutation();

  const [updateChangeApplicationOfficeMemberCheck] =
    useUpdateChangeApplicationOfficeMemberCheckMutation();

  //書類に紐づく審査会一覧を取得。
  const { data: hasUnheldReviewConference } =
    useDocumentHasUnheldReviewConferenceQuery(
      {
        documentId: documentId ?? -1,
      },
      { skip: isNullish(documentId) },
    );

  const [completeOfficeMemberCheck] = useCompleteOfficeMemberCheckMutation();

  const [editMode, setEditMode] = useState<"editable" | "readOnly">("readOnly");

  useLeaveEditingPrompt({ skip: editMode === "readOnly" });

  // 事務局確認内容
  const [content, setContent] = useState<OfficeMemberCheckContent>(
    initialOfficeMemberCheckContent,
  );
  const handleChange = useCallback(
    (
      change: (before: OfficeMemberCheckContent) => OfficeMemberCheckContent,
    ) => {
      setContent(change);
    },
    [],
  );

  const { fileUpload } = useFileUpload();
  const { openFileModal } = useFileModalContext()

  //編集ボタンを表示するか判定。
  //「UI-FN40_機能仕様(書類・申請)」_「S16メイン」_「編集ボタン」参照
  const canEdit = useMemo(() => {
    return (
      (documentState === "office_check" ||
        documentState === "awaiting_conclusion_no_review" ||
        documentState === "reviewing") &&
      documentPosition === "office_member"
    );
  }, [documentState, documentPosition]);

  //事務局確認完了ボタンを表示するか判定。
  //「UI-FN40_機能仕様(書類・申請)」_「S16メイン」_「編集ボタン」参照
  const canComplete = useMemo(() => {
    return (
      documentState === "office_check" && documentPosition === "office_member"
    );
  }, [documentState, documentPosition]);

  /**
   * 審査種別が「未定」で事務局確認完了ボタンが押下されるとエラーとする。
   *  「UI-FN40_機能仕様(書類・申請)」_「S16イベント」参照
   */
  const reviewTypeValidateWhenSaved = useCallback(
    (
      content: OfficeMemberCheckContent,
    ): {
      state: "error" | "ok";
      message?: string;
    } => {
      if (
        documentState !== "office_check" &&
        content.reviewType === "unselected"
      ) {
        return {
          state: "error",
          message: t("mes.事務局確認完了後審査種別未定選択エラーメッセージ"),
        };
      }
      //書類に紐づく審査会が1件でもある状態で、審査種別を委員会審査以外に変更し保存するとエラーとなる。
      //「UI-FN40_機能仕様(書類・申請)」_「S16補足」参照
      if (
        documentState === "reviewing" &&
        hasUnheldReviewConference &&
        content.reviewType !== "committee_review"
      ) {
        return {
          state: "error",
          message: t("mes.委員会審査種別変更不可エラーメッセージ"),
        };
      }
      return { state: "ok" };
    },
    [documentState, hasUnheldReviewConference, t],
  );

  const reviewTypeValidateWhenCompleted = useCallback(
    (
      content: OfficeMemberCheckContent,
    ): {
      state: "error" | "ok";
      message?: string;
    } => {
      if (content.reviewType === "unselected") {
        return {
          state: "error",
          message: t("mes.審査種別選択必須エラーメッセージ"),
        };
      }
      return { state: "ok" };
    },
    [t],
  );

  //ファイルアップロード時の処理。
  const handleAddFile = useCallback(
    async (
      _sectionId: string,
      subSectionId: "office_checklist_for_application",
      file: File,
    ) => {
      const response = await fileUpload(file, "private", null, null);
      if (isNullish(response)) return;

      const newFile: AttachmentFileInformation = {
        storageFileId: response.id,
        attachmentFileType: subSectionId,
        attachmentFileName: file.name,
        uploadedAt: response.created.datetime,
      };
      handleChange((before) => ({
        ...before,
        officeChecklist: [...before.officeChecklist, newFile],
      }));
    },
    [fileUpload, handleChange],
  );

  //添付ファイルダウンロード時の処理。
  const handleDownloadFile = useCallback(
    (fileId: string, fileName: string) => {
      void openFileModal(fileId, fileName);
    },
    [openFileModal],
  );

  const [deletingFileId, setDeletingFileId] = useState<string>("");

  //添付ファイル削除時の処理。
  const handleDeleteFile = useCallback(() => {
    handleChange((before) => ({
      ...before,
      officeChecklist: before.officeChecklist.filter(
        (v) => v.storageFileId.toString(10) !== deletingFileId,
      ),
    }));
  }, [deletingFileId, handleChange]);

  //編集ボタン押下時の処理。
  const handleClickEditButton = useCallback(() => {
    setEditMode("editable");
  }, []);

  const { createJsonPatchOperations } = useCreateJsonPatchOperations(
    officeMemberCheckContentMeta,
  );

  //変更を保存するボタン押下時の処理。
  const handleSave = async () => {
    const validationResult = officeMemberCheckContentMeta.validate(content);
    if (validationResult.state === "error") {
      validationErrorToast(validationResult.errors);
      return;
    }

    const jsonPatchOperations = createJsonPatchOperations(
      initialOfficeMemberCheckContent,
      content,
    );
    if (isNullish(jsonPatchOperations)) return;
    if (reviewTypeValidateWhenSaved(content).state === "ok") {
      try {
        if (documentType === "new_application") {
          await updateNewApplicationOfficeMemberCheck({
            documentId: documentId,
            commonUpdateRequest: {
              patchOperations: jsonPatchOperations,
            },
          }).unwrap();
        }
        if (documentType === "change_application") {
          await updateChangeApplicationOfficeMemberCheck({
            documentId: documentId,
            commonUpdateRequest: {
              patchOperations: jsonPatchOperations,
            },
          }).unwrap();
        }
        setEditMode("readOnly");
      } catch (e) {
        log.error(e);
        errorToast(t("mes.事務局確認内容更新失敗エラー"));
      }
    } else {
      errorToast(reviewTypeValidateWhenSaved(content).message ?? "");
    }
  };

  //編集終了確認モーダルの制御。
  const {
    isOpen: isOpenEditEndModal,
    onOpen: onOpenEditEndModal,
    onClose: onCloseEditEndModal,
  } = useDisclosure();

  //編集終了ボタン押下時の処理。
  const handleClickEndEditButton = useCallback(() => {
    onOpenEditEndModal();
  }, [onOpenEditEndModal]);

  //編集終了確認モーダルの確定ボタン押下時の処理。
  const handleSubmitEditEnd = useCallback(() => {
    setContent(initialOfficeMemberCheckContent);
    setEditMode("readOnly");
    onCloseEditEndModal();
  }, [initialOfficeMemberCheckContent, onCloseEditEndModal]);

  //事務局確認完了確認モーダルの制御。
  const {
    isOpen: isOpenCompleteModal,
    onOpen: onOpenCompleteModal,
    onClose: onCloseCompleteModal,
  } = useDisclosure();

  //事務局確認完了ボタン押下時の処理。
  const handleCompleteButton = () => {
    onOpenCompleteModal();
  };

  //変更保存確認モーダルの制御。
  const {
    isOpen: isOpenSaveModal,
    onOpen: onOpenSaveModal,
    onClose: onCloseSaveModal,
  } = useDisclosure();

  //変更保存ボタン押下時の処理。
  const handleClickSaveButton = () => {
    onOpenSaveModal();
  };

  //変更保存確認モーダルの制御。
  const {
    isOpen: isOpenDeleteFileModal,
    onOpen: onOpenDeleteFileModal,
    onClose: onCloseDeleteFileModal,
  } = useDisclosure();

  //変更保存ボタン押下時の処理。
  const handleClickDeleteFileButton = useCallback(
    (_sectionId: string, _subSectionId: string, fileId: string) => {
      setDeletingFileId(fileId);
      onOpenDeleteFileModal();
    },
    [onOpenDeleteFileModal],
  );

  /**
   * モーダルの完了ボタンが押下されると呼ばれ、事務局確認を完了する。
   */
  const handleSubmitComplete = async () => {
    onCloseCompleteModal();
    const completionValidationResult = reviewTypeValidateWhenCompleted(content);
    if (completionValidationResult.state === "error") {
      errorToast(completionValidationResult.message ?? "");
      return;
    }

    try {
      await completeOfficeMemberCheck({
        documentId: documentId,
      }).unwrap();
      navigate(
        `${projectPagePathPrefix}/document/${documentId}/office-member-check/complete`,
      );
    } catch (e) {
      log.error(e);
      errorToast(t("mes.事務局確認完了失敗エラー"));
    }
  };

  const fileItems = useMemo(
    () =>
      content.officeChecklist.map((v) => attachmentFileToFileUploadItem(t, v)),
    [content.officeChecklist, t],
  );

  return (
    <>
      <Flex direction={"column"} overflow={"auto"} pb={"50px"}>
        {/*事務局のみ編集可能で、業務執行理事は参照のみ可能*/}
        {loginUserRole === "office_member" && canEdit && (
          <FrameUpperRightButton sx={{ alignSelf: "flex-end" }}>
            {editMode === "readOnly" ? (
              <EditButton onClick={handleClickEditButton} />
            ) : (
              <HStack>
                <SaveChangesButton onClick={handleClickSaveButton} />
                <EditEndButton onClick={handleClickEndEditButton} />
              </HStack>
            )}
          </FrameUpperRightButton>
        )}

        <Container maxW={"720px"} className={"Container"}>
          <VStack mt={"30px"} alignItems={"stretch"}>
            <FormSection title={t("lbl.審査種別")}>
              <CMFormInputDropDown
                noHeader={true}
                editMode={editMode}
                value={content.reviewType}
                valueObjectMeta={reviewTypeMeta}
                onChange={(value: ReviewType) =>
                  handleChange((before) => ({
                    ...before,
                    reviewType: value,
                  }))
                }
                sx={{ w: "300px" }}
              />
            </FormSection>
            <FormSection title={t("lbl.臨床用株の場合の在庫確認")}>
              <CMFormInputRadio
                noHeader={true}
                editMode={editMode}
                value={content.clinicalStocksInventoryCheckState}
                valueObjectMeta={clinicalStocksInventoryCheckStateMeta}
                onChange={(value: "unchecked" | "checked") =>
                  handleChange((before) => ({
                    ...before,
                    clinicalStocksInventoryCheckState: value,
                  }))
                }
              />
            </FormSection>
            <FormSection title={t("lbl.備考")}>
              <CMFormInputTextArea
                valueObjectMeta={freeTextMeta}
                editMode={editMode}
                value={content.note}
                onChange={(value: string) =>
                  handleChange((before) => ({
                    ...before,
                    note: value,
                  }))
                }
              />
            </FormSection>
            <FormSection title={t("lbl.申請用事務局チェックリスト")}>
              <CMFormFileUpload
                value={{
                  title: "",
                  id: "",
                  subSectionContents: [
                    {
                      id: "office_checklist_for_application",
                      multiple: true,
                      editMode: editMode,
                      childrenButton: <UploadButtonEmpty />,
                      files: fileItems,
                    },
                  ],
                }}
                onDownloadFile={handleDownloadFile}
                onAddFile={handleAddFile}
                onDeleteFile={handleClickDeleteFileButton}
              />
            </FormSection>
            {/*事務局のみ完了ボタン押下可能で、業務執行理事は不可能*/}
            <VStack pt={"20px"}>
              {loginUserRole === "office_member" &&
                editMode === "readOnly" &&
                canComplete && (
                  <CMButton
                    label={t("btn.事務局確認を完了するボタン")}
                    onClick={handleCompleteButton}
                  />
                )}
            </VStack>
          </VStack>
        </Container>
      </Flex>
      {/*事務局確認完了時に表示するモーダル*/}
      <CMModalOptionsCancel
        isOpen={isOpenCompleteModal}
        labelSubmitButton={t("btn.完了するボタン")}
        labelCancelButton={t("btn.キャンセルボタン")}
        onSubmit={handleSubmitComplete}
        onCancel={onCloseCompleteModal}
        labelBody={t("mes.事務局確認完了確認モーダルメッセージ")}
        labelHeader={t("lbl.確認ポップアップタイトル")}
      />
      {/*編集終了時に表示するモーダル*/}
      <ConfirmationModal
        isOpen={isOpenEditEndModal}
        message={t("mes.変更内容破棄メッセージ")}
        onSubmit={handleSubmitEditEnd}
        onCancel={onCloseEditEndModal}
      />
      {/*変更保存確認時に表示するモーダル*/}
      <ConfirmationModal
        isOpen={isOpenSaveModal}
        message={t("mes.変更保存確認メッセージ")}
        onSubmit={handleSave}
        onCancel={onCloseSaveModal}
      />
      {/*添付ファイル削除時に表示するモーダル*/}
      <DeleteConfirmationModal
        isOpen={isOpenDeleteFileModal}
        deleteButtonLabel={t("btn.削除ボタン")}
        cancelButtonLabel={t("btn.キャンセルボタン")}
        onSubmit={handleClickDeleteFileButton}
        onClose={onCloseDeleteFileModal}
        message={t("mes.添付資料削除確認メッセージ")}
        title={t("lbl.確認ポップアップタイトル")}
        onConfirm={handleDeleteFile}
      />
    </>
  );
};
