import { hasValue } from "../../../../lib/util/common-util";
import { TerminationReportCreateConfirmPage } from "./_components/TerminationReportCreateConfirmPage/TerminationRepotCreateConfirmPage";
import { useAppParams } from "../../../../hooks/use-app-params";
import { useTerminationReportReadonlyVm } from "../../../../hooks/document/termination-report/use-termination-report-readonly-vm";

export const FN40S09TerminationReportCreateConfirm = () => {
  const { documentId } = useAppParams();

  const { currentData, vm } = useTerminationReportReadonlyVm(documentId);

  return (
    <>
      {hasValue(documentId) &&
        hasValue(vm) &&
        currentData?.documentState === "draft" && (
          <TerminationReportCreateConfirmPage documentId={documentId} vm={vm} />
        )}
    </>
  );
};
