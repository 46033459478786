import { CMProgressStep } from "@pscsrvlab/psc-react-components";
import React, { memo } from "react";
import { ComponentStyleProps } from "../../../../lib/styles/props/component-style-props";
import { useAppTranslation } from "../../../../hooks/use-app-translation";

export type ProgressStepDocumentCreateProps = {
  currentStep?: number;
} & ComponentStyleProps;
export const ProgressStepDocumentCreate = memo(
  function ProgressStepDocumentCreate({
    currentStep,
    sx,
    ...rest
  }: ProgressStepDocumentCreateProps) {
    const { t } = useAppTranslation();
    return (
      <CMProgressStep
        steps={[
          { label: "STEP1", description: t("lbl.書類作成") },
          { label: "STEP2", description: t("lbl.書類内容確認") },
          { label: "STEP3", description: t("lbl.提出完了") },
        ]}
        currentStep={currentStep}
        sx={{
          pt: "20px",
          display: "flex",
          justifyContent: "center",
          ...sx,
        }}
        {...rest}
      />
    );
  },
);
