import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * 使用終了後の取り扱い区分(値オブジェクト)
 *
 *
 *
 * abandonment:
 * return:
 * other:  */
export type HandlingAfterUseType = z.infer<
  typeof handlingAfterUseTypeMeta.schema
>;
/**
 * 使用終了後の取り扱い区分(値オブジェクト)のメタデータ。
 *
 *  */
export const handlingAfterUseTypeMeta = new ValueObjectMetaEnum<
  ["abandonment", "return", "other"]
>(
  "HandlingAfterUseType",
  "\u4F7F\u7528\u7D42\u4E86\u5F8C\u306E\u53D6\u308A\u6271\u3044\u533A\u5206",
  messageId(
    "lbl.\u4F7F\u7528\u7D42\u4E86\u5F8C\u306E\u53D6\u308A\u6271\u3044\u533A\u5206"
  ),
  ["abandonment", "return", "other"],
  {
    abandonment:
      "code.\u4F7F\u7528\u7D42\u4E86\u5F8C\u306E\u53D6\u308A\u6271\u3044\u533A\u5206.\u5EC3\u68C4\u3059\u308B",
    return:
      "code.\u4F7F\u7528\u7D42\u4E86\u5F8C\u306E\u53D6\u308A\u6271\u3044\u533A\u5206.\u8FD4\u5374\u3059\u308B",
    other:
      "code.\u4F7F\u7528\u7D42\u4E86\u5F8C\u306E\u53D6\u308A\u6271\u3044\u533A\u5206.\u305D\u306E\u4ED6",
  }
);
