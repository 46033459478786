import { IpsCellStockRequirementReason } from "../value/ips-cell-stock-requirement-reason";
import { ErrorMessageObject, messageId } from "@pscsrvlab/psc-react-components";
import { hasValue, isNullish } from "../../util/common-util";

export function ipsCellStockRequirementReasonCustomValidation(
  value: IpsCellStockRequirementReason,
  skipOptional: boolean,
): ErrorMessageObject[] {
  if (skipOptional) return [];

  const errorMessageList: ErrorMessageObject[] = [];

  /**
   * その他が選択されているにもかかわらず、その他入力欄に値が入力されていない場合のエラー
   */
  if (
    value.reasonType === "other" &&
    (isNullish(value.other) || value.other === "")
  ) {
    errorMessageList.push({
      errorMessageId: messageId(
        "mes.iPS細胞ストック必要理由その他未入力エラー",
      ),
    });
  }
  /**
   * その他以外が入力されているにもかかわらず、その他入力欄に値が入力されている場合のエラー
   */
  if (value.reasonType !== "other" && hasValue(value.other)) {
    errorMessageList.push({
      errorMessageId: messageId("mes.iPS細胞ストックを必要とする理由エラー"),
    });
  }

  return errorMessageList;
}
