import {
  messageId,
  PropertyMeta,
  ValueObjectMetaNumber,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.number().min(0);
const savedMinimalSchema = z.number().min(0);
const schema = z.number().min(0);
const savedSchema = z.number().min(0);
const jsonSchema = z.number();
/**
 * グループ内キー(値オブジェクト)のメタデータ。
 *
 * 配列内の要素のアイデンティティを表すためのキー。 */
export const groupLocalKeyMeta = new ValueObjectMetaNumber(
  "GroupLocalKey",
  "\u30B0\u30EB\u30FC\u30D7\u5185\u30AD\u30FC",
  messageId("lbl.\u30B0\u30EB\u30FC\u30D7\u5185\u30AD\u30FC"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
