import { ComponentStyleProps } from "../../../../../../../../lib/styles/props/component-style-props";
import { CMFormInputDate } from "@pscsrvlab/psc-react-components";
import React, { memo } from "react";
import { FormSection } from "../../../../../../../ui/form/FormSection/FormSection";
import { useAppTranslation } from "../../../../../../../../hooks/use-app-translation";

export type NextAnnualReportDeadlineCreateProjectSectionProps = {
  editMode: "editable" | "readOnly";

  /**
   * 値。
   */
  value?: { year: number; month: number; day: number };
  onChange: (value: { year: number; month: number; day: number }) => void;
} & ComponentStyleProps;

/**
 * 年次報告期限日セクション
 */
export const NextAnnualReportDeadlineCreateProjectSection = memo(
  function NextAnnualReportDeadlineCreateProjectSection({
    editMode,

    value,
    onChange,

    sx,
    ...rest
  }: NextAnnualReportDeadlineCreateProjectSectionProps) {
    const { t } = useAppTranslation();

    return (
      <FormSection title={t("lbl.次回年次報告期限日")} sx={sx} {...rest}>
        <CMFormInputDate
          editMode={editMode}
          value={value}
          onChange={onChange}
          sx={{ w: "200px" }}
        />
      </FormSection>
    );
  },
);
