import { HStack, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import { selectHasRole } from "../../../../store/auth/slice";
import React, { useCallback, useMemo, useState } from "react";
import { hasValue, isNullish } from "../../../../lib/util/common-util";
import {
  CMExtendedMenu,
  CMIndicatorStatus,
} from "@pscsrvlab/psc-react-components";
import { DocumentHeaderTabs } from "./_components/DocumentHeaderTabs/DocumentHeaderTabs";
import { useAppParams } from "../../../../hooks/use-app-params";
import { documentTypeMeta } from "../../../../lib/object/value/document-type";
import { documentStateMeta } from "../../../../lib/object/value/document-state";
import { documentPositionMeta } from "../../../../lib/object/value/document-position";
import { resultMeta } from "../../../../lib/object/value/result";
import { useAppTranslation } from "../../../../hooks/use-app-translation";
import { useAppGetDocumentQuery } from "../../../../hooks/query/use-app-get-document-query";
import { ConfirmationModal } from "../../../ui/modal/ConfirmationModal/ConfirmationModal";
import useCustomToast from "../../../../hooks/use-custom-toast";
import {
  useAbortDocumentMutation,
  useWithdrawDocumentMutation,
} from "../../../../store/api/generated/stock-request-api";

export const DocumentHeader = () => {
  const { t } = useAppTranslation();
  const { isApplicant, isOfficeMember } = useAppSelector(selectHasRole);
  const { documentId, projectId } = useAppParams();
  const { successToast, errorToast } = useCustomToast();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data: document } = useAppGetDocumentQuery(
    { documentId: documentId ?? -1 },
    { skip: isNullish(documentId) },
  );

  //region 中断・取り下げ
  // 書類中断が可能ならtrue。
  const canAbort = useMemo(() => {
    if (isNullish(document)) return false;
    return isOfficeMember && document.documentState !== "aborted";
  }, [document, isOfficeMember]);

  // 書類取り下げが可能ならtrue。
  const canWithdraw = useMemo(() => {
    if (isNullish(document)) return false;
    return (
      isApplicant &&
      document.documentPosition === "applicant" &&
      (document.documentState === "office_check" ||
        document.documentState === "awaiting_conclusion")
    );
  }, [document, isApplicant]);

  const extendedMenuItems = useMemo(
    () =>
      [
        canAbort
          ? {
              key: "abort",
              label: t("btn.書類を中断するボタン"),
            }
          : undefined,
        canWithdraw
          ? {
              key: "withdraw",
              label: t("btn.取り下げるボタン"),
            }
          : undefined,
      ].filter(hasValue),
    [canAbort, canWithdraw, t],
  );

  const [abort] = useAbortDocumentMutation();
  const [withdraw] = useWithdrawDocumentMutation();

  // 確認モーダルのタイプ
  const [modalType, setModalType] = useState<"abort" | "withdraw">();

  // 確認モーダルに表示するメッセージ
  const modalMessage = useMemo(() => {
    switch (modalType) {
      case "abort":
        return t("mes.書類中断確認メッセージ");
      case "withdraw":
        return t("mes.書類取下確認メッセージ");
      default:
        return undefined;
    }
  }, [modalType, t]);

  // 確認モーダル確定ボタンクリック時の処理
  const handleModalSubmit = useCallback(async () => {
    try {
      switch (modalType) {
        // 書類中断
        case "abort":
          if (hasValue(documentId)) {
            await abort({ documentId }).unwrap();
            successToast(t("mes.書類中断メッセージ"));
          }
          break;
        // 書類取り下げ
        case "withdraw":
          if (hasValue(documentId)) {
            await withdraw({ documentId }).unwrap();
            successToast(t("mes.書類取下メッセージ"));
          }
          break;
        default:
          break;
      }
    } catch (e) {
      errorToast(t("mes.汎用エラーメッセージ"));
    }
  }, [abort, documentId, errorToast, modalType, successToast, t, withdraw]);
  //endregion

  //region バッジ表示内容
  const documentTypeText: string = useMemo(() => {
    if (isNullish(document)) return "";
    return t(documentTypeMeta.dict[document.documentType]);
  }, [document, t]);
  const documentStateText: string = useMemo(() => {
    if (isNullish(document)) return "";
    return t(documentStateMeta.dict[document.documentState]);
  }, [document, t]);
  const documentPositionText: string = useMemo(() => {
    if (isNullish(document)) return "";
    return t(documentPositionMeta.dict[document.documentPosition]);
  }, [document, t]);
  const { resultText, resultColor } = useMemo(() => {
    if (isNullish(document)) return { resultText: "", resultColor: "gray" };
    if (isNullish(document.result))
      return {
        resultText: t("lbl.未定"),
        resultColor: "gray",
      };
    return {
      resultText: t(resultMeta.dict[document.result]),
      resultColor: "blue",
    };
  }, [document, t]);
  //endregion

  return (
    <>
      <HStack
        flex={"0 0 60px"}
        alignSelf={"stretch"}
        pr={"12px"}
        borderBottomWidth={"1px"}
        borderColor={"gray.400"}
      >
        {hasValue(documentId) && (
          <>
            <VStack
              alignSelf={"stretch"}
              className={"VStack"}
              alignItems={"flex-start"}
              flex={1}
              justifyContent={"stretch"}
              spacing={0}
            >
              {hasValue(document) && (
                <>
                  <HStack flex={1} pt={"5px"} pl={"14px"}>
                    <>
                      <HStack fontWeight={"bold"} color={"gray.700"}>
                        <Text whiteSpace={"nowrap"}>
                          {document.documentControlNumber}
                        </Text>
                        <Text whiteSpace={"nowrap"}>{documentTypeText}</Text>
                      </HStack>
                      <HStack pl={"10px"} spacing={"10px"}>
                        <CMIndicatorStatus
                          label={t("lbl.書類状態")}
                          badgeText={documentStateText}
                          badgeColor={"blue"}
                        />
                        <CMIndicatorStatus
                          label={t("lbl.書類位置")}
                          badgeText={documentPositionText}
                          badgeColor={"blue"}
                        />
                        <CMIndicatorStatus
                          label={t("lbl.結果")}
                          badgeText={resultText}
                          badgeColor={resultColor}
                        />
                      </HStack>
                    </>
                  </HStack>
                  <DocumentHeaderTabs
                    projectId={projectId}
                    documentId={documentId}
                    documentType={document.documentType}
                    pl={"10px"}
                    mt={"auto"}
                  />
                </>
              )}
            </VStack>
            {extendedMenuItems.length > 0 && (
              <CMExtendedMenu<"abort" | "withdraw">
                size={"sm"}
                menuItems={extendedMenuItems}
                onClick={(key: "abort" | "withdraw") => {
                  setModalType(key);
                  onOpen();
                }}
                ml={"auto"}
                menuZIndex={30}
              />
            )}
          </>
        )}
      </HStack>

      <ConfirmationModal
        isOpen={isOpen}
        message={modalMessage ?? ""}
        onSubmit={handleModalSubmit}
        onCancel={onClose}
      />
    </>
  );
};
