import {
  messageId,
  PropertyMeta,
  ValueObjectMetaComposite,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { shippingStateMeta } from "../value/shipping-state";
import { yearMonthDayMeta } from "../value/year-month-day";
/**
 * 発送情報(値オブジェクト)
 *
 *  */
export type ShippingInformation = z.infer<typeof schema>;
export type ShippingInformationSaved = z.infer<typeof savedSchema>;
const minimalSchema = z.object({
  shippingStatus: shippingStateMeta.minimalSchema,
  shippingDate: yearMonthDayMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
const savedMinimalSchema = z.object({
  shippingStatus: shippingStateMeta.savedMinimalSchema,
  shippingDate: yearMonthDayMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
const schema = z.object({
  shippingStatus: shippingStateMeta.schema,
  shippingDate: yearMonthDayMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
});
const savedSchema = z.object({
  shippingStatus: shippingStateMeta.savedSchema,
  shippingDate: yearMonthDayMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
const jsonSchema = z.object({
  shippingStatus: shippingStateMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  shippingDate: yearMonthDayMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
/**
 * 発送情報(値オブジェクト)のメタデータ。
 *
 *  */
export const shippingInformationMeta = new ValueObjectMetaComposite(
  "ShippingInformation",
  "\u767A\u9001\u60C5\u5831",
  messageId("lbl.\u767A\u9001\u60C5\u5831"),
  minimalSchema,
  savedMinimalSchema,
  schema,
  savedSchema,
  jsonSchema,
  undefined,
  undefined,
  {
    shippingStatus: new PropertyMeta(
      "shippingStatus",
      "\u767A\u9001\u72B6\u6CC1",
      messageId("lbl.\u767A\u9001\u72B6\u6CC1"),
      "always",
      false,
      shippingStateMeta
    ),
    shippingDate: new PropertyMeta(
      "shippingDate",
      "\u767A\u9001\u65E5",
      messageId("lbl.\u767A\u9001\u65E5"),
      "none",
      false,
      yearMonthDayMeta
    ),
  }
);
