import {
  messageId,
  PropertyMeta,
  ValueObjectMetaComposite,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { groupLocalKeyMeta } from "../value/group-local-key";
import { differentiatedCellProvisionToMeta } from "../value/differentiated-cell-provision-to";
import { differentiatedCellInformationMeta } from "../value/differentiated-cell-information";
import { differentiatedCellProvisionReasonMeta } from "../value/differentiated-cell-provision-reason";
import { differentiatedCellPreservationTypeMeta } from "../value/differentiated-cell-preservation-type";
/**
 * 分化細胞提供先(値オブジェクト)
 *
 *  */
export type DifferentiatedCellProvision = z.infer<typeof schema>;
export type DifferentiatedCellProvisionSaved = z.infer<typeof savedSchema>;
const minimalSchema = z.object({
  key: groupLocalKeyMeta.minimalSchema,
  recipientName: differentiatedCellProvisionToMeta.minimalSchema,
  cellInformation: differentiatedCellInformationMeta.minimalSchema,
  provisionReason: differentiatedCellProvisionReasonMeta.minimalSchema,
  cellDisposalType: differentiatedCellPreservationTypeMeta.minimalSchema,
});
const savedMinimalSchema = z.object({
  key: groupLocalKeyMeta.savedMinimalSchema,
  recipientName: differentiatedCellProvisionToMeta.savedMinimalSchema,
  cellInformation: differentiatedCellInformationMeta.savedMinimalSchema,
  provisionReason: differentiatedCellProvisionReasonMeta.savedMinimalSchema,
  cellDisposalType: differentiatedCellPreservationTypeMeta.savedMinimalSchema,
});
const schema = z.object({
  key: groupLocalKeyMeta.schema,
  recipientName: differentiatedCellProvisionToMeta.schema,
  cellInformation: differentiatedCellInformationMeta.schema,
  provisionReason: differentiatedCellProvisionReasonMeta.schema,
  cellDisposalType: differentiatedCellPreservationTypeMeta.schema,
});
const savedSchema = z.object({
  key: groupLocalKeyMeta.savedSchema,
  recipientName: differentiatedCellProvisionToMeta.savedSchema,
  cellInformation: differentiatedCellInformationMeta.savedSchema,
  provisionReason: differentiatedCellProvisionReasonMeta.savedSchema,
  cellDisposalType: differentiatedCellPreservationTypeMeta.savedSchema,
});
const jsonSchema = z.object({
  key: groupLocalKeyMeta.jsonSchema.nullish().transform((x) => x ?? undefined),
  recipientName: differentiatedCellProvisionToMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  cellInformation: differentiatedCellInformationMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  provisionReason: differentiatedCellProvisionReasonMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  cellDisposalType: differentiatedCellPreservationTypeMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
/**
 * 分化細胞提供先(値オブジェクト)のメタデータ。
 *
 *  */
export const differentiatedCellProvisionMeta = new ValueObjectMetaComposite(
  "DifferentiatedCellProvision",
  "\u5206\u5316\u7D30\u80DE\u63D0\u4F9B\u5148",
  messageId("lbl.\u5206\u5316\u7D30\u80DE\u63D0\u4F9B\u5148"),
  minimalSchema,
  savedMinimalSchema,
  schema,
  savedSchema,
  jsonSchema,
  undefined,
  undefined,
  {
    key: new PropertyMeta(
      "key",
      "\u30AD\u30FC",
      messageId("lbl.\u30AD\u30FC"),
      "always",
      false,
      groupLocalKeyMeta
    ),
    recipientName: new PropertyMeta(
      "recipientName",
      "\u63D0\u4F9B\u5148\u540D",
      messageId("lbl.\u63D0\u4F9B\u5148\u540D"),
      "always",
      false,
      differentiatedCellProvisionToMeta
    ),
    cellInformation: new PropertyMeta(
      "cellInformation",
      "\u5206\u5316\u7D30\u80DE\u60C5\u5831",
      messageId("lbl.\u5206\u5316\u7D30\u80DE\u60C5\u5831"),
      "always",
      false,
      differentiatedCellInformationMeta
    ),
    provisionReason: new PropertyMeta(
      "provisionReason",
      "\u63D0\u4F9B\u7406\u7531",
      messageId("lbl.\u63D0\u4F9B\u7406\u7531"),
      "always",
      false,
      differentiatedCellProvisionReasonMeta
    ),
    cellDisposalType: new PropertyMeta(
      "cellDisposalType",
      "\u5206\u5316\u7D30\u80DE\u4FDD\u7BA1\u30FB\u5EC3\u68C4\u72B6\u6CC1",
      messageId(
        "lbl.\u5206\u5316\u7D30\u80DE\u4FDD\u7BA1\u30FB\u5EC3\u68C4\u72B6\u6CC1"
      ),
      "always",
      false,
      differentiatedCellPreservationTypeMeta
    ),
  }
);
