import {
  MailLog as MailLogJson,
  useSearchMailLogQuery,
} from "../../store/api/generated/stock-request-api";
import { hasValue, isNullish } from "../../lib/util/common-util";
import { useMemo } from "react";
import { mailLogMeta, MailLogSaved } from "../../lib/object/entity/mail-log";

export function useAppSearchMailLogQuery(
  arg: Parameters<typeof useSearchMailLogQuery>[0],
  options?: Parameters<typeof useSearchMailLogQuery>[1],
  skipOptionalValidations = true, // get系は、skipOptionalValidationsのデフォルトはtrueとする。
): {
  data: {
    totalElements: number;
    items: MailLogSaved[];
  } | null;
} {
  const { data: rawData } = useSearchMailLogQuery(arg, options);

  const data = useMemo(() => {
    if (isNullish(rawData)) return null;

    const _rawData: {
      totalElements: number;
      items: MailLogJson[];
    } = rawData; // IDEの型推論がうまく行かないので、再代入。

    return {
      totalElements: _rawData.totalElements,
      items: _rawData.items
        .map((d: MailLogJson) =>
          mailLogMeta.toSavedDomainObjectOrNull(d, skipOptionalValidations),
        )
        .filter(hasValue),
    };
  }, [rawData, skipOptionalValidations]);
  return { data };
}
