import {
  messageId,
  PropertyMeta,
  ValueObjectMetaPredefinedDatetime,
  predefinedDatetimeMeta,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * 日時(値オブジェクト)のメタデータ。
 *
 * 日時。Date型をそのまま用いる。 */
export const datetimeMeta = predefinedDatetimeMeta;
