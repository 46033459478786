import {
  Review as ReviewJson,
  useListReviewQuery,
} from "../../store/api/generated/stock-request-api";
import { hasValue, isNullish } from "../../lib/util/common-util";
import { useMemo } from "react";
import { reviewMeta, ReviewSaved } from "../../lib/object/entity/review";

export function useAppListReviewQuery(
  arg: Parameters<typeof useListReviewQuery>[0],
  options?: Parameters<typeof useListReviewQuery>[1],
  skipOptionalValidations = true, // get系は、skipOptionalValidationsのデフォルトはtrueとする。
): { data: ReviewSaved[] | null } {
  const { data: rawData } = useListReviewQuery(arg, options);
  const data = useMemo(() => {
    if (isNullish(rawData)) return null;
    return rawData
      .map((d: ReviewJson) =>
        reviewMeta.toSavedDomainObjectOrNull(d, skipOptionalValidations),
      )
      .filter(hasValue);
  }, [rawData, skipOptionalValidations]);
  return { data };
}
