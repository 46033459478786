import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  selectAuthenticationStatus,
  selectHasAnyRoleOf,
} from "../../store/auth/slice";
import { Role } from "../../lib/object/value/role";
import { RootState } from "../../store";
import { ProcessLoader } from "../ui/other/ProcessLoader/ProcessLoader";
import { useEffect } from "react";
import useConfigFile from "../../hooks/use-config-file";
import { hasValue } from "../../lib/util/common-util";

type RoleRequiredRouteProps = {
  roles?: Role[];
};

/**
 * ログイン済みのユーザーのみがアクセス可能なページを設定するルートコンポーネント。
 */
export const ProtectedRoute = (props: RoleRequiredRouteProps) => {
  const { isMaintenance } = useConfigFile();

  // アクセス権限を保持しているかどうかを取得
  const isAuthorized = useSelector((state) =>
    selectHasAnyRoleOf(state, props.roles ?? []),
  );
  const authenticationStatus = useSelector(selectAuthenticationStatus);
  const { enabledMfa, userInfo } = useSelector(
    (state: RootState) => state.auth,
  );
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // 未ログインの場合は強制的にログイン画面を表示
    if (authenticationStatus === "unauthenticated") {
      navigate("/login", { state: { from: location } });
      return;
    }

    // 事務局以外 かつ メンテナンス中 の場合は503ページに強制遷移
    if (
      hasValue(userInfo) &&
      userInfo.role !== "office_member" &&
      isMaintenance === true
    ) {
      navigate("/error/503");
      return;
    }

    // 申請者 かつ 二段階認証未設定 かつ 二段階認証設定画面にいない場合はページを強制遷移
    if (
      userInfo?.role === "applicant" &&
      !enabledMfa &&
      location.pathname !== "/mfa-settings" // この条件が無いと無限ループする
    ) {
      // 申請者で二段階認証が設定されていない場合は二段階認証設定画面を表示
      navigate("/mfa-settings");
      return;
    }

    // ログイン済み かつ アクセス権限が無い場合は403エラーページを表示
    if (authenticationStatus === "authenticated" && !isAuthorized) {
      navigate("/error/403", { replace: true });
      return;
    }
  }, [
    location,
    navigate,
    authenticationStatus,
    userInfo,
    enabledMfa,
    isAuthorized,
    isMaintenance,
  ]);

  // 処理中の場合は null を返却（何も表示しない）
  if (authenticationStatus !== "authenticated") {
    return <ProcessLoader />;
  }

  // ログイン済み かつ アクセス権限がある場合はルーターにて指定されたコンポーネントを読み込み
  return <Outlet />;
};
