import {
  messageId,
  PropertyMeta,
  ValueObjectMetaString,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.string().max(100);
const savedMinimalSchema = z.string().max(100);
const schema = z.string().min(1).max(100);
const savedSchema = z.string().min(1).max(100);
const jsonSchema = z.string();
/**
 * 添付資料名(値オブジェクト)のメタデータ。
 *
 *  */
export const attachmentFileNameMeta = new ValueObjectMetaString(
  "AttachmentFileName",
  "\u6DFB\u4ED8\u8CC7\u6599\u540D",
  messageId("lbl.\u6DFB\u4ED8\u8CC7\u6599\u540D"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
