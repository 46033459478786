import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * 問題点有無(値オブジェクト)
 *
 *
 *
 * has_problem:
 * none:  */
export type ProblemPresence = z.infer<typeof problemPresenceMeta.schema>;
/**
 * 問題点有無(値オブジェクト)のメタデータ。
 *
 *  */
export const problemPresenceMeta = new ValueObjectMetaEnum<
  ["has_problem", "none"]
>(
  "ProblemPresence",
  "\u554F\u984C\u70B9\u6709\u7121",
  messageId("lbl.\u554F\u984C\u70B9\u6709\u7121"),
  ["has_problem", "none"],
  {
    has_problem: "code.\u554F\u984C\u70B9\u6709\u7121.\u6709",
    none: "code.\u554F\u984C\u70B9\u6709\u7121.\u7121",
  }
);
