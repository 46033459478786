import { useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  SearchProjectApiArg,
  useSearchProjectQuery,
} from "../../../../store/api/generated/stock-request-api";
import {
  getAllEnumFromSearchParams,
  getDateStringFromSearchParams,
  getEnumFromSearchParams,
  getNumberFromSearchParams,
  getStringFromSearchParams,
} from "../../../../lib/util/search-params-util";
import { projectSortConditionMeta } from "../../../../lib/object/value/project-sort-condition";
import { foreignTypeMeta } from "../../../../lib/object/value/foreign-type";
import { commercialTypeMeta } from "../../../../lib/object/value/commercial-type";
import { terminationReportSubmittedMeta } from "../../../../lib/object/value/termination-report-submitted";
import { projectStateMeta } from "../../../../lib/object/value/project-state";
import { projectControlNumberMeta } from "../../../../lib/object/value/project-control-number";
import { projectNameMeta } from "../../../../lib/object/value/project-name";
import { institutionIdMeta } from "../../../../lib/object/value/institution-id";
import { appUserIdMeta } from "../../../../lib/object/value/app-user-id";
import { collaborativePartnerNameMeta } from "../../../../lib/object/value/collaborative-partner-name";
import { searchConditionLimitMeta } from "../../../../lib/object/value/search-condition-limit";
import { searchConditionPageNumberMeta } from "../../../../lib/object/value/search-condition-page-number";
import { cellStockIdMeta } from "../../../../lib/object/value/cell-stock-id";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import { selectUserInfo } from "../../../../store/auth/slice";
import { hasValue, isNullish } from "../../../../lib/util/common-util";
import {
  projectMeta,
  ProjectSaved,
} from "../../../../lib/object/entity/project";
import { useSearchResultPaginationNumbers } from "../../../../hooks/use-search-result-pagination-numbers";
import { HStack, useDisclosure } from "@chakra-ui/react";
import { downloadAsCSVFromResp } from "../../../../lib/util/app-util";
import { CMButton } from "@pscsrvlab/psc-react-components";
import { SearchConditionSidebarProject } from "./_components/SearchConditionSidebarProject/SearchConditionSidebarProject";
import { SearchResultArea } from "../../../ui/search/SearchResultArea/SearchResultArea";
import { SearchResultTableProject } from "./_components/ProjectSearchTable/SearchResultTableProject";
import { useAppTranslation } from "../../../../hooks/use-app-translation";
import { ConfirmationModal } from "../../../ui/modal/ConfirmationModal/ConfirmationModal";
import { stockRequestApi } from "../../../../store/api/enhanced-api";
import { errorMessageOf } from "../../../../lib/util/error-util";
import useCustomToast from "../../../../hooks/use-custom-toast";
import { CSVDownloadButton } from "../../../ui/button/CSVDownloadButton/CSVDownloadButton";
import { useSearchPage } from "../../../../hooks/use-search-page";
import log from "loglevel";
import { AppContext } from "../../../../AppContextProvider";

/**
 * FN30-S01案件検索
 */
export const FN30S01ProjectSearch = () => {
  const { searchArgs, handleChangeSearchCondition, handleChangePage } =
    useSearchPage(urlSearchParamsToSearchArgs);

  const { t } = useAppTranslation();

  // 画面遷移
  const navigate = useNavigate();

  // ログインユーザー情報
  const currentUserInfo = useAppSelector(selectUserInfo);

  // ログインユーザーが事務局の場合,trueを設定する
  const isOfficeMember = useMemo(() => {
    if (isNullish(currentUserInfo)) return false;
    return currentUserInfo.role === "office_member";
  }, [currentUserInfo]);

  // ログインユーザーが財団側のメンバーの場合,trueを設定する
  const isFoundationMember = useMemo(() => {
    if (isNullish(currentUserInfo)) return false;
    return (
      currentUserInfo.role === "office_member" ||
      currentUserInfo.role === "committee_member" ||
      currentUserInfo.role === "executive_director"
    );
  }, [currentUserInfo]);

  /**
   * 検索処理。
   */
  const { data, isLoading } = useSearchProjectQuery(searchArgs);

  // 取得したデータをオブジェクトに変換する
  const projects: ProjectSaved[] = useMemo(() => {
    if (isNullish(data)) return [];
    return data.items
      .map((v) => {
        // 案件は下書きの場合がありうるため、skipOptionalはtrueとする。
        return projectMeta.toSavedDomainObjectOrNull(v, true);
      })
      .filter(hasValue);
  }, [data]);
  const { totalElements, currentPageNumber, totalPageCount } =
    useSearchResultPaginationNumbers(searchArgs, data);

  // 案件強制作成の確認モーダル制御用
  const { isOpen, onOpen, onClose } = useDisclosure();

  // CSVダウンロードの確認モーダル制御用
  const {
    isOpen: isOpenCSV,
    onOpen: onOpenCSV,
    onClose: onCloseCSV,
  } = useDisclosure();

  // CSVダウンロード開始トースト
  const { infoToast } = useCustomToast();

  // CSVダウンロード状態
  const { isProjectContentCsvDownloading, setIsProjectContentCsvDownloading } =
    useContext(AppContext);

  // 確認モーダルで確定ボタンが押された場合の処理
  const handleSubmitForceCreate = () => {
    // モーダルを非表示にする
    onClose();
    // 画面遷移
    navigate("/project/force-create/create");
  };

  const [triggerDownloadCsv] = stockRequestApi.useLazyCreateProjectCsvQuery();
  const handleDownloadCsv = async () => {
    try {
      // トースト表示
      infoToast(t("mes.CSVダウンロード開始メッセージ"));
      // ボタンをロード中の見た目にする
      setIsProjectContentCsvDownloading(true);
      const result = await triggerDownloadCsv(searchArgs).unwrap();
      downloadAsCSVFromResp(t("lbl.案件"), result);
    } catch (e) {
      log.error(errorMessageOf(e));
    } finally {
      // ボタンをロード中の見た目から戻す
      setIsProjectContentCsvDownloading(false);
    }
  };

  return (
    <HStack flex={1} spacing={0} overflow={"hidden"} alignItems={"stretch"}>
      {/*案件強制作成の確認モーダル*/}
      <ConfirmationModal
        isOpen={isOpen}
        message={t("mes.案件強制作成確認メッセージ")}
        onSubmit={handleSubmitForceCreate}
        onCancel={onClose}
      />

      {/*CSVダウンロード確認モーダル*/}
      <ConfirmationModal
        isOpen={isOpenCSV}
        message={t("mes.CSVダウンロード確認メッセージ")}
        onSubmit={handleDownloadCsv}
        onCancel={onCloseCSV}
      />

      {/*検索条件エリア*/}
      <SearchConditionSidebarProject
        searchArgs={searchArgs}
        isFoundationMember={isFoundationMember}
        onChange={handleChangeSearchCondition}
      />

      <SearchResultArea
        isLoading={isLoading}
        currentPageNumber={currentPageNumber}
        totalPageCount={totalPageCount}
        totalElements={totalElements}
        onPageChange={handleChangePage}
        upperRightElement={
          isOfficeMember && (
            <>
              <CSVDownloadButton
                isDownLoading={isProjectContentCsvDownloading}
                sx={{ alignSelf: "flex-end" }}
                onClick={onOpenCSV}
              />
              <CMButton
                alignSelf={"flex-end"}
                size={"sm"}
                label={t("btn.案件を強制作成するボタン")}
                onClick={onOpen}
              />
            </>
          )
        }
        upperElementHeight={90}
      >
        <SearchResultTableProject sx={{ flex: 1 }} entities={projects} />
      </SearchResultArea>
    </HStack>
  );
};

/**
 * クエリパラメータから検索条件を作成する.
 */
function urlSearchParamsToSearchArgs(
  searchParams: URLSearchParams,
): SearchProjectApiArg {
  const sortCondition =
    getEnumFromSearchParams(
      searchParams,
      "sortCondition",
      projectSortConditionMeta,
    ) ?? "updated_at_desc";
  const limit =
    getNumberFromSearchParams(
      searchParams,
      "limit",
      searchConditionLimitMeta,
    ) ?? 10;

  const projectControlNumber = getStringFromSearchParams(
    searchParams,
    "projectControlNumber",
    projectControlNumberMeta.partial,
  );
  const projectName = getStringFromSearchParams(
    searchParams,
    "projectName",
    projectNameMeta.partial,
  );
  const projectState = getAllEnumFromSearchParams(
    searchParams,
    "projectState",
    projectStateMeta,
  );
  const newApplicationSubmissionDateFrom = getDateStringFromSearchParams(
    searchParams,
    "newApplicationSubmissionDateFrom",
  );
  const newApplicationSubmissionDateTo = getDateStringFromSearchParams(
    searchParams,
    "newApplicationSubmissionDateTo",
  );
  const newApplicationApprovalDateFrom = getDateStringFromSearchParams(
    searchParams,
    "newApplicationApprovalDateFrom",
  );
  const newApplicationApprovalDateTo = getDateStringFromSearchParams(
    searchParams,
    "newApplicationApprovalDateTo",
  );
  const usageEndDateFrom = getDateStringFromSearchParams(
    searchParams,
    "usageEndDateFrom",
  );
  const usageEndDateTo = getDateStringFromSearchParams(
    searchParams,
    "usageEndDateTo",
  );
  const nextAnnualReportDeadlineDateFrom = getDateStringFromSearchParams(
    searchParams,
    "nextAnnualReportDeadlineDateFrom",
  );
  const nextAnnualReportDeadlineDateTo = getDateStringFromSearchParams(
    searchParams,
    "nextAnnualReportDeadlineDateTo",
  );
  const terminationReportSubmitted = getAllEnumFromSearchParams(
    searchParams,
    "terminationReportSubmitted",
    terminationReportSubmittedMeta,
  );
  const institutionId = getNumberFromSearchParams(
    searchParams,
    "institutionId",
    institutionIdMeta,
  );
  const foreignType = getAllEnumFromSearchParams(
    searchParams,
    "foreignType",
    foreignTypeMeta,
  );
  const commercialType = getAllEnumFromSearchParams(
    searchParams,
    "commercialType",
    commercialTypeMeta,
  );
  const principalInvestigatorAppUserId = getNumberFromSearchParams(
    searchParams,
    "principalInvestigatorAppUserId",
    appUserIdMeta,
  );
  const collaborativePartnerName = getStringFromSearchParams(
    searchParams,
    "collaborativePartnerName",
    collaborativePartnerNameMeta.partial,
  );
  const collaborativePartnerForeignType = getAllEnumFromSearchParams(
    searchParams,
    "collaborativePartnerForeignType",
    foreignTypeMeta,
  );
  const collaborativePartnerCommercialType = getAllEnumFromSearchParams(
    searchParams,
    "collaborativePartnerCommercialType",
    commercialTypeMeta,
  );
  const requestingCellsCellId = getNumberFromSearchParams(
    searchParams,
    "requestingCellsCellId",
    cellStockIdMeta,
  );
  const shippingCellsCellId = getNumberFromSearchParams(
    searchParams,
    "shippingCellsCellId",
    cellStockIdMeta,
  );
  const shippingCellsDateFrom = getDateStringFromSearchParams(
    searchParams,
    "shippingCellsDateFrom",
  );
  const shippingCellsDateTo = getDateStringFromSearchParams(
    searchParams,
    "shippingCellsDateTo",
  );

  const pageNumber = getNumberFromSearchParams(
    searchParams,
    "pageNumber",
    searchConditionPageNumberMeta,
  );

  return {
    projectControlNumber,
    projectName,
    projectState,
    newApplicationSubmissionDateFrom,
    newApplicationSubmissionDateTo,
    newApplicationApprovalDateFrom,
    newApplicationApprovalDateTo,
    usageEndDateFrom,
    usageEndDateTo,
    nextAnnualReportDeadlineDateFrom,
    nextAnnualReportDeadlineDateTo,
    terminationReportSubmitted,
    institutionId,
    foreignType,
    commercialType,
    principalInvestigatorAppUserId,
    collaborativePartnerName,
    collaborativePartnerForeignType,
    collaborativePartnerCommercialType,
    requestingCellsCellId,
    shippingCellsCellId,
    shippingCellsDateFrom,
    shippingCellsDateTo,
    sortCondition,
    limit,
    pageNumber,
  };
}
