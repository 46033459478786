import { Text, VStack } from "@chakra-ui/react";
import { CMButton } from "@pscsrvlab/psc-react-components";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { useProjectPagePathPrefix } from "../../../../hooks/use-project-page-path-prefix";
import { useAppParams } from "../../../../hooks/use-app-params";
import { isNullish } from "../../../../lib/util/common-util";
import { useAppTranslation } from "../../../../hooks/use-app-translation";

export const FN40S23RevisionRequestComplete = () => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();

  const { documentId } = useAppParams();
  const { projectPagePathPrefix } = useProjectPagePathPrefix();
  const handleClick = useCallback(() => {
    if (isNullish(documentId)) return;
    navigate(
      `${projectPagePathPrefix}/document/${documentId}/revision-request`,
    );
  }, [projectPagePathPrefix, navigate, documentId]);

  return (
    <VStack mt={"20px"}>
      <Text color={"gray.900"} fontSize={"2xl"} fontWeight={"bold"}>
        {t("mes.修正依頼完了メッセージ")}
      </Text>
      <Text color={"gray.900"} size={"sm"} whiteSpace={"pre-wrap"}>
        {t("gdc.修正依頼完了案内")}
      </Text>
      <CMButton
        label={t("btn.修正依頼一覧に戻るボタン")}
        onClick={handleClick}
      />
    </VStack>
  );
};
