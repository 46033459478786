import { Flex } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import { FN30S02ProjectHeader } from "../../model/project/FN30S02ProjectHeader/FN30S02ProjectHeader";
import { FN30S03ProjectMenu } from "../../model/project/FN30S03ProjectMenu/FN30S03ProjectMenu";

export const ProjectLayout = () => {
  return (
    <Flex
      flex={1}
      alignSelf={"stretch"}
      direction={"column"}
      alignItems={"stretch"}
    >
      <FN30S02ProjectHeader />
      <Flex direction={"row"} flexGrow={1} flexBasis={0} overflowY={"hidden"}>
        <Flex
          flex={"0 0 216px"}
          overflowY={"auto"}
          direction={"column"}
          justifyContent={"stretch"}
          alignItems={"stretch"}
          borderRight={"solid 1px"}
          borderColor={"gray.400"}
        >
          <FN30S03ProjectMenu />
        </Flex>
        <Flex
          flexGrow={1}
          flexBasis={0}
          minW={0}
          direction={"column"}
          justifyContent={"stretch"}
          alignItems={"stretch"}
          overflow={"hidden"}
        >
          <Outlet />
        </Flex>
      </Flex>
    </Flex>
  );
};
