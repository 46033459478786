import {
  messageId,
  PropertyMeta,
  ValueObjectMetaComposite,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { changeApplicationContentCustomValidation } from "../custom-validation/change-application-content";
import { changeReasonMeta } from "../value/change-reason";
import { jsonPatchOperationMeta } from "../value/json-patch-operation";
/**
 * 変更申請内容(値オブジェクト)
 *
 *  */
export type ChangeApplicationContent = z.infer<typeof schema>;
export type ChangeApplicationContentSaved = z.infer<typeof savedSchema>;
const minimalSchema = z.object({
  changeReasons: z.array(changeReasonMeta.minimalSchema),
  changeItems: z.array(jsonPatchOperationMeta.minimalSchema),
});
const savedMinimalSchema = z.object({
  changeReasons: z.array(changeReasonMeta.savedMinimalSchema),
  changeItems: z.array(jsonPatchOperationMeta.savedMinimalSchema),
});
const schema = z.object({
  changeReasons: z.array(changeReasonMeta.schema),
  changeItems: z.array(jsonPatchOperationMeta.schema),
});
const savedSchema = z.object({
  changeReasons: z.array(changeReasonMeta.savedSchema),
  changeItems: z.array(jsonPatchOperationMeta.savedSchema),
});
const jsonSchema = z.object({
  changeReasons: z
    .array(changeReasonMeta.jsonSchema)
    .nullish()
    .transform((x) => x ?? undefined),
  changeItems: z
    .array(jsonPatchOperationMeta.jsonSchema)
    .nullish()
    .transform((x) => x ?? undefined),
});
/**
 * 変更申請内容(値オブジェクト)のメタデータ。
 *
 *
 *
 * 以下のカスタムバリデーションを、 changeApplicationContentCustomValidation にて行う。
 * - (任意) S02相関チェックシート．添付資料（代表機関用）．研究者の略歴使用実績なし (エラーメッセージID=mes.研究者の略歴必須エラーメッセージ)
 * - (任意) S02相関チェックシート．添付資料（代表機関用）．研究責任者の所属する機関の概要使用実績なし (エラーメッセージID=mes.研究責任者の所属する機関の概要必須エラーメッセージ)
 * - (任意) S02相関チェックシート．添付資料（代表機関用）．研究実績を示す資料使用実績なし (エラーメッセージID=mes.研究実績を示す資料必須エラーメッセージ)
 * - (任意) S02相関チェックシート．添付資料（代表機関用）．使用施設の適格性を示す文書使用実績なし (エラーメッセージID=mes.使用施設の適格性を示す文書必須エラーメッセージ)
 * - 使用終了日期間妥当性チェック (エラーメッセージID=mes.使用終了日妥当性チェックエラー)
 * - 分担機関有の場合に分担機関が0件の場合はエラーとする。 (エラーメッセージID=mes.分担機関必須エラー) */
export const changeApplicationContentMeta = new ValueObjectMetaComposite(
  "ChangeApplicationContent",
  "\u5909\u66F4\u7533\u8ACB\u5185\u5BB9",
  messageId("lbl.\u5909\u66F4\u7533\u8ACB\u5185\u5BB9"),
  minimalSchema,
  savedMinimalSchema,
  schema,
  savedSchema,
  jsonSchema,
  undefined,
  changeApplicationContentCustomValidation,
  {
    changeReasons: new PropertyMeta(
      "changeReasons",
      "\u5909\u66F4\u7406\u7531",
      messageId("lbl.\u5909\u66F4\u7406\u7531"),
      "always",
      true,
      changeReasonMeta
    ),
    changeItems: new PropertyMeta(
      "changeItems",
      "\u5909\u66F4\u9805\u76EE",
      messageId("lbl.\u5909\u66F4\u9805\u76EE"),
      "always",
      true,
      jsonPatchOperationMeta
    ),
  }
);
