import { ComponentStyleProps } from "../../../../../../../../../lib/styles/props/component-style-props";
import { CMFormInputTextArea, Comments } from "@pscsrvlab/psc-react-components";
import { researchSiteMeta } from "../../../../../../../../../lib/object/value/research-site";
import React, { memo } from "react";
import { CMButtonFormCommentProps } from "@pscsrvlab/psc-react-components/src/components/form/comment";
import { useSectionDiffMode } from "../../../../../../../../../hooks/document/change-application/use-section-diff-mode";
import { useChangeBadgeProperties } from "../../../../../../../../../hooks/document/change-application/use-change-badge-properties";
import { ChangeReasonForm } from "../../form/ChangeReasonForm/ChangeReasonForm";
import { FormSection } from "../../../../../../../../ui/form/FormSection/FormSection";
import { useAppTranslation } from "../../../../../../../../../hooks/use-app-translation";
import { hasValue } from "../../../../../../../../../lib/util/common-util";
import { ChangeReasonItemViewModel } from "../../../../../../../../../lib/object/vm/change-application-view-model";

export type ResearchSiteSectionProps = {
  documentType:
  | "new_application"
  | "change_application"
  | "project_force_update"
  | "project_force_create"
  | "project_content";
  editMode: "editable" | "readOnly";

  value: string;
  onChange?: (value: string) => void;

  changedFrom?: string;
  changeReason?: string;
  onChangeReason?: (
    change: (before: ChangeReasonItemViewModel) => ChangeReasonItemViewModel,
  ) => void;
  displayChangesOnly?: boolean;

  /**
   * コメントボタンのprops。
   * これが存在すれば、コメントボタンを表示する。
   */
  commentButtonProps?: CMButtonFormCommentProps;
  /**
   * 1セクション単位の修正依頼コメント・返信。履歴参照画面で使用。
   */
  commentsList?: Comments[];
} & ComponentStyleProps;

/**
 * 研究実施場所セクション
 */
export const ResearchSiteSection = memo(function ResearchSiteSection({
  documentType,
  editMode,

  value,
  onChange,

  changedFrom,
  changeReason,
  onChangeReason,
  displayChangesOnly,

  commentButtonProps,
  commentsList,

  sx,
  ...rest
}: ResearchSiteSectionProps) {
  const { t } = useAppTranslation();

  const sectionDiffMode = useSectionDiffMode(
    documentType,
    changedFrom,
    value,
    onChangeReason,
  );

  const changeBadgeProperties = useChangeBadgeProperties(
    documentType,
    changedFrom,
  );

  return (
    <FormSection
      title={t("lbl.研究実施場所")}
      description={
        editMode === "editable" ? t("gdc.研究実施場所補足") : undefined
      }
      displayMode={sectionDiffMode}
      displayChangesOnly={displayChangesOnly}
      commentButtonProps={commentButtonProps}
      commentsList={commentsList}
      sx={sx}
      {...rest}
    >
      <CMFormInputTextArea
        resize={editMode === "editable" ? "vertical" : "none"}
        editMode={editMode}
        noHeader={!hasValue(changedFrom)}
        valueObjectMeta={researchSiteMeta}
        value={value}
        onChange={onChange}
        changeBadgeProperties={changeBadgeProperties}
      />
      {sectionDiffMode === "updated" && (
        <ChangeReasonForm
          value={changeReason}
          onChange={onChangeReason}
          editMode={editMode}
        />
      )}
    </FormSection>
  );
});
