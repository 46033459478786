import { InPageLink } from "../InPageLink/InPageLink";
import React, { memo, Ref, useMemo } from "react";
import { ComponentStyleProps } from "../../../../../../../../../lib/styles/props/component-style-props";
import { useAppTranslation } from "../../../../../../../../../hooks/use-app-translation";

export type InPageLinkApplicationDocumentProps = {
  scrollableRef: Ref<any>;
  scrollOffset: number;
} & ComponentStyleProps;
export const InPageLinkApplicationDocument = memo(
  function InPageLinkApplicationDocument({
    scrollableRef,
    scrollOffset,

    sx,
    ...rest
  }: InPageLinkApplicationDocumentProps) {
    const { t } = useAppTranslation();
    const inPageLinkList = useMemo(
      () => [
        { label: t("lbl.代表機関"), id: "main" },
        { label: t("lbl.添付資料"), id: "attachmentFiles" },
        { label: t("lbl.分担機関"), id: "partners" },
      ],
      [t],
    );

    return (
      <InPageLink
        linkList={inPageLinkList}
        scrollableRef={scrollableRef}
        scrollOffset={scrollOffset}
        sx={sx}
        {...rest}
      />
    );
  },
);
