import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * 審査会内容通知状況(値オブジェクト)
 *
 *
 *
 * not_notified:
 * notified:  */
export type ReviewConferenceNotificationState = z.infer<
  typeof reviewConferenceNotificationStateMeta.schema
>;
/**
 * 審査会内容通知状況(値オブジェクト)のメタデータ。
 *
 *  */
export const reviewConferenceNotificationStateMeta = new ValueObjectMetaEnum<
  ["not_notified", "notified"]
>(
  "ReviewConferenceNotificationState",
  "\u5BE9\u67FB\u4F1A\u5185\u5BB9\u901A\u77E5\u72B6\u6CC1",
  messageId("lbl.\u5BE9\u67FB\u4F1A\u5185\u5BB9\u901A\u77E5\u72B6\u6CC1"),
  ["not_notified", "notified"],
  {
    not_notified:
      "code.\u5BE9\u67FB\u4F1A\u5185\u5BB9\u901A\u77E5\u72B6\u6CC1.\u672A\u901A\u77E5",
    notified:
      "code.\u5BE9\u67FB\u4F1A\u5185\u5BB9\u901A\u77E5\u72B6\u6CC1.\u901A\u77E5\u6E08",
  }
);
