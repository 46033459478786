import { ChangeApplicationSaved } from "../../../lib/object/entity/change-application";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  ApplicationContent,
  applicationContentMeta,
} from "../../../lib/object/value/application-content";
import { useAppGetProjectSnapshotQuery } from "../../query/use-app-get-project-snapshot-query";
import { hasValue, isNullish } from "../../../lib/util/common-util";
import { projectMeta, ProjectSaved } from "../../../lib/object/entity/project";
import jsonpatch from "fast-json-patch";
import { Operation } from "fast-json-patch/commonjs/core";
import { ChangeReason } from "../../../lib/object/value/change-reason";
import { useAppGetProjectQuery } from "../../query/use-app-get-project-query";
import {
  useCreateChangeApplicationDraftMutation,
  useDeleteDocumentDraftMutation,
  useUpdateChangeApplicationContentMutation,
} from "../../../store/api/generated/stock-request-api";
import { useCreateJsonPatchOperations } from "../../use-create-json-patch-operations";
import {
  ChangeApplicationContent,
  changeApplicationContentMeta,
} from "../../../lib/object/value/change-application-content";
import { ValidationError } from "@pscsrvlab/psc-react-components";
import log from "loglevel";
import { errorMessageOf } from "../../../lib/util/error-util";
import {
  ChangeApplicationViewModel,
  ChangeReasonItemViewModel,
  ChangeReasonViewModel,
} from "../../../lib/object/vm/change-application-view-model";
import {
  SignatureValue,
  signatureValueMeta,
} from "../../../lib/object/value/signature-value";
import { merge } from "lodash";
import { ApplicationContentViewModel } from "../../../components/model/document/application/_components/ApplicationDocument/ApplicationDocument";
import { useApplicationContentValidation } from "../application-content/use-application-content-validation";

export function useChangeApplicationVMOrEmpty(
  /**
   * 案件ID。
   */
  projectId: number,

  /**
   * 書類ID。
   * この値がない場合、
   * 案件IDから新規の（空の）変更申請ビューモデルを作成する。
   */
  documentId: number | undefined,

  changeApplication?: ChangeApplicationSaved | null,

  options?: {
    /**
     * skipがtrueの場合、何も行わない。
     */
    skip: boolean;
  },
) {
  const _options = useMemo(() => merge({ skip: false }, options), [options]);

  /**
   * 初期表示時に渡す、ビューモデル。
   * 一度作成したら二度と更新しない。
   */
  const [initialChangeApplicationVM, setInitialChangeApplicationVM] =
    useState<ChangeApplicationViewModel | null>(null);

  /**
   * 初期化済ならtrue。
   * 一度でも初期化されていたら、間違って更新してしまわないようにするために用いる。
   */
  const isInitialized = useMemo(() => {
    return hasValue(initialChangeApplicationVM);
  }, [initialChangeApplicationVM]);

  // 保存済の変更申請から作成する場合。
  const vmFromSaved = useChangeApplicationVM(changeApplication);
  useEffect(() => {
    if (_options.skip || isInitialized) return;

    if (isNullish(vmFromSaved)) return;

    // ビューモデル初期値に値を設定する。
    setInitialChangeApplicationVM(vmFromSaved);
  }, [_options.skip, isInitialized, vmFromSaved]);

  // 新規の変更申請VMを作成する場合、①案件を取得する。
  const { data: projectData } = useAppGetProjectQuery(
    { projectId: projectId ?? -1 },
    {
      skip:
        _options.skip ||
        isNullish(projectId) ||
        hasValue(documentId) ||
        hasValue(changeApplication),
    },
  );
  // 新規の変更申請VMを作成する場合、②initialViewModelとbeforeValueの両方に、取得した案件の書類内容を設定し、initialChangeReasonVMには空を設定する。
  useEffect(() => {
    if (_options.skip || isInitialized) return;

    // 案件が取得できていなければ何もしない。
    if (isNullish(projectData)) return;

    const project = projectMeta.toSavedDomainObjectOrNull(projectData);
    if (isNullish(project)) return;

    setInitialChangeApplicationVM({
      type: "change_application",

      projectId: project.id,
      projectControlNumber: project.projectControlNumber,
      documentControlNumber: undefined,

      submissionDate: undefined,
      receptionDate: undefined,
      approvalDate: undefined,
      documentCompletionDate: undefined,

      contentVM: project.applicationContent,
      signatureValue: { correctnessCheck: "unchecked" },
      beforeValue: project.applicationContent,
      changeReasonVM: {
        projectName: { reason: "", changed: false },
        institutionName: { reason: "", changed: false },
        principalInvestigator: { reason: "", changed: false },
        contactPeople: { reason: "", changed: false },
        requestingCells: { reason: "", changed: false },
        ipsCellStockUsePurpose: { reason: "", changed: false },
        ipsCellStockRequirementReason: { reason: "", changed: false },
        planSummary: { reason: "", changed: false },
        hasCollaborativePartner: { reason: "", changed: false },
        usageEndDate: { reason: "", changed: false },
        researchSite: { reason: "", changed: false },
        ipsCultureExperience: { reason: "", changed: false },
        ethicalApplicationStatus: { reason: "", changed: false },
        handlingAfterUse: { reason: "", changed: false },
        foundationContractState: { reason: "", changed: false },
        attachmentFiles: { reason: "", changed: false },
        partners: [],
      },
    });
  }, [projectData, isInitialized, _options.skip]);

  const [triggerCreateDraft] = useCreateChangeApplicationDraftMutation();
  const [triggerDeleteDraft] = useDeleteDocumentDraftMutation();
  const [triggerUpdateContent] = useUpdateChangeApplicationContentMutation();

  const {
    createJsonPatchOperations: createApplicationContentJsonPatchOperations,
  } = useCreateJsonPatchOperations(applicationContentMeta);
  const {
    createJsonPatchOperations:
      createChangeApplicationContentJsonPatchOperations,
  } = useCreateJsonPatchOperations(changeApplicationContentMeta);

  // バリデーションエラー。
  const { validate: validateApplicationContent } =
    useApplicationContentValidation();
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>(
    [],
  );

  /**
   * ビューモデルを変更申請内容に変換する。
   * このとき、すべてのバリデーションを行う。
   */
  const vmToChangeApplicationContent = useCallback(
    async (
      contentVM: ApplicationContentViewModel,
      changeReasonVM: ChangeReasonViewModel,
      skipOptionalValidations: boolean,
    ): Promise<
      | { state: "ok"; value: ChangeApplicationContent }
      | { state: "error"; errors: ValidationError[] }
      | { state: "unexpectedError" }
    > => {
      if (isNullish(initialChangeApplicationVM))
        return { state: "unexpectedError" };

      const validationResult = await validateApplicationContent(
        contentVM,
        skipOptionalValidations,
      );
      if (validationResult.state !== "ok") return validationResult;

      const applicationContent: ApplicationContent = validationResult.value;

      const changeReasons = viewModelToChangeReason(changeReasonVM);
      const changeItems = createApplicationContentJsonPatchOperations(
        initialChangeApplicationVM.beforeValue,
        applicationContent,
      );
      // log.debug(`vmToChangeApplicationContent: beforeValue=`, beforeValue);
      // log.debug(
      //   `vmToChangeApplicationContent: applicationContent=`,
      //   applicationContent,
      // );
      // log.debug(`vmToChangeApplicationContent: changeItems=`, changeItems);
      if (isNullish(changeItems)) return { state: "unexpectedError" };

      const _changeApplicationContent = { changeReasons, changeItems };
      const validationState = changeApplicationContentMeta.validate(
        _changeApplicationContent,
        skipOptionalValidations,
      );

      if (validationState.state === "error") {
        return { state: "error", errors: validationState.errors };
      }

      return { state: "ok", value: _changeApplicationContent };
    },
    [
      initialChangeApplicationVM,
      createApplicationContentJsonPatchOperations,
      validateApplicationContent,
    ],
  );

  /**
   * 変更申請の下書きを新規作成する。
   * applicationContentのバリデーションは済んでいる前提とする。
   * changeApplicationに変換後のバリデーションは本関数内で行う。
   *
   * 成功・失敗に関わらず、結果を返却するだけなので、トーストを出すなどの後処理は呼び出し側の責務とする。
   */
  const createChangeApplicationDraft = useCallback(
    async (
      content: ApplicationContentViewModel,
      signatureValue: SignatureValue,
      changeReasonVM: ChangeReasonViewModel,
      skipOptionalValidations: boolean,
    ): Promise<
      | { state: "ok"; documentId: number }
      | { state: "error"; errors: ValidationError[] }
      | { state: "unexpectedError" }
    > => {
      if (isNullish(projectId)) {
        log.error(
          "案件IDが未指定のため、変更申請の下書き新規作成は行えません。",
        );
        return { state: "unexpectedError" };
      }

      if (hasValue(documentId)) {
        log.error(
          "書類IDが指定されているため、変更申請の下書き新規作成は行えません。",
        );
        return { state: "unexpectedError" };
      }

      const result = await vmToChangeApplicationContent(
        content,
        changeReasonVM,
        skipOptionalValidations,
      );
      if (result.state !== "ok") return result;

      const signatureValueValidationResult = signatureValueMeta.validate(
        signatureValue,
        skipOptionalValidations,
      );
      if (signatureValueValidationResult.state !== "ok") {
        return signatureValueValidationResult;
      }

      const contentJson = changeApplicationContentMeta.toJsonObjectOrNull(
        result.value,
      );
      if (isNullish(contentJson)) return { state: "unexpectedError" };

      try {
        const createResult = await triggerCreateDraft({
          projectId,
          correctnessCheck: signatureValue.correctnessCheck,
          changeApplicationContent: contentJson,
        }).unwrap();
        const documentId = createResult.id;
        if (isNullish(documentId)) return { state: "unexpectedError" };
        return { state: "ok", documentId };
      } catch (e) {
        log.error(errorMessageOf(e));
        return { state: "unexpectedError" };
      }
    },
    [projectId, documentId, triggerCreateDraft, vmToChangeApplicationContent],
  );

  /**
   * 変更申請内容を更新する。
   * applicationContentのバリデーションは済んでいる前提とする。
   * changeApplicationに変換後のバリデーションは本関数内で行う。
   *
   * 成功・失敗に関わらず、結果を返却するだけなので、トーストを出すなどの後処理は呼び出し側の責務とする。
   */
  const updateChangeApplicationContent = useCallback(
    async (
      content: ApplicationContentViewModel,
      signatureValue: SignatureValue,
      changeReasonVM: ChangeReasonViewModel,
      skipOptionalValidations: boolean,
    ): Promise<
      | { state: "ok"; documentId: number }
      | { state: "error"; errors: ValidationError[] }
      | { state: "unexpectedError" }
    > => {
      if (isNullish(documentId) || isNullish(changeApplication)) {
        log.error(
          "書類IDが未指定または変更申請が取得できていないため、変更申請内容の上書き保存は行えません。",
        );
        return { state: "unexpectedError" };
      }

      const result = await vmToChangeApplicationContent(
        content,
        changeReasonVM,
        skipOptionalValidations,
      );
      if (result.state !== "ok") return result;

      const signatureValueValidationResult = signatureValueMeta.validate(
        signatureValue,
        skipOptionalValidations,
      );
      if (signatureValueValidationResult.state !== "ok") {
        return signatureValueValidationResult;
      }

      const jsonPatchOperations =
        createChangeApplicationContentJsonPatchOperations(
          changeApplication.content,
          result.value,
        );
      if (isNullish(jsonPatchOperations)) return { state: "unexpectedError" };

      try {
        await triggerUpdateContent({
          documentId,
          correctnessCheck: signatureValue.correctnessCheck,
          commonUpdateRequest: { patchOperations: jsonPatchOperations },
        }).unwrap();
      } catch (e) {
        log.error(errorMessageOf(e));
        return { state: "unexpectedError" };
      }

      return { state: "ok", documentId };
    },
    [
      changeApplication,
      createChangeApplicationContentJsonPatchOperations,
      documentId,
      triggerUpdateContent,
      vmToChangeApplicationContent,
    ],
  );

  /**
   * 変更申請下書きを新規作成、または変更申請内容を更新する。
   */
  const saveChangeApplicationContent = useCallback(
    async (
      vm: ChangeApplicationViewModel,
      skipOptionalValidations: boolean,
    ): Promise<
      | { state: "ok"; documentId: number }
      | { state: "error"; errors: ValidationError[] }
      | { state: "unexpectedError" }
    > => {
      const result = isNullish(documentId)
        ? await createChangeApplicationDraft(
            vm.contentVM,
            vm.signatureValue,
            vm.changeReasonVM,
            skipOptionalValidations,
          )
        : await updateChangeApplicationContent(
            vm.contentVM,
            vm.signatureValue,
            vm.changeReasonVM,
            skipOptionalValidations,
          );
      if (result.state === "ok") setValidationErrors([]);
      if (result.state === "error") setValidationErrors(result.errors);

      return result;
    },
    [createChangeApplicationDraft, documentId, updateChangeApplicationContent],
  );

  const deleteChangeApplicationDraft = useCallback(async (): Promise<
    { state: "ok" } | { state: "unexpectedError" }
  > => {
    if (isNullish(documentId)) {
      log.error("書類IDが未指定のため、変更申請の下書き削除は行えません。");
      return { state: "unexpectedError" };
    }

    try {
      await triggerDeleteDraft({ documentId }).unwrap();
    } catch (e) {
      log.error(errorMessageOf(e));
      return { state: "unexpectedError" };
    }

    return { state: "ok" };
  }, [documentId, triggerDeleteDraft]);

  return {
    changeApplication,

    initialChangeApplicationVM,

    validationErrors,

    saveChangeApplicationContent,
    deleteChangeApplicationDraft,
  };
}

function changeReasonToViewModel(
  changeReasons: ChangeReason[],
): ChangeReasonViewModel {
  const getVMOf = (path: string) => {
    const reason = changeReasons.find((v) => v.path === path)?.content;
    if (isNullish(reason)) return { reason: "", changed: false };
    return { reason, changed: true };
  };

  const partnersChangeReasons = changeReasons
    .filter((v) => v.path.startsWith("/partners/"))
    .map((v): { key: number; value: ChangeReasonItemViewModel } | null => {
      const re = /\/partners\/(\d+)/;
      const m = v.path.match(re);
      if (isNullish(m)) return null;
      const key = Number.parseInt(m[1]);
      if (isNaN(key)) return null;

      return { key, value: { reason: v.content, changed: true } };
    })
    .filter(hasValue);

  log.debug(`partnersChangeReasons=`, partnersChangeReasons);

  return {
    projectName: getVMOf("/projectName"),
    institutionName: getVMOf("/institutionName"),
    principalInvestigator: getVMOf("/principalInvestigator"),
    contactPeople: getVMOf("/contactPeople"),
    requestingCells: getVMOf("/requestingCells"),
    ipsCellStockUsePurpose: getVMOf("/ipsCellStockUsePurpose"),
    ipsCellStockRequirementReason: getVMOf("/ipsCellStockRequirementReason"),
    planSummary: getVMOf("/planSummaryForm"),
    hasCollaborativePartner: getVMOf("/hasCollaborativePartner"),
    usageEndDate: getVMOf("/usageEndDate"),
    researchSite: getVMOf("/researchSite"),
    ipsCultureExperience: getVMOf("/ipsCultureExperience"),
    ethicalApplicationStatus: getVMOf("/ethicalApplicationStatus"),
    handlingAfterUse: getVMOf("/handlingAfterUse"),
    foundationContractState: getVMOf("/foundationContractState"),
    attachmentFiles: getVMOf("/attachmentFiles"),
    partners: partnersChangeReasons,
  };
}

function viewModelToChangeReason(vm: ChangeReasonViewModel): ChangeReason[] {
  const getChangeReasonOf = (
    path: string,
    itemVM: ChangeReasonItemViewModel,
  ) => {
    if (!itemVM.changed) return null;
    log.debug(
      `path=${path}, changed=${itemVM.changed}, reason=${itemVM.reason}`,
    );
    return { path, content: itemVM.reason };
  };
  return [
    getChangeReasonOf("/projectName", vm.projectName),
    getChangeReasonOf("/institutionName", vm.institutionName),
    getChangeReasonOf("/principalInvestigator", vm.principalInvestigator),
    getChangeReasonOf("/contactPeople", vm.contactPeople),
    getChangeReasonOf("/requestingCells", vm.requestingCells),
    getChangeReasonOf("/ipsCellStockUsePurpose", vm.ipsCellStockUsePurpose),
    getChangeReasonOf(
      "/ipsCellStockRequirementReason",
      vm.ipsCellStockRequirementReason,
    ),
    getChangeReasonOf("/planSummaryForm", vm.planSummary),
    getChangeReasonOf("/hasCollaborativePartner", vm.hasCollaborativePartner),
    getChangeReasonOf("/usageEndDate", vm.usageEndDate),
    getChangeReasonOf("/researchSite", vm.researchSite),
    getChangeReasonOf("/ipsCultureExperience", vm.ipsCultureExperience),
    getChangeReasonOf("/ethicalApplicationStatus", vm.ethicalApplicationStatus),
    getChangeReasonOf("/handlingAfterUse", vm.handlingAfterUse),
    getChangeReasonOf("/foundationContractState", vm.foundationContractState),
    getChangeReasonOf("/attachmentFiles", vm.attachmentFiles),
    ...vm.partners.map(({ key, value }) => {
      if (!value.changed) return null;
      return {
        path: `/partners/${key}`,
        content: value.reason,
      };
    }),
  ].filter(hasValue);
}

export function useChangeApplicationVM(
  changeApplication: ChangeApplicationSaved | null | undefined,
): ChangeApplicationViewModel | null {
  const { data: projectSnapshot } = useAppGetProjectSnapshotQuery(
    {
      projectSnapshotId:
        changeApplication?.projectSnapshotIdBeforeChangeApplication ?? -1,
    },
    {
      skip: isNullish(
        changeApplication?.projectSnapshotIdBeforeChangeApplication,
      ),
    },
  );
  const beforeProject: ProjectSaved | null = useMemo(() => {
    if (isNullish(projectSnapshot)) return null;
    const _beforeProject = projectMeta.toSavedDomainObjectOrNull(
      projectSnapshot.projectJson,
      true,
    );
    if (isNullish(_beforeProject)) {
      log.error(
        `案件スナップショットの案件への変換に失敗しました: projectSnapshot=`,
        projectSnapshot,
      );
      return null;
    }
    return _beforeProject;
  }, [projectSnapshot]);

  const beforeValue = useMemo(
    () => beforeProject?.applicationContent,
    [beforeProject?.applicationContent],
  );

  const initialApplicationContentVM = useMemo(() => {
    if (isNullish(changeApplication) || isNullish(beforeValue)) return null;
    let _initialViewModelJson: any;
    try {
      const result = jsonpatch.applyPatch(
        applicationContentMeta.toJsonObjectOrNull(beforeValue),
        changeApplication.content.changeItems as Operation[],
      );
      _initialViewModelJson = result.newDocument;
    } catch (e) {
      log.error(errorMessageOf(e));
      return;
    }

    return applicationContentMeta.toDomainObjectOrNull(
      _initialViewModelJson,
      true,
    );
  }, [beforeValue, changeApplication]);

  return useMemo(() => {
    if (
      isNullish(changeApplication) ||
      isNullish(beforeProject) ||
      isNullish(beforeValue) ||
      isNullish(initialApplicationContentVM)
    )
      return null;
    return {
      type: "change_application",

      projectId: changeApplication.projectId,
      projectControlNumber: beforeProject.projectControlNumber,
      documentControlNumber: changeApplication.documentControlNumber,

      submissionDate: changeApplication.submissionDate,
      receptionDate: changeApplication.receptionDate,
      approvalDate: changeApplication.approvalDate,
      documentCompletionDate: changeApplication.documentCompletionDate,

      contentVM: initialApplicationContentVM,
      signatureValue: changeApplication.signatureValue,
      beforeValue,
      changeReasonVM: changeReasonToViewModel(
        changeApplication.content.changeReasons,
      ),
    };
  }, [
    beforeProject,
    beforeValue,
    changeApplication,
    initialApplicationContentVM,
  ]);
}
