import { useAppParams } from "../../../../hooks/use-app-params";
import { hasValue } from "../../../../lib/util/common-util";
import { DocumentContentReadOnly } from "../../../model/document/DocumentContentReadOnly/DocumentContentReadOnly";
import { VStack } from "@chakra-ui/react";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import { selectUserInfo } from "../../../../store/auth/slice";
import { DocumentContentEdit } from "../../../model/document/DocumentContentEdit/DocumentContentEdit";

export type FN40S13DocumentContentProps = {
  mode: "view" | "edit";
};

export const FN40S13DocumentContent = ({
  mode,
}: FN40S13DocumentContentProps) => {
  const { documentId } = useAppParams();
  const loginUserInfo = useAppSelector(selectUserInfo);

  return (
    <VStack overflow={"hidden"} alignItems={"stretch"}>
      {mode === "view" && hasValue(documentId) && hasValue(loginUserInfo) && (
        <DocumentContentReadOnly
          key={documentId}
          documentId={documentId}
          loginUserInfo={loginUserInfo}
        />
      )}
      {mode === "edit" &&
        hasValue(documentId) &&
        hasValue(loginUserInfo) &&
        loginUserInfo.role === "office_member" && (
          <DocumentContentEdit
            documentId={documentId}
            loginUserInfo={loginUserInfo}
          />
        )}
    </VStack>
  );
};
