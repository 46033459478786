import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * 受領状況(値オブジェクト)
 *
 *
 *
 * not_received:
 * received:  */
export type ReceiptState = z.infer<typeof receiptStateMeta.schema>;
/**
 * 受領状況(値オブジェクト)のメタデータ。
 *
 *  */
export const receiptStateMeta = new ValueObjectMetaEnum<
  ["not_received", "received"]
>(
  "ReceiptState",
  "\u53D7\u9818\u72B6\u6CC1",
  messageId("lbl.\u53D7\u9818\u72B6\u6CC1"),
  ["not_received", "received"],
  {
    not_received: "code.\u53D7\u9818\u72B6\u6CC1.\u672A\u53D7\u9818",
    received: "code.\u53D7\u9818\u72B6\u6CC1.\u53D7\u9818\u6E08",
  }
);
