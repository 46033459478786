import { ComponentStyleProps } from "../../../../lib/styles/props/component-style-props";
import { ChangeBadgeProperties } from "@pscsrvlab/psc-react-components/src/lib";
import {
  CMFormInputText,
  ValueObjectMetaString,
} from "@pscsrvlab/psc-react-components";
import { memo } from "react";

export type LabelAndStringValueProps = {
  valueObjectMeta: ValueObjectMetaString;

  label?: string;
  value?: string | null;

  changeBadgeProperties?: ChangeBadgeProperties<string>;
} & ComponentStyleProps;
export const LabelAndStringValue = memo(function LabelAndStringValue({
  valueObjectMeta,

  label,
  value,

  changeBadgeProperties,

  sx,
  ...rest
}: LabelAndStringValueProps) {
  const capitalizedLabel = label;
  return (
    <CMFormInputText
      editMode={"readOnly"}
      size={"md"}
      valueObjectMeta={valueObjectMeta}
      label={capitalizedLabel}
      value={value}
      changeBadgeProperties={changeBadgeProperties}
      sx={{ minW: 0, ...sx }}
      {...rest}
    />
  );
});
