import { injectedRtkApi as api } from "./generated/stock-request-api";

/**
 * 拡張版バックエンドAPI
 */
const enhancedRtkApi = api.enhanceEndpoints({
  // ここに RTK Query API の設定を記載することによって、自動生成された RTK Query API に対してマージする形で設定を追加できる。
  // 参考：https://redux-toolkit.js.org/rtk-query/api/created-api/code-splitting#enhanceendpoints
  addTagTypes: [
    "AppUser",
    "CellStockDelivery",
    "Document",
    "DocumentSnapshot",
    "Review",
    "RevisionRequest",
    "Project",
    "Inquiry",
    "Institution",
    "SharedAttachmentFile",
    "ReviewConference",
    "ResultNotification",
    "WorkLog",
  ],
  endpoints: {
    // AppUser (ユーザー)
    createAppUser: {
      invalidatesTags: (_result, _error, _arg) => ["AppUser"],
    },
    getAppUser: {
      providesTags: (_result, _error, arg) => [
        { type: "AppUser", id: arg.appUserId },
      ],
    },
    listAppUser: {
      providesTags: (result, _error, _arg) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "AppUser" as const, id })),
              "AppUser",
            ]
          : ["AppUser"],
    },
    searchAppUser: {
      providesTags: (result, _error, _arg) =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: "AppUser" as const,
                id,
              })),
              "AppUser",
            ]
          : ["AppUser"],
    },
    updateAppUser: {
      invalidatesTags: (_result, _error, arg) => [
        { type: "AppUser", id: arg.appUserId },
      ],
    },
    resetTemporaryPassword: {
      invalidatesTags: (_result, _error, arg) => [
        { type: "AppUser", id: arg.appUserId },
      ],
    },
    resetMfa: {
      invalidatesTags: (_result, _error, arg) => [
        { type: "AppUser", id: arg.appUserId },
      ],
    },
    enableAppUser: {
      invalidatesTags: (_result, _error, arg) => [
        { type: "AppUser", id: arg.appUserId },
      ],
    },
    disableAppUser: {
      invalidatesTags: (_result, _error, arg) => [
        { type: "AppUser", id: arg.appUserId },
      ],
    },
    getCurrentAppUser: {
      providesTags: (result, _error, _arg) =>
        result ? [{ type: "AppUser", id: result.id }] : ["AppUser"],
    },
    changeLanguage: {
      invalidatesTags: ["AppUser"],
    },
    countPastProject: {
      providesTags: (_result, _error, arg) => [
        { type: "AppUser", id: arg.appUserId },
      ],
    },

    // CellStock (細胞)
    listCellStockCategory: {},
    listCellStock: {},

    // CellStockDelivery (細胞発送)
    createCellStockDelivery: {
      invalidatesTags: (_result, _error, _arg) => ["CellStockDelivery"],
    },
    getCellStockDelivery: {
      providesTags: (_result, _error, arg) => [
        { type: "CellStockDelivery", id: arg.cellStockDeliveryId },
      ],
    },
    listCellStockDelivery: {
      providesTags: (result, _error, _arg) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "CellStockDelivery" as const,
                id,
              })),
              "CellStockDelivery",
            ]
          : ["CellStockDelivery"],
    },
    updateCellStockDelivery: {
      invalidatesTags: (_result, _error, arg) => [
        { type: "CellStockDelivery", id: arg.cellStockDeliveryId },
      ],
    },
    deleteCellStockDelivery: {
      invalidatesTags: (_result, _error, arg) => [
        { type: "CellStockDelivery", id: arg.cellStockDeliveryId },
      ],
    },

    // Document (書類)
    getDocument: {
      providesTags: (_result, _error, arg) => [
        { type: "Document", id: arg.documentId },
      ],
    },
    listDocument: {
      providesTags: (result, _error, _arg) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Document" as const, id })),
              "Document",
            ]
          : ["Document"],
    },
    searchDocument: {
      providesTags: (result, _error, _arg) =>
        result
          ? [
              ...result.items.map(({ document: { id } }) => ({
                type: "Document" as const,
                id,
              })),
              "Document",
            ]
          : ["Document"],
    },
    getNumberOfDocumentTodos: {
      providesTags: [{ type: "Document", id: "ALL" }],
    },
    getNewApplication: {
      providesTags: (_result, _error, arg) => [
        { type: "Document", id: arg.documentId },
      ],
    },
    getChangeApplication: {
      providesTags: (_result, _error, arg) => [
        { type: "Document", id: arg.documentId },
      ],
    },
    getAnnualReport: {
      providesTags: (_result, _error, arg) => [
        { type: "Document", id: arg.documentId },
      ],
    },
    getTerminationReport: {
      providesTags: (_result, _error, arg) => [
        { type: "Document", id: arg.documentId },
      ],
    },
    createNewApplicationDraft: {
      invalidatesTags: (_result, _error, _arg) => [
        "Document",
        { type: "Document", id: "ALL" },
      ],
    },
    createChangeApplicationDraft: {
      invalidatesTags: (_result, _error, _arg) => [
        "Document",
        { type: "Document", id: "ALL" },
      ],
    },
    createAnnualReportDraft: {
      invalidatesTags: (_result, _error, _arg) => [
        "Document",
        { type: "Document", id: "ALL" },
      ],
    },
    createTerminationReportDraft: {
      invalidatesTags: (_result, _error, _arg) => [
        "Document",
        { type: "Document", id: "ALL" },
      ],
    },
    submitNewApplicationFromDraft: {
      invalidatesTags: (result, _error, arg) => [
        { type: "Document", id: arg.documentId },
        { type: "Document", id: "ALL" },
        { type: "Project", id: result?.projectId },
      ],
    },
    submitChangeApplicationFromDraft: {
      invalidatesTags: (_result, _error, arg) => [
        { type: "Document", id: arg.documentId },
        { type: "Document", id: "ALL" },
      ],
    },
    submitAnnualReportFromDraft: {
      invalidatesTags: (_result, _error, arg) => [
        { type: "Document", id: arg.documentId },
        { type: "Document", id: "ALL" },
      ],
    },
    submitTerminationReportFromDraft: {
      invalidatesTags: (_result, _error, arg) => [
        { type: "Document", id: arg.documentId },
        { type: "Document", id: "ALL" },
      ],
    },
    updateNewApplicationContent: {
      invalidatesTags: (_result, _error, arg) => [
        { type: "Document", id: arg.documentId },
        { type: "Document", id: "ALL" },
      ],
    },
    updateChangeApplicationContent: {
      invalidatesTags: (_result, _error, arg) => [
        { type: "Document", id: arg.documentId },
        { type: "Document", id: "ALL" },
      ],
    },
    updateAnnualReportContent: {
      invalidatesTags: (_result, _error, arg) => [
        { type: "Document", id: arg.documentId },
        { type: "Document", id: "ALL" },
      ],
    },
    updateTerminationReportContent: {
      invalidatesTags: (_result, _error, arg) => [
        { type: "Document", id: arg.documentId },
        { type: "Document", id: "ALL" },
      ],
    },
    receiveNewApplication: {
      invalidatesTags: (result, _error, arg) => [
        { type: "Document", id: arg.documentId },
        { type: "Document", id: "ALL" },
        { type: "Project", id: result?.projectId },
      ],
    },
    receiveChangeApplication: {
      invalidatesTags: (_result, _error, arg) => [
        { type: "Document", id: arg.documentId },
        { type: "Document", id: "ALL" },
      ],
    },
    receiveAnnualReport: {
      invalidatesTags: (_result, _error, arg) => [
        { type: "Document", id: arg.documentId },
        { type: "Document", id: "ALL" },
      ],
    },
    receiveTerminationReport: {
      invalidatesTags: (_result, _error, arg) => [
        { type: "Document", id: arg.documentId },
        { type: "Document", id: "ALL" },
      ],
    },
    updateNewApplicationOfficeMemberCheck: {
      invalidatesTags: (_result, _error, arg) => [
        { type: "Document", id: arg.documentId },
        { type: "Document", id: "ALL" },
        "Review",
      ],
    },
    updateChangeApplicationOfficeMemberCheck: {
      invalidatesTags: (_result, _error, arg) => [
        { type: "Document", id: arg.documentId },
        { type: "Document", id: "ALL" },
        "Review",
      ],
    },
    completeOfficeMemberCheck: {
      invalidatesTags: (result, _error, arg) => [
        { type: "Document", id: arg.documentId },
        { type: "Document", id: "ALL" },
        { type: "Project", id: result?.projectId },
        "Review",
      ],
    },
    listReview: {
      providesTags: (result, _error, _arg) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Review" as const, id })),
              "Review",
            ]
          : ["Review"],
    },
    updateReview: {
      invalidatesTags: (_result, _error, arg) => [
        { type: "Review", id: arg.reviewId },
      ],
    },
    concludeReview: {
      invalidatesTags: (result, _error, _arg) => [
        "Review",
        { type: "Document", id: result?.documentId },
        { type: "Document", id: "ALL" },
        { type: "DocumentSnapshot", id: "ALL" },
      ],
    },
    approveReview: {
      invalidatesTags: (result, _error, arg) => [
        { type: "Review", id: arg.reviewId },
        { type: "Document", id: result?.documentId },
        { type: "Document", id: "ALL" },
      ],
    },
    createResultNotification: {
      invalidatesTags: (result, _error, _arg) => [
        "ResultNotification",
        { type: "Document", id: result?.documentId },
        { type: "Document", id: "ALL" },
        "Project", // 案件IDが手に入らないので、全案件を取り直す。
      ],
    },
    getResultNotification: {
      providesTags: (_result, _error, arg) => [
        { type: "ResultNotification", id: arg.resultNotificationId },
      ],
    },
    listResultNotification: {
      providesTags: (result, _error, _arg) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "ResultNotification" as const,
                id,
              })),
              "ResultNotification",
            ]
          : ["ResultNotification"],
    },
    updateApplicationOfficeMemberNotes: {
      invalidatesTags: (result, _error, arg) => [
        { type: "Document", id: arg.documentId },
        { type: "Document", id: "ALL" },
      ],
    },
    updateReportOfficeMemberNotes: {
      invalidatesTags: (result, _error, arg) => [
        { type: "Document", id: arg.documentId },
        { type: "Document", id: "ALL" },
      ],
    },
    deleteDocumentDraft: {
      invalidatesTags: (result, _error, arg) => [
        { type: "Document", id: arg.documentId },
        { type: "Document", id: "ALL" },
      ],
    },
    abortDocument: {
      invalidatesTags: (_result, _error, arg) => [
        { type: "Document", id: arg.documentId },
        { type: "Document", id: "ALL" },
      ],
    },
    withdrawDocument: {
      invalidatesTags: (_result, _error, arg) => [
        { type: "Document", id: arg.documentId },
        { type: "Document", id: "ALL" },
      ],
    },
    getDocumentSnapshot: {
      providesTags: (_result, _error, _arg) => [
        { type: "DocumentSnapshot", id: "ALL" },
      ],
    },
    documentHasUnheldReviewConference: {
      providesTags: (_result, _error, arg) => [
        { type: "Document", id: arg.documentId },
      ],
    },

    // Inquiry (質疑応答)
    createInquiry: {
      invalidatesTags: (_result, _error, _arg) => [
        "Inquiry",
        { type: "Inquiry", id: "ALL" },
      ],
    },
    searchInquiry: {
      providesTags: (result, _error, _arg) =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: "Inquiry" as const,
                id,
              })),
              "Inquiry",
            ]
          : ["Inquiry"],
    },
    getNumberOfInquiryTodos: {
      providesTags: [{ type: "Inquiry", id: "ALL" }],
    },
    respondInquiry: {
      invalidatesTags: (_result, _error, arg) => [
        { type: "Inquiry", id: arg.inquiryId },
        { type: "Inquiry", id: "ALL" },
      ],
    },
    readInquiry: {
      invalidatesTags: (_result, _error, arg) => [
        { type: "Inquiry", id: arg.inquiryId },
        { type: "Inquiry", id: "ALL" },
      ],
    },

    // Institution (機関)
    createInstitution: {
      invalidatesTags: (_result, _error, _arg) => ["Institution"],
    },
    getInstitution: {
      providesTags: (_result, _error, arg) => [
        { type: "Institution", id: arg.institutionId },
      ],
    },
    listInstitution: {
      providesTags: (result, _error, _arg) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Institution" as const, id })),
              "Institution",
            ]
          : ["Institution"],
    },
    searchInstitution: {
      providesTags: (result, _error, _arg) =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: "Institution" as const,
                id,
              })),
              "Institution",
            ]
          : ["Institution"],
    },
    updateInstitution: {
      invalidatesTags: (_result, _error, arg) => [
        { type: "Institution", id: arg.institutionId },
      ],
    },

    // MailLog (メールログ)
    searchMailLog: {},

    // Project (案件)
    getProject: {
      providesTags: (_result, _error, arg) => [
        { type: "Project", id: arg.projectId },
      ],
    },
    listProject: {
      providesTags: (result, _error, _arg) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Project" as const, id })),
              "Project",
            ]
          : ["Project"],
    },
    searchProject: {
      providesTags: (result, _error, _arg) =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: "Project" as const,
                id,
              })),
              "Project",
            ]
          : ["Project"],
    },
    updateDisclosureDocument: {
      invalidatesTags: (_result, _error, arg) => [
        { type: "Project", id: arg.projectId },
      ],
    },
    forceCreateProject: {
      invalidatesTags: (_result, _error, _arg) => ["Project"],
    },
    forceUpdateProject: {
      invalidatesTags: (_result, _error, arg) => [
        { type: "Project", id: arg.projectId },
      ],
    },
    getProjectSnapshot: {},
    abortProject: {
      invalidatesTags: (_result, _error, arg) => [
        { type: "Project", id: arg.projectId },
      ],
    },

    // ReviewConference (審査会)
    createReviewConference: {
      invalidatesTags: (_result, _error, _arg) => [
        { type: "ReviewConference", id: "ALL" },
        "ReviewConference",
      ],
    },
    getReviewConference: {
      providesTags: (_result, _error, arg) => [
        { type: "ReviewConference", id: arg.reviewConferenceId },
      ],
    },
    searchReviewConference: {
      providesTags: (result, _error, _arg) =>
        result
          ? [
              ...result.items.map(({ reviewConference: { id } }) => ({
                type: "ReviewConference" as const,
                id,
              })),
              "ReviewConference",
            ]
          : ["ReviewConference"],
    },
    getNumberOfReviewConferenceTodos: {
      providesTags: [{ type: "ReviewConference", id: "ALL" }],
    },
    notifyReviewConference: {
      invalidatesTags: (_result, _error, arg) => [
        { type: "ReviewConference", id: arg.reviewConferenceId },
        { type: "ReviewConference", id: "ALL" },
      ],
    },
    updateReviewConference: {
      invalidatesTags: (_result, _error, arg) => [
        { type: "ReviewConference", id: arg.reviewConferenceId },
        { type: "ReviewConference", id: "ALL" },
      ],
    },
    concludeReviewConference: {
      invalidatesTags: (_result, _error, arg) => [
        { type: "ReviewConference", id: arg.reviewConferenceId },
        { type: "ReviewConference", id: "ALL" },
        "Review",
        { type: "Document", id: "ALL" },
        "Document",
        { type: "DocumentSnapshot", id: "ALL" },
      ],
    },
    deleteReviewConference: {
      invalidatesTags: (_result, _error, arg) => [
        { type: "ReviewConference", id: arg.reviewConferenceId },
        { type: "ReviewConference", id: "ALL" },
      ],
    },

    // RevisionRequest (修正依頼)
    createRevisionRequest: {
      invalidatesTags: (_result, _error, arg) => [
        "RevisionRequest",
        { type: "Document", id: arg.revisionRequest.documentId },
        { type: "Document", id: "ALL" },
      ],
    },
    getRevisionRequest: {
      providesTags: (_result, _error, arg) => [
        { type: "RevisionRequest", id: arg.revisionRequestId },
      ],
    },
    listRevisionRequest: {
      providesTags: (result, _error, _arg) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "RevisionRequest" as const,
                id,
              })),
              "RevisionRequest",
            ]
          : ["RevisionRequest"],
    },
    saveRevisionRequestReply: {
      invalidatesTags: (_result, _error, arg) => [
        { type: "RevisionRequest", id: arg.revisionRequestId },
      ],
    },
    submitRevisionRequestReply: {
      invalidatesTags: (result, _error, arg) => [
        { type: "RevisionRequest", id: arg.revisionRequestId },
        { type: "Document", id: result?.documentId },
        { type: "Document", id: "ALL" },
      ],
    },

    // SharedAttachmentFile (共有添付資料)
    createSharedAttachmentFile: {
      invalidatesTags: (_result, _error, _arg) => ["SharedAttachmentFile"],
    },
    listSharedAttachmentFile: {
      providesTags: (result, _error, _arg) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "SharedAttachmentFile" as const,
                id,
              })),
              "SharedAttachmentFile",
            ]
          : ["SharedAttachmentFile"],
    },
    deleteSharedAttachmentFile: {
      invalidatesTags: (_result, _error, arg) => [
        { type: "SharedAttachmentFile", id: arg.sharedAttachmentFileId },
      ],
    },

    // WorkLog (作業ログ)
    searchWorkLog: {},
  },
});

export { enhancedRtkApi as stockRequestApi };
