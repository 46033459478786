import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * ユーザー並び順(値オブジェクト)
 *
 *
 *
 * user_id_asc:
 * user_id_desc:
 * updated_at_asc:
 * updated_at_desc:  */
export type UserSortCondition = z.infer<typeof userSortConditionMeta.schema>;
/**
 * ユーザー並び順(値オブジェクト)のメタデータ。
 *
 *  */
export const userSortConditionMeta = new ValueObjectMetaEnum<
  ["user_id_asc", "user_id_desc", "updated_at_asc", "updated_at_desc"]
>(
  "UserSortCondition",
  "\u30E6\u30FC\u30B6\u30FC\u4E26\u3073\u9806",
  messageId("code.\u30E6\u30FC\u30B6\u30FC\u4E26\u3073\u9806"),
  ["user_id_asc", "user_id_desc", "updated_at_asc", "updated_at_desc"],
  {
    user_id_asc:
      "code.\u4E26\u3073\u9806.\u30E6\u30FC\u30B6\u30FCID\u6607\u9806",
    user_id_desc:
      "code.\u4E26\u3073\u9806.\u30E6\u30FC\u30B6\u30FCID\u964D\u9806",
    updated_at_asc:
      "code.\u4E26\u3073\u9806.\u66F4\u65B0\u65E5\u6642\u6607\u9806",
    updated_at_desc:
      "code.\u4E26\u3073\u9806.\u66F4\u65B0\u65E5\u6642\u964D\u9806",
  }
);
