import { AnnualReportCreatePage } from "../../../model/document/report/annual-report/AnnualReportCreatePage/AnnualReportCreatePage";
import { hasValue, isNullish } from "../../../../lib/util/common-util";
import { useAppParams } from "../../../../hooks/use-app-params";
import { useAnnualReport } from "../../../../hooks/document/annual-report/use-annual-report";
import { useMemo } from "react";
import {
  isBeforeDate,
  ymdToJsDateOrNull,
} from "../../../../lib/util/common-date-util";
import { useCurrentDatetime } from "../../../../hooks/use-current-datetime";

export const FN40S04AnnualReportCreate = () => {
  const { projectId, documentId } = useAppParams();

  const {
    project,

    initialAnnualReportVM,

    validationErrors,

    saveAnnualReportContent,
    deleteAnnualReportDraft,
  } = useAnnualReport(projectId ?? -1, documentId, {
    skip: isNullish(projectId),
  });

  /**
   * バックエンドから現在日時を取得する。
   */
  const currentDate = useCurrentDatetime();

  /**
   * 現在日時が使用終了日を過ぎている場合にtrue
   */
  const isOverdue = useMemo(() => {
    const usageEndDate = ymdToJsDateOrNull(
      project?.applicationContent?.usageEndDate,
    );
    if (hasValue(usageEndDate) && hasValue(currentDate)) {
      return isBeforeDate(usageEndDate, currentDate);
    }
  }, [project, currentDate]);

  return (
    <>
      {hasValue(initialAnnualReportVM) &&
        hasValue(projectId) &&
        hasValue(project) && (
          <AnnualReportCreatePage
            projectId={projectId}
            savedDocumentId={documentId}
            initialViewModel={initialAnnualReportVM}
            validationErrors={validationErrors}
            saveAnnualReportContent={saveAnnualReportContent}
            deleteAnnualReportDraft={deleteAnnualReportDraft}
            isOverdue={isOverdue}
            h={"100%"}
            w={"100%"}
          />
        )}
    </>
  );
};
