import { ProtectedRoute as PR } from "../components/route/ProtectedRoute";
import * as React from "react";
import { FN70S01WorkLogSearch } from "../components/page/FN70/FN70S01WorkLogSearch/FN70S01WorkLogSearch";
import { RouteObject } from "react-router-dom";

export const _workLogRoute: RouteObject = {
  path: "work-log",
  element: <PR roles={["office_member"]} />,
  children: [{ index: true, element: <FN70S01WorkLogSearch /> }],
};
