import { Box, HStack, VStack } from "@chakra-ui/react";
import React, { memo, useCallback, useMemo } from "react";
import {
  getMessageFromEnumValue,
  hasValue,
  isNullish,
} from "../../../../../../lib/util/common-util";
import {
  CMFormInputDropDown,
  CMFormInputText,
  CMFormInputTextArea,
} from "@pscsrvlab/psc-react-components";
import { ReviewTarget } from "../../../../../../lib/object/value/review-target";
import { freeTextMeta } from "../../../../../../lib/object/value/free-text";
import {
  ReviewResult,
  reviewResultMeta,
} from "../../../../../../lib/object/value/review-result";
import { unapprovalReasonMeta } from "../../../../../../lib/object/value/unapproval-reason";
import { documentTypeMeta } from "../../../../../../lib/object/value/document-type";
import { ReviewConferenceReviewTargetSelect } from "../ReviewConferenceReviewTargetSelect/ReviewConferenceReviewTargetSelect";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { useAppGetProjectQuery } from "../../../../../../hooks/query/use-app-get-project-query";
import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";
import { isEqual } from "lodash";
import { useSavedCurrentDocument } from "../../../../../../hooks/document/use-saved-current-document";

export type ReviewTargetCardProps = {
  index: number;

  editMode: "editable" | "readOnly";

  /**
   * 審査情報
   */
  reviewTarget: Partial<ReviewTarget>;

  /**
   * 新規登録の場合true
   */
  isCreate: boolean;

  onChange: (
    index: number,
    change: (before: Partial<ReviewTarget>) => Partial<ReviewTarget>,
  ) => void;
} & ComponentStyleProps;

/**
 * 審査対象カード。
 */
export const ReviewTargetCard = memo(
  function ReviewTargetCard({
    index,

    editMode,

    reviewTarget,
    isCreate,

    onChange,

    sx,
    ...rest
  }: ReviewTargetCardProps) {
    const { t } = useAppTranslation();

    const { document: documentData } = useSavedCurrentDocument(
      reviewTarget.documentId,
    );
    const document = useMemo(() => {
      // documentIdが未指定のときは、documentもnullにしたいので、このような処理を入れている。
      if (isNullish(reviewTarget.documentId) || isNullish(documentData))
        return null;
      return documentData;
    }, [documentData, reviewTarget.documentId]);

    const { data: projectData } = useAppGetProjectQuery(
      {
        projectId: document?.projectId ?? -1,
      },
      { skip: isNullish(document?.projectId) },
    );
    const project = useMemo(() => {
      // documentIdが未指定のときは、projectもnullにしたいので、このような処理を入れている。
      if (isNullish(document?.projectId) || isNullish(projectData)) return null;
      return projectData;
    }, [document?.projectId, projectData]);

    const documentTypeText = useMemo(() => {
      return getMessageFromEnumValue(
        t,
        documentTypeMeta,
        document?.documentType,
      );
    }, [document?.documentType, t]);
    const principalInvestigator = useMemo(() => {
      return project?.applicationContent?.principalInvestigator?.fullName;
    }, [project?.applicationContent?.principalInvestigator?.fullName]);
    const institutionName = useMemo(() => {
      return project?.applicationContent?.institution?.institutionName;
    }, [project?.applicationContent?.institution?.institutionName]);
    const projectName = useMemo(() => {
      return project?.applicationContent?.projectName;
    }, [project?.applicationContent?.projectName]);

    /**
     * 書類管理番号変更イベント
     */
    const handleChangeReview = useCallback(
      (value: { reviewId: number; documentId: number } | null) => {
        if (hasValue(value)) {
          onChange?.(index, (before) => ({
            ...before,
            ...value,
          }));
        } else {
          onChange?.(index, (before) => ({
            ...before,
            reviewId: undefined,
            documentId: undefined,
          }));
        }
      },
      [index, onChange],
    );

    /**
     * 審査結果変更イベント
     */
    const handleChangeReviewResult = useCallback(
      (value: ReviewResult | null) => {
        onChange?.(index, (before) => ({
          ...before,
          reviewResult: value ?? undefined,
        }));
      },
      [index, onChange],
    );

    /**
     * 承認以外の理由変更イベント
     */
    const handleChangeUnapprovalReason = useCallback(
      (value: string) => {
        onChange?.(index, (before) => ({
          ...before,
          unapprovalReason: value,
        }));
      },
      [index, onChange],
    );

    /**
     * 備考変更イベント
     */
    const handleChangeNote = useCallback(
      (value: string) => {
        onChange?.(index, (before) => ({
          ...before,
          officeMemberNote: value,
        }));
      },
      [index, onChange],
    );

    return (
      <>
        <VStack alignItems={"stretch"} spacing={"16px"} sx={sx} {...rest}>
          <HStack>
            {/*書類管理番号*/}
            <Box w={"200px"}>
              <ReviewConferenceReviewTargetSelect
                editMode={editMode}
                documentId={reviewTarget.documentId ?? null}
                reviewId={reviewTarget.reviewId ?? null}
                onChange={handleChangeReview}
              />
            </Box>
            {/*書類種別*/}
            <Box w={"200px"}>
              <CMFormInputText
                editMode={"readOnly"}
                label={t("lbl.書類種別")}
                valueObjectMeta={freeTextMeta}
                value={documentTypeText ?? ""}
              />
            </Box>
            <Box w={"200px"}>
              {/*研究責任者*/}
              <CMFormInputText
                editMode={"readOnly"}
                label={t("lbl.研究責任者")}
                valueObjectMeta={freeTextMeta}
                value={principalInvestigator ?? ""}
              />
            </Box>
          </HStack>
          <HStack justifyContent={"space-between"}>
            {/*機関名*/}
            <VStack flex={"1 1 auto"} minW={0}>
              <CMFormInputText
                editMode={"readOnly"}
                label={t("lbl.機関名")}
                valueObjectMeta={freeTextMeta}
                value={institutionName ?? ""}
              />
            </VStack>
            {/*課題名*/}
            <VStack flex={"1 1 auto"} minW={0}>
              <CMFormInputText
                editMode={"readOnly"}
                label={t("lbl.課題名")}
                valueObjectMeta={freeTextMeta}
                value={projectName ?? ""}
              />
            </VStack>
          </HStack>
          {!isCreate && (
            <>
              {/*審査結果*/}
              <CMFormInputDropDown
                editMode={editMode}
                label={t("lbl.審査結果")}
                valueObjectMeta={reviewResultMeta}
                nullable={true}
                placeholder={t("lbl.選択プルダウンプレースホルダー")}
                value={reviewTarget.reviewResult}
                onChange={handleChangeReviewResult}
                maxW={"300px"}
              />
              {/*承認以外の場合の理由*/}
              <CMFormInputTextArea
                editMode={editMode}
                label={t("lbl.承認以外の場合の理由")}
                valueObjectMeta={unapprovalReasonMeta}
                value={reviewTarget.unapprovalReason}
                onChange={handleChangeUnapprovalReason}
              />
              {/*備考*/}
              <CMFormInputTextArea
                editMode={editMode}
                label={t("lbl.備考")}
                valueObjectMeta={freeTextMeta}
                value={reviewTarget.officeMemberNote}
                onChange={handleChangeNote}
              />
            </>
          )}
        </VStack>
      </>
    );
  },
  (prevProps, nextProps) => {
    return isEqual(prevProps, nextProps);
  },
);
