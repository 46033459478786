import {
  messageId,
  PropertyMeta,
  ValueObjectMetaNumber,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.number().min(1);
const savedMinimalSchema = z.number().min(1);
const schema = z.number().min(1);
const savedSchema = z.number().min(1);
const jsonSchema = z.number();
/**
 * 連番(値オブジェクト)のメタデータ。
 *
 * 1始まりの連番。 */
export const serialNumberMeta = new ValueObjectMetaNumber(
  "SerialNumber",
  "\u9023\u756A",
  messageId("lbl.\u9023\u756A"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
