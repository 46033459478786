import { hasValue, isNullish } from "../../../../lib/util/common-util";
import { useAppParams } from "../../../../hooks/use-app-params";
import { useAppGetDocumentQuery } from "../../../../hooks/query/use-app-get-document-query";
import { useMemo } from "react";
import { ResultNotificationApplicationPage } from "../../../model/document/result-notification/ResultNotificationApplicationPage";
import { ResultNotificationReportPage } from "../../../model/document/result-notification/ResultNotificationReportPage";

export type FN40S33ResultNotificationProps = {
  mode: "view" | "create" | "empty";
};

/**
 * FN40S33 結果通知
 */
export const FN40S33ResultNotification = ({
  mode,
}: FN40S33ResultNotificationProps) => {
  const { documentId } = useAppParams();

  const { data: document } = useAppGetDocumentQuery(
    {
      documentId: documentId ?? -1,
    },
    { skip: isNullish(documentId) },
  );
  /**
   * 書類種別が申請であればtrue。
   * 書類をロードできるまでは、undefined。
   */
  const isDocumentTypeApplication = useMemo(() => {
    if (isNullish(document)) return undefined;
    return (
      document.documentType === "new_application" ||
      document.documentType === "change_application"
    );
  }, [document]);

  return (
    <>
      {hasValue(document) &&
        hasValue(isDocumentTypeApplication) &&
        (isDocumentTypeApplication ? (
          <ResultNotificationApplicationPage
            mode={mode}
            documentId={document.id}
          />
        ) : (
          <ResultNotificationReportPage mode={mode} documentId={document.id} />
        ))}
    </>
  );
};
