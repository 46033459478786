import {
  Button,
  Container,
  HStack,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { Icon } from "@chakra-ui/icons";
import { MdInfo, MdModeEdit } from "react-icons/md";
import { useAppSelector } from "../../../../../hooks/redux-hooks";
import { selectUserInfo } from "../../../../../store/auth/slice";
import React, { useCallback, useMemo, useRef } from "react";
import { hasValue, isNullish } from "../../../../../lib/util/common-util";
import { ApplicationContent } from "../../../../../lib/object/value/application-content";
import {
  CMButton,
  CMMessageInfo,
  CMTooltip,
} from "@pscsrvlab/psc-react-components";
import { BsExclamationDiamondFill, BsPlus } from "react-icons/bs";
import { ApplicationDocument } from "../../../document/application/_components/ApplicationDocument/ApplicationDocument";
import { FrameUpperRightButton } from "../../../../ui/frame/FrameUpperRightButton/FrameUpperRightButton";
import { FN30S05CreateDocument } from "../../FN30S05CreateDocument/FN30S05CreateDocument";
import { ComponentStyleProps } from "../../../../../lib/styles/props/component-style-props";
import { FrameUpperLeftButton } from "../../../../ui/frame/FrameUpperLeftButton/FrameUpperLeftButton";
import { useNavigate } from "react-router-dom";
import { useAppTranslation } from "../../../../../hooks/use-app-translation";
import { useAppGetProjectQuery } from "../../../../../hooks/query/use-app-get-project-query";
import { YearMonthDay } from "../../../../../lib/object/value/year-month-day";
import { useIsProjectMutable } from "../../../../../hooks/business-logic/use-is-project-mutable";
import useCustomToast from "../../../../../hooks/use-custom-toast";
import { ymdToDateTextOrNull } from "../../../../../lib/util/common-date-util";
import { WeakTextItem } from "../../../../ui/text/WeakTextItem/WeakTextItem";

export type ProjectContentApplicationPageProps = {
  projectId: number;
  projectApplicationContent: ApplicationContent;
  loginUserRole:
    | "applicant"
    | "office_member"
    | "committee_member"
    | "executive_director"
    | "internal"
    | undefined;
} & ComponentStyleProps;
export const ProjectContentApplicationPage = ({
  projectId,
  projectApplicationContent,
  loginUserRole,
  sx,
  ...rest
}: ProjectContentApplicationPageProps) => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const { errorToast } = useCustomToast();
  const loginUserInfo = useAppSelector(selectUserInfo);

  const { isOpen, onOpen, onClose } = useDisclosure();

  /**
   * 案件情報取得。
   */
  const { data: project } = useAppGetProjectQuery(
    {
      projectId: projectId ?? -1,
    },
    { skip: isNullish(projectId) },
  );

  const nextAnnualReportDeadlineYmd: YearMonthDay | undefined = useMemo(() => {
    if (isNullish(project)) {
      return undefined;
    }
    return project.nextAnnualReportDeadline;
  }, [project]);

  const { isProjectMutable } = useIsProjectMutable(projectId);
  const onForceEdit = useCallback(() => {
    if (hasValue(isProjectMutable) && isProjectMutable) {
      navigate(`/project/${projectId}/force-update`);
    } else {
      errorToast(t("mes.変更申請中強制編集不許可エラー"));
    }
  }, [errorToast, isProjectMutable, navigate, projectId, t]);

  const scrollableRef = useRef(null);

  return (
    <>
      <VStack
        flex={1}
        alignSelf={"stretch"}
        overflow={"auto"}
        pb={"100px"}
        sx={sx}
        {...rest}
        ref={scrollableRef}
      >
        <HStack alignSelf={"stretch"} justifyContent={"space-between"}>
          <FrameUpperLeftButton>
            <Stack>
              <CMMessageInfo
                label={t("gdc.書類内容インフォメーション(書類内容は最新)")}
              />
              {hasValue(nextAnnualReportDeadlineYmd) && (
                <CMMessageInfo
                  label={t("gdc.書類年次報告提出期限案内", {
                    年: nextAnnualReportDeadlineYmd.year,
                    月: nextAnnualReportDeadlineYmd.month,
                    日: nextAnnualReportDeadlineYmd.day,
                  })}
                  icon={BsExclamationDiamondFill}
                  iconColor={"red.500"}
                />
              )}
            </Stack>
          </FrameUpperLeftButton>

          <FrameUpperRightButton pl={"20px"}>
            {/*案件が進行中の場合のみ申請・報告を作成ボタンを表示
              案件状態が下書きなら強制編集できない。
              また、案件強制編集について、isProjectMutableは、エラー判定は遷移時に行うが、
              情報が取れて確定するまではこのボタンを表示しない。
              */}
            {loginUserRole === "applicant" &&
            project?.projectState === "ongoing" ? (
              <Button
                size={"sm"}
                leftIcon={<BsPlus />}
                colorScheme={"teal"}
                onClick={onOpen}
                mt={"-20px"}
              >
                {t("btn.申請・報告を作成ボタン")}
              </Button>
            ) : loginUserRole === "office_member" &&
              project?.projectState === "ongoing" &&
              hasValue(isProjectMutable) ? (
              <CMButton
                size={"sm"}
                label={t("btn.強制編集ボタン")}
                leftIcon={<MdModeEdit />}
                onClick={onForceEdit}
                mt={"-20px"}
              />
            ) : null}
            {/*FN30-S05 書類作成*/}
            <FN30S05CreateDocument
              projectId={projectId}
              isOpenModal={isOpen}
              onCloseModal={onClose}
            />
          </FrameUpperRightButton>
        </HStack>
        <Container minW={"500px"} maxW={"720px"}>
          <VStack alignItems={"stretch"}>
            <HStack justifyContent={"space-between"}>
              <HStack>
                <Text fontSize={"2xl"} fontWeight={"bold"}>
                  {`${t("lbl.案件管理番号")} : ${
                    project?.projectControlNumber
                  }`}
                </Text>
                {/*案件が移行データであれば表示。*/}
                {hasValue(project) &&
                  project.migrationDataType === "migration_data" && (
                    <CMTooltip
                      tooltipLabel={t("gdc.移行データ案内")}
                      tooltipBackgroundColor={"orange.100"}
                    >
                      <Button
                        variant={"ghost"}
                        _hover={{ bgColor: "none" }}
                        w={"20px"}
                        h={"20px"}
                      >
                        <Icon
                          as={MdInfo}
                          color={"blue.500"}
                          fontSize={"md"}
                          w={"20px"}
                          h={"20px"}
                        />
                      </Button>
                    </CMTooltip>
                  )}
              </HStack>
              {hasValue(project) && (
                <Stack spacing={0}>
                  <WeakTextItem
                    label={t("lbl.新規申請提出日")}
                    value={ymdToDateTextOrNull(
                      project.newApplicationSubmissionDate,
                    )}
                  />
                  <WeakTextItem
                    label={t("lbl.新規申請承認日")}
                    value={ymdToDateTextOrNull(
                      project.newApplicationApprovalDate,
                    )}
                  />
                  <WeakTextItem
                    label={t("lbl.完了日")}
                    value={ymdToDateTextOrNull(project.projectCompletionDate)}
                  />
                  )
                </Stack>
              )}
            </HStack>
          </VStack>

          {hasValue(loginUserInfo) && hasValue(projectApplicationContent) && (
            <ApplicationDocument
              loginUserInfo={loginUserInfo}
              documentType={"project_content"}
              editMode={"readOnly"}
              showControlNumbers={false}
              projectControlNumber={null}
              documentControlNumber={null}
              showDates={false}
              submissionDate={null}
              receptionDate={null}
              approvalDate={null}
              documentCompletionDate={null}
              projectId={projectId}
              value={projectApplicationContent}
              freezeFirstContactPerson={true}
              scrollableRef={scrollableRef}
              scrollOffset={60}
            />
          )}
        </Container>
      </VStack>
    </>
  );
};
