import { ProtectedRoute as PR } from "../components/route/ProtectedRoute";
import { FN40S02NewApplicationCreate } from "../components/page/FN40/FN40S02NewApplicationCreate/FN40S02NewApplicationCreate";
import { FN40S06NewApplicationCreateConfirm } from "../components/page/FN40/FN40S06NewApplicationCreateConfirm/FN40S06NewApplicationCreateConfirm";
import { FN40S10DocumentCreateComplete } from "../components/page/FN40/FN40S10DocumentCreateComplete/FN40S10DocumentCreateComplete";
import { FN40S03ChangeApplicationCreate } from "../components/page/FN40/FN40S03ChangeApplicationCreate/FN40S03ChangeApplicationCreate";
import { FN40S07ChangeApplicationCreateConfirm } from "../components/page/FN40/FN40S07ChangeApplicationCreateConfirm/FN40S07ChangeApplicationCreateConfirm";
import { FN40S04AnnualReportCreate } from "../components/page/FN40/FN40S04AnnualReportCreate/FN40S04AnnualReportCreate";
import { FN40S08AnnualReportCreateConfirm } from "../components/page/FN40/FN40S08AnnualReportCreateConfirm/FN40S08AnnualReportCreateConfirm";
import { FN40S05TerminationReportCreate } from "../components/page/FN40/FN40S05TerminationReportCreate/FN40S05TerminationReportCreate";
import { FN40S09TerminationReportCreateConfirm } from "../components/page/FN40/FN40S09TerminationReportCreateConfirm/FN40S09TerminationReportCreateConfirm";
import * as React from "react";
import { RouteObject } from "react-router-dom";

export const _documentCreateRoute: RouteObject = {
  path: "document",
  element: <PR roles={["applicant"]} />,
  children: [
    {
      path: "create-new-application",
      children: [
        {
          path: "create",
          element: <FN40S02NewApplicationCreate />,
          children: [
            {
              path: ":documentId",
              element: <FN40S02NewApplicationCreate />,
            },
          ],
        },
        {
          path: "confirm/:documentId",
          element: <FN40S06NewApplicationCreateConfirm />,
        },
        {
          path: "complete/:documentId",
          element: (
            <FN40S10DocumentCreateComplete documentType={"new_application"} />
          ),
        },
      ],
    },
    {
      path: "create-change-application/:projectId",
      children: [
        {
          path: "create",
          element: <FN40S03ChangeApplicationCreate />,
          children: [
            {
              path: ":documentId",
              element: <FN40S03ChangeApplicationCreate />,
            },
          ],
        },
        {
          path: "confirm/:documentId",
          element: <FN40S07ChangeApplicationCreateConfirm />,
        },
        {
          path: "complete/:documentId",
          element: (
            <FN40S10DocumentCreateComplete
              documentType={"change_application"}
            />
          ),
        },
      ],
    },
    {
      path: "create-annual-report/:projectId",
      children: [
        {
          path: "create",
          element: <FN40S04AnnualReportCreate />,
          children: [
            {
              path: ":documentId",
              element: <FN40S04AnnualReportCreate />,
            },
          ],
        },
        {
          path: "confirm/:documentId",
          element: <FN40S08AnnualReportCreateConfirm />,
        },
        {
          path: "complete/:documentId",
          element: (
            <FN40S10DocumentCreateComplete documentType={"annual_report"} />
          ),
        },
      ],
    },
    {
      path: "create-termination-report/:projectId",
      children: [
        {
          path: "create",
          element: <FN40S05TerminationReportCreate />,
          children: [
            {
              path: ":documentId",
              element: <FN40S05TerminationReportCreate />,
            },
          ],
        },
        {
          path: "confirm/:documentId",
          element: <FN40S09TerminationReportCreateConfirm />,
        },
        {
          path: "complete/:documentId",
          element: (
            <FN40S10DocumentCreateComplete
              documentType={"termination_report"}
            />
          ),
        },
      ],
    },
  ],
};
