import { Icon } from "@chakra-ui/icons";
import { MdFileUpload } from "react-icons/md";
import { Button } from "@chakra-ui/react";
import { ComponentStyleProps } from "../../../../lib/styles/props/component-style-props";
import { useCallback, useMemo } from "react";
import { CMButtonFileUpload } from "@pscsrvlab/psc-react-components";
import useConfigFile from "../../../../hooks/use-config-file";
import useFileUpload from "../../../../hooks/use-file-upload";
import { FilePrivacyType } from "../../../../lib/object/value/file-privacy-type";
import { StorageFileSaved } from "../../../../lib/object/entity/storage-file";
import { hasValue, isNullish } from "../../../../lib/util/common-util";
import { useAppTranslation } from "../../../../hooks/use-app-translation";

export type UploadButtonProps = {
  /**
   * ファイルのアップロードが完了したときのコールバック。
   * アップロードに失敗した場合、filesは0件、statusは"error"となる。
   */
  onUpload?: (
    files: { name: string; storageFile: StorageFileSaved }[],
    status: "ok" | "error",
  ) => void;

  /**
   * 財団内プライベートならprivate, 申請者(の少なくとも一部)にも公開するならpublic。
   */
  filePrivacyType: FilePrivacyType;
  /**
   * publicの場合に、本ファイルをダウンロードできる申請者をこの機関に絞りたければ、設定する。
   */
  institutionId: number | null;
  /**
   * publicの場合に、本ファイルをダウンロードできる申請者をこの案件に絞りたければ、設定する。
   */
  projectId: number | null;
} & ComponentStyleProps;

export const UploadButton = ({
  onUpload,

  filePrivacyType,
  institutionId,
  projectId,

  sx,
  ...rest
}: UploadButtonProps) => {
  const { t } = useAppTranslation();

  const { fileUpload } = useFileUpload();

  const { uploadableFileExtension } = useConfigFile();
  const accept = useMemo(() => {
    return uploadableFileExtension.map((ext) => `.${ext}`);
  }, [uploadableFileExtension]);

  const handleUpload = useCallback(
    async (files: File[]) => {
      const results: ({
        name: string;
        storageFile: StorageFileSaved;
      } | null)[] = await Promise.all(
        files.map(async (file) => {
          const uploadResult = await fileUpload(
            file,
            filePrivacyType,
            institutionId,
            projectId,
          );
          if (isNullish(uploadResult)) return null;
          return {
            name: file.name,
            storageFile: uploadResult,
          };
        }),
      );
      if (results.some((r) => hasValue(r))) {
        onUpload?.(results.filter(hasValue), "ok");
      } else {
        onUpload?.([], "error");
      }
    },
    [filePrivacyType, fileUpload, institutionId, onUpload, projectId],
  );

  return (
    <CMButtonFileUpload
      onUpload={handleUpload}
      accept={accept}
      multiple={false}
      sx={{ w: "max-content", ...sx }}
      {...rest}
    >
      <Button
        colorScheme={"teal"}
        leftIcon={<Icon as={MdFileUpload} />}
        size={"xs"}
      >
        {t("btn.アップロードボタン")}
      </Button>
    </CMButtonFileUpload>
  );
};
