import {
  messageId,
  PropertyMeta,
  ValueObjectMetaComposite,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { appUserIdMeta } from "../value/app-user-id";
import { roleMeta } from "../value/role";
import { inquiryContentMeta } from "../value/inquiry-content";
import { attachmentFileInformationMeta } from "../value/attachment-file-information";
import { datetimeMeta } from "../value/datetime";
/**
 * 質疑応答返信(値オブジェクト)
 *
 *  */
export type InquiryReply = z.infer<typeof schema>;
export type InquiryReplySaved = z.infer<typeof savedSchema>;
const minimalSchema = z.object({
  replierAppUserId: appUserIdMeta.minimalSchema,
  replierRole: roleMeta.minimalSchema,
  content: inquiryContentMeta.minimalSchema,
  attachmentFiles: z.array(attachmentFileInformationMeta.minimalSchema),
  replyDatetime: datetimeMeta.minimalSchema,
});
const savedMinimalSchema = z.object({
  replierAppUserId: appUserIdMeta.savedMinimalSchema,
  replierRole: roleMeta.savedMinimalSchema,
  content: inquiryContentMeta.savedMinimalSchema,
  attachmentFiles: z.array(attachmentFileInformationMeta.savedMinimalSchema),
  replyDatetime: datetimeMeta.savedMinimalSchema,
});
const schema = z.object({
  replierAppUserId: appUserIdMeta.schema,
  replierRole: roleMeta.schema,
  content: inquiryContentMeta.schema,
  attachmentFiles: z.array(attachmentFileInformationMeta.schema),
  replyDatetime: datetimeMeta.schema,
});
const savedSchema = z.object({
  replierAppUserId: appUserIdMeta.savedSchema,
  replierRole: roleMeta.savedSchema,
  content: inquiryContentMeta.savedSchema,
  attachmentFiles: z.array(attachmentFileInformationMeta.savedSchema),
  replyDatetime: datetimeMeta.savedSchema,
});
const jsonSchema = z.object({
  replierAppUserId: appUserIdMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  replierRole: roleMeta.jsonSchema.nullish().transform((x) => x ?? undefined),
  content: inquiryContentMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  attachmentFiles: z
    .array(attachmentFileInformationMeta.jsonSchema)
    .nullish()
    .transform((x) => x ?? undefined),
  replyDatetime: datetimeMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
/**
 * 質疑応答返信(値オブジェクト)のメタデータ。
 *
 *  */
export const inquiryReplyMeta = new ValueObjectMetaComposite(
  "InquiryReply",
  "\u8CEA\u7591\u5FDC\u7B54\u8FD4\u4FE1",
  messageId("lbl.\u8CEA\u7591\u5FDC\u7B54\u8FD4\u4FE1"),
  minimalSchema,
  savedMinimalSchema,
  schema,
  savedSchema,
  jsonSchema,
  undefined,
  undefined,
  {
    replierAppUserId: new PropertyMeta(
      "replierAppUserId",
      "\u8FD4\u4FE1\u8005\u30E6\u30FC\u30B6\u30FCID",
      messageId("lbl.\u8FD4\u4FE1\u8005\u30E6\u30FC\u30B6\u30FCID"),
      "always",
      false,
      appUserIdMeta
    ),
    replierRole: new PropertyMeta(
      "replierRole",
      "\u8FD4\u4FE1\u8005\u30ED\u30FC\u30EB",
      messageId("lbl.\u8FD4\u4FE1\u8005\u30ED\u30FC\u30EB"),
      "always",
      false,
      roleMeta
    ),
    content: new PropertyMeta(
      "content",
      "\u5185\u5BB9",
      messageId("lbl.\u5185\u5BB9"),
      "always",
      false,
      inquiryContentMeta
    ),
    attachmentFiles: new PropertyMeta(
      "attachmentFiles",
      "\u6DFB\u4ED8\u8CC7\u6599",
      messageId("lbl.\u6DFB\u4ED8\u8CC7\u6599"),
      "always",
      true,
      attachmentFileInformationMeta
    ),
    replyDatetime: new PropertyMeta(
      "replyDatetime",
      "\u8FD4\u4FE1\u65E5\u6642",
      messageId("lbl.\u8FD4\u4FE1\u65E5\u6642"),
      "always",
      false,
      datetimeMeta
    ),
  }
);
