import {
  messageId,
  PropertyMeta,
  ValueObjectMetaComposite,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { jsonPatchOperationTypeMeta } from "../value/json-patch-operation-type";
import { jsonPointerMeta } from "../value/json-pointer";
import { jsonMeta } from "../value/json";
/**
 * JSONパッチオペレーション(値オブジェクト)
 *
 *  */
export type JsonPatchOperation = z.infer<typeof schema>;
export type JsonPatchOperationSaved = z.infer<typeof savedSchema>;
const minimalSchema = z.object({
  op: jsonPatchOperationTypeMeta.minimalSchema,
  path: jsonPointerMeta.minimalSchema,
  value: jsonMeta.minimalSchema.nullish().transform((x) => x ?? undefined),
  from: jsonPointerMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
const savedMinimalSchema = z.object({
  op: jsonPatchOperationTypeMeta.savedMinimalSchema,
  path: jsonPointerMeta.savedMinimalSchema,
  value: jsonMeta.savedMinimalSchema.nullish().transform((x) => x ?? undefined),
  from: jsonPointerMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
const schema = z.object({
  op: jsonPatchOperationTypeMeta.schema,
  path: jsonPointerMeta.schema,
  value: jsonMeta.schema.nullish().transform((x) => x ?? undefined),
  from: jsonPointerMeta.schema.nullish().transform((x) => x ?? undefined),
});
const savedSchema = z.object({
  op: jsonPatchOperationTypeMeta.savedSchema,
  path: jsonPointerMeta.savedSchema,
  value: jsonMeta.savedSchema.nullish().transform((x) => x ?? undefined),
  from: jsonPointerMeta.savedSchema.nullish().transform((x) => x ?? undefined),
});
const jsonSchema = z.object({
  op: jsonPatchOperationTypeMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  path: jsonPointerMeta.jsonSchema.nullish().transform((x) => x ?? undefined),
  value: jsonMeta.jsonSchema.nullish().transform((x) => x ?? undefined),
  from: jsonPointerMeta.jsonSchema.nullish().transform((x) => x ?? undefined),
});
/**
 * JSONパッチオペレーション(値オブジェクト)のメタデータ。
 *
 *  */
export const jsonPatchOperationMeta = new ValueObjectMetaComposite(
  "JsonPatchOperation",
  "JSON\u30D1\u30C3\u30C1\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3",
  messageId(
    "lbl.JSON\u30D1\u30C3\u30C1\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3"
  ),
  minimalSchema,
  savedMinimalSchema,
  schema,
  savedSchema,
  jsonSchema,
  undefined,
  undefined,
  {
    op: new PropertyMeta(
      "op",
      "\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3",
      messageId("lbl.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3"),
      "always",
      false,
      jsonPatchOperationTypeMeta
    ),
    path: new PropertyMeta(
      "path",
      "\u30D1\u30B9",
      messageId("lbl.\u30D1\u30B9"),
      "always",
      false,
      jsonPointerMeta
    ),
    value: new PropertyMeta(
      "value",
      "\u5024",
      messageId("lbl.\u5024"),
      "none",
      false,
      jsonMeta
    ),
    from: new PropertyMeta(
      "from",
      "\u5143\u30D1\u30B9",
      messageId("lbl.\u5143\u30D1\u30B9"),
      "none",
      false,
      jsonPointerMeta
    ),
  }
);
