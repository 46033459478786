import {
  messageId,
  PropertyMeta,
  ValueObjectMetaComposite,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { groupLocalKeyMeta } from "../value/group-local-key";
import { collaborativePartnerNameMeta } from "../value/collaborative-partner-name";
import { foreignTypeMeta } from "../value/foreign-type";
import { commercialTypeMeta } from "../value/commercial-type";
import { collaborativePartnerRoleMeta } from "../value/collaborative-partner-role";
import { collaborativePartnerUserInformationMeta } from "../value/collaborative-partner-user-information";
import { collaborativePartnerResearchPlanProjectNameMeta } from "../value/collaborative-partner-research-plan-project-name";
import { attachmentFileInformationMeta } from "../value/attachment-file-information";
/**
 * 分担機関(値オブジェクト)
 *
 *  */
export type CollaborativePartner = z.infer<typeof schema>;
export type CollaborativePartnerSaved = z.infer<typeof savedSchema>;
const minimalSchema = z.object({
  key: groupLocalKeyMeta.minimalSchema,
  collaborativePartnerName: collaborativePartnerNameMeta.minimalSchema,
  foreignType: foreignTypeMeta.minimalSchema,
  commercialType: commercialTypeMeta.minimalSchema,
  collaborativePartnerRole: collaborativePartnerRoleMeta.minimalSchema,
  principalInvestigator: collaborativePartnerUserInformationMeta.minimalSchema,
  contactPerson: collaborativePartnerUserInformationMeta.minimalSchema,
  researchPlanProjectName:
    collaborativePartnerResearchPlanProjectNameMeta.minimalSchema,
  attachmentFiles: z.array(attachmentFileInformationMeta.minimalSchema),
});
const savedMinimalSchema = z.object({
  key: groupLocalKeyMeta.savedMinimalSchema,
  collaborativePartnerName: collaborativePartnerNameMeta.savedMinimalSchema,
  foreignType: foreignTypeMeta.savedMinimalSchema,
  commercialType: commercialTypeMeta.savedMinimalSchema,
  collaborativePartnerRole: collaborativePartnerRoleMeta.savedMinimalSchema,
  principalInvestigator:
    collaborativePartnerUserInformationMeta.savedMinimalSchema,
  contactPerson: collaborativePartnerUserInformationMeta.savedMinimalSchema,
  researchPlanProjectName:
    collaborativePartnerResearchPlanProjectNameMeta.savedMinimalSchema,
  attachmentFiles: z.array(attachmentFileInformationMeta.savedMinimalSchema),
});
const schema = z.object({
  key: groupLocalKeyMeta.schema,
  collaborativePartnerName: collaborativePartnerNameMeta.schema,
  foreignType: foreignTypeMeta.schema,
  commercialType: commercialTypeMeta.schema,
  collaborativePartnerRole: collaborativePartnerRoleMeta.schema,
  principalInvestigator: collaborativePartnerUserInformationMeta.schema,
  contactPerson: collaborativePartnerUserInformationMeta.schema,
  researchPlanProjectName:
    collaborativePartnerResearchPlanProjectNameMeta.schema,
  attachmentFiles: z.array(attachmentFileInformationMeta.schema),
});
const savedSchema = z.object({
  key: groupLocalKeyMeta.savedSchema,
  collaborativePartnerName: collaborativePartnerNameMeta.savedSchema,
  foreignType: foreignTypeMeta.savedSchema,
  commercialType: commercialTypeMeta.savedSchema,
  collaborativePartnerRole: collaborativePartnerRoleMeta.savedSchema,
  principalInvestigator: collaborativePartnerUserInformationMeta.savedSchema,
  contactPerson: collaborativePartnerUserInformationMeta.savedSchema,
  researchPlanProjectName:
    collaborativePartnerResearchPlanProjectNameMeta.savedSchema,
  attachmentFiles: z.array(attachmentFileInformationMeta.savedSchema),
});
const jsonSchema = z.object({
  key: groupLocalKeyMeta.jsonSchema.nullish().transform((x) => x ?? undefined),
  collaborativePartnerName: collaborativePartnerNameMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  foreignType: foreignTypeMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  commercialType: commercialTypeMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  collaborativePartnerRole: collaborativePartnerRoleMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  principalInvestigator: collaborativePartnerUserInformationMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  contactPerson: collaborativePartnerUserInformationMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  researchPlanProjectName:
    collaborativePartnerResearchPlanProjectNameMeta.jsonSchema
      .nullish()
      .transform((x) => x ?? undefined),
  attachmentFiles: z
    .array(attachmentFileInformationMeta.jsonSchema)
    .nullish()
    .transform((x) => x ?? undefined),
});
/**
 * 分担機関(値オブジェクト)のメタデータ。
 *
 *  */
export const collaborativePartnerMeta = new ValueObjectMetaComposite(
  "CollaborativePartner",
  "\u5206\u62C5\u6A5F\u95A2",
  messageId("lbl.\u5206\u62C5\u6A5F\u95A2"),
  minimalSchema,
  savedMinimalSchema,
  schema,
  savedSchema,
  jsonSchema,
  undefined,
  undefined,
  {
    key: new PropertyMeta(
      "key",
      "\u30AD\u30FC",
      messageId("lbl.\u30AD\u30FC"),
      "always",
      false,
      groupLocalKeyMeta
    ),
    collaborativePartnerName: new PropertyMeta(
      "collaborativePartnerName",
      "\u5206\u62C5\u6A5F\u95A2\u540D",
      messageId("lbl.\u5206\u62C5\u6A5F\u95A2\u540D"),
      "always",
      false,
      collaborativePartnerNameMeta
    ),
    foreignType: new PropertyMeta(
      "foreignType",
      "\u56FD\u5185\u5916\u533A\u5206",
      messageId("lbl.\u56FD\u5185\u5916\u533A\u5206"),
      "always",
      false,
      foreignTypeMeta
    ),
    commercialType: new PropertyMeta(
      "commercialType",
      "\u55B6\u5229\u975E\u55B6\u5229\u533A\u5206",
      messageId("lbl.\u55B6\u5229\u975E\u55B6\u5229\u533A\u5206"),
      "always",
      false,
      commercialTypeMeta
    ),
    collaborativePartnerRole: new PropertyMeta(
      "collaborativePartnerRole",
      "\u5206\u62C5\u6A5F\u95A2\u5F79\u5272",
      messageId("lbl.\u5206\u62C5\u6A5F\u95A2\u5F79\u5272"),
      "always",
      false,
      collaborativePartnerRoleMeta
    ),
    principalInvestigator: new PropertyMeta(
      "principalInvestigator",
      "\u7814\u7A76\u8CAC\u4EFB\u8005",
      messageId("lbl.\u7814\u7A76\u8CAC\u4EFB\u8005"),
      "always",
      false,
      collaborativePartnerUserInformationMeta
    ),
    contactPerson: new PropertyMeta(
      "contactPerson",
      "\u9023\u7D61\u62C5\u5F53\u8005",
      messageId("lbl.\u9023\u7D61\u62C5\u5F53\u8005"),
      "always",
      false,
      collaborativePartnerUserInformationMeta
    ),
    researchPlanProjectName: new PropertyMeta(
      "researchPlanProjectName",
      "\u7814\u7A76\u8A08\u753B\u66F8\u8AB2\u984C\u540D",
      messageId("lbl.\u7814\u7A76\u8A08\u753B\u66F8\u8AB2\u984C\u540D"),
      "always",
      false,
      collaborativePartnerResearchPlanProjectNameMeta
    ),
    attachmentFiles: new PropertyMeta(
      "attachmentFiles",
      "\u30D5\u30A1\u30A4\u30EB\u60C5\u5831",
      messageId("lbl.\u30D5\u30A1\u30A4\u30EB\u60C5\u5831"),
      "always",
      true,
      attachmentFileInformationMeta
    ),
  }
);
