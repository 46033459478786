import { HStack, useDisclosure } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import { selectUserInfo } from "../../../../store/auth/slice";
import React, { useMemo } from "react";
import { hasValue, isNullish } from "../../../../lib/util/common-util";
import { useSearchPage } from "../../../../hooks/use-search-page";
import {
  SearchAppUserApiArg,
  useSearchAppUserQuery,
} from "../../../../store/api/generated/stock-request-api";
import { useSearchResultPaginationNumbers } from "../../../../hooks/use-search-result-pagination-numbers";
import {
  appUserMeta,
  AppUserSaved,
} from "../../../../lib/object/entity/app-user";
import { SearchResultArea } from "../../../ui/search/SearchResultArea/SearchResultArea";
import { CMButton } from "@pscsrvlab/psc-react-components";
import { SearchConditionSidebarUser } from "./_components/SearchConditionSidebarUser/SearchConditionSidebarUser";
import { SearchResultTableUser } from "./_components/SearchResultTableUser/SearchResultTableUser";
import {
  getAllEnumFromSearchParams,
  getEnumFromSearchParams,
  getNumberFromSearchParams,
  getStringFromSearchParams,
} from "../../../../lib/util/search-params-util";
import { userSortConditionMeta } from "../../../../lib/object/value/user-sort-condition";
import { searchConditionLimitMeta } from "../../../../lib/object/value/search-condition-limit";
import { searchConditionPageNumberMeta } from "../../../../lib/object/value/search-condition-page-number";
import { roleMeta } from "../../../../lib/object/value/role";
import { accountStateMeta } from "../../../../lib/object/value/account-state";
import { fullNameMeta } from "../../../../lib/object/value/full-name";
import { titleAndPositionMeta } from "../../../../lib/object/value/title-and-position";
import { institutionIdMeta } from "../../../../lib/object/value/institution-id";
import { displayUserIdMeta } from "../../../../lib/object/value/display-user-id";
import { useAppTranslation } from "../../../../hooks/use-app-translation";
import { ConfirmationModal } from "../../../ui/modal/ConfirmationModal/ConfirmationModal";

export const FN81S01UserSearch = () => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const currentUserInfo = useAppSelector(selectUserInfo);

  // ログインユーザーが事務局の場合,trueを設定する
  const isOfficeMember = useMemo(() => {
    if (isNullish(currentUserInfo)) return false;
    return currentUserInfo.role === "office_member";
  }, [currentUserInfo]);
  // ログインユーザーが財団側のメンバーの場合,trueを設定する
  const isFoundationMember = useMemo(() => {
    if (isNullish(currentUserInfo)) return false;
    return (
      currentUserInfo.role === "office_member" ||
      currentUserInfo.role === "committee_member" ||
      currentUserInfo.role === "executive_director"
    );
  }, [currentUserInfo]);

  const { searchArgs, handleChangeSearchCondition, handleChangePage } =
    useSearchPage(urlSearchParamsToSearchArgs);

  /**
   * 検索処理。
   */
  const { data, isLoading } = useSearchAppUserQuery(searchArgs);
  const entities: AppUserSaved[] = useMemo(() => {
    if (isNullish(data)) return [];
    return data.items
      .map((v) => {
        // 案件は下書きの場合がありうるため、skipOptionalはtrueとする。
        return appUserMeta.toSavedDomainObjectOrNull(v, true);
      })
      .filter(hasValue);
  }, [data]);

  const { totalElements, currentPageNumber, totalPageCount } =
    useSearchResultPaginationNumbers(searchArgs, data);

  // 申請者作成の確認モーダル制御用
  const { isOpen, onOpen, onClose } = useDisclosure();

  // 確認モーダルで確定ボタンが押された場合の処理
  const handleSubmitCreateUser = () => {
    onClose();
    navigate("/user/create");
  };

  return (
    <>
      <HStack flex={1} spacing={0} overflow={"hidden"} alignItems={"stretch"}>
        <SearchConditionSidebarUser
          searchArgs={searchArgs}
          isFoundationMember={isFoundationMember}
          onChange={handleChangeSearchCondition}
        />
        <SearchResultArea
          isLoading={isLoading}
          currentPageNumber={currentPageNumber}
          totalPageCount={totalPageCount}
          totalElements={totalElements}
          onPageChange={handleChangePage}
          upperRightElement={
            isOfficeMember && (
              <CMButton
                alignSelf={"flex-end"}
                size={"sm"}
                label={t("btn.新しい申請者を作成するボタン")}
                onClick={onOpen}
              />
            )
          }
        >
          <SearchResultTableUser sx={{ flex: 1 }} entities={entities} />
        </SearchResultArea>
      </HStack>
      {/*ユーザー作成の確認モーダル*/}
      <ConfirmationModal
        isOpen={isOpen}
        message={t("mes.ユーザー作成確認メッセージ")}
        onSubmit={handleSubmitCreateUser}
        onCancel={onClose}
      />
    </>
  );
};

/**
 * クエリパラメータから検索条件を作成する.
 */
function urlSearchParamsToSearchArgs(
  searchParams: URLSearchParams,
): SearchAppUserApiArg {
  const sortCondition =
    getEnumFromSearchParams(
      searchParams,
      "sortCondition",
      userSortConditionMeta,
    ) ?? "user_id_asc";
  const limit =
    getNumberFromSearchParams(
      searchParams,
      "limit",
      searchConditionLimitMeta,
    ) ?? 10;

  const role = getAllEnumFromSearchParams(searchParams, "role", roleMeta);
  const displayUserId = getStringFromSearchParams(
    searchParams,
    "displayUserId",
    displayUserIdMeta.partial,
  );
  const fullName = getStringFromSearchParams(
    searchParams,
    "fullName",
    fullNameMeta.partial,
  );
  const institutionId = getNumberFromSearchParams(
    searchParams,
    "institutionId",
    institutionIdMeta,
  );
  const titleAndPosition = getStringFromSearchParams(
    searchParams,
    "titleAndPosition",
    titleAndPositionMeta.partial,
  );
  const accountState = getAllEnumFromSearchParams(
    searchParams,
    "accountState",
    accountStateMeta,
  );

  const pageNumber = getNumberFromSearchParams(
    searchParams,
    "pageNumber",
    searchConditionPageNumberMeta,
  );

  return {
    sortCondition,
    limit,

    role,
    displayUserId,
    fullName,
    institutionId,
    titleAndPosition,
    accountState,

    pageNumber,
  };
}
