import { theme as defaultTheme } from "@pscsrvlab/psc-react-components";
import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme(defaultTheme, {
  components: {
    Checkbox: {
      // 参考: https://github.com/chakra-ui/chakra-ui/issues/370#issuecomment-1055402655
      baseStyle: {
        icon: {},
        control: {},
        label: {
          _disabled: {
            opacity: 1,
          },
        },
      },
    },
    CMFormInputBaseCheckboxStyles: {
      baseStyle: {
        optionTextDisabled: {
          cursor: "default",
          _disabled: {
            opacity: 1,
          },
        },
      },
    },
    CMButtonSearchConditionStyles: {
      baseStyle: {
        container: {
          w: null,
        },
      },
    },
    CMMessageErrorStyles: {
      baseStyle: {
        container: {},
        icon: {
          mr: "10px",
        },
        labels: {},
        label: {
          fontSize: "16px",
        },
      },
    },
    CMSelectableListItemWithBadgeStyles: {
      baseStyle: {
        container: {
          _hover: {
            bgColor: "gray.200",
          },
        },
      },
    },
    CMFrameCardStyles: {
      baseStyle: {
        modeDelete: {
          backgroundColor: "gray.500",
        },
      },
    },
    StepItemStyles: {
      baseStyle: {
        labelBase: {
          mt: "6px",
        },
      },
    },
    CMButtonFormCommentStyles: {
      baseStyle: {
        popup: {
          zIndex: 10,
        },
      },
    },
    CMSearchAndSelectDropdownMenuStyles: {
      baseStyle: {
        menu: {
          maxH: "200px",
          overflowY: "auto",
        },
      },
    },
  },
});
