import {
  messageId,
  PropertyMeta,
  ValueObjectMetaString,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.string().max(100);
const savedMinimalSchema = z.string().max(100);
const schema = z.string().max(100);
const savedSchema = z.string().max(100);
const jsonSchema = z.string();
/**
 * 集荷先その他(値オブジェクト)のメタデータ。
 *
 *  */
export const pickupDestinationOtherMeta = new ValueObjectMetaString(
  "PickupDestinationOther",
  "\u96C6\u8377\u5148\u305D\u306E\u4ED6",
  messageId("lbl.\u96C6\u8377\u5148\u305D\u306E\u4ED6"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
