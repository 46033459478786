import {
  messageId,
  PropertyMeta,
  EntityMeta,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { documentIdMeta } from "../value/document-id";
import { documentControlNumberMeta } from "../value/document-control-number";
import { projectIdMeta } from "../value/project-id";
import { documentTypeMeta } from "../value/document-type";
import { documentStateMeta } from "../value/document-state";
import { documentPositionMeta } from "../value/document-position";
import { resultMeta } from "../value/result";
import { yearMonthDayMeta } from "../value/year-month-day";
import { creationMetadataMeta } from "../value/creation-metadata";
import { updateMetadataMeta } from "../value/update-metadata";
import { serialNumberMeta } from "../value/serial-number";
import { annualReportContentMeta } from "../value/annual-report-content";
import { reportOfficeMemberNotesMeta } from "../value/report-office-member-notes";
/**
 * 年次報告(エンティティ)
 *
 *  */
export type AnnualReport = z.infer<typeof schema>;
export type AnnualReportSaved = z.infer<typeof savedSchema>;
const minimalSchema = z.object({
  id: documentIdMeta.minimalSchema.optional(),
  documentControlNumber: documentControlNumberMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  projectId: projectIdMeta.minimalSchema,
  documentType: documentTypeMeta.minimalSchema,
  documentState: documentStateMeta.minimalSchema,
  documentPosition: documentPositionMeta.minimalSchema,
  result: resultMeta.minimalSchema.nullish().transform((x) => x ?? undefined),
  submissionDate: yearMonthDayMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  receptionDate: yearMonthDayMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  officeMemberCheckDate: yearMonthDayMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  approvalDate: yearMonthDayMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  documentCompletionDate: yearMonthDayMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  created: creationMetadataMeta.minimalSchema,
  updated: updateMetadataMeta.minimalSchema,
  annualReportSerialNumber: serialNumberMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  content: annualReportContentMeta.minimalSchema,
  officeMemberNotes: reportOfficeMemberNotesMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
const savedMinimalSchema = z.object({
  id: documentIdMeta.minimalSchema,
  documentControlNumber: documentControlNumberMeta.savedMinimalSchema,
  projectId: projectIdMeta.savedMinimalSchema,
  documentType: documentTypeMeta.savedMinimalSchema,
  documentState: documentStateMeta.savedMinimalSchema,
  documentPosition: documentPositionMeta.savedMinimalSchema,
  result: resultMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  submissionDate: yearMonthDayMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  receptionDate: yearMonthDayMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  officeMemberCheckDate: yearMonthDayMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  approvalDate: yearMonthDayMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  documentCompletionDate: yearMonthDayMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  created: creationMetadataMeta.savedMinimalSchema,
  updated: updateMetadataMeta.savedMinimalSchema,
  annualReportSerialNumber: serialNumberMeta.savedMinimalSchema,
  content: annualReportContentMeta.savedMinimalSchema,
  officeMemberNotes: reportOfficeMemberNotesMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
const schema = z.object({
  id: documentIdMeta.minimalSchema.optional(),
  documentControlNumber: documentControlNumberMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
  projectId: projectIdMeta.schema,
  documentType: documentTypeMeta.schema,
  documentState: documentStateMeta.schema,
  documentPosition: documentPositionMeta.schema,
  result: resultMeta.schema.nullish().transform((x) => x ?? undefined),
  submissionDate: yearMonthDayMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
  receptionDate: yearMonthDayMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
  officeMemberCheckDate: yearMonthDayMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
  approvalDate: yearMonthDayMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
  documentCompletionDate: yearMonthDayMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
  created: creationMetadataMeta.schema,
  updated: updateMetadataMeta.schema,
  annualReportSerialNumber: serialNumberMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
  content: annualReportContentMeta.schema,
  officeMemberNotes: reportOfficeMemberNotesMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
});
const savedSchema = z.object({
  id: documentIdMeta.minimalSchema,
  documentControlNumber: documentControlNumberMeta.savedSchema,
  projectId: projectIdMeta.savedSchema,
  documentType: documentTypeMeta.savedSchema,
  documentState: documentStateMeta.savedSchema,
  documentPosition: documentPositionMeta.savedSchema,
  result: resultMeta.savedSchema.nullish().transform((x) => x ?? undefined),
  submissionDate: yearMonthDayMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  receptionDate: yearMonthDayMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  officeMemberCheckDate: yearMonthDayMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  approvalDate: yearMonthDayMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  documentCompletionDate: yearMonthDayMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  created: creationMetadataMeta.savedSchema,
  updated: updateMetadataMeta.savedSchema,
  annualReportSerialNumber: serialNumberMeta.savedSchema,
  content: annualReportContentMeta.savedSchema,
  officeMemberNotes: reportOfficeMemberNotesMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
const jsonSchema = z.object({
  id: documentIdMeta.jsonSchema.optional(),
  documentControlNumber: documentControlNumberMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  projectId: projectIdMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  documentType: documentTypeMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  documentState: documentStateMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  documentPosition: documentPositionMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  result: resultMeta.jsonSchema.nullish().transform((x) => x ?? undefined),
  submissionDate: yearMonthDayMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  receptionDate: yearMonthDayMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  officeMemberCheckDate: yearMonthDayMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  approvalDate: yearMonthDayMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  documentCompletionDate: yearMonthDayMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  created: creationMetadataMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  updated: updateMetadataMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  annualReportSerialNumber: serialNumberMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  content: annualReportContentMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  officeMemberNotes: reportOfficeMemberNotesMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
/**
 * 年次報告(エンティティ)のメタデータ。
 *
 *  */
export const annualReportMeta = new EntityMeta<
  typeof schema,
  typeof savedSchema,
  typeof jsonSchema
>(
  "AnnualReport",
  "\u5E74\u6B21\u5831\u544A",
  messageId("lbl.\u5E74\u6B21\u5831\u544A"),
  minimalSchema,
  savedMinimalSchema,
  schema,
  savedSchema,
  jsonSchema,
  undefined,
  undefined,
  {
    id: new PropertyMeta(
      "id",
      "ID",
      messageId("lbl.ID"),
      "afterSave",
      false,
      documentIdMeta
    ),
    documentControlNumber: new PropertyMeta(
      "documentControlNumber",
      "\u66F8\u985E\u7BA1\u7406\u756A\u53F7",
      messageId("lbl.\u66F8\u985E\u7BA1\u7406\u756A\u53F7"),
      "afterSave",
      false,
      documentControlNumberMeta
    ),
    projectId: new PropertyMeta(
      "projectId",
      "\u6848\u4EF6ID",
      messageId("lbl.\u6848\u4EF6ID"),
      "always",
      false,
      projectIdMeta
    ),
    documentType: new PropertyMeta(
      "documentType",
      "\u66F8\u985E\u7A2E\u5225",
      messageId("lbl.\u66F8\u985E\u7A2E\u5225"),
      "always",
      false,
      documentTypeMeta
    ),
    documentState: new PropertyMeta(
      "documentState",
      "\u66F8\u985E\u72B6\u614B",
      messageId("lbl.\u66F8\u985E\u72B6\u614B"),
      "always",
      false,
      documentStateMeta
    ),
    documentPosition: new PropertyMeta(
      "documentPosition",
      "\u66F8\u985E\u4F4D\u7F6E",
      messageId("lbl.\u66F8\u985E\u4F4D\u7F6E"),
      "always",
      false,
      documentPositionMeta
    ),
    result: new PropertyMeta(
      "result",
      "\u7D50\u679C",
      messageId("lbl.\u7D50\u679C"),
      "none",
      false,
      resultMeta
    ),
    submissionDate: new PropertyMeta(
      "submissionDate",
      "\u63D0\u51FA\u65E5",
      messageId("lbl.\u63D0\u51FA\u65E5"),
      "none",
      false,
      yearMonthDayMeta
    ),
    receptionDate: new PropertyMeta(
      "receptionDate",
      "\u53D7\u4ED8\u65E5",
      messageId("lbl.\u53D7\u4ED8\u65E5"),
      "none",
      false,
      yearMonthDayMeta
    ),
    officeMemberCheckDate: new PropertyMeta(
      "officeMemberCheckDate",
      "\u4E8B\u52D9\u5C40\u78BA\u8A8D\u65E5",
      messageId("lbl.\u4E8B\u52D9\u5C40\u78BA\u8A8D\u65E5"),
      "none",
      false,
      yearMonthDayMeta
    ),
    approvalDate: new PropertyMeta(
      "approvalDate",
      "\u627F\u8A8D\u65E5",
      messageId("lbl.\u627F\u8A8D\u65E5"),
      "none",
      false,
      yearMonthDayMeta
    ),
    documentCompletionDate: new PropertyMeta(
      "documentCompletionDate",
      "\u66F8\u985E\u5B8C\u4E86\u65E5",
      messageId("lbl.\u66F8\u985E\u5B8C\u4E86\u65E5"),
      "none",
      false,
      yearMonthDayMeta
    ),
    created: new PropertyMeta(
      "created",
      "\u4F5C\u6210",
      messageId("lbl.\u4F5C\u6210"),
      "always",
      false,
      creationMetadataMeta
    ),
    updated: new PropertyMeta(
      "updated",
      "\u66F4\u65B0",
      messageId("lbl.\u66F4\u65B0"),
      "always",
      false,
      updateMetadataMeta
    ),
    annualReportSerialNumber: new PropertyMeta(
      "annualReportSerialNumber",
      "\u5E74\u6B21\u5831\u544A\u679D\u756A",
      messageId("lbl.\u5E74\u6B21\u5831\u544A\u679D\u756A"),
      "afterSave",
      false,
      serialNumberMeta
    ),
    content: new PropertyMeta(
      "content",
      "\u5185\u5BB9",
      messageId("lbl.\u5185\u5BB9"),
      "always",
      false,
      annualReportContentMeta
    ),
    officeMemberNotes: new PropertyMeta(
      "officeMemberNotes",
      "\u4E8B\u52D9\u5C40\u8A18\u5165\u60C5\u5831",
      messageId("lbl.\u4E8B\u52D9\u5C40\u8A18\u5165\u60C5\u5831"),
      "none",
      false,
      reportOfficeMemberNotesMeta
    ),
  }
);
