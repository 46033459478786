import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * 機関並び順(値オブジェクト)
 *
 *
 *
 * institution_id_asc:
 * institution_id_desc:
 * updated_at_asc:
 * updated_at_desc:  */
export type InstitutionSortCondition = z.infer<
  typeof institutionSortConditionMeta.schema
>;
/**
 * 機関並び順(値オブジェクト)のメタデータ。
 *
 *  */
export const institutionSortConditionMeta = new ValueObjectMetaEnum<
  [
    "institution_id_asc",
    "institution_id_desc",
    "updated_at_asc",
    "updated_at_desc"
  ]
>(
  "InstitutionSortCondition",
  "\u6A5F\u95A2\u4E26\u3073\u9806",
  messageId("lbl.\u6A5F\u95A2\u4E26\u3073\u9806"),
  [
    "institution_id_asc",
    "institution_id_desc",
    "updated_at_asc",
    "updated_at_desc",
  ],
  {
    institution_id_asc: "code.\u4E26\u3073\u9806.\u6A5F\u95A2ID\u6607\u9806",
    institution_id_desc: "code.\u4E26\u3073\u9806.\u6A5F\u95A2ID\u964D\u9806",
    updated_at_asc:
      "code.\u4E26\u3073\u9806.\u66F4\u65B0\u65E5\u6642\u6607\u9806",
    updated_at_desc:
      "code.\u4E26\u3073\u9806.\u66F4\u65B0\u65E5\u6642\u964D\u9806",
  }
);
