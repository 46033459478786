import { Box, HStack, VStack } from "@chakra-ui/react";
import { CMButton } from "@pscsrvlab/psc-react-components";
import { hasValue, isNullish } from "../../../../lib/util/common-util";
import { useNavigate } from "react-router-dom";
import { useMemo } from "react";
import { useAppTranslation } from "../../../../hooks/use-app-translation";
import { useAppGetReviewConferenceQuery } from "../../../../hooks/query/use-app-get-review-conference-query";
import { useAppParams } from "../../../../hooks/use-app-params";
import { ymdToDateText } from "../../../../lib/util/common-date-util";
import { LabeledTextVertical } from "../../../ui/text/LabeledTextVertical/LabeledTextVertical";

export const FN50S03ReviewConferenceConcluded = () => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();

  const { reviewConferenceId } = useAppParams();

  const { data: reviewConferenceData } = useAppGetReviewConferenceQuery(
    {
      reviewConferenceId: reviewConferenceId ?? -1,
    },
    { skip: isNullish(reviewConferenceId) },
  );

  const reviewConferenceName = useMemo(() => {
    if (isNullish(reviewConferenceData)) return undefined;
    return reviewConferenceData.reviewConferenceName;
  }, [reviewConferenceData]);

  const reviewConferenceDate = useMemo(() => {
    if (
      isNullish(reviewConferenceData) ||
      isNullish(reviewConferenceData.reviewConferenceDate)
    )
      return undefined;
    return ymdToDateText(reviewConferenceData.reviewConferenceDate);
  }, [reviewConferenceData]);

  const handleClick = () => {
    navigate("../");
  };

  return (
    <Box>
      {hasValue(reviewConferenceName) && (
        <VStack spacing={"20px"} pt={"50px"} color={"gray.900"}>
          <Box fontSize={"2xl"} fontWeight={"bold"}>
            {t("mes.審査会結果完了メッセージ")}
          </Box>
          <HStack spacing={"30px"}>
            <LabeledTextVertical
              label={t("lbl.審査会名")}
              text={reviewConferenceName}
            />
            <LabeledTextVertical
              label={t("lbl.審査実施日")}
              text={reviewConferenceDate}
            />
          </HStack>
          <CMButton label={t("btn.審査会に戻るボタン")} onClick={handleClick} />
        </VStack>
      )}
    </Box>
  );
};
