import { ComponentStyleProps } from "../../../../../lib/styles/props/component-style-props";
import { CMButtonSearchConditionDateRange } from "@pscsrvlab/psc-react-components";
import React, { useCallback } from "react";
import { hasValue, isNullish } from "../../../../../lib/util/common-util";
import {
  jsDateDiffInDays,
  ymdToDateText,
  ymdToJsDate,
} from "../../../../../lib/util/common-date-util";
import { useAppTranslation } from "../../../../../hooks/use-app-translation";
import useCustomToast from "../../../../../hooks/use-custom-toast";
import log from "loglevel";

export type ButtonSearchConditionDateRangeProps = {
  label: string;
  placeholderDate?: {
    start: string;
    end: string;
  };
  value?: {
    start?: {
      year: number;
      month: number;
      day: number;
    };
    end?: {
      year: number;
      month: number;
      day: number;
    };
  } | null;
  minDate?: {
    year: number;
    month: number;
    day: number;
  };
  maxDate?: {
    year: number;
    month: number;
    day: number;
  };

  /**
   * 指定した場合、指定した日数以下の期間のみを認め、
   * そうでなければエラーとする(開始か終了の片方でも未指定の場合も含む)。
   */
  maxPeriodDays?: number;

  hideClearButton?: boolean;

  onChange?: (start: string | undefined, end: string | undefined) => void;
} & ComponentStyleProps;

export const ButtonSearchConditionDateRange = ({
  label,
  placeholderDate,

  value,

  minDate,
  maxDate,

  maxPeriodDays,

  hideClearButton,

  onChange,

  sx,
  ...rest
}: ButtonSearchConditionDateRangeProps) => {
  const { t } = useAppTranslation();
  const { errorToast } = useCustomToast();

  const handleChange = useCallback(
    (value: {
      start?: {
        year: number;
        month: number;
        day: number;
      };
      end?: {
        year: number;
        month: number;
        day: number;
      };
    }) => {
      // maxPeriodDaysのチェック。
      if (hasValue(maxPeriodDays)) {
        if (isNullish(value.start)) {
          errorToast(t("mes.必須チェックエラー2", { name: "$t(lbl.開始日)" }));
          return;
        }
        if (isNullish(value.end)) {
          errorToast(t("mes.必須チェックエラー2", { name: "$t(lbl.終了日)" }));
          return;
        }

        if (!isWithinMaxPeriodDays(value.start, value.end, maxPeriodDays)) {
          errorToast(t("mes.日時指定範囲超過エラー"));
          return;
        }
      }

      // テキストに変換する。
      const startText = hasValue(value.start)
        ? ymdToDateText(value.start)
        : undefined;
      const endText = hasValue(value.end)
        ? ymdToDateText(value.end)
        : undefined;
      onChange?.(startText, endText);
    },
    [errorToast, maxPeriodDays, onChange, t],
  );

  const capitalizedLabel = label;

  return (
    <CMButtonSearchConditionDateRange
      label={capitalizedLabel}
      placeholderDate={placeholderDate}
      value={value}
      minDate={minDate}
      maxDate={maxDate}
      hideClearButton={hideClearButton}
      separatorRange={t("lbl.範囲シンボル")}
      labelSubmit={t("btn.確定ボタン")}
      labelClear={t("btn.クリアボタン")}
      onChange={handleChange}
      sx={sx}
      {...rest}
    />
  );
};

function isWithinMaxPeriodDays(
  start: {
    year: number;
    month: number;
    day: number;
  },
  end: {
    year: number;
    month: number;
    day: number;
  },
  maxPeriodDays: number,
): boolean {
  const startJsDate = ymdToJsDate(start);
  const endJsDate = ymdToJsDate(end);

  const diffDays = jsDateDiffInDays(startJsDate, endJsDate);
  log.debug(`diffDays=${diffDays}`);
  return 0 <= diffDays && diffDays <= maxPeriodDays;
}
