import {
  SharedAttachmentFile as SharedAttachmentFileJson,
  useListSharedAttachmentFileQuery,
} from "../../store/api/generated/stock-request-api";
import { isNullish } from "../../lib/util/common-util";
import { useMemo } from "react";
import {
  sharedAttachmentFileMeta,
  SharedAttachmentFileSaved,
} from "../../lib/object/entity/shared-attachment-file";

export function useAppListSharedAttachmentFileQuery(
  arg: Parameters<typeof useListSharedAttachmentFileQuery>[0],
  options?: Parameters<typeof useListSharedAttachmentFileQuery>[1],
  skipOptionalValidations = true, // get系は、skipOptionalValidationsのデフォルトはtrueとする。
): { data: SharedAttachmentFileSaved[] | null } {
  const { data: rawData } = useListSharedAttachmentFileQuery(arg, options);
  const data = useMemo(() => {
    if (isNullish(rawData)) return null;
    return rawData.map((d: SharedAttachmentFileJson) =>
      sharedAttachmentFileMeta.toSavedDomainObjectOrNull(
        d,
        skipOptionalValidations,
      ),
    );
  }, [rawData, skipOptionalValidations]);
  return { data };
}
