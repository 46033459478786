import { ComponentStyleProps } from "../../../../../../../../lib/styles/props/component-style-props";
import { memo, Ref, useCallback, useMemo } from "react";
import { VStack } from "@chakra-ui/react";
import { isNullish } from "../../../../../../../../lib/util/common-util";
import { commentButtonIdOf } from "../../../../../../../../lib/util/app-util";
import { useAppTranslation } from "../../../../../../../../hooks/use-app-translation";
import { useScrollToId } from "../../../../../../../../hooks/use-scroll-to-id";
import { RevisionRequestCommentHistoryListComment } from "../RevisionRequestCommentListComment/RevisionRequestCommentHistoryListComment";
import { Comments } from "@pscsrvlab/psc-react-components";

export type RevisionRequestCommentHistoryListThreadProps = {
  isApplicantEditable: boolean;

  /**
   * 1セクション単位の修正依頼コメント・返信履歴
   */
  comments: Comments;
  onSaveReply?: (path: string, value: string | null) => void;

  selected?: boolean;

  onClick?: () => void;

  scrollableRef: Ref<any>;
  scrollOffset: number;
} & ComponentStyleProps;

export const RevisionRequestCommentHistoryListThread = memo(
  function RevisionRequestCommentListThread({
    isApplicantEditable,

    comments,
    onSaveReply,

    selected,

    onClick,

    scrollableRef,
    scrollOffset,

    sx,
    ...rest
  }: RevisionRequestCommentHistoryListThreadProps) {
    const { t } = useAppTranslation();
    const { scrollToId } = useScrollToId(scrollableRef, scrollOffset);

    const officeMemberComment = useMemo(
      () => ({
        userName: t("lbl.事務局"),
        datetime: comments.mainComment?.date,
        body: comments.mainComment?.comment,
        isHistory: comments.isHistory
      }),
      [comments.isHistory, comments.mainComment?.comment, comments.mainComment?.date, t],
    );
    const applicantComment = useMemo(() => {
      if (isNullish(comments.replyComment)) return null;
      return {
        userName: comments.replyComment.userName,
        datetime: comments.replyComment.date,
        body: comments.replyComment.comment,
        isHistory: comments.isHistory
      };
    }, [comments.isHistory, comments.replyComment]);
    const bgColor = useMemo(() => {
      return selected ? "orange.100" : undefined;
    }, [selected]);

    const commentButtonId = useMemo(
      () => (!isNullish(comments) && !isNullish(comments.path) ? commentButtonIdOf(comments.path) : ""),
      [comments],
    );

    const handleClick = useCallback(() => {
      scrollToId(commentButtonId);
      onClick?.();
    }, [scrollToId, commentButtonId, onClick]);

    const handleSaveReply = useCallback(
      (value: string | null) => {
        (!isNullish(comments) && !isNullish(comments.path) ? onSaveReply?.(comments.path, value) : "")
      },
      [comments, onSaveReply],
    );

    return (
      <VStack
        alignItems={"stretch"}
        py={"4px"}
        sx={sx}
        {...rest}
        bgColor={bgColor}
      >
        {/*<Link href={linkTo} sx={linkStyles}>*/}
        <VStack onClick={handleClick} alignItems={"stretch"}>
          <RevisionRequestCommentHistoryListComment
            editable={false}
            comment={officeMemberComment}
            isOfficeMember
          />
        </VStack>
        {/*</Link>*/}
        <RevisionRequestCommentHistoryListComment
          editable={isApplicantEditable}
          onSave={handleSaveReply}
          comment={applicantComment}

        />
      </VStack>
    );
  },
);
