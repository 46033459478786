import React from "react";
import { Stack } from "@chakra-ui/react";
import {
  CMFormInputTextArea,
  ValueObjectMetaString,
} from "@pscsrvlab/psc-react-components";
import { FormSection } from "../../../../../ui/form/FormSection/FormSection";

export type SectionCommentProps = {
  /**
   * 画面編集モード
   */
  editMode?: "editable" | "disabled" | "readOnly";

  /**
   * タイトル
   */
  title?: string;

  /**
   * 補足
   */
  description?: string;

  value?: string;

  /**
   * 値オブジェクトメタデータ
   */
  valueObjectMeta?: ValueObjectMetaString;

  /**
   * 変更イベント
   * @param changedValue
   */
  onChange?: (changedValue: string) => void;
};

/**
 * FN40-S30審査
 * コメントセクション
 */
export const SectionComment = ({
  editMode = "readOnly",

  title,
  description,

  value,

  valueObjectMeta,
  onChange,
}: SectionCommentProps) => {
  const handleChange = (selectedValue: string) => {
    onChange?.(selectedValue);
  };

  return (
    <FormSection title={title} description={description}>
      <Stack pl={"16px"} pr={"16px"}>
        <CMFormInputTextArea
          editMode={editMode}
          noHeader={true}
          minRows={4}
          value={value}
          valueObjectMeta={valueObjectMeta}
          onChange={handleChange}
        />
      </Stack>
    </FormSection>
  );
};
