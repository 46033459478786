import { DifferentiatedCellProvision } from "../../../../../../../../lib/object/value/differentiated-cell-provision";
import { ComponentStyleProps } from "../../../../../../../../lib/styles/props/component-style-props";
import { Stack } from "@chakra-ui/react";
import {
  CMFormInputText,
  CMFormInputTextArea,
} from "@pscsrvlab/psc-react-components";
import { differentiatedCellProvisionToMeta } from "../../../../../../../../lib/object/value/differentiated-cell-provision-to";
import { differentiatedCellInformationMeta } from "../../../../../../../../lib/object/value/differentiated-cell-information";
import { differentiatedCellProvisionReasonMeta } from "../../../../../../../../lib/object/value/differentiated-cell-provision-reason";
import { differentiatedCellPreservationTypeMeta } from "../../../../../../../../lib/object/value/differentiated-cell-preservation-type";
import { useAppTranslation } from "../../../../../../../../hooks/use-app-translation";
import { memo, useCallback } from "react";
import { isEqual } from "lodash";
import log from "loglevel";

export type CellProvisionCardProps = {
  editMode: "editable" | "readOnly";

  index: number;

  value: DifferentiatedCellProvision;

  onChange: (
    index: number,
    change: (
      before: DifferentiatedCellProvision,
    ) => DifferentiatedCellProvision,
  ) => void;
} & ComponentStyleProps;

export const CellProvisionCard = memo(
  function CellProvisionCard({
    editMode,

    index,

    value,

    onChange,

    sx,
    ...rest
  }: CellProvisionCardProps) {
    const { t } = useAppTranslation();

    const handleChange = useCallback(
      (
        change: (
          before: DifferentiatedCellProvision,
        ) => DifferentiatedCellProvision,
      ) => {
        onChange?.(index, change);
      },
      [index, onChange],
    );

    const handleChangeRecipientName = useCallback(
      (v: string) =>
        handleChange((before) => ({
          ...before,
          recipientName: v,
        })),
      [handleChange],
    );
    const handleChangeCellInformation = useCallback(
      (v: string) =>
        handleChange((before) => ({
          ...before,
          cellInformation: v,
        })),
      [handleChange],
    );
    const handleChangeProvisionReason = useCallback(
      (v: string) =>
        handleChange((before) => ({
          ...before,
          provisionReason: v,
        })),
      [handleChange],
    );
    const handleChangeCellDisposalType = useCallback(
      (v: string) =>
        handleChange((before) => ({
          ...before,
          cellDisposalType: v,
        })),
      [handleChange],
    );

    return (
      <Stack direction={"column"} spacing={"20px"} sx={sx} {...rest}>
        <CMFormInputText
          label={t("lbl.提供先機関名")}
          value={value.recipientName}
          onChange={handleChangeRecipientName}
          editMode={editMode}
          valueObjectMeta={differentiatedCellProvisionToMeta}
        />
        <CMFormInputTextArea
          label={t("lbl.試料・情報の内容")}
          value={value.cellInformation}
          onChange={handleChangeCellInformation}
          editMode={editMode}
          valueObjectMeta={differentiatedCellInformationMeta}
        />
        <CMFormInputTextArea
          label={t("lbl.提供理由")}
          value={value.provisionReason}
          onChange={handleChangeProvisionReason}
          editMode={editMode}
          valueObjectMeta={differentiatedCellProvisionReasonMeta}
        />
        <CMFormInputTextArea
          label={t("lbl.試料・情報の保管・廃棄状況")}
          value={value.cellDisposalType}
          onChange={handleChangeCellDisposalType}
          editMode={editMode}
          valueObjectMeta={differentiatedCellPreservationTypeMeta}
        />
      </Stack>
    );
  },
  (prevProps, nextProps) => {
    const _isEqual = isEqual(prevProps, nextProps);
    log.debug(`CellProvisionCard: _isEqual=${_isEqual}`);
    return _isEqual;
  },
);
