import { useNavigate } from "react-router-dom";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { ProgressStepDocumentRevision } from "../../../../../ui/progress/ProgressStepDocumentRevision/ProgressStepDocumentRevision";
import {
  Center,
  Container,
  Flex,
  HStack,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { FrameUpperLeftButton } from "../../../../../ui/frame/FrameUpperLeftButton/FrameUpperLeftButton";
import { CMButtonBack } from "@pscsrvlab/psc-react-components";
import { hasValue } from "../../../../../../lib/util/common-util";
import { NewApplicationDocument } from "../../../../../model/document/application/new-application/NewApplicationDocument/NewApplicationDocument";
import { DocumentViewModel } from "../../../../../../lib/object/vm/document-view-model";
import { LoginUserInfo } from "../../../../../../store/auth/types";
import { RevisionRequestSaved } from "../../../../../../lib/object/entity/revision-request";
import { ChangeApplicationDocument } from "../../../../../model/document/application/change-application/ChangeApplicationDocument/ChangeApplicationDocument";
import { AnnualReportDocument } from "../../../../../model/document/report/annual-report/AnnualReportDocument/AnnualReportDocument";
import { TerminationReportDocument } from "../../../../../model/document/report/termination-report/TerminationReportDocument/TerminationReportDocument";
import { CommonNextButton } from "../../../../../ui/button/CommonNextButton/CommonNextButton";
import { RevisionRequestCommentList } from "../../../../../model/document/RevisionRequestPage/_components/RevisionRequestCommentList/RevisionRequestCommentList";
import { CommonCancelButton } from "../../../../../ui/button/CommonCancelButton/CommonCancelButton";
import log from "loglevel";
import { errorMessageOf } from "../../../../../../lib/util/error-util";
import { useSubmitRevisionRequestReplyMutation } from "../../../../../../store/api/generated/stock-request-api";
import useCustomToast from "../../../../../../hooks/use-custom-toast";
import { RevisionMainCommentBalloon } from "../../../../../model/revision/RevisionMainCommentBalloon/RevisionMainCommentBalloon";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { ConfirmationModal } from "../../../../../ui/modal/ConfirmationModal/ConfirmationModal";

export type ReviseConfirmPageProps<VM extends DocumentViewModel> = {
  loginUserInfo: LoginUserInfo;

  /**
   * URLパラメタの案件ID。
   * 案件画面内にいるかの判定に利用する。
   */
  projectIdParam?: number;

  projectId: number;
  documentId: number;

  vm: VM;

  revisionRequest: RevisionRequestSaved;
};
export const ReviseConfirmPage = <VM extends DocumentViewModel>({
  loginUserInfo,

  projectIdParam,

  projectId,
  documentId,

  vm,

  revisionRequest,
}: ReviseConfirmPageProps<VM>) => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const { errorToast } = useCustomToast();

  const [selectedCommentPath, setSelectedCommentPath] = useState<string | null>(
    null,
  );
  const handleSelectComment = useCallback((path: string | null) => {
    setSelectedCommentPath(path);
  }, []);

  const urlPathPrefix = useMemo(
    () => (hasValue(projectIdParam) ? `/project/${projectIdParam}` : ""),
    [projectIdParam],
  );
  const backUrlPath = useMemo(() => {
    return `${urlPathPrefix}/document/${documentId}/content/revise/${revisionRequest.id}/create`;
  }, [urlPathPrefix, documentId, revisionRequest.id]);

  const handleClickBack = useCallback(() => {
    navigate(backUrlPath);
  }, [navigate, backUrlPath]);

  const [submitRevisionRequestReply] = useSubmitRevisionRequestReplyMutation();
  const handleSubmit = useCallback(async () => {
    try {
      await submitRevisionRequestReply({
        revisionRequestId: revisionRequest.id,
      }).unwrap();
      navigate(
        `${urlPathPrefix}/document/${documentId}/content/revise/${revisionRequest.id}/complete`,
      );
    } catch (e) {
      errorToast(t("mes.汎用エラーメッセージ"));
      log.error(errorMessageOf(e));
    }
  }, [
    documentId,
    errorToast,
    navigate,
    revisionRequest.id,
    submitRevisionRequestReply,
    t,
    urlPathPrefix,
  ]);

  // 提出確認モーダル制御
  const {
    isOpen: isOpenSubmitModal,
    onOpen: onOpenSubmitModal,
    onClose: onCloseSubmitModal,
  } = useDisclosure();

  const scrollableRef = useRef(null);

  return (
    <VStack
      className={"ReviseConfirmPage"}
      alignSelf={"stretch"}
      alignItems={"stretch"}
      overflow={"hidden"}
      spacing={0}
    >
      <Flex
        direction={"row"}
        alignItems={"flex-start"}
        borderBottomWidth={"1px"}
        borderColor={"gray.300"}
        pb={"12px"}
      >
        <FrameUpperLeftButton>
          <CMButtonBack
            labelBack={t("btn.修正に戻るボタン")}
            onClick={handleClickBack}
          />
        </FrameUpperLeftButton>
        <Center flex={1}>
          <ProgressStepDocumentRevision currentStep={1} />
        </Center>
      </Flex>
      <HStack
        flex={"1 1 auto"}
        minH={0}
        spacing={0}
        alignItems={"stretch"}
        className={"HStack"}
      >
        <Flex
          direction={"column"}
          alignItems={"center"}
          flex={1}
          pb={"60px"}
          overflow={"auto"}
          className={"VStack"}
          ref={scrollableRef}
        >
          <HStack
            alignSelf={"stretch"}
            pt={"10px"}
            pl={"10px"}
            alignItems={"flex-start"}
          >
            <RevisionMainCommentBalloon
              mode={"request"}
              revisionRequestId={revisionRequest.id}
            />
            <RevisionMainCommentBalloon
              mode={"reply"}
              revisionRequestId={revisionRequest.id}
            />
          </HStack>
          <Container minW={"500px"} maxW={"720px"}>
            {vm.type === "new_application" &&
              hasValue(loginUserInfo) &&
              hasValue(projectId) && (
                <NewApplicationDocument
                  loginUserInfo={loginUserInfo}
                  editMode={"readOnly"}
                  value={vm}
                  revisionMode={"readOnly"}
                  revisionRequestItems={revisionRequest.items}
                  onSelectComment={handleSelectComment}
                  scrollableRef={scrollableRef}
                  scrollOffset={60}
                />
              )}
            {vm.type === "change_application" &&
              hasValue(loginUserInfo) &&
              hasValue(projectId) && (
                <ChangeApplicationDocument
                  loginUserInfo={loginUserInfo}
                  editMode={"readOnly"}
                  value={vm}
                  revisionMode={"readOnly"}
                  revisionRequestItems={revisionRequest.items}
                  onSelectComment={handleSelectComment}
                  scrollableRef={scrollableRef}
                  scrollOffset={60}
                />
              )}
            {vm.type === "annual_report" &&
              hasValue(loginUserInfo) &&
              hasValue(projectId) && (
                <AnnualReportDocument
                  loginUserInfo={loginUserInfo}
                  editMode={"readOnly"}
                  value={vm}
                  revisionMode={"readOnly"}
                  revisionRequestItems={revisionRequest.items}
                  onSelectComment={handleSelectComment}
                />
              )}
            {vm.type === "termination_report" &&
              hasValue(loginUserInfo) &&
              hasValue(projectId) && (
                <TerminationReportDocument
                  loginUserInfo={loginUserInfo}
                  editMode={"readOnly"}
                  value={vm}
                  revisionMode={"readOnly"}
                  revisionRequestItems={revisionRequest.items}
                  onSelectComment={handleSelectComment}
                />
              )}
          </Container>
          <HStack mt={"20px"}>
            <CommonCancelButton
              label={t("btn.修正に戻るボタン")}
              onClick={handleClickBack}
            />
            <CommonNextButton
              label={t("btn.この内容で提出するボタン")}
              onClick={onOpenSubmitModal}
            />
          </HStack>
        </Flex>
        <RevisionRequestCommentList
          revisionMode={"readOnly"}
          revisionRequestItems={revisionRequest.items}
          selectedCommentPath={selectedCommentPath}
          scrollableRef={scrollableRef}
          scrollOffset={60}
        />
      </HStack>
      <ConfirmationModal
        isOpen={isOpenSubmitModal}
        message={t("mes.提出確認メッセージ")}
        onSubmit={handleSubmit}
        onCancel={onCloseSubmitModal}
      />
    </VStack>
  );
};
