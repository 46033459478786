import { FN30S06ProjectContentApplication } from "../FN30S06ProjectContentApplication/FN30S06ProjectContentApplication";
import { FN30S08ProjectContentDisclosureDocument } from "../FN30S08ProjectContentDisclosureDocument/FN30S08ProjectContentDisclosureDocument";
import { ProjectContentTabs } from "./_components/ProjectContentTabs/ProjectContentTabs";
import { Divider } from "@chakra-ui/react";
import { hasValue } from "../../../../lib/util/common-util";
import { useAppParams } from "../../../../hooks/use-app-params";

export type FN30S04ProjectContentProps = {
  mode: "application" | "disclosure-document";
};
export const FN30S04ProjectContent = ({ mode }: FN30S04ProjectContentProps) => {
  const { projectId } = useAppParams();

  return (
    <>
      {hasValue(projectId) && (
        <>
          <ProjectContentTabs sx={{ h: "30px" }} projectId={projectId} />
          <Divider borderColor={"gray.300"} />
          {mode === "application" ? (
            <FN30S06ProjectContentApplication projectId={projectId} />
          ) : (
            <FN30S08ProjectContentDisclosureDocument />
          )}
        </>
      )}
    </>
  );
};
