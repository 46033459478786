import {
  messageId,
  PropertyMeta,
  ValueObjectMetaString,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.string().max(100);
const savedMinimalSchema = z.string().max(100);
const schema = z.string().min(1).max(100);
const savedSchema = z.string().min(1).max(100);
const jsonSchema = z.string();
/**
 * Cognitoユーザー名(値オブジェクト)のメタデータ。
 *
 * Cognitoにおけるユーザーの一意識別子（username）。 */
export const cognitoUsernameMeta = new ValueObjectMetaString(
  "CognitoUsername",
  "Cognito\u30E6\u30FC\u30B6\u30FC\u540D",
  messageId("lbl.Cognito\u30E6\u30FC\u30B6\u30FC\u540D"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
