import { Outlet, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectAuthenticationStatus } from "../../store/auth/slice";
import log from "loglevel";
import { ProcessLoader } from "../ui/other/ProcessLoader/ProcessLoader";
import { useFromPathnameSearchHash } from "../../hooks/use-from-pathname-search-hash";
import { useEffect } from "react";

/**
 *  ゲストにのみアクセス可能なページを設定するコンポーネント。
 *  既にログイン済みのユーザーがログイン画面にアクセスした場合に使用する。
 */
export const GuestRoute = () => {
  // 認証情報を取得
  const authenticationStatus = useSelector(selectAuthenticationStatus);

  // ログイン後の遷移先パス（デフォルトはホーム）
  const redirectPathnameSearchHash: string = useFromPathnameSearchHash();
  const navigate = useNavigate();

  useEffect(() => {
    // 既にログイン済みの場合は次の画面へリダイレクト
    if (authenticationStatus === "authenticated") {
      log.debug("Already logged in. Redirect to next page.");
      navigate(redirectPathnameSearchHash);
    }
  }, [authenticationStatus, navigate, redirectPathnameSearchHash]);

  if (authenticationStatus === "pending") {
    return <ProcessLoader />;
  }

  // // 既にログイン済みの場合は次の画面へリダイレクト
  // if (authenticationStatus === "authenticated") {
  //   log.debug("Already logged in. Redirect to next page.");
  //   return <Navigate to={redirectPathnameSearchHash} />;
  // }

  // ログイン済みの場合はルーターにて指定されたコンポーネントを読み込み
  return <Outlet />;
};
