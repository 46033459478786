import { CMFormInputText } from "@pscsrvlab/psc-react-components";
import React, { memo, useCallback } from "react";
import { shippingDestinationNameMeta } from "../../../../../../lib/object/value/shipping-destination-name";
import { pickupLocationMeta } from "../../../../../../lib/object/value/pickup-location";
import { shippingDestinationOtherMeta } from "../../../../../../lib/object/value/shipping-destination-other";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { ShippingDestination } from "../../../../../../lib/object/value/shipping-destination";
import { freeTextMeta } from "../../../../../../lib/object/value/free-text";
import { FormSection } from "../../../../../ui/form/FormSection/FormSection";

export type SectionShippingDestinationProps = {
  /**
   * 画面編集モード
   */
  editMode: "editable" | "readOnly";

  value: ShippingDestination;

  /**
   * 変更イベント
   */
  onChange: (
    change: (before: ShippingDestination) => ShippingDestination,
  ) => void;
};
/**
 * FN30-S09細胞発送管理
 * 出荷先セクション
 */
export const SectionShippingDestination = memo(
  function SectionShippingDestination({
    editMode,
    value,

    onChange,
  }: SectionShippingDestinationProps) {
    const { t } = useAppTranslation();

    const handleChangeName = useCallback(
      (v: string) => {
        onChange?.((before) => ({
          ...before,
          shippingDestinationName: v,
        }));
      },
      [onChange],
    );
    const handleChangePickupLocation = useCallback(
      (v: string) => {
        onChange?.((before) => ({
          ...before,
          pickupLocation: v,
        }));
      },
      [onChange],
    );
    const handleChangePostalCode = useCallback(
      (v: string) => {
        onChange?.((before) => ({
          ...before,
          postalCode: v,
        }));
      },
      [onChange],
    );
    const handleChangeAddress = useCallback(
      (v: string) => {
        onChange?.((before) => ({
          ...before,
          address: v,
        }));
      },
      [onChange],
    );
    const handleChangeTitleAndPosition = useCallback(
      (v: string) => {
        onChange?.((before) => ({
          ...before,
          titleAndPosition: v,
        }));
      },
      [onChange],
    );
    const handleChangeFullName = useCallback(
      (v: string) => {
        onChange?.((before) => ({
          ...before,
          fullName: v,
        }));
      },
      [onChange],
    );
    const handleChangePhoneNumber = useCallback(
      (v: string) => {
        onChange?.((before) => ({
          ...before,
          phoneNumber: v,
        }));
      },
      [onChange],
    );
    const handleChangeMailAddress = useCallback(
      (v: string) => {
        onChange?.((before) => ({
          ...before,
          mailAddress: v,
        }));
      },
      [onChange],
    );
    const handleChangeOther = useCallback(
      (v: string) => {
        onChange?.((before) => ({
          ...before,
          other: v,
        }));
      },
      [onChange],
    );

    return (
      <FormSection title={t("lbl.出荷先")}>
        <CMFormInputText
          editMode={editMode}
          label={t("lbl.名称")}
          value={value.shippingDestinationName}
          valueObjectMeta={shippingDestinationNameMeta}
          onChange={handleChangeName}
        />

        <CMFormInputText
          editMode={editMode}
          label={t("lbl.受取場所")}
          value={value.pickupLocation}
          valueObjectMeta={pickupLocationMeta}
          onChange={handleChangePickupLocation}
        />

        <CMFormInputText
          editMode={editMode}
          label={t("lbl.郵便番号")}
          value={value.postalCode}
          valueObjectMeta={freeTextMeta}
          onChange={handleChangePostalCode}
        />

        <CMFormInputText
          editMode={editMode}
          label={t("lbl.住所")}
          value={value.address}
          valueObjectMeta={freeTextMeta}
          onChange={handleChangeAddress}
        />

        <CMFormInputText
          editMode={editMode}
          label={t("lbl.受取人所属")}
          value={value.titleAndPosition}
          valueObjectMeta={freeTextMeta}
          onChange={handleChangeTitleAndPosition}
        />

        <CMFormInputText
          editMode={editMode}
          label={t("lbl.受取人氏名")}
          value={value.fullName}
          valueObjectMeta={freeTextMeta}
          onChange={handleChangeFullName}
        />

        <CMFormInputText
          editMode={editMode}
          label={t("lbl.電話番号")}
          value={value.phoneNumber}
          valueObjectMeta={freeTextMeta}
          onChange={handleChangePhoneNumber}
        />

        <CMFormInputText
          editMode={editMode}
          label={t("lbl.メールアドレス")}
          value={value.mailAddress}
          valueObjectMeta={freeTextMeta}
          onChange={handleChangeMailAddress}
        />
        <CMFormInputText
          editMode={editMode}
          label={t("lbl.その他")}
          value={value.other}
          valueObjectMeta={shippingDestinationOtherMeta}
          onChange={handleChangeOther}
        />
      </FormSection>
    );
  },
);
