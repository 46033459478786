import {
  messageId,
  PropertyMeta,
  EntityMeta,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { sharedAttachmentFileIdMeta } from "../value/shared-attachment-file-id";
import { attachmentFileInformationMeta } from "../value/attachment-file-information";
import { creationMetadataMeta } from "../value/creation-metadata";
import { updateMetadataMeta } from "../value/update-metadata";
/**
 * 共有添付資料(エンティティ)
 *
 *  */
export type SharedAttachmentFile = z.infer<typeof schema>;
export type SharedAttachmentFileSaved = z.infer<typeof savedSchema>;
const minimalSchema = z.object({
  id: sharedAttachmentFileIdMeta.minimalSchema.optional(),
  attachmentFileInformation: attachmentFileInformationMeta.minimalSchema,
  created: creationMetadataMeta.minimalSchema,
  updated: updateMetadataMeta.minimalSchema,
});
const savedMinimalSchema = z.object({
  id: sharedAttachmentFileIdMeta.minimalSchema,
  attachmentFileInformation: attachmentFileInformationMeta.savedMinimalSchema,
  created: creationMetadataMeta.savedMinimalSchema,
  updated: updateMetadataMeta.savedMinimalSchema,
});
const schema = z.object({
  id: sharedAttachmentFileIdMeta.minimalSchema.optional(),
  attachmentFileInformation: attachmentFileInformationMeta.schema,
  created: creationMetadataMeta.schema,
  updated: updateMetadataMeta.schema,
});
const savedSchema = z.object({
  id: sharedAttachmentFileIdMeta.minimalSchema,
  attachmentFileInformation: attachmentFileInformationMeta.savedSchema,
  created: creationMetadataMeta.savedSchema,
  updated: updateMetadataMeta.savedSchema,
});
const jsonSchema = z.object({
  id: sharedAttachmentFileIdMeta.jsonSchema.optional(),
  attachmentFileInformation: attachmentFileInformationMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  created: creationMetadataMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  updated: updateMetadataMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
/**
 * 共有添付資料(エンティティ)のメタデータ。
 *
 *  */
export const sharedAttachmentFileMeta = new EntityMeta<
  typeof schema,
  typeof savedSchema,
  typeof jsonSchema
>(
  "SharedAttachmentFile",
  "\u5171\u6709\u6DFB\u4ED8\u8CC7\u6599",
  messageId("lbl.\u5171\u6709\u6DFB\u4ED8\u8CC7\u6599"),
  minimalSchema,
  savedMinimalSchema,
  schema,
  savedSchema,
  jsonSchema,
  undefined,
  undefined,
  {
    id: new PropertyMeta(
      "id",
      "ID",
      messageId("lbl.ID"),
      "afterSave",
      false,
      sharedAttachmentFileIdMeta
    ),
    attachmentFileInformation: new PropertyMeta(
      "attachmentFileInformation",
      "\u6DFB\u4ED8\u8CC7\u6599\u60C5\u5831",
      messageId("lbl.\u6DFB\u4ED8\u8CC7\u6599\u60C5\u5831"),
      "always",
      false,
      attachmentFileInformationMeta
    ),
    created: new PropertyMeta(
      "created",
      "\u4F5C\u6210",
      messageId("lbl.\u4F5C\u6210"),
      "always",
      false,
      creationMetadataMeta
    ),
    updated: new PropertyMeta(
      "updated",
      "\u66F4\u65B0",
      messageId("lbl.\u66F4\u65B0"),
      "always",
      false,
      updateMetadataMeta
    ),
  }
);
