import React from "react";
import { ComponentStyleProps } from "../../../../lib/styles/props/component-style-props";
import { VerticalTableRow } from "../VerticalTableRow/VerticalTableRow";
import { InstitutionSearchSelect } from "../../../model/institution/InstitutionSearchSelect/InstitutionSearchSelect";

export type VerticalTableRowInstitutionProps = {
  /**
   * ヘッダーとして使用
   */
  label?: string;
  editMode: "editable" | "readOnly";

  /**
   * 値。
   */
  value?: number;

  /**
   * メニュー選択項目が確定された時のコールバック。
   * @param value メニュー選択済み項目の情報
   * nullの場合、選択項目のクリアを表す。
   */
  onSelect?: (institutionId?: number) => void;
} & ComponentStyleProps;

/**
 * テーブルデータが機関名選択の縦型テーブルRow
 */
export const VerticalTableRowInstitution = ({
  label,
  editMode,
  value,
  onSelect,
  sx,
  ...rest
}: VerticalTableRowInstitutionProps) => {
  return (
    <VerticalTableRow label={label} sx={sx} {...rest}>
      <InstitutionSearchSelect
        institutionId={value}
        onSelect={onSelect}
        editMode={editMode}
      />
    </VerticalTableRow>
  );
};
