import { ProtectedRoute as PR } from "../components/route/ProtectedRoute";
import { FN40S13DocumentContent } from "../components/page/FN40/FN40S13DocumentContent/FN40S13DocumentContent";
import { FN40S15DocumentContentReceived } from "../components/page/FN40/FN40S15DocumentContentReceived/FN40S15DocumentContentReceived";
import { FN40S24ReviseCreate } from "../components/page/FN40/FN40S24ReviseCreate/FN40S24ReviseCreate";
import { FN40S28ReviseConfirm } from "../components/page/FN40/FN40S28ReviseConfirm/FN40S28ReviseConfirm";
import { FN40S29ReviseComplete } from "../components/page/FN40/FN40S29ReviseComplete/FN40S29ReviseComplete";
import { FN40S16OfficeMemberCheck } from "../components/page/FN40/FN40S16OfficeMemberCheck/FN40S16OfficeMemberCheck";
import { FN40S18OfficeMemberCheckComplete } from "../components/page/FN40/FN40S18OfficeMemberCheckComplete/FN40S18OfficeMemberCheckComplete";
import { FN40S30ReviewDetails } from "../components/page/FN40/FN40S30ReviewDetails/FN40S30ReviewDetails";
import { FN40S31ReviewConcluded } from "../components/page/FN40/FN40S31ReviewConcluded/FN40S31ReviewConcluded";
import { FN40S32ReviewApproved } from "../components/page/FN40/FN40S32ReviewApproved/FN40S32ReviewApproved";
import { FN40S33ResultNotification } from "../components/page/FN40/FN40S33ResultNotification/FN40S33ResultNotification";
import { FN40S19RevisionRequest } from "../components/page/FN40/FN40S19RevisionRequest/FN40S19RevisionRequest";
import { FN40S23RevisionRequestComplete } from "../components/page/FN40/FN40S23RevisionRequestComplete/FN40S23RevisionRequestComplete";
import * as React from "react";
import { FN40S36DocumentMailLog } from "../components/page/FN40/FN40S36DocumentMailLog/FN40S36DocumentMailLog";
import { FN40S37DocumentWorkLog } from "../components/page/FN40/FN40S37DocumentWorkLog/FN40S37DocumentWorkLog";
import { FN40S39OfficeMemberNotes } from "../components/page/FN40/FN40S39OfficeMemberNotes/FN40S39OfficeMemberNotes";
import { DocumentLayout } from "../components/layout/DocumentLayout/DocumentLayout";
import { Navigate, RouteObject } from "react-router-dom";
import { _inquiryRoute } from "./inquiry-route";
import { FN40S35ResultNotificationComplete } from "../components/page/FN40/FN40S35ResultNotificationComplete/FN40S35ResultNotificationComplete";

export const _documentDetailsRoute: RouteObject = {
  path: "document",
  element: (
    <PR
      roles={[
        "applicant",
        "office_member",
        "committee_member",
        "executive_director",
      ]}
    />
  ),
  children: [
    {
      path: ":documentId",
      element: <DocumentLayout />,
      children: [
        {
          index: true,
          element: <Navigate to={"./content"} replace />,
        },
        {
          path: "content",
          children: [
            { index: true, element: <FN40S13DocumentContent mode={"view"} /> },
            {
              path: "edit",
              element: <PR roles={["office_member"]} />,
              children: [
                {
                  index: true,
                  element: <FN40S13DocumentContent mode={"edit"} />,
                },
              ],
            },
            {
              path: "received",
              element: <PR roles={["office_member"]} />,
              children: [
                { index: true, element: <FN40S15DocumentContentReceived /> },
              ],
            },
            {
              path: "revise/:revisionRequestId",
              element: <PR roles={["applicant"]} />,
              children: [
                {
                  path: "create",
                  element: <FN40S24ReviseCreate />,
                },
                {
                  path: "confirm",
                  element: <FN40S28ReviseConfirm />,
                },
                {
                  path: "complete",
                  element: <FN40S29ReviseComplete />,
                },
              ],
            },
          ],
        },
        {
          path: "office-member-check",
          element: <PR roles={["office_member", "executive_director"]} />,
          children: [
            {
              index: true,
              element: <FN40S16OfficeMemberCheck />,
            },
            {
              path: "complete",
              element: <FN40S18OfficeMemberCheckComplete />,
            },
          ],
        },
        {
          path: "review",
          element: (
            <PR
              roles={[
                "office_member",
                "committee_member",
                "executive_director",
              ]}
            />
          ),
          children: [
            {
              index: true,
              element: <FN40S30ReviewDetails mode={"empty"} />,
            },
            {
              path: ":reviewId",
              element: <FN40S30ReviewDetails mode={"view-or-edit"} />,
            },
            {
              path: ":reviewId",
              children: [
                {
                  path: "concluded",
                  element: <FN40S31ReviewConcluded />,
                },
                {
                  path: "approved",
                  element: <FN40S32ReviewApproved />,
                },
              ],
            },
          ],
        },
        {
          path: "result-notification",
          element: (
            <PR
              roles={[
                "applicant",
                "office_member",
                "committee_member",
                "executive_director",
              ]}
            />
          ),
          children: [
            {
              index: true,
              element: <FN40S33ResultNotification mode={"empty"} />,
            },
            {
              path: "create",
              element: <PR roles={["office_member"]} />,
              children: [
                {
                  index: true,
                  element: <FN40S33ResultNotification mode={"create"} />,
                },
              ],
            },
            {
              path: ":resultNotificationId",
              element: <FN40S33ResultNotification mode={"view"} />,
            },
            {
              path: ":resultNotificationId/complete",
              element: <FN40S35ResultNotificationComplete />,
            },
          ],
        },
        {
          path: "revision-request",
          element: (
            <PR roles={["applicant", "office_member", "executive_director"]} />
          ),
          children: [
            {
              index: true,
              element: <FN40S19RevisionRequest mode={"empty"} />,
            },
            {
              path: "create",
              element: <FN40S19RevisionRequest mode={"create"} />,
            },
            {
              path: ":revisionRequestId",
              element: <FN40S19RevisionRequest mode={"view"} />,
            },
            {
              path: ":revisionRequestId/complete",
              element: <FN40S23RevisionRequestComplete />,
            },
          ],
        },
        {
          path: "mail-log",
          element: <FN40S36DocumentMailLog />,
        },
        {
          path: "work-log",
          element: <FN40S37DocumentWorkLog />,
        },
        {
          path: "office-member-notes",
          element: <PR roles={["office_member"]} />,
          children: [
            {
              index: true,
              element: <FN40S39OfficeMemberNotes />,
            },
          ],
        },
        _inquiryRoute,
      ],
    },
  ],
};
