import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";
import { memo, useMemo } from "react";
import { MailLogSaved } from "../../../../../../lib/object/entity/mail-log";
import { CMCardEmailLog } from "@pscsrvlab/psc-react-components";
import {
  HeaderOperationContent,
  HeaderSubjectContent,
} from "@pscsrvlab/psc-react-components/src/components/others/types";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { roleMeta } from "../../../../../../lib/object/value/role";
import { operationTypeMeta } from "../../../../../../lib/object/value/operation-type";
import { hasValue, isNullish } from "../../../../../../lib/util/common-util";
import { useAppGetInstitutionQuery } from "../../../../../../hooks/query/use-app-get-institution-query";
import { useAppGetProjectQuery } from "../../../../../../hooks/query/use-app-get-project-query";
import { useAppGetDocumentQuery } from "../../../../../../hooks/query/use-app-get-document-query";
import { documentTypeMeta } from "../../../../../../lib/object/value/document-type";

export type MailLogCardProps = {
  mailLog: MailLogSaved;
} & ComponentStyleProps;

export const MailLogCard = memo(function MailLogCard({
  mailLog,

  sx,
  ...rest
}: MailLogCardProps) {
  const { t } = useAppTranslation();

  const headerOperationItem: HeaderOperationContent[] = useMemo(() => {
    const recipientRole = hasValue(mailLog.recipientRole)
      ? t(roleMeta.dict[mailLog.recipientRole])
      : undefined;
    const operationName = hasValue(mailLog.operation)
      ? t(operationTypeMeta.dict[mailLog.operation])
      : undefined;
    return [
      { label: t("lbl.送信先"), value: recipientRole },
      { label: t("lbl.メールアドレス"), value: mailLog.mailAddress },
      { label: t("lbl.オペレーション名"), value: operationName },
    ];
  }, [t, mailLog.mailAddress, mailLog.operation, mailLog.recipientRole]);

  const { data: institution } = useAppGetInstitutionQuery(
    {
      institutionId: mailLog.institutionId ?? -1,
    },
    { skip: isNullish(mailLog.institutionId) },
  );
  const { data: project } = useAppGetProjectQuery(
    {
      projectId: mailLog.projectId ?? -1,
    },
    { skip: isNullish(mailLog.projectId) },
  );
  const { data: document } = useAppGetDocumentQuery(
    {
      documentId: mailLog.documentId ?? -1,
    },
    { skip: isNullish(mailLog.documentId) },
  );

  const projectUrl = useMemo(() => {
    if (isNullish(mailLog.projectId)) return undefined;
    return `/project/${mailLog.projectId}`;
  }, [mailLog.projectId]);
  const documentUrl = useMemo(() => {
    if (isNullish(mailLog.documentId)) return undefined;
    return `/document/${mailLog.documentId}`;
  }, [mailLog.documentId]);

  const headerSubjectItem: HeaderSubjectContent[] = useMemo(() => {
    const projectDisplayValue = hasValue(project)
      ? `${project.projectControlNumber} ${project.applicationContent.projectName}`
      : undefined;
    const documentDisplayValue = hasValue(document)
      ? `${document.documentControlNumber} ${t(
          documentTypeMeta.dict[document.documentType],
        )}`
      : undefined;

    return [
      {
        label: t("lbl.機関"),
        value: institution?.name,
      },
      {
        label: t("lbl.案件"),
        value: projectDisplayValue,
        url: projectUrl,
      },
      {
        label: t("lbl.書類"),
        value: documentDisplayValue,
        url: documentUrl,
      },
    ];
  }, [document, documentUrl, institution?.name, project, projectUrl, t]);

  return (
    <CMCardEmailLog
      headerOperationItem={headerOperationItem}
      date={mailLog.sentAt}
      headerSubjectItem={headerSubjectItem}
      title={mailLog?.subject}
      mainText={mailLog?.content}
      labelButtonLabelOpen={t("btn.本文を開くボタン")}
      labelButtonLabelClose={t("btn.本文を閉じるボタン")}
      sx={sx}
      {...rest}
    />
  );
});
