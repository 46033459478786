import { Box, Radio, RadioGroup, Text, VStack } from "@chakra-ui/react";
import {
  CMFormInputRadio,
  CMFormInputText,
} from "@pscsrvlab/psc-react-components";
import React, { memo, useCallback, useMemo } from "react";
import { domesticDelivererTypeMeta } from "../../../../../../lib/object/value/domestic-deliverer-type";
import { domesticDelivererShipperRegistrationTypeMeta } from "../../../../../../lib/object/value/domestic-deliverer-shipper-registration-type";
import { foreignDelivererTypeMeta } from "../../../../../../lib/object/value/foreign-deliverer-type";
import { domesticDelivererOtherMeta } from "../../../../../../lib/object/value/domestic-deliverer-other";
import { foreignDelivererAccountInformationMeta } from "../../../../../../lib/object/value/foreign-deliverer-account-information";
import { foreignDelivererOtherMeta } from "../../../../../../lib/object/value/foreign-deliverer-other";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { FormSection } from "../../../../../ui/form/FormSection/FormSection";
import { hasValue } from "../../../../../../lib/util/common-util";
import { CellStockDeliveryViewModel } from "../../../../../../lib/object/vm/cell-stock-delivery-view-model";
import { LabeledTextVertical } from "../../../../../ui/text/LabeledTextVertical/LabeledTextVertical";

export type SectionDelivererProps = {
  /**
   * 画面編集モード
   */
  editMode: "editable" | "readOnly";

  value: CellStockDeliveryViewModel["deliverer"];

  /**
   * 変更イベント
   */
  onChange: (
    change: (
      before: CellStockDeliveryViewModel["deliverer"],
    ) => CellStockDeliveryViewModel["deliverer"],
  ) => void;
};

/**
 * FN30-S09細胞発送管理
 * 輸送業者セクション
 */
export const SectionDeliverer = memo(function SectionDeliverer({
  editMode,
  value,
  onChange,
}: SectionDelivererProps) {
  const { t } = useAppTranslation();

  /**
   * 輸送手配方法区分の更新
   */
  const handleChangeDeliveryArrangementMethod = useCallback(
    (
      selectedValue:
        | "arranged_by_applicant"
        | "arranged_by_foundation"
        | "decide_after_consultation",
    ) => {
      onChange?.((before) => ({
        ...before,
        deliveryArrangementMethodType: selectedValue,
      }));
    },
    [onChange],
  );

  /**
   * 国内輸送手配業者区分の更新
   */
  const handleChangeDomesticDeliverer = useCallback(
    (
      selectedValue:
        | "not_selected"
        | "seino_super_express"
        | "seino_transportation"
        | "other",
    ) => {
      onChange?.((before) => ({
        ...before,
        domesticDelivererType: selectedValue,
      }));
    },
    [onChange],
  );

  /**
   * 国内輸送手配業者荷主登録区分の更新
   */
  const handleChangeDomesticDelivererShipperRegistration = useCallback(
    (selectedValue: "registered" | "not_registered" | "other") => {
      onChange?.((before) => ({
        ...before,
        domesticDelivererShipperRegistrationType: selectedValue,
      }));
    },
    [onChange],
  );

  /**
   * 国外輸送手配業者区分の更新
   */
  const handleChangeForeignDeliverer = useCallback(
    (
      selectedValue:
        | "not_selected"
        | "dhl"
        | "world_courier"
        | "fedex"
        | "marken"
        | "other",
    ) => {
      onChange?.((before) => ({
        ...before,
        foreignDelivererType: selectedValue,
      }));
    },
    [onChange],
  );

  const handleChangeDomesticDelivererOther = useCallback(
    (v: string) => {
      onChange?.((before) => ({ ...before, domesticDelivererOther: v }));
    },
    [onChange],
  );
  const handleChangeForeignDelivererOther = useCallback(
    (v: string) => {
      onChange?.((before) => ({ ...before, foreignDelivererOther: v }));
    },
    [onChange],
  );
  const handleChangeForeignDelivererAccountInformation = useCallback(
    (v: string) => {
      onChange?.((before) => ({
        ...before,
        foreignDelivererAccountInformation: v,
      }));
    },
    [onChange],
  );

  const deliveryArrangementMethodTypeText = useMemo(() => {
    switch (value.deliveryArrangementMethodType) {
      case "arranged_by_applicant":
        return t("lbl.輸送手配方法1");
      case "arranged_by_foundation":
        return t("lbl.輸送手配方法2");
      case "decide_after_consultation":
        return t("lbl.輸送手配方法3");
    }
  }, [t, value.deliveryArrangementMethodType]);

  const {
    delivererText,
    shipperRegistrationText,
    foreignDelivererAccountInformationText,
  } = useMemo(() => {
    const domesticDelivererType = value.domesticDelivererType;
    if (domesticDelivererType !== "not_selected") {
      const delivererText =
        domesticDelivererType === "other"
          ? value.domesticDelivererOther
          : t(domesticDelivererTypeMeta.dict[domesticDelivererType]);

      const shipperRegistrationText =
        value.domesticDelivererShipperRegistrationType === "other"
          ? undefined
          : `(${t("lbl.荷主登録")}${t(
              domesticDelivererShipperRegistrationTypeMeta.dict[
                value.domesticDelivererShipperRegistrationType
              ],
            )})`;
      return {
        delivererText,
        shipperRegistrationText,
        foreignDelivererAccountInformationText: undefined,
      };
    }

    const foreignDelivererType = value.foreignDelivererType;
    if (foreignDelivererType !== "not_selected") {
      return {
        delivererText:
          foreignDelivererType === "other"
            ? value.foreignDelivererOther
            : t(foreignDelivererTypeMeta.dict[foreignDelivererType]),
        shipperRegistrationText: undefined,
        foreignDelivererAccountInformationText: hasValue(
          value.foreignDelivererAccountInformation,
        )
          ? value.foreignDelivererAccountInformation
          : undefined,
      };
    }

    return {
      delivererText: undefined,
      shipperRegistrationText: undefined,
      foreignDelivererAccountInformationText: undefined,
    };
  }, [
    t,
    value.domesticDelivererOther,
    value.domesticDelivererShipperRegistrationType,
    value.domesticDelivererType,
    value.foreignDelivererAccountInformation,
    value.foreignDelivererOther,
    value.foreignDelivererType,
  ]);

  return (
    <FormSection title={t("lbl.輸送業者について")}>
      {editMode === "editable" && (
        <Text fontSize={"12px"} color={"red"}>
          {t("gdc.輸送業者補足")}
        </Text>
      )}

      <Box>
        <Text fontWeight={"bold"} fontSize={"15px"}>
          {t("lbl.輸送手配方法")}
        </Text>
        {editMode === "readOnly" && (
          <Text whiteSpace={"pre-wrap"} color={"gray.900"}>
            {deliveryArrangementMethodTypeText}
          </Text>
        )}
        {editMode === "editable" && (
          <RadioGroup
            onChange={handleChangeDeliveryArrangementMethod}
            value={value.deliveryArrangementMethodType}
          >
            <VStack
              alignItems={"flex-start"}
              whiteSpace={"pre-wrap"}
              color={"gray.900"}
            >
              <Radio value="arranged_by_applicant">
                <Text>{t("lbl.輸送手配方法1")}</Text>
              </Radio>
              <Radio value="arranged_by_foundation">
                <Text>{t("lbl.輸送手配方法2")}</Text>
              </Radio>
              <Radio value="decide_after_consultation">
                <Text>{t("lbl.輸送手配方法3")}</Text>
              </Radio>
            </VStack>
          </RadioGroup>
        )}
      </Box>

      <Box>
        <Text fontWeight={"bold"} fontSize={"15px"}>
          {t("lbl.輸送業者名")}
        </Text>
        {editMode === "readOnly" && (
          <VStack alignItems={"flex-start"}>
            {hasValue(delivererText) && (
              <Text color={"gray.900"} wordBreak={"break-all"}>
                {delivererText}
              </Text>
            )}
            {hasValue(shipperRegistrationText) && (
              <Text color={"gray.900"}>{shipperRegistrationText}</Text>
            )}
            {hasValue(foreignDelivererAccountInformationText) && (
              <LabeledTextVertical
                label={t("lbl.お持ちの輸送業者アカウント情報")}
                text={foreignDelivererAccountInformationText}
              />
            )}
          </VStack>
        )}
        {editMode === "editable" && (
          <>
            <Text fontSize={"12px"} color={"red"}>
              {t("gdc.輸送業者名補足")}
            </Text>
            {/*国内輸送の場合*/}
            <Box mt={"16px"} pl={"16px"}>
              <Text fontSize={"14px"} color={"gray"}>
                {t("lbl.国内輸送の場合")}
              </Text>
              <Box
                mt={"6px"}
                ml={"12px"}
                pt={"1px"}
                pl={"8px"}
                border={"1px"}
                borderTopStyle={"none"}
                borderBottomStyle={"none"}
                borderColor={"transparent"}
                borderLeftColor={"gray.400"}
              >
                {editMode === "editable" && (
                  <Text mt={"8px"} fontSize={"12px"} color={"red"}>
                    {t("gdc.国内輸送補足")}
                  </Text>
                )}
                {/*国内輸送手配業者区分*/}
                <CMFormInputRadio
                  editMode={editMode}
                  noHeader={true}
                  value={value.domesticDelivererType}
                  valueObjectMeta={domesticDelivererTypeMeta}
                  onChange={handleChangeDomesticDeliverer}
                  sx={{
                    mt: "8px",
                    pl: "16px",
                    // 2個目以降のmarginを調整
                    ".chakra-stack div:nth-of-type(n+2)": { marginTop: "5px" },
                  }}
                />

                <VStack ml={"50px"} pr={"50px"}>
                  {/*その他*/}
                  <CMFormInputText
                    editMode={editMode}
                    noHeader={true}
                    value={value.domesticDelivererOther}
                    valueObjectMeta={domesticDelivererOtherMeta}
                    onChange={handleChangeDomesticDelivererOther}
                    mt={"8px"}
                  />
                </VStack>
                <Text mt={"16px"} fontSize={"14px"}>
                  {t("gdc.荷主登録確認")}
                </Text>
                {editMode === "editable" && (
                  <Text mt={"8px"} fontSize={"12px"} color={"red"}>
                    {t("gdc.荷主登録補足")}
                  </Text>
                )}

                {/*荷主登録*/}
                <CMFormInputRadio
                  editMode={editMode}
                  noHeader={true}
                  value={value.domesticDelivererShipperRegistrationType}
                  valueObjectMeta={domesticDelivererShipperRegistrationTypeMeta}
                  onChange={handleChangeDomesticDelivererShipperRegistration}
                  sx={{
                    mt: "8px",
                    pl: "16px",
                    // 2個目以降のmarginを調整
                    ".chakra-stack div:nth-of-type(n+2)": { marginTop: "5px" },
                  }}
                />
              </Box>
            </Box>
            {/*国外輸送の場合*/}
            <Box mt={"16px"} pl={"16px"}>
              <Text fontSize={"14px"} color={"gray"}>
                {t("lbl.国外輸送の場合")}
              </Text>
              <Box
                mt={"6px"}
                ml={"12px"}
                pt={"1px"}
                pl={"8px"}
                border={"1px"}
                borderTopStyle={"none"}
                borderBottomStyle={"none"}
                borderColor={"transparent"}
                borderLeftColor={"gray.400"}
              >
                {editMode === "editable" && (
                  <Text mt={"8px"} fontSize={"12px"} color={"red"}>
                    {t("gdc.国内輸送補足")}
                  </Text>
                )}
                <CMFormInputRadio
                  editMode={editMode}
                  noHeader={true}
                  value={value.foreignDelivererType}
                  valueObjectMeta={foreignDelivererTypeMeta}
                  onChange={handleChangeForeignDeliverer}
                  sx={{
                    mt: "8px",
                    pl: "16px",
                    // 2個目以降のmarginを調整
                    ".chakra-stack div:nth-of-type(n+2)": { marginTop: "5px" },
                  }}
                />
                <VStack alignItems={"stretch"} ml={"50px"} pr={"50px"}>
                  {/*その他*/}
                  <CMFormInputText
                    editMode={editMode}
                    noHeader={true}
                    value={value.foreignDelivererOther}
                    valueObjectMeta={foreignDelivererOtherMeta}
                    onChange={handleChangeForeignDelivererOther}
                    mt={"8px"}
                  />
                  {/*アカウント情報*/}
                  <CMFormInputText
                    editMode={editMode}
                    label={t("lbl.お持ちの輸送業者アカウント情報")}
                    value={value.foreignDelivererAccountInformation}
                    valueObjectMeta={foreignDelivererAccountInformationMeta}
                    onChange={handleChangeForeignDelivererAccountInformation}
                    mt={"8px"}
                  />

                  {editMode === "editable" && (
                    <Text mt={"8px"} fontSize={"12px"} color={"red"}>
                      {t("gdc.輸送業者アカウント情報補足")}
                    </Text>
                  )}
                </VStack>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </FormSection>
  );
});
