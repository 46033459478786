import { ProtectedRoute as PR } from "../components/route/ProtectedRoute";
import { FN81S01UserSearch } from "../components/page/FN81/FN81S01UserSearch/FN81S01UserSearch";
import { FN81S02UserDetails } from "../components/page/FN81/FN81S02UserDetails/FN81S02UserDetails";
import * as React from "react";
import { RouteObject } from "react-router-dom";

export const _userRoute: RouteObject = {
  path: "user",
  element: (
    <PR
      roles={[
        "applicant",
        "office_member",
        "committee_member",
        "executive_director",
      ]}
    />
  ),
  children: [
    { index: true, element: <FN81S01UserSearch /> },
    {
      path: "create",
      element: <PR roles={["office_member"]} />,
      children: [
        {
          index: true,
          element: <FN81S02UserDetails createMode={true} />,
        },
      ],
    },
    { path: ":appUserId", element: <FN81S02UserDetails createMode={false} /> },
  ],
};
