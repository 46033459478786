import { useAppListDocumentQuery } from "../query/use-app-list-document-query";
import { isNullish } from "../../lib/util/common-util";
import { useMemo } from "react";

/**
 * 案件が変更可能であることを確認する。
 * 案件が変更可能とは、変更申請の作成及び案件強制編集ができる状態を意味する。
 *
 * すでに下書き以降で未完了の変更申請が存在する場合は、変更不可能とする。
 *
 * 戻り値: ロード中はundefined, 変更可能ならtrue, 変更不可能ならfalse。
 */
export function useIsProjectMutable(projectId: number | null | undefined) {
  const { data: existingChangeApplications } = useAppListDocumentQuery(
    {
      projectId: projectId ?? -1,
      documentType: ["change_application"],
      documentState: [
        "draft",
        "submitted",
        "office_check",
        "reviewing",
        "awaiting_conclusion_rereview",
        "executive_director_reviewing",
        "awaiting_conclusion",
        "awaiting_conclusion_no_review",
        "awaiting_conditions_met",
      ],
    },
    { skip: isNullish(projectId) },
  );
  const isProjectMutable = useMemo(() => {
    if (isNullish(existingChangeApplications)) return undefined;
    return existingChangeApplications.length <= 0;
  }, [existingChangeApplications]);

  return { isProjectMutable };
}
