import {
  messageId,
  PropertyMeta,
  EntityMeta,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { workLogIdMeta } from "../value/work-log-id";
import { institutionIdMeta } from "../value/institution-id";
import { projectIdMeta } from "../value/project-id";
import { documentIdMeta } from "../value/document-id";
import { workTypeMeta } from "../value/work-type";
import { operationTypeMeta } from "../value/operation-type";
import { jsonMeta } from "../value/json";
import { creationMetadataMeta } from "../value/creation-metadata";
import { updateMetadataMeta } from "../value/update-metadata";
/**
 * 作業ログ(エンティティ)
 *
 *  */
export type WorkLog = z.infer<typeof schema>;
export type WorkLogSaved = z.infer<typeof savedSchema>;
const minimalSchema = z.object({
  id: workLogIdMeta.minimalSchema.optional(),
  institutionId: institutionIdMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  projectId: projectIdMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  documentId: documentIdMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  workType: workTypeMeta.minimalSchema,
  operation: operationTypeMeta.minimalSchema,
  beforeJson: jsonMeta.minimalSchema.nullish().transform((x) => x ?? undefined),
  afterJson: jsonMeta.minimalSchema.nullish().transform((x) => x ?? undefined),
  created: creationMetadataMeta.minimalSchema,
  updated: updateMetadataMeta.minimalSchema,
});
const savedMinimalSchema = z.object({
  id: workLogIdMeta.minimalSchema,
  institutionId: institutionIdMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  projectId: projectIdMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  documentId: documentIdMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  workType: workTypeMeta.savedMinimalSchema,
  operation: operationTypeMeta.savedMinimalSchema,
  beforeJson: jsonMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  afterJson: jsonMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  created: creationMetadataMeta.savedMinimalSchema,
  updated: updateMetadataMeta.savedMinimalSchema,
});
const schema = z.object({
  id: workLogIdMeta.minimalSchema.optional(),
  institutionId: institutionIdMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
  projectId: projectIdMeta.schema.nullish().transform((x) => x ?? undefined),
  documentId: documentIdMeta.schema.nullish().transform((x) => x ?? undefined),
  workType: workTypeMeta.schema,
  operation: operationTypeMeta.schema,
  beforeJson: jsonMeta.schema.nullish().transform((x) => x ?? undefined),
  afterJson: jsonMeta.schema.nullish().transform((x) => x ?? undefined),
  created: creationMetadataMeta.schema,
  updated: updateMetadataMeta.schema,
});
const savedSchema = z.object({
  id: workLogIdMeta.minimalSchema,
  institutionId: institutionIdMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  projectId: projectIdMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  documentId: documentIdMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  workType: workTypeMeta.savedSchema,
  operation: operationTypeMeta.savedSchema,
  beforeJson: jsonMeta.savedSchema.nullish().transform((x) => x ?? undefined),
  afterJson: jsonMeta.savedSchema.nullish().transform((x) => x ?? undefined),
  created: creationMetadataMeta.savedSchema,
  updated: updateMetadataMeta.savedSchema,
});
const jsonSchema = z.object({
  id: workLogIdMeta.jsonSchema.optional(),
  institutionId: institutionIdMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  projectId: projectIdMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  documentId: documentIdMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  workType: workTypeMeta.jsonSchema.nullish().transform((x) => x ?? undefined),
  operation: operationTypeMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  beforeJson: jsonMeta.jsonSchema.nullish().transform((x) => x ?? undefined),
  afterJson: jsonMeta.jsonSchema.nullish().transform((x) => x ?? undefined),
  created: creationMetadataMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  updated: updateMetadataMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
/**
 * 作業ログ(エンティティ)のメタデータ。
 *
 *  */
export const workLogMeta = new EntityMeta<
  typeof schema,
  typeof savedSchema,
  typeof jsonSchema
>(
  "WorkLog",
  "\u4F5C\u696D\u30ED\u30B0",
  messageId("lbl.\u4F5C\u696D\u30ED\u30B0"),
  minimalSchema,
  savedMinimalSchema,
  schema,
  savedSchema,
  jsonSchema,
  undefined,
  undefined,
  {
    id: new PropertyMeta(
      "id",
      "ID",
      messageId("lbl.ID"),
      "afterSave",
      false,
      workLogIdMeta
    ),
    institutionId: new PropertyMeta(
      "institutionId",
      "\u6A5F\u95A2ID",
      messageId("lbl.\u6A5F\u95A2ID"),
      "none",
      false,
      institutionIdMeta
    ),
    projectId: new PropertyMeta(
      "projectId",
      "\u6848\u4EF6ID",
      messageId("lbl.\u6848\u4EF6ID"),
      "none",
      false,
      projectIdMeta
    ),
    documentId: new PropertyMeta(
      "documentId",
      "\u66F8\u985EID",
      messageId("lbl.\u66F8\u985EID"),
      "none",
      false,
      documentIdMeta
    ),
    workType: new PropertyMeta(
      "workType",
      "\u4F5C\u696D\u533A\u5206",
      messageId("lbl.\u4F5C\u696D\u533A\u5206"),
      "always",
      false,
      workTypeMeta
    ),
    operation: new PropertyMeta(
      "operation",
      "\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3",
      messageId("lbl.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3"),
      "always",
      false,
      operationTypeMeta
    ),
    beforeJson: new PropertyMeta(
      "beforeJson",
      "\u4F5C\u696D\u524DJSON",
      messageId("lbl.\u4F5C\u696D\u524DJSON"),
      "none",
      false,
      jsonMeta
    ),
    afterJson: new PropertyMeta(
      "afterJson",
      "\u4F5C\u696D\u5F8CJSON",
      messageId("lbl.\u4F5C\u696D\u5F8CJSON"),
      "none",
      false,
      jsonMeta
    ),
    created: new PropertyMeta(
      "created",
      "\u4F5C\u6210",
      messageId("lbl.\u4F5C\u6210"),
      "always",
      false,
      creationMetadataMeta
    ),
    updated: new PropertyMeta(
      "updated",
      "\u66F4\u65B0",
      messageId("lbl.\u66F4\u65B0"),
      "always",
      false,
      updateMetadataMeta
    ),
  }
);
