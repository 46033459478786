import { hasValue, isNullish } from "../../../../lib/util/common-util";
import { TerminationReportCreatePage } from "../../../model/document/report/termination-report/TerminationReportCreatePage/TerminationReportCreatePage";
import { useAppParams } from "../../../../hooks/use-app-params";
import { useTerminationReport } from "../../../../hooks/document/termination-report/use-termination-report";

export const FN40S05TerminationReportCreate = () => {
  const { projectId, documentId } = useAppParams();

  const {
    project,

    initialTerminationReportVM,

    validationErrors,

    saveTerminationReportContent,
    deleteTerminationReportDraft,
  } = useTerminationReport(projectId ?? -1, documentId, {
    skip: isNullish(projectId),
  });

  return (
    <>
      {hasValue(initialTerminationReportVM) &&
        hasValue(projectId) &&
        hasValue(project) && (
          <TerminationReportCreatePage
            projectId={projectId}
            savedDocumentId={documentId}
            initialViewModel={initialTerminationReportVM}
            validationErrors={validationErrors}
            saveTerminationReportContent={saveTerminationReportContent}
            deleteTerminationReportDraft={deleteTerminationReportDraft}
            h={"100%"}
            w={"100%"}
          />
        )}
    </>
  );
};
