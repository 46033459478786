import { ComponentStyleProps } from "../../../../../../../../lib/styles/props/component-style-props";
import { memo, useCallback } from "react";
import { FormSection } from "../../../../../../../ui/form/FormSection/FormSection";
import { InstitutionContent } from "../content/InstitutionContent";
import { InstitutionInformation } from "../../../../../../../../lib/object/value/institution-information";
import { useAppTranslation } from "../../../../../../../../hooks/use-app-translation";

export type InstitutionNameCreateProjectSectionProps = {
  editMode: "editable" | "readOnly";

  value: Partial<InstitutionInformation>;
  onChange?: (value: Partial<InstitutionInformation>) => void;
} & ComponentStyleProps;

/**
 * 機関名セクション(案件強制作成)
 */
export const InstitutionNameCreateProjectSection = memo(
  function InstitutionNameCreateProjectSection({
    editMode,

    value,
    onChange,

    sx,

    ...rest
  }: InstitutionNameCreateProjectSectionProps) {
    const { t } = useAppTranslation();

    const handleSelect = useCallback(
      (v: InstitutionInformation | null) => {
        onChange?.(v ?? {});
      },
      [onChange],
    );

    return (
      <FormSection title={t("lbl.機関名")} sx={sx} {...rest}>
        <InstitutionContent
          editMode={editMode}
          onSelect={handleSelect}
          value={value}
        />
      </FormSection>
    );
  },
);
