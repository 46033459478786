import { ApplicationContent } from "../value/application-content";
import { ErrorMessageObject, messageId } from "@pscsrvlab/psc-react-components";
import { isNullish } from "../../util/common-util";

export function applicationContentCustomValidation(
  value: ApplicationContent,
  skipOptional: boolean,
): ErrorMessageObject[] {
  if (skipOptional) return [];

  const errorMessageList: ErrorMessageObject[] = [];

  /**
   * 研究責任者が選択済であること。
   */
  if (isNullish(value.principalInvestigator)) {
    errorMessageList.push({
      errorMessageId: messageId("mes.必須チェックエラー2"),
      errorMessageInterpolations: { name: "$t(lbl.研究責任者)" },
    });
  }

  /**
   * 分担機関の有無で無が選択されているにもかかわらず、分担機関カードが1つ以上ある場合のバリデーション
   */
  if (
    value.hasCollaborativePartner === "no_partner" &&
    value.partners.length > 0
  ) {
    errorMessageList.push({
      errorMessageId: messageId("mes.分担機関不要エラー"),
    });
  }

  /**
   * 分担機関の有無で有が選択されているにもかかわらず、分担機関カードが無い場合のバリデーション
   */
  if (
    value.hasCollaborativePartner === "has_partner" &&
    value.partners.length === 0
  ) {
    errorMessageList.push({
      errorMessageId: messageId("mes.分担機関必須エラー"),
    });
  }

  /**
   * 使用する細胞(研究用)には研究用株のみが、使用する細胞(臨床用)には臨床用株のみが設定されていることのバリデーション。
   * このバリデーションエラーは、通常の操作では起こりえないため、エラーメッセージは一般エラーメッセージとする。
   */
  const ok =
    value.requestingResearchCells.every(
      (cell) => cell.cellType === "research_purpose",
    ) &&
    value.requestingClinicalCells.every(
      (cell) => cell.cellType === "clinical_purpose",
    );
  if (!ok) {
    errorMessageList.push({
      errorMessageId: messageId("mes.一般エラーメッセージ"),
    });
  }

  /**
   * 「iPS細胞の使用目的」セクションで「ヒトへの投与あり(clinical)」が選択されているにも関わらず、
   * 「使用する細胞」セクションで研究用株が1つ以上選択されているということがないことのバリデーション。
   */
  if (
    value.requestingClinicalCells.length === 0 &&
    value.requestingResearchCells.length === 0
  ) {
    errorMessageList.push({
      errorMessageId: messageId("mes.使用する細胞必須エラーメッセージ"),
    });
  }

  /**
   * 「iPS細胞の使用目的」セクションで「ヒトへの投与あり(clinical)」が選択されているにも関わらず、
   * 「使用する細胞」セクションで研究用株が1つ以上選択されているということがないことのバリデーション。
   */
  if (
    value.ipsCellStockUsePurpose.purposeType === "clinical" &&
    value.requestingResearchCells.length > 0
  ) {
    errorMessageList.push({
      errorMessageId: messageId(
        "mes.研究株案件ヒトへの投与予定ありエラーメッセージ",
      ),
    });
  }

  /**
   * 使用終了日の必須チェックバリデーション
   */
  if (isNullish(value.usageEndDate)) {
    errorMessageList.push({
      errorMessageId: messageId("mes.必須チェックエラー2"),
      errorMessageInterpolations: { name: "$t(lbl.使用終了日)" },
    });
  }

  /**
   * 「添付資料(代表機関用)」セクションにおいて、以下の添付ファイルを常に必須チェックする。
   * ・倫理審査委員会、治験審査委員会、特定認定再生医療等委員会等に提出済み、または提出予定の研究計画書
   * ・提供したiPS細胞ストックの管理体制に関する文書がない場合バリデーションエラーとする。
   * 「UI-FN40_機能仕様(書類・申請)」の「S02相関チェック」シートの「添付資料の必須チェック(共通項目)」参照
   */
  /**
   * 倫理審査委員会、治験審査委員会、特定認定再生医療等委員会等に提出済み、または提出予定の研究計画書の添付ファイル
   */
  const researchProposal = value.attachmentFiles.filter(
    (file) => file.attachmentFileType === "research_proposal",
  );
  if (researchProposal.length === 0) {
    errorMessageList.push({
      errorMessageId: messageId("mes.研究計画書必須エラーメッセージ"),
    });
  }
  /**
   * 提供したiPS細胞ストックの管理体制に関する文書の添付ファイル
   */
  const managementSystem = value.attachmentFiles.filter(
    (file) => file.attachmentFileType === "management_system",
  );
  if (managementSystem.length === 0) {
    errorMessageList.push({
      errorMessageId: messageId("mes.ips管理体制文書必須エラーメッセージ"),
    });
  }
  /**
   * 使用施設の適格性を示す文書
   * (安全性確保法に基づく製造事業者の許可証、GMP適合を示す文書など)の添付ファイル
   */
  const facilityGmpCompliance = value.attachmentFiles.filter(
    (file) => file.attachmentFileType === "facility_gmp_compliance",
  );
  /**
   * 添付資料(代表機関用)セクションについて、「iPS細胞の使用目的」セクションで「ヒトへの投与あり」が選択されている場合、
   * 以下の添付ファイルを必須チェックする。
   * ・使用施設の適格性を示す文書(安全性確保法に基づく製造事業者の許可証、GMP適合を示す文書など)
   * ・
   * 「UI-FN40_機能仕様(書類・申請)」の「S02相関チェック」シートの「添付資料の必須チェック(共通項目)」参照
   */
  if (value.ipsCellStockUsePurpose.purposeType === "clinical") {
    if (facilityGmpCompliance.length === 0) {
      errorMessageList.push({
        errorMessageId: messageId(
          "mes.使用施設の適格性を示す文書必須エラーメッセージ",
        ),
      });
    }
  }

  /**
   * 「分担機関」セクションにおいて、以下の添付ファイルを常に必須チェックする。
   * ・倫理審査委員会、治験審査委員会、特定認定再生医療等委員会等に提出済み、または提出予定の研究計画書
   * ・提供したiPS細胞ストックの管理体制に関する文書がない場合バリデーションエラーとする。
   * 「UI-FN40_機能仕様(書類・申請)」の「S02相関チェック」シートの「添付資料の必須チェック(共通項目)」参照
   */
  value.partners.forEach((partner) => {
    const researchProposal = partner.attachmentFiles.filter(
      (file) => file.attachmentFileType === "research_proposal",
    );
    if (researchProposal.length === 0) {
      errorMessageList.push({
        errorMessageId: messageId("mes.分担機関研究計画書必須エラーメッセージ"),
      });
    }
  });
  value.partners.forEach((partner) => {
    const managementSystem = partner.attachmentFiles.filter(
      (file) => file.attachmentFileType === "management_system",
    );
    if (managementSystem.length === 0) {
      errorMessageList.push({
        errorMessageId: messageId(
          "mes.分担機関ips管理体制文書必須エラーメッセージ",
        ),
      });
    }
  });

  // チェックリストのチェックは不要。
  // if (
  //   value.checklistItem1 === "unchecked" ||
  //   value.checklistItem2 === "unchecked" ||
  //   value.checklistItem3 === "unchecked" ||
  //   value.checklistItem4 === "unchecked" ||
  //   value.checklistItem5 === "unchecked" ||
  //   value.checklistItem6 === "unchecked"
  // ) {
  //   errorMessageList.push({
  //     errorMessageId: messageId(
  //       "mes.iPS細胞ストックの使用に係るチェックリスト必須エラーメッセージ",
  //     ),
  //   });
  // }

  return errorMessageList;
}
