import {
  messageId,
  PropertyMeta,
  ValueObjectMetaString,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.string().max(100);
const savedMinimalSchema = z.string().max(100);
const schema = z.string().min(1).max(100);
const savedSchema = z.string().min(1).max(100);
const jsonSchema = z.string();
/**
 * 当該株を入手した管理番号(値オブジェクト)のメタデータ。
 *
 *  */
export const stockProviderProjectControlNumberMeta = new ValueObjectMetaString(
  "StockProviderProjectControlNumber",
  "\u5F53\u8A72\u682A\u3092\u5165\u624B\u3057\u305F\u7BA1\u7406\u756A\u53F7",
  messageId(
    "lbl.\u5F53\u8A72\u682A\u3092\u5165\u624B\u3057\u305F\u7BA1\u7406\u756A\u53F7"
  ),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
