import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * チェックリスト項目チェック状態(値オブジェクト)
 *
 *
 *
 * unchecked:
 * checked:  */
export type ChecklistItemCheckState = z.infer<
  typeof checklistItemCheckStateMeta.schema
>;
/**
 * チェックリスト項目チェック状態(値オブジェクト)のメタデータ。
 *
 *  */
export const checklistItemCheckStateMeta = new ValueObjectMetaEnum<
  ["unchecked", "checked"]
>(
  "ChecklistItemCheckState",
  "\u30C1\u30A7\u30C3\u30AF\u30EA\u30B9\u30C8\u9805\u76EE\u30C1\u30A7\u30C3\u30AF\u72B6\u614B",
  messageId(
    "lbl.\u30C1\u30A7\u30C3\u30AF\u30EA\u30B9\u30C8\u9805\u76EE\u30C1\u30A7\u30C3\u30AF\u72B6\u614B"
  ),
  ["unchecked", "checked"],
  {
    unchecked:
      "code.\u30C1\u30A7\u30C3\u30AF\u30EA\u30B9\u30C8\u9805\u76EE\u30C1\u30A7\u30C3\u30AF\u72B6\u614B.\u672A\u30C1\u30A7\u30C3\u30AF",
    checked:
      "code.\u30C1\u30A7\u30C3\u30AF\u30EA\u30B9\u30C8\u9805\u76EE\u30C1\u30A7\u30C3\u30AF\u72B6\u614B.\u30C1\u30A7\u30C3\u30AF\u6E08",
  }
);
