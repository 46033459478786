import { Box, Button, Divider, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";

export type ProjectForceCreateCompletePageProps = {
  projectControlNumbers: string[];
};

export const ProjectForceCreateCompletePage = ({
  projectControlNumbers,
}: ProjectForceCreateCompletePageProps) => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();

  return (
    <Box>
      <VStack spacing={"20px"}>
        <Box mt={"50px"}>
          <Text fontSize={"2xl"} fontWeight={"bold"}>
            {t("lbl.案件強制作成完了画面タイトル")}
          </Text>
          {projectControlNumbers.map((val, index) => (
            <Text key={index}>{`・${val}`}</Text>
          ))}
        </Box>
        <Divider borderColor={"gray.300"} w={"70%"} />
        <Box pt={"20px"}>
          <Button colorScheme={"teal"} onClick={() => navigate("/project")}>
            {t("btn.案件検索に戻るボタン")}
          </Button>
        </Box>
      </VStack>
    </Box>
  );
};
