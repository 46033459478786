import {
  messageId,
  PropertyMeta,
  ValueObjectMetaNumber,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.number().min(0);
const savedMinimalSchema = z.number().min(0);
const schema = z.number().min(0);
const savedSchema = z.number().min(0);
const jsonSchema = z.number();
/**
 * ストレージファイルID(値オブジェクト)のメタデータ。
 *
 *  */
export const storageFileIdMeta = new ValueObjectMetaNumber(
  "StorageFileId",
  "\u30B9\u30C8\u30EC\u30FC\u30B8\u30D5\u30A1\u30A4\u30EBID",
  messageId("lbl.\u30B9\u30C8\u30EC\u30FC\u30B8\u30D5\u30A1\u30A4\u30EBID"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
