import React from "react";
import { isNullish } from "../../../../../../lib/util/common-util";
import { AppLink } from "../../../../../ui/link/AppLink/AppLink";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { useAppGetReviewConferenceQuery } from "../../../../../../hooks/query/use-app-get-review-conference-query";
import { FormSection } from "../../../../../ui/form/FormSection/FormSection";

export type SectionCommitteeProps = {
  reviewConferenceId?: number;
};

/**
 * FN40-S30審査
 * 審査会セクション
 */
export const SectionCommittee = ({
  reviewConferenceId,
}: SectionCommitteeProps) => {
  const { t } = useAppTranslation();

  // 審査情報リストの取得
  const { data, isLoading } = useAppGetReviewConferenceQuery(
    {
      reviewConferenceId: reviewConferenceId ?? -1,
    },
    { skip: isNullish(reviewConferenceId) },
  );

  return (
    <FormSection title={t("lbl.審査会")}>
      {!isLoading && (
        <AppLink
          isExternal
          to={`/review-conference/${reviewConferenceId}`}
          ml={"10px"}
          mt={"10px"}
        >
          {data?.reviewConferenceName}
        </AppLink>
      )}
    </FormSection>
  );
};
