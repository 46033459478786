import { ComponentStyleProps } from "../../../../../lib/styles/props/component-style-props";
import { FormSection } from "../../../../ui/form/FormSection/FormSection";
import { useAppTranslation } from "../../../../../hooks/use-app-translation";
import React from "react";
import { CMFormInputTextArea } from "@pscsrvlab/psc-react-components";
import { freeTextMeta } from "../../../../../lib/object/value/free-text";

export type ResultNotificationSectionNoteProps = {
  editMode: "editable" | "readOnly";

  value: string;
  onChange?: (value: string) => void;
} & ComponentStyleProps;

export const ResultNotificationSectionNote = ({
  editMode,

  value = "",
  onChange,

  sx,
  ...rest
}: ResultNotificationSectionNoteProps) => {
  const { t } = useAppTranslation();

  return (
    <FormSection title={t("lbl.備考")} sx={sx} {...rest}>
      <CMFormInputTextArea
        editMode={editMode}
        valueObjectMeta={freeTextMeta}
        value={value}
        onChange={onChange}
      />
    </FormSection>
  );
};
