import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * 発送状況(値オブジェクト)
 *
 *
 *
 * unshipped:
 * shipped:  */
export type ShippingState = z.infer<typeof shippingStateMeta.schema>;
/**
 * 発送状況(値オブジェクト)のメタデータ。
 *
 *  */
export const shippingStateMeta = new ValueObjectMetaEnum<
  ["unshipped", "shipped"]
>(
  "ShippingState",
  "\u767A\u9001\u72B6\u6CC1",
  messageId("lbl.\u767A\u9001\u72B6\u6CC1"),
  ["unshipped", "shipped"],
  {
    unshipped: "code.\u767A\u9001\u72B6\u6CC1.\u672A\u767A\u9001",
    shipped: "code.\u767A\u9001\u72B6\u6CC1.\u767A\u9001\u6E08",
  }
);
