import { useSavedDocument } from "./use-saved-document";
import { useMemo } from "react";
import { hasValue, isNullish } from "../../lib/util/common-util";
import { useNewApplicationVMOrEmpty } from "./new-application/use-new-application-vm";
import { useChangeApplicationVMOrEmpty } from "./change-application/use-change-application-vm";
import { useAnnualReportVMOrEmpty } from "./annual-report/use-annual-report-vm";
import { useTerminationReportVMOrEmpty } from "./termination-report/use-termination-report-vm";
import { DocumentViewModelAndCallback } from "../../lib/object/vm/document-view-model-and-callback";

export function useSavedDocumentVM(
  documentId?: number | null,
  skipOptionalValidations = true, // get系は、skipOptionalValidationsのデフォルトはtrueとする。
) {
  const {
    projectId,

    newApplication,
    changeApplication,
    annualReport,
    terminationReport,

    document,
  } = useSavedDocument(documentId, skipOptionalValidations);

  const {
    initialNewApplicationVM,

    validationErrors: validationErrorsNewApplication,

    saveNewApplicationContent,
    deleteNewApplicationDraft,
  } = useNewApplicationVMOrEmpty(documentId ?? -1, newApplication, {
    skip: isNullish(documentId),
  });

  const {
    initialChangeApplicationVM,

    validationErrors: validationErrorsChangeApplication,

    saveChangeApplicationContent,
    deleteChangeApplicationDraft,
  } = useChangeApplicationVMOrEmpty(
    projectId ?? -1,
    documentId ?? -1,
    changeApplication,
    {
      skip: isNullish(projectId) || isNullish(documentId),
    },
  );
  const {
    initialAnnualReportVM,

    validationErrors: validationErrorsAnnualReport,

    saveAnnualReportContent,
    deleteAnnualReportDraft,
  } = useAnnualReportVMOrEmpty(
    projectId ?? -1,
    documentId ?? -1,
    annualReport,
    {
      skip: isNullish(projectId) || isNullish(documentId),
    },
  );
  const {
    initialTerminationReportVM,

    validationErrors: validationErrorsTerminationReport,

    saveTerminationReportContent,
    deleteTerminationReportDraft,
  } = useTerminationReportVMOrEmpty(
    projectId ?? -1,
    documentId ?? -1,
    terminationReport,
    {
      skip: isNullish(projectId) || isNullish(documentId),
    },
  );

  const validationErrors = useMemo(
    () => [
      ...validationErrorsNewApplication,
      ...validationErrorsChangeApplication,
      ...validationErrorsAnnualReport,
      ...validationErrorsTerminationReport,
    ],
    [
      validationErrorsAnnualReport,
      validationErrorsChangeApplication,
      validationErrorsNewApplication,
      validationErrorsTerminationReport,
    ],
  );

  const vmAndCallback: DocumentViewModelAndCallback | null = useMemo(() => {
    if (
      hasValue(initialNewApplicationVM) &&
      hasValue(saveNewApplicationContent) &&
      hasValue(deleteNewApplicationDraft)
    )
      return {
        vm: initialNewApplicationVM,
        isApplication: true,
        type: "new_application",
        saveDocument: saveNewApplicationContent,
        deleteDocument: deleteNewApplicationDraft,
      };
    if (
      hasValue(initialChangeApplicationVM) &&
      hasValue(saveChangeApplicationContent) &&
      hasValue(deleteChangeApplicationDraft)
    )
      return {
        vm: initialChangeApplicationVM,
        isApplication: true,
        type: "change_application",
        saveDocument: saveChangeApplicationContent,
        deleteDocument: deleteChangeApplicationDraft,
      };
    if (
      hasValue(initialAnnualReportVM) &&
      hasValue(saveAnnualReportContent) &&
      hasValue(deleteAnnualReportDraft)
    )
      return {
        vm: initialAnnualReportVM,
        isApplication: false,
        type: "annual_report",
        saveDocument: saveAnnualReportContent,
        deleteDocument: deleteAnnualReportDraft,
      };
    if (
      hasValue(initialTerminationReportVM) &&
      hasValue(saveTerminationReportContent) &&
      hasValue(deleteTerminationReportDraft)
    )
      return {
        vm: initialTerminationReportVM,
        isApplication: false,
        type: "termination_report",
        saveDocument: saveTerminationReportContent,
        deleteDocument: deleteTerminationReportDraft,
      };
    return null;
  }, [
    initialNewApplicationVM,
    saveNewApplicationContent,
    deleteNewApplicationDraft,
    initialChangeApplicationVM,
    saveChangeApplicationContent,
    deleteChangeApplicationDraft,
    initialAnnualReportVM,
    saveAnnualReportContent,
    deleteAnnualReportDraft,
    initialTerminationReportVM,
    saveTerminationReportContent,
    deleteTerminationReportDraft,
  ]);

  return {
    projectId,

    newApplication,
    changeApplication,
    annualReport,
    terminationReport,

    document,

    initialNewApplicationVM,
    initialChangeApplicationVM,
    initialAnnualReportVM,
    initialTerminationReportVM,

    vmAndCallback,

    validationErrors,
  };
}
