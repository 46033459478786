import { HStack } from "@chakra-ui/react";
import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";
import { ProjectContentTab } from "../ProjectContentTab/ProjectContentTab";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";

export type ProjectContentTabsProps = {
  projectId: number;
} & ComponentStyleProps;
export const ProjectContentTabs = ({
  projectId,
  sx,
  ...rest
}: ProjectContentTabsProps) => {
  const { t } = useAppTranslation();
  return (
    <HStack sx={sx} {...rest} pt={0} pb={0} pl={"10px"} spacing={"10px"}>
      <ProjectContentTab
        label={t("tab.書類内容タブ")}
        to={`/project/${projectId}/content/application`}
      />
      <ProjectContentTab
        label={t("tab.情報公開文書タブ")}
        to={`/project/${projectId}/content/disclosure-document`}
      />
    </HStack>
  );
};
