import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * 国内外区分(値オブジェクト)
 *
 *
 *
 * domestic:
 * foreign:  */
export type ForeignType = z.infer<typeof foreignTypeMeta.schema>;
/**
 * 国内外区分(値オブジェクト)のメタデータ。
 *
 *  */
export const foreignTypeMeta = new ValueObjectMetaEnum<["domestic", "foreign"]>(
  "ForeignType",
  "\u56FD\u5185\u5916\u533A\u5206",
  messageId("lbl.\u56FD\u5185\u5916\u533A\u5206"),
  ["domestic", "foreign"],
  {
    domestic: "code.\u56FD\u5185\u5916\u533A\u5206.\u56FD\u5185",
    foreign: "code.\u56FD\u5185\u5916\u533A\u5206.\u56FD\u5916",
  }
);
