import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * メールログ並び順(値オブジェクト)
 *
 *
 *
 * sent_at_asc:
 * sent_at_desc:  */
export type MailLogSortCondition = z.infer<
  typeof mailLogSortConditionMeta.schema
>;
/**
 * メールログ並び順(値オブジェクト)のメタデータ。
 *
 *  */
export const mailLogSortConditionMeta = new ValueObjectMetaEnum<
  ["sent_at_asc", "sent_at_desc"]
>(
  "MailLogSortCondition",
  "\u30E1\u30FC\u30EB\u30ED\u30B0\u4E26\u3073\u9806",
  messageId("lbl.\u30E1\u30FC\u30EB\u30ED\u30B0\u4E26\u3073\u9806"),
  ["sent_at_asc", "sent_at_desc"],
  {
    sent_at_asc: "code.\u4E26\u3073\u9806.\u9001\u4FE1\u65E5\u6642\u6607\u9806",
    sent_at_desc:
      "code.\u4E26\u3073\u9806.\u9001\u4FE1\u65E5\u6642\u964D\u9806",
  }
);
