import { Flex, HStack, VStack } from "@chakra-ui/react";
import { RequestingCell } from "../../../../../../store/api/generated/stock-request-api";
import React, { useCallback, useMemo } from "react";
import { isNullish } from "../../../../../../lib/util/common-util";
import {
  CMFormControlHeader,
  CMFormInputBaseDropDown,
  CMFormInputNumber,
} from "@pscsrvlab/psc-react-components";
import { cellQuantityMeta } from "../../../../../../lib/object/value/cell-quantity";
import { ShippingCell } from "../../../../../../lib/object/value/shipping-cell";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { CellType } from "../../../../../../lib/object/value/cell-type";

export type ShippingCellCardProps = {
  cellType: CellType;
  editMode: "editable" | "readOnly";
  value: Partial<ShippingCell>;

  requestingCells: RequestingCell[];

  /**
   * ユーザーの権限情報
   * 事務局ユーザーの場合,true
   */
  isOfficeMember: boolean;

  onChange: (
    cellType: CellType,
    change: (before: Partial<ShippingCell>) => Partial<ShippingCell>,
  ) => void;
};

/**
 * 発送する細胞セクションのフォーム内容
 */
export const ShippingCellCard = ({
  cellType,
  editMode,
  value,
  requestingCells,
  isOfficeMember,
  onChange,
}: ShippingCellCardProps) => {
  const { t, i18n } = useAppTranslation();

  /**
   * cellStockIdで株情報を取得する。
   */
  const getCellByIdOrNull = useCallback(
    (cellStockId: number | null) => {
      if (isNullish(cellStockId)) return null;
      return (
        requestingCells.find((value) => value.cellStockId === cellStockId) ??
        null
      );
    },
    [requestingCells],
  );

  /**
   * 研究用株のプルダウン選択肢
   */
  const researchCellList = useMemo(() => {
    const options = requestingCells
      .filter((cell) => cell.cellType === "research_purpose")
      .map((cell) => {
        return {
          label: i18n.language === "ja" ? cell.cellNameJa : cell.cellNameEn,
          value: cell.cellStockId,
        };
      });

    return { options: options };
  }, [requestingCells, i18n.language]);

  /**
   * 臨床用株のプルダウン選択肢
   */
  const clinicalCellList = useMemo(() => {
    const options = requestingCells
      .filter((cell) => cell.cellType === "clinical_purpose")
      .map((cell) => {
        return {
          label: i18n.language === "ja" ? cell.cellNameJa : cell.cellNameEn,
          value: cell.cellStockId,
        };
      });

    return { options: options };
  }, [requestingCells, i18n.language]);

  /**
   * 番号・株名の変更イベント
   */
  const handleChangeCellId = useCallback(
    (cellStockId: number) => {
      const cell = getCellByIdOrNull(cellStockId);
      onChange?.(cellType, (before) => ({
        ...before,
        cellStockId: cell?.cellStockId,
        cellNumber: cell?.cellNumber,
        cellStockCategoryId: cell?.cellStockCategoryId,
        cellCategoryNumber: cell?.cellCategoryNumber,
        cellCategoryNameJa: cell?.cellCategoryNameJa,
        cellCategoryNameEn: cell?.cellCategoryNameEn,
        cellNameJa: cell?.cellNameJa,
        cellNameEn: cell?.cellNameEn,
      }));
    },
    [cellType, getCellByIdOrNull, onChange],
  );

  /**
   * 本数の変更イベント
   */
  const handleChangeQuantity = useCallback(
    (v: number | null) => {
      onChange?.(cellType, (before) => ({
        ...before,
        quantity: v ?? undefined,
      }));
    },
    [cellType, onChange],
  );

  return (
    <HStack alignItems={"flex-start"}>
      <Flex direction={"column"} alignItems={"flex-start"} flex={"1 1 0"}>
        <CMFormControlHeader
          value={value.cellNumber}
          label={t("lbl.番号・株名")}
          mb={"2px"}
        />
        <CMFormInputBaseDropDown<number>
          placeholder={t("lbl.選択プルダウンプレースホルダー")}
          options={
            cellType === "research_purpose"
              ? researchCellList.options
              : clinicalCellList.options
          }
          nullable={true}
          editMode={isOfficeMember ? editMode : "readOnly"}
          onChangeAsNumber={handleChangeCellId}
          value={value.cellStockId}
          size={"md"}
        />
      </Flex>

      <VStack alignItems={"flex-start"} flex={"1 1 0"}>
        <CMFormInputNumber
          editMode={editMode}
          label={t("lbl.本数")}
          valueObjectMeta={cellQuantityMeta}
          skipValidationIfNullish={true}
          value={value.quantity}
          min={0}
          onChange={handleChangeQuantity}
        />
      </VStack>
    </HStack>
  );
};
