import {
  messageId,
  PropertyMeta,
  ValueObjectMetaComposite,
  predefinedYearMonthDayMeta,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * 日付(値オブジェクト)
 *
 * 日付。 */
export type YearMonthDay = z.infer<typeof yearMonthDayMeta.schema>;
/**
 * 日付(値オブジェクト)のメタデータ。
 *
 * 日付。 */
export const yearMonthDayMeta = predefinedYearMonthDayMeta;
