import { CellStockDeliverySaved } from "../entity/cell-stock-delivery";
import { ShippingCell } from "../value/shipping-cell";
import { ShippingInformation } from "../value/shipping-information";
import { ReceiptInformation } from "../value/receipt-information";
import { ShippingDestination } from "../value/shipping-destination";
import { YearMonthDay } from "../value/year-month-day";
import { DeliveryArrangementMethodType } from "../value/delivery-arrangement-method-type";
import { DomesticDelivererType } from "../value/domestic-deliverer-type";
import { DomesticDelivererShipperRegistrationType } from "../value/domestic-deliverer-shipper-registration-type";
import { ForeignDelivererType } from "../value/foreign-deliverer-type";
import { ShippingContainersArrangementType } from "../value/shipping-containers-arrangement-type";
import { PickupDestination } from "../value/pickup-destination";
import { AttachmentFileInformation } from "../value/attachment-file-information";
import { UpdateMetadataSaved } from "../value/update-metadata";
import { CreationMetadataSaved } from "../value/creation-metadata";

export type CellStockDeliveryViewModel = {
  id: number;
  projectId: number;
  shippingInformation: ShippingInformation;
  receiptInformation: ReceiptInformation;
  shippingResearchCells: Partial<ShippingCell>[];
  shippingClinicalCells: Partial<ShippingCell>[];
  shippingDestination: ShippingDestination;
  preferredDeliveryDate?: YearMonthDay;
  deliverer: {
    // 輸送手配方法区分
    deliveryArrangementMethodType: DeliveryArrangementMethodType;
    // 国内輸送手配業者区分
    domesticDelivererType: DomesticDelivererType;
    // 国内輸送手配業者その他
    domesticDelivererOther?: string;
    // 国内輸送手配業者荷主登録区分
    domesticDelivererShipperRegistrationType: DomesticDelivererShipperRegistrationType;
    // 国外輸送手配業者区分
    foreignDelivererType: ForeignDelivererType;
    // 国外輸送手配業者その他
    foreignDelivererOther?: string;
    // 国外輸送アカウント情報
    foreignDelivererAccountInformation: string;
  };
  container: {
    shippingContainerArrangementType: ShippingContainersArrangementType;
    shippingContainerReturningDate?: YearMonthDay;
  };
  pickupDestination: PickupDestination;
  provisionFeeMemo: string;
  officeMemberCommentForApplicants: string;
  officeMemberCommentInternal: string;
  attachmentFiles: AttachmentFileInformation[];
  created: CreationMetadataSaved;
  updated: UpdateMetadataSaved;
};

export function cellStockDeliveryToViewModel(
  v: CellStockDeliverySaved,
): CellStockDeliveryViewModel {
  return {
    id: v.id,
    projectId: v.projectId,
    shippingInformation: v.shippingInformation,
    receiptInformation: v.receiptInformation,
    shippingResearchCells: v.shippingResearchCells,
    shippingClinicalCells: v.shippingClinicalCells,
    shippingDestination: v.shippingDestination,
    preferredDeliveryDate: v.preferredDeliveryDate,
    deliverer: {
      deliveryArrangementMethodType: v.deliveryArrangementMethodType,
      domesticDelivererType: v.domesticDelivererType,
      domesticDelivererOther: v.domesticDelivererOther,
      domesticDelivererShipperRegistrationType:
        v.domesticDelivererShipperRegistrationType,
      foreignDelivererType: v.foreignDelivererType,
      foreignDelivererOther: v.foreignDelivererOther,
      foreignDelivererAccountInformation: v.foreignDelivererAccountInformation,
    },
    container: {
      shippingContainerArrangementType: v.shippingContainerArrangementType,
      shippingContainerReturningDate: v.shippingContainerReturningDate,
    },
    pickupDestination: v.pickupDestination,
    provisionFeeMemo: v.provisionFeeMemo,
    officeMemberCommentForApplicants: v.officeMemberCommentForApplicants,
    officeMemberCommentInternal: v.officeMemberCommentInternal,
    attachmentFiles: v.attachmentFiles,
    created: v.created,
    updated: v.updated,
  };
}
export function cellStockDeliveryFromViewModel(
  v: CellStockDeliveryViewModel,
): Omit<
  CellStockDeliverySaved,
  "shippingResearchCells" | "shippingClinicalCells"
> & {
  shippingResearchCells: Partial<ShippingCell>[];
  shippingClinicalCells: Partial<ShippingCell>[];
} {
  const cellStockDelivery = {
    id: v.id,
    projectId: v.projectId,
    shippingInformation: v.shippingInformation,
    receiptInformation: v.receiptInformation,
    shippingResearchCells: v.shippingResearchCells,
    shippingClinicalCells: v.shippingClinicalCells,
    shippingDestination: v.shippingDestination,
    preferredDeliveryDate: v.preferredDeliveryDate,
    deliveryArrangementMethodType: v.deliverer.deliveryArrangementMethodType,
    domesticDelivererType: v.deliverer.domesticDelivererType,
    domesticDelivererOther: v.deliverer.domesticDelivererOther,
    domesticDelivererShipperRegistrationType:
      v.deliverer.domesticDelivererShipperRegistrationType,
    foreignDelivererType: v.deliverer.foreignDelivererType,
    foreignDelivererOther: v.deliverer.foreignDelivererOther,
    foreignDelivererAccountInformation:
      v.deliverer.foreignDelivererAccountInformation,
    shippingContainerArrangementType:
      v.container.shippingContainerArrangementType,
    shippingContainerReturningDate: v.container.shippingContainerReturningDate,
    pickupDestination: v.pickupDestination,
    provisionFeeMemo: v.provisionFeeMemo,
    officeMemberCommentForApplicants: v.officeMemberCommentForApplicants,
    officeMemberCommentInternal: v.officeMemberCommentInternal,
    attachmentFiles: v.attachmentFiles,
    created: v.created,
    updated: v.updated,
  };
  return cellStockDelivery;
}
