import {
  messageId,
  PropertyMeta,
  ValueObjectMetaString,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.string().max(10000);
const savedMinimalSchema = z.string().max(10000);
const schema = z.string().max(10000);
const savedSchema = z.string().max(10000);
const jsonSchema = z.string();
/**
 * 自由記述テキスト(値オブジェクト)のメタデータ。
 *
 *  */
export const freeTextMeta = new ValueObjectMetaString(
  "FreeText",
  "\u81EA\u7531\u8A18\u8FF0\u30C6\u30AD\u30B9\u30C8",
  messageId("lbl.\u81EA\u7531\u8A18\u8FF0\u30C6\u30AD\u30B9\u30C8"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
