import {
  messageId,
  PropertyMeta,
  ValueObjectMetaComposite,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { requestingCellCustomValidation } from "../custom-validation/requesting-cell";
import { groupLocalKeyMeta } from "../value/group-local-key";
import { cellStockIdMeta } from "../value/cell-stock-id";
import { cellTypeMeta } from "../value/cell-type";
import { cellStockCategoryIdMeta } from "../value/cell-stock-category-id";
import { cellCategoryNumberMeta } from "../value/cell-category-number";
import { cellCategoryNameJaMeta } from "../value/cell-category-name-ja";
import { cellCategoryNameEnMeta } from "../value/cell-category-name-en";
import { cellNumberMeta } from "../value/cell-number";
import { cellNameJaMeta } from "../value/cell-name-ja";
import { cellNameEnMeta } from "../value/cell-name-en";
import { cellProvisionTypeMeta } from "../value/cell-provision-type";
import { stockProviderOrganizationNameMeta } from "../value/stock-provider-organization-name";
import { stockProviderProjectControlNumberMeta } from "../value/stock-provider-project-control-number";
/**
 * 使用する細胞(値オブジェクト)
 *
 *  */
export type RequestingCell = z.infer<typeof schema>;
export type RequestingCellSaved = z.infer<typeof savedSchema>;
const minimalSchema = z.object({
  key: groupLocalKeyMeta.minimalSchema,
  cellStockId: cellStockIdMeta.minimalSchema,
  cellType: cellTypeMeta.minimalSchema,
  cellStockCategoryId: cellStockCategoryIdMeta.minimalSchema,
  cellCategoryNumber: cellCategoryNumberMeta.minimalSchema,
  cellCategoryNameJa: cellCategoryNameJaMeta.minimalSchema,
  cellCategoryNameEn: cellCategoryNameEnMeta.minimalSchema,
  cellNumber: cellNumberMeta.minimalSchema,
  cellNameJa: cellNameJaMeta.minimalSchema,
  cellNameEn: cellNameEnMeta.minimalSchema,
  cellProvisionType: cellProvisionTypeMeta.minimalSchema,
  stockProviderOrganizationName: stockProviderOrganizationNameMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  stockProviderProjectControlNumber:
    stockProviderProjectControlNumberMeta.minimalSchema
      .nullish()
      .transform((x) => x ?? undefined),
});
const savedMinimalSchema = z.object({
  key: groupLocalKeyMeta.savedMinimalSchema,
  cellStockId: cellStockIdMeta.savedMinimalSchema,
  cellType: cellTypeMeta.savedMinimalSchema,
  cellStockCategoryId: cellStockCategoryIdMeta.savedMinimalSchema,
  cellCategoryNumber: cellCategoryNumberMeta.savedMinimalSchema,
  cellCategoryNameJa: cellCategoryNameJaMeta.savedMinimalSchema,
  cellCategoryNameEn: cellCategoryNameEnMeta.savedMinimalSchema,
  cellNumber: cellNumberMeta.savedMinimalSchema,
  cellNameJa: cellNameJaMeta.savedMinimalSchema,
  cellNameEn: cellNameEnMeta.savedMinimalSchema,
  cellProvisionType: cellProvisionTypeMeta.savedMinimalSchema,
  stockProviderOrganizationName:
    stockProviderOrganizationNameMeta.savedMinimalSchema
      .nullish()
      .transform((x) => x ?? undefined),
  stockProviderProjectControlNumber:
    stockProviderProjectControlNumberMeta.savedMinimalSchema
      .nullish()
      .transform((x) => x ?? undefined),
});
const schema = z.object({
  key: groupLocalKeyMeta.schema,
  cellStockId: cellStockIdMeta.schema,
  cellType: cellTypeMeta.schema,
  cellStockCategoryId: cellStockCategoryIdMeta.schema,
  cellCategoryNumber: cellCategoryNumberMeta.schema,
  cellCategoryNameJa: cellCategoryNameJaMeta.schema,
  cellCategoryNameEn: cellCategoryNameEnMeta.schema,
  cellNumber: cellNumberMeta.schema,
  cellNameJa: cellNameJaMeta.schema,
  cellNameEn: cellNameEnMeta.schema,
  cellProvisionType: cellProvisionTypeMeta.schema,
  stockProviderOrganizationName: stockProviderOrganizationNameMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
  stockProviderProjectControlNumber:
    stockProviderProjectControlNumberMeta.schema
      .nullish()
      .transform((x) => x ?? undefined),
});
const savedSchema = z.object({
  key: groupLocalKeyMeta.savedSchema,
  cellStockId: cellStockIdMeta.savedSchema,
  cellType: cellTypeMeta.savedSchema,
  cellStockCategoryId: cellStockCategoryIdMeta.savedSchema,
  cellCategoryNumber: cellCategoryNumberMeta.savedSchema,
  cellCategoryNameJa: cellCategoryNameJaMeta.savedSchema,
  cellCategoryNameEn: cellCategoryNameEnMeta.savedSchema,
  cellNumber: cellNumberMeta.savedSchema,
  cellNameJa: cellNameJaMeta.savedSchema,
  cellNameEn: cellNameEnMeta.savedSchema,
  cellProvisionType: cellProvisionTypeMeta.savedSchema,
  stockProviderOrganizationName: stockProviderOrganizationNameMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  stockProviderProjectControlNumber:
    stockProviderProjectControlNumberMeta.savedSchema
      .nullish()
      .transform((x) => x ?? undefined),
});
const jsonSchema = z.object({
  key: groupLocalKeyMeta.jsonSchema.nullish().transform((x) => x ?? undefined),
  cellStockId: cellStockIdMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  cellType: cellTypeMeta.jsonSchema.nullish().transform((x) => x ?? undefined),
  cellStockCategoryId: cellStockCategoryIdMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  cellCategoryNumber: cellCategoryNumberMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  cellCategoryNameJa: cellCategoryNameJaMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  cellCategoryNameEn: cellCategoryNameEnMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  cellNumber: cellNumberMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  cellNameJa: cellNameJaMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  cellNameEn: cellNameEnMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  cellProvisionType: cellProvisionTypeMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  stockProviderOrganizationName: stockProviderOrganizationNameMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  stockProviderProjectControlNumber:
    stockProviderProjectControlNumberMeta.jsonSchema
      .nullish()
      .transform((x) => x ?? undefined),
});
/**
 * 使用する細胞(値オブジェクト)のメタデータ。
 *
 *
 *
 * 以下のカスタムバリデーションを、 requestingCellCustomValidation にて行う。
 * - S02相関チェックシート．細胞提供．No.5 (エラーメッセージID=mes.自機関保有細胞使用管理番号未入力エラーメッセージ)
 * - S02相関チェックシート．細胞提供．No.9 (エラーメッセージID=mes.他機関保有細胞使用機関名未入力エラーメッセージ)
 * - S02相関チェックシート．細胞提供．No.11 (エラーメッセージID=mes.他機関保有細胞管理番号未入力エラーメッセージ) */
export const requestingCellMeta = new ValueObjectMetaComposite(
  "RequestingCell",
  "\u4F7F\u7528\u3059\u308B\u7D30\u80DE",
  messageId("lbl.\u4F7F\u7528\u3059\u308B\u7D30\u80DE"),
  minimalSchema,
  savedMinimalSchema,
  schema,
  savedSchema,
  jsonSchema,
  undefined,
  requestingCellCustomValidation,
  {
    key: new PropertyMeta(
      "key",
      "\u30AD\u30FC",
      messageId("lbl.\u30AD\u30FC"),
      "always",
      false,
      groupLocalKeyMeta
    ),
    cellStockId: new PropertyMeta(
      "cellStockId",
      "\u7D30\u80DEID",
      messageId("lbl.\u7D30\u80DEID"),
      "always",
      false,
      cellStockIdMeta
    ),
    cellType: new PropertyMeta(
      "cellType",
      "\u7D30\u80DE\u533A\u5206",
      messageId("lbl.\u7D30\u80DE\u533A\u5206"),
      "always",
      false,
      cellTypeMeta
    ),
    cellStockCategoryId: new PropertyMeta(
      "cellStockCategoryId",
      "\u7D30\u80DE\u30AB\u30C6\u30B4\u30EAID",
      messageId("lbl.\u7D30\u80DE\u30AB\u30C6\u30B4\u30EAID"),
      "always",
      false,
      cellStockCategoryIdMeta
    ),
    cellCategoryNumber: new PropertyMeta(
      "cellCategoryNumber",
      "\u7D30\u80DE\u30AB\u30C6\u30B4\u30EA\u756A\u53F7",
      messageId("lbl.\u7D30\u80DE\u30AB\u30C6\u30B4\u30EA\u756A\u53F7"),
      "always",
      false,
      cellCategoryNumberMeta
    ),
    cellCategoryNameJa: new PropertyMeta(
      "cellCategoryNameJa",
      "\u7D30\u80DE\u30AB\u30C6\u30B4\u30EA\u540D\uFF08\u65E5\uFF09",
      messageId(
        "lbl.\u7D30\u80DE\u30AB\u30C6\u30B4\u30EA\u540D\uFF08\u65E5\uFF09"
      ),
      "always",
      false,
      cellCategoryNameJaMeta
    ),
    cellCategoryNameEn: new PropertyMeta(
      "cellCategoryNameEn",
      "\u7D30\u80DE\u30AB\u30C6\u30B4\u30EA\u540D\uFF08\u82F1\uFF09",
      messageId(
        "lbl.\u7D30\u80DE\u30AB\u30C6\u30B4\u30EA\u540D\uFF08\u82F1\uFF09"
      ),
      "always",
      false,
      cellCategoryNameEnMeta
    ),
    cellNumber: new PropertyMeta(
      "cellNumber",
      "\u7D30\u80DE\u756A\u53F7",
      messageId("lbl.\u7D30\u80DE\u756A\u53F7"),
      "always",
      false,
      cellNumberMeta
    ),
    cellNameJa: new PropertyMeta(
      "cellNameJa",
      "\u7D30\u80DE\u540D\uFF08\u65E5\uFF09",
      messageId("lbl.\u7D30\u80DE\u540D\uFF08\u65E5\uFF09"),
      "always",
      false,
      cellNameJaMeta
    ),
    cellNameEn: new PropertyMeta(
      "cellNameEn",
      "\u7D30\u80DE\u540D\uFF08\u82F1\uFF09",
      messageId("lbl.\u7D30\u80DE\u540D\uFF08\u82F1\uFF09"),
      "always",
      false,
      cellNameEnMeta
    ),
    cellProvisionType: new PropertyMeta(
      "cellProvisionType",
      "\u7D30\u80DE\u63D0\u4F9B\u533A\u5206",
      messageId("lbl.\u7D30\u80DE\u63D0\u4F9B\u533A\u5206"),
      "always",
      false,
      cellProvisionTypeMeta
    ),
    stockProviderOrganizationName: new PropertyMeta(
      "stockProviderOrganizationName",
      "\u5F53\u8A72\u682A\u3092\u5165\u624B\u3057\u305F\u6A5F\u95A2\u540D",
      messageId(
        "lbl.\u5F53\u8A72\u682A\u3092\u5165\u624B\u3057\u305F\u6A5F\u95A2\u540D"
      ),
      "none",
      false,
      stockProviderOrganizationNameMeta
    ),
    stockProviderProjectControlNumber: new PropertyMeta(
      "stockProviderProjectControlNumber",
      "\u5F53\u8A72\u682A\u3092\u5165\u624B\u3057\u305F\u7BA1\u7406\u756A\u53F7",
      messageId(
        "lbl.\u5F53\u8A72\u682A\u3092\u5165\u624B\u3057\u305F\u7BA1\u7406\u756A\u53F7"
      ),
      "none",
      false,
      stockProviderProjectControlNumberMeta
    ),
  }
);
