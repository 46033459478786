/**
 * 定数ファイル
 */

export const APP_TIME_ZONE = "Asia/Tokyo";

/**
 * 質疑応答一覧
 */
export const INQUIRY_DEFAULT_SEARCH_LIMIT = 5;

/**
 * 配列操作
 */
export const ARRAY_EMPTY = 0;
export const ARRAY_NOT_CONTAIN = -1;
