import React from "react";
import { useSelector } from "react-redux";
import { selectUserInfo } from "../../../../store/auth/slice";
import { hasValue } from "../../../../lib/util/common-util";
import { ProfilePage } from "../../../model/profile/ProfilePage/ProfilePage";

/**
 * FN10-S07プロフィール
 */
export const FN10S07Profile = () => {
  const currentUserInfo = useSelector(selectUserInfo);

  return (
    <>
      {hasValue(currentUserInfo) && (
        <ProfilePage currentUserInfo={currentUserInfo} />
      )}
    </>
  );
};
