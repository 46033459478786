import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Center,
  Flex,
  Spacer,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { CMButton } from "@pscsrvlab/psc-react-components";
import { TitleText } from "../../../ui/text/TitleText/TitleText";
import { VerticalTableFrame } from "../../../ui/table/VerticalTableFrame/VerticalTableFrame";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ComponentStyleProps } from "../../../../lib/styles/props/component-style-props";
import { VerticalTableRowTextInput } from "../../../ui/table/VerticalTableRowTextInput/VerticalTableRowTextInput";
import { VerticalTableRowDropDown } from "../../../ui/table/VerticalTableRowDropDown/VerticalTableRowDropDown";
import { BackPageButton } from "../../../ui/button/BackPageButton/BackPageButton";
import useCustomToast from "../../../../hooks/use-custom-toast";
import { WeakTextItem } from "../../../ui/text/WeakTextItem/WeakTextItem";
import {
  getMessageFromEnumValue,
  hasValue,
  isNullish,
} from "../../../../lib/util/common-util";
import { institutionMeta } from "../../../../lib/object/entity/institution";
import { jsDateToDatetimeText } from "../../../../lib/util/common-date-util";
import { MdEdit } from "react-icons/md";
import { FrameUpperRightButton } from "../../../ui/frame/FrameUpperRightButton/FrameUpperRightButton";
import { useSelector } from "react-redux";
import { selectHasRole } from "../../../../store/auth/slice";
import {
  ForeignType,
  foreignTypeMeta,
} from "../../../../lib/object/value/foreign-type";
import {
  CommercialType,
  commercialTypeMeta,
} from "../../../../lib/object/value/commercial-type";
import {
  useCreateInstitutionMutation,
  useUpdateInstitutionMutation,
} from "../../../../store/api/generated/stock-request-api";
import { useAppParams } from "../../../../hooks/use-app-params";
import { useAppGetInstitutionQuery } from "../../../../hooks/query/use-app-get-institution-query";
import { useCreateJsonPatchOperations } from "../../../../hooks/use-create-json-patch-operations";
import { useAppTranslation } from "../../../../hooks/use-app-translation";
import { ConfirmationModal } from "../../../ui/modal/ConfirmationModal/ConfirmationModal";
import { backendErrorToErrorMessage } from "../../../../lib/util/error-util";
import { useLeaveEditingPrompt } from "../../../../hooks/use-leave-editing-prompt";

// 表示モード（新規作成・参照・編集）
type Mode = "create" | "reference" | "update";

// 確認モーダルの種別
type ModalType = "createInstitution" | "updateInstitution" | "notEdited";

export type InstitutionDetailProps = {
  createMode?: boolean;
} & ComponentStyleProps;

/**
 * 機関詳細画面（新規作成・参照・編集共通）
 */
export const InstitutionDetail = ({
  createMode = false,
  sx,
  ...rest
}: InstitutionDetailProps) => {
  const { t } = useAppTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const { successToast, errorToast, validationErrorToast } = useCustomToast();

  // 機関情報（入力情報）を保持
  const [displayInstitutionId, setDisplayInstitutionId] = useState("");
  const [name, setName] = useState("");
  const [nameKana, setNameKana] = useState("");
  const [foreignType, setForeignType] = useState<"domestic" | "foreign">(
    "domestic",
  );
  const [commercialType, setCommercialType] = useState<
    "commercial" | "noncommercial"
  >("commercial");

  // 表示モード（新規作成、参照、編集）
  const [mode, setMode] = useState<Mode>(createMode ? "create" : "reference");
  // 確認モーダルの種別
  const [modalType, setModalType] = useState<ModalType>();

  const { navigateWithoutPrompt } = useLeaveEditingPrompt({
    skip: mode === "reference",
  });

  const editMode = useMemo(
    () => (mode === "reference" ? "readOnly" : "editable"),
    [mode],
  );

  const { institutionId } = useAppParams();

  const { isApplicant, isOfficeMember } = useSelector(selectHasRole);

  // 機関情報を取得
  const { data: institution, error: institutionError } =
    useAppGetInstitutionQuery(
      { institutionId: institutionId ?? -1 },
      { skip: isNullish(institutionId) },
    );

  // 機関管理機能の各ミューテーション
  const [createInstitution] = useCreateInstitutionMutation();
  const [updateInstitution] = useUpdateInstitutionMutation();

  // 登録済みの機関情報が取得できた場合はStateへ上書き
  useEffect(() => {
    if (hasValue(institution)) {
      setDisplayInstitutionId(institution.displayInstitutionId);
      setName(institution.name);
      setNameKana(institution.nameKana);
      setForeignType(institution.foreignType);
      setCommercialType(institution.commercialType);
    }
  }, [institution]);

  // 新規作成時以外でURLのパスに正しい機関IDが指定されていない場合はエラーページへ遷移
  useEffect(() => {
    if (mode !== "create" && isNullish(institutionId)) {
      navigateWithoutPrompt("/error/404");
    }
  }, [institutionId, navigateWithoutPrompt, mode]);

  // 指定された機関が見つからない場合（バックエンドから404が返された場合）はエラーページへ遷移
  useEffect(() => {
    if (hasValue(institutionError) && "status" in institutionError) {
      if (institutionError.status === 404) {
        navigateWithoutPrompt("/error/404");
      }
    }
  }, [institutionError, navigateWithoutPrompt]);

  // フォーマットした作成日時
  const createdAt = useMemo(() => {
    if (institution?.created?.datetime) {
      return jsDateToDatetimeText(institution.created.datetime);
    }
  }, [institution]);

  // フォーマットした更新日時
  const updatedAt = useMemo(() => {
    if (institution?.updated?.datetime) {
      return jsDateToDatetimeText(institution.updated.datetime);
    }
  }, [institution]);

  // 確認モーダルに表示するメッセージ
  const modalMessage = useMemo(() => {
    switch (modalType) {
      case "createInstitution":
        return t("mes.機関作成確認メッセージ");
      case "updateInstitution":
        return t("mes.変更保存確認メッセージ");
      case "notEdited":
        return t("mes.変更内容破棄メッセージ");
    }
    return "";
  }, [modalType, t]);

  // 国内外区分のドロップダウンリスト項目
  const foreignTypeOptions = useMemo(() => {
    return [
      ...Object.keys(foreignTypeMeta.dict)
        .map((value) => {
          const foreignType = foreignTypeMeta.validate(value);
          if (foreignType.state === "ok") {
            return {
              label:
                getMessageFromEnumValue(
                  t,
                  foreignTypeMeta,
                  foreignType.value,
                ) ?? "",
              value: foreignType.value,
            };
          }
        })
        .filter(hasValue),
    ];
  }, [t]);

  // 営利非営利区分のドロップダウンリスト項目
  const commercialTypeOptions = useMemo(() => {
    return [
      ...Object.keys(commercialTypeMeta.dict)
        .map((value) => {
          const commercialType = commercialTypeMeta.validate(value);
          if (commercialType.state === "ok") {
            return {
              label:
                getMessageFromEnumValue(
                  t,
                  commercialTypeMeta,
                  commercialType.value,
                ) ?? "",
              value: commercialType.value,
            };
          }
        })
        .filter(hasValue),
    ];
  }, [t]);

  // 確認モーダルの表示イベント
  const handleOpenModal = useCallback(
    (state: ModalType) => {
      setModalType(state);
      onOpen();
    },
    [onOpen],
  );

  // 照会画面へ戻るボタン、編集終了ボタンが押された際、入力フォームをリセット
  const resetForm = useCallback(() => {
    setDisplayInstitutionId(institution?.displayInstitutionId ?? "");
    setName(institution?.name ?? "");
    setNameKana(institution?.nameKana ?? "");
    setForeignType(institution?.foreignType ?? "domestic");
    setCommercialType(institution?.commercialType ?? "commercial");
  }, [
    institution?.commercialType,
    institution?.displayInstitutionId,
    institution?.foreignType,
    institution?.name,
    institution?.nameKana,
  ]);

  const { createJsonPatchOperations } =
    useCreateJsonPatchOperations(institutionMeta);

  // 確認モーダルの確定ボタン押下時のイベント
  const handleModalSubmit = useCallback(async () => {
    try {
      // 確認モーダルの種別によって各処理を実施
      switch (modalType) {
        // 機関新規作成
        case "createInstitution": {
          // バリデーションチェック
          const validatedInstitution = institutionMeta.validate({
            displayInstitutionId: displayInstitutionId,
            name: name,
            nameKana: nameKana,
            foreignType: foreignType,
            commercialType: commercialType,
            created: {},
            updated: {},
          });

          // バリデーションエラーが存在する場合
          if (validatedInstitution.state !== "ok") {
            validationErrorToast(validatedInstitution.errors);
            return;
          }

          // 更新後の機関情報オブジェクトを作成
          const newInstitution = institutionMeta.toJsonObjectOrNull(
            validatedInstitution.value,
          );
          if (isNullish(newInstitution)) {
            errorToast(t("mes.一般エラーメッセージ"));
            return;
          }

          // 機関情報を作成
          await createInstitution({ institution: newInstitution }).unwrap();
          successToast(t("mes.機関登録完了メッセージ"));
          navigateWithoutPrompt("/institution");
          return;
        }

        // 機関情報更新
        case "updateInstitution": {
          if (isNullish(institution) || isNullish(institution.id)) return;
          // バリデーションチェック
          const validatedInstitution = institutionMeta.validate({
            id: institution.id,
            displayInstitutionId: displayInstitutionId,
            name: name,
            nameKana: nameKana,
            foreignType: foreignType,
            commercialType: commercialType,
            created: {},
            updated: {},
          });
          // バリデーションエラーが存在する場合
          if (validatedInstitution.state !== "ok") {
            validationErrorToast(validatedInstitution.errors);
            return;
          }
          // 更新後の機関情報オブジェクトを作成
          const updatedInstitution = institutionMeta.toDomainObjectOrNull(
            validatedInstitution.value,
          );
          if (isNullish(updatedInstitution)) {
            errorToast(t("mes.汎用エラーメッセージ"));
            return;
          }

          // JSONパッチを作成
          const patchOperations = createJsonPatchOperations(
            institution,
            updatedInstitution,
          );

          // 機関情報を更新
          await updateInstitution({
            institutionId: institution.id,
            commonUpdateRequest: {
              patchOperations,
            },
          }).unwrap();

          successToast(t("mes.変更保存メッセージ"));
          setMode("reference");
          break;
        }

        // 変更無し
        case "notEdited":
          resetForm();
          setMode("reference");
          break;
        default:
          break;
      }
    } catch (e) {
      errorToast(backendErrorToErrorMessage(t, e));
    } finally {
      // finally を使用して必ずモーダルの種別をリセットする
      // ※ 入力内容破棄の確認メッセージ表示の条件に使用する為
      setModalType(undefined);
    }
  }, [
    commercialType,
    createInstitution,
    createJsonPatchOperations,
    displayInstitutionId,
    errorToast,
    foreignType,
    institution,
    modalType,
    name,
    nameKana,
    navigateWithoutPrompt,
    resetForm,
    successToast,
    t,
    updateInstitution,
    validationErrorToast,
  ]);

  // // 変更内容破棄確認ダイアログ
  // usePrompt({
  //   // 編集中、または、新規追加時（何もイベントが実行されていない状態）の場合は
  //   // ページ遷移時に確認ダイアログを表示
  //   when: mode === "update" || (mode === "create" && isNullish(modalType)),
  //   message: t("mes.変更内容破棄メッセージ"),
  // });

  return (
    <>
      <Box w={"100%"} sx={sx} {...rest}>
        <>
          <Flex
            pl={"32px"}
            pr={"11px"}
            pt={"25px"}
            justifyContent={"space-between"}
          >
            {!isApplicant && (mode === "create" || mode === "reference") && (
              <BackPageButton
                label={t("btn.一覧に戻るボタン")}
                onClick={() => navigate("/institution")}
              />
            )}
            {mode === "update" && (
              <BackPageButton
                label={t("btn.照会画面に戻るボタン")}
                onClick={() => handleOpenModal("notEdited")}
              />
            )}

            {mode !== "create" && isOfficeMember && (
              <FrameUpperRightButton>
                {mode === "reference" && (
                  <>
                    <Button
                      size={"sm"}
                      leftIcon={<MdEdit />}
                      colorScheme={"teal"}
                      onClick={() => {
                        setMode("update");
                      }}
                    >
                      {t("btn.編集ボタン")}
                    </Button>
                  </>
                )}
                {mode === "update" && (
                  <>
                    <CMButton
                      size={"sm"}
                      label={t("btn.変更を保存するボタン")}
                      onClick={() => handleOpenModal("updateInstitution")}
                    />
                    <Button
                      size={"sm"}
                      ml={"5px"}
                      color={"gray.600"}
                      bgColor={"gray.200"}
                      _hover={{ bgColor: "gray.300" }}
                      onClick={() => handleOpenModal("notEdited")}
                    >
                      {t("btn.編集終了ボタン")}
                    </Button>
                  </>
                )}
              </FrameUpperRightButton>
            )}
          </Flex>

          <Center>
            <VStack spacing={"unset"} maxW={"540px"} mb={"68px"}>
              {mode === "create" && (
                <TitleText
                  title={t("lbl.機関詳細(登録)画面タイトル")}
                  sx={{ fontSize: "30px", mb: "28px" }}
                />
              )}
              {mode !== "create" && (
                <Flex w={"100%"} pt={"37px"} pb={"7px"}>
                  <VStack flex={"1 1 auto"} minW={0} alignItems={"stretch"}>
                    <TitleText title={institution?.name ?? ""} />
                    <WeakTextItem
                      label={t("lbl.機関ID")}
                      value={institution?.displayInstitutionId ?? ""}
                    />
                  </VStack>
                  <Spacer />
                  <VStack
                    flex={"1 0 auto"}
                    minW={0}
                    spacing={"unset"}
                    justifyContent={"end"}
                  >
                    <WeakTextItem label={t("lbl.作成日時")} value={createdAt} />
                    <WeakTextItem label={t("lbl.更新日時")} value={updatedAt} />
                  </VStack>
                </Flex>
              )}
              <VerticalTableFrame>
                <VerticalTableRowTextInput
                  label={t("lbl.機関ID")}
                  editMode={editMode}
                  value={displayInstitutionId}
                  onChange={(value) => setDisplayInstitutionId(value)}
                />
                <VerticalTableRowTextInput
                  label={t("lbl.機関名")}
                  editMode={editMode}
                  value={name}
                  onChange={(value) => setName(value)}
                />
                <VerticalTableRowTextInput
                  label={t("lbl.機関名かな")}
                  editMode={editMode}
                  value={nameKana}
                  onChange={(value) => setNameKana(value)}
                />
                <VerticalTableRowDropDown<ForeignType>
                  label={t("lbl.国内外区分")}
                  editMode={editMode}
                  value={foreignType}
                  options={foreignTypeOptions}
                  onChange={(value) => setForeignType(value)}
                />
                <VerticalTableRowDropDown<CommercialType>
                  label={t("lbl.営利非営利区分")}
                  editMode={editMode}
                  value={commercialType}
                  options={commercialTypeOptions}
                  onChange={(value) => setCommercialType(value)}
                />
              </VerticalTableFrame>
              {mode === "create" && (
                <Box>
                  <CMButton
                    label={t("btn.機関を登録するボタン")}
                    onClick={() => handleOpenModal("createInstitution")}
                    sx={{ mt: "36px" }}
                  />
                </Box>
              )}
            </VStack>
          </Center>
        </>
        <ConfirmationModal
          isOpen={isOpen}
          message={modalMessage}
          onSubmit={handleModalSubmit}
          onCancel={onClose}
        />
      </Box>
    </>
  );
};
