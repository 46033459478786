import {
  SearchDocumentApiArg,
  useSearchDocumentQuery,
} from "../../../../store/api/generated/stock-request-api";
import {
  getAllEnumFromSearchParams,
  getBooleanFromSearchParams,
  getDateStringFromSearchParams,
  getEnumFromSearchParams,
  getNumberFromSearchParams,
  getStringFromSearchParams,
} from "../../../../lib/util/search-params-util";
import { searchConditionLimitMeta } from "../../../../lib/object/value/search-condition-limit";
import { documentSortConditionMeta } from "../../../../lib/object/value/document-sort-condition";
import { documentControlNumberMeta } from "../../../../lib/object/value/document-control-number";
import { documentStateMeta } from "../../../../lib/object/value/document-state";
import { documentTypeMeta } from "../../../../lib/object/value/document-type";
import { documentPositionMeta } from "../../../../lib/object/value/document-position";
import {
  ReviewType,
  reviewTypeMeta,
} from "../../../../lib/object/value/review-type";
import { resultMeta } from "../../../../lib/object/value/result";
import { projectIdMeta } from "../../../../lib/object/value/project-id";
import { projectNameMeta } from "../../../../lib/object/value/project-name";
import { institutionIdMeta } from "../../../../lib/object/value/institution-id";
import { appUserIdMeta } from "../../../../lib/object/value/app-user-id";
import { searchConditionPageNumberMeta } from "../../../../lib/object/value/search-condition-page-number";
import { useNavigate } from "react-router-dom";
import React, { useCallback, useMemo } from "react";
import { HStack, useDisclosure } from "@chakra-ui/react";
import { SearchConditionSidebarDocument } from "./_components/SearchConditionSidebarDocument/SearchConditionSidebarDocument";
import { hasValue, isNullish } from "../../../../lib/util/common-util";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import { selectHasRole } from "../../../../store/auth/slice";
import { SearchResultArea } from "../../../ui/search/SearchResultArea/SearchResultArea";
import { CMButton } from "@pscsrvlab/psc-react-components";
import { useSearchPage } from "../../../../hooks/use-search-page";
import {
  documentMeta,
  DocumentSaved,
} from "../../../../lib/object/entity/document";
import { SearchResultTableDocument } from "./_components/SearchResultTableDocument/SearchResultTableDocument";
import { useSearchResultPaginationNumbers } from "../../../../hooks/use-search-result-pagination-numbers";
import { useAppTranslation } from "../../../../hooks/use-app-translation";
import { ConfirmationModal } from "../../../ui/modal/ConfirmationModal/ConfirmationModal";

export const FN40S01DocumentSearch = () => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const {
    isApplicant,
    isOfficeMember,
    isCommitteeMember,
    isExecutiveDirector,
  } = useAppSelector(selectHasRole);

  // ログインユーザーが財団側のメンバーの場合,trueを設定する
  const isFoundationMember = useMemo(() => {
    return isOfficeMember || isCommitteeMember || isExecutiveDirector;
  }, [isCommitteeMember, isExecutiveDirector, isOfficeMember]);

  const { searchArgs, handleChangeSearchCondition, handleChangePage } =
    useSearchPage(urlSearchParamsToSearchArgs);

  /**
   * 検索処理。
   */
  const { data, isLoading } = useSearchDocumentQuery(searchArgs);
  const entities: {
    value: DocumentSaved;
    reviewType?: ReviewType;
    todo: boolean;
  }[] = useMemo(() => {
    if (isNullish(data)) return [];
    return data.items
      .map((v) => {
        // 下書きの場合がありうるため、skipOptionalはtrueとする。
        const value = documentMeta.toSavedDomainObjectOrNull(v.document, true);
        if (isNullish(value)) return null;
        return {
          value,
          reviewType: v.reviewType,
          todo: v.todo,
        };
      })
      .filter(hasValue);
  }, [data]);

  const { totalElements, currentPageNumber, totalPageCount } =
    useSearchResultPaginationNumbers(searchArgs, data);

  // 新規申請作成の確認モーダル制御用
  const { isOpen, onOpen, onClose } = useDisclosure();

  // 確認モーダルで確定ボタンが押された場合の処理
  const handleSubmitCreateNewApplication = useCallback(() => {
    onClose();
    navigate("/document/create-new-application/create");
  }, [navigate, onClose]);

  return (
    <>
      <HStack flex={1} spacing={0} overflow={"hidden"} alignItems={"stretch"}>
        <SearchConditionSidebarDocument
          searchArgs={searchArgs}
          isFoundationMember={isFoundationMember}
          onChange={handleChangeSearchCondition}
        />
        <SearchResultArea
          isLoading={isLoading}
          currentPageNumber={currentPageNumber}
          totalPageCount={totalPageCount}
          totalElements={totalElements}
          onPageChange={handleChangePage}
          upperRightElement={
            isApplicant && (
              <CMButton
                alignSelf={"flex-end"}
                size={"sm"}
                label={t("btn.新規申請を作成するボタン")}
                onClick={onOpen}
              />
            )
          }
        >
          <SearchResultTableDocument
            sx={{ flex: 1 }}
            entities={entities}
            isApplicant={isApplicant}
            isExecutiveDirector={isExecutiveDirector}
          />
        </SearchResultArea>
      </HStack>
      {/*新規申請作成の確認モーダル*/}
      <ConfirmationModal
        isOpen={isOpen}
        onCancel={onClose}
        message={t("mes.新規申請作成確認メッセージ")}
        onSubmit={handleSubmitCreateNewApplication}
      />
    </>
  );
};

/**
 * クエリパラメータから検索条件を作成する.
 */
function urlSearchParamsToSearchArgs(
  searchParams: URLSearchParams,
): SearchDocumentApiArg {
  const todosFirst =
    getBooleanFromSearchParams(searchParams, "todosFirst") ?? true;
  const sortCondition =
    getEnumFromSearchParams(
      searchParams,
      "sortCondition",
      documentSortConditionMeta,
    ) ?? "updated_at_desc";
  const limit =
    getNumberFromSearchParams(
      searchParams,
      "limit",
      searchConditionLimitMeta,
    ) ?? 10;

  const documentControlNumber = getStringFromSearchParams(
    searchParams,
    "documentControlNumber",
    documentControlNumberMeta.partial,
  );
  const documentType = getAllEnumFromSearchParams(
    searchParams,
    "documentType",
    documentTypeMeta,
  );
  const documentState = getAllEnumFromSearchParams(
    searchParams,
    "documentState",
    documentStateMeta,
  );
  const documentPosition = getAllEnumFromSearchParams(
    searchParams,
    "documentPosition",
    documentPositionMeta,
  );
  const reviewType = getAllEnumFromSearchParams(
    searchParams,
    "reviewType",
    reviewTypeMeta,
  );
  const result = getAllEnumFromSearchParams(searchParams, "result", resultMeta);
  const submissionDateFrom = getDateStringFromSearchParams(
    searchParams,
    "submissionDateFrom",
  );
  const submissionDateTo = getDateStringFromSearchParams(
    searchParams,
    "submissionDateTo",
  );
  const receptionDateFrom = getDateStringFromSearchParams(
    searchParams,
    "receptionDateFrom",
  );
  const receptionDateTo = getDateStringFromSearchParams(
    searchParams,
    "receptionDateTo",
  );
  const approvalDateFrom = getDateStringFromSearchParams(
    searchParams,
    "approvalDateFrom",
  );
  const approvalDateTo = getDateStringFromSearchParams(
    searchParams,
    "approvalDateTo",
  );
  const completionDateFrom = getDateStringFromSearchParams(
    searchParams,
    "completionDateFrom",
  );
  const completionDateTo = getDateStringFromSearchParams(
    searchParams,
    "completionDateTo",
  );

  const projectId = getNumberFromSearchParams(
    searchParams,
    "projectId",
    projectIdMeta,
  );
  const projectName = getStringFromSearchParams(
    searchParams,
    "projectName",
    projectNameMeta.partial,
  );
  const institutionId = getNumberFromSearchParams(
    searchParams,
    "institutionId",
    institutionIdMeta,
  );
  const principalInvestigatorAppUserId = getNumberFromSearchParams(
    searchParams,
    "principalInvestigatorAppUserId",
    appUserIdMeta,
  );

  const pageNumber = getNumberFromSearchParams(
    searchParams,
    "pageNumber",
    searchConditionPageNumberMeta,
  );

  return {
    todosFirst,
    sortCondition,
    limit,
    documentControlNumber,
    documentType,
    documentState,
    documentPosition,
    reviewType,
    result,
    submissionDateFrom,
    submissionDateTo,
    receptionDateFrom,
    receptionDateTo,
    approvalDateFrom,
    approvalDateTo,
    completionDateFrom,
    completionDateTo,
    projectId,
    projectName,
    institutionId,
    principalInvestigatorAppUserId,
    pageNumber,
  };
}
