import React, { ChangeEvent, memo, useCallback, useId } from "react";
import { ComponentStyleProps } from "../../../../../../../../../lib/styles/props/component-style-props";
import { FormControl, FormLabel, Switch } from "@chakra-ui/react";
import { useAppTranslation } from "../../../../../../../../../hooks/use-app-translation";

export type ToggleDisplayChangesSwitchProps = {
  isChecked: boolean;
  onChange?: (isChecked: boolean) => void;
} & ComponentStyleProps;
export const ToggleDisplayChangesSwitch = memo(
  function ToggleDisplayChangesSwitch({
    isChecked,
    onChange,

    sx,
    ...rest
  }: ToggleDisplayChangesSwitchProps) {
    const { t } = useAppTranslation();

    const id = useId();

    const handleChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        onChange?.(e.target.checked);
      },
      [onChange],
    );

    return (
      <FormControl
        display="flex"
        flexFlow={"row nowrap"}
        alignItems="center"
        sx={sx}
        {...rest}
      >
        <Switch id={id} isChecked={isChecked} onChange={handleChange} />
        <FormLabel
          htmlFor={id}
          whiteSpace={"nowrap"}
          mt={0}
          mr={0}
          mb={0}
          ml={"10px"}
        >
          {t("lbl.変更箇所のみ表示")}
        </FormLabel>
      </FormControl>
    );
  },
);
