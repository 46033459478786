import { useAppParams } from "../../../../hooks/use-app-params";
import { hasValue, isNullish } from "../../../../lib/util/common-util";
import { ChangeApplicationCreateConfirmPage } from "./_components/ChangeApplicationCreateConfirmPage/ChangeApplicationCreateConfirmPage";
import { useChangeApplicationReadonlyVm } from "../../../../hooks/document/change-application/use-change-application-readonly-vm";

export const FN40S07ChangeApplicationCreateConfirm = () => {
  const { projectId, documentId } = useAppParams();

  // const { changeApplication, initialChangeApplicationVM } =
  //   useChangeApplication(projectId ?? -1, documentId ?? -1, {
  //     skip: isNullish(projectId) || isNullish(documentId),
  //   });
  const { currentData, vm } = useChangeApplicationReadonlyVm(documentId, {
    skip: isNullish(projectId) || isNullish(documentId),
  });

  return (
    <>
      {hasValue(documentId) &&
        hasValue(vm) &&
        currentData?.documentState === "draft" && (
          <ChangeApplicationCreateConfirmPage documentId={documentId} vm={vm} />
        )}
    </>
  );
};
