import React, { useMemo } from "react";
import { HStack } from "@chakra-ui/react";
import { CMFormInputText } from "@pscsrvlab/psc-react-components";
import { executiveDirectorApprovalStateMeta } from "../../../../../../lib/object/value/executive-director-approval-state";
import { fullNameMeta } from "../../../../../../lib/object/value/full-name";
import { yearMonthDayMeta } from "../../../../../../lib/object/value/year-month-day";
import {
  getMessageFromEnumValue,
  isNullish,
} from "../../../../../../lib/util/common-util";
import { ymdToDateText } from "../../../../../../lib/util/common-date-util";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { FormSection } from "../../../../../ui/form/FormSection/FormSection";

export type executiveDirectorApprovalType = {
  executiveDirectorApprovalState?: "unapproved" | "approved";
  appUserId?: number;
  fullName?: string;
  approvalDate?: { year: number; month: number; day: number };
};

export type SectionDirectorApprovalProps = {
  value?: executiveDirectorApprovalType;
};

/**
 * FN40-S30審査
 * 業務執行理事承認セクション
 */
export const SectionDirectorApproval = ({
  value,
}: SectionDirectorApprovalProps) => {
  const { t } = useAppTranslation();

  /**
   * 承認ステータス
   */
  const state = useMemo(() => {
    if (isNullish(value)) {
      return "";
    }

    return getMessageFromEnumValue(
      t,
      executiveDirectorApprovalStateMeta,
      value.executiveDirectorApprovalState,
    );
  }, [t, value]);

  /**
   * 承認日
   */
  const date = useMemo(() => {
    if (isNullish(value) || isNullish(value.approvalDate)) {
      return "-";
    }

    return ymdToDateText(value.approvalDate);
  }, [value]);

  return (
    <FormSection title={t("lbl.業務執行理事承認")}>
      <HStack pl={"24px"} alignItems={"flex-start"}>
        <CMFormInputText
          editMode={"readOnly"}
          label={t("lbl.承認")}
          value={state ?? "-"}
          valueObjectMeta={executiveDirectorApprovalStateMeta}
        />
        <CMFormInputText
          editMode={"readOnly"}
          label={t("lbl.承認者名")}
          value={value?.fullName ?? "-"}
          valueObjectMeta={fullNameMeta}
        />
        <CMFormInputText
          editMode={"readOnly"}
          label={t("lbl.承認日")}
          value={date}
          valueObjectMeta={yearMonthDayMeta}
        />
      </HStack>
    </FormSection>
  );
};
