import {
  messageId,
  PropertyMeta,
  ValueObjectMetaString,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.string().max(10000);
const savedMinimalSchema = z.string().max(10000);
const schema = z.string().min(1).max(10000);
const savedSchema = z.string().min(1).max(10000);
const jsonSchema = z.string();
/**
 * 進捗状況・結果概要(値オブジェクト)のメタデータ。
 *
 *  */
export const progressResultOutlineMeta = new ValueObjectMetaString(
  "ProgressResultOutline",
  "\u9032\u6357\u72B6\u6CC1\u30FB\u7D50\u679C\u6982\u8981",
  messageId("lbl.\u9032\u6357\u72B6\u6CC1\u30FB\u7D50\u679C\u6982\u8981"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
