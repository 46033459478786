import { CMSelectableListItemWithBadge } from "@pscsrvlab/psc-react-components";
import { useCallback, useMemo } from "react";
import {
  getMessageFromEnumValue,
  hasValue,
  isNullish,
} from "../../../../../lib/util/common-util";
import { resultMeta } from "../../../../../lib/object/value/result";
import { FrameLeftSideMenu } from "../../../../ui/frame/FrameLeftSideMenu/FrameLeftSideMenu";
import { useAppTranslation } from "../../../../../hooks/use-app-translation";
import { useNavigate } from "react-router-dom";
import { useProjectPagePathPrefix } from "../../../../../hooks/use-project-page-path-prefix";
import { ComponentStyleProps } from "../../../../../lib/styles/props/component-style-props";
import { useAppListResultNotificationQuery } from "../../../../../hooks/query/use-app-list-result-notification-query";
import { SideMenuCreateButton } from "../../../../ui/button/SideMenuCreateButton/SideMenuCreateButton";
import { useCanCreateResultNotification } from "../../../../../hooks/review/use-can-create-result-notification";

export type ResultNotificationSideMenuProps = {
  documentId: number;
  selectedResultNotificationId?: number;
} & ComponentStyleProps;

/**
 * FN40S33 結果通知
 * サイドメニュー
 */
export const ResultNotificationSideMenu = ({
  documentId,
  selectedResultNotificationId,

  sx,
  ...rest
}: ResultNotificationSideMenuProps) => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const { projectPagePathPrefix } = useProjectPagePathPrefix();

  const { data: resultNotifications } = useAppListResultNotificationQuery({
    documentId,
  });

  /**
   * 一覧表示の項目を定義
   */
  const contentList = useMemo(() => {
    if (isNullish(resultNotifications)) return [];
    return resultNotifications
      .map((v, i) => {
        return {
          id: v.id,
          label: `${t("lbl.結果通知")}${i + 1}`,
          badgeText: getMessageFromEnumValue(t, resultMeta, v.result),
          badgeColor: v.result === "no_need_to_review" ? "gray" : "blue",
          backgroundColor: "gray.100",
        };
      })
      .filter(hasValue);
  }, [t, resultNotifications]);

  /**
   * 新規作成が可能な場合 true
   */
  const canCreate = useCanCreateResultNotification(documentId);

  const handleSelect = useCallback(
    (resultNotificationId: number) => {
      navigate(
        `${projectPagePathPrefix}/document/${documentId}/result-notification/${resultNotificationId}`,
      );
    },
    [navigate, documentId, projectPagePathPrefix],
  );

  const handleCreate = useCallback(() => {
    navigate(
      `${projectPagePathPrefix}/document/${documentId}/result-notification/create`,
    );
  }, [documentId, navigate, projectPagePathPrefix]);

  return (
    <FrameLeftSideMenu title={t("lbl.結果通知一覧")} sx={sx} {...rest}>
      {contentList.map((v) => (
        <CMSelectableListItemWithBadge
          key={v.id}
          label={v.label}
          selected={
            hasValue(selectedResultNotificationId) &&
            v.id === selectedResultNotificationId
          }
          badgeText={v.badgeText}
          badgeColor={v.badgeColor}
          onClick={() => handleSelect(v.id)}
        />
      ))}
      {canCreate && (
        <SideMenuCreateButton
          label={t("btn.結果通知を新規作成するボタン")}
          onClick={handleCreate}
        />
      )}
    </FrameLeftSideMenu>
  );
};
