import { LoginUserInfo } from "../../../../../../store/auth/types";
import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";
import React, { useCallback, useMemo } from "react";
import {
  Center,
  Container,
  HStack,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ProjectNameFormReportSection } from "../../_components/section/ProjectNameFormReportSection";
import { InstitutionNameReportSection } from "../../_components/section/InstitutionNameReportSection";
import { PrincipalInvestigatorReportSection } from "../../_components/section/PrincipalInvestigatorReportSection";
import { CellProvisionTypeReportSection } from "../../_components/section/CellProvisionTypeReportSection";
import { CellProvisionsReportSection } from "../../_components/section/CellProvisionsReportSection";
import { PartnersReportSection } from "../../_components/section/PartnersReportSection";
import { AttachmentFilesReportSection } from "../../_components/section/AttachmentFilesReportSection";
import { DifferentiatedCellProvision } from "../../../../../../lib/object/value/differentiated-cell-provision";
import { DifferentiatedCellProvisionType } from "../../../../../../lib/object/value/differentiated-cell-provision-type";
import { AttachmentFileInformation } from "../../../../../../lib/object/value/attachment-file-information";
import { ResearchResultOutlineTerminationReportSection } from "./_components/section/ResearchResultOutlineTerminationReportSection";
import { NewApplicationApprovalDateTerminationReportSection } from "./_components/section/NewApplicationApprovalDateTerminationReportSection";
import { UsageEndDateTerminationReportSection } from "./_components/section/UsageEndDateTerminationReportSection";
import { ProblemsDuringThePeriodTerminationReportSection } from "./_components/section/ProblemsDuringThePeriodTerminationReportSection";
import { HandlingAfterTerminationReportSection } from "./_components/section/HandlingAfterTerminationReportSection";
import { useTerminationReportDocumentCommentProps } from "./_hooks/use-termination-report-document-comment-props";
import { RevisionRequestItemViewModel } from "../../../../../../lib/object/vm/revision-request-view-model";
import {
  TerminationReportContentViewModel,
  TerminationReportViewModel,
} from "../../../../../../lib/object/vm/termination-report-view-model";
import { hasValue } from "../../../../../../lib/util/common-util";
import { ErrorMessageArea } from "../../../../../ui/form/ErrorMessageArea/ErrorMessageArea";
import { Comments, ValidationError } from "@pscsrvlab/psc-react-components";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { CollaborativePartnerOnReport } from "../../../../../../lib/object/value/collaborative-partner-on-report";
import { DocumentHeaderControlNumbers } from "../../../_components/DocumentHeaderControlNumbers/DocumentHeaderControlNumbers";
import { DocumentHeaderDates } from "../../../_components/DocumentHeaderDates/DocumentHeaderDates";
import useLinkCommentPath from "../../../../../../hooks/use-link-comment-path";

export type TerminationReportDocumentProps = {
  loginUserInfo: LoginUserInfo;

  editMode: "editable" | "readOnly";

  value: TerminationReportViewModel;
  onChange?: (
    change: (before: TerminationReportViewModel) => TerminationReportViewModel,
  ) => void;

  /**
   * 修正依頼。
   */
  revisionRequestItems?: RevisionRequestItemViewModel[];
  /**
   * 1書類単位の修正依頼のコメント・返信履歴（履歴参照用）
   */
  commentsList?: Comments[];

  /**
   * 修正依頼コメントを選択したときのコールバック。
   * 未選択状態になる場合はnullを送出する。
   */
  onSelectComment?: (path: string | null) => void;
  /**
   * 修正依頼の変更。
   */
  onChangeRevisionRequestItems?: (
    change: (
      before: RevisionRequestItemViewModel[],
    ) => RevisionRequestItemViewModel[],
  ) => void;

  revisionMode?:
  | "none"
  | "readOnly"
  | "office_member_editable"
  | "applicant_editable";

  /**
   * バリデーションエラー。
   * 編集画面でのみ必要。
   */
  validationErrors?: ValidationError[];
} & ComponentStyleProps;

export const TerminationReportDocument = ({
  loginUserInfo,

  editMode,

  value,
  onChange,

  revisionRequestItems,
  commentsList,
  onSelectComment,
  onChangeRevisionRequestItems,
  revisionMode = "none",

  validationErrors,

  sx,
  ...rest
}: TerminationReportDocumentProps) => {
  const { t } = useAppTranslation();

  const projectId = useMemo(() => value.projectId, [value.projectId]);

  const contentVM = useMemo(() => value.contentVM, [value.contentVM]);

  const handleChange = useCallback(
    (
      change: (
        before: TerminationReportContentViewModel,
      ) => TerminationReportContentViewModel,
    ) => {
      onChange?.((before) => {
        return {
          ...before,
          contentVM: change(before.contentVM),
        };
      });
    },
    [onChange],
  );

  const {
    handleChangeUsageEndDate,
    handleChangeProblemsDuringThePeriod,
    handleChangeHandlingAfterTermination,
    handleChangeCellProvisionType,
    handleChangeCellProvisions,
    handleChangePartners,
    handleChangeResearchResultOutline,
    handleChangeAttachmentFiles,
  } = useChangeHandlers(handleChange);

  const commentProps = useTerminationReportDocumentCommentProps(
    loginUserInfo,
    contentVM,
    revisionMode,
    revisionRequestItems,
    onSelectComment,
    onChangeRevisionRequestItems,
  );

  /**
   * commentsListを各セクションごとにフィルタリングする関数
   */
  const { filterCommetsListByEndWith, filterCommetsListByStartsWith } = useLinkCommentPath();

  return (
    <Container maxW={"720px"} px={0} sx={sx} {...rest}>
      <VStack justifyContent={"flex-start"} alignItems={"stretch"}>
        {hasValue(validationErrors) && validationErrors.length > 0 && (
          <VStack
            pt={"10px"}
            position={"sticky"}
            top={0}
            bgColor={"white"}
            zIndex={20}
          >
            <ErrorMessageArea validationErrors={validationErrors} />
          </VStack>
        )}
        <DocumentHeaderControlNumbers
          projectControlNumber={value.projectControlNumber}
          documentControlNumber={value.documentControlNumber}
        />
        <Center>
          <Text fontWeight={"bold"}>{t("lbl.使用終了報告タイトル")}</Text>
        </Center>
        <HStack>
          <Stack>
            <Text>{t("lbl.書類宛先")}</Text>
            <Text>{t("mes.使用終了報告宣誓")}</Text>
          </Stack>
          <DocumentHeaderDates
            submissionDate={value.submissionDate}
            receptionDate={value.receptionDate}
            approvalDate={value.approvalDate}
            documentCompletionDate={value.documentCompletionDate}
            sx={{ flex: "1 1 auto" }}
          />
        </HStack>
        <VStack
          alignItems={"stretch"}
          overflowX={"hidden"}
          overflowY={"hidden"}
        >
          <ProjectNameFormReportSection
            value={contentVM?.projectName}
            commentButtonProps={commentProps.projectName}
            //commentsListのpathとcommentPropsのidを比較して一致するものを取得
            commentsList={
              filterCommetsListByEndWith(
                commentsList,
                commentProps.projectName?.id
              )
            }
          />
          <InstitutionNameReportSection
            value={contentVM.institution}
            commentButtonProps={commentProps.institution}
            commentsList={
              filterCommetsListByEndWith(
                commentsList,
                commentProps.institution?.id
              )
            }
          />
          <PrincipalInvestigatorReportSection
            value={contentVM.principalInvestigator}
            commentButtonProps={commentProps.principalInvestigator}
            commentsList={
              filterCommetsListByEndWith(
                commentsList,
                commentProps.principalInvestigator?.id
              )
            }
          />
          <NewApplicationApprovalDateTerminationReportSection
            value={contentVM.newApplicationApprovalDate}
            commentButtonProps={commentProps.newApplicationApprovalDate}
            commentsList={
              filterCommetsListByEndWith(
                commentsList,
                commentProps.newApplicationApprovalDate?.id
              )
            }
          />
          <UsageEndDateTerminationReportSection
            value={{
              start: contentVM.newApplicationApprovalDate,
              end: contentVM.usageEndDate,
            }}
            editMode={editMode}
            onChange={handleChangeUsageEndDate}
            commentButtonProps={commentProps.usageEndDate}
            commentsList={
              filterCommetsListByEndWith(
                commentsList,
                commentProps.usageEndDate?.id
              )
            }
          />
          <ProblemsDuringThePeriodTerminationReportSection
            editMode={editMode}
            value={contentVM.problemsDuringThePeriod}
            onChange={handleChangeProblemsDuringThePeriod}
            commentButtonProps={commentProps.problemsDuringThePeriod}
            commentsList={
              filterCommetsListByEndWith(
                commentsList,
                commentProps.problemsDuringThePeriod?.id
              )
            }
          />
          <HandlingAfterTerminationReportSection
            editMode={editMode}
            value={contentVM.handlingAfterTermination}
            onChange={handleChangeHandlingAfterTermination}
            commentButtonProps={commentProps.handlingAfterTermination}
            commentsList={
              filterCommetsListByEndWith(
                commentsList,
                commentProps.handlingAfterTermination?.id
              )
            }
          />
          <CellProvisionTypeReportSection
            editMode={editMode}
            value={contentVM.cellProvisionType}
            onChange={handleChangeCellProvisionType}
            commentButtonProps={commentProps.cellProvisionType}
            commentsList={
              filterCommetsListByEndWith(
                commentsList,
                commentProps.cellProvisionType?.id
              )
            }
          />
          {contentVM.cellProvisionType === "provided" && (
            <CellProvisionsReportSection
              editMode={editMode}
              value={contentVM.cellProvisions}
              onChange={handleChangeCellProvisions}
              commentButtonProps={commentProps.cellProvisions}
              commentsList={
                filterCommetsListByEndWith(
                  commentsList,
                  commentProps.cellProvisions?.id
                )
              }
              commentButtonPropsChildren={commentProps.cellProvisionsN}
              commentsListChildren={
                filterCommetsListByStartsWith(
                  commentsList, "/07:cellProvisions/"
                )
              }
            />
          )}
          {contentVM.partner.length > 0 && (
            <PartnersReportSection
              editMode={editMode}
              value={contentVM.partner}
              onChange={handleChangePartners}
              commentButtonProps={commentProps.partner}
              commentsList={
                filterCommetsListByEndWith(
                  commentsList,
                  commentProps.partner?.id
                )
              }
              commentButtonPropsChildren={commentProps.partnerN}
              commentsListChildren={
                filterCommetsListByStartsWith(
                  commentsList, "/08:partner"
                )
              }
            />
          )}
          <ResearchResultOutlineTerminationReportSection
            editMode={editMode}
            value={contentVM.researchResultOutline}
            onChange={handleChangeResearchResultOutline}
            commentButtonProps={commentProps.researchResultOutline}
            commentsList={
              filterCommetsListByEndWith(
                commentsList,
                commentProps.researchResultOutline?.id
              )
            }
          />
          <AttachmentFilesReportSection
            editMode={editMode}
            projectId={projectId}
            value={contentVM.attachmentFiles}
            onChange={handleChangeAttachmentFiles}
            commentButtonProps={commentProps.attachmentFiles}
            commentsList={
              filterCommetsListByEndWith(
                commentsList,
                commentProps.attachmentFiles?.id
              )
            }
          />
        </VStack>
      </VStack>
    </Container>
  );
};

function useChangeHandlers(
  onChange?: (
    change: (
      before: TerminationReportContentViewModel,
    ) => TerminationReportContentViewModel,
  ) => void,
) {
  const handleChange = useCallback(
    (
      change: (
        before: TerminationReportContentViewModel,
      ) => TerminationReportContentViewModel,
    ) => {
      onChange?.(change);
    },
    [onChange],
  );

  const handleChangeUsageEndDate = useCallback(
    (v: { year: number; month: number; day: number } | undefined) =>
      handleChange((before) => ({
        ...before,
        usageEndDate: v,
      })),
    [handleChange],
  );

  const handleChangeProblemsDuringThePeriod = useCallback(
    (v: {
      problemPresence: "none" | "has_problem";
      problemDetails?: string | undefined;
      responseToProblem?: string | undefined;
    }) =>
      handleChange((before) => ({
        ...before,
        problemsDuringThePeriod: v,
      })),
    [handleChange],
  );

  const handleChangeHandlingAfterTermination = useCallback(
    (v: {
      planComplianceType: "yes" | "no";
      reasonsForNotBeingAbleToComply?: string | undefined;
    }) =>
      handleChange((before) => ({
        ...before,
        handlingAfterTermination: v,
      })),
    [handleChange],
  );

  const handleChangeCellProvisionType = useCallback(
    (v: DifferentiatedCellProvisionType) =>
      handleChange((before) => ({
        ...before,
        cellProvisionType: v,
      })),
    [handleChange],
  );

  const handleChangeCellProvisions = useCallback(
    (
      change: (
        before: DifferentiatedCellProvision[],
      ) => DifferentiatedCellProvision[],
    ) =>
      handleChange((before) => ({
        ...before,
        cellProvisions: change(before.cellProvisions),
      })),
    [handleChange],
  );

  const handleChangePartners = useCallback(
    (
      change: (
        before: Partial<CollaborativePartnerOnReport>[],
      ) => Partial<CollaborativePartnerOnReport>[],
    ) =>
      handleChange((before) => ({
        ...before,
        partner: change(before.partner),
      })),
    [handleChange],
  );

  const handleChangeResearchResultOutline = useCallback(
    (v: string) =>
      handleChange((before) => ({
        ...before,
        researchResultOutline: v,
      })),
    [handleChange],
  );

  const handleChangeAttachmentFiles = useCallback(
    (
      change: (
        before: AttachmentFileInformation[],
      ) => AttachmentFileInformation[],
    ) =>
      handleChange((before) => ({
        ...before,
        attachmentFiles: change(before.attachmentFiles),
      })),
    [handleChange],
  );

  return {
    handleChangeUsageEndDate,
    handleChangeProblemsDuringThePeriod,
    handleChangeHandlingAfterTermination,
    handleChangeCellProvisionType,
    handleChangeCellProvisions,
    handleChangePartners,
    handleChangeResearchResultOutline,
    handleChangeAttachmentFiles,
  };
}
