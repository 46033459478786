import {
  useCreateAppUserMutation,
  useDisableAppUserMutation,
  useEnableAppUserMutation,
  useResetMfaMutation,
  useResetTemporaryPasswordMutation,
  useUpdateAppUserMutation,
} from "../../../../store/api/generated/stock-request-api";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Center,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spacer,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { CMButton } from "@pscsrvlab/psc-react-components";
import { TitleText } from "../../../ui/text/TitleText/TitleText";
import { VerticalTableFrame } from "../../../ui/table/VerticalTableFrame/VerticalTableFrame";
import React, { useEffect, useMemo, useState } from "react";
import { ComponentStyleProps } from "../../../../lib/styles/props/component-style-props";
import { VerticalTableRowTextInput } from "../../../ui/table/VerticalTableRowTextInput/VerticalTableRowTextInput";
import { BackPageButton } from "../../../ui/button/BackPageButton/BackPageButton";
import useCustomToast from "../../../../hooks/use-custom-toast";
import {
  backendErrorToErrorMessage,
  errorMessageOf,
} from "../../../../lib/util/error-util";
import { WeakTextItem } from "../../../ui/text/WeakTextItem/WeakTextItem";
import {
  getMessageFromEnumValue,
  hasValue,
  isNullish,
} from "../../../../lib/util/common-util";
import { appUserMeta } from "../../../../lib/object/entity/app-user";
import { jsDateToDatetimeText } from "../../../../lib/util/common-date-util";
import { VerticalTableRowDropDown } from "../../../ui/table/VerticalTableRowDropDown/VerticalTableRowDropDown";
import { VerticalTableRowInstitution } from "../../../ui/table/VerticalTableRowInstitution/VerticalTableRowInstitution";
import { Language, languageMeta } from "../../../../lib/object/value/language";
import { accountStateMeta } from "../../../../lib/object/value/account-state";
import { FrameUpperRightButton } from "../../../ui/frame/FrameUpperRightButton/FrameUpperRightButton";
import { useSelector } from "react-redux";
import { selectHasRole, selectUserInfo } from "../../../../store/auth/slice";
import { jsonPatchOperationMeta } from "../../../../lib/object/value/json-patch-operation";
import { Operation } from "fast-json-patch/commonjs/core";
import { Role, roleMeta } from "../../../../lib/object/value/role";
import { useAppTranslation } from "../../../../hooks/use-app-translation";
import { useAppGetAppUserQuery } from "../../../../hooks/query/use-app-get-app-user-query";
import { useAppParams } from "../../../../hooks/use-app-params";
import {
  MailNotification,
  mailNotificationMeta,
} from "../../../../lib/object/value/mail-notification";
import { EditButton } from "../../../ui/button/EditButton/EditButton";
import { SaveChangesButton } from "../../../ui/button/SaveChangesButton/SaveChangesButton";
import { EditEndButton } from "../../../ui/button/EditEndButton/EditEndButton";
import { ConfirmationModal } from "../../../ui/modal/ConfirmationModal/ConfirmationModal";
import { useLeaveEditingPrompt } from "../../../../hooks/use-leave-editing-prompt";
import log from "loglevel"; // 表示モード（新規作成・参照・編集）

// 表示モード（新規作成・参照・編集）
type Mode = "create" | "reference" | "update";

// 確認モーダルの種別
type ModalType =
  | "resetTempPassword"
  | "resetMfa"
  | "enableAccount"
  | "disableAccount"
  | "createAppUser"
  | "updateAppUser"
  | "notEdited";

export type UserDetailProps = {
  createMode?: boolean;
} & ComponentStyleProps;

/**
 * ユーザー詳細画面（新規作成・参照・編集共通）
 */
export const UserDetail = ({
  createMode = false,
  sx,
  ...rest
}: UserDetailProps) => {
  const { t } = useAppTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const { successToast, errorToast, validationErrorToast } = useCustomToast();

  // ユーザー情報（入力情報）を保持
  const [role, setRole] = useState<Role>("applicant");
  const [fullName, setFullName] = useState("");
  const [fullNameKana, setFullNameKana] = useState("");
  const [institutionId, setInstitutionId] = useState<number>();
  const [titleAndPosition, setTitleAndPosition] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [mailAddress, setMailAddress] = useState("");
  const [language, setLanguage] = useState<Language>("ja");
  const [mailNotification, setMailNotification] =
    useState<MailNotification>("notification");

  // 表示モード（新規作成、参照、編集）
  const [mode, setMode] = useState<Mode>(createMode ? "create" : "reference");
  // 確認モーダルの種別
  const [modalType, setModalType] = useState<ModalType>();

  const { navigateWithoutPrompt } = useLeaveEditingPrompt({
    skip: mode === "reference",
  });

  // URLパラメーターからuserIdを取得
  const { appUserId } = useAppParams();

  const { isOfficeMember } = useSelector(selectHasRole);

  // ログインユーザー情報を取得
  const loginUser = useSelector(selectUserInfo);

  // ユーザー情報を取得
  const { data: appUser, error: appUserError } = useAppGetAppUserQuery(
    {
      appUserId: appUserId ?? -1,
    },
    { skip: isNullish(appUserId) },
  );

  // ユーザー管理機能の各ミューテーション
  const [createAppUser] = useCreateAppUserMutation();
  const [updateAppUser] = useUpdateAppUserMutation();
  const [resetTempPassword] = useResetTemporaryPasswordMutation();
  const [resetMfa] = useResetMfaMutation();
  const [enableAppUser] = useEnableAppUserMutation();
  const [disableAppUser] = useDisableAppUserMutation();

  // 登録済みのユーザー情報が取得できた場合はStateへ上書き
  useEffect(() => {
    if (hasValue(appUser)) {
      setRole(appUser.role);
      setFullName(appUser.fullName);
      setFullNameKana(appUser.fullNameKana);
      setInstitutionId(appUser.institutionId);
      setTitleAndPosition(appUser.titleAndPosition);
      setPhoneNumber(appUser.phoneNumber);
      setMailAddress(appUser.mailAddress);
      setLanguage(appUser.language);
      setMailNotification(appUser.mailNotification);
    }
  }, [appUser]);

  // 新規作成時以外でURLのパスに正しいユーザーIDが指定されていない場合はエラーページへ遷移
  useEffect(() => {
    if (mode !== "create" && isNullish(appUserId)) {
      navigateWithoutPrompt("/error/404").then();
    }
  }, [appUserId, navigateWithoutPrompt, mode]);

  // 指定されたユーザーが見つからない場合（バックエンドから404が返された場合）はエラーページへ遷移
  useEffect(() => {
    if (hasValue(appUserError) && "status" in appUserError) {
      if (appUserError.status === 404) {
        navigateWithoutPrompt("/error/404").then();
      }
    }
  }, [appUserError, navigateWithoutPrompt]);

  // フォーマットした作成日時
  const createdAt = useMemo(() => {
    if (appUser?.created?.datetime) {
      return jsDateToDatetimeText(appUser.created.datetime);
    }
  }, [appUser]);

  // フォーマットした更新日時
  const updatedAt = useMemo(() => {
    if (appUser?.updated?.datetime) {
      return jsDateToDatetimeText(appUser.updated.datetime);
    }
  }, [appUser]);

  // 確認モーダルに表示するメッセージ
  const modalMessage = useMemo(() => {
    switch (modalType) {
      case "resetTempPassword":
        return t("mes.仮パスワード発行確認メッセージ");
      case "resetMfa":
        return t("mes.二段階認証リセット確認メッセージ");
      case "disableAccount":
        return t("mes.アカウント停止確認メッセージ");
      case "enableAccount":
        return t("mes.アカウント有効化確認メッセージ");
      case "createAppUser":
        return t("mes.ユーザー作成確認メッセージ");
      case "updateAppUser":
        return t("mes.変更保存確認メッセージ");
      case "notEdited":
        return t("mes.変更内容破棄メッセージ");
    }
    return "";
  }, [modalType, t]);

  // 自分自身を表示しているかどうか
  const isShowOwn = useMemo(() => {
    return hasValue(appUser?.id) && appUser?.id === loginUser?.id;
  }, [appUser?.id, loginUser?.id]);

  // ロールのドロップダウンリスト項目
  const roleOptions = useMemo(() => {
    if (role === "applicant") {
      return [{ label: t(roleMeta.dict["applicant"]), value: "applicant" }];
    } else {
      return [
        {
          label: t(roleMeta.dict["office_member"]),
          value: "office_member",
        },
        {
          label: t(roleMeta.dict["committee_member"]),
          value: "committee_member",
        },
        {
          label: t(roleMeta.dict["executive_director"]),
          value: "executive_director",
        },
        {
          label: t(roleMeta.dict["internal"]),
          value: "internal",
        },
      ];
    }
  }, [role, t]);

  // ロールの変更可否
  const editableRole = useMemo(() => {
    // 編集モード以外の場合は変更不可
    if (mode !== "update") {
      return "readOnly";
    }
    // 編集対象のユーザーが申請者の場合は変更不可
    if (appUser?.role === "applicant") {
      return "readOnly";
    }
    // ログイン中のユーザーが自分自身を編集する場合は変更不可
    if (isShowOwn) {
      return "readOnly";
    }
    // 上記以外は変更可
    return "editable";
  }, [appUser?.role, isShowOwn, mode]);

  // 確認モーダルの表示イベント
  const handleOpenModal = (state: ModalType) => {
    setModalType(state);
    onOpen();
  };

  // 照会画面へ戻るボタン、編集終了ボタンが押された際、入力フォームをリセット
  const resetForm = () => {
    setRole(appUser?.role ?? "applicant");
    setFullName(appUser?.fullName ?? "");
    setFullNameKana(appUser?.fullNameKana ?? "");
    setInstitutionId(appUser?.institutionId);
    setTitleAndPosition(appUser?.titleAndPosition ?? "");
    setPhoneNumber(appUser?.phoneNumber ?? "");
    setMailAddress(appUser?.mailAddress ?? "");
    setLanguage(appUser?.language ?? "ja");
    setMailNotification(appUser?.mailNotification ?? "notification");
  };

  // 確認モーダルの確定ボタン押下時のイベント
  const handleModalSubmit = async () => {
    try {
      // 確認モーダルの種別によって各処理を実施
      switch (modalType) {
        // 仮パスワード再発行
        case "resetTempPassword":
          if (isNullish(appUser) || isNullish(appUser.id)) return;
          await resetTempPassword({ appUserId: appUser.id }).unwrap();
          successToast(t("mes.仮パスワード再発行メッセージ"));
          break;

        // 二段階認証リセット
        case "resetMfa":
          if (isNullish(appUser) || isNullish(appUser.id)) return;
          await resetMfa({ appUserId: appUser.id }).unwrap();
          successToast(t("mes.二段階認証リセットメッセージ"));
          break;

        // ユーザーアカウント利用停止
        case "disableAccount":
          if (isNullish(appUser) || isNullish(appUser.id)) return;
          await disableAppUser({ appUserId: appUser.id }).unwrap();
          successToast(t("mes.アカウント利用停止メッセージ"));
          break;

        // ユーザーアカウント有効化
        case "enableAccount":
          if (isNullish(appUser) || isNullish(appUser.id)) return;
          await enableAppUser({ appUserId: appUser.id }).unwrap();
          successToast(t("mes.アカウント有効化メッセージ"));
          break;

        // ユーザー新規作成
        case "createAppUser": {
          // バリデーションチェック
          const validatedUser = appUserMeta.validate({
            role: role,
            fullName: fullName,
            fullNameKana: fullNameKana,
            institutionId: institutionId,
            titleAndPosition: titleAndPosition,
            phoneNumber: phoneNumber,
            mailAddress: mailAddress,
            language: language,
            mailNotification: mailNotification,
            accountState: "enabled",
            created: {},
            updated: {},
          });

          // バリデーションエラーが存在する場合
          if (validatedUser.state !== "ok") {
            validationErrorToast(validatedUser.errors);
            return;
          }

          // 更新後のユーザー情報オブジェクトを作成
          const newAppUser = appUserMeta.toJsonObjectOrNull(
            validatedUser.value,
          );
          if (isNullish(newAppUser)) {
            errorToast(t("mes.汎用エラーメッセージ"));
            return;
          }

          // ユーザー情報を作成
          await createAppUser({ appUser: newAppUser }).unwrap();
          successToast(t("mes.ユーザー登録完了メッセージ"));
          await navigateWithoutPrompt("/user");
          return;
        }

        // ユーザー情報更新
        case "updateAppUser": {
          if (isNullish(appUser) || isNullish(appUser.id)) return;
          // バリデーションチェック
          const validatedUser = appUserMeta.validate({
            id: appUser.id,
            displayUserId: appUser.displayUserId,
            cognitoUsername: appUser.cognitoUsername,
            role: role,
            fullName: fullName,
            fullNameKana: fullNameKana,
            institutionId: institutionId,
            titleAndPosition: titleAndPosition,
            phoneNumber: phoneNumber,
            mailAddress: appUser.mailAddress,
            accountState: appUser.accountState,
            language: appUser.language,
            mailNotification: mailNotification,
            created: {},
            updated: {},
          });
          // バリデーションエラーが存在する場合
          if (validatedUser.state !== "ok") {
            validationErrorToast(validatedUser.errors);
            return;
          }
          // 更新後のユーザー情報オブジェクトを作成
          const updatedAppUser = appUserMeta.toDomainObjectOrNull(
            validatedUser.value,
          );
          if (isNullish(updatedAppUser)) {
            errorToast(t("mes.汎用エラーメッセージ"));
            return;
          }

          // JSONパッチを作成
          const patchOperations = appUserMeta
            .toJsonPatchOperationsOrNull(appUser, updatedAppUser)
            ?.map((operation: Operation) => {
              return jsonPatchOperationMeta.toJsonObjectOrNull(operation);
            })
            .filter(hasValue);

          // ユーザー情報を更新
          await updateAppUser({
            appUserId: appUser.id,
            commonUpdateRequest: {
              patchOperations: patchOperations,
            },
          }).unwrap();

          successToast(t("mes.変更保存メッセージ"));
          setMode("reference");
          break;
        }

        // 変更無し
        case "notEdited":
          resetForm();
          setMode("reference");
          break;
        default:
          break;
      }
    } catch (e) {
      log.error(errorMessageOf(e));
      errorToast(backendErrorToErrorMessage(t, e));
    } finally {
      // finally を使用して必ずモーダルの種別をリセットする
      // ※ 入力内容破棄の確認メッセージ表示の条件に使用する為
      setModalType(undefined);
    }
  };

  // メール通知有無のドロップダウンリスト項目
  const mainNotificationOptions = useMemo(() => {
    return [
      ...Object.keys(mailNotificationMeta.dict)
        .map((value) => {
          const mainNotification = mailNotificationMeta.validate(value);
          if (mainNotification.state === "ok") {
            return {
              label:
                getMessageFromEnumValue(
                  t,
                  mailNotificationMeta,
                  mainNotification.value,
                ) ?? "",
              value: mainNotification.value,
            };
          }
        })
        .filter(hasValue),
    ];
  }, [t]);

  return (
    <>
      <Box w={"100%"} sx={sx} {...rest}>
        <>
          <Flex
            pl={"32px"}
            pr={"11px"}
            pt={"25px"}
            justifyContent={"space-between"}
          >
            {(mode === "create" || mode === "reference") && (
              <BackPageButton
                label={t("btn.一覧に戻るボタン")}
                onClick={() => navigate("/user")}
              />
            )}
            {mode === "update" && (
              <BackPageButton
                label={t("btn.照会画面に戻るボタン")}
                onClick={() => handleOpenModal("notEdited")}
              />
            )}

            {mode !== "create" && isOfficeMember && (
              <FrameUpperRightButton>
                {mode === "reference" && (
                  <>
                    <EditButton onClick={() => setMode("update")} />
                    {!isShowOwn && (
                      <Menu>
                        <MenuButton
                          h={"32px"}
                          w={"32px"}
                          minW={"0px"}
                          as={Button}
                          bgColor={"gray.200"}
                          _hover={{ bgColor: "gray.300" }}
                          _active={{ bgColor: "gray.200" }}
                          ml={"15px"}
                          px={0}
                          fontSize={"14px"}
                          color={"gray.700"}
                        >
                          {"･･･"}
                        </MenuButton>
                        <MenuList
                          border={"1px solid"}
                          borderColor={"gray.400"}
                          bgColor={"white"}
                          p={"4px"}
                          fontSize={"16px"}
                          color={"gray.900"}
                        >
                          {appUser?.role === "applicant" && (
                            <>
                              <MenuItem
                                onClick={() =>
                                  handleOpenModal("resetTempPassword")
                                }
                              >
                                {t("btn.仮パスワードを発行するボタン")}
                              </MenuItem>
                              <MenuDivider />
                              <MenuItem
                                onClick={() => handleOpenModal("resetMfa")}
                              >
                                {t("btn.二段階認証設定をリセットするボタン")}
                              </MenuItem>
                            </>
                          )}
                          <MenuDivider />
                          {appUser?.accountState === "enabled" && (
                            <MenuItem
                              color={"red.500"}
                              onClick={() => handleOpenModal("disableAccount")}
                            >
                              {t("btn.アカウントを利用停止にするボタン")}
                            </MenuItem>
                          )}
                          {appUser?.accountState === "disabled" && (
                            <MenuItem
                              color={"red.500"}
                              onClick={() => handleOpenModal("enableAccount")}
                            >
                              {t("btn.アカウントを再有効化するボタン")}
                            </MenuItem>
                          )}
                        </MenuList>
                      </Menu>
                    )}
                  </>
                )}
                {mode === "update" && (
                  <>
                    <SaveChangesButton
                      onClick={() => handleOpenModal("updateAppUser")}
                    />
                    <EditEndButton
                      ml={"5px"}
                      onClick={() => handleOpenModal("notEdited")}
                    />
                  </>
                )}
              </FrameUpperRightButton>
            )}
          </Flex>

          <Center>
            <VStack spacing={"unset"} maxW={"540px"} mb={"68px"}>
              {mode === "create" && (
                <TitleText
                  title={t("lbl.ユーザー詳細(登録)画面タイトル")}
                  sx={{ fontSize: "30px", mb: "28px" }}
                />
              )}
              {mode !== "create" && (
                <Flex w={"100%"} pt={"37px"} pb={"7px"}>
                  <Box>
                    <TitleText title={appUser?.fullName} />
                    <WeakTextItem
                      label={t("lbl.ユーザーID")}
                      value={appUser?.displayUserId}
                    />
                  </Box>
                  <Spacer />
                  <VStack spacing={"unset"} justifyContent={"end"}>
                    <WeakTextItem label={t("lbl.作成日時")} value={createdAt} />
                    <WeakTextItem label={t("lbl.更新日時")} value={updatedAt} />
                  </VStack>
                </Flex>
              )}
              <VerticalTableFrame>
                <VerticalTableRowDropDown
                  label={t("lbl.ロール")}
                  editMode={editableRole}
                  value={role}
                  options={roleOptions}
                  onChange={(value) => setRole(value)}
                />
                <VerticalTableRowTextInput
                  label={t("lbl.氏名")}
                  editMode={mode === "reference" ? "readOnly" : "editable"}
                  value={fullName}
                  onChange={(value) => setFullName(value)}
                />
                <VerticalTableRowTextInput
                  label={t("lbl.氏名かな")}
                  editMode={mode === "reference" ? "readOnly" : "editable"}
                  value={fullNameKana}
                  onChange={(value) => setFullNameKana(value)}
                />
                <VerticalTableRowInstitution
                  label={t("lbl.機関名")}
                  editMode={mode === "create" ? "editable" : "readOnly"}
                  value={institutionId}
                  onSelect={(value) => setInstitutionId(value)}
                />
                <VerticalTableRowTextInput
                  label={t("lbl.所属・職名")}
                  editMode={mode === "reference" ? "readOnly" : "editable"}
                  value={titleAndPosition}
                  onChange={(value) => setTitleAndPosition(value)}
                />
                <VerticalTableRowTextInput
                  label={t("lbl.電話番号")}
                  editMode={mode === "reference" ? "readOnly" : "editable"}
                  value={phoneNumber}
                  onChange={(value) => setPhoneNumber(value)}
                />
                <VerticalTableRowTextInput
                  label={t("lbl.メールアドレス")}
                  editMode={mode === "create" ? "editable" : "readOnly"}
                  value={mailAddress}
                  onChange={(value) => setMailAddress(value)}
                  attentionLabel={
                    mode === "create"
                      ? t("gdc.メールアドレスインフォメーション")
                      : undefined
                  }
                />
                <VerticalTableRowDropDown<MailNotification>
                  label={t("lbl.メール通知")}
                  editMode={mode === "reference" ? "readOnly" : "editable"}
                  value={mailNotification}
                  options={mainNotificationOptions}
                  onChange={setMailNotification}
                />
                {mode !== "create" && (
                  <VerticalTableRowTextInput
                    label={t("lbl.アカウント状態")}
                    editMode={"readOnly"}
                    value={getMessageFromEnumValue(
                      t,
                      accountStateMeta,
                      appUser?.accountState,
                    )}
                  />
                )}
                <VerticalTableRowTextInput
                  label={t("lbl.言語")}
                  editMode={"readOnly"}
                  value={getMessageFromEnumValue(t, languageMeta, language)}
                />
              </VerticalTableFrame>
              {mode === "create" && (
                <Box>
                  <CMButton
                    label={t("btn.ユーザーを登録するボタン")}
                    onClick={() => handleOpenModal("createAppUser")}
                    sx={{ mt: "36px" }}
                  />
                </Box>
              )}
            </VStack>
          </Center>
        </>
        <ConfirmationModal
          isOpen={isOpen}
          message={modalMessage}
          onSubmit={handleModalSubmit}
          onCancel={onClose}
        />
      </Box>
    </>
  );
};
