import { ComponentStyleProps } from "../../../../lib/styles/props/component-style-props";
import { Flex, HStack, Text, VStack } from "@chakra-ui/react";
import { useCallback, useMemo, useState } from "react";
import { Icon } from "@chakra-ui/icons";
import { IoMdMail } from "react-icons/io";
import { MdReply } from "react-icons/md";
import { useAppGetRevisionRequestQuery } from "../../../../hooks/query/use-app-get-revision-request-query";
import { hasValue, isNullish } from "../../../../lib/util/common-util";
import { jsDateToDatetimeText } from "../../../../lib/util/common-date-util";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import { selectUserInfo } from "../../../../store/auth/slice";
import { useAppTranslation } from "../../../../hooks/use-app-translation";

export type RevisionMainCommentBalloonProps = {
  mode: "request" | "reply";
  revisionRequestId: number;

  /**
   * trueなら、
   * - ユーザー名が取得できない場合、ログインユーザーの名前を使用する。
   * - 日時が取得できない場合、現在日時を使用する。
   *
   * ※不要かも。
   */
  substituteUserNameAndDatetime?: boolean;
} & ComponentStyleProps;

/**
 * 修正依頼のメインコメント（依頼本文・返信本文）枠。
 * 表示すべきものが取得できなければ何も表示しない。
 */
export const RevisionMainCommentBalloon = ({
  mode,
  revisionRequestId,

  substituteUserNameAndDatetime = false,

  sx,
  ...rest
}: RevisionMainCommentBalloonProps) => {
  const { t } = useAppTranslation();

  const loginUserInfo = useAppSelector(selectUserInfo);
  const { data: revisionRequest } = useAppGetRevisionRequestQuery({
    revisionRequestId,
  });

  const icon = useMemo(() => (mode === "request" ? IoMdMail : MdReply), [mode]);
  const title = useMemo(
    () =>
      mode === "request"
        ? t("lbl.修正依頼本文タイトル")
        : t("lbl.返信本文タイトル"),
    [mode, t],
  );
  const userName = useMemo(() => {
    if (mode === "request") return t("lbl.事務局");
    if (
      isNullish(revisionRequest) ||
      isNullish(revisionRequest.revisionRequestReplier)
    ) {
      if (substituteUserNameAndDatetime && hasValue(loginUserInfo)) {
        return loginUserInfo.fullName;
      } else {
        return undefined;
      }
    }

    return revisionRequest.revisionRequestReplier.fullName;
  }, [loginUserInfo, mode, revisionRequest, substituteUserNameAndDatetime, t]);

  const datetimeText = useMemo(() => {
    if (isNullish(revisionRequest)) return undefined;
    const datetime =
      mode === "request"
        ? revisionRequest.revisionRequestDatetime
        : revisionRequest.revisionRequestReplyDatetime;
    if (hasValue(datetime)) return jsDateToDatetimeText(datetime);
    if (substituteUserNameAndDatetime) return jsDateToDatetimeText(new Date());
    return undefined;
  }, [mode, revisionRequest, substituteUserNameAndDatetime]);

  const text = useMemo(() => {
    if (isNullish(revisionRequest)) return undefined;
    if (mode === "request") return revisionRequest.body;
    else return revisionRequest.reply;
  }, [mode, revisionRequest]);

  const [folded, setFolded] = useState(true);
  const toggleFolded = useCallback(() => {
    setFolded((v) => !v);
  }, []);
  const lineClampStyles = useMemo(() => {
    if (folded) {
      return {
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: "1",
      };
    }
    return {};
  }, [folded]);

  return (
    <>
      {hasValue(userName) && hasValue(datetimeText) && hasValue(text) && (
        <Flex
          direction={"column"}
          minW={"100px"}
          w={"250px"}
          bgColor={"orange.100"}
          color={"gray.900"}
          alignItems={"stretch"}
          overflow={"hidden"}
          borderWidth={"2px"}
          borderRadius={"4px"}
          borderColor={"orange.500"}
          px={"6px"}
          py={"5px"}
          zIndex={21}
          onClick={toggleFolded}
          sx={sx}
          {...rest}
        >
          <HStack>
            <Icon boxSize={"20px"} as={icon} color={"orange.500"} />
            <Text fontSize={"sm"} fontWeight={"bold"}>
              {title}
            </Text>
          </HStack>
          <HStack pl={"28px"}>
            <Text fontSize={"sm"} fontWeight={"bold"}>
              {userName}
            </Text>
            <Text fontSize={"xs"} color={"gray.500"}>
              {datetimeText}
            </Text>
          </HStack>
          <VStack alignItems={"flex-start"} px={"4px"}>
            <Text
              fontSize={"sm"}
              textOverflow={"ellipsis"}
              whiteSpace={"pre-wrap"}
              display={"-webkit-box"}
              sx={lineClampStyles}
            >
              {text}
            </Text>
          </VStack>
        </Flex>
      )}
    </>
  );
};
