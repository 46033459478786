import { ComponentStyleProps } from "../../../../../../../../lib/styles/props/component-style-props";

import useCustomToast from "../../../../../../../../hooks/use-custom-toast";
import {
  hasValue,
  isNullish,
} from "../../../../../../../../lib/util/common-util";
import { stockRequestApi } from "../../../../../../../../store/api/enhanced-api";
import {
  CMFormInputSearchAndSelect,
  SearchDropdownDisplayValue,
} from "@pscsrvlab/psc-react-components";
import { useCallback } from "react";
import log from "loglevel";
import { errorMessageOf } from "../../../../../../../../lib/util/error-util";
import { VStack } from "@chakra-ui/react";
import { InstitutionInformation } from "../../../../../../../../lib/object/value/institution-information";
import { institutionMeta } from "../../../../../../../../lib/object/entity/institution";
import { useAppTranslation } from "../../../../../../../../hooks/use-app-translation";

export type ApplicationInstitutionSelectProps = {
  editMode: "editable" | "readOnly";

  /**
   * 値。ユーザーID。
   */
  value: number | null;

  onChange?: (value: InstitutionInformation | null) => void;
} & ComponentStyleProps;

export const ApplicationInstitutionSelect = ({
  editMode,

  value,

  onChange,

  sx,
  ...rest
}: ApplicationInstitutionSelectProps) => {
  const { t } = useAppTranslation();
  const { errorToast } = useCustomToast();

  const [triggerGetInstitutionListQuery] =
    stockRequestApi.useLazyListInstitutionQuery();

  const [triggerGetInstitutionQuery] =
    stockRequestApi.useLazyGetInstitutionQuery();

  const toDisplayValue: (value: number) => Promise<SearchDropdownDisplayValue> =
    useCallback(
      async (value) => {
        try {
          const institution = await triggerGetInstitutionQuery(
            {
              institutionId: value,
            },
            true,
          ).unwrap();
          return {
            headingLabel: institution.displayInstitutionId,
            mainLabel: institution.name ?? "",
          };
        } catch (e) {
          log.error(errorMessageOf(e));
          return { headingLabel: undefined, mainLabel: "" };
        }
      },
      [triggerGetInstitutionQuery],
    );

  const handleSearch: (text: string) => Promise<number[]> = useCallback(
    async (text: string) => {
      try {
        const selectableInstitutions: number[] = (
          await triggerGetInstitutionListQuery({
            institutionName: text,
          }).unwrap()
        )
          .map((v) => v?.id)
          .filter(hasValue);
        return selectableInstitutions;
      } catch (e) {
        log.error(errorMessageOf(e));
        errorToast(t("mes.汎用エラーメッセージ"));
        return [];
      }
    },
    [errorToast, t, triggerGetInstitutionListQuery],
  );

  const handleChange = useCallback(
    async (value: number | null) => {
      if (isNullish(value)) {
        onChange?.(null);
        return;
      }
      try {
        const institutionData = await triggerGetInstitutionQuery({
          institutionId: value,
        }).unwrap();
        const institution =
          institutionMeta.toSavedDomainObjectOrNull(institutionData);
        if (isNullish(institution)) {
          onChange?.(null);
          return;
        }
        onChange?.({
          institutionId: institution.id,
          displayInstitutionId: institution.displayInstitutionId,
          institutionName: institution.name,
          institutionNameKana: institution.nameKana,
          foreignType: institution.foreignType,
          commercialType: institution.commercialType,
        });
      } catch (e) {
        log.error(errorMessageOf(e));
        onChange?.(null);
      }
    },
    [onChange, triggerGetInstitutionQuery],
  );

  return (
    <VStack sx={sx} {...rest} alignItems={"flex-start"}>
      {editMode === "editable" && (
        <CMFormInputSearchAndSelect<number>
          label={t("lbl.機関名")}
          value={value}
          labelSubmit={t("btn.確定ボタン")}
          labelClear={t("btn.クリアボタン")}
          placeholder={t("lbl.選択プルダウンプレースホルダー")}
          placeholderPopover={t("lbl.選択プルダウンプレースホルダー")}
          popoverTitle={t("mes.機関選択案内メッセージ")}
          searchResultNone={t("mes.該当機関なしメッセージ")}
          toDisplayValue={toDisplayValue}
          menuShowMaxCount={30}
          onSearch={handleSearch}
          onChange={handleChange}
          usePortal={false}
          minW={"200px"}
          w={"max-content"}
          maxW={"300px"}
        />
      )}
    </VStack>
  );
};
