import { CMPagination } from "@pscsrvlab/psc-react-components";
import React, { useCallback } from "react";
import { ComponentStyleProps } from "../../../../lib/styles/props/component-style-props";
import { useAppTranslation } from "../../../../hooks/use-app-translation";

export type PaginationProps = {
  /**
   * ページの総数。内部の計算用
   */
  totalPageCount: number;

  /**
   * 現在のページ番号として使用
   */
  currentPageNumber: number;

  /**
   * 表示するページ数
   */
  marginPagesDisplayed?: number;

  onPageChange?: (selectedPageNumber: number) => void;
} & ComponentStyleProps;

export const Pagination = ({
  totalPageCount,
  currentPageNumber,
  marginPagesDisplayed = 10,
  onPageChange,

  sx,
  ...rest
}: PaginationProps) => {
  const { t } = useAppTranslation();

  const labelPrevious = t("btn.前へページリンク");
  const labelNext = t("btn.次へページリンク");

  const handlePageChange = useCallback(
    (selectedPageNumber: number) => {
      onPageChange?.(selectedPageNumber);
    },
    [onPageChange],
  );

  return (
    <CMPagination
      pageCount={totalPageCount}
      marginPagesDisplayed={marginPagesDisplayed}
      currentPageNumber={currentPageNumber}
      labelPrevious={labelPrevious}
      labelNext={labelNext}
      onPageChange={handlePageChange}
      sx={sx}
      {...rest}
    />
  );
};
