import {
  AuthenticationStatus,
  AuthState,
  LoginUserInfo,
  SignInParameter,
  ThunkAPI,
  UserSession,
} from "./types";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { Auth, CognitoUser } from "@aws-amplify/auth";
import { RootState } from "../index";
import { stockRequestApi } from "../api/enhanced-api";
import { hasValue, isNullish } from "../../lib/util/common-util";
import { Role } from "../../lib/object/value/role";
import log from "loglevel";
import { errorNameAndMessageOf } from "../../lib/util/error-util";
import { ICredentials } from "aws-amplify/lib/Common/types/types";
import { appUserMeta } from "../../lib/object/entity/app-user";

const initialState: AuthState = {
  initialized: false,
  cognitoUser: undefined,
  userInfo: undefined,
  enabledMfa: false,
  signInInfo: {
    challengeName: undefined,
  },
  setupTotpState: {
    isFetching: false,
    data: undefined,
  },
};

// ユーザー情報リフレッシュ
export const refreshCognitoUser = createAsyncThunk<UserSession, void, ThunkAPI>(
  "auth/refreshCognitoUser",
  async (_, thunkAPI) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const session = user.getSignInUserSession();
      return {
        cognitoUsername: session?.isValid() ? user.getUsername() : undefined,
      };
    } catch (e) {
      return thunkAPI.rejectWithValue(errorNameAndMessageOf(e));
    }
  },
);

// ログイン（事務局）
export const federatedSignIn = createAsyncThunk<ICredentials, string, ThunkAPI>(
  "auth/federatedSignIn",
  async (redirectPathnameSearchHash, thunkAPI) => {
    try {
      return Auth.federatedSignIn({
        customProvider: "SeciossLink",
        customState: redirectPathnameSearchHash,
      });
    } catch (e) {
      return thunkAPI.rejectWithValue(errorNameAndMessageOf(e));
    }
  },
);

// ログイン（申請者）
export const signIn = createAsyncThunk<CognitoUser, SignInParameter, ThunkAPI>(
  "auth/signIn",
  async (arg, thunkAPI) => {
    try {
      // const user = await Auth.signIn(arg.username, arg.password);
      // if (user instanceof CognitoUser) {
      //   return user;
      // }
      return await Auth.signIn(arg.username, arg.password);
    } catch (e) {
      return thunkAPI.rejectWithValue(errorNameAndMessageOf(e));
    }
  },
);

// ログアウト
export const signOut = createAsyncThunk<void, void, ThunkAPI>(
  "auth/signOut",
  async (_, thunkAPI) => {
    try {
      await Auth.signOut({ global: true });
    } catch (e) {
      return thunkAPI.rejectWithValue(errorNameAndMessageOf(e));
    }
  },
);

// 二段階認証のセットアップ（QRコード生成）
export const setupTotp = createAsyncThunk<
  { secret: string; qrCode: string },
  void,
  ThunkAPI
>("auth/setupTotp", async (_, thunkAPI) => {
  try {
    const user = await Auth.currentAuthenticatedUser({ bypassCache: false });
    const secret = await Auth.setupTOTP(user);
    const issuer = encodeURI("ストック申請システム");
    const email = user.attributes?.email;
    // 認証アプリ用のQRコードを返却
    return {
      secret: secret,
      qrCode: `otpauth://totp/${issuer}:${email}?secret=${secret}&issuer=${issuer}&algorithm=SHA1&digits=6&period=30`,
    };
  } catch (e) {
    return thunkAPI.rejectWithValue(errorNameAndMessageOf(e));
  }
});

// 二段階認証のセットアップ（コード検証）
export const verifyTotp = createAsyncThunk<
  CognitoUserSession,
  { code: string },
  ThunkAPI
>("auth/verifyTOTP", async (arg, thunkAPI) => {
  try {
    // コードの検証
    const user = await Auth.currentAuthenticatedUser({ bypassCache: false });
    const result = await Auth.verifyTotpToken(user, arg.code);
    // 二段階認証の有効化
    await thunkAPI.dispatch(setEnablePreferredMFA());
    return result;
  } catch (e) {
    return thunkAPI.rejectWithValue(errorNameAndMessageOf(e));
  }
});

// 二段階認証のコード検証
export const confirmSignIn = createAsyncThunk<
  CognitoUser,
  { cognitoUser: CognitoUser; code: string },
  ThunkAPI
>("auth/confirmSignIn", async (arg, thunkAPI) => {
  try {
    const user: CognitoUser = await Auth.confirmSignIn(
      arg.cognitoUser,
      arg.code,
      "SOFTWARE_TOKEN_MFA",
    );
    return user;
  } catch (e) {
    return thunkAPI.rejectWithValue(errorNameAndMessageOf(e));
  }
});

// 本パスワードの登録
export const completeNewPassword = createAsyncThunk<
  CognitoUser,
  { cognitoUser: CognitoUser; newPassword: string },
  ThunkAPI
>("auth/completeNewPassword", async (arg, thunkAPI) => {
  try {
    const user: CognitoUser = await Auth.completeNewPassword(
      arg.cognitoUser,
      arg.newPassword,
    );
    return user;
  } catch (e) {
    return thunkAPI.rejectWithValue(errorNameAndMessageOf(e));
  }
});

// パスワード変更
export const changePassword = createAsyncThunk<
  "SUCCESS",
  { oldPassword: string; newPassword: string },
  ThunkAPI
>("auth/changePassword", async (arg, thunkAPI) => {
  try {
    const session = await Auth.currentAuthenticatedUser({ bypassCache: false });
    return await Auth.changePassword(session, arg.oldPassword, arg.newPassword);
  } catch (e) {
    return thunkAPI.rejectWithValue(errorNameAndMessageOf(e));
  }
});

// パスワードリセット（確認コード送信）
export const resetPassword = createAsyncThunk<
  void,
  { username: string },
  ThunkAPI
>("auth/resetPassword", async (arg, thunkAPI) => {
  try {
    await Auth.forgotPassword(arg.username);
  } catch (e) {
    return thunkAPI.rejectWithValue(errorNameAndMessageOf(e));
  }
});

// パスワードリセット（新パスワード設定）
export const resetPasswordSubmit = createAsyncThunk<
  string,
  { username: string; code: string; newPassword: string },
  ThunkAPI
>("auth/resetPasswordSubmit", async (arg, thunkAPI) => {
  try {
    return await Auth.forgotPasswordSubmit(
      arg.username,
      arg.code,
      arg.newPassword,
    );
  } catch (e) {
    return thunkAPI.rejectWithValue(errorNameAndMessageOf(e));
  }
});

// 二段階認証設定状態取得
export const getPreferredMFA = createAsyncThunk<string, void, ThunkAPI>(
  "auth/getPreferredMFA",
  async (_, thunkAPI) => {
    try {
      const user = await Auth.currentAuthenticatedUser({
        // Cognitoから最新の情報を取ってこなければならない為、bypassCache: true を指定
        bypassCache: true,
      });
      return await Auth.getPreferredMFA(user);
    } catch (e) {
      return thunkAPI.rejectWithValue(errorNameAndMessageOf(e));
    }
  },
);

// 二段階認証設定有効可
export const setEnablePreferredMFA = createAsyncThunk<string, void, ThunkAPI>(
  "auth/setPreferredMFA",
  async (_, thunkAPI) => {
    try {
      const user = await Auth.currentAuthenticatedUser({ bypassCache: false });
      return await Auth.setPreferredMFA(user, "SOFTWARE_TOKEN_MFA");
    } catch (e) {
      return thunkAPI.rejectWithValue(errorNameAndMessageOf(e));
    }
  },
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearAuthState: (state, _action) => {
      state.cognitoUser = undefined;
      state.userInfo = undefined;
      state.signInInfo = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(refreshCognitoUser.fulfilled, (state, action) => {
        if (isNullish(action.payload)) return;
        state.cognitoUser = action.payload;
      })
      .addCase(refreshCognitoUser.rejected, (state, _action) => {
        // 「CognitoUserの取得に失敗した」「アプリユーザー情報取得に成功・失敗した」タイミングで、初期化完了とする。
        state.initialized = true;
        state.cognitoUser = undefined;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        const challengeName = action.payload.challengeName;
        state.signInInfo = {
          cognitoUser: challengeName ? action.payload : undefined,
          challengeName: challengeName ?? undefined,
        };
      })
      .addCase(completeNewPassword.fulfilled, (state, action) => {
        // サインイン情報を更新（challengeNameが設定されてある場合はまだログインが完了していない）
        const challengeName = action.payload.challengeName;
        log.debug("challengeName: ", challengeName);
        state.signInInfo = {
          cognitoUser: challengeName ? action.payload : undefined,
          challengeName: challengeName ?? undefined,
        };
      })
      .addCase(setupTotp.pending, (state) => {
        state.setupTotpState.isFetching = true;
        state.setupTotpState.data = undefined;
      })
      .addCase(setupTotp.fulfilled, (state, action) => {
        state.setupTotpState.isFetching = false;
        state.setupTotpState.data = {
          qrCode: action.payload.qrCode,
          secret: action.payload.secret,
        };
      })
      .addCase(setupTotp.rejected, (state, _action) => {
        state.setupTotpState.isFetching = false;
      })
      .addCase(confirmSignIn.fulfilled, (state, _action) => {
        state.signInInfo = {
          cognitoUser: undefined,
          challengeName: undefined,
        };
      })
      .addCase(signOut.fulfilled, (state, _action) => {
        state.cognitoUser = undefined;
        state.userInfo = undefined;
      })
      .addCase(signOut.rejected, (state, _action) => {
        // ログアウトに失敗しても認証情報をクリアはする。
        state.cognitoUser = undefined;
        state.userInfo = undefined;
      })
      .addCase(getPreferredMFA.fulfilled, (state, action) => {
        state.enabledMfa = action.payload === "SOFTWARE_TOKEN_MFA";
      })
      .addCase(setEnablePreferredMFA.fulfilled, (state, action) => {
        if (action.payload === "SUCCESS") {
          state.enabledMfa = true;
        }
      })
      // 参考: https://github.com/reduxjs/redux-toolkit/issues/1509#issuecomment-919255436
      .addMatcher(
        stockRequestApi.endpoints.getCurrentAppUser.matchFulfilled,
        (state, action) => {
          // 「CognitoUserの取得に失敗した」「アプリユーザー情報取得に成功・失敗した」タイミングで、初期化完了とする。
          log.debug(
            `authSlice: auth state initialized! (getCurrentUser.matchFulfilled)`,
          );
          state.initialized = true;
          if (isNullish(action.payload) || isNullish(action.payload.role)) {
            state.userInfo = undefined;
            return;
          }
          const payload = appUserMeta.toSavedDomainObjectOrNull(action.payload);
          if (isNullish(payload)) {
            state.userInfo = undefined;
            return;
          }

          state.userInfo = {
            id: payload.id,
            displayUserId: payload.displayUserId,
            role: payload.role,
            fullName: payload.fullName,
            fullNameKana: payload.fullNameKana,
            cognitoUsername: payload.cognitoUsername,
            titleAndPosition: payload.titleAndPosition,
            phoneNumber: payload.phoneNumber,
            mailAddress: payload.mailAddress,
            language: payload.language,
            accountState: payload.accountState,
            institutionId: payload.institutionId,
            mailNotification: payload.mailNotification,
          };
          // state.userInfo = {
          //   appUserId: action.payload.id ?? -1,
          //   userName: action.payload.fullName ?? "",
          //   role: action.payload.role,
          //   institutionId: action.payload.institutionId ?? -1,
          // };
        },
      )
      .addMatcher(
        stockRequestApi.endpoints.getCurrentAppUser.matchRejected,
        (state, _action) => {
          // 「CognitoUserの取得に失敗した」「アプリユーザー情報取得に成功・失敗した」タイミングで、初期化完了とする。
          log.debug(
            `authSlice: auth state initialized! (getCurrentUser.matchRejected)`,
          );
          state.initialized = true;
          state.userInfo = undefined;
        },
      );
  },
});

export const { clearAuthState } = authSlice.actions;

export const selectCognitoUsername: (state: RootState) => string | undefined = (
  state,
) => state.auth.cognitoUser?.cognitoUsername;
const selectInitialized: (state: RootState) => boolean = (state) => {
  return state.auth.initialized;
};
const selectCognitoUser: (state: RootState) =>
  | {
      cognitoUsername: string;
    }
  | undefined = (state) => state.auth.cognitoUser;
export const selectUserInfo: (state: RootState) => LoginUserInfo | undefined = (
  state,
) => state.auth.userInfo;
export const selectHasAnyRoleOf = createSelector(
  [selectUserInfo, (userInfo, roles: Role[]) => roles],
  (userInfo, roles) => {
    if (isNullish(userInfo) || isNullish(userInfo?.role)) return false;
    return roles.includes(userInfo.role);
  },
);
export const selectHasRole = createSelector([selectUserInfo], (userInfo) => {
  return {
    isApplicant: userInfo?.role === "applicant",
    isInternalMember: userInfo?.role === "internal",
    isOfficeMember: userInfo?.role === "office_member",
    isCommitteeMember: userInfo?.role === "committee_member",
    isExecutiveDirector: userInfo?.role === "executive_director",
  };
});
export const selectAuthenticationStatus: (
  state: RootState,
) => AuthenticationStatus = createSelector(
  [selectInitialized, selectCognitoUser, selectUserInfo],
  (initialized, cognitoUser, userInfo) => {
    if (!initialized) return "pending";
    if (hasValue(cognitoUser) && hasValue(userInfo)) return "authenticated";
    return "unauthenticated";
  },
);

export const authReducer = authSlice.reducer;
