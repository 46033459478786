import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * 案件状態(値オブジェクト)
 *
 *
 *
 * draft:
 * applied:
 * ongoing:
 * terminated:
 * invalid:
 * withdrawn:
 * aborted:  */
export type ProjectState = z.infer<typeof projectStateMeta.schema>;
/**
 * 案件状態(値オブジェクト)のメタデータ。
 *
 *  */
export const projectStateMeta = new ValueObjectMetaEnum<
  [
    "draft",
    "applied",
    "ongoing",
    "terminated",
    "invalid",
    "withdrawn",
    "aborted"
  ]
>(
  "ProjectState",
  "\u6848\u4EF6\u72B6\u614B",
  messageId("lbl.\u6848\u4EF6\u72B6\u614B"),
  [
    "draft",
    "applied",
    "ongoing",
    "terminated",
    "invalid",
    "withdrawn",
    "aborted",
  ],
  {
    draft: "code.\u6848\u4EF6\u72B6\u614B.\u4E0B\u66F8\u304D",
    applied: "code.\u6848\u4EF6\u72B6\u614B.\u7533\u8ACB\u4E2D",
    ongoing: "code.\u6848\u4EF6\u72B6\u614B.\u9032\u884C\u4E2D",
    terminated: "code.\u6848\u4EF6\u72B6\u614B.\u4F7F\u7528\u7D42\u4E86",
    invalid: "code.\u6848\u4EF6\u72B6\u614B.\u7121\u52B9",
    withdrawn: "code.\u6848\u4EF6\u72B6\u614B.\u53D6\u308A\u4E0B\u3052",
    aborted: "code.\u6848\u4EF6\u72B6\u614B.\u4E2D\u65AD",
  }
);
