import { ComponentStyleProps } from "../../../../lib/styles/props/component-style-props";
import React, { memo } from "react";
import { VStack } from "@chakra-ui/react";
import {
  CMMessageError,
  ValidationError,
} from "@pscsrvlab/psc-react-components";
import { hasValue } from "../../../../lib/util/common-util";

export type ErrorMessageAreaType = {
  validationErrors: ValidationError[];
} & ComponentStyleProps;

export const ErrorMessageArea = memo(function ErrorMessageArea({
  validationErrors,

  sx,
  ...rest
}: ErrorMessageAreaType) {
  return (
    <>
      {validationErrors.length > 0 && (
        <VStack
          minH={"10px"}
          maxH={"120px"}
          alignSelf={"stretch"}
          alignItems={"stretch"}
          overflow={"auto"}
          className={"ErrorMessageArea"}
          sx={sx}
          {...rest}
        >
          {validationErrors.length > 0 && (
            <CMMessageError
              labels={validationErrors.map((value) => {
                return hasValue(value.fullPropertyDisplayName)
                  ? value.fullPropertyDisplayName + ": " + value.errorMessage
                  : value.errorMessage;
              })}
              sx={{ overflow: "auto" }}
            />
          )}
        </VStack>
      )}
    </>
  );
});
