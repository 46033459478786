import {
  messageId,
  PropertyMeta,
  ValueObjectMetaNumber,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.number().min(0);
const savedMinimalSchema = z.number().min(0);
const schema = z.number().min(0);
const savedSchema = z.number().min(0);
const jsonSchema = z.number();
/**
 * 結果通知ID(値オブジェクト)のメタデータ。
 *
 *  */
export const resultNotificationIdMeta = new ValueObjectMetaNumber(
  "ResultNotificationId",
  "\u7D50\u679C\u901A\u77E5ID",
  messageId("lbl.\u7D50\u679C\u901A\u77E5ID"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
