import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * オペレーション種別(値オブジェクト)
 *
 *
 *
 * GetCurrentUser:
 * ChangeLanguage:
 * ListSharedAttachmentFile:
 * DownloadFile:
 * UploadFile:
 * CreateSharedAttachmentFile:
 * DeleteSharedAttachmentFile:
 * ListProject:
 * GetProject:
 * UpdateDisclosureDocument:
 * CreateCellStockDelivery:
 * ListCellStockDelivery:
 * GetCellStockDelivery:
 * UpdateCellStockDelivery:
 * DeleteCellStockDelivery:
 * ForceCreateProject:
 * ForceUpdateProject:
 * ListDocument:
 * GetNumberOfDocumentTodos:
 * GetNewApplication:
 * GetChangeApplication:
 * GetAnnualReport:
 * GetTerminationReport:
 * ListRevisionRequest:
 * GetRevisionRequest:
 * RemindAnnualReport:
 * RemindTerminationReport:
 * GetInstitution:
 * GetUser:
 * CreateNewApplicationDraft:
 * CreateChangeApplicationDraft:
 * CreateAnnualReportDraft:
 * CreateTerminationReportDraft:
 * DeleteDocumentDraft:
 * SubmitNewApplication:
 * SubmitChangeApplication:
 * SubmitAnnualReport:
 * SubmitTerminationReport:
 * UpdateNewApplicationContent:
 * UpdateChangeApplicationContent:
 * UpdateAnnualReportContent:
 * UpdateTerminationReportContent:
 * ReceiveNewApplication:
 * ReceiveChangeApplication:
 * ReceiveAnnualReport:
 * ReceiveTerminationReport:
 * UpdateNewApplicationOfficeMemberCheck:
 * UpdateChangeApplicationOfficeMemberCheck:
 * CompleteOfficeMemberCheck:
 * CreateRevisionRequest:
 * SaveRevisionRequestReply:
 * SubmitRevisionRequestReply:
 * ListReview:
 * GetReview:
 * UpdateReview:
 * ConcludeReview:
 * ApproveReview:
 * ListResultNotification:
 * GetResultNotification:
 * CreateResultNotification:
 * UpdateApplicationOfficeMemberNotes:
 * UpdateReportOfficeMemberNotes:
 * ListReviewConference:
 * GetReviewConference:
 * NotifyReviewConference:
 * UpdateReviewConference:
 * CreateReviewConference:
 * DeleteReviewConference:
 * ConcludeReviewConference:
 * ListInquiry:
 * GetNumberOfInquiryTodos:
 * RespondInquiry:
 * ReadInquiry:
 * CreateInquiry:
 * ListWorkLog:
 * ListMailLog:
 * ListInstitution:
 * CreateInstitution:
 * UpdateInstitution:
 * ListAppUser:
 * CreateAppUser:
 * UpdateAppUser:
 * EnableAppUser:
 * DisableAppUser:
 * ResetTemporaryPassword:
 * ResetMFA:
 * ListCellStockCategory:
 * ListCellStock:
 * AbortProject:
 * AbortDocument:
 * WithdrawDocument:  */
export type OperationType = z.infer<typeof operationTypeMeta.schema>;
/**
 * オペレーション種別(値オブジェクト)のメタデータ。
 *
 *  */
export const operationTypeMeta = new ValueObjectMetaEnum<
  [
    "GetCurrentUser",
    "ChangeLanguage",
    "ListSharedAttachmentFile",
    "DownloadFile",
    "UploadFile",
    "CreateSharedAttachmentFile",
    "DeleteSharedAttachmentFile",
    "ListProject",
    "GetProject",
    "UpdateDisclosureDocument",
    "CreateCellStockDelivery",
    "ListCellStockDelivery",
    "GetCellStockDelivery",
    "UpdateCellStockDelivery",
    "DeleteCellStockDelivery",
    "ForceCreateProject",
    "ForceUpdateProject",
    "ListDocument",
    "GetNumberOfDocumentTodos",
    "GetNewApplication",
    "GetChangeApplication",
    "GetAnnualReport",
    "GetTerminationReport",
    "ListRevisionRequest",
    "GetRevisionRequest",
    "RemindAnnualReport",
    "RemindTerminationReport",
    "GetInstitution",
    "GetUser",
    "CreateNewApplicationDraft",
    "CreateChangeApplicationDraft",
    "CreateAnnualReportDraft",
    "CreateTerminationReportDraft",
    "DeleteDocumentDraft",
    "SubmitNewApplication",
    "SubmitChangeApplication",
    "SubmitAnnualReport",
    "SubmitTerminationReport",
    "UpdateNewApplicationContent",
    "UpdateChangeApplicationContent",
    "UpdateAnnualReportContent",
    "UpdateTerminationReportContent",
    "ReceiveNewApplication",
    "ReceiveChangeApplication",
    "ReceiveAnnualReport",
    "ReceiveTerminationReport",
    "UpdateNewApplicationOfficeMemberCheck",
    "UpdateChangeApplicationOfficeMemberCheck",
    "CompleteOfficeMemberCheck",
    "CreateRevisionRequest",
    "SaveRevisionRequestReply",
    "SubmitRevisionRequestReply",
    "ListReview",
    "GetReview",
    "UpdateReview",
    "ConcludeReview",
    "ApproveReview",
    "ListResultNotification",
    "GetResultNotification",
    "CreateResultNotification",
    "UpdateApplicationOfficeMemberNotes",
    "UpdateReportOfficeMemberNotes",
    "ListReviewConference",
    "GetReviewConference",
    "NotifyReviewConference",
    "UpdateReviewConference",
    "CreateReviewConference",
    "DeleteReviewConference",
    "ConcludeReviewConference",
    "ListInquiry",
    "GetNumberOfInquiryTodos",
    "RespondInquiry",
    "ReadInquiry",
    "CreateInquiry",
    "ListWorkLog",
    "ListMailLog",
    "ListInstitution",
    "CreateInstitution",
    "UpdateInstitution",
    "ListAppUser",
    "CreateAppUser",
    "UpdateAppUser",
    "EnableAppUser",
    "DisableAppUser",
    "ResetTemporaryPassword",
    "ResetMFA",
    "ListCellStockCategory",
    "ListCellStock",
    "AbortProject",
    "AbortDocument",
    "WithdrawDocument",
  ]
>(
  "OperationType",
  "\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225",
  messageId("lbl.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225"),
  [
    "GetCurrentUser",
    "ChangeLanguage",
    "ListSharedAttachmentFile",
    "DownloadFile",
    "UploadFile",
    "CreateSharedAttachmentFile",
    "DeleteSharedAttachmentFile",
    "ListProject",
    "GetProject",
    "UpdateDisclosureDocument",
    "CreateCellStockDelivery",
    "ListCellStockDelivery",
    "GetCellStockDelivery",
    "UpdateCellStockDelivery",
    "DeleteCellStockDelivery",
    "ForceCreateProject",
    "ForceUpdateProject",
    "ListDocument",
    "GetNumberOfDocumentTodos",
    "GetNewApplication",
    "GetChangeApplication",
    "GetAnnualReport",
    "GetTerminationReport",
    "ListRevisionRequest",
    "GetRevisionRequest",
    "RemindAnnualReport",
    "RemindTerminationReport",
    "GetInstitution",
    "GetUser",
    "CreateNewApplicationDraft",
    "CreateChangeApplicationDraft",
    "CreateAnnualReportDraft",
    "CreateTerminationReportDraft",
    "DeleteDocumentDraft",
    "SubmitNewApplication",
    "SubmitChangeApplication",
    "SubmitAnnualReport",
    "SubmitTerminationReport",
    "UpdateNewApplicationContent",
    "UpdateChangeApplicationContent",
    "UpdateAnnualReportContent",
    "UpdateTerminationReportContent",
    "ReceiveNewApplication",
    "ReceiveChangeApplication",
    "ReceiveAnnualReport",
    "ReceiveTerminationReport",
    "UpdateNewApplicationOfficeMemberCheck",
    "UpdateChangeApplicationOfficeMemberCheck",
    "CompleteOfficeMemberCheck",
    "CreateRevisionRequest",
    "SaveRevisionRequestReply",
    "SubmitRevisionRequestReply",
    "ListReview",
    "GetReview",
    "UpdateReview",
    "ConcludeReview",
    "ApproveReview",
    "ListResultNotification",
    "GetResultNotification",
    "CreateResultNotification",
    "UpdateApplicationOfficeMemberNotes",
    "UpdateReportOfficeMemberNotes",
    "ListReviewConference",
    "GetReviewConference",
    "NotifyReviewConference",
    "UpdateReviewConference",
    "CreateReviewConference",
    "DeleteReviewConference",
    "ConcludeReviewConference",
    "ListInquiry",
    "GetNumberOfInquiryTodos",
    "RespondInquiry",
    "ReadInquiry",
    "CreateInquiry",
    "ListWorkLog",
    "ListMailLog",
    "ListInstitution",
    "CreateInstitution",
    "UpdateInstitution",
    "ListAppUser",
    "CreateAppUser",
    "UpdateAppUser",
    "EnableAppUser",
    "DisableAppUser",
    "ResetTemporaryPassword",
    "ResetMFA",
    "ListCellStockCategory",
    "ListCellStock",
    "AbortProject",
    "AbortDocument",
    "WithdrawDocument",
  ],
  {
    GetCurrentUser:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u30ED\u30B0\u30A4\u30F3\u30E6\u30FC\u30B6\u30FC\u60C5\u5831\u53D6\u5F97",
    ChangeLanguage:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u8A00\u8A9E\u5909\u66F4",
    ListSharedAttachmentFile:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u95A2\u9023\u6587\u66F8\u30FB\u305D\u306E\u4ED6\u69D8\u5F0F\u4E00\u89A7\u53D6\u5F97",
    DownloadFile:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u30D5\u30A1\u30A4\u30EB\u30C0\u30A6\u30F3\u30ED\u30FC\u30C9URL\u53D6\u5F97",
    UploadFile:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u30D5\u30A1\u30A4\u30EB\u30A2\u30C3\u30D7\u30ED\u30FC\u30C9",
    CreateSharedAttachmentFile:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u95A2\u9023\u6587\u66F8\u30FB\u305D\u306E\u4ED6\u69D8\u5F0F\u4E00\u89A7\u30D5\u30A1\u30A4\u30EB\u767B\u9332",
    DeleteSharedAttachmentFile:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u95A2\u9023\u6587\u66F8\u30FB\u305D\u306E\u4ED6\u69D8\u5F0F\u4E00\u89A7\u30D5\u30A1\u30A4\u30EB\u524A\u9664",
    ListProject:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u6848\u4EF6\u691C\u7D22",
    GetProject:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u6848\u4EF6\u53D6\u5F97",
    UpdateDisclosureDocument:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u60C5\u5831\u516C\u958B\u6587\u66F8\u4FDD\u5B58",
    CreateCellStockDelivery:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u7D30\u80DE\u767A\u9001\u4F5C\u6210",
    ListCellStockDelivery:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u7D30\u80DE\u767A\u9001\u691C\u7D22",
    GetCellStockDelivery:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u7D30\u80DE\u767A\u9001\u53D6\u5F97",
    UpdateCellStockDelivery:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u7D30\u80DE\u767A\u9001\u4FDD\u5B58",
    DeleteCellStockDelivery:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u7D30\u80DE\u767A\u9001\u524A\u9664",
    ForceCreateProject:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u6848\u4EF6\u5F37\u5236\u4F5C\u6210",
    ForceUpdateProject:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u6848\u4EF6\u66F4\u65B0",
    ListDocument:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u66F8\u985E\u691C\u7D22",
    GetNumberOfDocumentTodos:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u66F8\u985ETODO\u4EF6\u6570",
    GetNewApplication:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u65B0\u898F\u7533\u8ACB\u53D6\u5F97",
    GetChangeApplication:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u5909\u66F4\u7533\u8ACB\u53D6\u5F97",
    GetAnnualReport:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u5E74\u6B21\u5831\u544A\u53D6\u5F97",
    GetTerminationReport:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u4F7F\u7528\u7D42\u4E86\u5831\u544A\u53D6\u5F97",
    ListRevisionRequest:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u4FEE\u6B63\u4F9D\u983C\u691C\u7D22",
    GetRevisionRequest:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u4FEE\u6B63\u4F9D\u983C\u53D6\u5F97",
    RemindAnnualReport:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u5E74\u6B21\u5831\u544A\u30EA\u30DE\u30A4\u30F3\u30C9",
    RemindTerminationReport:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u4F7F\u7528\u7D42\u4E86\u5831\u544A\u30EA\u30DE\u30A4\u30F3\u30C9",
    GetInstitution:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u6A5F\u95A2\u53D6\u5F97",
    GetUser:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u30E6\u30FC\u30B6\u30FC\u53D6\u5F97",
    CreateNewApplicationDraft:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u65B0\u898F\u7533\u8ACB\u4E0B\u66F8\u304D",
    CreateChangeApplicationDraft:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u5909\u66F4\u7533\u8ACB\u4E0B\u66F8\u304D",
    CreateAnnualReportDraft:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u5E74\u6B21\u5831\u544A\u4E0B\u66F8\u304D",
    CreateTerminationReportDraft:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u4F7F\u7528\u7D42\u4E86\u5831\u544A\u4E0B\u66F8\u304D",
    DeleteDocumentDraft:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u66F8\u985E\u4E0B\u66F8\u304D\u524A\u9664",
    SubmitNewApplication:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u65B0\u898F\u7533\u8ACB\u63D0\u51FA",
    SubmitChangeApplication:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u5909\u66F4\u7533\u8ACB\u63D0\u51FA",
    SubmitAnnualReport:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u5E74\u6B21\u5831\u544A\u63D0\u51FA",
    SubmitTerminationReport:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u4F7F\u7528\u7D42\u4E86\u5831\u544A\u63D0\u51FA",
    UpdateNewApplicationContent:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u65B0\u898F\u7533\u8ACB\u5185\u5BB9\u66F4\u65B0",
    UpdateChangeApplicationContent:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u5909\u66F4\u7533\u8ACB\u5185\u5BB9\u66F4\u65B0",
    UpdateAnnualReportContent:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u5E74\u6B21\u5831\u544A\u66F4\u65B0",
    UpdateTerminationReportContent:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u4F7F\u7528\u7D42\u4E86\u5831\u544A\u66F4\u65B0",
    ReceiveNewApplication:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u65B0\u898F\u7533\u8ACB\u53D7\u4ED8",
    ReceiveChangeApplication:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u5909\u66F4\u7533\u8ACB\u53D7\u4ED8",
    ReceiveAnnualReport:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u5E74\u6B21\u5831\u544A\u53D7\u4ED8",
    ReceiveTerminationReport:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u4F7F\u7528\u7D42\u4E86\u5831\u544A\u53D7\u4ED8",
    UpdateNewApplicationOfficeMemberCheck:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u65B0\u898F\u7533\u8ACB\u4E8B\u52D9\u5C40\u78BA\u8A8D\u4FDD\u5B58",
    UpdateChangeApplicationOfficeMemberCheck:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u5909\u66F4\u7533\u8ACB\u4E8B\u52D9\u5C40\u78BA\u8A8D\u4FDD\u5B58",
    CompleteOfficeMemberCheck:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u4E8B\u52D9\u5C40\u78BA\u8A8D\u5B8C\u4E86",
    CreateRevisionRequest:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u4FEE\u6B63\u4F9D\u983C",
    SaveRevisionRequestReply:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u4FEE\u6B63\u4F9D\u983C\u8FD4\u4FE1\u5185\u5BB9\u4FDD\u5B58",
    SubmitRevisionRequestReply:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u4FEE\u6B63\u4F9D\u983C\u8FD4\u4FE1\u63D0\u51FA",
    ListReview:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u5BE9\u67FB\u691C\u7D22",
    GetReview:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u5BE9\u67FB\u53D6\u5F97",
    UpdateReview:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u5BE9\u67FB\u66F4\u65B0",
    ConcludeReview:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u5BE9\u67FB\u7D50\u679C\u78BA\u5B9A",
    ApproveReview:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u696D\u52D9\u57F7\u884C\u7406\u4E8B\u627F\u8A8D",
    ListResultNotification:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u7D50\u679C\u901A\u77E5\u691C\u7D22",
    GetResultNotification:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u7D50\u679C\u901A\u77E5\u53D6\u5F97",
    CreateResultNotification:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u7D50\u679C\u901A\u77E5\u4F5C\u6210",
    UpdateApplicationOfficeMemberNotes:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u7533\u8ACB\u66F8\u985E\u4E8B\u52D9\u5C40\u8A18\u5165\u4FDD\u5B58",
    UpdateReportOfficeMemberNotes:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u5831\u544A\u66F8\u985E\u4E8B\u52D9\u5C40\u8A18\u5165\u4FDD\u5B58",
    ListReviewConference:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u5BE9\u67FB\u4F1A\u691C\u7D22",
    GetReviewConference:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u5BE9\u67FB\u4F1A\u53D6\u5F97",
    NotifyReviewConference:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u5BE9\u67FB\u4F1A\u958B\u50AC\u5185\u5BB9\u901A\u77E5",
    UpdateReviewConference:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u5BE9\u67FB\u4F1A\u66F4\u65B0",
    CreateReviewConference:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u5BE9\u67FB\u4F1A\u4F5C\u6210",
    DeleteReviewConference:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u5BE9\u67FB\u4F1A\u524A\u9664",
    ConcludeReviewConference:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u5BE9\u67FB\u4F1A\u5BE9\u67FB\u7D50\u679C\u78BA\u5B9A",
    ListInquiry:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u8CEA\u7591\u5FDC\u7B54\u691C\u7D22",
    GetNumberOfInquiryTodos:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u8CEA\u7591\u5FDC\u7B54TODO\u4EF6\u6570",
    RespondInquiry:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u8CEA\u7591\u5FDC\u7B54\u8FD4\u4FE1",
    ReadInquiry:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u8CEA\u7591\u5FDC\u7B54\u65E2\u8AAD",
    CreateInquiry:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u8CEA\u7591\u5FDC\u7B54\u4F5C\u6210",
    ListWorkLog:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u4F5C\u696D\u30ED\u30B0\u691C\u7D22",
    ListMailLog:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u30E1\u30FC\u30EB\u30ED\u30B0\u691C\u7D22",
    ListInstitution:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u6A5F\u95A2\u691C\u7D22",
    CreateInstitution:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u6A5F\u95A2\u4F5C\u6210",
    UpdateInstitution:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u6A5F\u95A2\u66F4\u65B0",
    ListAppUser:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u30E6\u30FC\u30B6\u30FC\u691C\u7D22",
    CreateAppUser:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u30E6\u30FC\u30B6\u30FC\u4F5C\u6210",
    UpdateAppUser:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u30E6\u30FC\u30B6\u30FC\u66F4\u65B0",
    EnableAppUser:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u30E6\u30FC\u30B6\u30FC\u6709\u52B9\u5316",
    DisableAppUser:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u30E6\u30FC\u30B6\u30FC\u7121\u52B9\u5316",
    ResetTemporaryPassword:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u4EEE\u30D1\u30B9\u30EF\u30FC\u30C9\u30EA\u30BB\u30C3\u30C8",
    ResetMFA:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u4E8C\u6BB5\u968E\u8A8D\u8A3C\u30EA\u30BB\u30C3\u30C8",
    ListCellStockCategory:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u7D30\u80DE\u30AB\u30C6\u30B4\u30EA\u30EA\u30B9\u30C8\u53D6\u5F97",
    ListCellStock:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u7D30\u80DE\u30EA\u30B9\u30C8\u53D6\u5F97",
    AbortProject:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u6848\u4EF6\u4E2D\u65AD",
    AbortDocument:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u66F8\u985E\u4E2D\u65AD",
    WithdrawDocument:
      "code.\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u7A2E\u5225.\u66F8\u985E\u53D6\u308A\u4E0B\u3052",
  },
);
