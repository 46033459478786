import { memo, useCallback } from "react";
import { ComponentStyleProps } from "../../../../../../../../../../../lib/styles/props/component-style-props";
import { HStack, Stack, Text, VStack } from "@chakra-ui/react";
import {
  CMButtonFormCommentProps,
  CMFormControlHeader,
  CMFormInputText,
  Comments,
} from "@pscsrvlab/psc-react-components";
import { fullNameMeta } from "../../../../../../../../../../../lib/object/value/full-name";
import { titleAndPositionMeta } from "../../../../../../../../../../../lib/object/value/title-and-position";
import { phoneNumberMeta } from "../../../../../../../../../../../lib/object/value/phone-number";
import { mailAddressMeta } from "../../../../../../../../../../../lib/object/value/mail-address";
import { CollaborativePartnerUserInformation } from "../../../../../../../../../../../lib/object/value/collaborative-partner-user-information";
import { useChangeBadgeProperties } from "../../../../../../../../../../../hooks/document/change-application/use-change-badge-properties";
import { useAppTranslation } from "../../../../../../../../../../../hooks/use-app-translation";

export type PartnerContentUserInfoProps = {
  documentType:
  | "new_application"
  | "change_application"
  | "project_force_update"
  | "project_force_create"
  | "project_content";
  editMode: "editable" | "readOnly";
  title: string;

  value: CollaborativePartnerUserInformation;
  onChange: (
    change: (
      before: CollaborativePartnerUserInformation,
    ) => CollaborativePartnerUserInformation,
  ) => void;

  changedFrom?: CollaborativePartnerUserInformation;
  /**
   * コメントボタンのprops。
   * これが存在すれば、コメントボタンを表示する。
   */
  commentButtonProps?: CMButtonFormCommentProps | undefined;
  /**
   * 1セクション単位の修正依頼コメント・返信。履歴参照画面で使用。
   */
  commentsList?: Comments[];
} & ComponentStyleProps;
export const PartnerContentUserInfo = memo(function PartnerContentUserInfo({
  documentType,
  editMode,

  title,

  value,
  onChange,

  changedFrom,

  commentButtonProps,

  commentsList,

  sx,
  ...rest
}: PartnerContentUserInfoProps) {
  const { t } = useAppTranslation();

  const handleChangeName = useCallback(
    (v: string) => {
      onChange((before) => ({ ...before, fullName: v }));
    },
    [onChange],
  );
  const handleChangeTitleAndPosition = useCallback(
    (v: string) => {
      onChange((before) => ({ ...before, titleAndPosition: v }));
    },
    [onChange],
  );
  const handleChangePhoneNumber = useCallback(
    (v: string) => {
      onChange((before) => ({ ...before, phoneNumber: v }));
    },
    [onChange],
  );
  const handleChangeMailAddress = useCallback(
    (v: string) => {
      onChange((before) => ({ ...before, mailAddress: v }));
    },
    [onChange],
  );

  const changeBadgePropertiesFullName = useChangeBadgeProperties(
    documentType,
    changedFrom?.fullName,
  );
  const changeBadgePropertiesTitleAndPosition = useChangeBadgeProperties(
    documentType,
    changedFrom?.titleAndPosition,
  );
  const changeBadgePropertiesPhoneNumber = useChangeBadgeProperties(
    documentType,
    changedFrom?.phoneNumber,
  );
  const changeBadgePropertiesMailAddress = useChangeBadgeProperties(
    documentType,
    changedFrom?.mailAddress,
  );

  return (
    <VStack alignItems={"stretch"} sx={sx} {...rest}>
      <HStack>
        <Text fontWeight={"bold"} alignSelf={"flex-start"}>
          {title}
        </Text>
        <CMFormControlHeader
          commentButtonProps={commentButtonProps}
          commentsList={commentsList}
        />
      </HStack>
      <Stack direction={"column"}>
        <Stack direction={"row"} spacing={"18px"} mb={"10px"}>
          <CMFormInputText
            editMode={editMode}
            size={"md"}
            label={t("lbl.氏名")}
            valueObjectMeta={fullNameMeta}
            value={value?.fullName}
            onChange={handleChangeName}
            changeBadgeProperties={changeBadgePropertiesFullName}
            flex={"1 1 0"}
            minW={0}
          />
          <CMFormInputText
            editMode={editMode}
            label={t("lbl.所属・職名")}
            valueObjectMeta={titleAndPositionMeta}
            value={value?.titleAndPosition}
            onChange={handleChangeTitleAndPosition}
            changeBadgeProperties={changeBadgePropertiesTitleAndPosition}
            flex={"1 1 0"}
            minW={0}
          />
        </Stack>
        <Stack direction={"row"} spacing={"18px"} mb={"10px"}>
          <CMFormInputText
            editMode={editMode}
            label={t("lbl.電話番号")}
            valueObjectMeta={phoneNumberMeta}
            value={value?.phoneNumber}
            onChange={handleChangePhoneNumber}
            changeBadgeProperties={changeBadgePropertiesPhoneNumber}
            flex={"1 1 0"}
            minW={0}
          />
          <CMFormInputText
            editMode={editMode}
            label={t("lbl.メールアドレス")}
            valueObjectMeta={mailAddressMeta}
            value={value?.mailAddress}
            onChange={handleChangeMailAddress}
            changeBadgeProperties={changeBadgePropertiesMailAddress}
            flex={"1 1 0"}
            minW={0}
          />
        </Stack>
      </Stack>
    </VStack>
  );
});
