import {
  messageId,
  PropertyMeta,
  ValueObjectMetaComposite,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { storageFileIdMeta } from "../value/storage-file-id";
import { attachmentFileTypeMeta } from "../value/attachment-file-type";
import { attachmentFileNameMeta } from "../value/attachment-file-name";
import { datetimeMeta } from "../value/datetime";
/**
 * 添付資料情報(値オブジェクト)
 *
 *  */
export type AttachmentFileInformation = z.infer<typeof schema>;
export type AttachmentFileInformationSaved = z.infer<typeof savedSchema>;
const minimalSchema = z.object({
  storageFileId: storageFileIdMeta.minimalSchema,
  attachmentFileType: attachmentFileTypeMeta.minimalSchema,
  attachmentFileName: attachmentFileNameMeta.minimalSchema,
  uploadedAt: datetimeMeta.minimalSchema,
});
const savedMinimalSchema = z.object({
  storageFileId: storageFileIdMeta.savedMinimalSchema,
  attachmentFileType: attachmentFileTypeMeta.savedMinimalSchema,
  attachmentFileName: attachmentFileNameMeta.savedMinimalSchema,
  uploadedAt: datetimeMeta.savedMinimalSchema,
});
const schema = z.object({
  storageFileId: storageFileIdMeta.schema,
  attachmentFileType: attachmentFileTypeMeta.schema,
  attachmentFileName: attachmentFileNameMeta.schema,
  uploadedAt: datetimeMeta.schema,
});
const savedSchema = z.object({
  storageFileId: storageFileIdMeta.savedSchema,
  attachmentFileType: attachmentFileTypeMeta.savedSchema,
  attachmentFileName: attachmentFileNameMeta.savedSchema,
  uploadedAt: datetimeMeta.savedSchema,
});
const jsonSchema = z.object({
  storageFileId: storageFileIdMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  attachmentFileType: attachmentFileTypeMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  attachmentFileName: attachmentFileNameMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  uploadedAt: datetimeMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
/**
 * 添付資料情報(値オブジェクト)のメタデータ。
 *
 *  */
export const attachmentFileInformationMeta = new ValueObjectMetaComposite(
  "AttachmentFileInformation",
  "\u6DFB\u4ED8\u8CC7\u6599\u60C5\u5831",
  messageId("lbl.\u6DFB\u4ED8\u8CC7\u6599\u60C5\u5831"),
  minimalSchema,
  savedMinimalSchema,
  schema,
  savedSchema,
  jsonSchema,
  undefined,
  undefined,
  {
    storageFileId: new PropertyMeta(
      "storageFileId",
      "\u30B9\u30C8\u30EC\u30FC\u30B8\u30D5\u30A1\u30A4\u30EBID",
      messageId("lbl.\u30B9\u30C8\u30EC\u30FC\u30B8\u30D5\u30A1\u30A4\u30EBID"),
      "always",
      false,
      storageFileIdMeta
    ),
    attachmentFileType: new PropertyMeta(
      "attachmentFileType",
      "\u6DFB\u4ED8\u8CC7\u6599\u7A2E\u5225",
      messageId("lbl.\u6DFB\u4ED8\u8CC7\u6599\u7A2E\u5225"),
      "always",
      false,
      attachmentFileTypeMeta
    ),
    attachmentFileName: new PropertyMeta(
      "attachmentFileName",
      "\u6DFB\u4ED8\u8CC7\u6599\u540D",
      messageId("lbl.\u6DFB\u4ED8\u8CC7\u6599\u540D"),
      "always",
      false,
      attachmentFileNameMeta
    ),
    uploadedAt: new PropertyMeta(
      "uploadedAt",
      "\u767B\u9332\u65E5\u6642",
      messageId("lbl.\u767B\u9332\u65E5\u6642"),
      "always",
      false,
      datetimeMeta
    ),
  }
);
