import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import index, { persistor } from "./store";
import reportWebVitals from "./lib/util/reportWebVitals";
import * as serviceWorker from "./lib/util/serviceWorker";
import { PersistGate } from "redux-persist/integration/react";
import { theme } from "./theme";
import { Amplify } from "aws-amplify";
import { awsExports } from "./config";
import { initializeApp } from "./init/initialize-app";
import { RouterProvider } from "react-router-dom";
import { router } from "./router";
import "./index.css";
import { ConfigFileProvider } from "./ConfigFileProvider";
import { FileModalProvider } from "./components/ui/modal/FileModal/FileModal";

console.log(awsExports);
Amplify.configure(awsExports);

initializeApp();

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(container);

root.render(
  <React.StrictMode>
    <ColorModeScript />
    <Provider store={index}>
      <PersistGate loading={null} persistor={persistor}>
        <ChakraProvider theme={theme}>
          <ConfigFileProvider>
            <FileModalProvider>
              {/*<BrowserRouter>*/}
              {/*<AuthProvider>*/}
              <RouterProvider router={router} />
              {/*</AuthProvider>*/}
              {/*</BrowserRouter>*/}
            </FileModalProvider>
          </ConfigFileProvider>
        </ChakraProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
