import {
  messageId,
  PropertyMeta,
  ValueObjectMetaString,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.string().max(300);
const savedMinimalSchema = z.string().max(300);
const schema = z.string().min(1).max(300);
const savedSchema = z.string().min(1).max(300);
const jsonSchema = z.string();
/**
 * 氏名かな(値オブジェクト)のメタデータ。
 *
 * 氏名かな。 */
export const fullNameKanaMeta = new ValueObjectMetaString(
  "FullNameKana",
  "\u6C0F\u540D\u304B\u306A",
  messageId("lbl.\u6C0F\u540D\u304B\u306A"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
