import React, { memo } from "react";
import {
  CMFormInputTextArea,
  ValueObjectMetaString,
} from "@pscsrvlab/psc-react-components";
import { FormSection } from "../../../../../ui/form/FormSection/FormSection";

export type SectionCommentProps = {
  editMode: "editable" | "readOnly";

  /**
   * タイトル
   */
  title?: string;

  /**
   * 補足
   */
  description?: string;

  value?: string;

  /**
   * 値オブジェクトメタデータ
   */
  valueObjectMeta?: ValueObjectMetaString;

  /**
   * 変更イベント
   */
  onChange: (v: string) => void;
};

/**
 * FN30-S09細胞発送管理
 * コメントセクション
 */
export const SectionComment = memo(function SectionComment({
  editMode,

  title,
  description,

  value,

  valueObjectMeta,
  onChange,
}: SectionCommentProps) {
  return (
    <FormSection title={title} description={description}>
      <CMFormInputTextArea
        editMode={editMode}
        noHeader={true}
        minRows={4}
        value={value}
        valueObjectMeta={valueObjectMeta}
        onChange={onChange}
      />
    </FormSection>
  );
});
