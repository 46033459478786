import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * 臨床用株の在庫確認状態(値オブジェクト)
 *
 *
 *
 * unchecked:
 * checked:  */
export type ClinicalStocksInventoryCheckState = z.infer<
  typeof clinicalStocksInventoryCheckStateMeta.schema
>;
/**
 * 臨床用株の在庫確認状態(値オブジェクト)のメタデータ。
 *
 *  */
export const clinicalStocksInventoryCheckStateMeta = new ValueObjectMetaEnum<
  ["unchecked", "checked"]
>(
  "ClinicalStocksInventoryCheckState",
  "\u81E8\u5E8A\u7528\u682A\u306E\u5728\u5EAB\u78BA\u8A8D\u72B6\u614B",
  messageId(
    "lbl.\u81E8\u5E8A\u7528\u682A\u306E\u5728\u5EAB\u78BA\u8A8D\u72B6\u614B"
  ),
  ["unchecked", "checked"],
  {
    unchecked:
      "code.\u81E8\u5E8A\u7528\u682A\u306E\u5728\u5EAB\u78BA\u8A8D\u72B6\u614B.\u672A\u78BA\u8A8D",
    checked:
      "code.\u81E8\u5E8A\u7528\u682A\u306E\u5728\u5EAB\u78BA\u8A8D\u72B6\u614B.\u78BA\u8A8D\u6E08",
  }
);
