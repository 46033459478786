import { ComponentStyleProps } from "../../../../../lib/styles/props/component-style-props";
import { FormSection } from "../../../../ui/form/FormSection/FormSection";
import { CMFormInputDate } from "@pscsrvlab/psc-react-components";
import React from "react";
import { useAppTranslation } from "../../../../../hooks/use-app-translation";
import { YearMonthDay } from "../../../../../lib/object/value/year-month-day";

export type ResultNotificationSectionApprovalConditionConfirmationDateProps = {
  editMode: "editable" | "readOnly";

  value?: YearMonthDay;
  onChange?: (value: YearMonthDay | undefined) => void;
} & ComponentStyleProps;

export const ResultNotificationSectionApprovalConditionConfirmationDate = ({
  editMode,

  value,
  onChange,

  sx,
  ...rest
}: ResultNotificationSectionApprovalConditionConfirmationDateProps) => {
  const { t } = useAppTranslation();
  return (
    <FormSection title={t("lbl.条件対応確認日")} sx={sx} {...rest}>
      <CMFormInputDate editMode={editMode} value={value} onChange={onChange} />
    </FormSection>
  );
};
