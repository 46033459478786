import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";
import React from "react";
import { SearchInquiryApiArg } from "../../../../../../store/api/generated/stock-request-api";
import {
  convertToNumberFromResultsA,
  convertToResultsAFromNumber,
} from "../../../../../../lib/util/search-util";
import { inquirySortConditionMeta } from "../../../../../../lib/object/value/inquiry-sort-condition";
import { numberOfSearchResultsAMeta } from "../../../../../../lib/object/value/number-of-search-results-a";
import { ButtonSearchConditionRadio } from "../../../../search/button/ButtonSearchConditionRadio/ButtonSearchConditionRadio";
import { ButtonSearchConditionInstitution } from "../../../../search/button/ButtonSearchConditionInstitution/ButtonSearchConditionInstitution";
import log from "loglevel";
import { ButtonSearchConditionProject } from "../../../../search/button/ButtonSearchConditionProject/ButtonSearchConditionProject";
import { ButtonSearchConditionDocument } from "../../../../search/button/ButtonSearchConditionDocument/ButtonSearchConditionDocument";
import { useAppSelector } from "../../../../../../hooks/redux-hooks";
import { selectHasRole } from "../../../../../../store/auth/slice";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { SearchConditionSidebar } from "../../../../../ui/search/SearchConditionSidebar/SearchConditionSidebar";
import { SearchConditionSidebarSection } from "../../../../../ui/search/SearchConditionSidebar/_components/SearchConditionSidebarSection/SearchConditionSidebarSection";

export type SearchConditionSidebarInquiryProps = {
  type: "root" | "project" | "document";

  /**
   * 検索条件
   */
  searchArgs: SearchInquiryApiArg;

  /**
   * 検索条件が変更されたタイミングで呼び出し元に値を返却する
   */
  onChange: (searchArgs: SearchInquiryApiArg) => void;
} & ComponentStyleProps;

export const SearchConditionSidebarInquiry = ({
  type,

  searchArgs,

  onChange,

  sx,
  ...rest
}: SearchConditionSidebarInquiryProps) => {
  const { t } = useAppTranslation();

  // ログインユーザー情報
  const { isOfficeMember } = useAppSelector(selectHasRole);

  return (
    <SearchConditionSidebar sx={sx} {...rest}>
      <SearchConditionSidebarSection>
        {/* 並び順 */}
        <ButtonSearchConditionRadio
          value={searchArgs.sortCondition}
          label={t("lbl.並び順")}
          valueObjectMeta={inquirySortConditionMeta}
          onChange={(value) => {
            onChange?.({
              ...searchArgs,
              sortCondition: value,
            });
          }}
        />
        {/* 表示件数 */}
        <ButtonSearchConditionRadio
          label={t("lbl.表示件数")}
          value={convertToResultsAFromNumber(searchArgs.limit)}
          valueObjectMeta={numberOfSearchResultsAMeta}
          onChange={(value: string | undefined) => {
            onChange?.({
              ...searchArgs,
              limit: convertToNumberFromResultsA(value),
            });
          }}
        />
        {/*機関名*/}
        {isOfficeMember && type === "root" && (
          <ButtonSearchConditionInstitution
            value={searchArgs.institutionId}
            label={t("lbl.機関名")}
            placeholder={""}
            onChange={(value: number | null) => {
              log.debug("機関名", value);
              onChange?.({
                ...searchArgs,
                institutionId: value ?? undefined,
              });
            }}
          />
        )}
        {/*案件管理番号*/}
        {type === "root" && (
          <ButtonSearchConditionProject
            value={searchArgs.projectId}
            label={t("lbl.案件管理番号")}
            placeholder={""}
            searchResultNone={t("mes.該当案件なしメッセージ")}
            onChange={(value: number | null) => {
              onChange({
                ...searchArgs,
                projectId: value ?? undefined,
              });
            }}
          />
        )}

        {/*書類管理番号*/}
        {(type === "root" || type === "project") && (
          <ButtonSearchConditionDocument
            value={searchArgs.documentId}
            label={t("lbl.書類管理番号")}
            placeholder={""}
            searchResultNone={t("mes.該当書類なしメッセージ")}
            onChange={(value: number | null) => {
              onChange({
                ...searchArgs,
                documentId: value ?? undefined,
              });
            }}
          />
        )}
      </SearchConditionSidebarSection>
    </SearchConditionSidebar>
  );
};
