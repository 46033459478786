import {
  messageId,
  PropertyMeta,
  ValueObjectMetaComposite,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { collaborativeResearchContractStateWithFoundationCustomValidation } from "../custom-validation/collaborative-research-contract-state-with-foundation";
import { collaborativeResearchContractStateWithFoundationTypeMeta } from "../value/collaborative-research-contract-state-with-foundation-type";
import { yearMonthMeta } from "../value/year-month";
/**
 * 財団との共同研究契約状況(値オブジェクト)
 *
 *  */
export type CollaborativeResearchContractStateWithFoundation = z.infer<
  typeof schema
>;
export type CollaborativeResearchContractStateWithFoundationSaved = z.infer<
  typeof savedSchema
>;
const minimalSchema = z.object({
  state: collaborativeResearchContractStateWithFoundationTypeMeta.minimalSchema,
  estimatedAgreementYearMonth: yearMonthMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
const savedMinimalSchema = z.object({
  state:
    collaborativeResearchContractStateWithFoundationTypeMeta.savedMinimalSchema,
  estimatedAgreementYearMonth: yearMonthMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
const schema = z.object({
  state: collaborativeResearchContractStateWithFoundationTypeMeta.schema,
  estimatedAgreementYearMonth: yearMonthMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
});
const savedSchema = z.object({
  state: collaborativeResearchContractStateWithFoundationTypeMeta.savedSchema,
  estimatedAgreementYearMonth: yearMonthMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
const jsonSchema = z.object({
  state: collaborativeResearchContractStateWithFoundationTypeMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  estimatedAgreementYearMonth: yearMonthMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
/**
 * 財団との共同研究契約状況(値オブジェクト)のメタデータ。
 *
 *
 *
 * 以下のカスタムバリデーションを、 collaborativeResearchContractStateWithFoundationCustomValidation にて行う。
 * - (任意) 共同契約の状況で協議中の場合に締結見込みを必須とする。 (エラーメッセージID=mes.共同研究契約の状況協議中締結見込み未入力エラー) */
export const collaborativeResearchContractStateWithFoundationMeta =
  new ValueObjectMetaComposite(
    "CollaborativeResearchContractStateWithFoundation",
    "\u8CA1\u56E3\u3068\u306E\u5171\u540C\u7814\u7A76\u5951\u7D04\u72B6\u6CC1",
    messageId(
      "lbl.\u8CA1\u56E3\u3068\u306E\u5171\u540C\u7814\u7A76\u5951\u7D04\u72B6\u6CC1"
    ),
    minimalSchema,
    savedMinimalSchema,
    schema,
    savedSchema,
    jsonSchema,
    undefined,
    collaborativeResearchContractStateWithFoundationCustomValidation,
    {
      state: new PropertyMeta(
        "state",
        "\u8CA1\u56E3\u3068\u306E\u5171\u540C\u7814\u7A76\u5951\u7D04\u72B6\u6CC1\u533A\u5206",
        messageId(
          "lbl.\u8CA1\u56E3\u3068\u306E\u5171\u540C\u7814\u7A76\u5951\u7D04\u72B6\u6CC1\u533A\u5206"
        ),
        "always",
        false,
        collaborativeResearchContractStateWithFoundationTypeMeta
      ),
      estimatedAgreementYearMonth: new PropertyMeta(
        "estimatedAgreementYearMonth",
        "\u7DE0\u7D50\u898B\u8FBC\u307F\u5E74\u6708",
        messageId("lbl.\u7DE0\u7D50\u898B\u8FBC\u307F\u5E74\u6708"),
        "none",
        false,
        yearMonthMeta
      ),
    }
  );
