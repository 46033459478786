import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";
import { Text, VStack } from "@chakra-ui/react";
import React, { ReactNode, useMemo } from "react";
import { hasValue } from "../../../../../../lib/util/common-util";

export type SearchConditionSidebarSectionProps = {
  title?: string;

  children: ReactNode;
} & ComponentStyleProps;

export const SearchConditionSidebarSection = ({
  title,
  children,

  sx,
  ...rest
}: SearchConditionSidebarSectionProps) => {
  const pl = useMemo(() => (hasValue(title) ? "4px" : "0"), [title]);

  const capitalizedTitle = title;

  return (
    <VStack pl={"4px"} alignItems={"stretch"} sx={sx} {...rest}>
      {hasValue(capitalizedTitle) && (
        <Text
          sx={{
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "14px",
          }}
          alignSelf={"flex-start"}
        >
          {capitalizedTitle}
        </Text>
      )}
      <VStack pl={pl} alignItems={"stretch"} spacing={"8px"}>
        {children}
      </VStack>
    </VStack>
  );
};
