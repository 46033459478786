import { useAppTranslation } from "../../../../hooks/use-app-translation";
import { Button, Spacer, Spinner, Text } from "@chakra-ui/react";
import { ComponentStyleProps } from "../../../../lib/styles/props/component-style-props";
import { Icon } from "@chakra-ui/icons";
import { MdFileDownload } from "react-icons/md";

/**
 * CSVダウンロードボタン
 */
export type CSVDownloadButtonProps = {
  isDownLoading?: boolean;
  onClick?: () => void;
} & ComponentStyleProps;
export const CSVDownloadButton = ({
  isDownLoading,
  onClick,

  sx,
  ...rest
}: CSVDownloadButtonProps) => {
  const { t } = useAppTranslation();
  return (
    <Button
      disabled={isDownLoading}
      onClick={onClick}
      colorScheme={"blue"}
      size={"sm"}
      sx={sx}
      {...rest}
    >
      {/*ダウンロード状態によってアイコンを変更する*/}
      {isDownLoading ? (
        <>
          <Spinner size={"sm"} borderWidth={2} />
          <Spacer width={2} />
          <Text>{t("mes.CSVダウンロード中")}</Text>
        </>
      ) : (
        <>
          <Icon as={MdFileDownload} />
          <Spacer width={1} />
          <Text>{t("btn.CSVダウンロードボタン")}</Text>
        </>
      )}
    </Button>
  );
};
