import { ComponentStyleProps } from "../../../../../lib/styles/props/component-style-props";
import {
  CMButtonSearchConditionMaster,
  SearchDropdownDisplayValue,
} from "@pscsrvlab/psc-react-components";
import log from "loglevel";
import { hasValue } from "../../../../../lib/util/common-util";
import React, { useMemo } from "react";
import useCustomToast from "../../../../../hooks/use-custom-toast";
import { stockRequestApi } from "../../../../../store/api/enhanced-api";
import { errorMessageOf } from "../../../../../lib/util/error-util";
import { useAppTranslation } from "../../../../../hooks/use-app-translation";

export type ButtonSearchConditionDocumentProps = {
  label: string;
  placeholder?: string;

  /**
   * 値。書類ID。
   */
  value?: number | null;

  hideClearButton?: boolean;
  searchResultNone: string;

  onChange?: (value: number | null) => void;
} & ComponentStyleProps;
export const ButtonSearchConditionDocument = ({
  label,
  placeholder = "",

  value = null,

  hideClearButton = false,
  searchResultNone,

  onChange,

  sx,
  ...rest
}: ButtonSearchConditionDocumentProps) => {
  const { t } = useAppTranslation();
  const { errorToast } = useCustomToast();

  const labelSubmit = useMemo(() => t("btn.確定ボタン"), [t]);
  const labelClear = useMemo(() => t("btn.クリアボタン"), [t]);

  const [triggerListDocumentQuery] = stockRequestApi.useLazyListDocumentQuery();

  const [triggerGetDocumentQuery] = stockRequestApi.useLazyGetDocumentQuery();

  const toDisplayValue: (
    value: number,
  ) => Promise<SearchDropdownDisplayValue> = async (value) => {
    // log.debug(`toDisplayValue: value=${value}`);
    try {
      const document = await triggerGetDocumentQuery({
        documentId: value,
      }).unwrap();
      return {
        headingLabel: undefined,
        mainLabel: document.documentControlNumber ?? "",
      };
    } catch (e) {
      log.error(errorMessageOf(e));
      return { headingLabel: undefined, mainLabel: "" };
    }
  };

  const handleSearch: (text: string) => Promise<number[]> = async (
    text: string,
  ) => {
    try {
      const selectableDocuments: number[] = (
        await triggerListDocumentQuery({
          documentControlNumber: text,
        }).unwrap()
      )
        .map((v) => v?.id)
        .filter(hasValue);
      return selectableDocuments;
    } catch (e) {
      log.error(errorMessageOf(e));
      errorToast(t("mes.汎用エラーメッセージ"));
      return [];
    }
  };

  const capitalizedLabel = label;

  return (
    <CMButtonSearchConditionMaster<number>
      label={capitalizedLabel}
      placeholder={placeholder}
      menuShowMaxCount={30}
      value={value}
      hideClearButton={hideClearButton}
      labelSubmit={labelSubmit}
      labelClear={labelClear}
      searchResultNone={searchResultNone}
      toDisplayValue={toDisplayValue}
      onSearch={handleSearch}
      onChange={onChange}
      sx={sx}
      {...rest}
    />
  );
};
