// noinspection NonAsciiCharacters,JSNonASCIINames

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { makeZodI18nMap } from "zod-i18n-map";
import { z } from "zod";
import { capitalizeFirstLetter } from "./lib/util/common-util";

i18n
  // .use(I18NextHttpBackend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: "ja",

    returnNull: false,

    interpolation: {
      escapeValue: false, // react already safes from xss
      skipOnVariables: false, // for nested interpolation: https://www.i18next.com/translation-function/nesting#passing-nesting-to-interpolated
    },

    react: {
      bindI18n: "languageChanged",
      bindI18nStore: "added",
      transEmptyNodeValue: "",
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["br", "strong", "i"],
      useSuspense: true,
    },
  });

// 参考: https://stackoverflow.com/a/66687895/12579447
// 2nd Solutionを参考に。
i18n.services.formatter?.add("capitalize", (value, _lng, _options) => {
  return capitalizeFirstLetter(value);
});

i18n.addResourceBundle("en", "translation", {
  capitalize: "{{value, uppercase}}",
});
i18n.addResourceBundle("ja", "translation", {
  capitalize: "{{value, uppercase}}",
});

z.setErrorMap(makeZodI18nMap({ ns: "commonValidation" }));

export default i18n;
