import { Button, Container, Input, Text, VStack } from "@chakra-ui/react";
import { FormEvent, useState } from "react";
import { useAppDispatch } from "../../../../hooks/redux-hooks";
import { resetPassword } from "../../../../store/auth/slice";
import { Form, useNavigate } from "react-router-dom";
import { CMButtonBack } from "@pscsrvlab/psc-react-components";
import useCustomToast from "../../../../hooks/use-custom-toast";
import { mailAddressMeta } from "../../../../lib/object/value/mail-address";
import log from "loglevel";
import { useAppTranslation } from "../../../../hooks/use-app-translation";
import { FrameUpperLeftButton } from "../../../ui/frame/FrameUpperLeftButton/FrameUpperLeftButton";

/**
 * パスワードリセット依頼ページ
 */
export const FN10S05ResetPasswordRequest = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useAppTranslation();
  const { errorToast } = useCustomToast();

  // メールアドレス
  const [mailAddress, setMailAddress] = useState("");
  // ロード状態
  const [isLoading, setIsLoading] = useState(false);

  /**
   * 送信ボタン押下時イベント
   * @param event イベント情報
   */
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Submit防止
    setIsLoading(true);
    // バリデーションエラーがある場合は中断
    if (!validate()) {
      setIsLoading(false);
      return;
    }
    // パスワードリセット用検証コード送信処理を実行
    await executeSendResetPasswordCode();
  };

  /**
   * バリデーションチェック
   * @return true: 非エラー / false: エラー
   */
  const validate = () => {
    // メールアドレスが未入力の場合
    if (!mailAddress) {
      errorToast(
        t("mes.必須チェックエラー2", { name: t("lbl.メールアドレス") }),
      );
      return false;
    }
    // メールアドレスの形式では無い場合
    if (mailAddressMeta.validate(mailAddress).state === "error") {
      errorToast(t("mes.メールアドレス形式チェックエラー"));
      return false;
    }
    return true;
  };

  /**
   * パスワードリセット用検証コード送信処理
   */
  const executeSendResetPasswordCode = async () => {
    try {
      // 非同期処理を実行
      await dispatch(resetPassword({ username: mailAddress })).unwrap();
      // パスワードリセット画面へ遷移
      navigate("/reset-password/confirm", {
        state: { mailAddress: mailAddress },
      });
    } catch (e) {
      log.error("パスワードリセット用検証コード送信処理でエラー発生：", e);

      // ロードを中断
      setIsLoading(false);

      // エラーの場合はやり直し
      setMailAddress("");
      errorToast(t("mes.汎用エラーメッセージ"));
      return;
    }
  };

  return (
    <VStack alignSelf={"stretch"} pt={"24px"} pb={"24px"} overflow={"auto"}>
      <FrameUpperLeftButton sx={{ alignSelf: "flex-start" }}>
        <CMButtonBack
          labelBack={t("btn.ログイン画面に戻るボタン")}
          onClick={() => navigate("/login")}
        />
      </FrameUpperLeftButton>
      <Container maxW={"600px"}>
        <Form onSubmit={handleSubmit}>
          <VStack align={"center"} spacing={"30px"} mt={"40px"}>
            <Text fontSize={"24px"} fontWeight={"bold"}>
              {t("lbl.パスワードリセット依頼画面タイトル")}
            </Text>
            <Text fontSize={"14px"}>{t("gdc.パスワードリセット依頼案内")}</Text>
            <Input
              type={"text"}
              value={mailAddress}
              placeholder={t("lbl.メールアドレス")}
              onChange={(e) => setMailAddress(e.target.value)}
              w={"300px"}
            ></Input>
            <Button
              type={"submit"}
              isLoading={isLoading}
              disabled={isLoading}
              colorScheme={"teal"}
              w={"150px"}
            >
              {t("btn.送信ボタン")}
            </Button>
          </VStack>
        </Form>
      </Container>
    </VStack>
  );
};
