import {
  messageId,
  PropertyMeta,
  ValueObjectMetaString,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.string().max(10000);
const savedMinimalSchema = z.string().max(10000);
const schema = z.string().min(1).max(10000);
const savedSchema = z.string().min(1).max(10000);
const jsonSchema = z.string();
/**
 * 質疑応答内容(値オブジェクト)のメタデータ。
 *
 *  */
export const inquiryContentMeta = new ValueObjectMetaString(
  "InquiryContent",
  "\u8CEA\u7591\u5FDC\u7B54\u5185\u5BB9",
  messageId("lbl.\u8CEA\u7591\u5FDC\u7B54\u5185\u5BB9"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
