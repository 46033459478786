import { Container, Flex, HStack, VStack } from "@chakra-ui/react";
import useCustomToast from "../../../../../../hooks/use-custom-toast";

import React, { useCallback, useMemo, useState } from "react";
import { selectHasRole } from "../../../../../../store/auth/slice";
import log from "loglevel";
import {
  CloseResult,
  PromiseModalOptionsCancel,
  PromiseModalOptionsCancelProps,
} from "../../../../../ui/modal/PromiseModalOptionsCancel/PromiseModalOptionsCancel";
import { FrameUpperRightButton } from "../../../../../ui/frame/FrameUpperRightButton/FrameUpperRightButton";
import { CMButton, CMExtendedMenu } from "@pscsrvlab/psc-react-components";
import { SectionReviewConferenceText } from "../SectionReviewConferenceText/SectionReviewConferenceText";
import { reviewConferenceNameMeta } from "../../../../../../lib/object/value/review-conference-name";
import { SectionReviewConferenceTime } from "../SectionReviewConferenceTime/SectionReviewConferenceTime";
import { venueMeta } from "../../../../../../lib/object/value/venue";
import { SectionReviewConferenceTextArea } from "../SectionReviewConferenceTextArea/SectionReviewConferenceTextArea";
import { reviewConferenceAgendaMeta } from "../../../../../../lib/object/value/review-conference-agenda";
import { SectionReviewConferenceFileUpload } from "../SectionReviewConferenceFileUpload/SectionReviewConferenceFileUpload";
import { freeTextMeta } from "../../../../../../lib/object/value/free-text";
import { SectionReviewConferenceCardList } from "../SectionReviewConferenceCardList/SectionReviewConferenceCardList";
import { useSelector } from "react-redux";
import { BackPageButton } from "../../../../../ui/button/BackPageButton/BackPageButton";
import {
  useConcludeReviewConferenceMutation,
  useCreateReviewConferenceMutation,
  useDeleteReviewConferenceMutation,
  useNotifyReviewConferenceMutation,
  useUpdateReviewConferenceMutation,
} from "../../../../../../store/api/generated/stock-request-api";
import { errorMessageOf } from "../../../../../../lib/util/error-util";
import { ReviewConferenceViewModel } from "../../ReviewConferenceDetailsPage";
import { ARRAY_EMPTY } from "../../../../../../lib/constant/constant";
import { reviewConferenceMeta } from "../../../../../../lib/object/entity/review-conference";
import { useCreateJsonPatchOperations } from "../../../../../../hooks/use-create-json-patch-operations";
import { isBeforeDate } from "../../../../../../lib/util/common-date-util";
import {
  hasDuplicates,
  isNullish,
} from "../../../../../../lib/util/common-util";
import { SectionConferenceTitle } from "../SectionConferenceTitle/SectionConferenceTitle";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { EditButton } from "../../../../../ui/button/EditButton/EditButton";
import { FrameUpperLeftButton } from "../../../../../ui/frame/FrameUpperLeftButton/FrameUpperLeftButton";
import { useLeaveEditingPrompt } from "../../../../../../hooks/use-leave-editing-prompt";
import { YearMonthDay } from "../../../../../../lib/object/value/year-month-day";
import { SaveChangesButton } from "../../../../../ui/button/SaveChangesButton/SaveChangesButton";
import { EditEndButton } from "../../../../../ui/button/EditEndButton/EditEndButton";
import { TitleText } from "../../../../../ui/text/TitleText/TitleText";
import { AttachmentFileInformation } from "../../../../../../lib/object/value/attachment-file-information";
import { ReviewTarget } from "../../../../../../lib/object/value/review-target";
import { TFunction } from "i18next";

export type ReviewConferenceContentProps = {
  /**
   * 表示モード
   * create: 新規作成
   * view-or-edit: 参照または編集
   */
  displayMode: "create" | "view-or-edit";

  /**
   * 編集可能モード
   */
  initialEditMode?: "editable" | "readOnly";

  /**
   * 選択中の審査会情報
   */
  initialReviewConferenceData: ReviewConferenceViewModel;
};

/**
 * FN50S02 審査会詳細
 * 審査会詳細の表示内容を制御する
 */
export const ReviewConferenceContent = ({
  displayMode,
  initialEditMode = "readOnly",
  initialReviewConferenceData,
}: ReviewConferenceContentProps) => {
  const { t } = useAppTranslation();
  const { successToast, errorToast, validationErrorToast } = useCustomToast();
  const { isOfficeMember, isCommitteeMember } = useSelector(selectHasRole);

  const reviewConferenceId = useMemo(
    () => initialReviewConferenceData?.id,
    [initialReviewConferenceData?.id],
  );

  // 画面モード
  const [editMode, setEditMode] = useState<"editable" | "readOnly">(
    isOfficeMember ? initialEditMode : "readOnly",
  );

  const { navigateWithoutPrompt } = useLeaveEditingPrompt({
    skip: editMode === "readOnly",
  });

  const [editContent, setEditContent] = useState<ReviewConferenceViewModel>(
    initialReviewConferenceData,
  );

  const { createJsonPatchOperations } =
    useCreateJsonPatchOperations(reviewConferenceMeta);

  // 審査会作成
  const [createReviewConference] = useCreateReviewConferenceMutation();
  // 審査会更新
  const [updateReviewConference] = useUpdateReviewConferenceMutation();
  // 審査会削除
  const [deleteReviewConference] = useDeleteReviewConferenceMutation();

  // 審査会開催内容通知
  const [notifyReviewConference] = useNotifyReviewConferenceMutation();
  // 審査会審査結果確定
  const [concludeReviewConference] = useConcludeReviewConferenceMutation();

  /**
   * 画面モード判定:新規登録の場合true
   */
  const isCreate = useMemo(() => {
    return displayMode === "create";
  }, [displayMode]);

  /**
   * 画面モード判定:参照の場合true
   */
  const isReadOnly = useMemo(() => {
    return displayMode === "view-or-edit" && editMode === "readOnly";
  }, [displayMode, editMode]);

  /**
   * 画面モード判定:更新の場合true
   */
  const isEditable = useMemo(() => {
    return displayMode === "view-or-edit" && editMode === "editable";
  }, [displayMode, editMode]);

  /**
   * 画面項目が編集されるたびに呼ばれる。
   */
  const handleChange = useCallback(
    (
      change: (before: ReviewConferenceViewModel) => ReviewConferenceViewModel,
    ) => {
      setEditContent(change);
    },
    [],
  );

  // 一覧に戻る確認モーダルの表示制御設定
  const [notifyModalConfig, setNotifyModalConfig] = React.useState<
    PromiseModalOptionsCancelProps | undefined
  >();

  /**
   * 一覧に戻るボタンイベント
   */
  const handleBackPage = useCallback(async () => {
    // 参照モードの場合はチェック無しで一覧へ遷移
    if (displayMode === "view-or-edit") {
      // 『審査会一覧』（FN50-S01）画面へ遷移
      await navigateWithoutPrompt("/review-conference");
    }

    // 新規作成の場合は作成を中断し、一覧に戻る確認メッセージを表示。
    const result = await new Promise<CloseResult>((resolve) => {
      setEditCancelModalConfig({
        onCloseModal: resolve,
      });
    });

    // モーダルを非表示にする
    setEditCancelModalConfig(undefined);
    if (result !== "OK") return;

    // 『審査会一覧』（FN50-S01）画面へ遷移
    await navigateWithoutPrompt("/review-conference");
  }, [displayMode, navigateWithoutPrompt]);

  /**
   * 開催内容を通知するボタンイベント
   */
  const handleNotify = useCallback(async () => {
    if (isNullish(reviewConferenceId)) return;

    // 確認モーダルの表示
    const result = await new Promise<CloseResult>((resolve) => {
      setNotifyModalConfig({
        onCloseModal: resolve,
      });
    });

    // モーダルを非表示にする
    setNotifyModalConfig(undefined);
    if (result !== "OK") return;

    try {
      // 委員会、業務執行理事宛に開催内容を通知する
      await notifyReviewConference({
        reviewConferenceId,
      }).unwrap();

      successToast(t("mes.開催内容通知メッセージ"));
    } catch (e) {
      errorToast(t("mes.審査会開催内容通知失敗エラー"));
      log.error(errorMessageOf(e));
    }
  }, [successToast, errorToast, notifyReviewConference, reviewConferenceId, t]);

  /**
   * 編集ボタンイベント
   */
  const handleEdit = useCallback(() => {
    // 参照モードに切り替え
    setEditMode("editable");
  }, []);

  // 編集モード終了確認モーダルの表示制御設定
  const [editCancelModalConfig, setEditCancelModalConfig] = React.useState<
    PromiseModalOptionsCancelProps | undefined
  >();

  /**
   * 編集終了イベント
   */
  const handleEditCancel = useCallback(async () => {
    // 確認モーダルの表示
    const result = await new Promise<CloseResult>((resolve) => {
      setEditCancelModalConfig({
        onCloseModal: resolve,
      });
    });

    // モーダルを非表示にする
    setEditCancelModalConfig(undefined);
    if (result !== "OK") return;

    // 表示内容を初期化
    setEditContent(initialReviewConferenceData);

    // 参照モードに切り替え
    setEditMode("readOnly");
  }, [initialReviewConferenceData]);

  // 保存確認モーダルの表示制御設定
  const [saveModalConfig, setSaveModalConfig] = React.useState<
    PromiseModalOptionsCancelProps | undefined
  >();

  const validationFilterOutPaths = useMemo(() => {
    const arr: string[] = [];
    editContent.reviewTarget.map((elem, index) => {
      arr.push("reviewTarget." + index + ".reviewId");
    });
    return arr;
  }, [editContent.reviewTarget]);

  const validationRenames = useMemo(() => {
    const arr: { path: string; fullPropertyDisplayName: string }[] = [];
    editContent.reviewTarget.map((elem, index) => {
      arr.push({
        path: "reviewTarget." + index + ".documentId",
        fullPropertyDisplayName:
          t("lbl.審査対象") +
          "(" +
          (index + 1) +
          ") - " +
          t("lbl.書類管理番号"),
      });
    });
    return arr;
  }, [editContent.reviewTarget, t]);

  /**
   * 変更を保存するボタンイベント
   */
  const handleSave = useCallback(async () => {
    if (isNullish(reviewConferenceId)) return;

    const reviewConference: ReviewConferenceViewModel = {
      ...editContent,
      created: {},
      updated: {},
    };

    // バリデーションチェック
    const validationState = reviewConferenceMeta.validate(reviewConference);
    if (validationState.state === "error") {
      validationErrorToast(
        validationState.errors,
        validationFilterOutPaths,
        validationRenames,
      );
      return;
    }

    const additionalValidationResult = additionalValidate(t, editContent);
    if (additionalValidationResult.state === "error") {
      errorToast(additionalValidationResult.errorMessage);
      return;
    }

    // 確認モーダルの表示
    const result = await new Promise<CloseResult>((resolve) => {
      setSaveModalConfig({
        onCloseModal: resolve,
      });
    });

    // モーダルを非表示にする
    setSaveModalConfig(undefined);
    if (result !== "OK") return;

    try {
      const jsonPatchOperations = createJsonPatchOperations(
        initialReviewConferenceData,
        reviewConference,
      );

      // 審査会更新処理
      await updateReviewConference({
        reviewConferenceId,
        commonUpdateRequest: {
          patchOperations: jsonPatchOperations,
        },
      }).unwrap();

      setEditMode("readOnly");
    } catch (e) {
      errorToast(t("mes.審査会更新失敗エラー"));
      log.error(errorMessageOf(e));
    }
  }, [
    createJsonPatchOperations,
    editContent,
    errorToast,
    initialReviewConferenceData,
    reviewConferenceId,
    t,
    updateReviewConference,
    validationErrorToast,
    validationFilterOutPaths,
    validationRenames,
  ]);

  // 審査会削除確認モーダルの表示制御設定
  const [confirmDeleteModalConfig, setConfirmDeleteModalConfig] =
    React.useState<PromiseModalOptionsCancelProps | undefined>();

  /**
   * 審査会削除イベント
   */
  const handleDelete = useCallback(async () => {
    if (isNullish(reviewConferenceId)) return;

    // 確認モーダルの表示
    const result = await new Promise<CloseResult>((resolve) => {
      setConfirmDeleteModalConfig({
        onCloseModal: resolve,
      });
    });

    // モーダルを非表示にする
    setConfirmDeleteModalConfig(undefined);
    if (result !== "OK") return;

    try {
      // 審査が紐づいている場合は削除不可
      if (initialReviewConferenceData.reviewTarget.length !== ARRAY_EMPTY) {
        errorToast(t("mes.審査会削除失敗エラー"));
        return;
      }

      // 審査会削除処理
      await deleteReviewConference({
        reviewConferenceId,
      }).unwrap();
    } catch (e) {
      errorToast(t("mes.汎用エラーメッセージ"));
      log.error(errorMessageOf(e));
      return;
    }

    // 『審査会一覧』（FN50-S01）画面へ遷移
    await navigateWithoutPrompt("/review-conference");
  }, [
    deleteReviewConference,
    errorToast,
    initialReviewConferenceData.reviewTarget.length,
    navigateWithoutPrompt,
    reviewConferenceId,
    t,
  ]);

  // 委員会作成確認モーダルの表示制御設定
  const [createModalConfig, setCreateModalConfig] = React.useState<
    PromiseModalOptionsCancelProps | undefined
  >();

  /**
   * 審査会を作成するボタンイベント
   */
  const handleCreate = useCallback(async () => {
    const reviewConference: ReviewConferenceViewModel = {
      ...editContent,
      created: {},
      updated: {},
    };

    // バリデーションチェック
    const validationState = reviewConferenceMeta.validate(reviewConference);
    if (validationState.state === "error") {
      validationErrorToast(
        validationState.errors,
        validationFilterOutPaths,
        validationRenames,
      );
      return;
    }

    const additionalValidationResult = additionalValidate(t, editContent);
    if (additionalValidationResult.state === "error") {
      errorToast(additionalValidationResult.errorMessage);
      return;
    }

    // 確認モーダルの表示
    const result = await new Promise<CloseResult>((resolve) => {
      setCreateModalConfig({
        onCloseModal: resolve,
      });
    });

    // モーダルを非表示にする
    setCreateModalConfig(undefined);
    if (result !== "OK") return;

    try {
      const content = reviewConferenceMeta.toJsonObjectOrNull(reviewConference);
      if (isNullish(content)) return;

      // 審査会作成処理
      await createReviewConference({
        reviewConference: content,
      }).unwrap();

      // 『審査会一覧』（FN50-S01）画面へ遷移
      await navigateWithoutPrompt("/review-conference");
    } catch (e) {
      errorToast(t("mes.審査会作成失敗エラー"));
      log.error(errorMessageOf(e));
      return;
    }
  }, [
    createReviewConference,
    editContent,
    errorToast,
    navigateWithoutPrompt,
    t,
    validationErrorToast,
    validationFilterOutPaths,
    validationRenames,
  ]);

  // 結果確定終了確認モーダルの表示制御設定
  const [fixedModalConfig, setFixedModalConfig] = React.useState<
    PromiseModalOptionsCancelProps | undefined
  >();

  /**
   * 結果確定ボタンイベント
   */
  const handleFix = useCallback(async () => {
    // 審査結果確定時のバリデーション
    // 開催日が入力済である。
    if (
      isNullish(editContent.reviewConferenceDate) ||
      isNullish(editContent.startDatetime) ||
      isNullish(editContent.endDatetime)
    ) {
      errorToast(t("mes.必須チェックエラー2", { name: t("lbl.開催日時") }));
      return;
    }

    const additionalValidationResult = additionalValidate(t, editContent);
    if (additionalValidationResult.state === "error") {
      errorToast(additionalValidationResult.errorMessage);
      return;
    }

    // 審査結果がすべて入力済である
    if (editContent.reviewTarget.some((v) => isNullish(v.reviewResult))) {
      errorToast(t("mes.必須チェックエラー2", { name: t("lbl.審査結果") }));
      return;
    }
    // 「承認」以外の場合の理由は、審査結果が「承認」以外の場合は必須
    if (
      editContent.reviewTarget.some(
        (v) => v.reviewResult !== "approved" && v.unapprovalReason === "",
      )
    ) {
      errorToast(t("mes.承認以外の場合の理由必須エラーメッセージ"));
      return;
    }

    // 確認モーダルの表示
    const result = await new Promise<CloseResult>((resolve) => {
      setFixedModalConfig({
        onCloseModal: resolve,
      });
    });

    // モーダルを非表示にする
    setFixedModalConfig(undefined);
    if (result !== "OK") return;

    const reviewConferenceId = initialReviewConferenceData.id;
    if (isNullish(reviewConferenceId)) return;
    try {
      // 審査会の結果を確定する。
      await concludeReviewConference({
        reviewConferenceId,
      }).unwrap();

      await navigateWithoutPrompt(
        `/review-conference/${reviewConferenceId}/concluded`,
      );
    } catch (e) {
      errorToast(t("mes.審査会審査結果確定失敗エラー"));
      log.error(errorMessageOf(e));
      return;
    }
  }, [
    concludeReviewConference,
    editContent,
    errorToast,
    initialReviewConferenceData.id,
    navigateWithoutPrompt,
    t,
  ]);

  const handleChangeReviewConferenceName = useCallback(
    (v: string) => {
      handleChange((before) => ({
        ...before,
        reviewConferenceName: v,
      }));
    },
    [handleChange],
  );
  const handleChangeDateAndTime = useCallback(
    (v: {
      reviewConferenceDate: YearMonthDay | undefined;
      startDatetime: Date | undefined;
      endDatetime: Date | undefined;
    }) => {
      handleChange((before) => ({
        ...before,
        reviewConferenceDate: v.reviewConferenceDate,
        startDatetime: v.startDatetime,
        endDatetime: v.endDatetime,
      }));
    },
    [handleChange],
  );
  const handleChangeVenue = useCallback(
    (v: string) => {
      handleChange((before) => ({
        ...before,
        venue: v,
      }));
    },
    [handleChange],
  );
  const handleChangeAgenda = useCallback(
    (v: string) => {
      handleChange((before) => ({
        ...before,
        agenda: v,
      }));
    },
    [handleChange],
  );
  const handleChangeAttachmentFiles = useCallback(
    (
      change: (
        before: AttachmentFileInformation[],
      ) => AttachmentFileInformation[],
    ) => {
      handleChange((before) => ({
        ...before,
        attachmentFiles: change(before.attachmentFiles),
      }));
    },
    [handleChange],
  );
  const handleChangeMailPostscript = useCallback(
    (v: string) => {
      handleChange((before) => ({
        ...before,
        mailPostscript: v,
      }));
    },
    [handleChange],
  );
  const handleChangeOfficeMemberMemo = useCallback(
    (v: string) => {
      handleChange((before) => ({
        ...before,
        officeMemberMemo: v,
      }));
    },
    [handleChange],
  );
  const handleChangeReviewTarget = useCallback(
    (change: (before: Partial<ReviewTarget>[]) => Partial<ReviewTarget>[]) => {
      handleChange((before) => ({
        ...before,
        reviewTarget: change(before.reviewTarget),
      }));
    },
    [handleChange],
  );

  return (
    <>
      <VStack flex={1} overflow={"auto"} alignItems={"stretch"}>
        <HStack pb={"20px"}>
          {/* 前画面に戻るリンクエリア */}
          <FrameUpperLeftButton w={"100%"}>
            {(isCreate || isReadOnly) && (
              <BackPageButton
                label={t("btn.一覧に戻るボタン")}
                onClick={handleBackPage}
              />
            )}

            {isOfficeMember && isEditable && (
              <BackPageButton
                label={t("btn.審査会照会画面に戻るボタン")}
                onClick={handleEditCancel}
              />
            )}
          </FrameUpperLeftButton>
          {/*ボタンエリア*/}
          {displayMode === "view-or-edit" && isOfficeMember && (
            <FrameUpperRightButton sx={{ alignSelf: "flex-end" }} mb={"10px"}>
              {editMode === "readOnly" ? (
                // 参照モード
                <>
                  {/*審査会が開催済みの場合は以下の項目を非表示にする。*/}
                  {initialReviewConferenceData.reviewConferenceState !==
                    "held" && (
                    <>
                      <CMButton
                        label={t("btn.開催内容を通知するボタン")}
                        onClick={handleNotify}
                        mr={"8px"}
                        size={"sm"}
                      />
                      <EditButton onClick={handleEdit} />
                    </>
                  )}
                </>
              ) : (
                // 編集モード
                <>
                  <SaveChangesButton onClick={handleSave} mr={"8px"} />
                  <EditEndButton onClick={handleEditCancel} />
                  <CMExtendedMenu
                    size={"sm"}
                    menuItems={[{ key: 0, label: t("btn.審査会を削除ボタン") }]}
                    onClick={handleDelete}
                    ml={"10px"}
                  />
                </>
              )}
            </FrameUpperRightButton>
          )}
        </HStack>

        {/* メインエリア */}
        <VStack>
          <Container minW={"400px"} maxW={"720px"}>
            <VStack alignItems={"stretch"}>
              {isCreate && (
                // 画面タイトル(新規作成時)
                <TitleText title={t("lbl.審査会作成画面タイトル")} />
              )}

              {/*管理ヘッダエリア*/}
              {displayMode === "view-or-edit" && (
                <SectionConferenceTitle value={initialReviewConferenceData} />
              )}

              {/*審査会名*/}
              <SectionReviewConferenceText
                editMode={editMode}
                title={t("lbl.審査会名")}
                value={editContent.reviewConferenceName}
                valueObjectMeta={reviewConferenceNameMeta}
                onChange={handleChangeReviewConferenceName}
              />
              {/*開催日時*/}
              <SectionReviewConferenceTime
                editMode={editMode}
                reviewConferenceDate={editContent.reviewConferenceDate}
                startDatetime={editContent.startDatetime}
                endDatetime={editContent.endDatetime}
                onChange={handleChangeDateAndTime}
              />

              {/*開催場所*/}
              <SectionReviewConferenceText
                editMode={editMode}
                title={t("lbl.開催場所")}
                value={editContent.venue}
                valueObjectMeta={venueMeta}
                onChange={handleChangeVenue}
              />

              {/*アジェンダ*/}
              <SectionReviewConferenceTextArea
                editMode={editMode}
                title={t("lbl.審査会アジェンダ")}
                value={editContent.agenda}
                valueObjectMeta={reviewConferenceAgendaMeta}
                onChange={handleChangeAgenda}
              />

              {/*添付資料*/}
              <SectionReviewConferenceFileUpload
                editMode={editMode}
                valueAttachmentFiles={editContent.attachmentFiles}
                onChange={handleChangeAttachmentFiles}
              />

              {/*メール用追記欄*/}
              <SectionReviewConferenceTextArea
                editMode={editMode}
                title={t("lbl.メール用追記欄")}
                value={editContent.mailPostscript}
                valueObjectMeta={freeTextMeta}
                onChange={handleChangeMailPostscript}
              />

              {/*事務局用メモ*/}
              {!isCommitteeMember && (
                <SectionReviewConferenceTextArea
                  editMode={editMode}
                  title={t("lbl.事務局用メモ")}
                  value={editContent.officeMemberMemo}
                  valueObjectMeta={freeTextMeta}
                  onChange={handleChangeOfficeMemberMemo}
                />
              )}

              {/*審査対象*/}
              <SectionReviewConferenceCardList
                editMode={editMode}
                reviewTargets={editContent.reviewTarget}
                isCreate={isCreate}
                onChange={handleChangeReviewTarget}
              />

              {/*フッタエリア*/}
              <Flex pt={"20px"} pb={"30px"} justifyContent={"center"}>
                {isCreate && isOfficeMember && (
                  <CMButton
                    size={"md"}
                    label={t("btn.審査会を作成するボタン")}
                    onClick={handleCreate}
                  />
                )}

                {/*審査会が開催済みの場合は以下の項目を非表示にする。*/}
                {editMode === "readOnly" &&
                  isOfficeMember &&
                  initialReviewConferenceData.reviewConferenceState !==
                    "held" && (
                    <CMButton
                      size={"md"}
                      label={t("btn.審査結果を確定するボタン")}
                      onClick={handleFix}
                    />
                  )}
              </Flex>
            </VStack>
          </Container>
        </VStack>
      </VStack>

      {/*審査会開催内容を通知確認メッセージ*/}
      {notifyModalConfig && (
        <PromiseModalOptionsCancel
          labelBody={t("mes.審査会開催内容を通知確認メッセージ")}
          onCloseModal={notifyModalConfig.onCloseModal}
        />
      )}

      {/*変更内容破棄メッセージ*/}
      {editCancelModalConfig && (
        <PromiseModalOptionsCancel
          labelBody={t("mes.変更内容破棄メッセージ")}
          onCloseModal={editCancelModalConfig.onCloseModal}
        />
      )}

      {/*変更保存確認メッセージ*/}
      {saveModalConfig && (
        <PromiseModalOptionsCancel
          labelBody={t("mes.変更保存確認メッセージ")}
          onCloseModal={saveModalConfig.onCloseModal}
        />
      )}

      {/*審査会削除確認メッセージ*/}
      {confirmDeleteModalConfig && (
        <PromiseModalOptionsCancel
          labelBody={t("mes.審査会削除確認メッセージ")}
          onCloseModal={confirmDeleteModalConfig.onCloseModal}
          labelSubmitButton={t("btn.削除ボタン")}
          submitButtonColorScheme={"red"}
        />
      )}

      {/*審査会作成確認メッセージ*/}
      {createModalConfig && (
        <PromiseModalOptionsCancel
          labelBody={t("mes.審査会作成確認メッセージ")}
          onCloseModal={createModalConfig.onCloseModal}
        />
      )}

      {/*結果確定確認メッセージ*/}
      {fixedModalConfig && (
        <PromiseModalOptionsCancel
          labelBody={t("mes.結果確定確認メッセージ")}
          onCloseModal={fixedModalConfig.onCloseModal}
        />
      )}
    </>
  );
};

function additionalValidate(
  t: TFunction<"translation", undefined, "translation">,
  v: ReviewConferenceViewModel,
): { state: "ok" } | { state: "error"; errorMessage: string } {
  if (!validateStartAndEndDate(v)) {
    return {
      state: "error",
      errorMessage: t("mes.開始終了日時妥当性チェックメッセージ"),
    };
  }
  if (!validateReviewTargetDuplicates(v)) {
    return {
      state: "error",
      errorMessage: t("mes.審査会重複不許可エラー"),
    };
  }
  return { state: "ok" };
}

function validateStartAndEndDate(v: ReviewConferenceViewModel) {
  // 開催日時は空でも保存できるべきなので、開始または終了のいずれかが空なら、バリデーションを行わず、trueを返す。
  if (isNullish(v.startDatetime) || isNullish(v.endDatetime)) {
    return true;
  }

  // 開催日時：開催日時開始＞開催日時終了の場合は呼び出し元でエラーを出力する。
  return isBeforeDate(v.startDatetime, v.endDatetime);
}

function validateReviewTargetDuplicates(v: ReviewConferenceViewModel) {
  const reviewIds = v.reviewTarget.map((rt) => rt.reviewId);
  return !hasDuplicates(reviewIds);
}
