import { DocumentContentEmptyPage } from "../DocumentContentEmptyPage/DocumentContentEmptyPage";
import { ReviewDetailsDocumentContent } from "../DocumentContent/ReviewDetailsDocumentContent";
import { isNullish } from "../../../../../../lib/util/common-util";
import { Ref } from "react";

export type ReviewDetailsDocumentContentTabProps = {
  documentSnapshotId?: number;

  scrollableRef: Ref<any>;
  scrollOffset: number;
};

export const ReviewDetailsDocumentContentTab = ({
  documentSnapshotId,

  scrollableRef,
  scrollOffset,
}: ReviewDetailsDocumentContentTabProps) => {
  return (
    <>
      {isNullish(documentSnapshotId) ? (
        <DocumentContentEmptyPage />
      ) : (
        <ReviewDetailsDocumentContent
          documentSnapshotId={documentSnapshotId}
          scrollableRef={scrollableRef}
          scrollOffset={scrollOffset}
        />
      )}
    </>
  );
};
