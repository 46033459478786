import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CloseResult } from "../../../ui/modal/PromiseModalOptionsCancel/PromiseModalOptionsCancel";
import {
  CMFormInputBaseDropDown,
  DropdownOption,
} from "@pscsrvlab/psc-react-components";
import { hasValue, isNullish } from "../../../../lib/util/common-util";
import { useNavigate } from "react-router-dom";
import log from "loglevel";
import useCustomToast from "../../../../hooks/use-custom-toast";
import {
  isAfterDate,
  ymdToJsDateOrNull,
} from "../../../../lib/util/common-date-util";
import { datetimeMeta } from "../../../../lib/object/value/datetime";
import { stockRequestApi } from "../../../../store/api/enhanced-api";
import { errorMessageOf } from "../../../../lib/util/error-util";
import { useAppTranslation } from "../../../../hooks/use-app-translation";
import { useAppGetProjectQuery } from "../../../../hooks/query/use-app-get-project-query";
import { useIsProjectMutable } from "../../../../hooks/business-logic/use-is-project-mutable";

export type FN30S05CreateDocumentProps = {
  /**
   * 案件ID
   */
  projectId: number;

  /**
   * モーダル表示設定
   */
  isOpenModal?: boolean;

  /**
   * モーダルを閉じたときのコールバック
   */
  onCloseModal?: (result: CloseResult) => void;
};

/**
 * FN30-S05書類作成
 */
export const FN30S05CreateDocument = ({
  projectId,
  isOpenModal = true,
  onCloseModal,
}: FN30S05CreateDocumentProps) => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const { errorToast } = useCustomToast();

  // 案件
  const { data: project } = useAppGetProjectQuery({
    projectId: projectId,
  });

  const [value, setValue] = useState<string | null>("change-application");

  const { isProjectMutable } = useIsProjectMutable(projectId);

  useEffect(() => {
    log.debug(`isProjectMutable=${isProjectMutable}`);
  }, [isProjectMutable]);

  // 選択項目の設定
  const options: DropdownOption[] = useMemo(() => {
    // 変更申請が作成可能か判定できるようになるまでは、選択肢全体を表示しない。
    if (isNullish(isProjectMutable)) return [];

    return [
      {
        value: "change-application",
        label: t("lbl.変更申請"),
      },
      {
        value: "annual-report",
        label: t("lbl.年次報告"),
      },
      {
        value: "termination-report",
        label: t("lbl.使用終了報告"),
      },
    ].filter(hasValue);
  }, [isProjectMutable, t]);

  /**
   * バックエンドから現在日時を取得する。
   */
  const [triggerGetCurrentDatetimeQuery] =
    stockRequestApi.useLazyGetCurrentDatetimeQuery();

  /**
   * 使用終了日と現在日時を比較する
   * 使用終了日が現在日時よりも未来の日付の場合: true
   */
  const checkValidateTime = useCallback(async () => {
    try {
      // 現在日時の取得
      const response = await triggerGetCurrentDatetimeQuery().unwrap();
      const currentDateTime = datetimeMeta.toDomainObjectOrNull(
        response.currentDatetime,
      );

      // 使用終了日の取得
      const usageEndDate = ymdToJsDateOrNull(
        project?.applicationContent?.usageEndDate,
      );

      // 日付が取得できない場合
      if (isNullish(usageEndDate) || isNullish(currentDateTime)) {
        return false;
      }

      // 使用終了日が現在日時よりも未来の日付の場合はtrue
      return isAfterDate(usageEndDate, currentDateTime);
    } catch (e) {
      log.error(errorMessageOf(e));
      errorToast(t("mes.汎用エラーメッセージ"));
      return false;
    }
  }, [
    errorToast,
    project?.applicationContent?.usageEndDate,
    t,
    triggerGetCurrentDatetimeQuery,
  ]);

  /**
   * 確定ボタン押下時の処理
   */
  const handleSubmit = useCallback(async () => {
    // 選択済みの値が存在しない場合は処理を行わない
    if (isNullish(value)) {
      log.debug("selected value none");
      return;
    }

    // プルダウンの選択項目によって遷移先を決定する
    switch (value) {
      case "change-application":
        if (hasValue(isProjectMutable) && isProjectMutable) {
          // 変更申請：「FN40-S03（申請（変更申請））」画面へ遷移。
          navigate(`/document/create-change-application/${projectId}/create`, {
            state: { projectId: projectId },
          });
        } else {
          errorToast(t("mes.変更申請二重作成不許可エラー"));
        }
        break;
      case "annual-report":
        // 年次報告可能な案件か判定する
        if (await checkValidateTime()) {
          // 現在日付が案件の使用終了日以前の場合、「FN40-S04（報告（年次報告））」画面へ遷移。
          navigate(`/document/create-annual-report/${projectId}/create`, {
            state: { projectId: projectId },
          });
        } else {
          // 現在日付が案件の使用終了日を過ぎている場合、トーストにてエラーメッセージを表示し、処理を終了する。
          errorToast(t("mes.年次報告使用期限切れエラーメッセージ"));
        }
        break;
      case "termination-report":
        // 使用終了報告：「FN40-S05（報告（使用終了報告））」画面へ遷移。
        navigate(`/document/create-termination-report/${projectId}/create`, {
          state: { projectId: projectId },
        });
        break;
    }

    // モーダルを閉じる
    onCloseModal?.("OK");
  }, [
    checkValidateTime,
    errorToast,
    isProjectMutable,
    navigate,
    onCloseModal,
    projectId,
    t,
    value,
  ]);

  /**
   * キャンセルボタン押下時の処理
   */
  const handleCancel = useCallback(() => {
    // モーダルを閉じる
    onCloseModal?.("CANCEL");
  }, [onCloseModal]);

  /**
   * プルダウン選択時の処理
   */
  const handleChange = useCallback((selectedValue: string | null) => {
    // 入力エリアに値の反映
    setValue(selectedValue);
  }, []);

  return (
    <>
      <Modal isOpen={isOpenModal} onClose={handleCancel}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t("lbl.作成対象書類")}</ModalHeader>
          <ModalBody>
            <Box>
              <Text>{t("gdc.作成書類選択案内")}</Text>
              <Box w={"270px"} mt={"8px"}>
                {/*プルダウン*/}
                <CMFormInputBaseDropDown
                  size={"lg"}
                  options={options}
                  value={value}
                  onChange={handleChange}
                />
              </Box>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme={"teal"} onClick={handleSubmit} mr={"16px"}>
              <Text>{t("btn.作成するボタン")}</Text>
            </Button>
            <Button colorScheme={"gray"} onClick={handleCancel}>
              <Text>{t("btn.キャンセルボタン")}</Text>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
