import React, { memo, useMemo } from "react";
import { CMButtonFormCommentProps } from "@pscsrvlab/psc-react-components/src/components/form/comment";
import { CollaborativePartner } from "../../../../../../../../../lib/object/value/collaborative-partner";
import { useSectionDiffMode } from "../../../../../../../../../hooks/document/change-application/use-section-diff-mode";
import { IpsCellStockUsePurposeType } from "../../../../../../../../../lib/object/value/ips-cell-stock-use-purpose-type";
import { ChangeReasonForm } from "../../form/ChangeReasonForm/ChangeReasonForm";
import { FormCard } from "../../../../../../../../ui/form/FormCard/FormCard";
import { FoldedPartnerContent } from "../../content/PartnerContent/FoldedPartnerContent";
import { useAppTranslation } from "../../../../../../../../../hooks/use-app-translation";
import { Comments } from "@pscsrvlab/psc-react-components";

export type FoldedPartnerCardProps = {
  documentType:
  | "new_application"
  | "change_application"
  | "project_force_update"
  | "project_force_create"
  | "project_content";
  editMode: "editable" | "readOnly";

  mode: "none" | "added" | "deleted";

  /**
   * iPS細胞の使用目的。
   * この値により、添付資料の表示が変わる。
   */
  purposeType?: IpsCellStockUsePurposeType;

  index: number;
  value: CollaborativePartner;

  changedFrom?: CollaborativePartner;
  changeReason?: string;

  commentButtonProps?: CMButtonFormCommentProps | undefined;
  /**
   * 1セクション単位の修正依頼コメント・返信。履歴参照画面で使用。
   */
  commentsList?: Comments[];
};

export const FoldedPartnerCard = memo(function PartnerCard({
  documentType,
  editMode,

  mode,

  index,
  value,

  changedFrom,
  changeReason,

  commentButtonProps,
  commentsList,
}: FoldedPartnerCardProps) {
  const { t } = useAppTranslation();

  const cardColor = useMemo(() => {
    const colorList = ["pink.50", "orange.50"];
    const colorIndex = index % colorList.length;
    return colorList[colorIndex];
  }, [index]);
  const cardTitle = useMemo(() => {
    return t("lbl.分担機関") + (index + 1).toString(10);
  }, [t, index]);
  const _editMode: "readOnly" | "editable" = useMemo(() => {
    if (mode === "deleted") return "readOnly";
    return editMode;
  }, [editMode, mode]);

  const sectionDiffModeOverwrite = useMemo(() => {
    return mode === "none" ? undefined : mode;
  }, [mode]);
  const sectionDiffMode = useSectionDiffMode(
    documentType,
    changedFrom,
    value,
    undefined,
    sectionDiffModeOverwrite,
  );

  return (
    <FormCard
      editMode={_editMode}
      backgroundColor={cardColor}
      title={cardTitle}
      displayMode={sectionDiffMode}
      spacing={"20px"}
      commentButtonProps={commentButtonProps}
      commentsList={commentsList}
    >
      <FoldedPartnerContent
        documentType={documentType}
        editMode={_editMode}
        value={value}
        changedFrom={changedFrom}
      />
      {sectionDiffMode === "updated" && (
        <ChangeReasonForm value={changeReason} editMode={_editMode} />
      )}
    </FormCard>
  );
});
