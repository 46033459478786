import {
  messageId,
  PropertyMeta,
  ValueObjectMetaNumber,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.number().min(0);
const savedMinimalSchema = z.number().min(0);
const schema = z.number().min(0);
const savedSchema = z.number().min(0);
const jsonSchema = z.number();
/**
 * ユーザーID(値オブジェクト)のメタデータ。
 *
 *  */
export const appUserIdMeta = new ValueObjectMetaNumber(
  "AppUserId",
  "\u30E6\u30FC\u30B6\u30FCID",
  messageId("lbl.\u30E6\u30FC\u30B6\u30FCID"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
