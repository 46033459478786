import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * 審査状態(値オブジェクト)
 *
 *
 *
 * unheld:
 * held:  */
export type ReviewState = z.infer<typeof reviewStateMeta.schema>;
/**
 * 審査状態(値オブジェクト)のメタデータ。
 *
 *  */
export const reviewStateMeta = new ValueObjectMetaEnum<["unheld", "held"]>(
  "ReviewState",
  "\u5BE9\u67FB\u72B6\u614B",
  messageId("lbl.\u5BE9\u67FB\u72B6\u614B"),
  ["unheld", "held"],
  {
    unheld: "code.\u5BE9\u67FB\u72B6\u614B.\u672A\u5B9F\u65BD",
    held: "code.\u5BE9\u67FB\u72B6\u614B.\u5B9F\u65BD\u6E08",
  }
);
