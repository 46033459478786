import { ComponentStyleProps } from "../../../../../lib/styles/props/component-style-props";
import {
  CMButtonSearchConditionDatetimeRange,
  DatetimeValue,
} from "@pscsrvlab/psc-react-components";
import React, { useCallback } from "react";
import { hasValue, isNullish } from "../../../../../lib/util/common-util";
import {
  datetimeToISOTextAsUTC,
  jsDateDiffInDays,
  ymdToJsDate,
} from "../../../../../lib/util/common-date-util";
import { useAppTranslation } from "../../../../../hooks/use-app-translation";
import useCustomToast from "../../../../../hooks/use-custom-toast";
import log from "loglevel";

export type ButtonSearchConditionDatetimeRangeProps = {
  label: string;

  datePlaceholder?: string;
  timePlaceholder?: string;

  value?: {
    start?: DatetimeValue;
    end?: DatetimeValue;
  } | null;
  defaultValue: {
    start: DatetimeValue;
    end: DatetimeValue;
  } | null;
  minDate?: {
    year: number;
    month: number;
    day: number;
  };
  maxDate?: {
    year: number;
    month: number;
    day: number;
  };

  /**
   * 指定した場合、指定した日数以下の期間のみを認め、
   * そうでなければエラーとする(開始か終了の片方でも未指定の場合も含む)。
   */
  maxPeriodDays?: number;

  hideClearButton?: boolean;

  onChange?: (start: string | undefined, end: string | undefined) => void;
} & ComponentStyleProps;

export const ButtonSearchConditionDatetimeRange = ({
  label,

  datePlaceholder,
  timePlaceholder,

  value,
  defaultValue,

  minDate,
  maxDate,

  maxPeriodDays,

  hideClearButton,

  onChange,

  sx,
  ...rest
}: ButtonSearchConditionDatetimeRangeProps) => {
  const { t } = useAppTranslation();
  const { errorToast } = useCustomToast();

  const handleChange = useCallback(
    (value: { start?: DatetimeValue; end?: DatetimeValue }) => {
      // クリア時または両方空の場合、デフォルト値に戻す。
      if (
        isNullish(value.start) &&
        isNullish(value.end) &&
        hasValue(defaultValue)
      ) {
        const startText = datetimeToISOTextAsUTC(defaultValue.start);
        const endText = datetimeToISOTextAsUTC(defaultValue.end);
        onChange?.(startText, endText);
        return;
      }

      // maxPeriodDaysのチェック。(時刻は使わず、日付だけで判定する。)
      if (hasValue(maxPeriodDays)) {
        if (isNullish(value.start)) {
          errorToast(t("mes.必須チェックエラー2", { name: "$t(lbl.開始日)" }));
          return;
        }
        if (isNullish(value.end)) {
          errorToast(t("mes.必須チェックエラー2", { name: "$t(lbl.終了日)" }));
          return;
        }

        if (
          !isWithinMaxPeriodDays(
            value.start.date,
            value.end.date,
            maxPeriodDays,
          )
        ) {
          errorToast(t("mes.日時指定範囲超過エラー"));
          return;
        }
      }

      // テキストに変換する。
      const startText = hasValue(value.start)
        ? datetimeToISOTextAsUTC(value.start)
        : undefined;
      const endText = hasValue(value.end)
        ? datetimeToISOTextAsUTC(value.end)
        : undefined;
      onChange?.(startText, endText);
    },
    [defaultValue, errorToast, maxPeriodDays, onChange, t],
  );

  const capitalizedLabel = label;

  return (
    <CMButtonSearchConditionDatetimeRange
      label={capitalizedLabel}
      datePlaceholder={datePlaceholder}
      timePlaceholder={timePlaceholder}
      value={value ?? undefined}
      minDate={minDate}
      maxDate={maxDate}
      hideClearButton={hideClearButton}
      separatorRange={t("lbl.範囲シンボル")}
      labelSubmit={t("btn.確定ボタン")}
      labelClear={t("btn.クリアボタン")}
      onChange={handleChange}
      sx={sx}
      {...rest}
    />
  );
};

function isWithinMaxPeriodDays(
  start: {
    year: number;
    month: number;
    day: number;
  },
  end: {
    year: number;
    month: number;
    day: number;
  },
  maxPeriodDays: number,
): boolean {
  const startJsDate = ymdToJsDate(start);
  const endJsDate = ymdToJsDate(end);

  const diffDays = jsDateDiffInDays(startJsDate, endJsDate);
  log.debug(`diffDays=${diffDays}`);
  return 0 <= diffDays && diffDays <= maxPeriodDays;
}
