import { useMemo } from "react";

export function useSearchResultPaginationNumbers(
  searchArgs: {
    limit?: number;
    pageNumber?: number;
  },
  data?: { totalElements: number },
  defaultNRowsPerPage = 10,
) {
  // 検索結果の全件数
  const totalElements = useMemo(
    () => data?.totalElements ?? 0,
    [data?.totalElements],
  );

  // ページネーション用の値管理
  // 1ページ当たりの表示件数
  const nRowsPerPage = useMemo(() => {
    return searchArgs.limit ?? defaultNRowsPerPage;
  }, [searchArgs, defaultNRowsPerPage]);

  // 現在のページ番号
  const currentPageNumber = useMemo(() => {
    return searchArgs.pageNumber ?? 1;
  }, [searchArgs]);

  // 表示可能な総ページ数
  const totalPageCount = useMemo(() => {
    if (isNaN(nRowsPerPage) || nRowsPerPage < 1) return 1;
    // 小数点切り上げで、表示ページ数を返却する
    return Math.ceil(totalElements / nRowsPerPage);
  }, [totalElements, nRowsPerPage]);

  return { totalElements, nRowsPerPage, currentPageNumber, totalPageCount };
}
