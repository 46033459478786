import {
  CMFormFileUpload,
  DeleteConfirmationModal,
} from "@pscsrvlab/psc-react-components";
import { memo, useCallback, useMemo, useState } from "react";
import {
  isNullish,
  parseIntOrNull,
} from "../../../../../../lib/util/common-util";
import { UploadButtonEmpty } from "../../../../../ui/button/UploadButtonEmpty/UploadButtonEmpty";
import { FileUploadItemContent } from "@pscsrvlab/psc-react-components/src/components/file-upload/types";
import { useFileModalContext } from "../../../../../ui/modal/FileModal/FileModal";
import useFileUpload from "../../../../../../hooks/use-file-upload";
import { AttachmentFileInformation } from "../../../../../../lib/object/value/attachment-file-information";
import { attachmentFileToFileUploadItem } from "../../../../../../lib/util/app-util";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { FormSection } from "../../../../../ui/form/FormSection/FormSection";
import { useDisclosure } from "@chakra-ui/react";

export type CellStockDeliveryOfficeMemberAttachmentFilesSectionProps = {
  /**
   * 画面編集モード
   */
  editMode: "editable" | "readOnly";

  /**
   * 案件ID
   * ファイルアップロード処理に使用
   */
  projectId: number;

  /**
   * 添付ファイル
   */
  value: AttachmentFileInformation[];

  /**
   * ユーザーの権限情報
   * 事務局ユーザーの場合,true
   */
  isOfficeMember: boolean;

  onChange: (
    change: (
      before: AttachmentFileInformation[],
    ) => AttachmentFileInformation[],
  ) => void;
};

/**
 * FN30-S09細胞発送管理
 * 事務局用添付資料セクション
 */
export const CellStockDeliveryOfficeMemberAttachmentFilesSection = memo(
  function CellStockDeliveryAttachmentFilesSection({
    editMode,
    projectId,
    value: attachmentFiles,
    isOfficeMember,
    onChange,
  }: CellStockDeliveryOfficeMemberAttachmentFilesSectionProps) {
    const { t } = useAppTranslation();
    const { fileUpload } = useFileUpload();
    const { openFileModal } = useFileModalContext()

    // 事務局用添付資料のファイルタイプ(自動生成)
    const attachmentFileType = "cell_delivery_office_member";

    const {
      isOpen: isOpenDeleteModal,
      onOpen: onOpenDeleteModal,
      onClose: onCloseDeleteModal,
    } = useDisclosure();
    const [deletingFileId, setDeletingFileId] = useState<number | null>(null);

    /**
     * 事務局用添付資料のファイル一覧を取得
     */
    const cellDeliveryOfficeMemberFiles = useMemo(() => {
      return attachmentFiles
        .filter((value) => value.attachmentFileType === attachmentFileType)
        .map((v) => attachmentFileToFileUploadItem(t, v));
    }, [t, attachmentFiles]);

    /**
     * ファイルアップロード処理
     */
    const handleAddFile = useCallback(
      async (
        _sectionId: string,
        subSectionId: "cell_delivery_office_member",
        file: File,
      ) => {
        const response = await fileUpload(file, "private", null, projectId);
        if (isNullish(response)) return;

        const newFile: AttachmentFileInformation = {
          storageFileId: response.id,
          attachmentFileType: subSectionId,
          attachmentFileName: file.name,
          uploadedAt: response.created.datetime,
        };
        // 呼び出し元に通知
        onChange?.((before) => [...before, newFile]);
      },
      [fileUpload, onChange, projectId],
    );

    /**
     * ファイルダウンロード処理
     */
    const handleDownloadFile = useCallback(
      (fileId: string, fileName: string) => {
        void openFileModal(fileId, fileName);
      },
      [openFileModal],
    );

    /**
     * 添付ファイル削除時に呼ばれる。
     */
    const handleDeleteFile = useCallback(
      async (_sectionId: string, _subSectionId: string, fileId: string) => {
        const intFileId = parseIntOrNull(fileId);
        if (isNullish(intFileId)) return;
        setDeletingFileId(intFileId);
        onOpenDeleteModal();
      },
      [onOpenDeleteModal],
    );

    /**
     * 添付ファイル削除処理
     */
    const handleConfirmDelete = useCallback(async () => {
      onChange?.((before) =>
        before.filter((v) => v.storageFileId !== deletingFileId),
      );
      onCloseDeleteModal();
      setDeletingFileId(null);
    }, [deletingFileId, onCloseDeleteModal, onChange]);

    return (
      <>
        <FormSection title={t("lbl.細胞発送事務局添付資料")}>
          <CMFormFileUpload
            onAddFile={handleAddFile}
            onDeleteFile={handleDeleteFile}
            onDownloadFile={handleDownloadFile}
            value={{
              id: "cell_delivery",
              title: "",
              subSectionContents: [
                {
                  editMode: isOfficeMember ? editMode : "readOnly",
                  id: "cell_delivery_office_member",
                  title: t("lbl.その他"),
                  childrenButton: <UploadButtonEmpty />,
                  files: cellDeliveryOfficeMemberFiles,
                },
              ],
            }}
          />
        </FormSection>

        <DeleteConfirmationModal
          isOpen={isOpenDeleteModal}
          onClose={onCloseDeleteModal}
          title={t("lbl.確認ポップアップタイトル")}
          message={t("mes.添付資料削除確認メッセージ")}
          deleteButtonLabel={t("btn.削除ボタン")}
          cancelButtonLabel={t("btn.キャンセルボタン")}
          onConfirm={handleConfirmDelete}
        />
      </>
    );
  },
);
