import { dropAllNullishFields, mapObjectValues } from "./common-object-util";
import { hasValue, isNullish } from "./common-util";
import { AttachmentFileInformation } from "../object/value/attachment-file-information";
import { FileUploadItemContent } from "@pscsrvlab/psc-react-components/src/components/file-upload/types";
import { TFunction } from "i18next";
import {
  hmToTimeText,
  isoTextToDatetimeOrNull,
  ymdToDateText,
} from "./common-date-util";

/**
 * オブジェクトを、searchParamとして使える値に変換する。
 * 具体的には、オブジェクトのすべての値を文字列または文字列の配列に変換する。
 * もし値がnullかundefinedか空配列なら、そのキー自体を消す。
 */
export function convertToSearchParamValue<
  T extends Record<
    string,
    | string
    | string[]
    | number
    | number[]
    | boolean
    | boolean[]
    | null
    | undefined
  >,
>(obj: T): Record<string, string | string[]> {
  const _obj = mapObjectValues<
    string,
    | string
    | string[]
    | number
    | number[]
    | boolean
    | boolean[]
    | null
    | undefined,
    string | string[] | undefined
  >(obj, (_k, v) => {
    if (isNullish(v)) return undefined;

    if (Array.isArray(v)) {
      if (v.length <= 0) {
        return undefined;
      }
      return v.map(valueToString);
    }

    return valueToString(v);
  });
  return dropAllNullishFields(_obj) as Record<string, string | string[]>;
}

function valueToString(value: string | number | boolean): string {
  switch (typeof value) {
    case "string":
      return value;
    case "number":
      return value.toString(10);
    case "boolean":
      return value ? "true" : "false";
  }
}

export function commentButtonIdOf(path: string): string {
  return `comment-button-${path}`;
}

export function attachmentFileToFileUploadItem(
  t: TFunction<"translation", undefined, "translation">,
  value: AttachmentFileInformation,
): FileUploadItemContent {
  return {
    id: value.storageFileId.toString(10),
    name: value.attachmentFileName,
    date: value.uploadedAt,
    deleteButtonTip: t("lbl.ファイルを削除"),
  };
}

export function attachmentFileToFileUploadItemWithChangeMode(
  t: TFunction<"translation", undefined, "translation">,
  value: AttachmentFileInformation,
  mode: "none" | "added" | "deleted" | undefined,
): FileUploadItemContent {
  return {
    id: value.storageFileId.toString(10),
    name: value.attachmentFileName,
    date: value.uploadedAt,
    deleteButtonTip: t("lbl.ファイルを削除"),
    diffModeVariant: mode,
  };
}

export function nextGroupLocalKey(values: { key: number }[]): number {
  const maxKey = Math.max(0, ...values.map((v) => v.key));
  return maxKey + 1;
}

export function downloadBlob(blob: Blob, filename: string): void {
  const a = document.createElement("a");
  a.href = URL.createObjectURL(blob);
  a.download = filename;
  a.target = "_blank";
  a.click();
  URL.revokeObjectURL(a.href);
}

export function downloadAsCSV(csvText: string, filename: string): void {
  // BOMはエクセル対策。参考: https://qiita.com/wadahiro/items/eb50ac6bbe2e18cf8813
  const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
  const blob = new Blob([bom, csvText], { type: "text/plain" });
  downloadBlob(blob, filename);
}

export function downloadAsCSVFromResp(
  entityName: string,
  resp: { body: string; now: string },
): void {
  const datetime = isoTextToDatetimeOrNull(resp.now);
  const dateText =
    hasValue(datetime) && hasValue(datetime.date)
      ? ymdToDateText(datetime.date, "")
      : "";
  const timeText =
    hasValue(datetime) && hasValue(datetime.time)
      ? hmToTimeText(datetime.time, "")
      : "";
  downloadAsCSV(resp.body, `${entityName}_${dateText}_${timeText}.csv`);
}
