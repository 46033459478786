import { ChangeApplicationCreatePage } from "../../../model/document/application/change-application/ChangeApplicationCreatePage/ChangeApplicationCreatePage";
import { hasValue, isNullish } from "../../../../lib/util/common-util";
import { useAppParams } from "../../../../hooks/use-app-params";
import { useChangeApplication } from "../../../../hooks/document/change-application/use-change-application";

export const FN40S03ChangeApplicationCreate = () => {
  const { projectId, documentId } = useAppParams();

  const {
    initialChangeApplicationVM,

    validationErrors,

    saveChangeApplicationContent,
    deleteChangeApplicationDraft,
  } = useChangeApplication(projectId ?? -1, documentId, {
    skip: isNullish(projectId),
  });

  return (
    <>
      {hasValue(projectId) && hasValue(initialChangeApplicationVM) && (
        <ChangeApplicationCreatePage
          projectId={projectId}
          // パスからdocumentIdが取得できればpropsで渡し、取得できなければundefinedを渡す。
          savedDocumentId={documentId}
          initialViewModel={initialChangeApplicationVM}
          validationErrors={validationErrors}
          saveChangeApplicationContent={saveChangeApplicationContent}
          deleteChangeApplicationDraft={deleteChangeApplicationDraft}
          h={"100%"}
          w={"100%"}
        />
      )}
    </>
  );
};
