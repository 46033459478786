import { useMemo } from "react";
import { hasValue, isNullish } from "../../../../lib/util/common-util";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import { selectUserInfo } from "../../../../store/auth/slice";
import { ProjectContentApplicationPage } from "../../../model/project/FN30S04ProjectContentApplication/ProjectContentApplicationPage/ProjectContentApplicationPage";
import { useAppGetProjectQuery } from "../../../../hooks/query/use-app-get-project-query";

export type FN30S06ProjectContentApplicationProps = {
  projectId: number;
};
export const FN30S06ProjectContentApplication = ({
  projectId,
}: FN30S06ProjectContentApplicationProps) => {
  /**
   * ログイン者情報
   */
  const loginUserInfo = useAppSelector(selectUserInfo);
  const loginUserRole = useMemo(() => {
    return loginUserInfo?.role;
  }, [loginUserInfo]);

  /**
   * 案件情報取得。
   */
  const { data: project } = useAppGetProjectQuery(
    {
      projectId: projectId ?? -1,
    },
    { skip: isNullish(projectId) },
  );

  const projectApplicationContent = useMemo(() => {
    if (isNullish(project)) {
      return undefined;
    }
    return project.applicationContent;
  }, [project]);

  return (
    <>
      {hasValue(projectApplicationContent) && hasValue(projectId) && (
        <ProjectContentApplicationPage
          projectId={projectId}
          projectApplicationContent={projectApplicationContent}
          loginUserRole={loginUserRole}
          sx={{ h: "100%" }}
        />
      )}
    </>
  );
};
