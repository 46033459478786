import { CollaborativeResearchContractStateWithFoundation } from "../value/collaborative-research-contract-state-with-foundation";
import { ErrorMessageObject, messageId } from "@pscsrvlab/psc-react-components";
import { hasValue, isNullish } from "../../util/common-util";

export function collaborativeResearchContractStateWithFoundationCustomValidation(
  value: CollaborativeResearchContractStateWithFoundation,
  skipOptional: boolean,
): ErrorMessageObject[] {
  if (skipOptional) return [];

  const errorMessageList: ErrorMessageObject[] = [];

  /**
   * 協議中が選択されているにもかかわらず、承認見込み月が入力されていない場合のエラー
   */
  if (
    value.state === "discussing" &&
    isNullish(value.estimatedAgreementYearMonth)
  ) {
    errorMessageList.push({
      errorMessageId: messageId(
        "mes.本申請に基づくiPS財団との共同研究契約の状況エラー",
      ),
    });
  }

  /**
   * 協議中以外が選択されているにもかかわらず、承認見込み月が入力されている場合のエラー
   */
  if (
    !skipOptional &&
    (value.state === "not_yet" || value.state === "agreed") &&
    hasValue(value.estimatedAgreementYearMonth)
  ) {
    errorMessageList.push({
      errorMessageId: messageId("mes.一般エラーメッセージ"),
    });
  }

  return errorMessageList;
}
