import { ComponentStyleProps } from "../../../../../../../../../lib/styles/props/component-style-props";
import { HStack, Link, VStack } from "@chakra-ui/react";
import { Icon } from "@chakra-ui/icons";
import { IoIosArrowDown } from "react-icons/io";
import { memo, Ref } from "react";
import { useScrollToId } from "../../../../../../../../../hooks/use-scroll-to-id";

export type InPageLinkProps = {
  linkList?: { label: string; id: string }[];

  scrollableRef: Ref<any>;
  scrollOffset: number;
} & ComponentStyleProps;

export const InPageLink = memo(function InPageLink({
  linkList,

  scrollableRef,
  scrollOffset,

  sx,
  ...rest
}: InPageLinkProps) {
  const { scrollToId } = useScrollToId(scrollableRef, scrollOffset);

  return (
    <HStack
      justifyContent={"space-between"}
      borderBottomWidth={"2px"}
      borderColor={"gray.200"}
      px={"80px"}
      sx={sx}
      {...rest}
    >
      {linkList?.map((value, index) => (
        <VStack key={index} spacing={"3px"}>
          <Link
            onClick={() => scrollToId(value.id)}
            href={undefined}
            fontWeight={"bold"}
            fontSize={"xs"}
          >
            {value.label}
          </Link>
          <Icon as={IoIosArrowDown} color={"gray.600"} />
        </VStack>
      ))}
    </HStack>
  );
});
