import {
  messageId,
  PropertyMeta,
  ValueObjectMetaString,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.string().max(100);
const savedMinimalSchema = z.string().max(100);
const schema = z
  .string()
  .min(1)
  .max(100)
  .regex(
    new RegExp(
      "^[a-zA-Z0-9_+-]+(\\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\\.)+[a-zA-Z]{2,}$"
    )
  );
const savedSchema = z
  .string()
  .min(1)
  .max(100)
  .regex(
    new RegExp(
      "^[a-zA-Z0-9_+-]+(\\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\\.)+[a-zA-Z]{2,}$"
    )
  );
const jsonSchema = z.string();
/**
 * メールアドレス(値オブジェクト)のメタデータ。
 *
 *  */
export const mailAddressMeta = new ValueObjectMetaString(
  "MailAddress",
  "\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9",
  messageId("lbl.\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
