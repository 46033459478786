import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spacer,
  useDisclosure,
} from "@chakra-ui/react";
import {
  CMButtonChangeLanguage,
  CMTabMainMenu,
} from "@pscsrvlab/psc-react-components";
import { useAppDispatch } from "../../../../hooks/redux-hooks";
import { selectHasRole, signOut } from "../../../../store/auth/slice";
import { errorMessageOf } from "../../../../lib/util/error-util";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ComponentStyleProps } from "../../../../lib/styles/props/component-style-props";
import { Icon } from "@chakra-ui/icons";
import { FaBars, FaUser } from "react-icons/fa";
import { GrLogout } from "react-icons/gr";
import {
  useApplyConfigMutation,
  useChangeLanguageMutation,
  useGetNumberOfDocumentTodosQuery,
  useGetNumberOfInquiryTodosQuery,
  useGetNumberOfReviewConferenceTodosQuery,
} from "../../../../store/api/generated/stock-request-api";
import log from "loglevel";
import { useSelector } from "react-redux";
import { LoginUserInfo } from "../../../../store/auth/types";
import { hasValue } from "../../../../lib/util/common-util";
import { useAppTranslation } from "../../../../hooks/use-app-translation";
import { ConfirmationModal } from "../../../ui/modal/ConfirmationModal/ConfirmationModal";
import useCustomToast from "../../../../hooks/use-custom-toast";

export type AfterLoginMainMenuProps = {
  /**
   * ログインユーザー情報
   */
  loginUserInfo: LoginUserInfo;
} & ComponentStyleProps;

/**
 * ログイン後メインメニュー
 */
export const AfterLoginMainMenu = ({
  loginUserInfo,

  sx,
  ...rest
}: AfterLoginMainMenuProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { successToast, errorToast } = useCustomToast();
  const { isApplicant, isInternalMember, isOfficeMember } =
    useSelector(selectHasRole);
  const { t, i18n } = useAppTranslation();

  // 確認モーダル制御用
  const { isOpen, onOpen, onClose } = useDisclosure();

  // 確認モーダル判定用
  const [modalType, setModalType] = useState<"applyConfig" | "logOut">();

  // 確認モーダル本文用
  const modalMessage = useMemo(() => {
    switch (modalType) {
      case "applyConfig":
        return t("mes.設定ファイル反映確認メッセージ");
      case "logOut":
        return t("mes.ログアウト確認メッセージ");
      default:
        return undefined;
    }
  }, [modalType, t]);

  // 書類Todoデータ取得
  const { data: documentTodos } = useGetNumberOfDocumentTodosQuery(undefined, {
    // 内部メンバーの場合はスキップ
    skip: isInternalMember,
  });
  // 質疑応答Todoデータ取得
  const { data: inquiryTodos } = useGetNumberOfInquiryTodosQuery(undefined, {
    // 申請者でも事務局でもない場合はスキップ
    skip: !isApplicant && !isOfficeMember,
  });
  // 審査会Todoデータ取得
  const { data: reviewConferenceTodos } =
    useGetNumberOfReviewConferenceTodosQuery(undefined, {
      // 内部メンバー、申請者の場合はスキップ
      skip: isInternalMember || isApplicant,
    });

  // 言語の切り換えるためのデータ更新用
  const [changeLanguage, _result] = useChangeLanguageMutation();

  // 設定ファイル反映用
  const [applyConfig] = useApplyConfigMutation();

  // 言語設定
  const [language, setLanguage] = useState<"ja" | "en">("ja");

  // 初回読み込み時のユーザー情報の言語設定と同期
  useEffect(
    () => {
      setLanguage(loginUserInfo.language);
    },
    // eslint-disable-next-line
    [],
  );

  // 言語の変更を監視し、変更があった場合に設定反映
  useEffect(
    () => {
      i18n.changeLanguage(language).then();
    },
    // eslint-disable-next-line
    [language],
  );

  const logOut = useCallback(async () => {
    try {
      await dispatch(signOut()).unwrap();
      navigate("/login");
    } catch (e) {
      log.debug(errorMessageOf(e));
    }
  }, [dispatch, navigate]);

  // 言語切り換え用イベント
  const handleChangeLanguage = useCallback(
    async (lang: "ja" | "en") => {
      // 言語の切り替え
      setLanguage(lang);
      // 非同期でユーザー情報を更新
      changeLanguage({
        changeLanguageRequest: { language: lang },
      });
    },
    [changeLanguage],
  );

  const handleApplyConfig = useCallback(() => {
    setModalType("applyConfig");
    onOpen();
  }, [onOpen]);

  const handleLogout = useCallback(() => {
    setModalType("logOut");
    onOpen();
  }, [onOpen]);

  const handleSubmitModal = useCallback(async () => {
    try {
      switch (modalType) {
        case "logOut":
          await logOut();
          break;
        case "applyConfig":
          await applyConfig().unwrap();
          successToast(t("mes.設定ファイル反映メッセージ"));
          break;
      }
    } catch (e) {
      log.error(errorMessageOf(e));
      errorToast(t("mes.汎用エラーメッセージ"));
    }

    onClose();
  }, [applyConfig, errorToast, logOut, modalType, onClose, successToast, t]);

  const handleClickInstitution = useCallback(() => {
    // 申請者の場合、機関詳細画面へ遷移。それ以外の場合、機関検索画面へ遷移。
    if (!isApplicant) {
      navigate("/institution");
      return;
    }
    navigate(`/institution/${loginUserInfo.institutionId}`);
  }, [isApplicant, navigate, loginUserInfo]);
  const handleClickUser = useCallback(() => navigate("/user"), [navigate]);
  const handleClickMailLog = useCallback(
    () => navigate("/mail-log"),
    [navigate],
  );
  const handleClickWorkLog = useCallback(
    () => navigate("/work-log"),
    [navigate],
  );

  const divider = (
    <Divider h={"30px"} borderColor={"gray.300"} orientation={"vertical"} />
  );
  const responsiveDisplayKey = useMemo(() => {
    switch (loginUserInfo.role) {
      case "applicant":
        return "lg";
      case "office_member":
        return "xl";
      case "committee_member":
        return "lg";
      case "executive_director":
        return "lg";
      case "internal":
        return "lg";
    }
  }, [loginUserInfo.role]);

  return (
    <>
      <HStack
        flex={"0 0 60px"}
        minH={0}
        alignSelf={"stretch"}
        py={"2px"}
        px={"20px"}
        spacing={"12px"}
        justifyContent={"flex-start"}
        bgColor={"yellow.100"}
        boxShadow="sm"
        sx={sx}
        {...rest}
      >
        <HStack
          alignSelf={"stretch"}
          flex={"0 0 content"}
          spacing={"16px"}
          divider={divider}
        >
          <Image
            src={"/image/CiRA_Foundation_Logo-04.png"}
            alt={"site logo"}
            minH={0}
            alignSelf={"stretch"}
            cursor={"pointer"}
            onClick={() => navigate("/")}
          />
          {/* 内部メンバーの場合はすべてのタブメニューが非表示 */}
          {!isInternalMember && (
            <HStack
              spacing={"16px"}
              divider={divider}
              flex={"0 0 content"}
              className={"tab-menus"}
            >
              <HStack>
                <CMTabMainMenu
                  labelName={t("btn.案件メニュー")}
                  onClick={() => navigate("/project")}
                />
                <CMTabMainMenu
                  badgeText={
                    hasValue(documentTodos) && documentTodos > 0
                      ? documentTodos.toString()
                      : undefined
                  }
                  badgeColor={"red.400"}
                  labelName={t("btn.書類メニュー")}
                  onClick={() => navigate("/document")}
                />
                {/* 申請者と事務局のみ、質疑応答ボタン表示 */}
                {(isApplicant || isOfficeMember) && (
                  <CMTabMainMenu
                    badgeText={
                      hasValue(inquiryTodos) && inquiryTodos > 0
                        ? inquiryTodos.toString()
                        : undefined
                    }
                    badgeColor={"red.400"}
                    labelName={t("btn.質疑応答メニュー")}
                    onClick={() => navigate("/inquiry")}
                  />
                )}
                {/* 申請者の場合、審査会ボタン非表示 */}
                {!isApplicant && (
                  <CMTabMainMenu
                    badgeText={
                      hasValue(reviewConferenceTodos) &&
                      reviewConferenceTodos > 0
                        ? reviewConferenceTodos.toString()
                        : undefined
                    }
                    badgeColor={"red.400"}
                    labelName={t("btn.審査会メニュー")}
                    onClick={() => navigate("/review-conference")}
                  />
                )}
              </HStack>

              {/* 画面の横幅に応じて表示を切り替える */}
              <Flex flexFlow="row nowrap">
                {/* 横幅が小さい場合 */}
                <Flex
                  display={{ base: "flex", [responsiveDisplayKey]: "none" }}
                >
                  <Menu>
                    <MenuButton
                      as={IconButton}
                      variant="outline"
                      icon={
                        <Icon
                          as={FaBars}
                          h={"16px"}
                          w={"16px"}
                          color={"gray.600"}
                        />
                      }
                      bgColor="whiteAlpha.700"
                      borderColor="gray.400"
                    />
                    <MenuList
                      border={"1px solid"}
                      borderColor={"gray.400"}
                      bgColor={"white"}
                      p={"4px"}
                      fontSize={"14px"}
                      color={"gray.900"}
                      zIndex={"999"}
                    >
                      <MenuItem onClick={handleClickInstitution}>
                        {t("btn.機関メニュー")}
                      </MenuItem>
                      <MenuItem onClick={handleClickUser}>
                        {t("btn.ユーザーメニュー")}
                      </MenuItem>
                      <MenuDivider />
                      <MenuItem onClick={handleClickMailLog}>
                        {t("btn.メールログメニュー")}
                      </MenuItem>

                      {/* 事務局のみ作業ログボタン表示 */}
                      {isOfficeMember && (
                        <MenuItem onClick={handleClickWorkLog}>
                          {t("btn.作業ログメニュー")}
                        </MenuItem>
                      )}
                    </MenuList>
                  </Menu>
                </Flex>
                {/* 横幅が大きい場合 */}
                <HStack
                  display={{ base: "none", [responsiveDisplayKey]: "flex" }}
                  spacing="16px"
                  divider={divider}
                >
                  <HStack>
                    <CMTabMainMenu
                      labelName={t("btn.機関メニュー")}
                      onClick={handleClickInstitution}
                    />
                    <CMTabMainMenu
                      labelName={t("btn.ユーザーメニュー")}
                      onClick={handleClickUser}
                    />
                  </HStack>
                  <HStack>
                    <CMTabMainMenu
                      labelName={t("btn.メールログメニュー")}
                      onClick={handleClickMailLog}
                    />

                    {/* 事務局のみ作業ログボタン表示 */}
                    {isOfficeMember && (
                      <CMTabMainMenu
                        labelName={t("btn.作業ログメニュー")}
                        onClick={handleClickWorkLog}
                      />
                    )}
                  </HStack>
                </HStack>
              </Flex>
            </HStack>
          )}
        </HStack>

        <Spacer />

        <CMButtonChangeLanguage
          className={"CMButtonChangeLanguage"}
          labelLanguage={t("btn.言語設定")}
          labelJP={t("btn.言語設定JP")}
          separatorSelectedLanguage={t("btn.言語設定スラッシュ")}
          labelEN={t("btn.言語設定EN")}
          selectedLanguage={language === "ja" ? "JP" : "EN"}
          onClick={() => handleChangeLanguage(language === "ja" ? "en" : "ja")}
          sx={{
            px: 0,
            _active: { bgColor: "transparent" },
            flex: "0 0 content",
          }}
        />
        {/* 次行のBoxはwarning潰しに必要。 参考: https://github.com/chakra-ui/chakra-ui/issues/3440#issuecomment-851707911 */}
        <Box>
          <Menu>
            <MenuButton
              as={Button}
              leftIcon={
                <Icon as={FaUser} h={"16px"} w={"16px"} color={"gray.700"} />
              }
              bgColor={"transparent"}
              _hover={{ bgColor: "transparent" }}
              _active={{ bgColor: "transparent" }}
              px={0}
              fontSize={"14px"}
              color={"gray.800"}
            >
              {loginUserInfo.fullName}
            </MenuButton>
            <MenuList
              border={"1px solid"}
              borderColor={"gray.400"}
              bgColor={"white"}
              p={"4px"}
              fontSize={"14px"}
              color={"gray.900"}
              zIndex={"999"}
            >
              {/* internalの場合、プロフィールボタン非表示 */}
              {!isInternalMember && (
                <>
                  <MenuItem onClick={() => navigate("/profile")}>
                    {t("btn.プロフィールボタン")}
                  </MenuItem>
                  <MenuDivider />
                </>
              )}

              {/* 事務局の場合、ファイル反映ボタン表示 */}
              {isOfficeMember && (
                <>
                  <MenuItem onClick={handleApplyConfig}>
                    {t("btn.設定ファイル反映ボタン")}
                  </MenuItem>
                  <MenuDivider />
                </>
              )}
              <MenuItem
                icon={<GrLogout size={"18px"} color={"gray.700"} />}
                onClick={handleLogout}
              >
                {t("btn.ログアウトボタン")}
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      </HStack>
      {/* 確認用モーダル */}
      <ConfirmationModal
        isOpen={isOpen}
        message={modalMessage ?? ""}
        onSubmit={handleSubmitModal}
        onCancel={onClose}
      />
    </>
  );
};
