import {
  messageId,
  PropertyMeta,
  ValueObjectMetaString,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.string().max(10000).regex(new RegExp("^(/.*)?$"));
const savedMinimalSchema = z.string().max(10000).regex(new RegExp("^(/.*)?$"));
const schema = z.string().max(10000).regex(new RegExp("^(/.*)?$"));
const savedSchema = z.string().max(10000).regex(new RegExp("^(/.*)?$"));
const jsonSchema = z.string();
/**
 * JSONポインタ(値オブジェクト)のメタデータ。
 *
 * JSON pointer (RFC6901)。 */
export const jsonPointerMeta = new ValueObjectMetaString(
  "JsonPointer",
  "JSON\u30DD\u30A4\u30F3\u30BF",
  messageId("lbl.JSON\u30DD\u30A4\u30F3\u30BF"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
