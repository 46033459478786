import { ComponentStyleProps } from "../../../../../lib/styles/props/component-style-props";
import {
  CMButtonSearchConditionRadio,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import React, { useCallback } from "react";
import { useAppTranslation } from "../../../../../hooks/use-app-translation";

export type ButtonSearchConditionRadioProps<T extends [string, ...string[]]> = {
  label: string;

  value?: T[number] | null;

  hideClearButton?: boolean;

  /**
   * 値オブジェクトメタデータ
   */
  valueObjectMeta: ValueObjectMetaEnum<T>;

  onChange?: (value: T[number] | undefined) => void;
} & ComponentStyleProps;

export const ButtonSearchConditionRadio = <T extends [string, ...string[]]>({
  label,
  value = null,
  hideClearButton = false,

  valueObjectMeta,

  onChange,

  sx,
  ...rest
}: ButtonSearchConditionRadioProps<T>) => {
  const { t } = useAppTranslation();

  const handleChange = useCallback(
    (value: T[number] | null) => {
      onChange?.(value ?? undefined);
    },
    [onChange],
  );

  const capitalizedLabel = label;

  return (
    <CMButtonSearchConditionRadio
      label={capitalizedLabel}
      value={value}
      hideClearButton={hideClearButton}
      valueObjectMeta={valueObjectMeta}
      labelSubmit={t("btn.確定ボタン")}
      labelClear={t("btn.クリアボタン")}
      onChange={handleChange}
      sx={sx}
      {...rest}
    />
  );
};
