import {
  messageId,
  PropertyMeta,
  ValueObjectMetaComposite,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { cellStockIdMeta } from "../value/cell-stock-id";
import { cellNumberMeta } from "../value/cell-number";
import { cellNameJaMeta } from "../value/cell-name-ja";
import { cellNameEnMeta } from "../value/cell-name-en";
import { cellTypeMeta } from "../value/cell-type";
import { cellStockCategoryIdMeta } from "../value/cell-stock-category-id";
/**
 * 細胞(値オブジェクト)
 *
 *  */
export type CellStock = z.infer<typeof schema>;
export type CellStockSaved = z.infer<typeof savedSchema>;
const minimalSchema = z.object({
  cellStockId: cellStockIdMeta.minimalSchema,
  cellNumber: cellNumberMeta.minimalSchema,
  cellNameJa: cellNameJaMeta.minimalSchema,
  cellNameEn: cellNameEnMeta.minimalSchema,
  cellType: cellTypeMeta.minimalSchema,
  cellStockCategoryId: cellStockCategoryIdMeta.minimalSchema,
});
const savedMinimalSchema = z.object({
  cellStockId: cellStockIdMeta.savedMinimalSchema,
  cellNumber: cellNumberMeta.savedMinimalSchema,
  cellNameJa: cellNameJaMeta.savedMinimalSchema,
  cellNameEn: cellNameEnMeta.savedMinimalSchema,
  cellType: cellTypeMeta.savedMinimalSchema,
  cellStockCategoryId: cellStockCategoryIdMeta.savedMinimalSchema,
});
const schema = z.object({
  cellStockId: cellStockIdMeta.schema,
  cellNumber: cellNumberMeta.schema,
  cellNameJa: cellNameJaMeta.schema,
  cellNameEn: cellNameEnMeta.schema,
  cellType: cellTypeMeta.schema,
  cellStockCategoryId: cellStockCategoryIdMeta.schema,
});
const savedSchema = z.object({
  cellStockId: cellStockIdMeta.savedSchema,
  cellNumber: cellNumberMeta.savedSchema,
  cellNameJa: cellNameJaMeta.savedSchema,
  cellNameEn: cellNameEnMeta.savedSchema,
  cellType: cellTypeMeta.savedSchema,
  cellStockCategoryId: cellStockCategoryIdMeta.savedSchema,
});
const jsonSchema = z.object({
  cellStockId: cellStockIdMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  cellNumber: cellNumberMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  cellNameJa: cellNameJaMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  cellNameEn: cellNameEnMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  cellType: cellTypeMeta.jsonSchema.nullish().transform((x) => x ?? undefined),
  cellStockCategoryId: cellStockCategoryIdMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
/**
 * 細胞(値オブジェクト)のメタデータ。
 *
 *  */
export const cellStockMeta = new ValueObjectMetaComposite(
  "CellStock",
  "\u7D30\u80DE",
  messageId("lbl.\u7D30\u80DE"),
  minimalSchema,
  savedMinimalSchema,
  schema,
  savedSchema,
  jsonSchema,
  undefined,
  undefined,
  {
    cellStockId: new PropertyMeta(
      "cellStockId",
      "\u7D30\u80DEID",
      messageId("lbl.\u7D30\u80DEID"),
      "always",
      false,
      cellStockIdMeta
    ),
    cellNumber: new PropertyMeta(
      "cellNumber",
      "\u7D30\u80DE\u756A\u53F7",
      messageId("lbl.\u7D30\u80DE\u756A\u53F7"),
      "always",
      false,
      cellNumberMeta
    ),
    cellNameJa: new PropertyMeta(
      "cellNameJa",
      "\u7D30\u80DE\u540D\uFF08\u65E5\uFF09",
      messageId("lbl.\u7D30\u80DE\u540D\uFF08\u65E5\uFF09"),
      "always",
      false,
      cellNameJaMeta
    ),
    cellNameEn: new PropertyMeta(
      "cellNameEn",
      "\u7D30\u80DE\u540D\uFF08\u82F1\uFF09",
      messageId("lbl.\u7D30\u80DE\u540D\uFF08\u82F1\uFF09"),
      "always",
      false,
      cellNameEnMeta
    ),
    cellType: new PropertyMeta(
      "cellType",
      "\u7D30\u80DE\u533A\u5206",
      messageId("lbl.\u7D30\u80DE\u533A\u5206"),
      "always",
      false,
      cellTypeMeta
    ),
    cellStockCategoryId: new PropertyMeta(
      "cellStockCategoryId",
      "\u7D30\u80DE\u30AB\u30C6\u30B4\u30EAID",
      messageId("lbl.\u7D30\u80DE\u30AB\u30C6\u30B4\u30EAID"),
      "always",
      false,
      cellStockCategoryIdMeta
    ),
  }
);
