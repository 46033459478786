import {
  messageId,
  PropertyMeta,
  ValueObjectMetaNumber,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.number().min(0);
const savedMinimalSchema = z.number().min(0);
const schema = z.number().min(0);
const savedSchema = z.number().min(0);
const jsonSchema = z.number();
/**
 * 細胞カテゴリID(値オブジェクト)のメタデータ。
 *
 *  */
export const cellStockCategoryIdMeta = new ValueObjectMetaNumber(
  "CellStockCategoryId",
  "\u7D30\u80DE\u30AB\u30C6\u30B4\u30EAID",
  messageId("lbl.\u7D30\u80DE\u30AB\u30C6\u30B4\u30EAID"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
