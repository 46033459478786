import {
  messageId,
  PropertyMeta,
  ValueObjectMetaNumber,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.number().min(0);
const savedMinimalSchema = z.number().min(0);
const schema = z.number().min(0);
const savedSchema = z.number().min(0);
const jsonSchema = z.number();
/**
 * 案件スナップショットID(値オブジェクト)のメタデータ。
 *
 *  */
export const projectSnapshotIdMeta = new ValueObjectMetaNumber(
  "ProjectSnapshotId",
  "\u6848\u4EF6\u30B9\u30CA\u30C3\u30D7\u30B7\u30E7\u30C3\u30C8ID",
  messageId(
    "lbl.\u6848\u4EF6\u30B9\u30CA\u30C3\u30D7\u30B7\u30E7\u30C3\u30C8ID"
  ),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
