import { Box, Flex, Text } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { ComponentStyleProps } from "../../../../lib/styles/props/component-style-props";

export type VerticalTableRowProps = {
  /**
   * テーブルヘッダーとして使用
   */
  label?: string;

  /**
   * ヘッダー右横の注意書き
   */
  attentionLabel?: string;

  /**
   * テーブルデータとして使用
   */
  children?: ReactNode;
} & ComponentStyleProps;

/**
 * 縦型テーブルのRow
 */
export const VerticalTableRow = ({
  label,
  attentionLabel,
  children,
  sx,
  ...rest
}: VerticalTableRowProps) => {
  const capitalizedLabel = label;

  return (
    <Box w={"100%"} p={"22px"} sx={sx} {...rest}>
      <Flex mb={"7px"} alignItems={"end"}>
        <Text fontWeight={700} fontSize={"14px"} color={"gray.900"}>
          {capitalizedLabel}
        </Text>
        {attentionLabel && (
          <Text ml={"12px"} fontSize={"12px"} color={"red.500"}>
            {attentionLabel}
          </Text>
        )}
      </Flex>
      <Box>{children}</Box>
    </Box>
  );
};
