import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";
import { AppUserSaved } from "../../../../../../lib/object/entity/app-user";
import React, { memo, useEffect, useMemo, useState } from "react";
import { SearchResultTableContent } from "@pscsrvlab/psc-react-components/src/components/search/table/types";
import { CMSearchResultTable } from "@pscsrvlab/psc-react-components";
import { institutionMeta } from "../../../../../../lib/object/entity/institution";
import { TFunction } from "i18next";
import { SearchResultRowContent } from "@pscsrvlab/psc-react-components/src/components/search/row/types";
import { stockRequestApi } from "../../../../../../store/api/enhanced-api";
import { LazyQueryTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { QueryDefinition } from "@reduxjs/toolkit/query";
import {
  GetInstitutionApiArg,
  Institution as InstitutionJson,
} from "../../../../../../store/api/generated/stock-request-api";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/dist/query/react";
import log from "loglevel";
import { errorMessageOf } from "../../../../../../lib/util/error-util";
import { getMessageFromEnumValue } from "../../../../../../lib/util/common-util";
import { roleMeta } from "../../../../../../lib/object/value/role";
import { accountStateMeta } from "../../../../../../lib/object/value/account-state";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";

export type SearchResultTableUserProps = {
  entities: AppUserSaved[];
} & ComponentStyleProps;

export const SearchResultTableUser = memo(function SearchResultTableUser({
  entities,

  sx,
  ...rest
}: SearchResultTableUserProps) {
  const { t } = useAppTranslation();
  const [triggerGetInstitutionQuery] =
    stockRequestApi.useLazyGetInstitutionQuery();

  const header = useMemo(() => {
    return {
      cells: [
        { value: t("lbl.ユーザーID"), width: "130px" },
        { value: t("lbl.ロール"), width: "80px" },
        { value: t("lbl.氏名"), width: "130px" },
        { value: t("lbl.氏名かな"), width: "130px" },
        { value: t("lbl.機関名"), width: "130px" },
        { value: t("lbl.所属・職名"), width: "130px" },
        { value: t("lbl.電話番号"), width: "130px" },
        { value: t("lbl.メールアドレス"), width: "130px" },
        { value: t("lbl.アカウント状態"), width: "80px" },
        { value: t("lbl.更新日時"), width: "140px" },
      ],
    };
  }, [t]);

  const [tableContent, setTableContent] = useState<SearchResultTableContent>({
    header: { cells: [] },
    rows: [],
  });
  // テーブル表示用にデータを整形する
  useEffect(() => {
    (async () => {
      try {
        // 各項目ごとにデータを整形
        const rows: SearchResultRowContent[] = await createRows(
          entities,
          t,
          triggerGetInstitutionQuery,
        );

        // テーブル表示に必要なデータを設定
        setTableContent({
          header,
          rows,
        });
      } catch (e) {
        log.error(errorMessageOf(e));
      }
    })();
  }, [entities, t, triggerGetInstitutionQuery, header]);

  return (
    <CMSearchResultTable
      content={tableContent}
      stickyHeaderTop={"29px"}
      stickyFirstColLeft={"30px"}
      sx={sx}
      {...rest}
    />
  );
});

function createRows(
  entities: AppUserSaved[],
  t: TFunction<"translation", undefined, "translation">,
  triggerGetInstitutionQuery: LazyQueryTrigger<
    QueryDefinition<
      GetInstitutionApiArg,
      BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, {}>,
      string,
      InstitutionJson,
      "api"
    >
  >,
): Promise<SearchResultRowContent[]> {
  return Promise.all(
    entities.map(async (entity) => {
      const institutionJson = await triggerGetInstitutionQuery({
        institutionId: entity.institutionId,
      }).unwrap();
      const institution = institutionMeta.toSavedDomainObjectOrNull(
        institutionJson,
        true,
      );
      return {
        cells: [
          {
            // ユーザーID
            type: "react_router_link",
            value: {
              text: entity.displayUserId,
              to: `/user/${entity.id}`,
            },
          },
          {
            // ロール
            type: "text",
            value: getMessageFromEnumValue(t, roleMeta, entity.role),
          },
          {
            // 氏名
            type: "text",
            value: entity.fullName,
          },
          {
            // 氏名かな
            type: "text",
            value: entity.fullNameKana,
          },
          {
            // 機関名
            type: "text",
            value: institution?.name,
          },
          {
            // 所属・職名
            type: "text",
            value: entity.titleAndPosition,
          },
          {
            // 電話番号
            type: "text",
            value: entity.phoneNumber,
          },
          {
            // メールアドレス
            type: "text",
            value: entity.mailAddress,
          },
          {
            // アカウント状態
            type: "text",
            value: getMessageFromEnumValue(
              t,
              accountStateMeta,
              entity.accountState,
            ),
          },
          {
            // 更新日時
            type: "datetime",
            value: entity.updated?.datetime,
          },
        ],
      };
    }),
  );
}
