import {
  messageId,
  PropertyMeta,
  ValueObjectMetaString,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.string().max(100);
const savedMinimalSchema = z.string().max(100);
const schema = z.string().min(1).max(100);
const savedSchema = z.string().min(1).max(100);
const jsonSchema = z.string();
/**
 * 書類管理番号(値オブジェクト)のメタデータ。
 *
 *  */
export const documentControlNumberMeta = new ValueObjectMetaString(
  "DocumentControlNumber",
  "\u66F8\u985E\u7BA1\u7406\u756A\u53F7",
  messageId("lbl.\u66F8\u985E\u7BA1\u7406\u756A\u53F7"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
