import { ProtectedRoute as PR } from "../components/route/ProtectedRoute";
import { FN30S09CellStockDelivery } from "../components/page/FN30/FN30S09CellStockDelivery/FN30S09CellStockDelivery";
import { FN30S14ProjectForceUpdate } from "../components/page/FN30/FN30S14ProjectForceUpdate/FN30S14ProjectForceUpdate";
import { FN30S15ProjectMailLog } from "../components/page/FN30/FN30S15ProjectMailLog/FN30S15ProjectMailLog";
import { FN30S16ProjectWorkLog } from "../components/page/FN30/FN30S16ProjectWorkLog/FN30S16ProjectWorkLog";
import { FN30S13ProjectForceCreate } from "../components/page/FN30/FN30S13ProjectForceCreate/FN30S13ProjectForceCreate";
import * as React from "react";
import { _documentDetailsRoute } from "./document-details-route";
import { Navigate, RouteObject } from "react-router-dom";
import { ProjectLayout } from "../components/layout/ProjectLayout/ProjectLayout";
import { _inquiryRoute } from "./inquiry-route";
import { FN30S04ProjectContent } from "../components/page/FN30/FN30S04ProjectContent/FN30S04ProjectContent";

export const _projectDetailsRoute: RouteObject = {
  path: "project",
  element: (
    <PR
      roles={[
        "applicant",
        "office_member",
        "committee_member",
        "executive_director",
      ]}
    />
  ),
  children: [
    {
      path: ":projectId",
      element: <ProjectLayout />,
      children: [
        {
          index: true,
          element: <Navigate to={"./content"} replace />,
        },
        {
          path: "content",
          children: [
            {
              index: true,
              element: <Navigate to={"./application"} replace />,
            },
            {
              path: "application",
              element: <FN30S04ProjectContent mode={"application"} />,
            },
            {
              path: "disclosure-document",
              element: <FN30S04ProjectContent mode={"disclosure-document"} />,
            },
          ],
        },
        {
          path: "mail-log",
          element: <FN30S15ProjectMailLog />,
        },
        {
          element: <PR roles={["office_member"]} />,
          children: [
            {
              path: "force-update",
              element: <FN30S14ProjectForceUpdate />,
            },
            {
              path: "work-log",
              element: <FN30S16ProjectWorkLog />,
            },
          ],
        },
        {
          element: (
            <PR roles={["applicant", "office_member", "executive_director"]} />
          ),
          children: [
            {
              path: "cell-stock-delivery/:cellStockDeliveryId",
              element: <FN30S09CellStockDelivery />,
            },
          ],
        },
        _documentDetailsRoute,
        _inquiryRoute,
      ],
    },
    {
      path: "force-create",
      element: <PR roles={["office_member"]} />,
      children: [
        {
          path: "create",
          element: <FN30S13ProjectForceCreate mode={"create"} />,
        },
        {
          path: "complete",
          element: <FN30S13ProjectForceCreate mode={"complete"} />,
        },
      ],
    },
  ],
};
