import { NewApplicationSaved } from "../../../lib/object/entity/new-application";
import { useCallback, useEffect, useMemo, useState } from "react";
import { merge } from "lodash";
import { ApplicationContentViewModel } from "../../../components/model/document/application/_components/ApplicationDocument/ApplicationDocument";
import {
  SignatureValue,
  signatureValueMeta,
} from "../../../lib/object/value/signature-value";
import { hasValue, isNullish } from "../../../lib/util/common-util";
import { LoginUserInfo } from "../../../store/auth/types";
import { InstitutionSaved } from "../../../lib/object/entity/institution";
import { useAppSelector } from "../../redux-hooks";
import { selectUserInfo } from "../../../store/auth/slice";
import { useAppGetInstitutionQuery } from "../../query/use-app-get-institution-query";
import { NewApplicationViewModel } from "../../../lib/object/vm/new-application-view-model";
import {
  useCreateNewApplicationDraftMutation,
  useDeleteDocumentDraftMutation,
  useUpdateNewApplicationContentMutation,
} from "../../../store/api/generated/stock-request-api";
import { applicationContentMeta } from "../../../lib/object/value/application-content";
import log from "loglevel";
import { errorMessageOf } from "../../../lib/util/error-util";
import { useCreateJsonPatchOperations } from "../../use-create-json-patch-operations";
import { useApplicationContentValidation } from "../application-content/use-application-content-validation";
import { ValidationError } from "@pscsrvlab/psc-react-components";
import { useAppGetProjectQuery } from "../../query/use-app-get-project-query";

export function useNewApplicationVMOrEmpty(
  /**
   * 書類ID。
   * この値がない場合、
   * 新規の（空の）新規申請ビューモデルを作成する。
   */
  documentId: number | undefined,

  newApplication?: NewApplicationSaved | null,

  options?: {
    /**
     * skipがtrueの場合、何も行わない。
     */
    skip: boolean;
  },
) {
  const _options = useMemo(() => merge({ skip: false }, options), [options]);

  const loginUserInfo = useAppSelector(selectUserInfo);

  /**
   * ログイン者の機関情報
   */
  const { data: institutionOfLoginUser } = useAppGetInstitutionQuery(
    {
      institutionId: loginUserInfo?.institutionId ?? -1,
    },
    { skip: isNullish(loginUserInfo?.institutionId) },
  );

  /**
   * 初期表示時に渡す、ビューモデル。
   * 一度作成したら二度と更新しない。
   */
  const [initialNewApplicationVM, setInitialNewApplicationVM] =
    useState<NewApplicationViewModel | null>(null);

  const isInitialized = useMemo(() => {
    return hasValue(initialNewApplicationVM);
  }, [initialNewApplicationVM]);

  // 保存済の新規申請から作成する場合。
  const vmFromSaved = useNewApplicationVM(newApplication);
  useEffect(() => {
    if (_options.skip || isInitialized) return;

    if (isNullish(vmFromSaved)) return;

    // ビューモデル初期値に値を設定する。
    setInitialNewApplicationVM(vmFromSaved);
  }, [_options.skip, isInitialized, vmFromSaved]);

  // 新規の新規申請VMを作成する場合。
  useEffect(() => {
    if (_options.skip || isInitialized) return;

    // 書類IDが指定されている場合は、作成しない。
    if (hasValue(documentId)) return;

    // ログインユーザー情報及びその機関情報を読み込むまでは作成しない。
    if (isNullish(loginUserInfo) || isNullish(institutionOfLoginUser)) return;

    const _applicationContent = createInitialViewModel(
      loginUserInfo,
      institutionOfLoginUser,
    );

    // 書類内容初期値に値を設定する。
    setInitialNewApplicationVM({
      type: "new_application",

      projectId: undefined,
      projectControlNumber: undefined,
      documentControlNumber: undefined,

      submissionDate: undefined,
      receptionDate: undefined,
      approvalDate: undefined,
      documentCompletionDate: undefined,

      contentVM: _applicationContent,
      signatureValue: { correctnessCheck: "unchecked" },
    });
  }, [
    documentId,
    loginUserInfo,
    institutionOfLoginUser,
    _options.skip,
    isInitialized,
  ]);

  const [triggerCreateDraft] = useCreateNewApplicationDraftMutation();
  const [triggerDeleteDraft] = useDeleteDocumentDraftMutation();
  const [triggerUpdateContent] = useUpdateNewApplicationContentMutation();

  const { validate } = useApplicationContentValidation();
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>(
    [],
  );

  /**
   * 新規申請の下書きを新規作成する。
   * バリデーションも行う。
   *
   * 成功・失敗に関わらず、結果を返却するだけなので、トーストを出すなどの後処理は呼び出し側の責務とする。
   */
  const createNewApplicationDraft = useCallback(
    async (
      content: ApplicationContentViewModel,
      signatureValue: SignatureValue,
      skipOptionalValidations: boolean,
    ): Promise<
      | { state: "ok"; documentId: number }
      | { state: "error"; errors: ValidationError[] }
      | { state: "unexpectedError" }
    > => {
      if (hasValue(documentId)) {
        log.error(
          "書類IDが指定されているため、新規申請の下書き新規作成は行えません。",
        );
        return { state: "unexpectedError" };
      }

      const validationResult = await validate(content, skipOptionalValidations);
      if (validationResult.state !== "ok") return validationResult;

      const signatureValueValidationResult = signatureValueMeta.validate(
        signatureValue,
        skipOptionalValidations,
      );
      if (signatureValueValidationResult.state !== "ok") {
        return signatureValueValidationResult;
      }

      const contentJson = applicationContentMeta.toJsonObjectOrNull(
        validationResult.value,
      );
      if (isNullish(contentJson)) return { state: "unexpectedError" };

      try {
        const createResult = await triggerCreateDraft({
          correctnessCheck: signatureValue.correctnessCheck,
          applicationContent: contentJson,
        }).unwrap();
        const documentId = createResult.id;
        if (isNullish(documentId)) return { state: "unexpectedError" };
        return { state: "ok", documentId };
      } catch (e) {
        log.error(errorMessageOf(e));
        return { state: "unexpectedError" };
      }
    },
    [documentId, triggerCreateDraft, validate],
  );

  const { createJsonPatchOperations } = useCreateJsonPatchOperations(
    applicationContentMeta,
  );

  /**
   * 新規申請内容を更新する。
   * バリデーションも行う。
   *
   * 成功・失敗に関わらず、結果を返却するだけなので、トーストを出すなどの後処理は呼び出し側の責務とする。
   */
  const updateNewApplicationContent = useCallback(
    async (
      content: ApplicationContentViewModel,
      signatureValue: SignatureValue,
      skipOptionalValidations: boolean,
    ): Promise<
      | { state: "ok"; documentId: number }
      | { state: "error"; errors: ValidationError[] }
      | { state: "unexpectedError" }
    > => {
      if (
        isNullish(documentId) ||
        isNullish(newApplication) ||
        isNullish(initialNewApplicationVM)
      ) {
        log.error(
          "書類IDが未指定または新規申請が取得できていないため、新規申請内容の上書き保存は行えません。",
        );
        return { state: "unexpectedError" };
      }

      const validationResult = await validate(content, skipOptionalValidations);
      if (validationResult.state !== "ok") return validationResult;

      const signatureValueValidationResult = signatureValueMeta.validate(
        signatureValue,
        skipOptionalValidations,
      );
      if (signatureValueValidationResult.state !== "ok") {
        return signatureValueValidationResult;
      }

      const jsonPatchOperations = createJsonPatchOperations(
        initialNewApplicationVM.contentVM,
        validationResult.value,
      );
      if (isNullish(jsonPatchOperations)) return { state: "unexpectedError" };

      try {
        await triggerUpdateContent({
          documentId,
          correctnessCheck: signatureValue.correctnessCheck,
          commonUpdateRequest: { patchOperations: jsonPatchOperations },
        }).unwrap();
      } catch (e) {
        log.error(errorMessageOf(e));
        return { state: "unexpectedError" };
      }

      return { state: "ok", documentId };
    },
    [
      createJsonPatchOperations,
      documentId,
      initialNewApplicationVM,
      newApplication,
      triggerUpdateContent,
      validate,
    ],
  );

  /**
   * 新規申請下書きを新規作成、または新規申請内容を更新する。
   */
  const saveNewApplicationContent = useCallback(
    async (
      vm: NewApplicationViewModel,
      skipOptionalValidations: boolean,
    ): Promise<
      | { state: "ok"; documentId: number }
      | { state: "error"; errors: ValidationError[] }
      | { state: "unexpectedError" }
    > => {
      const result = isNullish(documentId)
        ? await createNewApplicationDraft(
            vm.contentVM,
            vm.signatureValue,
            skipOptionalValidations,
          )
        : await updateNewApplicationContent(
            vm.contentVM,
            vm.signatureValue,
            skipOptionalValidations,
          );
      if (result.state === "ok") setValidationErrors([]);
      if (result.state === "error") setValidationErrors(result.errors);

      return result;
    },
    [createNewApplicationDraft, documentId, updateNewApplicationContent],
  );

  const deleteNewApplicationDraft = useCallback(async (): Promise<
    { state: "ok" } | { state: "unexpectedError" }
  > => {
    if (isNullish(documentId)) {
      log.error("書類IDが未指定のため、新規申請の下書き削除は行えません。");
      return { state: "unexpectedError" };
    }

    try {
      await triggerDeleteDraft({ documentId }).unwrap();
    } catch (e) {
      log.error(errorMessageOf(e));
      return { state: "unexpectedError" };
    }

    return { state: "ok" };
  }, [documentId, triggerDeleteDraft]);

  return {
    newApplication,

    initialNewApplicationVM,

    validationErrors,

    saveNewApplicationContent,
    deleteNewApplicationDraft,
  };
}

function createInitialViewModel(
  loginUserInfo: LoginUserInfo,
  institutionOfLoginUser: InstitutionSaved,
): ApplicationContentViewModel {
  return {
    ...emptyViewModel(),
    institution: {
      institutionId: institutionOfLoginUser.id,
      displayInstitutionId: institutionOfLoginUser.displayInstitutionId,
      institutionName: institutionOfLoginUser.name,
      institutionNameKana: institutionOfLoginUser.nameKana,
      foreignType: institutionOfLoginUser.foreignType,
      commercialType: institutionOfLoginUser.commercialType,
    },
    contactPeople: [
      {
        key: 0,
        appUserId: loginUserInfo.id,
        displayUserId: loginUserInfo.displayUserId,
        role: loginUserInfo.role,
        fullName: loginUserInfo.fullName,
        fullNameKana: loginUserInfo.fullNameKana,
        titleAndPosition: loginUserInfo.titleAndPosition,
        phoneNumber: loginUserInfo.phoneNumber,
        mailAddress: loginUserInfo.mailAddress,
      },
    ],
  };
}

/**
 * 空の申請内容
 */
function emptyViewModel(): ApplicationContentViewModel {
  return {
    projectName: "",
    institution: {},
    principalInvestigator: undefined,
    contactPeople: [],
    requestingResearchCells: [],
    requestingClinicalCells: [],
    ipsCellStockUsePurpose: {
      purposeType: "research",
      purposeSubType: undefined,
    },
    ipsCellStockRequirementReason: {
      reasonType: "risk_reduction",
      other: undefined,
    },
    planSummary: "",
    hasCollaborativePartner: "no_partner",
    usageEndDate: undefined,
    researchSite: "",
    ipsCultureExperience: "no_experience",
    ethicalApplicationStatus: {
      ethicalApplicationStatus: "requesting",
      estimatedApprovalYearMonth: undefined,
    },
    handlingAfterUse: { type: "abandonment", other: undefined },
    foundationContractState: {
      state: "not_yet",
      estimatedAgreementYearMonth: undefined,
    },
    researchPlanProjectName: "",
    attachmentFiles: [],
    partners: [],
    checklistItem1: "unchecked",
    checklistItem2: "unchecked",
    checklistItem3: "unchecked",
    checklistItem4: "unchecked",
    checklistItem5: "unchecked",
    checklistItem6: "unchecked",
  };
}

export function useNewApplicationVM(
  newApplication: NewApplicationSaved | null | undefined,
): NewApplicationViewModel | null {
  const { data: project } = useAppGetProjectQuery(
    { projectId: newApplication?.projectId ?? -1 },
    { skip: isNullish(newApplication?.projectId) },
  );
  return useMemo(() => {
    if (isNullish(newApplication) || isNullish(project)) return null;
    return {
      type: "new_application",

      projectId: newApplication.projectId,
      projectControlNumber: project.projectControlNumber,
      documentControlNumber: newApplication.documentControlNumber,

      submissionDate: newApplication.submissionDate,
      receptionDate: newApplication.receptionDate,
      approvalDate: newApplication.approvalDate,
      documentCompletionDate: newApplication.documentCompletionDate,

      contentVM: newApplication.content,
      signatureValue: newApplication.signatureValue,
    };
  }, [newApplication, project]);
}
