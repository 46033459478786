import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * 計画書遵守区分(値オブジェクト)
 *
 *
 *
 * yes:
 * no:  */
export type PlanComplianceType = z.infer<typeof planComplianceTypeMeta.schema>;
/**
 * 計画書遵守区分(値オブジェクト)のメタデータ。
 *
 *  */
export const planComplianceTypeMeta = new ValueObjectMetaEnum<["yes", "no"]>(
  "PlanComplianceType",
  "\u8A08\u753B\u66F8\u9075\u5B88\u533A\u5206",
  messageId("lbl.\u8A08\u753B\u66F8\u9075\u5B88\u533A\u5206"),
  ["yes", "no"],
  {
    yes: "code.\u8A08\u753B\u66F8\u9075\u5B88\u533A\u5206.\u306F\u3044",
    no: "code.\u8A08\u753B\u66F8\u9075\u5B88\u533A\u5206.\u3044\u3044\u3048",
  }
);
