import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";
import { useNavigate } from "react-router-dom";
import useCustomToast from "../../../../../../hooks/use-custom-toast";
import { useAppSelector } from "../../../../../../hooks/redux-hooks";
import { selectUserInfo } from "../../../../../../store/auth/slice";
import React, { useCallback, useRef } from "react";
import { hasValue } from "../../../../../../lib/util/common-util";
import { Container, HStack, useDisclosure, VStack } from "@chakra-ui/react";
import { FrameUpperLeftButton } from "../../../../../ui/frame/FrameUpperLeftButton/FrameUpperLeftButton";
import { ProgressStepDocumentCreate } from "../../../../../ui/progress/ProgressStepDocumentCreate/ProgressStepDocumentCreate";
import { CommonNextButton } from "../../../../../ui/button/CommonNextButton/CommonNextButton";
import { FrameUpperRightButton } from "../../../../../ui/frame/FrameUpperRightButton/FrameUpperRightButton";
import log from "loglevel";
import { errorMessageOf } from "../../../../../../lib/util/error-util";
import { useSubmitChangeApplicationFromDraftMutation } from "../../../../../../store/api/generated/stock-request-api";
import { ChangeApplicationDocument } from "../../../../../model/document/application/change-application/ChangeApplicationDocument/ChangeApplicationDocument";
import { ChangeApplicationViewModel } from "../../../../../../lib/object/vm/change-application-view-model";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { CMButtonBack } from "@pscsrvlab/psc-react-components";
import { ConfirmationModal } from "../../../../../ui/modal/ConfirmationModal/ConfirmationModal";

export type ChangeApplicationCreateConfirmPageProps = {
  documentId: number;
  vm: ChangeApplicationViewModel;
} & ComponentStyleProps;

export const ChangeApplicationCreateConfirmPage = ({
  documentId,
  vm,

  sx,
  ...rest
}: ChangeApplicationCreateConfirmPageProps) => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const { errorToast } = useCustomToast();
  const loginUserInfo = useAppSelector(selectUserInfo);

  const [submitChangeApplication] =
    useSubmitChangeApplicationFromDraftMutation();

  const handleSubmit = async () => {
    try {
      await submitChangeApplication({ documentId }).unwrap();
      navigate(
        `/document/create-change-application/${vm.projectId}/complete/${documentId}`,
      );
    } catch (e) {
      errorToast(t("mes.変更申請提出失敗エラー"));
      log.error(errorMessageOf(e));
    }
  };

  const handleBackCreatePage = useCallback(() => {
    navigate(
      `/document/create-change-application/${vm.projectId}/create/${documentId}`,
    );
  }, [documentId, navigate, vm.projectId]);

  // 提出確認モーダル制御
  const {
    isOpen: isOpenSubmitModal,
    onOpen: onOpenSubmitModal,
    onClose: onCloseSubmitModal,
  } = useDisclosure();

  const scrollableRef = useRef(null);

  return (
    <>
      <HStack
        alignItems={"flex-start"}
        overflow={"auto"}
        spacing={0}
        sx={sx}
        {...rest}
        ref={scrollableRef}
      >
        <FrameUpperLeftButton
          sx={{ position: "sticky", top: 0, flex: "250px" }}
        >
          <CMButtonBack
            onClick={handleBackCreatePage}
            labelBack={t("btn.書類作成に戻るボタン")}
          />
        </FrameUpperLeftButton>

        <Container minW={"500px"} maxW={"720px"}>
          <VStack alignItems={"stretch"}>
            <ProgressStepDocumentCreate currentStep={1} />
            {hasValue(loginUserInfo) && (
              <VStack alignSelf={"stretch"} mt={"18px"} pb={"100px"}>
                <ChangeApplicationDocument
                  loginUserInfo={loginUserInfo}
                  editMode={"readOnly"}
                  value={vm}
                  stickyAreaTop={0}
                  scrollableRef={scrollableRef}
                  scrollOffset={60}
                />
                {/*提出ボタン*/}
                <HStack>
                  <CommonNextButton
                    colorScheme={"gray"}
                    label={t("btn.書類作成に戻るボタン")}
                    onClick={handleBackCreatePage}
                  />
                  <CommonNextButton
                    label={t("btn.申請を確定するボタン")}
                    onClick={onOpenSubmitModal}
                  />
                </HStack>
              </VStack>
            )}
          </VStack>
        </Container>

        <FrameUpperRightButton
          pl={"20px"}
          sx={{ position: "sticky", top: 0, flex: "250px" }}
        ></FrameUpperRightButton>
      </HStack>
      <ConfirmationModal
        isOpen={isOpenSubmitModal}
        message={t("mes.提出確認メッセージ")}
        onSubmit={handleSubmit}
        onCancel={onCloseSubmitModal}
      />
    </>
  );
};
