import { useMemo } from "react";
import { isNullish } from "../lib/util/common-util";
import { useConfigFileContext } from "../ConfigFileProvider";

const useConfigFile = () => {
  // 設定ファイルのコンテキストを取得
  const { isLoading, systemEnv, messages } = useConfigFileContext();

  /**
   * メンテナンスフラグ。
   * メンテナンス中ならtrue。
   */
  const isMaintenance: boolean | undefined = useMemo(() => {
    if (isNullish(systemEnv) || isNullish(systemEnv.isMaintenance)) {
      return undefined;
    }
    return systemEnv.isMaintenance;
  }, [systemEnv]);

  /**
   * 内部メンバー用機関ID
   */
  const internalMemberInstitutionId: number | undefined = useMemo(() => {
    if (
      isNullish(systemEnv) ||
      isNullish(systemEnv.internalMemberInstitutionId)
    ) {
      return undefined;
    }
    return systemEnv.internalMemberInstitutionId;
  }, [systemEnv]);

  /**
   * 最大アップロードファイルサイズ（Byte）
   */
  const maxFileSizeByte: number | undefined = useMemo(() => {
    if (isNullish(systemEnv) || isNullish(systemEnv.maxFileSize)) {
      return undefined;
    }
    return systemEnv.maxFileSize;
  }, [systemEnv]);

  /**
   * アップロード可能拡張子
   */
  const uploadableFileExtension: string[] = useMemo(() => {
    if (isNullish(systemEnv) || isNullish(systemEnv.uploadableFileExtension)) {
      return [];
    }
    return systemEnv.uploadableFileExtension;
  }, [systemEnv]);

  /**
   * 設定ファイルキャッシュ更新間隔（分）
   */
  const cachePollingInterval: number | undefined = useMemo(() => {
    if (isNullish(systemEnv) || isNullish(systemEnv.cachePollingInterval)) {
      return undefined;
    }
    return systemEnv.cachePollingInterval;
  }, [systemEnv]);

  return {
    isMaintenance,
    isLoading,
    internalMemberInstitutionId,
    maxFileSizeByte,
    uploadableFileExtension,
    cachePollingInterval,
    messages,
  };
};
export default useConfigFile;
