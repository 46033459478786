import { getLogLevel } from "../lib/util/logging";
import log from "loglevel";
import { appConfig } from "../config";

export function initializeApp() {
  log.setDefaultLevel(appConfig.defaultLogLevel);
  console.log(
    `Log level is "${getLogLevel()}". Run "setLogLevel('debug')" to change.`,
  );
}
