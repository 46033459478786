import {
  messageId,
  PropertyMeta,
  ValueObjectMetaComposite,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { ipsCellStockRequirementReasonCustomValidation } from "../custom-validation/ips-cell-stock-requirement-reason";
import { ipsCellStockRequirementReasonTypeMeta } from "../value/ips-cell-stock-requirement-reason-type";
import { ipsCellStockRequirementReasonOtherMeta } from "../value/ips-cell-stock-requirement-reason-other";
/**
 * iPS細胞ストック必要理由(値オブジェクト)
 *
 *  */
export type IpsCellStockRequirementReason = z.infer<typeof schema>;
export type IpsCellStockRequirementReasonSaved = z.infer<typeof savedSchema>;
const minimalSchema = z.object({
  reasonType: ipsCellStockRequirementReasonTypeMeta.minimalSchema,
  other: ipsCellStockRequirementReasonOtherMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
const savedMinimalSchema = z.object({
  reasonType: ipsCellStockRequirementReasonTypeMeta.savedMinimalSchema,
  other: ipsCellStockRequirementReasonOtherMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
const schema = z.object({
  reasonType: ipsCellStockRequirementReasonTypeMeta.schema,
  other: ipsCellStockRequirementReasonOtherMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
});
const savedSchema = z.object({
  reasonType: ipsCellStockRequirementReasonTypeMeta.savedSchema,
  other: ipsCellStockRequirementReasonOtherMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
const jsonSchema = z.object({
  reasonType: ipsCellStockRequirementReasonTypeMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  other: ipsCellStockRequirementReasonOtherMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
/**
 * iPS細胞ストック必要理由(値オブジェクト)のメタデータ。
 *
 *
 *
 * 以下のカスタムバリデーションを、 ipsCellStockRequirementReasonCustomValidation にて行う。
 * - (任意) iPS細胞ストックを必要とする理由でその他を選択した場合に内容を必須にする。 (エラーメッセージID=mes.iPS細胞ストック必要理由その他未入力エラー) */
export const ipsCellStockRequirementReasonMeta = new ValueObjectMetaComposite(
  "IpsCellStockRequirementReason",
  "iPS\u7D30\u80DE\u30B9\u30C8\u30C3\u30AF\u5FC5\u8981\u7406\u7531",
  messageId(
    "lbl.iPS\u7D30\u80DE\u30B9\u30C8\u30C3\u30AF\u5FC5\u8981\u7406\u7531"
  ),
  minimalSchema,
  savedMinimalSchema,
  schema,
  savedSchema,
  jsonSchema,
  undefined,
  ipsCellStockRequirementReasonCustomValidation,
  {
    reasonType: new PropertyMeta(
      "reasonType",
      "iPS\u7D30\u80DE\u30B9\u30C8\u30C3\u30AF\u5FC5\u8981\u7406\u7531\u533A\u5206",
      messageId(
        "lbl.iPS\u7D30\u80DE\u30B9\u30C8\u30C3\u30AF\u5FC5\u8981\u7406\u7531\u533A\u5206"
      ),
      "always",
      false,
      ipsCellStockRequirementReasonTypeMeta
    ),
    other: new PropertyMeta(
      "other",
      "iPS\u7D30\u80DE\u30B9\u30C8\u30C3\u30AF\u5FC5\u8981\u7406\u7531\u305D\u306E\u4ED6",
      messageId(
        "lbl.iPS\u7D30\u80DE\u30B9\u30C8\u30C3\u30AF\u5FC5\u8981\u7406\u7531\u305D\u306E\u4ED6"
      ),
      "none",
      false,
      ipsCellStockRequirementReasonOtherMeta
    ),
  }
);
