import {
  RevisionRequest as RevisionRequestJson,
  useListRevisionRequestQuery,
} from "../../store/api/generated/stock-request-api";
import { hasValue, isNullish } from "../../lib/util/common-util";
import { useMemo } from "react";
import {
  revisionRequestMeta,
  RevisionRequestSaved,
} from "../../lib/object/entity/revision-request";

export function useAppListRevisionRequestQuery(
  arg: Parameters<typeof useListRevisionRequestQuery>[0],
  options?: Parameters<typeof useListRevisionRequestQuery>[1],
  skipOptionalValidations = true, // get系は、skipOptionalValidationsのデフォルトはtrueとする。
): { data: RevisionRequestSaved[] | null } {
  const { data: rawData } = useListRevisionRequestQuery(arg, options);
  const data = useMemo(() => {
    if (isNullish(rawData)) return null;
    return rawData
      .map((d: RevisionRequestJson) =>
        revisionRequestMeta.toSavedDomainObjectOrNull(
          d,
          skipOptionalValidations,
        ),
      )
      .filter(hasValue);
  }, [rawData, skipOptionalValidations]);
  return { data };
}
