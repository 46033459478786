import { Container, HStack, useDisclosure, VStack } from "@chakra-ui/react";
import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";
import { FrameUpperLeftButton } from "../../../../../ui/frame/FrameUpperLeftButton/FrameUpperLeftButton";
import { ProgressStepDocumentCreate } from "../../../../../ui/progress/ProgressStepDocumentCreate/ProgressStepDocumentCreate";
import { hasValue } from "../../../../../../lib/util/common-util";
import { CommonNextButton } from "../../../../../ui/button/CommonNextButton/CommonNextButton";
import { FrameUpperRightButton } from "../../../../../ui/frame/FrameUpperRightButton/FrameUpperRightButton";
import React, { useCallback } from "react";
import { useAppSelector } from "../../../../../../hooks/redux-hooks";
import { selectUserInfo } from "../../../../../../store/auth/slice";
import { useNavigate } from "react-router-dom";
import useCustomToast from "../../../../../../hooks/use-custom-toast";
import { useSubmitTerminationReportFromDraftMutation } from "../../../../../../store/api/generated/stock-request-api";
import log from "loglevel";
import { errorMessageOf } from "../../../../../../lib/util/error-util";
import { TerminationReportDocument } from "../../../../../model/document/report/termination-report/TerminationReportDocument/TerminationReportDocument";
import { TerminationReportViewModel } from "../../../../../../lib/object/vm/termination-report-view-model";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { CMButtonBack } from "@pscsrvlab/psc-react-components";
import { ConfirmationModal } from "../../../../../ui/modal/ConfirmationModal/ConfirmationModal";

export type TerminationReportCreateConfirmPageProps = {
  documentId: number;
  vm: TerminationReportViewModel;
} & ComponentStyleProps;
export const TerminationReportCreateConfirmPage = ({
  documentId,
  vm,

  sx,
  ...rest
}: TerminationReportCreateConfirmPageProps) => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const { errorToast } = useCustomToast();
  const loginUserInfo = useAppSelector(selectUserInfo);

  const [submitTerminationReport] =
    useSubmitTerminationReportFromDraftMutation();

  const handleSubmit = useCallback(async () => {
    try {
      await submitTerminationReport({ documentId: documentId }).unwrap();
      navigate(
        `/document/create-termination-report/${vm.projectId}/complete/${documentId}`,
      );
    } catch (e) {
      errorToast(t("mes.使用終了報告提出失敗エラー"));
      log.error(errorMessageOf(e));
    }
  }, [
    documentId,
    errorToast,
    navigate,
    vm.projectId,
    submitTerminationReport,
    t,
  ]);

  const handleBackCreatePage = useCallback(() => {
    navigate(
      `/document/create-termination-report/${vm.projectId}/create/${documentId}`,
    );
  }, [documentId, navigate, vm.projectId]);

  // 提出確認モーダル制御
  const {
    isOpen: isOpenSubmitModal,
    onOpen: onOpenSubmitModal,
    onClose: onCloseSubmitModal,
  } = useDisclosure();

  return (
    <>
      <HStack
        alignItems={"flex-start"}
        overflow={"auto"}
        spacing={0}
        // scrollPaddingTop={"150px"} // position: stickyのエリア分、スクロール位置をずらすため。
        sx={sx}
        {...rest}
      >
        <FrameUpperLeftButton
          sx={{ position: "sticky", top: 0, flex: "250px" }}
        >
          <CMButtonBack
            onClick={handleBackCreatePage}
            labelBack={t("btn.書類作成に戻るボタン")}
          />
        </FrameUpperLeftButton>

        <Container minW={"500px"} maxW={"720px"}>
          <VStack alignItems={"stretch"}>
            <ProgressStepDocumentCreate currentStep={1} />
            {hasValue(loginUserInfo) && (
              <VStack alignSelf={"stretch"} mt={"18px"} pb={"100px"}>
                <TerminationReportDocument
                  loginUserInfo={loginUserInfo}
                  editMode={"readOnly"}
                  value={vm}
                />
                {/*提出ボタン*/}
                <HStack pt={"30px"}>
                  <CommonNextButton
                    colorScheme={"gray"}
                    label={t("btn.書類作成に戻るボタン")}
                    onClick={handleBackCreatePage}
                  />
                  <CommonNextButton
                    label={t("btn.申請を確定するボタン")}
                    onClick={onOpenSubmitModal}
                  />
                </HStack>
              </VStack>
            )}
          </VStack>
        </Container>

        <FrameUpperRightButton
          pl={"20px"}
          sx={{ position: "sticky", top: 0, flex: "250px" }}
        ></FrameUpperRightButton>
      </HStack>
      <ConfirmationModal
        isOpen={isOpenSubmitModal}
        message={t("mes.提出確認メッセージ")}
        onSubmit={handleSubmit}
        onCancel={onCloseSubmitModal}
      />
    </>
  );
};
