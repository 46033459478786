import { CMFrameCard, Comments } from "@pscsrvlab/psc-react-components";
import React, { memo, useCallback, useMemo } from "react";
import { CMButtonFormCommentProps } from "@pscsrvlab/psc-react-components/src/components/form/comment";
import { SectionContentFrame } from "../../../../../application/_components/ApplicationDocument/_components/frame/SectionContentFrame/SectionContentFrame";
import { ComponentStyleProps } from "../../../../../../../../lib/styles/props/component-style-props";
import { UsedCell } from "../../../../../../../../lib/object/value/used-cell";
import { Divider, VStack } from "@chakra-ui/react";
import { UsedCellCard } from "../card/UsedCellCard";
import { hasValue } from "../../../../../../../../lib/util/common-util";
import { useAppTranslation } from "../../../../../../../../hooks/use-app-translation";
import { FormSection } from "../../../../../../../ui/form/FormSection/FormSection";
import { CellType } from "../../../../../../../../lib/object/value/cell-type";
import useLinkCommentPath from "../../../../../../../../hooks/use-link-comment-path";

export type UsedCellsAnnualReportSectionProps = {
  documentType?: DocumentType;
  editMode: "editable" | "readOnly";

  valueResearchCells: UsedCell[];
  valueClinicalCells: UsedCell[];

  onChangeResearchCells: (change: (before: UsedCell[]) => UsedCell[]) => void;
  onChangeClinicalCells: (change: (before: UsedCell[]) => UsedCell[]) => void;

  /**
   * コメントボタンのprops。
   * これが存在すれば、コメントボタンを表示する。
   */
  commentButtonProps?: CMButtonFormCommentProps;
  /**
   * 1セクション単位の修正依頼コメント・返信。履歴参照画面で使用。
   */
  commentsList?: Comments[];

  commentButtonPropsResearchCells?: (CMButtonFormCommentProps | undefined)[];
  /**
   * 1セクション単位の修正依頼コメント・返信(研究用株)。履歴参照画面で使用。
   */
  commentsListResearchCells?: Comments[];

  commentButtonPropsClinicalCells?: (CMButtonFormCommentProps | undefined)[];
  /**
   * 1セクション単位の修正依頼コメント・返信(臨床用株)。履歴参照画面で使用。
   */
  commentsListClinicalCells?: Comments[];
} & ComponentStyleProps;

/**
 * 使用する細胞セクション
 */
export const UsedCellsAnnualReportSection = memo(
  function UsedCellsAnnualReportSection({
    editMode,
    valueResearchCells,
    valueClinicalCells,
    onChangeResearchCells,
    onChangeClinicalCells,
    commentButtonProps,
    commentsList,
    commentButtonPropsResearchCells,
    commentsListResearchCells,
    commentButtonPropsClinicalCells,
    commentsListClinicalCells,
    sx,
    ...rest
  }: UsedCellsAnnualReportSectionProps) {
    const { t } = useAppTranslation();

    const handleChange = useCallback(
      (
        cellType: CellType,
        changeIndex: number,
        change: (before: UsedCell) => UsedCell,
      ) => {
        switch (cellType) {
          case "research_purpose": {
            onChangeResearchCells((before) => {
              return before.map((cell, cellsIndex) => {
                if (cellsIndex === changeIndex) {
                  return change(cell);
                } else {
                  return cell;
                }
              });
            });
            break;
          }
          case "clinical_purpose": {
            onChangeClinicalCells((before) => {
              return before.map((cell, cellsIndex) => {
                if (cellsIndex === changeIndex) {
                  return change(cell);
                } else {
                  return cell;
                }
              });
            });
            break;
          }
        }
      },
      [onChangeClinicalCells, onChangeResearchCells],
    );

    const description = useMemo(
      () =>
        editMode === "editable"
          ? t("gdc.使用する細胞補足(年次報告)")
          : undefined,
      [editMode, t],
    );

    //commentsListをフィルタリングする関数
    const { filterCommetsListByEndWith } = useLinkCommentPath();

    return (
      <FormSection
        title={t("lbl.使用する細胞")}
        description={description}
        commentButtonProps={commentButtonProps}
        commentsList={commentsList}
        sx={sx}
        {...rest}
      >
        <VStack spacing={"10px"} alignItems={"stretch"}>
          {valueResearchCells.map((v, index) => (
            <CMFrameCard
              title={t("lbl.研究用株") + (index + 1)}
              key={index}
              commentButtonProps={
                hasValue(commentButtonPropsResearchCells) &&
                  index < commentButtonPropsResearchCells.length
                  ? commentButtonPropsResearchCells[index]
                  : undefined
              }
              //研究用株の修正依頼コメント・返信を株ごとに表示する
              commentsList={
                hasValue(commentButtonPropsResearchCells) &&
                  hasValue(commentsListResearchCells) &&
                  index < commentButtonPropsResearchCells.length
                  ? filterCommetsListByEndWith(
                    commentsListResearchCells,
                    commentButtonPropsResearchCells[index]?.id
                  )
                  : undefined
              }
              backgroundColor={"gray.200"}
            >
              <SectionContentFrame pt={"10px"} pl={"20px"}>
                <UsedCellCard
                  value={v}
                  cellType={"research_purpose"}
                  index={index}
                  editMode={editMode}
                  onChange={handleChange}
                />
              </SectionContentFrame>
            </CMFrameCard>
          ))}
        </VStack>
        <Divider borderColor={"gray.400"} />
        <VStack spacing={"10px"} alignItems={"stretch"}>
          {valueClinicalCells.map((v, index) => (
            <CMFrameCard
              title={t("lbl.臨床用株") + (index + 1)}
              key={index}
              commentButtonProps={
                hasValue(commentButtonPropsClinicalCells) &&
                  index < commentButtonPropsClinicalCells.length
                  ? commentButtonPropsClinicalCells[index]
                  : undefined
              }
              //臨床用株の修正依頼コメント・返信を株ごとに表示する
              commentsList={
                hasValue(commentButtonPropsClinicalCells) &&
                  hasValue(commentsListClinicalCells) &&
                  index < commentButtonPropsClinicalCells.length
                  ? filterCommetsListByEndWith(
                    commentsListClinicalCells,
                    commentButtonPropsClinicalCells[index]?.id
                  )
                  : undefined
              }
              backgroundColor={"gray.200"}
            >
              <SectionContentFrame pt={"10px"} pl={"20px"}>
                <UsedCellCard
                  value={v}
                  cellType={"clinical_purpose"}
                  index={index}
                  editMode={editMode}
                  onChange={handleChange}
                />
              </SectionContentFrame>
            </CMFrameCard>
          ))}
        </VStack>
      </FormSection >
    );
  },
);
