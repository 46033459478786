import {
  messageId,
  PropertyMeta,
  ValueObjectMetaComposite,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { yearMeta } from "../value/year";
import { monthMeta } from "../value/month";
/**
 * 年月(値オブジェクト)
 *
 *  */
export type YearMonth = z.infer<typeof schema>;
export type YearMonthSaved = z.infer<typeof savedSchema>;
const minimalSchema = z.object({
  year: yearMeta.minimalSchema,
  month: monthMeta.minimalSchema,
});
const savedMinimalSchema = z.object({
  year: yearMeta.savedMinimalSchema,
  month: monthMeta.savedMinimalSchema,
});
const schema = z.object({ year: yearMeta.schema, month: monthMeta.schema });
const savedSchema = z.object({
  year: yearMeta.savedSchema,
  month: monthMeta.savedSchema,
});
const jsonSchema = z.object({
  year: yearMeta.jsonSchema.nullish().transform((x) => x ?? undefined),
  month: monthMeta.jsonSchema.nullish().transform((x) => x ?? undefined),
});
/**
 * 年月(値オブジェクト)のメタデータ。
 *
 *  */
export const yearMonthMeta = new ValueObjectMetaComposite(
  "YearMonth",
  "\u5E74\u6708",
  messageId("lbl.\u5E74\u6708"),
  minimalSchema,
  savedMinimalSchema,
  schema,
  savedSchema,
  jsonSchema,
  undefined,
  undefined,
  {
    year: new PropertyMeta(
      "year",
      "\u5E74",
      messageId("lbl.\u5E74"),
      "always",
      false,
      yearMeta
    ),
    month: new PropertyMeta(
      "month",
      "\u6708",
      messageId("lbl.\u6708"),
      "always",
      false,
      monthMeta
    ),
  }
);
