import {
  ApplicationContentViewModel,
  ApplicationDocument,
} from "../../../../document/application/_components/ApplicationDocument/ApplicationDocument";
import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";

import { useNavigate } from "react-router-dom";
import useCustomToast from "../../../../../../hooks/use-custom-toast";
import { useAppSelector } from "../../../../../../hooks/redux-hooks";
import { selectUserInfo } from "../../../../../../store/auth/slice";
import React, { useCallback, useRef, useState } from "react";
import { useLeaveEditingPrompt } from "../../../../../../hooks/use-leave-editing-prompt";
import { useForceUpdateProjectMutation } from "../../../../../../store/api/generated/stock-request-api";
import { useApplicationContentValidation } from "../../../../../../hooks/document/application-content/use-application-content-validation";
import { hasValue, isNullish } from "../../../../../../lib/util/common-util";
import log from "loglevel";
import { errorMessageOf } from "../../../../../../lib/util/error-util";
import { HStack, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { CommonNextButton } from "../../../../../ui/button/CommonNextButton/CommonNextButton";
import { FrameUpperRightButton } from "../../../../../ui/frame/FrameUpperRightButton/FrameUpperRightButton";
import { EditEndButton } from "../../../../../ui/button/EditEndButton/EditEndButton";
import { useCreateJsonPatchOperations } from "../../../../../../hooks/use-create-json-patch-operations";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { SaveChangesButton } from "../../../../../ui/button/SaveChangesButton/SaveChangesButton";
import { applicationContentMeta } from "../../../../../../lib/object/value/application-content";
import { ConfirmationModal } from "../../../../../ui/modal/ConfirmationModal/ConfirmationModal";

/**
 * 案件内容強制変更
 */
export type ProjectForceUpdatePageProps = {
  projectId: number;
  /**
   * 画面表示時の書類内容の初期値。
   */
  initialViewModel: ApplicationContentViewModel;
} & ComponentStyleProps;

/**
 * 新規申請作成画面
 */
export const ProjectForceUpdatePage = ({
  projectId,
  initialViewModel,
  sx,
  ...rest
}: ProjectForceUpdatePageProps) => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const { errorToast } = useCustomToast();
  const loginUserInfo = useAppSelector(selectUserInfo);

  /**
   * 編集中の書類内容の現在の値。
   */
  const [viewModel, setViewModel] =
    useState<ApplicationContentViewModel>(initialViewModel);

  const { navigateWithoutPrompt } = useLeaveEditingPrompt();

  const [forceUpdateProject] = useForceUpdateProjectMutation();

  const { validate, validationErrors } = useApplicationContentValidation();

  /**
   * 書類内容が編集されるたびに呼ばれる。
   */
  const handleChange = useCallback(
    (
      change: (
        before: ApplicationContentViewModel,
      ) => ApplicationContentViewModel,
    ) => {
      setViewModel(change);
    },
    [],
  );

  const { createJsonPatchOperations } = useCreateJsonPatchOperations(
    applicationContentMeta,
  );

  /**
   * 保存ボタン押下時、確認へ進むボタン押下時に呼ばれる。
   */
  const handleSaveDraft = useCallback(
    async (to: string) => {
      const validationResult = await validate(viewModel, false);
      if (validationResult.state !== "ok") return;

      const jsonPatchOperations = createJsonPatchOperations(
        initialViewModel,
        validationResult.value,
      );
      if (isNullish(jsonPatchOperations)) return;

      try {
        await forceUpdateProject({
          projectId: projectId,
          commonUpdateRequest: {
            patchOperations: jsonPatchOperations,
          },
        }).unwrap();
        await navigateWithoutPrompt(to);
      } catch (e) {
        errorToast(t("mes.汎用エラーメッセージ"));
        log.error(errorMessageOf(e));
      }
    },
    [
      createJsonPatchOperations,
      errorToast,
      forceUpdateProject,
      initialViewModel,
      navigateWithoutPrompt,
      projectId,
      t,
      validate,
      viewModel,
    ],
  );

  /**
   * 編集終了ボタン押下時に呼ばれる。
   */
  const handleClickEditingEnd = useCallback(() => {
    navigate(`/project/${projectId}/content`);
  }, [projectId, navigate]);

  //変更保存確認モーダルの制御。
  const {
    isOpen: isOpenSaveModal,
    onOpen: onOpenSaveModal,
    onClose: onCloseSaveModal,
  } = useDisclosure();

  const scrollableRef = useRef(null);

  return (
    <>
      <VStack
        overflow={"auto"}
        spacing={0}
        sx={sx}
        {...rest}
        alignItems={"stretch"}
        ref={scrollableRef}
      >
        <FrameUpperRightButton sx={{ alignSelf: "flex-end" }}>
          <HStack w={"max-content"}>
            <SaveChangesButton onClick={onOpenSaveModal} />
            <EditEndButton onClick={handleClickEditingEnd} />
          </HStack>
        </FrameUpperRightButton>
        <VStack position={"sticky"} top={0} bgColor={"white"} zIndex={3}>
          <Text mt={"20px"} fontSize={"2xl"} fontWeight={"bold"}>
            {t("lbl.案件強制編集")}
          </Text>
        </VStack>
        {hasValue(loginUserInfo) && (
          <VStack alignSelf={"stretch"} pb={"100px"}>
            <ApplicationDocument
              loginUserInfo={loginUserInfo}
              documentType={"project_force_update"}
              editMode={"editable"}
              projectId={projectId}
              showControlNumbers={false}
              projectControlNumber={null}
              documentControlNumber={null}
              showDates={false}
              submissionDate={null}
              receptionDate={null}
              approvalDate={null}
              documentCompletionDate={null}
              value={viewModel}
              onChange={handleChange}
              freezeFirstContactPerson={false}
              validationErrors={validationErrors}
              scrollableRef={scrollableRef}
              scrollOffset={60}
            />
            {/*提出ボタン*/}
            <CommonNextButton
              label={t("btn.変更を保存するボタン")}
              onClick={onOpenSaveModal}
            />
          </VStack>
        )}
      </VStack>
      {/*変更保存確認時に表示するモーダル*/}
      <ConfirmationModal
        isOpen={isOpenSaveModal}
        message={t("mes.変更保存確認メッセージ")}
        onSubmit={() => handleSaveDraft(`/project/${projectId}/content`)}
        onCancel={onCloseSaveModal}
      />
    </>
  );
};
