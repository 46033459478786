import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * 財団との共同研究契約状況区分(値オブジェクト)
 *
 *
 *
 * not_yet:
 * discussing:
 * agreed:  */
export type CollaborativeResearchContractStateWithFoundationType = z.infer<
  typeof collaborativeResearchContractStateWithFoundationTypeMeta.schema
>;
/**
 * 財団との共同研究契約状況区分(値オブジェクト)のメタデータ。
 *
 *  */
export const collaborativeResearchContractStateWithFoundationTypeMeta =
  new ValueObjectMetaEnum<["not_yet", "discussing", "agreed"]>(
    "CollaborativeResearchContractStateWithFoundationType",
    "\u8CA1\u56E3\u3068\u306E\u5171\u540C\u7814\u7A76\u5951\u7D04\u72B6\u6CC1\u533A\u5206",
    messageId(
      "lbl.\u8CA1\u56E3\u3068\u306E\u5171\u540C\u7814\u7A76\u5951\u7D04\u72B6\u6CC1\u533A\u5206"
    ),
    ["not_yet", "discussing", "agreed"],
    {
      not_yet:
        "code.\u8CA1\u56E3\u3068\u306E\u5171\u540C\u7814\u7A76\u5951\u7D04\u72B6\u6CC1\u533A\u5206.\u7DE0\u7D50\u524D",
      discussing:
        "code.\u8CA1\u56E3\u3068\u306E\u5171\u540C\u7814\u7A76\u5951\u7D04\u72B6\u6CC1\u533A\u5206.\u5354\u8B70\u4E2D",
      agreed:
        "code.\u8CA1\u56E3\u3068\u306E\u5171\u540C\u7814\u7A76\u5951\u7D04\u72B6\u6CC1\u533A\u5206.\u7DE0\u7D50\u6E08",
    }
  );
