import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";
import React, { memo, useEffect, useMemo, useState } from "react";
import { CMSearchResultTable } from "@pscsrvlab/psc-react-components";
import log from "loglevel";
import {
  getMessageFromEnumValue,
  hasValue,
} from "../../../../../../lib/util/common-util";
import { SearchResultRowContent } from "@pscsrvlab/psc-react-components/src/components/search/row/types";
import { TFunction } from "i18next";
import { ymdToDateText } from "../../../../../../lib/util/common-date-util";
import { QueryDefinition } from "@reduxjs/toolkit/query";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { LazyQueryTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { errorMessageOf } from "../../../../../../lib/util/error-util";
import { SearchResultTableContent } from "@pscsrvlab/psc-react-components/src/components/search/table/types";
import { DocumentSaved } from "../../../../../../lib/object/entity/document";
import { stockRequestApi } from "../../../../../../store/api/enhanced-api";
import {
  GetProjectApiArg,
  Project as ProjectJson,
} from "../../../../../../store/api/generated/stock-request-api";
import { projectMeta } from "../../../../../../lib/object/entity/project";
import { documentTypeMeta } from "../../../../../../lib/object/value/document-type";
import { documentStateMeta } from "../../../../../../lib/object/value/document-state";
import { documentPositionMeta } from "../../../../../../lib/object/value/document-position";
import {
  ReviewType,
  reviewTypeMeta,
} from "../../../../../../lib/object/value/review-type";
import { resultMeta } from "../../../../../../lib/object/value/result";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { SearchResultCellContent } from "@pscsrvlab/psc-react-components/src/components/search/cell";

export type SearchResultTableDocumentProps = {
  entities: { value: DocumentSaved; reviewType?: ReviewType; todo: boolean }[];

  isApplicant: boolean;
  isExecutiveDirector: boolean;
} & ComponentStyleProps;

/**
 * 書類検索結果テーブル。
 */
export const SearchResultTableDocument = memo(
  function SearchResultTableDocument({
    entities,

    isApplicant,
    isExecutiveDirector,

    sx,
    ...rest
  }: SearchResultTableDocumentProps) {
    const { t } = useAppTranslation();
    const [triggerGetProjectQuery] = stockRequestApi.useLazyGetProjectQuery();

    const header = useMemo(() => {
      return {
        cells: [
          { value: t("lbl.書類管理番号"), width: "140px" },
          { value: t("lbl.TODO"), width: "60px" },
          { value: t("lbl.機関名"), width: "120px" },
          { value: t("lbl.書類種別"), width: undefined },
          { value: t("lbl.書類状態"), width: undefined },
          { value: t("lbl.書類位置"), width: undefined },
          isApplicant
            ? undefined
            : { value: t("lbl.審査種別"), width: undefined },
          { value: t("lbl.結果"), width: undefined },
          {
            category: t("lbl.案件"),
            value: t("lbl.案件管理番号"),
            width: "130px",
          },
          {
            category: t("lbl.案件"),
            value: t("lbl.課題名"),
            width: "150px",
          },
          {
            category: t("lbl.案件"),
            value: t("lbl.研究責任者名"),
            width: "150px",
          },
          {
            category: t("lbl.日付"),
            value: t("lbl.提出日"),
            width: "100px",
          },
          {
            category: t("lbl.日付"),
            value: t("lbl.受付日"),
            width: "100px",
          },
          {
            category: t("lbl.日付"),
            value: t("lbl.承認日"),
            width: "100px",
          },
          {
            category: t("lbl.日付"),
            value: t("lbl.完了日"),
            width: "100px",
          },
          { value: t("lbl.更新日時"), width: "140px" },
        ].filter(hasValue),
      };
    }, [t, isApplicant]);

    const [tableContent, setTableContent] = useState<SearchResultTableContent>({
      header: { cells: [] },
      rows: [],
    });
    // テーブル表示用にデータを整形する
    useEffect(() => {
      (async () => {
        try {
          // 各項目ごとにデータを整形
          const rows: SearchResultRowContent[] = await createRows(
            entities,
            isApplicant,
            isExecutiveDirector,
            t,
            triggerGetProjectQuery,
          );

          // テーブル表示に必要なデータを設定
          setTableContent({
            header,
            rows,
          });
        } catch (e) {
          log.error(errorMessageOf(e));
        }
      })();
    }, [
      entities,
      t,
      triggerGetProjectQuery,
      header,
      isApplicant,
      isExecutiveDirector,
    ]);

    return (
      <CMSearchResultTable
        content={tableContent}
        stickyHeaderTop={"29px"}
        stickyFirstColLeft={"30px"}
        stickySecondColLeft={"170px"}
        sx={sx}
        {...rest}
      />
    );
  },
);

function createRows(
  entities: { value: DocumentSaved; reviewType?: ReviewType; todo: boolean }[],
  isApplicant: boolean,
  isExecutiveDirector: boolean,
  t: TFunction<"translation", undefined, "translation">,
  triggerGetProjectQuery: LazyQueryTrigger<
    QueryDefinition<
      GetProjectApiArg,
      BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, {}>,
      string,
      ProjectJson,
      "api"
    >
  >,
): Promise<SearchResultRowContent[]> {
  return Promise.all(
    entities.map(async ({ value: entity, reviewType, todo }) => {
      const projectJson = await triggerGetProjectQuery({
        projectId: entity.projectId,
      }).unwrap();
      const project = projectMeta.toSavedDomainObjectOrNull(projectJson, true);
      const linkPath = getLinkPath(entity, isApplicant, isExecutiveDirector);

      const cells: (SearchResultCellContent | undefined)[] = [
        {
          // 書類管理番号
          type: "react_router_link",
          value: {
            text: entity.documentControlNumber,
            to: linkPath,
          },
        },
        {
          // ＴＯＤＯ (<-半角にしたらIDEに誤解されるので全角)
          type: "notification_badge",
          value: todo,
        },
        {
          // 機関名
          type: "text",
          value: project?.applicationContent?.institution?.institutionName,
        },
        {
          // 書類種別
          type: "text",
          value: getMessageFromEnumValue(
            t,
            documentTypeMeta,
            entity.documentType,
          ),
        },
        {
          // 書類状態
          type: "text",
          value: getMessageFromEnumValue(
            t,
            documentStateMeta,
            entity.documentState,
          ),
        },
        {
          // 書類位置
          type: "text",
          value: getMessageFromEnumValue(
            t,
            documentPositionMeta,
            entity.documentPosition,
          ),
        },
        isApplicant
          ? undefined
          : {
              // 審査種別
              type: "text",
              value: getMessageFromEnumValue(t, reviewTypeMeta, reviewType),
            },
        {
          // 結果
          type: "text",
          value: getMessageFromEnumValue(t, resultMeta, entity.result),
        },
        {
          // 案件管理番号
          type: "text",
          value: project?.projectControlNumber,
        },
        {
          // 課題名
          type: "text",
          value: project?.applicationContent?.projectName,
        },
        {
          // 研究責任者名
          type: "text",
          value: project?.applicationContent?.principalInvestigator?.fullName,
        },
        {
          // 提出日
          type: "text",
          value: hasValue(entity.submissionDate)
            ? ymdToDateText(entity.submissionDate)
            : undefined,
        },
        {
          // 受付日
          type: "text",
          value: hasValue(entity.receptionDate)
            ? ymdToDateText(entity.receptionDate)
            : undefined,
        },
        {
          // 承認日
          type: "text",
          value: hasValue(entity.approvalDate)
            ? ymdToDateText(entity.approvalDate)
            : undefined,
        },
        {
          // 完了日
          type: "text",
          value: hasValue(entity.documentCompletionDate)
            ? ymdToDateText(entity.documentCompletionDate)
            : undefined,
        },
        {
          // 更新日時
          type: "datetime",
          value: entity.updated?.datetime,
        },
      ];
      return {
        cells: cells.filter(hasValue),
      };
    }),
  );
}

function getLinkPath(
  entity: DocumentSaved,
  isApplicant: boolean,
  isExecutiveDirector: boolean,
): string {
  if (isApplicant && entity.documentState === "draft") {
    switch (entity.documentType) {
      case "new_application":
        return `/document/create-new-application/create/${entity.id}`;
      case "change_application":
        return `/document/create-change-application/${entity.projectId}/create/${entity.id}`;
      case "annual_report":
        return `/document/create-annual-report/${entity.projectId}/create/${entity.id}`;
      case "termination_report":
        return `/document/create-termination-report/${entity.projectId}/create/${entity.id}`;
    }
  }

  if (
    isExecutiveDirector &&
    entity.documentState === "executive_director_reviewing"
  ) {
    return `/document/${entity.id}/review`;
  }

  return `/document/${entity.id}`;
}
