import { VStack } from "@chakra-ui/react";
import { CMMessageInfo } from "@pscsrvlab/psc-react-components";
import React, { Ref, useMemo } from "react";
import { hasValue, isNullish } from "../../../../../../lib/util/common-util";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { NewApplicationDocumentReadOnly } from "../../../application/new-application/NewApplicationDocumentReadOnly/NewApplicationDocumentReadOnly";
import { ChangeApplicationDocumentReadOnly } from "../../../application/change-application/ChangeApplicationDocumentReadOnly/ChangeApplicationDocumentReadOnly";
import { useAppGetDocumentSnapshotQuery } from "../../../../../../hooks/query/use-app-get-document-snapshot-query";
import { newApplicationMeta } from "../../../../../../lib/object/entity/new-application";
import log from "loglevel";
import { changeApplicationMeta } from "../../../../../../lib/object/entity/change-application";
import { useNewApplicationVM } from "../../../../../../hooks/document/new-application/use-new-application-vm";
import { useChangeApplicationVM } from "../../../../../../hooks/document/change-application/use-change-application-vm";

export type ReviewDetailsDocumentContentProps = {
  documentSnapshotId: number;

  scrollableRef: Ref<any>;
  scrollOffset: number;
};

export const ReviewDetailsDocumentContent = ({
  documentSnapshotId,

  scrollableRef,
  scrollOffset,
}: ReviewDetailsDocumentContentProps) => {
  const { t } = useAppTranslation();

  const { data: documentSnapshot } = useAppGetDocumentSnapshotQuery({
    documentSnapshotId,
  });

  const newApplication = useMemo(() => {
    if (
      isNullish(documentSnapshot) ||
      documentSnapshot.documentType !== "new_application"
    )
      return null;
    const newApplication = newApplicationMeta.toSavedDomainObjectOrNull(
      documentSnapshot.documentInformationJson,
    );
    if (isNullish(newApplication)) {
      log.error(
        `書類スナップショットの新規申請への変換に失敗しました: documentSnapshot=`,
        documentSnapshot,
      );
      return null;
    }
    return newApplication;
  }, [documentSnapshot]);
  const newApplicationVM = useNewApplicationVM(newApplication);

  const changeApplication = useMemo(() => {
    if (
      isNullish(documentSnapshot) ||
      documentSnapshot.documentType !== "change_application"
    )
      return null;
    const changeApplication = changeApplicationMeta.toSavedDomainObjectOrNull(
      documentSnapshot.documentInformationJson,
    );
    if (isNullish(changeApplication)) {
      log.error(
        `書類スナップショットの変更申請への変換に失敗しました: documentSnapshot=`,
        documentSnapshot,
      );
      return null;
    }
    return changeApplication;
  }, [documentSnapshot]);
  const changeApplicationVM = useChangeApplicationVM(changeApplication);

  return (
    <>
      <VStack flex={1} alignItems={"flex-start"}>
        <CMMessageInfo
          label={t("gdc.書類内容インフォメーション(審査結果確定時の書類内容)")}
        />

        {hasValue(newApplicationVM) ? (
          <NewApplicationDocumentReadOnly
            vm={newApplicationVM}
            scrollableRef={scrollableRef}
            scrollOffset={scrollOffset}
          />
        ) : hasValue(changeApplicationVM) ? (
          <ChangeApplicationDocumentReadOnly
            vm={changeApplicationVM}
            scrollableRef={scrollableRef}
            scrollOffset={scrollOffset}
          />
        ) : null}
      </VStack>
    </>
  );
};
