import { Auth } from "@aws-amplify/auth";
import { isLogLevel } from "./lib/util/logging";

const cognitoDomain = process.env.REACT_APP_COGNITO_DOMAIN;
const region = process.env.REACT_APP_COGNITO_REGION;
const userPoolId = process.env.REACT_APP_COGNITO_USER_POOL_ID;
const clientId = process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID;
const redirectSignInUri = process.env.REACT_APP_COGNITO_REDIRECT_SIGN_IN_URI;
const redirectSignOutUri = process.env.REACT_APP_COGNITO_REDIRECT_SIGN_OUT_URI;
const backendApiBaseUrl = process.env.REACT_APP_BACKEND_API_BASE_URL;
const defaultLogLevel = process.env.REACT_APP_DEFAULT_LOG_LEVEL;

export const appConfig = {
  backendApiBaseUrl,
  defaultLogLevel: isLogLevel(defaultLogLevel) ? defaultLogLevel : "info",
};

export const awsExports = {
  API: {
    endpoints: [
      {
        name: "StockRequestServiceCore",
        endpoint: backendApiBaseUrl,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getAccessToken()
              .getJwtToken()}`,
          };
        },
      },
    ],
  },
  Auth: {
    region: region,
    userPoolId: userPoolId,
    userPoolWebClientId: clientId,
    oauth: {
      domain: cognitoDomain,
      scope: ["email", "openid", "aws.cognito.signin.user.admin"],
      redirectSignIn: redirectSignInUri,
      redirectSignOut: redirectSignOutUri,
      responseType: "code",
    },
  },
};
