import { ErrorMessageObject, messageId } from "@pscsrvlab/psc-react-components";
import { TerminationReportContent } from "../value/termination-report-content";
import { isNullish } from "../../util/common-util";

export function terminationReportContentCustomValidation(
  value: TerminationReportContent,
  skipOptional: boolean,
): ErrorMessageObject[] {
  if (skipOptional) return [];

  const errorMessageList: ErrorMessageObject[] = [];

  if (isNullish(value.usageEndDate)) {
    errorMessageList.push({
      errorMessageId: messageId("mes.必須チェックエラー2"),
      errorMessageInterpolations: { name: "$t(lbl.使用終了日)" },
    });
  }

  if (
    value.cellProvisionType === "provided" &&
    value.cellProvisions.length === 0
  ) {
    errorMessageList.push({
      errorMessageId: messageId("mes.分化細胞必須エラー"),
    });
  }

  return errorMessageList;
}
