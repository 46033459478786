import React, { memo, useCallback, useMemo } from "react";
import { Box, HStack, Text } from "@chakra-ui/react";
import {
  CMFormInputDate,
  CMFormInputTime,
} from "@pscsrvlab/psc-react-components";
import {
  getDateYmd,
  getTimeHm,
  ymdToJsDate,
} from "../../../../../../lib/util/common-date-util";
import { isNullish } from "../../../../../../lib/util/common-util";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { FormSection } from "../../../../../ui/form/FormSection/FormSection";
import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";
import { YearMonthDay } from "../../../../../../lib/object/value/year-month-day";

export type SectionReviewConferenceTimeProps = {
  editMode?: "editable" | "readOnly";

  reviewConferenceDate?: { year: number; month: number; day: number };
  startDatetime?: Date;
  endDatetime?: Date;

  /**
   * 変更イベント
   */
  onChange: (changedValue: {
    reviewConferenceDate:
      | { year: number; month: number; day: number }
      | undefined;
    startDatetime: Date | undefined;
    endDatetime: Date | undefined;
  }) => void;
} & ComponentStyleProps;

/**
 * FN50S02 審査会詳細
 * 時間セクション
 */
export const SectionReviewConferenceTime = memo(
  function SectionReviewConferenceTime({
    editMode = "readOnly",

    reviewConferenceDate,
    startDatetime,
    endDatetime,

    onChange,

    sx,
    ...rest
  }: SectionReviewConferenceTimeProps) {
    const { t } = useAppTranslation();

    /**
     * 開始日時
     */
    const startTime = useMemo(() => {
      if (isNullish(startDatetime)) {
        return undefined;
      }

      const [hour, minute] = getTimeHm(startDatetime);
      return { hour, minute };
    }, [startDatetime]);

    /**
     * 終了日時
     */
    const endTime = useMemo(() => {
      if (isNullish(endDatetime)) {
        return undefined;
      }

      const [hour, minute] = getTimeHm(endDatetime);
      return { hour, minute };
    }, [endDatetime]);

    /**
     * 時刻をDate型に変換する
     */
    const createDateFromTime = useCallback(
      (v: { hour: number; minute: number } | null) => {
        if (isNullish(v)) {
          return undefined;
        }

        // 日時の情報が設定されてない場合、変換に失敗した場合は現在日時を元にDateを作成
        const date = isNullish(reviewConferenceDate)
          ? new Date()
          : ymdToJsDate(reviewConferenceDate) ?? new Date();

        // 日付と時刻の値からDate型を作成して返却
        const [year, month, day] = getDateYmd(date);
        return new Date(year, month - 1, day, v.hour, v.minute);
      },
      [reviewConferenceDate],
    );

    /**
     * 日付が変更された場合に実行する
     * 日付の情報に合わせて時刻の日付部分を更新する
     * @param date 日付
     * @param time 時刻
     */
    const updateDateTime = useCallback(
      (
        date: { year: number; month: number; day: number } | null,
        time: Date | undefined,
      ) => {
        if (isNullish(date) || isNullish(time)) {
          return time;
        }

        // 日付と時刻の情報が存在する場合は、それぞれの値を組み合わせてDateを作成する
        const [hour, minute] = getTimeHm(time);
        return new Date(date.year, date.month - 1, date.day, hour, minute);
      },
      [],
    );

    const handleChange = useCallback(
      (
        type: "date" | "startTime" | "endTime",
        changedDate: { year: number; month: number; day: number } | null,
        changedStartTime: { hour: number; minute: number } | null,
        changedEndTime: { hour: number; minute: number } | null,
      ) => {
        // 変更箇所以外は引数を呼び出し元に返却する
        if (type === "date") {
          onChange?.({
            reviewConferenceDate: changedDate ?? undefined,
            startDatetime: updateDateTime(changedDate, startDatetime),
            endDatetime: updateDateTime(changedDate, endDatetime),
          });
        } else if (type === "startTime") {
          // 変更後の値が存在する場合
          onChange?.({
            reviewConferenceDate: reviewConferenceDate,
            startDatetime: changedStartTime
              ? createDateFromTime(changedStartTime)
              : undefined,
            endDatetime: endDatetime,
          });
        } else if (type === "endTime") {
          // 変更後の値が存在する場合
          onChange?.({
            reviewConferenceDate: reviewConferenceDate,
            startDatetime: startDatetime,
            endDatetime: changedEndTime
              ? createDateFromTime(changedEndTime)
              : undefined,
          });
        }
      },
      [
        createDateFromTime,
        endDatetime,
        onChange,
        reviewConferenceDate,
        startDatetime,
        updateDateTime,
      ],
    );

    return (
      <FormSection title={t("lbl.開催日時")} sx={sx} {...rest}>
        <HStack>
          <Box w={"150px"}>
            <CMFormInputDate
              editMode={editMode}
              noHeader={true}
              size={"md"}
              value={reviewConferenceDate}
              onChange={(v: YearMonthDay | null) => {
                handleChange("date", v, null, null);
              }}
            />
          </Box>
          <Box w={editMode === "editable" ? "150px" : undefined}>
            <CMFormInputTime
              editMode={editMode}
              noHeader={true}
              size={"lg"}
              value={startTime}
              onChange={(v: { hour: number; minute: number } | null) => {
                handleChange("startTime", null, v, null);
              }}
            />
          </Box>

          <Text>{t("lbl.範囲シンボル")}</Text>
          <Box w={"150px"}>
            <CMFormInputTime
              editMode={editMode}
              noHeader={true}
              size={"lg"}
              value={endTime}
              onChange={(v: { hour: number; minute: number } | null) => {
                handleChange("endTime", null, null, v);
              }}
            />
          </Box>
        </HStack>
      </FormSection>
    );
  },
);
