import {
  CellStock as CellStockJson,
  useListCellStockQuery,
} from "../../store/api/generated/stock-request-api";
import { isNullish } from "../../lib/util/common-util";
import { useMemo } from "react";
import {
  cellStockMeta,
  CellStockSaved,
} from "../../lib/object/value/cell-stock";

export function useAppListCellStockQuery(
  arg: Parameters<typeof useListCellStockQuery>[0],
  options?: Parameters<typeof useListCellStockQuery>[1],
  skipOptionalValidations = true, // get系は、skipOptionalValidationsのデフォルトはtrueとする。
): { data: CellStockSaved[] | null } {
  const { data: rawData } = useListCellStockQuery(arg, options);
  const data = useMemo(() => {
    if (isNullish(rawData)) return null;
    return rawData.map((d: CellStockJson) =>
      cellStockMeta.toSavedDomainObjectOrNull(d, skipOptionalValidations),
    );
  }, [rawData, skipOptionalValidations]);
  return { data };
}
