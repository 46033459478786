import {
  messageId,
  PropertyMeta,
  ValueObjectMetaString,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.string().max(10000);
const savedMinimalSchema = z.string().max(10000);
const schema = z.string().min(1).max(10000);
const savedSchema = z.string().min(1).max(10000);
const jsonSchema = z.string();
/**
 * メール本文(値オブジェクト)のメタデータ。
 *
 *  */
export const mailContentMeta = new ValueObjectMetaString(
  "MailContent",
  "\u30E1\u30FC\u30EB\u672C\u6587",
  messageId("lbl.\u30E1\u30FC\u30EB\u672C\u6587"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
