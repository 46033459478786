import { useLocation } from "react-router-dom";
import { useAppParams } from "../../../../hooks/use-app-params";
import { useMemo } from "react";
import { hasValue } from "../../../../lib/util/common-util";
import { CellStockDeliveryWrap } from "./_components/CellStockDeliveryWrap";

/**
 * FN30-S09細胞発送管理
 */
export const FN30S09CellStockDelivery = () => {
  const { cellStockDeliveryId } = useAppParams();

  const location = useLocation();
  const isNewCreate = useMemo(() => {
    const _isNewCreate = location.state?.isNewCreate;
    if (typeof _isNewCreate === "boolean") return _isNewCreate;
    return false;
  }, [location.state]);

  return (
    <>
      {hasValue(cellStockDeliveryId) && (
        <CellStockDeliveryWrap
          key={cellStockDeliveryId}
          cellStockDeliveryId={cellStockDeliveryId}
          initialEditMode={isNewCreate ? "editable" : "readOnly"}
        />
      )}
    </>
  );
};
