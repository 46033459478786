import { ComponentStyleProps } from "../../../../../../../../lib/styles/props/component-style-props";
import { memo, useCallback, useMemo, useRef, useState } from "react";
import { Flex, HStack, Text, useOutsideClick, VStack } from "@chakra-ui/react";
import { jsDateToDatetimeText } from "../../../../../../../../lib/util/common-date-util";
import { AutoFocusTextarea } from "../../../../../../../ui/textbox/AutoFocusTextarea";
import {
  CMButton,
  CMDummyTextareaButton,
  CMFormCommentEditButton,
} from "@pscsrvlab/psc-react-components";
import { useAppTranslation } from "../../../../../../../../hooks/use-app-translation";
import {
  hasValue,
  isNullish,
} from "../../../../../../../../lib/util/common-util";
import log from "loglevel";

export type RevisionRequestCommentHistoryListCommentProps = {
  editable: boolean;
  onSave?: (value: string | null) => void;

  comment: {
    userName?: string;
    datetime?: Date;
    body?: string;
    isHistory?: boolean;
  } | null;

  isOfficeMember?: boolean;
} & ComponentStyleProps;

export const RevisionRequestCommentHistoryListComment = memo(
  function RevisionRequestCommentListComment({
    editable,
    onSave,

    comment,

    isOfficeMember = false,

    sx,
    ...rest
  }: RevisionRequestCommentHistoryListCommentProps) {
    const { t } = useAppTranslation();
    const [editing, setEditing] = useState(false);

    const datetimeText = useMemo(
      () => (hasValue(comment) && hasValue(comment.datetime) ? jsDateToDatetimeText(comment.datetime) : ""),
      [comment],
    );

    const [internalValue, setInternalValue] = useState(comment?.body ?? "");
    const handleStartEditing = useCallback(() => {
      setEditing(true);
      setInternalValue(comment?.body ?? "");
    }, [comment?.body]);

    const handleSave = useCallback(() => {
      setEditing(false);

      if (internalValue === "") {
        // 空文字列は未設定に戻す扱いとする。
        onSave?.(null);
      } else {
        onSave?.(internalValue);
      }
    }, [onSave, internalValue]);

    const handleClickDummyTextArea = useCallback(() => {
      // ちょっと汚いが、今の作りではこうしないとうまく動かない。
      onSave?.("");
      handleStartEditing();
    }, [handleStartEditing, onSave]);

    const handleCancel = useCallback(() => {
      setEditing(false);

      const _originalBody = comment?.body ?? "";
      setInternalValue(_originalBody);

      log.debug(`_originalBody=${_originalBody}`);
      if (_originalBody === "") {
        // ちょっと汚いが、今の作りではこうしないとうまく動かない。
        onSave?.(null);
      }
    }, [comment?.body, onSave]);

    const showEditButton = useMemo(
      () => editable && !editing,
      [editable, editing],
    );

    /**
     * 履歴参照コメント一覧のインデント調整を行う。
     */
    const pl = useMemo(
      () => {
        if (isOfficeMember) {
          return (comment?.isHistory) ? "30px" : "16px"
        }
        return (comment?.isHistory) ? "40px" : "26px"
      }, [comment, isOfficeMember]
    )

    const headerPr = useMemo(
      () => (showEditButton ? 0 : "10px"),
      [showEditButton],
    );

    const containerRef = useRef(null);
    useOutsideClick({
      ref: containerRef,
      handler: () => {
        if (editing) handleCancel();
      },
    });

    return (
      <>
        {hasValue(comment) && (
          <VStack
            alignItems={"stretch"}
            pt={"6px"}
            pr={"10px"}
            pb={"6px"}
            pl={pl}
            sx={sx}
            {...rest}
            ref={containerRef}
          >
            <Flex direction={"row"} alignItems={"baseline"} pr={headerPr}>
              <Text
                flex={1}
                minW={0}
                mr={"2px"}
                color={"gray.900"}
                fontWeight={"bold"}
                fontSize={"sm"}
              >
                {comment.userName}
              </Text>
              <Text
                flex={"0 0 auto"}
                ml={"auto"}
                color={"gray.500"}
                fontSize={"xs"}
              >
                {datetimeText}
              </Text>
              {showEditButton && (
                <CMFormCommentEditButton
                  ml={"2px"}
                  size={"sm"}
                  onClick={handleStartEditing}
                />
              )}
            </Flex>
            <VStack alignItems={"stretch"}>
              {editing ? (
                <VStack alignItems={"stretch"}>
                  <AutoFocusTextarea
                    value={internalValue}
                    onChange={setInternalValue}
                  />
                  <HStack minW={0}>
                    <CMButton
                      label={t("btn.保存して閉じるボタン")}
                      size={"xs"}
                      flex={"1 1 auto"}
                      minW={0}
                      onClick={handleSave}
                    />
                    <CMButton
                      label={t("btn.キャンセルボタン")}
                      colorScheme={"gray"}
                      size={"xs"}
                      flex={"0 1 auto"}
                      minW={0}
                      onClick={handleCancel}
                    />
                  </HStack>
                </VStack>
              ) : (
                <Text
                  color={"gray.900"}
                  fontSize={"sm"}
                  whiteSpace={"pre-wrap"}
                >
                  {comment.body}
                </Text>
              )}
            </VStack>
          </VStack>
        )}
        {isNullish(comment) && editable && (
          <VStack alignItems={"stretch"} px={"10px"} pb={"4px"}>
            <CMDummyTextareaButton
              label={t("lbl.申請者コメント")}
              onClick={handleClickDummyTextArea}
            />
          </VStack>
        )}
      </>
    );
  },
);
