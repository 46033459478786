import { useState } from "react";
import { Box, Center, Container, Link, Text, VStack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import useCustomToast from "../../../../hooks/use-custom-toast";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux-hooks";
import { federatedSignIn, signIn } from "../../../../store/auth/slice";
import { useFromPathnameSearchHash } from "../../../../hooks/use-from-pathname-search-hash";
import { CMFormLogin } from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { mailAddressMeta } from "../../../../lib/object/value/mail-address";
import log from "loglevel";
import { errorNameAndMessageOf } from "../../../../lib/util/error-util";
import { useAppTranslation } from "../../../../hooks/use-app-translation";
import { RootState } from "../../../../store";

/**
 * ログインページ
 */
export const FN10S01Login = () => {
  const dispatch = useAppDispatch();
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const { errorToast } = useCustomToast();
  const { enabledMfa } = useAppSelector((state: RootState) => state.auth);

  // ロード状態
  const [isLoading, setIsLoading] = useState(false);

  // 項目入力必須のバリデーションスキーマ
  const requiredSchema = z.string().min(1);

  // ログイン後の遷移先パス（デフォルトはホーム）
  const redirectPathnameSearchHash: string = useFromPathnameSearchHash();

  /**
   * ログインボタン(申請者)押下時のイベント
   * @param mailAddress メールアドレス
   * @param password パスワード
   */
  const handleSubmit = async (mailAddress: string, password: string) => {
    setIsLoading(true);
    // バリデーションエラーがある場合は中断
    if (!validate(mailAddress, password)) {
      setIsLoading(false);
      return;
    }
    // ログイン処理を実行
    await executeLogin(mailAddress, password);
  };

  /**
   * ログインリンク(CiRA_Fメンバー)押下時のイベント
   */
  const handleClickOfficeLogin = async () => {
    // 参考: https://redux-toolkit.js.org/api/createAsyncThunk#handling-thunk-results
    await dispatch(federatedSignIn(redirectPathnameSearchHash));
  };

  /**
   * バリデーションチェック
   * @param mailAddress メールアドレス
   * @param password パスワード
   * @return true: 非エラー / false: エラー
   */
  const validate = (mailAddress: string, password: string) => {
    // メールアドレスが未入力の場合
    if (!requiredSchema.safeParse(mailAddress).success) {
      errorToast(
        t("mes.必須チェックエラー2", { name: t("lbl.メールアドレス") }),
      );
      return false;
    }
    // メールアドレスの形式ではない場合
    if (mailAddressMeta.validate(mailAddress).state === "error") {
      errorToast(t("mes.メールアドレス形式チェックエラー"));
      return false;
    }
    // パスワードが未入力の場合
    if (!requiredSchema.safeParse(password).success) {
      errorToast(t("mes.必須チェックエラー2", { name: t("lbl.パスワード") }));
      return false;
    }
    return true;
  };

  /**
   * ログイン処理
   * @param mailAddress メールアドレス
   * @param password パスワード
   */
  const executeLogin = async (mailAddress: string, password: string) => {
    try {
      // 非同期処理を実行
      const result = await dispatch(
        signIn({ username: mailAddress, password: password }),
      ).unwrap();

      // サインイン情報のチャレンジに対応した画面へ遷移する
      switch (result?.challengeName) {
        // パスワード設定
        case "NEW_PASSWORD_REQUIRED":
          navigate("/login/password-settings");
          return;
        // 二段階認証
        case "SOFTWARE_TOKEN_MFA":
          navigate("/login/mfa");
          return;
      }

      // 二段階認証のリセット後はここに到達するが、エラーメッセージは表示したくないので、returnする。
      // ※二段階認証設定ページには、ProtectedRouteからリダイレクトするため、ここにはそのロジックはない。
      if (!enabledMfa) {
        return;
      }

      // 想定していないが、いずれにも該当しなかった場合はエラーとする
      errorToast(t("mes.汎用エラーメッセージ"));
      log.debug(`ログイン処理で想定外フロー`, result);
    } catch (e) {
      log.error("ログイン処理でエラー発生：", e);

      // ロードを中断
      setIsLoading(false);

      const error = errorNameAndMessageOf(e);

      if (error?.name === "NotAuthorizedException") {
        // メールアドレス、または、パスワードに誤りがある場合
        if (error?.message === "Incorrect username or password.") {
          errorToast(t("mes.ログイン失敗メッセージ"));
          return;
        }
        // 試行回数の上限を超えておりロックアウトされている場合
        // ※ 入力情報の誤りなのか、ロックアウトされているのかは同じ NotAuthorizedException が返される為、エラーメッセージから判断するしかない
        else if (error?.message === "Password attempts exceeded") {
          errorToast(t("mes.ロックアウトエラーメッセージ"));
          return;
        }
      }
      // その他のエラーが発生した場合
      errorToast(t("mes.汎用エラーメッセージ"));
      return;
    }
  };

  return (
    <>
      <Container pt={"24px"} pb={"24px"} maxW={"800px"}>
        <VStack align={"center"}>
          <Text fontSize={"24px"} fontWeight={"bold"}>
            {t("lbl.ログイン画面タイトル")}
          </Text>
          <Center>
            <CMFormLogin
              labelSubmit={t("btn.ログインボタン")}
              placeholder={{
                emailAddress: t("lbl.メールアドレス"),
                password: t("lbl.パスワード"),
              }}
              labelPasswordReissue={t("lbl.パスワードを忘れた場合")}
              linkPasswordReissue={"/reset-password/request"}
              onSubmit={handleSubmit}
              isLoading={isLoading}
            />
          </Center>
          <Link
            fontSize={"14px"}
            color={"blue.600"}
            onClick={handleClickOfficeLogin}
          >
            {t("btn.CiRA_Fログインリンク")}
          </Link>
          <Box>
            <Text mt={"30px"} fontSize={"13px"} lineHeight={"2"}>
              {t("gdc.ログイン画面問い合わせ案内")}
              <br />
              {t("lbl.ログイン画面問い合わせメールアドレス")}
              <br />
              {t("gdc.メールアドレス変換案内")}
            </Text>
          </Box>
        </VStack>
      </Container>
    </>
  );
};
