import { Text } from "@chakra-ui/react";
import React from "react";
import { ComponentStyleProps } from "../../../../lib/styles/props/component-style-props";
import { VerticalTableRow } from "../VerticalTableRow/VerticalTableRow";

export type VerticalTableRowTextProps = {
  /**
   * テーブルヘッダー(Th)として使用
   */
  label?: string;

  /**
   * テーブルデータ(Td)として使用
   */
  value?: string;
} & ComponentStyleProps;

/**
 * テーブルデータ(Td)がテキストの縦型テーブルRow
 */
export const VerticalTableRowText = ({
  label,
  value,
  sx,
  ...rest
}: VerticalTableRowTextProps) => {
  return (
    <VerticalTableRow label={label} sx={sx} {...rest}>
      <Text fontWeight={400} fontSize={"16px"} color={"gray.900"}>
        {value}
      </Text>
    </VerticalTableRow>
  );
};
