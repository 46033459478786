import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import { CMButton } from "@pscsrvlab/psc-react-components";
import { hasValue, isNullish } from "../../../../lib/util/common-util";
import { useNavigate } from "react-router-dom";
import { useMemo } from "react";
import { useAppTranslation } from "../../../../hooks/use-app-translation";
import { useAppParams } from "../../../../hooks/use-app-params";
import { useAppGetDocumentQuery } from "../../../../hooks/query/use-app-get-document-query";
import { LabeledTextVertical } from "../../../ui/text/LabeledTextVertical/LabeledTextVertical";
import { ymdToDateText } from "../../../../lib/util/common-date-util";
import { useAppGetResultNotificationQuery } from "../../../../hooks/query/use-app-get-result-notification-query";
import { useProjectPagePathPrefix } from "../../../../hooks/use-project-page-path-prefix";

export const FN40S35ResultNotificationComplete = () => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const { projectPagePathPrefix } = useProjectPagePathPrefix();

  const { documentId, resultNotificationId } = useAppParams();

  //書類情報を取得
  const { data: document } = useAppGetDocumentQuery(
    {
      documentId: documentId ?? -1,
    },
    { skip: isNullish(documentId) },
  );

  const documentControlNumber = useMemo(() => {
    if (hasValue(document?.documentControlNumber)) {
      return document?.documentControlNumber;
    }
  }, [document]);

  // 結果通知を取得
  const { data: resultNotification } = useAppGetResultNotificationQuery(
    {
      resultNotificationId: resultNotificationId ?? -1,
    },
    { skip: isNullish(resultNotificationId) },
  );

  const resultNotificationDate = useMemo(() => {
    if (
      isNullish(resultNotification) ||
      isNullish(resultNotification.resultNotificationDate)
    )
      return;
    return ymdToDateText(resultNotification.resultNotificationDate);
  }, [resultNotification]);

  const handleClick = () => {
    if (isNullish(resultNotification)) return;
    navigate(
      `${projectPagePathPrefix}/document/${resultNotification.documentId}/result-notification/${resultNotification.id}`,
    );
  };
  return (
    <Box pt={"20px"}>
      {hasValue(documentControlNumber) && (
        <VStack spacing={"20px"}>
          <Box fontSize={"2xl"} fontWeight={"bold"}>
            {t("mes.結果通知完了メッセージ")}
          </Box>
          <Text whiteSpace={"pre-wrap"}>{t("gdc.結果通知後案内")}</Text>
          <HStack>
            <LabeledTextVertical
              label={t("lbl.書類管理番号")}
              text={documentControlNumber}
            />
            <LabeledTextVertical
              label={t("lbl.結果通知日")}
              text={resultNotificationDate}
            />
          </HStack>
          <CMButton
            label={t("btn.結果通知に戻るボタン")}
            onClick={handleClick}
          />
        </VStack>
      )}
    </Box>
  );
};
