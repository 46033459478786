import { Icon } from "@chakra-ui/icons";
import { MdFileUpload } from "react-icons/md";
import { Button } from "@chakra-ui/react";
import { useAppTranslation } from "../../../../hooks/use-app-translation";

export const UploadButtonEmpty = () => {
  const { t } = useAppTranslation();
  return (
    <Button
      colorScheme={"teal"}
      leftIcon={<Icon as={MdFileUpload} />}
      size={"xs"}
    >
      {t("btn.アップロードボタン")}
    </Button>
  );
};
