import { Outlet } from "react-router-dom";
import { Flex } from "@chakra-ui/react";
import { FN00S01MainMenu } from "../../model/menu/FN00S01MainMenu/FN00S01MainMenu";
import { useLocation } from "react-router-dom";


export const MainLayout = () => {
  // BaseURL（http://localhost:3000など）以降を取得
  const currentPath = useLocation().pathname;
  return (
    <Flex
      flexFlow={"column nowrap"}
      justifyContent={"stretch"}
      alignItems={"stretch"}
      height={"100%"}
      overflow={"hidden"}
      className={"MainLayout"}
    >
      {/* 履歴参照画面の場合は画面上部のメインメニューを表示しない */}
      {!currentPath.startsWith("/revision-history") && <FN00S01MainMenu />}
      <Flex
        flex={1}
        minH={0}
        flexFlow={"column nowrap"}
        justifyContent={"stretch"}
        alignItems={"stretch"}
        overflow={"hidden"}
        className={"MainLayout-body"}
      >
        <Outlet />
      </Flex>
    </Flex>
  );
};
