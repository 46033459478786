import { Container, Flex, HStack, VStack } from "@chakra-ui/react";
import { hasValue, isNullish } from "../../../../lib/util/common-util";
import { useAppParams } from "../../../../hooks/use-app-params";
import { useAppListRevisionRequestQuery } from "../../../../hooks/query/use-app-list-revision-request-query";
import { RevisionRequestCommentHistoryList } from "./_components/RevisionRequestCommentList/RevisionRequestCommentHistoryList";
import { useMemo, useRef, useState } from "react";
import { CMMessageInfo, Comments } from "@pscsrvlab/psc-react-components";
import { t } from "i18next";
import { ChangeApplicationDocumentReadOnly } from "../application/change-application/ChangeApplicationDocumentReadOnly/ChangeApplicationDocumentReadOnly";
import { NewApplicationDocumentReadOnly } from "../application/new-application/NewApplicationDocumentReadOnly/NewApplicationDocumentReadOnly";
import { AnnualReportDocumentReadOnly } from "../report/annual-report/AnnualReportDocumentReadOnly/AnnualReportDocumentReadOnly";
import { TerminationReportDocumentReadOnly } from "../report/termination-report/TerminationReportDocumentReadOnly/TerminationReportDocumentReadOnly";
import { useSavedDocumentVM } from "../../../../hooks/document/use-saved-document-vm";
import { ComponentStyleProps } from "@pscsrvlab/psc-react-components/src/lib/styles/props/component-style-props";


export const FN40S41RevisionRequestHistory = ({
  sx,
  ...rest
}: ComponentStyleProps) => {
  // URLパラメタから各IDを取得する。
  // 書類IDは、確実に存在する。
  // 修正依頼IDは、選択済なら存在する。
  const { documentId } = useAppParams();

  const { vmAndCallback } = useSavedDocumentVM(documentId);

  const [selectedCommentPath, setSelectedCommentPath] = useState<string | null>(
    null,
  );

  const { data: revisionRequests } = useAppListRevisionRequestQuery(
    {
      documentId: documentId ?? -1,
    },
    { skip: isNullish(documentId) },
  );

  const scrollableRef = useRef(null);

  //事務局修正依頼コメントのuserNameに使用
  const officeMenberUserName = t("lbl.事務局");

  /**
   * 全件の修正依頼のitems(修正依頼コメント・返信の配列)を配列化
   */
  const revisionRequestsItems = useMemo(() => {
    return revisionRequests?.map((revisionRequest) => {
      return revisionRequest.items;
    }) ?? [[]]
  }, [revisionRequests])

  /**
   * 事務局コメント・申請者返信を1要素として配列化
   */
  const itemsList = useMemo(
    () => {
      const itemsList = []
      for (const revisionRequestsItem of revisionRequestsItems) {
        for (const items of revisionRequestsItem) {
          itemsList.push(items)
        }
      }
      /**
       * 表示される書類の項目順にコメント・返信を並び替え
       */
      itemsList.sort((a, b) => {
        if (a.path > b.path) return 1
        if (a.path < b.path) return -1
        /**
         * ">"の場合:コメント登録日の昇順
         * "<"の場合:コメント登録日の降順
         */
        if (a.officeMemberComment.datetime > b.officeMemberComment.datetime) {
          return 1
        } else {
          return -1
        }
      })
      return itemsList
    }, [revisionRequestsItems]
  )

  /**
   * 履歴参照画面用に、修正依頼コメント・返信を汎用コンポーネントに合わせた形に配列化
   * isHistory: 修正依頼コメントに履歴があるかどうか判断するフラグ
   * isHistoryがtrueの場合、コメントスレッドのインデント調整を行う
   */
  const commentsList = useMemo(
    () => {
      return itemsList.map((item, i): Comments => (
        {
          mainComment: {
            userName: officeMenberUserName,
            date: item.officeMemberComment.datetime,
            comment: item.officeMemberComment.body,
          },
          replyComment: hasValue(item.applicantComment) ? {
            userName: item.applicantComment?.commenter.fullName,
            date: item.applicantComment?.datetime,
            comment: item.applicantComment?.body,
          } : undefined,
          //修正依頼コメントが1件目の場合はfalse、修正依頼コメントが複数件存在する場合はtrue
          isHistory: i > 0 && itemsList[i - 1].path === item.path,
          path: item.path
        }
      ))
    }, [itemsList, officeMenberUserName]
  )

  return (
    <>
      <HStack
        flex={1}
        minH={0}
        alignItems="stretch"
        spacing={0}
        overflow={"hidden"}
      >
        {hasValue(documentId) &&
          hasValue(vmAndCallback) && (
          <HStack
            flex={1}
            minW={0}
            spacing={0}
            alignItems={"stretch"}
            sx={sx}
            {...rest}
          >
              <VStack
                flex={1}
                overflowY={"auto"}
                pb={"50px"}
                ref={scrollableRef}
              >
              <Flex
                alignSelf={"stretch"}
                direction={"column"}
                alignItems={"flex-start"}
              >
                <CMMessageInfo
                  label={t("gdc.修正依頼履歴参照画面案内")}
                  sx={{ alignSelf: "flex-start" }}
                  mt={"8px"}
                  ml={"8px"}
                />
                <CMMessageInfo
                  label={t("gdc.書類内容インフォメーション(書類内容は最新)")}
                  sx={{ alignSelf: "flex-start" }}
                  mt={"8px"}
                  ml={"8px"}
                />
              </Flex>

              <Container minW={"500px"} maxW={"720px"}>
                {vmAndCallback.type === "new_application" ? (
                  <NewApplicationDocumentReadOnly
                    vm={vmAndCallback.vm}
                    revisionRequestItems={itemsList}
                    commentsList={commentsList}
                    onSelectComment={setSelectedCommentPath}
                    revisionMode={"readOnly"}
                    stickyAreaTop={0}
                    scrollableRef={scrollableRef}
                    scrollOffset={60}
                  />
                  ) :
                    vmAndCallback.type === "change_application" ? (
                  <ChangeApplicationDocumentReadOnly
                    vm={vmAndCallback.vm}
                    revisionRequestItems={itemsList}
                    commentsList={commentsList}
                    onSelectComment={setSelectedCommentPath}
                    revisionMode={"readOnly"}
                    stickyAreaTop={0}
                    scrollableRef={scrollableRef}
                    scrollOffset={60}
                  />
                ) : vmAndCallback.type === "annual_report" ? (
                  <AnnualReportDocumentReadOnly
                    vm={vmAndCallback.vm}
                    revisionRequestItems={itemsList}
                    commentsList={commentsList}
                    onSelectComment={setSelectedCommentPath}
                    revisionMode={"readOnly"}
                  />
                ) : vmAndCallback.type === "termination_report" ? (
                  <TerminationReportDocumentReadOnly
                    vm={vmAndCallback.vm}
                    revisionRequestItems={itemsList}
                    commentsList={commentsList}
                    onSelectComment={setSelectedCommentPath}
                    revisionMode={"readOnly"}
                  />
                    ) :
                      null}
              </Container>
            </VStack>
            <RevisionRequestCommentHistoryList
              revisionMode={"readOnly"}
              revisionRequestsItems={revisionRequestsItems}
              commentsList={commentsList}
              selectedCommentPath={selectedCommentPath}
              scrollableRef={scrollableRef}
              scrollOffset={60}
            />
          </HStack>
        )}
      </HStack>
    </>
  );
};