import {
  messageId,
  PropertyMeta,
  ValueObjectMetaString,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.string().max(10000);
const savedMinimalSchema = z.string().max(10000);
const schema = z.string().min(1).max(10000);
const savedSchema = z.string().min(1).max(10000);
const jsonSchema = z.string();
/**
 * 使用終了後の取り扱いその他(値オブジェクト)のメタデータ。
 *
 *  */
export const handlingAfterUseOtherMeta = new ValueObjectMetaString(
  "HandlingAfterUseOther",
  "\u4F7F\u7528\u7D42\u4E86\u5F8C\u306E\u53D6\u308A\u6271\u3044\u305D\u306E\u4ED6",
  messageId(
    "lbl.\u4F7F\u7528\u7D42\u4E86\u5F8C\u306E\u53D6\u308A\u6271\u3044\u305D\u306E\u4ED6"
  ),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
