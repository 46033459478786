import {
  messageId,
  PropertyMeta,
  ValueObjectMetaComposite,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { signatureValueCustomValidation } from "../custom-validation/signature-value";
import { checklistItemCheckStateMeta } from "../value/checklist-item-check-state";
import { yearMonthDayMeta } from "../value/year-month-day";
/**
 * 署名値(値オブジェクト)
 *
 *  */
export type SignatureValue = z.infer<typeof schema>;
export type SignatureValueSaved = z.infer<typeof savedSchema>;
const minimalSchema = z.object({
  correctnessCheck: checklistItemCheckStateMeta.minimalSchema,
  checkDate: yearMonthDayMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
const savedMinimalSchema = z.object({
  correctnessCheck: checklistItemCheckStateMeta.savedMinimalSchema,
  checkDate: yearMonthDayMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
const schema = z.object({
  correctnessCheck: checklistItemCheckStateMeta.schema,
  checkDate: yearMonthDayMeta.schema.nullish().transform((x) => x ?? undefined),
});
const savedSchema = z.object({
  correctnessCheck: checklistItemCheckStateMeta.savedSchema,
  checkDate: yearMonthDayMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
const jsonSchema = z.object({
  correctnessCheck: checklistItemCheckStateMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  checkDate: yearMonthDayMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
/**
 * 署名値(値オブジェクト)のメタデータ。
 *
 *
 *
 * 以下のカスタムバリデーションを、 signatureValueCustomValidation にて行う。
 * - (任意) 事実関係チェックがチェック済であり、確認日に値があること。 (エラーメッセージID=mes.事実関係チェック必須エラーメッセージ) */
export const signatureValueMeta = new ValueObjectMetaComposite(
  "SignatureValue",
  "\u7F72\u540D\u5024",
  messageId("lbl.\u7F72\u540D\u5024"),
  minimalSchema,
  savedMinimalSchema,
  schema,
  savedSchema,
  jsonSchema,
  undefined,
  signatureValueCustomValidation,
  {
    correctnessCheck: new PropertyMeta(
      "correctnessCheck",
      "\u4E8B\u5B9F\u95A2\u4FC2\u30C1\u30A7\u30C3\u30AF",
      messageId("lbl.\u4E8B\u5B9F\u95A2\u4FC2\u30C1\u30A7\u30C3\u30AF"),
      "always",
      false,
      checklistItemCheckStateMeta
    ),
    checkDate: new PropertyMeta(
      "checkDate",
      "\u78BA\u8A8D\u65E5",
      messageId("lbl.\u78BA\u8A8D\u65E5"),
      "none",
      false,
      yearMonthDayMeta
    ),
  }
);
