import { useCallback, useEffect, useMemo, useState } from "react";
import { unstable_usePrompt as usePrompt, useNavigate } from "react-router-dom";
import { To } from "@remix-run/router";
import { NavigateOptions } from "react-router/dist/lib/context";
import { useTimeoutFn } from "react-use";
import { isNullish } from "../lib/util/common-util";
import { useAppTranslation } from "./use-app-translation";
import { merge } from "lodash";

export function useLeaveEditingPrompt(options?: {
  /**
   * skipがtrueの場合、何も行わない。
   */
  skip: boolean;
}) {
  const _options = useMemo(() => merge({ skip: false }, options), [options]);

  const { t } = useAppTranslation();
  const navigate = useNavigate();

  /**
   * 一時的にプロンプトを切る場合に利用する。
   * すぐに再有効化される。
   * ※F5リロードをした直後はなぜかページ内リンクでプロンプトが表示されてしまうという不思議な挙動をするため、一瞬プロンプトを切る必要があり、その対策。
   */
  const [temporarilyDisabled, setTemporarilyDisabled] = useState(false);
  const [_isReady, _cancel, resetReenableFn] = useTimeoutFn(() => {
    if (temporarilyDisabled) setTemporarilyDisabled(false);
  }, 500);
  const temporarilyDisablePrompt = useCallback(() => {
    setTemporarilyDisabled(true);
    resetReenableFn();
  }, [resetReenableFn]);

  /**
   * プロンプトなしでナビゲートしたい場合、ここに一時的にナビゲート情報を保存する。
   */
  const [pendingNavigationWithoutPrompt, setPendingNavigationWithoutPrompt] =
    useState<{
      to: To;
      options?: NavigateOptions;
    } | null>(null);
  useEffect(() => {
    if (isNullish(pendingNavigationWithoutPrompt)) return;
    setPendingNavigationWithoutPrompt(null);
    navigate(
      pendingNavigationWithoutPrompt.to,
      pendingNavigationWithoutPrompt.options,
    );
  }, [navigate, pendingNavigationWithoutPrompt]);
  const navigateWithoutPrompt = useCallback(
    async (to: To, options?: NavigateOptions) => {
      setPendingNavigationWithoutPrompt({ to, options });
    },
    [],
  );

  // ページ内遷移用に編集内容破棄アラートを表示させるかの制御を行う。
  usePrompt({
    when:
      !_options.skip &&
      !temporarilyDisabled &&
      isNullish(pendingNavigationWithoutPrompt),
    message: t("mes.変更内容破棄メッセージ"),
  });

  return {
    navigateWithoutPrompt,
    temporarilyDisablePrompt,
  };
}
