import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * ロール(値オブジェクト)
 *
 *
 *
 * applicant:
 * office_member:
 * committee_member:
 * executive_director:
 * internal:  */
export type Role = z.infer<typeof roleMeta.schema>;
/**
 * ロール(値オブジェクト)のメタデータ。
 *
 *  */
export const roleMeta = new ValueObjectMetaEnum<
  [
    "applicant",
    "office_member",
    "committee_member",
    "executive_director",
    "internal"
  ]
>(
  "Role",
  "\u30ED\u30FC\u30EB",
  messageId("lbl.\u30ED\u30FC\u30EB"),
  [
    "applicant",
    "office_member",
    "committee_member",
    "executive_director",
    "internal",
  ],
  {
    applicant: "code.\u30ED\u30FC\u30EB.\u7533\u8ACB\u8005",
    office_member: "code.\u30ED\u30FC\u30EB.\u4E8B\u52D9\u5C40",
    committee_member: "code.\u30ED\u30FC\u30EB.\u59D4\u54E1\u4F1A",
    executive_director:
      "code.\u30ED\u30FC\u30EB.\u696D\u52D9\u57F7\u884C\u7406\u4E8B",
    internal: "code.\u30ED\u30FC\u30EB.\u5185\u90E8",
  }
);
