import { useMemo } from "react";
import { difference, orderBy } from "lodash";
import { isNullish } from "../lib/util/common-util";

export function useKeyedFileData<
  V extends { storageFileId: number },
  CF extends V,
>(value: V[], changedFrom: CF[] | undefined) {
  const valueKeys = useMemo(() => value.map((v) => v.storageFileId), [value]);
  const changedFromKeys = useMemo(
    () => changedFrom?.map((v) => v.storageFileId) ?? [],
    [changedFrom],
  );

  const addedKeys = useMemo(
    () => difference(valueKeys, changedFromKeys),
    [changedFromKeys, valueKeys],
  );
  const deletedKeys = useMemo(
    () => difference(changedFromKeys, valueKeys),
    [changedFromKeys, valueKeys],
  );

  const allValuesIncludingDeleted: {
    mode: "none" | "added" | "deleted";
    value: V;
  }[] = useMemo(() => {
    if (isNullish(changedFrom))
      return value.map((v) => ({ mode: "none", value: v }));
    const _value = value.map((v) => ({
      mode: addedKeys.includes(v.storageFileId)
        ? ("added" as const)
        : ("none" as const),
      value: v,
    }));
    const _deleted = changedFrom
      .filter((v) => deletedKeys.includes(v.storageFileId))
      .map((v) => ({
        mode: "deleted" as const,
        value: v,
      }));
    return orderBy([..._value, ..._deleted], [(v) => v.value.storageFileId]);
  }, [changedFrom, value, addedKeys, deletedKeys]);

  return {
    valueKeys,
    changedFromKeys,
    addedKeys,
    deletedKeys,
    allValuesIncludingDeleted,
  };
}
