import { ReactNode, createContext, useState } from "react";

type AppContextType = {
  isProjectContentCsvDownloading: boolean;
  setIsProjectContentCsvDownloading: (value: boolean) => void;
  isInstitutionCsvDownloading: boolean;
  setIsInstitutionCsvDownloading: (value: boolean) => void;
};

export const AppContext = createContext<AppContextType>({
  isProjectContentCsvDownloading: false,
  setIsProjectContentCsvDownloading: () => {},
  isInstitutionCsvDownloading: false,
  setIsInstitutionCsvDownloading: () => {},
});

//アプリケーション全体で管理すべきstateを集約するコンポーネント
export const AppContextProvider = (props: { children: ReactNode }) => {
  const [isProjectContentCsvDownloading, setIsProjectContentCsvDownloading] =
    useState(false);

  const [isInstitutionCsvDownloading, setIsInstitutionCsvDownloading] =
    useState(false);

  return (
    <AppContext.Provider
      value={{
        isProjectContentCsvDownloading: isProjectContentCsvDownloading,
        setIsProjectContentCsvDownloading: setIsProjectContentCsvDownloading,
        isInstitutionCsvDownloading: isInstitutionCsvDownloading,
        setIsInstitutionCsvDownloading: setIsInstitutionCsvDownloading,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};
