import { pickBy } from "lodash";
import { hasValue } from "./common-util";

export function typedObjectKeys<T extends Record<string, any>>(
  obj: T,
): (keyof T)[] {
  return Object.keys(obj) as (keyof T)[];
}

export function typedIntegerKeyedObjectKeys<T extends Record<number, any>>(
  obj: T,
): (keyof T)[] {
  return Object.keys(obj).map((k) => parseInt(k, 10)) as (keyof T)[];
}

export function typedObjectEntries<Key extends string, Value>(
  obj: Record<Key, Value>,
): [Key, Value][] {
  return Object.entries(obj) as [Key, Value][];
}

export function typedIntegerKeyedObjectEntries<Key extends number, Value>(
  obj: Record<Key, Value>,
): [Key, Value][] {
  return Object.entries(obj).map(([k, v]) => [parseInt(k, 10), v]) as [
    Key,
    Value,
  ][];
}

export function typedObjectFromEntries<Key extends string, Value>(
  arr: [Key, Value][],
): Record<Key, Value> {
  return Object.fromEntries(arr) as Record<Key, Value>;
}

export function mapObjectValues<Key extends string, Value0, Value1>(
  obj: Record<Key, Value0>,
  mapFn: (k: Key, v: Value0) => Value1,
): Record<Key, Value1> {
  return typedObjectFromEntries(
    typedObjectEntries(obj).map(([key, value]): [Key, Value1] => [
      key,
      mapFn(key, value),
    ]),
  );
}

export function dropAllUndefinedFields<T extends Record<string, any>>(
  obj: T,
): Partial<T> {
  return pickBy(obj, (v) => v !== undefined) as Partial<T>;
}

export function dropAllUndefinedFieldsRecursively<
  T extends Record<string, any>,
>(obj: T): Partial<T> {
  return _dropAllUndefinedFieldsRecursively(obj);
}

function _dropAllUndefinedFieldsRecursively(value: any): any {
  if (typeof value !== "object") return value;
  const _value = pickBy(value, (v) => v !== undefined);
  return mapObjectValues(_value, (k, v) =>
    _dropAllUndefinedFieldsRecursively(v),
  );
}

export function dropAllNullishFields<T extends Record<string, any>>(
  obj: T,
): Partial<T> {
  return pickBy(obj, (v) => hasValue(v)) as Partial<T>;
}
