import { useAppGetDocumentQuery } from "../query/use-app-get-document-query";
import { isNullish } from "../../lib/util/common-util";
import { useMemo } from "react";
import { selectHasRole } from "../../store/auth/slice";
import { useAppSelector } from "../redux-hooks";

export function useCanCreateResultNotification(
  documentId: number | null | undefined,
) {
  const { isOfficeMember } = useAppSelector(selectHasRole);

  const { data: document } = useAppGetDocumentQuery(
    {
      documentId: documentId ?? -1,
    },
    { skip: isNullish(documentId) },
  );
  const documentState = useMemo(
    () => document?.documentState,
    [document?.documentState],
  );

  return useMemo(() => {
    if (isNullish(documentState)) return false;

    // 「結果通知待」or「結果通知待(審査不要)」or「結果通知待(保留・再審査)」or「条件対応待」の場合のみ
    const documentStateIsOk =
      documentState === "awaiting_conclusion" ||
      documentState === "awaiting_conclusion_no_review" ||
      documentState === "awaiting_conclusion_rereview" ||
      documentState === "awaiting_conditions_met";

    // 事務局ユーザーかつ書類状態で判定
    return isOfficeMember && documentStateIsOk;
  }, [documentState, isOfficeMember]);
}
