import { useGetAppUserQuery } from "../../store/api/generated/stock-request-api";
import { isNullish } from "../../lib/util/common-util";
import { useMemo } from "react";
import { appUserMeta, AppUserSaved } from "../../lib/object/entity/app-user";

export function useAppGetAppUserQuery(
  arg: Parameters<typeof useGetAppUserQuery>[0],
  options?: Parameters<typeof useGetAppUserQuery>[1],
  skipOptionalValidations = true, // get系は、skipOptionalValidationsのデフォルトはtrueとする。
): {
  data: AppUserSaved | null;
  isLoading: ReturnType<typeof useGetAppUserQuery>["isLoading"];
  refetch: ReturnType<typeof useGetAppUserQuery>["refetch"];
  error: ReturnType<typeof useGetAppUserQuery>["error"];
} {
  const {
    data: rawData,
    isLoading,
    refetch,
    error,
  } = useGetAppUserQuery(arg, options);
  const data = useMemo(() => {
    if (isNullish(rawData)) return null;
    return appUserMeta.toSavedDomainObjectOrNull(
      rawData,
      skipOptionalValidations,
    );
  }, [rawData, skipOptionalValidations]);
  return { data, isLoading, refetch, error };
}
