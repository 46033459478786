import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";
import { hasValue, isNullish } from "../../../../../../lib/util/common-util";
import { useCallback } from "react";
import { stockRequestApi } from "../../../../../../store/api/enhanced-api";
import { errorMessageOf } from "../../../../../../lib/util/error-util";
import log from "loglevel";
import { VStack } from "@chakra-ui/react";
import {
  CMFormControlHeader,
  CMFormInputSearchAndSelect,
  SearchDropdownDisplayValue,
} from "@pscsrvlab/psc-react-components";
import { AppLink } from "../../../../../ui/link/AppLink/AppLink";
import useCustomToast from "../../../../../../hooks/use-custom-toast";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { useAppGetDocumentQuery } from "../../../../../../hooks/query/use-app-get-document-query";

export type ReviewConferenceReviewTargetSelectProps = {
  editMode: "editable" | "readOnly";

  documentId: number | null;
  reviewId: number | null;

  onChange?: (value: { reviewId: number; documentId: number } | null) => void;
} & ComponentStyleProps;

export const ReviewConferenceReviewTargetSelect = ({
  editMode,

  documentId,
  reviewId,

  onChange,
  sx,
  ...rest
}: ReviewConferenceReviewTargetSelectProps) => {
  const { t } = useAppTranslation();
  const { errorToast } = useCustomToast();

  const { data: selectedDocument } = useAppGetDocumentQuery(
    {
      documentId: documentId ?? -1,
    },
    { skip: isNullish(documentId) },
  );

  const [triggerGetReviewQuery] = stockRequestApi.useLazyGetReviewQuery();
  const [triggerListReviewQuery] = stockRequestApi.useLazyListReviewQuery();
  const [triggerGetDocumentQuery] = stockRequestApi.useLazyGetDocumentQuery();

  /**
   * 表示用ラベルの取得
   */
  const toDisplayValue: (
    reviewId: number,
  ) => Promise<SearchDropdownDisplayValue> = useCallback(
    async (reviewId) => {
      try {
        const review = await triggerGetReviewQuery({
          reviewId,
        }).unwrap();
        if (isNullish(review.documentId))
          return { headingLabel: undefined, mainLabel: "" };
        const document = await triggerGetDocumentQuery({
          documentId: review.documentId,
        }).unwrap();

        return {
          headingLabel: undefined,
          mainLabel: document.documentControlNumber ?? "",
        };
      } catch (e) {
        log.error(errorMessageOf(e));
        log.debug(`toDisplayValue: ERROR`);
        return { headingLabel: undefined, mainLabel: "" };
      }
    },
    [triggerGetDocumentQuery, triggerGetReviewQuery],
  );

  /**
   * 検索イベント
   * @param text 検索用入力文字列
   */
  const handleSearch: (text: string) => Promise<number[]> = useCallback(
    async (text: string) => {
      try {
        // 以下の条件を満たす審査の一覧を取得する。
        // 1. 審査状態が未実施
        // 2. 委員会審査
        // 3. 審査会に紐づいていない
        // 4. 入力した書類管理番号に部分一致する
        return (
          await triggerListReviewQuery({
            reviewState: "unheld",
            reviewType: "committee_review",
            reviewConferenceIdIsNull: true,
            documentControlNumber: text,
          }).unwrap()
        )
          .map((v) => v?.id)
          .filter(hasValue);
      } catch (e) {
        log.error(errorMessageOf(e));
        errorToast(t("mes.汎用エラーメッセージ"));
        return [];
      }
    },
    [errorToast, t, triggerListReviewQuery],
  );

  /**
   * 変更イベント
   */
  const handleChange = useCallback(
    async (reviewId: number | null) => {
      if (isNullish(reviewId)) {
        onChange?.(null);
        return;
      }
      try {
        const review = await triggerGetReviewQuery({
          reviewId,
        }).unwrap();
        if (isNullish(review.documentId)) {
          onChange?.(null);
          return;
        }
        onChange?.({ reviewId, documentId: review.documentId });
      } catch (e) {
        log.error(errorMessageOf(e));
        onChange?.(null);
      }
    },
    [onChange, triggerGetReviewQuery],
  );

  return (
    <VStack sx={sx} {...rest} alignItems={"flex-start"}>
      {editMode === "readOnly" && hasValue(selectedDocument) ? (
        <VStack alignItems={"flex-start"}>
          <CMFormControlHeader label={t("lbl.書類管理番号")} />
          <AppLink
            isExternal
            to={`/document/${selectedDocument.id}/content`}
            pl={"10px"}
            mt={"10px"}
          >
            {selectedDocument.documentControlNumber}
          </AppLink>
        </VStack>
      ) : editMode === "editable" ? (
        <CMFormInputSearchAndSelect<number>
          label={t("lbl.書類管理番号")}
          value={reviewId}
          labelSubmit={t("btn.確定ボタン")}
          labelClear={t("btn.クリアボタン")}
          placeholder={t("lbl.選択プルダウンプレースホルダー")}
          placeholderPopover={t("lbl.選択プルダウンプレースホルダー")}
          popoverTitle={t("mes.書類選択案内メッセージ")}
          searchResultNone={t("mes.該当書類なしメッセージ")}
          toDisplayValue={toDisplayValue}
          menuShowMaxCount={30}
          onSearch={handleSearch}
          onChange={handleChange}
          usePortal={false}
          minW={"200px"}
          w={"max-content"}
          maxW={"300px"}
        />
      ) : null}
    </VStack>
  );
};
