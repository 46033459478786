import { ComponentStyleProps } from "../../../../../../../../../lib/styles/props/component-style-props";
import { CMFormInputRadio, Comments } from "@pscsrvlab/psc-react-components";
import React, { memo, useCallback, useMemo } from "react";
import { ipsCellStockUsePurposeTypeMeta } from "../../../../../../../../../lib/object/value/ips-cell-stock-use-purpose-type";
import { ipsCellStockUsePurposeSubTypeMeta } from "../../../../../../../../../lib/object/value/ips-cell-stock-use-purpose-sub-type";

import { Box, Text } from "@chakra-ui/react";
import {
  hasValue,
  isNullish,
} from "../../../../../../../../../lib/util/common-util";
import { IpsCellStockUsePurpose } from "../../../../../../../../../lib/object/value/ips-cell-stock-use-purpose";
import { CMButtonFormCommentProps } from "@pscsrvlab/psc-react-components/src/components/form/comment";
import { useChangeBadgeProperties } from "../../../../../../../../../hooks/document/change-application/use-change-badge-properties";
import { ChangeReasonForm } from "../../form/ChangeReasonForm/ChangeReasonForm";
import { useSectionDiffMode } from "../../../../../../../../../hooks/document/change-application/use-section-diff-mode";
import { FormSection } from "../../../../../../../../ui/form/FormSection/FormSection";
import { useAppTranslation } from "../../../../../../../../../hooks/use-app-translation";
import { ChangeReasonItemViewModel } from "../../../../../../../../../lib/object/vm/change-application-view-model";
import { useSelector } from "react-redux";
import { selectHasRole } from "../../../../../../../../../store/auth/slice";

export type IpsCellStockUsePurposeSectionProps = {
  documentType:
    | "new_application"
    | "change_application"
    | "project_force_update"
    | "project_force_create"
    | "project_content";
  editMode: "editable" | "readOnly";

  value: IpsCellStockUsePurpose;
  onChange?: (value: IpsCellStockUsePurpose) => void;

  changedFrom?: IpsCellStockUsePurpose;
  changeReason?: string;
  onChangeReason?: (
    change: (before: ChangeReasonItemViewModel) => ChangeReasonItemViewModel,
  ) => void;
  displayChangesOnly?: boolean;

  /**
   * コメントボタンのprops。
   * これが存在すれば、コメントボタンを表示する。
   */
  commentButtonProps?: CMButtonFormCommentProps;
  /**
   * 1セクション単位の修正依頼コメント・返信。履歴参照画面で使用。
   */
  commentsList?: Comments[];
} & ComponentStyleProps;

/**
 * iPS細胞の使用目的セクション
 */
export const IpsCellStockUsePurposeSection = memo(
  function IpsCellStockUsePurposeSection({
    documentType,
    editMode,

    value,
    onChange,

    changedFrom,
    changeReason,
    onChangeReason,
    displayChangesOnly,

    commentButtonProps,
    commentsList,

    sx,
    ...rest
  }: IpsCellStockUsePurposeSectionProps) {
    const { t } = useAppTranslation();
    const { isApplicant } = useSelector(selectHasRole);

    const handleChangePurposeType = useCallback(
      (selectedValue: "clinical" | "research") => {
        onChange?.({
          purposeType: selectedValue,
          purposeSubType: selectedValue === "research" ? undefined : "cellbank",
        });
      },
      [onChange],
    );

    const handleChangePurposeSubType = useCallback(
      (selectedValue: "clinical" | "cellbank" | undefined) => {
        onChange?.({
          purposeType: "clinical",
          purposeSubType: selectedValue,
        });
      },
      [onChange],
    );

    /**
     * 申請者が変更申請を編集(作成・修正)する場合、ラジオボタンを非活性にする
     */
    const radioEditMode = useMemo(() => {
      if (isApplicant && documentType === "change_application") {
        return "disabled";
      } else {
        return editMode;
      }
    }, [documentType, isApplicant, editMode]);

    const readOnlyPurpose: string = useMemo(() => {
      if (editMode !== "readOnly" || isNullish(value)) return "";

      switch (value.purposeType) {
        case "research":
          return t("code.iPS細胞の使用目的区分.ヒトへの投与なし（基礎研究等）");
        case "clinical":
          switch (value.purposeSubType) {
            case "cellbank":
              return (
                t("code.iPS細胞の使用目的区分.ヒトへの投与予定あり") +
                " (" +
                t("lbl.セルバンクの作製") +
                ")"
              );
            case "clinical":
              return (
                t("code.iPS細胞の使用目的区分.ヒトへの投与予定あり") +
                "(" +
                t("lbl.臨床研究・治験") +
                ")"
              );
            default:
              return "";
          }
      }
    }, [value, editMode, t]);

    const sectionDiffMode = useSectionDiffMode(
      documentType,
      changedFrom,
      value,
      onChangeReason,
    );

    const changeBadgePropertiesPurposeType = useChangeBadgeProperties(
      documentType,
      changedFrom?.purposeType,
    );
    const changeBadgePropertiesPurposeSubType = useChangeBadgeProperties(
      documentType,
      changedFrom?.purposeSubType,
    );

    return (
      <FormSection
        title={t("lbl.iPS細胞の使用目的")}
        commentButtonProps={commentButtonProps}
        commentsList={commentsList}
        displayMode={sectionDiffMode}
        displayChangesOnly={displayChangesOnly}
        sx={sx}
        {...rest}
      >
        {editMode === "readOnly" ? (
          <Text>{readOnlyPurpose}</Text>
        ) : (
          <Box>
            <CMFormInputRadio
              editMode={radioEditMode}
              value={value?.purposeType}
              valueObjectMeta={ipsCellStockUsePurposeTypeMeta}
              noHeader={!hasValue(changedFrom?.purposeType)}
              size={"sm"}
              onChange={handleChangePurposeType}
              changeBadgeProperties={changeBadgePropertiesPurposeType}
              sx={{ w: "100%" }}
            />
            {value?.purposeType === "clinical" && (
              <Box sx={{ w: "100%" }}>
                <CMFormInputRadio
                  editMode={radioEditMode}
                  value={value?.purposeSubType}
                  valueObjectMeta={ipsCellStockUsePurposeSubTypeMeta}
                  noHeader={!hasValue(changedFrom?.purposeSubType)}
                  size={"sm"}
                  onChange={handleChangePurposeSubType}
                  changeBadgeProperties={changeBadgePropertiesPurposeSubType}
                  ml={"20px"}
                  mt={"15px"}
                />
              </Box>
            )}
          </Box>
        )}
        {sectionDiffMode === "updated" && (
          <ChangeReasonForm
            value={changeReason}
            onChange={onChangeReason}
            editMode={editMode}
          />
        )}
      </FormSection>
    );
  },
);
