import { Inquiry } from "../entity/inquiry";
import { ErrorMessageObject, messageId } from "@pscsrvlab/psc-react-components";

export function inquiryCustomValidation(
  value: Inquiry,
  skipOptional: boolean,
): ErrorMessageObject[] {
  const errorMessageList: ErrorMessageObject[] = [];
  if (value.replies.length > 500) {
    const mesId = messageId("mes.質疑応答返信最大メッセージ数超過エラー");
    errorMessageList.push({ errorMessageId: mesId });
  }
  return errorMessageList;
}
