import {
  messageId,
  PropertyMeta,
  ValueObjectMetaComposite,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { executiveDirectorApprovalStateMeta } from "../value/executive-director-approval-state";
import { appUserIdMeta } from "../value/app-user-id";
import { fullNameMeta } from "../value/full-name";
import { yearMonthDayMeta } from "../value/year-month-day";
/**
 * 業務執行理事承認(値オブジェクト)
 *
 *  */
export type ExecutiveDirectorApproval = z.infer<typeof schema>;
export type ExecutiveDirectorApprovalSaved = z.infer<typeof savedSchema>;
const minimalSchema = z.object({
  executiveDirectorApprovalState:
    executiveDirectorApprovalStateMeta.minimalSchema,
  appUserId: appUserIdMeta.minimalSchema,
  fullName: fullNameMeta.minimalSchema,
  approvalDate: yearMonthDayMeta.minimalSchema,
});
const savedMinimalSchema = z.object({
  executiveDirectorApprovalState:
    executiveDirectorApprovalStateMeta.savedMinimalSchema,
  appUserId: appUserIdMeta.savedMinimalSchema,
  fullName: fullNameMeta.savedMinimalSchema,
  approvalDate: yearMonthDayMeta.savedMinimalSchema,
});
const schema = z.object({
  executiveDirectorApprovalState: executiveDirectorApprovalStateMeta.schema,
  appUserId: appUserIdMeta.schema,
  fullName: fullNameMeta.schema,
  approvalDate: yearMonthDayMeta.schema,
});
const savedSchema = z.object({
  executiveDirectorApprovalState:
    executiveDirectorApprovalStateMeta.savedSchema,
  appUserId: appUserIdMeta.savedSchema,
  fullName: fullNameMeta.savedSchema,
  approvalDate: yearMonthDayMeta.savedSchema,
});
const jsonSchema = z.object({
  executiveDirectorApprovalState: executiveDirectorApprovalStateMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  appUserId: appUserIdMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  fullName: fullNameMeta.jsonSchema.nullish().transform((x) => x ?? undefined),
  approvalDate: yearMonthDayMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
/**
 * 業務執行理事承認(値オブジェクト)のメタデータ。
 *
 *  */
export const executiveDirectorApprovalMeta = new ValueObjectMetaComposite(
  "ExecutiveDirectorApproval",
  "\u696D\u52D9\u57F7\u884C\u7406\u4E8B\u627F\u8A8D",
  messageId("lbl.\u696D\u52D9\u57F7\u884C\u7406\u4E8B\u627F\u8A8D"),
  minimalSchema,
  savedMinimalSchema,
  schema,
  savedSchema,
  jsonSchema,
  undefined,
  undefined,
  {
    executiveDirectorApprovalState: new PropertyMeta(
      "executiveDirectorApprovalState",
      "\u696D\u52D9\u57F7\u884C\u7406\u4E8B\u627F\u8A8D\u72B6\u614B",
      messageId(
        "lbl.\u696D\u52D9\u57F7\u884C\u7406\u4E8B\u627F\u8A8D\u72B6\u614B"
      ),
      "always",
      false,
      executiveDirectorApprovalStateMeta
    ),
    appUserId: new PropertyMeta(
      "appUserId",
      "\u30E6\u30FC\u30B6\u30FCID",
      messageId("lbl.\u30E6\u30FC\u30B6\u30FCID"),
      "always",
      false,
      appUserIdMeta
    ),
    fullName: new PropertyMeta(
      "fullName",
      "\u6C0F\u540D",
      messageId("lbl.\u6C0F\u540D"),
      "always",
      false,
      fullNameMeta
    ),
    approvalDate: new PropertyMeta(
      "approvalDate",
      "\u627F\u8A8D\u65E5",
      messageId("lbl.\u627F\u8A8D\u65E5"),
      "always",
      false,
      yearMonthDayMeta
    ),
  }
);
