import React, { memo, useMemo } from "react";
import { CMButton } from "@pscsrvlab/psc-react-components";
import { MdModeEdit } from "react-icons/md";
import { ComponentStyleProps } from "../../../../lib/styles/props/component-style-props";
import { hasValue } from "../../../../lib/util/common-util";
import { useAppTranslation } from "../../../../hooks/use-app-translation";

export type EditButtonProps = {
  label?: string;
  onClick?: () => void;
} & ComponentStyleProps;
export const EditButton = memo(function EditButton({
  label,
  onClick,

  sx,
  ...rest
}: EditButtonProps) {
  const { t } = useAppTranslation();

  const capitalizedLabel = useMemo(() => {
    return hasValue(label) ? label : t("btn.編集ボタン");
  }, [label, t]);

  return (
    <CMButton
      size={"sm"}
      label={capitalizedLabel}
      leftIcon={<MdModeEdit />}
      onClick={onClick}
      sx={sx}
      {...rest}
    />
  );
});
