import {
  CMFormInputRadio,
  Comments,
  DeleteConfirmationModal,
} from "@pscsrvlab/psc-react-components";
import { useDisclosure } from "@chakra-ui/react";
import React, { memo, useCallback } from "react";
import { CMButtonFormCommentProps } from "@pscsrvlab/psc-react-components/src/components/form/comment";
import {
  DifferentiatedCellProvisionType,
  differentiatedCellProvisionTypeMeta,
} from "../../../../../../lib/object/value/differentiated-cell-provision-type";
import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { FormSection } from "../../../../../ui/form/FormSection/FormSection";

export type CellProvisionTypeReportSectionProps = {
  editMode: "editable" | "readOnly";

  value: DifferentiatedCellProvisionType;

  onChange: (value: DifferentiatedCellProvisionType) => void;

  /**
   * コメントボタンのprops。
   * これが存在すれば、コメントボタンを表示する。
   */
  commentButtonProps?: CMButtonFormCommentProps;
  /**
   * 1セクション単位の修正依頼コメント・返信。履歴参照画面で使用。
   */
  commentsList?: Comments[];
} & ComponentStyleProps;

/**
 * 分化細胞等の提供先の有無(分担機関以外)セクション
 */
export const CellProvisionTypeReportSection = memo(
  function CellProvisionTypeReportSection({
    editMode,
    value,
    onChange,
    commentButtonProps,
    commentsList,

    sx,
    ...rest
  }: CellProvisionTypeReportSectionProps) {
    const { t } = useAppTranslation();

    // モーダル制御
    const {
      isOpen: isOpenDeleteModal,
      onOpen: onOpenDeleteModal,
      onClose: onCloseDeleteModal,
    } = useDisclosure();

    const handleChange = useCallback(
      (v: DifferentiatedCellProvisionType) => {
        switch (v) {
          case "provided":
            onChange?.("provided");
            break;
          case "none":
            onOpenDeleteModal();
            break;
        }
      },
      [onChange, onOpenDeleteModal],
    );

    const handleConfirmDelete = useCallback(() => {
      onChange?.("none");
      onCloseDeleteModal();
    }, [onChange, onCloseDeleteModal]);

    return (
      <>
        <FormSection
          title={t("lbl.分化細胞等の提供先の有無(分担機関以外)")}
          commentButtonProps={commentButtonProps}
          commentsList={commentsList}
          sx={sx}
          {...rest}
        >
          <CMFormInputRadio<["provided", "none"]>
            direction={"row"}
            noHeader={true}
            editMode={editMode}
            value={value}
            valueObjectMeta={differentiatedCellProvisionTypeMeta}
            onChange={handleChange}
          />
        </FormSection>

        <DeleteConfirmationModal
          isOpen={isOpenDeleteModal}
          title={t("lbl.確認ポップアップタイトル")}
          message={t("mes.分化細胞の提供先リセット確認メッセージ")}
          deleteButtonLabel={t("btn.削除ボタン")}
          cancelButtonLabel={t("btn.キャンセルボタン")}
          onConfirm={handleConfirmDelete}
          onCancel={onCloseDeleteModal}
          onClose={onCloseDeleteModal}
        />
      </>
    );
  },
);
