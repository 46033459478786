import { ComponentStyleProps } from "../../../../../lib/styles/props/component-style-props";
import { ResultNotificationViewModel } from "../../../../../lib/object/vm/result-notification-view-model";
import { Container, Flex, Text, useDisclosure, VStack } from "@chakra-ui/react";
import React, { useCallback, useMemo, useState } from "react";
import { useAppTranslation } from "../../../../../hooks/use-app-translation";
import { ResultNotificationBody } from "./ResultNotificationBody";
import { CommonNextButton } from "../../../../ui/button/CommonNextButton/CommonNextButton";
import { useCreateResultNotificationMutation } from "../../../../../store/api/generated/stock-request-api";
import { ConfirmationModal } from "../../../../ui/modal/ConfirmationModal/ConfirmationModal";
import { resultNotificationMeta } from "../../../../../lib/object/entity/result-notification";
import useCustomToast from "../../../../../hooks/use-custom-toast";
import { hasValue, isNullish } from "../../../../../lib/util/common-util";
import { errorMessageOf } from "../../../../../lib/util/error-util";
import log from "loglevel";
import { useLeaveEditingPrompt } from "../../../../../hooks/use-leave-editing-prompt";
import { useProjectPagePathPrefix } from "../../../../../hooks/use-project-page-path-prefix";
import { reviewTypeMeta } from "../../../../../lib/object/value/review-type";

export type ResultNotificationPageCreateProps = {
  initialVM: ResultNotificationViewModel;
} & ComponentStyleProps;

export const ResultNotificationPageCreate = ({
  initialVM,

  sx,
  ...rest
}: ResultNotificationPageCreateProps) => {
  const { t } = useAppTranslation();
  const { errorToast, validationErrorToast } = useCustomToast();
  const { navigateWithoutPrompt } = useLeaveEditingPrompt();
  const { projectPagePathPrefix } = useProjectPagePathPrefix();

  const [vm, setVm] = useState(initialVM.contentVM);
  const handleChange = useCallback(
    (
      change: (
        before: ResultNotificationViewModel["contentVM"],
      ) => ResultNotificationViewModel["contentVM"],
    ) => {
      setVm(change);
    },
    [],
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleSubmit = useCallback(() => {
    const validationResult = resultNotificationMeta.validate(vm);
    if (validationResult.state === "error") {
      validationErrorToast(validationResult.errors);
      return;
    }
    if (
      vm.resultNotificationType === "application_condition_confirmation" &&
      isNullish(vm.approvalConditionConfirmationDate)
    ) {
      errorToast(
        t("mes.必須チェックエラー2", { name: t("lbl.条件対応確認日") }),
      );
      return;
    }
    onOpen();
  }, [errorToast, onOpen, t, validationErrorToast, vm]);

  const [createResultNotification] = useCreateResultNotificationMutation();
  const handleConfirmSubmit = useCallback(async () => {
    const jsonObject = resultNotificationMeta.toJsonObjectOrNull(vm);
    if (isNullish(jsonObject)) {
      errorToast(t("mes.汎用エラーメッセージ"));
      return;
    }
    try {
      const result = await createResultNotification({
        resultNotification: jsonObject,
      }).unwrap();
      await navigateWithoutPrompt(
        `${projectPagePathPrefix}/document/${initialVM.contentVM.documentId}/result-notification/${result.id}/complete`,
      );
    } catch (e) {
      log.error(errorMessageOf(e));
      errorToast(t("mes.汎用エラーメッセージ"));
    }
  }, [
    createResultNotification,
    errorToast,
    initialVM.contentVM.documentId,
    navigateWithoutPrompt,
    projectPagePathPrefix,
    t,
    vm,
  ]);

  const fromReviewMessage = useMemo(() => {
    if (isNullish(initialVM.baseReview)) return undefined;
    const reviewTypeText = t(
      reviewTypeMeta.dict[initialVM.baseReview.reviewType],
    );
    return t("gdc.結果通知決定案内(審査結果より)", {
      審査タイトル: `${reviewTypeText}${initialVM.baseReview.reviewNumber}`,
    });
  }, [t, initialVM.baseReview]);

  return (
    <>
      <Flex
        direction={"column"}
        alignItems={"center"}
        flex={"1 1 auto"}
        minW={0}
        overflow={"auto"}
        pt={"50px"}
        pb={"80px"}
        sx={sx}
        {...rest}
      >
        <Container maxW={"720px"} mb={"8px"}>
          <VStack alignItems={"flex-start"} pl={"4px"}>
            {initialVM.contentVM.resultNotificationType ===
              "application_approve_or_conditional" &&
              hasValue(fromReviewMessage) && <Text>{fromReviewMessage}</Text>}
            {initialVM.contentVM.resultNotificationType ===
              "application_condition_confirmation" && (
              <Text>{t("gdc.条件対応完了確認案内")}</Text>
            )}
          </VStack>
        </Container>
        <ResultNotificationBody
          editMode={"editable"}
          vm={vm}
          onChange={handleChange}
        />
        <CommonNextButton
          mt={"30px"}
          label={t("btn.結果を確定するボタン")}
          onClick={handleSubmit}
        />
      </Flex>
      <ConfirmationModal
        isOpen={isOpen}
        message={t("mes.結果確定確認メッセージ")}
        onSubmit={handleConfirmSubmit}
        onCancel={onClose}
      />
    </>
  );
};
