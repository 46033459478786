import { Box, Flex, HStack, VStack } from "@chakra-ui/react";
import { PageNumberAndSearchResult } from "../PageNumberAndSearchResult/PageNumberAndSearchResult";
import { hasValue } from "../../../../lib/util/common-util";
import { Pagination } from "../Pagination/Pagination";
import React, { ReactNode, useMemo } from "react";

export type SearchResultAreaProps = {
  isLoading: boolean;
  currentPageNumber: number;
  totalPageCount: number;
  totalElements: number;

  onPageChange: (value: number) => void;

  upperLeftElement?: ReactNode;
  upperRightElement?: ReactNode;

  upperElementHeight?: number;

  children: ReactNode;
};
export const SearchResultArea = ({
  isLoading,
  currentPageNumber,
  totalPageCount,
  totalElements,

  onPageChange,

  upperLeftElement,
  upperRightElement,

  upperElementHeight = 50,

  children,
}: SearchResultAreaProps) => {
  const top = useMemo(() => {
    return `${-10 - upperElementHeight}px`;
  }, [upperElementHeight]);
  const upperElementFlex = useMemo(
    () => `0 0 ${upperElementHeight}px`,
    [upperElementHeight],
  );

  return (
    <VStack alignItems={"flex-start"} flex={1} overflow={"auto"} spacing={0}>
      {/*ヘッダ*/}
      <VStack
        alignSelf={"stretch"}
        alignItems={"stretch"}
        pt={"20px"}
        top={top}
        left={"0px"}
        position={"sticky"}
        spacing={0}
        bgColor={"white"}
        zIndex={10}
      >
        <Flex
          direction={"row"}
          flex={upperElementFlex}
          justifyContent={"flex-start"}
        >
          {hasValue(upperLeftElement) && (
            <VStack pl={"20px"}>{upperLeftElement}</VStack>
          )}
          {hasValue(upperRightElement) && (
            <VStack ml={"auto"} pr={"20px"}>
              {upperRightElement}
            </VStack>
          )}
        </Flex>

        {/*検索結果数とページ番号*/}
        {!isLoading && (
          <VStack alignItems={"flex-start"} pl={"40px"}>
            <PageNumberAndSearchResult
              currentPageNumber={currentPageNumber}
              totalElements={totalElements}
            />
          </VStack>
        )}
      </VStack>

      <HStack
        key={currentPageNumber}
        alignItems={"flex-start"}
        pr={"60px"}
        pb={"20px"}
        spacing={0}
      >
        {/*目隠しエリア*/}
        <Box
          alignSelf={"stretch"}
          flex={"0 0 30px"}
          left={"0px"}
          position={"sticky"}
          bgColor={"white"}
          zIndex={3}
        />

        {/*検索結果テーブル*/}
        {!isLoading && children}
      </HStack>

      {/*フッタ*/}
      <VStack
        alignSelf={"stretch"}
        left={"0px"}
        position={"sticky"}
        pb={"80px"}
      >
        {/*ページネーション*/}
        {hasValue(totalPageCount) && totalPageCount > 0 && (
          <Pagination
            totalPageCount={totalPageCount}
            currentPageNumber={currentPageNumber}
            onPageChange={onPageChange}
            sx={{
              display: "grid",
              justifyItems: "center",
            }}
          />
        )}
      </VStack>
    </VStack>
  );
};
