import React, { memo, useCallback } from "react";
import {
  CMFormInputDate,
  CMFormInputRadio,
} from "@pscsrvlab/psc-react-components";
import {
  ReceiptState,
  receiptStateMeta,
} from "../../../../../../lib/object/value/receipt-state";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { ReceiptInformation } from "../../../../../../lib/object/value/receipt-information";
import { YearMonthDay } from "../../../../../../lib/object/value/year-month-day";
import { FormSection } from "../../../../../ui/form/FormSection/FormSection";

export type SectionReceiptInformationProps = {
  /**
   * 画面編集モード
   */
  editMode: "editable" | "readOnly";

  value: ReceiptInformation;

  /**
   * ユーザーの権限情報
   * 事務局ユーザーの場合,true
   */
  isOfficeMember: boolean;

  /**
   * 変更イベント
   */
  onChange: (
    change: (before: ReceiptInformation) => ReceiptInformation,
  ) => void;
};

/**
 * FN30-S09細胞発送管理
 * 受領状況セクション
 */
export const SectionReceiptInformation = memo(
  function SectionReceiptInformation({
    editMode,
    value,
    isOfficeMember,
    onChange,
  }: SectionReceiptInformationProps) {
    const { t } = useAppTranslation();

    const handleChangeStatus = useCallback(
      (selectedValue: ReceiptState) => {
        onChange?.((before) => ({
          ...before,
          receivedStatus: selectedValue,
        }));
      },
      [onChange],
    );

    const handleChangeDate = useCallback(
      (selectedValue: YearMonthDay) => {
        onChange?.((before) => ({
          ...before,
          receiptDate: selectedValue,
        }));
      },
      [onChange],
    );

    return (
      <FormSection
        title={t("lbl.受領状況")}
        description={isOfficeMember ? t("gdc.受領状況補足") : undefined}
      >
        <CMFormInputRadio
          editMode={editMode}
          label={t("lbl.状況")}
          value={value.receivedStatus}
          valueObjectMeta={receiptStateMeta}
          onChange={handleChangeStatus}
          sx={{
            ".chakra-radio-group": { ml: "8px" },
            // 2個目以降のmarginを調整
            ".chakra-stack div:nth-of-type(n+2)": { mt: "5px" },
          }}
        />
        <CMFormInputDate
          editMode={editMode}
          label={t("lbl.受領日")}
          value={value.receiptDate}
          onChange={handleChangeDate}
          sx={{
            ".chakra-input__right-element": { zIndex: "1" },
          }}
        />
      </FormSection>
    );
  },
);
