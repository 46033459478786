import { ComponentStyleProps } from "../../../../lib/styles/props/component-style-props";
import { Button, HStack, Text } from "@chakra-ui/react";
import { Icon } from "@chakra-ui/icons";
import { BiPlus } from "react-icons/bi";

export type SideMenuCreateButtonProps = {
  label: string;
  onClick?: () => void;
} & ComponentStyleProps;

export const SideMenuCreateButton = ({
  label,
  onClick,

  sx,
  ...rest
}: SideMenuCreateButtonProps) => {
  const capitalizedLabel = label;
  return (
    <Button
      className={"SideMenuCreateButton"}
      fontSize={"sm"}
      fontWeight={"normal"}
      color={"gray.700"}
      onClick={onClick}
      sx={sx}
      {...rest}
    >
      <HStack w={"100%"} justifyContent={"flex-start"}>
        <Icon as={BiPlus} />
        <Text>{capitalizedLabel}</Text>
      </HStack>
    </Button>
  );
};
