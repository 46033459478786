import React, { memo, useCallback } from "react";
import {
  CMFormInputDate,
  CMFormInputRadio,
} from "@pscsrvlab/psc-react-components";
import {
  ShippingState,
  shippingStateMeta,
} from "../../../../../../lib/object/value/shipping-state";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { ShippingInformation } from "../../../../../../lib/object/value/shipping-information";
import { YearMonthDay } from "../../../../../../lib/object/value/year-month-day";
import { FormSection } from "../../../../../ui/form/FormSection/FormSection";

export type SectionShippingInformationProps = {
  /**
   * 画面編集モード
   */
  editMode?: "editable" | "readOnly";

  value: ShippingInformation;

  /**
   * ユーザーの権限情報
   * 事務局ユーザーの場合,true
   */
  isOfficeMember: boolean;

  /**
   * 変更イベント
   */
  onChange: (
    change: (before: ShippingInformation) => ShippingInformation,
  ) => void;
};

/**
 * FN30-S09細胞発送管理
 * 発送状況セクション
 */
export const SectionShippingInformation = memo(
  function SectionShippingInformation({
    editMode = "readOnly",
    value,
    isOfficeMember,
    onChange,
  }: SectionShippingInformationProps) {
    const { t } = useAppTranslation();

    const handleChangeStatus = useCallback(
      (v: ShippingState) => {
        onChange?.((before) => ({
          ...before,
          shippingStatus: v,
        }));
      },
      [onChange],
    );

    const handleChangeDate = useCallback(
      (v: YearMonthDay) => {
        onChange?.((before) => ({
          ...before,
          shippingDate: v,
        }));
      },
      [onChange],
    );

    return (
      <FormSection
        title={t("lbl.発送状況")}
        description={isOfficeMember ? t("gdc.発送状況補足") : undefined}
      >
        <CMFormInputRadio
          editMode={isOfficeMember ? editMode : "readOnly"}
          label={t("lbl.状況")}
          value={value?.shippingStatus}
          valueObjectMeta={shippingStateMeta}
          onChange={handleChangeStatus}
          sx={{
            ".chakra-radio-group": { ml: "8px" },
            // 2個目以降のmarginを調整
            ".chakra-stack div:nth-of-type(n+2)": { mt: "5px" },
          }}
        />
        <CMFormInputDate
          editMode={isOfficeMember ? editMode : "readOnly"}
          label={t("lbl.発送日")}
          value={value?.shippingDate}
          onChange={handleChangeDate}
          sx={{
            ".chakra-input__right-element": { zIndex: "1" },
          }}
        />
      </FormSection>
    );
  },
);
