import { ComponentStyleProps } from "../../../../../../../../../lib/styles/props/component-style-props";
import {
  CMFormInputDate,
  CMFormInputText,
  Comments,
} from "@pscsrvlab/psc-react-components";
import { HStack, Text } from "@chakra-ui/react";
import { yearMonthMeta } from "../../../../../../../../../lib/object/value/year-month";
import React, { memo } from "react";
import { CMButtonFormCommentProps } from "@pscsrvlab/psc-react-components/src/components/form/comment";
import { useSectionDiffMode } from "../../../../../../../../../hooks/document/change-application/use-section-diff-mode";
import { useChangeBadgeProperties } from "../../../../../../../../../hooks/document/change-application/use-change-badge-properties";
import { ChangeReasonForm } from "../../form/ChangeReasonForm/ChangeReasonForm";
import { YearMonthDay } from "../../../../../../../../../lib/object/value/year-month-day";
import { FormSection } from "../../../../../../../../ui/form/FormSection/FormSection";
import { useAppTranslation } from "../../../../../../../../../hooks/use-app-translation";
import { ChangeReasonItemViewModel } from "../../../../../../../../../lib/object/vm/change-application-view-model";

export type UsageEndDateSectionProps = {
  documentType:
  | "new_application"
  | "change_application"
  | "project_force_update"
  | "project_force_create"
  | "project_content";
  editMode: "editable" | "readOnly";

  value?: YearMonthDay;
  onChange?: (value: YearMonthDay) => void;

  changedFrom?: YearMonthDay;
  changeReason?: string;
  onChangeReason?: (
    change: (before: ChangeReasonItemViewModel) => ChangeReasonItemViewModel,
  ) => void;
  displayChangesOnly?: boolean;

  /**
   * コメントボタンのprops。
   * これが存在すれば、コメントボタンを表示する。
   */
  commentButtonProps?: CMButtonFormCommentProps;
  /**
   * 1セクション単位の修正依頼コメント・返信。履歴参照画面で使用。
   */
  commentsList?: Comments[];
} & ComponentStyleProps;

/**
 * 使用期間セクション
 */
export const UsageEndDateSection = memo(function UsageEndDateSection({
  documentType,
  editMode,

  value,
  onChange,

  changedFrom,
  changeReason,
  onChangeReason,
  displayChangesOnly,

  commentButtonProps,
  commentsList,

  sx,
  ...rest
}: UsageEndDateSectionProps) {
  const { t } = useAppTranslation();

  const sectionDiffMode = useSectionDiffMode(
    documentType,
    changedFrom,
    value,
    onChangeReason,
  );

  const changeBadgeProperties = useChangeBadgeProperties(
    documentType,
    changedFrom,
  );

  return (
    <FormSection
      title={t("lbl.使用期間")}
      description={editMode === "editable" ? t("gdc.使用期間補足") : undefined}
      displayMode={sectionDiffMode}
      displayChangesOnly={displayChangesOnly}
      commentButtonProps={commentButtonProps}
      commentsList={commentsList}
      sx={sx}
      {...rest}
    >
      <HStack spacing={"20px"}>
        <CMFormInputText
          editMode={"readOnly"}
          value={t("lbl.本申請の承認日")}
          label={t("lbl.使用開始日")}
          valueObjectMeta={yearMonthMeta}
          w={"max-content"}
        />
        <Text pt={"20px"} fontSize={"lg"}>
          {t("lbl.範囲シンボル")}
        </Text>
        <CMFormInputDate
          editMode={editMode}
          value={value}
          label={t("lbl.使用終了日")}
          onChange={onChange}
          changeBadgeProperties={changeBadgeProperties}
          size={"md"}
          w={"200px"}
        />
      </HStack>
      {sectionDiffMode === "updated" && (
        <ChangeReasonForm
          value={changeReason}
          onChange={onChangeReason}
          editMode={editMode}
        />
      )}
    </FormSection>
  );
});
