import { Box, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import {
  useCreateCellStockDeliveryMutation,
  useGetProjectQuery,
  useListCellStockDeliveryQuery,
  useListDocumentQuery,
} from "../../../../store/api/generated/stock-request-api";
import {
  getMessageFromEnumValue,
  hasValue,
  isNullish,
} from "../../../../lib/util/common-util";
import React, { useCallback, useMemo } from "react";
import { projectMeta } from "../../../../lib/object/entity/project";
import { CMModalOptionsCancel } from "@pscsrvlab/psc-react-components";
import { errorMessageOf } from "../../../../lib/util/error-util";
import useCustomToast from "../../../../hooks/use-custom-toast";
import { ProjectMenuItem } from "./_components/ProjectMenu/ProjectMenuItem";
import { cellStockDeliveryMeta } from "../../../../lib/object/entity/cell-stock-delivery";
import { BsPlus } from "react-icons/bs";
import { documentMeta } from "../../../../lib/object/entity/document";
import { ymdToDateText } from "../../../../lib/util/common-date-util";
import { FN30S05CreateDocument } from "../FN30S05CreateDocument/FN30S05CreateDocument";
import { documentTypeMeta } from "../../../../lib/object/value/document-type";
import { useAppParams } from "../../../../hooks/use-app-params";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import { selectUserInfo } from "../../../../store/auth/slice";
import log from "loglevel";
import { useAppTranslation } from "../../../../hooks/use-app-translation";

/**
 * FN30-S03案件メニュー
 */
export const FN30S03ProjectMenu = () => {
  const { t } = useAppTranslation();
  const { errorToast } = useCustomToast();
  const navigate = useNavigate();
  const currentUserInfo = useAppSelector(selectUserInfo);

  const myRole = useMemo(() => currentUserInfo?.role, [currentUserInfo]);

  // 新規書類作成モーダル用
  const createDocumentDisclosure = useDisclosure();
  // 新規細胞発送作成モーダル用
  const createCellDeliveryDisclosure = useDisclosure();
  // 書類メニュー開閉用
  const documentMenuDisclosure = useDisclosure();
  // 細胞発送メニュー開閉用
  const cellMenuDisclosure = useDisclosure();

  // URLパラメーターからprojectIdを取得
  const { projectId } = useAppParams();

  // 細胞発送を追加するためのミューテーション
  const [createCellStockDelivery] = useCreateCellStockDeliveryMutation();

  // 案件情報を取得
  const { data } = useGetProjectQuery(
    { projectId: projectId ?? -1 },
    { skip: isNullish(projectId) },
  );

  // 取得した案件データをオブジェクトに変換
  const projectData = useMemo(() => {
    return projectMeta.toSavedDomainObjectOrNull(data, true);
  }, [data]);

  // 案件idに紐づいた書類一覧を取得
  const { data: documentList } = useListDocumentQuery(
    { projectId: projectId ?? -1 },
    { skip: isNullish(projectId) },
  );

  // 取得した書類データをオブジェクトに変換
  const documentData = useMemo(() => {
    return documentList?.map((document) => {
      return documentMeta.toSavedDomainObjectOrNull(document, true);
    });
  }, [documentList]);

  // 案件idに紐づいた細胞発送一覧を取得
  const { data: cellData } = useListCellStockDeliveryQuery(
    { projectId: projectId ?? -1 },
    { skip: isNullish(projectId) },
  );

  // 取得した細胞発送データをオブジェクトに変換
  const cellStockDeliveryData = useMemo(() => {
    return cellData?.map((cell) => {
      return cellStockDeliveryMeta.toSavedDomainObjectOrNull(cell);
    });
  }, [cellData]);

  // メニューをクリックした時のイベント
  const handleClickMenu = useCallback(
    (path?: string) => {
      //案件内容メニューがクリックされると案件内容内の書類内容タブに遷移させる
      if (
        hasValue(projectData) &&
        path === `/project/${projectData.id}/content/*`
      ) {
        log.debug("debug!");
        navigate(`/project/${projectData.id}/content/application`);
      } else {
        !isNullish(path) && navigate(path);
      }
    },
    [navigate, projectData],
  );

  // 確認モーダル確定ボタンクリック時のイベント
  const handleModalSubmit = useCallback(async () => {
    if (isNullish(projectData)) {
      errorToast(t("mes.一般エラーメッセージ"));
      return;
    }
    try {
      // 新規細胞発送を作成
      const result = await createCellStockDelivery({
        cellStockDelivery: {
          projectId: projectData.id,
          shippingInformation: {
            shippingStatus: "unshipped",
          },
          receiptInformation: {
            receivedStatus: "not_received",
          },
          shippingResearchCells: [],
          shippingClinicalCells: [],
          shippingDestination: {
            shippingDestinationName: "",
            pickupLocation: "",
            postalCode: "",
            address: "",
            titleAndPosition: "",
            fullName: "",
            phoneNumber: "",
            mailAddress: "",
            other: "",
          },
          deliveryArrangementMethodType: "arranged_by_applicant",
          domesticDelivererType: "not_selected",
          domesticDelivererShipperRegistrationType: "not_registered",
          foreignDelivererType: "not_selected",
          foreignDelivererAccountInformation: "",
          shippingContainerArrangementType: "arranged_by_applicant",
          pickupDestination: {
            destinationName: "",
            pickupLocation: "",
            postalCode: "",
            address: "",
            titleAndPosition: "",
            fullName: "",
            phoneNumber: "",
            mailAddress: "",
            other: "",
          },
          provisionFeeMemo: "",
          officeMemberCommentForApplicants: "",
          officeMemberCommentInternal: "",
          attachmentFiles: [],
          created: {},
          updated: {},
        },
      }).unwrap();
      navigate(`/project/${projectData.id}/cell-stock-delivery/${result.id}`, {
        state: { isNewCreate: true },
      });
    } catch (e) {
      log.error(errorMessageOf(e));
      errorToast(t("mes.汎用エラーメッセージ"));
    }
  }, [createCellStockDelivery, errorToast, navigate, projectData, t]);

  return (
    <Box p={"6px 8px"}>
      {hasValue(myRole) && hasValue(projectId) && hasValue(projectData) && (
        <VStack spacing={"2px"}>
          <ProjectMenuItem
            path={`/project/${projectData.id}/content/*`}
            onClick={handleClickMenu}
          >
            <Text>{t("lbl.案件内容")}</Text>
          </ProjectMenuItem>

          <ProjectMenuItem
            {...documentMenuDisclosure.getButtonProps()}
            path={`/project/${projectId}/document`}
          >
            <Text>{t("lbl.書類")}</Text>
          </ProjectMenuItem>
          <VStack
            w={"100%"}
            spacing={"2px"}
            pl={"10px"}
            {...documentMenuDisclosure.getDisclosureProps()}
          >
            {hasValue(documentData) &&
              documentData.map(
                (document, index) =>
                  hasValue(document) && (
                    <ProjectMenuItem
                      key={index}
                      path={`/project/${projectId}/document/${document.id}/content`}
                      onClick={handleClickMenu}
                    >
                      <Text>{String(document.documentControlNumber)}</Text>
                      <Text>
                        {getMessageFromEnumValue(
                          t,
                          documentTypeMeta,
                          document.documentType,
                        )}
                      </Text>
                    </ProjectMenuItem>
                  ),
              )}
            {myRole === "applicant" &&
              projectData?.projectState === "ongoing" && (
                <ProjectMenuItem
                  onClick={createDocumentDisclosure.onOpen}
                  leftIcon={BsPlus}
                >
                  <Text>{t("btn.申請・報告を作成ボタン")}</Text>
                </ProjectMenuItem>
              )}
          </VStack>

          <ProjectMenuItem
            {...cellMenuDisclosure.getButtonProps()}
            path={`/project/${projectId}/cell-stock-delivery`}
          >
            <Text>{t("lbl.細胞発送")}</Text>
          </ProjectMenuItem>

          {(myRole === "applicant" ||
            myRole === "office_member" ||
            myRole === "executive_director") && (
            <VStack
              w={"100%"}
              spacing={"2px"}
              pl={"10px"}
              {...cellMenuDisclosure.getDisclosureProps()}
            >
              {hasValue(cellStockDeliveryData) &&
                cellStockDeliveryData.map(
                  (cellStockDelivery, index) =>
                    hasValue(cellStockDelivery) && (
                      <ProjectMenuItem
                        key={index}
                        path={`/project/${projectId}/cell-stock-delivery/${cellStockDelivery.id}`}
                        onClick={handleClickMenu}
                      >
                        <Text>
                          {`${t("lbl.発送")}${index + 1} `}
                          {cellStockDelivery.shippingInformation.shippingDate &&
                            `(${String(
                              ymdToDateText(
                                cellStockDelivery.shippingInformation
                                  .shippingDate,
                              ),
                            )})`}
                        </Text>
                      </ProjectMenuItem>
                    ),
                )}
              {myRole === "office_member" &&
                projectData?.projectState === "ongoing" && (
                  <ProjectMenuItem
                    onClick={createCellDeliveryDisclosure.onOpen}
                    leftIcon={BsPlus}
                  >
                    {t("btn.細胞発送新規作成ボタン")}
                  </ProjectMenuItem>
                )}
            </VStack>
          )}
          {(myRole === "applicant" || myRole === "office_member") && (
            <ProjectMenuItem
              path={`/project/${projectData.id}/inquiry`}
              onClick={handleClickMenu}
            >
              <Text>{t("lbl.質疑応答")}</Text>
            </ProjectMenuItem>
          )}

          <ProjectMenuItem
            path={`/project/${projectData.id}/mail-log`}
            onClick={handleClickMenu}
          >
            <Text>{t("lbl.メールログ")}</Text>
          </ProjectMenuItem>

          {myRole === "office_member" && (
            <ProjectMenuItem
              path={`/project/${projectData.id}/work-log`}
              onClick={handleClickMenu}
            >
              <Text>{t("lbl.作業ログ")}</Text>
            </ProjectMenuItem>
          )}
        </VStack>
      )}
      {/* 確認用モーダル */}
      <CMModalOptionsCancel
        isOpen={createCellDeliveryDisclosure.isOpen}
        labelHeader={t("lbl.確認ポップアップタイトル")}
        labelBody={t("mes.細胞発送管理追加確認メッセージ")}
        labelSubmitButton={t("btn.OKボタン")}
        labelCancelButton={t("btn.キャンセルボタン")}
        onSubmit={handleModalSubmit}
        onCancel={createCellDeliveryDisclosure.onClose}
      />
      {/* 新規書類作成用モーダル */}
      <FN30S05CreateDocument
        projectId={projectId ?? -1}
        isOpenModal={createDocumentDisclosure.isOpen}
        onCloseModal={createDocumentDisclosure.onClose}
      />
    </Box>
  );
};
