import { Box, HStack, VStack } from "@chakra-ui/react";
import { CMButton } from "@pscsrvlab/psc-react-components";
import {
  getMessageFromEnumValue,
  hasValue,
  isNullish,
} from "../../../../lib/util/common-util";
import { useNavigate } from "react-router-dom";
import { useMemo } from "react";
import { reviewResultMeta } from "../../../../lib/object/value/review-result";
import { useAppTranslation } from "../../../../hooks/use-app-translation";
import { useAppParams } from "../../../../hooks/use-app-params";
import { useAppGetDocumentQuery } from "../../../../hooks/query/use-app-get-document-query";
import { useAppGetReviewQuery } from "../../../../hooks/query/use-app-get-review-query";
import { LabeledTextVertical } from "../../../ui/text/LabeledTextVertical/LabeledTextVertical";
import { ymdToDateText } from "../../../../lib/util/common-date-util";

export const FN40S31ReviewConcluded = () => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();

  const { documentId, reviewId } = useAppParams();

  //書類情報を取得
  const { data: documentData } = useAppGetDocumentQuery(
    {
      documentId: documentId ?? -1,
    },
    { skip: isNullish(documentId) },
  );

  const documentControlNumber = useMemo(() => {
    if (hasValue(documentData?.documentControlNumber)) {
      return documentData?.documentControlNumber;
    }
  }, [documentData]);

  //審査情報を取得
  const { data: reviewData } = useAppGetReviewQuery(
    {
      reviewId: reviewId ?? -1,
    },
    { skip: isNullish(reviewId) },
  );

  const reviewResult = useMemo(() => {
    if (hasValue(reviewData?.reviewResult)) {
      return reviewData?.reviewResult;
    }
  }, [reviewData]);

  const reviewDateText = useMemo(() => {
    if (isNullish(reviewData) || isNullish(reviewData.reviewDate))
      return undefined;
    return ymdToDateText(reviewData.reviewDate);
  }, [reviewData]);

  const handleClick = () => {
    navigate("../");
  };

  return (
    <Box overflow={"auto"}>
      {hasValue(documentControlNumber) && (
        <VStack mt={"20px"} spacing={"20px"}>
          <Box fontSize={"2xl"} fontWeight={"bold"}>
            {t("mes.事務局審査完了メッセージ")}
          </Box>
          <HStack>
            <LabeledTextVertical
              label={t("lbl.書類管理番号")}
              text={documentControlNumber}
            />
            <LabeledTextVertical
              label={t("lbl.審査結果")}
              text={getMessageFromEnumValue(t, reviewResultMeta, reviewResult)}
            />
            <LabeledTextVertical
              label={t("lbl.審査実施日")}
              text={reviewDateText}
            />
          </HStack>
          <CMButton label={t("btn.審査に戻るボタン")} onClick={handleClick} />
        </VStack>
      )}
    </Box>
  );
};
