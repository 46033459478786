// [注意] propsを追加・変更するときは、memoの第二引数の比較関数が適切に動いているかを確認すること。
import { PartnerInfo } from "../../section/PartnersSection/PartnersSection";
import { CollaborativePartner } from "../../../../../../../../../lib/object/value/collaborative-partner";
import {
  CMButtonFormCommentProps,
  CMFormInputText,
} from "@pscsrvlab/psc-react-components";
import { ComponentStyleProps } from "../../../../../../../../../lib/styles/props/component-style-props";
import { memo } from "react";
import { useChangeBadgeProperties } from "../../../../../../../../../hooks/document/change-application/use-change-badge-properties";
import { HStack } from "@chakra-ui/react";
import { collaborativePartnerNameMeta } from "../../../../../../../../../lib/object/value/collaborative-partner-name";
import { fullNameMeta } from "../../../../../../../../../lib/object/value/full-name";
import { titleAndPositionMeta } from "../../../../../../../../../lib/object/value/title-and-position";
import { isEqual } from "lodash";
import { useAppTranslation } from "../../../../../../../../../hooks/use-app-translation";

export type FoldedPartnerContentProps = {
  documentType:
  | "new_application"
  | "change_application"
  | "project_force_update"
  | "project_force_create"
  | "project_content";
  editMode: "editable" | "readOnly";

  value: PartnerInfo;

  changedFrom?: CollaborativePartner;

  /**
   * コメントボタンのprops。
   * これが存在すれば、コメントボタンを表示する。
   */
  commentButtonProps?: {
    root: CMButtonFormCommentProps | undefined;
    collaborativePartnerName: CMButtonFormCommentProps | undefined;
    principalInvestigator: CMButtonFormCommentProps | undefined;
    contactPerson: CMButtonFormCommentProps | undefined;
  };
} & ComponentStyleProps;

export const FoldedPartnerContent = memo(
  function FoldedPartnerContent({
    documentType,
    editMode,

    value,

    changedFrom,

    sx,
    ...rest
  }: FoldedPartnerContentProps) {
    const { t } = useAppTranslation();

    const changeBadgePropertiesPartnerName = useChangeBadgeProperties(
      documentType,
      changedFrom?.collaborativePartnerName,
    );
    const changeBadgePropertiesPrincipalInvestigatorFullName =
      useChangeBadgeProperties(
        documentType,
        changedFrom?.principalInvestigator.fullName,
      );
    const changeBadgePropertiesPrincipalInvestigatorTitleAndPosition =
      useChangeBadgeProperties(
        documentType,
        changedFrom?.principalInvestigator.titleAndPosition,
      );

    return (
      <HStack alignItems={"flex-start"} sx={sx} {...rest}>
        <CMFormInputText
          label={t("lbl.機関名")}
          editMode={editMode}
          valueObjectMeta={collaborativePartnerNameMeta}
          value={value?.collaborativePartnerName}
          changeBadgeProperties={changeBadgePropertiesPartnerName}
          minW={0}
        />
        <CMFormInputText
          label={t("lbl.研究責任者名")}
          editMode={editMode}
          valueObjectMeta={fullNameMeta}
          value={value?.principalInvestigator.fullName}
          changeBadgeProperties={
            changeBadgePropertiesPrincipalInvestigatorFullName
          }
          minW={0}
        />
        <CMFormInputText
          label={t("lbl.研究責任者所属")}
          editMode={editMode}
          valueObjectMeta={titleAndPositionMeta}
          value={value?.principalInvestigator.titleAndPosition}
          changeBadgeProperties={
            changeBadgePropertiesPrincipalInvestigatorTitleAndPosition
          }
          minW={0}
        />
      </HStack>
    );
  },
  (prevProps, nextProps) => {
    const _isEqual = isEqual(prevProps, nextProps);
    // log.debug(`PartnerContent: _isEqual=${_isEqual}`);
    return _isEqual;
  },
);
