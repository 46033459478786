import {
  messageId,
  PropertyMeta,
  ValueObjectMetaNumber,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.number().min(0);
const savedMinimalSchema = z.number().min(0);
const schema = z.number().min(0);
const savedSchema = z.number().min(0);
const jsonSchema = z.number();
/**
 * 細胞発送ID(値オブジェクト)のメタデータ。
 *
 *  */
export const cellStockDeliveryIdMeta = new ValueObjectMetaNumber(
  "CellStockDeliveryId",
  "\u7D30\u80DE\u767A\u9001ID",
  messageId("lbl.\u7D30\u80DE\u767A\u9001ID"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
