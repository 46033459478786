import { Stack } from "@chakra-ui/react";
import { ReviewSaved } from "../../../../../../lib/object/entity/review";
import { useMemo } from "react";
import {
  getMessageFromEnumValue,
  hasValue,
} from "../../../../../../lib/util/common-util";
import { reviewTypeMeta } from "../../../../../../lib/object/value/review-type";
import { reviewResultMeta } from "../../../../../../lib/object/value/review-result";
import { FrameLeftSideMenu } from "../../../../../ui/frame/FrameLeftSideMenu/FrameLeftSideMenu";
import { CMSelectableListItemWithBadge } from "@pscsrvlab/psc-react-components";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";

export type ReviewDetailsSideMenuProps = {
  /**
   * 審査一覧
   */
  reviewDetailsList: ReviewSaved[];

  /**
   * 選択中の審査ID
   */
  reviewId?: number;

  /**
   * 審査一覧選択時のイベント
   */
  onClick: (reviewId: number) => void;
};

/**
 * FN40-S30審査
 * サイドメニュー
 */
export const ReviewDetailsSideMenu = ({
  reviewDetailsList,
  reviewId,
  onClick,
}: ReviewDetailsSideMenuProps) => {
  const { t } = useAppTranslation();

  const contentList = useMemo(() => {
    let officeCount = 1;
    let committeeCount = 1;

    return reviewDetailsList
      .map((v) => {
        const item = {
          id: v.id,
          label: `${getMessageFromEnumValue(t, reviewTypeMeta, v.reviewType)}`,
          badgeText: getMessageFromEnumValue(
            t,
            reviewResultMeta,
            v.reviewResult,
          ),
          badgeColor: v.reviewResult === undefined ? "gray" : "blue",
          backgroundColor: "gray.100",
        };

        // 一覧に表示する審査情報を絞り込む
        // 未選択と審査不要は審査ができないので対象外
        if (v?.reviewType === "office_review") {
          // 事務局審査の場合
          item.label = `${item.label}${officeCount}`;
          officeCount++;
          return item;
        } else if (v?.reviewType === "committee_review") {
          // 委員会審査の場合
          item.label = `${item.label}${committeeCount}`;
          committeeCount++;
          return item;
        }
      })
      .filter(hasValue);
  }, [t, reviewDetailsList]);

  return (
    <FrameLeftSideMenu title={t("lbl.審査一覧")} h={"100%"}>
      <Stack pt={"8px"} pl={"8px"} spacing={"8px"}>
        {contentList.map((v) => (
          <CMSelectableListItemWithBadge
            key={v.id}
            label={v.label}
            selected={reviewId === v.id}
            badgeText={v.badgeText}
            badgeColor={v.badgeColor}
            onClick={() => {
              onClick?.(v.id);
            }}
          />
        ))}
      </Stack>
    </FrameLeftSideMenu>
  );
};
