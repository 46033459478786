import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";
import { Divider, StackDivider, Text, VStack } from "@chakra-ui/react";
import { hasValue } from "../../../../../../lib/util/common-util";
import { RevisionRequestItemViewModel } from "../../../../../../lib/object/vm/revision-request-view-model";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { Ref, useMemo } from "react";
import { RevisionRequestCommentHistoryListThread } from "./_components/RevisionRequestCommentListThread/RevisionRequestCommentHistoryListThread";
import { Comments } from "@pscsrvlab/psc-react-components";

export type RevisionRequestCommentHistoryListProps = {
  revisionMode: "readOnly" | "office_member_editable" | "applicant_editable";

  /**
   * 修正依頼全件のitems(修正依頼コメント・返信の配列)の配列。
   */
  revisionRequestsItems: RevisionRequestItemViewModel[][];

  /**
   * 修正依頼コメント・返信のprops。
   */
  commentsList: Comments[];

  onSaveReply?: (path: string, value: string | null) => void;

  selectedCommentPath: string | null;

  scrollableRef: Ref<any>;
  scrollOffset: number;
} & ComponentStyleProps;

/**
 * 修正依頼コメントの一覧を表示するコンポーネント
 */
export const RevisionRequestCommentHistoryList = ({
  revisionMode,

  revisionRequestsItems,
  commentsList,

  onSaveReply,

  selectedCommentPath,

  scrollableRef,
  scrollOffset,

  sx,
  ...rest
}: RevisionRequestCommentHistoryListProps) => {
  const { t } = useAppTranslation();

  //表示するコメント数を算出
  const countAllComment = useMemo(
    () =>
      revisionRequestsItems
        .map((item) => item.length)
        .reduce((a, b) => a + b, 0),
    [revisionRequestsItems],
  );

  const isApplicantEditable = useMemo(
    () => revisionMode === "applicant_editable",
    [revisionMode],
  );

  //コメント数を全件表示
  const headingText = useMemo(
    () => `${countAllComment}${t("lbl.修正依頼コメント数")}`,
    [countAllComment, t],
  );

  return (
    <VStack
      flex={"0 0 240px"}
      alignSelf={"stretch"}
      alignItems={"stretch"}
      bgColor={"gray.50"}
      pt={"20px"}
      pb={"20px"}
      spacing={0}
      overflow={"auto"}
      borderWidth={"1px"}
      borderColor={"gray.100"}
      sx={sx}
      {...rest}
    >
      <Text pl={"20px"} pb={"12px"}>
        {headingText}
      </Text>
      <Divider borderColor="gray.300" />
      <VStack alignItems={"stretch"} spacing={0}>
        {commentsList.map((comments, index) => (
          <>
            {index !== 0 && (
              <Divider
                borderColor={
                  hasValue(selectedCommentPath) &&
                  comments.path === selectedCommentPath
                    ? "orange.500"
                    : "gray.200"
                }
              />
            )}
            <RevisionRequestCommentHistoryListThread
              key={index}
              isApplicantEditable={isApplicantEditable}
              comments={comments}
              onSaveReply={onSaveReply}
              selected={
                hasValue(selectedCommentPath) &&
                comments.path === selectedCommentPath
              }
              scrollableRef={scrollableRef}
              scrollOffset={scrollOffset}
            />
          </>
        ))}
      </VStack>
    </VStack>
  );
};
