import { LoginUserInfo } from "../../../../../../../store/auth/types";
import { useMemo } from "react";
import { ApplicationContentViewModel } from "../ApplicationDocument";
import { RevisionRequestItemViewModel } from "../../../../../../../lib/object/vm/revision-request-view-model";
import {
  getCommentProps,
  useRevisionRequestComment,
} from "../../../../../../../hooks/revision-request/use-revision-request-comment";
import { ApplicationContentSaved } from "../../../../../../../lib/object/value/application-content";
import { uniq } from "lodash";

export function useApplicationDocumentCommentProps(
  loginUserInfo: LoginUserInfo,
  value: ApplicationContentViewModel,
  /**
   * 変更申請前の内容。
   * 変更申請の場合のみ必須。
   */
  beforeValue: ApplicationContentSaved | undefined,

  revisionMode:
    | "none"
    | "readOnly"
    | "office_member_editable"
    | "applicant_editable",
  revisionRequestItems?: RevisionRequestItemViewModel[],
  /**
   * 修正依頼コメントを選択したときのコールバック。
   * 未選択状態になる場合はnullを送出する。
   */
  onSelectComment?: (path: string | null) => void,
  /**
   * 修正依頼の変更。
   */
  onChangeRevisionRequestItems?: (
    requestsChange: (
      before: RevisionRequestItemViewModel[],
    ) => RevisionRequestItemViewModel[],
  ) => void,
) {
  const { ctx } = useRevisionRequestComment(
    loginUserInfo,
    revisionMode,
    revisionRequestItems,
    onSelectComment,
    onChangeRevisionRequestItems,
  );

  const mergeKeysOf = (
    v0: { key: number }[],
    v1: { key: number }[] | undefined,
  ) => {
    return uniq([...v0, ...(v1 ?? [])].map((v) => v.key));
  };

  // [注意] 修正依頼コメントのパスは、データ構造のJSONポインタと1:1対応させることは不可能なので、修正依頼特有のパスとする。
  //   単純に、修正依頼の配置場所の識別子だと考えれば良い。
  //   このパスの辞書順に、画面右方のコメントリスト上は並べるので、それを考慮したパスとすること。
  const projectName = useMemo(
    () => getCommentProps(ctx, "/00:projectName"),
    [ctx],
  );
  const institution = useMemo(
    () => getCommentProps(ctx, "/01:institution"),
    [ctx],
  );
  const principalInvestigator = useMemo(
    () => getCommentProps(ctx, "/02:principalInvestigator"),
    [ctx],
  );
  const contactPeople = useMemo(
    () => getCommentProps(ctx, "/03:contactPeople"),
    [ctx],
  );

  const contactPeopleN = useMemo(
    () =>
      mergeKeysOf(value.contactPeople, beforeValue?.contactPeople).map(
        (key) => {
          const paddedKey = padKey(key);
          return {
            key,
            value: getCommentProps(ctx, `/04:contactPeople/${paddedKey}`),
          };
        },
      ),
    [beforeValue?.contactPeople, ctx, value.contactPeople],
  );
  // 「使用する細胞」セクション全体のコメントのパスは、 "/05:requestingCells" とする。
  const requestingCells = useMemo(
    () => getCommentProps(ctx, "/05:requestingCells"),
    [ctx],
  );
  const requestingResearchCellsN = useMemo(
    () =>
      mergeKeysOf(
        value.requestingResearchCells,
        beforeValue?.requestingResearchCells,
      ).map((key) => {
        const paddedKey = padKey(key);
        return {
          key,
          value: getCommentProps(
            ctx,
            `/06:requestingResearchCells/${paddedKey}`,
          ),
        };
      }),
    [beforeValue?.requestingResearchCells, ctx, value.requestingResearchCells],
  );
  const requestingClinicalCellsN = useMemo(
    () =>
      mergeKeysOf(
        value.requestingClinicalCells,
        beforeValue?.requestingClinicalCells,
      ).map((key) => {
        const paddedKey = padKey(key);
        return {
          key,
          value: getCommentProps(
            ctx,
            `/07:requestingClinicalCells/${paddedKey}`,
          ),
        };
      }),
    [beforeValue?.requestingClinicalCells, ctx, value.requestingClinicalCells],
  );
  const ipsCellStockUsePurpose = useMemo(
    () => getCommentProps(ctx, "/08:ipsCellStockUsePurpose"),
    [ctx],
  );
  const ipsCellStockRequirementReason = useMemo(
    () => getCommentProps(ctx, "/09:ipsCellStockRequirementReason"),
    [ctx],
  );
  const planSummary = useMemo(
    () => getCommentProps(ctx, "/10:planSummary"),
    [ctx],
  );
  const hasCollaborativePartner = useMemo(
    () => getCommentProps(ctx, "/11:hasCollaborativePartner"),
    [ctx],
  );
  const usageEndDate = useMemo(
    () => getCommentProps(ctx, "/12:usageEndDate"),
    [ctx],
  );
  const researchSite = useMemo(
    () => getCommentProps(ctx, "/13:researchSite"),
    [ctx],
  );
  const ipsCultureExperience = useMemo(
    () => getCommentProps(ctx, "/14:ipsCultureExperience"),
    [ctx],
  );
  const ethicalApplicationStatus = useMemo(
    () => getCommentProps(ctx, "/15:ethicalApplicationStatus"),
    [ctx],
  );
  const handlingAfterUse = useMemo(
    () => getCommentProps(ctx, "/16:handlingAfterUse"),
    [ctx],
  );
  const foundationContractState = useMemo(
    () => getCommentProps(ctx, "/17:foundationContractState"),
    [ctx],
  );

  // 添付資料
  const attachmentFiles = useMemo(() => {
    return {
      root: getCommentProps(ctx, "/18:attachmentFiles"),

      research_proposal: getCommentProps(
        ctx,
        "/18:attachmentFiles/00:research_proposal",
      ),
      certificate_of_ethics_committee_approval: getCommentProps(
        ctx,
        "/18:attachmentFiles/01:certificate_of_ethics_committee_approval",
      ),
      management_system: getCommentProps(
        ctx,
        "/18:attachmentFiles/02:management_system",
      ),
      principal_investigator_resume: getCommentProps(
        ctx,
        "/18:attachmentFiles/03:principal_investigator_resume",
      ),
      organization_overview: getCommentProps(
        ctx,
        "/18:attachmentFiles/04:organization_overview",
      ),
      research_achievement: getCommentProps(
        ctx,
        "/18:attachmentFiles/05:research_achievement",
      ),
      facility_gmp_compliance: getCommentProps(
        ctx,
        "/18:attachmentFiles/06:facility_gmp_compliance",
      ),
      other: getCommentProps(ctx, "/18:attachmentFiles/07:other"),
    };
  }, [ctx]);

  // 分担機関
  const partners = useMemo(() => getCommentProps(ctx, "/19:partners"), [ctx]);
  const partnersN = useMemo(
    () =>
      mergeKeysOf(value.partners, beforeValue?.partners).map((key) => {
        const paddedKey = padKey(key);
        return {
          key,
          value: {
            root: getCommentProps(ctx, `/19:partners/${paddedKey}`),
            collaborativePartnerName: getCommentProps(
              ctx,
              `/19:partners/${paddedKey}/00:collaborativePartnerName`,
            ),
            principalInvestigator: getCommentProps(
              ctx,
              `/19:partners/${paddedKey}/01:principalInvestigator`,
            ),
            contactPerson: getCommentProps(
              ctx,
              `/19:partners/${paddedKey}/02:contactPerson`,
            ),
            attachmentFiles: {
              research_proposal: getCommentProps(
                ctx,
                `/19:partners/${paddedKey}/03:attachmentFiles/00:research_proposal`,
              ),
              certificate_of_ethics_committee_approval: getCommentProps(
                ctx,
                `/19:partners/${paddedKey}/03:attachmentFiles/01:certificate_of_ethics_committee_approval`,
              ),
              management_system: getCommentProps(
                ctx,
                `/19:partners/${paddedKey}/03:attachmentFiles/02:management_system`,
              ),
              principal_investigator_resume: getCommentProps(
                ctx,
                `/19:partners/${paddedKey}/03:attachmentFiles/03:principal_investigator_resume`,
              ),
              organization_overview: getCommentProps(
                ctx,
                `/19:partners/${paddedKey}/03:attachmentFiles/04:organization_overview`,
              ),
              research_achievement: getCommentProps(
                ctx,
                `/19:partners/${paddedKey}/03:attachmentFiles/05:research_achievement`,
              ),
              facility_gmp_compliance: getCommentProps(
                ctx,
                `/19:partners/${paddedKey}/03:attachmentFiles/06:facility_gmp_compliance`,
              ),
              other: getCommentProps(
                ctx,
                `/19:partners/${paddedKey}/03:attachmentFiles/07:other`,
              ),
            },
          },
        };
      }),
    [beforeValue?.partners, ctx, value.partners],
  );

  // チェックリスト
  const checklists = useMemo(() => getCommentProps(ctx, "/checklists"), [ctx]);

  return useMemo(() => {
    return {
      projectName,
      institution,
      principalInvestigator,
      contactPeople,
      contactPeopleN,
      requestingCells,
      requestingResearchCellsN,
      requestingClinicalCellsN,
      ipsCellStockUsePurpose,
      ipsCellStockRequirementReason,
      planSummary,
      hasCollaborativePartner,
      usageEndDate,
      researchSite,
      ipsCultureExperience,
      ethicalApplicationStatus,
      handlingAfterUse,
      foundationContractState,
      attachmentFiles,
      partners,
      partnersN,
      checklists,
    };
  }, [
    attachmentFiles,
    checklists,
    contactPeople,
    contactPeopleN,
    ethicalApplicationStatus,
    foundationContractState,
    handlingAfterUse,
    hasCollaborativePartner,
    institution,
    ipsCellStockRequirementReason,
    ipsCellStockUsePurpose,
    ipsCultureExperience,
    partners,
    partnersN,
    planSummary,
    principalInvestigator,
    projectName,
    requestingCells,
    requestingClinicalCellsN,
    requestingResearchCellsN,
    researchSite,
    usageEndDate,
  ]);
}

function padKey(key: number): string {
  return key.toString(10).padStart(2, "0");
}
