import React from "react";
import { ComponentStyleProps } from "../../../../lib/styles/props/component-style-props";
import { VerticalTableRow } from "../VerticalTableRow/VerticalTableRow";
import { CMFormInputBaseText } from "@pscsrvlab/psc-react-components";

export type VerticalTableRowTextInputProps = {
  /**
   * ヘッダーとして使用
   */
  label?: string;
  editMode?: "editable" | "disabled" | "readOnly";

  /**
   * 注意書き
   */
  attentionLabel?: string;

  placeholder?: string;

  /**
   * 初期値。
   */
  value?: string;

  onChange?: (value: string) => void;
  onBlur?: () => void;
} & ComponentStyleProps;

/**
 * テーブルデータがインプットの縦型テーブルRow
 */
export const VerticalTableRowTextInput = ({
  label,
  attentionLabel,
  editMode,
  placeholder,
  value = "",
  onChange,
  onBlur,
  sx,
  ...rest
}: VerticalTableRowTextInputProps) => {
  return (
    <VerticalTableRow
      label={label}
      attentionLabel={attentionLabel}
      sx={sx}
      {...rest}
    >
      <CMFormInputBaseText
        editMode={editMode}
        value={value}
        placeholder={placeholder}
        onBlur={onBlur}
        onChange={onChange}
      />
    </VerticalTableRow>
  );
};
