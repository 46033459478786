import { Text } from "@chakra-ui/react";
import React, { memo } from "react";
import { ComponentStyleProps } from "../../../../lib/styles/props/component-style-props";

export type WeakTitleTextProps = {
  /**
   * ラベル
   */
  label?: string;

  /**
   * 区切り文字
   */
  separator?: string;

  /**
   * ラベルに対する値
   */
  value?: string | null;
} & ComponentStyleProps;

/**
 * グレー文字の項目と値のテキスト
 */
export const WeakTextItem = memo(function WeakTextItem({
  label = "",
  separator = ":",
  value,

  sx,
  ...rest
}: WeakTitleTextProps) {
  const capitalizedLabel = label;

  return (
    <Text
      sx={sx}
      fontWeight={400}
      fontSize={"14px"}
      color={"gray.500"}
      {...rest}
    >
      {`${capitalizedLabel}${separator} ${value ?? "-"}`}
    </Text>
  );
});
