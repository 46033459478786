import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * JSONパッチオペレーションタイプ(値オブジェクト)
 *
 *
 *
 * add:
 * remove:
 * replace:
 * move:
 * copy:
 * test:  */
export type JsonPatchOperationType = z.infer<
  typeof jsonPatchOperationTypeMeta.schema
>;
/**
 * JSONパッチオペレーションタイプ(値オブジェクト)のメタデータ。
 *
 *  */
export const jsonPatchOperationTypeMeta = new ValueObjectMetaEnum<
  ["add", "remove", "replace", "move", "copy", "test"]
>(
  "JsonPatchOperationType",
  "JSON\u30D1\u30C3\u30C1\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u30BF\u30A4\u30D7",
  messageId(
    "lbl.JSON\u30D1\u30C3\u30C1\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u30BF\u30A4\u30D7"
  ),
  ["add", "remove", "replace", "move", "copy", "test"],
  {
    add: "code.JSON\u30D1\u30C3\u30C1\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u30BF\u30A4\u30D7.\u8FFD\u52A0",
    remove:
      "code.JSON\u30D1\u30C3\u30C1\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u30BF\u30A4\u30D7.\u524A\u9664",
    replace:
      "code.JSON\u30D1\u30C3\u30C1\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u30BF\u30A4\u30D7.\u7F6E\u63DB",
    move: "code.JSON\u30D1\u30C3\u30C1\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u30BF\u30A4\u30D7.\u79FB\u52D5",
    copy: "code.JSON\u30D1\u30C3\u30C1\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u30BF\u30A4\u30D7.\u30B3\u30D4\u30FC",
    test: "code.JSON\u30D1\u30C3\u30C1\u30AA\u30DA\u30EC\u30FC\u30B7\u30E7\u30F3\u30BF\u30A4\u30D7.\u30C6\u30B9\u30C8",
  }
);
