import { ProtectedRoute as PR } from "../components/route/ProtectedRoute";
import * as React from "react";
import { FN71S01MailLogSearch } from "../components/page/FN71/FN71S01MailLogSearch/FN71S01MailLogSearch";
import { RouteObject } from "react-router-dom";

export const _mailLogRoute: RouteObject = {
  path: "mail-log",
  element: (
    <PR
      roles={[
        "applicant",
        "office_member",
        "committee_member",
        "executive_director",
      ]}
    />
  ),
  children: [
    {
      index: true,
      element: <FN71S01MailLogSearch />,
    },
  ],
};
