import { HStack, Text } from "@chakra-ui/react";
import { memo } from "react";
import { ComponentStyleProps } from "../../../../../../../../lib/styles/props/component-style-props";
import { CMButtonFormCommentProps, Comments } from "@pscsrvlab/psc-react-components/src/components/form/comment";
import { ymdToDateText } from "../../../../../../../../lib/util/common-date-util";
import { useAppTranslation } from "../../../../../../../../hooks/use-app-translation";
import { YearMonthDay } from "../../../../../../../../lib/object/value/year-month-day";
import { FormSection } from "../../../../../../../ui/form/FormSection/FormSection";

export type ReportingPeriodAnnualReportSectionProps = {
  editMode: "editable" | "readOnly";

  value: YearMonthDay;

  /**
   * コメントボタンのprops。
   * これが存在すれば、コメントボタンを表示する。
   */
  commentButtonProps?: CMButtonFormCommentProps;
  /**
   * 1セクション単位の修正依頼コメント・返信。履歴参照画面で使用。
   */
  commentsList?: Comments[];
} & ComponentStyleProps;

/**
 * 使用期間セクション
 */
export const ReportingPeriodAnnualReportSection = memo(
  function ReportingPeriodAnnualReportSection({
    editMode,
    value,
    commentButtonProps,
    commentsList,

    sx,
    ...rest
  }: ReportingPeriodAnnualReportSectionProps) {
    const { t } = useAppTranslation();

    return (
      <FormSection
        title={t("lbl.報告対象期間")}
        description={
          editMode === "editable" ? t("gdc.報告対象期間補足") : undefined
        }
        commentButtonProps={commentButtonProps}
        commentsList={commentsList}
        sx={sx}
        {...rest}
      >
        <HStack spacing={"20px"} mt={"10px"}>
          <Text>{ymdToDateText(value)}</Text>
          <Text fontSize={"lg"}>{t("lbl.範囲シンボル")}</Text>
          <Text>{t("lbl.本年次報告の提出日")}</Text>
        </HStack>
      </FormSection>
    );
  },
);
