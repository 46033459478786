import {
  messageId,
  PropertyMeta,
  ValueObjectMetaComposite,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { freeTextMeta } from "../value/free-text";
/**
 * 報告書類事務局記入情報(値オブジェクト)
 *
 *  */
export type ReportOfficeMemberNotes = z.infer<typeof schema>;
export type ReportOfficeMemberNotesSaved = z.infer<typeof savedSchema>;
const minimalSchema = z.object({
  officeMemberNote: freeTextMeta.minimalSchema,
});
const savedMinimalSchema = z.object({
  officeMemberNote: freeTextMeta.savedMinimalSchema,
});
const schema = z.object({ officeMemberNote: freeTextMeta.schema });
const savedSchema = z.object({ officeMemberNote: freeTextMeta.savedSchema });
const jsonSchema = z.object({
  officeMemberNote: freeTextMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
/**
 * 報告書類事務局記入情報(値オブジェクト)のメタデータ。
 *
 *  */
export const reportOfficeMemberNotesMeta = new ValueObjectMetaComposite(
  "ReportOfficeMemberNotes",
  "\u5831\u544A\u66F8\u985E\u4E8B\u52D9\u5C40\u8A18\u5165\u60C5\u5831",
  messageId(
    "lbl.\u5831\u544A\u66F8\u985E\u4E8B\u52D9\u5C40\u8A18\u5165\u60C5\u5831"
  ),
  minimalSchema,
  savedMinimalSchema,
  schema,
  savedSchema,
  jsonSchema,
  undefined,
  undefined,
  {
    officeMemberNote: new PropertyMeta(
      "officeMemberNote",
      "\u4E8B\u52D9\u5C40\u30B3\u30E1\u30F3\u30C8",
      messageId("lbl.\u4E8B\u52D9\u5C40\u30B3\u30E1\u30F3\u30C8"),
      "always",
      false,
      freeTextMeta
    ),
  }
);
