import {
  CellStockCategory as CellStockCategoryJson,
  useListCellStockCategoryQuery,
} from "../../store/api/generated/stock-request-api";
import { isNullish } from "../../lib/util/common-util";
import { useMemo } from "react";
import {
  cellStockCategoryMeta,
  CellStockCategorySaved,
} from "../../lib/object/value/cell-stock-category";

export function useAppListCellStockCategoryQuery(
  arg: Parameters<typeof useListCellStockCategoryQuery>[0],
  options?: Parameters<typeof useListCellStockCategoryQuery>[1],
  skipOptionalValidations = true, // get系は、skipOptionalValidationsのデフォルトはtrueとする。
): { data: CellStockCategorySaved[] | null } {
  const { data: rawData } = useListCellStockCategoryQuery(arg, options);
  const data = useMemo(() => {
    if (isNullish(rawData)) return null;
    return rawData.map((d: CellStockCategoryJson) =>
      cellStockCategoryMeta.toSavedDomainObjectOrNull(
        d,
        skipOptionalValidations,
      ),
    );
  }, [rawData, skipOptionalValidations]);
  return { data };
}
