import { ComponentStyleProps } from "../../../../../../../../../lib/styles/props/component-style-props";
import {
  CMFormInputRadio,
  CMFormInputTextArea,
  Comments,
} from "@pscsrvlab/psc-react-components";
import React, { memo, useMemo } from "react";
import { hasValue } from "../../../../../../../../../lib/util/common-util";
import { Text, VStack } from "@chakra-ui/react";
import { ipsCellStockRequirementReasonTypeMeta } from "../../../../../../../../../lib/object/value/ips-cell-stock-requirement-reason-type";
import { ipsCellStockRequirementReasonOtherMeta } from "../../../../../../../../../lib/object/value/ips-cell-stock-requirement-reason-other";
import { CMButtonFormCommentProps } from "@pscsrvlab/psc-react-components/src/components/form/comment";
import { IpsCellStockRequirementReason } from "../../../../../../../../../lib/object/value/ips-cell-stock-requirement-reason";
import { useChangeBadgeProperties } from "../../../../../../../../../hooks/document/change-application/use-change-badge-properties";
import { ChangeReasonForm } from "../../form/ChangeReasonForm/ChangeReasonForm";
import { useSectionDiffMode } from "../../../../../../../../../hooks/document/change-application/use-section-diff-mode";
import { FormSection } from "../../../../../../../../ui/form/FormSection/FormSection";
import { useAppTranslation } from "../../../../../../../../../hooks/use-app-translation";
import { ChangeReasonItemViewModel } from "../../../../../../../../../lib/object/vm/change-application-view-model";

export type IpsCellStockRequirementReasonSectionProps = {
  documentType:
  | "new_application"
  | "change_application"
  | "project_force_update"
  | "project_force_create"
  | "project_content";
  editMode: "editable" | "readOnly";

  value: IpsCellStockRequirementReason;
  onChange?: (value: IpsCellStockRequirementReason) => void;

  changedFrom?: IpsCellStockRequirementReason;
  changeReason?: string;
  onChangeReason?: (
    change: (before: ChangeReasonItemViewModel) => ChangeReasonItemViewModel,
  ) => void;
  displayChangesOnly?: boolean;

  /**
   * コメントボタンのprops。
   * これが存在すれば、コメントボタンを表示する。
   */
  commentButtonProps?: CMButtonFormCommentProps;
  /**
   * 1セクション単位の修正依頼コメント・返信。履歴参照画面で使用。
   */
  commentsList?: Comments[];
} & ComponentStyleProps;

/**
 * iPS細胞ストックを必要とする理由セクション
 */
export const IpsCellStockRequirementReasonSection = memo(
  function IpsCellStockRequirementReasonSection({
    documentType,
    editMode,

    value,
    onChange,

    changedFrom,
    changeReason,
    onChangeReason,
    displayChangesOnly,

    commentButtonProps,
    commentsList,

    sx,
    ...rest
  }: IpsCellStockRequirementReasonSectionProps) {
    const { t } = useAppTranslation();

    const handleChangeReasonType = (v: "risk_reduction" | "other") => {
      onChange?.({ reasonType: v, other: v === "other" ? "" : undefined });
    };

    const handleChangeOtherText = (v: string) => {
      onChange?.({ reasonType: value.reasonType, other: v });
    };

    const readOnlyReason: string = useMemo(() => {
      if (editMode === "readOnly") {
        if (value?.reasonType === "risk_reduction") {
          return t("lbl.細胞移植時の免疫反応のリスクを低減させるため");
        }
        if (value?.reasonType === "other" && hasValue(value.other)) {
          return value.other;
        }
      }
      return "";
    }, [value, editMode, t]);

    const sectionDiffMode = useSectionDiffMode(
      documentType,
      changedFrom,
      value,
      onChangeReason,
    );

    const changeBadgePropertiesReasonType = useChangeBadgeProperties(
      documentType,
      changedFrom?.reasonType,
    );
    const changeBadgePropertiesOther = useChangeBadgeProperties(
      documentType,
      changedFrom?.other,
    );

    return (
      <FormSection
        title={t("lbl.iPS細胞ストックを必要とする理由")}
        commentButtonProps={commentButtonProps}
        displayMode={sectionDiffMode}
        displayChangesOnly={displayChangesOnly}
        commentsList={commentsList}
        sx={sx}
        {...rest}
      >
        {editMode === "readOnly" ? (
          <Text>{readOnlyReason}</Text>
        ) : (
          <VStack alignItems={"stretch"}>
            <CMFormInputRadio
              editMode={editMode}
              value={value.reasonType}
              noHeader={!hasValue(changedFrom?.reasonType)}
              valueObjectMeta={ipsCellStockRequirementReasonTypeMeta}
              changeBadgeProperties={changeBadgePropertiesReasonType}
              onChange={handleChangeReasonType}
            />
            {value.reasonType === "other" && (
              <CMFormInputTextArea
                editMode={editMode}
                value={value.other}
                noHeader={!hasValue(changedFrom?.other)}
                valueObjectMeta={ipsCellStockRequirementReasonOtherMeta}
                resize={editMode === "editable" ? "vertical" : "none"}
                minRows={2}
                onChange={handleChangeOtherText}
                changeBadgeProperties={changeBadgePropertiesOther}
                ml={"30px"}
                mt={"10px"}
              />
            )}
          </VStack>
        )}
        {sectionDiffMode === "updated" && (
          <ChangeReasonForm
            value={changeReason}
            onChange={onChangeReason}
            editMode={editMode}
          />
        )}
      </FormSection>
    );
  },
);
