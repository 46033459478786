import { StackDivider, VStack } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { ComponentStyleProps } from "../../../../lib/styles/props/component-style-props";

export type VerticalTableFrameProps = {
  /**
   * テーブル内の項目
   */
  children?: ReactNode;
} & ComponentStyleProps;

/**
 * 縦型テーブルフレーム
 */
export const VerticalTableFrame = ({
  children,
  sx,
  ...rest
}: VerticalTableFrameProps) => {
  return (
    <VStack
      divider={<StackDivider borderColor="gray.200" />}
      spacing={0}
      align={"stretch"}
      sx={{
        ...sx,
        borderCollapse: "separate",
        borderSpacing: 0,
      }}
      w={"540px"}
      border={"1px solid"}
      borderColor={"gray.400"}
      borderRadius={"6px"}
      bgColor={"white"}
      {...rest}
    >
      {children}
    </VStack>
  );
};
