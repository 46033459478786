import { ComponentStyleProps } from "../../../../lib/styles/props/component-style-props";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import { selectUserInfo } from "../../../../store/auth/slice";
import { hasValue, isNullish } from "../../../../lib/util/common-util";
import {
  Container,
  Divider,
  HStack,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { RevisionRequestCommentList } from "./_components/RevisionRequestCommentList/RevisionRequestCommentList";
import React, { useCallback, useRef, useState } from "react";
import {
  CMButton,
  CMButtonBack,
  CMFormInputTextArea,
} from "@pscsrvlab/psc-react-components";
import { useNavigate } from "react-router-dom";
import { FrameUpperLeftButton } from "../../../ui/frame/FrameUpperLeftButton/FrameUpperLeftButton";
import { CommonNextButton } from "../../../ui/button/CommonNextButton/CommonNextButton";
import useCustomToast from "../../../../hooks/use-custom-toast";
import { useCreateRevisionRequestMutation } from "../../../../store/api/generated/stock-request-api";
import { useSavedDocumentVM } from "../../../../hooks/document/use-saved-document-vm";
import {
  RevisionRequestItemViewModel,
  RevisionRequestViewModel,
} from "../../../../lib/object/vm/revision-request-view-model";
import { NewApplicationDocument } from "../application/new-application/NewApplicationDocument/NewApplicationDocument";
import { ChangeApplicationDocument } from "../application/change-application/ChangeApplicationDocument/ChangeApplicationDocument";
import { AnnualReportDocument } from "../report/annual-report/AnnualReportDocument/AnnualReportDocument";
import { TerminationReportDocument } from "../report/termination-report/TerminationReportDocument/TerminationReportDocument";
import { revisionRequestMeta } from "../../../../lib/object/entity/revision-request";
import log from "loglevel";
import { errorMessageOf } from "../../../../lib/util/error-util";
import { UserInformation } from "../../../../lib/object/value/user-information";
import { useLeaveEditingPrompt } from "../../../../hooks/use-leave-editing-prompt";
import { useAppTranslation } from "../../../../hooks/use-app-translation";
import { useProjectPagePathPrefix } from "../../../../hooks/use-project-page-path-prefix";
import { ConfirmationModal } from "../../../ui/modal/ConfirmationModal/ConfirmationModal";
import { revisionRequestBodyMeta } from "../../../../lib/object/value/revision-request-body";
import { FrameUpperRightButton } from "../../../ui/frame/FrameUpperRightButton/FrameUpperRightButton";

export type RevisionRequestCreatePageProps = {
  documentId: number;
} & ComponentStyleProps;
export const RevisionRequestCreatePage = ({
  documentId,

  sx,
  ...rest
}: RevisionRequestCreatePageProps) => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const { projectPagePathPrefix } = useProjectPagePathPrefix();
  const { errorToast } = useCustomToast();
  const loginUserInfo = useAppSelector(selectUserInfo);

  const { vmAndCallback } = useSavedDocumentVM(documentId);

  const { navigateWithoutPrompt } = useLeaveEditingPrompt();

  const [revisionRequestBodyVM, setRevisionRequestBodyVM] =
    useState<string>("");

  const [revisionRequestItemsVM, setRevisionRequestItemsVM] = useState<
    RevisionRequestItemViewModel[]
  >([]);

  const [selectedCommentPath, setSelectedCommentPath] = useState<string | null>(
    null,
  );
  const handleSelectComment = useCallback((path: string | null) => {
    setSelectedCommentPath(path);
  }, []);

  const handleClickBack = useCallback(() => {
    navigate(
      `${projectPagePathPrefix}/document/${documentId}/revision-request`,
    );
  }, [projectPagePathPrefix, navigate, documentId]);

  /**
   * 履歴参照ボタンが押下された際のイベント
   * routerに定義されたURLで履歴参照画面を別タブで表示させる
   */
  const handleClickHistoryButton = useCallback(() => {
    const baseURL = window.location.origin;
    window.open(
      baseURL + `/revision-history/${documentId}`,
      '_blank',
    );
  }, [documentId]);

  // 修正依頼モーダルの制御。
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [createRevisionRequest] = useCreateRevisionRequestMutation();
  const handleConfirm = useCallback(async () => {
    onClose();

    if (isNullish(loginUserInfo)) return;

    const revisionRequester: UserInformation = {
      appUserId: loginUserInfo.id,
      displayUserId: loginUserInfo.displayUserId,
      role: loginUserInfo.role,
      fullName: loginUserInfo.fullName,
      fullNameKana: loginUserInfo.fullNameKana,
      titleAndPosition: loginUserInfo.titleAndPosition,
      phoneNumber: loginUserInfo.phoneNumber,
      mailAddress: loginUserInfo.mailAddress,
    };

    const revisionRequest: RevisionRequestViewModel = {
      documentId,
      revisionRequestState: "revising",
      body: revisionRequestBodyVM,
      revisionRequester,
      items: revisionRequestItemsVM,
      created: {},
      updated: {},
    };
    const validationState = revisionRequestMeta.validate(revisionRequest);
    if (validationState.state === "error") {
      validationState.errors.forEach((e) => {
        errorToast(e.fullPropertyDisplayName + ": " + e.errorMessage);
      });
      return;
    }
    const jsonObject = revisionRequestMeta.toJsonObjectOrNull(
      validationState.value,
    );
    if (isNullish(jsonObject)) {
      errorToast(t("mes.汎用エラーメッセージ"));
      return;
    }

    try {
      const result = await createRevisionRequest({
        revisionRequest: jsonObject,
      }).unwrap();
      await navigateWithoutPrompt(
        `${projectPagePathPrefix}/document/${documentId}/revision-request/${result.id}/complete`,
      );
    } catch (e) {
      log.error(errorMessageOf(e));
      errorToast(t("mes.汎用エラーメッセージ"));
    }
  }, [
    onClose,
    loginUserInfo,
    documentId,
    revisionRequestBodyVM,
    revisionRequestItemsVM,
    errorToast,
    t,
    createRevisionRequest,
    navigateWithoutPrompt,
    projectPagePathPrefix,
  ]);

  const scrollableRef = useRef(null);

  return (
    <>
      <HStack
        flex={1}
        spacing={0}
        alignItems={"stretch"}
        minW={0}
        sx={sx}
        {...rest}
      >
        <VStack
          flex={1}
          overflowY={"scroll"}
          spacing={0}
          pb={"100px"}
          ref={scrollableRef}
        >
          <HStack justifyContent={"space-between"} width="100%">
            <FrameUpperLeftButton>
              <CMButtonBack
                labelBack={t("btn.修正依頼一覧に戻るボタン")}
                onClick={handleClickBack}
              />
            </FrameUpperLeftButton>

            {/* 履歴参照ボタン */}
            <FrameUpperRightButton>
              <CMButton
                size={"sm"}
                label={t("btn.修正依頼履歴参照ボタン")}
                onClick={handleClickHistoryButton}
              />
            </FrameUpperRightButton>

          </HStack>
          <Container minW={"500px"} maxW={"720px"} pb={"20px"}>
            <VStack alignItems={"flex-start"} pb={"20px"}>
              <HStack alignItems={"baseline"} pl={"4px"}>
                <Text fontSize={"lg"} color={"gray.900"} fontWeight={"bold"}>
                  {t("lbl.修正依頼本文")}
                </Text>
                <Text fontSize={"11px"} color={"gray.600"}>
                  {t("gdc.修正依頼本文補足")}
                </Text>
              </HStack>
              <CMFormInputTextArea
                noHeader={true}
                valueObjectMeta={revisionRequestBodyMeta}
                value={revisionRequestBodyVM}
                onChange={setRevisionRequestBodyVM}
                minH={"120px"}
                sx={{ alignSelf: "stretch" }}
              />
            </VStack>
            <Divider borderColor={"gray.500"} />
            <VStack pt={"16px"} pb={"16px"}>
              {vmAndCallback?.type === "new_application" &&
                hasValue(loginUserInfo) && (
                  <NewApplicationDocument
                    editMode={"readOnly"}
                    loginUserInfo={loginUserInfo}
                    value={vmAndCallback.vm}
                    revisionMode={"office_member_editable"}
                    revisionRequestItems={revisionRequestItemsVM}
                    onSelectComment={handleSelectComment}
                    onChangeRevisionRequestItems={setRevisionRequestItemsVM}
                    scrollableRef={scrollableRef}
                    scrollOffset={60}
                  />
                )}
              {vmAndCallback?.type === "change_application" &&
                hasValue(loginUserInfo) && (
                  <ChangeApplicationDocument
                    editMode={"readOnly"}
                    loginUserInfo={loginUserInfo}
                    value={vmAndCallback.vm}
                    revisionMode={"office_member_editable"}
                    revisionRequestItems={revisionRequestItemsVM}
                    onSelectComment={handleSelectComment}
                    onChangeRevisionRequestItems={setRevisionRequestItemsVM}
                    scrollableRef={scrollableRef}
                    scrollOffset={60}
                  />
                )}
              {vmAndCallback?.type === "annual_report" &&
                hasValue(loginUserInfo) && (
                  <AnnualReportDocument
                    loginUserInfo={loginUserInfo}
                    editMode={"readOnly"}
                    value={vmAndCallback.vm}
                    revisionMode={"office_member_editable"}
                    revisionRequestItems={revisionRequestItemsVM}
                    onSelectComment={handleSelectComment}
                    onChangeRevisionRequestItems={setRevisionRequestItemsVM}
                  />
                )}
              {vmAndCallback?.type === "termination_report" &&
                hasValue(loginUserInfo) && (
                  <TerminationReportDocument
                    loginUserInfo={loginUserInfo}
                    editMode={"readOnly"}
                    value={vmAndCallback.vm}
                    revisionMode={"office_member_editable"}
                    revisionRequestItems={revisionRequestItemsVM}
                    onSelectComment={handleSelectComment}
                    onChangeRevisionRequestItems={setRevisionRequestItemsVM}
                  />
                )}
            </VStack>
          </Container>
          <CommonNextButton
            label={t("btn.修正を依頼するボタン")}
            onClick={onOpen}
          />
        </VStack>
        <RevisionRequestCommentList
          revisionMode={"office_member_editable"}
          revisionRequestItems={revisionRequestItemsVM}
          selectedCommentPath={selectedCommentPath}
          scrollableRef={scrollableRef}
          scrollOffset={60}
        />
      </HStack>
      <ConfirmationModal
        isOpen={isOpen}
        message={t("mes.修正依頼確認メッセージ")}
        onSubmit={handleConfirm}
        onCancel={onClose}
      />
    </>
  );
};
