import React, { memo, useCallback, useMemo } from "react";
import { RequestingCellFormContent } from "../../content/RequestingCellFormContent/RequestingCellFormContent";
import { RequestingCellViewModel } from "../../../ApplicationDocument";
import { RequestingCell } from "../../../../../../../../../lib/object/value/requesting-cell";
import { CMButtonFormCommentProps } from "@pscsrvlab/psc-react-components/src/components/form/comment";
import { CellType } from "../../../../../../../../../lib/object/value/cell-type";
import { FormCard } from "../../../../../../../../ui/form/FormCard/FormCard";
import { useSectionDiffMode } from "../../../../../../../../../hooks/document/change-application/use-section-diff-mode";
import { useAppTranslation } from "../../../../../../../../../hooks/use-app-translation";
import { Comments } from "@pscsrvlab/psc-react-components";

export type RequestingCellCardProps = {
  documentType:
  | "new_application"
  | "change_application"
  | "project_force_update"
  | "project_force_create"
  | "project_content";
  editMode: "editable" | "readOnly";

  mode: "none" | "added" | "deleted";

  cellType: CellType;

  index: number;
  value: RequestingCellViewModel;

  onChange: (
    cellType: CellType,
    key: number,
    change: (before: RequestingCellViewModel) => RequestingCellViewModel,
  ) => void;
  onDelete: (key: number) => void;

  changedFrom?: RequestingCell;

  commentButtonProps?: CMButtonFormCommentProps;

  /**
   * 1セクション単位の修正依頼コメント・返信。履歴参照画面で使用。
   */
  commentsList?: Comments[];
};
export const RequestingCellCard = memo(function RequestingCellCard({
  documentType,
  editMode,

  mode,

  cellType,
  index,
  value,

  onChange,
  onDelete,

  changedFrom,

  commentButtonProps,

  commentsList,

}: RequestingCellCardProps) {
  const { t } = useAppTranslation();

  const cardTitle = useMemo(() => {
    const numberText = (index + 1).toString(10);
    switch (cellType) {
      case "research_purpose":
        return t("lbl.研究用株") + numberText;
      case "clinical_purpose":
        return t("lbl.臨床用株") + numberText;
    }
  }, [index, cellType, t]);
  const _editMode: "readOnly" | "editable" = useMemo(() => {
    if (mode === "deleted") return "readOnly";
    return editMode;
  }, [editMode, mode]);

  const handleChange = useCallback(
    (change: (before: RequestingCellViewModel) => RequestingCellViewModel) =>
      onChange(cellType, value.key, change),
    [cellType, onChange, value.key],
  );
  const handleDelete = useCallback(
    () => onDelete(value.key),
    [onDelete, value.key],
  );

  const sectionDiffModeOverwrite = useMemo(() => {
    return mode === "none" ? undefined : mode;
  }, [mode]);
  const sectionDiffMode = useSectionDiffMode(
    documentType,
    changedFrom,
    value,
    undefined,
    sectionDiffModeOverwrite,
  );

  return (
    <FormCard
      editMode={_editMode}
      title={cardTitle}
      onDelete={handleDelete}
      displayMode={sectionDiffMode}
      commentButtonProps={commentButtonProps}
      commentsList={commentsList}
    >
      <RequestingCellFormContent
        documentType={documentType}
        editMode={_editMode}
        cellType={cellType}
        value={value}
        onChange={handleChange}
        changedFrom={changedFrom}
      />
    </FormCard>
  );
});
