import { Box, Button } from "@chakra-ui/react";
import React, { ReactNode, useMemo } from "react";
import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";
import { hasValue, isNullish } from "../../../../../../lib/util/common-util";
import { Icon } from "@chakra-ui/icons";
import { IconType } from "react-icons";
import { useLocation } from "react-router-dom";

export type ProjectMenuItemProps = {
  path?: string;
  leftIcon?: IconType;
  rightIcon?: IconType;
  onClick?: (path?: string) => void;
  children?: ReactNode;
} & ComponentStyleProps;

/**
 * 案件メニューで使用するメニュー項目
 */
export const ProjectMenuItem = ({
  path,
  leftIcon,
  rightIcon,
  onClick,
  sx,
  children,
  ...rest
}: ProjectMenuItemProps) => {
  // 現在のパス
  const currentPath = useLocation().pathname;

  // メニューが選択中かどうか
  const isSelected = useMemo(() => {
    // パスが指定されていない場合は未選択とする
    if (isNullish(path)) {
      return false;
    }
    // 指定されたパスが現在のパスに対して前方一致するかどうかで判断する
    return RegExp(`^${path}.*`).test(currentPath);
  }, [path, currentPath]);

  return (
    <Button
      onClick={() => onClick?.(path)}
      w={"100%"}
      h={"40px"}
      leftIcon={
        hasValue(leftIcon) ? <Icon as={leftIcon} boxSize={"16px"} /> : undefined
      }
      rightIcon={
        hasValue(rightIcon) ? (
          <Icon as={rightIcon} boxSize={"16px"} />
        ) : undefined
      }
      justifyContent={"start"}
      textAlign={"left"}
      borderRadius={"4px"}
      fontSize={"14px"}
      fontWeight={isSelected ? 700 : 400}
      color={isSelected ? "blue.700" : "gray.600"}
      bgColor={isSelected ? "blue.50" : "inherit"}
      _hover={{
        bgColor: "blue.50",
        color: "blue.700",
        fontWeight: 700,
      }}
      sx={sx}
      {...rest}
    >
      <Box>{children}</Box>
    </Button>
  );
};
