import { Divider, Text, VStack } from "@chakra-ui/react";
import { ProgressStepDocumentRevision } from "../../../ui/progress/ProgressStepDocumentRevision/ProgressStepDocumentRevision";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { CalloutBox } from "../../../ui/text/CalloutBox/CalloutBox";
import { CMButton } from "@pscsrvlab/psc-react-components";
import { useAppParams } from "../../../../hooks/use-app-params";
import { hasValue, isNullish } from "../../../../lib/util/common-util";
import { useAppTranslation } from "../../../../hooks/use-app-translation";
import { useProjectPagePathPrefix } from "../../../../hooks/use-project-page-path-prefix";

export const FN40S29ReviseComplete = () => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const { projectPagePathPrefix } = useProjectPagePathPrefix();
  const { documentId } = useAppParams();

  const handleClickBack = useCallback(() => {
    if (isNullish(documentId)) return;
    navigate(`${projectPagePathPrefix}/document/${documentId}/content`);
  }, [documentId, navigate, projectPagePathPrefix]);

  return (
    <VStack
      flex={"1 1 auto"}
      minH={0}
      pb={"50px"}
      overflow={"auto"}
      spacing={"20px"}
    >
      <ProgressStepDocumentRevision currentStep={2} />
      <Text color={"gray.900"} fontSize={"2xl"} fontWeight={"bold"}>
        {t("mes.修正提出完了メッセージ")}
      </Text>
      <Divider />
      <VStack spacing={"20px"}>
        <CalloutBox>
          <Text>{t("gdc.修正提出完了お願い1")}</Text>
          <Text>{t("gdc.修正提出完了お願い2")}</Text>
        </CalloutBox>
        {hasValue(documentId) && (
          <CMButton
            label={t("btn.書類内容に戻るボタン")}
            onClick={handleClickBack}
          />
        )}
      </VStack>
    </VStack>
  );
};
