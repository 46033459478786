import {
  messageId,
  PropertyMeta,
  EntityMeta,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { reviewConferenceIdMeta } from "../value/review-conference-id";
import { reviewConferenceNameMeta } from "../value/review-conference-name";
import { yearMonthDayMeta } from "../value/year-month-day";
import { datetimeMeta } from "../value/datetime";
import { venueMeta } from "../value/venue";
import { reviewConferenceAgendaMeta } from "../value/review-conference-agenda";
import { attachmentFileInformationMeta } from "../value/attachment-file-information";
import { freeTextMeta } from "../value/free-text";
import { reviewTargetMeta } from "../value/review-target";
import { reviewConferenceNotificationStateMeta } from "../value/review-conference-notification-state";
import { reviewConferenceStateMeta } from "../value/review-conference-state";
import { creationMetadataMeta } from "../value/creation-metadata";
import { updateMetadataMeta } from "../value/update-metadata";
/**
 * 審査会(エンティティ)
 *
 *  */
export type ReviewConference = z.infer<typeof schema>;
export type ReviewConferenceSaved = z.infer<typeof savedSchema>;
const minimalSchema = z.object({
  id: reviewConferenceIdMeta.minimalSchema.optional(),
  reviewConferenceName: reviewConferenceNameMeta.minimalSchema,
  reviewConferenceDate: yearMonthDayMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  startDatetime: datetimeMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  endDatetime: datetimeMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  venue: venueMeta.minimalSchema,
  agenda: reviewConferenceAgendaMeta.minimalSchema,
  attachmentFiles: z.array(attachmentFileInformationMeta.minimalSchema),
  mailPostscript: freeTextMeta.minimalSchema,
  officeMemberMemo: freeTextMeta.minimalSchema,
  reviewTarget: z.array(reviewTargetMeta.minimalSchema),
  reviewConferenceNotificationDatetime: datetimeMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  reviewConferenceNotificationState:
    reviewConferenceNotificationStateMeta.minimalSchema,
  reviewConferenceState: reviewConferenceStateMeta.minimalSchema,
  created: creationMetadataMeta.minimalSchema,
  updated: updateMetadataMeta.minimalSchema,
});
const savedMinimalSchema = z.object({
  id: reviewConferenceIdMeta.minimalSchema,
  reviewConferenceName: reviewConferenceNameMeta.savedMinimalSchema,
  reviewConferenceDate: yearMonthDayMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  startDatetime: datetimeMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  endDatetime: datetimeMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  venue: venueMeta.savedMinimalSchema,
  agenda: reviewConferenceAgendaMeta.savedMinimalSchema,
  attachmentFiles: z.array(attachmentFileInformationMeta.savedMinimalSchema),
  mailPostscript: freeTextMeta.savedMinimalSchema,
  officeMemberMemo: freeTextMeta.savedMinimalSchema,
  reviewTarget: z.array(reviewTargetMeta.savedMinimalSchema),
  reviewConferenceNotificationDatetime: datetimeMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  reviewConferenceNotificationState:
    reviewConferenceNotificationStateMeta.savedMinimalSchema,
  reviewConferenceState: reviewConferenceStateMeta.savedMinimalSchema,
  created: creationMetadataMeta.savedMinimalSchema,
  updated: updateMetadataMeta.savedMinimalSchema,
});
const schema = z.object({
  id: reviewConferenceIdMeta.minimalSchema.optional(),
  reviewConferenceName: reviewConferenceNameMeta.schema,
  reviewConferenceDate: yearMonthDayMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
  startDatetime: datetimeMeta.schema.nullish().transform((x) => x ?? undefined),
  endDatetime: datetimeMeta.schema.nullish().transform((x) => x ?? undefined),
  venue: venueMeta.schema,
  agenda: reviewConferenceAgendaMeta.schema,
  attachmentFiles: z.array(attachmentFileInformationMeta.schema),
  mailPostscript: freeTextMeta.schema,
  officeMemberMemo: freeTextMeta.schema,
  reviewTarget: z.array(reviewTargetMeta.schema),
  reviewConferenceNotificationDatetime: datetimeMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
  reviewConferenceNotificationState:
    reviewConferenceNotificationStateMeta.schema,
  reviewConferenceState: reviewConferenceStateMeta.schema,
  created: creationMetadataMeta.schema,
  updated: updateMetadataMeta.schema,
});
const savedSchema = z.object({
  id: reviewConferenceIdMeta.minimalSchema,
  reviewConferenceName: reviewConferenceNameMeta.savedSchema,
  reviewConferenceDate: yearMonthDayMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  startDatetime: datetimeMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  endDatetime: datetimeMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  venue: venueMeta.savedSchema,
  agenda: reviewConferenceAgendaMeta.savedSchema,
  attachmentFiles: z.array(attachmentFileInformationMeta.savedSchema),
  mailPostscript: freeTextMeta.savedSchema,
  officeMemberMemo: freeTextMeta.savedSchema,
  reviewTarget: z.array(reviewTargetMeta.savedSchema),
  reviewConferenceNotificationDatetime: datetimeMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  reviewConferenceNotificationState:
    reviewConferenceNotificationStateMeta.savedSchema,
  reviewConferenceState: reviewConferenceStateMeta.savedSchema,
  created: creationMetadataMeta.savedSchema,
  updated: updateMetadataMeta.savedSchema,
});
const jsonSchema = z.object({
  id: reviewConferenceIdMeta.jsonSchema.optional(),
  reviewConferenceName: reviewConferenceNameMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  reviewConferenceDate: yearMonthDayMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  startDatetime: datetimeMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  endDatetime: datetimeMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  venue: venueMeta.jsonSchema.nullish().transform((x) => x ?? undefined),
  agenda: reviewConferenceAgendaMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  attachmentFiles: z
    .array(attachmentFileInformationMeta.jsonSchema)
    .nullish()
    .transform((x) => x ?? undefined),
  mailPostscript: freeTextMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  officeMemberMemo: freeTextMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  reviewTarget: z
    .array(reviewTargetMeta.jsonSchema)
    .nullish()
    .transform((x) => x ?? undefined),
  reviewConferenceNotificationDatetime: datetimeMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  reviewConferenceNotificationState:
    reviewConferenceNotificationStateMeta.jsonSchema
      .nullish()
      .transform((x) => x ?? undefined),
  reviewConferenceState: reviewConferenceStateMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  created: creationMetadataMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  updated: updateMetadataMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
/**
 * 審査会(エンティティ)のメタデータ。
 *
 *  */
export const reviewConferenceMeta = new EntityMeta<
  typeof schema,
  typeof savedSchema,
  typeof jsonSchema
>(
  "ReviewConference",
  "\u5BE9\u67FB\u4F1A",
  messageId("lbl.\u5BE9\u67FB\u4F1A"),
  minimalSchema,
  savedMinimalSchema,
  schema,
  savedSchema,
  jsonSchema,
  undefined,
  undefined,
  {
    id: new PropertyMeta(
      "id",
      "ID",
      messageId("lbl.ID"),
      "afterSave",
      false,
      reviewConferenceIdMeta
    ),
    reviewConferenceName: new PropertyMeta(
      "reviewConferenceName",
      "\u5BE9\u67FB\u4F1A\u540D",
      messageId("lbl.\u5BE9\u67FB\u4F1A\u540D"),
      "always",
      false,
      reviewConferenceNameMeta
    ),
    reviewConferenceDate: new PropertyMeta(
      "reviewConferenceDate",
      "\u958B\u50AC\u65E5",
      messageId("lbl.\u958B\u50AC\u65E5"),
      "none",
      false,
      yearMonthDayMeta
    ),
    startDatetime: new PropertyMeta(
      "startDatetime",
      "\u958B\u50AC\u958B\u59CB\u6642\u523B",
      messageId("lbl.\u958B\u50AC\u958B\u59CB\u6642\u523B"),
      "none",
      false,
      datetimeMeta
    ),
    endDatetime: new PropertyMeta(
      "endDatetime",
      "\u958B\u50AC\u7D42\u4E86\u6642\u523B",
      messageId("lbl.\u958B\u50AC\u7D42\u4E86\u6642\u523B"),
      "none",
      false,
      datetimeMeta
    ),
    venue: new PropertyMeta(
      "venue",
      "\u958B\u50AC\u5834\u6240",
      messageId("lbl.\u958B\u50AC\u5834\u6240"),
      "always",
      false,
      venueMeta
    ),
    agenda: new PropertyMeta(
      "agenda",
      "\u5BE9\u67FB\u4F1A\u30A2\u30B8\u30A7\u30F3\u30C0",
      messageId("lbl.\u5BE9\u67FB\u4F1A\u30A2\u30B8\u30A7\u30F3\u30C0"),
      "always",
      false,
      reviewConferenceAgendaMeta
    ),
    attachmentFiles: new PropertyMeta(
      "attachmentFiles",
      "\u5BE9\u67FB\u4F1A\u6DFB\u4ED8\u8CC7\u6599",
      messageId("lbl.\u5BE9\u67FB\u4F1A\u6DFB\u4ED8\u8CC7\u6599"),
      "always",
      true,
      attachmentFileInformationMeta
    ),
    mailPostscript: new PropertyMeta(
      "mailPostscript",
      "\u30E1\u30FC\u30EB\u7528\u8FFD\u8A18",
      messageId("lbl.\u30E1\u30FC\u30EB\u7528\u8FFD\u8A18"),
      "always",
      false,
      freeTextMeta
    ),
    officeMemberMemo: new PropertyMeta(
      "officeMemberMemo",
      "\u5BE9\u67FB\u4F1A\u4E8B\u52D9\u5C40\u7528\u30E1\u30E2\u6B04",
      messageId(
        "lbl.\u5BE9\u67FB\u4F1A\u4E8B\u52D9\u5C40\u7528\u30E1\u30E2\u6B04"
      ),
      "always",
      false,
      freeTextMeta
    ),
    reviewTarget: new PropertyMeta(
      "reviewTarget",
      "\u5BE9\u67FB\u5BFE\u8C61",
      messageId("lbl.\u5BE9\u67FB\u5BFE\u8C61"),
      "always",
      true,
      reviewTargetMeta
    ),
    reviewConferenceNotificationDatetime: new PropertyMeta(
      "reviewConferenceNotificationDatetime",
      "\u5BE9\u67FB\u4F1A\u5185\u5BB9\u901A\u77E5\u65E5\u6642",
      messageId("lbl.\u5BE9\u67FB\u4F1A\u5185\u5BB9\u901A\u77E5\u65E5\u6642"),
      "none",
      false,
      datetimeMeta
    ),
    reviewConferenceNotificationState: new PropertyMeta(
      "reviewConferenceNotificationState",
      "\u5BE9\u67FB\u4F1A\u5185\u5BB9\u901A\u77E5\u72B6\u6CC1",
      messageId("lbl.\u5BE9\u67FB\u4F1A\u5185\u5BB9\u901A\u77E5\u72B6\u6CC1"),
      "always",
      false,
      reviewConferenceNotificationStateMeta
    ),
    reviewConferenceState: new PropertyMeta(
      "reviewConferenceState",
      "\u5BE9\u67FB\u4F1A\u958B\u50AC\u72B6\u6CC1",
      messageId("lbl.\u5BE9\u67FB\u4F1A\u958B\u50AC\u72B6\u6CC1"),
      "always",
      false,
      reviewConferenceStateMeta
    ),
    created: new PropertyMeta(
      "created",
      "\u4F5C\u6210",
      messageId("lbl.\u4F5C\u6210"),
      "always",
      false,
      creationMetadataMeta
    ),
    updated: new PropertyMeta(
      "updated",
      "\u66F4\u65B0",
      messageId("lbl.\u66F4\u65B0"),
      "always",
      false,
      updateMetadataMeta
    ),
  }
);
