import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * 細胞提供区分(値オブジェクト)
 *
 *
 *
 * from_foundation:
 * own:
 * from_others:  */
export type CellProvisionType = z.infer<typeof cellProvisionTypeMeta.schema>;
/**
 * 細胞提供区分(値オブジェクト)のメタデータ。
 *
 *  */
export const cellProvisionTypeMeta = new ValueObjectMetaEnum<
  ["from_foundation", "own", "from_others"]
>(
  "CellProvisionType",
  "\u7D30\u80DE\u63D0\u4F9B\u533A\u5206",
  messageId("lbl.\u7D30\u80DE\u63D0\u4F9B\u533A\u5206"),
  ["from_foundation", "own", "from_others"],
  {
    from_foundation:
      "code.\u7D30\u80DE\u63D0\u4F9B\u533A\u5206.iPS\u8CA1\u56E3\u304B\u3089\u306E\u63D0\u4F9B\u3092\u5E0C\u671B\u3059\u308B",
    own: "code.\u7D30\u80DE\u63D0\u4F9B\u533A\u5206.\u81EA\u6A5F\u95A2\u3067\u4F7F\u7528\u3057\u3066\u3044\u308B\u7D30\u80DE\u682A\u3092\u4F7F\u7528",
    from_others:
      "code.\u7D30\u80DE\u63D0\u4F9B\u533A\u5206.\u4ED6\u6A5F\u95A2\u3067\u4F7F\u7528\u3057\u3066\u3044\u308B\u7D30\u80DE\u682A\u306E\u63D0\u4F9B\u3092\u53D7\u3051\u3001\u4F7F\u7528\u3059\u308B",
  }
);
