import { Flex } from "@chakra-ui/react";
import { useMemo } from "react";
import { hasValue, isNullish } from "../../../../lib/util/common-util";
import { ReviewConferenceContent } from "./_compornents/ReviewConferenceContent/ReviewConferenceContent";
import { ReviewConference } from "../../../../lib/object/entity/review-conference";
import { CreationMetadata } from "../../../../lib/object/value/creation-metadata";
import { UpdateMetadata } from "../../../../lib/object/value/update-metadata";
import { ReviewTarget } from "../../../../lib/object/value/review-target";
import { useAppGetReviewConferenceQuery } from "../../../../hooks/query/use-app-get-review-conference-query";

export type ReviewConferencePageProps = {
  displayMode: "create" | "view-or-edit";

  reviewConferenceId?: number;
};

/**
 * FN50S02 審査会詳細
 * メインエリアを表示
 */
export const ReviewConferencePage = ({
  displayMode,
  reviewConferenceId,
}: ReviewConferencePageProps) => {
  const { data: reviewConference } = useAppGetReviewConferenceQuery(
    {
      reviewConferenceId: reviewConferenceId ?? -1,
    },
    { skip: isNullish(reviewConferenceId) },
  );

  /**
   * 画面表示の初期値
   */
  const reviewConferenceData: ReviewConferenceViewModel | null = useMemo(() => {
    // 新規作成モードの場合は空のデータを作成
    if (displayMode === "create") {
      return emptyViewModel();
    }
    return reviewConference;
  }, [displayMode, reviewConference]);

  /**
   * 編集モード
   */
  const editMode = useMemo(() => {
    if (displayMode === "create") {
      return "editable";
    }
    return "readOnly";
  }, [displayMode]);

  return (
    <>
      {hasValue(reviewConferenceData) && (
        <Flex flex={1} flexDirection={"column"} overflow={"hidden"}>
          <ReviewConferenceContent
            displayMode={displayMode}
            initialEditMode={editMode}
            initialReviewConferenceData={reviewConferenceData}
          />
        </Flex>
      )}
    </>
  );
};

export type ReviewConferenceViewModel = Omit<
  ReviewConference,
  "reviewTarget" | "created" | "updated"
> & {
  reviewTarget: Partial<ReviewTarget>[];
  created?: CreationMetadata;
  updated?: UpdateMetadata;
};

function emptyViewModel(): ReviewConferenceViewModel {
  return {
    reviewConferenceName: "",
    venue: "",
    agenda: "",
    attachmentFiles: [],
    mailPostscript: "",
    officeMemberMemo: "",
    reviewTarget: [],
    reviewConferenceNotificationState: "not_notified",
    reviewConferenceState: "unheld",
  };
}
