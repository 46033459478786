import { HStack, Text, VStack } from "@chakra-ui/react";
import { useAppParams } from "../../../../hooks/use-app-params";
import { useSavedDocument } from "../../../../hooks/document/use-saved-document";
import { useCallback } from "react";
import { hasValue, isNullish } from "../../../../lib/util/common-util";
import {
  CMButton,
  CMFormInputDate,
  CMFormInputText,
} from "@pscsrvlab/psc-react-components";
import { useNavigate } from "react-router-dom";
import { documentControlNumberMeta } from "../../../../lib/object/value/document-control-number";
import { useProjectPagePathPrefix } from "../../../../hooks/use-project-page-path-prefix";
import { useAppTranslation } from "../../../../hooks/use-app-translation";

export const FN40S15DocumentContentReceived = () => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();

  const { documentId } = useAppParams();
  const { document } = useSavedDocument(documentId);

  const { projectPagePathPrefix } = useProjectPagePathPrefix();
  const handleClickBack = useCallback(() => {
    if (isNullish(documentId)) return;
    navigate(`${projectPagePathPrefix}/document/${documentId}/content`);
  }, [projectPagePathPrefix, documentId, navigate]);

  return (
    <VStack mt={"50px"}>
      <Text color={"gray.900"} fontSize={"2xl"} fontWeight={"bold"}>
        {t("mes.受付完了メッセージ")}
      </Text>
      {hasValue(document) &&
        hasValue(document.documentControlNumber) &&
        hasValue(document.receptionDate) && (
          <>
            <Text color={"gray.900"} size={"sm"} whiteSpace={"pre-wrap"}>
              {t("gdc.受付完了案内", {
                書類管理番号: document.documentControlNumber,
              })}
            </Text>
            <HStack>
              <CMFormInputText
                label={t("lbl.書類管理番号")}
                valueObjectMeta={documentControlNumberMeta}
                editMode={"readOnly"}
                value={document.documentControlNumber}
                sx={{ w: "150px" }}
              />
              <CMFormInputDate
                label={t("lbl.受付日")}
                editMode={"readOnly"}
                value={document.receptionDate}
                sx={{ w: "150px" }}
              />
            </HStack>
            <CMButton
              label={t("btn.書類内容に戻るボタン")}
              onClick={handleClickBack}
            />
          </>
        )}
    </VStack>
  );
};
