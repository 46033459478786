import { ErrorMessageObject, messageId } from "@pscsrvlab/psc-react-components";
import { SignatureValue } from "../value/signature-value";
import { isNullish } from "../../util/common-util";

export function signatureValueCustomValidation(
  value: SignatureValue,
  skipOptional: boolean,
): ErrorMessageObject[] {
  if (skipOptional) return [];

  const errorMessageList: ErrorMessageObject[] = [];

  if (value.correctnessCheck !== "checked" || isNullish(value.checkDate))
    errorMessageList.push({
      errorMessageId: messageId("mes.事実関係チェック必須エラーメッセージ"),
    });
  return errorMessageList;
}
