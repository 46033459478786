import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * 移行データ区分(値オブジェクト)
 *
 *
 *
 * migration_data:
 * normal_data:  */
export type MigrationDataType = z.infer<typeof migrationDataTypeMeta.schema>;
/**
 * 移行データ区分(値オブジェクト)のメタデータ。
 *
 *  */
export const migrationDataTypeMeta = new ValueObjectMetaEnum<
  ["migration_data", "normal_data"]
>(
  "MigrationDataType",
  "\u79FB\u884C\u30C7\u30FC\u30BF\u533A\u5206",
  messageId("lbl.\u79FB\u884C\u30C7\u30FC\u30BF\u533A\u5206"),
  ["migration_data", "normal_data"],
  {
    migration_data:
      "code.\u79FB\u884C\u30C7\u30FC\u30BF\u533A\u5206.\u79FB\u884C\u30C7\u30FC\u30BF",
    normal_data:
      "code.\u79FB\u884C\u30C7\u30FC\u30BF\u533A\u5206.\u901A\u5E38\u30C7\u30FC\u30BF",
  }
);
