import {
  messageId,
  PropertyMeta,
  ValueObjectMetaComposite,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { contractPersonMeta } from "../value/contract-person";
import { provisionFeeRequiredMeta } from "../value/provision-fee-required";
import { reviewFeeRequiredMeta } from "../value/review-fee-required";
import { freeTextMeta } from "../value/free-text";
import { patentLicenseExplanationStateMeta } from "../value/patent-license-explanation-state";
/**
 * 申請書類事務局記入情報(値オブジェクト)
 *
 *  */
export type ApplicationOfficeMemberNotes = z.infer<typeof schema>;
export type ApplicationOfficeMemberNotesSaved = z.infer<typeof savedSchema>;
const minimalSchema = z.object({
  contractPerson: contractPersonMeta.minimalSchema,
  provisionFeeRequired: provisionFeeRequiredMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  reviewFeeRequired: reviewFeeRequiredMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  invoiceIssuanceMemoForReviewFee: freeTextMeta.minimalSchema,
  explanationOfStockMaintenanceFee: freeTextMeta.minimalSchema,
  status: freeTextMeta.minimalSchema,
  patentLicenseExplanationStatus:
    patentLicenseExplanationStateMeta.minimalSchema,
  securityExportControl: freeTextMeta.minimalSchema,
  officeMemberNote: freeTextMeta.minimalSchema,
});
const savedMinimalSchema = z.object({
  contractPerson: contractPersonMeta.savedMinimalSchema,
  provisionFeeRequired: provisionFeeRequiredMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  reviewFeeRequired: reviewFeeRequiredMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  invoiceIssuanceMemoForReviewFee: freeTextMeta.savedMinimalSchema,
  explanationOfStockMaintenanceFee: freeTextMeta.savedMinimalSchema,
  status: freeTextMeta.savedMinimalSchema,
  patentLicenseExplanationStatus:
    patentLicenseExplanationStateMeta.savedMinimalSchema,
  securityExportControl: freeTextMeta.savedMinimalSchema,
  officeMemberNote: freeTextMeta.savedMinimalSchema,
});
const schema = z.object({
  contractPerson: contractPersonMeta.schema,
  provisionFeeRequired: provisionFeeRequiredMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
  reviewFeeRequired: reviewFeeRequiredMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
  invoiceIssuanceMemoForReviewFee: freeTextMeta.schema,
  explanationOfStockMaintenanceFee: freeTextMeta.schema,
  status: freeTextMeta.schema,
  patentLicenseExplanationStatus: patentLicenseExplanationStateMeta.schema,
  securityExportControl: freeTextMeta.schema,
  officeMemberNote: freeTextMeta.schema,
});
const savedSchema = z.object({
  contractPerson: contractPersonMeta.savedSchema,
  provisionFeeRequired: provisionFeeRequiredMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  reviewFeeRequired: reviewFeeRequiredMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  invoiceIssuanceMemoForReviewFee: freeTextMeta.savedSchema,
  explanationOfStockMaintenanceFee: freeTextMeta.savedSchema,
  status: freeTextMeta.savedSchema,
  patentLicenseExplanationStatus: patentLicenseExplanationStateMeta.savedSchema,
  securityExportControl: freeTextMeta.savedSchema,
  officeMemberNote: freeTextMeta.savedSchema,
});
const jsonSchema = z.object({
  contractPerson: contractPersonMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  provisionFeeRequired: provisionFeeRequiredMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  reviewFeeRequired: reviewFeeRequiredMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  invoiceIssuanceMemoForReviewFee: freeTextMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  explanationOfStockMaintenanceFee: freeTextMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  status: freeTextMeta.jsonSchema.nullish().transform((x) => x ?? undefined),
  patentLicenseExplanationStatus: patentLicenseExplanationStateMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  securityExportControl: freeTextMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  officeMemberNote: freeTextMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
/**
 * 申請書類事務局記入情報(値オブジェクト)のメタデータ。
 *
 *  */
export const applicationOfficeMemberNotesMeta = new ValueObjectMetaComposite(
  "ApplicationOfficeMemberNotes",
  "\u7533\u8ACB\u66F8\u985E\u4E8B\u52D9\u5C40\u8A18\u5165\u60C5\u5831",
  messageId(
    "lbl.\u7533\u8ACB\u66F8\u985E\u4E8B\u52D9\u5C40\u8A18\u5165\u60C5\u5831"
  ),
  minimalSchema,
  savedMinimalSchema,
  schema,
  savedSchema,
  jsonSchema,
  undefined,
  undefined,
  {
    contractPerson: new PropertyMeta(
      "contractPerson",
      "\u5951\u7D04\u62C5\u5F53",
      messageId("lbl.\u5951\u7D04\u62C5\u5F53"),
      "always",
      false,
      contractPersonMeta
    ),
    provisionFeeRequired: new PropertyMeta(
      "provisionFeeRequired",
      "\u63D0\u4F9B\u4EE3\u8981\u5426",
      messageId("lbl.\u63D0\u4F9B\u4EE3\u8981\u5426"),
      "none",
      false,
      provisionFeeRequiredMeta
    ),
    reviewFeeRequired: new PropertyMeta(
      "reviewFeeRequired",
      "\u5BE9\u67FB\u6599\u8981\u5426",
      messageId("lbl.\u5BE9\u67FB\u6599\u8981\u5426"),
      "none",
      false,
      reviewFeeRequiredMeta
    ),
    invoiceIssuanceMemoForReviewFee: new PropertyMeta(
      "invoiceIssuanceMemoForReviewFee",
      "\u5BE9\u67FB\u6599\u306E\u8ACB\u6C42\u66F8\u767A\u884C\u30E1\u30E2",
      messageId(
        "lbl.\u5BE9\u67FB\u6599\u306E\u8ACB\u6C42\u66F8\u767A\u884C\u30E1\u30E2"
      ),
      "always",
      false,
      freeTextMeta
    ),
    explanationOfStockMaintenanceFee: new PropertyMeta(
      "explanationOfStockMaintenanceFee",
      "\u30B9\u30C8\u30C3\u30AF\u7B49\u7DAD\u6301\u7BA1\u7406\u6599\u306E\u8AAC\u660E",
      messageId(
        "lbl.\u30B9\u30C8\u30C3\u30AF\u7B49\u7DAD\u6301\u7BA1\u7406\u6599\u306E\u8AAC\u660E"
      ),
      "always",
      false,
      freeTextMeta
    ),
    status: new PropertyMeta(
      "status",
      "\u72B6\u6CC1",
      messageId("lbl.\u72B6\u6CC1"),
      "always",
      false,
      freeTextMeta
    ),
    patentLicenseExplanationStatus: new PropertyMeta(
      "patentLicenseExplanationStatus",
      "\u7279\u8A31\u30E9\u30A4\u30BB\u30F3\u30B9\u8AAC\u660E\u72B6\u6CC1",
      messageId(
        "lbl.\u7279\u8A31\u30E9\u30A4\u30BB\u30F3\u30B9\u8AAC\u660E\u72B6\u6CC1"
      ),
      "always",
      false,
      patentLicenseExplanationStateMeta
    ),
    securityExportControl: new PropertyMeta(
      "securityExportControl",
      "\u5B89\u5168\u8F38\u51FA\u7BA1\u7406",
      messageId("lbl.\u5B89\u5168\u8F38\u51FA\u7BA1\u7406"),
      "always",
      false,
      freeTextMeta
    ),
    officeMemberNote: new PropertyMeta(
      "officeMemberNote",
      "\u4E8B\u52D9\u5C40\u30B3\u30E1\u30F3\u30C8",
      messageId("lbl.\u4E8B\u52D9\u5C40\u30B3\u30E1\u30F3\u30C8"),
      "always",
      false,
      freeTextMeta
    ),
  }
);
