import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";
import { SearchWorkLogApiArg } from "../../../../../../store/api/generated/stock-request-api";
import { SearchConditionSidebar } from "../../../../../ui/search/SearchConditionSidebar/SearchConditionSidebar";
import { SearchConditionSidebarSection } from "../../../../../ui/search/SearchConditionSidebar/_components/SearchConditionSidebarSection/SearchConditionSidebarSection";
import { ButtonSearchConditionRadio } from "../../../../search/button/ButtonSearchConditionRadio/ButtonSearchConditionRadio";
import {
  convertToNumberFromResultsC,
  convertToResultsCFromNumber,
  getDefaultPeriod,
} from "../../../../../../lib/util/search-util";
import { ButtonSearchConditionCheckbox } from "../../../../search/button/ButtonSearchConditionCheckbox/ButtonSearchConditionCheckbox";
import React, { useMemo } from "react";
import { workLogSortConditionMeta } from "../../../../../../lib/object/value/work-log-sort-condition";
import { numberOfSearchResultsCMeta } from "../../../../../../lib/object/value/number-of-search-results-c";
import { isoTextToDatetimeOrNull } from "../../../../../../lib/util/common-date-util";
import { ButtonSearchConditionUser } from "../../../../search/button/ButtonSearchConditionUser/ButtonSearchConditionUser";
import { ButtonSearchConditionProject } from "../../../../search/button/ButtonSearchConditionProject/ButtonSearchConditionProject";
import { workTypeMeta } from "../../../../../../lib/object/value/work-type";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { ButtonSearchConditionDocument } from "../../../../search/button/ButtonSearchConditionDocument/ButtonSearchConditionDocument";
import { ButtonSearchConditionDatalist } from "../../../../search/button/ButtonSearchConditionDatalist/ButtonSearchConditionDatalist";
import { operationTypeMeta } from "../../../../../../lib/object/value/operation-type";
import { ButtonSearchConditionDatetimeRange } from "../../../../search/button/ButtonSearchConditionDatetimeRange/ButtonSearchConditionDatetimeRange";
import { ButtonSearchConditionInstitution } from "../../../../search/button/ButtonSearchConditionInstitution/ButtonSearchConditionInstitution";

export type SearchConditionSidebarWorkLogProps = {
  type: "root" | "project" | "document";

  searchArgs: SearchWorkLogApiArg;

  /**
   * 検索条件が変更されたタイミングで呼び出し元に値を返却する
   */
  onChange: (searchArgs: SearchWorkLogApiArg) => void;
} & ComponentStyleProps;

export const SearchConditionSidebarWorkLog = ({
  type,

  searchArgs,

  onChange,

  sx,
  ...rest
}: SearchConditionSidebarWorkLogProps) => {
  const { t } = useAppTranslation();
  const defaultPeriod = useMemo(() => {
    const { defaultFromDatetimeValue, defaultToDatetimeValue } =
      getDefaultPeriod(30);
    return { start: defaultFromDatetimeValue, end: defaultToDatetimeValue };
  }, []);

  return (
    <SearchConditionSidebar sx={sx} {...rest}>
      <SearchConditionSidebarSection>
        <ButtonSearchConditionRadio
          value={searchArgs.sortCondition}
          label={t("lbl.並び順")}
          valueObjectMeta={workLogSortConditionMeta}
          onChange={(value) => {
            onChange({
              ...searchArgs,
              sortCondition: value,
            });
          }}
        />
        <ButtonSearchConditionRadio
          label={t("lbl.表示件数")}
          value={convertToResultsCFromNumber(searchArgs.limit)}
          valueObjectMeta={numberOfSearchResultsCMeta}
          onChange={(value) => {
            onChange({
              ...searchArgs,
              limit: convertToNumberFromResultsC(value),
            });
          }}
        />
        <ButtonSearchConditionDatetimeRange
          value={{
            start:
              isoTextToDatetimeOrNull(searchArgs.workDateTimeFrom) ?? undefined,
            end:
              isoTextToDatetimeOrNull(searchArgs.workDateTimeTo) ?? undefined,
          }}
          defaultValue={defaultPeriod}
          label={t("lbl.作業日時")}
          maxPeriodDays={30}
          onChange={(start: string | undefined, end: string | undefined) => {
            onChange({
              ...searchArgs,
              workDateTimeFrom: start,
              workDateTimeTo: end,
            });
          }}
        />
        <ButtonSearchConditionCheckbox
          values={searchArgs.workType}
          label={t("lbl.作業区分")}
          valueObjectMeta={workTypeMeta}
          onChange={(value) => {
            onChange({
              ...searchArgs,
              workType: value,
            });
          }}
        />
        <ButtonSearchConditionInstitution
          value={searchArgs.institutionId}
          label={t("lbl.機関名")}
          onChange={(value: number | null) => {
            onChange?.({
              ...searchArgs,
              institutionId: value ?? undefined,
            });
          }}
        />
        <ButtonSearchConditionUser
          value={searchArgs.appUserId}
          label={t("lbl.ユーザー名")}
          searchResultNone={t("mes.該当ユーザーなしメッセージ")}
          onChange={(value: number | null) => {
            onChange({
              ...searchArgs,
              appUserId: value ?? undefined,
            });
          }}
        />
        {type === "root" && (
          <ButtonSearchConditionProject
            value={searchArgs.projectId}
            label={t("lbl.案件管理番号")}
            searchResultNone={t("mes.該当案件なしメッセージ")}
            onChange={(value: number | null) => {
              onChange({
                ...searchArgs,
                projectId: value ?? undefined,
              });
            }}
          />
        )}
        {(type === "root" || type === "project") && (
          <ButtonSearchConditionDocument
            value={searchArgs.documentId}
            label={t("lbl.書類管理番号")}
            searchResultNone={t("mes.該当書類なしメッセージ")}
            onChange={(value: number | null) => {
              onChange({
                ...searchArgs,
                documentId: value ?? undefined,
              });
            }}
          />
        )}
        <ButtonSearchConditionDatalist
          label={t("lbl.オペレーション名")}
          value={searchArgs.operationType}
          valueObjectMeta={operationTypeMeta}
          onChange={(value) => {
            onChange({
              ...searchArgs,
              operationType: value,
            });
          }}
        />
      </SearchConditionSidebarSection>
    </SearchConditionSidebar>
  );
};
