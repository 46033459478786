import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * 作業ログ並び順(値オブジェクト)
 *
 *
 *
 * datetime_asc:
 * datetime_desc:  */
export type WorkLogSortCondition = z.infer<
  typeof workLogSortConditionMeta.schema
>;
/**
 * 作業ログ並び順(値オブジェクト)のメタデータ。
 *
 *  */
export const workLogSortConditionMeta = new ValueObjectMetaEnum<
  ["datetime_asc", "datetime_desc"]
>(
  "WorkLogSortCondition",
  "\u4F5C\u696D\u30ED\u30B0\u4E26\u3073\u9806",
  messageId("lbl.\u4F5C\u696D\u30ED\u30B0\u4E26\u3073\u9806"),
  ["datetime_asc", "datetime_desc"],
  {
    datetime_asc:
      "code.\u4E26\u3073\u9806.\u4F5C\u696D\u65E5\u6642\u6607\u9806",
    datetime_desc:
      "code.\u4E26\u3073\u9806.\u4F5C\u696D\u65E5\u6642\u964D\u9806",
  }
);
