import React, { memo } from "react";
import { CMButton } from "@pscsrvlab/psc-react-components";
import { ComponentStyleProps } from "../../../../lib/styles/props/component-style-props";
import { useAppTranslation } from "../../../../hooks/use-app-translation";

export type SaveChangesButtonProps = {
  onClick?: () => void;
} & ComponentStyleProps;
export const SaveChangesButton = memo(function SaveChangesButton({
  onClick,

  sx,
  ...rest
}: SaveChangesButtonProps) {
  const { t } = useAppTranslation();
  return (
    <CMButton
      size={"sm"}
      label={t("btn.変更を保存するボタン")}
      onClick={onClick}
      sx={sx}
      {...rest}
    />
  );
});
