import { ComponentStyleProps } from "../../../../../../../../lib/styles/props/component-style-props";
import { memo, Ref, useCallback, useMemo } from "react";
import { VStack } from "@chakra-ui/react";
import { RevisionRequestCommentListComment } from "../RevisionRequestCommentListComment/RevisionRequestCommentListComment";
import { isNullish } from "../../../../../../../../lib/util/common-util";
import { commentButtonIdOf } from "../../../../../../../../lib/util/app-util";
import { RevisionRequestItemViewModel } from "../../../../../../../../lib/object/vm/revision-request-view-model";
import { useAppTranslation } from "../../../../../../../../hooks/use-app-translation";
import { useScrollToId } from "../../../../../../../../hooks/use-scroll-to-id";

export type RevisionRequestCommentListThreadProps = {
  isApplicantEditable: boolean;

  item: RevisionRequestItemViewModel;
  onSaveReply?: (path: string, value: string | null) => void;

  selected?: boolean;

  onClick?: () => void;

  scrollableRef: Ref<any>;
  scrollOffset: number;
} & ComponentStyleProps;

export const RevisionRequestCommentListThread = memo(
  function RevisionRequestCommentListThread({
    isApplicantEditable,

    item,
    onSaveReply,

    selected,

    onClick,

    scrollableRef,
    scrollOffset,

    sx,
    ...rest
  }: RevisionRequestCommentListThreadProps) {
    const { t } = useAppTranslation();
    const { scrollToId } = useScrollToId(scrollableRef, scrollOffset);

    const officeMemberComment = useMemo(
      () => ({
        userName: t("lbl.事務局"),
        datetime: item.officeMemberComment.datetime,
        body: item.officeMemberComment.body,
      }),
      [item.officeMemberComment.body, item.officeMemberComment.datetime, t],
    );
    const applicantComment = useMemo(() => {
      if (isNullish(item.applicantComment)) return null;
      return {
        userName: item.applicantComment.commenter.fullName,
        datetime: item.applicantComment.datetime,
        body: item.applicantComment.body,
      };
    }, [item]);
    const bgColor = useMemo(() => {
      return selected ? "orange.100" : undefined;
    }, [selected]);

    const commentButtonId = useMemo(
      () => commentButtonIdOf(item.path),
      [item.path],
    );

    const handleClick = useCallback(() => {
      scrollToId(commentButtonId);
      onClick?.();
    }, [scrollToId, commentButtonId, onClick]);

    const handleSaveReply = useCallback(
      (value: string | null) => {
        onSaveReply?.(item.path, value);
      },
      [item.path, onSaveReply],
    );

    return (
      <VStack
        alignItems={"stretch"}
        py={"4px"}
        sx={sx}
        {...rest}
        bgColor={bgColor}
      >
        {/*<Link href={linkTo} sx={linkStyles}>*/}
        <VStack onClick={handleClick} alignItems={"stretch"}>
          <RevisionRequestCommentListComment
            editable={false}
            comment={officeMemberComment}
          />
        </VStack>
        {/*</Link>*/}
        <RevisionRequestCommentListComment
          editable={isApplicantEditable}
          onSave={handleSaveReply}
          comment={applicantComment}
          indent
        />
      </VStack>
    );
  },
);
