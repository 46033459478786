import { useAppGetReviewQuery } from "../query/use-app-get-review-query";
import { isNullish } from "../../lib/util/common-util";
import { useAppListReviewQuery } from "../query/use-app-list-review-query";
import { useMemo } from "react";

/**
 * 書類・審査種別が一致する審査のうちでの、連番（1始まり）を取得する。
 */
export function useReviewNumber(
  reviewId: number | null | undefined,
): number | undefined {
  const { data: review } = useAppGetReviewQuery(
    { reviewId: reviewId ?? -1 },
    { skip: isNullish(reviewId) },
  );
  const { data: reviews } = useAppListReviewQuery(
    {
      documentId: review?.documentId ?? -1,
    },
    { skip: isNullish(review?.documentId) },
  );
  return useMemo(() => {
    if (isNullish(review) || isNullish(reviews)) return undefined;

    const reviewType = review.reviewType;
    if (reviewType !== "office_review" && reviewType !== "committee_review")
      return undefined;

    // 並び順は、listで取得したそのままが正しいという前提。
    const reviewsOfType = reviews.filter((r) => r.reviewType === reviewType);
    const index = reviewsOfType.findIndex((r) => r.id === review.id);
    if (index === -1) return undefined;
    return index + 1;
  }, [review, reviews]);
}
