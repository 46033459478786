import { ReviewSaved } from "../../lib/object/entity/review";
import { useAppListReviewQuery } from "../query/use-app-list-review-query";
import { isNullish } from "../../lib/util/common-util";
import { useMemo } from "react";
import { maxBy, merge } from "lodash";
import { useReviewNumber } from "../review/use-review-number";

/**
 * 指定した書類が申請で、かつ最後の審査が実施済だった場合、
 * その審査を取得する。
 *
 * ついでに、審査番号(書類・審査種別ごとの連番)も取得する。
 */
export function useLatestReviewIfHeld(
  documentId: number,
  options?: {
    /**
     * skipがtrueの場合、何も行わない。
     */
    skip: boolean;
  },
): {
  reviewNumber?: number;
  latestReview?: Omit<ReviewSaved, "reviewType"> & {
    reviewType: "office_review" | "committee_review";
  };
} {
  const _options = useMemo(() => merge({ skip: false }, options), [options]);

  const { data: reviews } = useAppListReviewQuery(
    { documentId: documentId ?? -1 },
    {
      skip: _options.skip || isNullish(documentId),
    },
  );
  const latestReview: ReviewSaved | undefined = useMemo(() => {
    return maxBy(reviews, (r) => r.created.datetime);
  }, [reviews]);
  const reviewNumber = useReviewNumber(latestReview?.id);

  return useMemo(() => {
    if (
      isNullish(latestReview) ||
      latestReview.reviewState !== "held" ||
      isNullish(reviewNumber)
    )
      return {};
    const reviewType = latestReview.reviewType;
    if (reviewType === "unselected" || reviewType === "no_need_to_review")
      return {};
    return {
      reviewNumber,
      latestReview: { ...latestReview, reviewType },
    };
  }, [latestReview, reviewNumber]);
}
