import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * 分化細胞提供先有無(値オブジェクト)
 *
 *
 *
 * provided:
 * none:  */
export type DifferentiatedCellProvisionType = z.infer<
  typeof differentiatedCellProvisionTypeMeta.schema
>;
/**
 * 分化細胞提供先有無(値オブジェクト)のメタデータ。
 *
 *  */
export const differentiatedCellProvisionTypeMeta = new ValueObjectMetaEnum<
  ["provided", "none"]
>(
  "DifferentiatedCellProvisionType",
  "\u5206\u5316\u7D30\u80DE\u63D0\u4F9B\u5148\u6709\u7121",
  messageId("lbl.\u5206\u5316\u7D30\u80DE\u63D0\u4F9B\u5148\u6709\u7121"),
  ["provided", "none"],
  {
    provided:
      "code.\u5206\u5316\u7D30\u80DE\u63D0\u4F9B\u5148\u6709\u7121.\u6709",
    none: "code.\u5206\u5316\u7D30\u80DE\u63D0\u4F9B\u5148\u6709\u7121.\u7121",
  }
);
