import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * 言語(値オブジェクト)
 *
 *
 *
 * ja:
 * en:  */
export type Language = z.infer<typeof languageMeta.schema>;
/**
 * 言語(値オブジェクト)のメタデータ。
 *
 *  */
export const languageMeta = new ValueObjectMetaEnum<["ja", "en"]>(
  "Language",
  "\u8A00\u8A9E",
  messageId("lbl.\u8A00\u8A9E"),
  ["ja", "en"],
  {
    ja: "code.\u8A00\u8A9E.\u65E5\u672C\u8A9E",
    en: "code.\u8A00\u8A9E.\u82F1\u8A9E",
  }
);
