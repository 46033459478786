import { useGetDocumentQuery } from "../../store/api/generated/stock-request-api";
import { isNullish } from "../../lib/util/common-util";
import { useMemo } from "react";
import { documentMeta, DocumentSaved } from "../../lib/object/entity/document";

export function useAppGetDocumentQuery(
  arg: Parameters<typeof useGetDocumentQuery>[0],
  options?: Parameters<typeof useGetDocumentQuery>[1],
  skipOptionalValidations = true, // get系は、skipOptionalValidationsのデフォルトはtrueとする。
): {
  data: DocumentSaved | null;
  currentData: DocumentSaved | null;
  isLoading: ReturnType<typeof useGetDocumentQuery>["isLoading"];
  refetch: ReturnType<typeof useGetDocumentQuery>["refetch"];
  error: ReturnType<typeof useGetDocumentQuery>["error"];
} {
  const {
    data: rawData,
    currentData: rawCurrentData,
    isLoading,
    refetch,
    error,
  } = useGetDocumentQuery(arg, options);

  const data = useMemo(() => {
    if (isNullish(rawData)) return null;
    return documentMeta.toSavedDomainObjectOrNull(
      rawData,
      skipOptionalValidations,
    );
  }, [rawData, skipOptionalValidations]);
  const currentData = useMemo(() => {
    if (isNullish(rawCurrentData)) return null;
    return documentMeta.toSavedDomainObjectOrNull(
      rawCurrentData,
      skipOptionalValidations,
    );
  }, [rawCurrentData, skipOptionalValidations]);

  return { data, currentData, isLoading, refetch, error };
}
