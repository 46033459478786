import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";
import { ReviewConferenceSaved } from "../../../../../../lib/object/entity/review-conference";
import React, { memo, useEffect, useMemo, useState } from "react";
import { SearchResultTableContent } from "@pscsrvlab/psc-react-components/src/components/search/table/types";
import { TFunction } from "i18next";
import { SearchResultRowContent } from "@pscsrvlab/psc-react-components/src/components/search/row/types";
import {
  getMessageFromEnumValue,
  hasValue,
  isNullish,
} from "../../../../../../lib/util/common-util";
import { jsDateToDateText } from "../../../../../../lib/util/common-date-util";
import { reviewConferenceStateMeta } from "../../../../../../lib/object/value/review-conference-state";
import { reviewConferenceNotificationStateMeta } from "../../../../../../lib/object/value/review-conference-notification-state";
import { stockRequestApi } from "../../../../../../store/api/enhanced-api";
import { LazyQueryTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { QueryDefinition } from "@reduxjs/toolkit/query";
import {
  Document as DocumentJson,
  GetDocumentApiArg,
} from "../../../../../../store/api/generated/stock-request-api";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/dist/query/react";
import { documentMeta } from "../../../../../../lib/object/entity/document";
import { errorMessageOf } from "../../../../../../lib/util/error-util";
import log from "loglevel";
import { CMSearchResultTable } from "@pscsrvlab/psc-react-components";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";

export type SearchResultTableReviewConferenceProps = {
  entities: { value: ReviewConferenceSaved; todo: boolean }[];
} & ComponentStyleProps;

/**
 * 審査会検索結果テーブル。
 */
export const SearchResultTableReviewConference = memo(
  function SearchResultTableReviewConference({
    entities,

    sx,
    ...rest
  }: SearchResultTableReviewConferenceProps) {
    const { t } = useAppTranslation();
    const [triggerGetDocumentQuery] = stockRequestApi.useLazyGetDocumentQuery();

    const header = useMemo(() => {
      return {
        cells: [
          { value: t("lbl.審査会名"), width: "130px" },
          { value: t("lbl.TODO"), width: "60px" },
          { value: t("lbl.開催日"), width: "100px" },
          { value: t("lbl.開催状況"), width: "100px" },
          { value: t("lbl.通知状況"), width: "100px" },
          { value: t("lbl.審査対象"), width: "160px" },
          { value: t("lbl.通知日時"), width: "140px" },
          { value: t("lbl.更新日時"), width: "140px" },
        ],
      };
    }, [t]);

    const [tableContent, setTableContent] = useState<SearchResultTableContent>({
      header: { cells: [] },
      rows: [],
    });
    // テーブル表示用にデータを整形する
    useEffect(() => {
      (async () => {
        try {
          // 各項目ごとにデータを整形
          const rows: SearchResultRowContent[] = await createRows(
            entities,
            t,
            triggerGetDocumentQuery,
          );

          // テーブル表示に必要なデータを設定
          setTableContent({
            header,
            rows,
          });
        } catch (e) {
          log.error(errorMessageOf(e));
        }
      })();
    }, [entities, t, triggerGetDocumentQuery, header]);

    return (
      <CMSearchResultTable
        content={tableContent}
        stickyHeaderTop={"29px"}
        stickyFirstColLeft={"30px"}
        stickySecondColLeft={"160px"}
        sx={sx}
        {...rest}
      />
    );
  },
);

function createRows(
  entities: { value: ReviewConferenceSaved; todo: boolean }[],
  t: TFunction<"translation", undefined, "translation">,
  triggerGetDocumentQuery: LazyQueryTrigger<
    QueryDefinition<
      GetDocumentApiArg,
      BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, {}>,
      string,
      DocumentJson,
      "api"
    >
  >,
): Promise<SearchResultRowContent[]> {
  return Promise.all(
    entities.map(async ({ value: entity, todo }) => {
      return {
        cells: [
          {
            // 審査会名
            type: "react_router_link",
            value: {
              text: entity.reviewConferenceName,
              to: `/review-conference/${entity.id}`,
            },
          },
          {
            // ＴＯＤＯ (<-半角にしたらIDEに誤解されるので全角)
            type: "notification_badge",
            value: todo,
          },
          {
            // 開催日
            type: "text",
            value: hasValue(entity.startDatetime)
              ? jsDateToDateText(entity.startDatetime)
              : undefined,
          },
          {
            // 開催状況
            type: "text",
            value: getMessageFromEnumValue(
              t,
              reviewConferenceStateMeta,
              entity.reviewConferenceState,
            ),
          },
          {
            // 通知状況
            type: "text",
            value: getMessageFromEnumValue(
              t,
              reviewConferenceNotificationStateMeta,
              entity.reviewConferenceNotificationState,
            ),
          },
          {
            // 審査対象
            type: "react_router_link_list",
            value: (
              await Promise.all(
                entity.reviewTarget.map(async (v) => {
                  try {
                    const documentJson = await triggerGetDocumentQuery({
                      documentId: v.documentId,
                    }).unwrap();
                    const document = documentMeta.toSavedDomainObjectOrNull(
                      documentJson,
                      true,
                    );
                    if (isNullish(document)) {
                      log.error(
                        `documentMeta.toSavedDomainObjectOrNull failed.`,
                      );
                      return null;
                    }
                    return {
                      text: document.documentControlNumber,
                      to: `/document/${v.documentId}`,
                    };
                  } catch (e) {
                    log.error(errorMessageOf(e));
                    return null;
                  }
                }),
              )
            ).filter(hasValue),
          },
          {
            // 通知日時
            type: "datetime",
            value: entity.reviewConferenceNotificationDatetime,
          },
          {
            // 更新日時
            type: "datetime",
            value: entity.updated?.datetime,
          },
        ],
      };
    }),
  );
}
