import { RefObject, useEffect, useRef } from "react";

/**
 * マウントされたときに、返却したrefにフォーカスする。
 * @param enabled falseなら、何もしない。
 * @param ref フォーカスしたいref。もし指定しなければ、この関数内で作成する。
 * @return このrefを設定した要素に、マウント時にフォーカスする。
 */
export function useAutoFocus<T extends HTMLElement>(
  enabled: boolean,
  ref?: RefObject<T>,
): RefObject<T> {
  // 参考: https://stackabuse.com/how-to-set-focus-on-element-after-rendering-with-react/
  const ref_ = useRef<T>(null);
  const autoFocusRef = ref ?? ref_;

  useEffect(
    () => {
      if (enabled) autoFocusRef.current?.focus();
    },
    // eslint-disable-next-line
    [], // マウント時にのみ動くよう、意図的にdepを空にしている。
  );

  return autoFocusRef;
}
