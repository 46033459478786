import { useAppSelector } from "../redux-hooks";
import { selectHasRole } from "../../store/auth/slice";
import { useSavedDocument } from "../document/use-saved-document";
import { useMemo } from "react";
import { hasValue, isNullish } from "../../lib/util/common-util";
import { useAppListRevisionRequestQuery } from "../query/use-app-list-revision-request-query";
import { RevisionRequestSaved } from "../../lib/object/entity/revision-request";

/**
 * 指定した書類を修正可能（=修正ボタンを表示する）ならtrueを返す。
 * 修正不可能であればfalseを返す。
 * ロード中等、不定な状態でもfalseを返す。
 */
export function useCanRevise(documentId: number) {
  const { isApplicant } = useAppSelector(selectHasRole);
  const { document } = useSavedDocument(documentId);

  const { data: activeRevisionRequests } = useAppListRevisionRequestQuery({
    documentId,
    revisionRequestState: "revising",
  });

  /**
   * 現在進行中の修正依頼。
   */
  const activeRevisionRequest: RevisionRequestSaved | undefined =
    useMemo(() => {
      if (
        isNullish(activeRevisionRequests) ||
        activeRevisionRequests.length <= 0
      )
        return undefined;
      return activeRevisionRequests[0];
    }, [activeRevisionRequests]);

  return useMemo(() => {
    if (isNullish(document)) return false;

    const isApplicationAndInRevisableState =
      document.isApplication &&
      (document.documentState === "office_check" ||
        document.documentState === "awaiting_conditions_met");
    const isReportAndInRevisableState =
      !document.isApplication &&
      document.documentState === "awaiting_conclusion";

    return (
      isApplicant &&
      document.documentPosition === "applicant" &&
      (isApplicationAndInRevisableState || isReportAndInRevisableState) &&
      hasValue(activeRevisionRequest)
    );
  }, [activeRevisionRequest, document, isApplicant]);
}
