import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";
import { Box, Center, Flex, Text, VStack } from "@chakra-ui/react";
import { UploadButton } from "../../../../../ui/button/UploadButton/UploadButton";
import { useCallback, useState } from "react";
import {
  SharedAttachmentFilesSectionSortCondition,
  SharedAttachmentFilesSectionSortConditionSelect,
} from "./_components/SharedAttachmentFilesSectionSortConditionSelect";
import { SharedAttachmentFilesTable } from "./_components/SharedAttachmentFilesTable";
import { StorageFileSaved } from "../../../../../../lib/object/entity/storage-file";
import { useCreateSharedAttachmentFileMutation } from "../../../../../../store/api/generated/stock-request-api";
import {
  SharedAttachmentFile,
  sharedAttachmentFileMeta,
} from "../../../../../../lib/object/entity/shared-attachment-file";
import { isNullish } from "../../../../../../lib/util/common-util";
import { useAppSelector } from "../../../../../../hooks/redux-hooks";
import { selectHasRole } from "../../../../../../store/auth/slice";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";

export type SharedAttachmentFilesSectionProps = ComponentStyleProps;

export const SharedAttachmentFilesSection = ({
  sx,
  ...rest
}: SharedAttachmentFilesSectionProps) => {
  const { t } = useAppTranslation();
  const { isOfficeMember } = useAppSelector(selectHasRole);

  const [createSharedAttachmentFile] = useCreateSharedAttachmentFileMutation();

  const [sortCondition, setSortCondition] =
    // ホーム画面のデフォルトファイルソート順は名前昇順
    useState<SharedAttachmentFilesSectionSortCondition>("name_asc");
  const handleUpload = useCallback(
    (
      files: { name: string; storageFile: StorageFileSaved }[],
      _status: "ok" | "error",
    ) => {
      files.forEach(({ name, storageFile }) => {
        const sharedAttachmentFile: SharedAttachmentFile = {
          attachmentFileInformation: {
            storageFileId: storageFile.id,
            attachmentFileType: "shared",
            attachmentFileName: name,
            uploadedAt: storageFile.created.datetime,
          },
          created: {},
          updated: {},
        };
        const jsonObject =
          sharedAttachmentFileMeta.toJsonObjectOrNull(sharedAttachmentFile);
        if (isNullish(jsonObject)) return;
        createSharedAttachmentFile({
          sharedAttachmentFile: jsonObject,
        });
      });
    },
    [createSharedAttachmentFile],
  );

  return (
    <VStack sx={sx} {...rest} alignItems={"stretch"} spacing={"8px"}>
      <Flex direction={"row"} alignItems={"flex-end"}>
        <SharedAttachmentFilesSectionSortConditionSelect
          value={sortCondition}
          onChange={setSortCondition}
        />
        <Center flex={1}>
          <Text
            color={"blue.700"}
            fontSize={"28px"}
            lineHeight={"28px"}
            fontWeight={"bold"}
            whiteSpace={"nowrap"}
            ml={"10px"}
            mr={"20px"}
          >
            {t("lbl.関連文書・その他様式タイトル")}
          </Text>
        </Center>
        {isOfficeMember ? (
          <UploadButton
            onUpload={handleUpload}
            filePrivacyType={"public"}
            institutionId={null}
            projectId={null}
            ml={"auto"}
          />
        ) : (
          // 位置調整用
          <Box minW={0} w={"60px"} />
        )}
      </Flex>
      <SharedAttachmentFilesTable sortCondition={sortCondition} />
    </VStack>
  );
};
