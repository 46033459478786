import { ComponentStyleProps } from "../../../../../lib/styles/props/component-style-props";
import {
  CMButtonSearchConditionRadio,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import React, { useCallback, useState } from "react";
import { Checkbox, VStack } from "@chakra-ui/react";
import { useAppTranslation } from "../../../../../hooks/use-app-translation";

export type ButtonSearchConditionRadioWithTodoProps<
  T extends [string, ...string[]],
> = {
  label: string;

  todosFirst?: boolean;

  value?: T[number] | null;

  hideClearButton?: boolean;

  /**
   * 値オブジェクトメタデータ
   */
  valueObjectMeta: ValueObjectMetaEnum<T>;

  onChange?: (todosFirst: boolean, value: T[number] | undefined) => void;
} & ComponentStyleProps;

export const ButtonSearchConditionRadioWithTodo = <
  T extends [string, ...string[]],
>({
  label,

  todosFirst = false,

  value = null,
  hideClearButton = false,

  valueObjectMeta,

  onChange,

  sx,
  ...rest
}: ButtonSearchConditionRadioWithTodoProps<T>) => {
  const { t } = useAppTranslation();

  const [todosFirstInternal, setTodosFirstInternal] = useState(todosFirst);

  const handleChangeTodosFirst = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setTodosFirstInternal(e.target.checked);
    },
    [],
  );

  const handleChange = useCallback(
    (value: T[number] | null) => {
      onChange?.(todosFirstInternal, value ?? undefined);
    },
    [onChange, todosFirstInternal],
  );

  const capitalizedLabel = label;

  return (
    <CMButtonSearchConditionRadio
      label={capitalizedLabel}
      value={value}
      hideClearButton={hideClearButton}
      valueObjectMeta={valueObjectMeta}
      labelSubmit={t("btn.確定ボタン")}
      labelClear={t("btn.クリアボタン")}
      onChange={handleChange}
      sx={sx}
      {...rest}
    >
      <VStack alignSelf={"stretch"} alignItems={"flex-start"}>
        <Checkbox
          isChecked={todosFirstInternal}
          onChange={handleChangeTodosFirst}
        >
          {t("lbl.TODOを最初に表示")}
        </Checkbox>
      </VStack>
    </CMButtonSearchConditionRadio>
  );
};
