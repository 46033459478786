import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * 結果通知種別(値オブジェクト)
 *
 *
 *
 * application_no_review:
 * application_approve_or_conditional:
 * application_condition_confirmation:
 * application_rereview:
 * report_result:  */
export type ResultNotificationType = z.infer<
  typeof resultNotificationTypeMeta.schema
>;
/**
 * 結果通知種別(値オブジェクト)のメタデータ。
 *
 *  */
export const resultNotificationTypeMeta = new ValueObjectMetaEnum<
  [
    "application_no_review",
    "application_approve_or_conditional",
    "application_condition_confirmation",
    "application_rereview",
    "report_result"
  ]
>(
  "ResultNotificationType",
  "\u7D50\u679C\u901A\u77E5\u7A2E\u5225",
  messageId("lbl.\u7D50\u679C\u901A\u77E5\u7A2E\u5225"),
  [
    "application_no_review",
    "application_approve_or_conditional",
    "application_condition_confirmation",
    "application_rereview",
    "report_result",
  ],
  {
    application_no_review:
      "code.\u7D50\u679C\u901A\u77E5\u7A2E\u5225.\u7533\u8ACB_\u5BE9\u67FB\u4E0D\u8981",
    application_approve_or_conditional:
      "code.\u7D50\u679C\u901A\u77E5\u7A2E\u5225.\u7533\u8ACB_\u627F\u8A8D\u307E\u305F\u306F\u6761\u4EF6\u4ED8\u627F\u8A8D",
    application_condition_confirmation:
      "code.\u7D50\u679C\u901A\u77E5\u7A2E\u5225.\u7533\u8ACB_\u6761\u4EF6\u5BFE\u5FDC\u78BA\u8A8D",
    application_rereview:
      "code.\u7D50\u679C\u901A\u77E5\u7A2E\u5225.\u7533\u8ACB_\u4FDD\u7559\u30FB\u518D\u5BE9\u67FB",
    report_result:
      "code.\u7D50\u679C\u901A\u77E5\u7A2E\u5225.\u5831\u544A_\u7D50\u679C",
  }
);
