import { ProtectedRoute as PR } from "../components/route/ProtectedRoute";
import { FN80S01InstitutionSearch } from "../components/page/FN80/FN80S01InstitutionSearch/FN80S01InstitutionSearch";
import * as React from "react";
import { RouteObject } from "react-router-dom";
import { FN80S02InstitutionDetails } from "../components/page/FN80/FN80S02InstitutionDetails/FN80S02InstitutionDetails";

export const _institutionRoute: RouteObject = {
  path: "institution",
  element: (
    <PR
      roles={[
        "applicant",
        "office_member",
        "committee_member",
        "executive_director",
      ]}
    />
  ),
  children: [
    { index: true, element: <FN80S01InstitutionSearch /> },
    {
      path: "create",
      element: <PR roles={["office_member"]} />,
      children: [
        {
          index: true,
          element: <FN80S02InstitutionDetails createMode={true} />,
        },
      ],
    },
    {
      path: ":institutionId",
      element: <FN80S02InstitutionDetails createMode={false} />,
    },
  ],
};
