import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * iPS細胞の使用目的区分(値オブジェクト)
 *
 *
 *
 * research:
 * clinical:  */
export type IpsCellStockUsePurposeType = z.infer<
  typeof ipsCellStockUsePurposeTypeMeta.schema
>;
/**
 * iPS細胞の使用目的区分(値オブジェクト)のメタデータ。
 *
 *  */
export const ipsCellStockUsePurposeTypeMeta = new ValueObjectMetaEnum<
  ["research", "clinical"]
>(
  "IpsCellStockUsePurposeType",
  "iPS\u7D30\u80DE\u306E\u4F7F\u7528\u76EE\u7684\u533A\u5206",
  messageId("lbl.iPS\u7D30\u80DE\u306E\u4F7F\u7528\u76EE\u7684\u533A\u5206"),
  ["research", "clinical"],
  {
    research:
      "code.iPS\u7D30\u80DE\u306E\u4F7F\u7528\u76EE\u7684\u533A\u5206.\u30D2\u30C8\u3078\u306E\u6295\u4E0E\u306A\u3057\uFF08\u57FA\u790E\u7814\u7A76\u7B49\uFF09",
    clinical:
      "code.iPS\u7D30\u80DE\u306E\u4F7F\u7528\u76EE\u7684\u533A\u5206.\u30D2\u30C8\u3078\u306E\u6295\u4E0E\u4E88\u5B9A\u3042\u308A",
  }
);
