import { ComponentStyleProps } from "../../../../lib/styles/props/component-style-props";
import { HStack, Text } from "@chakra-ui/react";
import React from "react";

export type LabeledControlNumberProps = {
  label: string;
  value?: string | null;
} & ComponentStyleProps;

export const LabeledControlNumber = ({
  label,
  value,

  sx,
  ...rest
}: LabeledControlNumberProps) => {
  const capitalizedLabel = label;
  return (
    <HStack sx={sx} {...rest}>
      <Text fontSize={"sm"}>{`${capitalizedLabel} : `}</Text>
      <Text fontSize={"sm"} fontWeight={"bold"}>
        {value ?? "-"}
      </Text>
    </HStack>
  );
};
