import { ComponentStyleProps } from "../../../lib/styles/props/component-style-props";
import { Textarea } from "@chakra-ui/react";
import { ChangeEvent, useCallback } from "react";
import { useAutoFocus } from "../../../hooks/use-auto-focus";

export type AutoFocusTextareaProps = {
  autoFocus?: boolean;

  value?: string;
  onChange?: (value: string) => void;
} & ComponentStyleProps;

export const AutoFocusTextarea = ({
  autoFocus = true,

  value,
  onChange,

  sx,
  ...rest
}: AutoFocusTextareaProps) => {
  const autoFocusRef = useAutoFocus<HTMLTextAreaElement>(autoFocus);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      onChange?.(e.target.value);
    },
    [onChange],
  );
  return (
    <Textarea
      value={value}
      onChange={handleChange}
      sx={sx}
      {...rest}
      ref={autoFocusRef}
    />
  );
};
