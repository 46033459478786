import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * 特許ライセンス説明状況(値オブジェクト)
 *
 *
 *
 * not_explained:
 * explained:  */
export type PatentLicenseExplanationState = z.infer<
  typeof patentLicenseExplanationStateMeta.schema
>;
/**
 * 特許ライセンス説明状況(値オブジェクト)のメタデータ。
 *
 *  */
export const patentLicenseExplanationStateMeta = new ValueObjectMetaEnum<
  ["not_explained", "explained"]
>(
  "PatentLicenseExplanationState",
  "\u7279\u8A31\u30E9\u30A4\u30BB\u30F3\u30B9\u8AAC\u660E\u72B6\u6CC1",
  messageId(
    "lbl.\u7279\u8A31\u30E9\u30A4\u30BB\u30F3\u30B9\u8AAC\u660E\u72B6\u6CC1"
  ),
  ["not_explained", "explained"],
  {
    not_explained:
      "code.\u7279\u8A31\u30E9\u30A4\u30BB\u30F3\u30B9\u8AAC\u660E\u72B6\u6CC1.\u672A\u5B9F\u65BD",
    explained:
      "code.\u7279\u8A31\u30E9\u30A4\u30BB\u30F3\u30B9\u8AAC\u660E\u72B6\u6CC1.\u5B9F\u65BD\u6E08",
  }
);
