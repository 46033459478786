import {
  messageId,
  PropertyMeta,
  ValueObjectMetaNumber,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.number().min(0);
const savedMinimalSchema = z.number().min(0);
const schema = z.number().min(0);
const savedSchema = z.number().min(0);
const jsonSchema = z.number();
/**
 * メールログID(値オブジェクト)のメタデータ。
 *
 *  */
export const mailLogIdMeta = new ValueObjectMetaNumber(
  "MailLogId",
  "\u30E1\u30FC\u30EB\u30ED\u30B0ID",
  messageId("lbl.\u30E1\u30FC\u30EB\u30ED\u30B0ID"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
