import { useUpdateReportOfficeMemberNotesMutation } from "../../../../store/api/generated/stock-request-api";
import { Container, Flex, HStack, useDisclosure } from "@chakra-ui/react";
import { ProjectState } from "../../../../lib/object/value/project-state";
import useCustomToast from "../../../../hooks/use-custom-toast";
import React, { useMemo, useState } from "react";
import {
  ReportOfficeMemberNotes,
  reportOfficeMemberNotesMeta,
} from "../../../../lib/object/value/report-office-member-notes";
import log from "loglevel";
import { ComponentStyleProps } from "../../../../lib/styles/props/component-style-props";
import { CMFormInputTextArea } from "@pscsrvlab/psc-react-components";
import { FrameUpperRightButton } from "../../../ui/frame/FrameUpperRightButton/FrameUpperRightButton";
import { freeTextMeta } from "../../../../lib/object/value/free-text";
import { isNullish } from "../../../../lib/util/common-util";
import { useCreateJsonPatchOperations } from "../../../../hooks/use-create-json-patch-operations";
import { useAppTranslation } from "../../../../hooks/use-app-translation";
import { EditButton } from "../../../ui/button/EditButton/EditButton";
import { EditEndButton } from "../../../ui/button/EditEndButton/EditEndButton";
import { ConfirmationModal } from "../../../ui/modal/ConfirmationModal/ConfirmationModal";
import { SaveChangesButton } from "../../../ui/button/SaveChangesButton/SaveChangesButton";
import { FormSection } from "../../../ui/form/FormSection/FormSection";
import { useLeaveEditingPrompt } from "../../../../hooks/use-leave-editing-prompt";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import { selectHasRole } from "../../../../store/auth/slice";
import { errorMessageOf } from "../../../../lib/util/error-util";
import { DocumentState } from "../../../../lib/object/value/document-state";

export type ReportOfficeMemberNotesPageProps = {
  documentId: number;
  /**
   * バックエンドから取得する最新の事務局確認内容。参照モードではこれを表示する。
   */
  initialOfficeMemberNotesContent: ReportOfficeMemberNotes;
  projectState: ProjectState;
  documentState: DocumentState;
} & ComponentStyleProps;

export const ReportOfficeMemberNotesPage = ({
  documentId,
  initialOfficeMemberNotesContent,
  projectState,
  documentState,

  sx,
  ...rest
}: ReportOfficeMemberNotesPageProps) => {
  const { t } = useAppTranslation();
  const { errorToast, validationErrorToast } = useCustomToast();
  const { isOfficeMember } = useAppSelector(selectHasRole);

  const [updateReportOfficeMemberNotes] =
    useUpdateReportOfficeMemberNotesMutation();

  const [editMode, setEditMode] = useState<"editable" | "readOnly">("readOnly");

  useLeaveEditingPrompt({
    skip: editMode === "readOnly",
  });

  //事務局記入内容
  const [content, setContent] = useState<ReportOfficeMemberNotes>(
    initialOfficeMemberNotesContent,
  );

  const handleChange = (value: ReportOfficeMemberNotes) => {
    setContent(value);
  };

  const canEdit = useMemo(() => {
    return (
      isOfficeMember &&
      (projectState === "applied" || projectState === "ongoing") &&
      documentState !== "draft" &&
      documentState !== "submitted"
    );
  }, [documentState, isOfficeMember, projectState]);

  //編集ボタン押下時の処理。
  const handleClickEditButton = () => {
    setEditMode("editable");
  };

  const { createJsonPatchOperations } = useCreateJsonPatchOperations(
    reportOfficeMemberNotesMeta,
  );

  const handleSave = async () => {
    const validationResult = reportOfficeMemberNotesMeta.validate(content);
    if (validationResult.state === "error") {
      validationErrorToast(validationResult.errors);
      return;
    }

    const jsonPatchOperations = createJsonPatchOperations(
      initialOfficeMemberNotesContent,
      content,
    );
    if (isNullish(jsonPatchOperations)) return;

    try {
      await updateReportOfficeMemberNotes({
        documentId: documentId,
        commonUpdateRequest: {
          patchOperations: jsonPatchOperations,
        },
      }).unwrap();

      setEditMode("readOnly");
    } catch (e) {
      log.error(errorMessageOf(e));
      errorToast(t("mes.事務局記入内容更新失敗エラー"));
    }
  };

  //編集終了確認モーダルの制御。
  const {
    isOpen: isOpenEditEndModal,
    onOpen: onOpenEditEndModal,
    onClose: onCloseEditEndModal,
  } = useDisclosure();

  //編集終了ボタン押下時の処理。
  const handleClickEndEditButton = () => {
    onOpenEditEndModal();
  };

  //編集終了確認モーダルの確定ボタン押下時の処理。
  const handleSubmitEditEnd = () => {
    setContent(initialOfficeMemberNotesContent);
    setEditMode("readOnly");
    onCloseEditEndModal();
  };

  // 保存確認モーダルの表示制御設定
  const {
    isOpen: isOpenSaveModal,
    onOpen: onOpenSaveModal,
    onClose: onCloseSaveModal,
  } = useDisclosure();

  return (
    <>
      <Flex
        direction={"column"}
        flex={"1 1 auto"}
        alignSelf={"stretch"}
        minH={0}
        overflow={"auto"}
        sx={sx}
        {...rest}
      >
        {canEdit && (
          <FrameUpperRightButton sx={{ alignSelf: "flex-end" }}>
            {editMode === "readOnly" ? (
              <EditButton onClick={handleClickEditButton} />
            ) : (
              <HStack>
                <SaveChangesButton onClick={onOpenSaveModal} />
                <EditEndButton onClick={handleClickEndEditButton} />
              </HStack>
            )}
          </FrameUpperRightButton>
        )}

        <Container minW={"500px"} maxW={"720px"} mt={"20px"} mb={"50px"}>
          <FormSection title={t("lbl.事務局コメント欄")}>
            <CMFormInputTextArea
              noHeader={true}
              editMode={editMode}
              valueObjectMeta={freeTextMeta}
              value={content.officeMemberNote}
              onChange={(value: "required" | "not_required") =>
                handleChange({ ...content, officeMemberNote: value })
              }
            />
          </FormSection>
        </Container>
      </Flex>
      <ConfirmationModal
        isOpen={isOpenSaveModal}
        message={t("mes.変更保存確認メッセージ")}
        onSubmit={handleSave}
        onCancel={onCloseSaveModal}
      />
      <ConfirmationModal
        isOpen={isOpenEditEndModal}
        message={t("mes.変更内容破棄メッセージ")}
        onSubmit={handleSubmitEditEnd}
        onCancel={onCloseEditEndModal}
      />
    </>
  );
};
