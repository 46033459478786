import { Text, VStack } from "@chakra-ui/react";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import { selectHasRole } from "../../../../store/auth/slice";
import { useMemo } from "react";
import { useAppTranslation } from "../../../../hooks/use-app-translation";

export const RevisionRequestEmptyPage = () => {
  const { t } = useAppTranslation();
  const { isOfficeMember } = useAppSelector(selectHasRole);

  const message = useMemo(
    () =>
      isOfficeMember
        ? t("gdc.修正依頼選択案内(修正依頼)")
        : t("gdc.修正依頼選択案内(修正)"),
    [isOfficeMember, t],
  );

  return (
    <VStack flex={1} alignItems={"flex-start"}>
      <Text mt={"12px"} ml={"20px"}>
        {message}
      </Text>
    </VStack>
  );
};
