import {
  messageId,
  PropertyMeta,
  ValueObjectMetaComposite,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { collaborativePartnerOnReportCustomValidation } from "../custom-validation/collaborative-partner-on-report";
import { groupLocalKeyMeta } from "../value/group-local-key";
import { collaborativePartnerNameMeta } from "../value/collaborative-partner-name";
import { collaborativePartnerRoleMeta } from "../value/collaborative-partner-role";
import { collaborativePartnerUserInformationMeta } from "../value/collaborative-partner-user-information";
import { differentiatedCellProvisionTypeMeta } from "../value/differentiated-cell-provision-type";
import { differentiatedCellProvisionMeta } from "../value/differentiated-cell-provision";
/**
 * 分担機関（報告）(値オブジェクト)
 *
 *  */
export type CollaborativePartnerOnReport = z.infer<typeof schema>;
export type CollaborativePartnerOnReportSaved = z.infer<typeof savedSchema>;
const minimalSchema = z.object({
  key: groupLocalKeyMeta.minimalSchema,
  collaborativePartnerName: collaborativePartnerNameMeta.minimalSchema,
  collaborativePartnerRole: collaborativePartnerRoleMeta.minimalSchema,
  principalInvestigator: collaborativePartnerUserInformationMeta.minimalSchema,
  cellProvisionType: differentiatedCellProvisionTypeMeta.minimalSchema,
  cellProvisions: z.array(differentiatedCellProvisionMeta.minimalSchema),
});
const savedMinimalSchema = z.object({
  key: groupLocalKeyMeta.savedMinimalSchema,
  collaborativePartnerName: collaborativePartnerNameMeta.savedMinimalSchema,
  collaborativePartnerRole: collaborativePartnerRoleMeta.savedMinimalSchema,
  principalInvestigator:
    collaborativePartnerUserInformationMeta.savedMinimalSchema,
  cellProvisionType: differentiatedCellProvisionTypeMeta.savedMinimalSchema,
  cellProvisions: z.array(differentiatedCellProvisionMeta.savedMinimalSchema),
});
const schema = z.object({
  key: groupLocalKeyMeta.schema,
  collaborativePartnerName: collaborativePartnerNameMeta.schema,
  collaborativePartnerRole: collaborativePartnerRoleMeta.schema,
  principalInvestigator: collaborativePartnerUserInformationMeta.schema,
  cellProvisionType: differentiatedCellProvisionTypeMeta.schema,
  cellProvisions: z.array(differentiatedCellProvisionMeta.schema),
});
const savedSchema = z.object({
  key: groupLocalKeyMeta.savedSchema,
  collaborativePartnerName: collaborativePartnerNameMeta.savedSchema,
  collaborativePartnerRole: collaborativePartnerRoleMeta.savedSchema,
  principalInvestigator: collaborativePartnerUserInformationMeta.savedSchema,
  cellProvisionType: differentiatedCellProvisionTypeMeta.savedSchema,
  cellProvisions: z.array(differentiatedCellProvisionMeta.savedSchema),
});
const jsonSchema = z.object({
  key: groupLocalKeyMeta.jsonSchema.nullish().transform((x) => x ?? undefined),
  collaborativePartnerName: collaborativePartnerNameMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  collaborativePartnerRole: collaborativePartnerRoleMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  principalInvestigator: collaborativePartnerUserInformationMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  cellProvisionType: differentiatedCellProvisionTypeMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  cellProvisions: z
    .array(differentiatedCellProvisionMeta.jsonSchema)
    .nullish()
    .transform((x) => x ?? undefined),
});
/**
 * 分担機関（報告）(値オブジェクト)のメタデータ。
 *
 *
 *
 * 以下のカスタムバリデーションを、 collaborativePartnerOnReportCustomValidation にて行う。
 * - 分化細胞の提供先有無が有の場合は分化細胞の提供先を必須とする。 (エラーメッセージID=mes.分化細胞必須エラー) */
export const collaborativePartnerOnReportMeta = new ValueObjectMetaComposite(
  "CollaborativePartnerOnReport",
  "\u5206\u62C5\u6A5F\u95A2\uFF08\u5831\u544A\uFF09",
  messageId("lbl.\u5206\u62C5\u6A5F\u95A2\uFF08\u5831\u544A\uFF09"),
  minimalSchema,
  savedMinimalSchema,
  schema,
  savedSchema,
  jsonSchema,
  undefined,
  collaborativePartnerOnReportCustomValidation,
  {
    key: new PropertyMeta(
      "key",
      "\u30AD\u30FC",
      messageId("lbl.\u30AD\u30FC"),
      "always",
      false,
      groupLocalKeyMeta
    ),
    collaborativePartnerName: new PropertyMeta(
      "collaborativePartnerName",
      "\u5206\u62C5\u6A5F\u95A2\u540D",
      messageId("lbl.\u5206\u62C5\u6A5F\u95A2\u540D"),
      "always",
      false,
      collaborativePartnerNameMeta
    ),
    collaborativePartnerRole: new PropertyMeta(
      "collaborativePartnerRole",
      "\u5206\u62C5\u6A5F\u95A2\u5F79\u5272",
      messageId("lbl.\u5206\u62C5\u6A5F\u95A2\u5F79\u5272"),
      "always",
      false,
      collaborativePartnerRoleMeta
    ),
    principalInvestigator: new PropertyMeta(
      "principalInvestigator",
      "\u7814\u7A76\u8CAC\u4EFB\u8005",
      messageId("lbl.\u7814\u7A76\u8CAC\u4EFB\u8005"),
      "always",
      false,
      collaborativePartnerUserInformationMeta
    ),
    cellProvisionType: new PropertyMeta(
      "cellProvisionType",
      "\u5206\u5316\u7D30\u80DE\u63D0\u4F9B\u5148\u6709\u7121",
      messageId("lbl.\u5206\u5316\u7D30\u80DE\u63D0\u4F9B\u5148\u6709\u7121"),
      "always",
      false,
      differentiatedCellProvisionTypeMeta
    ),
    cellProvisions: new PropertyMeta(
      "cellProvisions",
      "\u5206\u5316\u7D30\u80DE\u63D0\u4F9B\u5148",
      messageId("lbl.\u5206\u5316\u7D30\u80DE\u63D0\u4F9B\u5148"),
      "always",
      true,
      differentiatedCellProvisionMeta
    ),
  }
);
