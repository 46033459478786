import { useLatestReviewIfHeld } from "./use-latest-review-if-held";
import { useMemo } from "react";
import { useAppGetDocumentQuery } from "../query/use-app-get-document-query";
import { isNullish } from "../../lib/util/common-util";
import { ResultNotificationViewModel } from "../../lib/object/vm/result-notification-view-model";
import { useAppSelector } from "../redux-hooks";
import { selectHasRole } from "../../store/auth/slice";

/**
 * 現在の書類等の値から、結果通知作成の初期値を作成する。
 * ログインユーザーが事務局以外の場合及び結果通知を作成できない状態の場合、nullを返却する。
 */
export function useResultNotificationCreateInitialValue(
  documentId: number,
): ResultNotificationViewModel | null {
  const { isOfficeMember } = useAppSelector(selectHasRole);
  const { data: document } = useAppGetDocumentQuery({ documentId });
  const { reviewNumber, latestReview } = useLatestReviewIfHeld(documentId, {
    skip: !isOfficeMember,
  });

  return useMemo<ResultNotificationViewModel | null>(() => {
    if (!isOfficeMember || isNullish(document)) return null;

    const documentType = document.documentType;
    const documentState = document.documentState;
    const documentPosition = document.documentPosition;

    if (documentPosition !== "office_member") return null;

    if (
      documentType === "new_application" ||
      documentType === "change_application"
    ) {
      if (documentState === "awaiting_conclusion_no_review") {
        return {
          baseReview: null,
          contentVM: {
            documentId,
            resultNotificationType: "application_no_review",
            result: undefined,
            reviewDate: undefined,
            unapprovalReason: "",
            note: "",
            created: {},
            updated: {},
          },
        };
      }

      if (
        isNullish(latestReview) ||
        isNullish(latestReview.reviewResult) ||
        isNullish(reviewNumber)
      )
        return null;
      if (documentState === "awaiting_conditions_met") {
        return {
          baseReview: {
            reviewId: latestReview.id,
            reviewType: latestReview.reviewType,
            reviewNumber,
          },
          contentVM: {
            documentId,
            resultNotificationType: "application_condition_confirmation",
            result: undefined,
            reviewDate: latestReview.reviewDate,
            unapprovalReason: latestReview.unapprovalReason,
            note: latestReview.note,
            executiveDirectorApproval: latestReview.executiveDirectorApproval,
            created: {},
            updated: {},
          },
        };
      }
      return {
        baseReview: {
          reviewId: latestReview.id,
          reviewType: latestReview.reviewType,
          reviewNumber,
        },
        contentVM: {
          documentId,
          resultNotificationType: "application_approve_or_conditional",
          result: undefined,
          reviewDate: latestReview.reviewDate,
          unapprovalReason: latestReview.unapprovalReason,
          note: latestReview.note,
          executiveDirectorApproval: latestReview.executiveDirectorApproval,
          created: {},
          updated: {},
        },
      };
    }

    if (
      documentType === "annual_report" ||
      documentType === "termination_report"
    ) {
      if (documentState !== "awaiting_conclusion") {
        return null;
      }
      return {
        baseReview: null,
        contentVM: {
          documentId,
          resultNotificationType: "report_result",
          result: undefined,
          reviewDate: undefined,
          unapprovalReason: "",
          note: "",
          created: {},
          updated: {},
        },
      };
    }

    // unreachable
    return null;
  }, [document, documentId, isOfficeMember, latestReview, reviewNumber]);
}
