import {
  Box,
  Center,
  Container,
  Flex,
  Heading,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import {
  CMButton,
  CMButtonBack,
  CMFormInputFileAttachableTextArea,
  CMFormInputText,
} from "@pscsrvlab/psc-react-components";
import { useNavigate } from "react-router-dom";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { inquirySubjectMeta } from "../../../../lib/object/value/inquiry-subject";
import { inquiryContentMeta } from "../../../../lib/object/value/inquiry-content";
import { FormInputInstitutionSelect } from "../../../model/form/FormInputInstitutionSelect";
import { FormInputProjectSelect } from "../../../model/form/FormInputProjectSelect";
import { hasValue, isNullish } from "../../../../lib/util/common-util";
import { FormInputDocumentSelect } from "../../../model/form/FormInputDocumentSelect";
import { stockRequestApi as api } from "../../../../store/api/enhanced-api";
import { useSelector } from "react-redux";
import { selectHasRole, selectUserInfo } from "../../../../store/auth/slice";
import { useAppParams } from "../../../../hooks/use-app-params";
import { useCreateInquiryMutation } from "../../../../store/api/generated/stock-request-api";
import useFileUpload from "../../../../hooks/use-file-upload";
import useCustomToast from "../../../../hooks/use-custom-toast";
import { FileUploadItemContent } from "@pscsrvlab/psc-react-components/src/components/file-upload/types";
import log from "loglevel";
import { inquiryMeta } from "../../../../lib/object/entity/inquiry";
import { useAppTranslation } from "../../../../hooks/use-app-translation";
import { ConfirmationModal } from "../../../ui/modal/ConfirmationModal/ConfirmationModal";
import { FrameUpperLeftButton } from "../../../ui/frame/FrameUpperLeftButton/FrameUpperLeftButton";
import { useFileModalContext } from "../../../ui/modal/FileModal/FileModal";

type InquiryCreateInputValue = {
  /**
   * この質疑応答に紐づける機関のID。
   * この質疑応答に紐づける案件の、機関IDと必ず一致する。
   *
   * 注意: 宛先機関ではない！事務局の作成時には宛先機関となるが、申請者の作成時には自身の機関となる。
   */
  institutionId?: number;
  projectId?: number;
  documentId?: number;

  subject?: string;
  content?: string;
  files: FileUploadItemContent[];
};

type ModalEvent = {
  name: ModalEventType;
  message: string;
  func: (...args: any[]) => Promise<void>;
  funcArgs?: any[];
};

type ModalEventType =
  // 添付ファイル削除
  | "deleteFile"
  // 質疑応答作成
  | "createInquiry";

export const FN60S02InquiryCreate = () => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const { validationErrorToast } = useCustomToast();
  const { fileUpload } = useFileUpload();
  const { openFileModal } = useFileModalContext()
  const { isApplicant, isOfficeMember } = useSelector(selectHasRole);

  // 案件情報取得用遅延クエリ
  const [triggerGetProjectQuery] = api.useLazyGetProjectQuery();
  // 書類情報取得用遅延クエリ
  const [triggerGetDocumentQuery] = api.useLazyGetDocumentQuery();
  // 質疑応答作成ミューテーション
  const [createInquiryMutation] = useCreateInquiryMutation();

  // クエリパラメータから案件ID、書類IDを取得
  const { projectId, documentId } = useAppParams();

  // ログインユーザー
  const loginUserInfo = useSelector(selectUserInfo);

  // ログインユーザーの機関ID
  const myInstitutionId = useMemo(
    () => loginUserInfo?.institutionId,
    [loginUserInfo?.institutionId],
  );

  // 確認モーダル制御用
  const { isOpen, onOpen, onClose } = useDisclosure();

  // 各項目の入力値
  const [inputValues, setInputValues] = useState<InquiryCreateInputValue>({
    institutionId: undefined,
    projectId: undefined,
    documentId: undefined,
    subject: "",
    content: "",
    files: [],
  });

  // 機関IDの初期選択値
  const [defaultInstitutionId, setDefaultInstitutionId] = useState<number>();
  // 案件IDの初期選択値
  const [defaultProjectId, setDefaultProjectId] = useState<number>();
  // 書類IDの初期選択値
  const [defaultDocumentId, setDefaultDocumentId] = useState<number>();

  // 確認モーダルのイベント
  const [modalEvent, setModalEvent] = useState<ModalEvent>();

  // 書類IDの初期選択値を取得
  const getDefaultDocumentId = useCallback(async (): Promise<
    number | undefined
  > => {
    // 書類メニューから遷移してきた場合はURLから書類IDを取得
    if (hasValue(documentId)) {
      return documentId;
    }
    return undefined;
  }, [documentId]);

  // 案件IDの初期選択値を取得
  const getDefaultProjectId = useCallback(async (): Promise<
    number | undefined
  > => {
    // 案件メニューから遷移してきた場合はURLから書類IDを取得
    if (hasValue(projectId)) {
      return projectId;
    }
    // 書類IDが存在する場合は書類情報に紐づく案件IDを取得
    const defaultDocumentId = await getDefaultDocumentId();
    if (hasValue(defaultDocumentId)) {
      const document = await triggerGetDocumentQuery({
        documentId: defaultDocumentId,
      }).unwrap();
      return document?.projectId;
    }
    return undefined;
  }, [getDefaultDocumentId, projectId, triggerGetDocumentQuery]);

  // 機関IDの初期選択値を取得
  const getDefaultInstitutionId = useCallback(async (): Promise<
    number | undefined
  > => {
    // 申請者の場合は自身の機関IDを取得
    if (isApplicant) {
      return myInstitutionId;
    }
    // 案件IDの初期値が存在する場合は案件情報に紐づく機関IDを取得
    const defaultProjectId = await getDefaultProjectId();
    if (hasValue(defaultProjectId)) {
      const project = await triggerGetProjectQuery({
        projectId: defaultProjectId,
      }).unwrap();
      return project?.applicationContent?.institution?.institutionId;
    }
    return undefined;
  }, [
    getDefaultProjectId,
    isApplicant,
    myInstitutionId,
    triggerGetProjectQuery,
  ]);

  // 初回レンダリング時
  useEffect(
    () => {
      const initialize = async () => {
        // 各項目の初期値を取得
        const defaultInstitutionId = await getDefaultInstitutionId();
        const defaultProjectId = await getDefaultProjectId();
        const defaultDocumentId = await getDefaultDocumentId();
        // ステートへ設定
        setDefaultInstitutionId(defaultInstitutionId);
        setDefaultProjectId(defaultProjectId);
        setDefaultDocumentId(defaultDocumentId);
        setInputValues({
          ...inputValues,
          institutionId: defaultInstitutionId,
          projectId: defaultProjectId,
          documentId: defaultDocumentId,
        });
      };
      initialize().then();
    },
    // eslint-disable-next-line
    [],
  );

  // 各項目の値変更時のイベント
  const handleChangeValue = useCallback(
    (change: (before: InquiryCreateInputValue) => InquiryCreateInputValue) => {
      setInputValues(change);
    },
    [],
  );

  // ファイルアップロード時のイベント
  const handleAddFile = useCallback(
    async (file: File) => {
      if (isNullish(myInstitutionId)) return;

      // APIのファイルアップロード処理呼び出し
      // ひとまず、自機関プライベートなファイルとしてアップロードする。質疑応答が作成されたとき、ここは正しく書き換えられる。
      const response = await fileUpload(file, "public", myInstitutionId, null);
      if (isNullish(response)) return;

      setInputValues((before) => ({
        ...before,
        files: [
          ...before.files,
          {
            id: response.id.toString(),
            name: file.name,
            date: response.created.datetime,
          },
        ],
      }));
    },
    [fileUpload, myInstitutionId],
  );

  // 添付ファイル削除のイベント
  const handleDeleteFile = useCallback(async (fileId: string) => {
    // 画面上の要素のみ削除を行い、実データは削除しない
    setInputValues((before) => ({
      ...before,
      files: before.files.filter((file) => file.id !== fileId),
    }));
  }, []);

  // ファイルダウンロード時のイベント
  const handleDownloadFile = useCallback(
    async (fileId: string, fileName: string) => {
      // APIのファイルダウンロード処理呼び出し
      openFileModal(fileId, fileName);
    },
    [openFileModal],
  );

  // 質疑作成のイベント
  const handleCreateInquiry = async () => {
    // バリデーション
    const validate = inquiryMeta.validate({
      institutionId: inputValues.institutionId,
      projectId: inputValues.projectId,
      documentId: inputValues.documentId,
      issuerRole: loginUserInfo?.role,
      subject: inputValues.subject,
      content: inputValues.content,
      attachmentFiles: [
        ...inputValues.files.map((file: FileUploadItemContent) => {
          return {
            storageFileId: parseInt(file.id),
            attachmentFileType: "inquiry_attachment",
            attachmentFileName: file.name,
            uploadedAt: file.date,
          };
        }),
      ],
      applicantReadState: "unread",
      officeMemberReadState: "unread",
      replies: [],
      created: {},
      updated: {},
    });
    // バリデーションチェックNGの場合
    if (validate.state === "error") {
      validationErrorToast(validate.errors, undefined, [
        {
          path: "institutionId",
          fullPropertyDisplayName: t("lbl.機関"),
        },
        {
          path: "projectId",
          fullPropertyDisplayName: t("lbl.案件"),
        },
      ]);
      return;
    }
    // バリデーションチェックOKの場合
    if (validate.state === "ok") {
      try {
        // JSON形式へ変換
        const json = inquiryMeta.toJsonObjectOrNull(validate.value);
        if (isNullish(json)) {
          log.error("質疑応答作成のJSON変換処理でエラー発生");
          return;
        }
        // APIの質疑応答作成処理を呼び出し
        await createInquiryMutation({
          inquiry: json,
        }).unwrap();
        // 質疑応答一覧へ遷移
        navigate("../");
      } catch (e) {
        log.error("質疑応答作成の非同期処理でエラー発生");
        return;
      }
    }
  };

  // 確認モーダル表示のイベント
  const handleOpenModal = useCallback(
    (event: ModalEvent) => {
      setModalEvent(event);
      onOpen();
    },
    [onOpen],
  );

  // 確認モーダルのOKボタン押下時のイベント
  const handleSubmit = useCallback(async () => {
    if (modalEvent?.funcArgs) {
      // イベント実行
      await modalEvent?.func(...modalEvent.funcArgs);
    } else {
      // イベント実行
      await modalEvent?.func();
    }
    setModalEvent(undefined);
    onClose();
  }, [modalEvent, onClose]);

  return (
    <>
      <Flex flexDir={"column"} h={"100%"} overflowY={"auto"}>
        <FrameUpperLeftButton>
          <CMButtonBack
            labelBack={t("btn.質疑一覧に戻るボタン")}
            onClick={() => navigate("../")}
          />
        </FrameUpperLeftButton>
        <Box flexBasis={"content"}>
          <Container w={"100%"} maxW={"600px"}>
            <VStack spacing={"20px"}>
              <Box>
                <Heading size={"md"}>
                  {t("lbl.質疑応答作成画面タイトル")}
                </Heading>
              </Box>
              <VStack
                alignItems={"start"}
                w={"100%"}
                p={"30px"}
                spacing={"20px"}
                border={"solid 1px"}
                borderColor={"gray.500"}
              >
                {/* 宛先機関 */}
                {isOfficeMember && (
                  <FormInputInstitutionSelect
                    editMode={
                      hasValue(defaultInstitutionId) ? "readOnly" : "editable"
                    }
                    label={t("lbl.宛先機関")}
                    institutionId={inputValues.institutionId}
                    onChange={(institutionId?: number) => {
                      // 機関IDが変更された場合は案件IDと書類IDをリセット
                      handleChangeValue((before) => ({
                        ...before,
                        institutionId,
                        projectId: undefined,
                        documentId: undefined,
                      }));
                    }}
                    requiredLabelProps={{
                      label: t("lbl.必須マーク"),
                    }}
                    w={"100%"}
                  />
                )}
                {/* 案件管理番号 */}
                <FormInputProjectSelect
                  editMode={
                    isNullish(inputValues.institutionId)
                      ? "disabled"
                      : hasValue(defaultProjectId)
                      ? "readOnly"
                      : "editable"
                  }
                  institutionId={inputValues.institutionId}
                  projectId={inputValues.projectId}
                  onChange={(projectId?: number) => {
                    // 案件IDが変更された場合は書類IDをリセット
                    handleChangeValue((before) => ({
                      ...before,
                      projectId,
                      documentId: undefined,
                    }));
                  }}
                  requiredLabelProps={{
                    label: t("lbl.必須マーク"),
                  }}
                  w={"100%"}
                />
                {/* 書類管理番号 */}
                <FormInputDocumentSelect
                  editMode={
                    isNullish(inputValues.projectId)
                      ? "disabled"
                      : hasValue(defaultDocumentId)
                      ? "readOnly"
                      : "editable"
                  }
                  projectId={inputValues.projectId}
                  documentId={inputValues.documentId}
                  onChange={(documentId?: number) => {
                    handleChangeValue((before) => ({
                      ...before,
                      documentId,
                    }));
                  }}
                  w={"100%"}
                />
                {/* 件名 */}
                <CMFormInputText
                  label={t("lbl.件名")}
                  requiredLabelProps={{
                    label: t("lbl.必須マーク"),
                  }}
                  valueObjectMeta={inquirySubjectMeta}
                  value={inputValues.subject}
                  onChange={(value: string) => {
                    handleChangeValue((before) => ({
                      ...before,
                      subject: value,
                    }));
                  }}
                />
                {/* 質疑内容 */}
                <CMFormInputFileAttachableTextArea
                  label={t("lbl.質疑内容")}
                  requiredLabelProps={{
                    label: t("lbl.必須マーク"),
                  }}
                  valueObjectMeta={inquiryContentMeta}
                  value={inputValues.content}
                  onChange={(value: string) => {
                    handleChangeValue((before) => ({
                      ...before,
                      content: value,
                    }));
                  }}
                  files={inputValues.files}
                  buttonUploadLabel={t("btn.質疑応答アップロードボタン")}
                  uploadingLabel={t("mes.アップロード中メッセージ")}
                  onAddFile={handleAddFile}
                  onDeleteFile={(fileId: string) =>
                    handleOpenModal({
                      name: "deleteFile",
                      message: t("mes.添付資料削除確認メッセージ"),
                      func: handleDeleteFile,
                      funcArgs: [fileId],
                    })
                  }
                  onDownloadFile={handleDownloadFile}
                  w={"100%"}
                />
              </VStack>
            </VStack>
          </Container>
        </Box>
        <Box flexBasis={"content"}>
          <Center py={"20px"}>
            <CMButton
              label={t("btn.質疑スレッドを作成するボタン")}
              onClick={() =>
                handleOpenModal({
                  name: "createInquiry",
                  message: t("mes.質疑応答送信確認メッセージ"),
                  func: handleCreateInquiry,
                })
              }
              my={"10px"}
            />
          </Center>
        </Box>
      </Flex>
      {/* 確認用モーダル */}
      <ConfirmationModal
        isOpen={isOpen}
        message={modalEvent?.message ?? ""}
        onSubmit={handleSubmit}
        onCancel={() => {
          // モーダルイベントの初期化
          setModalEvent(undefined);
          onClose();
        }}
      />
    </>
  );
};
