import {
  messageId,
  PropertyMeta,
  ValueObjectMetaString,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.string().max(100);
const savedMinimalSchema = z.string().max(100);
const schema = z.string().min(1).max(100);
const savedSchema = z.string().min(1).max(100);
const jsonSchema = z.string();
/**
 * 分化細胞提供先名(値オブジェクト)のメタデータ。
 *
 *  */
export const differentiatedCellProvisionToMeta = new ValueObjectMetaString(
  "DifferentiatedCellProvisionTo",
  "\u5206\u5316\u7D30\u80DE\u63D0\u4F9B\u5148\u540D",
  messageId("lbl.\u5206\u5316\u7D30\u80DE\u63D0\u4F9B\u5148\u540D"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
