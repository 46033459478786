import log from "loglevel";
import { isNullish } from "./common-util";

export const logLevels = [
  "trace",
  "debug",
  "info",
  "warn",
  "error",
  "silent",
] as const;

export type LogLevel = (typeof logLevels)[number];

export function isLogLevel(
  value: string | null | undefined,
): value is LogLevel {
  if (isNullish(value)) return false;
  return logLevels.some((v) => v === value);
}

export function getLogLevel(): LogLevel | "unknown-level" {
  switch (log.getLevel()) {
    case 0:
      return "trace";
    case 1:
      return "debug";
    case 2:
      return "info";
    case 3:
      return "warn";
    case 4:
      return "error";
    case 5:
      return "silent";
    default:
      return "unknown-level";
  }
}

/**
 * ブラウザの開発者ツールから、 setLogLevel("debug") などでログレベルを変更するための関数。
 * @param level
 */
function setLogLevel(level: LogLevel) {
  log.setLevel(level);
  console.log(`log level was set to "${getLogLevel()}"`);
}

(window as any).getLogLevel = getLogLevel;
(window as any).setLogLevel = setLogLevel;
