import { Container, HStack, useDisclosure, VStack } from "@chakra-ui/react";
import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";
import { FrameUpperLeftButton } from "../../../../../ui/frame/FrameUpperLeftButton/FrameUpperLeftButton";
import { ProgressStepDocumentCreate } from "../../../../../ui/progress/ProgressStepDocumentCreate/ProgressStepDocumentCreate";
import { hasValue } from "../../../../../../lib/util/common-util";
import { CommonNextButton } from "../../../../../ui/button/CommonNextButton/CommonNextButton";
import { FrameUpperRightButton } from "../../../../../ui/frame/FrameUpperRightButton/FrameUpperRightButton";
import React, { useCallback, useRef } from "react";
import { useAppSelector } from "../../../../../../hooks/redux-hooks";
import { selectUserInfo } from "../../../../../../store/auth/slice";
import { useNavigate } from "react-router-dom";
import useCustomToast from "../../../../../../hooks/use-custom-toast";
import { useSubmitNewApplicationFromDraftMutation } from "../../../../../../store/api/generated/stock-request-api";
import log from "loglevel";
import { errorMessageOf } from "../../../../../../lib/util/error-util";
import { NewApplicationViewModel } from "../../../../../../lib/object/vm/new-application-view-model";
import { NewApplicationDocument } from "../../../../../model/document/application/new-application/NewApplicationDocument/NewApplicationDocument";
import { CommonCancelButton } from "../../../../../ui/button/CommonCancelButton/CommonCancelButton";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { CMButtonBack } from "@pscsrvlab/psc-react-components";
import { ConfirmationModal } from "../../../../../ui/modal/ConfirmationModal/ConfirmationModal";

export type NewApplicationCreateConfirmPageProps = {
  documentId: number;
  vm: NewApplicationViewModel;
} & ComponentStyleProps;
export const NewApplicationCreateConfirmPage = ({
  documentId,
  vm,

  sx,
  ...rest
}: NewApplicationCreateConfirmPageProps) => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const { errorToast } = useCustomToast();
  const loginUserInfo = useAppSelector(selectUserInfo);

  const [submitNewApplication] = useSubmitNewApplicationFromDraftMutation();

  const handleSubmit = useCallback(async () => {
    try {
      await submitNewApplication({ documentId }).unwrap();
      navigate(`/document/create-new-application/complete/${documentId}`);
    } catch (e) {
      errorToast(t("mes.新規申請提出失敗エラー"));
      log.error(errorMessageOf(e));
    }
  }, [documentId, errorToast, navigate, submitNewApplication, t]);

  const handleBackCreatePage = useCallback(() => {
    navigate(`/document/create-new-application/create/${documentId}`);
  }, [documentId, navigate]);

  // 提出確認モーダル制御
  const {
    isOpen: isOpenSubmitModal,
    onOpen: onOpenSubmitModal,
    onClose: onCloseSubmitModal,
  } = useDisclosure();

  const scrollableRef = useRef(null);

  return (
    <>
      <HStack
        alignItems={"flex-start"}
        overflow={"auto"}
        spacing={0}
        sx={sx}
        {...rest}
        ref={scrollableRef}
      >
        <FrameUpperLeftButton
          sx={{ position: "sticky", top: 0, flex: "250px" }}
        >
          <CMButtonBack
            onClick={handleBackCreatePage}
            labelBack={t("btn.書類作成に戻るボタン")}
          />
        </FrameUpperLeftButton>

        <Container minW={"500px"} maxW={"720px"}>
          <VStack alignItems={"stretch"}>
            <ProgressStepDocumentCreate currentStep={1} />
            {hasValue(loginUserInfo) && (
              <VStack alignSelf={"stretch"} mt={"18px"} pb={"100px"}>
                <NewApplicationDocument
                  loginUserInfo={loginUserInfo}
                  editMode={"readOnly"}
                  value={vm}
                  scrollableRef={scrollableRef}
                  scrollOffset={60}
                />
                {/*提出ボタン*/}
                <HStack>
                  <CommonCancelButton
                    label={t("btn.書類作成に戻るボタン")}
                    onClick={handleBackCreatePage}
                  />
                  <CommonNextButton
                    label={t("btn.申請を確定するボタン")}
                    onClick={onOpenSubmitModal}
                  />
                </HStack>
              </VStack>
            )}
          </VStack>
        </Container>

        <FrameUpperRightButton
          pl={"20px"}
          sx={{ position: "sticky", top: 0, flex: "250px" }}
        ></FrameUpperRightButton>
      </HStack>
      <ConfirmationModal
        isOpen={isOpenSubmitModal}
        message={t("mes.提出確認メッセージ")}
        onSubmit={handleSubmit}
        onCancel={onCloseSubmitModal}
      />
    </>
  );
};
