import { NewApplicationSaved } from "../../lib/object/entity/new-application";
import { ChangeApplicationSaved } from "../../lib/object/entity/change-application";
import { AnnualReportSaved } from "../../lib/object/entity/annual-report";
import { TerminationReportSaved } from "../../lib/object/entity/termination-report";
import { hasValue, isNullish } from "../../lib/util/common-util";
import { useMemo } from "react";
import { DocumentType } from "../../lib/object/value/document-type";
import { GenericDocument } from "../../lib/object/other/generic-document";
import { useAppGetAnnualReportQuery } from "../query/use-app-get-annual-report-query";
import { useAppGetTerminationReportQuery } from "../query/use-app-get-termination-report-query";
import { useAppGetChangeApplicationQuery } from "../query/use-app-get-change-application-query";
import { useAppGetNewApplicationQuery } from "../query/use-app-get-new-application-query";
import { useAppGetDocumentQuery } from "../query/use-app-get-document-query";

export function useSavedCurrentDocument(
  documentId?: number | null,
  skipOptionalValidations = true, // get系は、skipOptionalValidationsのデフォルトはtrueとする。
): {
  isLoading: boolean;
  projectId: number | null;

  newApplication: NewApplicationSaved | null;
  changeApplication: ChangeApplicationSaved | null;
  annualReport: AnnualReportSaved | null;
  terminationReport: TerminationReportSaved | null;

  document: GenericDocument | null;
} {
  // まずは書類として取得。
  const { currentData: document, isLoading: isLoadingDocument } =
    useAppGetDocumentQuery(
      { documentId: documentId ?? -1 },
      { skip: isNullish(documentId) },
      skipOptionalValidations,
    );

  const documentType: DocumentType | null = useMemo(() => {
    if (isNullish(document)) return null;
    return document.documentType;
  }, [document]);

  // 書類種別が新規申請なら、新規申請として取得。
  const { currentData: newApplication, isLoading: isLoadingNewApplication } =
    useAppGetNewApplicationQuery(
      { documentId: documentId ?? -1 },
      { skip: isNullish(documentId) || documentType !== "new_application" },
      skipOptionalValidations,
    );

  // 書類種別が変更申請なら、変更申請として取得。
  const {
    currentData: changeApplication,
    isLoading: isLoadingChangeApplication,
  } = useAppGetChangeApplicationQuery(
    { documentId: documentId ?? -1 },
    { skip: isNullish(documentId) || documentType !== "change_application" },
    skipOptionalValidations,
  );

  // 書類種別が年次報告なら、年次報告として取得。
  const { currentData: annualReport, isLoading: isLoadingAnnualReport } =
    useAppGetAnnualReportQuery(
      { documentId: documentId ?? -1 },
      { skip: isNullish(documentId) || documentType !== "annual_report" },
      skipOptionalValidations,
    );

  // 書類種別が使用終了報告なら、使用終了報告として取得。
  const {
    currentData: terminationReport,
    isLoading: isLoadingTerminationReport,
  } = useAppGetTerminationReportQuery(
    { documentId: documentId ?? -1 },
    { skip: isNullish(documentId) || documentType !== "termination_report" },
    skipOptionalValidations,
  );

  const genericDocument: GenericDocument | null = useMemo(() => {
    if (hasValue(newApplication))
      return {
        ...newApplication,
        isApplication: true,
        type: "new_application",
      };
    if (hasValue(changeApplication))
      return {
        ...changeApplication,
        isApplication: true,
        type: "change_application",
      };
    if (hasValue(annualReport))
      return {
        ...annualReport,
        isApplication: false,
        type: "annual_report",
      };
    if (hasValue(terminationReport))
      return {
        ...terminationReport,
        isApplication: false,
        type: "termination_report",
      };
    return null;
  }, [newApplication, changeApplication, annualReport, terminationReport]);

  const projectId = useMemo(() => {
    if (hasValue(newApplication)) return newApplication.projectId;
    if (hasValue(changeApplication)) return changeApplication.projectId;
    if (hasValue(annualReport)) return annualReport.projectId;
    if (hasValue(terminationReport)) return terminationReport.projectId;
    return null;
  }, [annualReport, changeApplication, newApplication, terminationReport]);

  const isLoading = useMemo(
    () =>
      isLoadingDocument ||
      isLoadingNewApplication ||
      isLoadingChangeApplication ||
      isLoadingAnnualReport ||
      isLoadingTerminationReport,
    [
      isLoadingDocument,
      isLoadingNewApplication,
      isLoadingChangeApplication,
      isLoadingAnnualReport,
      isLoadingTerminationReport,
    ],
  );

  return {
    isLoading,

    projectId,

    newApplication,
    changeApplication,
    annualReport,
    terminationReport,

    document: genericDocument,
  };
}
