import { hasValue, isNullish } from "./common-util";
import {
  ValueObjectMetaEnum,
  ValueObjectMetaNumber,
  ValueObjectMetaString,
  ValueObjectMetaStringPartial,
} from "@pscsrvlab/psc-react-components";
import { isDateString, isISO8601Text } from "./common-date-util";

// export function getStringFromSearchParams(
//   searchParams: URLSearchParams,
//   targetName: string,
// ): string | undefined {
//   const value = searchParams.get(targetName);
//
//   return hasValue(value) ? value : undefined;
// }

export function getStringFromSearchParams(
  searchParams: URLSearchParams,
  targetName: string,
  valueObjectMeta: ValueObjectMetaString | ValueObjectMetaStringPartial,
): string | undefined {
  const value = searchParams.get(targetName);
  const validationResult = valueObjectMeta.validate(value);
  return validationResult.state === "ok" ? validationResult.value : undefined;
}

export function getAllEnumFromSearchParams<T extends [string, ...string[]]>(
  searchParams: URLSearchParams,
  targetName: string,
  valueObjectMeta: ValueObjectMetaEnum<T>,
): T[number][] | undefined {
  const values = searchParams.getAll(targetName);
  if (values.length <= 0) return undefined;
  return values
    .map((v) => {
      const validationResult = valueObjectMeta.validate(v);
      return validationResult.state === "ok"
        ? validationResult.value
        : undefined;
    })
    .filter(hasValue);
}

export function getEnumFromSearchParams<T extends [string, ...string[]]>(
  searchParams: URLSearchParams,
  targetName: string,
  valueObjectMeta: ValueObjectMetaEnum<T>,
): T[number] | undefined {
  const value = searchParams.get(targetName);
  const validationResult = valueObjectMeta.validate(value);
  return validationResult.state === "ok" ? validationResult.value : undefined;
}

export function getNumberFromSearchParams(
  searchParams: URLSearchParams,
  targetName: string,
  valueObjectMeta: ValueObjectMetaNumber,
): number | undefined {
  const value = searchParams.get(targetName);

  // 値が取得できなかった場合
  if (isNullish(value)) {
    return undefined;
  }

  const number = parseInt(value, 10);

  // 数値に変換できなかった場合
  if (isNaN(number)) {
    return undefined;
  }

  const validationResult = valueObjectMeta.validate(number);
  return validationResult.state === "ok" ? validationResult.value : undefined;
}

export function getBooleanFromSearchParams(
  searchParams: URLSearchParams,
  targetName: string,
): boolean | undefined {
  const value = searchParams.get(targetName);
  if (value === "true") return true;
  if (value === "false") return false;

  return undefined;
}

export function getDateStringFromSearchParams(
  searchParams: URLSearchParams,
  targetName: string,
): string | undefined {
  const value = searchParams.get(targetName);
  if (isNullish(value)) return undefined;
  return isDateString(value) ? value : undefined;
}

export function getISO8601StringFromSearchParams(
  searchParams: URLSearchParams,
  targetName: string,
): string | undefined {
  const value = searchParams.get(targetName);
  if (isNullish(value)) return undefined;
  return isISO8601Text(value) ? value : undefined;
}
