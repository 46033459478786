import { ReviewConferencePage } from "../../../model/reviewConference/FN50S02ReviewConferenceDetails/ReviewConferenceDetailsPage";
import { useAppParams } from "../../../../hooks/use-app-params";

export type FN50S02ReviewConferenceDetailsProps = {
  mode: "create" | "view-or-edit";
};

export const FN50S02ReviewConferenceDetails = ({
  mode,
}: FN50S02ReviewConferenceDetailsProps) => {
  // URLからパラメータを取得
  const { reviewConferenceId } = useAppParams();
  return (
    <ReviewConferencePage
      displayMode={mode}
      reviewConferenceId={reviewConferenceId}
    />
  );
};
