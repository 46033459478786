import { ComponentStyleProps } from "../../../../../../../../../lib/styles/props/component-style-props";
import {
  CMFormControlHeader,
  CMFormInputBaseDropDown,
  CMFormInputRadio,
  CMFormInputText,
} from "@pscsrvlab/psc-react-components";
import { HStack, VStack } from "@chakra-ui/react";
import { memo, useCallback, useMemo } from "react";
import { stockProviderProjectControlNumberMeta } from "../../../../../../../../../lib/object/value/stock-provider-project-control-number";
import { stockProviderOrganizationNameMeta } from "../../../../../../../../../lib/object/value/stock-provider-organization-name";
import { isNullish } from "../../../../../../../../../lib/util/common-util";
import { CellType } from "../../../../../../../../../lib/object/value/cell-type";
import { CellStockCategory } from "../../../../../../../../../lib/object/value/cell-stock-category";
import { CellStock } from "../../../../../../../../../lib/object/value/cell-stock";
import { RequestingCellViewModel } from "../../../ApplicationDocument";
import { useChangeBadgeProperties } from "../../../../../../../../../hooks/document/change-application/use-change-badge-properties";
import { cellProvisionTypeMeta } from "../../../../../../../../../lib/object/value/cell-provision-type";
import { useAppTranslation } from "../../../../../../../../../hooks/use-app-translation";
import { useAppListCellStockCategoryQuery } from "../../../../../../../../../hooks/query/use-app-list-cell-stock-category-query";
import { useAppListCellStockQuery } from "../../../../../../../../../hooks/query/use-app-list-cell-stock-query";

export type RequestingCellFormContentProps = {
  documentType:
    | "new_application"
    | "change_application"
    | "project_force_update"
    | "project_force_create"
    | "project_content";
  editMode: "editable" | "readOnly";
  cellType: CellType;

  value: RequestingCellViewModel;
  changedFrom?: RequestingCellViewModel;

  onChange: (
    change: (before: RequestingCellViewModel) => RequestingCellViewModel,
  ) => void;
} & ComponentStyleProps;

/**
 * 使用する細胞セクションのフォーム内容
 */
export const RequestingCellFormContent = memo(
  function RequestingCellFormContent({
    documentType,
    editMode,
    cellType,

    value,
    onChange,

    changedFrom,

    sx,
    ...rest
  }: RequestingCellFormContentProps) {
    const { t, i18n } = useAppTranslation();

    const { data: categories } = useAppListCellStockCategoryQuery({
      cellType,
    });

    /**
     * 細胞株カテゴリのプルダウン選択肢
     */
    const cellCategoryOptions: { label: string; value: number }[] =
      useMemo(() => {
        if (isNullish(categories)) return [];
        return categories.map((v) => ({
          label:
            i18n.language === "ja"
              ? v.cellCategoryNameJa
              : v.cellCategoryNameEn,
          value: v.cellStockCategoryId,
        }));
      }, [categories, i18n.language]);

    /**
     * 細胞種別を指定し、バックエンドから細胞リストを取得する。
     */
    const { data: allCells } = useAppListCellStockQuery({
      cellType,
    });
    const cellsOfCategory = useMemo(() => {
      if (isNullish(allCells) || isNullish(value.cellStockCategoryId))
        return [];
      return allCells.filter(
        (c) => c.cellStockCategoryId === value.cellStockCategoryId,
      );
    }, [allCells, value.cellStockCategoryId]);

    /**
     * 細胞のプルダウン選択肢
     */
    const cellOptions: { label: string; value: number }[] = useMemo(() => {
      if (isNullish(cellsOfCategory)) return [];

      return cellsOfCategory.map((v) => ({
        label: `${v.cellNumber}_${
          i18n.language === "ja" ? v.cellNameJa : v.cellNameEn
        }`,
        value: v.cellStockId,
      }));
    }, [cellsOfCategory, i18n.language]);

    /**
     * 細胞IDで株情報を取得する。
     */
    const getCellByIdOrNull = useCallback(
      (cellStockId: number | null): CellStock | null => {
        if (isNullish(cellStockId) || isNullish(allCells)) return null;
        return allCells.find((v) => v.cellStockId === cellStockId) ?? null;
      },
      [allCells],
    );

    /**
     * 細胞カテゴリIDで株カテゴリ情報を取得する。
     */
    const getCellCategoryByIdOrNull = useCallback(
      (cellStockCategoryId: number | null): CellStockCategory | null => {
        if (isNullish(cellStockCategoryId) || isNullish(categories))
          return null;
        return (
          categories.find(
            (v) => v.cellStockCategoryId === cellStockCategoryId,
          ) ?? null
        );
      },
      [categories],
    );

    const handleChangeCategory = useCallback(
      (v: number | null) => {
        const cellCategory = getCellCategoryByIdOrNull(v);

        onChange?.((before) => {
          return {
            ...before,
            cellStockId: undefined,
            cellType,
            cellStockCategoryId: cellCategory?.cellStockCategoryId,
            cellCategoryNumber: cellCategory?.cellCategoryNumber,
            cellCategoryNameJa: cellCategory?.cellCategoryNameJa,
            cellCategoryNameEn: cellCategory?.cellCategoryNameEn,
            cellNameJa: undefined,
            cellNameEn: undefined,
            cellNumber: undefined,
          };
        });
      },
      [cellType, getCellCategoryByIdOrNull, onChange],
    );

    const handleChangeCell = useCallback(
      (v: number | null) => {
        const cell = getCellByIdOrNull(v);

        onChange?.((before) => {
          return {
            ...before,
            cellStockId: cell?.cellStockId,
            cellNumber: cell?.cellNumber,
            cellNameJa: cell?.cellNameJa,
            cellNameEn: cell?.cellNameEn,
          };
        });
      },
      [getCellByIdOrNull, onChange],
    );

    const handleChangeProvisionType = useCallback(
      (v: "from_foundation" | "own" | "from_others") => {
        if (v === "from_foundation") {
          onChange?.((before) => {
            return {
              ...before,
              stockProviderProjectControlNumber: undefined,
              stockProviderOrganizationName: undefined,
              cellProvisionType: v,
            };
          });
        }
        if (v === "own") {
          onChange?.((before) => {
            return {
              ...before,
              stockProviderProjectControlNumber: "",
              stockProviderOrganizationName: undefined,
              cellProvisionType: v,
            };
          });
        }
        if (v === "from_others") {
          onChange?.((before) => {
            return {
              ...before,
              stockProviderProjectControlNumber: "",
              stockProviderOrganizationName: "",
              cellProvisionType: v,
            };
          });
        }
      },
      [onChange],
    );

    const handleChangeProvider = useCallback(
      (v: string) => {
        onChange?.((before) => ({
          ...before,
          stockProviderOrganizationName: v,
        }));
      },
      [onChange],
    );

    const handleChangeProviderProjectControlNumber = useCallback(
      (v: string) => {
        onChange?.((before) => ({
          ...before,
          stockProviderProjectControlNumber: v,
        }));
      },
      [onChange],
    );

    const changeBadgePropertiesCategory = useChangeBadgeProperties(
      documentType,
      changedFrom?.cellStockCategoryId,
    );
    const changeBadgePropertiesCell = useChangeBadgeProperties(
      documentType,
      changedFrom?.cellStockId,
    );
    const changeBadgePropertiesCellProvisionType = useChangeBadgeProperties(
      documentType,
      changedFrom?.cellProvisionType,
    );
    const changeBadgePropertiesStockProviderProjectControlNumber =
      useChangeBadgeProperties(
        documentType,
        changedFrom?.stockProviderProjectControlNumber,
      );
    const changeBadgePropertiesStockProviderOrganizationName =
      useChangeBadgeProperties(
        documentType,
        changedFrom?.stockProviderOrganizationName,
      );

    const toDisplayValueCategory = useCallback(
      (cellStockCategoryId: number): string => {
        const category = getCellCategoryByIdOrNull(cellStockCategoryId);
        if (isNullish(category)) return "";
        return i18n.language === "ja"
          ? category.cellCategoryNameJa
          : category.cellCategoryNameEn;
      },
      [getCellCategoryByIdOrNull, i18n.language],
    );
    const toDisplayValueCell = useCallback(
      (cellStockId: number): string => {
        const cell = getCellByIdOrNull(cellStockId);
        if (isNullish(cell)) return "";

        const cellName =
          i18n.language === "ja" ? cell.cellNameJa : cell.cellNameEn;
        return `${cell.cellNumber}_${cellName}`;
      },
      [getCellByIdOrNull, i18n.language],
    );

    return (
      <VStack alignItems={"stretch"} sx={sx} {...rest}>
        <VStack alignItems={"flex-start"} spacing={"2px"} mb={"5px"}>
          <CMFormControlHeader
            value={value.cellStockCategoryId}
            changeBadgeProperties={changeBadgePropertiesCategory}
            toDisplayValue={toDisplayValueCategory}
            label={t("lbl.カテゴリ")}
          />
          <CMFormInputBaseDropDown<number>
            placeholder={t("lbl.選択プルダウンプレースホルダー")}
            options={cellCategoryOptions}
            editMode={editMode}
            onChangeAsNumber={handleChangeCategory}
            value={value.cellStockCategoryId}
            variant={
              documentType === "change_application" &&
              value.cellStockCategoryId !== changedFrom?.cellStockCategoryId
                ? "diff"
                : "default"
            }
            nullable={true}
            size={"md"}
          />
        </VStack>
        <VStack alignItems={"flex-start"} spacing={"2px"} mb={"15px"}>
          <CMFormControlHeader
            value={value.cellStockId}
            label={t("lbl.番号・株名")}
            toDisplayValue={toDisplayValueCell}
            changeBadgeProperties={changeBadgePropertiesCell}
          />
          <CMFormInputBaseDropDown<number>
            // カテゴリによって番号・株名ドロップダウンを初期化するためにkeyを設定。
            key={value.cellStockCategoryId}
            placeholder={t("lbl.選択プルダウンプレースホルダー")}
            options={cellOptions}
            editMode={
              isNullish(value.cellStockCategoryId) ? "disabled" : editMode
            }
            onChangeAsNumber={handleChangeCell}
            value={value.cellStockId}
            variant={
              documentType === "change_application" &&
              value.cellStockId !== changedFrom?.cellStockId
                ? "diff"
                : "default"
            }
            nullable={true}
            size={"md"}
          />
        </VStack>
        <VStack alignItems={"stretch"}>
          <CMFormInputRadio
            editMode={editMode}
            label={t("lbl.細胞株提供について")}
            direction={"column"}
            value={value.cellProvisionType}
            valueObjectMeta={cellProvisionTypeMeta}
            onChange={handleChangeProvisionType}
            changeBadgeProperties={changeBadgePropertiesCellProvisionType}
          />
          {(value.cellProvisionType === "own" ||
            value.cellProvisionType === "from_others") && (
            <HStack alignItems={"flex-start"} spacing={"10px"} mt={"10px"}>
              <CMFormInputText
                label={t("lbl.当該株入手時の管理番号")}
                valueObjectMeta={stockProviderProjectControlNumberMeta}
                value={value.stockProviderProjectControlNumber}
                onChange={handleChangeProviderProjectControlNumber}
                editMode={editMode}
                changeBadgeProperties={
                  changeBadgePropertiesStockProviderProjectControlNumber
                }
                size={"md"}
                minW={0}
              />
              {value.cellProvisionType === "from_others" && (
                <CMFormInputText
                  label={t("lbl.提供元の機関名")}
                  valueObjectMeta={stockProviderOrganizationNameMeta}
                  value={value.stockProviderOrganizationName}
                  onChange={handleChangeProvider}
                  editMode={editMode}
                  changeBadgeProperties={
                    changeBadgePropertiesStockProviderOrganizationName
                  }
                  size={"md"}
                  minW={0}
                />
              )}
            </HStack>
          )}
        </VStack>
      </VStack>
    );
  },
);
