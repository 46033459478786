import { TerminationReportContentViewModel } from "../../../lib/object/vm/termination-report-view-model";
import { useCallback } from "react";
import {
  filterOutErrorsOfPaths,
  ValidationError,
} from "@pscsrvlab/psc-react-components";
import { isNullish } from "../../../lib/util/common-util";
import { isBeforeDate, ymdToJsDate } from "../../../lib/util/common-date-util";
import { TFunction } from "i18next";
import { YearMonthDay } from "../../../lib/object/value/year-month-day";
import {
  TerminationReportContent,
  terminationReportContentMeta,
} from "../../../lib/object/value/termination-report-content";
import { useAppTranslation } from "../../use-app-translation";

export function useTerminationReportContentValidation() {
  const { t } = useAppTranslation();

  const validate = useCallback(
    (
      viewModel: TerminationReportContentViewModel,
      approvedUsageEndDate: YearMonthDay,
      skipOptionalValidations: boolean,
    ):
      | { state: "ok"; value: TerminationReportContent }
      | { state: "error"; errors: ValidationError[] }
      | { state: "unexpectedError" } => {
      const regularValidationResult = regularValidations(
        viewModel,
        skipOptionalValidations,
      );

      const usageEndDateValidationResult = usageEndDateValidation(
        viewModel,
        approvedUsageEndDate,
        t,
        skipOptionalValidations,
      );

      const validationErrors = [
        ...(regularValidationResult.state === "error"
          ? regularValidationResult.errors
          : []),
        ...(usageEndDateValidationResult.state === "error"
          ? usageEndDateValidationResult.errors
          : []),
      ];
      // setValidationErrors(validationErrors);

      return regularValidationResult.state === "ok" &&
        usageEndDateValidationResult.state === "ok"
        ? { state: "ok", value: regularValidationResult.value }
        : { state: "error", errors: validationErrors };
    },
    [t],
  );

  return { validate };
}

/**
 * 書類内容に対するバリデーション。
 * バリデーション完了後、エラー一覧をセットするが、その際にユーザーに表示したくないエラーは除外する。
 * ただし、ユーザーに表示したくないエラーであっても、エラーがあれば戻り値は"error"を返す。
 * 注意: よって、ユーザーに表示したくないエラーがあるときは、他の（表示すべき）エラーもある状態とすべき。
 */
function regularValidations(
  viewModel: TerminationReportContentViewModel,
  skipOptional: boolean,
):
  | { state: "ok"; value: TerminationReportContent }
  | { state: "error"; errors: ValidationError[] } {
  const validationState = terminationReportContentMeta.validate(
    viewModel,
    skipOptional,
  );
  if (validationState.state === "ok")
    return { state: "ok", value: validationState.value };

  const errors = filterOutErrorsOfPaths(validationState.errors, []);
  return { state: "error", errors };
}

/**
 * 案件情報にある使用終了日を超える日付は入力不可。
 * 機能仕様(部品)(P0番台)_P0005項目_使用期間参照。
 */
function usageEndDateValidation(
  viewModel: TerminationReportContentViewModel,
  approvedUsageEndDate: YearMonthDay,
  t: TFunction<"translation", undefined, "translation">,
  skipOptional: boolean,
): { state: "ok" } | { state: "error"; errors: ValidationError[] } {
  if (skipOptional) return { state: "ok" };

  // 使用終了日未入力の場合は、判断のしようがないのでokを返す。（他のバリデーションエラーに引っかかるのでこれで良い。）
  if (isNullish(viewModel.usageEndDate)) return { state: "ok" };

  const _approvedUsageEndDate = ymdToJsDate(approvedUsageEndDate);
  const targetUsageEndDate = ymdToJsDate(viewModel.usageEndDate);
  const newApplicationApprovalDate = ymdToJsDate(
    viewModel.newApplicationApprovalDate,
  );
  const errors: ValidationError[] = [];
  if (isBeforeDate(_approvedUsageEndDate, targetUsageEndDate)) {
    errors.push({
      errorMessage: t("mes.使用終了日使用期間超過エラー"),
      fullPropertyDisplayName: null,
      fullPropertyName: null,
      objectName: t("lbl.使用期間"),
      objectLogicalName: "usageEndDate",
    });
  }
  if (isBeforeDate(targetUsageEndDate, newApplicationApprovalDate)) {
    errors.push({
      errorMessage: t("mes.開始終了日付妥当性チェックメッセージ"),
      fullPropertyDisplayName: null,
      fullPropertyName: null,
      objectName: t("lbl.使用期間"),
      objectLogicalName: "usageEndDate",
    });
  }
  if (errors.length > 0) {
    return { state: "error", errors };
  } else {
    return { state: "ok" };
  }
}
