import { Button, Container, Flex, Text, VStack } from "@chakra-ui/react";
import { FormEvent, useState } from "react";
import { changePassword } from "../../../../store/auth/slice";
import { useAppDispatch } from "../../../../hooks/redux-hooks";
import useCustomToast from "../../../../hooks/use-custom-toast";
import { Form, useNavigate } from "react-router-dom";
import {
  CMButtonBack,
  CMFormInputPassword,
} from "@pscsrvlab/psc-react-components";
import { PasswordPolicy } from "../../../model/user/PasswordPolicy/PasswordPolicy";
import { isValidPassword } from "../../../../lib/util/password-util";
import log from "loglevel";
import { errorNameAndMessageOf } from "../../../../lib/util/error-util";
import { useAppTranslation } from "../../../../hooks/use-app-translation";

/**
 * パスワード変更ページ
 */
export const FN10S08ChangePassword = () => {
  const dispatch = useAppDispatch();
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const { successToast, errorToast } = useCustomToast();

  // 現在のパスワード
  const [oldPassword, setOldPassword] = useState("");
  // 新しいパスワード
  const [newPassword, setNewPassword] = useState("");
  // 新しいパスワード(確認)
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  // ロード状態
  const [isLoading, setIsLoading] = useState(false);

  /**
   * 入力内容変更時のイベント
   * @param _oldPassword 現在のパスワード
   * @param _newPassword 新しいパスワード
   * @param _confirmPassword 新しいパスワード(確認)
   */
  const handleChangeValue = (
    _oldPassword: string,
    _newPassword: string,
    _confirmPassword: string,
  ) => {
    setOldPassword(_oldPassword);
    setNewPassword(_newPassword);
    setConfirmNewPassword(_confirmPassword);
  };

  /**
   * パスワードを変更するボタン押下時のイベント
   * @param event イベント情報
   */
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Submit防止
    setIsLoading(true);
    // バリデーションエラーがある場合は中断
    if (!validate()) {
      setIsLoading(false);
      return;
    }
    // パスワード変更処理を実行
    await executeChangePassword();
  };

  /**
   * バリデーションチェック
   * @return true: 非エラー / false: エラー
   */
  const validate = () => {
    // 検証用コードが未入力の場合
    if (!oldPassword) {
      errorToast(
        t("mes.必須チェックエラー2", { name: t("lbl.現在のパスワード") }),
      );
      return false;
    }
    // 新しいパスワードが未入力の場合
    if (!newPassword) {
      errorToast(
        t("mes.必須チェックエラー2", { name: t("lbl.新しいパスワード") }),
      );
      return false;
    }
    // 新しいパスワード(確認用)が未入力の場合
    if (!confirmNewPassword) {
      errorToast(
        t("mes.必須チェックエラー2", {
          name: t("lbl.新しいパスワード（確認用）"),
        }),
      );
      return false;
    }
    // パスワードポリシーに沿っていない場合
    if (!isValidPassword(newPassword)) {
      errorToast(t("mes.パスワードポリシーエラーメッセージ"));
      return false;
    }
    // パスワード(確認用)が一致していない場合
    if (newPassword !== confirmNewPassword) {
      errorToast(t("mes.パスワード確認不一致エラー"));
      return false;
    }
    return true;
  };

  /**
   * パスワード変更処理
   */
  const executeChangePassword = async () => {
    try {
      // 非同期処理を実行
      await dispatch(
        changePassword({
          oldPassword: oldPassword,
          newPassword: newPassword,
        }),
      ).unwrap();
      successToast(t("mes.パスワード変更完了メッセージ"));
      // プロフィール画面へ遷移
      navigate("/profile");
      return;
    } catch (e) {
      log.error("パスワード変更処理でエラー発生：", e);

      // ロードを中断
      setIsLoading(false);

      const error = errorNameAndMessageOf(e);

      // 現在のパスワードに誤りがある場合
      if (error?.name === "NotAuthorizedException") {
        errorToast(t("mes.現在のパスワード一致チェックエラー"));
        return;
      }
      // 試行回数の上限を超えた為、一時的にロックアウトされている場合
      if (error?.name === "LimitExceededException") {
        errorToast(t("mes.ロックアウトエラーメッセージ"));
        return;
      }
      // 試行回数の上限を超えた為、一時的にロックアウトされている場合
      if (error?.name === "InvalidPasswordException") {
        errorToast(t("mes.パスワードポリシーエラーメッセージ"));
        return;
      }
      // その他のエラーの場合はやり直し
      errorToast(t("mes.汎用エラーメッセージ"));
      return;
    }
  };

  return (
    <>
      <Flex
        direction={"column"}
        alignSelf={"stretch"}
        minH={0}
        flex={1}
        alignItems={"center"}
        overflow={"auto"}
      >
        <VStack pt={"24px"} alignSelf={"stretch"} alignItems={"flex-start"}>
          <CMButtonBack
            labelBack={t("btn.プロフィールに戻るボタン")}
            onClick={() => navigate("/profile")}
          />
        </VStack>

        <Container pb={"24px"} maxW={"600px"}>
          <Form onSubmit={handleSubmit}>
            <VStack align={"center"} spacing={"30px"} mt={"40px"}>
              <Text fontSize={"24px"} fontWeight={"bold"}>
                {t("lbl.パスワード変更画面タイトル")}
              </Text>
              <CMFormInputPassword
                initialValue={{
                  currentPassword: oldPassword,
                  newPassword: newPassword,
                  verifyPassword: confirmNewPassword,
                }}
                labelCurrentPassword={t("lbl.現在のパスワード")}
                labelNewPassword={t("lbl.新しいパスワード")}
                labelVerifyPassword={t("lbl.新しいパスワード（確認用）")}
                labelDisplayChangeButton={{
                  show: t("btn.パスワード表示ボタン"),
                  hide: t("btn.パスワード非表示ボタン"),
                }}
                onChange={handleChangeValue}
                w={"100%"}
              />
              <PasswordPolicy fontSize={"12px"} />
              <Button
                isLoading={isLoading}
                disabled={isLoading}
                colorScheme={"teal"}
                type={"submit"}
              >
                {t("btn.パスワードを変更するボタン")}
              </Button>
            </VStack>
          </Form>
        </Container>
      </Flex>
    </>
  );
};
