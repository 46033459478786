import {
  messageId,
  PropertyMeta,
  ValueObjectMetaNumber,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.number().min(0);
const savedMinimalSchema = z.number().min(0);
const schema = z.number().min(0);
const savedSchema = z.number().min(0);
const jsonSchema = z.number();
/**
 * 共有添付資料ID(値オブジェクト)のメタデータ。
 *
 *  */
export const sharedAttachmentFileIdMeta = new ValueObjectMetaNumber(
  "SharedAttachmentFileId",
  "\u5171\u6709\u6DFB\u4ED8\u8CC7\u6599ID",
  messageId("lbl.\u5171\u6709\u6DFB\u4ED8\u8CC7\u6599ID"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
