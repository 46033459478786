import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * 審査会開催状況(値オブジェクト)
 *
 *
 *
 * unheld:
 * held:  */
export type ReviewConferenceState = z.infer<
  typeof reviewConferenceStateMeta.schema
>;
/**
 * 審査会開催状況(値オブジェクト)のメタデータ。
 *
 *  */
export const reviewConferenceStateMeta = new ValueObjectMetaEnum<
  ["unheld", "held"]
>(
  "ReviewConferenceState",
  "\u5BE9\u67FB\u4F1A\u958B\u50AC\u72B6\u6CC1",
  messageId("lbl.\u5BE9\u67FB\u4F1A\u958B\u50AC\u72B6\u6CC1"),
  ["unheld", "held"],
  {
    unheld:
      "code.\u5BE9\u67FB\u4F1A\u958B\u50AC\u72B6\u6CC1.\u672A\u958B\u50AC",
    held: "code.\u5BE9\u67FB\u4F1A\u958B\u50AC\u72B6\u6CC1.\u958B\u50AC\u6E08",
  }
);
