import { Flex, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUserInfo } from "../../../../store/auth/slice";
import React, { useCallback, useMemo } from "react";
import { CMButton, CMMessageInfo } from "@pscsrvlab/psc-react-components";
import { GuidedButton } from "./_components/GuidedButton/GuidedButton";
import { SharedAttachmentFilesSection } from "./_components/SharedAttachmentFilesSection/SharedAttachmentFilesSection";
import { LatestMailSection } from "./_components/LatestMailSection/LatestMailSection";
import { useAppTranslation } from "../../../../hooks/use-app-translation";
import { ConfirmationModal } from "../../../ui/modal/ConfirmationModal/ConfirmationModal";

export const FN20S01Home = () => {
  const navigate = useNavigate();
  const currentUserInfo = useSelector(selectUserInfo);
  const myRole = useMemo(() => currentUserInfo?.role, [currentUserInfo]);

  const { t } = useAppTranslation();

  // 新規申請作成の確認モーダル制御用
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleCreateNewApplication = useCallback(() => {
    onOpen();
  }, [onOpen]);
  const handleConfirmCreateNewApplication = useCallback(() => {
    onClose();
    navigate("/document/create-new-application/create");
  }, [onClose, navigate]);

  const handleCheckApplicationStatus = useCallback(() => {
    navigate("/document");
  }, [navigate]);
  const handleCheckProjects = useCallback(() => {
    navigate("/project");
  }, [navigate]);
  const handleCheckConferences = useCallback(() => {
    navigate("/review-conference");
  }, [navigate]);
  const handleCheckReviews = useCallback(() => {
    navigate("/document");
  }, [navigate]);

  return (
    <>
      <Flex
        direction={"column"}
        flex={"1 1 auto"}
        minH={0}
        alignSelf={"stretch"}
        overflow={"auto"}
        pb={"100px"}
      >
        {/* 上部エリア */}
        <Flex direction={"row"} alignSelf={"stretch"} alignItems={"flex-start"}>
          {/* 左上メッセージエリア */}
          <VStack
            pt={"10px"}
            pl={"10px"}
            mr={"30px"}
            alignItems={"flex-start"}
            flex={1}
          >
            <Text color={"gray.500"} fontSize={"sm"} minW={"60px"}>
              {t("lbl.システム名")}
            </Text>
            {myRole === "applicant" && (
              <VStack pl={"6px"} alignItems={"flex-start"}>
                <CMMessageInfo
                  size={"sm"}
                  label={t("gdc.ホーム画面情報メッセージ1")}
                />
                <CMMessageInfo
                  size={"sm"}
                  label={t("gdc.ホーム画面情報メッセージ2")}
                />
              </VStack>
            )}
            {myRole === "internal" && (
              <Text ml={"10px"} color={"gray.900"}>
                {t("gdc.連絡待機案内")}
              </Text>
            )}
          </VStack>
          {/* 右上ボタンエリア */}
          <VStack pt={"10px"} pr={"10px"} ml={"auto"} alignItems={"flex-end"}>
            {myRole === "applicant" && (
              <>
                <CMButton
                  colorScheme={"red"}
                  bgColor={"red.400"}
                  size={"sm"}
                  label={t("btn.新規申請を作成するボタン")}
                  onClick={handleCreateNewApplication}
                  w={"180px"}
                />
                <CMButton
                  size={"sm"}
                  label={t("btn.申請状況を確認するボタン")}
                  onClick={handleCheckApplicationStatus}
                  w={"180px"}
                />
                <GuidedButton
                  message={t("gdc.案件を確認するガイダンス(申請者)")}
                  buttonLabel={t("btn.案件を確認するボタン")}
                  onClick={handleCheckProjects}
                />
              </>
            )}
            {myRole === "office_member" && (
              <>
                <GuidedButton
                  message={t("gdc.案件を確認するガイダンス(事務局)")}
                  buttonLabel={t("btn.案件を確認するボタン")}
                  onClick={handleCheckProjects}
                />
                <GuidedButton
                  message={t("gdc.申請状況を確認するガイダンス")}
                  buttonLabel={t("btn.申請状況を確認するボタン")}
                  onClick={handleCheckApplicationStatus}
                />
              </>
            )}
            {myRole === "committee_member" && (
              <>
                <GuidedButton
                  message={t("gdc.審査会を確認するガイダンス")}
                  buttonLabel={t("btn.審査会を確認するボタン")}
                  onClick={handleCheckConferences}
                />
              </>
            )}
            {myRole === "executive_director" && (
              <>
                <GuidedButton
                  message={t("gdc.審査を確認するガイダンス")}
                  buttonLabel={t("btn.審査を確認するボタン")}
                  onClick={handleCheckReviews}
                />
              </>
            )}
          </VStack>
        </Flex>
        {myRole !== "internal" && (
          <VStack mt={"40px"} spacing={"40px"}>
            <LatestMailSection />
            <SharedAttachmentFilesSection />
          </VStack>
        )}
      </Flex>
      {/*新規申請作成の確認モーダル*/}
      <ConfirmationModal
        isOpen={isOpen}
        message={t("mes.新規申請作成確認メッセージ")}
        onSubmit={handleConfirmCreateNewApplication}
        onCancel={onClose}
      />
    </>
  );
};
