import { useCallback, useEffect, useMemo, useState } from "react";
import { merge } from "lodash";
import useCustomToast from "../../use-custom-toast";
import { hasValue, isNullish } from "../../../lib/util/common-util";
import { useAppSelector } from "../../redux-hooks";
import { selectUserInfo } from "../../../store/auth/slice";
import { useAppGetInstitutionQuery } from "../../query/use-app-get-institution-query";
import {
  useCreateTerminationReportDraftMutation,
  useDeleteDocumentDraftMutation,
  useUpdateTerminationReportContentMutation,
} from "../../../store/api/generated/stock-request-api";
import log from "loglevel";
import { errorMessageOf } from "../../../lib/util/error-util";
import { useCreateJsonPatchOperations } from "../../use-create-json-patch-operations";
import {
  TerminationReportContentViewModel,
  TerminationReportViewModel,
} from "../../../lib/object/vm/termination-report-view-model";
import { terminationReportContentMeta } from "../../../lib/object/value/termination-report-content";
import { InstitutionInformation } from "../../../lib/object/value/institution-information";
import { UserInformation } from "../../../lib/object/value/user-information";
import { CollaborativePartnerOnReport } from "../../../lib/object/value/collaborative-partner-on-report";
import { TerminationReportSaved } from "../../../lib/object/entity/termination-report";
import { useAppGetProjectQuery } from "../../query/use-app-get-project-query";
import { ApplicationContentSaved } from "../../../lib/object/value/application-content";
import { ValidationError } from "@pscsrvlab/psc-react-components";
import { useTerminationReportContentValidation } from "./use-termination-report-content-validation";
import { YearMonthDay } from "../../../lib/object/value/year-month-day";
import { useAppTranslation } from "../../use-app-translation";

export function useTerminationReportVMOrEmpty(
  /**
   * 案件ID。
   */
  projectId: number,
  /**
   * 書類ID。
   * この値がない場合、
   * 新規の（空の）使用終了報告ビューモデルを作成する。
   */
  documentId: number | undefined,

  terminationReport?: TerminationReportSaved | null,

  options?: {
    /**
     * skipがtrueの場合、何も行わない。
     */
    skip: boolean;
  },
) {
  const _options = useMemo(() => merge({ skip: false }, options), [options]);

  const { t } = useAppTranslation();
  const { errorToast } = useCustomToast();
  const loginUserInfo = useAppSelector(selectUserInfo);

  /**
   * ログイン者の機関情報
   */
  const { data: institutionOfLoginUser } = useAppGetInstitutionQuery(
    {
      institutionId: loginUserInfo?.institutionId ?? -1,
    },
    { skip: _options.skip || isNullish(loginUserInfo?.institutionId) },
  );

  const { data: project } = useAppGetProjectQuery(
    {
      projectId: projectId ?? -1,
    },
    { skip: _options.skip || isNullish(projectId) },
  );

  // /**
  //  * 初期表示時に渡す、書類内容。
  //  * 一度作成したら二度と更新しない。
  //  */
  // const [initialViewModel, setInitialViewModel] =
  //   useState<TerminationReportContentViewModel | null>(null);

  /**
   * 初期表示時に渡す、ビューモデル。
   * 一度作成したら二度と更新しない。
   */
  const [initialTerminationReportVM, setInitialTerminationReportVM] =
    useState<TerminationReportViewModel | null>(null);

  const isInitialized = useMemo(() => {
    return hasValue(initialTerminationReportVM);
  }, [initialTerminationReportVM]);

  // 保存済の使用終了報告から作成する場合。
  const vmFromSaved: TerminationReportViewModel | null =
    useTerminationReportVM(terminationReport);
  useEffect(() => {
    if (_options.skip || isInitialized) return;

    if (isNullish(vmFromSaved)) return;

    // ビューモデル初期値に値を設定する。
    setInitialTerminationReportVM(vmFromSaved);
  }, [_options.skip, isInitialized, vmFromSaved]);

  // 新規の使用終了報告VMを作成する場合。
  useEffect(() => {
    if (_options.skip || isInitialized) return;

    // 書類IDが指定されている場合は、作成しない。
    if (hasValue(documentId)) return;

    // 色々と読み込むまでは作成しない。
    if (isNullish(project)) return;

    if (
      isNullish(project.newApplicationApprovalDate) ||
      isNullish(project.applicationContent.usageEndDate)
    )
      return;

    const projectContent: ApplicationContentSaved = project.applicationContent;

    const partners: CollaborativePartnerOnReport[] = [];
    projectContent?.partners.forEach((val) => {
      if (hasValue(val)) {
        partners.push({
          key: val.key,
          collaborativePartnerName: val.collaborativePartnerName,
          collaborativePartnerRole: val.collaborativePartnerRole,
          principalInvestigator: val.principalInvestigator,
          cellProvisionType: "provided",
          cellProvisions: [],
        });
      }
    });

    if (isNullish(projectContent?.principalInvestigator)) {
      log.error(
        `FN40S05TerminationReportCreate: 研究責任者情報が空なので、処理を中断します。`,
      );
      errorToast(t("mes.汎用エラーメッセージ"));
      return;
    }
    const _reportContent = createInitialViewModel(
      projectContent.projectName,
      projectContent.institution,
      projectContent?.principalInvestigator,
      partners,
      project.newApplicationApprovalDate,
    );
    setInitialTerminationReportVM({
      type: "termination_report",

      projectId: project.id,
      projectControlNumber: project.projectControlNumber,
      documentControlNumber: terminationReport?.documentControlNumber,

      submissionDate: terminationReport?.submissionDate,
      receptionDate: terminationReport?.receptionDate,
      approvalDate: terminationReport?.approvalDate,
      documentCompletionDate: terminationReport?.documentCompletionDate,

      contentVM: _reportContent,
      approvedUsageEndDate: project.applicationContent.usageEndDate,
    });
  }, [
    documentId,
    loginUserInfo,
    institutionOfLoginUser,
    project,
    errorToast,
    t,
    _options.skip,
    isInitialized,
    terminationReport?.documentControlNumber,
    terminationReport?.submissionDate,
    terminationReport?.receptionDate,
    terminationReport?.approvalDate,
    terminationReport?.documentCompletionDate,
  ]);

  // const initialTerminationReportVM: TerminationReportViewModel | null =
  //   useMemo(() => {
  //     if (
  //       isNullish(initialViewModel) ||
  //       isNullish(project) ||
  //       isNullish(project.applicationContent.usageEndDate)
  //     )
  //       return null;
  //
  //     return {
  //       type: "termination_report",
  //
  //       projectId: project.id,
  //       projectControlNumber: project.projectControlNumber,
  //       documentControlNumber: terminationReport?.documentControlNumber,
  //
  //       submissionDate: terminationReport?.submissionDate,
  //       receptionDate: terminationReport?.receptionDate,
  //       approvalDate: terminationReport?.approvalDate,
  //       documentCompletionDate: terminationReport?.documentCompletionDate,
  //
  //       contentVM: initialViewModel,
  //       approvedUsageEndDate: project.applicationContent.usageEndDate,
  //     };
  //   }, [
  //     initialViewModel,
  //     project,
  //     terminationReport?.approvalDate,
  //     terminationReport?.documentCompletionDate,
  //     terminationReport?.documentControlNumber,
  //     terminationReport?.receptionDate,
  //     terminationReport?.submissionDate,
  //   ]);
  // useEffect(() => {
  //   log.debug(`initialTerminationReportVM=`, initialTerminationReportVM);
  // }, [initialTerminationReportVM]);

  const [triggerCreateDraft] = useCreateTerminationReportDraftMutation();
  const [triggerDeleteDraft] = useDeleteDocumentDraftMutation();
  const [triggerUpdateContent] = useUpdateTerminationReportContentMutation();

  const { validate } = useTerminationReportContentValidation();
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>(
    [],
  );

  /**
   * 使用終了報告の下書きを新規作成する。
   * applicationContentのバリデーションは済んでいる前提とする。
   *
   * 成功・失敗に関わらず、結果を返却するだけなので、トーストを出すなどの後処理は呼び出し側の責務とする。
   */
  const createTerminationReportDraft = useCallback(
    async (
      content: TerminationReportContentViewModel,
      approvedUsageEndDate: YearMonthDay,
      skipOptionalValidations: boolean,
    ): Promise<
      | { state: "ok"; documentId: number }
      | { state: "error"; errors: ValidationError[] }
      | { state: "unexpectedError" }
    > => {
      if (hasValue(documentId)) {
        log.error(
          "書類IDが指定されているため、使用終了報告の下書き新規作成は行えません。",
        );
        return { state: "unexpectedError" };
      }

      const validationResult = validate(
        content,
        approvedUsageEndDate,
        skipOptionalValidations,
      );
      if (validationResult.state !== "ok") return validationResult;

      const contentJson = terminationReportContentMeta.toJsonObjectOrNull(
        validationResult.value,
      );
      if (isNullish(contentJson)) return { state: "unexpectedError" };

      try {
        const createResult = await triggerCreateDraft({
          projectId,
          terminationReportContent: contentJson,
        }).unwrap();
        const documentId = createResult.id;
        if (isNullish(documentId)) return { state: "unexpectedError" };
        return { state: "ok", documentId };
      } catch (e) {
        log.error(errorMessageOf(e));
        return { state: "unexpectedError" };
      }
    },
    [documentId, projectId, triggerCreateDraft, validate],
  );

  const { createJsonPatchOperations } = useCreateJsonPatchOperations(
    terminationReportContentMeta,
  );

  /**
   * 使用終了報告内容を更新する。
   * applicationContentのバリデーションは済んでいる前提とする。
   *
   * 成功・失敗に関わらず、結果を返却するだけなので、トーストを出すなどの後処理は呼び出し側の責務とする。
   */
  const updateTerminationReportContent = useCallback(
    async (
      content: TerminationReportContentViewModel,
      approvedUsageEndDate: YearMonthDay,
      skipOptionalValidations: boolean,
    ): Promise<
      | { state: "ok"; documentId: number }
      | { state: "error"; errors: ValidationError[] }
      | { state: "unexpectedError" }
    > => {
      if (
        isNullish(documentId) ||
        isNullish(terminationReport) ||
        isNullish(initialTerminationReportVM)
      ) {
        log.error(
          "書類IDが未指定または使用終了報告が取得できていないため、使用終了報告内容の上書き保存は行えません。",
        );
        return { state: "unexpectedError" };
      }

      const validationResult = validate(
        content,
        approvedUsageEndDate,
        skipOptionalValidations,
      );
      if (validationResult.state !== "ok") return validationResult;

      const jsonPatchOperations = createJsonPatchOperations(
        initialTerminationReportVM.contentVM,
        validationResult.value,
      );
      if (isNullish(jsonPatchOperations)) return { state: "unexpectedError" };

      try {
        await triggerUpdateContent({
          documentId,
          commonUpdateRequest: { patchOperations: jsonPatchOperations },
        }).unwrap();
      } catch (e) {
        log.error(errorMessageOf(e));
        return { state: "unexpectedError" };
      }

      return { state: "ok", documentId };
    },
    [
      createJsonPatchOperations,
      documentId,
      initialTerminationReportVM,
      terminationReport,
      triggerUpdateContent,
      validate,
    ],
  );

  /**
   * 使用終了報告下書きを新規作成、または使用終了報告内容を更新する。
   */
  const saveTerminationReportContent = useCallback(
    async (
      vm: TerminationReportViewModel,
      skipOptionalValidations: boolean,
    ): Promise<
      | { state: "ok"; documentId: number }
      | { state: "error"; errors: ValidationError[] }
      | { state: "unexpectedError" }
    > => {
      const result = isNullish(documentId)
        ? await createTerminationReportDraft(
            vm.contentVM,
            vm.approvedUsageEndDate,
            skipOptionalValidations,
          )
        : await updateTerminationReportContent(
            vm.contentVM,
            vm.approvedUsageEndDate,
            skipOptionalValidations,
          );
      if (result.state === "ok") setValidationErrors([]);
      if (result.state === "error") setValidationErrors(result.errors);

      return result;
    },
    [createTerminationReportDraft, documentId, updateTerminationReportContent],
  );

  const deleteTerminationReportDraft = useCallback(async (): Promise<
    { state: "ok" } | { state: "unexpectedError" }
  > => {
    if (isNullish(documentId)) {
      log.error("書類IDが未指定のため、使用終了報告の下書き削除は行えません。");
      return { state: "unexpectedError" };
    }

    try {
      await triggerDeleteDraft({ documentId }).unwrap();
    } catch (e) {
      log.error(errorMessageOf(e));
      return { state: "unexpectedError" };
    }

    return { state: "ok" };
  }, [documentId, triggerDeleteDraft]);

  return {
    project,
    terminationReport,

    initialTerminationReportVM,

    validationErrors,

    saveTerminationReportContent,
    deleteTerminationReportDraft,
  };
}

function createInitialViewModel(
  projectName: string,
  institution: InstitutionInformation,
  principalInvestigator: UserInformation,
  partners: CollaborativePartnerOnReport[],
  newApplicationApprovalDate: { year: number; month: number; day: number },
): TerminationReportContentViewModel {
  return {
    projectName,
    institution,
    principalInvestigator,
    newApplicationApprovalDate,
    usageEndDate: undefined,
    attachmentFiles: [],
    cellProvisionType: "provided",
    cellProvisions: [],
    handlingAfterTermination: {
      planComplianceType: "yes",
      reasonsForNotBeingAbleToComply: undefined,
    },
    problemsDuringThePeriod: {
      problemPresence: "has_problem",
      problemDetails: "",
      responseToProblem: "",
    },
    researchResultOutline: "",
    partner: partners,
  };
}

export function useTerminationReportVM(
  terminationReport: TerminationReportSaved | null | undefined,
): TerminationReportViewModel | null {
  const { data: project } = useAppGetProjectQuery(
    { projectId: terminationReport?.projectId ?? -1 },
    { skip: isNullish(terminationReport?.projectId) },
  );
  return useMemo(() => {
    if (
      isNullish(terminationReport) ||
      isNullish(project) ||
      isNullish(project.applicationContent.usageEndDate)
    )
      return null;

    return {
      type: "termination_report",

      projectId: project.id,
      projectControlNumber: project.projectControlNumber,
      documentControlNumber: terminationReport?.documentControlNumber,

      submissionDate: terminationReport?.submissionDate,
      receptionDate: terminationReport?.receptionDate,
      approvalDate: terminationReport?.approvalDate,
      documentCompletionDate: terminationReport?.documentCompletionDate,

      contentVM: terminationReport.content,
      approvedUsageEndDate: project.applicationContent.usageEndDate,
    };
  }, [project, terminationReport]);
}
