import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * 審査料要否(値オブジェクト)
 *
 *
 *
 * required:
 * not_required:  */
export type ReviewFeeRequired = z.infer<typeof reviewFeeRequiredMeta.schema>;
/**
 * 審査料要否(値オブジェクト)のメタデータ。
 *
 *  */
export const reviewFeeRequiredMeta = new ValueObjectMetaEnum<
  ["required", "not_required"]
>(
  "ReviewFeeRequired",
  "\u5BE9\u67FB\u6599\u8981\u5426",
  messageId("lbl.\u5BE9\u67FB\u6599\u8981\u5426"),
  ["required", "not_required"],
  {
    required: "code.\u5BE9\u67FB\u6599\u8981\u5426.\u8981",
    not_required: "code.\u5BE9\u67FB\u6599\u8981\u5426.\u5426",
  }
);
