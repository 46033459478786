import { Flex } from "@chakra-ui/react";
import { ReviewDetailsSideMenu } from "./_components/ReviewDetailsSideMenu/ReviewDetailsSideMenu";
import { useCallback, useMemo, useState } from "react";
import {
  getMessageFromEnumValue,
  hasValue,
  isNullish,
} from "../../../../lib/util/common-util";
import { ReviewSaved } from "../../../../lib/object/entity/review";
import { ReviewDetails } from "./_components/ReviewDetails/ReviewDetails";
import { useNavigate } from "react-router-dom";
import { ReviewDetailsEmptyPage } from "./_components/ReviewDetailsEmptyPage/ReviewDetailsEmptyPage";
import { ARRAY_NOT_CONTAIN } from "../../../../lib/constant/constant";
import { reviewTypeMeta } from "../../../../lib/object/value/review-type";
import { useAppTranslation } from "../../../../hooks/use-app-translation";
import { useProjectPagePathPrefix } from "../../../../hooks/use-project-page-path-prefix";
import { useAppListReviewQuery } from "../../../../hooks/query/use-app-list-review-query";

export type ReviewDetailsPageProps = {
  mode: "empty" | "view-or-edit";

  documentId: number;
  initialReviewId?: number;
};

/**
 * FN40-S30審査
 * サイドメニューとメインエリアを表示
 */
export const ReviewDetailsPage = ({
  mode,
  documentId,
  initialReviewId,
}: ReviewDetailsPageProps) => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();

  const [selectedReviewId, setSelectedReviewId] = useState(initialReviewId);

  // 審査情報リストの取得
  const { data: reviewsData } = useAppListReviewQuery(
    { documentId: documentId },
    { skip: isNullish(documentId) },
  );
  const reviews = useMemo(() => reviewsData ?? [], [reviewsData]);

  /**
   * 選択された審査情報
   */
  const selectedReview: ReviewSaved | null = useMemo(() => {
    return reviews.find((v) => v.id === selectedReviewId) ?? null;
  }, [reviews, selectedReviewId]);

  /**
   * 選択された審査情報の番号
   */
  const reviewDetailDataLabel: string | undefined = useMemo(() => {
    if (isNullish(reviews) || isNullish(selectedReview)) return undefined;
    const selectedReviewIndex = reviews
      .filter((data) => data.reviewType === selectedReview.reviewType)
      .findIndex((v) => v.id === selectedReview.id);

    // 配列に存在しない、場合は処理を行わない
    if (selectedReviewIndex === ARRAY_NOT_CONTAIN) {
      return undefined;
    }

    const label = getMessageFromEnumValue(
      t,
      reviewTypeMeta,
      selectedReview.reviewType,
    );

    // 画面表示用に調整
    return `${label}${selectedReviewIndex + 1}`;
  }, [t, reviews, selectedReview]);

  const { projectPagePathPrefix } = useProjectPagePathPrefix();

  /**
   * メニュー選択イベント
   * @param reviewId 審査ID
   */
  const handleClickMenu = useCallback(
    (reviewId: number) => {
      // 内部の審査ID更新する
      setSelectedReviewId(reviewId);

      navigate(
        `${projectPagePathPrefix}/document/${documentId}/review/${reviewId}`,
      );
    },
    [documentId, navigate, projectPagePathPrefix],
  );

  return (
    <Flex
      flex={"1 1 auto"}
      overflow={"hidden"}
      flexDirection={"row"}
      alignItems={"stretch"}
    >
      {/*サイドメニュー*/}
      <ReviewDetailsSideMenu
        reviewDetailsList={reviews ?? []}
        reviewId={selectedReviewId}
        onClick={handleClickMenu}
      />
      {mode === "empty" || reviews.length <= 0 ? (
        <ReviewDetailsEmptyPage anyReviewsExists={reviews.length > 0} />
      ) : (
        mode === "view-or-edit" &&
        hasValue(selectedReview) && (
          <ReviewDetails
            key={selectedReviewId}
            review={selectedReview}
            reviewDetailDataLabel={reviewDetailDataLabel}
          />
        )
      )}
    </Flex>
  );
};
