import {
  messageId,
  PropertyMeta,
  ValueObjectMetaNumber,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.number().min(1);
const savedMinimalSchema = z.number().min(1);
const schema = z.number().min(1);
const savedSchema = z.number().min(1);
const jsonSchema = z.number();
/**
 * 細胞カテゴリ番号(値オブジェクト)のメタデータ。
 *
 *  */
export const cellCategoryNumberMeta = new ValueObjectMetaNumber(
  "CellCategoryNumber",
  "\u7D30\u80DE\u30AB\u30C6\u30B4\u30EA\u756A\u53F7",
  messageId("lbl.\u7D30\u80DE\u30AB\u30C6\u30B4\u30EA\u756A\u53F7"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
