import {
  messageId,
  PropertyMeta,
  ValueObjectMetaNumber,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.number().min(0);
const savedMinimalSchema = z.number().min(0);
const schema = z.number().min(0);
const savedSchema = z.number().min(0);
const jsonSchema = z.number();
/**
 * 審査ID(値オブジェクト)のメタデータ。
 *
 *  */
export const reviewIdMeta = new ValueObjectMetaNumber(
  "ReviewId",
  "\u5BE9\u67FBID",
  messageId("lbl.\u5BE9\u67FBID"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
