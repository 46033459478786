import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";
import {
  Container,
  HStack,
  Stack,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { AnnualReportDocument } from "../AnnualReportDocument/AnnualReportDocument";
import React, { useCallback, useEffect, useState } from "react";
import useCustomToast from "../../../../../../hooks/use-custom-toast";
import { hasValue } from "../../../../../../lib/util/common-util";
import {
  CMButtonBack,
  CMExtendedMenu,
  CMMessageInfo,
  DeleteConfirmationModal,
  ValidationError,
} from "@pscsrvlab/psc-react-components";
import { FrameUpperLeftButton } from "../../../../../ui/frame/FrameUpperLeftButton/FrameUpperLeftButton";
import { ProgressStepDocumentCreate } from "../../../../../ui/progress/ProgressStepDocumentCreate/ProgressStepDocumentCreate";
import { CommonNextButton } from "../../../../../ui/button/CommonNextButton/CommonNextButton";
import { SaveDraftButton } from "../../../../../ui/button/SaveDraftButton/SaveDraftButton";
import { EditEndButton } from "../../../../../ui/button/EditEndButton/EditEndButton";
import { BsExclamationDiamondFill } from "react-icons/bs";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { FrameUpperRightButton } from "../../../../../ui/frame/FrameUpperRightButton/FrameUpperRightButton";
import { useAppSelector } from "../../../../../../hooks/redux-hooks";
import { selectUserInfo } from "../../../../../../store/auth/slice";
import { AnnualReportViewModel } from "../../../../../../lib/object/vm/annual-report-view-model";
import { useLeaveEditingPrompt } from "../../../../../../hooks/use-leave-editing-prompt";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { ConfirmationModal } from "../../../../../ui/modal/ConfirmationModal/ConfirmationModal";

export type AnnualReportCreatePageProps = {
  projectId: number;
  /**
   * 下書き保存済の書類が存在する場合、その書類ID。
   * 新規作成時はundefined。
   */
  savedDocumentId?: number;
  /**
   * 画面表示時の書類内容の初期値。
   */
  initialViewModel: AnnualReportViewModel;

  validationErrors: ValidationError[];

  saveAnnualReportContent: (
    vm: AnnualReportViewModel,
    skipOptionalValidations: boolean,
  ) => Promise<
    | { state: "ok"; documentId: number }
    | { state: "error"; errors: ValidationError[] }
    | { state: "unexpectedError" }
  >;
  deleteAnnualReportDraft: () => Promise<
    { state: "ok" } | { state: "unexpectedError" }
  >;

  isOverdue?: boolean;
} & ComponentStyleProps;

/**
 * 年次報告作成画面
 */
export const AnnualReportCreatePage = ({
  projectId,
  savedDocumentId,

  initialViewModel,

  validationErrors,

  saveAnnualReportContent,
  deleteAnnualReportDraft,

  isOverdue = false,
  sx,
  ...rest
}: AnnualReportCreatePageProps) => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const loginUserInfo = useAppSelector(selectUserInfo);

  const [viewModel, setViewModel] =
    useState<AnnualReportViewModel>(initialViewModel);

  const { errorToast } = useCustomToast();
  const { navigateWithoutPrompt } = useLeaveEditingPrompt();

  // 提供先の有無で無が選択されると提供先を空にする。
  useEffect(() => {
    if (
      viewModel.contentVM.cellProvisionType === "none" &&
      viewModel.contentVM.cellProvisions.length !== 0
    ) {
      setViewModel({
        ...viewModel,
        contentVM: { ...viewModel.contentVM, cellProvisions: [] },
      });
    }
  }, [viewModel]);

  /**
   * 戻るボタン押下時に呼ばれる。
   */
  const handleClickBack = useCallback(() => {
    navigate(`/project/${projectId}`);
  }, [navigate, projectId]);

  // 下書き保存確認モーダル制御
  const {
    isOpen: isOpenSaveDraftModal,
    onOpen: onOpenSaveDraftModal,
    onClose: onCloseSaveDraftModal,
  } = useDisclosure();
  /**
   * 下書き保存ボタン押下時、確認へ進むボタン押下時に呼ばれる。
   */
  const handleSaveDraft = useCallback(
    async (to: "back" | "confirm", skipOptionalValidations: boolean) => {
      // 年次報告下書きを新規作成、または年次報告内容を更新する。
      const resp = await saveAnnualReportContent(
        viewModel,
        skipOptionalValidations,
      );
      if (resp.state === "error") {
        return;
      }
      if (resp.state === "unexpectedError") {
        errorToast(t("mes.汎用エラーメッセージ"));
        return;
      }
      if (to === "back") {
        await navigateWithoutPrompt(`/project/${projectId}`);
      }
      if (to === "confirm") {
        // await sleep(200); // すぐに遷移すると、遷移後に古い値が表示されてしまうことへの対策。
        await navigateWithoutPrompt(
          `/document/create-annual-report/${projectId}/confirm/${resp.documentId}`,
        );
      }
    },
    [
      errorToast,
      navigateWithoutPrompt,
      projectId,
      saveAnnualReportContent,
      t,
      viewModel,
    ],
  );

  /**
   * 編集終了ボタン押下時に呼ばれる。
   */
  const handleClickEditEnd = useCallback(() => {
    navigate(`/project/${projectId}`);
  }, [navigate, projectId]);

  //下書き削除確認モーダル制御
  const {
    isOpen: isOpenDeleteDraftModal,
    onOpen: onOpenDeleteDraftModal,
    onClose: onCloseDeleteDraftModal,
  } = useDisclosure();

  /**
   * 下書き削除ボタン押下時に呼ばれる。
   */
  const handleDeleteDraftButtonClick = useCallback(() => {
    onOpenDeleteDraftModal();
  }, [onOpenDeleteDraftModal]);

  /**
   * モーダルの削除ボタンを押下すると呼ばる。
   */
  const handleConfirmDeleteDraft = useCallback(async () => {
    onCloseDeleteDraftModal();

    const result = await deleteAnnualReportDraft();
    if (result.state === "unexpectedError") {
      errorToast(t("mes.下書き削除失敗エラー"));
      return;
    }
    navigateWithoutPrompt(`/project/${projectId}`);
  }, [
    deleteAnnualReportDraft,
    errorToast,
    navigateWithoutPrompt,
    onCloseDeleteDraftModal,
    projectId,
    t,
  ]);

  return (
    <>
      <HStack
        justifyContent={"space-between"}
        alignItems={"flex-start"}
        overflow={"auto"}
        spacing={0}
        sx={sx}
        {...rest}
      >
        <FrameUpperLeftButton sx={{ position: "sticky", top: 0 }}>
          <CMButtonBack
            onClick={handleClickBack}
            labelBack={t("btn.案件画面に戻るボタン")}
          />
        </FrameUpperLeftButton>

        <Container minW={"500px"} maxW={"720px"}>
          <VStack alignItems={"stretch"}>
            <ProgressStepDocumentCreate />
            <Stack spacing={"10px"} minW={0}>
              {hasValue(initialViewModel.nextAnnualReportDeadline) && (
                <CMMessageInfo
                  label={t("gdc.書類年次報告提出期限案内", {
                    年: initialViewModel.nextAnnualReportDeadline.year,
                    月: initialViewModel.nextAnnualReportDeadline.month,
                    日: initialViewModel.nextAnnualReportDeadline.day,
                  })}
                  icon={BsExclamationDiamondFill}
                  iconColor={"red.500"}
                />
              )}
              {isOverdue && (
                <CMMessageInfo
                  label={t("mes.年次報告使用期限切れエラーメッセージ")}
                  icon={BsExclamationDiamondFill}
                  iconColor={"red.500"}
                />
              )}
            </Stack>
            {hasValue(loginUserInfo) && (
              <VStack alignSelf={"stretch"} mt={"18px"} pb={"100px"}>
                <AnnualReportDocument
                  loginUserInfo={loginUserInfo}
                  editMode={"editable"}
                  value={viewModel}
                  onChange={setViewModel}
                  validationErrors={validationErrors}
                />
                <CommonNextButton
                  label={t("btn.確認へ進むボタン")}
                  onClick={() => handleSaveDraft("confirm", false)}
                />
              </VStack>
            )}
          </VStack>
        </Container>

        <FrameUpperRightButton sx={{ position: "sticky", top: 0 }}>
          <HStack w={"max-content"}>
            <SaveDraftButton onClick={onOpenSaveDraftModal} />
            <EditEndButton onClick={handleClickEditEnd} />
            {hasValue(savedDocumentId) && (
              <CMExtendedMenu
                size={"sm"}
                menuItems={[
                  {
                    key: 0,
                    icon: RiDeleteBin6Fill,
                    label: t("btn.下書きを削除するボタン"),
                  },
                ]}
                onClick={handleDeleteDraftButtonClick}
              />
            )}
          </HStack>
        </FrameUpperRightButton>
      </HStack>
      <DeleteConfirmationModal
        isOpen={isOpenDeleteDraftModal}
        title={t("lbl.確認ポップアップタイトル")}
        message={t("mes.下書き削除確認メッセージ")}
        deleteButtonLabel={t("btn.削除ボタン")}
        cancelButtonLabel={t("btn.キャンセルボタン")}
        onConfirm={handleConfirmDeleteDraft}
        onCancel={onCloseDeleteDraftModal}
        onClose={onCloseDeleteDraftModal}
      />
      <ConfirmationModal
        isOpen={isOpenSaveDraftModal}
        message={t("mes.変更保存確認メッセージ")}
        onSubmit={() => handleSaveDraft("back", true)}
        onCancel={onCloseSaveDraftModal}
      />
    </>
  );
};
