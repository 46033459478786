import { useParams } from "react-router-dom";
import { useMemo } from "react";
import { isNullish, parseIntOrNull } from "../lib/util/common-util";
import { projectIdMeta } from "../lib/object/value/project-id";
import { documentIdMeta } from "../lib/object/value/document-id";
import { cellStockDeliveryIdMeta } from "../lib/object/value/cell-stock-delivery-id";
import { revisionRequestIdMeta } from "../lib/object/value/revision-request-id";
import { institutionIdMeta } from "../lib/object/value/institution-id";
import { appUserIdMeta } from "../lib/object/value/app-user-id";
import { resultNotificationIdMeta } from "../lib/object/value/result-notification-id";
import { reviewIdMeta } from "../lib/object/value/review-id";
import { reviewConferenceIdMeta } from "../lib/object/value/review-conference-id";

export function useAppParams() {
  const {
    appUserId: appUserIdParam,
    institutionId: institutionIdParam,
    projectId: projectIdParam,
    documentId: documentIdParam,
    revisionRequestId: revisionRequestIdParam,
    reviewId: reviewIdParam,
    reviewConferenceId: reviewConferenceIdParam,
    cellStockDeliveryId: cellStockDeliveryIdParam,
    resultNotificationId: resultNotificationIdParam,
  } = useParams<{
    appUserId?: string;
    institutionId?: string;
    projectId?: string;
    documentId?: string;
    revisionRequestId?: string;
    reviewId?: string;
    reviewConferenceId?: string;
    cellStockDeliveryId?: string;
    resultNotificationId?: string;
  }>();

  const appUserId = useMemo(() => {
    if (isNullish(appUserIdParam)) return undefined;
    return (
      appUserIdMeta.toDomainObjectOrNull(parseIntOrNull(appUserIdParam)) ??
      undefined
    );
  }, [appUserIdParam]);

  const institutionId = useMemo(() => {
    if (isNullish(institutionIdParam)) return undefined;
    return (
      institutionIdMeta.toDomainObjectOrNull(
        parseIntOrNull(institutionIdParam),
      ) ?? undefined
    );
  }, [institutionIdParam]);

  const projectId = useMemo(() => {
    if (isNullish(projectIdParam)) return undefined;
    return (
      projectIdMeta.toDomainObjectOrNull(parseIntOrNull(projectIdParam)) ??
      undefined
    );
  }, [projectIdParam]);

  const documentId = useMemo(() => {
    if (isNullish(documentIdParam)) return undefined;
    return (
      documentIdMeta.toDomainObjectOrNull(parseIntOrNull(documentIdParam)) ??
      undefined
    );
  }, [documentIdParam]);

  const revisionRequestId = useMemo(() => {
    if (isNullish(revisionRequestIdParam)) return undefined;
    return (
      revisionRequestIdMeta.toDomainObjectOrNull(
        parseIntOrNull(revisionRequestIdParam),
      ) ?? undefined
    );
  }, [revisionRequestIdParam]);

  const reviewId = useMemo(() => {
    if (isNullish(reviewIdParam)) return undefined;
    return (
      reviewIdMeta.toDomainObjectOrNull(parseIntOrNull(reviewIdParam)) ??
      undefined
    );
  }, [reviewIdParam]);

  const reviewConferenceId = useMemo(() => {
    if (isNullish(reviewConferenceIdParam)) return undefined;
    return (
      reviewConferenceIdMeta.toDomainObjectOrNull(
        parseIntOrNull(reviewConferenceIdParam),
      ) ?? undefined
    );
  }, [reviewConferenceIdParam]);

  const cellStockDeliveryId = useMemo(() => {
    if (isNullish(cellStockDeliveryIdParam)) return undefined;
    return (
      cellStockDeliveryIdMeta.toDomainObjectOrNull(
        parseIntOrNull(cellStockDeliveryIdParam),
      ) ?? undefined
    );
  }, [cellStockDeliveryIdParam]);

  const resultNotificationId = useMemo(() => {
    if (isNullish(resultNotificationIdParam)) return undefined;
    return (
      resultNotificationIdMeta.toDomainObjectOrNull(
        parseIntOrNull(resultNotificationIdParam),
      ) ?? undefined
    );
  }, [resultNotificationIdParam]);

  return {
    appUserId,
    institutionId,
    projectId,
    documentId,
    revisionRequestId,
    reviewId,
    reviewConferenceId,
    cellStockDeliveryId,
    resultNotificationId,
  };
}
