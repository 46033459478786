import {
  messageId,
  PropertyMeta,
  EntityMeta,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { reviewIdMeta } from "../value/review-id";
import { documentIdMeta } from "../value/document-id";
import { reviewTypeMeta } from "../value/review-type";
import { yearMonthDayMeta } from "../value/year-month-day";
import { reviewResultMeta } from "../value/review-result";
import { unapprovalReasonMeta } from "../value/unapproval-reason";
import { freeTextMeta } from "../value/free-text";
import { executiveDirectorApprovalMeta } from "../value/executive-director-approval";
import { reviewStateMeta } from "../value/review-state";
import { documentSnapshotIdMeta } from "../value/document-snapshot-id";
import { reviewConferenceIdMeta } from "../value/review-conference-id";
import { attachmentFileInformationMeta } from "../value/attachment-file-information";
import { creationMetadataMeta } from "../value/creation-metadata";
import { updateMetadataMeta } from "../value/update-metadata";
/**
 * 審査(エンティティ)
 *
 *  */
export type Review = z.infer<typeof schema>;
export type ReviewSaved = z.infer<typeof savedSchema>;
const minimalSchema = z.object({
  id: reviewIdMeta.minimalSchema.optional(),
  documentId: documentIdMeta.minimalSchema,
  reviewType: reviewTypeMeta.minimalSchema,
  reviewDate: yearMonthDayMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  reviewResult: reviewResultMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  unapprovalReason: unapprovalReasonMeta.minimalSchema,
  note: freeTextMeta.minimalSchema,
  executiveDirectorApproval: executiveDirectorApprovalMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  reviewState: reviewStateMeta.minimalSchema,
  reviewResultConfirmationDate: yearMonthDayMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  documentSnapshotIdOnConclusion: documentSnapshotIdMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  reviewConferenceId: reviewConferenceIdMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  officeChecklist: z.array(attachmentFileInformationMeta.minimalSchema),
  created: creationMetadataMeta.minimalSchema,
  updated: updateMetadataMeta.minimalSchema,
});
const savedMinimalSchema = z.object({
  id: reviewIdMeta.minimalSchema,
  documentId: documentIdMeta.savedMinimalSchema,
  reviewType: reviewTypeMeta.savedMinimalSchema,
  reviewDate: yearMonthDayMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  reviewResult: reviewResultMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  unapprovalReason: unapprovalReasonMeta.savedMinimalSchema,
  note: freeTextMeta.savedMinimalSchema,
  executiveDirectorApproval: executiveDirectorApprovalMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  reviewState: reviewStateMeta.savedMinimalSchema,
  reviewResultConfirmationDate: yearMonthDayMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  documentSnapshotIdOnConclusion: documentSnapshotIdMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  reviewConferenceId: reviewConferenceIdMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  officeChecklist: z.array(attachmentFileInformationMeta.savedMinimalSchema),
  created: creationMetadataMeta.savedMinimalSchema,
  updated: updateMetadataMeta.savedMinimalSchema,
});
const schema = z.object({
  id: reviewIdMeta.minimalSchema.optional(),
  documentId: documentIdMeta.schema,
  reviewType: reviewTypeMeta.schema,
  reviewDate: yearMonthDayMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
  reviewResult: reviewResultMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
  unapprovalReason: unapprovalReasonMeta.schema,
  note: freeTextMeta.schema,
  executiveDirectorApproval: executiveDirectorApprovalMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
  reviewState: reviewStateMeta.schema,
  reviewResultConfirmationDate: yearMonthDayMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
  documentSnapshotIdOnConclusion: documentSnapshotIdMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
  reviewConferenceId: reviewConferenceIdMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
  officeChecklist: z.array(attachmentFileInformationMeta.schema),
  created: creationMetadataMeta.schema,
  updated: updateMetadataMeta.schema,
});
const savedSchema = z.object({
  id: reviewIdMeta.minimalSchema,
  documentId: documentIdMeta.savedSchema,
  reviewType: reviewTypeMeta.savedSchema,
  reviewDate: yearMonthDayMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  reviewResult: reviewResultMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  unapprovalReason: unapprovalReasonMeta.savedSchema,
  note: freeTextMeta.savedSchema,
  executiveDirectorApproval: executiveDirectorApprovalMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  reviewState: reviewStateMeta.savedSchema,
  reviewResultConfirmationDate: yearMonthDayMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  documentSnapshotIdOnConclusion: documentSnapshotIdMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  reviewConferenceId: reviewConferenceIdMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  officeChecklist: z.array(attachmentFileInformationMeta.savedSchema),
  created: creationMetadataMeta.savedSchema,
  updated: updateMetadataMeta.savedSchema,
});
const jsonSchema = z.object({
  id: reviewIdMeta.jsonSchema.optional(),
  documentId: documentIdMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  reviewType: reviewTypeMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  reviewDate: yearMonthDayMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  reviewResult: reviewResultMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  unapprovalReason: unapprovalReasonMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  note: freeTextMeta.jsonSchema.nullish().transform((x) => x ?? undefined),
  executiveDirectorApproval: executiveDirectorApprovalMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  reviewState: reviewStateMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  reviewResultConfirmationDate: yearMonthDayMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  documentSnapshotIdOnConclusion: documentSnapshotIdMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  reviewConferenceId: reviewConferenceIdMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  officeChecklist: z
    .array(attachmentFileInformationMeta.jsonSchema)
    .nullish()
    .transform((x) => x ?? undefined),
  created: creationMetadataMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  updated: updateMetadataMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
/**
 * 審査(エンティティ)のメタデータ。
 *
 *  */
export const reviewMeta = new EntityMeta<
  typeof schema,
  typeof savedSchema,
  typeof jsonSchema
>(
  "Review",
  "\u5BE9\u67FB",
  messageId("lbl.\u5BE9\u67FB"),
  minimalSchema,
  savedMinimalSchema,
  schema,
  savedSchema,
  jsonSchema,
  undefined,
  undefined,
  {
    id: new PropertyMeta(
      "id",
      "ID",
      messageId("lbl.ID"),
      "afterSave",
      false,
      reviewIdMeta
    ),
    documentId: new PropertyMeta(
      "documentId",
      "\u66F8\u985EID",
      messageId("lbl.\u66F8\u985EID"),
      "always",
      false,
      documentIdMeta
    ),
    reviewType: new PropertyMeta(
      "reviewType",
      "\u5BE9\u67FB\u7A2E\u5225",
      messageId("lbl.\u5BE9\u67FB\u7A2E\u5225"),
      "always",
      false,
      reviewTypeMeta
    ),
    reviewDate: new PropertyMeta(
      "reviewDate",
      "\u5BE9\u67FB\u5B9F\u65BD\u65E5",
      messageId("lbl.\u5BE9\u67FB\u5B9F\u65BD\u65E5"),
      "none",
      false,
      yearMonthDayMeta
    ),
    reviewResult: new PropertyMeta(
      "reviewResult",
      "\u5BE9\u67FB\u7D50\u679C",
      messageId("lbl.\u5BE9\u67FB\u7D50\u679C"),
      "none",
      false,
      reviewResultMeta
    ),
    unapprovalReason: new PropertyMeta(
      "unapprovalReason",
      "\u627F\u8A8D\u4EE5\u5916\u306E\u7406\u7531",
      messageId("lbl.\u627F\u8A8D\u4EE5\u5916\u306E\u7406\u7531"),
      "always",
      false,
      unapprovalReasonMeta
    ),
    note: new PropertyMeta(
      "note",
      "\u5099\u8003",
      messageId("lbl.\u5099\u8003"),
      "always",
      false,
      freeTextMeta
    ),
    executiveDirectorApproval: new PropertyMeta(
      "executiveDirectorApproval",
      "\u696D\u52D9\u57F7\u884C\u7406\u4E8B\u627F\u8A8D",
      messageId("lbl.\u696D\u52D9\u57F7\u884C\u7406\u4E8B\u627F\u8A8D"),
      "none",
      false,
      executiveDirectorApprovalMeta
    ),
    reviewState: new PropertyMeta(
      "reviewState",
      "\u5BE9\u67FB\u72B6\u614B",
      messageId("lbl.\u5BE9\u67FB\u72B6\u614B"),
      "always",
      false,
      reviewStateMeta
    ),
    reviewResultConfirmationDate: new PropertyMeta(
      "reviewResultConfirmationDate",
      "\u5BE9\u67FB\u7D50\u679C\u78BA\u5B9A\u65E5",
      messageId("lbl.\u5BE9\u67FB\u7D50\u679C\u78BA\u5B9A\u65E5"),
      "none",
      false,
      yearMonthDayMeta
    ),
    documentSnapshotIdOnConclusion: new PropertyMeta(
      "documentSnapshotIdOnConclusion",
      "\u5BE9\u67FB\u78BA\u5B9A\u6642\u66F8\u985E\u30B9\u30CA\u30C3\u30D7\u30B7\u30E7\u30C3\u30C8ID",
      messageId(
        "lbl.\u5BE9\u67FB\u78BA\u5B9A\u6642\u66F8\u985E\u30B9\u30CA\u30C3\u30D7\u30B7\u30E7\u30C3\u30C8ID"
      ),
      "none",
      false,
      documentSnapshotIdMeta
    ),
    reviewConferenceId: new PropertyMeta(
      "reviewConferenceId",
      "\u5BE9\u67FB\u4F1AID",
      messageId("lbl.\u5BE9\u67FB\u4F1AID"),
      "none",
      false,
      reviewConferenceIdMeta
    ),
    officeChecklist: new PropertyMeta(
      "officeChecklist",
      "\u7533\u8ACB\u7528\u4E8B\u52D9\u5C40\u30C1\u30A7\u30C3\u30AF\u30EA\u30B9\u30C8",
      messageId(
        "lbl.\u7533\u8ACB\u7528\u4E8B\u52D9\u5C40\u30C1\u30A7\u30C3\u30AF\u30EA\u30B9\u30C8"
      ),
      "always",
      true,
      attachmentFileInformationMeta
    ),
    created: new PropertyMeta(
      "created",
      "\u4F5C\u6210",
      messageId("lbl.\u4F5C\u6210"),
      "always",
      false,
      creationMetadataMeta
    ),
    updated: new PropertyMeta(
      "updated",
      "\u66F4\u65B0",
      messageId("lbl.\u66F4\u65B0"),
      "always",
      false,
      updateMetadataMeta
    ),
  }
);
