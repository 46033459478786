import { hasValue, isNullish } from "./common-util";
import { convertToNumberFromStr } from "./search-util";
import { format, parseISO } from "date-fns";
import { YearMonthDay } from "../object/value/year-month-day";
import { DatetimeValue } from "@pscsrvlab/psc-react-components";
import { toDate } from "date-fns-tz";
import { APP_TIME_ZONE } from "../constant/constant";
import log from "loglevel";

/**
 * 年月の情報から日付の文字列を作成して返却する。
 *
 * @param year 年
 * @param month 月
 * @param separator 区切り文字
 */
export function ymToYearMonthText(
  year: number,
  month: number,
  separator = "-",
) {
  return year.toString(10) + separator + month.toString(10).padStart(2, "0");
}

/**
 * 年月日の情報から日付の文字列を作成して返却する。
 *
 * @param year 年
 * @param month 月
 * @param day 日
 * @param separator 区切り文字
 */
export function ymdToDateText(
  { year, month, day }: { year: number; month: number; day: number },
  separator = "-",
): string {
  return (
    year.toString(10) +
    separator +
    month.toString(10).padStart(2, "0") +
    separator +
    day.toString(10).padStart(2, "0")
  );
}

export function ymdToDateTextOrNull(
  value?: { year: number; month: number; day: number } | null,
  separator = "-",
): string | null {
  if (isNullish(value)) return null;
  return ymdToDateText(value, separator);
}

/**
 * 時間の情報から日付の文字列を作成して返却する。
 */
export function hmToTimeText(
  { hour, minute }: { hour: number; minute: number },
  separator = ":",
) {
  return (
    hour.toString(10).padStart(2, "0") +
    separator +
    minute.toString(10).padStart(2, "0")
  );
}

/**
 * 年月の情報からJS Dateを作成して返却する。
 */
export function ymToJsDate({ year, month }: { year: number; month: number }) {
  // 年月指定のみでDateが作成できないため、引数で受け取った年月の1日を指定する。
  return ymdToJsDate({ year, month, day: 1 });
}

/**
 * 年月日の情報からDateを作成して返却する。
 */
export function ymdToJsDate({
  year,
  month,
  day,
}: {
  year: number;
  month: number;
  day: number;
}): Date {
  return new Date(year, month - 1, day);
}

/**
 * 年月日の情報からDateを作成して返却する。
 */
export function ymdToJsDateOrNull(
  value:
    | {
        year: number;
        month: number;
        day: number;
      }
    | null
    | undefined,
): Date | null {
  if (isNullish(value)) return null;
  return ymdToJsDate(value);
}

/**
 * yyyy-MM形式の日付文字列を受け取り、日時として利用できるか判定する。
 * 区切り文字文字を変更する場合は引数を設定すること。
 *
 * @param strDate 日付文字列
 * @param separator 区切り文字
 */
export function isYearMonth(strDate: string, separator = "-") {
  // 空文字は許容しない
  if (strDate === "") {
    return false;
  }

  // yyyy-MM形式
  const regExp = "^\\d{4}" + "\\" + separator + "\\d{1,2}$";
  if (!strDate.match(regExp)) {
    return false;
  }

  // 日付変換された日付が元の入力値と同じ事を確認
  const [year, month] = strDate.split(separator).map(Number);

  const date = new Date(strDate + separator + String(1));
  const [dYear, dMonth] = getDateYmd(date);

  return dYear === year && dMonth === month;
}

/**
 * yyyy-MM-dd形式の日付文字列を受け取り、日時として利用できるか判定する。
 * @param strDate 日付文字列
 * @param separator 区切り文字
 */
export function isDateString(strDate: string, separator = "-") {
  // 空文字は許容しない
  if (strDate === "") {
    return false;
  }

  // yyyy-MM-dd形式のみ許容
  const regExp =
    "^\\d{4}" + "\\" + separator + "\\d{1,2}" + separator + "\\d{1,2}$";
  if (!strDate.match(regExp)) {
    return false;
  }

  // 日付変換された日付が元の入力値と同じ事を確認
  const [year, month, day] = strDate.split(separator).map(Number);

  const date = new Date(strDate);
  const [dYear, dMonth, dDay] = getDateYmd(date);

  return dYear === year && dMonth === month && dDay === day;
}

export function isISO8601Text(text: string): boolean {
  try {
    parseISO(text);
    return true;
  } catch (e) {
    return false;
  }
}

/**
 * Dateを年月日に分割して返却。
 * @param date 分割対象の日付
 */
export function getDateYmd(date: Date) {
  const year: number = date.getFullYear();
  const month: number = date.getMonth() + 1;
  const day: number = date.getDate();

  return [year, month, day];
}

/**
 * 日付(年月日)の比較を行う。
 *
 * @param targetDate チェック対象の日時
 * @param baseDate 基準日
 *
 * チェック対象の日時が基準日よりも前の日時の場合:true
 * targetDate < baseDate
 */
export function isBeforeDate(targetDate: Date, baseDate: Date) {
  return targetDate.getTime() < baseDate.getTime();
}

/**
 * 日付(年月日)の比較を行う。
 *
 * @param targetDate チェック対象の日時
 * @param baseDate 基準日
 *
 * チェック対象の日時が基準日よりも後の日時の場合:true
 * targetDate > baseDate
 */
export function isAfterDate(targetDate: Date, baseDate: Date) {
  const [targetYear, targetMonth, targetDay] = getDateYmd(targetDate);
  const [baseYear, baseMonth, baseDay] = getDateYmd(baseDate);

  // 年月日の順に比較を行う。
  // 値が異なる時点で、比較結果を返却する。
  if (targetYear !== baseYear) {
    return targetYear > baseYear;
  } else if (targetMonth !== baseMonth) {
    return targetMonth > baseMonth;
  } else {
    return targetDay > baseDay;
  }
}

/**
 * 時刻の比較を行う。
 *
 * @param targetDate チェック対象の日時
 * @param baseDate 基準時刻
 *
 * チェック対象の時刻が基準時刻よりも前の日時の場合:true
 * targetDate < baseDate
 */
export function isBeforeTime(targetDate: Date, baseDate: Date) {
  const [targetHour, targetMinute] = getTimeHm(targetDate);
  const [baseHour, baseMinute] = getTimeHm(baseDate);

  // 時分の順に比較を行う。
  // 値が異なる時点で、比較結果を返却する。
  if (targetHour !== baseHour) {
    return targetHour < baseHour;
  } else {
    return targetMinute < baseMinute;
  }
}

/**
 * 時刻の比較を行う。
 *
 * @param targetDate チェック対象の日時
 * @param baseDate 基準時刻
 *
 * チェック対象の時刻が基準時刻よりも後の日時の場合:true
 * targetDate > baseDate
 */
export function isAfterTime(targetDate: Date, baseDate: Date) {
  const [targetHour, targetMinute] = getTimeHm(targetDate);
  const [baseHour, baseMinute] = getTimeHm(baseDate);

  // 時分の順に比較を行う。
  // 値が異なる時点で、比較結果を返却する。
  if (targetHour !== baseHour) {
    return targetHour > baseHour;
  } else {
    return targetMinute > baseMinute;
  }
}

/**
 * 文字列から日付情報を作成する
 *
 * @param strDate 日付
 * @param separator
 */
export function dateTextToYmd(
  strDate?: string,
  separator = "-",
):
  | {
      year: number;
      month: number;
      day: number;
    }
  | undefined {
  if (isNullish(strDate) || !isDateString(strDate)) {
    return undefined;
  }

  const [year, month, day] = strDate.split(separator);
  const y = convertToNumberFromStr(year);
  const m = convertToNumberFromStr(month);
  const d = convertToNumberFromStr(day);

  return { year: y, month: m, day: d };
}

/**
 * ymd型の日付情報を受け取って、文字列を返却する.
 * 日付情報が未定義の場合、ハイフンを返却.
 */
export const ymdToDateTextOrHyphen = (
  date:
    | {
        year: number;
        month: number;
        day: number;
      }
    | null
    | undefined,
) => {
  // 日付情報が未定義の場合
  if (isNullish(date)) {
    return "-";
  }

  return ymdToDateText(date);
};

/**
 * 日付(ymd型)を受け取って、文字列を返却する
 * 開始日時と終了日時が設定されている場合、区切り文字で結合した文字列を返却する
 *
 * @param value
 * @param separatorRange
 * @constructor
 */
export function dateRangeToText(
  value?: {
    start?: {
      year: number;
      month: number;
      day: number;
    } | null;
    end?: {
      year: number;
      month: number;
      day: number;
    } | null;
  },
  separatorRange?: string,
): string | null {
  if (isNullish(value)) {
    return null;
  }
  if (isNullish(value.start) && isNullish(value.end)) {
    return null;
  }

  const startStr = hasValue(value.start)
    ? ymdToDateTextOrHyphen(value.start)
    : undefined;

  const endStr = hasValue(value.end)
    ? ymdToDateTextOrHyphen(value.end)
    : undefined;

  if (hasValue(startStr) && hasValue(endStr)) {
    return startStr + separatorRange + endStr;
  } else if (hasValue(startStr)) {
    return startStr + separatorRange;
  } else if (hasValue(endStr)) {
    return separatorRange + endStr;
  } else {
    return "";
  }
}

export function getDateTimeStr(date: Date): string {
  return format(date, "yyyy-MM-dd HH:mm");
}

/**
 * Dateから時間を時分に分割して返却。
 * @param date 分割対象の日付・時間
 */
export function getTimeHm(date: Date) {
  const hour: number = date.getHours();
  const minute: number = date.getMinutes();

  return [hour, minute];
}

/**
 * yyyy-MM-dd形式の日付文字列を受け取り、Dateに変換する。
 * @param strDate 日付文字列
 */
export function createDateFromYmdStr(strDate?: string): Date | null {
  if (isNullish(strDate)) {
    return null;
  }

  // 日付に変換できない場合
  if (!isDateString(strDate)) {
    return null;
  }

  return new Date(strDate);
}

/**
 * JS Dateオブジェクトから、日付のテキストを作成する。
 */
export function jsDateToDateText(jsDate: Date): string {
  const year = jsDate.getFullYear();
  const month = jsDate.getMonth() + 1;
  const day = jsDate.getDate();

  return ymdToDateText({ year, month, day });
}

/**
 * JS Dateオブジェクトから、日時のテキストを作成する。
 */
export function jsDateToDatetimeText(jsDate: Date): string {
  const year = jsDate.getFullYear();
  const month = jsDate.getMonth() + 1;
  const day = jsDate.getDate();
  const hour = jsDate.getHours();
  const minute = jsDate.getMinutes();

  const dateText = ymdToDateText({ year, month, day });
  const timeText = hmToTimeText({ hour, minute });

  return `${dateText} ${timeText}`;
}

export function jsDateToYmd(jsDate: Date): YearMonthDay {
  const year = jsDate.getFullYear();
  const month = jsDate.getMonth() + 1;
  const day = jsDate.getDate();

  return { year, month, day };
}
export function jsDateToDatetime(jsDate: Date): DatetimeValue {
  return {
    date: jsDateToYmd(jsDate),
    time: { hour: jsDate.getHours(), minute: jsDate.getMinutes() },
  };
}

/**
 * JSDateの差分を、日数として返す。
 * d1(第2引数) - d0(第1引数)を返すので、d1の方が過去であれば、負の値を返す。
 * 参考: https://stackoverflow.com/a/15289883/12579447
 */
export function jsDateDiffInDays(d0: Date, d1: Date) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(d0.getFullYear(), d0.getMonth(), d0.getDate());
  const utc2 = Date.UTC(d1.getFullYear(), d1.getMonth(), d1.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export function datetimeToJsDate(value: DatetimeValue): Date {
  const text = datetimeToDatetimeText(value);
  const jsDate = toDate(text, { timeZone: APP_TIME_ZONE });
  log.debug(`datetimeToJsDate: jsDate=${jsDate.toString()}`);
  return jsDate;
}

export function datetimeToDatetimeText({ date, time }: DatetimeValue): string {
  return ymdToDateText(date) + " " + hmToTimeText(time);
}

export function datetimeToISOTextAsUTC(v: DatetimeValue): string {
  // const text = format(datetimeToJsDate(v), "yyyy-MM-dd'T'HH:mm:ss'Z'");
  const text = datetimeToJsDate(v).toISOString();
  log.debug(`datetimeToISOTextAsUTC: text=${text}`);
  return text;
}

export function isoTextToDatetimeOrNull(
  text: string | null | undefined,
): DatetimeValue | null {
  if (isNullish(text)) return null;
  try {
    const _date = parseISO(text);
    return jsDateToDatetime(_date);
  } catch (e) {
    return null;
  }
}
