import { HStack, useDisclosure } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useSearchPage } from "../../../../hooks/use-search-page";
import {
  SearchInstitutionApiArg,
  useSearchInstitutionQuery,
} from "../../../../store/api/generated/stock-request-api";
import { useContext, useMemo } from "react";
import { hasValue, isNullish } from "../../../../lib/util/common-util";
import { useSearchResultPaginationNumbers } from "../../../../hooks/use-search-result-pagination-numbers";
import {
  institutionMeta,
  InstitutionSaved,
} from "../../../../lib/object/entity/institution";
import {
  getAllEnumFromSearchParams,
  getEnumFromSearchParams,
  getNumberFromSearchParams,
  getStringFromSearchParams,
} from "../../../../lib/util/search-params-util";
import { institutionSortConditionMeta } from "../../../../lib/object/value/institution-sort-condition";
import { searchConditionLimitMeta } from "../../../../lib/object/value/search-condition-limit";
import { displayInstitutionIdMeta } from "../../../../lib/object/value/display-institution-id";
import { institutionNameMeta } from "../../../../lib/object/value/institution-name";
import { foreignTypeMeta } from "../../../../lib/object/value/foreign-type";
import { commercialTypeMeta } from "../../../../lib/object/value/commercial-type";
import { searchConditionPageNumberMeta } from "../../../../lib/object/value/search-condition-page-number";
import { SearchResultArea } from "../../../ui/search/SearchResultArea/SearchResultArea";
import { CMButton } from "@pscsrvlab/psc-react-components";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import { selectUserInfo } from "../../../../store/auth/slice";
import { SearchConditionSidebarInstitution } from "./_components/SearchConditionSidebarInstitution/SearchConditionSidebarInstitution";
import { SearchResultTableInstitution } from "./_components/SearchResultTableInstitution/SearchResultTableInstitution";
import { useAppTranslation } from "../../../../hooks/use-app-translation";
import { ConfirmationModal } from "../../../ui/modal/ConfirmationModal/ConfirmationModal";
import { stockRequestApi } from "../../../../store/api/enhanced-api";
import { downloadAsCSVFromResp } from "../../../../lib/util/app-util";
import log from "loglevel";
import { errorMessageOf } from "../../../../lib/util/error-util";
import useCustomToast from "../../../../hooks/use-custom-toast";
import { CSVDownloadButton } from "../../../ui/button/CSVDownloadButton/CSVDownloadButton";
import { AppContext } from "../../../../AppContextProvider";

export const FN80S01InstitutionSearch = () => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const currentUserInfo = useAppSelector(selectUserInfo);

  // ログインユーザーが事務局の場合,trueを設定する
  const isOfficeMember = useMemo(() => {
    if (isNullish(currentUserInfo)) return false;
    return currentUserInfo.role === "office_member";
  }, [currentUserInfo]);

  const { searchArgs, handleChangeSearchCondition, handleChangePage } =
    useSearchPage(urlSearchParamsToSearchArgs);

  /**
   * 検索処理。
   */
  const { data, isLoading } = useSearchInstitutionQuery(searchArgs);
  const entities: InstitutionSaved[] = useMemo(() => {
    if (isNullish(data)) return [];
    return data.items
      .map((v) => {
        // 案件は下書きの場合がありうるため、skipOptionalはtrueとする。
        return institutionMeta.toSavedDomainObjectOrNull(v, true);
      })
      .filter(hasValue);
  }, [data]);

  const { totalElements, currentPageNumber, totalPageCount } =
    useSearchResultPaginationNumbers(searchArgs, data);

  // 機関作成の確認モーダル制御用
  const { isOpen, onOpen, onClose } = useDisclosure();

  // CSVダウンロードの確認モーダル制御用
  const {
    isOpen: isOpenCSV,
    onClose: onCloseCSV,
    onOpen: onOpenCSV,
  } = useDisclosure();

  // CSVダウンロード開始トースト
  const { infoToast } = useCustomToast();

  // CSVダウンロード状態
  const { isInstitutionCsvDownloading, setIsInstitutionCsvDownloading } =
    useContext(AppContext);

  // 確認モーダルで確定ボタンが押された場合の処理
  const handleSubmitCreateInstitution = () => {
    onClose();
    navigate("/institution/create");
  };

  const [triggerDownloadCsv] =
    stockRequestApi.useLazyCreateInstitutionCsvQuery();
  const handleDownloadCsv = async () => {
    try {
      // トースト表示
      infoToast(t("mes.CSVダウンロード開始メッセージ"));
      // ボタンをロード中の見た目にする
      setIsInstitutionCsvDownloading(true);

      const result = await triggerDownloadCsv(searchArgs).unwrap();
      downloadAsCSVFromResp(t("lbl.機関"), result);
    } catch (e) {
      log.error(errorMessageOf(e));
    } finally {
      // ボタンをロード中の見た目から戻す
      setIsInstitutionCsvDownloading(false);
    }
  };

  return (
    <>
      <HStack flex={1} spacing={0} overflow={"hidden"} alignItems={"stretch"}>
        {/*CSVダウンロード確認モーダル*/}
        <ConfirmationModal
          isOpen={isOpenCSV}
          message={t("mes.CSVダウンロード確認メッセージ")}
          onSubmit={handleDownloadCsv}
          onCancel={onCloseCSV}
        />

        <SearchConditionSidebarInstitution
          searchArgs={searchArgs}
          onChange={handleChangeSearchCondition}
        />
        <SearchResultArea
          isLoading={isLoading}
          currentPageNumber={currentPageNumber}
          totalPageCount={totalPageCount}
          totalElements={totalElements}
          onPageChange={handleChangePage}
          upperRightElement={
            isOfficeMember && (
              <>
                <CSVDownloadButton
                  isDownLoading={isInstitutionCsvDownloading}
                  sx={{ alignSelf: "flex-end" }}
                  onClick={onOpenCSV}
                />
                <CMButton
                  alignSelf={"flex-end"}
                  size={"sm"}
                  label={t("btn.新しい機関を作成するボタン")}
                  onClick={onOpen}
                />
              </>
            )
          }
          upperElementHeight={90}
        >
          <SearchResultTableInstitution sx={{ flex: 1 }} entities={entities} />
        </SearchResultArea>
      </HStack>
      {/*機関作成の確認モーダル*/}
      <ConfirmationModal
        isOpen={isOpen}
        message={t("mes.機関作成確認メッセージ")}
        onSubmit={handleSubmitCreateInstitution}
        onCancel={onClose}
      />
    </>
  );
};

/**
 * クエリパラメータから検索条件を作成する.
 */
function urlSearchParamsToSearchArgs(
  searchParams: URLSearchParams,
): SearchInstitutionApiArg {
  const sortCondition =
    getEnumFromSearchParams(
      searchParams,
      "sortCondition",
      institutionSortConditionMeta,
    ) ?? "institution_id_asc";
  const limit =
    getNumberFromSearchParams(
      searchParams,
      "limit",
      searchConditionLimitMeta,
    ) ?? 10;

  const displayInstitutionId = getStringFromSearchParams(
    searchParams,
    "displayInstitutionId",
    displayInstitutionIdMeta.partial,
  );
  const institutionName = getStringFromSearchParams(
    searchParams,
    "institutionName",
    institutionNameMeta.partial,
  );
  const foreignType = getAllEnumFromSearchParams(
    searchParams,
    "foreignType",
    foreignTypeMeta,
  );
  const commercialType = getAllEnumFromSearchParams(
    searchParams,
    "commercialType",
    commercialTypeMeta,
  );

  const pageNumber = getNumberFromSearchParams(
    searchParams,
    "pageNumber",
    searchConditionPageNumberMeta,
  );

  return {
    sortCondition,
    limit,

    displayInstitutionId,
    institutionName,
    foreignType,
    commercialType,

    pageNumber,
  };
}
