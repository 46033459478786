import { useDisclosure, VStack } from "@chakra-ui/react";
import {
  CMFrameCard,
  CMSectionMultipleCardAddable,
  DeleteConfirmationModal,
} from "@pscsrvlab/psc-react-components";
import { SectionContentFrame } from "../../../../document/application/_components/ApplicationDocument/_components/frame/SectionContentFrame/SectionContentFrame";
import { RiDeleteBin5Line } from "react-icons/ri";
import React, { memo, useCallback, useMemo, useState } from "react";
import { ReviewTargetCard } from "../ConferenceCard/ReviewTargetCard";
import { ReviewTarget } from "../../../../../../lib/object/value/review-target";
import { useSelector } from "react-redux";
import { selectHasRole } from "../../../../../../store/auth/slice";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { FormSection } from "../../../../../ui/form/FormSection/FormSection";
import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";

export type SectionReviewConferenceCardListProps = {
  editMode: "editable" | "readOnly";

  /**
   * 審査情報リスト
   */
  reviewTargets: Partial<ReviewTarget>[];

  /**
   * 新規登録の場合true
   */
  isCreate: boolean;

  onChange: (
    change: (before: Partial<ReviewTarget>[]) => Partial<ReviewTarget>[],
  ) => void;
} & ComponentStyleProps;

export const SectionReviewConferenceCardList = memo(
  function SectionReviewConferenceCardList({
    editMode,

    reviewTargets,
    isCreate,

    onChange,

    sx,
    ...rest
  }: SectionReviewConferenceCardListProps) {
    const { t } = useAppTranslation();
    const { isOfficeMember } = useSelector(selectHasRole);

    const getEmptyReviewTarget: () => Partial<ReviewTarget> =
      useCallback(() => {
        return {
          reviewId: undefined,
          documentId: undefined,
          reviewResult: undefined,
          unapprovalReason: "",
          officeMemberNote: "",
        };
      }, []);

    /**
     * 審査対象の追加
     */
    const handleAdd = useCallback(() => {
      onChange?.((before) => [...before, getEmptyReviewTarget()]);
    }, [getEmptyReviewTarget, onChange]);

    /**
     * 変更イベント
     * @param changeIndex 変更されたCardの要素番号
     * @param changedValue 変更後の値
     */
    const handleChange = useCallback(
      (
        changeIndex: number,
        change: (before: Partial<ReviewTarget>) => Partial<ReviewTarget>,
      ) => {
        onChange?.((before) => {
          return before.map((review, index) => {
            if (index === changeIndex) {
              return change(review);
            } else {
              return review;
            }
          });
        });
      },
      [onChange],
    );

    const {
      isOpen: isOpenDeleteResearchCellModal,
      onOpen: onOpenDeleteResearchCellModal,
      onClose: onCloseDeleteResearchCellModal,
    } = useDisclosure();

    /**
     * カード削除対象のカードインデックス
     */
    const [reviewTargetDeletingIndex, setReviewTargetDeletingIndex] =
      useState(0);

    /**
     * カードの削除ボタン押下時の処理。
     * @param index
     */
    const handleResearchCellDeleteButtonClick = useCallback(
      (index: number) => {
        setReviewTargetDeletingIndex(index);
        onOpenDeleteResearchCellModal();
      },
      [onOpenDeleteResearchCellModal],
    );

    /**
     * モーダルの削除ボタン押下により、審査対象カードが削除される。
     */
    const handleResearchCellConfirmDelete = useCallback(() => {
      onChange?.((before) => {
        return before.filter((v, index) => index !== reviewTargetDeletingIndex);
      });
      onCloseDeleteResearchCellModal();
    }, [onChange, onCloseDeleteResearchCellModal, reviewTargetDeletingIndex]);

    const deleteButtonIcon = useMemo(
      () => editMode === "editable" && isOfficeMember && <RiDeleteBin5Line />,
      [editMode, isOfficeMember],
    );

    return (
      <>
        <FormSection title={t("lbl.審査対象")} sx={sx} {...rest}>
          <CMSectionMultipleCardAddable
            editMode={isOfficeMember ? editMode : "readOnly"}
            onAddSection={handleAdd}
            buttonLabel={t("btn.審査対象を追加ボタン")}
            mb={"10px"}
          >
            <VStack spacing={"10px"} alignItems={"stretch"}>
              {reviewTargets.map((v, index) => (
                <CMFrameCard
                  key={index}
                  backgroundColor={"gray.200"}
                  title={t("lbl.申請") + (index + 1).toString(10)}
                  deleteButtonIcon={deleteButtonIcon}
                  onDelete={() => handleResearchCellDeleteButtonClick(index)}
                >
                  <SectionContentFrame>
                    <ReviewTargetCard
                      index={index}
                      editMode={editMode}
                      reviewTarget={v}
                      isCreate={isCreate}
                      onChange={handleChange}
                    />
                  </SectionContentFrame>
                </CMFrameCard>
              ))}
            </VStack>
          </CMSectionMultipleCardAddable>
        </FormSection>

        {/*審査対象カード削除時に出現するモーダル*/}
        <DeleteConfirmationModal
          isOpen={isOpenDeleteResearchCellModal}
          onClose={onCloseDeleteResearchCellModal}
          title={t("lbl.確認ポップアップタイトル")}
          message={t("mes.審査対象削除確認メッセージ")}
          deleteButtonLabel={t("btn.削除ボタン")}
          cancelButtonLabel={t("btn.キャンセルボタン")}
          onConfirm={handleResearchCellConfirmDelete}
          onCancel={undefined}
        />
      </>
    );
  },
);
