import { HStack, Spacer, Text, useDisclosure } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useAbortProjectMutation } from "../../../../store/api/generated/stock-request-api";
import {
  getMessageFromEnumValue,
  hasValue,
  isNullish,
} from "../../../../lib/util/common-util";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  CMButtonBack,
  CMExtendedMenu,
  CMIndicatorStatus,
} from "@pscsrvlab/psc-react-components";
import { selectHasRole } from "../../../../store/auth/slice";
import useCustomToast from "../../../../hooks/use-custom-toast";
import { projectStateMeta } from "../../../../lib/object/value/project-state";
import { AppLink } from "../../../ui/link/AppLink/AppLink";
import { useAppParams } from "../../../../hooks/use-app-params";
import { useAppTranslation } from "../../../../hooks/use-app-translation";
import { useAppGetDocumentQuery } from "../../../../hooks/query/use-app-get-document-query";
import { useAppGetProjectQuery } from "../../../../hooks/query/use-app-get-project-query";
import { ConfirmationModal } from "../../../ui/modal/ConfirmationModal/ConfirmationModal";
import { useAppSelector } from "../../../../hooks/redux-hooks";

/**
 * FN30-S02案件ヘッダ
 */
export const FN30S02ProjectHeader = () => {
  const { t } = useAppTranslation();
  const { successToast, errorToast } = useCustomToast();
  const navigate = useNavigate();
  const { isOfficeMember } = useAppSelector(selectHasRole);

  const { isOpen, onOpen, onClose } = useDisclosure();

  // URLパラメタから案件ID, 書類IDを取得
  const { documentId, projectId: projectIdParam } = useAppParams();

  // 書類IDと書類
  // URLパラメタに書類IDがあれば、書類IDとして設定。
  const { data: document } = useAppGetDocumentQuery(
    { documentId: documentId ?? -1 },
    { skip: isNullish(documentId) },
  );

  // 案件ID
  const [projectId, setProjectId] = useState<number | null>(null);
  // URLパラメタに案件IDがあれば、案件IDとして設定。
  // URLパラメタに案件IDが無いが書類IDがあれば、書類を取得して、その書類の案件のIDを、案件IDとして設定。
  useEffect(() => {
    if (hasValue(projectIdParam)) {
      setProjectId(projectIdParam);
      return;
    }
    if (hasValue(document) && hasValue(document.projectId)) {
      setProjectId(document.projectId);
      return;
    }
  }, [document, projectIdParam]);

  // 案件情報を取得
  const { data: project } = useAppGetProjectQuery(
    { projectId: projectId ?? -1 },
    { skip: isNullish(projectId) },
  );

  // 案件中断用ミューテーション
  const [abort, _abortResult] = useAbortProjectMutation();

  // 案件中断が可能ならtrue。
  const canAbort = useMemo(() => {
    if (isNullish(project)) return false;
    return isOfficeMember && project.projectState !== "aborted";
  }, [project, isOfficeMember]);

  // 確認モーダルのタイプ
  const [modalType, setModalType] = useState<"abort">();

  // 確認モーダルに表示するメッセージ
  const modalMessage = useMemo(() => {
    switch (modalType) {
      case "abort":
        return t("mes.案件中断確認メッセージ");
      default:
        return undefined;
    }
  }, [modalType, t]);

  // 確認モーダル確定ボタンクリック時の処理
  const handleModalSubmit = useCallback(async () => {
    try {
      switch (modalType) {
        // 案件中断
        case "abort":
          if (project?.id) {
            await abort({ projectId: project.id }).unwrap();
            successToast(t("mes.案件中断メッセージ"));
          }
          break;
        default:
          break;
      }
    } catch (e) {
      errorToast(t("mes.汎用エラーメッセージ"));
    }
  }, [abort, errorToast, modalType, project?.id, successToast, t]);

  return (
    <>
      <HStack
        alignSelf="stretch"
        flex={"0 0 52px"}
        px={"12px"}
        borderBottom={"solid 1px"}
        borderColor={"gray.400"}
      >
        <CMButtonBack
          onClick={() => {
            navigate(-1);
          }}
          sx={{ p: "0px", m: "0px", w: "15px", h: "15px", minW: "unset" }}
        />
        {hasValue(project) && (
          <HStack
            spacing={"10px"}
            h={"100%"}
            color={"blue.600"}
            fontSize={"16px"}
          >
            <AppLink
              to={`/institution/${project.applicationContent.institution.institutionId}`}
            >
              <Text
                whiteSpace="nowrap"
                maxW={"300px"}
                overflow={"hidden"}
                textOverflow={"ellipsis"}
              >
                {project.applicationContent.institution.institutionName}
              </Text>
            </AppLink>
            <Text color={"gray.500"}>{t("lbl.スラッシュ")}</Text>
            <AppLink to={`/project/${project.id}`}>
              <Text
                fontWeight={700}
                whiteSpace="nowrap"
                maxW={"300px"}
                overflow={"hidden"}
                textOverflow={"ellipsis"}
              >
                {project.projectControlNumber}{" "}
                {project.applicationContent.projectName}
              </Text>
            </AppLink>
            <CMIndicatorStatus
              label={t("lbl.案件状態")}
              badgeText={getMessageFromEnumValue(
                t,
                projectStateMeta,
                project?.projectState,
              )}
              badgeColor={"blue"}
              sx={{ pl: "18px", pt: "2px" }}
            />
          </HStack>
        )}
        <Spacer />
        {canAbort && (
          <CMExtendedMenu
            size={"sm"}
            menuItems={[
              {
                key: 0,
                label: t("btn.案件を中断するボタン"),
              },
            ]}
            onClick={() => {
              setModalType("abort");
              onOpen();
            }}
            menuZIndex={30}
          />
        )}
      </HStack>
      {/* 確認用モーダル */}
      <ConfirmationModal
        isOpen={isOpen}
        message={modalMessage ?? ""}
        onSubmit={handleModalSubmit}
        onCancel={onClose}
      />
    </>
  );
};
