// 参考: https://redux-toolkit.js.org/rtk-query/usage/code-generation#usage
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { hasValue } from "../../lib/util/common-util";
import debug from "loglevel";
import { Auth } from "@aws-amplify/auth";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BACKEND_API_BASE_URL,
  mode: process.env.REACT_APP_BACKEND_QUERY_MODE as RequestMode,

  prepareHeaders: async (headers) => {
    const token = await Auth.currentSession()
      .then((session) => {
        return session.getAccessToken().getJwtToken();
      })
      .catch(() => {
        return undefined;
      });

    if (hasValue(token)) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const customBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result.error) {
    debug.error("バックエンドAPIでエラーが発生しました。", result);
    const status = result.error.status;
    // サーバー側で特定のエラーが発生した場合はエラーページへリダイレクト
    switch (status) {
      // case 403:
      case 500:
      case 503:
        window.location.href = getErrorPageUrl(status);
        break;
      case "FETCH_ERROR":
        window.location.href = getErrorPageUrl(500);
        break;
    }
  }
  return result;
};

// initialize an empty api service that we'll inject endpoints into later as needed
export const emptySplitApi = createApi({
  baseQuery: customBaseQuery,
  // 参考: https://redux-toolkit.js.org/rtk-query/usage/cache-behavior#encouraging-re-fetching-with-refetchonmountorargchange
  keepUnusedDataFor: 60,

  endpoints: () => ({}),
});

const getErrorPageUrl = (status: number) => {
  if (process.env.REACT_APP_DEVELOPMENT_MODE === "true") {
    alert(
      "エラーが発生しました。debuggerで一旦停止します。（開発モード以外ではこのアラートは表示されず、停止もしません。）",
    );
    // eslint-disable-next-line no-debugger
    debugger;
  }
  // エラーページのURLを取得
  return `/error/${status}`;
};
