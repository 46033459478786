import { useGetAnnualReportQuery } from "../../store/api/generated/stock-request-api";
import { isNullish } from "../../lib/util/common-util";
import { useMemo } from "react";
import {
  annualReportMeta,
  AnnualReportSaved,
} from "../../lib/object/entity/annual-report";

export function useAppGetAnnualReportQuery(
  arg: Parameters<typeof useGetAnnualReportQuery>[0],
  options?: Parameters<typeof useGetAnnualReportQuery>[1],
  skipOptionalValidations = true, // get系は、skipOptionalValidationsのデフォルトはtrueとする。
): {
  data: AnnualReportSaved | null;
  currentData: AnnualReportSaved | null;
  isLoading: ReturnType<typeof useGetAnnualReportQuery>["isLoading"];
  refetch: ReturnType<typeof useGetAnnualReportQuery>["refetch"];
  error: ReturnType<typeof useGetAnnualReportQuery>["error"];
} {
  const {
    data: rawData,
    currentData: rawCurrentData,
    isLoading,
    refetch,
    error,
  } = useGetAnnualReportQuery(arg, options);

  const data = useMemo(() => {
    if (isNullish(rawData)) return null;
    return annualReportMeta.toSavedDomainObjectOrNull(
      rawData,
      skipOptionalValidations,
    );
  }, [rawData, skipOptionalValidations]);
  const currentData = useMemo(() => {
    if (isNullish(rawCurrentData)) return null;
    return annualReportMeta.toSavedDomainObjectOrNull(
      rawCurrentData,
      skipOptionalValidations,
    );
  }, [rawCurrentData, skipOptionalValidations]);

  return { data, currentData, isLoading, refetch, error };
}
