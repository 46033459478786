import {
  messageId,
  PropertyMeta,
  ValueObjectMetaString,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.string().max(40);
const savedMinimalSchema = z.string().max(40);
const schema = z.string().min(1).max(40);
const savedSchema = z.string().min(1).max(40);
const jsonSchema = z.string();
/**
 * 氏名(値オブジェクト)のメタデータ。
 *
 * 氏名。 */
export const fullNameMeta = new ValueObjectMetaString(
  "FullName",
  "\u6C0F\u540D",
  messageId("lbl.\u6C0F\u540D"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
