import { Stack } from "@chakra-ui/react";
import { CMFormFileUpload } from "@pscsrvlab/psc-react-components";
import React, { memo, useCallback } from "react";
import log from "loglevel";
import {
  CloseResult,
  PromiseModalOptionsCancel,
  PromiseModalOptionsCancelProps,
} from "../../../../../ui/modal/PromiseModalOptionsCancel/PromiseModalOptionsCancel";
import { hasValue, isNullish } from "../../../../../../lib/util/common-util";
import { UploadButtonEmpty } from "../../../../../ui/button/UploadButtonEmpty/UploadButtonEmpty";
import useFileUpload from "../../../../../../hooks/use-file-upload";
import { AttachmentFileInformation } from "../../../../../../lib/object/value/attachment-file-information";
import { FileUploadItemContent } from "@pscsrvlab/psc-react-components/src/components/file-upload/types";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";
import { FormSection } from "../../../../../ui/form/FormSection/FormSection";
import { useFileModalContext } from "../../../../../ui/modal/FileModal/FileModal";

export type SectionReviewConferenceFileUploadProps = {
  editMode: "editable" | "readOnly";

  /**
   * 添付ファイル
   */
  valueAttachmentFiles: AttachmentFileInformation[];

  onChange: (
    change: (
      before: AttachmentFileInformation[],
    ) => AttachmentFileInformation[],
  ) => void;
} & ComponentStyleProps;

/**
 * FN50S02 審査会詳細
 * 添付資料セクション
 */
export const SectionReviewConferenceFileUpload = memo(
  function SectionReviewConferenceFileUpload({
    editMode,
    valueAttachmentFiles,
    onChange,

    sx,
    ...rest
  }: SectionReviewConferenceFileUploadProps) {
    const { t } = useAppTranslation();

    const { fileUpload } = useFileUpload();
    const { openFileModal } = useFileModalContext()

    const getFileList = useCallback(
      (attachmentFileType: "review_conference_attachment") => {
        const files: FileUploadItemContent[] =
          valueAttachmentFiles
            ?.filter((value) => value.attachmentFileType === attachmentFileType)
            .map((value) => {
              return {
                id: value.storageFileId.toString() ?? "",
                name: value.attachmentFileName,
                url: "",
                date: value.uploadedAt,
                deleteButtonTip: t("lbl.ファイルを削除"),
              };
            })
            .filter(hasValue) ?? [];

        return files;
      },
      [t, valueAttachmentFiles],
    );

    /**
     * ファイルアップロード処理
     */
    const handleAddFile = useCallback(
      async (
        sectionId: string,
        subSectionId: "office_checklist_for_application",
        file: File,
      ) => {
        const resp = await fileUpload(file, "private", null, null);

        if (isNullish(resp)) {
          log.error("ファイルアップロードに失敗しました。");
          return;
        }

        // アップロード済みファイルの情報を返却
        if (hasValue(resp)) {
          // 内部のファイル一覧を更新
          const newFile: AttachmentFileInformation = {
            storageFileId: resp.id,
            attachmentFileType: subSectionId,
            attachmentFileName: file.name,
            uploadedAt: resp.created.datetime,
          };

          // 呼び出し元に通知
          onChange?.((before) => [...before, newFile]);
        }
      },
      [fileUpload, onChange],
    );

    /**
     * ファイルダウンロード処理
     */
    const handleDownloadFile = useCallback(
      (fileId: string, fileName: string) => {
        // ダウンロード処理の呼び出し
        void openFileModal(fileId, fileName);
      },
      [openFileModal],
    );

    // 削除確認モーダルの表示制御設定
    const [deleteConfirmModalConfig, setDeleteConfirmModalConfig] =
      React.useState<PromiseModalOptionsCancelProps | undefined>();

    /**
     * 添付ファイル削除時に呼ばれる。
     */
    const handleDeleteFile = useCallback(
      async (sectionId: string, subSectionId: string, fileId: string) => {
        const result = await new Promise<CloseResult>((resolve) => {
          setDeleteConfirmModalConfig({
            onCloseModal: resolve,
          });
        });
        setDeleteConfirmModalConfig(undefined);
        if (result !== "OK") return;

        // 削除処理
        // 呼び出し元に通知
        onChange?.((before) =>
          before.filter((value) => value?.storageFileId?.toString() !== fileId),
        );
      },
      [onChange],
    );

    return (
      <>
        <FormSection title={t("lbl.添付資料")} sx={sx} {...rest}>
          <Stack h={"100%"} w={"100%"} spacing={"40px"} pb={"10px"}>
            <CMFormFileUpload
              onAddFile={handleAddFile}
              onDeleteFile={handleDeleteFile}
              onDownloadFile={handleDownloadFile}
              value={{
                id: "review_conference",
                title: "",
                subSectionContents: [
                  {
                    editMode: editMode,
                    id: "review_conference_attachment",
                    childrenButton: <UploadButtonEmpty />,
                    files: getFileList("review_conference_attachment"),
                  },
                ],
              }}
            />
          </Stack>
        </FormSection>

        {/*添付ファイル削除確認メッセージ*/}
        {deleteConfirmModalConfig && (
          <PromiseModalOptionsCancel
            labelBody={t("mes.添付資料削除確認メッセージ")}
            onCloseModal={deleteConfirmModalConfig.onCloseModal}
          />
        )}
      </>
    );
  },
);
