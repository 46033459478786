import { ComponentStyleProps } from "../../../../../lib/styles/props/component-style-props";
import { FormSection } from "../../../../ui/form/FormSection/FormSection";
import { useAppTranslation } from "../../../../../hooks/use-app-translation";
import React, { useMemo } from "react";
import { LabeledTextVertical } from "../../../../ui/text/LabeledTextVertical/LabeledTextVertical";
import { ExecutiveDirectorApproval } from "../../../../../lib/object/value/executive-director-approval";
import { HStack } from "@chakra-ui/react";
import { ymdToDateText } from "../../../../../lib/util/common-date-util";

export type ResultNotificationSectionExecutiveDirectorApprovalProps = {
  value: ExecutiveDirectorApproval;
} & ComponentStyleProps;

export const ResultNotificationSectionExecutiveDirectorApproval = ({
  value,

  sx,
  ...rest
}: ResultNotificationSectionExecutiveDirectorApprovalProps) => {
  const { t } = useAppTranslation();

  const approvalDateText = useMemo(
    () => ymdToDateText(value.approvalDate),
    [value.approvalDate],
  );

  return (
    <FormSection title={t("lbl.業務執行理事承認")} sx={sx} {...rest}>
      <HStack>
        <LabeledTextVertical
          label={t("lbl.承認者名")}
          text={value.fullName}
          sx={{ flex: "1 1 auto" }}
        />
        <LabeledTextVertical
          label={t("lbl.承認年月日")}
          text={approvalDateText}
          sx={{ flex: "1 1 auto" }}
        />
      </HStack>
    </FormSection>
  );
};
