import {
  messageId,
  PropertyMeta,
  ValueObjectMetaComposite,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { annualReportContentCustomValidation } from "../custom-validation/annual-report-content";
import { projectNameMeta } from "../value/project-name";
import { institutionInformationMeta } from "../value/institution-information";
import { userInformationMeta } from "../value/user-information";
import { usedCellMeta } from "../value/used-cell";
import { yearMonthDayMeta } from "../value/year-month-day";
import { attachmentFileInformationMeta } from "../value/attachment-file-information";
import { collaborativePartnerOnReportMeta } from "../value/collaborative-partner-on-report";
import { differentiatedCellProvisionTypeMeta } from "../value/differentiated-cell-provision-type";
import { differentiatedCellProvisionMeta } from "../value/differentiated-cell-provision";
import { progressResultOutlineMeta } from "../value/progress-result-outline";
/**
 * 年次報告内容(値オブジェクト)
 *
 *  */
export type AnnualReportContent = z.infer<typeof schema>;
export type AnnualReportContentSaved = z.infer<typeof savedSchema>;
const minimalSchema = z.object({
  projectName: projectNameMeta.minimalSchema,
  institution: institutionInformationMeta.minimalSchema,
  principalInvestigator: userInformationMeta.minimalSchema,
  usedResearchCells: z.array(usedCellMeta.minimalSchema),
  usedClinicalCells: z.array(usedCellMeta.minimalSchema),
  reportPeriodStartDate: yearMonthDayMeta.minimalSchema,
  reportPeriodEndDate: yearMonthDayMeta.minimalSchema,
  attachmentFiles: z.array(attachmentFileInformationMeta.minimalSchema),
  partners: z.array(collaborativePartnerOnReportMeta.minimalSchema),
  cellProvisionType: differentiatedCellProvisionTypeMeta.minimalSchema,
  cellProvisions: z.array(differentiatedCellProvisionMeta.minimalSchema),
  progressResultOutline: progressResultOutlineMeta.minimalSchema,
});
const savedMinimalSchema = z.object({
  projectName: projectNameMeta.savedMinimalSchema,
  institution: institutionInformationMeta.savedMinimalSchema,
  principalInvestigator: userInformationMeta.savedMinimalSchema,
  usedResearchCells: z.array(usedCellMeta.savedMinimalSchema),
  usedClinicalCells: z.array(usedCellMeta.savedMinimalSchema),
  reportPeriodStartDate: yearMonthDayMeta.savedMinimalSchema,
  reportPeriodEndDate: yearMonthDayMeta.savedMinimalSchema,
  attachmentFiles: z.array(attachmentFileInformationMeta.savedMinimalSchema),
  partners: z.array(collaborativePartnerOnReportMeta.savedMinimalSchema),
  cellProvisionType: differentiatedCellProvisionTypeMeta.savedMinimalSchema,
  cellProvisions: z.array(differentiatedCellProvisionMeta.savedMinimalSchema),
  progressResultOutline: progressResultOutlineMeta.savedMinimalSchema,
});
const schema = z.object({
  projectName: projectNameMeta.schema,
  institution: institutionInformationMeta.schema,
  principalInvestigator: userInformationMeta.schema,
  usedResearchCells: z.array(usedCellMeta.schema),
  usedClinicalCells: z.array(usedCellMeta.schema),
  reportPeriodStartDate: yearMonthDayMeta.schema,
  reportPeriodEndDate: yearMonthDayMeta.schema,
  attachmentFiles: z.array(attachmentFileInformationMeta.schema),
  partners: z.array(collaborativePartnerOnReportMeta.schema),
  cellProvisionType: differentiatedCellProvisionTypeMeta.schema,
  cellProvisions: z.array(differentiatedCellProvisionMeta.schema),
  progressResultOutline: progressResultOutlineMeta.schema,
});
const savedSchema = z.object({
  projectName: projectNameMeta.savedSchema,
  institution: institutionInformationMeta.savedSchema,
  principalInvestigator: userInformationMeta.savedSchema,
  usedResearchCells: z.array(usedCellMeta.savedSchema),
  usedClinicalCells: z.array(usedCellMeta.savedSchema),
  reportPeriodStartDate: yearMonthDayMeta.savedSchema,
  reportPeriodEndDate: yearMonthDayMeta.savedSchema,
  attachmentFiles: z.array(attachmentFileInformationMeta.savedSchema),
  partners: z.array(collaborativePartnerOnReportMeta.savedSchema),
  cellProvisionType: differentiatedCellProvisionTypeMeta.savedSchema,
  cellProvisions: z.array(differentiatedCellProvisionMeta.savedSchema),
  progressResultOutline: progressResultOutlineMeta.savedSchema,
});
const jsonSchema = z.object({
  projectName: projectNameMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  institution: institutionInformationMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  principalInvestigator: userInformationMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  usedResearchCells: z
    .array(usedCellMeta.jsonSchema)
    .nullish()
    .transform((x) => x ?? undefined),
  usedClinicalCells: z
    .array(usedCellMeta.jsonSchema)
    .nullish()
    .transform((x) => x ?? undefined),
  reportPeriodStartDate: yearMonthDayMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  reportPeriodEndDate: yearMonthDayMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  attachmentFiles: z
    .array(attachmentFileInformationMeta.jsonSchema)
    .nullish()
    .transform((x) => x ?? undefined),
  partners: z
    .array(collaborativePartnerOnReportMeta.jsonSchema)
    .nullish()
    .transform((x) => x ?? undefined),
  cellProvisionType: differentiatedCellProvisionTypeMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  cellProvisions: z
    .array(differentiatedCellProvisionMeta.jsonSchema)
    .nullish()
    .transform((x) => x ?? undefined),
  progressResultOutline: progressResultOutlineMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
/**
 * 年次報告内容(値オブジェクト)のメタデータ。
 *
 *
 *
 * 以下のカスタムバリデーションを、 annualReportContentCustomValidation にて行う。
 * - 分化細胞の提供先有無が有の場合は分化細胞の提供先を必須とする。 (エラーメッセージID=mes.分化細胞必須エラー)
 * - 使用する細胞(研究用)には研究用株のみが、使用する細胞(臨床用)には臨床用株のみが設定されている。 (エラーメッセージID=mes.一般エラーメッセージ) */
export const annualReportContentMeta = new ValueObjectMetaComposite(
  "AnnualReportContent",
  "\u5E74\u6B21\u5831\u544A\u5185\u5BB9",
  messageId("lbl.\u5E74\u6B21\u5831\u544A\u5185\u5BB9"),
  minimalSchema,
  savedMinimalSchema,
  schema,
  savedSchema,
  jsonSchema,
  undefined,
  annualReportContentCustomValidation,
  {
    projectName: new PropertyMeta(
      "projectName",
      "\u8AB2\u984C\u540D",
      messageId("lbl.\u8AB2\u984C\u540D"),
      "always",
      false,
      projectNameMeta
    ),
    institution: new PropertyMeta(
      "institution",
      "\u6A5F\u95A2",
      messageId("lbl.\u6A5F\u95A2"),
      "always",
      false,
      institutionInformationMeta
    ),
    principalInvestigator: new PropertyMeta(
      "principalInvestigator",
      "\u7814\u7A76\u8CAC\u4EFB\u8005",
      messageId("lbl.\u7814\u7A76\u8CAC\u4EFB\u8005"),
      "always",
      false,
      userInformationMeta
    ),
    usedResearchCells: new PropertyMeta(
      "usedResearchCells",
      "\u4F7F\u7528\u3059\u308B\u7D30\u80DE(\u7814\u7A76\u7528)",
      messageId("lbl.\u4F7F\u7528\u3059\u308B\u7D30\u80DE(\u7814\u7A76\u7528)"),
      "always",
      true,
      usedCellMeta
    ),
    usedClinicalCells: new PropertyMeta(
      "usedClinicalCells",
      "\u4F7F\u7528\u3059\u308B\u7D30\u80DE(\u81E8\u5E8A\u7528)",
      messageId("lbl.\u4F7F\u7528\u3059\u308B\u7D30\u80DE(\u81E8\u5E8A\u7528)"),
      "always",
      true,
      usedCellMeta
    ),
    reportPeriodStartDate: new PropertyMeta(
      "reportPeriodStartDate",
      "\u5831\u544A\u5BFE\u8C61\u671F\u9593\u958B\u59CB\u65E5",
      messageId("lbl.\u5831\u544A\u5BFE\u8C61\u671F\u9593\u958B\u59CB\u65E5"),
      "always",
      false,
      yearMonthDayMeta
    ),
    reportPeriodEndDate: new PropertyMeta(
      "reportPeriodEndDate",
      "\u5831\u544A\u5BFE\u8C61\u671F\u9593\u7D42\u4E86\u65E5",
      messageId("lbl.\u5831\u544A\u5BFE\u8C61\u671F\u9593\u7D42\u4E86\u65E5"),
      "always",
      false,
      yearMonthDayMeta
    ),
    attachmentFiles: new PropertyMeta(
      "attachmentFiles",
      "\u6DFB\u4ED8\u8CC7\u6599",
      messageId("lbl.\u6DFB\u4ED8\u8CC7\u6599"),
      "always",
      true,
      attachmentFileInformationMeta
    ),
    partners: new PropertyMeta(
      "partners",
      "\u5206\u62C5\u6A5F\u95A2",
      messageId("lbl.\u5206\u62C5\u6A5F\u95A2"),
      "always",
      true,
      collaborativePartnerOnReportMeta
    ),
    cellProvisionType: new PropertyMeta(
      "cellProvisionType",
      "\u5206\u5316\u7D30\u80DE\u63D0\u4F9B\u5148\u6709\u7121",
      messageId("lbl.\u5206\u5316\u7D30\u80DE\u63D0\u4F9B\u5148\u6709\u7121"),
      "always",
      false,
      differentiatedCellProvisionTypeMeta
    ),
    cellProvisions: new PropertyMeta(
      "cellProvisions",
      "\u5206\u5316\u7D30\u80DE\u63D0\u4F9B\u5148",
      messageId("lbl.\u5206\u5316\u7D30\u80DE\u63D0\u4F9B\u5148"),
      "always",
      true,
      differentiatedCellProvisionMeta
    ),
    progressResultOutline: new PropertyMeta(
      "progressResultOutline",
      "\u9032\u6357\u72B6\u6CC1\u30FB\u7D50\u679C\u6982\u8981",
      messageId("lbl.\u9032\u6357\u72B6\u6CC1\u30FB\u7D50\u679C\u6982\u8981"),
      "always",
      false,
      progressResultOutlineMeta
    ),
  }
);
