import log from "loglevel";
import { hasValue, isNullish } from "../lib/util/common-util";
import useCustomToast from "./use-custom-toast";
import { StorageFileSaved } from "../lib/object/entity/storage-file";
import useConfigFile from "./use-config-file";
import { FilePrivacyType } from "../lib/object/value/file-privacy-type";
import { useAppTranslation } from "./use-app-translation";
import { Auth } from "@aws-amplify/auth";
import { errorMessageOf } from "../lib/util/error-util";
import { useCallback, useMemo } from "react";

const useFileUpload = () => {
  const { t } = useAppTranslation();
  const { successToast, errorToast } = useCustomToast();

  // 接続先情報
  const baseUrl = useMemo(() => process.env.REACT_APP_BACKEND_API_BASE_URL, []);

  const { uploadableFileExtension, maxFileSizeByte } = useConfigFile();

  /**
   * ファイル名のバリデーションを行う。
   * @param file チェック対象のファイル
   */
  const fileNameValidate = useCallback((file: File): "ok" | "error" => {
    if (file.name.length <= 100) {
      return "ok";
    } else {
      return "error";
    }
  }, []);

  /**
   * ファイル拡張子のバリデーションを行う。
   * @param file チェック対象のファイル
   */
  const fileExtensionValidate = useCallback(
    (file: File): "ok" | "error" => {
      // 設定値が取得できない場合はチェックを省略
      if (
        isNullish(uploadableFileExtension) ||
        uploadableFileExtension.length <= 0
      ) {
        return "ok";
      }
      // ファイルの拡張子に一致する拡張子を抽出
      const element = uploadableFileExtension?.find((extension) => {
        const fileExtension = file.name.split(".").pop();
        return fileExtension === extension;
      });
      // 許可された拡張子のいずれかに該当する場合は ok を返却
      // 許可された拡張子のいずれにも該当しなかった場合は error を返却
      return hasValue(element) ? "ok" : "error";
    },
    [uploadableFileExtension],
  );
  /**
   * ファイルサイズのバリデーションを行う。
   * @param file チェック対象のファイル
   */
  const fileSizeValidate = useCallback(
    (file: File): "ok" | "error" => {
      // 設定値が取得できない場合はチェックを省略
      if (isNullish(maxFileSizeByte)) {
        return "ok";
      }
      return file.size <= maxFileSizeByte ? "ok" : "error";
    },
    [maxFileSizeByte],
  );

  /**
   * ファイルアップロードを行う。
   * 成功または失敗時に、トーストを表示する。
   * @param file アップロードするファイル。
   * @param filePrivacyType 財団内プライベートならprivate, 申請者(の少なくとも一部)にも公開するならpublic。
   * @param institutionId publicの場合に、本ファイルをダウンロードできる申請者をこの機関に絞りたければ、設定する。
   * @param projectId publicの場合に、本ファイルをダウンロードできる申請者をこの案件に絞りたければ、設定する。
   * @param suppressSuccessToast 成功時のトーストを表示したくない場合、trueを設定する。
   */
  const fileUpload = useCallback(
    async (
      file: File,
      filePrivacyType: FilePrivacyType,
      institutionId: number | null,
      projectId: number | null,

      suppressSuccessToast = false,
    ): Promise<StorageFileSaved | null> => {
      if (fileNameValidate(file) === "error") {
        errorToast(t("mes.添付資料名文字数超過エラーメッセージ"));
        return null;
      }
      // 許可されていないファイル拡張子の場合はエラー
      if (fileExtensionValidate(file) === "error") {
        errorToast(t("mes.添付資料拡張子エラーメッセージ"));
        return null;
      }
      if (fileSizeValidate(file) === "error") {
        errorToast(t("mes.添付資料サイズ超過エラーメッセージ"));
        return null;
      }

      const errorMessage = t("mes.ファイルアップロードエラーメッセージ");

      const accessToken = await Auth.currentSession()
        .then((session) => {
          return session.getAccessToken().getJwtToken();
        })
        .catch(() => {
          return undefined;
        });

      // 接続情報が設定されていない場合は処理を行わない
      if (isNullish(accessToken) || isNullish(baseUrl)) {
        log.error("useFileUpload", "settings error");
        log.debug(
          "settings error",
          `accessToken=${accessToken}`,
          `baseUrl=${baseUrl}`,
        );
        errorToast(errorMessage);
        return null;
      }

      try {
        // API用のデータに整形する
        const data = new FormData();
        data.append("file", file);
        data.append("filePrivacyType", filePrivacyType);
        if (hasValue(institutionId)) {
          data.append("institutionId", institutionId.toString(10));
        }
        if (hasValue(projectId)) {
          data.append("projectId", projectId.toString(10));
        }

        // ファイルアップロードAPIの呼び出し
        const response = await fetch(`${baseUrl}/storage-files:upload`, {
          method: "POST",
          headers: new Headers({
            Authorization: `Bearer ${accessToken}`,
          }),
          body: data,
        });

        // レスポンスがNGの場合は処理を行わない
        if (!response.ok) {
          errorToast(errorMessage);
          log.error("useFileUpload", `response status=${response.status}`);

          return null;
        }

        // レスポンスデータをjson形式に変換する
        const responseJson = await response.json();

        const addedFileResponse: StorageFileSaved = {
          id: responseJson.id,
          storageSideFolderId: responseJson.storageSideFolderId,
          storageSideFileId: responseJson.storageSideFileId,
          filePrivacyType: responseJson.filePrivacyType,
          projectId: responseJson.projectId,
          created: {
            appUserId: responseJson.created.appUserId,
            datetime: new Date(responseJson.created.datetime),
          },
          updated: {
            appUserId: responseJson.updated.appUserId,
            datetime: new Date(responseJson.updated.datetime),
          },
        };

        if (!suppressSuccessToast)
          successToast(t("mes.ファイルアップロード成功メッセージ"));

        return addedFileResponse;
      } catch (e) {
        log.error(errorMessageOf(e));
        errorToast(errorMessage);
      }

      return null;
    },
    [
      baseUrl,
      errorToast,
      fileExtensionValidate,
      fileNameValidate,
      fileSizeValidate,
      successToast,
      t,
    ],
  );

  return { fileUpload };
};
export default useFileUpload;
