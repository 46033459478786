import {
  messageId,
  PropertyMeta,
  ValueObjectMetaComposite,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { groupLocalKeyMeta } from "../value/group-local-key";
import { appUserIdMeta } from "../value/app-user-id";
import { displayUserIdMeta } from "../value/display-user-id";
import { roleMeta } from "../value/role";
import { fullNameMeta } from "../value/full-name";
import { fullNameKanaMeta } from "../value/full-name-kana";
import { titleAndPositionMeta } from "../value/title-and-position";
import { phoneNumberMeta } from "../value/phone-number";
import { mailAddressMeta } from "../value/mail-address";
/**
 * キー付きユーザー情報(値オブジェクト)
 *
 *  */
export type UserInformationKeyed = z.infer<typeof schema>;
export type UserInformationKeyedSaved = z.infer<typeof savedSchema>;
const minimalSchema = z.object({
  key: groupLocalKeyMeta.minimalSchema,
  appUserId: appUserIdMeta.minimalSchema,
  displayUserId: displayUserIdMeta.minimalSchema,
  role: roleMeta.minimalSchema,
  fullName: fullNameMeta.minimalSchema,
  fullNameKana: fullNameKanaMeta.minimalSchema,
  titleAndPosition: titleAndPositionMeta.minimalSchema,
  phoneNumber: phoneNumberMeta.minimalSchema,
  mailAddress: mailAddressMeta.minimalSchema,
});
const savedMinimalSchema = z.object({
  key: groupLocalKeyMeta.savedMinimalSchema,
  appUserId: appUserIdMeta.savedMinimalSchema,
  displayUserId: displayUserIdMeta.savedMinimalSchema,
  role: roleMeta.savedMinimalSchema,
  fullName: fullNameMeta.savedMinimalSchema,
  fullNameKana: fullNameKanaMeta.savedMinimalSchema,
  titleAndPosition: titleAndPositionMeta.savedMinimalSchema,
  phoneNumber: phoneNumberMeta.savedMinimalSchema,
  mailAddress: mailAddressMeta.savedMinimalSchema,
});
const schema = z.object({
  key: groupLocalKeyMeta.schema,
  appUserId: appUserIdMeta.schema,
  displayUserId: displayUserIdMeta.schema,
  role: roleMeta.schema,
  fullName: fullNameMeta.schema,
  fullNameKana: fullNameKanaMeta.schema,
  titleAndPosition: titleAndPositionMeta.schema,
  phoneNumber: phoneNumberMeta.schema,
  mailAddress: mailAddressMeta.schema,
});
const savedSchema = z.object({
  key: groupLocalKeyMeta.savedSchema,
  appUserId: appUserIdMeta.savedSchema,
  displayUserId: displayUserIdMeta.savedSchema,
  role: roleMeta.savedSchema,
  fullName: fullNameMeta.savedSchema,
  fullNameKana: fullNameKanaMeta.savedSchema,
  titleAndPosition: titleAndPositionMeta.savedSchema,
  phoneNumber: phoneNumberMeta.savedSchema,
  mailAddress: mailAddressMeta.savedSchema,
});
const jsonSchema = z.object({
  key: groupLocalKeyMeta.jsonSchema.nullish().transform((x) => x ?? undefined),
  appUserId: appUserIdMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  displayUserId: displayUserIdMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  role: roleMeta.jsonSchema.nullish().transform((x) => x ?? undefined),
  fullName: fullNameMeta.jsonSchema.nullish().transform((x) => x ?? undefined),
  fullNameKana: fullNameKanaMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  titleAndPosition: titleAndPositionMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  phoneNumber: phoneNumberMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  mailAddress: mailAddressMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
/**
 * キー付きユーザー情報(値オブジェクト)のメタデータ。
 *
 *  */
export const userInformationKeyedMeta = new ValueObjectMetaComposite(
  "UserInformationKeyed",
  "\u30AD\u30FC\u4ED8\u304D\u30E6\u30FC\u30B6\u30FC\u60C5\u5831",
  messageId("lbl.\u30AD\u30FC\u4ED8\u304D\u30E6\u30FC\u30B6\u30FC\u60C5\u5831"),
  minimalSchema,
  savedMinimalSchema,
  schema,
  savedSchema,
  jsonSchema,
  undefined,
  undefined,
  {
    key: new PropertyMeta(
      "key",
      "\u30AD\u30FC",
      messageId("lbl.\u30AD\u30FC"),
      "always",
      false,
      groupLocalKeyMeta
    ),
    appUserId: new PropertyMeta(
      "appUserId",
      "\u30E6\u30FC\u30B6\u30FCID",
      messageId("lbl.\u30E6\u30FC\u30B6\u30FCID"),
      "always",
      false,
      appUserIdMeta
    ),
    displayUserId: new PropertyMeta(
      "displayUserId",
      "\u8868\u793A\u7528\u30E6\u30FC\u30B6\u30FCID",
      messageId("lbl.\u8868\u793A\u7528\u30E6\u30FC\u30B6\u30FCID"),
      "always",
      false,
      displayUserIdMeta
    ),
    role: new PropertyMeta(
      "role",
      "\u30ED\u30FC\u30EB",
      messageId("lbl.\u30ED\u30FC\u30EB"),
      "always",
      false,
      roleMeta
    ),
    fullName: new PropertyMeta(
      "fullName",
      "\u6C0F\u540D",
      messageId("lbl.\u6C0F\u540D"),
      "always",
      false,
      fullNameMeta
    ),
    fullNameKana: new PropertyMeta(
      "fullNameKana",
      "\u6C0F\u540D\u304B\u306A",
      messageId("lbl.\u6C0F\u540D\u304B\u306A"),
      "always",
      false,
      fullNameKanaMeta
    ),
    titleAndPosition: new PropertyMeta(
      "titleAndPosition",
      "\u6240\u5C5E\u30FB\u8077\u540D",
      messageId("lbl.\u6240\u5C5E\u30FB\u8077\u540D"),
      "always",
      false,
      titleAndPositionMeta
    ),
    phoneNumber: new PropertyMeta(
      "phoneNumber",
      "\u96FB\u8A71\u756A\u53F7",
      messageId("lbl.\u96FB\u8A71\u756A\u53F7"),
      "always",
      false,
      phoneNumberMeta
    ),
    mailAddress: new PropertyMeta(
      "mailAddress",
      "\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9",
      messageId("lbl.\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9"),
      "always",
      false,
      mailAddressMeta
    ),
  }
);
