import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * 国外輸送手配業者区分(値オブジェクト)
 *
 *
 *
 * not_selected:
 * dhl:
 * world_courier:
 * fedex:
 * marken:
 * other:  */
export type ForeignDelivererType = z.infer<
  typeof foreignDelivererTypeMeta.schema
>;
/**
 * 国外輸送手配業者区分(値オブジェクト)のメタデータ。
 *
 *  */
export const foreignDelivererTypeMeta = new ValueObjectMetaEnum<
  ["not_selected", "dhl", "world_courier", "fedex", "marken", "other"]
>(
  "ForeignDelivererType",
  "\u56FD\u5916\u8F38\u9001\u624B\u914D\u696D\u8005\u533A\u5206",
  messageId("lbl.\u56FD\u5916\u8F38\u9001\u624B\u914D\u696D\u8005\u533A\u5206"),
  ["not_selected", "dhl", "world_courier", "fedex", "marken", "other"],
  {
    not_selected:
      "code.\u56FD\u5916\u8F38\u9001\u624B\u914D\u696D\u8005\u533A\u5206.\u672A\u9078\u629E",
    dhl: "code.\u56FD\u5916\u8F38\u9001\u624B\u914D\u696D\u8005\u533A\u5206.DHL",
    world_courier:
      "code.\u56FD\u5916\u8F38\u9001\u624B\u914D\u696D\u8005\u533A\u5206.World Courier",
    fedex:
      "code.\u56FD\u5916\u8F38\u9001\u624B\u914D\u696D\u8005\u533A\u5206.Fedex",
    marken:
      "code.\u56FD\u5916\u8F38\u9001\u624B\u914D\u696D\u8005\u533A\u5206.Marken",
    other:
      "code.\u56FD\u5916\u8F38\u9001\u624B\u914D\u696D\u8005\u533A\u5206.\u305D\u306E\u4ED6",
  }
);
