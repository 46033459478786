import { ComponentStyleProps } from "../../../../lib/styles/props/component-style-props";
import { ChangeBadgeProperties } from "@pscsrvlab/psc-react-components/src/lib";
import {
  CMFormInputRadio,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";

export type LabelAndEnumValueProps<T extends [string, ...string[]]> = {
  valueObjectMeta: ValueObjectMetaEnum<T>;

  label?: string;
  value?: T[number] | null;

  changeBadgeProperties?: ChangeBadgeProperties<T[number]>;
} & ComponentStyleProps;
export const LabelAndEnumValue = <T extends [string, ...string[]]>({
  valueObjectMeta,

  label,
  value,

  changeBadgeProperties,

  sx,
  ...rest
}: LabelAndEnumValueProps<T>) => {
  const capitalizedLabel = label;

  return (
    <CMFormInputRadio
      editMode={"readOnly"}
      size={"md"}
      valueObjectMeta={valueObjectMeta}
      label={capitalizedLabel}
      value={value}
      changeBadgeProperties={changeBadgeProperties}
      minW={0}
      sx={sx}
      {...rest}
    />
  );
};
