import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";
import { Divider, StackDivider, Text, VStack } from "@chakra-ui/react";
import { RevisionRequestCommentListThread } from "./_components/RevisionRequestCommentListThread/RevisionRequestCommentListThread";
import { hasValue } from "../../../../../../lib/util/common-util";
import { RevisionRequestItemViewModel } from "../../../../../../lib/object/vm/revision-request-view-model";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { Ref, useMemo } from "react";

export type RevisionRequestCommentListProps = {
  revisionMode: "readOnly" | "office_member_editable" | "applicant_editable";

  revisionRequestItems: RevisionRequestItemViewModel[];
  onSaveReply?: (path: string, value: string | null) => void;

  selectedCommentPath: string | null;

  scrollableRef: Ref<any>;
  scrollOffset: number;
} & ComponentStyleProps;

/**
 * 修正依頼コメントの一覧を表示するコンポーネント
 */
export const RevisionRequestCommentList = ({
  revisionMode,

  revisionRequestItems,
  onSaveReply,

  selectedCommentPath,

  scrollableRef,
  scrollOffset,

  sx,
  ...rest
}: RevisionRequestCommentListProps) => {
  const { t } = useAppTranslation();

  const isApplicantEditable = useMemo(
    () => revisionMode === "applicant_editable",
    [revisionMode],
  );

  const headingText = useMemo(
    () => `${revisionRequestItems.length}${t("lbl.修正依頼コメント数")}`,
    [revisionRequestItems.length, t],
  );

  return (
    <VStack
      flex={"0 0 240px"}
      alignSelf={"stretch"}
      alignItems={"stretch"}
      bgColor={"gray.50"}
      pt={"20px"}
      pb={"20px"}
      spacing={0}
      overflow={"auto"}
      borderWidth={"1px"}
      borderColor={"gray.100"}
      sx={sx}
      {...rest}
    >
      <Text pl={"20px"} pb={"12px"}>
        {headingText}
      </Text>
      <Divider borderColor="gray.300" />
      <VStack
        alignItems={"stretch"}
        spacing={0}
        divider={<StackDivider borderColor="gray.200" />}
      >
        {revisionRequestItems.map((item, index) => (
          <RevisionRequestCommentListThread
            key={index}
            isApplicantEditable={isApplicantEditable}
            item={item}
            onSaveReply={onSaveReply}
            selected={
              hasValue(selectedCommentPath) && item.path === selectedCommentPath
            }
            scrollableRef={scrollableRef}
            scrollOffset={scrollOffset}
          />
        ))}
      </VStack>
    </VStack>
  );
};
