import { HStack } from "@chakra-ui/react";
import { hasValue } from "../../../../lib/util/common-util";
import { RevisionRequestEmptyPage } from "../../../model/document/RevisionRequestPage/RevisionRequestEmptyPage";
import { RevisionRequestSideMenu } from "../../../model/document/RevisionRequestPage/_components/RevisionRequestsSideMenu/RevisionRequestsSideMenu";
import { RevisionRequestViewPage } from "../../../model/document/RevisionRequestPage/RevisionRequestViewPage";
import { RevisionRequestCreatePage } from "../../../model/document/RevisionRequestPage/RevisionRequestCreatePage";
import { useAppParams } from "../../../../hooks/use-app-params";

export type FN40S19RevisionRequestProps = {
  mode: "empty" | "view" | "create";
};

export const FN40S19RevisionRequest = ({
  mode,
}: FN40S19RevisionRequestProps) => {
  // URLパラメタから各IDを取得する。
  // 書類IDは、確実に存在する。
  // 修正依頼IDは、選択済なら存在する。
  const { documentId, revisionRequestId } = useAppParams();

  return (
    <>
      {hasValue(documentId) && (
        <HStack
          flex={1}
          minH={0}
          alignItems="stretch"
          spacing={0}
          overflow={"hidden"}
        >
          {mode === "create" ? (
            <RevisionRequestCreatePage documentId={documentId} />
          ) : (
            <>
              <RevisionRequestSideMenu
                documentId={documentId}
                selectedRevisionRequestId={revisionRequestId}
              />
              {mode === "empty" ? (
                <RevisionRequestEmptyPage />
              ) : mode === "view" && hasValue(revisionRequestId) ? (
                <RevisionRequestViewPage
                  documentId={documentId}
                  revisionRequestId={revisionRequestId}
                />
              ) : null}
            </>
          )}
        </HStack>
      )}
    </>
  );
};
