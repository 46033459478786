import { ComponentStyleProps } from "../../../../lib/styles/props/component-style-props";
import { VStack } from "@chakra-ui/react";
import { ReactNode } from "react";

export type SearchConditionSidebarProps = {
  children: ReactNode;
} & ComponentStyleProps;
export const SearchConditionSidebar = ({
  children,

  sx,
  ...rest
}: SearchConditionSidebarProps) => {
  return (
    <VStack
      backgroundColor={"gray.100"}
      pl={"8px"}
      pt={"10px"}
      pb={"270px"}
      pr={"8px"}
      overflowY={"auto"}
      // w={"240px"}
      flex={"0 0 240px"}
      alignItems={"stretch"}
      sx={sx}
      {...rest}
    >
      {children}
    </VStack>
  );
};
