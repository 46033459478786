import { RequestingCell } from "../value/requesting-cell";
import { ErrorMessageObject, messageId } from "@pscsrvlab/psc-react-components";
import { hasValue } from "../../util/common-util";

export function requestingCellCustomValidation(
  value: RequestingCell,
  skipOptional: boolean,
): ErrorMessageObject[] {
  /**
   * 「使用する細胞」セクションでカテゴリ、管理番号や提供元機関が選択されていないカードが存在する場合のバリデーション。
   */
  if (
    !skipOptional &&
    value.cellProvisionType === "from_foundation" &&
    hasValue(value.stockProviderProjectControlNumber)
  ) {
    const mesId = messageId("mes.当該細胞株入手時の管理番号エラー");
    return [{ errorMessageId: mesId }];
  }

  if (
    !skipOptional &&
    (value.cellProvisionType === "from_foundation" ||
      value.cellProvisionType === "own") &&
    hasValue(value.stockProviderOrganizationName)
  ) {
    const mesId = messageId("mes.提供元の機関名エラー");
    return [{ errorMessageId: mesId }];
  }
  return [];
}
