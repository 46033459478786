import { Flex } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { ComponentStyleProps } from "../../../../lib/styles/props/component-style-props";

export type UpperRightButtonFrameProps = {
  children?: ReactNode;
} & ComponentStyleProps;

/**
 * 画面右上に表示するボタンのフレーム
 */
export const FrameUpperRightButton = ({
  children,
  sx,
  ...rest
}: UpperRightButtonFrameProps) => {
  return (
    <Flex justifyContent={"flex-end"} pt={"20px"} pr={"20px"} sx={sx} {...rest}>
      {children}
    </Flex>
  );
};
