import { ComponentStyleProps } from "../../../../lib/styles/props/component-style-props";
import { CMFormControlHeader } from "@pscsrvlab/psc-react-components";
import { hasValue } from "../../../../lib/util/common-util";
import { AppLink } from "../../link/AppLink/AppLink";
import React, { useMemo } from "react";
import { VStack } from "@chakra-ui/react";
import { ChangeBadgeProperties } from "@pscsrvlab/psc-react-components/src/lib";

export type FormValueLinkProps = {
  label: string;
  value?: string | null;
  to?: string | null;

  changeBadgeProperties?: ChangeBadgeProperties<string>;
} & ComponentStyleProps;

export const FormValueLink = ({
  label,
  value,
  to,

  changeBadgeProperties,

  sx,
  ...rest
}: FormValueLinkProps) => {
  const valueStyles = useMemo(() => {
    const diffStyles =
      hasValue(changeBadgeProperties) &&
      value !== changeBadgeProperties.changedFrom
        ? {
            borderWidth: "1px",
            borderColor: "orange.500",
          }
        : undefined;
    return {
      ...diffStyles,
      alignSelf: "stretch",
      // w: "max-content",
    };
  }, [changeBadgeProperties, value]);

  return (
    <VStack
      maxW={"360px"}
      minW={"150px"}
      alignItems={"flex-start"}
      overflow={"hidden"}
      spacing={0}
      sx={sx}
      {...rest}
    >
      <CMFormControlHeader
        label={label}
        value={value}
        changeBadgeProperties={changeBadgeProperties}
      />
      {hasValue(value) && hasValue(to) && (
        <AppLink isExternal to={to} sx={valueStyles}>
          {value}
        </AppLink>
      )}
    </VStack>
  );
};
