import { useMemo } from "react";
import { isNullish } from "../lib/util/common-util";
import { useLocation } from "react-router-dom";

/**
 * 遷移元のパス + クエリ + ハッシュを返す。
 * ただし、遷移元が { state: { from: location } } をnavigate時に渡している前提とする。
 */
export function useFromPathnameSearchHash() {
  const location = useLocation();
  return useMemo(() => {
    const state = location.state;
    if (isNullish(state?.from)) return "/";
    return state.from.pathname + state.from.search + state.from.hash;
  }, [location]);
}
