import { useAppSelector } from "../../../../hooks/redux-hooks";
import {
  selectAuthenticationStatus,
  selectUserInfo,
} from "../../../../store/auth/slice";
import React from "react";
import { AfterLoginMainMenu } from "../AfterLoginMainMenu/AfterLoginMainMenu";
import { hasValue } from "../../../../lib/util/common-util";
import { BeforeLoginMainMenu } from "../BeforeLoginMainMenu/BeforeLoginMainMenu";

/**
 * FN00-S01メインメニュー
 */
export const FN00S01MainMenu = () => {
  const authenticationStatus = useAppSelector(selectAuthenticationStatus);
  const userInfo = useAppSelector(selectUserInfo);

  return (
    <>
      {authenticationStatus === "pending" ? null : authenticationStatus ===
        "unauthenticated" ? (
        <BeforeLoginMainMenu />
      ) : (
        hasValue(userInfo) && <AfterLoginMainMenu loginUserInfo={userInfo} />
      )}
    </>
  );
};
