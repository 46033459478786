import {
  messageId,
  PropertyMeta,
  ValueObjectMetaString,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.string().max(10000);
const savedMinimalSchema = z.string().max(10000);
const schema = z.string().max(10000);
const savedSchema = z.string().max(10000);
const jsonSchema = z.string();
/**
 * 画面表示パス(値オブジェクト)のメタデータ。
 *
 * 画面表示で用いるパス。 */
export const viewPathMeta = new ValueObjectMetaString(
  "ViewPath",
  "\u753B\u9762\u8868\u793A\u30D1\u30B9",
  messageId("lbl.\u753B\u9762\u8868\u793A\u30D1\u30B9"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
