import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";

import React, { useCallback, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useCustomToast from "../../../../../../hooks/use-custom-toast";
import { useAppSelector } from "../../../../../../hooks/redux-hooks";
import { selectUserInfo } from "../../../../../../store/auth/slice";
import { useLeaveEditingPrompt } from "../../../../../../hooks/use-leave-editing-prompt";
import {
  CMButtonBack,
  CMExtendedMenu,
  DeleteConfirmationModal,
  ValidationError,
} from "@pscsrvlab/psc-react-components";
import { hasValue } from "../../../../../../lib/util/common-util";
import { Container, HStack, useDisclosure, VStack } from "@chakra-ui/react";
import { FrameUpperLeftButton } from "../../../../../ui/frame/FrameUpperLeftButton/FrameUpperLeftButton";
import { FrameUpperRightButton } from "../../../../../ui/frame/FrameUpperRightButton/FrameUpperRightButton";
import { SaveDraftButton } from "../../../../../ui/button/SaveDraftButton/SaveDraftButton";
import { EditEndButton } from "../../../../../ui/button/EditEndButton/EditEndButton";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { ChangeApplicationViewModel } from "../../../../../../lib/object/vm/change-application-view-model";
import { ProgressStepDocumentCreate } from "../../../../../ui/progress/ProgressStepDocumentCreate/ProgressStepDocumentCreate";
import { CommonNextButton } from "../../../../../ui/button/CommonNextButton/CommonNextButton";
import { ChangeApplicationDocument } from "../ChangeApplicationDocument/ChangeApplicationDocument";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { ConfirmationModal } from "../../../../../ui/modal/ConfirmationModal/ConfirmationModal";

export type ChangeApplicationCreatePageProps = {
  projectId: number;
  savedDocumentId?: number;

  initialViewModel: ChangeApplicationViewModel;

  validationErrors: ValidationError[];

  saveChangeApplicationContent: (
    vm: ChangeApplicationViewModel,
    skipOptionalValidations: boolean,
  ) => Promise<
    | { state: "ok"; documentId: number }
    | { state: "error"; errors: ValidationError[] }
    | { state: "unexpectedError" }
  >;
  deleteChangeApplicationDraft: () => Promise<
    { state: "ok" } | { state: "unexpectedError" }
  >;
} & ComponentStyleProps;
/**
 * 変更申請作成画面
 */
export const ChangeApplicationCreatePage = ({
  projectId,
  savedDocumentId,

  initialViewModel,

  validationErrors,

  saveChangeApplicationContent,
  deleteChangeApplicationDraft,

  sx,
  ...rest
}: ChangeApplicationCreatePageProps) => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const { errorToast } = useCustomToast();
  const loginUserInfo = useAppSelector(selectUserInfo);

  /**
   * 編集中の書類の現在の値。
   */
  const [viewModel, setViewModel] =
    useState<ChangeApplicationViewModel>(initialViewModel);

  const { navigateWithoutPrompt } = useLeaveEditingPrompt();

  /**
   * 戻るボタン押下時に呼ばれる。
   */
  const handleClickBack = useCallback(() => {
    navigate(`/project/${projectId}`);
  }, [navigate, projectId]);

  /**
   * 編集終了ボタン押下時に呼ばれる。
   */
  const handleClickEditEnd = useCallback(() => {
    navigate(`/project/${projectId}`);
  }, [navigate, projectId]);

  /**
   * 下書き保存ボタン押下時、確認へ進むボタン押下時に呼ばれる。
   */
  const handleSaveDraft = useCallback(
    async (to: "back" | "confirm", skipOptional: boolean) => {
      // 変更申請下書きを新規作成、または変更申請内容を更新する。
      const resp = await saveChangeApplicationContent(viewModel, skipOptional);
      if (resp.state === "error") {
        return;
      }
      if (resp.state === "unexpectedError") {
        errorToast(t("mes.汎用エラーメッセージ"));
        return;
      }
      if (to === "back") {
        await navigateWithoutPrompt(`/project/${projectId}`);
      }
      if (to === "confirm") {
        // await sleep(200); // すぐに遷移すると、遷移後に古い値が表示されてしまうことへの対策。
        await navigateWithoutPrompt(
          `/document/create-change-application/${projectId}/confirm/${resp.documentId}`,
        );
      }
    },
    [
      errorToast,
      navigateWithoutPrompt,
      projectId,
      saveChangeApplicationContent,
      t,
      viewModel,
    ],
  );

  // 下書き削除確認モーダル制御
  const {
    isOpen: isOpenDeleteDraftModal,
    onOpen: onOpenDeleteDraftModal,
    onClose: onCloseDeleteDraftModal,
  } = useDisclosure();

  /**
   * 下書き削除ボタン押下時に呼ばれる。
   */
  const handleDeleteDraftButtonClick = useCallback(() => {
    onOpenDeleteDraftModal();
  }, [onOpenDeleteDraftModal]);

  // 下書き保存確認モーダル制御
  const {
    isOpen: isOpenSaveDraftModal,
    onOpen: onOpenSaveDraftModal,
    onClose: onCloseSaveDraftModal,
  } = useDisclosure();

  /**
   * 下書き削除モーダルを確定したときに呼ばれ、下書き削除を行う。
   */
  const handleConfirmDeleteDraft = useCallback(async () => {
    onCloseDeleteDraftModal();

    const result = await deleteChangeApplicationDraft();
    if (result.state === "unexpectedError") {
      errorToast(t("mes.下書き削除失敗エラー"));
      return;
    }
    await navigateWithoutPrompt(`/project/${projectId}`);
  }, [
    deleteChangeApplicationDraft,
    errorToast,
    navigateWithoutPrompt,
    onCloseDeleteDraftModal,
    projectId,
    t,
  ]);

  const scrollableRef = useRef(null);

  return (
    <>
      <HStack
        justifyContent={"space-between"}
        alignItems={"flex-start"}
        overflow={"auto"}
        spacing={0}
        sx={sx}
        {...rest}
        ref={scrollableRef}
      >
        <FrameUpperLeftButton sx={{ position: "sticky", top: 0 }}>
          <CMButtonBack
            onClick={handleClickBack}
            labelBack={t("btn.案件画面に戻るボタン")}
          />
        </FrameUpperLeftButton>

        <Container minW={"500px"} maxW={"720px"}>
          <VStack alignItems={"stretch"}>
            <ProgressStepDocumentCreate />
            {hasValue(loginUserInfo) && (
              <VStack alignSelf={"stretch"} mt={"18px"} pb={"100px"}>
                <ChangeApplicationDocument
                  loginUserInfo={loginUserInfo}
                  editMode={"editable"}
                  value={viewModel}
                  onChange={setViewModel}
                  validationErrors={validationErrors}
                  freezeFirstContactPerson={false}
                  scrollableRef={scrollableRef}
                  scrollOffset={60}
                />
                <CommonNextButton
                  label={t("btn.確認へ進むボタン")}
                  onClick={() => handleSaveDraft("confirm", false)}
                />
              </VStack>
            )}
          </VStack>
        </Container>

        <FrameUpperRightButton sx={{ position: "sticky", top: 0 }}>
          <HStack w={"max-content"}>
            <SaveDraftButton onClick={onOpenSaveDraftModal} />
            <EditEndButton onClick={handleClickEditEnd} />
            {hasValue(savedDocumentId) && (
              <CMExtendedMenu
                size={"sm"}
                menuItems={[
                  {
                    key: 0,
                    icon: RiDeleteBin6Fill,
                    label: t("btn.下書きを削除するボタン"),
                  },
                ]}
                onClick={handleDeleteDraftButtonClick}
              />
            )}
          </HStack>
        </FrameUpperRightButton>
      </HStack>
      <DeleteConfirmationModal
        isOpen={isOpenDeleteDraftModal}
        onClose={onCloseDeleteDraftModal}
        title={t("lbl.確認ポップアップタイトル")}
        message={t("mes.下書き削除確認メッセージ")}
        deleteButtonLabel={t("btn.削除ボタン")}
        cancelButtonLabel={t("btn.キャンセルボタン")}
        onConfirm={handleConfirmDeleteDraft}
      />
      <ConfirmationModal
        isOpen={isOpenSaveDraftModal}
        message={t("mes.変更保存確認メッセージ")}
        onSubmit={() => handleSaveDraft("back", true)}
        onCancel={onCloseSaveDraftModal}
      />
    </>
  );
};
