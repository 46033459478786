import { useMemo } from "react";
import { merge } from "lodash";
import { isNullish } from "../../../lib/util/common-util";
import { useAppGetChangeApplicationQuery } from "../../query/use-app-get-change-application-query";
import { ChangeApplicationSaved } from "../../../lib/object/entity/change-application";
import { ChangeApplicationViewModel } from "../../../lib/object/vm/change-application-view-model";
import { useChangeApplicationVM } from "./use-change-application-vm";

export function useChangeApplicationReadonlyVm(
  documentId: number | null | undefined,

  options?: {
    /**
     * skipがtrueの場合、何も行わない。
     */
    skip: boolean;
  },
): {
  currentData: ChangeApplicationSaved | null;
  vm: ChangeApplicationViewModel | null;
} {
  const _options = useMemo(() => merge({ skip: false }, options), [options]);

  const { currentData } = useAppGetChangeApplicationQuery(
    { documentId: documentId ?? -1 },
    { skip: _options.skip || isNullish(documentId) },
  );

  const vm = useChangeApplicationVM(currentData);
  return { currentData, vm };
}
