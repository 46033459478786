import {
  messageId,
  PropertyMeta,
  ValueObjectMetaString,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.string().max(100);
const savedMinimalSchema = z.string().max(100);
const schema = z.string().min(1).max(100);
const savedSchema = z.string().min(1).max(100);
const jsonSchema = z.string();
/**
 * 細胞名（日）(値オブジェクト)のメタデータ。
 *
 *  */
export const cellNameJaMeta = new ValueObjectMetaString(
  "CellNameJa",
  "\u7D30\u80DE\u540D\uFF08\u65E5\uFF09",
  messageId("lbl.\u7D30\u80DE\u540D\uFF08\u65E5\uFF09"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
