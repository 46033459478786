import {
  messageId,
  PropertyMeta,
  ValueObjectMetaNumber,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.number().min(0);
const savedMinimalSchema = z.number().min(0);
const schema = z.number().min(0);
const savedSchema = z.number().min(0);
const jsonSchema = z.number();
/**
 * 作業ログID(値オブジェクト)のメタデータ。
 *
 *  */
export const workLogIdMeta = new ValueObjectMetaNumber(
  "WorkLogId",
  "\u4F5C\u696D\u30ED\u30B0ID",
  messageId("lbl.\u4F5C\u696D\u30ED\u30B0ID"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
