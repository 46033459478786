import React, { useEffect, useMemo } from "react";
import { OfficeMemberCheckContent } from "../../../../lib/object/value/office-member-check-content";
import { hasValue, isNullish } from "../../../../lib/util/common-util";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import { selectUserInfo } from "../../../../store/auth/slice";
import { useParams } from "react-router-dom";
import { OfficeMemberCheckPage } from "../../../model/document/OfficeMemberCheck/_components/OfficeMemberCheckPage";
import { documentIdMeta } from "../../../../lib/object/value/document-id";
import log from "loglevel";
import { useAppGetNewApplicationQuery } from "../../../../hooks/query/use-app-get-new-application-query";
import { useAppGetChangeApplicationQuery } from "../../../../hooks/query/use-app-get-change-application-query";
import { useAppGetDocumentQuery } from "../../../../hooks/query/use-app-get-document-query";

export const FN40S16OfficeMemberCheck = () => {
  /**
   * urlの末尾にdocumentIdがあれば取得する。
   */
  const { documentId: documentIdParam } = useParams();
  const documentId: number | undefined = useMemo(() => {
    if (isNullish(documentIdParam)) return undefined;
    const _documentId = Number.parseInt(documentIdParam, 10);
    return documentIdMeta.toDomainObjectOrNull(_documentId) ?? undefined;
  }, [documentIdParam]);

  // 書類情報を取得
  const { data: documentData, isLoading: isLoadingDocumentData } =
    useAppGetDocumentQuery(
      {
        documentId: documentId ?? -1,
      },
      { skip: isNullish(documentId) },
    );

  // useEffect(() => {
  //   log.debug("documentData=", documentData);
  // }, [documentData]);

  // 書類タイプ
  const documentType = useMemo(() => {
    if (hasValue(documentData)) {
      return documentData.documentType;
    }
  }, [documentData]);
  // 書類状態
  const documentState = useMemo(() => {
    if (hasValue(documentData)) {
      return documentData.documentState;
    }
  }, [documentData]);

  useEffect(() => {
    log.debug(documentState);
  }, [documentState]);
  // 書類位置
  const documentPosition = useMemo(() => {
    if (hasValue(documentData)) {
      return documentData.documentPosition;
    }
  }, [documentData]);
  useEffect(() => {
    log.debug(documentPosition);
  }, [documentPosition]);

  // 新規申請内容を取得。
  const { data: newAppData } = useAppGetNewApplicationQuery(
    {
      documentId: documentId ?? -1,
    },
    {
      skip:
        isNullish(documentId) ||
        isLoadingDocumentData ||
        documentType !== "new_application",
    },
  );

  // 変更申請内容を取得。
  const { data: changeAppData } = useAppGetChangeApplicationQuery(
    {
      documentId: documentId ?? -1,
    },
    {
      skip:
        isNullish(documentId) ||
        isLoadingDocumentData ||
        documentType !== "change_application",
    },
  );

  // 事務局確認内容の初期値
  const initialOfficeMemberCheckContent = useMemo(() => {
    const domainObj =
      documentType === "new_application"
        ? newAppData?.officeMemberCheckContent
        : changeAppData?.officeMemberCheckContent;

    if (hasValue(domainObj)) {
      return domainObj;
    }
    return emptyOfficeMemberCheckContent();
  }, [newAppData, changeAppData, documentType]);

  // ログイン者情報
  const loginUserInfo = useAppSelector(selectUserInfo);

  const loginUserRole = useMemo(() => {
    return loginUserInfo?.role;
  }, [loginUserInfo]);

  return (
    <>
      {(hasValue(newAppData) || hasValue(changeAppData)) &&
        hasValue(documentId) &&
        hasValue(documentType) &&
        hasValue(documentState) &&
        hasValue(documentPosition) &&
        hasValue(loginUserRole) && (
          <OfficeMemberCheckPage
            initialOfficeMemberCheckContent={initialOfficeMemberCheckContent}
            documentId={documentId}
            documentType={documentType}
            documentState={documentState}
            documentPosition={documentPosition}
            loginUserRole={loginUserRole}
          />
        )}
    </>
  );
};

function emptyOfficeMemberCheckContent(): OfficeMemberCheckContent {
  return {
    reviewType: "unselected",
    officeChecklist: [],
    note: "",
    clinicalStocksInventoryCheckState: "unchecked",
  };
}
