import {
  messageId,
  PropertyMeta,
  ValueObjectMetaString,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.string().max(10000);
const savedMinimalSchema = z.string().max(10000);
const schema = z.string().max(10000);
const savedSchema = z.string().max(10000);
const jsonSchema = z.string();
/**
 * 承認以外の理由(値オブジェクト)のメタデータ。
 *
 *  */
export const unapprovalReasonMeta = new ValueObjectMetaString(
  "UnapprovalReason",
  "\u627F\u8A8D\u4EE5\u5916\u306E\u7406\u7531",
  messageId("lbl.\u627F\u8A8D\u4EE5\u5916\u306E\u7406\u7531"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
