import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * 作業区分(値オブジェクト)
 *
 *
 *
 * create:
 * update:
 * delete:
 * other:  */
export type WorkType = z.infer<typeof workTypeMeta.schema>;
/**
 * 作業区分(値オブジェクト)のメタデータ。
 *
 *  */
export const workTypeMeta = new ValueObjectMetaEnum<
  ["create", "update", "delete", "other"]
>(
  "WorkType",
  "\u4F5C\u696D\u533A\u5206",
  messageId("lbl.\u4F5C\u696D\u533A\u5206"),
  ["create", "update", "delete", "other"],
  {
    create: "code.\u4F5C\u696D\u533A\u5206.\u4F5C\u6210",
    update: "code.\u4F5C\u696D\u533A\u5206.\u66F4\u65B0",
    delete: "code.\u4F5C\u696D\u533A\u5206.\u524A\u9664",
    other: "code.\u4F5C\u696D\u533A\u5206.\u305D\u306E\u4ED6",
  }
);
