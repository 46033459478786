import {
  messageId,
  PropertyMeta,
  ValueObjectMetaString,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.string().max(100);
const savedMinimalSchema = z.string().max(100);
const schema = z.string().min(1).max(100);
const savedSchema = z.string().min(1).max(100);
const jsonSchema = z.string();
/**
 * 審査会名(値オブジェクト)のメタデータ。
 *
 *  */
export const reviewConferenceNameMeta = new ValueObjectMetaString(
  "ReviewConferenceName",
  "\u5BE9\u67FB\u4F1A\u540D",
  messageId("lbl.\u5BE9\u67FB\u4F1A\u540D"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
