import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * 修正依頼状態(値オブジェクト)
 *
 *
 *
 * revising:
 * revised:  */
export type RevisionRequestState = z.infer<
  typeof revisionRequestStateMeta.schema
>;
/**
 * 修正依頼状態(値オブジェクト)のメタデータ。
 *
 *  */
export const revisionRequestStateMeta = new ValueObjectMetaEnum<
  ["revising", "revised"]
>(
  "RevisionRequestState",
  "\u4FEE\u6B63\u4F9D\u983C\u72B6\u614B",
  messageId("lbl.\u4FEE\u6B63\u4F9D\u983C\u72B6\u614B"),
  ["revising", "revised"],
  {
    revising: "code.\u4FEE\u6B63\u4F9D\u983C\u72B6\u614B.\u4FEE\u6B63\u4E2D",
    revised: "code.\u4FEE\u6B63\u4F9D\u983C\u72B6\u614B.\u4FEE\u6B63\u6E08",
  }
);
