import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";
import React, { useMemo } from "react";
import { SearchMailLogApiArg } from "../../../../../../store/api/generated/stock-request-api";
import {
  convertToNumberFromResultsA,
  convertToResultsAFromNumber,
  getDefaultPeriod,
} from "../../../../../../lib/util/search-util";
import { numberOfSearchResultsAMeta } from "../../../../../../lib/object/value/number-of-search-results-a";
import { ButtonSearchConditionRadio } from "../../../../search/button/ButtonSearchConditionRadio/ButtonSearchConditionRadio";
import { ButtonSearchConditionInstitution } from "../../../../search/button/ButtonSearchConditionInstitution/ButtonSearchConditionInstitution";
import { ButtonSearchConditionProject } from "../../../../search/button/ButtonSearchConditionProject/ButtonSearchConditionProject";
import { ButtonSearchConditionDocument } from "../../../../search/button/ButtonSearchConditionDocument/ButtonSearchConditionDocument";
import { useAppSelector } from "../../../../../../hooks/redux-hooks";
import { selectHasRole } from "../../../../../../store/auth/slice";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { mailLogSortConditionMeta } from "../../../../../../lib/object/value/mail-log-sort-condition";
import { isoTextToDatetimeOrNull } from "../../../../../../lib/util/common-date-util";
import { ButtonSearchConditionCheckbox } from "../../../../search/button/ButtonSearchConditionCheckbox/ButtonSearchConditionCheckbox";
import { roleMeta } from "../../../../../../lib/object/value/role";
import { ButtonSearchConditionDatalist } from "../../../../search/button/ButtonSearchConditionDatalist/ButtonSearchConditionDatalist";
import { operationTypeMeta } from "../../../../../../lib/object/value/operation-type";
import { ButtonSearchConditionText } from "../../../../search/button/ButtonSearchConditionText/ButtonSearchConditionText";
import { SearchConditionSidebar } from "../../../../../ui/search/SearchConditionSidebar/SearchConditionSidebar";
import { SearchConditionSidebarSection } from "../../../../../ui/search/SearchConditionSidebar/_components/SearchConditionSidebarSection/SearchConditionSidebarSection";
import { ButtonSearchConditionDatetimeRange } from "../../../../search/button/ButtonSearchConditionDatetimeRange/ButtonSearchConditionDatetimeRange";

export type SearchConditionSidebarMailLogProps = {
  type: "root" | "project" | "document";

  /**
   * 検索条件
   */
  searchArgs: SearchMailLogApiArg;

  /**
   * 検索条件が変更されたタイミングで呼び出し元に値を返却する
   */
  onChange: (searchArgs: SearchMailLogApiArg) => void;
} & ComponentStyleProps;

export const SearchConditionSidebarMailLog = ({
  type,

  searchArgs,

  onChange,

  sx,
  ...rest
}: SearchConditionSidebarMailLogProps) => {
  const { t } = useAppTranslation();
  const defaultPeriod = useMemo(() => {
    const { defaultFromDatetimeValue, defaultToDatetimeValue } =
      getDefaultPeriod(30);
    return { start: defaultFromDatetimeValue, end: defaultToDatetimeValue };
  }, []);

  const { isOfficeMember } = useAppSelector(selectHasRole);

  return (
    <SearchConditionSidebar sx={sx} {...rest}>
      <SearchConditionSidebarSection>
        <ButtonSearchConditionRadio
          value={searchArgs.sortCondition}
          label={t("lbl.並び順")}
          valueObjectMeta={mailLogSortConditionMeta}
          onChange={(value) => {
            onChange?.({
              ...searchArgs,
              sortCondition: value,
            });
          }}
        />
        <ButtonSearchConditionRadio
          label={t("lbl.表示件数")}
          value={convertToResultsAFromNumber(searchArgs.limit)}
          valueObjectMeta={numberOfSearchResultsAMeta}
          onChange={(value: string | undefined) => {
            onChange?.({
              ...searchArgs,
              limit: convertToNumberFromResultsA(value),
            });
          }}
        />
        <ButtonSearchConditionDatetimeRange
          value={{
            start:
              isoTextToDatetimeOrNull(searchArgs.sendDateTimeFrom) ?? undefined,
            end:
              isoTextToDatetimeOrNull(searchArgs.sendDateTimeTo) ?? undefined,
          }}
          defaultValue={defaultPeriod}
          label={t("lbl.送信日時")}
          maxPeriodDays={30}
          onChange={(start: string | undefined, end: string | undefined) => {
            onChange({
              ...searchArgs,
              sendDateTimeFrom: start,
              sendDateTimeTo: end,
            });
          }}
        />
        <ButtonSearchConditionCheckbox
          values={searchArgs.recipientRole}
          label={t("lbl.送信先")}
          valueObjectMeta={roleMeta}
          onChange={(value) => {
            onChange({
              ...searchArgs,
              recipientRole: value,
            });
          }}
        />
        {isOfficeMember && type === "root" && (
          <ButtonSearchConditionInstitution
            value={searchArgs.institutionId}
            label={t("lbl.機関名")}
            placeholder={""}
            onChange={(value: number | null) => {
              onChange?.({
                ...searchArgs,
                institutionId: value ?? undefined,
              });
            }}
          />
        )}
        {type === "root" && (
          <ButtonSearchConditionProject
            value={searchArgs.projectId}
            label={t("lbl.案件管理番号")}
            placeholder={""}
            searchResultNone={t("mes.該当案件なしメッセージ")}
            onChange={(value: number | null) => {
              onChange({
                ...searchArgs,
                projectId: value ?? undefined,
              });
            }}
            width={"100%"}
          />
        )}
        {(type === "root" || type === "project") && (
          <ButtonSearchConditionDocument
            value={searchArgs.documentId}
            label={t("lbl.書類管理番号")}
            placeholder={""}
            searchResultNone={t("mes.該当書類なしメッセージ")}
            onChange={(value: number | null) => {
              onChange({
                ...searchArgs,
                documentId: value ?? undefined,
              });
            }}
          />
        )}
        <ButtonSearchConditionDatalist
          label={t("lbl.オペレーション名")}
          value={searchArgs.operationType}
          valueObjectMeta={operationTypeMeta}
          onChange={(value) => {
            onChange({
              ...searchArgs,
              operationType: value,
            });
          }}
        />
        <ButtonSearchConditionText
          value={searchArgs.subject}
          label={t("lbl.件名")}
          onChange={(value: string | undefined) => {
            onChange({
              ...searchArgs,
              subject: value,
            });
          }}
        />
        <ButtonSearchConditionText
          value={searchArgs.mailAddress}
          label={t("lbl.メールアドレス")}
          onChange={(value: string | undefined) => {
            onChange({
              ...searchArgs,
              mailAddress: value,
            });
          }}
        />
      </SearchConditionSidebarSection>
    </SearchConditionSidebar>
  );
};
