import { useGetResultNotificationQuery } from "../../store/api/generated/stock-request-api";
import { isNullish } from "../../lib/util/common-util";
import { useMemo } from "react";
import {
  resultNotificationMeta,
  ResultNotificationSaved,
} from "../../lib/object/entity/result-notification";

export function useAppGetResultNotificationQuery(
  arg: Parameters<typeof useGetResultNotificationQuery>[0],
  options?: Parameters<typeof useGetResultNotificationQuery>[1],
  skipOptionalValidations = true, // get系は、skipOptionalValidationsのデフォルトはtrueとする。
): {
  data: ResultNotificationSaved | null;
  refetch: ReturnType<typeof useGetResultNotificationQuery>["refetch"];
  error: ReturnType<typeof useGetResultNotificationQuery>["error"];
} {
  const {
    data: rawData,
    refetch,
    error,
  } = useGetResultNotificationQuery(arg, options);
  const data = useMemo(() => {
    if (isNullish(rawData)) return null;
    return resultNotificationMeta.toSavedDomainObjectOrNull(
      rawData,
      skipOptionalValidations,
    );
  }, [rawData, skipOptionalValidations]);
  return { data, refetch, error };
}
