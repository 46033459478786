import {
  messageId,
  PropertyMeta,
  ValueObjectMetaNumber,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.number().min(0);
const savedMinimalSchema = z.number().min(0);
const schema = z.number().min(0);
const savedSchema = z.number().min(0);
const jsonSchema = z.number();
/**
 * 審査会ID(値オブジェクト)のメタデータ。
 *
 *  */
export const reviewConferenceIdMeta = new ValueObjectMetaNumber(
  "ReviewConferenceId",
  "\u5BE9\u67FB\u4F1AID",
  messageId("lbl.\u5BE9\u67FB\u4F1AID"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
