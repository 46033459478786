import { CMButton } from "@pscsrvlab/psc-react-components";
import React, { useMemo } from "react";
import { HStack, Text, useDisclosure } from "@chakra-ui/react";
import {
  SearchReviewConferenceApiArg,
  useSearchReviewConferenceQuery,
} from "../../../../store/api/generated/stock-request-api";
import { hasValue, isNullish } from "../../../../lib/util/common-util";
import {
  reviewConferenceMeta,
  ReviewConferenceSaved,
} from "../../../../lib/object/entity/review-conference";
import { useSearchPage } from "../../../../hooks/use-search-page";
import { useNavigate } from "react-router-dom";
import { searchConditionLimitMeta } from "../../../../lib/object/value/search-condition-limit";
import { getNumberFromSearchParams } from "../../../../lib/util/search-params-util";
import { searchConditionPageNumberMeta } from "../../../../lib/object/value/search-condition-page-number";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import { selectUserInfo } from "../../../../store/auth/slice";
import { SearchResultArea } from "../../../ui/search/SearchResultArea/SearchResultArea";
import { SearchResultTableReviewConference } from "./_components/SearchResultTableReviewConference/SearchResultTableReviewConference";
import { useSearchResultPaginationNumbers } from "../../../../hooks/use-search-result-pagination-numbers";
import { useAppTranslation } from "../../../../hooks/use-app-translation";
import { ConfirmationModal } from "../../../ui/modal/ConfirmationModal/ConfirmationModal";

export const FN50S01ReviewConferenceList = () => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const currentUserInfo = useAppSelector(selectUserInfo);

  // ログインユーザーが事務局の場合,trueを設定する
  const isOfficeMember = useMemo(() => {
    if (isNullish(currentUserInfo)) return false;
    return currentUserInfo.role === "office_member";
  }, [currentUserInfo]);

  const { searchArgs, handleChangePage } = useSearchPage(
    urlSearchParamsToSearchArgs,
  );

  /**
   * 検索処理。
   */
  const { data, isLoading } = useSearchReviewConferenceQuery(searchArgs);
  const entities: { value: ReviewConferenceSaved; todo: boolean }[] =
    useMemo(() => {
      if (isNullish(data)) return [];
      return data.items
        .map((v) => {
          const value = reviewConferenceMeta.toSavedDomainObjectOrNull(
            v.reviewConference,
            true,
          );
          if (isNullish(value)) return null;
          return {
            value,
            todo: v.todo,
          };
        })
        .filter(hasValue);
    }, [data]);

  const { totalElements, currentPageNumber, totalPageCount } =
    useSearchResultPaginationNumbers(searchArgs, data);

  // 審査会作成の確認モーダル制御用
  const { isOpen, onOpen, onClose } = useDisclosure();

  // 確認モーダルで確定ボタンが押された場合の処理
  const handleSubmitCreate = () => {
    onClose();
    navigate("/review-conference/create");
  };

  return (
    <>
      <HStack flex={1} spacing={0} overflow={"hidden"} alignItems={"stretch"}>
        <SearchResultArea
          isLoading={isLoading}
          currentPageNumber={currentPageNumber}
          totalPageCount={totalPageCount}
          totalElements={totalElements}
          onPageChange={handleChangePage}
          upperLeftElement={<Text>{t("gdc.審査会一覧ガイダンス")}</Text>}
          upperRightElement={
            isOfficeMember && (
              <CMButton
                alignSelf={"flex-end"}
                size={"sm"}
                label={t("btn.審査会作成ボタン")}
                onClick={onOpen}
              />
            )
          }
        >
          <SearchResultTableReviewConference
            sx={{ flex: 1 }}
            entities={entities}
          />
        </SearchResultArea>
      </HStack>
      {/*審査会作成の確認モーダル*/}
      <ConfirmationModal
        isOpen={isOpen}
        message={t("mes.審査会作成確認メッセージ")}
        onSubmit={handleSubmitCreate}
        onCancel={onClose}
      />
    </>
  );
};

/**
 * クエリパラメータから検索条件を作成する.
 */
function urlSearchParamsToSearchArgs(
  searchParams: URLSearchParams,
): SearchReviewConferenceApiArg {
  const limit = getNumberFromSearchParams(
    searchParams,
    "limit",
    searchConditionLimitMeta,
  );

  const pageNumber = getNumberFromSearchParams(
    searchParams,
    "pageNumber",
    searchConditionPageNumberMeta,
  );

  return {
    limit,

    pageNumber,
  };
}
