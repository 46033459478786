import { ReviewDetailsPage } from "../../../model/document/ReviewDetails/ReviewDetailsPage";
import { hasValue } from "../../../../lib/util/common-util";
import { useAppParams } from "../../../../hooks/use-app-params";

export type FN40S30ReviewDetailsProps = {
  mode: "empty" | "view-or-edit";
};

/**
 * FN40-S30 審査
 */
export const FN40S30ReviewDetails = ({ mode }: FN40S30ReviewDetailsProps) => {
  // URLからprojectIdを取得
  const { documentId, reviewId } = useAppParams();

  return (
    <>
      {hasValue(documentId) && (
        <ReviewDetailsPage
          mode={mode}
          documentId={documentId}
          initialReviewId={reviewId}
        />
      )}
    </>
  );
};
