import React, { memo, useCallback } from "react";
import {
  CMFormInputText,
  ValueObjectMetaString,
} from "@pscsrvlab/psc-react-components";
import { FormSection } from "../../../../../ui/form/FormSection/FormSection";
import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";

export type SectionReviewConferenceTextProps = {
  editMode: "editable" | "readOnly";

  title?: string;

  value?: string;

  valueObjectMeta?: ValueObjectMetaString;

  onChange: (changedValue: string) => void;
} & ComponentStyleProps;

/**
 * FN50S02 審査会詳細
 * テキストセクション
 */
export const SectionReviewConferenceText = memo(
  function SectionReviewConferenceText({
    editMode,

    title,

    value,

    valueObjectMeta,
    onChange,

    sx,
    ...rest
  }: SectionReviewConferenceTextProps) {
    const handleChange = useCallback(
      (selectedValue: string) => {
        onChange?.(selectedValue);
      },
      [onChange],
    );

    return (
      <FormSection title={title} sx={sx} {...rest}>
        <CMFormInputText
          editMode={editMode}
          noHeader={true}
          value={value}
          valueObjectMeta={valueObjectMeta}
          onChange={handleChange}
          sx={{ w: "100%" }}
        />
      </FormSection>
    );
  },
);
