import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * 書類状態(値オブジェクト)
 *
 *
 *
 * draft:
 * submitted:
 * office_check:
 * withdrawn:
 * reviewing:
 * awaiting_conclusion_rereview:
 * executive_director_reviewing:
 * awaiting_conclusion:
 * awaiting_conclusion_no_review:
 * awaiting_conditions_met:
 * done:
 * aborted:  */
export type DocumentState = z.infer<typeof documentStateMeta.schema>;
/**
 * 書類状態(値オブジェクト)のメタデータ。
 *
 *  */
export const documentStateMeta = new ValueObjectMetaEnum<
  [
    "draft",
    "submitted",
    "office_check",
    "withdrawn",
    "reviewing",
    "awaiting_conclusion_rereview",
    "executive_director_reviewing",
    "awaiting_conclusion",
    "awaiting_conclusion_no_review",
    "awaiting_conditions_met",
    "done",
    "aborted"
  ]
>(
  "DocumentState",
  "\u66F8\u985E\u72B6\u614B",
  messageId("lbl.\u66F8\u985E\u72B6\u614B"),
  [
    "draft",
    "submitted",
    "office_check",
    "withdrawn",
    "reviewing",
    "awaiting_conclusion_rereview",
    "executive_director_reviewing",
    "awaiting_conclusion",
    "awaiting_conclusion_no_review",
    "awaiting_conditions_met",
    "done",
    "aborted",
  ],
  {
    draft: "code.\u66F8\u985E\u72B6\u614B.\u4E0B\u66F8\u304D",
    submitted: "code.\u66F8\u985E\u72B6\u614B.\u63D0\u51FA",
    office_check:
      "code.\u66F8\u985E\u72B6\u614B.\u4E8B\u52D9\u5C40\u78BA\u8A8D",
    withdrawn: "code.\u66F8\u985E\u72B6\u614B.\u53D6\u308A\u4E0B\u3052",
    reviewing: "code.\u66F8\u985E\u72B6\u614B.\u5BE9\u67FB",
    awaiting_conclusion_rereview:
      "code.\u66F8\u985E\u72B6\u614B.\u7D50\u679C\u901A\u77E5\u5F85(\u4FDD\u7559\u30FB\u518D\u5BE9\u67FB)",
    executive_director_reviewing:
      "code.\u66F8\u985E\u72B6\u614B.\u696D\u52D9\u57F7\u884C\u7406\u4E8B\u627F\u8A8D\u5F85",
    awaiting_conclusion:
      "code.\u66F8\u985E\u72B6\u614B.\u7D50\u679C\u901A\u77E5\u5F85",
    awaiting_conclusion_no_review:
      "code.\u66F8\u985E\u72B6\u614B.\u7D50\u679C\u901A\u77E5\u5F85(\u5BE9\u67FB\u4E0D\u8981)",
    awaiting_conditions_met:
      "code.\u66F8\u985E\u72B6\u614B.\u6761\u4EF6\u5BFE\u5FDC\u5F85",
    done: "code.\u66F8\u985E\u72B6\u614B.\u5B8C\u4E86",
    aborted: "code.\u66F8\u985E\u72B6\u614B.\u4E2D\u65AD",
  }
);
