import { CollaborativePartnerOnReport } from "../value/collaborative-partner-on-report";
import { ErrorMessageObject, messageId } from "@pscsrvlab/psc-react-components";

export function collaborativePartnerOnReportCustomValidation(
  value: CollaborativePartnerOnReport,
  skipOptional: boolean,
): ErrorMessageObject[] {
  const mesId = messageId("mes.分化細胞必須エラー");

  if (
    !skipOptional &&
    value.cellProvisionType === "provided" &&
    value.cellProvisions.length === 0
  ) {
    return [{ errorMessageId: mesId }];
  }
  return [];
}
