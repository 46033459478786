import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";
import { LoginUserInfo } from "../../../../../../store/auth/types";
import { useNavigate } from "react-router-dom";
import useCustomToast from "../../../../../../hooks/use-custom-toast";
import { HStack, useDisclosure, VStack } from "@chakra-ui/react";
import {
  CMModalOptionsCancel,
  ValidationError,
} from "@pscsrvlab/psc-react-components";
import { FrameUpperRightButton } from "../../../../../ui/frame/FrameUpperRightButton/FrameUpperRightButton";
import { SaveChangesButton } from "../../../../../ui/button/SaveChangesButton/SaveChangesButton";
import { EditEndButton } from "../../../../../ui/button/EditEndButton/EditEndButton";
import React, { useCallback, useState } from "react";
import { useLeaveEditingPrompt } from "../../../../../../hooks/use-leave-editing-prompt";
import { AnnualReportDocument } from "../AnnualReportDocument/AnnualReportDocument";
import { AnnualReportViewModel } from "../../../../../../lib/object/vm/annual-report-view-model";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { sleep } from "../../../../../../lib/util/common-util";

export type DocumentContentAnnualReportEditProps = {
  loginUserInfo: LoginUserInfo;

  /**
   * 画面表示時の書類内容の初期値。
   */
  initialViewModel: AnnualReportViewModel;

  validationErrors: ValidationError[];

  saveDocument: (
    vm: AnnualReportViewModel,
    skipOptionalValidations: boolean,
  ) => Promise<
    | { state: "ok"; documentId: number }
    | { state: "error"; errors: ValidationError[] }
    | { state: "unexpectedError" }
  >;

  /**
   * 戻り先のURLパス。
   */
  backUrlPath: string;
} & ComponentStyleProps;

export const DocumentContentAnnualReportEdit = ({
  loginUserInfo,

  initialViewModel,

  validationErrors,

  saveDocument,

  backUrlPath,

  sx,
  ...rest
}: DocumentContentAnnualReportEditProps) => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const { errorToast } = useCustomToast();

  /**
   * 編集中の書類内容の現在の値。
   */
  const [viewModel, setViewModel] =
    useState<AnnualReportViewModel>(initialViewModel);

  const { navigateWithoutPrompt } = useLeaveEditingPrompt();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleEditEnd = useCallback(() => {
    navigate(backUrlPath);
  }, [navigate, backUrlPath]);
  const handleConfirmSave = useCallback(async () => {
    const result = await saveDocument(viewModel, false);
    switch (result.state) {
      case "ok":
        await sleep(200); // すぐに遷移すると、遷移後に古い値が表示されてしまうことへの対策。
        await navigateWithoutPrompt(backUrlPath);
        break;
      case "error":
        break;
      case "unexpectedError":
        errorToast(t("mes.汎用エラーメッセージ"));
        break;
    }
  }, [
    backUrlPath,
    errorToast,
    navigateWithoutPrompt,
    saveDocument,
    t,
    viewModel,
  ]);

  return (
    <>
      <VStack alignItems={"center"} spacing={0} sx={sx} {...rest}>
        <VStack alignSelf={"stretch"}>
          <FrameUpperRightButton
            ml={"auto"}
            sx={{ position: "sticky", top: 0 }}
          >
            <HStack w={"max-content"}>
              <SaveChangesButton onClick={onOpen} />
              <EditEndButton onClick={handleEditEnd} />
            </HStack>
          </FrameUpperRightButton>
        </VStack>
        <VStack alignSelf={"stretch"} mt={"18px"} pb={"100px"}>
          <AnnualReportDocument
            loginUserInfo={loginUserInfo}
            editMode={"editable"}
            value={viewModel}
            onChange={setViewModel}
            validationErrors={validationErrors}
          />
        </VStack>
      </VStack>
      {/* 確認用モーダル */}
      <CMModalOptionsCancel
        isOpen={isOpen}
        labelHeader={t("lbl.確認ポップアップタイトル")}
        labelBody={t("mes.変更保存確認メッセージ")}
        labelSubmitButton={t("btn.OKボタン")}
        labelCancelButton={t("btn.キャンセルボタン")}
        onSubmit={handleConfirmSave}
        onCancel={onClose}
      />
    </>
  );
};
