import { useAppGetCellStockDeliveryQuery } from "../../../../../hooks/query/use-app-get-cell-stock-delivery-query";
import { hasValue, isNullish } from "../../../../../lib/util/common-util";
import { useEffect, useState } from "react";
import {
  cellStockDeliveryToViewModel,
  CellStockDeliveryViewModel,
} from "../../../../../lib/object/vm/cell-stock-delivery-view-model";
import { CellStockDeliveryPage } from "../../../../model/project/FN30S09CellStockDelivery/CellStockDeliveryPage";

export type CellStockDeliveryWrapProps = {
  cellStockDeliveryId: number;
  /**
   * 初期表示時の画面編集モード
   * ※ただし、ロールが適切でなければ強制的にreadOnlyになる。
   */
  initialEditMode?: "editable" | "readOnly";
};
export const CellStockDeliveryWrap = ({
  cellStockDeliveryId,
  initialEditMode,
}: CellStockDeliveryWrapProps) => {
  const { data: cellStockDelivery } = useAppGetCellStockDeliveryQuery({
    cellStockDeliveryId,
  });
  const [initialVM, setInitialVM] = useState<CellStockDeliveryViewModel | null>(
    null,
  );
  useEffect(() => {
    if (hasValue(initialVM) || isNullish(cellStockDelivery)) return;
    setInitialVM(cellStockDeliveryToViewModel(cellStockDelivery));
  }, [cellStockDelivery, initialVM]);

  return (
    <>
      {hasValue(initialVM) && (
        <CellStockDeliveryPage
          key={cellStockDeliveryId}
          initialVM={initialVM}
          initialEditMode={initialEditMode}
        />
      )}
    </>
  );
};
