import { HandlingAfterUse } from "../value/handling-after-use";
import { ErrorMessageObject, messageId } from "@pscsrvlab/psc-react-components";
import { hasValue, isNullish } from "../../util/common-util";

export function handlingAfterUseCustomValidation(
  value: HandlingAfterUse,
  skipOptional: boolean,
): ErrorMessageObject[] {
  if (skipOptional) return [];

  const errorMessageList: ErrorMessageObject[] = [];

  /**
   * その他が選択されているにもかかわらず、その他入力欄に値が入力されていない場合のエラー
   */
  if (
    value.type === "other" &&
    (isNullish(value.other) || value.other === "")
  ) {
    errorMessageList.push({
      errorMessageId: messageId(
        "mes.使用期間終了後の細胞の取り扱いその他未入力エラー",
      ),
    });
  }
  /**
   * その他以外が選択されているにもかかわらず、その他入力欄に値が入力されている場合のエラー
   */
  if (value.type !== "other" && hasValue(value.other)) {
    errorMessageList.push({
      errorMessageId: messageId("mes.使用終了後の取り扱いエラー"),
    });
  }

  return errorMessageList;
}
