import { useCallback, useMemo } from "react";
import { convertToSearchParamValue } from "../lib/util/app-util";
import { useSearchParams } from "react-router-dom";

export function useSearchPage<
  SearchArgs extends { limit?: number; pageNumber?: number },
>(urlSearchParamsToSearchArgs: (searchParams: URLSearchParams) => SearchArgs) {
  const _urlSearchParamsToSearchArgs = useCallback(
    urlSearchParamsToSearchArgs,
    [urlSearchParamsToSearchArgs],
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const searchArgs = useMemo(() => {
    return _urlSearchParamsToSearchArgs(searchParams);
  }, [searchParams, _urlSearchParamsToSearchArgs]);

  /**
   * searchArgsを検索条件として設定する。
   * ただし、ページ番号だけは、指定した値で上書きし、未指定であれば削除する。
   */
  const handleChangeSearchCondition = useCallback(
    (searchArgs: SearchArgs, pageNumber?: number) => {
      setSearchParams(convertToSearchParamValue({ ...searchArgs, pageNumber }));
    },
    [setSearchParams],
  );

  // ページ変更関数
  const handleChangePage = useCallback(
    (pageNumber: number) => {
      handleChangeSearchCondition(searchArgs, pageNumber);
    },
    [handleChangeSearchCondition, searchArgs],
  );

  return {
    searchArgs,
    handleChangeSearchCondition,
    handleChangePage,
  };
}
