import {
  messageId,
  PropertyMeta,
  ValueObjectMetaString,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.string().max(10000);
const savedMinimalSchema = z.string().max(10000);
const schema = z.string().min(1).max(10000);
const savedSchema = z.string().min(1).max(10000);
const jsonSchema = z.string();
/**
 * 分化細胞情報(値オブジェクト)のメタデータ。
 *
 *  */
export const differentiatedCellInformationMeta = new ValueObjectMetaString(
  "DifferentiatedCellInformation",
  "\u5206\u5316\u7D30\u80DE\u60C5\u5831",
  messageId("lbl.\u5206\u5316\u7D30\u80DE\u60C5\u5831"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
