import { Box, HStack, VStack } from "@chakra-ui/react";
import { CMButton } from "@pscsrvlab/psc-react-components";
import { hasValue, isNullish } from "../../../../lib/util/common-util";
import { useNavigate } from "react-router-dom";
import { useMemo } from "react";
import { useAppTranslation } from "../../../../hooks/use-app-translation";
import { useAppParams } from "../../../../hooks/use-app-params";
import { useAppGetDocumentQuery } from "../../../../hooks/query/use-app-get-document-query";
import { useAppGetReviewQuery } from "../../../../hooks/query/use-app-get-review-query";
import { ymdToDateText } from "../../../../lib/util/common-date-util";
import { LabeledTextVertical } from "../../../ui/text/LabeledTextVertical/LabeledTextVertical";

export const FN40S32ReviewApproved = () => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();

  const { documentId, reviewId } = useAppParams();

  //書類情報を取得
  const { data: documentData } = useAppGetDocumentQuery(
    {
      documentId: documentId ?? -1,
    },
    { skip: isNullish(documentId) },
  );

  const documentControlNumber = useMemo(() => {
    if (hasValue(documentData?.documentControlNumber)) {
      return documentData?.documentControlNumber;
    }
  }, [documentData]);

  //審査情報を取得
  const { data: reviewData } = useAppGetReviewQuery(
    {
      reviewId: reviewId ?? -1,
    },
    { skip: isNullish(reviewId) },
  );

  const approvalDate = useMemo(() => {
    if (
      isNullish(reviewData) ||
      isNullish(reviewData.executiveDirectorApproval) ||
      isNullish(reviewData.executiveDirectorApproval.approvalDate)
    )
      return undefined;
    return ymdToDateText(reviewData.executiveDirectorApproval.approvalDate);
  }, [reviewData]);

  const handleClick = () => {
    navigate("../");
  };

  return (
    <Box overflow={"auto"}>
      {hasValue(documentControlNumber) && (
        <VStack spacing={"20px"} mt={"20px"}>
          <Box fontSize={"2xl"} fontWeight={"bold"}>
            {t("mes.業務執行理事承認完了メッセージ")}
          </Box>
          <HStack>
            <LabeledTextVertical
              label={t("lbl.書類管理番号")}
              text={documentControlNumber}
            />
            <LabeledTextVertical label={t("lbl.承認日")} text={approvalDate} />
          </HStack>
          <CMButton label={t("btn.審査に戻るボタン")} onClick={handleClick} />
        </VStack>
      )}
    </Box>
  );
};
