import { isNullish } from "./common-util";
import { NumberOfSearchResultsB } from "../object/value/number-of-search-results-b";
import { NumberOfSearchResultsA } from "../object/value/number-of-search-results-a";
import { NumberOfSearchResultsC } from "../object/value/number-of-search-results-c";
import { formatISO } from "date-fns";
import { jsDateToDatetime } from "./common-date-util";

/**
 * 検索結果件数Aの値を数値に変換する
 *
 * @param value
 * @constructor
 */
export function convertToNumberFromResultsA(
  value: string | undefined,
): number | undefined {
  if (isNullish(value)) {
    return undefined;
  }

  let val = 5;
  switch (value) {
    case "n5":
      val = 5;
      break;
    case "n10":
      val = 10;
      break;
    case "n20":
      val = 20;
      break;
    case "n50":
      val = 50;
      break;
  }

  return val;
}

/**
 * 数値を検索結果件数Aの値に変換する
 *
 * @param value
 * @constructor
 */
export function convertToResultsAFromNumber(
  value: number | undefined,
): NumberOfSearchResultsA | undefined {
  if (isNullish(value)) {
    return undefined;
  }

  let val: NumberOfSearchResultsA | undefined = undefined;
  switch (value) {
    case 5:
      val = "n5";
      break;
    case 10:
      val = "n10";
      break;
    case 20:
      val = "n20";
      break;
    case 50:
      val = "n50";
      break;
    default:
      return undefined;
  }

  return val;
}

/**
 * 検索結果件数Bの値を数値に変換する
 *
 * @param value
 * @constructor
 */
export function convertToNumberFromResultsB(
  value: string | undefined,
): number | undefined {
  if (isNullish(value)) {
    return undefined;
  }

  let val = 10;
  switch (value) {
    case "n10":
      val = 10;
      break;
    case "n20":
      val = 20;
      break;
    case "n50":
      val = 50;
      break;
    case "n100":
      val = 100;
      break;
  }

  return val;
}

/**
 * 数値を検索結果件数Bの値に変換する
 *
 * @param value
 * @constructor
 */
export function convertToResultsBFromNumber(
  value: number | undefined,
): NumberOfSearchResultsB | undefined {
  if (isNullish(value)) {
    return undefined;
  }

  let val: NumberOfSearchResultsB | undefined = undefined;
  switch (value) {
    case 10:
      val = "n10";
      break;
    case 20:
      val = "n20";
      break;
    case 50:
      val = "n50";
      break;
    case 100:
      val = "n100";
      break;
    default:
      return undefined;
  }

  return val;
}

/**
 * 検索結果件数Cの値を数値に変換する
 *
 * @param value
 * @constructor
 */
export function convertToNumberFromResultsC(
  value: NumberOfSearchResultsC | undefined,
): number | undefined {
  if (isNullish(value)) {
    return undefined;
  }

  switch (value) {
    case "n50":
      return 50;
    case "n100":
      return 100;
    case "n150":
      return 150;
    case "n200":
      return 200;
    default:
      return undefined;
  }
}

/**
 * 数値を検索結果件数Cの値に変換する
 *
 * @param value
 * @constructor
 */
export function convertToResultsCFromNumber(
  value: number | undefined,
): NumberOfSearchResultsC | undefined {
  if (isNullish(value)) {
    return undefined;
  }

  switch (value) {
    case 50:
      return "n50";
    case 100:
      return "n100";
    case 150:
      return "n150";
    case 200:
      return "n200";
    default:
      return undefined;
  }
}

/**
 * 文字列を分割した配列を返却する
 *
 * @param values
 * @param separator
 */
export function splitValuesToArray(values?: string, separator = ","): string[] {
  if (isNullish(values)) {
    return [];
  }
  return values.split(separator);
}

/**
 * 文字列を数値に変換する
 * @param str
 */
export function convertToNumberFromStr(str?: string): number {
  if (isNullish(str)) {
    return 0;
  }

  const number = parseInt(str, 10);

  // 数値に変換できなかった場合
  if (isNaN(number)) {
    return 0;
  }

  return number;
}

/**
 * 文字列を数値に変換する
 * @param str
 */
export function toSearchConditionNumber(
  str?: string | null,
): number | undefined {
  if (isNullish(str)) {
    return undefined;
  }

  return parseInt(str, 10);
}

/**
 * 数値を文字列に変換する
 * @param num
 */
export function toSearchConditionString(
  num?: number | null,
): string | undefined {
  if (isNullish(num)) {
    return undefined;
  }

  return String(num);
}

/**
 * ページに使用する設定値が、想定通りの値かチェックを行い
 * 想定外の値の場合はデフォルト値を設定する
 * @param defaultValue デフォルト値
 * @param target 1ページ当たりの表示件数
 */
export function checkPageNumber(
  defaultValue: number,
  target?: number | null,
): number {
  // 未定義の場合はデフォルト値を返却
  if (isNullish(target)) {
    return defaultValue;
  }

  // 0以下が設定されている場合はデフォルト値を返却
  if (target <= 0) {
    return defaultValue;
  }

  return target;
}

/**
 * <periodDays>日前から現在までの期間の開始終了日時を取得する。
 */
export function getDefaultPeriod(periodDays: number) {
  const defaultFromDate = new Date();
  defaultFromDate.setDate(defaultFromDate.getDate() - periodDays);
  const defaultFromDateText = formatISO(defaultFromDate);
  const defaultFromDatetimeValue = jsDateToDatetime(defaultFromDate);

  const defaultToDate = new Date();
  const defaultToDateText = formatISO(defaultToDate);
  const defaultToDatetimeValue = jsDateToDatetime(defaultToDate);

  return {
    defaultFromDateText,
    defaultFromDatetimeValue,

    defaultToDateText,
    defaultToDatetimeValue,
  };
}
