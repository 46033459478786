import { NewApplicationCreatePage } from "../../../model/document/application/new-application/NewApplicationCreatePage/NewApplicationCreatePage";
import { hasValue } from "../../../../lib/util/common-util";
import { useAppParams } from "../../../../hooks/use-app-params";
import { useNewApplication } from "../../../../hooks/document/new-application/use-new-application";

export const FN40S02NewApplicationCreate = () => {
  const { documentId } = useAppParams();

  const {
    initialNewApplicationVM,

    validationErrors,

    saveNewApplicationContent,
    deleteNewApplicationDraft,
  } = useNewApplication(documentId);

  return (
    <>
      {hasValue(initialNewApplicationVM) && (
        <NewApplicationCreatePage
          savedDocumentId={documentId}
          initialViewModel={initialNewApplicationVM}
          validationErrors={validationErrors}
          saveNewApplicationContent={saveNewApplicationContent}
          deleteNewApplicationDraft={deleteNewApplicationDraft}
          h={"100%"}
          w={"100%"}
        />
      )}
    </>
  );
};
