import {
  messageId,
  PropertyMeta,
  ValueObjectMetaComposite,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { viewPathMeta } from "../value/view-path";
import { revisionRequestItemCommentMeta } from "../value/revision-request-item-comment";
/**
 * 修正依頼項目(値オブジェクト)
 *
 *  */
export type RevisionRequestItem = z.infer<typeof schema>;
export type RevisionRequestItemSaved = z.infer<typeof savedSchema>;
const minimalSchema = z.object({
  path: viewPathMeta.minimalSchema,
  officeMemberComment: revisionRequestItemCommentMeta.minimalSchema,
  applicantComment: revisionRequestItemCommentMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
const savedMinimalSchema = z.object({
  path: viewPathMeta.savedMinimalSchema,
  officeMemberComment: revisionRequestItemCommentMeta.savedMinimalSchema,
  applicantComment: revisionRequestItemCommentMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
const schema = z.object({
  path: viewPathMeta.schema,
  officeMemberComment: revisionRequestItemCommentMeta.schema,
  applicantComment: revisionRequestItemCommentMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
});
const savedSchema = z.object({
  path: viewPathMeta.savedSchema,
  officeMemberComment: revisionRequestItemCommentMeta.savedSchema,
  applicantComment: revisionRequestItemCommentMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
const jsonSchema = z.object({
  path: viewPathMeta.jsonSchema.nullish().transform((x) => x ?? undefined),
  officeMemberComment: revisionRequestItemCommentMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  applicantComment: revisionRequestItemCommentMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
/**
 * 修正依頼項目(値オブジェクト)のメタデータ。
 *
 *  */
export const revisionRequestItemMeta = new ValueObjectMetaComposite(
  "RevisionRequestItem",
  "\u4FEE\u6B63\u4F9D\u983C\u9805\u76EE",
  messageId("lbl.\u4FEE\u6B63\u4F9D\u983C\u9805\u76EE"),
  minimalSchema,
  savedMinimalSchema,
  schema,
  savedSchema,
  jsonSchema,
  undefined,
  undefined,
  {
    path: new PropertyMeta(
      "path",
      "\u30D1\u30B9",
      messageId("lbl.\u30D1\u30B9"),
      "always",
      false,
      viewPathMeta
    ),
    officeMemberComment: new PropertyMeta(
      "officeMemberComment",
      "\u4E8B\u52D9\u5C40\u30B3\u30E1\u30F3\u30C8",
      messageId("lbl.\u4E8B\u52D9\u5C40\u30B3\u30E1\u30F3\u30C8"),
      "always",
      false,
      revisionRequestItemCommentMeta
    ),
    applicantComment: new PropertyMeta(
      "applicantComment",
      "\u7533\u8ACB\u8005\u30B3\u30E1\u30F3\u30C8",
      messageId("lbl.\u7533\u8ACB\u8005\u30B3\u30E1\u30F3\u30C8"),
      "none",
      false,
      revisionRequestItemCommentMeta
    ),
  }
);
