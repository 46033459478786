import { FC, ReactNode, useEffect, useState } from "react";
import { useAppDispatch } from "./hooks/redux-hooks";
import {
  clearAuthState,
  getPreferredMFA,
  refreshCognitoUser,
  selectAuthenticationStatus,
  signOut,
} from "./store/auth/slice";
import { stockRequestApi } from "./store/api/enhanced-api";
import { Hub } from "aws-amplify";
import log from "loglevel";
import { useNavigate } from "react-router-dom";
import { hasValue, isNullish } from "./lib/util/common-util";
import { useSelector } from "react-redux";

interface AuthProviderProps {
  children: ReactNode;
}

const validPathnameSearchHashRegex =
  /^\/([\w-_/]+)*(\?([\w-_]+=[\w-_+]+)(&[\w-_]+=[\w-_+]+)*)?(#[\w-_]+)?$/;

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [triggerCurrentUserQuery] =
    stockRequestApi.useLazyGetCurrentAppUserQuery();
  const authenticationStatus = useSelector(selectAuthenticationStatus);

  const [
    pendingRedirectionOnAuthenticated,
    setPendingRedirectionOnAuthenticated,
  ] = useState<string | null>(null);
  useEffect(() => {
    // log.debug(`authenticationStatus=${authenticationStatus}`);
    if (
      authenticationStatus === "authenticated" &&
      hasValue(pendingRedirectionOnAuthenticated)
    ) {
      setPendingRedirectionOnAuthenticated(null);
      // log.debug(
      //   `navigating with pendingRedirectionOnAuthenticated to ${JSON.stringify(
      //     pendingRedirectionOnAuthenticated,
      //   )}`,
      // );
      navigate(pendingRedirectionOnAuthenticated);
    }
  }, [authenticationStatus, pendingRedirectionOnAuthenticated, navigate]);

  const refreshUserInfo = async () => {
    // 二段階認証の設定状況を取得
    await dispatch(getPreferredMFA());
    // 認証情報の取得
    await dispatch(refreshCognitoUser());
    triggerCurrentUserQuery();
  };
  // 参考: https://docs.amplify.aws/lib/auth/social/q/platform/js/#setup-frontend
  useEffect(() => {
    const unsubscribe = Hub.listen(
      "auth",
      async ({ payload: { event, data } }) => {
        log.debug(`Hub.listen: called. event: `, event);
        switch (event) {
          case "signIn":
            log.debug(`signed in!`);
            // ログイン成功後、ユーザー情報をリフレッシュする
            await refreshUserInfo();
            break;
          case "signOut":
            await dispatch(clearAuthState);
            break;
          case "customOAuthState":
            // シングルサインオンの場合はここで次の画面へ遷移する
            log.debug("event: customOAuthState");
            if (typeof data !== "string")
              throw new Error(
                `customOAuthStateがstringではありません: customOAuthState=${JSON.stringify(
                  data,
                )}`,
              );
            if (isNullish(data.match(validPathnameSearchHashRegex)))
              throw new Error(
                `customOAuthStateが正しいパスではありません: customOAuthState=${JSON.stringify(
                  data,
                )}`,
              );
            setPendingRedirectionOnAuthenticated(data);
            break;
          case "tokenRefresh_failure":
            // トークンのリフレッシュに失敗した場合は強制ログアウト
            dispatch(signOut());
        }
      },
    );

    // アプリ起動時に必ず一度ユーザー情報を取得する。
    // これが完了すると authState.initialized = true となる。
    // ログイン後であっても、上記Hub.listen内のrefreshUserInfoよりも
    // 先に呼ばれるため、こちらが先に完了し、そのタイミングで一瞬authenticationStatusが
    // unauthenticatedになってしまう。その後、上のrefreshUserInfoが完了し、
    // authenticatedになる。この挙動は恐らく避けられないと考えられる。
    refreshUserInfo();

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{props.children}</>;
};
