import { ComponentStyleProps } from "../../../../lib/styles/props/component-style-props";
import { Text, VStack } from "@chakra-ui/react";

export type LabeledTextVerticalProps = {
  label: string;
  text?: string;
} & ComponentStyleProps;

export const LabeledTextVertical = ({
  label,
  text = "",

  sx,
  ...rest
}: LabeledTextVerticalProps) => {
  const capitalizedLabel = label;
  return (
    <VStack sx={sx} {...rest} alignItems={"flex-start"} spacing={"4px"}>
      <Text color={"gray.500"} fontSize={"xs"}>
        {capitalizedLabel}
      </Text>
      <Text color={"gray.900"} fontSize={"md"}>
        {text}
      </Text>
    </VStack>
  );
};
