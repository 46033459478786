import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";
import { HStack } from "@chakra-ui/react";
import { GuidanceMessageAndArrow } from "../GuidanceMessageAndArrow/GuidanceMessageAndArrow";
import { CMButton } from "@pscsrvlab/psc-react-components";

export type GuidedButtonProps = {
  message: string;
  buttonLabel: string;
  onClick: () => void;
} & ComponentStyleProps;

export const GuidedButton = ({
  message,
  buttonLabel,
  onClick,

  sx,
  ...rest
}: GuidedButtonProps) => {
  return (
    <HStack sx={sx} {...rest}>
      <GuidanceMessageAndArrow message={message} />
      <CMButton size={"sm"} label={buttonLabel} onClick={onClick} w={"180px"} />
    </HStack>
  );
};
