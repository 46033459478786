import {
  messageId,
  PropertyMeta,
  ValueObjectMetaString,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.string().max(10000);
const savedMinimalSchema = z.string().max(10000);
const schema = z.string().min(1).max(10000);
const savedSchema = z.string().min(1).max(10000);
const jsonSchema = z.string();
/**
 * 研究実施場所(値オブジェクト)のメタデータ。
 *
 *  */
export const researchSiteMeta = new ValueObjectMetaString(
  "ResearchSite",
  "\u7814\u7A76\u5B9F\u65BD\u5834\u6240",
  messageId("lbl.\u7814\u7A76\u5B9F\u65BD\u5834\u6240"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
