import { Box, Center, Image, Text } from "@chakra-ui/react";
import { CMButtonChangeLanguage } from "@pscsrvlab/psc-react-components";
import React, { useState } from "react";
import { useAppTranslation } from "../../../../hooks/use-app-translation";

/**
 * ログイン前メインメニュー
 */
export const BeforeLoginMainMenu = () => {
  const { t, i18n } = useAppTranslation();

  // 言語設定
  const [language, setLanguage] = useState<"ja" | "en">("ja");

  const handleChangeLanguage = async (lang: "ja" | "en") => {
    setLanguage(lang);
    await i18n.changeLanguage(lang);
  };

  return (
    <Center w={"100%"} h={"150px"} position={"relative"}>
      <Image
        src={"/image/CiRA_Foundation_Logo-04.png"}
        alt={"site logo"}
        width={"121px"}
      />
      <Text color={"gray.500"} fontWeight={400} fontSize={"16px"} ml={"23px"}>
        {t("lbl.システム名")}
      </Text>
      <Box position={"absolute"} top={"0px"} right={"20px"}>
        <CMButtonChangeLanguage
          labelLanguage={t("btn.言語設定")}
          labelJP={t("btn.言語設定JP")}
          separatorSelectedLanguage={t("btn.言語設定スラッシュ")}
          labelEN={t("btn.言語設定EN")}
          selectedLanguage={language === "ja" ? "JP" : "EN"}
          onClick={() => handleChangeLanguage(language === "ja" ? "en" : "ja")}
          sx={{ px: 0, mt: "11px", _active: { bgColor: "transparent" } }}
        />
      </Box>
    </Center>
  );
};
