import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * 提供代要否(値オブジェクト)
 *
 *
 *
 * required:
 * not_required:  */
export type ProvisionFeeRequired = z.infer<
  typeof provisionFeeRequiredMeta.schema
>;
/**
 * 提供代要否(値オブジェクト)のメタデータ。
 *
 *  */
export const provisionFeeRequiredMeta = new ValueObjectMetaEnum<
  ["required", "not_required"]
>(
  "ProvisionFeeRequired",
  "\u63D0\u4F9B\u4EE3\u8981\u5426",
  messageId("lbl.\u63D0\u4F9B\u4EE3\u8981\u5426"),
  ["required", "not_required"],
  {
    required: "code.\u63D0\u4F9B\u4EE3\u8981\u5426.\u8981",
    not_required: "code.\u63D0\u4F9B\u4EE3\u8981\u5426.\u5426",
  }
);
