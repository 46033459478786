import { isNullish } from "../../../lib/util/common-util";
import { merge } from "lodash";
import { useMemo } from "react";
import { useNewApplicationVMOrEmpty } from "./use-new-application-vm";
import { useAppGetNewApplicationQuery } from "../../query/use-app-get-new-application-query";

/**
 * 新規申請の表示内容を取得する。
 *
 * 書類IDを指定しなかった場合、
 * - その案件から新規申請を新規作成する場合の内容を取得する。
 * - 保存時は、新規作成オペレーションを呼び出す。
 *
 * 書類IDを指定した場合、
 * - その書類IDの新規申請の内容を取得する。
 * - 保存時は、更新オペレーションを呼び出す。
 */
export function useNewApplication(
  /**
   * 書類ID。
   * この値がない場合、
   * 案件IDから新規の（空の）新規申請ビューモデルを作成する。
   */
  documentId: number | undefined,

  options?: {
    /**
     * skipがtrueの場合、何も行わない。
     */
    skip: boolean;
  },
) {
  const _options = useMemo(() => merge({ skip: false }, options), [options]);

  // 書類IDが指定されている場合、新規申請を取得する。
  const { data: newApplication } = useAppGetNewApplicationQuery(
    { documentId: documentId ?? -1 },
    { skip: isNullish(documentId) },
  );

  return useNewApplicationVMOrEmpty(documentId, newApplication, _options);
}
