import { hasValue, isNullish } from "../../../../lib/util/common-util";
import { useAppParams } from "../../../../hooks/use-app-params";
import { useResultNotificationCreateInitialValue } from "../../../../hooks/result-notification/use-result-notification-create-initial-value";
import { ResultNotificationPageEmpty } from "./_components/ResultNotificationPageEmpty";
import { ResultNotificationPageReadOnly } from "./_components/ResultNotificationPageReadOnly";
import { ResultNotificationPageCreate } from "./_components/ResultNotificationPageCreate";
import { useEffect } from "react";
import { useAppListResultNotificationQuery } from "../../../../hooks/query/use-app-list-result-notification-query";
import { useNavigate } from "react-router-dom";
import { useProjectPagePathPrefix } from "../../../../hooks/use-project-page-path-prefix";

export type ResultNotificationReportPageProps = {
  /**
   * 表示モード
   * view: 参照
   * create: 新規作成
   * empty: id未指定
   */
  mode: "view" | "create" | "empty";
  documentId: number;
};

/**
 * FN40S34 結果通知(報告)
 * メインエリアを表示
 */
export const ResultNotificationReportPage = ({
  mode,
  documentId,
}: ResultNotificationReportPageProps) => {
  const navigate = useNavigate();
  const { projectPagePathPrefix } = useProjectPagePathPrefix();
  const { resultNotificationId } = useAppParams();

  const resultNotificationCreateInitialValue =
    useResultNotificationCreateInitialValue(documentId);

  const { data: resultNotifications } = useAppListResultNotificationQuery({
    documentId,
  });

  useEffect(() => {
    if (mode !== "empty" || isNullish(resultNotifications)) return;
    if (resultNotifications.length > 0) {
      navigate(
        `${projectPagePathPrefix}/document/${documentId}/result-notification/${resultNotifications[0].id}`,
        { replace: true },
      );
      return;
    }

    if (hasValue(resultNotificationCreateInitialValue)) {
      navigate(
        `${projectPagePathPrefix}/document/${documentId}/result-notification/create`,
        { replace: true },
      );
      return;
    }
  }, [
    documentId,
    resultNotificationCreateInitialValue,
    mode,
    navigate,
    projectPagePathPrefix,
    resultNotifications,
  ]);

  return (
    <>
      {mode === "empty" ? (
        <ResultNotificationPageEmpty mode={"tbd"} />
      ) : mode === "view" && hasValue(resultNotificationId) ? (
        <ResultNotificationPageReadOnly
          resultNotificationId={resultNotificationId}
        />
      ) : mode === "create" &&
        hasValue(resultNotificationCreateInitialValue) ? (
        <ResultNotificationPageCreate
          initialVM={resultNotificationCreateInitialValue}
        />
      ) : null}
    </>
  );
};
