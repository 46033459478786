import { hasValue } from "../../../../lib/util/common-util";
import { AnnualReportCreateConfirmPage } from "./_components/AnnualReportCreateConfirmPage/AnnualReportCreateConfirmPage";
import { useAppParams } from "../../../../hooks/use-app-params";
import { useAnnualReportReadonlyVm } from "../../../../hooks/document/annual-report/use-annual-report-readonly-vm";

export const FN40S08AnnualReportCreateConfirm = () => {
  const { documentId } = useAppParams();

  const { currentData, vm } = useAnnualReportReadonlyVm(documentId);

  return (
    <>
      {hasValue(documentId) &&
        hasValue(vm) &&
        currentData?.documentState === "draft" && (
          <AnnualReportCreateConfirmPage documentId={documentId} vm={vm} />
        )}
    </>
  );
};
