import { useGetTerminationReportQuery } from "../../store/api/generated/stock-request-api";
import { isNullish } from "../../lib/util/common-util";
import { useMemo } from "react";
import {
  terminationReportMeta,
  TerminationReportSaved,
} from "../../lib/object/entity/termination-report";

export function useAppGetTerminationReportQuery(
  arg: Parameters<typeof useGetTerminationReportQuery>[0],
  options?: Parameters<typeof useGetTerminationReportQuery>[1],
  skipOptionalValidations = true, // get系は、skipOptionalValidationsのデフォルトはtrueとする。
): {
  data: TerminationReportSaved | null;
  currentData: TerminationReportSaved | null;
  isLoading: ReturnType<typeof useGetTerminationReportQuery>["isLoading"];
  refetch: ReturnType<typeof useGetTerminationReportQuery>["refetch"];
  error: ReturnType<typeof useGetTerminationReportQuery>["error"];
} {
  const {
    data: rawData,
    currentData: rawCurrentData,
    isLoading,
    refetch,
    error,
  } = useGetTerminationReportQuery(arg, options);

  const data = useMemo(() => {
    if (isNullish(rawData)) return null;
    return terminationReportMeta.toSavedDomainObjectOrNull(
      rawData,
      skipOptionalValidations,
    );
  }, [rawData, skipOptionalValidations]);
  const currentData = useMemo(() => {
    if (isNullish(rawCurrentData)) return null;
    return terminationReportMeta.toSavedDomainObjectOrNull(
      rawCurrentData,
      skipOptionalValidations,
    );
  }, [rawCurrentData, skipOptionalValidations]);

  return { data, currentData, isLoading, refetch, error };
}
