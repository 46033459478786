import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * 分担機関の有無(値オブジェクト)
 *
 *
 *
 * has_partner:
 * no_partner:  */
export type HasCollaborativePartner = z.infer<
  typeof hasCollaborativePartnerMeta.schema
>;
/**
 * 分担機関の有無(値オブジェクト)のメタデータ。
 *
 *  */
export const hasCollaborativePartnerMeta = new ValueObjectMetaEnum<
  ["has_partner", "no_partner"]
>(
  "HasCollaborativePartner",
  "\u5206\u62C5\u6A5F\u95A2\u306E\u6709\u7121",
  messageId("lbl.\u5206\u62C5\u6A5F\u95A2\u306E\u6709\u7121"),
  ["has_partner", "no_partner"],
  {
    has_partner: "code.\u5206\u62C5\u6A5F\u95A2\u306E\u6709\u7121.\u6709",
    no_partner: "code.\u5206\u62C5\u6A5F\u95A2\u306E\u6709\u7121.\u7121",
  }
);
