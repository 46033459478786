import {
  messageId,
  PropertyMeta,
  ValueObjectMetaComposite,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { ethicalApplicationStatusCustomValidation } from "../custom-validation/ethical-application-status";
import { ethicalApplicationStatusTypeMeta } from "../value/ethical-application-status-type";
import { yearMonthMeta } from "../value/year-month";
/**
 * 倫理申請状況(値オブジェクト)
 *
 *  */
export type EthicalApplicationStatus = z.infer<typeof schema>;
export type EthicalApplicationStatusSaved = z.infer<typeof savedSchema>;
const minimalSchema = z.object({
  ethicalApplicationStatus: ethicalApplicationStatusTypeMeta.minimalSchema,
  estimatedApprovalYearMonth: yearMonthMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
const savedMinimalSchema = z.object({
  ethicalApplicationStatus: ethicalApplicationStatusTypeMeta.savedMinimalSchema,
  estimatedApprovalYearMonth: yearMonthMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
const schema = z.object({
  ethicalApplicationStatus: ethicalApplicationStatusTypeMeta.schema,
  estimatedApprovalYearMonth: yearMonthMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
});
const savedSchema = z.object({
  ethicalApplicationStatus: ethicalApplicationStatusTypeMeta.savedSchema,
  estimatedApprovalYearMonth: yearMonthMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
const jsonSchema = z.object({
  ethicalApplicationStatus: ethicalApplicationStatusTypeMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  estimatedApprovalYearMonth: yearMonthMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
/**
 * 倫理申請状況(値オブジェクト)のメタデータ。
 *
 *
 *
 * 以下のカスタムバリデーションを、 ethicalApplicationStatusCustomValidation にて行う。
 * - (任意) 倫理申請の状況で申請中の場合に承認見込みを必須とする。 (エラーメッセージID=mes.倫理申請の状況申請中承認見込み未入力エラー) */
export const ethicalApplicationStatusMeta = new ValueObjectMetaComposite(
  "EthicalApplicationStatus",
  "\u502B\u7406\u7533\u8ACB\u72B6\u6CC1",
  messageId("lbl.\u502B\u7406\u7533\u8ACB\u72B6\u6CC1"),
  minimalSchema,
  savedMinimalSchema,
  schema,
  savedSchema,
  jsonSchema,
  undefined,
  ethicalApplicationStatusCustomValidation,
  {
    ethicalApplicationStatus: new PropertyMeta(
      "ethicalApplicationStatus",
      "\u502B\u7406\u7533\u8ACB\u72B6\u6CC1",
      messageId("lbl.\u502B\u7406\u7533\u8ACB\u72B6\u6CC1"),
      "always",
      false,
      ethicalApplicationStatusTypeMeta
    ),
    estimatedApprovalYearMonth: new PropertyMeta(
      "estimatedApprovalYearMonth",
      "\u627F\u8A8D\u898B\u8FBC\u307F\u5E74\u6708",
      messageId("lbl.\u627F\u8A8D\u898B\u8FBC\u307F\u5E74\u6708"),
      "none",
      false,
      yearMonthMeta
    ),
  }
);
