import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * 使用終了提出済(値オブジェクト)
 *
 *
 *
 * not_submitted:
 * submitted:  */
export type TerminationReportSubmitted = z.infer<
  typeof terminationReportSubmittedMeta.schema
>;
/**
 * 使用終了提出済(値オブジェクト)のメタデータ。
 *
 *  */
export const terminationReportSubmittedMeta = new ValueObjectMetaEnum<
  ["not_submitted", "submitted"]
>(
  "TerminationReportSubmitted",
  "\u4F7F\u7528\u7D42\u4E86\u63D0\u51FA\u6E08",
  messageId("lbl.\u4F7F\u7528\u7D42\u4E86\u63D0\u51FA\u6E08"),
  ["not_submitted", "submitted"],
  {
    not_submitted:
      "code.\u4F7F\u7528\u7D42\u4E86\u63D0\u51FA\u6E08.\u672A\u63D0\u51FA",
    submitted:
      "code.\u4F7F\u7528\u7D42\u4E86\u63D0\u51FA\u6E08.\u63D0\u51FA\u6E08",
  }
);
