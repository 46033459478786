import {
  messageId,
  PropertyMeta,
  ValueObjectMetaString,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.string().max(100);
const savedMinimalSchema = z.string().max(100);
const schema = z.string().min(1).max(100);
const savedSchema = z.string().min(1).max(100);
const jsonSchema = z.string();
/**
 * 案件管理番号(値オブジェクト)のメタデータ。
 *
 * 案件の管理番号。
 * 新規申請の提出時にシステムで自動で採番を行う。
 * 案件強制登録の場合は手動で登録する。 */
export const projectControlNumberMeta = new ValueObjectMetaString(
  "ProjectControlNumber",
  "\u6848\u4EF6\u7BA1\u7406\u756A\u53F7",
  messageId("lbl.\u6848\u4EF6\u7BA1\u7406\u756A\u53F7"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
