import { Center, Divider, Heading, Text, VStack } from "@chakra-ui/react";
import { WarningTwoIcon } from "@chakra-ui/icons";

export const ForbiddenPage = () => {
  return (
    <Center w={"100vw"} h={"100vh"}>
      <VStack align={"start"}>
        <Heading>
          <WarningTwoIcon color={"red.400"} /> 403 Forbidden.
        </Heading>
        <Divider />
        <Text>アクセス権限がありません。</Text>
        <Text>You don&apos;t have permission to access it.</Text>
      </VStack>
    </Center>
  );
};
