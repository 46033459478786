import {
  messageId,
  PropertyMeta,
  ValueObjectMetaPredefinedJson,
  predefinedJsonMeta,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * JSON(値オブジェクト)のメタデータ。
 *
 * JSON。any型を用いる。 */
export const jsonMeta = predefinedJsonMeta;
