import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * 審査種別(値オブジェクト)
 *
 *
 *
 * unselected:
 * office_review:
 * committee_review:
 * no_need_to_review:  */
export type ReviewType = z.infer<typeof reviewTypeMeta.schema>;
/**
 * 審査種別(値オブジェクト)のメタデータ。
 *
 *  */
export const reviewTypeMeta = new ValueObjectMetaEnum<
  ["unselected", "office_review", "committee_review", "no_need_to_review"]
>(
  "ReviewType",
  "\u5BE9\u67FB\u7A2E\u5225",
  messageId("lbl.\u5BE9\u67FB\u7A2E\u5225"),
  ["unselected", "office_review", "committee_review", "no_need_to_review"],
  {
    unselected: "code.\u5BE9\u67FB\u7A2E\u5225.\u672A\u5B9A",
    office_review:
      "code.\u5BE9\u67FB\u7A2E\u5225.\u4E8B\u52D9\u5C40\u5BE9\u67FB",
    committee_review:
      "code.\u5BE9\u67FB\u7A2E\u5225.\u59D4\u54E1\u4F1A\u5BE9\u67FB",
    no_need_to_review: "code.\u5BE9\u67FB\u7A2E\u5225.\u5BE9\u67FB\u4E0D\u8981",
  }
);
