import {
  messageId,
  PropertyMeta,
  ValueObjectMetaComposite,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { applicationContentCustomValidation } from "../custom-validation/application-content";
import { projectNameMeta } from "../value/project-name";
import { institutionInformationMeta } from "../value/institution-information";
import { userInformationMeta } from "../value/user-information";
import { userInformationKeyedMeta } from "../value/user-information-keyed";
import { requestingCellMeta } from "../value/requesting-cell";
import { ipsCellStockUsePurposeMeta } from "../value/ips-cell-stock-use-purpose";
import { ipsCellStockRequirementReasonMeta } from "../value/ips-cell-stock-requirement-reason";
import { planSummaryMeta } from "../value/plan-summary";
import { hasCollaborativePartnerMeta } from "../value/has-collaborative-partner";
import { yearMonthDayMeta } from "../value/year-month-day";
import { researchSiteMeta } from "../value/research-site";
import { ipsCultureExperienceMeta } from "../value/ips-culture-experience";
import { ethicalApplicationStatusMeta } from "../value/ethical-application-status";
import { handlingAfterUseMeta } from "../value/handling-after-use";
import { collaborativeResearchContractStateWithFoundationMeta } from "../value/collaborative-research-contract-state-with-foundation";
import { researchPlanProjectNameMeta } from "../value/research-plan-project-name";
import { attachmentFileInformationMeta } from "../value/attachment-file-information";
import { collaborativePartnerMeta } from "../value/collaborative-partner";
import { checklistItemCheckStateMeta } from "../value/checklist-item-check-state";
/**
 * 申請内容(値オブジェクト)
 *
 *  */
export type ApplicationContent = z.infer<typeof schema>;
export type ApplicationContentSaved = z.infer<typeof savedSchema>;
const minimalSchema = z.object({
  projectName: projectNameMeta.minimalSchema,
  institution: institutionInformationMeta.minimalSchema,
  principalInvestigator: userInformationMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  contactPeople: z.array(userInformationKeyedMeta.minimalSchema),
  requestingResearchCells: z.array(requestingCellMeta.minimalSchema),
  requestingClinicalCells: z.array(requestingCellMeta.minimalSchema),
  ipsCellStockUsePurpose: ipsCellStockUsePurposeMeta.minimalSchema,
  ipsCellStockRequirementReason:
    ipsCellStockRequirementReasonMeta.minimalSchema,
  planSummary: planSummaryMeta.minimalSchema,
  hasCollaborativePartner: hasCollaborativePartnerMeta.minimalSchema,
  usageEndDate: yearMonthDayMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  researchSite: researchSiteMeta.minimalSchema,
  ipsCultureExperience: ipsCultureExperienceMeta.minimalSchema,
  ethicalApplicationStatus: ethicalApplicationStatusMeta.minimalSchema,
  handlingAfterUse: handlingAfterUseMeta.minimalSchema,
  foundationContractState:
    collaborativeResearchContractStateWithFoundationMeta.minimalSchema,
  researchPlanProjectName: researchPlanProjectNameMeta.minimalSchema,
  attachmentFiles: z.array(attachmentFileInformationMeta.minimalSchema),
  partners: z.array(collaborativePartnerMeta.minimalSchema),
  checklistItem1: checklistItemCheckStateMeta.minimalSchema,
  checklistItem2: checklistItemCheckStateMeta.minimalSchema,
  checklistItem3: checklistItemCheckStateMeta.minimalSchema,
  checklistItem4: checklistItemCheckStateMeta.minimalSchema,
  checklistItem5: checklistItemCheckStateMeta.minimalSchema,
  checklistItem6: checklistItemCheckStateMeta.minimalSchema,
});
const savedMinimalSchema = z.object({
  projectName: projectNameMeta.savedMinimalSchema,
  institution: institutionInformationMeta.savedMinimalSchema,
  principalInvestigator: userInformationMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  contactPeople: z.array(userInformationKeyedMeta.savedMinimalSchema),
  requestingResearchCells: z.array(requestingCellMeta.savedMinimalSchema),
  requestingClinicalCells: z.array(requestingCellMeta.savedMinimalSchema),
  ipsCellStockUsePurpose: ipsCellStockUsePurposeMeta.savedMinimalSchema,
  ipsCellStockRequirementReason:
    ipsCellStockRequirementReasonMeta.savedMinimalSchema,
  planSummary: planSummaryMeta.savedMinimalSchema,
  hasCollaborativePartner: hasCollaborativePartnerMeta.savedMinimalSchema,
  usageEndDate: yearMonthDayMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  researchSite: researchSiteMeta.savedMinimalSchema,
  ipsCultureExperience: ipsCultureExperienceMeta.savedMinimalSchema,
  ethicalApplicationStatus: ethicalApplicationStatusMeta.savedMinimalSchema,
  handlingAfterUse: handlingAfterUseMeta.savedMinimalSchema,
  foundationContractState:
    collaborativeResearchContractStateWithFoundationMeta.savedMinimalSchema,
  researchPlanProjectName: researchPlanProjectNameMeta.savedMinimalSchema,
  attachmentFiles: z.array(attachmentFileInformationMeta.savedMinimalSchema),
  partners: z.array(collaborativePartnerMeta.savedMinimalSchema),
  checklistItem1: checklistItemCheckStateMeta.savedMinimalSchema,
  checklistItem2: checklistItemCheckStateMeta.savedMinimalSchema,
  checklistItem3: checklistItemCheckStateMeta.savedMinimalSchema,
  checklistItem4: checklistItemCheckStateMeta.savedMinimalSchema,
  checklistItem5: checklistItemCheckStateMeta.savedMinimalSchema,
  checklistItem6: checklistItemCheckStateMeta.savedMinimalSchema,
});
const schema = z.object({
  projectName: projectNameMeta.schema,
  institution: institutionInformationMeta.schema,
  principalInvestigator: userInformationMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
  contactPeople: z.array(userInformationKeyedMeta.schema),
  requestingResearchCells: z.array(requestingCellMeta.schema),
  requestingClinicalCells: z.array(requestingCellMeta.schema),
  ipsCellStockUsePurpose: ipsCellStockUsePurposeMeta.schema,
  ipsCellStockRequirementReason: ipsCellStockRequirementReasonMeta.schema,
  planSummary: planSummaryMeta.schema,
  hasCollaborativePartner: hasCollaborativePartnerMeta.schema,
  usageEndDate: yearMonthDayMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
  researchSite: researchSiteMeta.schema,
  ipsCultureExperience: ipsCultureExperienceMeta.schema,
  ethicalApplicationStatus: ethicalApplicationStatusMeta.schema,
  handlingAfterUse: handlingAfterUseMeta.schema,
  foundationContractState:
    collaborativeResearchContractStateWithFoundationMeta.schema,
  researchPlanProjectName: researchPlanProjectNameMeta.schema,
  attachmentFiles: z.array(attachmentFileInformationMeta.schema),
  partners: z.array(collaborativePartnerMeta.schema),
  checklistItem1: checklistItemCheckStateMeta.schema,
  checklistItem2: checklistItemCheckStateMeta.schema,
  checklistItem3: checklistItemCheckStateMeta.schema,
  checklistItem4: checklistItemCheckStateMeta.schema,
  checklistItem5: checklistItemCheckStateMeta.schema,
  checklistItem6: checklistItemCheckStateMeta.schema,
});
const savedSchema = z.object({
  projectName: projectNameMeta.savedSchema,
  institution: institutionInformationMeta.savedSchema,
  principalInvestigator: userInformationMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  contactPeople: z.array(userInformationKeyedMeta.savedSchema),
  requestingResearchCells: z.array(requestingCellMeta.savedSchema),
  requestingClinicalCells: z.array(requestingCellMeta.savedSchema),
  ipsCellStockUsePurpose: ipsCellStockUsePurposeMeta.savedSchema,
  ipsCellStockRequirementReason: ipsCellStockRequirementReasonMeta.savedSchema,
  planSummary: planSummaryMeta.savedSchema,
  hasCollaborativePartner: hasCollaborativePartnerMeta.savedSchema,
  usageEndDate: yearMonthDayMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  researchSite: researchSiteMeta.savedSchema,
  ipsCultureExperience: ipsCultureExperienceMeta.savedSchema,
  ethicalApplicationStatus: ethicalApplicationStatusMeta.savedSchema,
  handlingAfterUse: handlingAfterUseMeta.savedSchema,
  foundationContractState:
    collaborativeResearchContractStateWithFoundationMeta.savedSchema,
  researchPlanProjectName: researchPlanProjectNameMeta.savedSchema,
  attachmentFiles: z.array(attachmentFileInformationMeta.savedSchema),
  partners: z.array(collaborativePartnerMeta.savedSchema),
  checklistItem1: checklistItemCheckStateMeta.savedSchema,
  checklistItem2: checklistItemCheckStateMeta.savedSchema,
  checklistItem3: checklistItemCheckStateMeta.savedSchema,
  checklistItem4: checklistItemCheckStateMeta.savedSchema,
  checklistItem5: checklistItemCheckStateMeta.savedSchema,
  checklistItem6: checklistItemCheckStateMeta.savedSchema,
});
const jsonSchema = z.object({
  projectName: projectNameMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  institution: institutionInformationMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  principalInvestigator: userInformationMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  contactPeople: z
    .array(userInformationKeyedMeta.jsonSchema)
    .nullish()
    .transform((x) => x ?? undefined),
  requestingResearchCells: z
    .array(requestingCellMeta.jsonSchema)
    .nullish()
    .transform((x) => x ?? undefined),
  requestingClinicalCells: z
    .array(requestingCellMeta.jsonSchema)
    .nullish()
    .transform((x) => x ?? undefined),
  ipsCellStockUsePurpose: ipsCellStockUsePurposeMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  ipsCellStockRequirementReason: ipsCellStockRequirementReasonMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  planSummary: planSummaryMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  hasCollaborativePartner: hasCollaborativePartnerMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  usageEndDate: yearMonthDayMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  researchSite: researchSiteMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  ipsCultureExperience: ipsCultureExperienceMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  ethicalApplicationStatus: ethicalApplicationStatusMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  handlingAfterUse: handlingAfterUseMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  foundationContractState:
    collaborativeResearchContractStateWithFoundationMeta.jsonSchema
      .nullish()
      .transform((x) => x ?? undefined),
  researchPlanProjectName: researchPlanProjectNameMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  attachmentFiles: z
    .array(attachmentFileInformationMeta.jsonSchema)
    .nullish()
    .transform((x) => x ?? undefined),
  partners: z
    .array(collaborativePartnerMeta.jsonSchema)
    .nullish()
    .transform((x) => x ?? undefined),
  checklistItem1: checklistItemCheckStateMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  checklistItem2: checklistItemCheckStateMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  checklistItem3: checklistItemCheckStateMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  checklistItem4: checklistItemCheckStateMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  checklistItem5: checklistItemCheckStateMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  checklistItem6: checklistItemCheckStateMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
/**
 * 申請内容(値オブジェクト)のメタデータ。
 *
 *
 *
 * 以下のカスタムバリデーションを、 applicationContentCustomValidation にて行う。
 * - (任意) S02相関チェックシート．添付資料（代表機関用）．研究者の略歴使用実績なし (エラーメッセージID=mes.研究者の略歴必須エラーメッセージ)
 * - (任意) S02相関チェックシート．添付資料（代表機関用）．研究責任者の所属する機関の概要使用実績なし (エラーメッセージID=mes.研究責任者の所属する機関の概要必須エラーメッセージ)
 * - (任意) S02相関チェックシート．添付資料（代表機関用）．研究実績を示す資料使用実績なし (エラーメッセージID=mes.研究実績を示す資料必須エラーメッセージ)
 * - (任意) S02相関チェックシート．添付資料（代表機関用）．使用施設の適格性を示す文書使用実績なし (エラーメッセージID=mes.使用施設の適格性を示す文書必須エラーメッセージ)
 * - (任意) 使用終了日必須チェック (エラーメッセージID=mes.必須チェックエラー2)
 * - (任意) 使用終了日期間妥当性チェック (エラーメッセージID=mes.使用終了日妥当性チェックエラー)
 * - (任意) 分担機関有の場合に分担機関が0件の場合はエラーとする。 (エラーメッセージID=mes.分担機関必須エラー)
 * - (任意) 確認日必須チェック (エラーメッセージID=mes.必須チェックエラー2)
 * - 使用する細胞(研究用)には研究用株のみが、使用する細胞(臨床用)には臨床用株のみが設定されている。 (エラーメッセージID=mes.一般エラーメッセージ) */
export const applicationContentMeta = new ValueObjectMetaComposite(
  "ApplicationContent",
  "\u7533\u8ACB\u5185\u5BB9",
  messageId("lbl.\u7533\u8ACB\u5185\u5BB9"),
  minimalSchema,
  savedMinimalSchema,
  schema,
  savedSchema,
  jsonSchema,
  undefined,
  applicationContentCustomValidation,
  {
    projectName: new PropertyMeta(
      "projectName",
      "\u8AB2\u984C\u540D",
      messageId("lbl.\u8AB2\u984C\u540D"),
      "always",
      false,
      projectNameMeta
    ),
    institution: new PropertyMeta(
      "institution",
      "\u6A5F\u95A2\u60C5\u5831",
      messageId("lbl.\u6A5F\u95A2\u60C5\u5831"),
      "always",
      false,
      institutionInformationMeta
    ),
    principalInvestigator: new PropertyMeta(
      "principalInvestigator",
      "\u7814\u7A76\u8CAC\u4EFB\u8005\u60C5\u5831",
      messageId("lbl.\u7814\u7A76\u8CAC\u4EFB\u8005\u60C5\u5831"),
      "none",
      false,
      userInformationMeta
    ),
    contactPeople: new PropertyMeta(
      "contactPeople",
      "\u9023\u7D61\u62C5\u5F53\u8005\u60C5\u5831",
      messageId("lbl.\u9023\u7D61\u62C5\u5F53\u8005\u60C5\u5831"),
      "always",
      true,
      userInformationKeyedMeta
    ),
    requestingResearchCells: new PropertyMeta(
      "requestingResearchCells",
      "\u4F7F\u7528\u3059\u308B\u7D30\u80DE(\u7814\u7A76\u7528)",
      messageId("lbl.\u4F7F\u7528\u3059\u308B\u7D30\u80DE(\u7814\u7A76\u7528)"),
      "always",
      true,
      requestingCellMeta
    ),
    requestingClinicalCells: new PropertyMeta(
      "requestingClinicalCells",
      "\u4F7F\u7528\u3059\u308B\u7D30\u80DE(\u81E8\u5E8A\u7528)",
      messageId("lbl.\u4F7F\u7528\u3059\u308B\u7D30\u80DE(\u81E8\u5E8A\u7528)"),
      "always",
      true,
      requestingCellMeta
    ),
    ipsCellStockUsePurpose: new PropertyMeta(
      "ipsCellStockUsePurpose",
      "iPS\u7D30\u80DE\u306E\u4F7F\u7528\u76EE\u7684",
      messageId("lbl.iPS\u7D30\u80DE\u306E\u4F7F\u7528\u76EE\u7684"),
      "always",
      false,
      ipsCellStockUsePurposeMeta
    ),
    ipsCellStockRequirementReason: new PropertyMeta(
      "ipsCellStockRequirementReason",
      "iPS\u7D30\u80DE\u30B9\u30C8\u30C3\u30AF\u5FC5\u8981\u7406\u7531",
      messageId(
        "lbl.iPS\u7D30\u80DE\u30B9\u30C8\u30C3\u30AF\u5FC5\u8981\u7406\u7531"
      ),
      "always",
      false,
      ipsCellStockRequirementReasonMeta
    ),
    planSummary: new PropertyMeta(
      "planSummary",
      "\u8A08\u753B\u306E\u6982\u8981",
      messageId("lbl.\u8A08\u753B\u306E\u6982\u8981"),
      "always",
      false,
      planSummaryMeta
    ),
    hasCollaborativePartner: new PropertyMeta(
      "hasCollaborativePartner",
      "\u5206\u62C5\u6A5F\u95A2\u306E\u6709\u7121",
      messageId("lbl.\u5206\u62C5\u6A5F\u95A2\u306E\u6709\u7121"),
      "always",
      false,
      hasCollaborativePartnerMeta
    ),
    usageEndDate: new PropertyMeta(
      "usageEndDate",
      "\u4F7F\u7528\u7D42\u4E86\u65E5",
      messageId("lbl.\u4F7F\u7528\u7D42\u4E86\u65E5"),
      "none",
      false,
      yearMonthDayMeta
    ),
    researchSite: new PropertyMeta(
      "researchSite",
      "\u7814\u7A76\u5B9F\u65BD\u5834\u6240",
      messageId("lbl.\u7814\u7A76\u5B9F\u65BD\u5834\u6240"),
      "always",
      false,
      researchSiteMeta
    ),
    ipsCultureExperience: new PropertyMeta(
      "ipsCultureExperience",
      "iPS\u57F9\u990A\u7D4C\u9A13",
      messageId("lbl.iPS\u57F9\u990A\u7D4C\u9A13"),
      "always",
      false,
      ipsCultureExperienceMeta
    ),
    ethicalApplicationStatus: new PropertyMeta(
      "ethicalApplicationStatus",
      "\u502B\u7406\u7533\u8ACB\u72B6\u6CC1",
      messageId("lbl.\u502B\u7406\u7533\u8ACB\u72B6\u6CC1"),
      "always",
      false,
      ethicalApplicationStatusMeta
    ),
    handlingAfterUse: new PropertyMeta(
      "handlingAfterUse",
      "\u4F7F\u7528\u7D42\u4E86\u5F8C\u306E\u53D6\u308A\u6271\u3044",
      messageId(
        "lbl.\u4F7F\u7528\u7D42\u4E86\u5F8C\u306E\u53D6\u308A\u6271\u3044"
      ),
      "always",
      false,
      handlingAfterUseMeta
    ),
    foundationContractState: new PropertyMeta(
      "foundationContractState",
      "\u8CA1\u56E3\u3068\u306E\u5171\u540C\u7814\u7A76\u5951\u7D04\u72B6\u6CC1",
      messageId(
        "lbl.\u8CA1\u56E3\u3068\u306E\u5171\u540C\u7814\u7A76\u5951\u7D04\u72B6\u6CC1"
      ),
      "always",
      false,
      collaborativeResearchContractStateWithFoundationMeta
    ),
    researchPlanProjectName: new PropertyMeta(
      "researchPlanProjectName",
      "\u7814\u7A76\u8A08\u753B\u66F8\u8AB2\u984C\u540D",
      messageId("lbl.\u7814\u7A76\u8A08\u753B\u66F8\u8AB2\u984C\u540D"),
      "always",
      false,
      researchPlanProjectNameMeta
    ),
    attachmentFiles: new PropertyMeta(
      "attachmentFiles",
      "\u6DFB\u4ED8\u8CC7\u6599",
      messageId("lbl.\u6DFB\u4ED8\u8CC7\u6599"),
      "always",
      true,
      attachmentFileInformationMeta
    ),
    partners: new PropertyMeta(
      "partners",
      "\u5206\u62C5\u6A5F\u95A2",
      messageId("lbl.\u5206\u62C5\u6A5F\u95A2"),
      "always",
      true,
      collaborativePartnerMeta
    ),
    checklistItem1: new PropertyMeta(
      "checklistItem1",
      "ips\u30B9\u30C8\u30C3\u30AF\u4F7F\u7528\u30C1\u30A7\u30C3\u30AF\u30EA\u30B9\u30C81",
      messageId(
        "lbl.ips\u30B9\u30C8\u30C3\u30AF\u4F7F\u7528\u30C1\u30A7\u30C3\u30AF\u30EA\u30B9\u30C81"
      ),
      "always",
      false,
      checklistItemCheckStateMeta
    ),
    checklistItem2: new PropertyMeta(
      "checklistItem2",
      "ips\u30B9\u30C8\u30C3\u30AF\u4F7F\u7528\u30C1\u30A7\u30C3\u30AF\u30EA\u30B9\u30C82",
      messageId(
        "lbl.ips\u30B9\u30C8\u30C3\u30AF\u4F7F\u7528\u30C1\u30A7\u30C3\u30AF\u30EA\u30B9\u30C82"
      ),
      "always",
      false,
      checklistItemCheckStateMeta
    ),
    checklistItem3: new PropertyMeta(
      "checklistItem3",
      "ips\u30B9\u30C8\u30C3\u30AF\u4F7F\u7528\u30C1\u30A7\u30C3\u30AF\u30EA\u30B9\u30C83",
      messageId(
        "lbl.ips\u30B9\u30C8\u30C3\u30AF\u4F7F\u7528\u30C1\u30A7\u30C3\u30AF\u30EA\u30B9\u30C83"
      ),
      "always",
      false,
      checklistItemCheckStateMeta
    ),
    checklistItem4: new PropertyMeta(
      "checklistItem4",
      "ips\u30B9\u30C8\u30C3\u30AF\u4F7F\u7528\u30C1\u30A7\u30C3\u30AF\u30EA\u30B9\u30C84",
      messageId(
        "lbl.ips\u30B9\u30C8\u30C3\u30AF\u4F7F\u7528\u30C1\u30A7\u30C3\u30AF\u30EA\u30B9\u30C84"
      ),
      "always",
      false,
      checklistItemCheckStateMeta
    ),
    checklistItem5: new PropertyMeta(
      "checklistItem5",
      "ips\u30B9\u30C8\u30C3\u30AF\u4F7F\u7528\u30C1\u30A7\u30C3\u30AF\u30EA\u30B9\u30C85",
      messageId(
        "lbl.ips\u30B9\u30C8\u30C3\u30AF\u4F7F\u7528\u30C1\u30A7\u30C3\u30AF\u30EA\u30B9\u30C85"
      ),
      "always",
      false,
      checklistItemCheckStateMeta
    ),
    checklistItem6: new PropertyMeta(
      "checklistItem6",
      "ips\u30B9\u30C8\u30C3\u30AF\u4F7F\u7528\u30C1\u30A7\u30C3\u30AF\u30EA\u30B9\u30C86",
      messageId(
        "lbl.ips\u30B9\u30C8\u30C3\u30AF\u4F7F\u7528\u30C1\u30A7\u30C3\u30AF\u30EA\u30B9\u30C86"
      ),
      "always",
      false,
      checklistItemCheckStateMeta
    ),
  }
);
