import {
  messageId,
  PropertyMeta,
  ValueObjectMetaComposite,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { handlingAfterUseCustomValidation } from "../custom-validation/handling-after-use";
import { handlingAfterUseTypeMeta } from "../value/handling-after-use-type";
import { handlingAfterUseOtherMeta } from "../value/handling-after-use-other";
/**
 * 使用終了後の取り扱い(値オブジェクト)
 *
 *  */
export type HandlingAfterUse = z.infer<typeof schema>;
export type HandlingAfterUseSaved = z.infer<typeof savedSchema>;
const minimalSchema = z.object({
  type: handlingAfterUseTypeMeta.minimalSchema,
  other: handlingAfterUseOtherMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
const savedMinimalSchema = z.object({
  type: handlingAfterUseTypeMeta.savedMinimalSchema,
  other: handlingAfterUseOtherMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
const schema = z.object({
  type: handlingAfterUseTypeMeta.schema,
  other: handlingAfterUseOtherMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
});
const savedSchema = z.object({
  type: handlingAfterUseTypeMeta.savedSchema,
  other: handlingAfterUseOtherMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
const jsonSchema = z.object({
  type: handlingAfterUseTypeMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  other: handlingAfterUseOtherMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
/**
 * 使用終了後の取り扱い(値オブジェクト)のメタデータ。
 *
 *
 *
 * 以下のカスタムバリデーションを、 handlingAfterUseCustomValidation にて行う。
 * - (任意) 使用期間終了後の細胞の取り扱いでその他を選択した場合に内容を必須にする。 (エラーメッセージID=mes.使用期間終了後の細胞の取り扱いその他未入力エラー) */
export const handlingAfterUseMeta = new ValueObjectMetaComposite(
  "HandlingAfterUse",
  "\u4F7F\u7528\u7D42\u4E86\u5F8C\u306E\u53D6\u308A\u6271\u3044",
  messageId("lbl.\u4F7F\u7528\u7D42\u4E86\u5F8C\u306E\u53D6\u308A\u6271\u3044"),
  minimalSchema,
  savedMinimalSchema,
  schema,
  savedSchema,
  jsonSchema,
  undefined,
  handlingAfterUseCustomValidation,
  {
    type: new PropertyMeta(
      "type",
      "\u4F7F\u7528\u7D42\u4E86\u5F8C\u306E\u53D6\u308A\u6271\u3044\u533A\u5206",
      messageId(
        "lbl.\u4F7F\u7528\u7D42\u4E86\u5F8C\u306E\u53D6\u308A\u6271\u3044\u533A\u5206"
      ),
      "always",
      false,
      handlingAfterUseTypeMeta
    ),
    other: new PropertyMeta(
      "other",
      "\u4F7F\u7528\u7D42\u4E86\u5F8C\u306E\u53D6\u308A\u6271\u3044\u305D\u306E\u4ED6",
      messageId(
        "lbl.\u4F7F\u7528\u7D42\u4E86\u5F8C\u306E\u53D6\u308A\u6271\u3044\u305D\u306E\u4ED6"
      ),
      "none",
      false,
      handlingAfterUseOtherMeta
    ),
  }
);
