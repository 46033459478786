import {
  messageId,
  PropertyMeta,
  ValueObjectMetaComposite,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { cellStockIdMeta } from "../value/cell-stock-id";
import { cellTypeMeta } from "../value/cell-type";
import { cellStockCategoryIdMeta } from "../value/cell-stock-category-id";
import { cellCategoryNumberMeta } from "../value/cell-category-number";
import { cellCategoryNameJaMeta } from "../value/cell-category-name-ja";
import { cellCategoryNameEnMeta } from "../value/cell-category-name-en";
import { cellNumberMeta } from "../value/cell-number";
import { cellNameJaMeta } from "../value/cell-name-ja";
import { cellNameEnMeta } from "../value/cell-name-en";
import { cellProvisionTypeMeta } from "../value/cell-provision-type";
import { opinionsOfStockUsedMeta } from "../value/opinions-of-stock-used";
/**
 * 使用する細胞（年次報告）(値オブジェクト)
 *
 *  */
export type UsedCell = z.infer<typeof schema>;
export type UsedCellSaved = z.infer<typeof savedSchema>;
const minimalSchema = z.object({
  cellStockId: cellStockIdMeta.minimalSchema,
  cellType: cellTypeMeta.minimalSchema,
  cellStockCategoryId: cellStockCategoryIdMeta.minimalSchema,
  cellCategoryNumber: cellCategoryNumberMeta.minimalSchema,
  cellCategoryNameJa: cellCategoryNameJaMeta.minimalSchema,
  cellCategoryNameEn: cellCategoryNameEnMeta.minimalSchema,
  cellNumber: cellNumberMeta.minimalSchema,
  cellNameJa: cellNameJaMeta.minimalSchema,
  cellNameEn: cellNameEnMeta.minimalSchema,
  cellProvisionType: cellProvisionTypeMeta.minimalSchema,
  opinions: opinionsOfStockUsedMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
const savedMinimalSchema = z.object({
  cellStockId: cellStockIdMeta.savedMinimalSchema,
  cellType: cellTypeMeta.savedMinimalSchema,
  cellStockCategoryId: cellStockCategoryIdMeta.savedMinimalSchema,
  cellCategoryNumber: cellCategoryNumberMeta.savedMinimalSchema,
  cellCategoryNameJa: cellCategoryNameJaMeta.savedMinimalSchema,
  cellCategoryNameEn: cellCategoryNameEnMeta.savedMinimalSchema,
  cellNumber: cellNumberMeta.savedMinimalSchema,
  cellNameJa: cellNameJaMeta.savedMinimalSchema,
  cellNameEn: cellNameEnMeta.savedMinimalSchema,
  cellProvisionType: cellProvisionTypeMeta.savedMinimalSchema,
  opinions: opinionsOfStockUsedMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
const schema = z.object({
  cellStockId: cellStockIdMeta.schema,
  cellType: cellTypeMeta.schema,
  cellStockCategoryId: cellStockCategoryIdMeta.schema,
  cellCategoryNumber: cellCategoryNumberMeta.schema,
  cellCategoryNameJa: cellCategoryNameJaMeta.schema,
  cellCategoryNameEn: cellCategoryNameEnMeta.schema,
  cellNumber: cellNumberMeta.schema,
  cellNameJa: cellNameJaMeta.schema,
  cellNameEn: cellNameEnMeta.schema,
  cellProvisionType: cellProvisionTypeMeta.schema,
  opinions: opinionsOfStockUsedMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
});
const savedSchema = z.object({
  cellStockId: cellStockIdMeta.savedSchema,
  cellType: cellTypeMeta.savedSchema,
  cellStockCategoryId: cellStockCategoryIdMeta.savedSchema,
  cellCategoryNumber: cellCategoryNumberMeta.savedSchema,
  cellCategoryNameJa: cellCategoryNameJaMeta.savedSchema,
  cellCategoryNameEn: cellCategoryNameEnMeta.savedSchema,
  cellNumber: cellNumberMeta.savedSchema,
  cellNameJa: cellNameJaMeta.savedSchema,
  cellNameEn: cellNameEnMeta.savedSchema,
  cellProvisionType: cellProvisionTypeMeta.savedSchema,
  opinions: opinionsOfStockUsedMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
const jsonSchema = z.object({
  cellStockId: cellStockIdMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  cellType: cellTypeMeta.jsonSchema.nullish().transform((x) => x ?? undefined),
  cellStockCategoryId: cellStockCategoryIdMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  cellCategoryNumber: cellCategoryNumberMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  cellCategoryNameJa: cellCategoryNameJaMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  cellCategoryNameEn: cellCategoryNameEnMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  cellNumber: cellNumberMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  cellNameJa: cellNameJaMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  cellNameEn: cellNameEnMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  cellProvisionType: cellProvisionTypeMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  opinions: opinionsOfStockUsedMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
/**
 * 使用する細胞（年次報告）(値オブジェクト)のメタデータ。
 *
 *  */
export const usedCellMeta = new ValueObjectMetaComposite(
  "UsedCell",
  "\u4F7F\u7528\u3059\u308B\u7D30\u80DE\uFF08\u5E74\u6B21\u5831\u544A\uFF09",
  messageId(
    "lbl.\u4F7F\u7528\u3059\u308B\u7D30\u80DE\uFF08\u5E74\u6B21\u5831\u544A\uFF09"
  ),
  minimalSchema,
  savedMinimalSchema,
  schema,
  savedSchema,
  jsonSchema,
  undefined,
  undefined,
  {
    cellStockId: new PropertyMeta(
      "cellStockId",
      "\u7D30\u80DEID",
      messageId("lbl.\u7D30\u80DEID"),
      "always",
      false,
      cellStockIdMeta
    ),
    cellType: new PropertyMeta(
      "cellType",
      "\u7D30\u80DE\u533A\u5206",
      messageId("lbl.\u7D30\u80DE\u533A\u5206"),
      "always",
      false,
      cellTypeMeta
    ),
    cellStockCategoryId: new PropertyMeta(
      "cellStockCategoryId",
      "\u7D30\u80DE\u30AB\u30C6\u30B4\u30EAID",
      messageId("lbl.\u7D30\u80DE\u30AB\u30C6\u30B4\u30EAID"),
      "always",
      false,
      cellStockCategoryIdMeta
    ),
    cellCategoryNumber: new PropertyMeta(
      "cellCategoryNumber",
      "\u7D30\u80DE\u30AB\u30C6\u30B4\u30EA\u756A\u53F7",
      messageId("lbl.\u7D30\u80DE\u30AB\u30C6\u30B4\u30EA\u756A\u53F7"),
      "always",
      false,
      cellCategoryNumberMeta
    ),
    cellCategoryNameJa: new PropertyMeta(
      "cellCategoryNameJa",
      "\u7D30\u80DE\u30AB\u30C6\u30B4\u30EA\u540D\uFF08\u65E5\uFF09",
      messageId(
        "lbl.\u7D30\u80DE\u30AB\u30C6\u30B4\u30EA\u540D\uFF08\u65E5\uFF09"
      ),
      "always",
      false,
      cellCategoryNameJaMeta
    ),
    cellCategoryNameEn: new PropertyMeta(
      "cellCategoryNameEn",
      "\u7D30\u80DE\u30AB\u30C6\u30B4\u30EA\u540D\uFF08\u82F1\uFF09",
      messageId(
        "lbl.\u7D30\u80DE\u30AB\u30C6\u30B4\u30EA\u540D\uFF08\u82F1\uFF09"
      ),
      "always",
      false,
      cellCategoryNameEnMeta
    ),
    cellNumber: new PropertyMeta(
      "cellNumber",
      "\u7D30\u80DE\u756A\u53F7",
      messageId("lbl.\u7D30\u80DE\u756A\u53F7"),
      "always",
      false,
      cellNumberMeta
    ),
    cellNameJa: new PropertyMeta(
      "cellNameJa",
      "\u7D30\u80DE\u540D\uFF08\u65E5\uFF09",
      messageId("lbl.\u7D30\u80DE\u540D\uFF08\u65E5\uFF09"),
      "always",
      false,
      cellNameJaMeta
    ),
    cellNameEn: new PropertyMeta(
      "cellNameEn",
      "\u7D30\u80DE\u540D\uFF08\u82F1\uFF09",
      messageId("lbl.\u7D30\u80DE\u540D\uFF08\u82F1\uFF09"),
      "always",
      false,
      cellNameEnMeta
    ),
    cellProvisionType: new PropertyMeta(
      "cellProvisionType",
      "\u7D30\u80DE\u63D0\u4F9B\u533A\u5206",
      messageId("lbl.\u7D30\u80DE\u63D0\u4F9B\u533A\u5206"),
      "always",
      false,
      cellProvisionTypeMeta
    ),
    opinions: new PropertyMeta(
      "opinions",
      "\u4F7F\u7528\u682A\u306E\u610F\u898B",
      messageId("lbl.\u4F7F\u7528\u682A\u306E\u610F\u898B"),
      "none",
      false,
      opinionsOfStockUsedMeta
    ),
  }
);
