import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * iPS細胞ストック必要理由区分(値オブジェクト)
 *
 *
 *
 * risk_reduction:
 * other:  */
export type IpsCellStockRequirementReasonType = z.infer<
  typeof ipsCellStockRequirementReasonTypeMeta.schema
>;
/**
 * iPS細胞ストック必要理由区分(値オブジェクト)のメタデータ。
 *
 *  */
export const ipsCellStockRequirementReasonTypeMeta = new ValueObjectMetaEnum<
  ["risk_reduction", "other"]
>(
  "IpsCellStockRequirementReasonType",
  "iPS\u7D30\u80DE\u30B9\u30C8\u30C3\u30AF\u5FC5\u8981\u7406\u7531\u533A\u5206",
  messageId(
    "lbl.iPS\u7D30\u80DE\u30B9\u30C8\u30C3\u30AF\u5FC5\u8981\u7406\u7531\u533A\u5206"
  ),
  ["risk_reduction", "other"],
  {
    risk_reduction:
      "code.iPS\u7D30\u80DE\u30B9\u30C8\u30C3\u30AF\u5FC5\u8981\u7406\u7531\u533A\u5206.\u7D30\u80DE\u79FB\u690D\u6642\u306E\u514D\u75AB\u53CD\u5FDC\u306E\u30EA\u30B9\u30AF\u3092\u4F4E\u6E1B\u3055\u305B\u308B\u305F\u3081",
    other:
      "code.iPS\u7D30\u80DE\u30B9\u30C8\u30C3\u30AF\u5FC5\u8981\u7406\u7531\u533A\u5206.\u305D\u306E\u4ED6",
  }
);
