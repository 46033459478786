import { ComponentStyleProps } from "../../../../../lib/styles/props/component-style-props";
import {
  CMButtonSearchConditionCheckbox,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import React, { useCallback } from "react";
import { useAppTranslation } from "../../../../../hooks/use-app-translation";

export type ButtonSearchConditionCheckboxProps<
  T extends [string, ...string[]],
> = {
  label: string;

  values?: T[number][] | null;

  hideClearButton?: boolean;

  /**
   * 値オブジェクトメタデータ
   */
  valueObjectMeta: ValueObjectMetaEnum<T>;

  onChange?: (value: T[number][] | undefined) => void;
} & ComponentStyleProps;

export const ButtonSearchConditionCheckbox = <T extends [string, ...string[]]>({
  label,

  values,

  hideClearButton,

  valueObjectMeta,
  onChange,

  sx,
  ...rest
}: ButtonSearchConditionCheckboxProps<T>) => {
  const { t } = useAppTranslation();

  const handleChange = useCallback(
    (selectedValue: T[number][] | null) => {
      onChange?.(selectedValue ?? undefined);
    },
    [onChange],
  );
  const capitalizedLabel = label;

  return (
    <CMButtonSearchConditionCheckbox
      label={capitalizedLabel}
      values={values}
      hideClearButton={hideClearButton}
      valueObjectMeta={valueObjectMeta}
      onChange={handleChange}
      labelSubmit={t("btn.確定ボタン")}
      labelClear={t("btn.クリアボタン")}
      sx={sx}
      {...rest}
    />
  );
};
