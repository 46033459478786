import { ComponentStyleProps } from "../../../../../lib/styles/props/component-style-props";
import { FormSection } from "../../../../ui/form/FormSection/FormSection";
import { useAppTranslation } from "../../../../../hooks/use-app-translation";
import React, { useCallback, useMemo } from "react";
import { Result, resultMeta } from "../../../../../lib/object/value/result";
import { CMFormInputBaseDropDown } from "@pscsrvlab/psc-react-components";
import { getMessageFromEnumValue } from "../../../../../lib/util/common-util";
import { useSelectableResults } from "../../../../../hooks/result-notification/use-selectable-results";

export type ResultNotificationSectionResultProps = {
  editMode: "editable" | "readOnly";
  documentId: number;

  value?: Result;
  onChange?: (value: Result | undefined) => void;
} & ComponentStyleProps;

export const ResultNotificationSectionResult = ({
  editMode,
  documentId,

  value,
  onChange,

  sx,
  ...rest
}: ResultNotificationSectionResultProps) => {
  const { t } = useAppTranslation();

  const { selectableResults } = useSelectableResults(documentId);
  const toOption = useCallback(
    (v: Result) => {
      return {
        label: getMessageFromEnumValue(t, resultMeta, v) ?? "",
        value: v,
      };
    },
    [t],
  );
  const options = useMemo(() => {
    // readOnlyモードなら、単に表示用なので全部表示できるようにする。
    // selectableResultsが今は異なる可能性があるため、こうせざるを得ない。
    if (editMode === "readOnly") return resultMeta.values.map(toOption);

    return selectableResults.map(toOption);
  }, [editMode, selectableResults, toOption]);

  const handleChange = useCallback(
    (value: Result | null) => {
      onChange?.(value ?? undefined);
    },
    [onChange],
  );

  return (
    <FormSection title={t("lbl.結果")} sx={sx} {...rest}>
      <CMFormInputBaseDropDown
        editMode={editMode}
        options={options}
        nullable={true}
        value={value}
        onChange={handleChange}
      />
    </FormSection>
  );
};
