import { AnnualReportContent } from "../value/annual-report-content";
import { ErrorMessageObject, messageId } from "@pscsrvlab/psc-react-components";

export function annualReportContentCustomValidation(
  value: AnnualReportContent,
  skipOptional: boolean,
): ErrorMessageObject[] {
  const errorMessageList: ErrorMessageObject[] = [];
  if (
    !skipOptional &&
    value.cellProvisionType === "provided" &&
    value.cellProvisions.length === 0
  ) {
    const mesId = messageId("mes.分化細胞必須エラー");
    errorMessageList.push({ errorMessageId: mesId });
  }
  return errorMessageList;
}
