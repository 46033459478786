import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * 倫理申請状況区分(値オブジェクト)
 *
 *
 *
 * requesting:
 * approved:  */
export type EthicalApplicationStatusType = z.infer<
  typeof ethicalApplicationStatusTypeMeta.schema
>;
/**
 * 倫理申請状況区分(値オブジェクト)のメタデータ。
 *
 *  */
export const ethicalApplicationStatusTypeMeta = new ValueObjectMetaEnum<
  ["requesting", "approved"]
>(
  "EthicalApplicationStatusType",
  "\u502B\u7406\u7533\u8ACB\u72B6\u6CC1\u533A\u5206",
  messageId("lbl.\u502B\u7406\u7533\u8ACB\u72B6\u6CC1\u533A\u5206"),
  ["requesting", "approved"],
  {
    requesting:
      "code.\u502B\u7406\u7533\u8ACB\u72B6\u6CC1\u533A\u5206.\u7533\u8ACB\u4E2D",
    approved:
      "code.\u502B\u7406\u7533\u8ACB\u72B6\u6CC1\u533A\u5206.\u627F\u8A8D\u6E08",
  }
);
