import { Box, Flex, HStack, Stack, VStack } from "@chakra-ui/react";
import {
  SearchInquiryApiArg,
  useSearchInquiryQuery,
} from "../../../../store/api/generated/stock-request-api";
import { useNavigate } from "react-router-dom";
import { SearchConditionSidebarInquiry } from "./_components/SearchConditionSidebarInquiry/SearchConditionSidebarInquiry";
import { hasValue, isNullish } from "../../../../lib/util/common-util";
import { CMButton } from "@pscsrvlab/psc-react-components";
import React, { useCallback, useMemo } from "react";
import {
  inquiryMeta,
  InquirySaved,
} from "../../../../lib/object/entity/inquiry";
import { Pagination } from "../../../ui/search/Pagination/Pagination";
import { PageNumberAndSearchResult } from "../../../ui/search/PageNumberAndSearchResult/PageNumberAndSearchResult";
import { CommentThread } from "./_components/CommentThread/CommentThread";
import { useAppTranslation } from "../../../../hooks/use-app-translation";
import {
  getEnumFromSearchParams,
  getNumberFromSearchParams,
} from "../../../../lib/util/search-params-util";
import { projectIdMeta } from "../../../../lib/object/value/project-id";
import { institutionIdMeta } from "../../../../lib/object/value/institution-id";
import { documentIdMeta } from "../../../../lib/object/value/document-id";
import { inquirySortConditionMeta } from "../../../../lib/object/value/inquiry-sort-condition";
import { searchConditionLimitMeta } from "../../../../lib/object/value/search-condition-limit";
import { searchConditionPageNumberMeta } from "../../../../lib/object/value/search-condition-page-number";
import { useAppParams } from "../../../../hooks/use-app-params";
import { useSearchPage } from "../../../../hooks/use-search-page";
import { INQUIRY_DEFAULT_SEARCH_LIMIT } from "../../../../lib/constant/constant";
import { useSearchResultPaginationNumbers } from "../../../../hooks/use-search-result-pagination-numbers";
import { FrameUpperRightButton } from "../../../ui/frame/FrameUpperRightButton/FrameUpperRightButton";

export type InquiryPageProps = {
  type: "root" | "project" | "document";
};
export const InquiryPage = ({ type }: InquiryPageProps) => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const { projectId, documentId } = useAppParams();

  // 案件画面内なら案件ID、書類画面内なら書類IDをデフォルト検索条件とする。
  const urlSearchParamsToSearchArgsWithDefault = useCallback(
    (searchParams: URLSearchParams) => {
      return urlSearchParamsToSearchArgs(
        searchParams,
        type === "project" && hasValue(projectId) ? projectId : null,
        type === "document" && hasValue(documentId) ? documentId : null,
      );
    },
    [type, projectId, documentId],
  );

  const { searchArgs, handleChangeSearchCondition, handleChangePage } =
    useSearchPage(urlSearchParamsToSearchArgsWithDefault);

  /**
   * 質疑応答一覧の取得
   */
  const { data, isLoading } = useSearchInquiryQuery(searchArgs);

  // 取得したデータをオブジェクトに変換する
  const inquiries: InquirySaved[] = useMemo(() => {
    if (isNullish(data)) return [];
    return data.items
      .map((v) => {
        // 検索時は、原則skipOptionalをtrueとする。
        return inquiryMeta.toSavedDomainObjectOrNull(v, true);
      })
      .filter(hasValue);
  }, [data]);

  const { totalElements, currentPageNumber, totalPageCount } =
    useSearchResultPaginationNumbers(searchArgs, data);

  return (
    <HStack flex={1} spacing={0} overflow={"hidden"} alignItems={"stretch"}>
      {/* 検索条件エリア */}
      <SearchConditionSidebarInquiry
        type={type}
        searchArgs={searchArgs}
        onChange={handleChangeSearchCondition}
      />

      {/* 検索結果一覧エリア */}
      <Flex
        direction={"column"}
        flexGrow={1}
        h={"100%"}
        pb={"50px"}
        overflow={"auto"}
        position={"relative"}
      >
        {/*ヘッダ*/}
        <VStack alignSelf={"stretch"} position={"sticky"} left={"0px"}>
          <FrameUpperRightButton
            sx={{
              alignSelf: "flex-end",
            }}
          >
            {/*新しいスレッドを作成するボタン*/}
            <CMButton
              size={"sm"}
              label={t("btn.新しいスレッドを作成するボタン")}
              onClick={() => {
                // 「FN60-S02（質疑応答作成）」画面へ遷移。
                navigate("./create");
              }}
              sx={{
                display: "block",
                ml: "auto",
              }}
            />
          </FrameUpperRightButton>
        </VStack>
        <VStack alignSelf={"flex-start"}>
          {!isLoading && (
            <PageNumberAndSearchResult
              currentPageNumber={currentPageNumber}
              totalElements={totalElements}
              sx={{ mt: "10px", pl: "50px" }}
            />
          )}
        </VStack>

        {/*質疑応答*/}
        <Box mt={"10px"} paddingX={"50px"}>
          {!isLoading && (
            <Stack spacing="32px">
              {inquiries.map((inquiry, index) => (
                <CommentThread key={index} inquiry={inquiry} />
              ))}
            </Stack>
          )}
        </Box>
        {/*フッタ*/}
        <VStack
          alignSelf={"stretch"}
          left={"0px"}
          position={"sticky"}
          pb={"80px"}
        >
          {/*ページネーション*/}
          {hasValue(totalPageCount) && totalPageCount > 0 && (
            <Pagination
              totalPageCount={totalPageCount}
              currentPageNumber={currentPageNumber}
              onPageChange={handleChangePage}
              sx={{
                display: "grid",
                justifyItems: "center",
                mt: "30px",
              }}
            />
          )}
        </VStack>
      </Flex>
    </HStack>
  );
};

/**
 * クエリパラメータから検索条件を作成する.
 */
function urlSearchParamsToSearchArgs(
  searchParams: URLSearchParams,
  defaultProjectId: number | null,
  defaultDocumentId: number | null,
): SearchInquiryApiArg {
  const sortCondition =
    getEnumFromSearchParams(
      searchParams,
      "sortCondition",
      inquirySortConditionMeta,
    ) ?? "updated_at_desc";
  const limit =
    getNumberFromSearchParams(
      searchParams,
      "limit",
      searchConditionLimitMeta,
    ) ?? INQUIRY_DEFAULT_SEARCH_LIMIT;

  const institutionId = getNumberFromSearchParams(
    searchParams,
    "institutionId",
    institutionIdMeta,
  );
  const projectId = hasValue(defaultProjectId)
    ? defaultProjectId
    : getNumberFromSearchParams(searchParams, "projectId", projectIdMeta);
  const documentId = hasValue(defaultDocumentId)
    ? defaultDocumentId
    : getNumberFromSearchParams(searchParams, "documentId", documentIdMeta);

  const pageNumber = getNumberFromSearchParams(
    searchParams,
    "pageNumber",
    searchConditionPageNumberMeta,
  );

  return {
    sortCondition,
    limit,

    institutionId,
    projectId,
    documentId,

    pageNumber,
  };
}
