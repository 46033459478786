import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * メール通知(値オブジェクト)
 *
 *
 *
 * notification:
 * stop:  */
export type MailNotification = z.infer<typeof mailNotificationMeta.schema>;
/**
 * メール通知(値オブジェクト)のメタデータ。
 *
 *  */
export const mailNotificationMeta = new ValueObjectMetaEnum<
  ["notification", "stop"]
>(
  "MailNotification",
  "\u30E1\u30FC\u30EB\u901A\u77E5",
  messageId("lbl.\u30E1\u30FC\u30EB\u901A\u77E5"),
  ["notification", "stop"],
  {
    notification: "code.\u30E1\u30FC\u30EB\u901A\u77E5.\u901A\u77E5",
    stop: "code.\u30E1\u30FC\u30EB\u901A\u77E5.\u901A\u77E5\u505C\u6B62",
  }
);
