import { Flex, VStack } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import { DocumentHeader } from "../../model/document/DocumentHeader/DocumentHeader";
import { FN30S02ProjectHeader } from "../../model/project/FN30S02ProjectHeader/FN30S02ProjectHeader";
import { useAppParams } from "../../../hooks/use-app-params";
import { isNullish } from "../../../lib/util/common-util";

export const DocumentLayout = () => {
  const { projectId } = useAppParams();

  return (
    <Flex
      flex={"1 1 auto"}
      minH={0}
      alignSelf={"stretch"}
      flexFlow={"column nowrap"}
      justifyContent={"stretch"}
      alignItems={"stretch"}
    >
      {/* 案件画面内であれば、案件レイアウトの方で案件ヘッダが出ているので、ここでは不要。 */}
      {isNullish(projectId) && <FN30S02ProjectHeader />}
      <DocumentHeader />
      <VStack
        flex={"1 1 auto"}
        minH={0}
        alignItems={"stretch"}
        overflow={"hidden"}
      >
        <Outlet />
      </VStack>
    </Flex>
  );
};
