import {
  Action,
  AnyAction,
  CombinedState,
  combineReducers,
  configureStore,
} from "@reduxjs/toolkit";
import thunk, { ThunkAction } from "redux-thunk";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { emptySplitApi as api } from "./api/empty-api";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist/es/constants";
import { authReducer } from "./auth/slice";

// 定義したReducerはここに列挙が必要
const combinedReducer = combineReducers({
  auth: authReducer,
  [api.reducerPath]: api.reducer,
  // auth: authReducer,
});

const reducers = (state: CombinedState<any> | undefined, action: AnyAction) => {
  // ログアウトアクションの場合はすべての State をクリアする
  if (action.type === "auth/logout") {
    state = undefined;
  }
  return combinedReducer(state, action);
};

// State永続化の設定
// 永続化しないStateについては blacklist で指定する
const persistConfig = {
  key: "root",
  storage,
  blacklist: [
    "auth",
    // 以下URLのお勧めに従って、apiをpersistの対象から外した。
    // が、メッセージは例外にしたいところなので、もうちょっと考える必要あり。
    // https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
    api.reducerPath,
  ],
};
const persistedReducer = persistReducer(persistConfig, reducers);

const index = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // 参考: https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          "auth/getPreferredMFA/rejected",
          "auth/refreshCognitoUser/rejected",
          "auth/resetPasswordSubmit/rejected",
          "auth/signIn/fulfilled",
          "auth/signIn/rejected",
          "auth/confirmSignIn/fulfilled",
          "auth/confirmSignIn/rejected",
        ],
        ignoredPaths: ["auth.signInInfo.cognitoUser"],
      },
    }).concat(thunk, api.middleware),
});
export type AppDispatch = typeof index.dispatch;
export type RootState = ReturnType<typeof index.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export const persistor = persistStore(index);
export default index;
