import {
  messageId,
  PropertyMeta,
  ValueObjectMetaComposite,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { viewPathMeta } from "../value/view-path";
import { changeReasonContentMeta } from "../value/change-reason-content";
/**
 * 変更理由(値オブジェクト)
 *
 *  */
export type ChangeReason = z.infer<typeof schema>;
export type ChangeReasonSaved = z.infer<typeof savedSchema>;
const minimalSchema = z.object({
  path: viewPathMeta.minimalSchema,
  content: changeReasonContentMeta.minimalSchema,
});
const savedMinimalSchema = z.object({
  path: viewPathMeta.savedMinimalSchema,
  content: changeReasonContentMeta.savedMinimalSchema,
});
const schema = z.object({
  path: viewPathMeta.schema,
  content: changeReasonContentMeta.schema,
});
const savedSchema = z.object({
  path: viewPathMeta.savedSchema,
  content: changeReasonContentMeta.savedSchema,
});
const jsonSchema = z.object({
  path: viewPathMeta.jsonSchema.nullish().transform((x) => x ?? undefined),
  content: changeReasonContentMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
/**
 * 変更理由(値オブジェクト)のメタデータ。
 *
 *  */
export const changeReasonMeta = new ValueObjectMetaComposite(
  "ChangeReason",
  "\u5909\u66F4\u7406\u7531",
  messageId("lbl.\u5909\u66F4\u7406\u7531"),
  minimalSchema,
  savedMinimalSchema,
  schema,
  savedSchema,
  jsonSchema,
  undefined,
  undefined,
  {
    path: new PropertyMeta(
      "path",
      "\u30D1\u30B9",
      messageId("lbl.\u30D1\u30B9"),
      "always",
      false,
      viewPathMeta
    ),
    content: new PropertyMeta(
      "content",
      "\u5185\u5BB9",
      messageId("lbl.\u5185\u5BB9"),
      "always",
      false,
      changeReasonContentMeta
    ),
  }
);
