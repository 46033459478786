import { isNullish } from "../../../lib/util/common-util";
import { merge } from "lodash";
import { useMemo } from "react";
import { useAppGetTerminationReportQuery } from "../../query/use-app-get-termination-report-query";
import { useTerminationReportVMOrEmpty } from "./use-termination-report-vm";

/**
 * 使用終了報告の表示内容を取得する。
 *
 * 書類IDを指定しなかった場合、
 * - その案件から使用終了報告を新規作成する場合の内容を取得する。
 * - 保存時は、新規作成オペレーションを呼び出す。
 *
 * 書類IDを指定した場合、
 * - その書類IDの使用終了報告の内容を取得する。
 * - 保存時は、更新オペレーションを呼び出す。
 */
export function useTerminationReport(
  /**
   * 案件ID。
   */
  projectId: number,

  /**
   * 書類ID。
   * この値がない場合、
   * 案件IDから新規の（空の）使用終了報告ビューモデルを作成する。
   */
  documentId: number | undefined,

  options?: {
    /**
     * skipがtrueの場合、何も行わない。
     */
    skip: boolean;
  },
) {
  const _options = useMemo(() => merge({ skip: false }, options), [options]);

  // 書類IDが指定されている場合、使用終了報告を取得する。
  const { data: terminationReport } = useAppGetTerminationReportQuery(
    { documentId: documentId ?? -1 },
    { skip: _options.skip || isNullish(documentId) },
  );

  return useTerminationReportVMOrEmpty(
    projectId,
    documentId,
    terminationReport,
    _options,
  );
}
