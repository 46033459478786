import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * 結果(値オブジェクト)
 *
 *
 *
 * approved:
 * conditionally_approved:
 * reexamination:
 * rejected:
 * no_need_to_review:
 * accepted:  */
export type Result = z.infer<typeof resultMeta.schema>;
/**
 * 結果(値オブジェクト)のメタデータ。
 *
 *  */
export const resultMeta = new ValueObjectMetaEnum<
  [
    "approved",
    "conditionally_approved",
    "reexamination",
    "rejected",
    "no_need_to_review",
    "accepted"
  ]
>(
  "Result",
  "\u7D50\u679C",
  messageId("lbl.\u7D50\u679C"),
  [
    "approved",
    "conditionally_approved",
    "reexamination",
    "rejected",
    "no_need_to_review",
    "accepted",
  ],
  {
    approved: "code.\u7D50\u679C.\u627F\u8A8D",
    conditionally_approved: "code.\u7D50\u679C.\u6761\u4EF6\u4ED8\u627F\u8A8D",
    reexamination: "code.\u7D50\u679C.\u4FDD\u7559\u30FB\u518D\u5BE9\u67FB",
    rejected: "code.\u7D50\u679C.\u5374\u4E0B",
    no_need_to_review: "code.\u7D50\u679C.\u5BE9\u67FB\u4E0D\u8981",
    accepted: "code.\u7D50\u679C.\u53D7\u7406",
  }
);
