import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * 業務執行理事承認状態(値オブジェクト)
 *
 *
 *
 * unapproved:
 * approved:  */
export type ExecutiveDirectorApprovalState = z.infer<
  typeof executiveDirectorApprovalStateMeta.schema
>;
/**
 * 業務執行理事承認状態(値オブジェクト)のメタデータ。
 *
 *  */
export const executiveDirectorApprovalStateMeta = new ValueObjectMetaEnum<
  ["unapproved", "approved"]
>(
  "ExecutiveDirectorApprovalState",
  "\u696D\u52D9\u57F7\u884C\u7406\u4E8B\u627F\u8A8D\u72B6\u614B",
  messageId("lbl.\u696D\u52D9\u57F7\u884C\u7406\u4E8B\u627F\u8A8D\u72B6\u614B"),
  ["unapproved", "approved"],
  {
    unapproved:
      "code.\u696D\u52D9\u57F7\u884C\u7406\u4E8B\u627F\u8A8D\u72B6\u614B.\u672A\u627F\u8A8D",
    approved:
      "code.\u696D\u52D9\u57F7\u884C\u7406\u4E8B\u627F\u8A8D\u72B6\u614B.\u627F\u8A8D",
  }
);
