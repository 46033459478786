import { ZodType } from "zod";
import { useCallback } from "react";
import { hasValue, isNullish } from "../lib/util/common-util";
import { DomainObjectMeta } from "@pscsrvlab/psc-react-components";
import { jsonPatchOperationMeta } from "../lib/object/value/json-patch-operation";

export function useCreateJsonPatchOperations<
  Schema extends ZodType<any>,
  SavedSchema extends ZodType<any>,
  JsonSchema extends ZodType<any>,
  Meta extends DomainObjectMeta<Schema, SavedSchema, JsonSchema>,
>(meta: Meta) {
  const createJsonPatchOperations = useCallback(
    (before: any, after: any) => {
      const jsonPatchOperations = meta.toJsonPatchOperationsOrNull(
        before,
        after,
      );
      if (isNullish(jsonPatchOperations)) return undefined;
      return jsonPatchOperations
        .map((op) => jsonPatchOperationMeta.toDomainObjectOrNull(op))
        .filter(hasValue);
    },
    [meta],
  );

  return { createJsonPatchOperations };
}
