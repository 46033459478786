import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * 国内輸送手配業者荷主登録区分(値オブジェクト)
 *
 *
 *
 * registered:
 * not_registered:
 * other:  */
export type DomesticDelivererShipperRegistrationType = z.infer<
  typeof domesticDelivererShipperRegistrationTypeMeta.schema
>;
/**
 * 国内輸送手配業者荷主登録区分(値オブジェクト)のメタデータ。
 *
 *  */
export const domesticDelivererShipperRegistrationTypeMeta =
  new ValueObjectMetaEnum<["registered", "not_registered", "other"]>(
    "DomesticDelivererShipperRegistrationType",
    "\u56FD\u5185\u8F38\u9001\u624B\u914D\u696D\u8005\u8377\u4E3B\u767B\u9332\u533A\u5206",
    messageId(
      "lbl.\u56FD\u5185\u8F38\u9001\u624B\u914D\u696D\u8005\u8377\u4E3B\u767B\u9332\u533A\u5206"
    ),
    ["registered", "not_registered", "other"],
    {
      registered:
        "code.\u56FD\u5185\u8F38\u9001\u624B\u914D\u696D\u8005\u8377\u4E3B\u767B\u9332\u533A\u5206.\u3042\u308A",
      not_registered:
        "code.\u56FD\u5185\u8F38\u9001\u624B\u914D\u696D\u8005\u8377\u4E3B\u767B\u9332\u533A\u5206.\u306A\u3057",
      other:
        "code.\u56FD\u5185\u8F38\u9001\u624B\u914D\u696D\u8005\u8377\u4E3B\u767B\u9332\u533A\u5206.\u305D\u306E\u4ED6",
    }
  );
