import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * アカウント状態(値オブジェクト)
 *
 *
 *
 * enabled:
 * disabled:  */
export type AccountState = z.infer<typeof accountStateMeta.schema>;
/**
 * アカウント状態(値オブジェクト)のメタデータ。
 *
 *  */
export const accountStateMeta = new ValueObjectMetaEnum<
  ["enabled", "disabled"]
>(
  "AccountState",
  "\u30A2\u30AB\u30A6\u30F3\u30C8\u72B6\u614B",
  messageId("lbl.\u30A2\u30AB\u30A6\u30F3\u30C8\u72B6\u614B"),
  ["enabled", "disabled"],
  {
    enabled: "code.\u30A2\u30AB\u30A6\u30F3\u30C8\u72B6\u614B.\u6709\u52B9",
    disabled:
      "code.\u30A2\u30AB\u30A6\u30F3\u30C8\u72B6\u614B.\u5229\u7528\u505C\u6B62",
  }
);
