import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * 細胞区分(値オブジェクト)
 *
 *
 *
 * research_purpose:
 * clinical_purpose:  */
export type CellType = z.infer<typeof cellTypeMeta.schema>;
/**
 * 細胞区分(値オブジェクト)のメタデータ。
 *
 *  */
export const cellTypeMeta = new ValueObjectMetaEnum<
  ["research_purpose", "clinical_purpose"]
>(
  "CellType",
  "\u7D30\u80DE\u533A\u5206",
  messageId("lbl.\u7D30\u80DE\u533A\u5206"),
  ["research_purpose", "clinical_purpose"],
  {
    research_purpose: "code.\u7D30\u80DE\u533A\u5206.\u7814\u7A76\u7528",
    clinical_purpose: "code.\u7D30\u80DE\u533A\u5206.\u81E8\u5E8A\u7528",
  }
);
