import {
  messageId,
  PropertyMeta,
  ValueObjectMetaString,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.string().max(100);
const savedMinimalSchema = z.string().max(100);
const schema = z.string().min(1).max(100);
const savedSchema = z.string().min(1).max(100);
const jsonSchema = z.string();
/**
 * 分担機関名(値オブジェクト)のメタデータ。
 *
 *  */
export const collaborativePartnerNameMeta = new ValueObjectMetaString(
  "CollaborativePartnerName",
  "\u5206\u62C5\u6A5F\u95A2\u540D",
  messageId("lbl.\u5206\u62C5\u6A5F\u95A2\u540D"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
