import React, { memo } from "react";
import {
  CMFormInputTextArea,
  ValueObjectMetaString,
} from "@pscsrvlab/psc-react-components";
import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";
import { FormSection } from "../../../../../ui/form/FormSection/FormSection";

export type SectionReviewConferenceTextAreaProps = {
  /**
   * 画面編集モード
   */
  editMode?: "editable" | "readOnly";

  /**
   * タイトル
   */
  title?: string;

  value?: string;

  /**
   * 値オブジェクトメタデータ
   */
  valueObjectMeta?: ValueObjectMetaString;

  /**
   * 変更イベント
   */
  onChange?: (changedValue: string) => void;
} & ComponentStyleProps;

/**
 * FN50S02 審査会詳細
 * テキストエリアセクション
 */
export const SectionReviewConferenceTextArea = memo(
  function SectionReviewConferenceTextArea({
    editMode,

    title,

    value,

    valueObjectMeta,
    onChange,

    sx,
    ...rest
  }: SectionReviewConferenceTextAreaProps) {
    return (
      <FormSection title={title} sx={sx} {...rest}>
        <CMFormInputTextArea
          editMode={editMode}
          noHeader={true}
          minRows={3}
          value={value}
          valueObjectMeta={valueObjectMeta}
          onChange={onChange}
          sx={{ w: "100%" }}
        />
      </FormSection>
    );
  },
);
