import {
  messageId,
  PropertyMeta,
  ValueObjectMetaEnum,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
/**
 * 国内輸送手配業者区分(値オブジェクト)
 *
 *
 *
 * not_selected:
 * seino_super_express:
 * seino_transportation:
 * other:  */
export type DomesticDelivererType = z.infer<
  typeof domesticDelivererTypeMeta.schema
>;
/**
 * 国内輸送手配業者区分(値オブジェクト)のメタデータ。
 *
 *  */
export const domesticDelivererTypeMeta = new ValueObjectMetaEnum<
  ["not_selected", "seino_super_express", "seino_transportation", "other"]
>(
  "DomesticDelivererType",
  "\u56FD\u5185\u8F38\u9001\u624B\u914D\u696D\u8005\u533A\u5206",
  messageId("lbl.\u56FD\u5185\u8F38\u9001\u624B\u914D\u696D\u8005\u533A\u5206"),
  ["not_selected", "seino_super_express", "seino_transportation", "other"],
  {
    not_selected:
      "code.\u56FD\u5185\u8F38\u9001\u624B\u914D\u696D\u8005\u533A\u5206.\u672A\u9078\u629E",
    seino_super_express:
      "code.\u56FD\u5185\u8F38\u9001\u624B\u914D\u696D\u8005\u533A\u5206.\u30BB\u30A4\u30CE\u30FC\u30B9\u30FC\u30D1\u30FC\u30A8\u30AF\u30B9\u30D7\u30EC\u30B9",
    seino_transportation:
      "code.\u56FD\u5185\u8F38\u9001\u624B\u914D\u696D\u8005\u533A\u5206.\u897F\u6FC3\u904B\u8F38",
    other:
      "code.\u56FD\u5185\u8F38\u9001\u624B\u914D\u696D\u8005\u533A\u5206.\u305D\u306E\u4ED6",
  }
);
