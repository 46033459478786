import { useGetRevisionRequestQuery } from "../../store/api/generated/stock-request-api";
import { isNullish } from "../../lib/util/common-util";
import { useMemo } from "react";
import {
  revisionRequestMeta,
  RevisionRequestSaved,
} from "../../lib/object/entity/revision-request";

export function useAppGetRevisionRequestQuery(
  arg: Parameters<typeof useGetRevisionRequestQuery>[0],
  options?: Parameters<typeof useGetRevisionRequestQuery>[1],
  skipOptionalValidations = true, // get系は、skipOptionalValidationsのデフォルトはtrueとする。
): {
  data: RevisionRequestSaved | null;
  refetch: ReturnType<typeof useGetRevisionRequestQuery>["refetch"];
  error: ReturnType<typeof useGetRevisionRequestQuery>["error"];
} {
  const {
    data: rawData,
    refetch,
    error,
  } = useGetRevisionRequestQuery(arg, options);
  const data = useMemo(() => {
    if (isNullish(rawData)) return null;
    return revisionRequestMeta.toSavedDomainObjectOrNull(
      rawData,
      skipOptionalValidations,
    );
  }, [rawData, skipOptionalValidations]);
  return { data, refetch, error };
}
