import { ComponentStyleProps } from "../../../../../../../../../lib/styles/props/component-style-props";
import {
  CMFormInputRadio,
  CMFormInputTextArea,
  Comments,
} from "@pscsrvlab/psc-react-components";
import { handlingAfterUseTypeMeta } from "../../../../../../../../../lib/object/value/handling-after-use-type";
import { handlingAfterUseOtherMeta } from "../../../../../../../../../lib/object/value/handling-after-use-other";
import React, { memo, useCallback, useMemo } from "react";
import { CMButtonFormCommentProps } from "@pscsrvlab/psc-react-components/src/components/form/comment";
import { HandlingAfterUse } from "../../../../../../../../../lib/object/value/handling-after-use";
import { useSectionDiffMode } from "../../../../../../../../../hooks/document/change-application/use-section-diff-mode";
import { useChangeBadgeProperties } from "../../../../../../../../../hooks/document/change-application/use-change-badge-properties";
import { ChangeReasonForm } from "../../form/ChangeReasonForm/ChangeReasonForm";
import { FormSection } from "../../../../../../../../ui/form/FormSection/FormSection";
import { useAppTranslation } from "../../../../../../../../../hooks/use-app-translation";
import { hasValue } from "../../../../../../../../../lib/util/common-util";
import { Text } from "@chakra-ui/react";
import { ChangeReasonItemViewModel } from "../../../../../../../../../lib/object/vm/change-application-view-model";

export type HandlingAfterUseSectionProps = {
  documentType:
  | "new_application"
  | "change_application"
  | "project_force_update"
  | "project_force_create"
  | "project_content";
  editMode: "editable" | "readOnly";

  value: HandlingAfterUse;
  onChange?: (value: HandlingAfterUse) => void;

  changedFrom?: HandlingAfterUse;
  changeReason?: string;
  onChangeReason?: (
    change: (before: ChangeReasonItemViewModel) => ChangeReasonItemViewModel,
  ) => void;
  displayChangesOnly?: boolean;

  /**
   * コメントボタンのprops。
   * これが存在すれば、コメントボタンを表示する。
   */
  commentButtonProps?: CMButtonFormCommentProps;
  /**
   * 1セクション単位の修正依頼コメント・返信。履歴参照画面で使用。
   */
  commentsList?: Comments[];
} & ComponentStyleProps;

export const HandlingAfterUseSection = memo(function HandlingAfterUseSection({
  documentType,
  editMode,

  value,
  onChange,

  changedFrom,
  changeReason,
  onChangeReason,
  displayChangesOnly,

  commentButtonProps,
  commentsList,

  sx,
  ...rest
}: HandlingAfterUseSectionProps) {
  const { t } = useAppTranslation();

  const handleChangeType = useCallback(
    (v: "other" | "abandonment" | "return") => {
      onChange?.({
        type: v,
        other: v === "other" ? value.other : undefined,
      });
    },
    [onChange, value.other],
  );

  const handleChangeOther = useCallback(
    (v: string) => {
      onChange?.({ type: value.type, other: v });
    },
    [onChange, value.type],
  );

  const sectionDiffMode = useSectionDiffMode(
    documentType,
    changedFrom,
    value,
    onChangeReason,
  );

  const changeBadgePropertiesType = useChangeBadgeProperties(
    documentType,
    changedFrom?.type,
  );
  const changeBadgePropertiesOther = useChangeBadgeProperties(
    documentType,
    changedFrom?.other,
  );

  const readOnlyValue: string = useMemo(() => {
    if (editMode === "readOnly") {
      if (value.type === "return") {
        return t("code.使用終了後の取り扱い区分.返却する");
      }
      if (value.type === "abandonment") {
        return t("code.使用終了後の取り扱い区分.廃棄する");
      }
      if (value.type === "other" && hasValue(value.other)) {
        return value.other;
      }
    }
    return "";
  }, [editMode, value, t]);

  return (
    <FormSection
      title={t("lbl.使用期間終了後の細胞の取り扱い")}
      description={
        editMode === "editable"
          ? t("gdc.使用期間終了後の細胞の取り扱い補足")
          : undefined
      }
      displayMode={sectionDiffMode}
      displayChangesOnly={displayChangesOnly}
      commentButtonProps={commentButtonProps}
      commentsList={commentsList}
      sx={{ w: "100%", ...sx }}
      {...rest}
    >
      {editMode === "readOnly" ? (
        <Text>{readOnlyValue}</Text>
      ) : (
        <CMFormInputRadio
          editMode={editMode}
          noHeader={!hasValue(changedFrom?.type)}
          value={value.type}
          valueObjectMeta={handlingAfterUseTypeMeta}
          onChange={handleChangeType}
          changeBadgeProperties={changeBadgePropertiesType}
          insertedNodeOnSelected={
            <CMFormInputTextArea
              noHeader={!hasValue(changedFrom?.other)}
              value={value.other}
              valueObjectMeta={handlingAfterUseOtherMeta}
              editMode={editMode}
              minRows={2}
              onChange={handleChangeOther}
              changeBadgeProperties={changeBadgePropertiesOther}
              ml={"30px"}
              sx={{ w: "500px" }}
            />
          }
          insertValue={"other"}
        />
      )}

      {sectionDiffMode === "updated" && (
        <ChangeReasonForm
          value={changeReason}
          onChange={onChangeReason}
          editMode={editMode}
        />
      )}
    </FormSection>
  );
});
