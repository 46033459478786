import { useMemo } from "react";
import { merge } from "lodash";
import { isNullish } from "../../../lib/util/common-util";
import { TerminationReportSaved } from "../../../lib/object/entity/termination-report";
import { TerminationReportViewModel } from "../../../lib/object/vm/termination-report-view-model";
import { useAppGetTerminationReportQuery } from "../../query/use-app-get-termination-report-query";
import { useTerminationReportVM } from "./use-termination-report-vm";

export function useTerminationReportReadonlyVm(
  documentId: number | null | undefined,

  options?: {
    /**
     * skipがtrueの場合、何も行わない。
     */
    skip: boolean;
  },
): {
  currentData: TerminationReportSaved | null;
  vm: TerminationReportViewModel | null;
} {
  const _options = useMemo(() => merge({ skip: false }, options), [options]);

  const { currentData } = useAppGetTerminationReportQuery(
    { documentId: documentId ?? -1 },
    { skip: _options.skip || isNullish(documentId) },
  );

  const vm = useTerminationReportVM(currentData);
  return { currentData, vm };
}
