import { VStack } from "@chakra-ui/react";
import { DocumentState } from "../../../../../../lib/object/value/document-state";
import { ReviewSaved } from "../../../../../../lib/object/entity/review";
import { CMButton } from "@pscsrvlab/psc-react-components";
import React, { useMemo } from "react";
import { SectionComment } from "../SectionComment/SectionComment";
import { SectionOfficeChecklist } from "../SectionOfficeChecklist/SectionOfficeChecklist";
import { SectionCommittee } from "../SectionCommittee/SectionCommittee";
import { SectionDate } from "../SectionDate/SectionDate";
import { SectionResult } from "../SectionResult/SectionResult";
import { freeTextMeta } from "../../../../../../lib/object/value/free-text";
import { SectionDirectorApproval } from "../SectionDirectorApproval/SectionDirectorApproval";
import { useAppSelector } from "../../../../../../hooks/redux-hooks";
import { selectUserInfo } from "../../../../../../store/auth/slice";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { ReviewResult } from "../../../../../../lib/object/value/review-result";

export type ReviewDetailsContentTabProps = {
  editMode?: "editable" | "readOnly";
  editContent: ReviewSaved;
  documentState?: DocumentState;
  onChange: (change: (before: ReviewSaved) => ReviewSaved) => void;
  onFixedReview?: () => void;
  onApprovalReview?: () => void;
};

/**
 * FN40-S30審査
 * 書類内容タブの表示内容を制御する
 */
export const ReviewDetailsContentTab = ({
  editMode = "readOnly",
  editContent,
  documentState,
  onChange,
  onFixedReview,
  onApprovalReview,
}: ReviewDetailsContentTabProps) => {
  const { t } = useAppTranslation();

  // ログインユーザー情報
  const currentUserInfo = useAppSelector(selectUserInfo);

  const canConcludeReview = useMemo(() => {
    return (
      documentState === "reviewing" &&
      editContent.reviewType === "office_review" &&
      editContent.reviewState === "unheld"
    );
  }, [documentState, editContent.reviewState, editContent.reviewType]);

  const canApproveReview = useMemo(() => {
    return (
      documentState === "executive_director_reviewing" &&
      (editContent.reviewType === "office_review" ||
        editContent.reviewType === "committee_review") &&
      (editContent.reviewResult === "approved" ||
        editContent.reviewResult === "conditionally_approved") &&
      editContent.executiveDirectorApproval?.executiveDirectorApprovalState !==
        "approved"
    );
  }, [
    documentState,
    editContent.executiveDirectorApproval?.executiveDirectorApprovalState,
    editContent.reviewResult,
    editContent.reviewType,
  ]);

  return (
    // メインエリア
    <VStack alignItems={"stretch"}>
      {editContent.reviewType === "committee_review" && (
        // 委員会審査の場合のみ表示
        // 審査会
        <SectionCommittee reviewConferenceId={editContent.reviewConferenceId} />
      )}
      {/*審査実施日*/}
      <SectionDate
        editMode={editMode}
        value={editContent.reviewDate}
        onChange={(v: { year: number; month: number; day: number }) => {
          onChange((before) => ({
            ...before,
            reviewDate: v,
          }));
        }}
      />
      {/*審査結果*/}
      <SectionResult
        editMode={editMode}
        value={editContent.reviewResult}
        onChange={(v: ReviewResult | null) => {
          onChange((before) => ({
            ...before,
            reviewResult: v ?? undefined,
          }));
        }}
      />
      {/*「承認」以外の場合の理由*/}
      <SectionComment
        editMode={editMode}
        title={t("lbl.「承認」以外の場合の理由")}
        valueObjectMeta={freeTextMeta}
        value={editContent.unapprovalReason}
        onChange={(v: string) => {
          onChange((before) => ({
            ...before,
            unapprovalReason: v,
          }));
        }}
      />
      {/*備考*/}
      <SectionComment
        editMode={editMode}
        title={t("lbl.備考")}
        valueObjectMeta={freeTextMeta}
        value={editContent.note}
        onChange={(v: string) => {
          onChange((before) => ({
            ...before,
            note: v,
          }));
        }}
      />
      {/*業務執行理事承認*/}
      <SectionDirectorApproval value={editContent.executiveDirectorApproval} />
      {/*添付資料*/}
      {editContent.reviewType === "office_review" && (
        <SectionOfficeChecklist
          editMode={editMode}
          files={editContent.officeChecklist ?? []}
          onChange={(change) => {
            onChange((before) => ({
              ...before,
              officeChecklist: change(before.officeChecklist),
            }));
          }}
        />
      )}
      <VStack>
        {currentUserInfo?.role === "office_member" &&
          editMode === "readOnly" &&
          canConcludeReview && (
            <CMButton
              label={t("btn.審査結果を確定するボタン")}
              onClick={onFixedReview}
            />
          )}
        {currentUserInfo?.role === "executive_director" &&
          editMode === "readOnly" &&
          canApproveReview && (
            <CMButton
              label={t("btn.審査結果を承認するボタン")}
              onClick={onApprovalReview}
            />
          )}
      </VStack>
    </VStack>
  );
};
