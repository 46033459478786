import {
  messageId,
  PropertyMeta,
  EntityMeta,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { inquiryCustomValidation } from "../custom-validation/inquiry";
import { inquiryIdMeta } from "../value/inquiry-id";
import { institutionIdMeta } from "../value/institution-id";
import { projectIdMeta } from "../value/project-id";
import { documentIdMeta } from "../value/document-id";
import { roleMeta } from "../value/role";
import { inquirySubjectMeta } from "../value/inquiry-subject";
import { inquiryContentMeta } from "../value/inquiry-content";
import { attachmentFileInformationMeta } from "../value/attachment-file-information";
import { inquiryReadStateMeta } from "../value/inquiry-read-state";
import { inquiryReplyMeta } from "../value/inquiry-reply";
import { creationMetadataMeta } from "../value/creation-metadata";
import { updateMetadataMeta } from "../value/update-metadata";
/**
 * 質疑応答(エンティティ)
 *
 *  */
export type Inquiry = z.infer<typeof schema>;
export type InquirySaved = z.infer<typeof savedSchema>;
const minimalSchema = z.object({
  id: inquiryIdMeta.minimalSchema.optional(),
  institutionId: institutionIdMeta.minimalSchema,
  projectId: projectIdMeta.minimalSchema,
  documentId: documentIdMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  issuerRole: roleMeta.minimalSchema,
  subject: inquirySubjectMeta.minimalSchema,
  content: inquiryContentMeta.minimalSchema,
  attachmentFiles: z.array(attachmentFileInformationMeta.minimalSchema),
  applicantReadState: inquiryReadStateMeta.minimalSchema,
  officeMemberReadState: inquiryReadStateMeta.minimalSchema,
  replies: z.array(inquiryReplyMeta.minimalSchema),
  created: creationMetadataMeta.minimalSchema,
  updated: updateMetadataMeta.minimalSchema,
});
const savedMinimalSchema = z.object({
  id: inquiryIdMeta.minimalSchema,
  institutionId: institutionIdMeta.savedMinimalSchema,
  projectId: projectIdMeta.savedMinimalSchema,
  documentId: documentIdMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  issuerRole: roleMeta.savedMinimalSchema,
  subject: inquirySubjectMeta.savedMinimalSchema,
  content: inquiryContentMeta.savedMinimalSchema,
  attachmentFiles: z.array(attachmentFileInformationMeta.savedMinimalSchema),
  applicantReadState: inquiryReadStateMeta.savedMinimalSchema,
  officeMemberReadState: inquiryReadStateMeta.savedMinimalSchema,
  replies: z.array(inquiryReplyMeta.savedMinimalSchema),
  created: creationMetadataMeta.savedMinimalSchema,
  updated: updateMetadataMeta.savedMinimalSchema,
});
const schema = z.object({
  id: inquiryIdMeta.minimalSchema.optional(),
  institutionId: institutionIdMeta.schema,
  projectId: projectIdMeta.schema,
  documentId: documentIdMeta.schema.nullish().transform((x) => x ?? undefined),
  issuerRole: roleMeta.schema,
  subject: inquirySubjectMeta.schema,
  content: inquiryContentMeta.schema,
  attachmentFiles: z.array(attachmentFileInformationMeta.schema),
  applicantReadState: inquiryReadStateMeta.schema,
  officeMemberReadState: inquiryReadStateMeta.schema,
  replies: z.array(inquiryReplyMeta.schema),
  created: creationMetadataMeta.schema,
  updated: updateMetadataMeta.schema,
});
const savedSchema = z.object({
  id: inquiryIdMeta.minimalSchema,
  institutionId: institutionIdMeta.savedSchema,
  projectId: projectIdMeta.savedSchema,
  documentId: documentIdMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  issuerRole: roleMeta.savedSchema,
  subject: inquirySubjectMeta.savedSchema,
  content: inquiryContentMeta.savedSchema,
  attachmentFiles: z.array(attachmentFileInformationMeta.savedSchema),
  applicantReadState: inquiryReadStateMeta.savedSchema,
  officeMemberReadState: inquiryReadStateMeta.savedSchema,
  replies: z.array(inquiryReplyMeta.savedSchema),
  created: creationMetadataMeta.savedSchema,
  updated: updateMetadataMeta.savedSchema,
});
const jsonSchema = z.object({
  id: inquiryIdMeta.jsonSchema.optional(),
  institutionId: institutionIdMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  projectId: projectIdMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  documentId: documentIdMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  issuerRole: roleMeta.jsonSchema.nullish().transform((x) => x ?? undefined),
  subject: inquirySubjectMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  content: inquiryContentMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  attachmentFiles: z
    .array(attachmentFileInformationMeta.jsonSchema)
    .nullish()
    .transform((x) => x ?? undefined),
  applicantReadState: inquiryReadStateMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  officeMemberReadState: inquiryReadStateMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  replies: z
    .array(inquiryReplyMeta.jsonSchema)
    .nullish()
    .transform((x) => x ?? undefined),
  created: creationMetadataMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  updated: updateMetadataMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
/**
 * 質疑応答(エンティティ)のメタデータ。
 *
 *
 *
 * 以下のカスタムバリデーションを、 inquiryCustomValidation にて行う。
 * - 返信数が500件を超えていないこと。 (エラーメッセージID=mes.質疑応答返信最大メッセージ数超過エラー) */
export const inquiryMeta = new EntityMeta<
  typeof schema,
  typeof savedSchema,
  typeof jsonSchema
>(
  "Inquiry",
  "\u8CEA\u7591\u5FDC\u7B54",
  messageId("lbl.\u8CEA\u7591\u5FDC\u7B54"),
  minimalSchema,
  savedMinimalSchema,
  schema,
  savedSchema,
  jsonSchema,
  undefined,
  inquiryCustomValidation,
  {
    id: new PropertyMeta(
      "id",
      "ID",
      messageId("lbl.ID"),
      "afterSave",
      false,
      inquiryIdMeta
    ),
    institutionId: new PropertyMeta(
      "institutionId",
      "\u6A5F\u95A2ID",
      messageId("lbl.\u6A5F\u95A2ID"),
      "always",
      false,
      institutionIdMeta
    ),
    projectId: new PropertyMeta(
      "projectId",
      "\u6848\u4EF6ID",
      messageId("lbl.\u6848\u4EF6ID"),
      "always",
      false,
      projectIdMeta
    ),
    documentId: new PropertyMeta(
      "documentId",
      "\u66F8\u985EID",
      messageId("lbl.\u66F8\u985EID"),
      "none",
      false,
      documentIdMeta
    ),
    issuerRole: new PropertyMeta(
      "issuerRole",
      "\u8D77\u7968\u8005\u30ED\u30FC\u30EB",
      messageId("lbl.\u8D77\u7968\u8005\u30ED\u30FC\u30EB"),
      "always",
      false,
      roleMeta
    ),
    subject: new PropertyMeta(
      "subject",
      "\u4EF6\u540D",
      messageId("lbl.\u4EF6\u540D"),
      "always",
      false,
      inquirySubjectMeta
    ),
    content: new PropertyMeta(
      "content",
      "\u5185\u5BB9",
      messageId("lbl.\u5185\u5BB9"),
      "always",
      false,
      inquiryContentMeta
    ),
    attachmentFiles: new PropertyMeta(
      "attachmentFiles",
      "\u6DFB\u4ED8\u8CC7\u6599",
      messageId("lbl.\u6DFB\u4ED8\u8CC7\u6599"),
      "always",
      true,
      attachmentFileInformationMeta
    ),
    applicantReadState: new PropertyMeta(
      "applicantReadState",
      "\u7533\u8ACB\u8005\u65E2\u8AAD\u72B6\u6CC1",
      messageId("lbl.\u7533\u8ACB\u8005\u65E2\u8AAD\u72B6\u6CC1"),
      "always",
      false,
      inquiryReadStateMeta
    ),
    officeMemberReadState: new PropertyMeta(
      "officeMemberReadState",
      "\u4E8B\u52D9\u5C40\u65E2\u8AAD\u72B6\u6CC1",
      messageId("lbl.\u4E8B\u52D9\u5C40\u65E2\u8AAD\u72B6\u6CC1"),
      "always",
      false,
      inquiryReadStateMeta
    ),
    replies: new PropertyMeta(
      "replies",
      "\u8FD4\u4FE1",
      messageId("lbl.\u8FD4\u4FE1"),
      "always",
      true,
      inquiryReplyMeta
    ),
    created: new PropertyMeta(
      "created",
      "\u4F5C\u6210",
      messageId("lbl.\u4F5C\u6210"),
      "always",
      false,
      creationMetadataMeta
    ),
    updated: new PropertyMeta(
      "updated",
      "\u66F4\u65B0",
      messageId("lbl.\u66F4\u65B0"),
      "always",
      false,
      updateMetadataMeta
    ),
  }
);
