import {
  messageId,
  PropertyMeta,
  ValueObjectMetaNumber,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.number().min(0);
const savedMinimalSchema = z.number().min(0);
const schema = z.number().min(0);
const savedSchema = z.number().min(0);
const jsonSchema = z.number();
/**
 * 発送細胞本数(値オブジェクト)のメタデータ。
 *
 *  */
export const cellQuantityMeta = new ValueObjectMetaNumber(
  "CellQuantity",
  "\u767A\u9001\u7D30\u80DE\u672C\u6570",
  messageId("lbl.\u767A\u9001\u7D30\u80DE\u672C\u6570"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
