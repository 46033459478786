import { useCallback, useMemo } from "react";
import { hasValue, isNullish } from "../../../../../../lib/util/common-util";
import { ComponentStyleProps } from "../../../../../../lib/styles/props/component-style-props";
import { FrameLeftSideMenu } from "../../../../../ui/frame/FrameLeftSideMenu/FrameLeftSideMenu";
import { CMSelectableListItemWithBadge } from "@pscsrvlab/psc-react-components";
import { useNavigate } from "react-router-dom";
import { jsDateToDatetimeText } from "../../../../../../lib/util/common-date-util";
import { useAppListRevisionRequestQuery } from "../../../../../../hooks/query/use-app-list-revision-request-query";
import { useAppSelector } from "../../../../../../hooks/redux-hooks";
import { selectHasRole } from "../../../../../../store/auth/slice";
import { useAppGetDocumentQuery } from "../../../../../../hooks/query/use-app-get-document-query";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { useProjectPagePathPrefix } from "../../../../../../hooks/use-project-page-path-prefix";
import { SideMenuCreateButton } from "../../../../../ui/button/SideMenuCreateButton/SideMenuCreateButton";

export type RevisionRequestSideMenuProps = {
  documentId: number;
  selectedRevisionRequestId?: number;
} & ComponentStyleProps;

/**
 * 修正依頼一覧を表示する左サイドメニュー
 */
export const RevisionRequestSideMenu = ({
  documentId,
  selectedRevisionRequestId,

  sx,
  ...rest
}: RevisionRequestSideMenuProps) => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const { projectPagePathPrefix } = useProjectPagePathPrefix();
  const { isOfficeMember } = useAppSelector(selectHasRole);

  const { data: document } = useAppGetDocumentQuery({ documentId });

  // 選択肢として表示すべき修正依頼の一覧。
  const { data: revisionRequests } = useAppListRevisionRequestQuery({
    documentId,
  });

  const handleCreate = useCallback(() => {
    navigate(
      `${projectPagePathPrefix}/document/${documentId}/revision-request/create`,
    );
  }, [documentId, navigate, projectPagePathPrefix]);

  // 修正依頼フレームのバッジ色。
  const badgeColorOf = useCallback(
    (state: "revising" | "revised" | undefined) => {
      switch (state) {
        case "revising":
          return "gray";
        case "revised":
          return "blue";
        default:
          return "gray";
      }
    },
    [],
  );

  // 修正依頼フレームのバッジテキスト。
  const badgeTextOf = useCallback(
    (state: "revising" | "revised" | undefined) => {
      if (state === "revising") {
        return t("code.修正依頼状態.修正中");
      }
      if (state === "revised") {
        return t("code.修正依頼状態.修正済");
      }
      return "";
    },
    [t],
  );

  // 修正依頼を新規作成できるかの判定。以下すべてを満たす必要あり。
  // 1. 自身が事務局である。
  // 2. (書類が申請書類であり、かつ状態が事務局確認か条件対応待) または (書類が報告書類であり、かつ状態が結果通知待)
  // 3. この書類の修正依頼が全て「回答済」である。
  const canCreateNewRevisionRequest: boolean = useMemo(() => {
    if (isNullish(revisionRequests) || isNullish(document)) return false;

    // 1. 自身が事務局である。
    if (!isOfficeMember) return false;

    // 2. (書類が申請書類であり、かつ状態が事務局確認か条件対応待) または (書類が報告書類であり、かつ状態が結果通知待)
    const isOkAsApplication =
      ["new_application", "change_application"].includes(
        document.documentType,
      ) &&
      ["office_check", "awaiting_conditions_met"].includes(
        document.documentState,
      );
    const isOkAsReport =
      ["annual_report", "termination_report"].includes(document.documentType) &&
      document.documentState === "awaiting_conclusion";
    if (!isOkAsApplication && !isOkAsReport) return false;

    // 3. この書類の修正依頼が全て「回答済」である。
    return revisionRequests.every((v) => v.revisionRequestState === "revised");
  }, [document, isOfficeMember, revisionRequests]);
  // const canCreateNewRevisionRequest = true; // デバッグ用。

  const handleSelect = useCallback(
    (revisionRequestId: number) => {
      navigate(
        `${projectPagePathPrefix}/document/${documentId}/revision-request/${revisionRequestId}`,
      );
    },
    [navigate, projectPagePathPrefix, documentId],
  );

  return (
    <FrameLeftSideMenu title={t("lbl.修正依頼一覧")} sx={sx} {...rest}>
      {revisionRequests?.map((v) => (
        <CMSelectableListItemWithBadge
          key={v.id}
          label={jsDateToDatetimeText(v.created.datetime)}
          selected={
            hasValue(selectedRevisionRequestId) &&
            v.id === selectedRevisionRequestId
          }
          badgeText={badgeTextOf(v.revisionRequestState)}
          badgeColor={badgeColorOf(v.revisionRequestState)}
          onClick={() => handleSelect(v.id)}
        />
      ))}
      {canCreateNewRevisionRequest && (
        <SideMenuCreateButton
          label={t("btn.修正依頼新規作成ボタン")}
          onClick={handleCreate}
        />
      )}
    </FrameLeftSideMenu>
  );
};
