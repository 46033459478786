import React, { ReactElement } from "react";
import { ComponentStyleProps } from "../../../../lib/styles/props/component-style-props";
import { VerticalTableRow } from "../VerticalTableRow/VerticalTableRow";
import {
  CMFormInputBaseDropDown,
  DropdownOption,
} from "@pscsrvlab/psc-react-components";

export type VerticalTableRowDropDownProps<T extends string = string> = {
  /**
   * ヘッダーとして使用
   */
  label?: string;
  editMode?: "editable" | "disabled" | "readOnly";
  variant?: "default" | "error" | "diff";
  size?: "sm" | "md" | "lg";

  placeholder?: string;

  /**
   * ドロップダウン表示用のデータ
   */
  options: DropdownOption[];

  /**
   * 値。
   */
  value?: T;

  /**
   * 読み取り専用モードのみ使用。表示内容をリンク表示するために使用
   */
  url?: string;

  icon?: ReactElement;

  onChange?: (value: T) => void;
  onBlur?: () => void;
} & ComponentStyleProps;

/**
 * テーブルデータがドロップダウンの縦型テーブルRow
 */
export const VerticalTableRowDropDown = <T extends string = string>({
  label,
  editMode,
  variant,
  size,
  options,
  url,
  icon,
  placeholder,
  value,
  onChange,
  onBlur,

  sx,
  ...rest
}: VerticalTableRowDropDownProps<T>) => {
  return (
    <VerticalTableRow label={label} sx={sx} {...rest}>
      <CMFormInputBaseDropDown<T>
        editMode={editMode}
        variant={variant}
        size={size}
        options={options}
        url={url}
        icon={icon}
        value={value}
        placeholder={placeholder}
        onBlur={onBlur}
        onChange={onChange}
      />
    </VerticalTableRow>
  );
};
