import { ComponentStyleProps } from "../../../../../lib/styles/props/component-style-props";
import { CMButtonSearchConditionText } from "@pscsrvlab/psc-react-components";
import React from "react";
import { useAppTranslation } from "../../../../../hooks/use-app-translation";

export type ButtonSearchConditionTextProps = {
  label: string;
  placeholder?: string;

  /**
   * 最大文字数
   */
  maxLength?: number;

  value?: string | null;

  hideClearButton?: boolean;

  onChange?: (value: string | undefined) => void;
} & ComponentStyleProps;

export const ButtonSearchConditionText = ({
  label,
  placeholder = "",

  maxLength,

  value,

  hideClearButton,

  onChange,

  sx,
  ...rest
}: ButtonSearchConditionTextProps) => {
  const { t } = useAppTranslation();

  const handleChange = (value: string | null) => {
    onChange?.(value ?? undefined);
  };

  const capitalizedLabel = label;

  return (
    <CMButtonSearchConditionText
      label={capitalizedLabel}
      placeholder={placeholder}
      maxLength={maxLength}
      value={value}
      hideClearButton={hideClearButton}
      labelSubmit={t("btn.確定ボタン")}
      labelClear={t("btn.クリアボタン")}
      onChange={handleChange}
      sx={sx}
      {...rest}
    />
  );
};
