import { Divider, useDisclosure, VStack } from "@chakra-ui/react";
import {
  CMFrameCard,
  CMSectionMultipleCardAddable,
  DeleteConfirmationModal,
} from "@pscsrvlab/psc-react-components";
import { SectionContentFrame } from "../../../../document/application/_components/ApplicationDocument/_components/frame/SectionContentFrame/SectionContentFrame";
import { RiDeleteBin5Line } from "react-icons/ri";
import React, { memo, useCallback, useMemo, useState } from "react";
import { ShippingCellCard } from "../ShippingCellCard/ShippingCellCard";
import { ShippingCell } from "../../../../../../lib/object/value/shipping-cell";
import { CellType } from "../../../../../../lib/object/value/cell-type";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { useAppGetProjectQuery } from "../../../../../../hooks/query/use-app-get-project-query";
import { isNullish } from "../../../../../../lib/util/common-util";
import { FormSection } from "../../../../../ui/form/FormSection/FormSection";
import log from "loglevel";

export type SectionShippingCellProps = {
  editMode: "editable" | "readOnly";

  projectId: number;

  valueResearchCells: Partial<ShippingCell>[];
  valueClinicalCells: Partial<ShippingCell>[];

  onChangeResearchCells: (
    change: (before: Partial<ShippingCell>[]) => Partial<ShippingCell>[],
  ) => void;
  onChangeClinicalCells: (
    change: (before: Partial<ShippingCell>[]) => Partial<ShippingCell>[],
  ) => void;

  /**
   * ユーザーの権限情報
   * 事務局ユーザーの場合,true
   */
  isOfficeMember: boolean;
};

export const SectionShippingCell = memo(function SectionShippingCell({
  editMode,
  projectId,
  valueResearchCells,
  valueClinicalCells,

  onChangeResearchCells,
  onChangeClinicalCells,

  isOfficeMember,
}: SectionShippingCellProps) {
  const { t } = useAppTranslation();

  const { data: project } = useAppGetProjectQuery({
    projectId,
  });

  /**
   * 案件の「使用する細胞」から細胞情報を取得
   */
  const requestingCells = useMemo(() => {
    if (isNullish(project)) {
      return [];
    }

    log.debug(
      `project.applicationContent.requestingClinicalCells=`,
      project.applicationContent.requestingClinicalCells,
    );

    return [
      ...project.applicationContent.requestingResearchCells,
      ...project.applicationContent.requestingClinicalCells,
    ];
  }, [project]);

  const getEmptyCell: (cellType: CellType) => Partial<ShippingCell> =
    useCallback((cellType) => {
      return {
        cellStockId: undefined,
        cellStockCategoryId: undefined,
        cellNameJa: "",
        cellNameEn: "",
        cellType: cellType,
        cellNumber: undefined,
        cellCategoryNameJa: "",
        cellCategoryNameEn: "",
        cellCategoryNumber: undefined,
        cellProvisionType: "from_foundation",
        stockProviderOrganizationName: undefined,
        stockProviderProjectControlNumber: undefined,
      };
    }, []);
  const handleAdd = useCallback(
    (cellType: CellType) => {
      switch (cellType) {
        case "research_purpose":
          onChangeResearchCells?.((before) => [
            ...before,
            getEmptyCell("research_purpose"),
          ]);
          break;
        case "clinical_purpose":
          onChangeClinicalCells?.((before) => [
            ...before,
            getEmptyCell("clinical_purpose"),
          ]);
          break;
      }
    },
    [getEmptyCell, onChangeClinicalCells, onChangeResearchCells],
  );

  const handleChange = useCallback(
    (
      cellType: CellType,
      changeIndex: number,
      change: (before: Partial<ShippingCell>) => Partial<ShippingCell>,
    ) => {
      switch (cellType) {
        case "research_purpose": {
          onChangeResearchCells?.((before) =>
            before.map((cell, cellsIndex) => {
              if (cellsIndex === changeIndex) {
                return change(cell);
              } else {
                return cell;
              }
            }),
          );
          break;
        }
        case "clinical_purpose": {
          onChangeClinicalCells?.((before) =>
            before.map((cell, cellsIndex) => {
              if (cellsIndex === changeIndex) {
                return change(cell);
              } else {
                return cell;
              }
            }),
          );
          break;
        }
      }
    },
    [onChangeClinicalCells, onChangeResearchCells],
  );

  const {
    isOpen: isOpenDeleteResearchCellModal,
    onOpen: onOpenDeleteResearchCellModal,
    onClose: onCloseDeleteResearchCellModal,
  } = useDisclosure();

  const {
    isOpen: isOpenDeleteClinicalCellModal,
    onOpen: onOpenDeleteClinicalCellModal,
    onClose: onCloseDeleteClinicalCellModal,
  } = useDisclosure();

  /**
   * カード削除対象のカードインデックス
   */
  const [researchCellDeletingIndex, setResearchCellDeletingIndex] = useState(0);
  const [clinicalCellDeletingIndex, setClinicalCellDeletingIndex] = useState(0);

  /**
   * 臨床株カードの削除ボタン押下時の処理。
   */
  const handleResearchCellDeleteButtonClick = useCallback(
    (index: number) => {
      setResearchCellDeletingIndex(index);
      onOpenDeleteResearchCellModal();
    },
    [onOpenDeleteResearchCellModal],
  );

  /**
   * 臨床株カードの削除ボタン押下時の処理。
   */
  const handleClinicalCellDeleteButtonClick = useCallback(
    (index: number) => {
      setClinicalCellDeletingIndex(index);
      onOpenDeleteClinicalCellModal();
    },
    [onOpenDeleteClinicalCellModal],
  );

  /**
   * モーダルの削除ボタン押下により、研究株カードが削除される。
   */
  const handleResearchCellConfirmDelete = useCallback(() => {
    onChangeResearchCells?.((before) =>
      before.filter((cell, index) => index !== researchCellDeletingIndex),
    );
    onCloseDeleteResearchCellModal();
  }, [
    onChangeResearchCells,
    onCloseDeleteResearchCellModal,
    researchCellDeletingIndex,
  ]);

  /**
   * モーダルの削除ボタン押下により、臨床株カードが削除される。
   */
  const handleClinicalCellConfirmDelete = useCallback(() => {
    onChangeClinicalCells?.((before) =>
      before.filter((cell, index) => index !== clinicalCellDeletingIndex),
    );
    onCloseDeleteClinicalCellModal();
  }, [
    clinicalCellDeletingIndex,
    onChangeClinicalCells,
    onCloseDeleteClinicalCellModal,
  ]);

  return (
    <>
      <FormSection title={t("lbl.発送する細胞")}>
        <CMSectionMultipleCardAddable
          editMode={isOfficeMember ? editMode : "readOnly"}
          onAddSection={() => handleAdd("research_purpose")}
          buttonLabel={t("btn.研究用株を追加ボタン")}
          mb={"10px"}
        >
          <VStack spacing={"10px"} alignItems={"stretch"}>
            {valueResearchCells.map((v, index) => (
              <CMFrameCard
                key={index}
                backgroundColor={"gray.200"}
                title={t("lbl.研究用株") + (index + 1).toString(10)}
                deleteButtonIcon={
                  editMode === "editable" &&
                  isOfficeMember && <RiDeleteBin5Line />
                }
                onDelete={() => handleResearchCellDeleteButtonClick(index)}
              >
                <SectionContentFrame>
                  <ShippingCellCard
                    cellType={"research_purpose"}
                    value={v}
                    editMode={editMode}
                    requestingCells={requestingCells}
                    isOfficeMember={isOfficeMember}
                    onChange={(cellType, change) =>
                      handleChange(cellType, index, change)
                    }
                  />
                </SectionContentFrame>
              </CMFrameCard>
            ))}
          </VStack>
        </CMSectionMultipleCardAddable>
        <Divider borderColor={"gray.400"} mb={"10px"} />
        <CMSectionMultipleCardAddable
          editMode={isOfficeMember ? editMode : "readOnly"}
          onAddSection={() => handleAdd("clinical_purpose")}
          buttonLabel={t("btn.臨床用株を追加ボタン")}
        >
          <VStack spacing={"10px"} alignItems={"stretch"}>
            {valueClinicalCells.map((v, index) => (
              <CMFrameCard
                key={index}
                backgroundColor={"gray.200"}
                title={t("lbl.臨床用株") + (index + 1).toString(10)}
                deleteButtonIcon={
                  editMode === "editable" &&
                  isOfficeMember && <RiDeleteBin5Line />
                }
                onDelete={() => handleClinicalCellDeleteButtonClick(index)}
              >
                <SectionContentFrame>
                  <ShippingCellCard
                    cellType={"clinical_purpose"}
                    value={v}
                    editMode={editMode}
                    requestingCells={requestingCells}
                    isOfficeMember={isOfficeMember}
                    onChange={(cellType, change) =>
                      handleChange(cellType, index, change)
                    }
                  />
                </SectionContentFrame>
              </CMFrameCard>
            ))}
          </VStack>
        </CMSectionMultipleCardAddable>
      </FormSection>

      {/*研究用株カード削除時に出現するモーダル*/}
      <DeleteConfirmationModal
        isOpen={isOpenDeleteResearchCellModal}
        onClose={onCloseDeleteResearchCellModal}
        title={t("lbl.確認ポップアップタイトル")}
        message={t("mes.細胞削除確認メッセージ")}
        deleteButtonLabel={t("btn.削除ボタン")}
        cancelButtonLabel={t("btn.キャンセルボタン")}
        onConfirm={handleResearchCellConfirmDelete}
        onCancel={undefined}
      />
      {/*臨床用株カード削除時に出現するモーダル*/}
      <DeleteConfirmationModal
        isOpen={isOpenDeleteClinicalCellModal}
        onClose={onCloseDeleteClinicalCellModal}
        title={t("lbl.確認ポップアップタイトル")}
        message={t("mes.細胞削除確認メッセージ")}
        deleteButtonLabel={t("btn.削除ボタン")}
        cancelButtonLabel={t("btn.キャンセルボタン")}
        onConfirm={handleClinicalCellConfirmDelete}
        onCancel={undefined}
      />
    </>
  );
});
