import {
  messageId,
  PropertyMeta,
  ValueObjectMetaComposite,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { cellStockIdMeta } from "../value/cell-stock-id";
import { cellTypeMeta } from "../value/cell-type";
import { cellStockCategoryIdMeta } from "../value/cell-stock-category-id";
import { cellCategoryNumberMeta } from "../value/cell-category-number";
import { cellCategoryNameJaMeta } from "../value/cell-category-name-ja";
import { cellCategoryNameEnMeta } from "../value/cell-category-name-en";
import { cellNumberMeta } from "../value/cell-number";
import { cellNameJaMeta } from "../value/cell-name-ja";
import { cellNameEnMeta } from "../value/cell-name-en";
import { cellQuantityMeta } from "../value/cell-quantity";
/**
 * 発送細胞情報(値オブジェクト)
 *
 *  */
export type ShippingCell = z.infer<typeof schema>;
export type ShippingCellSaved = z.infer<typeof savedSchema>;
const minimalSchema = z.object({
  cellStockId: cellStockIdMeta.minimalSchema,
  cellType: cellTypeMeta.minimalSchema,
  cellStockCategoryId: cellStockCategoryIdMeta.minimalSchema,
  cellCategoryNumber: cellCategoryNumberMeta.minimalSchema,
  cellCategoryNameJa: cellCategoryNameJaMeta.minimalSchema,
  cellCategoryNameEn: cellCategoryNameEnMeta.minimalSchema,
  cellNumber: cellNumberMeta.minimalSchema,
  cellNameJa: cellNameJaMeta.minimalSchema,
  cellNameEn: cellNameEnMeta.minimalSchema,
  quantity: cellQuantityMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
const savedMinimalSchema = z.object({
  cellStockId: cellStockIdMeta.savedMinimalSchema,
  cellType: cellTypeMeta.savedMinimalSchema,
  cellStockCategoryId: cellStockCategoryIdMeta.savedMinimalSchema,
  cellCategoryNumber: cellCategoryNumberMeta.savedMinimalSchema,
  cellCategoryNameJa: cellCategoryNameJaMeta.savedMinimalSchema,
  cellCategoryNameEn: cellCategoryNameEnMeta.savedMinimalSchema,
  cellNumber: cellNumberMeta.savedMinimalSchema,
  cellNameJa: cellNameJaMeta.savedMinimalSchema,
  cellNameEn: cellNameEnMeta.savedMinimalSchema,
  quantity: cellQuantityMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
const schema = z.object({
  cellStockId: cellStockIdMeta.schema,
  cellType: cellTypeMeta.schema,
  cellStockCategoryId: cellStockCategoryIdMeta.schema,
  cellCategoryNumber: cellCategoryNumberMeta.schema,
  cellCategoryNameJa: cellCategoryNameJaMeta.schema,
  cellCategoryNameEn: cellCategoryNameEnMeta.schema,
  cellNumber: cellNumberMeta.schema,
  cellNameJa: cellNameJaMeta.schema,
  cellNameEn: cellNameEnMeta.schema,
  quantity: cellQuantityMeta.schema.nullish().transform((x) => x ?? undefined),
});
const savedSchema = z.object({
  cellStockId: cellStockIdMeta.savedSchema,
  cellType: cellTypeMeta.savedSchema,
  cellStockCategoryId: cellStockCategoryIdMeta.savedSchema,
  cellCategoryNumber: cellCategoryNumberMeta.savedSchema,
  cellCategoryNameJa: cellCategoryNameJaMeta.savedSchema,
  cellCategoryNameEn: cellCategoryNameEnMeta.savedSchema,
  cellNumber: cellNumberMeta.savedSchema,
  cellNameJa: cellNameJaMeta.savedSchema,
  cellNameEn: cellNameEnMeta.savedSchema,
  quantity: cellQuantityMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
const jsonSchema = z.object({
  cellStockId: cellStockIdMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  cellType: cellTypeMeta.jsonSchema.nullish().transform((x) => x ?? undefined),
  cellStockCategoryId: cellStockCategoryIdMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  cellCategoryNumber: cellCategoryNumberMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  cellCategoryNameJa: cellCategoryNameJaMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  cellCategoryNameEn: cellCategoryNameEnMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  cellNumber: cellNumberMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  cellNameJa: cellNameJaMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  cellNameEn: cellNameEnMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  quantity: cellQuantityMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
/**
 * 発送細胞情報(値オブジェクト)のメタデータ。
 *
 *  */
export const shippingCellMeta = new ValueObjectMetaComposite(
  "ShippingCell",
  "\u767A\u9001\u7D30\u80DE\u60C5\u5831",
  messageId("lbl.\u767A\u9001\u7D30\u80DE\u60C5\u5831"),
  minimalSchema,
  savedMinimalSchema,
  schema,
  savedSchema,
  jsonSchema,
  undefined,
  undefined,
  {
    cellStockId: new PropertyMeta(
      "cellStockId",
      "\u7D30\u80DEID",
      messageId("lbl.\u7D30\u80DEID"),
      "always",
      false,
      cellStockIdMeta
    ),
    cellType: new PropertyMeta(
      "cellType",
      "\u7D30\u80DE\u533A\u5206",
      messageId("lbl.\u7D30\u80DE\u533A\u5206"),
      "always",
      false,
      cellTypeMeta
    ),
    cellStockCategoryId: new PropertyMeta(
      "cellStockCategoryId",
      "\u7D30\u80DE\u30AB\u30C6\u30B4\u30EA\u756A\u53F7",
      messageId("lbl.\u7D30\u80DE\u30AB\u30C6\u30B4\u30EA\u756A\u53F7"),
      "always",
      false,
      cellStockCategoryIdMeta
    ),
    cellCategoryNumber: new PropertyMeta(
      "cellCategoryNumber",
      "\u7D30\u80DE\u30AB\u30C6\u30B4\u30EA\u756A\u53F7",
      messageId("lbl.\u7D30\u80DE\u30AB\u30C6\u30B4\u30EA\u756A\u53F7"),
      "always",
      false,
      cellCategoryNumberMeta
    ),
    cellCategoryNameJa: new PropertyMeta(
      "cellCategoryNameJa",
      "\u7D30\u80DE\u30AB\u30C6\u30B4\u30EA\u540D\uFF08\u65E5\uFF09",
      messageId(
        "lbl.\u7D30\u80DE\u30AB\u30C6\u30B4\u30EA\u540D\uFF08\u65E5\uFF09"
      ),
      "always",
      false,
      cellCategoryNameJaMeta
    ),
    cellCategoryNameEn: new PropertyMeta(
      "cellCategoryNameEn",
      "\u7D30\u80DE\u30AB\u30C6\u30B4\u30EA\u540D\uFF08\u82F1\uFF09",
      messageId(
        "lbl.\u7D30\u80DE\u30AB\u30C6\u30B4\u30EA\u540D\uFF08\u82F1\uFF09"
      ),
      "always",
      false,
      cellCategoryNameEnMeta
    ),
    cellNumber: new PropertyMeta(
      "cellNumber",
      "\u7D30\u80DE\u756A\u53F7",
      messageId("lbl.\u7D30\u80DE\u756A\u53F7"),
      "always",
      false,
      cellNumberMeta
    ),
    cellNameJa: new PropertyMeta(
      "cellNameJa",
      "\u7D30\u80DE\u540D\uFF08\u65E5\uFF09",
      messageId("lbl.\u7D30\u80DE\u540D\uFF08\u65E5\uFF09"),
      "always",
      false,
      cellNameJaMeta
    ),
    cellNameEn: new PropertyMeta(
      "cellNameEn",
      "\u7D30\u80DE\u540D\uFF08\u82F1\uFF09",
      messageId("lbl.\u7D30\u80DE\u540D\uFF08\u82F1\uFF09"),
      "always",
      false,
      cellNameEnMeta
    ),
    quantity: new PropertyMeta(
      "quantity",
      "\u767A\u9001\u7D30\u80DE\u672C\u6570",
      messageId("lbl.\u767A\u9001\u7D30\u80DE\u672C\u6570"),
      "none",
      false,
      cellQuantityMeta
    ),
  }
);
