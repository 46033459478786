import {
  messageId,
  PropertyMeta,
  ValueObjectMetaComposite,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { terminationReportContentCustomValidation } from "../custom-validation/termination-report-content";
import { projectNameMeta } from "../value/project-name";
import { institutionInformationMeta } from "../value/institution-information";
import { userInformationMeta } from "../value/user-information";
import { yearMonthDayMeta } from "../value/year-month-day";
import { attachmentFileInformationMeta } from "../value/attachment-file-information";
import { collaborativePartnerOnReportMeta } from "../value/collaborative-partner-on-report";
import { differentiatedCellProvisionTypeMeta } from "../value/differentiated-cell-provision-type";
import { differentiatedCellProvisionMeta } from "../value/differentiated-cell-provision";
import { problemsDuringThePeriodMeta } from "../value/problems-during-the-period";
import { handlingAfterTerminationMeta } from "../value/handling-after-termination";
import { researchResultOutlineMeta } from "../value/research-result-outline";
/**
 * 使用終了報告内容(値オブジェクト)
 *
 *  */
export type TerminationReportContent = z.infer<typeof schema>;
export type TerminationReportContentSaved = z.infer<typeof savedSchema>;
const minimalSchema = z.object({
  projectName: projectNameMeta.minimalSchema,
  institution: institutionInformationMeta.minimalSchema,
  principalInvestigator: userInformationMeta.minimalSchema,
  newApplicationApprovalDate: yearMonthDayMeta.minimalSchema,
  usageEndDate: yearMonthDayMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  attachmentFiles: z.array(attachmentFileInformationMeta.minimalSchema),
  partner: z.array(collaborativePartnerOnReportMeta.minimalSchema),
  cellProvisionType: differentiatedCellProvisionTypeMeta.minimalSchema,
  cellProvisions: z.array(differentiatedCellProvisionMeta.minimalSchema),
  problemsDuringThePeriod: problemsDuringThePeriodMeta.minimalSchema,
  handlingAfterTermination: handlingAfterTerminationMeta.minimalSchema,
  researchResultOutline: researchResultOutlineMeta.minimalSchema,
});
const savedMinimalSchema = z.object({
  projectName: projectNameMeta.savedMinimalSchema,
  institution: institutionInformationMeta.savedMinimalSchema,
  principalInvestigator: userInformationMeta.savedMinimalSchema,
  newApplicationApprovalDate: yearMonthDayMeta.savedMinimalSchema,
  usageEndDate: yearMonthDayMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  attachmentFiles: z.array(attachmentFileInformationMeta.savedMinimalSchema),
  partner: z.array(collaborativePartnerOnReportMeta.savedMinimalSchema),
  cellProvisionType: differentiatedCellProvisionTypeMeta.savedMinimalSchema,
  cellProvisions: z.array(differentiatedCellProvisionMeta.savedMinimalSchema),
  problemsDuringThePeriod: problemsDuringThePeriodMeta.savedMinimalSchema,
  handlingAfterTermination: handlingAfterTerminationMeta.savedMinimalSchema,
  researchResultOutline: researchResultOutlineMeta.savedMinimalSchema,
});
const schema = z.object({
  projectName: projectNameMeta.schema,
  institution: institutionInformationMeta.schema,
  principalInvestigator: userInformationMeta.schema,
  newApplicationApprovalDate: yearMonthDayMeta.schema,
  usageEndDate: yearMonthDayMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
  attachmentFiles: z.array(attachmentFileInformationMeta.schema),
  partner: z.array(collaborativePartnerOnReportMeta.schema),
  cellProvisionType: differentiatedCellProvisionTypeMeta.schema,
  cellProvisions: z.array(differentiatedCellProvisionMeta.schema),
  problemsDuringThePeriod: problemsDuringThePeriodMeta.schema,
  handlingAfterTermination: handlingAfterTerminationMeta.schema,
  researchResultOutline: researchResultOutlineMeta.schema,
});
const savedSchema = z.object({
  projectName: projectNameMeta.savedSchema,
  institution: institutionInformationMeta.savedSchema,
  principalInvestigator: userInformationMeta.savedSchema,
  newApplicationApprovalDate: yearMonthDayMeta.savedSchema,
  usageEndDate: yearMonthDayMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  attachmentFiles: z.array(attachmentFileInformationMeta.savedSchema),
  partner: z.array(collaborativePartnerOnReportMeta.savedSchema),
  cellProvisionType: differentiatedCellProvisionTypeMeta.savedSchema,
  cellProvisions: z.array(differentiatedCellProvisionMeta.savedSchema),
  problemsDuringThePeriod: problemsDuringThePeriodMeta.savedSchema,
  handlingAfterTermination: handlingAfterTerminationMeta.savedSchema,
  researchResultOutline: researchResultOutlineMeta.savedSchema,
});
const jsonSchema = z.object({
  projectName: projectNameMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  institution: institutionInformationMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  principalInvestigator: userInformationMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  newApplicationApprovalDate: yearMonthDayMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  usageEndDate: yearMonthDayMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  attachmentFiles: z
    .array(attachmentFileInformationMeta.jsonSchema)
    .nullish()
    .transform((x) => x ?? undefined),
  partner: z
    .array(collaborativePartnerOnReportMeta.jsonSchema)
    .nullish()
    .transform((x) => x ?? undefined),
  cellProvisionType: differentiatedCellProvisionTypeMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  cellProvisions: z
    .array(differentiatedCellProvisionMeta.jsonSchema)
    .nullish()
    .transform((x) => x ?? undefined),
  problemsDuringThePeriod: problemsDuringThePeriodMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  handlingAfterTermination: handlingAfterTerminationMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  researchResultOutline: researchResultOutlineMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
/**
 * 使用終了報告内容(値オブジェクト)のメタデータ。
 *
 *
 *
 * 以下のカスタムバリデーションを、 terminationReportContentCustomValidation にて行う。
 * - 分化細胞の提供先有無が有の場合は分化細胞の提供先を必須とする。 (エラーメッセージID=mes.分化細胞必須エラー)
 * - (任意) 使用終了日を必須とする (エラーメッセージID=mes.必須チェックエラー2) */
export const terminationReportContentMeta = new ValueObjectMetaComposite(
  "TerminationReportContent",
  "\u4F7F\u7528\u7D42\u4E86\u5831\u544A\u5185\u5BB9",
  messageId("lbl.\u4F7F\u7528\u7D42\u4E86\u5831\u544A\u5185\u5BB9"),
  minimalSchema,
  savedMinimalSchema,
  schema,
  savedSchema,
  jsonSchema,
  undefined,
  terminationReportContentCustomValidation,
  {
    projectName: new PropertyMeta(
      "projectName",
      "\u8AB2\u984C\u540D",
      messageId("lbl.\u8AB2\u984C\u540D"),
      "always",
      false,
      projectNameMeta
    ),
    institution: new PropertyMeta(
      "institution",
      "\u6A5F\u95A2",
      messageId("lbl.\u6A5F\u95A2"),
      "always",
      false,
      institutionInformationMeta
    ),
    principalInvestigator: new PropertyMeta(
      "principalInvestigator",
      "\u7814\u7A76\u8CAC\u4EFB\u8005",
      messageId("lbl.\u7814\u7A76\u8CAC\u4EFB\u8005"),
      "always",
      false,
      userInformationMeta
    ),
    newApplicationApprovalDate: new PropertyMeta(
      "newApplicationApprovalDate",
      "\u65B0\u898F\u7533\u8ACB\u627F\u8A8D\u65E5",
      messageId("lbl.\u65B0\u898F\u7533\u8ACB\u627F\u8A8D\u65E5"),
      "always",
      false,
      yearMonthDayMeta
    ),
    usageEndDate: new PropertyMeta(
      "usageEndDate",
      "\u4F7F\u7528\u7D42\u4E86\u65E5",
      messageId("lbl.\u4F7F\u7528\u7D42\u4E86\u65E5"),
      "none",
      false,
      yearMonthDayMeta
    ),
    attachmentFiles: new PropertyMeta(
      "attachmentFiles",
      "\u6DFB\u4ED8\u8CC7\u6599",
      messageId("lbl.\u6DFB\u4ED8\u8CC7\u6599"),
      "always",
      true,
      attachmentFileInformationMeta
    ),
    partner: new PropertyMeta(
      "partner",
      "\u5206\u62C5\u6A5F\u95A2",
      messageId("lbl.\u5206\u62C5\u6A5F\u95A2"),
      "always",
      true,
      collaborativePartnerOnReportMeta
    ),
    cellProvisionType: new PropertyMeta(
      "cellProvisionType",
      "\u5206\u5316\u7D30\u80DE\u63D0\u4F9B\u5148\u6709\u7121",
      messageId("lbl.\u5206\u5316\u7D30\u80DE\u63D0\u4F9B\u5148\u6709\u7121"),
      "always",
      false,
      differentiatedCellProvisionTypeMeta
    ),
    cellProvisions: new PropertyMeta(
      "cellProvisions",
      "\u5206\u5316\u7D30\u80DE\u63D0\u4F9B\u5148",
      messageId("lbl.\u5206\u5316\u7D30\u80DE\u63D0\u4F9B\u5148"),
      "always",
      true,
      differentiatedCellProvisionMeta
    ),
    problemsDuringThePeriod: new PropertyMeta(
      "problemsDuringThePeriod",
      "\u671F\u9593\u4E2D\u306E\u554F\u984C\u70B9",
      messageId("lbl.\u671F\u9593\u4E2D\u306E\u554F\u984C\u70B9"),
      "always",
      false,
      problemsDuringThePeriodMeta
    ),
    handlingAfterTermination: new PropertyMeta(
      "handlingAfterTermination",
      "\u4F7F\u7528\u7D42\u4E86\u5F8C\u306E\u53D6\u308A\u6271\u3044\uFF08\u4F7F\u7528\u7D42\u4E86\u5831\u544A\uFF09",
      messageId(
        "lbl.\u4F7F\u7528\u7D42\u4E86\u5F8C\u306E\u53D6\u308A\u6271\u3044\uFF08\u4F7F\u7528\u7D42\u4E86\u5831\u544A\uFF09"
      ),
      "always",
      false,
      handlingAfterTerminationMeta
    ),
    researchResultOutline: new PropertyMeta(
      "researchResultOutline",
      "\u7814\u7A76\u7D50\u679C\u6982\u8981",
      messageId("lbl.\u7814\u7A76\u7D50\u679C\u6982\u8981"),
      "always",
      false,
      researchResultOutlineMeta
    ),
  }
);
