import { Container, Flex, HStack, Stack, VStack } from "@chakra-ui/react";
import {
  reviewMeta,
  ReviewSaved,
} from "../../../../../../lib/object/entity/review";
import { CMTabSmallList } from "@pscsrvlab/psc-react-components";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { FrameUpperRightButton } from "../../../../../ui/frame/FrameUpperRightButton/FrameUpperRightButton";
import log from "loglevel";
import {
  CloseResult,
  PromiseModalOptionsCancel,
  PromiseModalOptionsCancelProps,
} from "../../../../../ui/modal/PromiseModalOptionsCancel/PromiseModalOptionsCancel";
import { useAppSelector } from "../../../../../../hooks/redux-hooks";
import { selectHasRole } from "../../../../../../store/auth/slice";
import useCustomToast from "../../../../../../hooks/use-custom-toast";
import {
  hasValue,
  isNullish,
  sleep,
} from "../../../../../../lib/util/common-util";
import { getDateTimeStr } from "../../../../../../lib/util/common-date-util";
import { ReviewDetailsContentTab } from "../ReviewDetailsContentTab/ReviewDetailsContentTab";
import { errorMessageOf } from "../../../../../../lib/util/error-util";
import {
  useApproveReviewMutation,
  useConcludeReviewMutation,
  useUpdateReviewMutation,
} from "../../../../../../store/api/generated/stock-request-api";
import { ReviewDetailsDocumentContentTab } from "../DocumentContentTab/ReviewDetailsDocumentContentTab";
import { useCreateJsonPatchOperations } from "../../../../../../hooks/use-create-json-patch-operations";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { useLeaveEditingPrompt } from "../../../../../../hooks/use-leave-editing-prompt";
import { useProjectPagePathPrefix } from "../../../../../../hooks/use-project-page-path-prefix";
import { useAppGetDocumentQuery } from "../../../../../../hooks/query/use-app-get-document-query";
import { EditButton } from "../../../../../ui/button/EditButton/EditButton";
import { SaveChangesButton } from "../../../../../ui/button/SaveChangesButton/SaveChangesButton";
import { EditEndButton } from "../../../../../ui/button/EditEndButton/EditEndButton";
import { WeakTextItem } from "../../../../../ui/text/WeakTextItem/WeakTextItem";
import { TitleText } from "../../../../../ui/text/TitleText/TitleText";

export type ReviewDetailsProps = {
  review: ReviewSaved;
  reviewDetailDataLabel?: string;
};

/**
 * FN40-S30審査
 * メインエリアを表示、タブで表示内容を切り替える
 */
export const ReviewDetails = ({
  review,
  reviewDetailDataLabel,
}: ReviewDetailsProps) => {
  const { t } = useAppTranslation();
  const { errorToast, validationErrorToast } = useCustomToast();
  const { isOfficeMember } = useAppSelector(selectHasRole);
  const { projectPagePathPrefix } = useProjectPagePathPrefix();

  const reviewId = useMemo(() => review.id, [review.id]);
  const documentId = useMemo(() => review.documentId, [review.documentId]);

  // 画面モード
  const [editMode, setEditMode] = useState<"editable" | "readOnly">("readOnly");

  const { navigateWithoutPrompt } = useLeaveEditingPrompt({
    skip: editMode === "readOnly",
  });

  // 書類情報を取得
  const { data: document } = useAppGetDocumentQuery(
    {
      documentId: documentId ?? -1,
    },
    { skip: isNullish(documentId) },
  );

  // 書類状態
  const documentState = useMemo(() => document?.documentState, [document]);

  const [initialContent, setInitialContent] = useState<ReviewSaved>(review);
  const [editContent, setEditContent] = useState<ReviewSaved>(review);

  // JSONパッチの作成
  const { createJsonPatchOperations } =
    useCreateJsonPatchOperations(reviewMeta);

  // 審査情報の更新
  const [updateReview] = useUpdateReviewMutation();

  // 審査結果確定
  const [concludeReview] = useConcludeReviewMutation();

  // 審査結果承認
  const [approveReview] = useApproveReviewMutation();

  const tabNameList = useMemo(() => {
    return [t("tab.審査内容タブ"), t("tab.書類内容タブ")];
  }, [t]);

  /**
   * 作成日時
   */
  const createdDate = useMemo(() => {
    if (
      hasValue(review) &&
      hasValue(review.created) &&
      hasValue(review.created.datetime)
    ) {
      return getDateTimeStr(review.created.datetime);
    }

    return "-";
  }, [review]);

  /**
   * 更新日時
   */
  const updatedDate = useMemo(() => {
    if (
      hasValue(review) &&
      hasValue(review.updated) &&
      hasValue(review.updated.datetime)
    ) {
      return getDateTimeStr(review.updated.datetime);
    }

    return "-";
  }, [review]);

  /**
   * 編集可能な場合はtrue。
   * 以下を満たすとき、編集可能である。
   * - ログインユーザーが事務局。
   * - 審査が事務局審査。
   * - 審査が未実施。
   * - 書類が審査状態（念のため）。
   */
  const canEdit = useMemo(() => {
    return (
      isOfficeMember &&
      review.reviewType === "office_review" &&
      review.reviewState === "unheld" &&
      documentState === "reviewing"
    );
  }, [isOfficeMember, review.reviewType, review.reviewState, documentState]);

  /**
   * 編集ボタンイベント
   */
  const handleEdit = useCallback(() => {
    // 参照モードに切り替え
    setEditMode("editable");
  }, []);

  // 編集モード終了確認モーダルの表示制御設定
  const [editCancelModalConfig, setEditCancelModalConfig] =
    useState<PromiseModalOptionsCancelProps>();

  /**
   * 編集終了ボタンイベント
   */
  const handleEditCancel = useCallback(async () => {
    // 確認モーダルの表示
    const result = await new Promise<CloseResult>((resolve) => {
      setEditCancelModalConfig({
        onCloseModal: resolve,
      });
    });

    // モーダルを非表示にする
    setEditCancelModalConfig(undefined);
    if (result !== "OK") return;

    // 表示内容を初期化
    setEditContent(initialContent);

    // 参照モードに切り替え
    setEditMode("readOnly");
  }, [initialContent]);

  // 保存確認モーダルの表示制御設定
  const [saveModalConfig, setSaveModalConfig] =
    useState<PromiseModalOptionsCancelProps>();

  /**
   * 変更を保存するボタンイベント
   */
  const handleSave = useCallback(async () => {
    // バリデーションチェック
    const validationState = reviewMeta.validate(editContent);
    if (validationState.state === "error") {
      validationErrorToast(validationState.errors);
      return;
    }

    // 確認モーダルの表示
    const result = await new Promise<CloseResult>((resolve) => {
      setSaveModalConfig({
        onCloseModal: resolve,
      });
    });

    // モーダルを非表示にする
    setSaveModalConfig(undefined);
    if (result !== "OK") return;

    // JSONパッチを作成
    const jsonPatchOperations = createJsonPatchOperations(
      initialContent,
      editContent,
    );

    try {
      // 保存処理
      await updateReview({
        reviewId: editContent.id ?? -1,
        commonUpdateRequest: {
          patchOperations: jsonPatchOperations,
        },
      }).unwrap();

      // 初期内容を更新
      setInitialContent(editContent);

      // 参照モードに切り替え
      setEditMode("readOnly");
    } catch (e) {
      errorToast(t("mes.審査内容更新失敗エラー"));
      log.error(errorMessageOf(e));
    }
  }, [
    createJsonPatchOperations,
    editContent,
    errorToast,
    initialContent,
    t,
    updateReview,
    validationErrorToast,
  ]);

  // 審査結果確定の確認モーダルの表示制御設定
  const [concludeReviewModalConfig, setConcludeReviewModalConfig] =
    useState<PromiseModalOptionsCancelProps>();

  /**
   * 審査結果確定ボタンイベント
   */
  const handleConcludeReview = useCallback(async () => {
    // バリデーションチェック
    const validationState = reviewMeta.validate(editContent);
    if (validationState.state === "error") {
      validationErrorToast(validationState.errors);
      return;
    }
    const validatedReview = validationState.value;

    if (isNullish(validatedReview.reviewDate)) {
      errorToast(t("mes.必須チェックエラー2", { name: t("lbl.審査実施日") }));
      return;
    }
    if (isNullish(validatedReview.reviewResult)) {
      errorToast(t("mes.必須チェックエラー2", { name: t("lbl.審査結果") }));
      return;
    }
    if (
      validatedReview.reviewResult !== "approved" &&
      validatedReview.unapprovalReason.length <= 0
    ) {
      errorToast(t("mes.承認以外の場合の理由必須エラーメッセージ"));
      return;
    }

    // 確認モーダルの表示
    const result = await new Promise<CloseResult>((resolve) => {
      setConcludeReviewModalConfig({
        onCloseModal: resolve,
      });
    });

    // モーダルを非表示にする
    setConcludeReviewModalConfig(undefined);
    if (result !== "OK") return;

    // JSONパッチを作成
    const jsonPatchOperations = createJsonPatchOperations(
      initialContent,
      validatedReview,
    );

    try {
      // 保存処理
      await updateReview({
        reviewId: validatedReview.id ?? -1,
        commonUpdateRequest: {
          patchOperations: jsonPatchOperations,
        },
      }).unwrap();

      // 審査結果確定処理
      await concludeReview({
        reviewId: reviewId,
      }).unwrap();

      // 参照モードに切り替え
      setEditMode("readOnly");

      // 「FN40-S31（事務局審査結果確定完了）」画面に遷移
      await navigateWithoutPrompt(
        `${projectPagePathPrefix}/document/${documentId}/review/${reviewId}/concluded`,
      );
    } catch (e) {
      errorToast(t("mes.審査結果確定失敗エラー"));
      log.error(errorMessageOf(e));
    }
  }, [
    concludeReview,
    createJsonPatchOperations,
    documentId,
    editContent,
    errorToast,
    initialContent,
    navigateWithoutPrompt,
    projectPagePathPrefix,
    reviewId,
    t,
    updateReview,
    validationErrorToast,
  ]);

  // 審査結果確定の確認モーダルの表示制御設定
  const [approvalReviewModalConfig, setApprovalReviewModalConfig] =
    useState<PromiseModalOptionsCancelProps>();

  /**
   * 審査結果承認ボタンイベント
   */
  const handleApproveReview = async () => {
    // 確認モーダルの表示
    const result = await new Promise<CloseResult>((resolve) => {
      setApprovalReviewModalConfig({
        onCloseModal: resolve,
      });
    });

    // モーダルを非表示にする
    setApprovalReviewModalConfig(undefined);
    if (result !== "OK") return;

    try {
      // 審査結果承認処理
      await approveReview({
        reviewId,
      }).unwrap();

      // 参照モードに切り替え
      setEditMode("readOnly");

      await sleep(200);

      // 「FN40-S32（業務執行理事承認完了）」画面に遷移
      await navigateWithoutPrompt(
        `${projectPagePathPrefix}/document/${documentId}/review/${reviewId}/approved`,
      );
    } catch (e) {
      errorToast(t("mes.審査結果承認失敗エラー"));
      log.error(errorMessageOf(e));
    }
  };

  const scrollableRef = useRef(null);

  return (
    <>
      <Flex
        flex={"1 1 auto"}
        overflow={"auto"}
        flexDirection={"column"}
        ref={scrollableRef}
        pb={"20px"}
      >
        {/*ボタンエリア*/}
        <FrameUpperRightButton mb={"10px"}>
          {canEdit && (
            <HStack>
              {editMode === "readOnly" ? (
                // 参照モード
                <>
                  <EditButton onClick={handleEdit} />
                </>
              ) : (
                // 編集モード
                <>
                  <SaveChangesButton onClick={handleSave} mr={"8px"} />
                  <EditEndButton onClick={handleEditCancel} />
                </>
              )}
            </HStack>
          )}
        </FrameUpperRightButton>

        <Container minW={"400px"} maxW={"720px"}>
          <Stack alignItems={"stretch"}>
            {/*タイトルエリア*/}
            <Flex
              direction={"row"}
              alignItems={"flex-start"}
              justifyContent={"space-between"}
            >
              <TitleText title={reviewDetailDataLabel} />
              <VStack alignItems={"flex-start"} spacing={0}>
                <WeakTextItem label={t("lbl.作成日時")} value={createdDate} />
                <WeakTextItem label={t("lbl.更新日時")} value={updatedDate} />
              </VStack>
            </Flex>
            {/*メインエリア*/}
            <CMTabSmallList
              tabNameList={tabNameList}
              childrenList={[
                <ReviewDetailsContentTab
                  key={"審査内容タブ"}
                  editMode={editMode}
                  editContent={editContent}
                  documentState={documentState}
                  onChange={setEditContent}
                  onFixedReview={handleConcludeReview}
                  onApprovalReview={handleApproveReview}
                />,
                <ReviewDetailsDocumentContentTab
                  key={"書類内容タブ"}
                  documentSnapshotId={review.documentSnapshotIdOnConclusion}
                  scrollableRef={scrollableRef}
                  scrollOffset={60}
                />,
              ]}
            />
          </Stack>
        </Container>
      </Flex>

      {/*変更保存確認メッセージ*/}
      {saveModalConfig && (
        <PromiseModalOptionsCancel
          labelBody={t("mes.変更保存確認メッセージ")}
          onCloseModal={saveModalConfig.onCloseModal}
        />
      )}

      {/*変更内容破棄メッセージ*/}
      {editCancelModalConfig && (
        <PromiseModalOptionsCancel
          labelBody={t("mes.変更内容破棄メッセージ")}
          onCloseModal={editCancelModalConfig.onCloseModal}
        />
      )}

      {/*審査結果確定メッセージ*/}
      {concludeReviewModalConfig && (
        <PromiseModalOptionsCancel
          labelBody={t("mes.審査結果確定確認メッセージ")}
          onCloseModal={concludeReviewModalConfig.onCloseModal}
        />
      )}

      {/*審査結果承認確認メッセージ*/}
      {approvalReviewModalConfig && (
        <PromiseModalOptionsCancel
          labelBody={t("mes.審査結果承認確認メッセージ")}
          onCloseModal={approvalReviewModalConfig.onCloseModal}
        />
      )}
    </>
  );
};
