import { Link } from "@chakra-ui/react";
import React, { memo } from "react";
import { ComponentStyleProps } from "../../../../lib/styles/props/component-style-props";
import { Icon } from "@chakra-ui/icons";
import { MdArrowBack } from "react-icons/md";
import { IconType } from "react-icons";

export type BackPageButtonProps = {
  icon?: IconType;
  label?: string;

  onClick?: () => void;
} & ComponentStyleProps;

export const BackPageButton = memo(function BackPageButton({
  icon = MdArrowBack,
  label,
  onClick,
  sx,
  ...rest
}: BackPageButtonProps) {
  const capitalizedLabel = label;
  return (
    <Link
      onClick={onClick}
      fontSize={"14px"}
      color={"gray.500"}
      whiteSpace={"nowrap"}
      display={"flex"}
      _hover={{ border: "none" }}
      sx={sx}
      {...rest}
    >
      <Icon as={icon} w={"20px"} h={"20px"} mr={"8px"} color={"gray.500"} />
      {capitalizedLabel}
    </Link>
  );
});
