import { useCallback, useEffect, useMemo, useState } from "react";
import { merge } from "lodash";
import useCustomToast from "../../use-custom-toast";
import { hasValue, isNullish } from "../../../lib/util/common-util";
import { useAppSelector } from "../../redux-hooks";
import { selectUserInfo } from "../../../store/auth/slice";
import { useAppGetInstitutionQuery } from "../../query/use-app-get-institution-query";
import {
  useCreateAnnualReportDraftMutation,
  useDeleteDocumentDraftMutation,
  useUpdateAnnualReportContentMutation,
} from "../../../store/api/generated/stock-request-api";
import log from "loglevel";
import { errorMessageOf } from "../../../lib/util/error-util";
import { useCreateJsonPatchOperations } from "../../use-create-json-patch-operations";
import {
  AnnualReportContentViewModel,
  AnnualReportViewModel,
} from "../../../lib/object/vm/annual-report-view-model";
import { annualReportContentMeta } from "../../../lib/object/value/annual-report-content";
import { InstitutionInformation } from "../../../lib/object/value/institution-information";
import { UserInformation } from "../../../lib/object/value/user-information";
import { UsedCell } from "../../../lib/object/value/used-cell";
import { CollaborativePartnerOnReport } from "../../../lib/object/value/collaborative-partner-on-report";
import { AnnualReportSaved } from "../../../lib/object/entity/annual-report";
import { useAppGetProjectQuery } from "../../query/use-app-get-project-query";
import { ApplicationContentSaved } from "../../../lib/object/value/application-content";
import {
  jsDateToYmd,
  ymdToJsDateOrNull,
} from "../../../lib/util/common-date-util";
import { ValidationError } from "@pscsrvlab/psc-react-components";
import { useAppTranslation } from "../../use-app-translation";

export function useAnnualReportVMOrEmpty(
  /**
   * 案件ID。
   */
  projectId: number,
  /**
   * 書類ID。
   * この値がない場合、
   * 新規の（空の）年次報告ビューモデルを作成する。
   */
  documentId: number | undefined,

  annualReport?: AnnualReportSaved | null,

  options?: {
    /**
     * skipがtrueの場合、何も行わない。
     */
    skip: boolean;
  },
) {
  const _options = useMemo(() => merge({ skip: false }, options), [options]);

  const { t } = useAppTranslation();
  const { errorToast } = useCustomToast();
  const loginUserInfo = useAppSelector(selectUserInfo);

  /**
   * ログイン者の機関情報
   */
  const { data: institutionOfLoginUser } = useAppGetInstitutionQuery(
    {
      institutionId: loginUserInfo?.institutionId ?? -1,
    },
    { skip: _options.skip || isNullish(loginUserInfo?.institutionId) },
  );

  const { data: project } = useAppGetProjectQuery(
    {
      projectId: projectId ?? -1,
    },
    { skip: _options.skip || isNullish(projectId) },
  );

  // /**
  //  * 初期表示時に渡す、書類内容。
  //  * 一度作成したら二度と更新しない。
  //  */
  // const [initialViewModel, setInitialViewModel] =
  //   useState<AnnualReportContentViewModel | null>(null);

  /**
   * 初期表示時に渡す、ビューモデル。
   * 一度作成したら二度と更新しない。
   */
  const [initialAnnualReportVM, setInitialAnnualReportVM] =
    useState<AnnualReportViewModel | null>(null);

  const isInitialized = useMemo(() => {
    return hasValue(initialAnnualReportVM);
  }, [initialAnnualReportVM]);

  // 報告期間初。
  //「機能仕様(部品)(P0番台)」_「報告対象期間」_「開始日」参照。
  const reportPeriodStartDate = useMemo(() => {
    const newApplicationApprovalDate = ymdToJsDateOrNull(
      project?.newApplicationApprovalDate,
    );
    const lastAnnualReportSubmissionDate = ymdToJsDateOrNull(
      project?.lastAnnualReportSubmissionDate,
    );
    if (
      isNullish(lastAnnualReportSubmissionDate) &&
      hasValue(newApplicationApprovalDate)
    ) {
      return newApplicationApprovalDate;
    }
    if (hasValue(lastAnnualReportSubmissionDate)) {
      return lastAnnualReportSubmissionDate;
    }
    return null;
  }, [project]);

  // 保存済の年次報告から作成する場合。
  const vmFromSaved: AnnualReportViewModel | null =
    useAnnualReportVM(annualReport);
  useEffect(() => {
    if (_options.skip || isInitialized) return;

    // データ取得済でなければ、何もしない。
    if (isNullish(vmFromSaved)) return;

    // ビューモデル初期値に値を設定する。
    setInitialAnnualReportVM(vmFromSaved);
  }, [_options.skip, isInitialized, vmFromSaved]);

  // 新規の年次報告VMを作成する場合。
  useEffect(() => {
    if (_options.skip || isInitialized) return;

    // 書類IDが指定されている場合は、作成しない。
    if (hasValue(documentId)) return;

    // 色々と読み込むまでは作成しない。
    if (isNullish(project) || isNullish(reportPeriodStartDate)) return;

    const projectContent: ApplicationContentSaved = project.applicationContent;
    const usedResearchCells: UsedCell[] = projectContent.requestingResearchCells
      .map((val) => {
        if (hasValue(val)) {
          return {
            ...val,
            opinions: "",
          };
        }
      })
      .filter(hasValue);
    const usedClinicalCells: UsedCell[] = projectContent.requestingClinicalCells
      .map((val) => {
        if (hasValue(val)) {
          return {
            ...val,
            opinions: "",
          };
        }
      })
      .filter(hasValue);
    const partners: CollaborativePartnerOnReport[] = projectContent.partners
      .map((val) => {
        if (hasValue(val)) {
          return {
            key: val.key,
            collaborativePartnerName: val.collaborativePartnerName,
            collaborativePartnerRole: val.collaborativePartnerRole,
            principalInvestigator: val.principalInvestigator,
            cellProvisionType: "provided" as const,
            cellProvisions: [],
          };
        }
      })
      .filter(hasValue);

    if (isNullish(projectContent?.principalInvestigator)) {
      log.error(
        `FN40S04AnnualReportCreate: 研究責任者情報が空なので、処理を中断します。`,
      );
      errorToast(t("mes.汎用エラーメッセージ"));
      return;
    }

    const _reportContent = createInitialViewModel(
      projectContent.projectName,
      projectContent.institution,
      projectContent.principalInvestigator,
      usedResearchCells,
      usedClinicalCells,
      reportPeriodStartDate,
      partners,
    );

    // 書類内容初期値に値を設定する。
    setInitialAnnualReportVM({
      type: "annual_report",

      projectId: project.id,
      projectControlNumber: project.projectControlNumber,
      documentControlNumber: annualReport?.documentControlNumber,

      submissionDate: annualReport?.submissionDate,
      receptionDate: annualReport?.receptionDate,
      approvalDate: annualReport?.approvalDate,
      documentCompletionDate: annualReport?.documentCompletionDate,

      contentVM: _reportContent,
      nextAnnualReportDeadline: project.nextAnnualReportDeadline,
    });
  }, [
    documentId,
    loginUserInfo,
    institutionOfLoginUser,
    project,
    reportPeriodStartDate,
    errorToast,
    t,
    _options.skip,
    isInitialized,
    annualReport?.documentControlNumber,
    annualReport?.submissionDate,
    annualReport?.receptionDate,
    annualReport?.approvalDate,
    annualReport?.documentCompletionDate,
  ]);

  // const initialAnnualReportVM: AnnualReportViewModel | null = useMemo(() => {
  //   if (isNullish(initialViewModel) || isNullish(project)) return null;
  //   return {
  //     type: "annual_report",
  //
  //     projectId,
  //     projectControlNumber: project.projectControlNumber,
  //     documentControlNumber: annualReport?.documentControlNumber,
  //
  //     submissionDate: annualReport?.submissionDate,
  //     receptionDate: annualReport?.receptionDate,
  //     approvalDate: annualReport?.approvalDate,
  //     documentCompletionDate: annualReport?.documentCompletionDate,
  //
  //     contentVM: initialViewModel,
  //     nextAnnualReportDeadline: project.nextAnnualReportDeadline,
  //   };
  // }, [
  //   annualReport?.approvalDate,
  //   annualReport?.documentCompletionDate,
  //   annualReport?.documentControlNumber,
  //   annualReport?.receptionDate,
  //   annualReport?.submissionDate,
  //   initialViewModel,
  //   project,
  //   projectId,
  // ]);

  const [triggerCreateDraft] = useCreateAnnualReportDraftMutation();
  const [triggerDeleteDraft] = useDeleteDocumentDraftMutation();
  const [triggerUpdateContent] = useUpdateAnnualReportContentMutation();

  const [validationErrors, setValidationErrors] = useState<ValidationError[]>(
    [],
  );

  /**
   * 年次報告の下書きを新規作成する。
   * applicationContentのバリデーションは済んでいる前提とする。
   *
   * 成功・失敗に関わらず、結果を返却するだけなので、トーストを出すなどの後処理は呼び出し側の責務とする。
   */
  const createAnnualReportDraft = useCallback(
    async (
      content: AnnualReportContentViewModel,
      skipOptionalValidations: boolean,
    ): Promise<
      | { state: "ok"; documentId: number }
      | { state: "error"; errors: ValidationError[] }
      | { state: "unexpectedError" }
    > => {
      if (hasValue(documentId)) {
        log.error(
          "書類IDが指定されているため、年次報告の下書き新規作成は行えません。",
        );
        return { state: "unexpectedError" };
      }

      const validationState = annualReportContentMeta.validate(
        content,
        skipOptionalValidations,
      );
      if (validationState.state !== "ok") {
        return validationState;
      }

      const contentJson = annualReportContentMeta.toJsonObjectOrNull(
        validationState.value,
      );
      if (isNullish(contentJson)) return { state: "unexpectedError" };

      try {
        const createResult = await triggerCreateDraft({
          projectId,
          annualReportContent: contentJson,
        }).unwrap();
        const documentId = createResult.id;
        if (isNullish(documentId)) return { state: "unexpectedError" };
        return { state: "ok", documentId };
      } catch (e) {
        log.error(errorMessageOf(e));
        return { state: "unexpectedError" };
      }
    },
    [documentId, projectId, triggerCreateDraft],
  );

  const { createJsonPatchOperations } = useCreateJsonPatchOperations(
    annualReportContentMeta,
  );

  /**
   * 年次報告内容を更新する。
   * applicationContentのバリデーションは済んでいる前提とする。
   *
   * 成功・失敗に関わらず、結果を返却するだけなので、トーストを出すなどの後処理は呼び出し側の責務とする。
   */
  const updateAnnualReportContent = useCallback(
    async (
      content: AnnualReportContentViewModel,
      skipOptionalValidations: boolean,
    ): Promise<
      | { state: "ok"; documentId: number }
      | { state: "error"; errors: ValidationError[] }
      | { state: "unexpectedError" }
    > => {
      if (
        isNullish(documentId) ||
        isNullish(annualReport) ||
        isNullish(initialAnnualReportVM)
      ) {
        log.error(
          "書類IDが未指定または年次報告が取得できていないため、年次報告内容の上書き保存は行えません。",
        );
        return { state: "unexpectedError" };
      }

      const validationState = annualReportContentMeta.validate(
        content,
        skipOptionalValidations,
      );
      if (validationState.state !== "ok") {
        return validationState;
      }

      const jsonPatchOperations = createJsonPatchOperations(
        initialAnnualReportVM.contentVM,
        validationState.value,
      );
      if (isNullish(jsonPatchOperations)) return { state: "unexpectedError" };

      try {
        await triggerUpdateContent({
          documentId,
          commonUpdateRequest: { patchOperations: jsonPatchOperations },
        }).unwrap();
      } catch (e) {
        log.error(errorMessageOf(e));
        return { state: "unexpectedError" };
      }

      return { state: "ok", documentId };
    },
    [
      documentId,
      annualReport,
      initialAnnualReportVM,
      createJsonPatchOperations,
      triggerUpdateContent,
    ],
  );

  /**
   * 年次報告下書きを新規作成、または年次報告内容を更新する。
   */
  const saveAnnualReportContent = useCallback(
    async (
      vm: AnnualReportViewModel,
      skipOptionalValidations: boolean,
    ): Promise<
      | { state: "ok"; documentId: number }
      | { state: "error"; errors: ValidationError[] }
      | { state: "unexpectedError" }
    > => {
      const result = isNullish(documentId)
        ? await createAnnualReportDraft(vm.contentVM, skipOptionalValidations)
        : await updateAnnualReportContent(
            vm.contentVM,
            skipOptionalValidations,
          );
      if (result.state === "ok") setValidationErrors([]);
      if (result.state === "error") setValidationErrors(result.errors);

      return result;
    },
    [createAnnualReportDraft, documentId, updateAnnualReportContent],
  );

  const deleteAnnualReportDraft = useCallback(async (): Promise<
    { state: "ok" } | { state: "unexpectedError" }
  > => {
    if (isNullish(documentId)) {
      log.error("書類IDが未指定のため、年次報告の下書き削除は行えません。");
      return { state: "unexpectedError" };
    }

    try {
      await triggerDeleteDraft({ documentId }).unwrap();
    } catch (e) {
      log.error(errorMessageOf(e));
      return { state: "unexpectedError" };
    }

    return { state: "ok" };
  }, [documentId, triggerDeleteDraft]);

  return {
    project,
    annualReport,

    initialAnnualReportVM,

    validationErrors,

    saveAnnualReportContent,
    deleteAnnualReportDraft,
  };
}

function createInitialViewModel(
  projectName: string,
  institution: InstitutionInformation,
  principalInvestigator: UserInformation,
  usedResearchCells: UsedCell[],
  usedClinicalCells: UsedCell[],
  reportPeriodStartDate: Date,
  partners: CollaborativePartnerOnReport[],
): AnnualReportContentViewModel {
  return {
    projectName,
    institution,
    principalInvestigator,
    usedResearchCells,
    usedClinicalCells,
    reportPeriodStartDate: jsDateToYmd(reportPeriodStartDate),
    reportPeriodEndDate: {
      year: 1970,
      month: 1,
      day: 1,
    },
    attachmentFiles: [],
    partners,
    cellProvisionType: "provided",
    cellProvisions: [],
    progressResultOutline: "",
  };
}

export function useAnnualReportVM(
  annualReport: AnnualReportSaved | null | undefined,
): AnnualReportViewModel | null {
  const { data: project } = useAppGetProjectQuery(
    { projectId: annualReport?.projectId ?? -1 },
    { skip: isNullish(annualReport?.projectId) },
  );
  return useMemo(() => {
    if (isNullish(annualReport) || isNullish(project)) return null;
    return {
      type: "annual_report",

      projectId: annualReport.projectId,
      projectControlNumber: project.projectControlNumber,
      documentControlNumber: annualReport?.documentControlNumber,

      submissionDate: annualReport?.submissionDate,
      receptionDate: annualReport?.receptionDate,
      approvalDate: annualReport?.approvalDate,
      documentCompletionDate: annualReport?.documentCompletionDate,

      contentVM: annualReport.content,
      nextAnnualReportDeadline: project.nextAnnualReportDeadline,
    };
  }, [annualReport, project]);
}
