import { HandlingAfterTermination } from "../value/handling-after-termination";
import { ErrorMessageObject, messageId } from "@pscsrvlab/psc-react-components";
import { isNullish } from "../../util/common-util";

export function handlingAfterTerminationCustomValidation(
  value: HandlingAfterTermination,
  skipOptional: boolean,
): ErrorMessageObject[] {
  const mesId = messageId(
    "mes.使用終了使用終了後の取り扱い不順守理由未入力エラー",
  );

  if (
    !skipOptional &&
    value.planComplianceType === "no" &&
    isNullish(value.reasonsForNotBeingAbleToComply)
  ) {
    return [{ errorMessageId: mesId }];
  }
  return [];
}
