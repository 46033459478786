import {
  messageId,
  PropertyMeta,
  ValueObjectMetaString,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.string().max(10000);
const savedMinimalSchema = z.string().max(10000);
const schema = z.string().min(1).max(10000);
const savedSchema = z.string().min(1).max(10000);
const jsonSchema = z.string();
/**
 * 分化細胞保管・廃棄状況(値オブジェクト)のメタデータ。
 *
 *  */
export const differentiatedCellPreservationTypeMeta = new ValueObjectMetaString(
  "DifferentiatedCellPreservationType",
  "\u5206\u5316\u7D30\u80DE\u4FDD\u7BA1\u30FB\u5EC3\u68C4\u72B6\u6CC1",
  messageId(
    "lbl.\u5206\u5316\u7D30\u80DE\u4FDD\u7BA1\u30FB\u5EC3\u68C4\u72B6\u6CC1"
  ),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
