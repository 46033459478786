import { ChangeBadgeProperties } from "@pscsrvlab/psc-react-components/src/lib/types/types";
import { useMemo } from "react";
import { useAppTranslation } from "../../use-app-translation";

/**
 * changeBadgePropertiesを返却する。
 */
export function useChangeBadgeProperties<T extends {}>(
  documentType:
    | "new_application"
    | "change_application"
    | "project_force_update"
    | "project_force_create"
    | "project_content",
  changedFrom?: T | null,
): ChangeBadgeProperties<T> | undefined {
  const { t } = useAppTranslation();
  return useMemo(() => {
    if (
      documentType === "new_application" ||
      documentType === "project_force_create" ||
      documentType === "project_force_update" ||
      documentType === "project_content"
    )
      return undefined;
    return {
      size: "md",
      label: t("lbl.更新"),
      changedFromLabel: t("lbl.変更前"),
      changedFrom,
    };
  }, [changedFrom, documentType, t]);
}
