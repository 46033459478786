import { NewApplicationCreateConfirmPage } from "./_components/NewApplicationCreateConfirmPage/NewApplicationCreateConfirmPage";
import { hasValue } from "../../../../lib/util/common-util";
import { useAppParams } from "../../../../hooks/use-app-params";
import { useNewApplicationReadonlyVm } from "../../../../hooks/document/new-application/use-new-application-readonly-vm";

export const FN40S06NewApplicationCreateConfirm = () => {
  const { documentId } = useAppParams();

  const { currentData, vm } = useNewApplicationReadonlyVm(documentId);

  return (
    <>
      {hasValue(documentId) &&
        hasValue(vm) &&
        currentData?.documentState === "draft" && (
          <NewApplicationCreateConfirmPage documentId={documentId} vm={vm} />
        )}
    </>
  );
};
