import {
  messageId,
  PropertyMeta,
  ValueObjectMetaString,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.string().max(100);
const savedMinimalSchema = z.string().max(100);
const schema = z.string().max(100);
const savedSchema = z.string().max(100);
const jsonSchema = z.string();
/**
 * 集荷先名称(値オブジェクト)のメタデータ。
 *
 *  */
export const pickupDestinationNameMeta = new ValueObjectMetaString(
  "PickupDestinationName",
  "\u96C6\u8377\u5148\u540D\u79F0",
  messageId("lbl.\u96C6\u8377\u5148\u540D\u79F0"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
