import { Box } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { ComponentStyleProps } from "../../../../lib/styles/props/component-style-props";

export type UpperLeftButtonFrameProps = {
  children?: ReactNode;
} & ComponentStyleProps;

/**
 * 画面右上に表示するボタンのフレーム
 */
export const FrameUpperLeftButton = ({
  children,
  sx,
  ...rest
}: UpperLeftButtonFrameProps) => {
  return (
    <Box pt={"20px"} pl={"20px"} sx={sx} {...rest}>
      {children}
    </Box>
  );
};
