import { CMFormInputDate } from "@pscsrvlab/psc-react-components";
import React from "react";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { FormSection } from "../../../../../ui/form/FormSection/FormSection";

export type SectionDateProps = {
  /**
   * 画面編集モード
   */
  editMode?: "editable" | "disabled" | "readOnly";

  /**
   * 日付
   */
  value?: { year: number; month: number; day: number };

  /**
   * 変更イベント
   * @param changedValue
   */
  onChange?: (changedValue: {
    year: number;
    month: number;
    day: number;
  }) => void;
};
/**
 * FN40-S30審査
 * 到着希望日セクション
 */
export const SectionDate = ({
  editMode = "readOnly",
  value,
  onChange,
}: SectionDateProps) => {
  const { t } = useAppTranslation();

  const handleChange = (selectedValue: {
    year: number;
    month: number;
    day: number;
  }) => {
    onChange?.(selectedValue);
  };

  return (
    <FormSection title={t("lbl.審査実施日")}>
      <CMFormInputDate
        editMode={editMode}
        noHeader={true}
        value={value}
        onChange={handleChange}
      />
    </FormSection>
  );
};
