import { ProtectedRoute as PR } from "../components/route/ProtectedRoute";
import * as React from "react";
import { FN60S01InquirySearch } from "../components/page/FN60/FN60S01InquirySearch/FN60S01InquirySearch";
import { FN60S02InquiryCreate } from "../components/page/FN60/FN60S02InquiryCreate/FN60S02InquiryCreate";
import { RouteObject } from "react-router-dom";

export const _inquiryRoute: RouteObject = {
  path: "inquiry",
  element: <PR roles={["applicant", "office_member"]} />,
  children: [
    {
      index: true,
      element: <FN60S01InquirySearch />,
    },
    {
      path: "create",
      element: <FN60S02InquiryCreate />,
    },
  ],
};
