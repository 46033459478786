import { LoginUserInfo } from "../../../../store/auth/types";
import { ComponentStyleProps } from "../../../../lib/styles/props/component-style-props";
import React, { memo, useMemo, useRef } from "react";
import { hasValue } from "../../../../lib/util/common-util";
import { VStack } from "@chakra-ui/react";
import { DocumentContentNewApplicationEdit } from "../application/new-application/DocumentContentNewApplicationEdit/DocumentContentNewApplicationEdit";
import { DocumentContentChangeApplicationEdit } from "../application/change-application/DocumentContentChangeApplicationEdit/DocumentContentChangeApplicationEdit";
import { DocumentContentAnnualReportEdit } from "../report/annual-report/DocumentContentAnnualReportEdit/DocumentContentAnnualReportEdit";
import { DocumentContentTerminationReportEdit } from "../report/termination-report/DocumentContentTerminationReportEdit/DocumentContentTerminationReportEdit";
import { useSavedDocumentVM } from "../../../../hooks/document/use-saved-document-vm";
import { useProjectPagePathPrefix } from "../../../../hooks/use-project-page-path-prefix";

export type DocumentContentEditProps = {
  documentId: number;
  loginUserInfo: LoginUserInfo;
} & ComponentStyleProps;

export const DocumentContentEdit = memo(function DocumentContentEdit({
  documentId,
  loginUserInfo,

  sx,
  ...rest
}: DocumentContentEditProps) {
  const { projectPagePathPrefix } = useProjectPagePathPrefix();

  const backUrlPath = useMemo(() => {
    return `${projectPagePathPrefix}/document/${documentId}/content`;
  }, [projectPagePathPrefix, documentId]);

  const { projectId, vmAndCallback, validationErrors } =
    useSavedDocumentVM(documentId);

  const scrollableRef = useRef(null);

  return (
    <>
      {hasValue(projectId) && hasValue(vmAndCallback) && (
        <VStack
          alignItems={"stretch"}
          overflow={"auto"}
          sx={sx}
          {...rest}
          ref={scrollableRef}
        >
          {vmAndCallback.type === "new_application" ? (
            <DocumentContentNewApplicationEdit
              loginUserInfo={loginUserInfo}
              initialViewModel={vmAndCallback.vm}
              validationErrors={validationErrors}
              saveDocument={vmAndCallback.saveDocument}
              backUrlPath={backUrlPath}
              scrollableRef={scrollableRef}
              scrollOffset={60}
            />
          ) : vmAndCallback.type === "change_application" ? (
            <DocumentContentChangeApplicationEdit
              loginUserInfo={loginUserInfo}
              initialViewModel={vmAndCallback.vm}
              validationErrors={validationErrors}
              saveDocument={vmAndCallback.saveDocument}
              backUrlPath={backUrlPath}
              scrollableRef={scrollableRef}
              scrollOffset={60}
            />
          ) : vmAndCallback.type === "annual_report" ? (
            <DocumentContentAnnualReportEdit
              loginUserInfo={loginUserInfo}
              initialViewModel={vmAndCallback.vm}
              validationErrors={validationErrors}
              saveDocument={vmAndCallback.saveDocument}
              backUrlPath={backUrlPath}
            />
          ) : vmAndCallback.type === "termination_report" ? (
            <DocumentContentTerminationReportEdit
              loginUserInfo={loginUserInfo}
              initialViewModel={vmAndCallback.vm}
              validationErrors={validationErrors}
              saveDocument={vmAndCallback.saveDocument}
              backUrlPath={backUrlPath}
            />
          ) : null}
        </VStack>
      )}
    </>
  );
});
