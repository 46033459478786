import {
  Institution as InstitutionJson,
  useListInstitutionQuery,
} from "../../store/api/generated/stock-request-api";
import { hasValue, isNullish } from "../../lib/util/common-util";
import { useMemo } from "react";
import {
  institutionMeta,
  InstitutionSaved,
} from "../../lib/object/entity/institution";

export function useAppListInstitutionQuery(
  arg: Parameters<typeof useListInstitutionQuery>[0],
  options?: Parameters<typeof useListInstitutionQuery>[1],
  skipOptionalValidations = true, // get系は、skipOptionalValidationsのデフォルトはtrueとする。
): { data: InstitutionSaved[] | null } {
  const { data: rawData } = useListInstitutionQuery(arg, options);
  const data = useMemo(() => {
    if (isNullish(rawData)) return null;
    return rawData
      .map((d: InstitutionJson) =>
        institutionMeta.toSavedDomainObjectOrNull(d, skipOptionalValidations),
      )
      .filter(hasValue);
  }, [rawData, skipOptionalValidations]);
  return { data };
}
