import {
  messageId,
  PropertyMeta,
  ValueObjectMetaNumber,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.number().min(1);
const savedMinimalSchema = z.number().min(1);
const schema = z.number().min(1);
const savedSchema = z.number().min(1);
const jsonSchema = z.number();
/**
 * 書類ID(値オブジェクト)のメタデータ。
 *
 *  */
export const documentIdMeta = new ValueObjectMetaNumber(
  "DocumentId",
  "\u66F8\u985EID",
  messageId("lbl.\u66F8\u985EID"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
