import { useGetCurrentDatetimeQuery } from "../store/api/generated/stock-request-api";
import { useMemo } from "react";
import { isNullish } from "../lib/util/common-util";
import { datetimeMeta } from "../lib/object/value/datetime";

/**
 * バックエンドからシステム日時（JST）を取得する。
 * 自動的に更新されるわけではないので、秒～分単位の厳密性が必要であれば、これは用いないこと。
 */
export function useCurrentDatetime() {
  const { data: currentDateData } = useGetCurrentDatetimeQuery();
  const currentDate: Date | null = useMemo(() => {
    if (isNullish(currentDateData)) return null;
    return datetimeMeta.toDomainObjectOrNull(currentDateData.currentDatetime);
  }, [currentDateData]);
  return currentDate;
}
