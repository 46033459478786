import { ComponentStyleProps } from "../../../lib/styles/props/component-style-props";
import {
  ChangeBadgeProperties,
  CMButtonFormCommentProps,
  CMFormInputSearchAndSelect,
  FormRequiredLabelProps,
  SearchDropdownDisplayValue,
} from "@pscsrvlab/psc-react-components";
import { Project } from "../../../store/api/generated/stock-request-api";
import { ReactElement, useCallback, useState } from "react";
import { hasValue } from "../../../lib/util/common-util";
import log from "loglevel";
import { errorMessageOf } from "../../../lib/util/error-util";
import useCustomToast from "../../../hooks/use-custom-toast";
import { stockRequestApi as api } from "../../../store/api/enhanced-api";
import { useAppTranslation } from "../../../hooks/use-app-translation";

export type FormInputProjectSelectProps = {
  editMode?: "editable" | "disabled" | "readOnly";
  size?: "sm" | "md" | "lg";
  noHeader?: boolean;
  label?: string;
  placeholder?: string;
  placeholderPopover?: string;
  institutionId?: number;
  projectId?: number;
  hideClearButton?: boolean;
  labelSubmit?: string;
  labelClear?: string;
  selectButtonRightIcon?: ReactElement;
  popoverTitle?: string;
  searchResultNone?: string;
  changedFromDisplayValue?: string;
  onChange?: (value?: number) => void;
  requiredLabelProps?: FormRequiredLabelProps;
  changeBadgeProperties?: ChangeBadgeProperties<number>;
  checkEqualityFn?: (v0: number, v1: number) => boolean | Promise<boolean>;
  commentButtonProps?: CMButtonFormCommentProps;
  usePortal?: boolean;
} & ComponentStyleProps;

/**
 * 案件セレクトボックス(入力フォーム用)
 */
export const FormInputProjectSelect = ({
  editMode,
  size,
  noHeader,
  label,
  placeholder,
  placeholderPopover,
  institutionId,
  projectId,
  hideClearButton,
  labelSubmit,
  labelClear,
  selectButtonRightIcon,
  popoverTitle,
  searchResultNone,
  changedFromDisplayValue,
  onChange,
  requiredLabelProps,
  changeBadgeProperties,
  checkEqualityFn,
  commentButtonProps,
  usePortal,
  sx,
  ...rest
}: FormInputProjectSelectProps) => {
  const { t } = useAppTranslation();
  const { errorToast } = useCustomToast();

  // 案件情報一覧取得用遅延クエリ
  const [triggerListProjectQuery] = api.useLazyListProjectQuery();
  // 案件情報取得用遅延クエリ
  const [triggerGetProjectQuery] = api.useLazyGetProjectQuery();

  // 検索結果(退避用)
  const [searchResult, setSearchResult] = useState<Project[]>([]);

  // 案件情報の取得
  const getProject = useCallback(
    async (projectId: number): Promise<Project | undefined> => {
      try {
        // 検索結果の退避が無い場合はAPIから取得
        if (!searchResult.length) {
          return await triggerGetProjectQuery({
            projectId: projectId,
          }).unwrap();
        }
        // 検索結果の退避が在る場合はそこから取得
        else {
          // 案件IDが一致するデータを検索結果(退避用)から抽出して返却
          return searchResult?.find((project) => project.id === projectId);
        }
      } catch (e) {
        log.error(errorMessageOf(e));
        errorToast(t("mes.汎用エラーメッセージ"));
      }
    },
    [errorToast, searchResult, t, triggerGetProjectQuery],
  );

  // 表示用ラベルの取得
  const toDisplayValue = useCallback(
    async (projectId: number): Promise<SearchDropdownDisplayValue> => {
      // 案件情報を取得し案件管理番号を返却
      const project = await getProject(projectId);
      return {
        mainLabel: project?.projectControlNumber ?? "",
      };
    },
    [getProject],
  );

  // 検索時のイベントハンドラ
  const handleSearch = useCallback(
    async (text: string) => {
      try {
        // 機関IDと案件管理番号(入力値)を元に案件を検索
        const data = await triggerListProjectQuery({
          institutionId,
          projectControlNumber: text,
        }).unwrap();
        // 検索結果を退避
        setSearchResult(data);
        // 案件IDのリストを返却
        return data.map((project) => project.id);
      } catch (e) {
        // 案件取得に失敗した場合は空のリストを返却
        log.error(errorMessageOf(e));
        errorToast(t("mes.汎用エラーメッセージ"));
        setSearchResult([]);
        return [];
      }
    },
    [triggerListProjectQuery, institutionId, errorToast, t],
  );

  // 確定ボタン押下時のイベントハンドラ
  const handleChange = useCallback(
    (projectId: number | null) => {
      // 親コンポーネントから渡されたイベントをトリガー
      onChange?.(hasValue(projectId) ? projectId : undefined);
    },
    [onChange],
  );

  return (
    <CMFormInputSearchAndSelect
      editMode={editMode}
      size={size}
      noHeader={noHeader}
      label={label ?? t("lbl.案件管理番号")}
      placeholder={placeholder}
      placeholderPopover={placeholderPopover}
      value={projectId}
      hideClearButton={hideClearButton}
      labelSubmit={labelSubmit ?? t("btn.確定ボタン")}
      labelClear={labelClear ?? t("btn.クリアボタン")}
      selectButtonRightIcon={selectButtonRightIcon}
      popoverTitle={popoverTitle ?? t("mes.案件選択案内メッセージ")}
      searchResultNone={searchResultNone ?? t("mes.該当案件なしメッセージ")}
      toDisplayValue={toDisplayValue}
      menuShowMaxCount={30}
      changedFromDisplayValue={changedFromDisplayValue}
      onSearch={handleSearch}
      onChange={handleChange}
      requiredLabelProps={requiredLabelProps}
      changeBadgeProperties={changeBadgeProperties}
      checkEqualityFn={checkEqualityFn}
      commentButtonProps={commentButtonProps}
      usePortal={usePortal}
      sx={sx}
      {...rest}
    />
  );
};
