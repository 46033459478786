import { ComponentStyleProps } from "../../../../../../../../lib/styles/props/component-style-props";
import { Center, Flex, Text } from "@chakra-ui/react";
import { useAppSearchMailLogQuery } from "../../../../../../../../hooks/query/use-app-search-mail-log-query";
import {
  hasValue,
  isNullish,
} from "../../../../../../../../lib/util/common-util";
import { LatestMailRow } from "../LatestMailRow/LatestMailRow";
import { useMemo } from "react";
import { useAppTranslation } from "../../../../../../../../hooks/use-app-translation";
import { useAppSelector } from "../../../../../../../../hooks/redux-hooks";
import { selectUserInfo } from "../../../../../../../../store/auth/slice";

export type LatestMailTableProps = {} & ComponentStyleProps;

export const LatestMailTable = ({ sx, ...rest }: LatestMailTableProps) => {
  const { t } = useAppTranslation();

  const loginUserInfo = useAppSelector(selectUserInfo);
  const { data: mailLogSearchResult } = useAppSearchMailLogQuery(
    {
      appUserId: loginUserInfo?.id ?? -1,
      sortCondition: "sent_at_desc",
      limit: 5,
    },
    { skip: isNullish(loginUserInfo?.id) },
  );

  const mailLogs = useMemo(() => {
    return mailLogSearchResult?.items;
  }, [mailLogSearchResult]);

  return (
    <Flex
      direction={"column"}
      minW={"760px"}
      maxW={"760px"}
      minH={"60px"}
      borderWidth={"1px"}
      borderColor={"gray.400"}
      borderRadius={"4px"}
      alignItems={"stretch"}
      justifyContent={"center"}
      overflow={"hidden"}
      sx={sx}
      {...rest}
    >
      {hasValue(mailLogs) && mailLogs.length > 0 ? (
        mailLogs.map((m, idx) => (
          <LatestMailRow
            key={m.id}
            mailLog={m}
            styleType={(idx % 2) as 0 | 1}
          />
        ))
      ) : (
        <Center flex={1}>
          <Text color={"gray.700"}>{t("mes.新着メールがありません")}</Text>
        </Center>
      )}
    </Flex>
  );
};
