import { ComponentStyleProps } from "../../../../../lib/styles/props/component-style-props";
import { FormSection } from "../../../../ui/form/FormSection/FormSection";
import { useAppTranslation } from "../../../../../hooks/use-app-translation";
import React, { useMemo } from "react";
import { CMFormInputTextArea } from "@pscsrvlab/psc-react-components";
import { unapprovalReasonMeta } from "../../../../../lib/object/value/unapproval-reason";

export type ResultNotificationSectionUnapprovalReasonProps = {
  resultNotificationType:
    | "application_no_review"
    | "application_approve_or_conditional"
    | "application_condition_confirmation"
    | "application_rereview";
  editMode: "editable" | "readOnly";

  value: string;
  onChange?: (value: string) => void;
} & ComponentStyleProps;

export const ResultNotificationSectionUnapprovalReason = ({
  resultNotificationType,
  editMode,

  value,
  onChange,

  sx,
  ...rest
}: ResultNotificationSectionUnapprovalReasonProps) => {
  const { t } = useAppTranslation();

  const title = useMemo(() => {
    if (resultNotificationType === "application_condition_confirmation")
      return t("lbl.条件付承認時の条件");
    return t("lbl.承認以外の場合の理由");
  }, [t, resultNotificationType]);

  return (
    <FormSection title={title} sx={sx} {...rest}>
      <CMFormInputTextArea
        editMode={editMode}
        valueObjectMeta={unapprovalReasonMeta}
        value={value}
        onChange={onChange}
      />
    </FormSection>
  );
};
