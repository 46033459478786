import {
  messageId,
  PropertyMeta,
  EntityMeta,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { revisionRequestIdMeta } from "../value/revision-request-id";
import { documentIdMeta } from "../value/document-id";
import { revisionRequestStateMeta } from "../value/revision-request-state";
import { revisionRequestBodyMeta } from "../value/revision-request-body";
import { userInformationMeta } from "../value/user-information";
import { datetimeMeta } from "../value/datetime";
import { revisionRequestItemMeta } from "../value/revision-request-item";
import { creationMetadataMeta } from "../value/creation-metadata";
import { updateMetadataMeta } from "../value/update-metadata";
/**
 * 修正依頼(エンティティ)
 *
 *  */
export type RevisionRequest = z.infer<typeof schema>;
export type RevisionRequestSaved = z.infer<typeof savedSchema>;
const minimalSchema = z.object({
  id: revisionRequestIdMeta.minimalSchema.optional(),
  documentId: documentIdMeta.minimalSchema,
  revisionRequestState: revisionRequestStateMeta.minimalSchema,
  body: revisionRequestBodyMeta.minimalSchema,
  reply: revisionRequestBodyMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  revisionRequester: userInformationMeta.minimalSchema,
  revisionRequestReplier: userInformationMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  revisionRequestDatetime: datetimeMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  revisionRequestReplyDatetime: datetimeMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  items: z.array(revisionRequestItemMeta.minimalSchema),
  created: creationMetadataMeta.minimalSchema,
  updated: updateMetadataMeta.minimalSchema,
});
const savedMinimalSchema = z.object({
  id: revisionRequestIdMeta.minimalSchema,
  documentId: documentIdMeta.savedMinimalSchema,
  revisionRequestState: revisionRequestStateMeta.savedMinimalSchema,
  body: revisionRequestBodyMeta.savedMinimalSchema,
  reply: revisionRequestBodyMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  revisionRequester: userInformationMeta.savedMinimalSchema,
  revisionRequestReplier: userInformationMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  revisionRequestDatetime: datetimeMeta.savedMinimalSchema,
  revisionRequestReplyDatetime: datetimeMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
  items: z.array(revisionRequestItemMeta.savedMinimalSchema),
  created: creationMetadataMeta.savedMinimalSchema,
  updated: updateMetadataMeta.savedMinimalSchema,
});
const schema = z.object({
  id: revisionRequestIdMeta.minimalSchema.optional(),
  documentId: documentIdMeta.schema,
  revisionRequestState: revisionRequestStateMeta.schema,
  body: revisionRequestBodyMeta.schema,
  reply: revisionRequestBodyMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
  revisionRequester: userInformationMeta.schema,
  revisionRequestReplier: userInformationMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
  revisionRequestDatetime: datetimeMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
  revisionRequestReplyDatetime: datetimeMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
  items: z.array(revisionRequestItemMeta.schema),
  created: creationMetadataMeta.schema,
  updated: updateMetadataMeta.schema,
});
const savedSchema = z.object({
  id: revisionRequestIdMeta.minimalSchema,
  documentId: documentIdMeta.savedSchema,
  revisionRequestState: revisionRequestStateMeta.savedSchema,
  body: revisionRequestBodyMeta.savedSchema,
  reply: revisionRequestBodyMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  revisionRequester: userInformationMeta.savedSchema,
  revisionRequestReplier: userInformationMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  revisionRequestDatetime: datetimeMeta.savedSchema,
  revisionRequestReplyDatetime: datetimeMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
  items: z.array(revisionRequestItemMeta.savedSchema),
  created: creationMetadataMeta.savedSchema,
  updated: updateMetadataMeta.savedSchema,
});
const jsonSchema = z.object({
  id: revisionRequestIdMeta.jsonSchema.optional(),
  documentId: documentIdMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  revisionRequestState: revisionRequestStateMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  body: revisionRequestBodyMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  reply: revisionRequestBodyMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  revisionRequester: userInformationMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  revisionRequestReplier: userInformationMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  revisionRequestDatetime: datetimeMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  revisionRequestReplyDatetime: datetimeMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  items: z
    .array(revisionRequestItemMeta.jsonSchema)
    .nullish()
    .transform((x) => x ?? undefined),
  created: creationMetadataMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  updated: updateMetadataMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
/**
 * 修正依頼(エンティティ)のメタデータ。
 *
 *  */
export const revisionRequestMeta = new EntityMeta<
  typeof schema,
  typeof savedSchema,
  typeof jsonSchema
>(
  "RevisionRequest",
  "\u4FEE\u6B63\u4F9D\u983C",
  messageId("lbl.\u4FEE\u6B63\u4F9D\u983C"),
  minimalSchema,
  savedMinimalSchema,
  schema,
  savedSchema,
  jsonSchema,
  undefined,
  undefined,
  {
    id: new PropertyMeta(
      "id",
      "ID",
      messageId("lbl.ID"),
      "afterSave",
      false,
      revisionRequestIdMeta
    ),
    documentId: new PropertyMeta(
      "documentId",
      "\u66F8\u985EID",
      messageId("lbl.\u66F8\u985EID"),
      "always",
      false,
      documentIdMeta
    ),
    revisionRequestState: new PropertyMeta(
      "revisionRequestState",
      "\u4FEE\u6B63\u4F9D\u983C\u72B6\u614B",
      messageId("lbl.\u4FEE\u6B63\u4F9D\u983C\u72B6\u614B"),
      "always",
      false,
      revisionRequestStateMeta
    ),
    body: new PropertyMeta(
      "body",
      "\u4FEE\u6B63\u4F9D\u983C\u4F9D\u983C\u6642\u672C\u6587",
      messageId("lbl.\u4FEE\u6B63\u4F9D\u983C\u4F9D\u983C\u6642\u672C\u6587"),
      "always",
      false,
      revisionRequestBodyMeta
    ),
    reply: new PropertyMeta(
      "reply",
      "\u4FEE\u6B63\u4F9D\u983C\u8FD4\u4FE1\u6642\u672C\u6587",
      messageId("lbl.\u4FEE\u6B63\u4F9D\u983C\u8FD4\u4FE1\u6642\u672C\u6587"),
      "none",
      false,
      revisionRequestBodyMeta
    ),
    revisionRequester: new PropertyMeta(
      "revisionRequester",
      "\u4FEE\u6B63\u4F9D\u983C\u8005",
      messageId("lbl.\u4FEE\u6B63\u4F9D\u983C\u8005"),
      "always",
      false,
      userInformationMeta
    ),
    revisionRequestReplier: new PropertyMeta(
      "revisionRequestReplier",
      "\u4FEE\u6B63\u8005",
      messageId("lbl.\u4FEE\u6B63\u8005"),
      "none",
      false,
      userInformationMeta
    ),
    revisionRequestDatetime: new PropertyMeta(
      "revisionRequestDatetime",
      "\u4FEE\u6B63\u4F9D\u983C\u65E5\u6642",
      messageId("lbl.\u4FEE\u6B63\u4F9D\u983C\u65E5\u6642"),
      "afterSave",
      false,
      datetimeMeta
    ),
    revisionRequestReplyDatetime: new PropertyMeta(
      "revisionRequestReplyDatetime",
      "\u4FEE\u6B63\u8FD4\u4FE1\u65E5\u6642",
      messageId("lbl.\u4FEE\u6B63\u8FD4\u4FE1\u65E5\u6642"),
      "none",
      false,
      datetimeMeta
    ),
    items: new PropertyMeta(
      "items",
      "\u4FEE\u6B63\u4F9D\u983C\u9805\u76EE",
      messageId("lbl.\u4FEE\u6B63\u4F9D\u983C\u9805\u76EE"),
      "always",
      true,
      revisionRequestItemMeta
    ),
    created: new PropertyMeta(
      "created",
      "\u4F5C\u6210",
      messageId("lbl.\u4F5C\u6210"),
      "always",
      false,
      creationMetadataMeta
    ),
    updated: new PropertyMeta(
      "updated",
      "\u66F4\u65B0",
      messageId("lbl.\u66F4\u65B0"),
      "always",
      false,
      updateMetadataMeta
    ),
  }
);
