import { ProtectedRoute as PR } from "../components/route/ProtectedRoute";
import * as React from "react";
import { FN50S01ReviewConferenceList } from "../components/page/FN50/FN50S01ReviewConferenceList/FN50S01ReviewConferenceList";
import { FN50S02ReviewConferenceDetails } from "../components/page/FN50/FN50S02ReviewConferenceDetails/FN50S02ReviewConferenceDetails";
import { FN50S03ReviewConferenceConcluded } from "../components/page/FN50/FN50S03ReviewConferenceConcluded/FN50S03ReviewConferenceConcluded";
import { RouteObject } from "react-router-dom";

export const _reviewConferenceRoute: RouteObject = {
  path: "review-conference",
  element: (
    <PR roles={["office_member", "committee_member", "executive_director"]} />
  ),
  children: [
    { index: true, element: <FN50S01ReviewConferenceList /> },
    {
      path: "create",
      element: <FN50S02ReviewConferenceDetails mode={"create"} />,
    },
    {
      path: ":reviewConferenceId",
      children: [
        {
          index: true,
          element: <FN50S02ReviewConferenceDetails mode={"view-or-edit"} />,
        },
        { path: "concluded", element: <FN50S03ReviewConferenceConcluded /> },
      ],
    },
  ],
};
