import {
  messageId,
  PropertyMeta,
  ValueObjectMetaString,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
const minimalSchema = z.string().max(100);
const savedMinimalSchema = z.string().max(100);
const schema = z.string().min(1).max(100);
const savedSchema = z.string().min(1).max(100);
const jsonSchema = z.string();
/**
 * 表示用ユーザーID(値オブジェクト)のメタデータ。
 *
 *  */
export const displayUserIdMeta = new ValueObjectMetaString(
  "DisplayUserId",
  "\u8868\u793A\u7528\u30E6\u30FC\u30B6\u30FCID",
  messageId("lbl.\u8868\u793A\u7528\u30E6\u30FC\u30B6\u30FCID"),
  minimalSchema,
  schema,
  jsonSchema,
  undefined,
  undefined
);
