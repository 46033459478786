import { CMFormInputTextArea, Comments } from "@pscsrvlab/psc-react-components";
import React, { memo, useCallback } from "react";
import { CMButtonFormCommentProps } from "@pscsrvlab/psc-react-components/src/components/form/comment";
import { ComponentStyleProps } from "../../../../../../../../lib/styles/props/component-style-props";
import { progressResultOutlineMeta } from "../../../../../../../../lib/object/value/progress-result-outline";
import { useAppTranslation } from "../../../../../../../../hooks/use-app-translation";
import { FormSection } from "../../../../../../../ui/form/FormSection/FormSection";

export type ProgressResultOutlineSectionProps = {
  documentType?: DocumentType;
  editMode: "editable" | "disabled" | "readOnly";

  /**
   * 現在の値。
   */
  value: string;

  onChange: (value: string) => void;

  /**
   * コメントボタンのprops。
   * これが存在すれば、コメントボタンを表示する。
   */
  commentButtonProps?: CMButtonFormCommentProps;
  /**
   * 1セクション単位の修正依頼コメント・返信。履歴参照画面で使用。
   */
  commentsList?: Comments[];

} & ComponentStyleProps;

/**
 * 研究の進捗状況・結果の概要セクション
 */
export const ProgressResultOutlineAnnualReportSection = memo(
  function ProjectNameFormSection({
    editMode,
    value,
    onChange,
    commentButtonProps,
    commentsList,
    sx,
    ...rest
  }: ProgressResultOutlineSectionProps) {
    const { t } = useAppTranslation();

    const handleChange = useCallback(
      (val: string) => {
        onChange(val);
      },
      [onChange],
    );

    return (
      <FormSection
        title={t("lbl.研究の進捗状況・結果の概要")}
        commentButtonProps={commentButtonProps}
        commentsList={commentsList}
        sx={sx}
        {...rest}
      >
        <CMFormInputTextArea
          editMode={editMode}
          valueObjectMeta={progressResultOutlineMeta}
          value={value}
          onChange={handleChange}
        />
      </FormSection>
    );
  },
);
