import { ComponentStyleProps } from "../../../../../../../../lib/styles/props/component-style-props";
import { CMFormInputText } from "@pscsrvlab/psc-react-components";
import React, { memo } from "react";
import { FormSection } from "../../../../../../../ui/form/FormSection/FormSection";
import { projectControlNumberMeta } from "../../../../../../../../lib/object/value/project-control-number";
import { useAppTranslation } from "../../../../../../../../hooks/use-app-translation";

export type ProjectControlNumberCreateProjectSectionProps = {
  editMode: "editable" | "readOnly";

  /**
   * 値。
   */
  value: string;
  onChange: (value: string) => void;
} & ComponentStyleProps;

/**
 * 案件管理番号セクション
 */
export const ProjectControlNumberCreateProjectSection = memo(
  function ProjectControlNumberCreateProjectSection({
    editMode,

    value,
    onChange,

    sx,
    ...rest
  }: ProjectControlNumberCreateProjectSectionProps) {
    const { t } = useAppTranslation();

    return (
      <FormSection title={t("lbl.案件管理番号")} sx={sx} {...rest}>
        <CMFormInputText
          editMode={editMode}
          value={value}
          onChange={onChange}
          valueObjectMeta={projectControlNumberMeta}
          sx={{ w: "300px" }}
        />
      </FormSection>
    );
  },
);
