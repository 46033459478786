import {
  messageId,
  PropertyMeta,
  ValueObjectMetaComposite,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { ipsCellStockUsePurposeCustomValidation } from "../custom-validation/ips-cell-stock-use-purpose";
import { ipsCellStockUsePurposeTypeMeta } from "../value/ips-cell-stock-use-purpose-type";
import { ipsCellStockUsePurposeSubTypeMeta } from "../value/ips-cell-stock-use-purpose-sub-type";
/**
 * iPS細胞の使用目的(値オブジェクト)
 *
 *  */
export type IpsCellStockUsePurpose = z.infer<typeof schema>;
export type IpsCellStockUsePurposeSaved = z.infer<typeof savedSchema>;
const minimalSchema = z.object({
  purposeType: ipsCellStockUsePurposeTypeMeta.minimalSchema,
  purposeSubType: ipsCellStockUsePurposeSubTypeMeta.minimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
const savedMinimalSchema = z.object({
  purposeType: ipsCellStockUsePurposeTypeMeta.savedMinimalSchema,
  purposeSubType: ipsCellStockUsePurposeSubTypeMeta.savedMinimalSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
const schema = z.object({
  purposeType: ipsCellStockUsePurposeTypeMeta.schema,
  purposeSubType: ipsCellStockUsePurposeSubTypeMeta.schema
    .nullish()
    .transform((x) => x ?? undefined),
});
const savedSchema = z.object({
  purposeType: ipsCellStockUsePurposeTypeMeta.savedSchema,
  purposeSubType: ipsCellStockUsePurposeSubTypeMeta.savedSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
const jsonSchema = z.object({
  purposeType: ipsCellStockUsePurposeTypeMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  purposeSubType: ipsCellStockUsePurposeSubTypeMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
});
/**
 * iPS細胞の使用目的(値オブジェクト)のメタデータ。
 *
 *
 *
 * 以下のカスタムバリデーションを、 ipsCellStockUsePurposeCustomValidation にて行う。
 * - (任意) S02相関チェックシート．iPs細胞の使用目的．No.2,6 (エラーメッセージID=mes.研究株案件ヒトへの投与予定ありエラーメッセージ) */
export const ipsCellStockUsePurposeMeta = new ValueObjectMetaComposite(
  "IpsCellStockUsePurpose",
  "iPS\u7D30\u80DE\u306E\u4F7F\u7528\u76EE\u7684",
  messageId("lbl.iPS\u7D30\u80DE\u306E\u4F7F\u7528\u76EE\u7684"),
  minimalSchema,
  savedMinimalSchema,
  schema,
  savedSchema,
  jsonSchema,
  undefined,
  ipsCellStockUsePurposeCustomValidation,
  {
    purposeType: new PropertyMeta(
      "purposeType",
      "iPS\u7D30\u80DE\u306E\u4F7F\u7528\u76EE\u7684\u533A\u5206",
      messageId(
        "lbl.iPS\u7D30\u80DE\u306E\u4F7F\u7528\u76EE\u7684\u533A\u5206"
      ),
      "always",
      false,
      ipsCellStockUsePurposeTypeMeta
    ),
    purposeSubType: new PropertyMeta(
      "purposeSubType",
      "iPS\u7D30\u80DE\u306E\u4F7F\u7528\u76EE\u7684\u8A73\u7D30\u533A\u5206",
      messageId(
        "lbl.iPS\u7D30\u80DE\u306E\u4F7F\u7528\u76EE\u7684\u8A73\u7D30\u533A\u5206"
      ),
      "none",
      false,
      ipsCellStockUsePurposeSubTypeMeta
    ),
  }
);
