import { z } from "zod";

// 使用可能な特殊記号
const SYMBOL = "\\^$*.[\\]{}()?\"!@#%&/\\\\,><':;|_~`+-";
// パスワードバリデーション用スキーマ
const schema = z
  .string()
  .regex(
    RegExp(
      `^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[${SYMBOL}])[a-zA-Z0-9${SYMBOL}]{12,99}$`,
    ),
  );

/**
 * パスワードがパスワードポリシーに沿っているかどうかチェックします。
 * @param password パスワード
 * @return true: 有効なパスワード / false: 無効なパスワード
 */
export const isValidPassword = (password: string): boolean => {
  return schema.safeParse(password).success;
};
