import {
  messageId,
  PropertyMeta,
  ValueObjectMetaComposite,
} from "@pscsrvlab/psc-react-components";
import { z } from "zod";
import { cellStockCategoryIdMeta } from "../value/cell-stock-category-id";
import { cellCategoryNumberMeta } from "../value/cell-category-number";
import { cellCategoryNameJaMeta } from "../value/cell-category-name-ja";
import { cellCategoryNameEnMeta } from "../value/cell-category-name-en";
import { cellTypeMeta } from "../value/cell-type";
/**
 * 細胞カテゴリ(値オブジェクト)
 *
 *  */
export type CellStockCategory = z.infer<typeof schema>;
export type CellStockCategorySaved = z.infer<typeof savedSchema>;
const minimalSchema = z.object({
  cellStockCategoryId: cellStockCategoryIdMeta.minimalSchema,
  cellCategoryNumber: cellCategoryNumberMeta.minimalSchema,
  cellCategoryNameJa: cellCategoryNameJaMeta.minimalSchema,
  cellCategoryNameEn: cellCategoryNameEnMeta.minimalSchema,
  cellType: cellTypeMeta.minimalSchema,
});
const savedMinimalSchema = z.object({
  cellStockCategoryId: cellStockCategoryIdMeta.savedMinimalSchema,
  cellCategoryNumber: cellCategoryNumberMeta.savedMinimalSchema,
  cellCategoryNameJa: cellCategoryNameJaMeta.savedMinimalSchema,
  cellCategoryNameEn: cellCategoryNameEnMeta.savedMinimalSchema,
  cellType: cellTypeMeta.savedMinimalSchema,
});
const schema = z.object({
  cellStockCategoryId: cellStockCategoryIdMeta.schema,
  cellCategoryNumber: cellCategoryNumberMeta.schema,
  cellCategoryNameJa: cellCategoryNameJaMeta.schema,
  cellCategoryNameEn: cellCategoryNameEnMeta.schema,
  cellType: cellTypeMeta.schema,
});
const savedSchema = z.object({
  cellStockCategoryId: cellStockCategoryIdMeta.savedSchema,
  cellCategoryNumber: cellCategoryNumberMeta.savedSchema,
  cellCategoryNameJa: cellCategoryNameJaMeta.savedSchema,
  cellCategoryNameEn: cellCategoryNameEnMeta.savedSchema,
  cellType: cellTypeMeta.savedSchema,
});
const jsonSchema = z.object({
  cellStockCategoryId: cellStockCategoryIdMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  cellCategoryNumber: cellCategoryNumberMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  cellCategoryNameJa: cellCategoryNameJaMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  cellCategoryNameEn: cellCategoryNameEnMeta.jsonSchema
    .nullish()
    .transform((x) => x ?? undefined),
  cellType: cellTypeMeta.jsonSchema.nullish().transform((x) => x ?? undefined),
});
/**
 * 細胞カテゴリ(値オブジェクト)のメタデータ。
 *
 *  */
export const cellStockCategoryMeta = new ValueObjectMetaComposite(
  "CellStockCategory",
  "\u7D30\u80DE\u30AB\u30C6\u30B4\u30EA",
  messageId("lbl.\u7D30\u80DE\u30AB\u30C6\u30B4\u30EA"),
  minimalSchema,
  savedMinimalSchema,
  schema,
  savedSchema,
  jsonSchema,
  undefined,
  undefined,
  {
    cellStockCategoryId: new PropertyMeta(
      "cellStockCategoryId",
      "\u7D30\u80DE\u30AB\u30C6\u30B4\u30EAID",
      messageId("lbl.\u7D30\u80DE\u30AB\u30C6\u30B4\u30EAID"),
      "always",
      false,
      cellStockCategoryIdMeta
    ),
    cellCategoryNumber: new PropertyMeta(
      "cellCategoryNumber",
      "\u7D30\u80DE\u30AB\u30C6\u30B4\u30EA\u756A\u53F7",
      messageId("lbl.\u7D30\u80DE\u30AB\u30C6\u30B4\u30EA\u756A\u53F7"),
      "always",
      false,
      cellCategoryNumberMeta
    ),
    cellCategoryNameJa: new PropertyMeta(
      "cellCategoryNameJa",
      "\u7D30\u80DE\u30AB\u30C6\u30B4\u30EA\u540D\uFF08\u65E5\uFF09",
      messageId(
        "lbl.\u7D30\u80DE\u30AB\u30C6\u30B4\u30EA\u540D\uFF08\u65E5\uFF09"
      ),
      "always",
      false,
      cellCategoryNameJaMeta
    ),
    cellCategoryNameEn: new PropertyMeta(
      "cellCategoryNameEn",
      "\u7D30\u80DE\u30AB\u30C6\u30B4\u30EA\u540D\uFF08\u82F1\uFF09",
      messageId(
        "lbl.\u7D30\u80DE\u30AB\u30C6\u30B4\u30EA\u540D\uFF08\u82F1\uFF09"
      ),
      "always",
      false,
      cellCategoryNameEnMeta
    ),
    cellType: new PropertyMeta(
      "cellType",
      "\u7D30\u80DE\u533A\u5206",
      messageId("lbl.\u7D30\u80DE\u533A\u5206"),
      "always",
      false,
      cellTypeMeta
    ),
  }
);
