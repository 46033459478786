import { ComponentStyleProps } from "../../../../../lib/styles/props/component-style-props";
import { Flex, VStack } from "@chakra-ui/react";
import React, { memo, useMemo } from "react";
import { useAppTranslation } from "../../../../../hooks/use-app-translation";
import { YearMonthDay } from "../../../../../lib/object/value/year-month-day";
import { WeakTextItem } from "../../../../ui/text/WeakTextItem/WeakTextItem";
import { ymdToDateTextOrHyphen } from "../../../../../lib/util/common-date-util";

export type DocumentHeaderDatesProps = {
  submissionDate: YearMonthDay | null | undefined;
  receptionDate: YearMonthDay | null | undefined;
  approvalDate: YearMonthDay | null | undefined;
  documentCompletionDate: YearMonthDay | null | undefined;
} & ComponentStyleProps;

/**
 * 各種書類の右上に表示する、提出日、受付日、承認日、完了日。
 */
export const DocumentHeaderDates = memo(function DocumentHeaderDates({
  submissionDate,
  receptionDate,
  approvalDate,
  documentCompletionDate,

  sx,
  ...rest
}: DocumentHeaderDatesProps) {
  const { t } = useAppTranslation();

  const submissionDateText = useMemo(() => {
    return ymdToDateTextOrHyphen(submissionDate);
  }, [submissionDate]);
  const receptionDateText = useMemo(() => {
    return ymdToDateTextOrHyphen(receptionDate);
  }, [receptionDate]);
  const approvalDateText = useMemo(() => {
    return ymdToDateTextOrHyphen(approvalDate);
  }, [approvalDate]);
  const documentCompletionDateText = useMemo(() => {
    return ymdToDateTextOrHyphen(documentCompletionDate);
  }, [documentCompletionDate]);

  return (
    <Flex justifyContent={"flex-end"} sx={sx} {...rest}>
      <VStack alignItems={"flex-start"} spacing={0}>
        <WeakTextItem label={t("lbl.提出日")} value={submissionDateText} />
        <WeakTextItem label={t("lbl.受付日")} value={receptionDateText} />
        <WeakTextItem label={t("lbl.承認日")} value={approvalDateText} />
        <WeakTextItem
          label={t("lbl.完了日")}
          value={documentCompletionDateText}
        />
      </VStack>
    </Flex>
  );
});
