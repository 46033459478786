import { VStack } from "@chakra-ui/react";
import { useAppTranslation } from "../../../../../hooks/use-app-translation";
import { ComponentStyleProps } from "../../../../../lib/styles/props/component-style-props";
import { useMemo } from "react";
import { CMMessageInfo } from "@pscsrvlab/psc-react-components";

export type ResultNotificationPageEmptyProps = {
  mode: "tbd" | "create_one" | "select_one";
} & ComponentStyleProps;
export const ResultNotificationPageEmpty = ({
  mode,

  sx,
  ...rest
}: ResultNotificationPageEmptyProps) => {
  const { t } = useAppTranslation();

  const message = useMemo(() => {
    switch (mode) {
      case "tbd":
        return t("gdc.結果未定インフォメーション");
      case "create_one":
        return t("gdc.結果通知作成インフォメーション");
      case "select_one":
        return t("gdc.結果通知選択案内");
    }
  }, [mode, t]);
  return (
    <VStack flex={1} alignItems={"flex-start"} sx={sx} {...rest}>
      <CMMessageInfo mt={"12px"} ml={"20px"} label={message} />
    </VStack>
  );
};
