import { ComponentStyleProps } from "../../../../../lib/styles/props/component-style-props";
import { LabeledControlNumber } from "../../../../ui/text/LabeledControlNumber/LabeledControlNumber";
import { Container, Flex, VStack } from "@chakra-ui/react";
import React, { memo } from "react";
import { useAppTranslation } from "../../../../../hooks/use-app-translation";

export type DocumentHeaderControlNumbersProps = {
  projectControlNumber: string | null | undefined;
  documentControlNumber: string | null | undefined;
} & ComponentStyleProps;

/**
 * 各種書類の右上に表示する、案件管理番号と書類管理番号。
 */
export const DocumentHeaderControlNumbers = memo(
  function DocumentHeaderControlNumbers({
    projectControlNumber,
    documentControlNumber,

    sx,
    ...rest
  }: DocumentHeaderControlNumbersProps) {
    const { t } = useAppTranslation();
    return (
      <Container minW={"400px"} maxW={"720px"} pt={"10px"} sx={sx} {...rest}>
        <Flex justifyContent={"flex-end"}>
          <VStack alignItems={"flex-start"}>
            <LabeledControlNumber
              label={t("lbl.案件管理番号")}
              value={projectControlNumber}
            />
            <LabeledControlNumber
              label={t("lbl.書類管理番号")}
              value={documentControlNumber}
            />
          </VStack>
        </Flex>
      </Container>
    );
  },
);
